/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import ReactDOM from 'react-dom';
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { PowerShell } from '../svg/power-shell';
import { Windows } from '../svg/windows';
import { Skull } from '../svg/skull';

@observer
export class Slide06 extends AnimatedSlide {
  // Animation players
  players = {
    windows: null,
    powershell: null,
    execute1: null,
    execute2: null,
    attacker: null,
  };

  // Progress references
  progress1 = 0;

  // Run the first animation
  componentDidMount() {
    const { windows, powershell, execute1, execute2, attacker } = this.players;

    // Load connection title
    let executeTitle1 = ReactDOM.findDOMNode(execute1).childNodes[0];
    let executeTitle2 = ReactDOM.findDOMNode(execute2).childNodes[0];
    let powershellLine = ReactDOM.findDOMNode(powershell).childNodes[1];

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(7000);
        executeTitle1.classList.remove('red-top');
        executeTitle2.classList.remove('red-top');
        powershellLine.classList.remove('red');
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(2000);
      },
      // Step 3
      () => {
        this.autoNext(500);
      },
      // Step 4
      () => {
        this.autoNext(2000);
      },
      // Step 4
      () => {
        powershellLine.classList.add('red');
        this.autoNext(500);
      },
      // Step 4
      () => {
        executeTitle1.classList.add('red-top');
        executeTitle2.classList.add('red-top');
        this.autoNext(500);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Do nothing
      ],
      //
      // Step 1 - attacker shows up
      //
      [
        {
          targets: windows,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: powershell,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 500,
          complete: activateShimmer,
        },
        {
          targets: attacker,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 1000,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2 - Execute powershell 1
      //
      [
        {
          targets: execute1,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 3 - Execute powershell 2
      //
      [
        {
          targets: execute1,
          opacity: 1,
          translateY: '-200px',
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: execute2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 300,
          duration: 500,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-06 ${className}`}>
        {children}
        <div className="content">
          {/* Windows */}
          <div
            className="player windows"
            data-active="0"
            ref={node => (this.players.windows = node)}
          >
            <Card IconComponent={Windows} text={'Windows'} />
            <div className={'animated-dotted-line reverse ants-1'}></div>
            <div className={'animated-dotted-line ants-2'}></div>
          </div>
          {/* Attacker */}
          <div
            className="player attacker"
            data-active="0"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text={'Attacker'} />
            <div className={'animated-dotted-line reverse red ants-1'}></div>
            <div
              className={'animated-dotted-line reverse ants-2 red ants-2'}
            ></div>
          </div>
          {/* Powershell */}
          <div
            className="player powershell"
            data-active="0"
            ref={node => (this.players.powershell = node)}
          >
            <PlayerCircleIcon
              color={this.colors.blue}
              size={100}
              IconSize={60}
              Icon={PowerShell}
            />
            <div className={'animated-dotted-line reverse ants-1'}></div>
          </div>
          {/* Execute Powershell 1st time */}
          <div
            className="player execute1"
            data-active="0"
            ref={node => (this.players.execute1 = node)}
          >
            <PlayerTextBlock
              title={'EXECUTING POWERSHELL'}
              sections={[
                {
                  title: ['MEMO.PS1'],
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['SOURCE', 'WINDOWS'],
                  grid: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['PASS'],
                  color: 'green',
                  colorCells: 1,
                  grid: 2,
                  animate: true,
                  delay: 600,
                },
              ]}
            />
          </div>
          {/* Execute Powershell 2nd time */}
          <div
            className="player execute2"
            data-active="0"
            ref={node => (this.players.execute2 = node)}
          >
            <PlayerTextBlock
              title={'EXECUTING POWERSHELL'}
              sections={[
                {
                  title: ['MEMO.PS1'],
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['SOURCE', 'WINDOWS'],
                  grid: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['PASS'],
                  color: 'green',
                  colorCells: 1,
                  grid: 2,
                  animate: true,
                  delay: 600,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
