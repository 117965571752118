/** @jsx createElement */
import { observer } from 'mobx-react';
import { getColor, uid } from '../helpers';
import {
  cloneElement,
  isValidElement,
  createElement,
  Component,
  HTMLAttributes,
} from 'react';
import ReactDOM from 'react-dom';
import anime from 'animejs';
import './icon-load.scss';

// SVG
import { Warning } from '../svg/warning';
import { Loading } from '../svg/loading';

@observer
export class PlayerIconLoad extends Component {
  cx = 0;
  cy = 0;
  r = 0;
  size = 70;
  timer = null;
  complete = null;
  node = null;

  wrapperStyles = {};
  strokeWidth = 4;
  shimmer = '';
  animation = null;
  autostart = true;
  complete = null;

  start = {
    id: uid(),
    IconComponent: Warning,
    icon: null,
    color: getColor('white'),
    size: 40,
    stroke: getColor('white'),
    left: 0,
    top: 0,
    strokeWidth: 2,
    opacity: 0.2,
    node: null,
  };

  loading = {};

  loadingPreset = {
    id: uid(),
    completeId: 0,
    duration: 2000,
    delay: 1000,
    strokeDashoffset: '10 100',
    repeatCount: 'indefinite',
    stroke: getColor('green'),
    node: null,
    circle: null,
  };

  end = {};

  endPreset = {
    id: uid(),
    completeId: 0,
    IconComponent: null,
    icon: null,
    color: getColor('white'),
    size: 40,
    stroke: getColor('blue'),
    opacity: 1,
    node: null,
  };

  // Animation presets
  animations = {
    fadeIn: {
      opacity: [0, 1],
      duration: 500,
    },
    fadeOut: {
      opacity: [1, 0],
      duration: 200,
    },
  };

  componentWillUnmount() {
    anime.remove(this.animation);
  }

  // Component mounted
  componentDidMount() {
    // Stuff
    if (this.loading.node) {
      this.loading.node.style.opacity = 0;
      if (this.autostart) this.loadingAnimation();
    }
    if (this.end.node) {
      this.end.node.style.opacity = 0;
    }
  }

  addClass(node, className = 'shimmer') {
    if (!node) return;
    setTimeout(() => {
      node.classList.add(className);
    }, 1);
  }

  removeClass(node, className = 'shimmer') {
    if (!node) return;
    node.classList.add(className);
  }

  loadingAnimation() {
    if (!this.loading.duration) return;
    // Show loading
    this.animation = anime({
      targets: this.loading.circle,
      delay: this.loading.delay,
      duration: this.loading.duration,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      begin: () => {
        if (!this.loading.node) return;
        this.loading.node.style.opacity = 1;
      },
      complete: () => {
        this.addClass(this.node);
        this.endingAnimation();
      },
    });
  }

  changeIcon() {
    if (!this.start.node || !this.end.node) return;
    this.start.node.style.opacity = 0;
    this.end.node.style.opacity = 1;
    if (this.loading.node) {
      this.loading.node.style.opacity = 0;
    }
    setTimeout(() => {
      this.node.classList.add('shimmer');
    }, 1);
    if (typeof this.complete === 'function') {
      this.complete();
    }
  }

  endingAnimation() {
    if (!this.end.node) return;
    this.animation = anime({
      targets: this.end.node,
      begin: () => this.changeIcon(),
    });
  }

  /*
  <PlayerIconLoad
    size={70}
    strokeWidth={2}
  />
  */

  getIcon(settings) {
    if (settings.icon && isValidElement(settings.icon)) {
      return cloneElement(settings.icon, {
        x:
          (this.size - parseInt(settings.icon.props.size)) / 2 -
          this.strokeWidth,
        y:
          (this.size - parseInt(settings.icon.props.size)) / 2 +
          this.strokeWidth,
      });
    }
    if (!settings.IconComponent) return;
    // Calculate x,y coordinates for center
    let x = (this.size - settings.size) / 2;
    let y = (this.size - settings.size) / 2;
    if ('left' in settings) {
      x += settings.left;
    }
    if ('top' in settings) {
      y += settings.top;
    }
    return (
      <settings.IconComponent
        size={settings.size}
        color={settings.color}
        strokeWidth={settings.strokeWidth}
        x={x}
        y={y}
      />
    );
  }

  renderLoading() {
    if (Object.keys(this.loading).length === 0) return;
    if ('node' in this.end) {
      return (
        <g id={this.loading.id} ref={node => (this.loading.node = node)}>
          <circle
            className={`Player__IconLoad__Loader`}
            cx={this.cx}
            cy={this.cy}
            r={this.r}
            fill={'none'}
            stroke={this.loading.stroke}
            strokeWidth={this.strokeWidth}
            opacity={this.loading.opacity}
            ref={node => (this.loading.circle = node)}
          />
        </g>
      );
    }
    return (
      <Loading
        strokeWidth={this.strokeWidth}
        size={this.size}
        dash={this.loading.strokeDashoffset}
        delay={this.loading.delay * -1}
      />
    );
  }

  // Render
  render() {
    const {
      className = '',
      size = 70,
      strokeWidth = 2,
      start = {},
      loading = null,
      end = null,
      complete = null,
    } = this.props;

    // Set styles
    this.wrapperStyles.width = parseInt(size) + 'px';
    this.wrapperStyles.height = parseInt(size) + 'px';
    this.strokeWidth = strokeWidth;
    this.size = size;
    this.complete = complete;

    // Get settings
    Object.assign(this.start, start);
    let nextId = this.start.id;
    if (loading) {
      Object.assign(this.loading, this.loadingPreset, loading);
      nextId = this.loading.id;
    }
    if (end) {
      Object.assign(this.end, this.endPreset, end);
    }

    // Circle properties
    this.cx = size / 2;
    this.cy = size / 2;
    this.r = size / 2 - this.strokeWidth / 2;

    // Render
    return (
      <div
        className={`Player__IconLoad ${className} ${this.shimmer}`}
        style={this.wrapperStyles}
        ref={node => (this.node = node)}
      >
        <svg viewBox={`0 0 ${size} ${size}`} xmlns="http://www.w3.org/2000/svg">
          <g id={this.start.id} ref={node => (this.start.node = node)}>
            <circle
              cx={this.cx}
              cy={this.cy}
              r={this.r}
              fill={'none'}
              stroke={this.start.stroke}
              strokeWidth={this.strokeWidth}
              opacity={this.start.opacity}
            />
            {this.getIcon(this.start)}
          </g>
          {this.renderLoading()}
          {this.end && this.end.IconComponent && (
            <g id={this.end.id} ref={node => (this.end.node = node)}>
              <circle
                cx={this.cx}
                cy={this.cy}
                r={this.r}
                fill={'none'}
                stroke={this.end.stroke}
                strokeWidth={this.strokeWidth}
                opacity={this.end.opacity}
              />
              {this.getIcon(this.end)}
            </g>
          )}
        </svg>
      </div>
    );
  }
}
