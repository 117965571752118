/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { StoryBlock } from '../story-block';
import { PlayerDemoGoals } from '../players/demo-goals';

// SVG
import { Bug } from '../svg/bug';
import { Code2 } from '../svg/code2';
import { Key } from '../svg/key';
import { Bell } from '../svg/bell';
import { CheckShield } from '../svg/check-shield';
import { Check } from '../svg/check';
import { Clock } from '../svg/clock';
import { Eye } from '../svg/eye';

@observer
export class Slide01 extends AnimatedSlide {
  // Animation players
  players = {
    card1: null,
    card2: null,
    card3: null,
    card4: null,
    card5: null,
    card6: null,
  };

  // Run the first animation
  componentDidMount() {
    const { card1, card2, card3, card4, card5, card6 } = this.players;

    this.steps = [
      () => {
        this.animateProgress(3000);
        this.autoNext(200);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        {
          targets: card1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 0,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card3,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card4,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card5,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2000,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card6,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2500,
          duration: 500,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-01 ${className}`}>
        {children}
        <div className="content">
          {/* Story block 1 */}
          <div
            className="player block1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <StoryBlock
              IconComponent={Bug}
              title={'Attackers take advantage of holiday weekend'}
              color={this.colors.red}
              description={
                "Just before the long July 4th weekend in 2021, eSentire's TRU team became aware that Kaseya may have been the victim of a supply chain attack."
              }
            />
          </div>

          {/* Story block 2 */}
          <div
            className="player block2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <StoryBlock
              IconComponent={Clock}
              title={'TRU team begins proactive threat hunts within 30 minutes'}
              color={this.colors.blue}
              description={
                "Once reports of mass exploitation of Kaseya's VSA product were confirmed, the Threat Response Unit (TRU) team moved within 30 minutes to weaponize early intel and drive proactive threat hunts across the entire client base for log and endpoint indicators of compromise (IoCs). "
              }
            />
          </div>

          {/* Story block 3 */}
          <div
            className="player block3"
            data-active="0"
            ref={node => (this.players.card3 = node)}
          >
            <StoryBlock
              IconComponent={Eye}
              title={
                'Threat hunts immediately confirm a customer has been impacted'
              }
              color={this.colors.blue}
              description={
                'A postive threat detection revealed that at least one customer has been impacted. eSentire SOC cyber analysts isolate impacted endpoints to prevent lateral spread, remediate and return impacted systems to service.'
              }
            />
          </div>

          {/* Story block 4 */}
          <div
            className="player block4"
            data-active="0"
            ref={node => (this.players.card4 = node)}
          >
            <div className={'animated-dotted-line ants-1'}></div>
            <div className={'animated-dotted-line ants-2'}></div>
            <div className={'animated-dotted-line ants-3'}></div>
            <StoryBlock
              IconComponent={Eye}
              title={'TRU sets up a dedicated team for Kaseya'}
              color={this.colors.blue}
              description={
                'A cross-functional team is set up, and the TRU team collects known IoCs based on proactive threat hunts and adds them to the Threat Intelligence Platform for processing and development of machine-learning powered threat hunting activities across the global client base.'
              }
            />
          </div>

          {/* Story block 5 */}
          <div
            className="player block5"
            data-active="0"
            ref={node => (this.players.card5 = node)}
          >
            <StoryBlock
              IconComponent={Bell}
              title={'Security Advisory sent to all customers'}
              color={this.colors.blue}
              description={
                'A Security Advisory goes out to all customers, and by that night eSentire SOC cyber analysts ban known malicious hashes across MDR for Endpoint customers, effectively protecting all endpoints across our customer base from any Kaseya exploit.'
              }
            />
          </div>

          {/* Story block 6 */}
          <div
            className="player block6"
            data-active="0"
            ref={node => (this.players.card6 = node)}
          >
            <StoryBlock
              IconComponent={CheckShield}
              title={
                'By Monday, a new Atlas XDR detection is pushed to all MDR for Endpoint customers'
              }
              color={this.colors.blue}
              description={
                '24/7 monitoring and investigations over the July 4th weekend demonstrated additional indicators of compromise in the Kaseya detection tool. TRU develops incremental runbooks and updates machine learning models to improve detections and automated blocks. By the end of the day Monday, a machine-learning powered Atlas XDR detection is pushed across all MDR for Endpoint customers, automating threat detections for Kaseya to protect clients from potential impact, and a new investigation runbook is ready for eSentire SOC cyber analysts to use for any new detections and human-led investigation.'
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
