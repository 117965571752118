/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Template__TeamSendsAdvisory } from '../../templates/team-sends-advisory';

@observer
export class Slide12 extends Component {
  componentDidMount() {
    // Do something
  }

  render() {
    const { children } = this.props;
    return (
      <div className={`slide-12`}>
        {children}
        <Template__TeamSendsAdvisory
          title={{
            text:
              'PoC Released, Active Exploitation of Exchange Vulnerabilities Observed',
            fontSize: 40,
            lineHeight: 30,
            letterSpacing: -3,
          }}
        />
      </div>
    );
  }
}
