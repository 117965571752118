/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class SignalCloud extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 2,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity={opacity}
        >
          <path d="M44.6557 28.6781V33.8142M45.1974 51.9357C54.1314 51.9357 58.9999 44.2894 58.9999 36.3465C59.0084 34.3072 58.6151 32.2862 57.8424 30.399C57.0697 28.5118 55.9327 26.7953 54.4965 25.3476C53.0602 23.8999 51.3528 22.7494 49.4717 21.9617C47.5907 21.1741 45.5729 20.7648 43.5336 20.7572C43.2048 20.7587 42.8764 20.778 42.5498 20.8151C41.2278 16.8938 38.5486 13.5737 34.9952 11.4531C31.4418 9.33246 27.2478 8.55077 23.169 9.24889C19.0902 9.94702 15.3949 12.079 12.7491 15.2607C10.1032 18.4425 8.68075 22.4646 8.73812 26.6023C8.74028 27.458 8.81287 28.3121 8.95514 29.1559C6.33159 30.0418 4.1074 31.8297 2.67828 34.2014C1.24915 36.5732 0.707781 39.3751 1.15048 42.1086C1.59318 44.842 2.99124 47.3298 5.09593 49.1293C7.20061 50.9287 9.87542 51.9232 12.6445 51.9357H45.1974Z" />
        </g>
      </svg>
    );
  }
}
