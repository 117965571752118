/** @jsx createElement */
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import './risk-analysis-tool.scss';
import { Router, RouteManager } from 'vega-router';
import { AppActions } from '../app-actions';
import { HamburgerIcon } from '../svg/hamburger-icon';
import { EsentireIcon } from '../svg/esentire-icon';
import { RiskAnalysisImpact } from './impact/impact';
import { RiskAnalysisProbability } from './probability/probability';
import { RiskAnalysisRates } from './rates/rates';
import { Popup } from '../popup';
import { Gear } from '../svg/gear';
import { Input } from '../input';
import { Select } from '../select';
import { Modal } from '../modal';
import { ChartDataStore } from '../../data/graph-data-transforms';
import {
  RiskAnalysisToolActions,
  RiskAnalysisToolState,
  SENSORS_OPTIONS,
} from './risk-analysis-tool-actions';

@observer
export class RiskAnalysisTool extends Router {
  constructor(props) {
    super(props);

    this.addRoute('impact', /^\/risk\/impact/, RiskAnalysisImpact);
    this.addRoute(
      'probability',
      /^\/risk\/probability/,
      RiskAnalysisProbability
    );

    this.contentFrame = null;
    this.contentFrameContainer = null;

    // Must be last
    this.addRoute('rates', /^\/(.*)/, RiskAnalysisRates);
  }

  componentDidMount() {
    AppActions.setShortcutName('Risk Analysis');
    AppActions.hideBackgroundGrid();

    // Make sure the filter selections are initialized
    RiskAnalysisToolActions.initFilterSelection();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
  }

  changeInput = (type, asNumber) => value => {
    if (asNumber) {
      if (!value) value = '0';
      const check = Number.parseFloat(value);
      if (isNaN(check)) return;
      value = check;
    }

    RiskAnalysisToolActions.updateField(type, { label: value, value });
  };

  changeSelect = type => value => {
    RiskAnalysisToolActions.updateField(type, value);
  };

  closeModal = () => {
    RiskAnalysisToolActions.closeModal();
  };

  render() {
    const { className = '' } = this.props;

    const title = 'Risk Analysis';
    const subtitle = `${RiskAnalysisToolState.title}`;

    const popupTrigger = (
      <div className="RiskAnalysisTool__BottomNav__Settings__Gear">
        <Gear />
      </div>
    );

    return (
      <div className={`RiskAnalysisTool ${className}`}>
        <Modal
          show={RiskAnalysisToolState.showProbabilityModal}
          onClose={this.closeModal}
        >
          <h3>Incident Probability</h3>
          <p>
            This tool simulates for you what the chance of discovering one or
            more incidents of each type on one or more of your sensors would
            have been if a monitoring program was instituted for a company of
            your specified size, filtered by selected industry/geography. It
            gives a raw breakdown of your probability for finding such a
            situation, but doesn’t really talk about what that means or how big
            the hole might be. The confidence of our projection is based on how
            big a sample size we have of people matching your filter conditions,
            but a good way to think about the interval is to treat the lower
            bounds as a “best case”, and the upper bounds as a “worst case”,
            with the observed rate being an “average case”. To get a better look
            at what the impact from these risks can look like, check out our
            Impact Calculator.
          </p>
        </Modal>
        <Modal
          show={RiskAnalysisToolState.showImpactModal}
          onClose={this.closeModal}
        >
          <h3>Yearly Impacted Assets for Incidents</h3>
          <p>
            This tool displays the percentage of clients we monitored with your
            filtering criteria who required containment or remediation efforts
            by the size of the containment effort, measured as a yearly bucket
            size of all assets impacted by incident of this type assuming an
            incident occurred.
          </p>
          <p>
            This view can help you get a better handle on what the range is in
            terms of cleanup and containment costs after breaches are detected.
            It shows you what the different buckets are in terms of how many
            assets were impacted and what percentage of clients fell within that
            bucket, so you can get a better handle on what a “best case”, “worst
            case”, and “most likely case” looks like for you if the incidents we
            talked about in the probability section happen for you. You’ll
            probably want to think about what your environment looks like here –
            if you have a locked down environment and close holes as soon as you
            see the first incident, you’re probably closer to the left. If you
            have a fairly flat environment without many internal controls and
            often leave holes to the outside open for business reasons, a breach
            probably has a cleanup cost closer to the right. This doesn’t assess
            the probability that an incident of that type will occur – for that,
            check out our Probability Calculator.
          </p>
        </Modal>
        <div className="RiskAnalysisTool__MainMenu">
          <button
            className="HamburgerButton"
            onClick={() => RouteManager.transitionTo('/')}
          >
            <HamburgerIcon color="#fafafa" opacity={0.7} />
          </button>
        </div>
        <div className="RiskAnalysisTool__Logo">
          <EsentireIcon />
        </div>
        {title && <div className="RiskAnalysisTool__Title">{title}</div>}
        {subtitle && (
          <div className="RiskAnalysisTool__Subtitle capitalize">
            {subtitle}
          </div>
        )}
        <div className="RiskAnalysisTool__BottomNav">
          <div
            className={`RiskAnalysisTool__BottomNav__NavItem ${
              this.route.name === 'rates'
                ? 'RiskAnalysisTool__BottomNav__NavItem--active'
                : ''
            }`}
            onClick={() => {
              RiskAnalysisToolState.title = 'Risk Rates';
              RouteManager.transitionTo('/risk/rates');
            }}
          >
            Risk Rates
          </div>
          <div
            className={`RiskAnalysisTool__BottomNav__NavItem ${
              this.route.name === 'probability'
                ? 'RiskAnalysisTool__BottomNav__NavItem--active'
                : ''
            }`}
            onClick={() => {
              RiskAnalysisToolState.title = 'Probability Calculator';
              RouteManager.transitionTo('/risk/probability');
            }}
          >
            Probability Calculator
          </div>
          <div
            className={`RiskAnalysisTool__BottomNav__NavItem ${
              this.route.name === 'impact'
                ? 'RiskAnalysisTool__BottomNav__NavItem--active'
                : ''
            }`}
            onClick={() => {
              RiskAnalysisToolState.title = 'Impact Calculator';
              RouteManager.transitionTo('/risk/impact');
            }}
          >
            Impact Calculator
          </div>
          <div className="RiskAnalysisTool__BottomNav__Settings">
            <Popup
              className="RiskAnalysisTool__BottomNav__Settings__Popup"
              position="bottom-right"
              trigger={popupTrigger}
              width={700}
            >
              <Select
                className={`RiskAnalysisTool__BottomNav__Settings__Select`}
                value={RiskAnalysisToolState.category}
                options={RiskAnalysisToolState.categoryOptions}
                onChange={this.changeSelect('category')}
                label="Category"
                nested
              />
              <Input
                className={`RiskAnalysisTool__BottomNav__Settings__Select`}
                value={RiskAnalysisToolState.sensors.value}
                onChange={this.changeInput('sensors', true)}
                label="Sensors"
                width={80}
                nested
              />
              <Select
                className={`RiskAnalysisTool__BottomNav__Settings__Select`}
                value={RiskAnalysisToolState.filter}
                options={RiskAnalysisToolState.filterOptions}
                onChange={this.changeSelect('filter')}
                label="Filter"
                nested
              />
            </Popup>
          </div>
        </div>
        {ChartDataStore.loadingRiskData ||
        ChartDataStore.loadingAssetData ||
        false ? (
          <div className="RiskAnalysisTool__Middle__Loader">
            <div className="RiskAnalysisTool__Middle__Spinner" />
            <div className="RiskAnalysisTool__Middle__LoaderLabel">
              Loading Data
            </div>
          </div>
        ) : (
          <div className="RiskAnalysisTool__Middle FadeIn">
            {super.render()}
          </div>
        )}
      </div>
    );
  }
}
