/** @jsx createElement */
// SVG
import { World } from '../svg/world';
import { USAScanner } from '../svg/usa-scanner';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import { observable } from 'mobx';
import ReactDOM from 'react-dom';

@observer
export class Slide06 extends AnimatedSlide {
  // Animation players
  players = {
    world: null,
    scanner: null,
    dots: null,
  };

  // Scanner stuff
  @observable
  scanner = {
    node: null, // The node
    index: 0, // Starting index of positions
    hold: 100, // How long to hold each point
    timer: null, // The timer
    positions: [
      // Max top = 115px
      // Max left = 245px
      { top: '100px', left: '105px' },
      { top: '185px', left: '555px' },
      { top: '45px', left: '305px' },
      { top: '315', left: '190px' },
      { top: '250px', left: '430px' },
      { top: '360px', left: '730px' },
      { top: '180px', left: '730px' },
      { top: '130px', left: '580px' },
      { top: '180px', left: '420px' },
      { top: '110px', left: '450px' },
      { top: '250px', left: '140px' },
      { top: '130px', left: '120px' },
      { top: '100px', left: '430px' },
      { top: '130px', left: '120px' },
      { top: '250px', left: '580px' },
      { top: '260px', left: '650px' },
      { top: '70px', left: '570px' },
      { top: '180px', left: '420px' },
      { top: '250px', left: '430px' },
      { top: '45px', left: '305px' },
      { top: '100px', left: '430px' },
    ],
  };

  // Animate the scanner
  animateScanner() {
    let node = ReactDOM.findDOMNode(this.scanner.node);
    let positions = this.scanner.positions;
    let index = this.scanner.index;
    this.scanner.timer = setInterval(() => {
      index++;
      if (index >= positions.length) index = 0;
      node.style.top = positions[index].top;
      node.style.left = positions[index].left;
    }, this.scanner.hold);
  }

  // Run the first animation
  componentDidMount() {
    const { world, scanner, dots } = this.players;

    this.steps = [
      () => {
        this.animateProgress(4000);
        this.autoNext(4000);
        this.animateScanner();
      },
      () => {
        if (world) {
          world.classList.add('off');
        }
        clearInterval(this.scanner.timer);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [],
      //
      // Step 2
      //
      [
        {
          targets: dots,
          opacity: 1,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: scanner,
          opacity: 0,
          duration: 200,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-06 ${className}`}>
        {children}
        <div className="content">
          {/*-------PLAYERS START-------*/}

          {/* World */}
          <div
            className="player world"
            data-active="1"
            ref={node => (this.players.world = node)}
          >
            <USAScanner ref={node => (this.scanner.node = node)} />
            <World size={862} />
          </div>

          {/* Dots */}
          <div
            className="player dots"
            data-active="0"
            ref={node => (this.players.dots = node)}
          >
            <span>44</span>
            <span>32</span>
          </div>

          {/*-------PLAYERS END-------*/}
        </div>
      </div>
    );
  }
}
