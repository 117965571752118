/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class DropDown2 extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 11,
      x = 0,
      y = 0,
      opacity = 0.4,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.99999 5.5C3.89096 5.50014 3.78315 5.47702 3.68377 5.43218C3.58438 5.38735 3.49571 5.32183 3.42365 5.24L0.153319 1.52267C0.0499552 1.39949 -0.00111138 1.24075 0.0110385 1.08041C0.0231884 0.920061 0.0975904 0.770833 0.218335 0.664632C0.33908 0.55843 0.496584 0.503684 0.657168 0.512101C0.817752 0.520517 0.96867 0.591427 1.07765 0.709668L3.93732 3.96033C3.94514 3.96926 3.95478 3.97641 3.96558 3.98131C3.97639 3.9862 3.98812 3.98874 3.99999 3.98874C4.01185 3.98874 4.02358 3.9862 4.03439 3.98131C4.04519 3.97641 4.05483 3.96926 4.06265 3.96033L6.92232 0.709668C6.97506 0.64681 7.03984 0.595129 7.11285 0.557664C7.18585 0.5202 7.26561 0.497706 7.34743 0.491506C7.42925 0.485306 7.51148 0.495525 7.5893 0.521562C7.66712 0.547599 7.73894 0.588928 7.80056 0.643121C7.86217 0.697314 7.91233 0.763277 7.94809 0.837133C7.98384 0.910988 8.00447 0.991247 8.00877 1.07319C8.01306 1.15513 8.00093 1.23711 7.97309 1.3143C7.94525 1.39149 7.90226 1.46233 7.84665 1.52267L4.57732 5.23867C4.50514 5.3207 4.41633 5.38642 4.31678 5.43148C4.21724 5.47654 4.10925 5.4999 3.99999 5.5Z"
          fill={color}
        />
      </svg>
    );
  }
}
