import { Bug } from '../../components/svg/bug';
import { Crosshair } from '../../components/svg/crosshair';
import { Page } from '../../components/svg/page';
import { Cloud } from '../../components/svg/cloud';
import { Sun } from '../../components/svg/sun';
import { Kaseya } from '../../components/svg/kaseya';
import { Squirrel } from '../../components/svg/squirrel';
import { Log4j } from '../../components/svg/log4j';
import { Proxyshell } from '../../components/svg/proxyshell';

import { genericPhishingSlides } from './slides/generic-phishing';
import { ransomwareSlides } from './ransomware/slides';
import {
  esinsiderSlides,
  ESINSIDER_CATEGORY,
  ESINSIDER_CATEGORY_ICON,
  ESINSIDER_COLUMNS,
} from './esinsider/slides';
import {
  cloudSlides,
  CLOUD_CATEGORY,
  CLOUD_CATEGORY_ICONS,
  CLOUD_COLUMNS,
} from './cloud/slides';
import {
  powershellSlides,
  POWERSHELL_CATEGORY,
  POWERSHELL_CATEGORY_ICONS,
  POWERSHELL_COLUMNS,
} from './powershell/slides';
import {
  sunwalkerSlides,
  SUNWALKER_CATEGORY,
  SUNWALKER_CATEGORY_ICONS,
  SUNWALKER_COLUMNS,
} from './sunwalker/slides';
import {
  kaseyaSlides,
  KASEYA_CATEGORY,
  KASEYA_CATEGORY_ICONS,
  KASEYA_COLUMNS,
} from './kaseya/slides';
import {
  waffleSlides,
  WAFFLE_CATEGORY,
  WAFFLE_CATEGORY_ICONS,
  WAFFLE_COLUMNS,
} from './waffle/slides';
import {
  logSlides,
  LOG_CATEGORY,
  LOG_CATEGORY_ICONS,
  LOG_COLUMNS,
} from './log/slides';
import {
  proxySlides,
  PROXY_CATEGORY,
  PROXY_CATEGORY_ICONS,
  PROXY_COLUMNS,
} from './proxyshell/slides';
import { CATEGORY, CATEGORY_ICONS, CATEGORY_COLUMNS } from '../category';
import { Binoculars } from '../../components/svg/binoculars';

const COLUMNS = [[CATEGORY.INFECTION], [CATEGORY.INVESTIGATION]];

export const useCases = [
  {
    key: 'malicious-documents',
    title: 'Phishing: Malicious Document',
    product: 'eSentire MDR for Endpoint / eSentire MDR for Network',
    products: ['MDR for Endpoint', 'MDR for Network'],
    icon: Page,
    attributes: [],
    slides: genericPhishingSlides,
    CATEGORY: CATEGORY,
    CATEGORY_ICONS: CATEGORY_ICONS,
    COLUMNS: CATEGORY_COLUMNS,
  },
  {
    key: 'powershell',
    title: 'Powershell: Bluesteel',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    products: ['MDR for Endpoint', 'MDR for Network', 'MDR for Log'],
    icon: Crosshair,
    attributes: [],
    slides: powershellSlides,
    CATEGORY: POWERSHELL_CATEGORY,
    CATEGORY_ICONS: POWERSHELL_CATEGORY_ICONS,
    COLUMNS: POWERSHELL_COLUMNS,
  },
  {
    key: 'ransomware',
    title: 'Ransomware',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    products: ['MDR for Endpoint', 'MDR for Network', 'MDR for Log'],
    icon: Bug,
    attributes: [],
    slides: ransomwareSlides,
    CATEGORY: CATEGORY,
    CATEGORY_ICONS: CATEGORY_ICONS,
    COLUMNS: CATEGORY_COLUMNS,
  },
  {
    key: 'cloud',
    title: 'Cloud Compromise',
    product: 'eSentire MDR for Endpoint / eSentire MDR for Log',
    products: ['MDR for Endpoint', 'MDR for Log'],
    icon: Cloud,
    attributes: [],
    slides: cloudSlides,
    CATEGORY: CLOUD_CATEGORY,
    CATEGORY_ICONS: CLOUD_CATEGORY_ICONS,
    COLUMNS: CLOUD_COLUMNS,
  },
  {
    key: 'esinsider',
    title: 'Insider Threat',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    products: ['MDR for Endpoint', 'MDR for Network', 'MDR for Log'],
    icon: Binoculars,
    attributes: [],
    slides: esinsiderSlides,
    CATEGORY: ESINSIDER_CATEGORY,
    CATEGORY_ICONS: ESINSIDER_CATEGORY_ICON,
    COLUMNS: ESINSIDER_COLUMNS,
  },
  {
    key: 'sunwalker',
    title: 'Sunwalker',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    products: ['MDR for Endpoint', 'MDR for Network', 'MDR for Log'],
    icon: Sun,
    attributes: [],
    slides: sunwalkerSlides,
    CATEGORY: SUNWALKER_CATEGORY,
    CATEGORY_ICONS: SUNWALKER_CATEGORY_ICONS,
    COLUMNS: SUNWALKER_COLUMNS,
  },
  {
    key: 'kaseya',
    title: 'Kaseya',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    products: ['MDR for Endpoint', 'MDR for Network', 'MDR for Log'],
    icon: Kaseya,
    attributes: [],
    slides: kaseyaSlides,
    CATEGORY: KASEYA_CATEGORY,
    CATEGORY_ICONS: KASEYA_CATEGORY_ICONS,
    COLUMNS: KASEYA_COLUMNS,
  },
  {
    key: 'waffle',
    title: 'SquirrelWaffle',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    products: ['MDR for Endpoint', 'MDR for Network', 'MDR for Log'],
    icon: Squirrel,
    attributes: [],
    slides: waffleSlides,
    CATEGORY: WAFFLE_CATEGORY,
    CATEGORY_ICONS: WAFFLE_CATEGORY_ICONS,
    COLUMNS: WAFFLE_COLUMNS,
  },
  {
    key: 'log4j',
    title: 'Log4j',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    products: ['MDR for Endpoint', 'MDR for Network', 'MDR for Log'],
    icon: Log4j,
    attributes: [],
    slides: logSlides,
    CATEGORY: LOG_CATEGORY,
    CATEGORY_ICONS: LOG_CATEGORY_ICONS,
    COLUMNS: LOG_COLUMNS,
  },
  {
    key: 'proxyshell',
    title: 'ProxyShell',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    products: ['MDR for Endpoint', 'MDR for Network', 'MDR for Log'],
    icon: Proxyshell,
    attributes: [],
    slides: proxySlides,
    CATEGORY: PROXY_CATEGORY,
    CATEGORY_ICONS: PROXY_CATEGORY_ICONS,
    COLUMNS: PROXY_COLUMNS,
  },
];
