/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// SCSS
import '../message.scss';

// Players
import { PlayerDots } from '../players/dots';
import { PlayerDataScan } from '../players/data-scan';
import { PlayerProgressPie } from '../players/progress-pie';

// SVG
import { Shield } from '../svg/shield';
import { Signal } from '../svg/signal';
import { Computer } from '../svg/computer';

@observer
export class Slide11 extends AnimatedSlide {
  // Animation players
  players = {
    shield: null,
    dots: null,
    scanner: null,
    queries: null,
    data: null,
    data1: null,
    data2: null,
    data3: null,
    terminal1: null,
    terminal2: null,
    terminal3: null,
    query1: null,
    query2: null,
    query3: null,
  };

  // Six progress bars
  bars = [
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
  ];

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;
    const {
      shield,
      dots,
      scanner,
      queries,
      data,
      data1,
      data2,
      data3,
      terminal1,
      terminal2,
      terminal3,
      query1,
      query2,
      query3,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(10000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(2000);
      },
      // Step 2 - data 1
      () => {
        shield.classList.add('blue');
        data1.classList.add('show');
        query1.classList.add('show');
        this.autoNext(500);
      },
      // Step 3 - data 2
      () => {
        data2.classList.add('show');
        query2.classList.add('show');
        this.autoNext(500);
      },
      // Step 4 - data 3
      () => {
        data3.classList.add('show');
        query3.classList.add('show');
        this.autoNext(500);
      },
      // Step 4
      () => {
        this.autoNext(2000);
      },
      // Step 5
      () => {
        if (!this._isMounted) return;
        this.bars[0].progress = 100;
        this.bars[0].color = this.colors.red;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 5
      () => {
        if (!this._isMounted) return;
        queries.classList.add('one');
        this.bars[1].progress = 100;
        this.bars[1].color = this.colors.red;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 5
      () => {
        if (!this._isMounted) return;
        queries.classList.add('two');
        this.bars[2].progress = 100;
        this.bars[2].color = this.colors.red;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 6
      () => {
        queries.classList.add('three');
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: shield,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: dots,
          width: [0, 200],
          easing: 'easeOutQuad',
          duration: 1000,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: scanner,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: data,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1200,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: terminal1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: terminal2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: terminal3,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: queries,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2700,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2
      //
      [
        {
          targets: shield,
          opacity: 1,
          duration: 200,
          complete: shimmer,
        },
        {
          targets: data1,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: query1,
          translateX: [
            {
              value: '+=92px',
              duration: 300,
            },
            {
              value: '+=0px',
              duration: 200,
            },
            {
              value: '+=80px',
              duration: 300,
            },
          ],
          translateY: [
            {
              value: '+=0px',
              duration: 300,
            },
            {
              value: '-=65px',
              duration: 200,
            },
            {
              value: '+=0px',
              duration: 300,
            },
          ],
          easing: 'linear',
          loop: true,
          begin: activateShimmer,
        },
      ],
      //
      // Step 3
      //
      [
        {
          targets: data2,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: query2,
          translateX: [0, 180],
          easing: 'linear',
          loop: true,
          begin: activateShimmer,
        },
      ],
      //
      // Step 4
      //
      [
        {
          targets: data3,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: query3,
          translateX: [
            {
              value: '+=92px',
              duration: 300,
            },
            {
              value: '+=0px',
              duration: 200,
            },
            {
              value: '+=80px',
              duration: 300,
            },
          ],
          translateY: [
            {
              value: '+=0px',
              duration: 300,
            },
            {
              value: '+=65px',
              duration: 200,
            },
            {
              value: '+=0px',
              duration: 300,
            },
          ],
          easing: 'linear',
          loop: true,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-11 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* MDR for Log */}
          <div
            className="player shield"
            data-active="0"
            ref={node => (this.players.shield = node)}
          >
            <Shield
              IconComponent={Signal}
              letterSpacing={1}
              IconSize={70}
              text="MDR for LOG"
            />
            <div
              ref={node => (this.players.dots = node)}
              className={'animated-dotted-line reverse ants-1'}
            ></div>
          </div>
          {/* Scanner */}
          <div
            className="player scanner"
            data-active="0"
            ref={node => (this.players.scanner = node)}
          >
            <PlayerDataScan width={200} height={200} increment={1} />
          </div>
          {/* Queries */}
          <div
            className="player queries"
            data-active="0"
            ref={node => (this.players.queries = node)}
          >
            <h3>QUERIES</h3>
            <ul>
              <li>
                <span></span>
                <span></span>
              </li>
              <li>
                <span></span>
                <span></span>
                <span></span>
              </li>
              <li>
                <span></span>
                <span></span>
              </li>
            </ul>
          </div>
          {/* Data dots */}
          <div
            className="player data"
            data-active="0"
            ref={node => (this.players.data = node)}
          >
            <div
              ref={node => (this.players.query1 = node)}
              className="query query-1"
            ></div>
            <div
              ref={node => (this.players.query2 = node)}
              className="query query-2"
            ></div>
            <div
              ref={node => (this.players.query3 = node)}
              className="query query-3"
            ></div>
            <div
              ref={node => (this.players.data1 = node)}
              className={'animated-dotted-line reverse ants-1 blue'}
            >
              <div className={'animated-dotted-line reverse blue'}>
                <div className={'animated-dotted-line reverse blue'}></div>
              </div>
            </div>
            <div
              ref={node => (this.players.data2 = node)}
              className={'animated-dotted-line reverse ants-2 blue'}
            ></div>
            <div
              ref={node => (this.players.data3 = node)}
              className={'animated-dotted-line reverse ants-3 blue'}
            >
              <div className={'animated-dotted-line reverse blue'}>
                <div className={'animated-dotted-line reverse blue'}></div>
              </div>
            </div>
          </div>
          {/* Terminal 1 */}
          <div
            className="player terminal terminal1"
            data-active="0"
            ref={node => (this.players.terminal1 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[0].progress}
              color={this.bars[0].color}
              size={70}
              stroke={3}
            />
          </div>
          {/* Terminal 2 */}
          <div
            className="player terminal terminal2"
            data-active="0"
            ref={node => (this.players.terminal2 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[1].progress}
              color={this.bars[1].color}
              size={70}
              stroke={3}
            />
          </div>
          {/* Terminal 3 */}
          <div
            className="player terminal terminal3"
            data-active="0"
            ref={node => (this.players.terminal3 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[2].progress}
              color={this.bars[2].color}
              size={70}
              stroke={3}
            />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
