/** @jsx createElement */
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { AppActions } from '../app-actions';
import { PlayerTimeline } from '../players/timeline';

@observer
export class Slide27 extends AnimatedSlide {
  // Animation players
  players = {
    timeline: null,
    events: null,
  };

  timeline = {
    unit: 'minute',
    units: 16,
    start: 0,
    alternate: true,
    details: [
      {
        start: '1:15',
        description: 'Initial analysis and obtain malicious file',
        active: true,
      },
      {
        start: '4:00',
        description: 'Confirm machine is affected',
      },
      {
        start: '4:55',
        description: 'Isolation of host and disruption of CnC IPs',
      },
      {
        start: '5:15',
        description: 'Initital alert sent to client',
      },
      {
        start: '9:00',
        description:
          'Child process cmd.exe spawns taskkill.exe to make its presence',
      },
      {
        start: '10:00',
        description:
          'Netsh.exe enables Windows firewall profiles (testing for Admin privilege), and later resets the firewall to less secure settings',
      },
      {
        start: '15:00',
        end: '16:00',
        description: 'Deep dive investigation information provided to client',
      },
    ],
  };

  // Run the first animation
  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.hideRightArrow();
    AppActions.disableAppScreenshot();

    this.steps = [
      // Step 1
      () => {},
    ];

    this.animations = [
      // Step 1
      [],
    ];

    // Play the first animation
    this.play();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
    AppActions.showRightArrow();
    AppActions.enableAppScreenshot();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`TimelineSummary timeline-slide ${className}`}>
        {children}
        <div className="content">
          <PlayerTimeline
            events={this.timeline.details}
            unit={this.timeline.unit}
            units={this.timeline.units}
            start={this.timeline.start}
            alternate={this.timeline.alternate}
          />
        </div>
      </div>
    );
  }
}
