/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import {
  activate,
  deactivate,
  shimmer,
  activateShimmer,
  getColor,
} from '../helpers';
import './path.scss';
import anime from 'animejs';
import ReactDOM from 'react-dom';

@observer
export class PlayerPath extends Component {
  // Store all X and Y values
  x = [];
  y = [];
  maxX = 0;
  maxY = 0;
  node = null; // Quick reference for the entire node

  // Array of path objects
  paths = [];

  width = 0;
  height = 0;
  style = {};
  curveAmount = 0.25;

  reveal = {
    // strokeWidth: Set to this.dots.strokeWidth if not provided
    // radius: Set to this.dots.strokeWidth / 2 if not provided
    stroke: getColor('white'),
    fill: getColor('white'),
    delay: 0,
    duration: 500,
    opacity: 0.5,
    id: this.getID(),
    maskID: this.getID(),
    maskNode: null,
    maskCircle: null,
    use: false,
  };

  pointsChange = {
    points: null, // Must be passed in as an array of points
    delay: 0,
    duration: 1000,
  };

  // Data parameters
  data = {
    fill: getColor('blue'),
    size: 12,
    animationDuration: '2s',
    duration: 2000,
    delay: 0,
    begin: '0s',
    opacity: 1,
    className: '',
    calcMode: 'linear',
    repeatCount: 'indefinite',
    repeatDur: 'indefinite',
    motionID: this.getID(),
    node: null,
  };

  // Dot parameters
  dots = {
    stroke: getColor('blue'),
    strokeWidth: 4,
    strokeDashArray: '4,4',
    animationDuration: '15s',
    strokeLinecap: 'butt',
    opacity: 1,
    className: '',
    mask: false, // String containing name of preset
    gradientPreset: false, // String containing name of preset
    id: this.getID(),
    maskID: this.getID(),
    fadeID: this.getID(),
  };

  // Gradient parameters
  gradient = {
    id: this.getID(),
    preset: false,
    x1: '',
    y1: '',
    x2: '',
    y2: '',
    direction: 'left',
    gradientUnits: 'objectBoundingBox', // objectBoundingBox or userSpaceOnUse
    colors: [],
  };

  gradientChange = {
    id: this.getID(),
    direction: 'left',
    preset: false, // If a string is passed, then the preset colors will be copied to the change colors
    colors: [], // If there are colors, then this will change
    wait: 0, // Do not change by default, force it to be called
  };

  endingDot = {
    fill: getColor('blue'),
    radius: 5,
    opacity: 1,
  };
  endingDotNode = null;

  // Gradient presets
  gradientPresets = {
    blueBlue: [
      {
        offset: 0,
        stopColor: getColor('blue'),
        stopOpacity: 1,
      },
      {
        offset: 1,
        stopColor: getColor('blue'),
        stopOpacity: 1,
      },
    ],
    redRed: [
      {
        offset: 0,
        stopColor: getColor('red'),
        stopOpacity: 1,
      },
      {
        offset: 1,
        stopColor: getColor('red'),
        stopOpacity: 1,
      },
    ],
    whiteWhite: [
      {
        offset: 0,
        stopColor: getColor('white'),
        stopOpacity: 0.5,
      },
      {
        offset: 1,
        stopColor: getColor('white'),
        stopOpacity: 0.5,
      },
    ],
    blueWhite: [
      {
        offset: 0,
        stopColor: getColor('blue'),
        stopOpacity: 1,
      },
      {
        offset: 1,
        stopColor: getColor('white'),
        stopOpacity: 0.5,
      },
    ],
    blueRed: [
      {
        offset: 0,
        stopColor: getColor('blue'),
        stopOpacity: 1,
      },
      {
        offset: 1,
        stopColor: getColor('red'),
        stopOpacity: 1,
      },
    ],
    whiteRed: [
      {
        offset: 0,
        stopColor: getColor('red'),
        stopOpacity: 1,
      },
      {
        offset: 1,
        stopColor: getColor('white'),
        stopOpacity: 0.5,
      },
    ],
    orangeBlue: [
      {
        offset: 0,
        stopColor: getColor('orange'),
        stopOpacity: 1,
      },
      {
        offset: 1,
        stopColor: getColor('blue'),
        stopOpacity: 1,
      },
    ],
  };

  // Gradient presets
  maskPresets = {
    fadeIn: [
      {
        offset: 0,
        stopOpacity: 0,
      },
      {
        offset: 1,
        stopOpacity: 1,
      },
    ],
    fadeOut: [
      {
        offset: 0,
        stopOpacity: 1,
      },
      {
        offset: 1,
        stopOpacity: 0,
      },
    ],
    fadeBoth: [
      {
        offset: 0,
        stopOpacity: 0,
      },
      {
        offset: 0.1,
        stopOpacity: 1,
      },
      {
        offset: 0.9,
        stopOpacity: 1,
      },
      {
        offset: 1,
        stopOpacity: 0,
      },
    ],
  };

  changeTimer = null;

  // Circle dot presets
  presets = {
    round: {
      stroke: '#ffffff',
      opacity: 0.5,
      strokeLinecap: 'round',
      strokeWidth: 6,
      strokeDashArray: '0, 20',
      animationDuration: '4s',
    },
    roundCloser: {
      stroke: '#ffffff',
      opacity: 0.5,
      strokeLinecap: 'round',
      strokeWidth: 6,
      strokeDashArray: '0, 10',
      animationDuration: '10s',
    },
  };

  componentDidMount() {
    // Check if there is a gradient change
    this.changeGradient();
    // Animate reveal
    this.animateReveal();
    // Animate data
    this.animateData();
    // Animate the path
    this.animatePath();
  }

  animatePath() {
    // Only animate the path if there is a second path
    if (this.paths.length > 1 && this.pointsChange.delay) {
      anime({
        targets: this.path,
        d: [this.paths[0].path, this.paths[1].path],
        duration: this.pointsChange.duration,
        delay: this.pointsChange.delay,
        easing: 'easeInOutQuad',
      });
    }
  }

  changeGradient() {
    if (this.gradientChange.colors.length && this.gradientChange.wait) {
      this.changeTimer = setTimeout(
        () => this.change(),
        this.gradientChange.wait
      );
    }
  }

  change() {
    this.path.setAttribute('stroke', this.getURL(this.gradientChange.id));
  }

  getID() {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(2, 10);
  }

  renderData() {
    const { data = false, reverse = false } = this.props;
    if (data == false) return;

    // If data is an object
    Object.assign(this.data, data);

    // Calculate offset to ensure the rect is in the center
    let offset = (this.data.size / 2) * -1;

    let dataStyles = {
      transform: `translate(${offset}px, ${offset}px)`,
    };

    return (
      <g ref={node => (this.data.node = node)} opacity={0}>
        <rect
          width={this.data.size}
          height={this.data.size}
          transform={`translate(-${this.data.size / 2},-${this.data.size / 2})`}
          fill={this.data.fill}
          style={dataStyles}
        ></rect>
      </g>
    );
  }

  dataTimer = null;
  animateData() {
    // Animate circle on the path
    if (this.data.node === null) return;
    let dataPath = anime.path(this.path);
    this.dataTimer = setTimeout(() => {
      anime({
        targets: this.data.node,
        translateX: dataPath('x'),
        translateY: dataPath('y'),
        easing: 'linear',
        duration: this.data.duration,
        loop: true,
        begin: () => {
          if (!this.data.node) return;
          this.data.node.setAttribute('opacity', 1);
        },
      });
    }, this.data.delay);
  }

  revealMask() {
    if (!this.reveal.use) return;
    return (
      <mask id={this.reveal.maskID} maskUnits="userSpaceOnUse">
        <path
          d={this.getPath(this.paths[0].points)}
          id={this.reveal.id}
          fill="none"
          stroke="white"
          strokeWidth={this.reveal.strokeWidth}
          ref={node => (this.reveal.maskNode = node)}
        />
      </mask>
    );
  }

  revealPath() {
    if (!this.reveal.use) return;
    return (
      <g className={`animated-line-reveal`}>
        <path
          d={this.getPath(this.paths[0].points)}
          maskUnits="userSpaceOnUse"
          fill="none"
          stroke={this.reveal.stroke}
          strokeWidth={this.reveal.strokeWidth}
          opacity={this.reveal.opacity}
          mask={this.getURL(this.reveal.maskID)}
        />
        <circle
          r={this.reveal.radius}
          cx={0}
          cy={0}
          fill={this.reveal.fill}
          opacity={0}
          ref={node => (this.reveal.maskCircle = node)}
        ></circle>
      </g>
    );
  }

  animateEndingDot() {
    if (this.endingDotNode) {
      anime({
        targets: this.endingDotNode,
        opacity: this.endingDot.opacity,
        duration: 500,
        easing: 'easeOutQuad',
      });
    }
  }

  animateReveal() {
    if (!this.reveal.maskNode) {
      if (this.endingDotNode) {
        this.endingDotNode.setAttribute('opacity', 1);
      }
      return;
    }
    // Set primary path opacity
    this.path.setAttribute('opacity', 0);
    this.reveal.maskNode.setAttribute('opacity', 0);

    // Animate circle on the path
    let maskPath = anime.path(this.reveal.maskNode);
    anime({
      targets: this.reveal.maskCircle,
      opacity: 1,
      translateX: maskPath('x'),
      translateY: maskPath('y'),
      easing: 'easeOutQuad',
      duration: this.reveal.duration,
      delay: this.reveal.delay,
      complete: () => {
        if (this.reveal.maskCircle) {
          anime({
            targets: this.reveal.maskCircle,
            opacity: 0,
            duration: 200,
            easing: 'easeOutQuad',
          });
        }
      },
    });
    // Animate the path
    anime({
      targets: this.reveal.maskNode,
      opacity: [0, this.reveal.opacity],
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'easeOutQuad',
      duration: this.reveal.duration,
      delay: this.reveal.delay,
      begin: () => {
        // Start animation
      },
      complete: () => {
        if (this.path) {
          anime({
            targets: this.path,
            opacity: this.dots.opacity,
            duration: 500,
            easing: 'easeOutQuad',
          });
        }
        if (this.reveal.maskNode) {
          anime({
            targets: this.reveal.maskNode,
            opacity: 0,
            duration: 500,
            easing: 'easeOutQuad',
          });
        }
        if (this.endingDotNode) {
          anime({
            targets: this.endingDotNode,
            radius: [0, this.endingDot.radius],
            opacity: this.endingDot.opacity,
            duration: 500,
            easing: 'easeOutQuad',
          });
        }
        if (this.node) {
          this.node.classList.add('shimmer');
          this.timer = setTimeout(() => {
            if (this.node) this.node.classList.remove('shimmer');
          }, 500);
        }
      },
    });
  }

  getURL(id) {
    return `url(#${id})`;
  }

  gradientStop(value, key) {
    return (
      <stop
        offset={value.offset}
        stopColor={value.stopColor ? value.stopColor : '#ffffff'}
        stopOpacity={value.stopOpacity}
        key={key}
      />
    );
  }

  maskFill() {
    if (this.dots.mask == false) return;
    return (
      <mask
        id={this.dots.maskID}
        maskContentUnits="userSpaceOnUse"
        maskUnits="userSpaceOnUse"
      >
        <rect
          width={this.width + this.dots.strokeWidth}
          height={this.height + this.dots.strokeWidth}
          x={(this.dots.strokeWidth / 2) * -1}
          y={(this.dots.strokeWidth / 2) * -1}
          fill={this.getURL(this.dots.fadeID)}
        />
      </mask>
    );
  }

  setGradientDirection(gradient) {
    // If a simple direction is passed in
    if (typeof gradient.direction === 'string') {
      gradient.direction = gradient.direction.toLowerCase();
      gradient.x1 = '0%';
      gradient.x2 = '0%';
      gradient.y1 = '0%';
      gradient.y2 = '0%';
      if (gradient.direction == 'left') {
        gradient.x1 = '100%';
      }
      if (gradient.direction == 'right') {
        gradient.x2 = '100%';
      }
      if (gradient.direction == 'up') {
        gradient.y1 = '100%';
      }
      if (gradient.direction == 'down') {
        gradient.y2 = '100%';
      }
    }
    // Convert an angle to x1,y1,x2,y2 values
    if (typeof gradient.direction === 'number') {
      let pointOfAngle = function(a) {
        return {
          x: Math.cos(a),
          y: Math.sin(a),
        };
      };
      let degreesToRadians = function(d) {
        return (d * Math.PI) / 180;
      };
      let eps = Math.pow(2, -52);
      let angle = gradient.direction % 360;
      let startPoint = pointOfAngle(degreesToRadians(180 - angle));
      let endPoint = pointOfAngle(degreesToRadians(360 - angle));
      if (startPoint.x <= 0 || Math.abs(startPoint.x) <= eps) startPoint.x = 0;
      if (startPoint.y <= 0 || Math.abs(startPoint.y) <= eps) startPoint.y = 0;
      if (endPoint.x <= 0 || Math.abs(endPoint.x) <= eps) endPoint.x = 0;
      if (endPoint.y <= 0 || Math.abs(endPoint.y) <= eps) endPoint.y = 0;

      // Save updated angle
      gradient.x1 = startPoint.x;
      gradient.x2 = endPoint.x;
      gradient.y1 = startPoint.y;
      gradient.y2 = endPoint.y;
    }
  }

  applyGradients() {
    return (
      <defs>
        {this.dots.mask in this.maskPresets && (
          <linearGradient id={this.dots.fadeID} y2="0" x2="1">
            {this.maskPresets[this.dots.mask].map(this.gradientStop)}
          </linearGradient>
        )}
        {this.gradient.colors.length && (
          <linearGradient
            id={this.gradient.id}
            x1={this.gradient.x1 || 0}
            y1={this.gradient.y1 || 0}
            x2={this.gradient.x2 || 0}
            y2={this.gradient.y2 || 0}
            gradientUnits={this.gradient.gradientUnits}
          >
            {this.gradient.colors.map(this.gradientStop)}
          </linearGradient>
        )}
        {this.gradientChange.colors.length && (
          <linearGradient
            id={this.gradientChange.id}
            x1={this.gradientChange.x1 || 0}
            y1={this.gradientChange.y1 || 0}
            x2={this.gradientChange.x2 || 0}
            y2={this.gradientChange.y2 || 0}
            gradientUnits={this.gradientChange.gradientUnits}
          >
            {this.gradientChange.colors.map(this.gradientStop)}
          </linearGradient>
        )}
        {this.maskFill()}
        {this.revealMask()}
      </defs>
    );
  }

  getEndingDot(points) {
    const { endingDot = false } = this.props;
    if (!endingDot) return;

    if (typeof endingDot === 'object') {
      Object.assign(this.endingDot, endingDot);
    }

    let lastPoint = points[points.length - 1];

    return (
      <circle
        r={this.endingDot.radius}
        cx={lastPoint.x}
        cy={lastPoint.y}
        fill={this.endingDot.fill}
        opacity={0}
        ref={node => (this.endingDotNode = node)}
      ></circle>
    );
  }

  // Requires three points to create bezier curves
  controlPoints(a, b, c) {
    if (!a || !c) return b;
    return {
      x: b.x + (c.x - a.x) * this.curveAmount,
      y: b.y + (c.y - a.y) * this.curveAmount,
    };
  }

  // Get curves
  getCurves(points) {
    let pointsWithControlPoints = points
      .flatMap((pt, i) => [
        this.controlPoints(points[i + 1], pt, points[i - 1]),
        pt,
        this.controlPoints(points[i - 1], pt, points[i + 1]),
      ])
      .slice(1, -1) // remove the control points before the first point and after the last one
      .map(pt => [pt.x, pt.y]);
    return `M${pointsWithControlPoints[0]} C${pointsWithControlPoints.slice(
      1
    )}`;
  }

  getPath(points) {
    const { curve = false } = this.props;
    if (curve && points.length >= 3) {
      if (typeof curve === 'number') this.curveAmount = curve;
      return this.getCurves(points);
    }
    let p = [];
    points.map(value => {
      p.push(`${value.x},${value.y}`);
    });
    return 'M ' + p.join(' ');
  }

  addPath(points) {
    let path = {
      points: [],
      path: '',
      x: [],
      y: [],
    };
    points.map(xy => {
      if (typeof xy === 'string') {
        let coords = xy.split(',');
        path.x.push(parseFloat(coords[0]));
        path.y.push(parseFloat(coords[1]));
        path.points.push({
          x: parseFloat(coords[0]),
          y: parseFloat(coords[1]),
        });
      }
    });
    path.path = this.getPath(path.points);
    this.paths.push(path);
  }

  getDimensions() {
    this.paths.map(p => {
      this.x = this.x.concat(p.x);
      this.y = this.y.concat(p.y);
    });
    this.minX = Math.min.apply(null, this.x);
    this.maxX = Math.max.apply(null, this.x);
    this.minY = Math.min.apply(null, this.y);
    this.maxY = Math.max.apply(null, this.y);
    this.width = Math.ceil(this.maxX + this.dots.strokeWidth);
    this.height = Math.ceil(this.maxY + this.dots.strokeWidth);
  }

  render() {
    const {
      children,
      points = [],
      pointsChange = {},
      className = '',
      preset = false,
      gradient = {},
      gradientChange = {},
      reverse = false,
      reveal = false,
      style = {},
      dots = {},
      width = false,
      height = false,
    } = this.props;

    // Support presets
    let presetOverride = {};
    if (preset && this.presets[preset]) {
      presetOverride = this.presets[preset];
    }

    // Assign properties to dots
    Object.assign(this.dots, presetOverride, dots);
    Object.assign(this.style, style);
    Object.assign(this.pointsChange, pointsChange);

    // Reveal properties
    if (reveal) {
      this.reveal.use = true;
      // Merge user defined properties
      if (typeof reveal === 'object') {
        Object.assign(this.reveal, reveal);
      }
      // Copy size properties from the path
      if (!('strokeWidth' in this.reveal)) {
        this.reveal.strokeWidth = parseFloat(this.dots.strokeWidth);
      }
      if (!('radius' in this.reveal)) {
        this.reveal.radius = parseFloat(this.dots.strokeWidth / 2);
      }
    }

    // Allow gradients to be passed in
    Object.assign(this.gradient, gradient);
    Object.assign(this.gradientChange, this.gradient, gradientChange);
    this.gradientChange.id = this.getID();

    // Load coordinates
    this.addPath(points);

    // Add the change points if provided
    if (Array.isArray(this.pointsChange.points)) {
      this.addPath(this.pointsChange.points);
    }

    // Calculate dimensions
    this.getDimensions();

    if (width) {
      this.width = width;
    }
    if (height) {
      this.height = height;
    }

    // NOTE: SVG is a pain, took forever to figure this out!
    // "objectBoundingBox" when both width AND height have geometry
    // "userSpaceOnUse" when width OR height have no geometry
    if (this.minX == this.maxX || this.minY == this.maxY) {
      this.gradient.gradientUnits = 'userSpaceOnUse';
    }

    // Get stroke animation
    let strokeAnimation = reverse
      ? `animatedDotsForwards`
      : `animatedDotsBackwards`;

    // Create animation style
    let pathStyles = {
      animation: `${strokeAnimation} linear ${this.dots.animationDuration} infinite`,
    };

    // Apply gradient to stroke if set
    if (
      this.dots.gradientPreset &&
      this.dots.gradientPreset in this.gradientPresets
    ) {
      this.dots.stroke = this.getURL(this.gradient.id);
      this.gradient.colors = this.gradientPresets[this.dots.gradientPreset];
    }

    // Allow preset to be also set within the gradient property
    if (this.gradient.preset in this.gradientPresets) {
      this.dots.stroke = this.getURL(this.gradient.id);
      this.gradient.colors = this.gradientPresets[this.gradient.preset];
    }

    // Set colors for the change from a preset
    if (
      this.gradientChange.preset &&
      this.gradientChange.preset in this.gradientPresets
    ) {
      this.gradientChange.colors = this.gradientPresets[
        this.gradientChange.preset
      ];
    }

    // If there is a gradient set
    if (
      Array.isArray(this.gradient.colors) &&
      this.gradient.colors.length > 0
    ) {
      this.dots.stroke = this.getURL(this.gradient.id);
    }

    // Set direction for each gradient
    if (this.gradient.direction) {
      this.setGradientDirection(this.gradient);
    }
    if (this.gradientChange.direction) {
      this.setGradientDirection(this.gradientChange);
    }

    return (
      <div
        className={`Player__Path ${className}`}
        style={this.style}
        ref={node => (this.node = node)}
      >
        <svg
          width={this.width}
          height={this.height}
          viewBox={`0 0 ${this.width} ${this.height}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          {this.applyGradients()}
          <path
            ref={node => (this.path = node)}
            d={this.getPath(this.paths[0].points)}
            fill="none"
            className={this.dots.className}
            stroke={this.dots.stroke}
            strokeWidth={this.dots.strokeWidth}
            strokeDasharray={this.dots.strokeDashArray}
            strokeLinecap={this.dots.strokeLinecap}
            style={pathStyles}
            id={this.dots.id}
            opacity={this.dots.opacity}
            mask={this.getURL(this.dots.maskID)}
          />
          {this.revealPath()}
          {this.renderData()}
          {this.getEndingDot(this.paths[0].points)}
        </svg>
      </div>
    );
  }
}
