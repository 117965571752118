/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { getColor } from '../components/helpers';
import { AnimatedSlide } from '../components/animated-slide';

// SCSS
import './webinar.scss';

// Players
import { PlayerPath } from '../components/players/path';

// SVG
import { Webinar } from '../components/svg/webinar';
import { World } from '../components/svg/world';

@observer
export class Template__Webinar extends AnimatedSlide {
  // Animation players
  players = {
    world: null,
    webinar: null,
    paths: null,
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;

    const { world, webinar, paths } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(world, 0, 500),
        this.fadeIn(webinar, 500, 500),
        this.fadeIn(paths, 500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  revealPreset(delay, duration = 1000) {
    let offset = 800;
    return {
      delay: delay + offset,
      duration: duration,
      radius: 2,
    };
  }

  render() {
    const { children, className = '' } = this.props;

    let dotsPreset = {
      strokeWidth: 2,
    };

    let width = 670;
    let height = 300;

    return (
      <div className={`Template__Webinar ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* World */}
          <div
            className="player world"
            data-active="0"
            ref={node => (this.players.world = node)}
          >
            <World size={862} />
          </div>
          {/* Paths */}
          <div
            className="player paths"
            data-active="0"
            ref={node => (this.players.paths = node)}
          >
            {/* 1 */}
            <PlayerPath
              points={['15,40', '115,0', '230,40'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(500)}
            />
            {/* 2 */}
            <PlayerPath
              points={['0,60', '125,0', '240,30'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(700)}
            />
            {/* 3 */}
            <PlayerPath
              points={['0,50', '100,0', '200,30'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1000)}
            />
            {/* 4 */}
            <PlayerPath
              points={['0,20', '70,0', '140,20'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1200)}
            />
            {/* 5 */}
            <PlayerPath
              points={['0,90', '40,30', '130,0'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1400)}
            />
            {/* 6 */}
            <PlayerPath
              points={['0,110', '35,40', '120,0'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1600)}
            />
            {/* 7 */}
            <PlayerPath
              points={['0,150', '30,50', '110,0'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1800)}
            />
            {/* 8 */}
            <PlayerPath
              points={['0,127', '20,40', '83,0'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1900)}
            />
            {/* 9 */}
            <PlayerPath
              points={['0,35', '10,10', '20,0'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(2000)}
            />
            {/* 10 */}
            <PlayerPath
              points={['0,40', '0,20', '10,0'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(2200)}
            />
            {/* 11 */}
            <PlayerPath
              points={['0,45', '70,0', '155,10'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(2500)}
            />
            {/* Right side */}
            {/* 12 */}
            <PlayerPath
              points={['0,0', '15,30', '20,60']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(2600)}
            />
            {/* 13 */}
            <PlayerPath
              points={['0,0', '20,35', '30,110']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(2500)}
            />
            {/* 14 */}
            <PlayerPath
              points={['0,0', '25,30', '40,80']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(2300)}
            />
            {/* 15 */}
            <PlayerPath
              points={['0,0', '20,10', '50,40']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(2200)}
            />
            {/* 16 */}
            <PlayerPath
              points={['0,0', '150,45', '260,150']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(2100)}
            />
            {/* 17 */}
            <PlayerPath
              points={['0,0', '160,50', '280,170']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1900)}
            />
            {/* 18 */}
            <PlayerPath
              points={['0,0', '95,0', '200,30']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1700)}
            />
            {/* 19 */}
            <PlayerPath
              points={['0,10', '70,0', '160,10']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1500)}
            />
            {/* 20 */}
            <PlayerPath
              points={['0,20', '110,0', '266,30']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1300)}
            />
            {/* 21 */}
            <PlayerPath
              points={['0,50', '130,0', '290,10']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1000)}
            />
            {/* 22 */}
            <PlayerPath
              points={['0,60', '10,30', '60,10']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(700)}
            />
            {/* Top */}
            {/* 23 */}
            <PlayerPath
              points={['0,20', '170,0', '310,80'].reverse()}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(900)}
            />
            <PlayerPath
              points={['0,30', '5,15', '15,0']}
              curve={0.3}
              endingDot={true}
              dots={dotsPreset}
              reveal={this.revealPreset(1100)}
            />
          </div>
          {/* Webinar */}
          <div
            className="player webinar"
            data-active="0"
            ref={node => (this.players.webinar = node)}
          >
            <Webinar size={120} />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
