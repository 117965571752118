/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Page extends Component {
  render() {
    const {
      children,
      className = '',
      color = 'white',
      size = 100,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 83 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.0001 0.333344C5.31675 0.333344 0.718416 4.98334 0.718416 10.6667L0.666748 93.3334C0.666748 99.0167 5.26508 103.667 10.9484 103.667H73.0001C78.6834 103.667 83.3334 99.0167 83.3334 93.3334V31.3333L52.3334 0.333344H11.0001ZM47.1667 36.5V8.08334L75.5834 36.5H47.1667Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
