/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class MVS extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 2,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: 'visible' }}
        {...containerProps}
      >
        <g
          stroke={color}
          strokeWidth={strokeWidth}
          opacity={opacity}
          strokeLinejoin="round"
        >
          <path d="M52.1223 22.291H8.01732C7.53259 22.291 7.13965 22.684 7.13965 23.1687V57.6193C7.13965 58.104 7.53259 58.497 8.01732 58.497H52.1223C52.6072 58.497 52.9999 58.104 52.9999 57.6193V23.1687C52.9999 22.684 52.6072 22.291 52.1223 22.291Z" />
          <path d="M16.7916 22.1329V14.7795" />
          <path d="M16.7916 14.779C16.7916 11.258 18.1903 7.88126 20.68 5.39158C23.1697 2.90188 26.5465 1.50317 30.0675 1.50317C33.5883 1.50317 36.9651 2.90188 39.4549 5.39158C41.9446 7.88126 43.3433 11.258 43.3433 14.779" />
          <path d="M30.073 37.3059V43.1966" />
        </g>
      </svg>
    );
  }
}
