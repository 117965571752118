/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerDataScan } from '../players/data-scan';
import ReactDOM from 'react-dom';
import { observable } from 'mobx';
import anime from 'animejs';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';

@observer
export class Slide15 extends AnimatedSlide {
  // Animation players
  players = {
    analyst: null,
    info1: null,
    info2: null,
    info3: null,
    scanner: null,
  };

  // Run the first animation
  componentDidMount() {
    const { analyst, info1, info2, info3, scanner } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 0
      () => {
        this.autoNext(500);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Pause for effect
      ],
      //
      // Step 1 - Introduce the crew
      //
      [
        {
          targets: analyst,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 0,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: info1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: info2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: info3,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: scanner,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1200,
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Final step
      //
      [
        // Do nothing
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-15 ${className}`}>
        {children}
        <div className="content">
          {/* Analyst */}
          <div
            className="player analyst"
            data-active="0"
            ref={node => (this.players.analyst = node)}
          >
            <Card IconComponent={Person} text={'Analyst'} />
            <div className={'animated-dotted-line reverse'}></div>
          </div>
          {/* OSINT */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerTextBlock
              title={'OSINT'}
              className={'red-top'}
              sections={[
                {
                  title: ['SOURCE', '91.218.114.31'],
                  color: 'red',
                  colorCells: 2,
                  grid: 2,
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['KEY', 'NONE'],
                  grid: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['INDICATOR', 'MCAFEE'],
                  grid: 2,
                  animate: true,
                  delay: 600,
                },
                {
                  title: ['UPDATED', '04-20-2020'],
                  grid: 2,
                  animate: true,
                  delay: 800,
                },
                {
                  lines: 3,
                  lineColumns: 4,
                  animate: true,
                  delay: 1000,
                },
              ]}
            />
          </div>
          {/* Comparing */}
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerTextBlock title={'Comparing'} />
            <div className={'animated-dotted-line reverse'}></div>
          </div>
          {/* Data scanner */}
          <div
            className="player scanner"
            data-active="0"
            ref={node => (this.players.scanner = node)}
          >
            <PlayerDataScan
              width={200}
              height={200}
              increment={0.5}
              gap={5}
              rows={20}
              columns={[
                '20px 1fr',
                '20px 1fr 1fr',
                '20px 1fr 1fr 1fr',
                '20px 1fr 1fr 1fr 1fr',
                '20px 2fr 1fr',
                '20px 1fr 2fr 1fr',
                '20px 1.5fr 1fr 2fr 1fr',
              ]}
            />
          </div>
          {/* Associated Groups */}
          <div
            className="player info3"
            data-active="0"
            ref={node => (this.players.info3 = node)}
          >
            <PlayerTextBlock
              title={'OSINT'}
              className={'red-top'}
              sections={[
                {
                  title: ['Cognizant maze ransomware infe...'],
                  color: 'red',
                  colorCells: 1,
                  animate: true,
                  delay: 1000,
                },
                {
                  title: ['New SunCrypt Ransomware Sheds...'],
                  color: 'red',
                  colorCells: 1,
                  animate: true,
                  delay: 2500,
                },
                {
                  title: ['Q3 Malware Trends: Ransomware...'],
                  color: 'red',
                  colorCells: 1,
                  animate: true,
                  delay: 3200,
                },
                {
                  title: ['Navigating the MAZE: Tactics, Tec...'],
                  color: 'red',
                  colorCells: 1,
                  animate: true,
                  delay: 4500,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
