/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// SVG
import { Bug } from '../svg/bug';
import { Eye } from '../svg/eye';
import { CheckShield } from '../svg/check-shield';
import { Bell } from '../svg/bell';
import { Chip } from '../svg/chip';

@observer
export class Slide01 extends AnimatedSlide {
  // Animation players
  players = {
    card1: null,
    card2: null,
    card3: null,
    card4: null,
  };

  // Run the first animation
  componentDidMount() {
    const { card1, card2, card3, card4 } = this.players;

    this.steps = [
      () => {
        this.autoNext(200);
      },
      () => {
        this.autoNext(200);
      },
      () => {
        this.autoNext(200);
      },
      () => {
        this.autoNext(200);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        {
          targets: card1,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: card2,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: card3,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 5
      //
      [
        {
          targets: card4,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-01 ${className}`}>
        {children}
        <div className="content">
          {/* Card 1 */}
          <div
            className="player card1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <Stage
              Icon={Chip}
              stage={'01'}
              title={'Memory Scrape (Mimikatz)'}
            />
          </div>

          {/* Card 2 */}
          <div
            className="player card2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <Stage Icon={Eye} stage={'02'} title={'Investigation + Analysis'} />
          </div>

          {/* Card 3 */}
          <div
            className="player card3"
            data-active="0"
            ref={node => (this.players.card3 = node)}
          >
            <Stage
              Icon={CheckShield}
              stage={'03'}
              title={'Endpoint + Network Containment'}
            />
          </div>

          {/* Card 4 */}
          <div
            className="player card4"
            data-active="0"
            ref={node => (this.players.card4 = node)}
          >
            <Stage Icon={Bell} stage={'04'} title={'Client Alert + Response'} />
          </div>
        </div>
      </div>
    );
  }
}
