/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import './modal.scss';
import { Close } from './svg/close';
import { KeyboardShortcuts } from './keyboard-shortcuts';

@observer
export class Modal extends Component {
  onClick = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  render() {
    const {
      children,
      className = '',
      show = false,
      onClose,
      fullPage = false,
      preventCloseIcon = false,
    } = this.props;

    if (!show) return null;

    return (
      <div
        className={`Modal ${fullPage ? 'Modal--full-page' : ''} ${className}`}
        onClick={onClose}
      >
        <KeyboardShortcuts
          shortcuts={{
            Escape: onClose,
          }}
        />
        <div
          className={`Modal__Body ${fullPage ? 'Modal__Body--full-page' : ''}`}
          onClick={this.onClick}
        >
          {preventCloseIcon ? null : (
            <div className={`Modal__Body__Close`} onClick={onClose}>
              <Close />
            </div>
          )}
          <div
            className={`Modal__Body__Content ${
              fullPage ? 'Modal__Body__Content--full-page' : ''
            }`}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}
