/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Code extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      size = 41,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 114 69"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.66422 30.7602L30.7642 19.8402V13.1202L0.384216 27.8902V33.6302L30.7642 48.4002V41.6802L7.66422 30.7602Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M33.0742 66.3902L38.6742 68.9102L75.4942 2.9702L69.8942 0.450195L33.0742 66.3902Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M106.336 30.7602L83.2358 41.6802V48.4002L113.616 33.6302V27.8902L83.2358 13.1202V19.8402L106.336 30.7602Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
