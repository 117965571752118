import Color from 'color';

class ColorGenerator {
  baseColor: Color;
  count: number;

  /**
   * Generate colors with a visually appealling interval
   *
   * @param baseColor The initial color to use for the generator
   * @param count How many colors we expect to produce
   */
  constructor(baseColor: Color, count: number) {
    this.baseColor = baseColor;
    this.count = count;
  }

  color(index: number): Color {
    const { count, baseColor } = this;

    if (index === 0) return baseColor;

    // Calculate how many degrees of separation we need to evenly distribute the
    // colors around the wheel
    const degrees = 360 / count;

    return baseColor.hue(
      // Start on the opposite side of the wheel
      baseColor.hue() -
        180 +
        // Alternate left / right
        (index % 2 ? 1 : -1) *
          // Fan out
          degrees *
          (index / 2)
    );
  }
}

export const colorGenerator = new ColorGenerator(new Color('#50C2E3'), 20);

/**
 * Return a themed color at the specified index
 *
 * The color returned is a Color object, which allows for manipulation for
 * things like hover state. If you don't like that, you can do a straight
 * conversion to string with either String(color(i)) or an implicit type
 * coersion such as `${color(i)}`
 *
 * @param index The index. This not a super important value. It just ensures
 * unique colors
 *
 * @return The color
 */
export function generateColor(index) {
  return colorGenerator.color(index);
}
