/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Blackhat extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={opacity} fill={color}>
          <path d="M53.3806 48.504C57.5216 43.3916 60 36.8927 60 29.8185C60 13.3502 46.5685 0 30 0C13.4315 0 0 13.3502 0 29.8185C0 37.6025 3.0007 44.6898 7.91505 50C7.87959 49.8825 7.86585 49.8188 7.86585 49.8188C7.86585 49.8188 12.0732 44.0005 21.0366 42.7278C21.0366 42.2197 20.0652 41.1436 19.2402 40.2297C18.589 39.5084 18.0291 38.8881 18.1098 38.7277C18.2197 38.5092 19.2547 38.2906 20.1029 38.1116C20.6659 37.9927 21.1465 37.8912 21.2195 37.8187C21.2654 37.773 21.1271 37.5329 20.8999 37.1384C20.2214 35.9604 18.7503 33.4062 19.0244 30.5458C14.8171 28.7276 11.8902 27.0913 12.0732 25.4549C12.2034 24.2895 15.3026 23.4008 17.208 22.8544C17.9781 22.6335 18.5532 22.4686 18.6585 22.3639C18.4756 19.6366 19.0244 15.2729 19.3902 14.5457C19.7561 13.8184 21.0732 12.6547 21.9512 12.3638C22.8293 12.0728 32.3171 12.0608 36.9512 12.182C37.3171 12.1214 38.5976 12.6547 39.3293 13.8184C40.2439 15.2729 41.5244 21.6367 41.3415 22.3639C41.5184 22.3991 41.7248 22.4377 41.9539 22.4805C44.0931 22.8802 48.2041 23.6483 48.6585 25.4549C49.0976 27.2003 43.4756 29.6973 40.6098 30.7277C40.5704 30.9779 40.5351 31.2242 40.5 31.4695C40.2627 33.1253 40.0314 34.7395 38.5976 37.2732C38.9897 37.4193 39.3818 37.5524 39.7457 37.676C40.7401 38.0135 41.5244 38.2797 41.5244 38.5459C41.5244 38.6946 41.1576 39.2077 40.699 39.8495C40.0355 40.7778 39.1797 41.9753 38.9634 42.7278C49.3191 43.9286 52.9981 48.043 53.3806 48.504Z" />
        </g>
      </svg>
    );
  }
}
