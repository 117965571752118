/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';

@observer
export class Slide13 extends AnimatedSlide {
  // Animation players
  players = {
    events: null,
  };

  // Run the first animation
  componentDidMount() {
    const { events } = this.players;

    this.steps = [
      () => {
        this.animateProgress(1000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: events,
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-13 ${className}`}>
        {children}
        <div className="content">
          {/* Last 30 */}
          <div
            className="player events"
            data-active="0"
            ref={node => (this.players.events = node)}
          >
            <PlayerTextBlock
              title={'Event “Createuser”'}
              width={'250px'}
              className={''}
              sections={[
                {
                  title: [
                    `_sourcecategory=”fcto/aws/cloudtrail” “{{parameter1}}”`,
                    `jason field=_raw_ “eventname”`,
                    `Count by eventname`,
                    `sort by _count asc`,
                  ],
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
