import { observable, computed, action } from 'mobx';
// import { MitreDataCells } from './mitre-data.constants';
const MitreDataCells = require('./mitre-data.constants.json');
const MitreDataCloudCells = require('./mitre-data-cloud.constants.json');

export interface ICellData {
  info: string;
  title: string;
  filters: FilterType[];
  attacks: AttackType[];
  /** Coupled with attacks */
  attackInfo: string[];
}

export interface IColumnData {
  name: string;
  totalCells: number;
  cells: ICellData[];
}

export enum FilterType {
  esEndpoint,
  esNetwork,
  esLog,
  esInsider,
  AWS,
  Azure,
  GCP,
  O365,
  GSuite,
}

export enum AttackType {
  NONE,
  COBALT_STRIKE,
  DARK_COMET,
  RANSOMWARE,
  WANNACRY,
  TRICKBOT,
  DRIDEX,
  SOLARWINDS,
  RYUK,
}

const columnMap = {};
const data: { columns: IColumnData[] } = { columns: [] };
const cloudData: { columns: IColumnData[] } = { columns: [] };
const cloudColumnMap = {};
const csvAttackLookup = {
  Cobalt: AttackType.COBALT_STRIKE,
  DarkComet: AttackType.DARK_COMET,
  Ransomware: AttackType.RANSOMWARE,
  Wannacry: AttackType.WANNACRY,
  Trickbot: AttackType.TRICKBOT,
  Dridex: AttackType.DRIDEX,
  SolarWinds: AttackType.SOLARWINDS,
  Ryuk: AttackType.RYUK,
};

const csvFilterLookup = {
  esEndpoint: FilterType.esEndpoint,
  esInsider: FilterType.esInsider,
  esLog: FilterType.esLog,
  esNetwork: FilterType.esNetwork,
  AWS: FilterType.AWS,
  Azure: FilterType.Azure,
  GCP: FilterType.GCP,
  O365: FilterType.O365,
  GSuite: FilterType.GSuite,
};

MitreDataCells.forEach((cell, rowIndex) => {
  const [col, title] = cell;
  let column = columnMap[col];

  if (!column) {
    columnMap[col] = column = { name: col, cells: [], totalCells: 0 };
    data.columns.push(column);
  }

  const newCell: ICellData = {
    title,
    info: '',
    filters: [],
    attacks: [],
    attackInfo: [],
  };
  column.cells.push(newCell);
  column.totalCells++;
  let filtersIndex = -1;

  for (let i = 2; i < cell.length; i += 2) {
    const attack = cell[i];

    if (attack === 'Filters') {
      filtersIndex = i;
      break;
    }

    const info = cell[i + 1];
    const attackType = csvAttackLookup[attack];

    if (attackType === void 0) {
      console.warn(
        'Row',
        rowIndex + 1,
        'Invalid attack type found in CSV data:',
        attack
      );
    }

    newCell.attacks.push(attackType);
    newCell.attackInfo.push(info);
  }

  if (filtersIndex > -1) {
    for (let i = filtersIndex + 1; i < cell.length; ++i) {
      const filter = csvFilterLookup[cell[i]];
      if (filter === void 0) continue;
      newCell.filters.push(filter);
    }
  }
});

MitreDataCloudCells.forEach((cell, rowIndex) => {
  const [col, title] = cell;
  let column = cloudColumnMap[col];

  if (!column) {
    cloudColumnMap[col] = column = { name: col, cells: [], totalCells: 0 };
    cloudData.columns.push(column);
  }

  const newCell: ICellData = {
    title,
    info: '',
    filters: [],
    attacks: [],
    attackInfo: [],
  };
  column.cells.push(newCell);
  column.totalCells++;
  let filtersIndex = -1;

  for (let i = 2; i < cell.length; i += 2) {
    const attack = cell[i];

    if (attack === 'Filters') {
      filtersIndex = i;
      break;
    }

    const info = cell[i + 1];
    const attackType = csvAttackLookup[attack];

    if (attackType === void 0) {
      console.warn(
        'Row',
        rowIndex + 1,
        'Invalid attack type found in CSV data:',
        attack
      );
    }

    newCell.attacks.push(attackType);
    newCell.attackInfo.push(info);
  }

  if (filtersIndex > -1) {
    for (let i = filtersIndex + 1; i < cell.length; ++i) {
      const filter = csvFilterLookup[cell[i]];
      if (filter === void 0) continue;
      newCell.filters.push(filter);
    }
  }
});

export enum MitreDataMode {
  ENTERPRISE,
  CLOUD,
}

export class Store {
  @observable loading: boolean = false;
  @observable filters: FilterType[] = [];
  @observable attack: AttackType = AttackType.NONE;
  @observable data = data;
  @observable cloudData = cloudData;
  @observable tooltip = null;
  @observable mode = MitreDataMode.ENTERPRISE;

  @computed
  get columnCount() {
    const out = new Map();
    const filterSet = this.filterSet;
    const isAttack = this.attack !== AttackType.NONE;
    const isFilter = this.filters.length > 0;

    if (this.mode === MitreDataMode.ENTERPRISE) {
      // When there is an attack and filter present, the total is the number of
      // cells with the specified attack. The count is the number of cells with
      // the attack AND with a filter in the current filter list.
      if (isAttack && isFilter) {
        this.data.columns.forEach(column => {
          let total = 0;
          let count = 0;

          column.cells.forEach(cell => {
            const attack = cell.attacks.find(atk => atk === this.attack);

            if (attack !== void 0) {
              total++;
              const filter = cell.filters.find(filter => filterSet.has(filter));
              if (filter !== void 0) count++;
            }
          });

          out.set(column.name, [count, total]);
        });
      }

      // When only an attack is present, the total is the total cells of the
      // column and the count is the number of cells with the current attack
      else if (isAttack) {
        this.data.columns.forEach(column => {
          const total = column.cells.length;
          let count = 0;

          column.cells.forEach(cell => {
            const attack = cell.attacks.find(atk => atk === this.attack);
            if (attack !== void 0) count++;
          });

          out.set(column.name, [count, total]);
        });
      }

      // When only a filter is present, the total is the total cells of the column
      // and the count is the number of cells that contains one of the current
      // filters.
      else if (isFilter) {
        this.data.columns.forEach(column => {
          const total = column.cells.length;
          let count = 0;

          column.cells.forEach(cell => {
            const filter = cell.filters.find(filter => filterSet.has(filter));
            if (filter !== void 0) count++;
          });

          out.set(column.name, [count, total]);
        });
      }

      // No filters or attacks present means no info
      else {
        this.data.columns.forEach(column => {
          out.set(column.name, []);
        });
      }
    }

    // Cloud data only has to handle filters for the cell information
    else if (this.mode === MitreDataMode.CLOUD) {
      if (isFilter) {
        this.cloudData.columns.forEach(column => {
          const total = column.cells.length;
          let count = 0;

          column.cells.forEach(cell => {
            const filter = cell.filters.find(filter => filterSet.has(filter));
            if (filter !== void 0) count++;
          });

          out.set(column.name, [count, total]);
        });
      }

      // No filters or attacks present means no info
      else {
        this.cloudData.columns.forEach(column => {
          out.set(column.name, []);
        });
      }
    }

    return out;
  }

  @computed
  get chartData() {
    let out = this.data;

    if (this.mode === MitreDataMode.CLOUD) {
      out = this.cloudData;
    }

    return out;
  }

  @computed
  get showCount() {
    return this.filters.length > 0 || this.attack !== AttackType.NONE;
  }

  @computed
  get filterSet() {
    return new Set(this.filters);
  }

  @action
  changeMode(mode: MitreDataMode) {
    if (mode !== this.mode) {
      this.reset();
      this.mode = mode;
    }
  }

  @action
  reset() {
    this.filters = [];
    this.attack = AttackType.NONE;
  }

  @action
  toggleFilter(filter: FilterType) {
    const filters = new Set(this.filters);

    // Multiple filters for enterprise mode
    if (this.mode === MitreDataMode.ENTERPRISE) {
      if (filters.has(filter)) filters.delete(filter);
      else filters.add(filter);
    }

    // Only single filter at a time for cloud data
    else {
      if (filters.has(filter)) {
        filters.clear();
      } else {
        filters.clear();
        filters.add(filter);
      }
    }

    this.filters = Array.from(filters);
  }

  hasFilter(filter: FilterType) {
    return this.filters.find(f => f === filter) !== void 0;
  }
}

export const MitreDataStore = new Store();
