/** @jsx createElement */
import { createElement, Component } from 'react';
import './mitre-select.scss';
import { DropDown2 } from '../svg/drop-down-2';

export class MitreSelect extends Component {
  onBlur = () => {
    // If nested in a popup, remove focus from select,
    // but apply to the container to maintain focus on ascendants
    this.props.nested && this.container && this.container.focus();
  };

  onChange = e => {
    const option = this.props.options.find(
      option => option.value.toString() === e.currentTarget.value
    );
    this.props.onChange(option, e);
  };

  render() {
    const {
      className = '',
      label = '',
      value = '',
      options = [],
      width = null,
    } = this.props;

    const selectedOption =
      value !== void 0 && options.find(option => option.value === value);

    return (
      <div
        className={`MitreSelect ${className}`}
        tabIndex={-1}
        ref={node => (this.container = node)}
      >
        <span className={'MitreSelect__Caption'}>{label}</span>
        <label className={`MitreSelect__Label`}>
          <select
            className={`MitreSelect__Input`}
            value={(selectedOption && selectedOption.value.toString()) || ''}
            onChange={this.onChange}
            onBlur={this.onBlur}
            style={{
              width: width ? `${width}px` : '100%',
            }}
          >
            {options.map(option => {
              return (
                <option
                  className={`MitreSelect__Option`}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              );
            })}
          </select>
          <span className={'MitreSelect__Label__Arrow'}>
            <DropDown2 size={8} color="rgba(255, 255, 255, 0.3)" />
          </span>
        </label>
      </div>
    );
  }
}
