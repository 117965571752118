/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerCircle } from '../players/circle';
import { PlayerProgressPie } from '../players/progress-pie';
import { PlayerPath } from '../players/path';

// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { Person } from '../svg/person';

@observer
export class Slide05 extends AnimatedSlide {
  // Animation players
  players = {
    analyst: null,
    circle: null,
    terminal1: null,
    terminal2: null,
    terminal3: null,
    terminal4: null,
    terminal5: null,
    terminal6: null,
  };

  // Six progress bars
  bars = [
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
  ];

  fadeIn(target, delay = 0) {
    return {
      targets: target,
      opacity: 1,
      easing: 'easeOutQuad',
      delay: delay,
      duration: 500,
      begin: activate,
      complete: activateShimmer,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  animateTerminal(target) {
    return {
      targets: target,
      delay: 0,
      duration: 1000,
      complete: shimmer,
    };
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;

    // Constants
    const {
      analyst,
      circle,
      terminal1,
      terminal2,
      terminal3,
      terminal4,
      terminal5,
      terminal6,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(9000);
        this.autoNext(1000);
      },
      // STep 2
      () => {
        this.autoNext(1000);
      },
      // STep 3
      () => {
        this.autoNext(1000);
      },
      //
      // TERMINAL ANIMATIONS
      //
      // Step 4
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal1, 'hide-line');
        this.bars[0].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 5
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal2, 'hide-line');
        this.bars[0].color = this.colors.blue;
        this.bars[1].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 6
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal3, 'hide-line');
        this.bars[1].color = this.colors.blue;
        this.bars[2].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 7
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal4, 'hide-line');
        this.bars[2].color = this.colors.blue;
        this.bars[3].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 8
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal5, 'hide-line');
        this.bars[3].color = this.colors.blue;
        this.bars[4].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 9
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal6, 'hide-line');
        this.bars[4].color = this.colors.blue;
        this.bars[5].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // STep 10
      () => {
        if (!this._isMounted) return;
        this.bars[5].color = this.colors.blue;
        this.forceUpdate();
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      // ReactDOM.findDOMNode()

      // Step 1
      [
        this.fadeIn(circle, 0),
        this.fadeIn(analyst, 0),
        this.fadeIn(terminal1, 500),
        this.fadeIn(terminal2, 800),
        this.fadeIn(terminal3, 1000),
        this.fadeIn(terminal4, 1200),
        this.fadeIn(terminal5, 1400),
        this.fadeIn(terminal6, 1600),
      ],
      // Animate terminals
      // Step 4
      [],
      [this.animateTerminal(terminal1)],
      // Step 5
      [this.animateTerminal(terminal2)],
      // Step 6
      [this.animateTerminal(terminal3)],
      // Step 7
      [this.animateTerminal(terminal4)],
      // Step 8
      [this.animateTerminal(terminal5)],
      // Step 9
      [this.animateTerminal(terminal6)],
      // Step 10
      [],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-05 ${className}`}>
        {children}
        <div className="content">
          {/*-------PLAYERS START-------*/}

          {/* Large circle */}
          <div
            className="player circle"
            data-active="0"
            ref={node => (this.players.circle = node)}
          >
            <PlayerCircle />
          </div>

          {/*-------ANALYST SECTION-------*/}

          {/* Analyst */}
          <div
            className="player analyst"
            data-active="0"
            ref={node => (this.players.analyst = node)}
          >
            <Card IconComponent={Person} letterSpacing={0} text="SOC ANALYST" />
          </div>

          {/* Terminal 1 */}
          <div
            className="player terminal terminal1"
            data-active="0"
            ref={node => (this.players.terminal1 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[0].progress}
              color={this.bars[0].color}
              size={70}
              stroke={3}
            />
            <PlayerPath points={['0,0', '50,50'].reverse()} />
          </div>
          {/* Terminal 2 */}
          <div
            className="player terminal terminal2"
            data-active="0"
            ref={node => (this.players.terminal2 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[1].progress}
              color={this.bars[1].color}
              size={70}
              stroke={3}
            />
            <PlayerPath points={['0,3', '70,3'].reverse()} dots={{}} />
          </div>
          {/* Terminal 3 */}
          <div
            className="player terminal terminal3"
            data-active="0"
            ref={node => (this.players.terminal3 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[2].progress}
              color={this.bars[2].color}
              size={70}
              stroke={3}
            />
            <PlayerPath points={['0,50', '50,0'].reverse()} dots={{}} />
          </div>
          {/* Terminal 4 */}
          <div
            className="player terminal terminal4"
            data-active="0"
            ref={node => (this.players.terminal4 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[3].progress}
              color={this.bars[3].color}
              size={70}
              stroke={3}
            />
            <PlayerPath points={['50,0', '0,50'].reverse()} dots={{}} />
          </div>
          {/* Terminal 5 */}
          <div
            className="player terminal terminal5"
            data-active="0"
            ref={node => (this.players.terminal5 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[4].progress}
              color={this.bars[4].color}
              size={70}
              stroke={3}
            />
            <PlayerPath points={['70,3', '0,3'].reverse()} dots={{}} />
          </div>
          {/* Terminal 6 */}
          <div
            className="player terminal terminal6"
            data-active="0"
            ref={node => (this.players.terminal6 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[5].progress}
              color={this.bars[5].color}
              size={70}
              stroke={3}
            />
            <PlayerPath points={['50,50', '0,0'].reverse()} dots={{}} />
          </div>

          {/*-------PLAYERS END-------*/}
        </div>
      </div>
    );
  }
}
