/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class CheckBoxes extends Component {
  render() {
    const {
      children,
      className = '',
      color = 'white',
      size = 100,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.48438 4V18.0156H16.5V19.9844H2.48438C1.95313 19.9844 1.48438 19.7969 1.07812 19.4219C0.703125 19.0156 0.515625 18.5469 0.515625 18.0156V4H2.48438ZM10.9688 12.0156L17.4844 5.40625L16.0781 4L10.9688 9.15625L8.90625 7.09375L7.5 8.5L10.9688 12.0156ZM18.5156 0.015625C19.0469 0.015625 19.5 0.21875 19.875 0.625C20.2812 1 20.4844 1.45313 20.4844 1.98438V13.9844C20.4844 14.5156 20.2812 14.9844 19.875 15.3906C19.5 15.7969 19.0469 16 18.5156 16H6.51562C5.98438 16 5.51562 15.7969 5.10938 15.3906C4.70312 14.9844 4.5 14.5156 4.5 13.9844V1.98438C4.5 1.45313 4.70312 1 5.10938 0.625C5.51562 0.21875 5.98438 0.015625 6.51562 0.015625H18.5156Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
