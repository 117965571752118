/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// SVG
import { Person } from '../svg/person';
import { ShieldBlank } from '../svg/shield-blank';
import { XLS } from '../svg/xls';
import { Document } from '../svg/document';
import { Card } from '../svg/card';

// PLAYERS
import { PlayerPath } from '../players/path';
import { PlayerReveal } from '../players/reveal';
import { PlayerDataScan } from '../players/data-scan';

@observer
export class Slide04 extends AnimatedSlide {
  children = {
    gradient1: createRef(),
    gradient2: createRef(),
  };

  // Animation players
  players = {
    card1: null,
    card2: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
    path5: null,
    scanner: null,
    reveal1: null,
    reveal2: null,
  };

  changeGradient1() {
    if (!this.children.gradient1.current) return;
    this.children.gradient1.current.change();
  }

  changeGradient2() {
    if (!this.children.gradient2.current) return;
    this.children.gradient2.current.change();
  }

  fadeIn(target, delay = 0) {
    return {
      targets: target,
      opacity: 1,
      easing: 'easeOutQuad',
      delay: delay,
      duration: 500,
      begin: activate,
      complete: activateShimmer,
    };
  }

  // Run the first animation
  componentDidMount() {
    const {
      card1,
      card2,
      path1,
      path2,
      path3,
      path4,
      path5,
      scanner,
      reveal1,
      reveal2,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(15000);
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        // Card 1
        this.fadeIn(card1, 0),
        this.fadeIn(path1, 500),
        this.fadeIn(scanner, 1000),
        // Card 2
        this.fadeIn(card2, 1500),
        this.fadeIn(path2, 2000),
        // Reveals
        this.fadeIn(path3, 2200),
        this.fadeIn(reveal1, 2500),
        this.fadeIn(reveal2, 3000),
        this.fadeIn(path4, 3200),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let reveal1 = { delay: 700 };
    let reveal2 = { delay: 2200 };
    let reveal3 = { delay: 2400 };
    let reveal4 = { delay: 3600 };

    return (
      <div className={`slide-04 ${className}`}>
        {children}
        <div className="content">
          {/*---------- CONTENT START ----------*/}
          {/* Card 1 */}
          <div
            className="player card1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <Card IconComponent={Person} text={'THREAT HUNTERS'} delay={300} />
          </div>
          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,3', '60,3', '60,53', '120,53']}
              gradient={{
                direction: 'left',
                preset: 'blueWhite',
              }}
              reveal={reveal1}
              data={false}
            />
          </div>
          {/* Card 2 */}
          <div
            className="player card2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <Card IconComponent={Person} text={'SOC ANALYSTS'} delay={1800} />
          </div>
          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,60', '60,60', '60,3', '120,3']}
              gradient={{
                direction: 'left',
                preset: 'blueWhite',
              }}
              reveal={reveal2}
              data={false}
            />
          </div>
          {/* Scanner */}
          <div
            className="player scanner"
            data-active="0"
            ref={node => (this.players.scanner = node)}
          >
            <div className="scannerTitle top">
              <span className="titleBorder">
                <ShieldBlank size={14} opacity={1} />
                MDR FOR NETWORK
              </span>
            </div>
            <PlayerDataScan
              columns={[
                '20px 1fr 1fr',
                '20px 1fr 1fr 1fr',
                '20px 1fr 1fr 1fr 1fr',
                '20px 2fr 1fr',
                '20px 2fr 1.5fr 1fr',
                '20px 1fr 1.5fr 2fr 1fr',
              ]}
              gap={4}
              size={180}
              width={180}
              height={180}
              rows={30}
              increment={0.3}
            />
            <div className="scannerTitle bottom">
              <span className="titleBorder">
                <ShieldBlank size={14} opacity={1} />
                MDR FOR ENDPOINT
              </span>
            </div>
          </div>
          {/* Path 3 */}
          <div
            className="player path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,120', '60,120', '60,3', '180,3', '180,30']}
              gradient={{
                direction: 'right',
                preset: 'blueWhite',
              }}
              gradientChange={{
                preset: 'blueRed',
              }}
              data={{
                duration: 2000,
                delay: reveal4.delay + 500,
              }}
              reveal={reveal3}
              ref={this.children.gradient1}
            />
          </div>
          {/* Reveal1 */}
          <div
            className="player reveal1"
            data-active="0"
            ref={node => (this.players.reveal1 = node)}
          >
            <PlayerReveal
              IconComponent={XLS}
              width={120}
              height={120}
              alert={true}
              delay={3000}
              complete={() => this.changeGradient1()}
            />
          </div>
          {/* Reveal2 */}
          <div
            className="player reveal2"
            data-active="0"
            ref={node => (this.players.reveal2 = node)}
          >
            <PlayerReveal
              IconComponent={Document}
              width={120}
              height={120}
              alert={true}
              delay={5000}
              complete={() => this.changeGradient2()}
            />
          </div>
          {/* Path 4 */}
          <div
            className="player path4"
            data-active="0"
            ref={node => (this.players.path4 = node)}
          >
            <PlayerPath
              points={['0,3', '60,3', '60,120', '180,120', '180,90'].reverse()}
              gradient={{
                direction: 'right',
                preset: 'blueWhite',
              }}
              gradientChange={{
                preset: 'blueRed',
              }}
              data={{
                duration: 2000,
                delay: reveal4.delay + 500,
              }}
              reveal={reveal4}
              ref={this.children.gradient2}
            />
          </div>
          {/*---------- CONTENT END ----------*/}
        </div>
      </div>
    );
  }
}
