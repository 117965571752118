/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import anime from 'animejs';
import './list-complete.scss';

// Import icon
import { Check } from '../svg/check';

@observer
export class PlayerListComplete extends Component {
  wrapper = null;

  // Store list item nodes
  nodes = [];

  // The promise
  animatingNode = null;

  // Add remove class
  addRemoveClass(node, className, wait = 1000) {
    node.classList.add(className);
    setTimeout(() => {
      node.classList.remove(className);
    }, wait);
  }

  animateList() {
    const { duration = 1000 } = this.props;
    this.nodes.map((node, index) => {
      setTimeout(() => {
        this.addRemoveClass(node, 'processing', duration);
        setTimeout(() => {
          node.classList.add('complete', 'shimmer');
        }, duration);
      }, duration * index);
    });
  }

  componentDidMount() {
    const { delay = 1000 } = this.props;
    setTimeout(() => {
      this.animateList();
    }, delay);
  }

  render() {
    // Get parameters
    const {
      className = '',
      width = 400,
      title = 'LIST',
      duration = 500,
      items = [],
    } = this.props;

    // Get width in pixels
    let w = width.toString().replace(/px/g, '') + 'px';

    // Styles
    let styles = { width: w };

    return (
      <div
        className={`Player__ListComplete ${className}`}
        style={styles}
        ref={node => (this.wrapper = node)}
      >
        <div className="Player__ListComplete__Title">
          <h3>{title}</h3>
        </div>
        <div className="Player__ListComplete__Items">
          {items.map((item, index) => {
            return (
              <div
                className="Player__ListComplete__Items__Item"
                key={index}
                ref={node => (this.nodes[index] = node)}
              >
                <span>
                  <Check size={14} color={'#151719'} />
                </span>
                <span>{item}</span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
