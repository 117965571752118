import { uniq } from 'lodash';
import { observable } from 'mobx';
import * as xlsx from 'xlsx';

const toKey = (value: string) => {
  return value
    .toString()
    .toLowerCase()
    .trim()
    .split(' ')
    .join('-');
};

export const ChartDataStore = (() => {
  let _complianceData;

  class ChartDataStore {
    @observable loadingComplianceAlignmentData = true;

    async processComplianceAlignmentData() {
      if (!_complianceData) {
        this.loadingComplianceAlignmentData = true;

        _complianceData = new Promise(resolve => {
          const req = new XMLHttpRequest();
          req.open('GET', require('./compliance-alignment-mapping.xlsx'), true);
          req.responseType = 'arraybuffer';

          req.onload = e => {
            const data = new Uint8Array(req.response);
            const wb = xlsx.read(data, { type: 'array' });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            resolve(xlsx.utils.sheet_to_json(ws, { header: 1 }));
            this.loadingComplianceAlignmentData = false;
          };

          req.send();
        });
      }

      return _complianceData;
    }
  }

  return new ChartDataStore();
})();

interface IFunctionItem {
  key: string;
  name: string;
  products: { [key: string]: IProductItem };
}

interface IProductItem {
  key: string;
  name: string;
  coverages: { [key: string]: ICoverageItem };
}

interface ICoverageItem {
  key: string;
  name: string;
  values: string[];
  total: number;
}

export async function getComplianceAlignmentData() {
  const data = await ChartDataStore.processComplianceAlignmentData();

  const functions: { [key: string]: IFunctionItem } = {};
  const products: { [key: string]: IProductItem } = {};
  const coverages: { [key: string]: ICoverageItem } = {};

  let rowNumber = 0;
  let currentFunction: string | null = null;
  for (const row of data) {
    rowNumber++;

    // Skip the header
    if (rowNumber === 1) {
      continue;
    }

    // Gather the coverage headers
    if (rowNumber === 2) {
      row
        .slice(1, -2)
        .map(item => item.trim())
        .filter(Boolean)
        .forEach(coverageItem => {
          const coverageKey = toKey(coverageItem);
          if (!coverages[coverageKey]) {
            coverages[coverageKey] = {
              key: coverageKey,
              name: coverageItem,
              values: [],
              total: 0,
            };
          }
        });
      continue;
    }

    // Skip the subheaders
    if (rowNumber === 3) {
      continue;
    }

    // Ingest the rest of the rows
    if (rowNumber > 3) {
      const functionItem = row[0] && row[0].trim();
      const isFunctionItem =
        functionItem &&
        functionItem !== 'eSentire Security Framework' &&
        functionItem !== '#' &&
        functionItem !== 'Function';

      if (isFunctionItem) {
        currentFunction = toKey(functionItem);
        if (!functions[currentFunction]) {
          functions[currentFunction] = {
            key: currentFunction,
            name: functionItem,
            products: {},
          };
        }
      }

      const productItem = row[4] && row[4].trim();
      const isProductItem =
        productItem &&
        productItem !== 'Providing eSentire Service (Product Specific)';

      const currentFunctionCached = currentFunction;
      if (isProductItem && currentFunctionCached) {
        productItem
          .split(',')
          .filter(Boolean)
          .map(item => {
            return item
              .replace(
                'Vulnerabiltiy Asessment (VA)',
                'Vulnerability Assessment'
              )
              .replace('eSentire Threat Intellegence (', '')
              .replace(')', '')
              .replace(' (Internal and Extneral', '');
          })
          .map(item => item.trim())
          .filter(product => {
            const productKey = toKey(product);
            if (!products[productKey]) {
              products[productKey] = {
                key: productKey,
                name: product,
                coverages: {},
              };
            }
            if (!functions[currentFunctionCached].products[productKey]) {
              functions[currentFunctionCached].products[productKey] = {
                key: productKey,
                name: product,
                coverages: {},
              };
            }

            Object.keys(coverages).forEach((coverage, i) => {
              const item = row[i + 6];
              if (item) {
                const items = item
                  .toString()
                  .replace(/\r/g, '')
                  .replace(/\n/g, '')
                  .split(',');

                coverages[coverage].values.push(...items);
                coverages[coverage].values = uniq(coverages[coverage].values);
                coverages[coverage].total = coverages[coverage].values.length;
                if (!products[productKey].coverages[coverage]) {
                  products[productKey].coverages[coverage] = {
                    key: coverage,
                    name: coverages[coverage].name,
                    values: [],
                    total: 0,
                  };
                }
                products[productKey].coverages[coverage].values.push(...items);
                products[productKey].coverages[coverage].values = uniq(
                  products[productKey].coverages[coverage].values
                );
                products[productKey].coverages[coverage].total =
                  products[productKey].coverages[coverage].values.length;
                if (
                  !functions[currentFunctionCached].products[productKey]
                    .coverages[coverage]
                ) {
                  functions[currentFunctionCached].products[
                    productKey
                  ].coverages[coverage] = {
                    key: coverage,
                    name: coverages[coverage].name,
                    values: [],
                    total: 0,
                  };
                }
                functions[currentFunctionCached].products[productKey].coverages[
                  coverage
                ].values.push(...items);
                functions[currentFunctionCached].products[productKey].coverages[
                  coverage
                ].values = uniq(
                  functions[currentFunctionCached].products[productKey]
                    .coverages[coverage].values
                );
                functions[currentFunctionCached].products[productKey].coverages[
                  coverage
                ].total =
                  functions[currentFunctionCached].products[
                    productKey
                  ].coverages[coverage].values.length;
              }
            });
          });
      }
    }
  }

  return {
    functions,
    products,
    coverages,
  };
}
