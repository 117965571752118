/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class ArrowRight extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#C53D43',
      bold = false,
      opacity = bold ? 1 : 0.5,
      x = 0,
      y = 0,
      size = 24,
    } = this.props;

    if (bold) {
      return (
        <svg
          className={className}
          {...{ x, y }}
          width={size}
          height={size}
          viewBox="0 0 69 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M68.4445 24L42.8464 0V14.25H0V33.75H42.8464V48L68.4445 24Z"
            fill={color}
            fillOpacity={opacity}
          />
        </svg>
      );
    }

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 24 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9795 5.80413C23.9735 5.77413 23.9625 5.74663 23.954 5.71763C23.944 5.68413 23.9365 5.65013 23.923 5.61763C23.9085 5.58313 23.889 5.55213 23.8715 5.51964C23.8575 5.49514 23.847 5.46964 23.831 5.44564C23.794 5.39014 23.7525 5.33914 23.7055 5.29214L18.7066 0.293244C18.3156 -0.097748 17.6831 -0.097748 17.2921 0.293244C16.9016 0.683736 16.9016 1.31622 17.2921 1.70721L20.5851 5.00015H0.999979C0.447491 5.00015 0 5.44764 0 6.00012C0 6.55211 0.447491 7.0001 0.999979 7.0001H20.5851L17.2921 10.293C16.9016 10.6835 16.9016 11.316 17.2921 11.707C17.4876 11.902 17.7436 12 17.9996 12C18.2551 12 18.5111 11.902 18.7066 11.707L23.7055 6.70761C23.7525 6.66061 23.794 6.60961 23.831 6.55411C23.847 6.53061 23.8575 6.50461 23.8715 6.48011C23.889 6.44762 23.9085 6.41662 23.923 6.38212C23.9365 6.34962 23.944 6.31562 23.954 6.28262C23.9625 6.25312 23.9735 6.22562 23.9795 6.19562C24.0055 6.06662 24.0055 5.93313 23.9795 5.80413Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
