import * as React from 'react';
import './kitchen-sink.scss';
import { Button } from '../button.jsx';
import { GraphicsTab } from './graphics-tab.jsx';
import { RouteManager, Router } from 'vega-router/dist';
import { ChartsTab } from './charts-tab.jsx';
import { PopupsTab } from './popups-tab.jsx';
import { AppActions } from '../app-actions';

export class KitchenSink extends Router {
  constructor(...rest) {
    super(...rest);
    this.addRoute('graphics', /\bsink(?:\/graphics\b|$)/, GraphicsTab);
    this.addRoute('charts', /\bsink\/charts\b/, ChartsTab);
    this.addRoute('popups', /\bsink\/popups\b/, PopupsTab);
  }

  componentDidMount() {
    AppActions.setShortcutName('Kitchen Sink');
  }

  handleTab = tab => () => {
    RouteManager.transitionTo(`/sink/${tab}/`);
  };

  render() {
    const { className = '' } = this.props;
    const tab = (this.route && this.route.name) || 'graphics';

    return (
      <div className={`KitchenSink ${className}`}>
        <div className="KitchenSink__Navigation">
          <div className="KitchenSink__Tabs">
            {this.routes.map(route => (
              <Button
                key={route.name}
                invert={tab === route.name}
                onClick={this.handleTab(route.name)}
              >
                {route.name}
              </Button>
            ))}
            <Button
              className="KitchenSink__BackLink"
              onClick={() => RouteManager.transitionTo('/')}
            >
              &lt; Back to App
            </Button>
          </div>
          <h2>Kitchen Sink</h2>
        </div>
        <div className="KitchenSink__Body">{super.render()}</div>
      </div>
    );
  }
}
