import { observable, toJS } from 'mobx';
import { defaultsDeep } from 'lodash';

const { ceil } = Math;

export const BackgroundGridState = observable({
  tileSize: 40,
  width: 0,
  height: 0,
  tiles: [],
});

export class Tile {
  @observable row = 0;
  @observable column = 0;
  @observable className = 'intro';

  get key() {
    return `r${this.row}c${this.column}`;
  }

  /**
   * @param {Partial<Tile>} properties
   */
  constructor(properties = {}) {
    Object.assign(this, properties);
  }
}

export class BackgroundGridActions {
  static setSizeInPixels(width, height) {
    this.setSizeInTiles(
      ceil(width / BackgroundGridState.tileSize),
      ceil(height / BackgroundGridState.tileSize)
    );
  }

  static setSizeInTiles(width, height) {
    BackgroundGridState.width = width;
    BackgroundGridState.height = height;

    const tiles = BackgroundGridState.tiles.slice();

    BackgroundGridState.tiles = new Array(height).fill(0).map((row, y) =>
      new Array(width).fill(0).map((_, x) => {
        if (tiles.length > y && tiles[y].length > x) return tiles[y][x];
        const tile = new Tile({
          row: y,
          column: x,
        });
        return tile;
      })
    );
  }
}
