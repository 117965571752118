/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Device extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 48,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.878 48H42.122C43.6805 47.9984 45.1746 47.3786 46.2766 46.2766C47.3786 45.1746 47.9984 43.6805 48 42.122V5.878C47.9984 4.31954 47.3786 2.82538 46.2766 1.72338C45.1746 0.621386 43.6805 0.00158837 42.122 0L5.878 0C4.31954 0.00158837 2.82538 0.621386 1.72338 1.72338C0.621386 2.82538 0.00158837 4.31954 0 5.878V42.122C0.00158837 43.6805 0.621386 45.1746 1.72338 46.2766C2.82538 47.3786 4.31954 47.9984 5.878 48ZM42.122 44.082H5.878C5.35818 44.082 4.85964 43.8755 4.49207 43.5079C4.1245 43.1404 3.918 42.6418 3.918 42.122V6C3.918 5.46957 4.12871 4.96086 4.50379 4.58579C4.87886 4.21071 5.38757 4 5.918 4H42.082C42.6124 4 43.1211 4.21071 43.4962 4.58579C43.8713 4.96086 44.082 5.46957 44.082 6V42.122C44.082 42.6418 43.8755 43.1404 43.5079 43.5079C43.1404 43.8755 42.6418 44.082 42.122 44.082Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M37.672 31.184L33.544 34.848C33.4578 34.9236 33.3485 34.9676 33.2339 34.9727C33.1194 34.9778 33.0065 34.9437 32.914 34.876L30.182 32.852C29.5183 32.387 28.722 32.1492 27.912 32.174C27.0884 32.192 26.2969 32.4969 25.674 33.036L20.64 37.504C20.2422 37.8562 20.0006 38.352 19.9683 38.8824C19.936 39.4127 20.1158 39.9342 20.468 40.332C20.8202 40.7298 21.316 40.9714 21.8464 41.0037C22.3767 41.0359 22.8982 40.8562 23.296 40.504L27.762 36.54C27.8477 36.4642 27.9568 36.4201 28.071 36.415C28.1853 36.4099 28.2979 36.4441 28.39 36.512L31.122 38.536C31.7853 39.0021 32.5817 39.2406 33.392 39.216C34.2158 39.1973 35.0073 38.8917 35.63 38.352L40.328 34.184C40.525 34.0096 40.6857 33.7981 40.8009 33.5616C40.9162 33.3251 40.9837 33.0682 40.9997 32.8056C41.0157 32.543 40.9798 32.2798 40.894 32.0311C40.8083 31.7824 40.6744 31.553 40.5 31.356C40.3256 31.159 40.1141 30.9983 39.8776 30.8831C39.6411 30.7678 39.3842 30.7003 39.1216 30.6843C38.859 30.6683 38.5958 30.7042 38.3471 30.79C38.0984 30.8757 37.869 31.0096 37.672 31.184Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M28.662 20.246C28.6264 20.1959 28.5801 20.1543 28.5265 20.1242C28.4729 20.0941 28.4133 20.0763 28.352 20.072L20.22 19.444C20.154 19.4385 20.0876 19.4486 20.0263 19.4737C19.965 19.4987 19.9105 19.5379 19.8672 19.588C19.8239 19.6381 19.7931 19.6977 19.7772 19.7621C19.7614 19.8264 19.761 19.8935 19.776 19.958L21.544 27.92C21.5577 27.9797 21.5841 28.0358 21.6214 28.0845C21.6586 28.1331 21.7059 28.1732 21.76 28.202C21.8142 28.2305 21.8739 28.2469 21.935 28.25C21.9962 28.2531 22.0572 28.2428 22.114 28.22C23.7442 27.569 25.1952 26.5378 26.3461 25.2123C27.4969 23.8868 28.3143 22.3054 28.73 20.6C28.7459 20.5397 28.7481 20.4765 28.7363 20.4152C28.7246 20.354 28.6992 20.2961 28.662 20.246Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M14.418 7.61801C14.3604 7.63842 14.3079 7.67104 14.2641 7.71362C14.2203 7.7562 14.1862 7.80775 14.1642 7.86473C14.1422 7.92171 14.1327 7.98278 14.1365 8.04375C14.1402 8.10473 14.1571 8.16417 14.186 8.21801L18.718 16.6C18.7515 16.6618 18.8 16.7142 18.8591 16.7523C18.9181 16.7904 18.9859 16.813 19.056 16.818L28.544 17.552C28.6052 17.5569 28.6668 17.5484 28.7243 17.527C28.7819 17.5057 28.8341 17.4721 28.8774 17.4284C28.9206 17.3848 28.9538 17.3323 28.9746 17.2746C28.9955 17.2168 29.0034 17.1552 28.998 17.094C28.8574 15.4224 28.3364 13.805 27.4748 12.3657C26.6131 10.9264 25.4337 9.70318 24.0267 8.78969C22.6197 7.8762 21.0224 7.29662 19.357 7.09527C17.6916 6.89392 16.0022 7.07615 14.418 7.62801V7.61801Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M11.672 9.21599C11.6121 9.20296 11.5501 9.2032 11.4904 9.21667C11.4306 9.23015 11.3745 9.25654 11.326 9.29399C9.50989 10.6965 8.17786 12.6319 7.51642 14.8292C6.85497 17.0264 6.89722 19.3755 7.63725 21.5475C8.37728 23.7196 9.77804 25.6058 11.6434 26.9421C13.5088 28.2785 15.7454 28.998 18.04 29C18.254 29 18.466 29 18.678 28.982C18.7392 28.9787 18.7988 28.9619 18.8528 28.9329C18.9067 28.9038 18.9536 28.8632 18.99 28.814C19.0266 28.7651 19.0524 28.7089 19.0655 28.6492C19.0786 28.5895 19.0788 28.5277 19.066 28.468L16.852 18.506C16.8435 18.4677 16.83 18.4308 16.812 18.396L11.956 9.42799C11.9269 9.37417 11.8864 9.32734 11.8373 9.29073C11.7883 9.25412 11.7319 9.22862 11.672 9.21599Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M35 11C35 11.5304 35.2107 12.0391 35.5858 12.4142C35.9609 12.7893 36.4696 13 37 13C37.5304 13 38.0391 12.7893 38.4142 12.4142C38.7893 12.0391 39 11.5304 39 11C39 10.4696 38.7893 9.96086 38.4142 9.58579C38.0391 9.21071 37.5304 9 37 9C36.4696 9 35.9609 9.21071 35.5858 9.58579C35.2107 9.96086 35 10.4696 35 11Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M35 17C35 17.5304 35.2107 18.0391 35.5858 18.4142C35.9609 18.7893 36.4696 19 37 19C37.5304 19 38.0391 18.7893 38.4142 18.4142C38.7893 18.0391 39 17.5304 39 17C39 16.4696 38.7893 15.9609 38.4142 15.5858C38.0391 15.2107 37.5304 15 37 15C36.4696 15 35.9609 15.2107 35.5858 15.5858C35.2107 15.9609 35 16.4696 35 17Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M35 23C35 23.5304 35.2107 24.0391 35.5858 24.4142C35.9609 24.7893 36.4696 25 37 25C37.5304 25 38.0391 24.7893 38.4142 24.4142C38.7893 24.0391 39 23.5304 39 23C39 22.4696 38.7893 21.9609 38.4142 21.5858C38.0391 21.2107 37.5304 21 37 21C36.4696 21 35.9609 21.2107 35.5858 21.5858C35.2107 21.9609 35 22.4696 35 23Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
