/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Download2 extends Component {
  render() {
    const {
      children,
      className = '',
      color = 'white',
      size = 100,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 0C12.8355 0 9.74207 0.938383 7.11088 2.69649C4.4797 4.45459 2.42894 6.95344 1.21793 9.87706C0.00693255 12.8007 -0.309921 16.0177 0.307443 19.1214C0.924806 22.2251 2.44866 25.0761 4.6863 27.3137C6.92394 29.5513 9.77486 31.0752 12.8786 31.6926C15.9823 32.3099 19.1993 31.9931 22.1229 30.7821C25.0466 29.5711 27.5454 27.5203 29.3035 24.8891C31.0616 22.2579 32 19.1645 32 16C31.9948 11.7581 30.3074 7.6915 27.308 4.69205C24.3085 1.69259 20.2419 0.00521114 16 0V0ZM20.9231 16C21.1549 15.9999 21.382 16.0652 21.5783 16.1885C21.7746 16.3117 21.9321 16.4879 22.0327 16.6968C22.1333 16.9056 22.1728 17.1387 22.1468 17.369C22.1207 17.5993 22.0302 17.8176 21.8855 17.9988L16.9625 24.1526C16.8472 24.2972 16.7008 24.414 16.5342 24.4943C16.3675 24.5746 16.185 24.6163 16 24.6163C15.815 24.6163 15.6325 24.5746 15.4658 24.4943C15.2992 24.414 15.1528 24.2972 15.0375 24.1526L10.1145 17.9988C9.96982 17.8176 9.87927 17.5993 9.85324 17.369C9.8272 17.1387 9.86674 16.9056 9.96731 16.6968C10.0679 16.4879 10.2254 16.3117 10.4217 16.1885C10.618 16.0652 10.8451 15.9999 11.0769 16H13.5385C13.7017 16 13.8582 15.9352 13.9736 15.8198C14.089 15.7043 14.1538 15.5478 14.1538 15.3846V8.61538C14.1538 8.12575 14.3484 7.65618 14.6946 7.30995C15.0408 6.96373 15.5104 6.76923 16 6.76923C16.4896 6.76923 16.9592 6.96373 17.3054 7.30995C17.6517 7.65618 17.8462 8.12575 17.8462 8.61538V15.3846C17.8462 15.5478 17.911 15.7043 18.0264 15.8198C18.1418 15.9352 18.2983 16 18.4615 16H20.9231Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
