/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Page } from '../svg/page';
import { Computer } from '../svg/computer';

@observer
export class Slide09 extends AnimatedSlide {
  // Animation players
  players = {
    computer: null,
    page: null,
    donut: null,
    analyzing: null,
  };

  // Run the first animation
  componentDidMount() {
    const { computer, page, donut, analyzing } = this.players;

    this.steps = [
      // Step 1
      () => {
        // Do nothing
        this.animateProgress(2000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: page,
          opacity: 1,
          scale: 3,
          top: 370,
          delay: 0,
          duration: 1000,
          begin: activate,
        },
        {
          targets: donut,
          opacity: [0, 1],
          delay: 0,
          duration: 1000,
          begin: activate,
        },
        {
          targets: computer,
          easing: 'easeOutQuad',
          opacity: 0,
          delay: 0,
          duration: 500,
          complete: deactivate,
        },
        {
          targets: analyzing,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 840,
          duration: 200,
          delay: 1000,
          begin: activate,
          complete: shimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`AnalyzeActivity ${className}`}>
        {children}
        <div className="content">
          {/* Text block */}
          <div
            className="player analyzing"
            data-active="0"
            ref={node => (this.players.analyzing = node)}
          >
            <PlayerTextBlock title="Sandbox" actionTitle="Analyzing file" />
          </div>

          {/* Page */}
          <div
            className="player page"
            data-active="1"
            ref={node => (this.players.page = node)}
          >
            <Page size={'45'} />
          </div>

          {/* Computer */}
          <div
            className="player computer"
            data-active="1"
            ref={node => (this.players.computer = node)}
          >
            <Computer size={'140'} />
          </div>

          {/* Donut */}
          <div
            className="player donut"
            data-active="0"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
