/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';
import ReactDOM from 'react-dom';
import { observable } from 'mobx';
import anime from 'animejs';

// SVG
import { Page } from '../svg/page';
import { Alert } from '../svg/alert';
import { Warning } from '../svg/warning';

@observer
export class Slide14 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    document: null,
    alert: null,
    border: null,
    info1: null,
    info2: null,
  };

  @observable
  donut = {
    color: 'blue',
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');
    const { donut, page, alert, border, info1, info2 } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(4000);
      },
      // Step 2
      () => {
        this.donut.color = 'red';
        this.autoNext(2000);
      },
      // Step 3
      () => {
        this.autoNext(500);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Pause for effect
      ],

      //
      // Step 1
      //
      [
        {
          targets: donut,
          opacity: 1,
          delay: 0,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: page,
          opacity: 1,
          delay: 500,
          duration: 1000,
          begin: activate,
        },
      ],
      //
      // Step 2
      //
      [
        // Turning red
      ],
      //
      // Step 3
      //
      [
        {
          targets: alert,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
        {
          targets: donut,
          opacity: 0,
          delay: 0,
          duration: 500,
          complete: deactivate,
        },
        {
          targets: page,
          opacity: 0,
          delay: 0,
          duration: 500,
          complete: deactivate,
        },
        {
          targets: info1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: info2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Final step
      //
      [
        // Do nothing
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-14 ${className}`}>
        {children}
        <div className="content">
          {/* Donut */}
          <div
            className={`player donut ${this.donut.color}`}
            data-active="0"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
          {/* Page */}
          <div
            className="player page"
            data-active="0"
            ref={node => (this.players.page = node)}
          >
            <Page size={100} />
          </div>
          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'CONFIRMED'}
              subtitle={'Suncrypt Ransomware'}
              IconComponent={Warning}
              color={this.colors.red}
            />
          </div>
          {/* Malicious Indicators */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerTextBlock
              title={'Malicious Indicators'}
              className={'red-top'}
              sections={[
                {
                  title: ['RANSOMWARE/BANKING'],
                  color: 'red',
                  colorCells: 2,
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['DETAILS', 'FILES_ENCRYPTED'],
                  color: 'red',
                  colorCells: 2,
                  grid: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['SOURCE', 'EXTRACTED_FILE'],
                  grid: 2,
                  animate: true,
                  delay: 600,
                },
                {
                  title: ['RELEVANCE', '5/10'],
                  grid: 2,
                  animate: true,
                  delay: 800,
                },
              ]}
            />
          </div>
          {/* File Info */}
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerTextBlock
              title={'File Info'}
              className={'red-top'}
              sections={[
                {
                  lines: 7,
                  lineColumns: 3,
                  animate: true,
                  delay: 200,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
