/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// SVG
import { Bug } from '../svg/bug';
import { Eye } from '../svg/eye';
import { CheckShield } from '../svg/check-shield';
import { Bell } from '../svg/bell';
import { Device } from '../svg/device';
import { PersonCircle } from '../svg/person-circle';
import { Card } from '../svg/card';
import { Binoculars } from '../svg/binoculars';
import { DesktopDownload } from '../svg/desktop-download';
import { CloudUpload } from '../svg/cloud-upload';

@observer
export class Slide02 extends AnimatedSlide {
  // Animation players
  players = {
    card1: null,
    card2: null,
    card3: null,
  };

  // Run the first animation
  componentDidMount() {
    const { card1, card2, card3 } = this.players;

    this.steps = [
      () => {
        this.autoNext(200);
      },
      () => {
        this.autoNext(200);
      },
      () => {
        this.autoNext(200);
      },
      () => {
        this.autoNext(200);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        {
          targets: card1,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: card2,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: card3,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-02 ${className}`}>
        {children}
        <div className="content">
          {/* Card 1 */}
          <div
            className="player card1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <Card IconComponent={Binoculars} iconSize={48} text={'RECON'} />
          </div>

          {/* Card 2 */}
          <div
            className="player card2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <Card
              IconComponent={DesktopDownload}
              iconSize={48}
              text={'COLLECTION'}
            />
          </div>

          {/* Card 3 */}
          <div
            className="player card3"
            data-active="0"
            ref={node => (this.players.card3 = node)}
          >
            <Card
              IconComponent={CloudUpload}
              iconSize={48}
              text={'EXFILTRATION'}
            />
          </div>
        </div>
      </div>
    );
  }
}
