/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import ReactDOM from 'react-dom';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Shield } from '../svg/shield';
import { Signal } from '../svg/signal';

@observer
export class Slide19 extends AnimatedSlide {
  // Animation players
  players = {
    attacker: null,
    info: null,
    dots1: null,
    dots2: null,
    dots3: null,
    mdr: null,
  };

  // Run the first animation
  componentDidMount() {
    const { analyst, info, dots1, dots2, dots3, mdr } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(4000);
      },
      // Step 2
      () => {
        // this.autoNext(2000);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Pause for effect
      ],

      //
      // Step 1
      //
      [
        {
          targets: analyst,
          opacity: 1,
          delay: 0,
          translateX: -300,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: dots1,
          width: [0, 100],
          delay: 500,
          duration: 500,
        },
        {
          targets: dots2,
          width: [0, 120],
          delay: 1000,
          duration: 500,
        },
        {
          targets: info,
          opacity: 1,
          delay: 1500,
          duration: 1500,
          begin: activate,
          complete: shimmer,
        },
      ],

      [
        {
          targets: mdr,
          opacity: 1,
          delay: 0,
          translateX: 150,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: dots3,
          width: [0, 120],
          opacity: 1,
          delay: 300,
          duration: 1000,
        },
        {
          targets: analyst,
          opacity: 1,
          translateX: [-300, -450],
          duration: 500,
          easing: 'easeOutQuad',
        },
        {
          targets: info,
          opacity: 1,
          translateX: -150,
          duration: 500,
          easing: 'easeOutQuad',
        },
      ],

      //
      // Step 2
      //
      [
        // Do nothing
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-19 ${className}`}>
        {children}
        <div className="content">
          {/* Analyst */}
          <div
            className="player analyst"
            data-active="0"
            ref={node => (this.players.analyst = node)}
          >
            <Card IconComponent={Person} text={'Analyst'} />
            <div
              ref={node => (this.players.dots1 = node)}
              className={'animated-dotted-line reverse ants-1'}
            ></div>
            <div
              ref={node => (this.players.dots2 = node)}
              className={'animated-dotted-line reverse ants-2'}
            ></div>
          </div>

          {/* Ban */}
          <div
            className="player info"
            data-active="0"
            ref={node => (this.players.info = node)}
          >
            <PlayerTextBlock
              title={'Ban'}
              className={'red-top'}
              sections={[
                {
                  title: ['suncrypt binary'],
                  color: 'red',
                  colorCells: 2,
                  animate: true,
                  delay: 500,
                },
                {
                  title: ['PROCESS', 'Torus[dot]exe #728164'],
                  grid: 2,
                  color: 'red',
                  colorCells: 2,
                  animate: true,
                  delay: 500,
                },
                {
                  title: ['BACKDOOR', 'PESTDC03 #728164'],
                  grid: 2,
                  color: 'red',
                  colorCells: 2,
                  animate: true,
                  delay: 800,
                },
                {
                  title: ['HASH', 'Sun Ransomware'],
                  grid: 2,
                  color: 'red',
                  colorCells: 2,
                  animate: true,
                  delay: 1000,
                },
                {
                  title: ['TICKET', '728164'],
                  grid: 2,
                  animate: true,
                  delay: 1400,
                },
              ]}
            />
          </div>
          {/* MDR */}
          <div
            className="player mdr"
            data-active="0"
            ref={node => (this.players.mdr = node)}
          >
            <Shield IconComponent={Signal} text={'MDR'} />
            <div
              ref={node => (this.players.dots3 = node)}
              className={'animated-dotted-line reverse'}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
