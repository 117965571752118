/** @jsx createElement */
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import { AssetChart } from '../../charts/asset-chart';
import { Select } from '../../select';
import { Info } from '../../svg/info';
import {
  RiskAnalysisImpactState,
  RiskAnalysisImpactActions,
} from './impact-actions';
import './impact.scss';
import {
  RiskAnalysisToolState,
  RiskAnalysisToolActions,
} from '../risk-analysis-tool-actions';

@observer
export class RiskAnalysisImpact extends Component {
  static HORIZONTAL_PADDING = 80;
  static VERTICAL_PADDING = 300;

  @observable height = 520;
  @observable width = 1000;
  @observable tooFewClients = false;

  componentDidMount() {
    RiskAnalysisImpactActions.getAssetDataAttackTypes();

    window.addEventListener('resize', this.handleResize);
    window.addEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
    setTimeout(this.handleResize, 500);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
  }

  handleOrientationChange = () => {
    setTimeout(this.handleResize, 500);
  };

  handleResize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    this.width = Math.max(
      0,
      windowWidth - RiskAnalysisImpact.HORIZONTAL_PADDING
    );
    this.height = Math.max(
      0,
      windowHeight - RiskAnalysisImpact.VERTICAL_PADDING
    );
  };

  handleTooFewClients = tooFew => {
    if (tooFew !== this.tooFewClients) {
      this.tooFewClients = tooFew;
    }
  };

  changeSelect = type => value => {
    RiskAnalysisImpactActions.updateField(type, value);
  };

  clickInfoModal = () => {
    RiskAnalysisToolActions.showImpactModal();
  };

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`RiskAnalysisImpact ${className}`}>
        <div className={`RiskAnalysisImpact__Row`}>
          <h2>Yearly Impacted Assets for Incidents</h2>
          <div
            className="RiskAnalysisImpact__Info"
            onClick={this.clickInfoModal}
          >
            <Info />
          </div>
          <Select
            className={`RiskAnalysisImpact__Select`}
            value={RiskAnalysisImpactState.attackType}
            options={RiskAnalysisImpactState.attackTypeOptions}
            onChange={this.changeSelect('attackType')}
            label="Attack Type"
          />
        </div>
        <div className={`RiskAnalysisImpact__Chart`}>
          <AssetChart
            width={this.width}
            height={this.height}
            maxBuckets={50}
            attackType={
              RiskAnalysisImpactState.attackType &&
              RiskAnalysisImpactState.attackType.value
            }
            filter={
              RiskAnalysisToolState.filter && RiskAnalysisToolState.filter.value
            }
            category={
              RiskAnalysisToolState.category &&
              RiskAnalysisToolState.category.label
            }
            onTooFewClients={this.handleTooFewClients}
          />
          {this.tooFewClients ? (
            <div>
              <br />
              *Showing global breach data as there is insufficient breach data
              available to draw a full histogram for this industry.
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
