/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class CheckCircle extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      size = 49,
      opacity = 1,
      background = 'none',
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="50%" cy="50%" r="40%" fill={background} />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.6847 17.731L19.6847 31.731C19.4927 31.911 19.2467 32 19.0027 32C18.7467 32 18.4907 31.902 18.2957 31.707L13.2957 26.707C12.9047 26.316 12.9047 25.683 13.2957 25.293C13.6857 24.902 14.3187 24.902 14.7097 25.293L19.0267 29.609L33.3207 16.268C33.7237 15.893 34.3567 15.914 34.7337 16.317C35.1107 16.721 35.0887 17.354 34.6847 17.731ZM24.0027 0C10.7687 0 0.00268555 10.766 0.00268555 24C0.00268555 37.233 10.7687 48 24.0027 48C37.2357 48 48.0027 37.233 48.0027 24C48.0027 10.766 37.2357 0 24.0027 0Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
