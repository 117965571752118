/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Word extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 34,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0428 0.199219H17.7602C14.0839 0.857422 10.4058 1.49707 6.72827 2.13672C4.55243 2.51562 2.37677 2.89453 0.201752 3.27637L0.200867 12.2256C0.199982 18.1924 0.199097 24.1611 0.201752 30.1299C2.17926 30.4775 4.15729 30.8203 6.13501 31.1631C9.97879 31.8291 13.8215 32.4951 17.6573 33.1992H20.0428V30.1299H22.6626C25.609 30.1279 28.5543 30.126 31.5021 30.1406C32.1507 30.1104 32.8723 30.1602 33.4296 29.7607C33.7807 29.2217 33.7851 28.5752 33.7895 27.9463C33.7906 27.7754 33.7918 27.6055 33.8 27.4395C33.7744 23.8135 33.7794 20.1865 33.7845 16.5596C33.79 12.625 33.7955 8.69141 33.7618 4.75781C33.8418 3.88281 33.1587 3.20312 32.2884 3.29199C29.8583 3.25293 27.4281 3.26074 24.9972 3.26855C23.3462 3.27344 21.6948 3.27832 20.0428 3.26855V0.199219ZM32.6393 4.42188H20.0428V7.1084H29.9673V8.64258H20.0428V10.5615H29.9673V12.0967H20.0428V14.0146H29.9673V15.5498H20.0428V17.4688H29.9673V19.0029H20.0428V20.9219H29.9673V22.457H20.0428V24.375H29.9673V25.9102H20.0428V28.9805H32.6393V4.42188ZM10.2633 11.0908C9.53809 11.1289 8.81284 11.1602 8.08759 11.2021C7.76956 12.6953 7.44623 14.1885 7.12296 15.6816L6.46484 18.7305C6.23981 17.127 5.97046 15.5293 5.70096 13.9316C5.55905 13.0898 5.41711 12.248 5.28156 11.4053C4.62866 11.4287 3.97214 11.4561 3.31955 11.4863C3.66196 13.4453 4.05563 15.3945 4.44931 17.3438C4.73572 18.7617 5.02216 20.1797 5.28888 21.6016L6.23645 21.6631C6.65695 21.6895 7.07681 21.7139 7.49548 21.7354C7.66779 20.9053 7.8479 20.0762 8.02808 19.2471C8.38272 17.6143 8.73758 15.9814 9.03363 14.3369C9.31622 15.9238 9.65182 17.501 9.9874 19.0781C10.1914 20.0361 10.3953 20.9941 10.5873 21.9541C10.8672 21.9346 11.1742 21.9697 11.4823 22.0049C12.0063 22.0654 12.5335 22.126 12.935 21.916C13.5354 19.5635 14.06 17.1885 14.5846 14.8135C14.8839 13.459 15.1831 12.1035 15.4965 10.7529C14.7367 10.7998 13.9733 10.8418 13.2098 10.8682L12.8477 12.9424C12.5032 14.9092 12.1589 16.875 11.8397 18.8457C11.29 16.2676 10.7709 13.6816 10.2633 11.0908Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
