/** @jsx createElement */
// SVG
import { Alert } from '../svg/alert';
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Email } from '../svg/email';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';

@observer
export class Slide26 extends AnimatedSlide {
  // Animation players
  players = {
    title: null,
    alert: null,
    timeline: null,
    domains: null,
    hashes: null,
    border: null,
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');

    const { alert, timeline, domains, hashes, border, title } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      // Step 2
      () => {
        this.autoNext(4000);
      },
      // Step 3
      () => {
        this.autoNext(500);
      },
      // Step 4
      () => {
        this.autoNext(500);
      },
      // Step 5
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: title,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: 1,
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },

        // Deactivate
        {
          targets: timeline,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: timeline,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
        {
          targets: domains,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: domains,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: hashes,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 5
      //
      [
        {
          targets: hashes,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`DeepDive2 ${className}`}>
        {children}
        <div className="content">
          {/* Title */}
          <div
            className="player title"
            data-active="0"
            ref={node => (this.players.title = node)}
          >
            <h1>eSentire Investigation Analysis</h1>
          </div>

          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'Message from eSentire'}
              subtitle={'Investigation Analysis'}
              color={'#50C2E3'}
            />
          </div>

          {/* Incident timeline */}
          <div
            className="player timeline"
            data-active="0"
            ref={node => (this.players.timeline = node)}
          >
            <PlayerTextBlock
              title={'Incident timeline'}
              showTimeline={true}
              width={'300px'}
              sections={[
                {
                  lines: 3,
                  lineColumns: 2,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
                {
                  lines: 3,
                  lineColumns: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Malicious IPs + Domains */}
          <div
            className="player domains"
            data-active="0"
            ref={node => (this.players.domains = node)}
          >
            <PlayerTextBlock
              title={'Malicious IPs + Domains'}
              width={'250px'}
              sections={[
                {
                  title: ['IP', '45.40.148.106'],
                  grid: 2,
                },
                {
                  title: ['WWW.EASY123PROPERTY.COM'],
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Malicious Files + Hashes */}
          <div
            className="player hashes"
            data-active="0"
            ref={node => (this.players.hashes = node)}
          >
            <PlayerTextBlock
              title={'Malicious Files + Hashes'}
              width={'250px'}
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
