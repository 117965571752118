/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Alert } from '../svg/alert';
import { Computer } from '../svg/computer';
import { Shield } from '../svg/shield';
import { Server } from '../svg/server';
import { ComputerGroup } from '../svg/computer-group';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import { Badge } from '../badge';
import { Warning } from '../svg/warning';
import { PlayerCircleIcon } from '../players/circle-icon';
import { Binoculars } from '../svg/binoculars';
import { PlayerDonut } from '../players/donut';

@observer
export class Slide03 extends AnimatedSlide {
  // Animation players
  players = {
    computerGroup: null,
    dotsLeft: null,
    dotsRight: null,
    computer: null,
    recon: null,
    donut: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      computerGroup,
      dotsLeft,
      recon,
      dotsRight,
      computer,
      donut,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(7000);
        this.autoNext(3000);
      },
      // Step 2
      () => {
        this.autoNext(1000);
      },
      // Step 3
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: computer,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 1000,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: computer,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 100,
          translateX: -250,
        },
        {
          targets: computerGroup,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 300,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: recon,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 500,
          begin: activateShimmer,
        },
        {
          targets: dotsLeft,
          easing: 'easeOutQuad',
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: dotsRight,
          easing: 'easeOutQuad',
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },
        {
          targets: donut,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 1500,
          opacity: 1,
          begin: activate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-03 ${className}`}>
        {children}
        <div className="content">
          {/* computer */}
          <div
            className="player computerGroup"
            data-active="0"
            ref={node => (this.players.computerGroup = node)}
          >
            <ComputerGroup color={'#ffffff'} />
          </div>

          {/* computer donut */}
          <div
            className="player donut"
            data-active="0"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut className="red" />
          </div>

          {/* computer */}
          <div
            className="player computer"
            data-active="0"
            ref={node => (this.players.computer = node)}
          >
            <Card IconComponent={Computer} text={'TSMITH-DESKTOP'} />
          </div>

          {/* Animated dots left */}
          <div
            className="player dots-left"
            data-active="0"
            ref={node => (this.players.dotsLeft = node)}
          >
            <PlayerDots className={'in-out'} />
          </div>

          {/* Animated dots right */}
          <div
            className="player dots-right"
            data-active="0"
            ref={node => (this.players.dotsRight = node)}
          >
            <PlayerDots className={'in-out'} />
          </div>

          {/* Recon */}
          <div
            className="player recon"
            data-active="0"
            ref={node => (this.players.recon = node)}
          >
            <PlayerCircleIcon Icon={Binoculars} />
          </div>
        </div>
      </div>
    );
  }
}
