/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// SCSS
import '../message.scss';

// Players
import { PlayerPath } from '../players/path';
import { PlayerInfoBlock } from '../players/info-block';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Attachment } from '../svg/attachment';

@observer
export class Slide08 extends AnimatedSlide {
  // Animation players
  players = {
    card1: null,
    card2: null,
    card3: null,
    path1: null,
    path2: null,
    path3: null,
    message: null,
    alert: null,
  };

  fadeIn(target, delay) {
    return {
      targets: target,
      opacity: 1,
      easing: 'easeOutQuad',
      delay: delay,
      duration: 500,
      begin: activateShimmer,
      complete: shimmer,
    };
  }

  // Run the first animation
  componentDidMount() {
    const {
      card1,
      card2,
      card3,
      path1,
      path2,
      path3,
      message,
      alert,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(1000);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(card1, 500),
        this.fadeIn(path1, 700),
        this.fadeIn(card2, 700),
        this.fadeIn(path2, 900),
        this.fadeIn(message, 1000),
        this.fadeIn(path3, 1500),
        this.fadeIn(card3, 1700),
        this.fadeIn(alert, 2500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let offset = 200;

    return (
      <div className={`slide-08 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TRU Team */}
          <div
            className="player card1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <Card IconComponent={Person} text="Competitor" delay={800} />
          </div>
          {/* FBI */}
          <div
            className="player card2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <Card IconComponent={Person} text="FBI" delay={1000} />
          </div>
          {/* Competitor */}
          <div
            className="player card3"
            data-active="0"
            ref={node => (this.players.card3 = node)}
          >
            <Card IconComponent={Person} text="Client" delay={2100} />
          </div>
          {/* Message */}
          <div
            className="player message"
            data-active="0"
            ref={node => (this.players.message = node)}
          >
            <h3>Friday, November 5th</h3>
            <div>
              <span className="redacted">CUSTOMER</span> is in the midst of a
              potential breach, notified by{' '}
              <span className="redacted">COMPETITOR</span> and FBI about
              possible ransomware activity - CS1251718
              <br />
              <br />
              This is what <span className="redacted">COMPETITOR</span> has told
              them in the screenshot below: All I have so far but hoping someone
              can jump on here and have a look in the CB possibly.
              <br />
              <br />
              <Attachment />
            </div>
          </div>
          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            copy={0}
            ref={node => (this.players.alert = node)}
          >
            <PlayerInfoBlock
              width={340}
              sections={[
                {
                  type: 'alert',
                  content: 'Sounds like hints of SQUIRRELWAFFLE',
                },
              ]}
            />
          </div>
          {/* Path 1 */}
          <div
            className="player terminal path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,3', '50,3', '50,50', '100,50']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
              reveal={this.revealPreset(1000 + offset)}
            />
          </div>
          {/* Path 2 */}
          <div
            className="player terminal path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,50', '50,50', '50,3', '100,3']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
              reveal={this.revealPreset(1200 + offset)}
            />
          </div>
          {/* Path 3 */}
          <div
            className="player terminal path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,3', '100,3']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
              reveal={this.revealPreset(1800 + offset)}
            />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
