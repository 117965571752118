/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Triage extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 19,
      x = 0,
      y = 0,
      opacity = 1,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g opacity={opacity} fill={color}>
          <path d="M20 0H40V60H20V0Z" />
          <path d="M0 20H20V40H0V20Z" />
          <path d="M40 20H60V40H40V20Z" />
        </g>
      </svg>
    );
  }
}
