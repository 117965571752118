import classnames from 'classnames';
import * as React from 'react';
import { composeRefs } from './util/compose-refs';
import { normalizeWheel } from './util/normalize-wheel';
import { stopPropagation } from './util/stop-propagation';
import { when } from './util/when';
import './scroll-view.scss';

export enum ScrollBarSide {
  RIGHT,
  LEFT,
}

/**
 * This lets you define children for a component and forces the children to be nested as opposed to
 * being declared in the props.
 */
export type INestedChildren<T> = {
  children?: T;
};

export interface IScrollView extends INestedChildren<React.ReactNode> {
  /** When set, the scroll will have some inertia for touch controls */
  allowTouchInertia?: boolean;
  /** Provides a custom class name to the container of this component */
  className?: string;
  /**  */
  contentClassName?: string;
  /** Props to apply directly to the container div of this component */
  containerProps?: React.ClassAttributes<HTMLDivElement> & {
    ref?: React.Ref<HTMLDivElement>;
  };
  /** Props to apply directly to the scrollview content panel container */
  contentProps?: React.ClassAttributes<HTMLDivElement>;
  /** This forces a maximum amount of scroll room */
  scrollHeight?: number;
  /** Sets this scroll view to the given scroll top value */
  scrollTopOnce?: number;
  /** Determines which side the scroll bar should appear on */
  side?: ScrollBarSide;

  /** When this view changes the scroll top value it provides it in this callback */
  onScroll?(
    scrollTop: number,
    scrollMax: number,
    viewBounds: ClientRect,
    contentBounds: ClientRect
  ): void;
}

interface IState {
  isOpen: boolean;
  isDragging: boolean;
  top: number;
  bounds?: ClientRect;
  contentBounds?: ClientRect;
}

/**
 * This is a custom scroller for rendering a custom scrollbar
 */
export class ScrollView extends React.Component<IScrollView> {
  state: IState = {
    isOpen: false,
    isDragging: false,
    top: 0,
  };

  container = React.createRef<HTMLDivElement>();
  private content = React.createRef<HTMLDivElement>();
  private scrolledToOnce?: number;
  private currentScrollHeight?: number;
  private initialized: boolean = false;
  private childrenChanged: boolean = false;
  private isUpdatingChildren: boolean = false;
  private touch = {
    id: 0,
    y: 0,
    inertia: 0,
    animation: -1,
  };

  getSnapshotBeforeUpdate(prevProps: Readonly<IScrollView>) {
    if (!this.isUpdatingChildren) {
      this.childrenChanged = prevProps.children === this.props.children;
    }

    this.isUpdatingChildren = false;
    return null;
  }

  init = () => {
    const { bounds, contentBounds } = this.state;

    // Don't trigger an initial scroll event until there is something able to
    // scroll
    if (
      !bounds?.height ||
      !contentBounds?.height ||
      bounds.height >= contentBounds.height
    ) {
      return;
    }

    if (
      !this.initialized &&
      bounds &&
      contentBounds &&
      this.container.current
    ) {
      this.initialized = true;

      if (this.props.onScroll) {
        const metrics = this.getScrollMetrics();
        this.props.onScroll(
          this.container.current.scrollTop,
          metrics.scrollSpace,
          bounds,
          contentBounds
        );
      }
    }
  };

  componentDidMount() {
    this.componentDidUpdate();
    window.addEventListener('resize', this.handleResize);
    document.onwheel;

    // Initial scroll broadcast to indicate initial scroll position
    setTimeout(() => this.componentDidUpdate(), 10);

    // We HAVE to manually add the event to get around the React + Chrome issues that exists
    // with passive event listeners.
    if (this.container.current) {
      this.container.current.addEventListener('wheel', this.handleWheel, {
        passive: false,
      });

      this.container.current.addEventListener(
        'touchmove',
        this.handleTouchMove,
        {
          passive: false,
        }
      );

      this.container.current.addEventListener(
        'touchstart',
        this.handleTouchStart
      );

      this.container.current.addEventListener('touchend', this.handleTouchEnd);
    }
  }

  componentDidUpdate() {
    const { bounds, contentBounds } = this.state;

    if (
      (!this.state.bounds || this.childrenChanged) &&
      this.content.current &&
      this.container.current
    ) {
      this.childrenChanged = false;
      this.isUpdatingChildren = true;
      const bounds = this.container.current.getBoundingClientRect();
      const contentRect = this.content.current.getBoundingClientRect();
      const contentBounds = {
        left: contentRect.left,
        right: contentRect.right,
        width: contentRect.width,
        height: contentRect.height,
        bottom: contentRect.bottom,
        top: contentRect.top,
      };

      if (this.content.current.scrollHeight > contentBounds.height) {
        contentBounds.height = this.content.current.scrollHeight;
      }

      if (this.props.onScroll) {
        const metrics = this.getScrollMetrics();

        this.props.onScroll(
          this.container.current.scrollTop,
          metrics.scrollSpace,
          bounds,
          contentBounds
        );
      }

      this.setState({
        bounds,
        contentBounds,
      });
    }

    // Handle scrollToOnce application
    if (
      bounds &&
      contentBounds &&
      this.container.current &&
      this.scrolledToOnce !== this.props.scrollTopOnce
    ) {
      this.scrolledToOnce = this.props.scrollTopOnce;
      const metrics = this.getScrollMetrics();

      this.container.current.scrollTop = Math.max(
        Math.min(this.scrolledToOnce || 0, metrics.scrollSpace),
        0
      );

      this.setState({ top: this.container.current.scrollTop });

      // Broadcast for initial scroll change
      if (
        this.props.onScroll &&
        bounds &&
        contentBounds &&
        this.container.current
      ) {
        const metrics = this.getScrollMetrics();
        this.props.onScroll(
          this.container.current.scrollTop,
          metrics.scrollSpace,
          bounds,
          contentBounds
        );
      }
    }

    // Broadcast scroll metrics when the scroll height changes
    else if (
      bounds &&
      contentBounds &&
      this.container.current &&
      this.currentScrollHeight !== this.props.scrollHeight &&
      this.props.onScroll
    ) {
      this.currentScrollHeight = this.props.scrollHeight;
      const metrics = this.getScrollMetrics();
      this.props.onScroll(
        this.container.current.scrollTop,
        metrics.scrollSpace,
        bounds,
        contentBounds
      );
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);

    if (this.container.current) {
      this.container.current.removeEventListener('wheel', this.handleWheel);
      this.container.current.removeEventListener(
        'touchmove',
        this.handleTouchMove
      );
      this.container.current.removeEventListener(
        'touchstart',
        this.handleTouchStart
      );
      this.container.current.removeEventListener(
        'touchend',
        this.handleTouchEnd
      );
    }
  }

  private getScrollMetrics() {
    const { bounds, contentBounds } = this.state;
    let barHeight = 0;
    let barRatio = 1;
    let barSpace = 0;
    let scrollSpace = 0;

    if (bounds && contentBounds) {
      barRatio = bounds.height / contentBounds.height;
      barHeight = barRatio * bounds.height;
      barHeight = Math.max(barHeight, 30);
      barHeight = Math.min(barHeight, bounds.height);
    }

    if (barRatio < 1 && bounds && contentBounds) {
      barSpace = bounds.height - barHeight;
      scrollSpace = contentBounds.height - bounds.height;
    }

    return {
      barHeight,
      barRatio,
      barSpace,
      scrollSpace,
    };
  }

  handleResize = () => {
    this.setState({
      bounds: undefined,
      contentBounds: undefined,
    });
  };

  handleTouchStart = (e: TouchEvent) => {
    let touch: Touch | null = null;

    for (let i = 0, iMax = e.touches.length; i < iMax; ++i) {
      touch = e.touches.item(i);
      if (touch) {
        break;
      }
    }

    if (touch) {
      this.touch.y = touch.pageY;
      this.touch.id = touch.identifier;
    }

    this.setState({ top, isOpen: true });
    cancelAnimationFrame(this.touch.animation);
    this.touch.animation = -1;
  };

  handleTouchEnd = (e: TouchEvent) => {
    let touch: Touch | null = null;
    for (let i = 0, iMax = e.touches.length; i < iMax; ++i) {
      touch = e.touches.item(i);
      if (touch) {
        if (touch.identifier === this.touch.id) break;
        touch = null;
      }
    }

    if (!touch) {
      for (let i = 0, iMax = e.targetTouches.length; i < iMax; ++i) {
        touch = e.targetTouches.item(i);
        if (touch) {
          if (touch.identifier === this.touch.id) break;
          touch = null;
        }
      }
    }

    if (!touch) {
      for (let i = 0, iMax = e.changedTouches.length; i < iMax; ++i) {
        touch = e.changedTouches.item(i);
        if (touch) {
          if (touch.identifier === this.touch.id) break;
          touch = null;
        }
      }
    }

    if (!touch) return;
    this.setState({ top, isOpen: false });

    if (Math.abs(this.touch.inertia) > 0 && this.container.current) {
      const { bounds, contentBounds } = this.state;
      const current = this.container.current;
      cancelAnimationFrame(this.touch.animation);

      const animateInertia = () => {
        current.scrollTop += this.touch.inertia;
        const metrics = this.getScrollMetrics();
        current.scrollTop = Math.min(current.scrollTop, metrics.scrollSpace);

        this.setState({
          top: current.scrollTop,
        });

        if (this.props.onScroll && bounds && contentBounds) {
          this.props.onScroll(
            current.scrollTop,
            metrics.scrollSpace,
            bounds,
            contentBounds
          );
        }

        this.touch.inertia *= 0.95;
        if (Math.abs(this.touch.inertia) > 0.001) {
          requestAnimationFrame(animateInertia);
        } else this.touch.animation = -1;
      };

      this.touch.animation = requestAnimationFrame(animateInertia);
    }
  };

  handleTouchMove = (e: TouchEvent) => {
    if (!(e.currentTarget instanceof HTMLDivElement)) return;

    const { bounds, contentBounds, isOpen } = this.state;

    let touch: Touch | null = null;
    for (let i = 0, iMax = e.touches.length; i < iMax; ++i) {
      touch = e.touches.item(i);
      if (touch) {
        if (touch.identifier === this.touch.id) break;
      }
    }

    if (!touch) return;

    e.currentTarget.scrollTop += this.touch.y - touch.pageY;
    this.touch.inertia = this.touch.y - touch.pageY;
    if (!this.props.allowTouchInertia) this.touch.inertia = 0;
    this.touch.y = touch.pageY;
    const metrics = this.getScrollMetrics();

    e.currentTarget.scrollTop = Math.min(
      e.currentTarget.scrollTop,
      metrics.scrollSpace
    );

    this.setState({
      top: e.currentTarget.scrollTop,
    });

    if (this.props.onScroll && bounds && contentBounds) {
      this.props.onScroll(
        e.currentTarget.scrollTop,
        metrics.scrollSpace,
        bounds,
        contentBounds
      );
    }

    if (isOpen && bounds && metrics.barRatio < 1) {
      stopPropagation(e);
    }

    cancelAnimationFrame(this.touch.animation);
  };

  handleWheel = (e: MouseWheelEvent) => {
    if (!(e.currentTarget instanceof HTMLDivElement)) return;
    const { bounds, contentBounds, isOpen } = this.state;
    const wheel = normalizeWheel(e);
    e.currentTarget.scrollTop -= wheel.pixelY;
    const metrics = this.getScrollMetrics();

    e.currentTarget.scrollTop = Math.min(
      e.currentTarget.scrollTop,
      metrics.scrollSpace
    );

    this.setState({
      top: e.currentTarget.scrollTop,
    });

    if (this.props.onScroll && bounds && contentBounds) {
      this.props.onScroll(
        e.currentTarget.scrollTop,
        metrics.scrollSpace,
        bounds,
        contentBounds
      );
    }

    if (isOpen && bounds && metrics.barRatio < 1) {
      stopPropagation(e);
    }
  };

  handleBarDown = (e: React.MouseEvent<HTMLDivElement>) => {
    const { bounds, contentBounds } = this.state;
    // Once the bar is mouse downed, we begin to start the dragging process which
    // should be registered across the whole document
    const metrics = this.getScrollMetrics();

    // We must calculate how much one pixel of movement would scroll the content
    const pixelScroll = metrics.scrollSpace / metrics.barSpace;
    let startY = e.nativeEvent.clientY;

    this.setState({
      isDragging: true,
    });

    const mousemove = (e: MouseEvent) => {
      const newY = e.clientY;
      const deltaY = newY - startY;
      startY = newY;

      if (this.container.current) {
        this.container.current.scrollTop += deltaY * pixelScroll;

        this.container.current.scrollTop = Math.min(
          this.container.current.scrollTop,
          metrics.scrollSpace
        );

        this.setState({
          top: this.container.current.scrollTop,
        });
      }

      // Prevent any event bubbling to prevent any default dragging behaviorss
      if (e.stopPropagation) e.stopPropagation();
      if (e.preventDefault) e.preventDefault();
      e.cancelBubble = true;
      e.returnValue = false;

      // Make sure the scroll info is broadcast still
      if (
        this.props.onScroll &&
        bounds &&
        contentBounds &&
        this.container.current
      ) {
        this.props.onScroll(
          this.container.current.scrollTop,
          metrics.scrollSpace,
          bounds,
          contentBounds
        );
      }

      return false;
    };

    const mouseup = () => {
      document.removeEventListener('mousemove', mousemove);
      document.removeEventListener('mouseup', mouseup);

      this.setState({
        isDragging: false,
      });
    };

    document.addEventListener('mousemove', mousemove);
    document.addEventListener('mouseup', mouseup);
  };

  render() {
    const { isOpen, isDragging, bounds, top } = this.state;
    const {
      className,
      contentClassName,
      containerProps,
      contentProps,
      children,
      scrollHeight,
      side = ScrollBarSide.RIGHT,
    } = this.props;
    let barTop = top;
    const metrics = this.getScrollMetrics();
    barTop += (top / metrics.scrollSpace) * metrics.barSpace;

    if (isNaN(barTop)) {
      barTop = 0;
    }

    return (
      <div
        className={classnames('ScrollView', className)}
        {...containerProps}
        ref={composeRefs(this.container, containerProps && containerProps.ref)}
        onMouseOver={() => {
          this.setState({ top, isOpen: true });
        }}
        onMouseOut={e => {
          const event = e.nativeEvent;
          let node: Element | null =
            (event as any).toElement || event.relatedTarget;

          while (node) {
            if (
              node.parentNode === this.container.current ||
              node === this.container.current
            ) {
              return;
            }

            node = node.parentElement;
          }

          this.setState({ top, isOpen: false });
        }}
      >
        <div
          ref={this.content}
          className={classnames('ScrollView__Content', contentClassName)}
          style={{
            height: scrollHeight,
            flex: `1 1 ${scrollHeight}px`,
            minHeight: scrollHeight,
          }}
          {...contentProps}
        >
          {children}
        </div>
        <div
          className="ScrollView__Bar"
          style={{
            top: barTop,
            height: when(
              ((isOpen || this.touch.animation >= 0) &&
                bounds &&
                metrics.barRatio < 1) ||
                isDragging,
              metrics.barHeight,
              0
            ),
            opacity: when(
              ((isOpen || this.touch.animation >= 0) &&
                bounds &&
                metrics.barRatio < 1) ||
                isDragging,
              1,
              0
            ),
            left: when(side === ScrollBarSide.LEFT, '0px'),
            right: when(side === ScrollBarSide.RIGHT, '0px'),
          }}
          onMouseDown={this.handleBarDown}
        />
      </div>
    );
  }
}
