/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './demo-goals.scss';
import { Check } from '../svg/check';

@observer
export class PlayerDemoGoals extends Component {
  render() {
    const {
      children,
      className = '',
      title = '',
      goals = [],
      size = 20,
      color = '#50C2E3',
    } = this.props;

    return (
      <div className="DemoGoals">
        <div className="DemoGoals__Title">{title}</div>
        <div className="DemoGoals__Line"></div>
        {goals.map((text, index) => {
          return (
            <div key={index} className="DemoGoals__Goal">
              <Check size={size} color={color} />
              <div>{text}</div>
            </div>
          );
        })}
      </div>
    );
  }
}
