/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class EmailAlert extends Component {
  render() {
    const {
      className = '',
      color = '#C53D43',
      size = 74,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 71 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.8578 0.742544C61.0178 0.283436 60.0701 0 59.0485 0H5.90423C4.88267 0 3.93493 0.283436 3.09644 0.742544L32.4764 18.9312L61.8578 0.742544Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.3324 23.6104C48.162 21.9511 49.8228 20.9236 51.6681 20.9236C53.5133 20.9236 55.1726 21.9481 55.9993 23.5985L64.9541 41.641V5.90593C64.9541 4.78104 64.6205 3.7403 64.0728 2.8457L33.2536 21.923C33.0159 22.0706 32.7472 22.1445 32.4771 22.1445C32.2069 22.1445 31.9382 22.0706 31.7006 21.923L0.881309 2.8457C0.333628 3.7403 0 4.78104 0 5.90593V38.383C0 41.6381 2.64836 44.2879 5.90492 44.2879H37.0696L47.3324 23.6104Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.668 55.436C50.7793 55.436 50.0574 54.7156 50.0574 53.8254C50.0574 52.9367 50.7793 52.2149 51.668 52.2149C52.5566 52.2149 53.2785 52.9367 53.2785 53.8254C53.2785 54.7156 52.5566 55.436 51.668 55.436ZM50.1917 38.1139C50.1917 37.2976 50.8531 36.6377 51.668 36.6377C52.4828 36.6377 53.1442 37.2976 53.1442 38.1139V47.7758C53.1442 48.5907 52.4828 49.2521 51.668 49.2521C50.8531 49.2521 50.1917 48.5907 50.1917 47.7758V38.1139ZM70.8162 60.0914L70.5874 59.6293L62.9715 44.2839L53.3568 24.9128C53.1162 24.433 52.691 24.0876 52.1906 23.9444C52.0238 23.8972 51.8481 23.8721 51.668 23.8721C50.9476 23.8721 50.2995 24.2751 49.9748 24.9246L40.366 44.2839L32.6305 59.8655C32.4032 60.3246 32.4298 60.8664 32.6985 61.3004C32.9686 61.7359 33.4425 61.9987 33.9532 61.9987H69.3828C69.3916 61.9987 69.402 61.9987 69.4123 61.9987C70.2272 61.9987 70.8885 61.3388 70.8885 60.5224C70.8885 60.3719 70.859 60.2287 70.8162 60.0914Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
