/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Computer extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 70,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 70 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 6.2012H62V40.2726H9V6.2012ZM4.47727 46.4674H66.5227C67.3397 46.4674 68 45.7736 68 44.9187V6.20115C68 2.78472 65.3498 0.00634766 62.0909 0.00634766H8.90909C5.65023 0.00634766 3 2.78472 3 6.20115V44.9187C3 45.7736 3.66034 46.4674 4.47727 46.4674Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.5417 48.5325H42.2917C41.4852 48.5325 40.8333 49.2263 40.8333 50.0812V51.6299H29.1667V50.0812C29.1667 49.2263 28.5148 48.5325 27.7083 48.5325H1.45833C0.651875 48.5325 0 49.2263 0 50.0812V56.276C0 58.8375 1.96292 60.9221 4.375 60.9221H65.625C68.0371 60.9221 70 58.8375 70 56.276V50.0812C70 49.2263 69.3481 48.5325 68.5417 48.5325Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
