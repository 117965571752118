/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Refresh extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 32,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 32 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.756 0.673337C10.8909 0.90622 5.7514 4.66873 3.75733 10.1893C3.70419 10.3386 3.55263 10.4292 3.39599 10.4053L1.14399 10.0733C0.892586 10.0417 0.644136 10.1508 0.497326 10.3573C0.352971 10.5684 0.342092 10.8435 0.469326 11.0653L4.04133 17.332C4.14436 17.5119 4.32522 17.6336 4.53066 17.6613C4.5604 17.6634 4.59025 17.6634 4.61999 17.6613C4.796 17.6609 4.96471 17.591 5.08933 17.4667L10.116 12.472C10.2947 12.2942 10.3578 12.0308 10.2791 11.7913C10.2004 11.5518 9.99335 11.3771 9.74399 11.34L7.45733 11C7.35409 10.9844 7.26403 10.9216 7.21381 10.83C7.1636 10.7385 7.15898 10.6288 7.20133 10.5333C9.39974 5.55596 14.9039 2.93044 20.1557 4.35407C25.4074 5.77771 28.832 10.8236 28.2156 16.2299C27.5993 21.6361 23.1267 25.7817 17.6893 25.9867C14.0442 26.1299 10.5667 24.4499 8.41333 21.5053C7.85004 20.8236 6.85241 20.7005 6.14031 21.2249C5.42822 21.7492 5.24959 22.7384 5.73333 23.4787C8.43366 27.1586 12.7249 29.3327 17.2893 29.3333C17.4653 29.3333 17.6427 29.3333 17.8227 29.3227C22.9626 29.1649 27.6242 26.2661 30.0389 21.726C32.4536 17.1858 32.2514 11.7001 29.509 7.35007C26.7666 3.00004 21.9042 0.452245 16.7667 0.673337H16.756Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
