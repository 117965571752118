/** @jsx createElement */
import { createElement, Component } from 'react';
import './input.scss';

export class Input extends Component {
  onBlur = () => {
    // If nested in a popup, remove focus from select,
    // but apply to the container to maintain focus on ascendants
    this.props.nested && this.container && this.container.focus();
  };

  onChange = e => {
    this.props.onChange(e.currentTarget.value, e);
  };

  render() {
    const {
      className = '',
      label = '',
      value = '',
      type = 'text',
      width = null,
    } = this.props;

    return (
      <div
        className={`Input ${className}`}
        ref={node => (this.container = node)}
      >
        <span className={`Input__Caption`}>{label}</span>
        <label className={`Input__Label`}>
          <input
            type={type}
            className={`Input__Input`}
            value={value}
            onChange={this.onChange}
            onBlur={this.onBlur}
            style={{
              width: width ? `${width}px` : '100%',
            }}
          />
        </label>
      </div>
    );
  }
}
