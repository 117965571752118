/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes, createRef } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// SVG
import { Code2 } from '../svg/code2';
import { Page } from '../svg/page';
import { Cloud } from '../svg/cloud';
import { Computer } from '../svg/computer';
import { Shield } from '../svg/shield';
import { Squirrel } from '../svg/squirrel';
import { TRU } from '../svg/tru';

// PLAYERS
import { PlayerPath } from '../players/path';
import { PlayerReveal } from '../players/reveal';
import { PlayerSignals } from '../players/signals';
import { PlayerNetworkScan } from '../players/network-scan';
@observer
export class Slide03 extends AnimatedSlide {
  children = {
    gradient1: createRef(),
    gradient2: createRef(),
    gradient3: createRef(),
  };

  // Animation players
  players = {
    shield: null,
    network: null,
    path1: null,
    path2: null,
    path3: null,
    reveal: null,
    signals: null,
  };

  changeGradients() {
    if (!this.children.gradient3.current) return;
    this.children.gradient3.current.change();
  }

  fadeIn(target, delay = 0) {
    return {
      targets: target,
      opacity: 1,
      easing: 'easeOutQuad',
      duration: 200,
      delay: delay,
      begin: activate,
      complete: activateShimmer,
    };
  }

  // Run the first animation
  componentDidMount() {
    const {
      network,
      shield,
      reveal,
      signals,
      path1,
      path2,
      path3,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(11000);
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(network, 0),
        this.fadeIn(path1, 200),
        this.fadeIn(signals, 500),
        this.fadeIn(path2, 700),
        this.fadeIn(shield, 1000),
        this.fadeIn(path3, 1200),
        this.fadeIn(reveal, 1500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let whiteDots1 = {
      stroke: this.colors.white,
      opacity: 0.5,
      animationDuration: '25s',
    };
    let whiteDots2 = {
      stroke: this.colors.white,
      opacity: 0.6,
      animationDuration: '20s',
    };
    let whiteDots3 = {
      stroke: this.colors.white,
      opacity: 0.7,
      animationDuration: '15s',
    };
    let whiteDots4 = {
      stroke: this.colors.white,
      opacity: 0.8,
      animationDuration: '10s',
    };

    return (
      <div className={`slide-03 ${className}`}>
        {children}
        <div className="content">
          {/*---------- CONTENT START ----------*/}
          {/* Network */}
          <div
            className="player network"
            data-active="0"
            ref={node => (this.players.network = node)}
          >
            <div className="player-title">
              PROACTIVE THREAT HUNT
              <br />
              ACROSS 1200+ CUSTOMERS
            </div>
            <PlayerNetworkScan columns={6} rows={6} width={180} gap={6} />
          </div>
          {/* Reveal */}
          <div
            className="player reveal"
            data-active="0"
            ref={node => (this.players.reveal = node)}
          >
            <PlayerReveal
              IconComponent={Squirrel}
              text={'SQUIRRELWAFFLE'}
              width={160}
              height={160}
              alert={true}
              pixels={{
                color: this.colors.blue,
              }}
              styles={{
                border: `4px solid ${this.colors.blue}`,
              }}
              complete={() => this.changeGradients()}
            />
          </div>
          {/* SHIELD */}
          <div
            className="player shield"
            data-active="0"
            ref={node => (this.players.shield = node)}
          >
            <Shield
              IconComponent={TRU}
              IconSize={50}
              IconOpacity={1}
              text={'TRU TEAM'}
              stroke={this.colors.blue}
              strokeOpacity={1}
            />
          </div>
          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,3', '56,3']}
              dots={whiteDots1}
              reveal={{ delay: 700, duration: 800 }}
            />
            <PlayerPath
              points={['0,3', '54,3']}
              dots={whiteDots2}
              reveal={{ delay: 700, duration: 700 }}
            />
            <PlayerPath
              points={['0,3', '52,3']}
              dots={whiteDots3}
              reveal={{ delay: 700, duration: 600 }}
            />
            <PlayerPath
              points={['0,3', '50,3']}
              dots={whiteDots4}
              reveal={{ delay: 700, duration: 500 }}
            />
            <PlayerPath
              points={['0,3', '52,3']}
              dots={whiteDots3}
              reveal={{ delay: 700, duration: 600 }}
            />
            <PlayerPath
              points={['0,3', '54,3']}
              dots={whiteDots2}
              reveal={{ delay: 700, duration: 700 }}
            />
            <PlayerPath
              points={['0,3', '56,3']}
              dots={whiteDots1}
              reveal={{ delay: 700, duration: 800 }}
            />
          </div>
          {/* Signals */}
          <div
            className="player signals"
            data-active="0"
            ref={node => (this.players.signals = node)}
          >
            <div className="player-title">SIGNALS</div>
            <PlayerSignals delay={1000} />
          </div>
          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,3', '50,3']}
              preset={'roundCloser'}
              dots={{
                animationDuration: '10s',
                stroke: this.colors.blue,
                opacity: 1,
              }}
              reveal={{
                delay: 1000,
                strokeWidth: 4,
                radius: 4,
              }}
              ref={this.children.gradient2}
            />
          </div>
          {/* Path 3 */}
          <div
            className="player path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,3', '50,3']}
              preset={'roundCloser'}
              gradient={{
                preset: 'blueBlue',
              }}
              dots={{
                opacity: 1,
                animationDuration: '15s',
              }}
              gradientChange={{
                direction: 'right',
                preset: 'blueRed',
              }}
              reveal={{
                delay: 2000,
                strokeWidth: 4,
                radius: 4,
              }}
              ref={this.children.gradient3}
            />
          </div>
          {/*---------- CONTENT END ----------*/}
        </div>
      </div>
    );
  }
}
