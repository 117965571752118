/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// Players
import { PlayerInfoBlock } from '../players/info-block';
import { PlayerReveal } from '../players/reveal';
import { PlayerPath } from '../players/path';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Log4j } from '../svg/log4j';

@observer
export class Slide03 extends AnimatedSlide {
  children = {
    gradient1: createRef(),
    gradient2: createRef(),
    gradient3: createRef(),
    gradient4: createRef(),
  };

  // Animation players
  players = {
    card: null,
    info: null,
    reveal: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
  };

  changeGradients() {
    if (!this.children.gradient2.current) return;
    this.children.gradient2.current.change();
    this.children.gradient3.current.change();
    this.children.gradient4.current.change();
  }

  // Run the first animation
  componentDidMount() {
    const { card, info, reveal, path1, path2, path3, path4 } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(1500);
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(card, 0),
        this.fadeIn(path1, 200),
        this.fadeIn(info, 500),
        this.fadeIn(reveal, 1000),
        this.fadeIn(path2, 1000),
        this.fadeIn(path3, 1200),
        this.fadeIn(path4, 1400),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-03 ${className}`}>
        {children}
        <div className="content">
          {/* -------------------CONTENT------------------- */}
          {/* Card */}
          <div
            className="player card"
            data-active="0"
            ref={node => (this.players.card = node)}
          >
            <Card IconComponent={Person} text={'TRU TEAM'} delay={200} />
          </div>
          {/* Info Review */}
          <div
            className="player info"
            data-active="0"
            ref={node => (this.players.info = node)}
          >
            <PlayerInfoBlock
              gap={10}
              width={320}
              animation={{
                delay: 500,
              }}
              sections={[
                {
                  type: 'header',
                  color: 'red',
                  content: 'APACHE ZERO-DAY VULNERABILITY',
                },
                {
                  type: 'grid',
                  content: 'CVE-2021-4428',
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'REMOTE CODE' },
                    {
                      preset: 'red',
                      html: 'LOG4SHELL/lOGJAM',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'CERT NZ' },
                    {
                      preset: 'red',
                      html: 'POC EXPLOIT CODE',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'REAL-WORLD DETAILS' },
                    {
                      preset: 'red',
                      html: 'N/A',
                    },
                  ],
                },
              ]}
            />
          </div>
          {/* Reveal */}
          <div
            className="player reveal"
            data-active="0"
            ref={node => (this.players.reveal = node)}
          >
            <PlayerReveal
              IconComponent={Log4j}
              text={'Log4j'}
              width={160}
              height={160}
              alert={true}
              pixels={{
                color: this.colors.blue,
              }}
              styles={{
                border: `4px solid ${this.colors.blue}`,
              }}
              complete={() => this.changeGradients()}
            />
          </div>
          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,160', '50,160', '50,3', '100,3']}
              gradient={{
                direction: 270,
                preset: 'whiteRed',
              }}
              gradientChange={{
                preset: 'redRed',
              }}
              reveal={{
                delay: 500,
              }}
              dots={{
                strokeWidth: 2,
                strokeDashArray: '2,2',
              }}
              data={false}
              ref={this.children.gradient1}
            />
          </div>
          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,3', '80,3', '110,40', '170,40']}
              gradient={{
                direction: 'left',
                preset: 'blueRed',
              }}
              gradientChange={{
                preset: 'redRed',
              }}
              dots={{
                strokeWidth: 2,
                strokeDashArray: '2,2',
              }}
              reveal={{
                delay: 1400,
              }}
              data={false}
              ref={this.children.gradient2}
            />
          </div>
          {/* Path 3 */}
          <div
            className="player path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,3', '85,3', '115,40', '210,40']}
              gradient={{
                direction: 'left',
                preset: 'blueRed',
              }}
              gradientChange={{
                preset: 'redRed',
              }}
              dots={{
                strokeWidth: 2,
                strokeDashArray: '2,2',
              }}
              reveal={{
                delay: 1600,
              }}
              data={false}
              ref={this.children.gradient3}
            />
          </div>
          {/* Path 4 */}
          <div
            className="player path4"
            data-active="0"
            ref={node => (this.players.path4 = node)}
          >
            <PlayerPath
              points={['0,3', '60,3', '90,40', '220,40']}
              gradient={{
                direction: 'left',
                preset: 'blueRed',
              }}
              gradientChange={{
                preset: 'redRed',
              }}
              dots={{
                strokeWidth: 2,
                strokeDashArray: '2,2',
              }}
              reveal={{
                delay: 1800,
              }}
              data={false}
              ref={this.children.gradient4}
            />
          </div>
          {/* -------------------CONTENT------------------- */}
        </div>
      </div>
    );
  }
}
