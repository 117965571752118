/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class XLS extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 0.3,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.8858 10.0907H3.11419C2.7702 10.0907 2.49135 10.3693 2.49135 10.713V50.1291C2.49135 50.4728 2.7702 50.7514 3.11419 50.7514H56.8858C57.2298 50.7514 57.5087 50.4728 57.5087 50.1291V10.713C57.5087 10.3693 57.2298 10.0907 56.8858 10.0907ZM3.11419 7.60123C1.39427 7.60123 0 8.99443 0 10.713V50.1291C0 51.8477 1.39427 53.2409 3.11419 53.2409H56.8858C58.6057 53.2409 60 51.8477 60 50.1291V10.713C60 8.99443 58.6057 7.60123 56.8858 7.60123H3.11419Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M34.0484 2L0 8.01614V52.826L34.0484 58.8421V2ZM8.92734 20.9819L13.0796 20.6708L15.8824 27.7242L19.1003 20.2559L23.564 19.9447L18.2699 29.9024L23.564 40.0676L18.8927 39.8602L15.6747 32.1844L12.5606 39.3415L8.51211 39.1341L13.391 29.9024L8.92734 20.9819Z"
          />
          <path d="M44.4291 13.4099H53.7716V18.5962H44.4291V13.4099Z" />
          <path d="M33.0104 13.4099H42.3529V18.5962H33.0104V13.4099Z" />
          <path d="M44.4291 20.6708H53.7716V25.8571H44.4291V20.6708Z" />
          <path d="M33.0104 20.6708H42.3529V25.8571H33.0104V20.6708Z" />
          <path d="M44.4291 27.9316H53.7716V33.1179H44.4291V27.9316Z" />
          <path d="M33.0104 27.9316H42.3529V33.1179H33.0104V27.9316Z" />
          <path d="M44.4291 35.1925H53.7716V40.3788H44.4291V35.1925Z" />
          <path d="M33.0104 35.1925H42.3529V40.3788H33.0104V35.1925Z" />
          <path d="M44.4291 42.4533H53.7716V47.6396H44.4291V42.4533Z" />
          <path d="M33.0104 42.4533H42.3529V47.6396H33.0104V42.4533Z" />
        </g>
      </svg>
    );
  }
}
