/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class InternetExplorer extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 35,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 33 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.3536 1.17955C24.8585 1.17955 20.8108 4.06529 20.8108 4.06529C20.8108 4.06529 32.2647 5.96568 31.3568 19.1991H12.4297C12.4297 19.1991 12.1504 24.3378 17.0394 24.3378C20.6011 24.3378 21.2996 21.0995 21.2996 21.0995H31.2173C31.2173 21.0995 28.5631 31.0243 17.3885 31.0243C14.7345 31.0243 13.7568 30.8133 13.7568 30.8133C13.7568 30.8133 9.21713 34.4422 4.74722 34.4422C0.277309 34.4422 -0.141881 29.5458 0.486842 27.3641L0.595943 26.9745C1.38687 24.12 4.16003 14.1131 14.2457 7.16295V6.66979C14.2457 6.66979 7.05197 9.69615 1.88369 17.1581C1.88369 17.1581 1.67416 9.90709 8.02978 5.82506C13.8615 2.27037 19.6932 3.85436 19.6932 3.85436C19.6932 3.85436 24.5823 0.264512 28.4933 0.264512C28.4933 0.264512 34.5893 -0.309706 31.4508 7.89732L31.2738 7.71959C31.2738 7.71959 33.8932 1.17955 28.3536 1.17955ZM17.0204 10.2743C21.5869 10.2743 21.4072 14.6952 21.4072 14.6952H12.4057C12.4057 14.6952 12.2916 10.2743 17.0204 10.2743ZM13.2026 30.6678C6.59985 29.1796 4.34439 24.9637 4.34439 24.9637C4.34439 24.9637 1.67886 29.0575 3.31915 31.9491C6.38122 34.8397 13.2026 30.6678 13.2026 30.6678Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
