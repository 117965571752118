/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { Router } from 'vega-router';
import { CurrencyCell } from '../../currency-cell';
import { KeyValueSet } from '../../key-value-set';
import { SOCCalculatorState } from '../soc-calculator-actions';
import { Popup } from '../../popup';
import { Info } from '../../svg/info';
import './detail.scss';

@observer
export class SOCCalculatorDetail extends Router {
  render() {
    const { className = '' } = this.props;

    return (
      <div className={`SOCCalculatorDetail ${className}`}>
        <div className="SOCCalculatorDetail__Savings">
          {SOCCalculatorState.yearTotalSavings}% Savings
        </div>
        <div className="SOCCalculatorDetail__Section SOCCalculatorDetail__SecurityTools">
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              Security Tools
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Next-Gen Antivirus (EPP)
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Endpoint Forensics (EDR)
            </div>
            <div className="SOCCalculatorDetail__SectionCell">IDS/IPS</div>
            <div className="SOCCalculatorDetail__SectionCell">
              Siem Platform
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Vulnerability Scanning / MGMT
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Threat Intel Feeds
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              Year 1
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1PreventionEndpoints}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1EdrEndpoints} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Sensors} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1DailyGbIngest} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1IpAddresses} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Feeds} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              Year 2
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1PreventionEndpoints}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1EdrEndpoints} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Sensors} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1DailyGbIngest} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1IpAddresses} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Feeds} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              Year 3
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1PreventionEndpoints}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1EdrEndpoints} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Sensors} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1DailyGbIngest} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1IpAddresses} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Feeds} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              Totals
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1PreventionEndpoints * 3}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1EdrEndpoints * 3} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Sensors * 3} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1DailyGbIngest * 3} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1IpAddresses * 3} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Feeds * 3} />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorDetail__Section SOCCalculatorDetail__SecurityOperations">
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              <div>
                Sec Ops Tools Per Analyst{' '}
                <Popup position="top-left" trigger={<Info />} width={400}>
                  Security Operations Tools Include:
                  <p>
                    Workflow / Automation
                    <br />
                    Investigation Tools
                    <br />
                    Response Tools
                    <br />
                    Threat Management
                    <br />
                    Total Cost of Operations Tools
                  </p>
                </Popup>
              </div>
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Total Cost of Operations Tools
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1OperationTools} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1OperationTools} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1OperationTools} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1OperationTools * 3}
              />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorDetail__Section SOCCalculatorDetail__Personnel">
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              Personnel
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Core Analyst 24x7 Coverage
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Security Operations Manager
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Intelligence Analysts
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Intelligence Managers
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Network Security Engineer
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Network Security Administrator
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Analysts} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1SecOpsManagers} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1IntelligenceAnalysts}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1IntelligenceManagers}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Engineers} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Admins} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Analysts} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1SecOpsManagers} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1IntelligenceAnalysts}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1IntelligenceManagers}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Engineers} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Admins} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Analysts} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1SecOpsManagers} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1IntelligenceAnalysts}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1IntelligenceManagers}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Engineers} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Admins} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Analysts * 3} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1SecOpsManagers * 3}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1IntelligenceAnalysts * 3}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1IntelligenceManagers * 3}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Engineers * 3} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Admins * 3} />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorDetail__Section SOCCalculatorDetail__OperationalExpenses">
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              Operational Expenses
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Product Maintenance (Labor)
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Implementation &amp; Integration
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              Product Maintenance (Hardware)
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1MaintenanceLabor} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1Implementation} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1MaintenanceHardware}
              />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1MaintenanceLabor} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={0} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1MaintenanceHardware}
              />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1MaintenanceLabor} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={0} />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1MaintenanceHardware}
              />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__SectionHeader">
              &nbsp;
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1MaintenanceLabor * 3}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1Implementation * 1}
              />
            </div>
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell
                value={SOCCalculatorState.year1MaintenanceHardware * 3}
              />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorDetail__Section SOCCalculatorDetail__InHouseCost">
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__LargeText">
              Total 24x7 In-House Costs
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1InHouse} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year2InHouse} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year2InHouse} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn SOCCalculatorDetail__SectionColumn--blue">
            <div className="SOCCalculatorDetail__SectionCell">
              <KeyValueSet
                set={[
                  {
                    key: '3-Year Total',
                    value: SOCCalculatorState.yearTotalInHouse,
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorDetail__Section SOCCalculatorDetail__EsentireCost">
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell SOCCalculatorDetail__LargeText SOCCalculatorDetail__WhiteText">
              Esentire Cost
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year1EsentireCost} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year2EsentireCost} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn">
            <div className="SOCCalculatorDetail__SectionCell">
              <CurrencyCell value={SOCCalculatorState.year3EsentireCost} />
            </div>
          </div>
          <div className="SOCCalculatorDetail__SectionColumn SOCCalculatorDetail__SectionColumn--white">
            <div className="SOCCalculatorDetail__SectionCell">
              <KeyValueSet
                set={[
                  {
                    key: '3-Year Total',
                    value: SOCCalculatorState.yearTotalEsentireCost.toString(),
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
