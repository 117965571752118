/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Thumb extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd" clipRule="evenodd">
          <path d="M60 26.2925C60 23.535 57.7575 21.2925 55 21.2925H39.3312C40.3937 18.4 42.2112 12.5887 41.2037 9.00125C39.64 3.4325 36.38 3 35.4187 3C33.0812 3 31.25 4.995 31.25 7.5425C31.25 16.815 22.9287 27.5425 17.5 27.5425C16.81 27.5425 16.25 28.1025 16.25 28.7925V50.0425C16.25 50.665 16.7075 51.1925 17.3225 51.28C20.8525 51.785 22.945 52.275 24.79 52.71C27.4663 53.3388 29.3988 53.7925 33.75 53.7925H47.5C50.8175 53.7925 52.5 52.53 52.5 50.0425C52.5 49.0137 52.1875 48.0575 51.6537 47.2612C53.6 46.5775 55 44.72 55 42.5425C55 41.5138 54.6875 40.5575 54.1537 39.7612C56.1 39.0775 57.5 37.22 57.5 35.0425C57.5 33.975 57.1637 32.985 56.5912 32.1712C58.69 31.1637 60 28.31 60 26.2925Z" />
          <path d="M8.75 51.2925C8.06 51.2925 7.5 50.7325 7.5 50.0425C7.5 49.3525 8.06 48.7925 8.75 48.7925C9.44 48.7925 10 49.3525 10 50.0425C10 50.7325 9.44 51.2925 8.75 51.2925ZM13.75 23.7925H1.25C0.56 23.7925 0 24.3525 0 25.0425V55.0425C0 55.7325 0.56 56.2925 1.25 56.2925H13.75C14.44 56.2925 15 55.7325 15 55.0425V25.0425C15 24.3525 14.44 23.7925 13.75 23.7925Z" />
        </g>
      </svg>
    );
  }
}
