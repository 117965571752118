/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Eye extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 52,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 52 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6667 0C14 0 4.03667 7.25667 0 17.5C4.03667 27.7433 14 35 25.6667 35C37.3333 35 47.2967 27.7433 51.3333 17.5C47.2967 7.25667 37.3333 0 25.6667 0ZM25.6667 29.1667C19.2267 29.1667 14 23.94 14 17.5C14 11.06 19.2267 5.83333 25.6667 5.83333C32.1067 5.83333 37.3333 11.06 37.3333 17.5C37.3333 23.94 32.1067 29.1667 25.6667 29.1667ZM25.6667 10.5C21.7933 10.5 18.6667 13.6267 18.6667 17.5C18.6667 21.3733 21.7933 24.5 25.6667 24.5C29.54 24.5 32.6667 21.3733 32.6667 17.5C32.6667 13.6267 29.54 10.5 25.6667 10.5Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
