/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import './slide-process-location.scss';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

// Icons
import { Page } from '../svg/page';

@observer
export class Slide12 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    binary: null,
    paths: null,
    hosts: null,
    page: null,
  };

  // Run the first animation
  componentDidMount() {
    const { donut, binary, paths, hosts, page } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(3000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: donut,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
        },
        {
          targets: binary,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: [-100, 0],
          duration: 200,
          delay: 500,
          begin: activateShimmer,
        },
        {
          targets: paths,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: [-100, 0],
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },
        {
          targets: hosts,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: [-100, 0],
          duration: 200,
          delay: 1500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`PayloadAnalysis ${className}`}>
        {children}
        <div className="content">
          {/* Donut */}
          <div
            className="player donut"
            data-active="1"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>

          {/* Page */}
          <div
            className="player page"
            data-active="1"
            ref={node => (this.players.page = node)}
          >
            <Page size={'100'} />
          </div>

          {/* Binary data */}
          <div
            className="player binary"
            data-active="0"
            ref={node => (this.players.binary = node)}
          >
            <PlayerTextBlock
              title={'Binary data'}
              width={'240px'}
              sections={[
                {
                  title: '941BEB4B319484820C82FBBEF9FDA',
                },
                {
                  title: [
                    '238789.exe',
                    '516721.exe',
                    'dhcpsrv.exe',
                    'screenmgmt.exe',
                  ],
                  grid: 2,
                },
              ]}
            />
          </div>

          <div
            className="player paths"
            data-active="0"
            ref={node => (this.players.paths = node)}
          >
            <PlayerTextBlock
              title={'Observed paths'}
              width={'240px'}
              sections={[
                {
                  lines: 2,
                },
              ]}
            />
          </div>

          {/* Process text box */}
          <div
            className="player hosts"
            data-active="0"
            ref={node => (this.players.hosts = node)}
          >
            <PlayerTextBlock
              title={'Hosts + Sensor IDS'}
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
