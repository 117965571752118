import { Slide01 } from '../../../components/waffle/slide-01';
import { Slide02 } from '../../../components/waffle/slide-02';
import { Slide03 } from '../../../components/waffle/slide-03';
import { Slide04 } from '../../../components/waffle/slide-04';
import { Slide05 } from '../../../components/waffle/slide-05';
import { Slide06 } from '../../../components/waffle/slide-06';
import { Slide07 } from '../../../components/waffle/slide-07';
import { Slide08 } from '../../../components/waffle/slide-08';
import { Slide09 } from '../../../components/waffle/slide-09';
import { Slide10 } from '../../../components/waffle/slide-10';
import { Slide11 } from '../../../components/waffle/slide-11';
import { Slide12 } from '../../../components/waffle/slide-12';
import { Slide13 } from '../../../components/waffle/slide-13';
import { Slide14 } from '../../../components/waffle/slide-14';
import { Slide15 } from '../../../components/waffle/slide-15';

import { Bug } from '../../../components/svg/bug';
import { Eye } from '../../../components/svg/eye';
import { CheckShield } from '../../../components/svg/check-shield';
import { Bell } from '../../../components/svg/bell';
import { Clock } from '../../../components/svg/clock';
import { Triage } from '../../../components/svg/triage';
import { Person } from '../../../components/svg/person';
import { Squirrel } from '../../../components/svg/squirrel';
import { Shield } from '../../../components/svg/shield';
import { Target } from '../../../components/svg/target';

export const WAFFLE_CATEGORY = {
  STAGE1: 'Proactive Global Threat Hunt',
  STAGE2: 'Endpoint Containment & Client Communication',
  STAGE3: 'Government and Competitor Reach Out To Our Client with Attack Tip',
  STAGE4: 'Reactive Threat Hunt Actioning Government and Competitor Tip',
  STAGE5:
    'Confirmation That Our Proactive Threat Hunt Had Already Caught and Remediated The Cyber Threat',
};

export const WAFFLE_COLUMNS = [
  [WAFFLE_CATEGORY.STAGE1],
  [WAFFLE_CATEGORY.STAGE2, WAFFLE_CATEGORY.STAGE3],
  [WAFFLE_CATEGORY.STAGE4],
  [WAFFLE_CATEGORY.STAGE5],
];

export const WAFFLE_CATEGORY_ICONS = {
  [WAFFLE_CATEGORY.STAGE1]: Eye,
  [WAFFLE_CATEGORY.STAGE2]: CheckShield,
  [WAFFLE_CATEGORY.STAGE3]: Bell,
  [WAFFLE_CATEGORY.STAGE4]: Bell,
  [WAFFLE_CATEGORY.STAGE5]: CheckShield,
};

export const waffleSlides = [
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'SquirrelWaffle',
    subtitle: 'Scenario Overview',
    component: Slide01,
  },
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'SquirrelWaffle',
    subtitle: 'Scenario Overview',
    component: Slide02,
  },
  {
    title: 'SquirrelWaffle',
    subtitle: 'TRU Gets a Positive Hit on a Global Threat Hunt',
    product: 'MDR for Endpoint & MDR For Network',
    time: '00:00:00:00',
    component: Slide03,
    icon: Bug,
    category: WAFFLE_CATEGORY.STAGE1,
    appScreenshotUrl: [
      require('../../../assets/waffle/screen-01-01.png'),
      require('../../../assets/waffle/screen-01-02.png'),
    ],
    description:
      "eSentire's TRU team develops hypothesis-driven threat hunts that ingest Network, Endpoint, Log and Cloud signal data based on original threat research and threat intel across eSentire's customer base to proactively detect an Indicator of Compromise (IoC) for SQUIRRELWAFFLE malware. On November 1st, 2021, TRU's global threat hunting tool was engaged in one of these proactive threat hunts and received a positive threat alert in a client's environment for SQUIRRELWAFFLE.",
  },
  {
    title: 'SquirrelWaffle',
    subtitle:
      'Multi-Signal Investigation of Endpoint and Network Signals Identifies SQUIRRELWAFFLE Malware',
    product: 'MDR for Endpoint & MDR For Network',
    time: '00:00:00:00',
    component: Slide04,
    icon: Eye,
    category: WAFFLE_CATEGORY.STAGE1,
    appScreenshotUrl: [
      require('../../../assets/waffle/screen-02-01.png'),
      require('../../../assets/waffle/screen-02-02.png'),
    ],
    description: [
      'eSentire elite threat hunters and SOC cyber analysts respond rapidly, investigating and correlating the IoC from the network hit and the endpoint that alerted the true positive hit from the threat hunt. This multi-signal detection was driven by MDR for Network and for Endpoint. eSentire MDR for Endpoint detected a malicious Excel document installing SQUIRRELWAFFLE.',
      'SQUIRRELWAFFLE malware is typically used as a foothold for conducting further attacks, such as the deployment of Qakbot and Cobalt Strike as part of a ransomware attack. The SQUIRRELWAFFLE threat specializes in malware distribution via email, usually through a technique like email thread hijacking. eSentire MDR for Network also identified SQUIRRELWAFFLE & Qakbot C2 communications and remote access tool communications.',
    ],
  },
  {
    title: 'SquirrelWaffle',
    subtitle:
      'Multi-Signal Investigation of Endpoint and Network Signals Identifies SQUIRRELWAFFLE Malware',
    product: 'MDR for Endpoint',
    time: '00:00:06:00',
    component: Slide05,
    category: WAFFLE_CATEGORY.STAGE2,
    description:
      "eSentire SOC cyber analysts investigate and respond rapidly on the client's behalf to the infected hosts where SQUIRRELWAFFLE payloads were identified. The team works with the client to isolate and contain the hosts where lateral movement occurred, returning them to service once the threat is remediated.",
  },
  {
    title: 'SquirrelWaffle',
    subtitle: 'Client Notification',
    time: '00:00:08:00',
    component: Slide06,
    category: WAFFLE_CATEGORY.STAGE2,
    clientCommunication: true,
    appScreenshotUrl: [
      require('../../../assets/waffle/screen-04-01.png'),
      require('../../../assets/waffle/screen-04-02.png'),
    ],
    description:
      "The alert is escalated and the SOC alerts the client's team about the malware detection, notifies them the hosts have been isolated, and provides recommendations on next steps for remediation and further forensic investigation. The cyber analysts request acknowledgment of receipt from the client. ",
  },
  {
    title: 'SquirrelWaffle',
    subtitle: 'Security Advisory Published on SQUIRRELWAFFLE',
    product: 'Threat Response Unit (TRU)',
    time: '00:11:41:00',
    component: Slide07,
    category: WAFFLE_CATEGORY.STAGE2,
    icon: Bell,
    clientCommunication: true,
    appScreenshotUrl: [
      require('../../../assets/waffle/screen-05-01.png'),
      require('../../../assets/waffle/screen-05-02.png'),
    ],
    description:
      'eSentire publishes a TRU Positive Security Advisory higlighting details of the SQUIRRELWAFFLE infection including what was found and recommended actions for our clients.',
  },
  {
    title: 'SquirrelWaffle',
    subtitle:
      "FBI + Competitor Alert on a Possible Infection in our Client's Network",
    time: '04:00:06:00',
    component: Slide08,
    category: WAFFLE_CATEGORY.STAGE3,
    icon: Bell,
    clientCommunication: true,
    appScreenshotUrl: require('../../../assets/waffle/screen-06-01.png'),
    description:
      "On November 5th, the client informs eSentire's team that the FBI and a competitive service provider have shared a tip that their network has been compromised.",
  },
  {
    title: 'SquirrelWaffle',
    subtitle: 'Incident Handler Channel is Created',
    product: 'Threat Response Unit (TRU)',
    time: '04:00:37:00',
    component: Slide09,
    category: WAFFLE_CATEGORY.STAGE3,
    appScreenshotUrl: require('../../../assets/waffle/screen-07-01.png'),
    description:
      "An incident handler channel is created and TRU conducts research into the client's network based on the FBI and competitor tip.",
  },
  {
    title: 'SquirrelWaffle',
    subtitle:
      'Threat Hunters Identify Incident as the SQUIRRELWAFFLE Infection from November 1',
    product: 'Threat Response Unit (TRU)',
    time: '04:00:54:00',
    component: Slide10,
    category: WAFFLE_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/waffle/screen-08-01.png'),
    description:
      'At 12:13UTC eSentire threat hunters indicate that they believe the FBI + Competitor tip is actually the SQUIRRELWAFFLE malware infection from November 1st that was already proactively detected, investigated, contained and remediated. Additional threat hunters review the IoCs provided by the FBI and determine that 3 indicators match the positive hit eSentire identified on November 1st. ',
  },
  {
    title: 'SquirrelWaffle',
    subtitle: 'TRU Conducts Retroactive Threat Hunt to Search for Qakbot C2s',
    time: '04:02:01:00',
    product: 'Threat Response Unit (TRU)',
    component: Slide11,
    icon: Eye,
    category: WAFFLE_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/waffle/screen-09-01.png'),
    description:
      'The TRU team conducts a retroactive threat hunt in order to search for Qakbot C2s which is malware associated with SQUIRRELWAFFLE infections. The search indicates no hits in the client environment. ',
  },
  {
    title: 'SquirrelWaffle',
    subtitle:
      'TRU Concludes Positive Hits Were Already Included in the November 1 Alert',
    time: '04:02:58:00',
    product: 'Threat Response Unit (TRU)',
    component: Slide12,
    category: WAFFLE_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/waffle/screen-10-01.png'),
    description:
      'The TRU retroactive threat hunt continues in order to search for SQUIRRELWAFFLE domains. Only hits are the 3 positives that were already included in the original alert on November 1st.',
  },
  {
    title: 'SquirrelWaffle',
    subtitle: 'TRU Continues Retroactive Hunt to Search for IPv4',
    product: 'Threat Response Unit (TRU)',
    time: '04:03:00:00',
    component: Slide13,
    category: WAFFLE_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/waffle/screen-11-01.png'),
    description:
      "The TRU's retroactive threat hunt continues in order to search for SQUIRRELWAFFLE IPv4. The team uncovers no hits.",
  },
  {
    title: 'SquirrelWaffle',
    subtitle:
      'TRU Concludes the SQUIRRELWAFFLE Threat Was Already Caught and Remediated 4 Days Before the FBI + Competitor Tip',
    product: 'Threat Response Unit (TRU)',
    time: '04:04:13:00',
    component: Slide14,
    category: WAFFLE_CATEGORY.STAGE5,
    appScreenshotUrl: require('../../../assets/waffle/screen-12-01.png'),
    description:
      'The retroactive threat hunt concludes that the SQUIRRELWAFFLE threat was already caught and remediated 4 days prior to the FBI + Competitor tip. The customer is satisfied with the initial actions conducted by eSentire.',
  },
  // Timeline
  {
    title: 'SquirrelWaffle',
    subtitle: 'Timeline Summary',
    component: Slide15,
    icon: Clock,
    timelineSummary: true,
    category: WAFFLE_CATEGORY.STAGE5,
  },
];
