/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import ReactDOM from 'react-dom';
import { observable } from 'mobx';
import { Stage } from '../stage';
import anime from 'animejs';

// SCSS
import '../message.scss';

// Players
import { PlayerMapBlip } from '../players/map-blip';
import { PlayerCircle } from '../players/circle';
import { PlayerProgressPie } from '../players/progress-pie';

// SVG
import { World } from '../svg/world';
import { USAScanner } from '../svg/usa-scanner';
import { Crowdstrike } from '../svg/crowdstrike';
import { Shield } from '../svg/shield';
import { Computer } from '../svg/computer';

@observer
export class Slide05 extends AnimatedSlide {
  // Animation players
  players = {
    world: null,
    scanner: null,
    blip: null,
    crowdstrike: null,
    circle: null,
    terminal1: null,
    terminal2: null,
    terminal3: null,
    terminal4: null,
    terminal5: null,
    terminal6: null,
  };

  // Six progress bars
  bars = [
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
    {
      color: this.colors.green,
      progress: 0,
    },
  ];

  // Scanner stuff
  @observable
  scan = {
    index: 0, // Starting index of positions
    hold: 100, // How long to hold each point
    timer: null, // The timer
    positions: [
      // Max top = 115px
      // Max left = 245px
      { top: '100px', left: '105px' },
      { top: '185px', left: '555px' },
      { top: '45px', left: '305px' },
      { top: '315', left: '190px' },
      { top: '250px', left: '430px' },
      { top: '360px', left: '730px' },
      { top: '180px', left: '730px' },
      { top: '130px', left: '580px' },
      { top: '180px', left: '420px' },
      { top: '110px', left: '450px' },
      { top: '250px', left: '140px' },
      { top: '130px', left: '120px' },
      { top: '100px', left: '430px' },
      { top: '130px', left: '120px' },
      { top: '250px', left: '580px' },
      { top: '260px', left: '650px' },
      { top: '70px', left: '570px' },
      { top: '180px', left: '420px' },
      { top: '250px', left: '430px' },
      { top: '45px', left: '305px' },
      { top: '100px', left: '430px' },
    ],
  };

  // Animate the scanner
  animateScanner() {
    let node = ReactDOM.findDOMNode(this.players.scanner);
    let positions = this.scan.positions;
    let index = this.scan.index;
    this.scan.timer = setInterval(() => {
      index++;
      if (index >= positions.length) index = 0;
      node.style.top = positions[index].top;
      node.style.left = positions[index].left;
    }, this.scan.hold);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;
    // Constants
    const {
      world,
      scanner,
      blip,
      crowdstrike,
      circle,
      terminal1,
      terminal2,
      terminal3,
      terminal4,
      terminal5,
      terminal6,
    } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(15000);
        this.animateScanner();
        this.autoNext(4000);
      },
      // Step 1
      () => {
        this.autoNext(3000);
        clearInterval(this.scan.timer);
      },
      // STep 2
      () => {
        this.autoNext(1000);
      },
      // STep 3
      () => {
        this.autoNext(1000);
      },
      //
      // TERMINAL ANIMATIONS
      //
      // Step 4
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal1, 'hide-line');
        this.bars[0].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 5
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal2, 'hide-line');
        this.bars[0].color = this.colors.blue;
        this.bars[1].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 6
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal3, 'hide-line');
        this.bars[1].color = this.colors.blue;
        this.bars[2].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 7
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal4, 'hide-line');
        this.bars[2].color = this.colors.blue;
        this.bars[3].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 8
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal5, 'hide-line');
        this.bars[3].color = this.colors.blue;
        this.bars[4].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 9
      () => {
        if (!this._isMounted) return;
        this.addRemoveClass(terminal6, 'hide-line');
        this.bars[4].color = this.colors.blue;
        this.bars[5].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // STep 10
      () => {
        if (!this._isMounted) return;
        this.bars[5].color = this.colors.blue;
        this.forceUpdate();
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      // ReactDOM.findDOMNode()

      //
      // Step 1
      //
      [
        {
          targets: ReactDOM.findDOMNode(scanner),
          opacity: 0,
          duration: 500,
          begin: shimmer,
        },
        {
          targets: blip,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 0,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],

      // Step 3
      [
        {
          targets: world,
          opacity: 0,
          duration: 500,
          begin: shimmer,
        },
        {
          targets: blip,
          opacity: 0,
          duration: 500,
          begin: shimmer,
        },
        {
          targets: circle,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: shimmer,
          complete: activateShimmer,
        },
        {
          targets: crowdstrike,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        // Terminals
        {
          targets: terminal1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 600,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: terminal2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 800,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: terminal3,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: terminal4,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1200,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: terminal5,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1400,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: terminal6,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1600,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
      // Animate terminals
      // Step 4
      [],
      [
        {
          targets: terminal1,
          delay: 0,
          duration: 1000,
          complete: shimmer,
        },
      ],
      // Step 5
      [
        {
          targets: terminal2,
          delay: 0,
          duration: 1000,
          complete: shimmer,
        },
      ],
      // Step 6
      [
        {
          targets: terminal3,
          delay: 0,
          duration: 1000,
          complete: shimmer,
        },
      ],
      // Step 7
      [
        {
          targets: terminal4,
          delay: 0,
          duration: 1000,
          complete: shimmer,
        },
      ],
      // Step 8
      [
        {
          targets: terminal5,
          delay: 0,
          duration: 1000,
          complete: shimmer,
        },
      ],
      // Step 9
      [
        {
          targets: terminal6,
          delay: 0,
          duration: 1000,
          complete: shimmer,
        },
      ],
      // Step 10
      [],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-05 ${className}`}>
        {children}
        <div className="content">
          {/*-------PLAYERS START-------*/}

          {/* World */}
          <div
            className="player world"
            data-active="1"
            ref={node => (this.players.world = node)}
          >
            <USAScanner ref={node => (this.players.scanner = node)} />
            <World size={862} />
          </div>

          {/* Map blip */}
          <div
            className="player blip"
            data-active="0"
            ref={node => (this.players.blip = node)}
          >
            <PlayerMapBlip />
          </div>

          {/* Large circle */}
          <div
            className="player circle"
            data-active="0"
            ref={node => (this.players.circle = node)}
          >
            <PlayerCircle />
          </div>

          {/*-------CROWDSTRIKE SECTION-------*/}

          {/* Crowdstrike */}
          <div
            className="player crowdstrike"
            data-active="0"
            ref={node => (this.players.crowdstrike = node)}
          >
            <Shield
              IconComponent={Crowdstrike}
              IconSize={80}
              letterSpacing={1}
              text="CROWDSTRIKE"
            />
          </div>

          {/* Terminal 1 */}
          <div
            className="player terminal terminal1"
            data-active="0"
            ref={node => (this.players.terminal1 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[0].progress}
              color={this.bars[0].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line blue'}></div>
          </div>
          {/* Terminal 2 */}
          <div
            className="player terminal terminal2"
            data-active="0"
            ref={node => (this.players.terminal2 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[1].progress}
              color={this.bars[1].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line blue'}></div>
          </div>
          {/* Terminal 3 */}
          <div
            className="player terminal terminal3"
            data-active="0"
            ref={node => (this.players.terminal3 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[2].progress}
              color={this.bars[2].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line blue'}></div>
          </div>
          {/* Terminal 4 */}
          <div
            className="player terminal terminal4"
            data-active="0"
            ref={node => (this.players.terminal4 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[3].progress}
              color={this.bars[3].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line reverse blue'}></div>
          </div>
          {/* Terminal 5 */}
          <div
            className="player terminal terminal5"
            data-active="0"
            ref={node => (this.players.terminal5 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[4].progress}
              color={this.bars[4].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line reverse blue'}></div>
          </div>
          {/* Terminal 6 */}
          <div
            className="player terminal terminal6"
            data-active="0"
            ref={node => (this.players.terminal6 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[5].progress}
              color={this.bars[5].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line reverse blue'}></div>
          </div>

          {/*-------PLAYERS END-------*/}
        </div>
      </div>
    );
  }
}
