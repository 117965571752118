/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Download extends Component {
  render() {
    const {
      children,
      className = '',
      color = 'white',
      size = 100,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.5357 40.2539L37.121 57.5396C36.9737 57.6841 36.7996 57.7989 36.6048 57.879C36.4117 57.9591 36.2058 58 36 58C35.7942 58 35.5883 57.9591 35.3952 57.879C35.2004 57.7989 35.0263 57.6841 34.879 57.5396L17.4643 40.2539C16.8452 39.6394 16.8452 38.6463 17.4643 38.0319C18.0834 37.4174 19.084 37.4174 19.7031 38.0319L34.4167 52.6351V15.5714C34.4167 14.7024 35.126 14 36 14C36.874 14 37.5833 14.7024 37.5833 15.5714V52.6351L52.2969 38.0319C52.916 37.4174 53.9166 37.4174 54.5357 38.0319C55.1548 38.6463 55.1548 39.6394 54.5357 40.2539ZM36 0C16.1499 0 0 16.1483 0 36C0 55.8517 16.1499 72 36 72C55.8501 72 72 55.8517 72 36C72 16.1483 55.8501 0 36 0Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
