/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import ReactDOM from 'react-dom';
import { PlayerDots } from '../players/dots';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { Skull } from '../svg/skull';
import { RDP } from '../svg/rdp';

@observer
export class Slide05 extends AnimatedSlide {
  // Animation players
  players = {
    attacker: null,
    rdp: null,
    dots1: null,
    dots2: null,
    connection: null,
  };

  // Progress references
  progress1 = 0;

  // Run the first animation
  componentDidMount() {
    const { attacker, rdp, dots1, dots2, connection } = this.players;

    // Load connection title
    let connectionTitle = ReactDOM.findDOMNode(connection).childNodes[0];

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(5000);
        connectionTitle.classList.remove('red-top');
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(200);
      },
      // Step 2
      () => {
        this.autoNext(200);
      },
      // Step 3
      () => {
        this.autoNext(200);
      },
      // Step 4
      () => {
        this.autoNext(200);
      },
      // Step 5 - Connection starts
      () => {
        this.autoNext(200);
      },
      // Step 6 - Connection 2
      () => {
        this.autoNext(500);
      },
      // Step 7 - Connection 3
      () => {
        this.autoNext(500);
      },
      // Step 8 - Connection 4
      () => {
        this.autoNext(500);
      },
      // Step 9 - Connection 5
      () => {
        connectionTitle.classList.add('red-top');
        this.autoNext(500);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Do nothing
      ],
      //
      // Step 1 - attacker shows up
      //
      [
        {
          targets: attacker,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2 - attacker shows up
      //
      [
        {
          targets: attacker,
          opacity: 1,
          delay: 0,
          translateX: -300,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: rdp,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 3 - Show dots 1
      //
      [
        {
          targets: dots1,
          opacity: 1,
          delay: 0,
          duration: 500,
          begin: activateShimmer,
        },
      ],
      //
      // Step 4 - Show dots 2
      //
      [
        {
          targets: dots2,
          opacity: 1,
          delay: 0,
          duration: 500,
          begin: activateShimmer,
        },
      ],
      //
      // Step 5 - Connection info
      //
      [
        {
          targets: connection,
          opacity: 1,
          delay: 0,
          duration: 500,
          begin: activateShimmer,
        },
      ],
      //
      // Step 6 - Connection move up
      //
      [
        {
          targets: connection,
          opacity: 1,
          translateY: -50,
          duration: 200,
          begin: activateShimmer,
        },
      ],
      //
      // Step 7 - Connection move up
      //
      [
        {
          targets: connection,
          opacity: 1,
          translateY: -100,
          duration: 200,
          begin: activateShimmer,
        },
      ],
      //
      // Step 8 - Connection move up
      //
      [
        {
          targets: connection,
          opacity: 1,
          translateY: -150,
          duration: 200,
          begin: activateShimmer,
        },
      ],
      //
      // Step 9 - Connection move up
      //
      [
        {
          targets: connection,
          opacity: 1,
          translateY: -200,
          duration: 200,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-05 ${className}`}>
        {children}
        <div className="content">
          {/* Attacker */}
          <div
            className="player attacker"
            data-active="0"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text={'Attacker'} />
          </div>
          {/* RDP */}
          <div
            className="player rdp"
            data-active="0"
            ref={node => (this.players.rdp = node)}
          >
            <Card IconComponent={RDP} text={'rdp'} />
          </div>
          {/* dots 1 */}
          <div
            className="player dots1"
            data-active="0"
            ref={node => (this.players.dots1 = node)}
          >
            <PlayerDots
              color={this.colors.red}
              width={160}
              dots={10}
              className={'in-out'}
            />
          </div>
          {/* dots 2 */}
          <div
            className="player dots2"
            data-active="0"
            ref={node => (this.players.dots2 = node)}
          >
            <PlayerDots
              color={this.colors.red}
              width={140}
              dots={10}
              className={'in-out'}
            />
          </div>
          {/* Connection info */}
          <div
            className="player connection"
            data-active="0"
            ref={node => (this.players.connection = node)}
          >
            <PlayerTextBlock
              title={'Connection Info'}
              sections={[
                {
                  title: ['37 COMPUTERS'],
                  color: 'red',
                  colorCells: 2,
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['8770 PROCESSES'],
                  color: 'red',
                  colorCells: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['LOCAL IP', '10.12.254.254'],
                  grid: 2,
                  animate: true,
                  delay: 600,
                },
                {
                  title: ['LOCAL PORT', '3389'],
                  grid: 2,
                  animate: true,
                  delay: 800,
                },
                {
                  title: ['REMOTE PORT', '64422'],
                  grid: 2,
                  animate: true,
                  delay: 1000,
                },
                {
                  lines: 4,
                  animate: true,
                  delay: 2000,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
