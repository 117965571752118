/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './bottom-nav.scss';
import { NavDot } from './svg/nav-dot';
import { List } from './svg/list';
import { Quote } from './svg/quote';
import { RouteManager } from 'vega-router';

@observer
export class BottomNav extends Component {
  // region Handlers
  handleClickSlide = slide => () => {
    const callback = this.props.onClick || this.props.onChange || (() => {});

    callback(slide);
  };
  // endregion

  // region Renderers
  render() {
    const {
      children,
      className = '',
      slides = [],
      useCaseKey = '',
    } = this.props;

    return (
      <div className={`BottomNav ${className}`}>
        {slides.map((slide, i) => this.renderSlide(slide, i))}
        <a
          onClick={() => RouteManager.transitionTo(`/${useCaseKey}/list`)}
          className="BottomNav__ListButton"
        >
          <List size={26} opacity={0.5} />
        </a>
        {children}
      </div>
    );
  }

  renderSlide(slide = {}, i = 0) {
    const color = slide.selected
      ? '#50C2E3'
      : slide.active
      ? '#50C2E3'
      : 'gray';
    const Icon = slide.icon || NavDot;

    return [
      i !== 0 && (
        <span
          className={`BottomNav__Spacer ${
            slide.active ? 'BottomNav__Spacer--active' : ''
          }`}
          onClick={this.handleClickSlide(slide)}
          key={`${i}-spacer`}
        />
      ),
      <span
        className={`BottomNav__Slide ${
          slide.active ? 'BottomNav__Slide--active' : ''
        }`}
        onClick={this.handleClickSlide(slide)}
        key={i}
      >
        <Icon size={slide.icon ? 20 : 10} color={color} />
        {slide.clientCommunication && (
          <Quote
            className="BottomNav__Slide__ClientCommunications"
            size={13}
            color={color}
          />
        )}
      </span>,
    ];
  }
  // endregion
}
