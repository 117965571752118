/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { AnimatedSlide } from '../components/animated-slide';
import { getColor } from '../components/helpers';

// SCSS
import './team-scans-threats.scss';

// Players
import { PlayerBoxScanner } from '../components/players/box-scanner';
import { PlayerPath } from '../components/players/path';

// SVG
import { Shield } from '../components/svg/shield';
import { TRU } from '../components/svg/tru';
import { MachineLearning } from '../components/svg/machine-learning';
import { Skull } from '../components/svg/skull';

@observer
export class Template__TeamScansThreats extends AnimatedSlide {
  // Animation players
  players = {
    team: null,
    product: null,
    scanner1: null,
    scanner2: null,
    scanner3: null,
    file1: null,
    file2: null,
    file3: null,
    pathsLeft: null,
    pathsRight: null,
  };

  // Team shield
  team = {
    icon: <TRU size={60} color={this.colors.white} opacity={1} />,
    text: 'TRU Team',
    stroke: this.colors.blue,
    strokeOpacity: 1,
  };

  // Product shield
  product = {
    icon: <MachineLearning size={60} color={this.colors.white} opacity={1} />,
    text: 'MDR',
    stroke: this.colors.blue,
    strokeOpacity: 1,
    gradient: true,
  };

  // Run the first animation
  componentDidMount() {
    const {
      team,
      product,
      scanner1,
      scanner2,
      scanner3,
      file1,
      file2,
      file3,
      pathsLeft,
      pathsRight,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(team, 500),
        this.fadeIn(product, 2000),
        this.fadeIn(file1, 500),
        this.fadeIn(file2, 4000),
        this.fadeIn(file3, 8000),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '', team = {}, product = {} } = this.props;

    Object.assign(this.team, team);
    Object.assign(this.product, product);

    let dots = {
      stroke: this.colors.blue,
      strokeWidth: 2,
      strokeDashArray: '2,2',
    };

    let reveal = {
      duration: 1000,
    };

    return (
      <div className={`Template__TeamScansThreats ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TRU Team */}
          <div
            className="player team"
            data-active="1"
            ref={node => (this.players.team = node)}
          >
            <Shield {...this.team} />
          </div>

          {/* Product */}
          <div
            className="player product"
            data-active="1"
            ref={node => (this.players.product = node)}
          >
            <Shield {...this.product} />
          </div>

          {/* Scanners */}
          <div
            className="player scanner scanner1"
            data-active="1"
            ref={node => (this.players.scanner1 = node)}
          >
            <PlayerBoxScanner width={140} height={30} />
          </div>
          <div
            className="player scanner scanner2"
            data-active="1"
            ref={node => (this.players.scanner2 = node)}
          >
            <PlayerBoxScanner width={140} height={30} delay={4000} />
          </div>
          <div
            className="player scanner scanner3"
            data-active="1"
            ref={node => (this.players.scanner3 = node)}
          >
            <PlayerBoxScanner width={140} height={30} delay={8000} />
          </div>

          {/* Threats */}
          <div
            className="player file1"
            data-active="0"
            ref={node => (this.players.file1 = node)}
          >
            <div className="threat">
              <Skull size={14} color={this.colors.white} />
              cve-2021-34473
            </div>
          </div>
          <div
            className="player file2"
            data-active="0"
            ref={node => (this.players.file2 = node)}
          >
            <div className="threat">
              <Skull size={14} color={this.colors.white} />
              cve-2021-34523
            </div>
          </div>
          <div
            className="player file3"
            data-active="0"
            ref={node => (this.players.file3 = node)}
          >
            <div className="threat">
              <Skull size={14} color={this.colors.white} />
              cve-2021-31207
            </div>
          </div>

          {/* Lines */}
          <div
            className="player linesLeft"
            data-active="1"
            ref={node => (this.players.linesLeft = node)}
          >
            <PlayerPath
              points={['0,53', '40,53', '125,0', '165,0']}
              dots={dots}
              reveal={this.revealPreset(500, 1000)}
            />
            <PlayerPath
              points={['0,0', '165,0']}
              dots={dots}
              reveal={this.revealPreset(4000, 1000)}
            />
            <PlayerPath
              points={['0,0', '40,0', '125,53', '165,53']}
              dots={dots}
              reveal={this.revealPreset(8000, 1000)}
            />
          </div>
          <div
            className="player linesRight"
            data-active="1"
            ref={node => (this.players.linesLeft = node)}
          >
            <PlayerPath
              points={['0,0', '40,0', '125,53', '165,53']}
              dots={dots}
              data={{ delay: 4500, size: 8, duration: 800 }}
              reveal={this.revealPreset(4500, 1000)}
            />
            <PlayerPath
              points={['0,0', '165,0']}
              dots={dots}
              data={{ delay: 8500, size: 8, duration: 1000 }}
              reveal={this.revealPreset(8500, 1000)}
            />
            <PlayerPath
              points={['0,53', '40,53', '125,0', '165,0']}
              dots={dots}
              data={{ delay: 12500, size: 8, duration: 700 }}
              reveal={this.revealPreset(12500, 1000)}
            />
          </div>

          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
