/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Money extends Component {
  render() {
    const {
      children,
      className = '',
      color = 'white',
      size = 100,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.375 9.28125C10.8333 9.40625 11.2083 9.53125 11.5 9.65625C11.8125 9.78125 12.1354 9.95833 12.4688 10.1875C12.8021 10.3958 13.0521 10.6667 13.2188 11C13.4062 11.3333 13.5 11.7292 13.5 12.1875C13.5 12.8333 13.2812 13.3646 12.8438 13.7812C12.4271 14.1771 11.8646 14.4375 11.1562 14.5625V16H9.15625V14.5625C8.46875 14.4167 7.89583 14.125 7.4375 13.6875C7 13.25 6.76042 12.6875 6.71875 12H8.1875C8.27083 12.9375 8.92708 13.4062 10.1562 13.4062C10.8021 13.4062 11.2604 13.2917 11.5312 13.0625C11.8229 12.8125 11.9688 12.5312 11.9688 12.2188C11.9688 11.4688 11.3021 10.9271 9.96875 10.5938C7.88542 10.1146 6.84375 9.19792 6.84375 7.84375C6.84375 7.23958 7.0625 6.72917 7.5 6.3125C7.9375 5.875 8.48958 5.58333 9.15625 5.4375V4H11.1562V5.46875C11.8438 5.63542 12.3646 5.94792 12.7188 6.40625C13.0938 6.86458 13.2917 7.39583 13.3125 8H11.8438C11.8021 7.0625 11.2396 6.59375 10.1562 6.59375C9.61458 6.59375 9.17708 6.70833 8.84375 6.9375C8.53125 7.16667 8.375 7.46875 8.375 7.84375C8.375 8.44792 9.04167 8.92708 10.375 9.28125Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
