/** @jsx createElement */
import anime from 'animejs';
import { createElement, Fragment } from 'react';
import { observable, toJS, computed } from 'mobx';
import { observer } from 'mobx-react';
import { AnimatedSlide } from '../animated-slide';
import { RouteManager } from 'vega-router';
import { Badge } from '../badge';
import { Timer } from '../timer';
import { Button } from '../button';
import { Select } from '../select';
import Color from 'color';

import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerCloudShark } from '../players/cloudshark';
import { PlayerDataMoving } from '../players/data-moving';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

import { Alert } from '../svg/alert';
import { ArrowRight } from '../svg/arrow-right';
import { BackArrow } from '../svg/back-arrow';
import { Bell } from '../svg/bell';
import { Bug } from '../svg/bug';
import { Card } from '../svg/card';
import { Chain } from '../svg/chain';
import { Check } from '../svg/check';
import { CheckBoxes } from '../svg/check-boxes';
import { CheckCircle } from '../svg/check-circle';
import { CheckShield } from '../svg/check-shield';
import { Clock } from '../svg/clock';
import { Close } from '../svg/close';
import { CMD } from '../svg/cmd';
import { Computer } from '../svg/computer';
import { ComputerGroup } from '../svg/computer-group';
import { Crosshair } from '../svg/crosshair';
import { Desktop } from '../svg/desktop';
import { Directory } from '../svg/directory';
import { DiskDrive } from '../svg/disk-drive';
import { Document } from '../svg/document';
import { Download } from '../svg/download';
import { DropDown } from '../svg/drop-down';
import { IconCloudShark } from '../svg/cloudshark';
import { Email } from '../svg/email';
import { EsentireIcon } from '../svg/esentire-icon';
import { Eye } from '../svg/eye';
import { FingerPrint } from '../svg/finger-print';
import { Gear } from '../svg/gear';
import { HamburgerIcon } from '../svg/hamburger-icon';
import { Info } from '../svg/info';
import { InternetExplorer } from '../svg/internet-explorer';
import { List } from '../svg/list';
import { Money } from '../svg/money';
import { NavDot } from '../svg/nav-dot';
import { NewDocument } from '../svg/new-document';
import { Page } from '../svg/page';
import { Person } from '../svg/person';
import { Phone } from '../svg/phone';
import { PowerShell } from '../svg/power-shell';
import { Quote } from '../svg/quote';
import { Refresh } from '../svg/refresh';
import { Server } from '../svg/server';
import { Shield } from '../svg/shield';
import { Skull } from '../svg/skull';
import { USAScanner } from '../svg/usa-scanner';
import { USA } from '../svg/usa';
import { Warning } from '../svg/warning';
import { PersonCircle } from '../svg/person-circle';
import { DoubleArrowRight } from '../svg/double-arrow-right';
import { Sun } from '../svg/sun';

import './graphics-tab.scss';
import { colorGenerator } from '../../data/color-generator';
import { Binoculars } from '../svg/binoculars';
import { Device } from '../svg/device';
import { DesktopDownload } from '../svg/desktop-download';
import { Cloud } from '../svg/cloud';
import { CloudUpload } from '../svg/cloud-upload';
import { Download2 } from '../svg/download-2';
import { Laptop } from '../svg/laptop';
import { Code } from '../svg/code';
import { Code2 } from '../svg/code2';
import { Transfer } from '../svg/transfer';
import { Dropbox } from '../svg/dropbox';
import { GoogleDrive } from '../svg/google-drive';
import { Gmail } from '../svg/gmail';

const icons = [
  ArrowRight,
  BackArrow,
  Bell,
  Binoculars,
  Bug,
  Chain,
  Check,
  CheckBoxes,
  CheckCircle,
  CheckShield,
  Clock,
  Close,
  Code,
  Code2,
  CMD,
  Cloud,
  CloudUpload,
  Computer,
  Crosshair,
  Desktop,
  DesktopDownload,
  Device,
  Directory,
  DiskDrive,
  Document,
  DoubleArrowRight,
  Download,
  Download2,
  Dropbox,
  DropDown,
  Email,
  Eye,
  Eye,
  FingerPrint,
  Gear,
  GoogleDrive,
  Gmail,
  HamburgerIcon,
  IconCloudShark,
  Info,
  InternetExplorer,
  Laptop,
  List,
  Money,
  NavDot,
  NewDocument,
  Page,
  Person,
  PersonCircle,
  Phone,
  PowerShell,
  Quote,
  Refresh,
  Server,
  Skull,
  Transfer,
  USAScanner,
  Warning,
  Sun,
];

const OPTIONS = [
  { label: 'This', value: 'this' },
  { label: 'That', value: 'that' },
  { label: 'Those', value: 'those' },
];

const changeValue = (instance, field, transform = d => d) => event => {
  const { value } = event.target;
  instance[field] = transform(value);
};

@observer
export class GraphicsTab extends AnimatedSlide {
  @observable color = '#FFF';
  @observable size = 35;
  @observable selectValue = OPTIONS[0];
  @observable highlighted = -1;

  //
  // For color swatches
  //
  @observable colorCount = 20;
  @observable baseColor = `${colorGenerator.baseColor}`;
  @computed
  get colors() {
    try {
      colorGenerator.baseColor = new Color(this.baseColor);
    } catch (error) {}
    colorGenerator.count = this.colorCount;

    return [...new Array(this.colorCount)].map((_, i) => ({
      color: colorGenerator.color(i),
      index: i,
      hovered: false,
    }));
  }

  alerts = [];

  // Run the first animation
  componentDidMount() {
    this.animations = [
      this.alerts.map(alert => ({
        targets: alert.querySelector('.border'),
        opacity: [0, 1],
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 1000,
        easing: 'easeInOutSine',
      })),
    ];

    // Play the first animation
    this.play();
  }

  changeSelect = value => {
    this.selectValue = value;
  };

  render() {
    const { className = '' } = this.props;

    const size = Number(this.size);

    return (
      <div className={`GraphicsTab ${className}`}>
        <h2>
          COLOR GENERATOR
          <span>
            <form action="">
              <fieldset>
                <label>Base Color:</label>
                <input
                  type="text"
                  onChange={changeValue(this, 'baseColor')}
                  value={this.baseColor}
                />
              </fieldset>
              <fieldset>
                <label>Color Count:</label>
                <input
                  type="number"
                  onChange={changeValue(this, 'colorCount', Number)}
                  value={this.colorCount}
                />
              </fieldset>
            </form>
          </span>
        </h2>
        <div className="GraphicsTab__Colors">
          {this.colors.map(swatch => {
            const color = swatch.hovered
              ? swatch.color.lighten(0.2).hex()
              : swatch.color.hex();

            return (
              <div
                className="GraphicsTab__Swatch"
                style={{
                  background: color,
                }}
                onMouseOver={() => {
                  swatch.hovered = true;
                }}
                onMouseOut={() => {
                  swatch.hovered = false;
                }}
                key={swatch.index}
              >
                <dt>{swatch.index}</dt>
                <dd>{color}</dd>
              </div>
            );
          })}
        </div>
        <h2>
          ICONS{' '}
          <small>
            (svg color:{' '}
            <input
              value={this.color}
              onChange={event => (this.color = event.currentTarget.value)}
            />
            ) (icon size:{' '}
            <input
              type="range"
              min="1"
              max="200"
              value={this.size}
              onChange={event => (this.size = event.currentTarget.value)}
            />
            <input
              type="number"
              min="1"
              max="200"
              value={this.size}
              onChange={event => (this.size = event.currentTarget.value)}
            />
            )
          </small>
        </h2>
        <div
          className="GraphicsTab__Icons"
          style={{
            gridTemplateColumns: `repeat(auto-fit, minmax(${size}px, 1fr))`,
          }}
        >
          {icons.map(Icon => (
            <div key={Icon.name}>
              <h3 className="h3" title={Icon.name}>
                <span>{Icon.name}</span>
              </h3>
              <Icon color={this.color} size={size} opacity={1} />
            </div>
          ))}
        </div>
        <h2>CARDS</h2>
        <div className="GraphicsTab__Cards">
          <div>
            <Card IconComponent={Person} color={this.color} text="Analyst" />
          </div>
          <div>
            <Card IconComponent={Computer} color={this.color} text="10.0.0.1" />
          </div>
          <div>
            <Card IconComponent={Warning} color={this.color} text="Critical" />
          </div>
        </div>
        <h2>BADGES</h2>
        <div className="GraphicsTab__Cards">
          <div>
            <Badge text="10.0.0.1" />
          </div>
          <div>
            <Badge text="10.0.0.1" primary />
          </div>
          <div>
            <Badge text="10.0.0.1" danger />
          </div>
        </div>
        <h2>Form Inputs</h2>
        <div className="GraphicsTab__Cards">
          <div>
            <Select
              value={this.selectValue}
              options={OPTIONS}
              onChange={this.changeSelect}
              label="Location"
            />
          </div>
        </div>
        <h2>Buttons</h2>
        <div className="GraphicsTab__Cards">
          <div>
            <Button>Ok</Button>
          </div>
          <div>
            <Button icon={Clock}>View Timeline</Button>
          </div>
        </div>
        <h2>SHIELDS</h2>
        <div className="GraphicsTab__Cards">
          <div>
            <Shield IconComponent={Server} color={this.color} text="AMD" />
          </div>
          <div>
            <Shield
              IconComponent={Computer}
              color={this.color}
              text="10.0.0.1"
            />
          </div>
          <div>
            <Shield
              IconComponent={CheckCircle}
              color={this.color}
              text="Confirmed"
            />
          </div>
        </div>
        <h2>MISCELLANEOUS</h2>
        <div className="GraphicsTab__Misc">
          <div>
            <h3>{ComputerGroup.name}</h3>
            <ComputerGroup color={this.color} />
          </div>
          <div>
            <h3>{EsentireIcon.name}</h3>
            <EsentireIcon color={this.color} />
          </div>
          <div>
            <h3>{USA.name}</h3>
            <USA color={this.color} />
          </div>
          <div>
            <h3>{Timer.name}</h3>
            <Timer time="00:00:00:45" progress={100} />
          </div>
        </div>
        <h2>ALERTS</h2>
        <div className="GraphicsTab__Alerts">
          <div ref={node => (this.alerts[0] = node)}>
            <Alert color="#A93439" />
          </div>
          <div ref={node => (this.alerts[1] = node)}>
            <Alert
              color="#A93439"
              IconComponent={Warning}
              subtitle="Malicious Traits"
            />
          </div>
          <div ref={node => (this.alerts[2] = node)}>
            <Alert
              color="#A93439"
              IconComponent={Download}
              subtitle="Payload Downloaded"
              title="Confirmed"
            />
          </div>
          <div ref={node => (this.alerts[3] = node)}>
            <Alert
              color="#54D5AE"
              IconComponent={Email}
              subtitle="Additional Investigation"
              subtitle2="Initiated"
              title="Message From Esentire"
            />
          </div>
          <div ref={node => (this.alerts[4] = node)}>
            <Alert
              color="#50C2E3"
              IconComponent={Email}
              subtitle="Investigation Analysis"
              title="Message From Esentire"
            />
          </div>
        </div>
        <h2>PLAYERS</h2>
        <div className="GraphicsTab__Players">
          <div>
            <h3>PlayerCircleIcon:</h3>
            <div style={{ width: '78px' }}>
              <PlayerCircleIcon />
            </div>
          </div>
          <div>
            <h3>PlayerCloudShark:</h3>
            <div style={{ width: '220px', marginTop: '40px' }}>
              <PlayerCloudShark wait={1000} />
            </div>
          </div>
          <div>
            <h3>PlayerDataMoving:</h3>
            <div>
              <PlayerDataMoving />
            </div>
          </div>
          <div>
            <h3>PlayerTextBlock:</h3>
            <div>
              <PlayerTextBlock />
            </div>
          </div>
          <div>
            <h3>PlayerDonut:</h3>
            <div style={{ marginLeft: '150px' }}>
              <PlayerDonut />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
