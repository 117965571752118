/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import ReactDOM from 'react-dom';
import { Stage } from '../stage';
import anime from 'animejs';

// SCSS
import '../message.scss';

// Players
import { PlayerTextBlock } from '../players/text-block';
import { PlayerDots } from '../players/dots';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Skull } from '../svg/skull';
import { Shield } from '../svg/shield';
import { Kaseya } from '../svg/kaseya';
import { KaseyaLogo } from '../svg/kaseya-logo';

@observer
export class Slide04 extends AnimatedSlide {
  // Animation players
  players = {
    attacker: null,
    vsa: null,
    team: null,
    dots1: null,
    dots2: null,
    dots3: null,
    message: null,
    border: null,
  };

  // Run the first animation
  componentDidMount() {
    // VSA Border
    this.players.border = this.players.vsa.querySelector('svg');
    // Constants
    const {
      attacker,
      team,
      vsa,
      info1,
      info2,
      dots1,
      dots2,
      dots3,
      border,
      message,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        border.classList.remove('red');
        this.animateProgress(1500);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(6000);
      },
      // Step 4
      () => {
        border.classList.add('red');
        this.autoNext(2000);
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: attacker,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: vsa,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: border,
          opacity: 1,
          'stroke-color': this.colors.red,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: team,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: dots1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: dots2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: dots3,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],

      // Step 3
      [],

      //
      // Step 4
      //
      [
        {
          targets: attacker,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
        },
        {
          targets: vsa,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
        },
        {
          targets: team,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
        },
        {
          targets: dots1,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
        },
        {
          targets: dots2,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
        },
        {
          targets: dots3,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
        },
        {
          targets: message,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 0,
          duration: 1000,
          begin: activate,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-04 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* Attacker */}
          <div
            className="player attacker"
            data-active="0"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text="ATTACKER" />
          </div>
          {/* Kaseya VSA */}
          <div
            className="player vsa"
            data-active="0"
            ref={node => (this.players.vsa = node)}
          >
            <Shield
              IconComponent={Kaseya}
              letterSpacing={1}
              IconSize={70}
              text={'KASEYA VSA'}
            />
          </div>
          {/* TRU Team */}
          <div
            className="player team"
            data-active="0"
            ref={node => (this.players.team = node)}
          >
            <Card IconComponent={Person} text="TRU TEAM" />
          </div>
          {/* dots 1 */}
          <div
            className="player dots1"
            data-active="0"
            ref={node => (this.players.dots1 = node)}
          >
            <PlayerDots
              color={this.colors.red}
              width={160}
              dots={10}
              className={'left-to-right'}
            />
          </div>
          {/* dots 2 */}
          <div
            className="player dots2"
            data-active="0"
            ref={node => (this.players.dots2 = node)}
          >
            <PlayerDots
              color={this.colors.blue}
              width={160}
              dots={10}
              className={'left-to-right'}
            />
          </div>
          {/* dots 3 */}
          <div
            className="player dots3"
            data-active="0"
            ref={node => (this.players.dots3 = node)}
          >
            <PlayerDots
              color={this.colors.blue}
              width={160}
              dots={10}
              className={'right-to-left'}
            />
          </div>
          {/* Message */}
          <div
            className="player message"
            data-active="0"
            ref={node => (this.players.message = node)}
          >
            <div className="Kaseya__Message">
              <KaseyaLogo />
              <h2>Kaseya VSA Update</h2>
              <h3>4:00 PM EDT</h3>
              <div>
                We are experiencing a potential attack against the VSA that has
                been limited to a small number of on-premise customers only as
                of 2:00 PM EDT today.
                <br />
                <br />
                We are in the process of investigating the root cause of the
                incident with an abundance of caution but we recommend that
                you&nbsp;
                <b>
                  IMMEDIATELY shutdown your VSA server until you receive further
                  notice from us.
                </b>
                <br />
                <br />
                <b>
                  It’s critical that you do this immediately because one of the
                  first things the attacker does is shutoff administrative
                  access to the VSA.
                </b>
              </div>
            </div>
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
