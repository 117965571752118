/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { observable } from 'mobx';
import { Computer } from '../svg/computer';

@observer
export class Slide05 extends AnimatedSlide {
  // Animation players
  players = {
    terminal: null,
    donut: null,
    client: null,
    tcp: null,
    event: null,
  };

  @observable
  donut = {
    color: 'blue',
  };

  // Run the first animation
  componentDidMount() {
    const { terminal, donut, client, tcp, event } = this.players;

    this.steps = [
      //
      // Step 1
      //
      () => {
        this.animateProgress(16000);
        this.donut.color = 'blue';
        this.autoNext(2000);
      },

      //
      // Step 2
      //
      () => {
        this.donut.color = 'green';
        this.autoNext(2000);
      },

      //
      // Step 3
      //
      () => {
        this.autoNext(4000);
      },

      //
      // Step 4
      //
      () => {
        this.autoNext(4000);
      },

      //
      // Step 5
      //
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: terminal,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: terminal,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 496,
          duration: 500,
          begin: activate,
        },
        {
          targets: donut,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },

        // Deactivate
        { targets: client, opacity: 0, duration: 500, complete: deactivate },
      ],

      //
      // Step 3
      //
      [
        {
          targets: terminal,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 100,
          duration: 500,
          complete: shimmer,
        },
        {
          targets: client,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: [-50, 0],
          duration: 500,
          begin: activate,
          complete: shimmer,
        },

        // Deactivate
        {
          targets: donut,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
        {
          targets: tcp,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: tcp,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: [-50, 0],
          duration: 500,
          begin: activate,
          complete: shimmer,
        },

        // Deactivate
        {
          targets: event,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          complete: deactivate,
        },
      ],

      //
      // Step 5
      //
      [
        {
          targets: event,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: [-50, 0],
          duration: 200,
          begin: activate,
          complete: shimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-05 ${className}`}>
        {children}
        <div className="content">
          {/* Client */}
          <div
            className="player client"
            data-active="0"
            ref={node => (this.players.client = node)}
          >
            <PlayerTextBlock
              title={'Client'}
              width="250px"
              showDots={true}
              sections={[
                {
                  title: '192.168.1.140',
                },
              ]}
            />
          </div>

          {/* TCP Stream */}
          <div
            className="player tcp"
            data-active="0"
            ref={node => (this.players.tcp = node)}
          >
            <PlayerTextBlock
              title={'DOWNLOAD LINK'}
              width="250px"
              sections={[
                {
                  title: 'URL',
                  lines: 7,
                  columns: true,
                  animate: true,
                  delay: 1000,
                },
                {
                  title: 'URL',
                  lines: 7,
                  columns: true,
                  animate: true,
                  delay: 1500,
                },
              ]}
            />
          </div>

          {/* Event Subject */}
          <div
            className="player event"
            data-active="0"
            ref={node => (this.players.event = node)}
          >
            <PlayerTextBlock
              title={'Event Subject'}
              width="300px"
              sections={[
                {
                  lines: 3,
                  animate: true,
                  delay: 1000,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                  animate: true,
                  delay: 1500,
                },
              ]}
            />
          </div>

          {/* Computer terminal icon */}
          <div
            className="player terminal"
            data-active="1"
            ref={node => (this.players.terminal = node)}
          >
            <Card IconComponent={Computer} text={'192.168.1.140'} />
          </div>

          {/* Animated donut */}
          <div
            className={`player donut ${this.donut.color}`}
            data-active="1"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
