/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Webinar extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#ffffff',
      size = 100,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M114 16H6C2.692 16 0 18.692 0 22V86C0 89.308 2.692 92 6 92H48V104H36C34.894 104 34 104.896 34 106C34 107.104 34.894 108 36 108H84C85.106 108 86 107.104 86 106C86 104.896 85.106 104 84 104H72V92H114C117.308 92 120 89.308 120 86V22C120 18.692 117.308 16 114 16ZM112 84H8V24H112V84Z"
          fill={color}
        />
        <rect
          opacity="0.5"
          x="8"
          y="24"
          width="104"
          height="60"
          fill="#50C2E3"
        />
        <g clipPath="url(#webinarGroupPath)" fill={color}>
          <path d="M74.5998 43.5997C74.5998 50.5476 68.9479 56.1995 62 56.1995C55.0522 56.1995 49.4003 50.5476 49.4003 43.5997C49.4003 36.6519 55.0522 31 62 31C68.9479 31 74.5998 36.6519 74.5998 43.5997Z" />
          <path d="M85.5377 102.999L86.8802 111.95C86.9702 112.554 87.4895 113.001 88.1 113.001H102.9C103.51 113.001 104.028 112.554 104.12 111.95L107.662 88.3341H112.767C113.447 88.3341 114 87.7816 114 87.1008V63.6675C114 63.136 113.661 62.6661 113.156 62.4971L102.056 58.7971C101.454 58.5973 100.804 58.8822 100.543 59.457L95.4999 70.5544L90.4556 59.4582C90.1942 58.8835 89.5417 58.5998 88.9423 58.7984L78.615 62.2408L71.5686 59.892C70.6902 59.6004 69.7416 60.0162 69.36 60.855L61.9999 77.0511L54.6381 60.8568C54.2565 60.018 53.3043 59.604 52.4295 59.8938L45.3879 62.241L35.0563 58.7971C34.4544 58.5973 33.8045 58.8822 33.543 59.457L28.4999 70.5544L23.4556 59.4582C23.1941 58.8835 22.5417 58.5998 21.9423 58.7984L10.8424 62.4983C10.3392 62.6661 10 63.136 10 63.6675V87.1008C10 87.7816 10.5525 88.3341 11.2333 88.3341H16.3381L19.8802 111.95C19.9702 112.554 20.4895 113.001 21.1 113.001H35.8999C36.5104 113.001 37.0284 112.554 37.1197 111.95L38.4622 102.999H44.2505L49.42 137.465C49.5514 138.347 50.3092 138.999 51.2002 138.999H72.7997C73.6907 138.999 74.4467 138.347 74.5799 137.465L79.7494 102.999H85.5377Z" />
          <path d="M95.5001 56.2666C100.261 56.2666 104.133 52.3939 104.133 47.6333C104.133 42.8727 100.261 39 95.5001 39C90.7394 39 86.8668 42.8727 86.8668 47.6333C86.8668 52.3939 90.7394 56.2666 95.5001 56.2666Z" />
          <path d="M28.5001 56.2666C33.2607 56.2666 37.1334 52.3939 37.1334 47.6333C37.1334 42.8727 33.2607 39 28.5001 39C23.7394 39 19.8668 42.8727 19.8668 47.6333C19.8668 52.3939 23.7394 56.2666 28.5001 56.2666Z" />
        </g>
        <defs>
          <clipPath id="webinarGroupPath">
            <rect
              width="100"
              height="51"
              fill="white"
              transform="translate(10 31)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
