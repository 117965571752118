/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './counter.scss';
import { getColor } from '../helpers';

@observer
export class PlayerCounter extends Component {
  intervalTimer = null;

  defaults = {
    fontSize: 12,
    letterSpacing: 0,
    color: getColor('white'),
    start: 0,
    end: 100,
    interval: 20,
    duration: 4000,
    delay: 0,
  };

  timer = null;
  increment = 0;
  currentValue = 0;
  number = null;

  formatNumber(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  animateNumber() {
    const {
      interval = this.defaults.interval,
      start = this.defaults.start,
      end = this.defaults.end,
      delay = this.defaults.delay,
      duration = this.defaults.duration,
    } = this.props;

    // Calculate jumps
    let difference = end - start;
    let steps = duration / this.defaults.interval;
    this.increment = Math.ceil(difference / steps);

    this.currentValue = start;
    this.timer = setTimeout(() => {
      this.intervalTimer = setInterval(() => {
        this.currentValue += this.increment;
        if (this.currentValue >= end) {
          clearInterval(this.intervalTimer);
          this.currentValue = end;
        }
        if (this.number)
          this.number.innerHTML = this.formatNumber(this.currentValue);
      }, interval);
    }, delay);
  }

  componentDidMount() {
    // Count up
    this.animateNumber();
  }

  render() {
    const {
      children,
      className = '',
      fontSize = this.defaults.fontSize,
      color = this.defaults.color,
    } = this.props;

    let numberStyles = {
      fontSize: fontSize,
      letterSpacing: 0,
      color: getColor(color),
    };

    return (
      <div className={`Player__Counter ${className}`}>
        <div
          className="Player__Counter__Number"
          ref={node => (this.number = node)}
          style={numberStyles}
        ></div>
      </div>
    );
  }
}
