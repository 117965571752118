/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import './slide-pcap.scss';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDataMoving } from '../players/data-moving';
import { PlayerCloudShark } from '../players/cloudshark';
import { PlayerTextLines } from '../players/text-lines';

@observer
export class Slide06 extends AnimatedSlide {
  // Animation players
  players = {
    cloudshark: null,
    dataLeft: null,
    dataRight: null,
  };

  // Run the first animation
  componentDidMount() {
    const { cloudshark, dataRight, dataLeft, textLines } = this.players;

    this.steps = [
      () => {
        this.animateProgress(2000);
        let node = ReactDOM.findDOMNode(dataRight);
        node.style.left = 'auto';
        node.style.right = '40px';
      },
      // Step 2
      () => {
        let node = ReactDOM.findDOMNode(dataRight);
        node.style.left = '40px';
        node.style.right = 'auto';
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: cloudshark,
          opacity: 1,
          translateX: 0,
          duration: 500,
          begin: activate,
        },
        { targets: dataLeft, opacity: 1, translateX: 0, begin: activate },
        { targets: dataRight, opacity: 1, duration: 500, begin: activate },
        {
          targets: textLines,
          opacity: 1,
          translateX: { value: 0, duration: 0 },
          duration: 1000,
          easing: 'linear',
          begin: activate,
        },
      ],

      //
      // Step 2 : SKIP FOR NOW UNTIL FIND A WAY TO ANIMATE AWAY
      //
      // [
      //   { targets: cloudshark, opacity: 0, translateX: -600, duration: 500, delay: 0, end: deactivate, },
      //   { targets: textLines, opacity: 0, translateX: -600, duration: 500, delay: 0, end: deactivate, },
      //   { targets: dataLeft, opacity: 0, translateX: -600, duration: 1000, delay: 0, end: deactivate, },
      // ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-pcap ${className}`}>
        {children}
        <div className="content">
          {/* Text animating */}
          <div
            className="player textLines"
            data-active="0"
            ref={node => (this.players.textLines = node)}
          >
            <PlayerTextLines group="2" />
          </div>

          {/* Left data moving */}
          <div
            className="player data-left"
            data-active="1"
            ref={node => (this.players.dataLeft = node)}
          >
            <PlayerDataMoving wait="0" />
          </div>

          {/* Right data moving */}
          <div
            className="player data-right"
            data-active="1"
            ref={node => (this.players.dataRight = node)}
          >
            <PlayerDataMoving wait="1500" />
          </div>

          {/* CloudShark */}
          <div
            className="player cloudshark"
            data-active="1"
            ref={node => (this.players.cloudshark = node)}
          >
            <PlayerCloudShark wait="1000" duration="4000" />
          </div>
        </div>
      </div>
    );
  }
}
