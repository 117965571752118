/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import {
  activate,
  deactivate,
  shimmer,
  activateShimmer,
  getColor,
} from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { StoryBlock } from '../story-block';
import { PlayerDemoGoals } from '../players/demo-goals';

// Players
import { PlayerPath } from '../players/path';

// SVG
import { CheckCircle } from '../svg/check-circle';
import { Bell } from '../svg/bell';
import { CheckShield } from '../svg/check-shield';
import { Eye } from '../svg/eye';
import { Target } from '../svg/target';
import { Loading } from '../svg/loading';
import { Email } from '../svg/email';
import { Bug } from '../svg/bug';

@observer
export class Slide01 extends AnimatedSlide {
  // Animation players
  players = {
    card1: null,
    card2: null,
    card3: null,
    card4: null,
    line1: null,
    line2: null,
    line3: null,
  };

  // Run the first animation
  componentDidMount() {
    const { card1, card2, card3, card4, line1, line2, line3 } = this.players;

    this.steps = [
      () => {
        this.animateProgress(3000);
        this.autoNext(500);
      },
      () => {},
    ];

    // transform
    const growAnimation = [1.1, 1];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        this.fadeIn(card1, 0),
        this.fadeIn(card2, 500),
        this.fadeIn(card3, 2500),
        this.fadeIn(card4, 3000),
        this.fadeIn(line1, 500),
        this.fadeIn(line2, 1000),
        this.fadeIn(line3, 1500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-01 ${className}`}>
        {children}
        <div className="content">
          {/* -------------------CONTENT------------------- */}
          {/* Story block 1 */}
          <div
            className="player block1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <StoryBlock
              IconComponent={Bug}
              title={'TRU Identifies Log4j Vulnerabilities During Daily Triage'}
              color={this.colors.red}
              description={
                "eSentire's Threat Response Unit (TRU) conducts proactive daily triage reviews of the latest technical news and emerging threat landscape. On December 10th, 2021, Apache disclosed a critical vulnerability in Log4j which was identified during this triage review."
              }
            />
          </div>

          {/* Story block 2 */}
          <div
            className="player block2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <StoryBlock
              IconComponent={Target}
              title={'Vulnerability Has Possibility of Mass Exploitation'}
              color={this.colors.red}
              description={
                "The Log4j vulnerability immediately stood out and was identified as a significant threat due to its ubiquity across most software applications and the possibility of mass exploitation. It is identified that malware deployment is possible. eSentire's TRU team jumped into action to protect our customers from this critical vulnerability. "
              }
            />
          </div>

          {/* Story block 3 */}
          <div
            className="player block3"
            data-active="0"
            ref={node => (this.players.card3 = node)}
          >
            <StoryBlock
              IconComponent={Eye}
              title={
                'eSentire Ensures Customers Can Rest Easy Over The Holidays'
              }
              color={this.colors.blue}
              description={
                'Going into the end December, while other IT teams where getting ready to wrap up the year, the TRU team was hard at work running proactive global threat hunts, pushing new detections to protect the entire customer base, and helping our customers rest easy over the holidays knowing that eSentire was protecting their IT environments and assets from attacks targeting Log4j exploits.'
              }
            />
          </div>
          {/* Story block 4 */}
          <div
            className="player block4"
            data-active="0"
            ref={node => (this.players.card4 = node)}
          >
            <StoryBlock
              IconComponent={Bell}
              title={'Customer Communications'}
              color={this.colors.blue}
              description={
                'eSentire deploys a Customer Threat Advisory on December 10th as well as an update on December 13th with details, remediation actions and risk mitigation recommendations for the Log4j vulnerability. On December 18th, TRU hosts a Threat Intelligence webinar with customers to review of the Log4j exploitation and threat landscape with recommendations on how to protect their business.'
              }
            />
          </div>

          {/* Line 1 */}
          <div
            className="player line1"
            data-active="0"
            ref={node => (this.players.line1 = node)}
          >
            <PlayerPath
              points={['3,0', '3,40']}
              dots={{
                stroke: getColor('red'),
              }}
              reveal={{
                delay: 1000,
              }}
            />
          </div>

          {/* Line 2 */}
          <div
            className="player line2"
            data-active="0"
            ref={node => (this.players.line2 = node)}
          >
            <PlayerPath
              points={['0,370', '0,410', '270,410', '270,3', '540,3', '540,40']}
              gradient={{
                preset: 'blueRed',
                direction: 200,
              }}
              reveal={{
                delay: 1500,
                duration: 1500,
              }}
            />
          </div>

          {/* Line 3 */}
          <div
            className="player line3"
            data-active="0"
            ref={node => (this.players.line3 = node)}
          >
            <PlayerPath
              points={['3,0', '3,40']}
              reveal={{
                delay: 3500,
              }}
            />
          </div>

          {/* -------------------CONTENT------------------- */}
        </div>
      </div>
    );
  }
}
