/** @jsx createElement */
// SVG
import { DiskDrive } from '../svg/disk-drive';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';

@observer
export class Slide20 extends AnimatedSlide {
  // Animation players
  players = {
    computer: null,
    dots: null,
    circle: null,
    animatedDots: null,
  };

  // Run the first animation
  componentDidMount() {
    const { computer, dots, circle, animatedDots } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(5000);
        circle.classList.remove('highlighted');
        animatedDots.classList.remove('blocked');
        this.autoNext(4000);
      },
      // Step 2
      () => {
        circle.classList.add('highlighted');
        animatedDots.classList.add('blocked');
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: computer,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: circle,
          easing: 'easeOutQuad',
          opacity: 0,
          width: 10,
          height: 10,
          duration: 1000,
          complete: deactivate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: dots,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          complete: shimmer,
        },
        {
          targets: animatedDots,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
        },
        {
          targets: circle,
          easing: 'easeOutQuad',
          opacity: 1,
          width: 160,
          height: 160,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`MaliciousIP ${className}`}>
        {children}
        <div className="content">
          {/* Dots */}
          <div className="center-player">
            <div
              className="player dots"
              data-active="1"
              ref={node => (this.players.dots = node)}
            >
              <div
                className="dotContainer"
                ref={node => (this.players.animatedDots = node)}
              >
                <PlayerDots reverse={true} className={'red-dots'} />
                <PlayerDots reverse={true} className={'red-dots'} />
                <PlayerDots reverse={true} className={'red-dots'} />
                <PlayerDots reverse={true} className={'red-dots'} />
                <PlayerDots reverse={true} className={'red-dots'} />
                <PlayerDots reverse={true} className={'red-dots'} />
                <PlayerDots reverse={true} className={'red-dots'} />
                <PlayerDots reverse={true} className={'red-dots'} />
              </div>
              <div
                className="circle"
                ref={node => (this.players.circle = node)}
              />
            </div>
          </div>

          {/* Computer */}
          <div
            className="player computer"
            data-active="1"
            ref={node => (this.players.computer = node)}
          >
            <DiskDrive size={'60'} />
          </div>
        </div>
      </div>
    );
  }
}
