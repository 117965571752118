/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class RDP extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 46,
      x = 0,
      y = 0,
      opacity = 0.3,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd" clipRule="evenodd" opacity={opacity}>
          <path d="M7.71431 9.30987H53.1429V38.514H7.71431V9.30987ZM3.83769 43.8238H57.0195C57.7197 43.8238 58.2857 43.2291 58.2857 42.4963V9.30983C58.2857 6.38146 56.0141 4 53.2208 4H7.63639C4.84308 4 2.57145 6.38146 2.57145 9.30983V42.4963C2.57145 43.2291 3.13746 43.8238 3.83769 43.8238Z" />
          <path d="M58.75 45.5937H36.25C35.5588 45.5937 35 46.1884 35 46.9212V48.2486H25V46.9212C25 46.1884 24.4412 45.5937 23.75 45.5937H1.25C0.55875 45.5937 0 46.1884 0 46.9212V52.231C0 54.4266 1.6825 56.2134 3.75 56.2134H56.25C58.3175 56.2134 60 54.4266 60 52.231V46.9212C60 46.1884 59.4413 45.5937 58.75 45.5937Z" />
          <path d="M28.6103 26.7088L23.948 22.0466L25.7663 20.2283L32.2468 26.7088L25.7663 33.1894L23.948 31.3711L28.6103 26.7088Z" />
          <path d="M32.6754 20.7088L37.3377 16.0466L35.5194 14.2283L29.0389 20.7088L35.5194 27.1894L37.3377 25.3711L32.6754 20.7088Z" />
        </g>
      </svg>
    );
  }
}
