/** @jsx createElement */
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import { PlayerCircleIcon } from '../players/circle-icon';
import { Download2 } from '../svg/download-2';
import { Laptop } from '../svg/laptop';
import { Code } from '../svg/code';
import { Transfer } from '../svg/transfer';
import { CloudUpload } from '../svg/cloud-upload';
import { Dropbox } from '../svg/dropbox';
import { GoogleDrive } from '../svg/google-drive';
import { Gmail } from '../svg/gmail';

@observer
export class Slide07 extends AnimatedSlide {
  // Animation players
  players = {};

  // Run the first animation
  componentDidMount() {
    const {
      dotsLeft,
      dotsRight1,
      dotsRight2,
      dotsRight3,
      computer,
      upload,
      dropbox,
      drive,
      gmail,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(6000);
        this.autoNext(2000);
      },
      // Step 2
      () => {
        this.autoNext(2000);
      },
      // Step 3
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: computer,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 1000,
          translateX: -520,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: dropbox,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 200,
          begin: activateShimmer,
        },
        {
          targets: gmail,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 400,
          begin: activateShimmer,
        },
        {
          targets: drive,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 600,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: upload,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 400,
          begin: activateShimmer,
        },
        {
          targets: dotsLeft,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 200,
          begin: activateShimmer,
        },
        {
          targets: dotsRight1,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 600,
          begin: activateShimmer,
        },
        {
          targets: dotsRight2,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 800,
          begin: activateShimmer,
        },
        {
          targets: dotsRight3,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 1000,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-07 ${className}`}>
        {children}
        <div className="content">
          {/* computer */}
          <div
            className="player computer"
            data-active="1"
            ref={node => (this.players.computer = node)}
          >
            <Card IconComponent={Computer} text={'TSMITH-DESKTOP'} />
          </div>

          {/* Animated dots left */}
          <div
            className="player dots-left"
            data-active="0"
            ref={node => (this.players.dotsLeft = node)}
          >
            <PlayerDots dots={6} className={'collection'} />
          </div>

          {/* Animated dots right 1 */}
          <div
            className="player dots-right-1"
            data-active="0"
            ref={node => (this.players.dotsRight1 = node)}
          >
            <PlayerDots dots={6} className={'collection'} />
          </div>

          {/* Animated dots right 2 */}
          <div
            className="player dots-right-2"
            data-active="0"
            ref={node => (this.players.dotsRight2 = node)}
          >
            <PlayerDots dots={6} className={'collection'} />
          </div>

          {/* Animated dots right 3 */}
          <div
            className="player dots-right-3"
            data-active="0"
            ref={node => (this.players.dotsRight3 = node)}
          >
            <PlayerDots dots={6} className={'collection'} />
          </div>

          {/* upload */}
          <div
            className="player upload"
            data-active="0"
            ref={node => (this.players.upload = node)}
          >
            <PlayerCircleIcon Icon={CloudUpload} />
          </div>

          {/* dropbox */}
          <div
            className="player dropbox"
            data-active="0"
            ref={node => (this.players.dropbox = node)}
          >
            <PlayerCircleIcon color="#50C2E3" Icon={Dropbox} />
          </div>

          {/* Google Drive */}
          <div
            className="player drive"
            data-active="0"
            ref={node => (this.players.drive = node)}
          >
            <PlayerCircleIcon color="#50C2E3" Icon={GoogleDrive} />
          </div>

          {/* GMail */}
          <div
            className="player gmail"
            data-active="0"
            ref={node => (this.players.gmail = node)}
          >
            <PlayerCircleIcon color="#50C2E3" Icon={Gmail} />
          </div>
        </div>
      </div>
    );
  }
}
