/** @jsx createElement */
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { AppActions } from '../app-actions';
import { PlayerTimeline } from '../players/timeline';

@observer
export class Slide15 extends AnimatedSlide {
  // Animation players
  players = {
    timeline: null,
    events: null,
  };

  /*
  Slide 20-25
  Slide 26 Investigation Summary Provided
  */

  timeline = {
    unit: 'day', // day, hour, minute
    units: 5,
    start: 0,
    details: [
      {
        start: '00:00:00:00',
        end: '00:00:01:00',
        description: 'TRU Gets a Positive Hit on a Global Threat Hunt',
        active: true,
      },
      {
        start: '00:00:06:00',
        description:
          'Multi-Signal Investigation Identifies SQUIRRELWAFFLE Malware',
      },
      {
        start: '00:00:08:00',
        description: 'Client Notification',
      },
      {
        start: '00:11:41:00',
        description: 'Security Advisory Published on SQUIRRELWAFFLE',
      },
      {
        start: '04:00:06:00',
        description:
          "FBI + Competitor Alert on Possible Infection in our Client's Network",
      },
      {
        start: '04:00:37:00',
        description: 'Incident Handler Channel is Created',
      },
      {
        start: '04:00:54:00',
        description: 'Threat Hunters Identify Incident as SQUIRRELWAFFLE',
      },
      {
        start: '04:02:01:00',
        description: 'TRU Conducts Retroactive Threat Hunt',
      },
      {
        start: '04:02:58:00',
        description:
          'TRU Concludes Positive Hits Were Already Included in Alert',
      },
      {
        start: '04:03:00:00',
        description: 'TRU Continues Retroactive Hunt to Search for IPv4',
      },
      {
        start: '04:04:13:00',
        end: '04:04:30:00',
        description:
          'TRU Concludes SQUIRRELWAFFLE Was Already Caught & Remediated',
      },
    ],
  };

  // Run the first animation
  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.hideRightArrow();
    AppActions.disableAppScreenshot();

    this.steps = [
      // Step 1
      () => {},
    ];

    this.animations = [
      // Step 1
      [],
    ];

    // Play the first animation
    this.play();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
    AppActions.showRightArrow();
    AppActions.enableAppScreenshot();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-22 timeline-slide ${className}`}>
        {children}
        <div className="content">
          <PlayerTimeline
            events={this.timeline.details}
            unit={this.timeline.unit}
            width={this.timeline.width}
            units={this.timeline.units}
            start={this.timeline.start}
            alternate={this.timeline.alternate}
          />
        </div>
      </div>
    );
  }
}
