/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Gmail extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      size = 32,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 32 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M2.18182 24.0057H7.27273V11.642L0 6.1875V21.8239C0 23.0293 0.976364 24.0057 2.18182 24.0057Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            opacity="0.3"
            d="M24.7273 24.0057H29.8182C31.0237 24.0057 32 23.0293 32 21.8239V6.1875L24.7273 11.642V24.0057Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            opacity="0.6"
            d="M24.7273 2.1877V11.6422L32 6.1877V3.27861C32 0.582243 28.9218 -0.957757 26.7637 0.660425L24.7273 2.1877Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            opacity="0.8"
            d="M7.27271 11.642V2.1875L16 8.73295L24.7272 2.1875V11.642L16 18.1875L7.27271 11.642Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            opacity="0.6"
            d="M0 3.27861V6.1877L7.27273 11.6422V2.1877L5.23636 0.660425C3.07818 -0.957757 0 0.582243 0 3.27861Z"
            fill={color}
            fillOpacity={opacity}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="32"
              height="24.0058"
              fill={color}
              fillOpacity={opacity}
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
