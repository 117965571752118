/** @jsx createElement */
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { createElement, Component } from 'react';
import { RiskChart } from '../../charts/risk-chart';
import './rates.scss';
import { RiskAnalysisToolState } from '../risk-analysis-tool-actions';

const formatPercent = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 0,
}).format;

@observer
export class RiskAnalysisRates extends Component {
  static HORIZONTAL_PADDING = 80;
  static VERTICAL_PADDING = 340;

  @observable height = 540;
  @observable width = 520;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
    setTimeout(this.handleResize, 500);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
  }

  handleOrientationChange = () => {
    setTimeout(this.handleResize, 500);
  };

  handleResize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    this.width = Math.max(
      0,
      (windowWidth - RiskAnalysisRates.HORIZONTAL_PADDING) / 2
    );
    this.height = Math.max(
      0,
      windowHeight - RiskAnalysisRates.VERTICAL_PADDING
    );
  };

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`RiskAnalysisRates ${className}`}>
        <div className="RiskAnalysisRates__Left">
          <h2>
            {RiskAnalysisToolState.filter && RiskAnalysisToolState.filter.label}
          </h2>
          <h1>Cumulative Risk at a Glance</h1>
          <p>
            For each row, the mean probability listed is the actual rate at
            which our SOC found and mitigated one or more security incidents of
            type in question when monitoring sensors deployed in the{' '}
            <span className="capitalize">
              {RiskAnalysisToolState.filter &&
                RiskAnalysisToolState.filter.label}
            </span>{' '}
            demographic over the period. Our sample size of deployed sensors is
            high enough to let us project with the confidence listed that the
            actual risk of that type of incident occurring for a population
            sharing a similar demographic to those we protect would be between
            the upper and lower bounds.
          </p>
          <h2>This Year</h2>
          <table cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th>Attack Type</th>
                <th>Mean</th>
                <th>Lower Bound</th>
                <th>Upper Bound</th>
                <th>Confidence</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Malware</td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Malware || {}).mean
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Malware || {}).min
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Malware || {}).max
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Malware || {}).confidence
                  )}
                </td>
              </tr>
              <tr>
                <td>Phishing</td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Phishing || {}).mean
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Phishing || {}).min
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Phishing || {}).max
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Phishing || {}).confidence
                  )}
                </td>
              </tr>
              <tr>
                <td>Exploit</td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Exploit || {}).mean
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Exploit || {}).min
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Exploit || {}).max
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableYear.Exploit || {}).confidence
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <h2>This Month</h2>
          <table cellSpacing="0" cellPadding="0">
            <thead>
              <tr>
                <th>Attack Type</th>
                <th>Mean</th>
                <th>Lower Bound</th>
                <th>Upper Bound</th>
                <th>Confidence</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Malware</td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Malware || {}).mean
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Malware || {}).min
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Malware || {}).max
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Malware || {}).confidence
                  )}
                </td>
              </tr>
              <tr>
                <td>Phishing</td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Phishing || {}).mean
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Phishing || {}).min
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Phishing || {}).max
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Phishing || {}).confidence
                  )}
                </td>
              </tr>
              <tr>
                <td>Exploit</td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Exploit || {}).mean
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Exploit || {}).min
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Exploit || {}).max
                  )}
                </td>
                <td>
                  {formatPercent(
                    (RiskAnalysisToolState.tableMonth.Exploit || {}).confidence
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="RiskAnalysisRates__Right">
          <div className="RiskAnalysisRates__Chart">
            <RiskChart
              category={
                RiskAnalysisToolState.category &&
                RiskAnalysisToolState.category.value
              }
              industries={
                (RiskAnalysisToolState.comparativeIndustries &&
                  RiskAnalysisToolState.comparativeIndustries.slice(0)) ||
                undefined
              }
              width={this.width}
              height={this.height}
              sensorCount={RiskAnalysisToolState.sensors.value}
            />
          </div>
        </div>
      </div>
    );
  }
}
