/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import './slide-pcap.scss';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDataMoving } from '../players/data-moving';
import { PlayerTextLines } from '../players/text-lines';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Page } from '../svg/page';

@observer
export class Slide07 extends AnimatedSlide {
  // Animation players
  players = {
    dataLeft: null,
    tcp: null,
    file: null,
  };

  // Run the first animation
  componentDidMount() {
    const { dataLeft, tcp, file } = this.players;

    this.steps = [
      // Slide 1
      () => {
        this.animateProgress(4000);
        this.autoNext(2000);
      },
      // Step 2
      () => {
        // Once they've seent he transition, no need to see it again
        this.animations[0][1].delay = 0;
        this.animations[0][2].delay = 0;
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: dataLeft,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
        },
        {
          targets: tcp,
          easing: 'easeOutQuad',
          opacity: 1,
          top: 220,
          duration: 200,
          delay: 3000,
          begin: activateShimmer,
        },
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 1,
          top: 460,
          duration: 200,
          delay: 3000,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        { targets: dataLeft, easing: 'easeOutQuad', opacity: 0, duration: 200 },
        {
          targets: tcp,
          easing: 'easeOutQuad',
          opacity: 0,
          top: 300,
          duration: 200,
          delay: 0,
        },
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 1,
          top: 340,
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let tcpSettings = {
      title: 'TCP STREAM',
      sections: [
        {
          title: 'GET / SCAN / INVOICE HTTP / 1.1',
          lines: 3,
          animate: true,
        },
        {
          title: 'HOST: WWW.EASYPROPERTY.COM',
          lines: 3,
          lineColumns: 2,
          animate: true,
          delay: 3000,
        },
      ],
    };

    let fileSettings = {
      title: 'TCP STREAM',
      actionTitle: 'FILE FOUND',
      icon: <Page size={'45'} />,
      width: 300,
      sections: [
        {
          title: 'FILENAME="Purchase 2017.docx"',
        },
      ],
    };

    return (
      <div className={`slide-pcap2 ${className}`}>
        {children}
        <div className="content">
          {/* File found */}
          <div
            className="player file"
            data-active="0"
            ref={node => (this.players.file = node)}
          >
            <PlayerTextBlock {...fileSettings} />
          </div>

          {/* Text Block */}
          <div
            className="player tcp"
            data-active="1"
            ref={node => (this.players.tcp = node)}
          >
            <PlayerTextBlock {...tcpSettings} />
          </div>

          {/* Left data moving */}
          <div
            className="player data-left"
            data-active="1"
            ref={node => (this.players.dataLeft = node)}
          >
            <PlayerDataMoving wait="0" />
          </div>
        </div>
      </div>
    );
  }
}
