/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';

@observer
export class Slide03 extends AnimatedSlide {
  // Animation players
  players = {
    terminal: null,
    request: null,
  };

  // Run the first animation
  componentDidMount() {
    const { terminal, request } = this.players;

    this.steps = [
      () => {
        this.animateProgress(5000);
        this.autoNext(2000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: terminal,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 496,
          duration: 500,
          begin: activate,
        },

        // Deactivate
        { targets: request, opacity: 0, duration: 500, complete: deactivate },
      ],

      //
      // Step 2
      //
      [
        {
          targets: request,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
          complete: shimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-03 ${className}`}>
        {children}
        <div className="content">
          {/* SOC Request */}
          <div
            className="player request"
            data-active="0"
            ref={node => (this.players.request = node)}
          >
            <PlayerTextBlock
              title={'SOC Request'}
              width="250px"
              showDots={true}
              sections={[
                {
                  title: 'Additional Information',
                },
              ]}
            />
          </div>

          {/* Computer terminal */}
          <div
            className="player terminal"
            data-active="1"
            ref={node => (this.players.terminal = node)}
          >
            <Card IconComponent={Computer} text={'192.168.1.140'} />
          </div>
        </div>
      </div>
    );
  }
}
