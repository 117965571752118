import { Slide01 } from '../../../components/esinsider/slide-01';
import { Slide02 } from '../../../components/esinsider/slide-02';
import { Slide03 } from '../../../components/esinsider/slide-03';
import { Slide04 } from '../../../components/esinsider/slide-04';
import { Slide05 } from '../../../components/esinsider/slide-05';
import { Slide06 } from '../../../components/esinsider/slide-06';
import { Slide07 } from '../../../components/esinsider/slide-07';
import { Slide08 } from '../../../components/esinsider/slide-08';
import { Slide09 } from '../../../components/esinsider/slide-09';
import { Slide10 } from '../../../components/esinsider/slide-10';
import { Slide11 } from '../../../components/esinsider/slide-11';
import { Slide12 } from '../../../components/esinsider/slide-12';
import { Slide13 } from '../../../components/esinsider/slide-13';
import { Slide14 } from '../../../components/esinsider/slide-14';
import { Slide15 } from '../../../components/esinsider/slide-15';
import { Slide16 } from '../../../components/esinsider/slide-16';
import { Slide17 } from '../../../components/esinsider/slide-17';
import { Slide18 } from '../../../components/esinsider/slide-18';
import { Slide19 } from '../../../components/esinsider/slide-19';
import { Slide20 } from '../../../components/esinsider/slide-20';
import { Slide21 } from '../../../components/esinsider/slide-21';
import { Slide22 } from '../../../components/esinsider/slide-22';
import { Slide23 } from '../../../components/esinsider/slide-23';
import { Slide24 } from '../../../components/esinsider/slide-24';
import { Slide25 } from '../../../components/esinsider/slide-25';
import { Slide26 } from '../../../components/esinsider/slide-26';
import { Slide27 } from '../../../components/esinsider/slide-27';
import { Slide28 } from '../../../components/esinsider/slide-28';
import { Eye } from '../../../components/svg/eye';
import { Device } from '../../../components/svg/device';
import { Bell } from '../../../components/svg/bell';
import { PersonCircle } from '../../../components/svg/person-circle';

export const ESINSIDER_CATEGORY = {
  ANALYSIS: 'ANALYSIS',
  INVESTIGATION: 'INVESTIGATION',
  CLIENT_ALERT: 'CLIENT ALERT',
  CLIENT_RESPONSE: 'CLIENT RESPONSE',
};

export const ESINSIDER_COLUMNS = [
  [ESINSIDER_CATEGORY.ANALYSIS],
  [ESINSIDER_CATEGORY.INVESTIGATION],
  [ESINSIDER_CATEGORY.CLIENT_ALERT],
  [ESINSIDER_CATEGORY.CLIENT_RESPONSE],
];

export const ESINSIDER_CATEGORY_ICON = {
  [ESINSIDER_CATEGORY.ANALYSIS]: Eye,
  [ESINSIDER_CATEGORY.INVESTIGATION]: Device,
  [ESINSIDER_CATEGORY.CLIENT_ALERT]: Bell,
  [ESINSIDER_CATEGORY.CLIENT_RESPONSE]: PersonCircle,
};

export const esinsiderSlides = [
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Insider Threat',
    subtitle: 'Scenario Overview',
    component: Slide01,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Attack Behaviors',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'While monitoring, eSentire MDR for Insider Threat looks for attack chain behaviours threat actors cannot obfuscate within the network: reconnaissance, collection and exfiltration.',
    component: Slide02,
    category: ESINSIDER_CATEGORY.ANALYSIS,
    icon: ESINSIDER_CATEGORY_ICON[ESINSIDER_CATEGORY.ANALYSIS],
  },
  {
    title: 'Insider Threat',
    subtitle: 'Insider: Recon',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'In this scenario, user “TSmith” used their work computer “tsmith-desktop” to perform network scans, port scans and reverse DNS lookups on the network.',
    component: Slide03,
    category: ESINSIDER_CATEGORY.ANALYSIS,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Insider: Collection',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      '“TSmith” successfully  discovers two databases “dbserver01” and” dbserver2” and begins to pull sensitive PCI and PII data from them.',
    component: Slide04,
    category: ESINSIDER_CATEGORY.ANALYSIS,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Insider: Collection',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'TSmith then used a notebook computer “eprindle-notebook”, that belonged to a teammate, to access the company’s engineering source code repository, and pulled the sensitive source code to the laptop.',
    component: Slide05,
  },

  {
    title: 'Insider Threat',
    subtitle: 'Insider: Collection',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'TSmith then pulled that data back to their work computer from EPrindle’s notebook.',
    component: Slide06,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Insider: Exfiltration',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'TSmith then performed several exfiltration activities, sending the collected data to external locations on Dropbox, Google Drive and Gmail.',
    component: Slide07,
    category: ESINSIDER_CATEGORY.ANALYSIS,
  },
  {
    title: 'Insider Threat',
    subtitle: 'ThreatCase© Generated',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'Based on the suspicious behaviour of TSmith and all three attack chain behaviours were identified, a ThreatCase© has been generated by eSentire MDR for Insider Threat for a SOC analyst to investigate.',
    component: Slide08,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
    icon: ESINSIDER_CATEGORY_ICON[ESINSIDER_CATEGORY.INVESTIGATION],
  },
  {
    title: 'Insider Threat',
    subtitle: 'ThreatCase©: Overview',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description: '',
    component: Slide09,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'ThreatCase©: Timeline',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'The timeline shows individual events in a ThreatCase© as they relate to activity that occurred during the time window covered by the ThreatCase©. As you click through various areas in the web UI, the timeline will update to show the occurances in the timeline for that particular event or group of activities.',
    component: Slide10,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'ThreatCase©: Network Map',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'The network map shows the relationships between suspicious hosts, internal hosts, and external hosts in a ThreatCase©, along with the directional flow of data that occurred between them.',
    component: Slide11,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Network Scanning',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'In this particular ThreatCase©, the first action TSmith took was to perform network scanning, an attack behaviour identified as reconnaissance.',
    component: Slide12,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Port Scanning',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'TSmith performs a second reconnaissance activity, port scanning.',
    component: Slide13,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'DNS Lookups',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'TSmith performs a third reconnaissance activity, unusual reverse DNS lookups.',
    component: Slide14,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Collection',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'In the next stage of the attack chain, TSmith identifies a target and collects 113.94MB of data from dbserver01 executing the second attack chain behaviour required to trigger the ThreatCase©.',
    component: Slide15,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Collection',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'TSmith identifies another target, and then collects 98.24 MB of data from dbserver02 to tsmith-desktop.',
    component: Slide16,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Exfiltration',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'TSmith exfiltrates data to a location on Dropbox executing on the third attack chain behaviour, exfiltration.',
    component: Slide17,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Recon',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description: 'TSmith performs additional network scanning.',
    component: Slide18,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Exfiltration',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description: 'TSmith exfiltrates data via Google Mail.',
    component: Slide19,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Collection',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'EPrindle collects a large amount of data from a source code repository.',
    component: Slide20,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Collection',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'TSmith collects the source code repository from EPrindle’s notebook to TSmith’s desktop.',
    component: Slide21,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Exfiltration',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description: 'TSmith exfiltrates data to a location on Google Drive.',
    component: Slide22,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'ThreatCase©: Evidence Card',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'This shows the evidence card that describes TSmith exfiltrating data to a location on Google Drive.',
    component: Slide23,
    category: ESINSIDER_CATEGORY.INVESTIGATION,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Host Isolation',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'Hosts identified with the attack behaviors in the ThreatCase© can be isolated based on customer preference.',
    component: Slide24,
    category: ESINSIDER_CATEGORY.CLIENT_ALERT,
    icon: ESINSIDER_CATEGORY_ICON[ESINSIDER_CATEGORY.CLIENT_ALERT],
  },
  {
    title: 'Insider Threat',
    subtitle: 'Client Alert',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'The SOC alerts the customer with the threat summary showing the eSentire MDR for Insider Threat dashboard of data that leads to the isolated host and disabled user account.',
    component: Slide25,
    category: ESINSIDER_CATEGORY.CLIENT_ALERT,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Client Request',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description: 'Customer requires a deeper investigation.',
    component: Slide26,
    category: ESINSIDER_CATEGORY.CLIENT_RESPONSE,
    icon: ESINSIDER_CATEGORY_ICON[ESINSIDER_CATEGORY.CLIENT_RESPONSE],
  },
  {
    title: 'Insider Threat',
    subtitle: 'Deeper Analysis',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'eSentire’s SOC analysts analyze a combination of eSentire MDR for Insider Threat’s ThreatCase, eSentire MDR for Endpoint data and eSentire MDR for Log  data to determine the full extent and forensic understanding of what happened across those attack chain stages, the amount of data exfiltrated and what users were involved.',
    component: Slide27,
    category: ESINSIDER_CATEGORY.CLIENT_RESPONSE,
  },
  {
    title: 'Insider Threat',
    subtitle: 'Threat Summary',
    product: 'eSentire MDR for Insider Threat',
    time: null,
    description:
      'The eSentire SOC provides a full summary of the insider threat and will work with the customer to assist in remediating it.',
    component: Slide28,
    category: ESINSIDER_CATEGORY.CLIENT_RESPONSE,
  },
];
