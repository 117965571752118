/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { Page091 } from './pages/page-09-1';
import { Page101 } from './pages/page-10-1';

@observer
export class Slide12 extends AnimatedSlide {
  // Animation players
  players = {};

  // Run the first animation
  componentDidMount() {
    const { page, page2, arrow, label } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(1000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: page,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 200,
        },
        {
          targets: page2,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 200,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-12 ${className}`}>
        {children}
        <div className="content">
          {/* page1 */}
          <div
            className="player page"
            data-active="1"
            ref={node => (this.players.page = node)}
          >
            <Page091 />
          </div>

          {/* page 2 */}
          <div
            className="player page2"
            data-active="1"
            ref={node => (this.players.page2 = node)}
          >
            <Page101 />
          </div>
        </div>
      </div>
    );
  }
}
