/** @jsx createElement */
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { AppActions } from '../app-actions';
import { PlayerTimeline } from '../players/timeline';

@observer
export class Slide22 extends AnimatedSlide {
  // Animation players
  players = {
    timeline: null,
    events: null,
  };

  /*
  Slide 20-25
  Slide 26 Investigation Summary Provided
  */

  timeline = {
    unit: 'hour',
    units: 24,
    start: 0,
    alternate: true,
    details: [
      {
        start: '00:00:00',
        end: '00:01:00',
        description:
          'Adversary accesses the client via VPN using admin credentials.',
        active: true,
      },
      {
        start: '00:01:00',
        description:
          'Adversary is observed performing a port scan of the network.',
      },
      {
        start: '00:53:00',
        description:
          'Adversary moves laterally within the environment using RDP and stolen credentials.',
      },
      {
        start: '00:57:00',
        description:
          'Malicious PowerShell commands are executed on an internal server.',
      },
      {
        start: '01:07:00',
        description: 'Log data shows adversary created a new account.',
      },
      {
        start: '01:28:00',
        description:
          'Adversary extracts credentials from the server using Mimikatz.',
      },
      {
        start: '01:57:00',
        description: 'eSentire SOC analysts investigate and respond.',
      },
      {
        start: '02:51:00',
        description: 'eSentire SOC analysts shut down the VPN tunnel.',
      },
      {
        start: '02:53:48',
        description:
          'Adversary makes a second attempt to connect using different credentials.',
      },
      {
        start: '03:16:22',
        description:
          'eSentire discovers a second VPN tunnel and works to cut off access.',
      },
      {
        start: '07:33:00',
        description:
          'Adversary returns and attempts to deploy Suncrypt on multiple hosts.',
      },
      {
        start: '07:43:00',
        description:
          'eSentire SOC analysts retrieve a copy of the ransomware payload.',
      },
      {
        start: '07:46:00',
        description:
          'eSentire SOC analysts compare threat with historical threat intelligence.',
      },
      {
        start: '07:53:00',
        description:
          'eSentire SOC analysts block the C2 IP across all client networks.',
      },
      {
        start: '07:55:00',
        description:
          'Adversary realizes they were detected and fails to remove VMWare Carbon Black.',
      },
      {
        start: '08:10:00',
        description:
          'Adversary uploads a Netwalker ransomware binary on the file server. Attempts are blocked by VMWare Carbon Black.',
      },
      {
        start: '11:47:00',
        description:
          'eSentire SOC cyber analysts ban the Suncrypt binary in MDR for Endpoint.',
      },
      {
        start: '14:16:00',
        description:
          'eSentire SOC analysts ban the Netwalker binary in MDR for Endpoint.',
      },
      {
        start: '20:13:00',
        end: '24:00:00',
        description:
          'eSentire SOC analysts complete post-incident review with the client.',
      },
    ],
  };

  // Run the first animation
  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.hideRightArrow();
    AppActions.disableAppScreenshot();

    this.steps = [
      // Step 1
      () => {},
    ];

    this.animations = [
      // Step 1
      [],
    ];

    // Play the first animation
    this.play();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
    AppActions.showRightArrow();
    AppActions.enableAppScreenshot();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-22 timeline-slide ${className}`}>
        {children}
        <div className="content">
          <PlayerTimeline
            events={this.timeline.details}
            unit={this.timeline.unit}
            width={this.timeline.width}
            units={this.timeline.units}
            start={this.timeline.start}
            alternate={this.timeline.alternate}
          />
        </div>
      </div>
    );
  }
}
