/** @jsx createElement */
import { createElement, Component } from 'react';
import './popup.scss';

export class Popup extends Component {
  onOpen = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  render() {
    const { trigger } = this.props;

    return (
      <div
        className="PopupContainer"
        tabIndex={-1}
        onClick={this.onOpen}
        ref={node => (this.node = node)}
      >
        {trigger}
        <div className="PopupContainer__PopupPixel">{this.renderPopup()}</div>
      </div>
    );
  }

  renderPopup() {
    const {
      className = '',
      contentClass = '',
      position = '',
      width = 500,
    } = this.props;

    const marginLeft =
      position === 'top-middle' || position === 'bottom-middle'
        ? -1 * (width / 2)
        : 0;
    const style = {
      width: width,
      marginLeft: marginLeft,
    };

    return (
      <div
        ref={this.loadPopup}
        className={`Popup ${className} ${position ? `Popup--${position}` : ''}`}
        style={style}
      >
        <div
          className={`Popup__QuoteMark ${
            position ? `Popup__QuoteMark--${position}` : ''
          }`}
        />
        <div className={`Popup__Middle ${contentClass}`} tabIndex={-1}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
