/** @jsx createElement */
import { createElement, useState } from 'react';
import './key-value-set.scss';

const formatCurrency = new Intl.NumberFormat('en-US', {
  currency: 'USD',
}).format;

export const KeyValueSet = function(props) {
  const { className = '', set = [] } = props;

  const [editMode, setEditMode] = useState(null);

  const elements = [];
  set.map((keyValue, i) => {
    elements.push(
      <div
        className="KeyValueSet__KeyValue"
        key={i}
        tabIndex={keyValue.isEditable ? i : undefined}
        onFocus={() => keyValue.isEditable && setEditMode(i)}
      >
        <div className="KeyValueSet__Key">{keyValue.key}</div>
        {editMode !== null && editMode === i ? (
          <input
            autoFocus
            name={keyValue.key}
            type="number"
            value={keyValue.value}
            onChange={keyValue.onChange}
            onBlur={() => setEditMode(null)}
          />
        ) : (
          <div className="KeyValueSet__Value">
            {keyValue.isCurrency
              ? `$${formatCurrency(keyValue.value)}`
              : keyValue.value}
          </div>
        )}
      </div>
    );
    if (i < set.length - 1) {
      elements.push(
        <div className="KeyValueSet__Plus" key={i + '-plus'}>
          +
        </div>
      );
    }
  });

  return <div className={`KeyValueSet ${className}`}>{elements}</div>;
};
