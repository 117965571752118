/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class HamburgerIcon extends Component {
  render() {
    const {
      children,
      className = '',
      color = 'white',
      size = 25,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={`HamburgerIcon ${className}`}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 25 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="25" height="2" fill={color} fillOpacity={opacity} />
        <rect y="6" width="25" height="2" fill={color} fillOpacity={opacity} />
        <rect y="12" width="25" height="2" fill={color} fillOpacity={opacity} />
      </svg>
    );
  }
}
