/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerBarrier } from '../players/barrier';
import ReactDOM from 'react-dom';

// SVG
import { Card } from '../svg/card';
import { Skulls } from '../svg/skulls';
import { Computer } from '../svg/computer';
import { Shield } from '../svg/shield';
import { Cloud } from '../svg/cloud';

@observer
export class Slide18 extends AnimatedSlide {
  // Animation players
  players = {
    attackers: null,
    computer: null,
    vmware: null,
    barrier: null,
    particle1: null,
    particle2: null,
    particle3: null,
    particle4: null,
    particle5: null,
    particle6: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      attackers,
      computer,
      vmware,
      barrier,
      particle1,
      particle2,
      particle3,
      particle4,
      particle5,
      particle6,
    } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 0
      () => {
        this.autoNext(4000);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Pause for effect
      ],

      //
      // Step 1
      //
      [
        {
          targets: attackers,
          opacity: 1,
          delay: 0,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: computer,
          opacity: 1,
          delay: 500,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: barrier,
          opacity: 1,
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: vmware,
          opacity: 1,
          delay: 1500,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
      ],

      //
      // Step 2
      //
      [
        // Do nothing
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-18 ${className}`}>
        {children}
        <div className="content">
          {/* Attacker */}
          <div
            className="player attackers"
            data-active="0"
            ref={node => (this.players.attackers = node)}
          >
            <Card IconComponent={Skulls} text={'Attackers'} />
            <div className={'animated-dotted-line reverse red'}></div>
          </div>
          {/* Computer */}
          <div
            className="player computer"
            data-active="0"
            ref={node => (this.players.computer = node)}
          >
            <Card IconComponent={Computer} text={'FILE SERVER'} />
          </div>
          {/* barrier */}
          <div
            className="player barrier"
            data-active="0"
            ref={node => (this.players.barrier = node)}
          >
            <PlayerBarrier color={this.colors.blue} width={160} />
          </div>
          {/* VMWare */}
          <div
            className="player vmware"
            data-active="0"
            ref={node => (this.players.vmware = node)}
          >
            <Shield
              IconComponent={Cloud}
              text={['VMWARE', 'CARBON BLACK']}
              letterSpacing={0}
              IconSize={65}
            />
          </div>
        </div>
      </div>
    );
  }
}
