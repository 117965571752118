/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import ReactDOM from 'react-dom';
import anime from 'animejs';
import './data-scan.scss';

@observer
export class PlayerDataScan extends Component {
  defaults = {
    size: 300,
    height: 300,
    columns: 4,
    increment: 2,
    duration: 2000,
    rows: 40,
    gap: 10,
    blinks: 3,
    blinkInterval: 200,
  };

  gap = [10, 4];

  columns = [
    '40px 1fr 1fr',
    '40px 1fr 1fr 1fr',
    '40px 1fr 1fr 1fr 1fr',
    '40px 1fr 1fr 1fr 1fr 1fr',
  ];

  // Array of row nodes
  rows = [];

  // Keep total rows for the purpose of random selection
  totalRows = 0;

  // Row references
  row = [];

  currentIndex = 0;

  // Starting points for translateY animations
  y1 = 0;
  y2 = 100;

  // The interval
  interval = null;
  blinkInterval = null;

  getRandomNumbers(quantity, max) {
    const arr = [];
    while (arr.length < quantity) {
      let candidateInt = Math.floor(Math.random() * max) + 1;
      if (arr.indexOf(candidateInt) === -1) arr.push(candidateInt);
    }
    return arr;
  }

  blinkRow(node) {
    const { blinkInterval = this.defaults.blinkInterval } = this.props;
    if (!node) return;
    node.classList.add('blink-row');
    setTimeout(() => {
      if (!node) return;
      node.classList.remove('blink-row');
    }, blinkInterval);
  }

  blinkRows() {
    const { blinks = this.defaults.blinks } = this.props;
    // Blink random row
    this.blinkInterval = setInterval(() => {
      let blinkIndexes = this.getRandomNumbers(blinks, this.rows.length);
      blinkIndexes.map(index => {
        this.blinkRow(this.rows[index - 1]);
      });
    }, 200);
  }

  animateUp() {
    const { increment = this.defaults.increment } = this.props;
    let node1 = ReactDOM.findDOMNode(this.row[0]);
    let node2 = ReactDOM.findDOMNode(this.row[1]);
    let y1 = this.y1;
    let y2 = this.y2;
    this.interval = setInterval(() => {
      y1 = y1 - increment;
      y2 = y2 - increment;
      node1.style.transform = 'translateY(' + y1.toString() + '%)';
      node2.style.transform = 'translateY(' + y2.toString() + '%)';
      if (y1 < -100) y1 = 100;
      if (y2 < -100) y2 = 100;
    }, 5);
  }

  startAnimation() {
    // Start animating up
    this.animateUp();
    // Blink rows
    this.blinkRows();
  }

  componentDidMount() {
    // Get object referebces
    this.startAnimation();
  }

  getRandomRow() {
    let column = this.columns[Math.floor(Math.random() * this.columns.length)];
    let columns = column.split(' ');
    return {
      css: column,
      columns: columns.length,
    };
  }

  getRows(rowIndex) {
    const {
      size = this.defaults.size,
      height = this.defaults.height,
      columns = this.defaults.columns,
      rows = this.defaults.rows,
      gap = this.defaults.gap,
    } = this.props;
    // Assuming 10px rows + 10px margin = 20px
    // Then number of rows is size / 20px
    this.totalRows = Math.ceil(rows / 2);
    const rowArray = Array.from(Array(this.totalRows).keys());

    // Allow for vertical and horizontal gap
    if (gap && Array.isArray(gap)) {
      this.gap = gap;
    }
    if (gap && typeof gap == 'number') {
      this.gap[0] = gap;
    }

    // If columns is an array of strings containing grid definitions "40px 1fr 1fr 1fr"
    if (Array.isArray(columns)) {
      this.columns = columns;
    }

    let rowsStyles = {
      gridTemplateRows: `repeat(${this.totalRows}, 1fr)`,
      height: height - gap[0],
      gap: gap[0],
      paddingBottom: gap[0],
    };

    return (
      <div
        className="Player__DataScan__Rows"
        style={rowsStyles}
        ref={node => (this.row[rowIndex] = node)}
      >
        {rowArray.map((row, index) => {
          // Build lines
          let r = this.getRandomRow();
          const cols = Array.from(Array(r.columns).keys());
          let rowStyles = {
            gridTemplateColumns: r.css,
            gap: gap[1],
          };
          return (
            <div
              key={row}
              className={'Player__DataScan__Row '}
              style={rowStyles}
              ref={node => (this.rows[this.currentIndex++] = node)}
            >
              {cols.map(col => {
                return <span key={col}></span>;
              })}
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    const { className = '', width = 180, height = 180 } = this.props;

    const styles = {
      width: width,
      height: height,
    };

    // Create two rows for a smoother animation that doesn't jerk
    return (
      <div className={`Player__DataScan ${className}`} style={styles}>
        {this.getRows(0)}
        {this.getRows(1)}
      </div>
    );
  }
}
