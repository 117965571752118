/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';

// Icons
import { Directory } from '../svg/directory';
import { Page } from '../svg/page';

@observer
export class Slide15 extends AnimatedSlide {
  // Animation players
  players = {
    folder: null,
    filename: null,
    host: null,
    file: null,
  };

  // Run the first animation
  componentDidMount() {
    const { folder, filename, host, file } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
        this.autoNext(1000);
      },
      // Step 2
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [],
      //
      // Step 2
      //
      [
        {
          targets: filename,
          opacity: 1,
          delay: 0,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: host,
          opacity: 1,
          delay: 500,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: file,
          opacity: 1,
          delay: 1000,
          duration: 200,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-15 ${className}`}>
        {children}
        <div className="content">
          {/* File info */}
          <div
            className="player file"
            data-active="0"
            ref={node => (this.players.file = node)}
          >
            <PlayerTextBlock
              title={'FILE INFO'}
              className={'red-top'}
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Host */}
          <div
            className="player host"
            data-active="0"
            ref={node => (this.players.host = node)}
          >
            <PlayerTextBlock
              title={'HOST'}
              className={'red-top'}
              sections={[
                {
                  title: 'sw.symcd.com',
                },
              ]}
            />
          </div>

          {/* filename */}
          <div
            className="player filename"
            data-active="0"
            ref={node => (this.players.filename = node)}
          >
            <PlayerTextBlock
              title={'FILENAME'}
              className={'red-top'}
              showDots={true}
              sections={[
                {
                  title: '6k3mE219UX7o=',
                },
              ]}
            />
          </div>

          {/* Folder */}
          <div
            className="player folder"
            data-active="1"
            ref={node => (this.players.folder = node)}
          >
            <PlayerCircleIcon
              color={'#ffffff'}
              Icon={Page}
              IconSize={'50'}
              showTitle={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
