/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Skull extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#C53D43',
      size = 35,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        width={size}
        height={size}
        viewBox={`0 0 33 37`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          fillOpacity={opacity}
        >
          <path d="M23.3262 24.1302C25.5438 24.1302 27.3479 22.326 27.3479 20.1084C27.3479 17.8908 25.5438 16.0867 23.3262 16.0867C21.1086 16.0867 19.3044 17.8908 19.3044 20.1084C19.3044 22.326 21.1086 24.1302 23.3262 24.1302Z" />
          <path d="M8.8484 16.0867C6.63081 16.0867 4.82666 17.8908 4.82666 20.1084C4.82666 22.326 6.63081 24.1302 8.8484 24.1302C11.066 24.1302 12.8701 22.326 12.8701 20.1084C12.8701 17.8908 11.066 16.0867 8.8484 16.0867Z" />
          <path d="M17.6957 20.1087C17.6957 17.0039 20.2213 14.4783 23.3261 14.4783C26.4309 14.4783 28.9565 17.0039 28.9565 20.1087C28.9565 23.2135 26.4309 25.7391 23.3261 25.7391C20.2213 25.7391 17.6957 23.2135 17.6957 20.1087ZM16.9074 25.7391H16.8913H15.2826C15.0035 25.7391 14.7445 25.5943 14.5981 25.3579C14.4517 25.1206 14.4389 24.8246 14.5627 24.5752L15.3671 22.9665C15.6397 22.4212 16.5342 22.4212 16.806 22.9665L17.546 24.4465C17.6498 24.5817 17.7117 24.7506 17.7117 24.9348C17.7117 25.3788 17.3514 25.7391 16.9074 25.7391ZM8.84783 25.7391C5.74305 25.7391 3.21739 23.2135 3.21739 20.1087C3.21739 17.0039 5.74305 14.4783 8.84783 14.4783C11.9526 14.4783 14.4783 17.0039 14.4783 20.1087C14.4783 23.2135 11.9526 25.7391 8.84783 25.7391ZM30.5524 14.4783C27.4098 14.4783 24.2254 12.2406 24.2254 7.23913C24.2254 6.79513 24.5849 6.43478 25.0297 6.43478C25.4737 6.43478 25.8341 6.79513 25.8341 7.23913C25.8341 11.3904 28.2777 12.8696 30.5652 12.8696L31.853 12.8889C30.3658 5.54598 23.8626 0 16.087 0C8.31857 0 1.81863 5.53552 0.324152 12.8696H1.6087C3.89626 12.8696 6.33987 11.3904 6.33987 7.23913C6.33987 6.79513 6.70022 6.43478 7.14422 6.43478C7.58902 6.43478 7.94857 6.79513 7.94857 7.23913C7.94857 12.2406 4.76415 14.4783 1.6087 14.4783H0.0812392C0.0289565 15.0075 0 15.5432 0 16.087V20.1087C0 24.7168 3.53994 28.5125 8.04348 28.9203V37H12.8696V28.1522C12.8696 27.7082 13.2299 27.3478 13.6739 27.3478C14.1179 27.3478 14.4783 27.7082 14.4783 28.1522V37H17.6957V28.1522C17.6957 27.7082 18.056 27.3478 18.5 27.3478C18.944 27.3478 19.3044 27.7082 19.3044 28.1522V37H24.1304V28.9203C28.634 28.5125 32.1739 24.7168 32.1739 20.1087V16.087C32.1739 15.5521 32.145 15.0236 32.0935 14.5024L30.5524 14.4783Z" />
        </g>
      </svg>
    );
  }
}
