/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { observable } from 'mobx';

// Players
import { PlayerDonut } from '../players/donut';
import { PlayerTextLines } from '../players/text-lines';

// Icons
import { Alert } from '../svg/alert';

@observer
export class Slide13 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    binary: null,
    alert: null,
    border: null,
  };

  @observable
  donut = {
    color: 'blue',
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');

    const { donut, binary, alert, border } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
        this.donut.color = 'blue';
        this.autoNext(2000);
      },
      // Step 2
      () => {
        this.donut.color = 'red';
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: donut,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
        },
        {
          targets: binary,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 0,
        },
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          delay: 0,
          complete: deactivate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: donut,
          easing: 'easeOutQuad',
          opacity: 0.5,
          duration: 500,
          delay: 1000,
        },
        {
          targets: binary,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 500,
          delay: 1000,
        },
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 1500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          delay: 1500,
          easing: 'easeInOutSine',
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`ProcessLocation3 ${className}`}>
        {children}
        <div className="content">
          {/* Alert */}
          <div
            className={`player alert`}
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert title={'Match'} subtitle={'Win32.Emotet Trojan'} />
          </div>

          {/* Binary */}
          <div
            className={`player binary`}
            data-active="1"
            ref={node => (this.players.binary = node)}
          >
            <PlayerTextLines
              characters={'010101010101010101010101010111010101'}
              rows={3}
            />
          </div>

          {/* Donut */}
          <div
            className={`player donut ${this.donut.color}`}
            data-active="1"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
