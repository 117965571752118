import { observer } from 'mobx-react';
import * as React from 'react';
import { Component } from 'react';
import './charts-tab-store.js';
import './charts-tab.scss';
import { RiskChart } from '../charts/risk-chart';
import { ProbabilityInstantChart } from '../charts/probability-instant-chart';
import { ProbabilityCumulativeChart } from '../charts/probability-cumulative-chart';
import { AssetChart } from '../charts/asset-chart';
import { Scenario } from '../../data/graph-data-transforms';

@observer
export class ChartsTab extends Component {
  render() {
    const { className = '' } = this.props;

    return (
      <div className={`ChartsTab ${className}`}>
        <h2>Risk Chart</h2>
        <RiskChart width={500} height={300} />
        <h2>Instant Probability Chart</h2>
        <ProbabilityInstantChart
          width={500}
          height={300}
          series={[
            {
              category: 'Industry',
              filter: 'Biotechnology',
              attackType: 'Malware',
            },
            {
              category: 'Industry',
              filter: 'Biotechnology',
              attackType: 'Phishing',
            },
            {
              category: 'Industry',
              filter: 'Biotechnology',
              attackType: 'Exploit',
            },
          ]}
          isCumulative={false}
          scenario={Scenario.AVERAGE}
          showCumulative={true}
        />
        <h2>Cumulative Probability Chart</h2>
        <ProbabilityCumulativeChart
          width={500}
          height={300}
          series={[
            {
              category: 'Industry',
              filter: 'Biotechnology',
              attackType: 'Malware',
            },
            {
              category: 'Industry',
              filter: 'Biotechnology',
              attackType: 'Phishing',
            },
            {
              category: 'Industry',
              filter: 'Biotechnology',
              attackType: 'Exploit',
            },
          ]}
          scenario={Scenario.AVERAGE}
          showCumulative={true}
        />
        <h2>Asset Chart</h2>
        <AssetChart
          width={500}
          height={300}
          maxBuckets={15}
          filter="Biotechnology"
          category="Industry"
          attackType="Phishing"
        />
      </div>
    );
  }
}
