/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// Players
import { PlayerDots } from '../players/dots';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Shield } from '../svg/shield';
import { Kaseya } from '../svg/kaseya';
import { Page } from '../svg/page';

@observer
export class Slide13 extends AnimatedSlide {
  // Animation players
  players = {
    shield: null,
    dots: null,
    page: null,
    admin: null,
  };

  // Run the first animation
  componentDidMount() {
    const { shield, dots, page, admin } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(2000);
      },
      // Step 2
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: shield,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: dots,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: admin,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2
      //
      [
        {
          targets: page,
          translateX: [0, 280],
          easing: 'linear',
          delay: 0,
          duration: 2000,
          loop: true,
          begin: activate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-13 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* Shield */}
          <div
            className="player shield"
            data-active="0"
            ref={node => (this.players.shield = node)}
          >
            <Shield
              IconComponent={Kaseya}
              IconSize={70}
              letterSpacing={1}
              className="pulse-blue"
              text={['KASEYA VSA']}
            />
          </div>
          {/* Data */}
          <div
            className="player dots"
            data-active="0"
            ref={node => (this.players.dots = node)}
          >
            <PlayerDots
              className="left-to-right"
              width={300}
              dots={20}
              duration={5}
              color={this.colors.blue}
            />
          </div>
          {/* Page */}
          <div
            className="player page"
            data-active="0"
            ref={node => (this.players.page = node)}
          >
            <Page size={70} />
          </div>
          {/* SOC admin */}
          <div
            className="player admin"
            data-active="0"
            ref={node => (this.players.admin = node)}
          >
            <Card IconComponent={Person} text="ADMIN" />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
