/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Attachment extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      width = 114,
      height = 80,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={width}
        height={height}
        viewBox="0 0 114 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3298_141323)" opacity={opacity}>
          <rect width="114" height="60" fill="white" fillOpacity="0.1" />
          <rect x="6" y="9" width="56" height="2" fill="#C4C4C4" />
          <rect x="6" y="13" width="48" height="2" fill="#C4C4C4" />
          <rect x="6" y="20" width="65" height="2" fill="#C4C4C4" />
          <rect x="10" y="24" width="53" height="2" fill="#C4C4C4" />
          <rect x="6" y="24" width="2" height="2" fill="#C4C4C4" />
          <rect x="10" y="28" width="53" height="2" fill="#C4C4C4" />
          <rect x="10" y="36" width="50" height="2" fill="#C4C4C4" />
          <rect x="10" y="44" width="54" height="2" fill="#C4C4C4" />
          <rect x="10" y="48" width="34" height="2" fill="#C4C4C4" />
          <rect x="10" y="40" width="38" height="2" fill="#C4C4C4" />
          <rect x="50" y="40" width="38" height="2" fill="#C4C4C4" />
          <rect x="10" y="32" width="28" height="2" fill="#C4C4C4" />
          <rect x="6" y="28" width="2" height="2" fill="#C4C4C4" />
          <rect x="6" y="36" width="2" height="2" fill="#C4C4C4" />
          <rect x="6" y="44" width="2" height="2" fill="#C4C4C4" />
          <rect x="40" y="32" width="28" height="2" fill="#C4C4C4" />
          <rect x="6" y="48" width="2" height="2" fill="#C4C4C4" />
          <rect x="6" y="40" width="2" height="2" fill="#C4C4C4" />
          <rect x="6" y="32" width="2" height="2" fill="#C4C4C4" />
          <path d="M103 80L103 69L92 80L103 80Z" fill="black" />
          <path d="M0 60H114V69L103 80H0V60Z" fill="white" fillOpacity="0.2" />
          <path
            d="M0.5 60.5H113.5V68.7929L102.793 79.5H0.5V60.5Z"
            stroke="white"
            strokeOpacity="0.2"
          />
          <rect x="5" y="65" width="20" height="10" fill="#C4C4C4" />
          <path
            d="M7.37109 73V66.999H10.291C10.418 66.999 10.5498 67.0202 10.6865 67.0625C10.8265 67.1048 10.9437 67.1602 11.0381 67.2285C11.1097 67.2839 11.2057 67.3734 11.3262 67.4971C11.4466 67.6208 11.5312 67.7152 11.5801 67.7803C11.707 67.9528 11.7705 68.1725 11.7705 68.4395V69.2891C11.7705 69.5397 11.707 69.7562 11.5801 69.9385C11.5378 70.0068 11.4548 70.1012 11.3311 70.2217C11.2106 70.3421 11.113 70.4284 11.0381 70.4805C10.8298 70.6335 10.5807 70.71 10.291 70.71H8.5918V73H7.37109ZM8.5918 69.5381H10.291C10.3073 69.5381 10.348 69.5104 10.4131 69.4551C10.4782 69.3965 10.5238 69.3444 10.5498 69.2988V68.4102C10.5238 68.3678 10.4847 68.3239 10.4326 68.2783C10.3838 68.2295 10.3366 68.1937 10.291 68.1709H8.5918V69.5381ZM12.7129 73V66.999H13.9629L15.8818 70.5781V66.999H17.1025V73H15.8721L13.9336 69.4697V73H12.7129ZM18.3477 71.5303V68.4883C18.3477 68.2344 18.4046 68.0244 18.5186 67.8584C18.5967 67.7575 18.7122 67.6273 18.8652 67.4678C19.0215 67.3083 19.1484 67.1992 19.2461 67.1406C19.4577 67.0202 19.6774 66.96 19.9053 66.96H21.4287C21.8975 66.96 22.3337 67.2399 22.7373 67.7998C22.8708 67.9821 22.9831 68.1953 23.0742 68.4395L21.9463 68.7617C21.8747 68.6152 21.8014 68.4964 21.7266 68.4053C21.6517 68.3141 21.5492 68.223 21.4189 68.1318H19.959C19.7962 68.2393 19.666 68.3678 19.5684 68.5176V71.5498C19.5684 71.5758 19.6107 71.6296 19.6953 71.7109C19.7799 71.7891 19.8597 71.846 19.9346 71.8818H21.3945C21.4694 71.8493 21.5508 71.7907 21.6387 71.7061C21.7298 71.6182 21.7754 71.5563 21.7754 71.5205V70.6221H20.4082V69.4795H22.9961V71.7305C22.9961 71.8314 23.0189 72.0218 23.0645 72.3018L23.1865 73.0098H21.9756L21.9365 72.8291C21.9268 72.8324 21.8828 72.86 21.8047 72.9121C21.6419 73.0033 21.5133 73.0488 21.4189 73.0488H19.9053C19.6872 73.0488 19.4674 72.9854 19.2461 72.8584C19.1452 72.7933 19.0166 72.6859 18.8604 72.5361C18.7074 72.3831 18.5934 72.2546 18.5186 72.1504C18.4046 71.9714 18.3477 71.7646 18.3477 71.5303Z"
            fill="#313538"
          />
          <path d="M103 69L103 80L114 69L103 69Z" fill="#6A6D70" />
        </g>
        <defs>
          <clipPath id="clip0_3298_141323">
            <rect width="114" height="80" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
