/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// Players
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { KaseyaFile } from '../svg/kaseya-file';

@observer
export class Slide14 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    file: null,
    info1: null,
    info2: null,
    info3: null,
  };

  // Run the first animation
  componentDidMount() {
    const { donut, file, info1, info2, info3 } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(2000);
      },
      // Step 2
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: donut,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: info1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: file,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 800,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: info2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: info3,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2
      //
      [],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-14 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* Info1 */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerTextBlock
              title="INVESTIGATION"
              width={260}
              compact={true}
              className={''}
              sections={[
                {
                  title: ['10ec4c5b19b88a5e1b7bf1e3a9b43c12'],
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['Analyzing Kaseya Detection Tool'],
                  className: 'action',
                  animate: true,
                  delay: 500,
                },
              ]}
            />
          </div>
          {/* Donut */}
          <div
            className={`player donut`}
            data-active="0"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
          {/* Kaseya file */}
          <div
            className="player file"
            data-active="0"
            ref={node => (this.players.file = node)}
          >
            <KaseyaFile size={140} />
          </div>
          {/* Info2 */}
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerTextBlock
              title={'IoCs'}
              width={260}
              compact={true}
              className={''}
              sections={[
                {
                  title: ['Kaseyawebpagesmanagedfiles\vsaticketfilesagent.crt'],
                  multiline: true,
                  className: 'normal-text',
                  animate: true,
                  delay: 0,
                },
                {
                  title: ['Kaseyawebpagesmanagedfiles\vsaticketfilesagent.exe'],
                  multiline: true,
                  className: 'normal-text',
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['10ec4c5b19b88a5e1b7bf1e3a9b43c12'],
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['BINARY', 'AGENT.EXE'],
                  compact: true,
                  animate: true,
                  delay: 600,
                },
                {
                  title: ['FOLDER', 'C:WORKING'],
                  compact: true,
                  animate: true,
                  delay: 800,
                },
              ]}
            />
          </div>
          {/* Info3 */}
          <div
            className="player info3"
            data-active="0"
            ref={node => (this.players.info3 = node)}
          >
            <PlayerTextBlock
              title={'DETECTED ACTIVITY'}
              width={260}
              compact={true}
              className={'red-top'}
              sections={[
                {
                  title: ['script 1', 'scanning vsa server'],
                  colors: [null, this.colors.red],
                  compact: true,
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['script 2', 'scanning endpoints'],
                  colors: [null, this.colors.red],
                  compact: true,
                  animate: true,
                  delay: 400,
                },
              ]}
            />
          </div>
          {/* ------------------ END OF CONTENT ------------------*/}
        </div>
      </div>
    );
  }
}
