/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Stack extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 44,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 48 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17 7H46C47.104 7 48 6.104 48 5C48 3.896 47.104 3 46 3H17C15.896 3 15 3.896 15 5C15 6.104 15.896 7 17 7Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46 19H17C15.896 19 15 19.896 15 21C15 22.104 15.896 23 17 23H46C47.104 23 48 22.104 48 21C48 19.896 47.104 19 46 19Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46 35H17C15.896 35 15 35.896 15 37C15 38.104 15.896 39 17 39H46C47.104 39 48 38.104 48 37C48 35.896 47.104 35 46 35Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 0H1C0.447 0 0 0.447 0 1V9C0 9.553 0.447 10 1 10H9C9.553 10 10 9.553 10 9V1C10 0.447 9.553 0 9 0Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 16H1C0.447 16 0 16.447 0 17V25C0 25.553 0.447 26 1 26H9C9.553 26 10 25.553 10 25V17C10 16.447 9.553 16 9 16Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 32H1C0.447 32 0 32.447 0 33V41C0 41.553 0.447 42 1 42H9C9.553 42 10 41.553 10 41V33C10 32.447 9.553 32 9 32Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
