/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { observable } from 'mobx';

@observer
export class Slide09 extends AnimatedSlide {
  // Animation players
  players = {
    terminal: null,
    host: null,
    http: null,
    stream: null,
  };

  // Run the first animation
  componentDidMount() {
    const { terminal, host, http, stream } = this.players;

    this.steps = [
      () => {
        this.animateProgress(5000);
        this.autoNext(2000);
      },
      () => {
        this.autoNext(2000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: terminal,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: host,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },

        // Deactivate
        { targets: stream, opacity: 0, duration: 500, complete: deactivate },
      ],

      //
      // Step 2
      //
      [
        {
          targets: stream,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },

        // Deactivate
        { targets: http, opacity: 0, duration: 500, complete: deactivate },
      ],

      //
      // Step 3
      //
      [
        {
          targets: http,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-09 ${className}`}>
        {children}
        <div className="content">
          {/* Permanent http */}
          <div
            className="player http"
            data-active="0"
            ref={node => (this.players.http = node)}
          >
            <PlayerTextBlock
              className={'red-top'}
              title={'Http Info'}
              width="300px"
              sections={[
                {
                  lines: 5,
                  animate: true,
                  delay: 100,
                },
                {
                  lines: 2,
                  lineColumns: 2,
                  animate: true,
                  delay: 300,
                },
              ]}
            />
          </div>

          {/* stream */}
          <div
            className="player stream"
            data-active="0"
            ref={node => (this.players.stream = node)}
          >
            <PlayerTextBlock
              className={'red-top'}
              title={'Stream Info'}
              width="300px"
              sections={[
                {
                  lines: 3,
                  animate: true,
                  delay: 100,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                  animate: true,
                  delay: 300,
                },
              ]}
            />
          </div>

          {/* Host */}
          <div
            className="player host"
            data-active="1"
            ref={node => (this.players.host = node)}
          >
            <PlayerTextBlock
              className={'red-top'}
              title={'Host'}
              width="250px"
              showDots={true}
              sections={[
                {
                  title: ['Hjhqmbxyinislkkt.1j43kf.top'],
                  color: 'red',
                  colorCells: 1,
                },
              ]}
            />
          </div>

          {/* Computer terminal icon */}
          <div
            className="player terminal"
            data-active="1"
            ref={node => (this.players.terminal = node)}
          >
            <Card IconComponent={Computer} text={'HOST'} />
          </div>
        </div>
      </div>
    );
  }
}
