/** @jsx createElement */
// SVG
import { Page } from '../svg/page';
import { Eye } from '../svg/eye';
import { NewDocument } from '../svg/new-document';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerCircleIcon } from '../players/circle-icon';

@observer
export class Slide16 extends AnimatedSlide {
  // Animation players
  players = {
    page: null,
    file: null,
    eye: null,
    doc: null,
  };

  // Run the first animation
  componentDidMount() {
    const { page, file, eye, doc } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(5000);
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(2000);
      },
      // Step 3
      () => {
        this.autoNext(2000);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: page,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: eye,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: eye,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: doc,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: doc,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`SandboxFindings2 ${className}`}>
        {children}
        <div className="content">
          {/* File mod */}
          <div
            className="player file"
            data-active="0"
            ref={node => (this.players.file = node)}
          >
            <PlayerTextBlock
              title="Filename"
              showDots={true}
              className={'red-top'}
              sections={[
                {
                  title: 'PURCHASE2017.DOC',
                },
              ]}
            />
          </div>

          {/* Eye */}
          <div
            className="player eye"
            data-active="0"
            ref={node => (this.players.eye = node)}
          >
            <PlayerCircleIcon
              Icon={Eye}
              showDots={true}
              rotate={'0deg'}
              title={'Detected emerging threats'}
            />
          </div>

          {/* Document */}
          <div
            className="player document"
            data-active="0"
            ref={node => (this.players.doc = node)}
          >
            <PlayerCircleIcon
              Icon={NewDocument}
              showDots={true}
              title={'Document spawns new processes'}
            />
          </div>

          {/* Page */}
          <div
            className="player page"
            data-active="1"
            ref={node => (this.players.page = node)}
          >
            <PlayerCircleIcon Icon={Page} showTitle={false} color={'#ffffff'} />
          </div>
        </div>
      </div>
    );
  }
}
