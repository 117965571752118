/** @jsx createElement */
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { createElement, Component, HTMLAttributes } from 'react';
import './progress-bar.scss';

@observer
export class PlayerProgressBar extends Component {
  defaults = {
    background: '#ffffff',
    duration: 10000,
    delay: 0,
  };

  percent = 0;
  complete = null;

  animateBar() {
    const {
      duration = this.defaults.duration,
      delay = this.defaults.delay,
    } = this.props;
    anime({
      targets: this.complete,
      width: [0, 430],
      easing: 'linear',
      delay: delay,
      duration: duration,
      update: a => {
        this.percent = Math.round(a.progress);
        this.forceUpdate();
      },
      complete: shimmer,
    });
  }

  componentDidMount() {
    this.animateBar();
  }

  render() {
    const {
      background = this.defaults.background,
      color = this.defaults.color,
      size = this.defaults.size,
      stroke = this.defaults.stroke,
      progress = this.defaults.progress,
    } = this.props;

    return (
      <div className={'Player__ProgressBar'}>
        <div className={'Player__ProgressBar__Percent'}>
          <span>{this.percent}</span>
          <span>%</span>
        </div>
        <div
          className={'Player__ProgressBar__Complete'}
          ref={node => (this.complete = node)}
        ></div>
        <div className={'Player__ProgressBar__Background'}></div>
      </div>
    );
  }
}
