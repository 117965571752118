/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// SVG
import { Person } from '../svg/person';
import { ShieldBlank } from '../svg/shield-blank';
import { Card } from '../svg/card';
import { Skull } from '../svg/skull';

// PLAYERS
import { PlayerPath } from '../players/path';
import { PlayerDataScan } from '../players/data-scan';

@observer
export class Slide10 extends AnimatedSlide {
  // Animation players
  players = {
    card: null,
    path1: null,
    path2: null,
    scanner: null,
    title: null,
    row1: null,
    row2: null,
    row3: null,
  };

  fadeIn(target, delay = 0) {
    return {
      targets: target,
      opacity: 1,
      easing: 'easeOutQuad',
      delay: delay,
      duration: 500,
      begin: activate,
      complete: activateShimmer,
    };
  }

  // Run the first animation
  componentDidMount() {
    const {
      card,
      path1,
      path2,
      scanner,
      title,
      row1,
      row2,
      row3,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(15000);
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(card, 200),
        this.fadeIn(path1, 500),
        this.fadeIn(scanner, 1000),
        this.fadeIn(path2, 1200),
        this.fadeIn(title, 1400),
        this.fadeIn(row1, 2000),
        this.fadeIn(row2, 3000),
        this.fadeIn(row3, 4000),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let offset = 200;

    return (
      <div className={`slide-10 ${className}`}>
        {children}
        <div className="content">
          {/*---------- CONTENT START ----------*/}
          {/* Card 1 */}
          <div
            className="player card"
            data-active="0"
            ref={node => (this.players.card = node)}
          >
            <Card IconComponent={Person} text={'THREAT HUNTERS'} />
          </div>
          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,3', '100,3']}
              gradient={{
                direction: 'left',
                preset: 'blueWhite',
              }}
              data={false}
              reveal={this.revealPreset(500 + offset)}
            />
          </div>
          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,60', '60,60', '60,3', '120,3']}
              data={false}
              reveal={this.revealPreset(1700 + offset)}
            />
          </div>
          {/* Scanner */}
          <div
            className="player scanner"
            data-active="0"
            ref={node => (this.players.scanner = node)}
          >
            <div className="scannerTitle top">
              <span className="titleBorder">
                <ShieldBlank size={14} opacity={1} />
                EVALUATING EVIDENCE
              </span>
            </div>
            <PlayerDataScan
              columns={[
                '20px 1fr 1fr',
                '20px 1fr 1fr 1fr',
                '20px 1fr 1fr 1fr 1fr',
                '20px 2fr 1fr',
                '20px 2fr 1.5fr 1fr',
                '20px 1fr 1.5fr 2fr 1fr',
              ]}
              gap={4}
              size={180}
              width={180}
              height={180}
              rows={30}
              increment={0.3}
            />
          </div>
          {/* Title */}
          <div
            className="player title"
            data-active="0"
            ref={node => (this.players.title = node)}
          >
            MATCHES
          </div>
          {/* row1 */}
          <div
            className="player row row1"
            data-active="0"
            ref={node => (this.players.row1 = node)}
          >
            <Skull size={14} color={this.colors.white} />
            ROW 6253
          </div>
          {/* row2 */}
          <div
            className="player row row2"
            data-active="0"
            ref={node => (this.players.row2 = node)}
          >
            <Skull size={14} color={this.colors.white} />
            ROW 6254
          </div>
          {/* row3 */}
          <div
            className="player row row3"
            data-active="0"
            ref={node => (this.players.row3 = node)}
          >
            <Skull size={14} color={this.colors.white} />
            ROW 6255
          </div>
          {/*---------- CONTENT END ----------*/}
        </div>
      </div>
    );
  }
}
