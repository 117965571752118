/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { Router, RouteManager } from 'vega-router';
import { SOCCalculatorLeftNav } from './left-nav/left-nav';
import { AppActions } from '../app-actions';
import { HamburgerIcon } from '../svg/hamburger-icon';
import { EsentireIcon } from '../svg/esentire-icon';
import './soc-calculator.scss';

// Import these after ./soc-calculator.scss to override styles properly
import { SOCCalculatorDetail } from './detail/detail';
import { SOCCalculatorGraph } from './graph/graph';
import { SOCCalculatorSummary } from './summary/summary';

@observer
export class SOCCalculator extends Router {
  constructor(props) {
    super(props);

    this.addRoute('detail', /^\/soc\/detail/, SOCCalculatorDetail);
    this.addRoute('graph', /^\/soc\/graph/, SOCCalculatorGraph);

    this.contentFrame = null;
    this.contentFrameContainer = null;

    // Must be last
    this.addRoute('summary', /^\/(.*)/, SOCCalculatorSummary);
  }

  componentDidMount() {
    AppActions.setShortcutName('SOC Calculator');
    AppActions.hideBackgroundGrid();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
  }

  render() {
    const { className = '' } = this.props;

    const title = 'DIY SOC Calculator';
    const subtitle = 'What does it cost to run your SOC in-house?';

    return (
      <div className={`SOCCalculator ${className}`}>
        <div className="SOCCalculator__MainMenu">
          <button
            className="HamburgerButton"
            onClick={() => RouteManager.transitionTo('/')}
          >
            <HamburgerIcon color="#fafafa" opacity={0.7} />
          </button>
        </div>
        <div className="SOCCalculator__Logo">
          <EsentireIcon />
        </div>
        <div className="SOCCalculator__Title">{title}</div>
        <div className="SOCCalculator__Subtitle">{subtitle}</div>
        <SOCCalculatorLeftNav className="SOCCalculator__LeftNav"></SOCCalculatorLeftNav>
        <div className="SOCCalculator__Middle FadeIn">
          <div className="SOCCalculator__Tabs">
            <div
              className={`SOCCalculator__Tab ${
                this.route.name === 'graph' ? 'SOCCalculator__Tab--active' : ''
              }`}
              onClick={() => RouteManager.transitionTo('/soc/graph')}
            >
              Graph
            </div>
            <div
              className={`SOCCalculator__Tab ${
                this.route.name === 'detail' ? 'SOCCalculator__Tab--active' : ''
              }`}
              onClick={() => RouteManager.transitionTo('/soc/detail')}
            >
              Detail
            </div>
            <div
              className={`SOCCalculator__Tab ${
                this.route.name === 'summary'
                  ? 'SOCCalculator__Tab--active'
                  : ''
              }`}
              onClick={() => RouteManager.transitionTo('/soc/summary')}
            >
              Summary
            </div>
          </div>
          <div className="SOCCalculator__Content">{super.render()}</div>
        </div>
      </div>
    );
  }
}
