/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import ReactDOM from 'react-dom';
import { Stage } from '../stage';
import anime from 'animejs';

// Players
import { PlayerCircle } from '../players/circle';

// SVG
import { Team } from '../svg/team';

@observer
export class Slide07 extends AnimatedSlide {
  // Animation players
  players = {
    team1: null,
    team2: null,
    team3: null,
    circle: null,
    dots1: null,
    dots2: null,
    dots3: null,
    border1: null,
    border2: null,
    border3: null,
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;
    // Load borders for animation
    this.players.border1 = this.players.team1.querySelector('.Icon__Team');
    this.players.border2 = this.players.team2.querySelector('.Icon__Team');
    this.players.border3 = this.players.team3.querySelector('.Icon__Team');
    const {
      team1,
      team2,
      team3,
      circle,
      dots1,
      dots2,
      dots3,
      border1,
      border2,
      border3,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      // Step 1 - Reveal teams
      () => {
        this.autoNext(2000);
      },
      // Step 2 - Enable communication
      () => {
        this.autoNext(500);
      },
      // Step 3
      () => {
        if (!this._isMounted) return;
        border1.classList.add('blue-border');
        dots1.classList.add('show');
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 4
      () => {
        if (!this._isMounted) return;
        border2.classList.add('blue-border');
        dots2.classList.add('show');
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 5
      () => {
        if (!this._isMounted) return;
        border3.classList.add('blue-border');
        dots3.classList.add('show');
        this.forceUpdate();
        this.autoNext(1000);
      },
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: team1,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: team2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 200,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: team3,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 400,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2
      //
      [
        {
          targets: circle,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
      //
      // Step 3
      //
      [
        {
          targets: circle,
          easing: 'easeOutQuad',
          duration: 200,
          begin: shimmer,
        },
        {
          targets: dots1,
          easing: 'easeOutQuad',
          duration: 200,
          begin: shimmer,
        },
        {
          targets: team1,
          easing: 'easeOutQuad',
          duration: 200,
          begin: shimmer,
        },
      ],
      //
      // Step 4
      //
      [
        {
          targets: circle,
          easing: 'easeOutQuad',
          duration: 200,
          begin: shimmer,
        },
        {
          targets: dots2,
          easing: 'easeOutQuad',
          duration: 200,
          begin: shimmer,
        },
        {
          targets: team2,
          easing: 'easeOutQuad',
          duration: 200,
          begin: shimmer,
        },
      ],
      //
      // Step 5
      //
      [
        {
          targets: circle,
          easing: 'easeOutQuad',
          duration: 200,
          begin: shimmer,
        },
        {
          targets: dots3,
          easing: 'easeOutQuad',
          duration: 200,
          complete: shimmer,
        },
        {
          targets: team3,
          easing: 'easeOutQuad',
          duration: 200,
          begin: shimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-07 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* Team 1 */}
          <div
            className="player team1"
            data-active="0"
            ref={node => (this.players.team1 = node)}
          >
            <Team text="Elite Threat Hunters" />
          </div>
          {/* Team 2 */}
          <div
            className="player team2"
            data-active="0"
            ref={node => (this.players.team2 = node)}
          >
            <Team text="SOC Cyber Analysts" />
          </div>
          {/* Team 3 */}
          <div
            className="player team3"
            data-active="0"
            ref={node => (this.players.team3 = node)}
          >
            <Team text="Executive Leadership" />
          </div>
          {/* Large circle */}
          <div
            className="player circle"
            data-active="0"
            ref={node => (this.players.circle = node)}
          >
            <PlayerCircle size={80} strokeWidth={4} />
            <div
              ref={node => (this.players.dots1 = node)}
              className={'animated-dotted-line blue ants-1'}
            ></div>
            <div
              ref={node => (this.players.dots3 = node)}
              className={'animated-dotted-line reverse blue ants-2'}
            ></div>
            <div
              ref={node => (this.players.dots2 = node)}
              className={'animated-dotted-line blue ants-3'}
            ></div>
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
