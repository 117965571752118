/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Bell extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      size = 41,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 36 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6862 37.1148C13.6862 39.2624 15.4238 41 17.5714 41C19.719 41 21.4567 39.2624 21.4567 37.1148H13.6862ZM31.0038 28.9343V17.5714C31.0038 11.2262 26.611 5.91571 20.6757 4.51V3.10429C20.6757 1.38619 19.2895 0 17.5714 0C15.8533 0 14.4671 1.38619 14.4671 3.10429V4.51C8.5319 5.91571 4.13905 11.2262 4.13905 17.5714V28.9343L0 33.0733V35.1429H35.1429V33.0733L31.0038 28.9343Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
