/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { Router, RouteManager } from 'vega-router';
import {
  ComplianceAlignmentState,
  DESCRIPTIONS,
} from '../compliance-alignment-actions';
import { BackArrow } from '../../svg/back-arrow';
import { Eye } from '../../svg/eye';
import { FingerPrint } from '../../svg/finger-print';
import { Bell } from '../../svg/bell';
import { CheckShield } from '../../svg/check-shield';
import { Refresh } from '../../svg/refresh';
import './detail.scss';

/**
 * We have old spreadsheets we parsed to produce correct associations between
 * data. The safest way to produce different titles will be transforming the
 * labels on the fly.
 */
function getProductName(name) {
  switch (name.toLocaleLowerCase()) {
    case 'esendpoint':
      return 'eSentire MDR for Endpoint';
    case 'esnetwork':
      return 'eSentire MDR for Network';
    case 'eslog+':
      return 'eSentire MDR for Log';
    case 'esinsider':
      return 'eSentire MDR for Insider Threat';
    case 'escloud':
      return 'eSentire MDR for Cloud';

    default:
      return name;
  }
}

@observer
export class ComplianceAlignmentDetail extends Router {
  componentDidMount() {
    ComplianceAlignmentState.title = 'Compliance Alignment';
    ComplianceAlignmentState.subtitle = 'Coverage Details';
  }

  isDisabled(item, active) {
    return !item || !active || !active.find(active => active.key === item.key);
  }

  render() {
    const { className = '' } = this.props;

    const {
      data: { functions = {} },
      activeFunctions,
      activeCoverages,
      activeProducts,
    } = ComplianceAlignmentState;

    return (
      <div className={`ComplianceAlignmentDetail ${className}`}>
        <div className="ComplianceAlignmentDetail__Header">
          <div
            className="ComplianceAlignmentDetail__Back"
            onClick={() => RouteManager.transitionTo('/compliance')}
          >
            <BackArrow size={24} opacity={0.5} /> Back to Coverage Selection
          </div>
        </div>
        <div className="ComplianceAlignmentDetail__Section">
          <div className="ComplianceAlignmentDetail__H1 ComplianceAlignmentDetail__H1--functions">
            Functions Covered{' '}
            <div className="ComplianceAlignmentDetail__Count">
              {ComplianceAlignmentState.activeFunctions.length}
            </div>
          </div>
          <div className="ComplianceAlignmentDetail__Functions">
            {Object.values(functions).map(item => {
              let icon = null;
              switch (item.key) {
                case 'identify':
                  icon = <Eye size={40} color="#F05938" />;
                  break;
                case 'protect':
                  icon = <FingerPrint size={40} color="#F05938" />;
                  break;
                case 'detect':
                  icon = <Bell size={40} color="#F05938" />;
                  break;
                case 'respond':
                  icon = <Refresh size={40} color="#F05938" />;
                  break;
                case 'recover':
                  icon = <CheckShield size={40} color="#F05938" />;
                  break;
              }
              return (
                <div
                  className={`ComplianceAlignmentDetail__FunctionItem ${
                    this.isDisabled(
                      item,
                      ComplianceAlignmentState.activeFunctions
                    )
                      ? 'ComplianceAlignmentDetail__FunctionItem--disabled'
                      : ''
                  }`}
                  key={item.key}
                >
                  <div className="ComplianceAlignmentDetail__FunctionItemIcon">
                    {icon}
                    {item.name.toLowerCase()}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="ComplianceAlignmentDetail__Section">
          <div className="ComplianceAlignmentDetail__H1 ComplianceAlignmentDetail__H1--products">
            Required Products{' '}
            <div className="ComplianceAlignmentDetail__Count">
              {ComplianceAlignmentState.activeProducts.length}
            </div>
          </div>
          {activeProducts.map(item => {
            return [
              <div className="ComplianceAlignmentDetail__H2" key={item.key}>
                {getProductName(item.name)}
              </div>,
              <p key={`${item.key}-p`}>{DESCRIPTIONS[item.key]}</p>,
            ];
          })}
        </div>
        <div className="ComplianceAlignmentDetail__Section">
          <div className="ComplianceAlignmentDetail__H1 ComplianceAlignmentDetail__H1--coverages">
            Compliance Coverage{' '}
            <div className="ComplianceAlignmentDetail__Count">
              {ComplianceAlignmentState.activeCoverageValues.length}
            </div>
          </div>
          {activeCoverages.map(item => {
            return (
              <div
                className="ComplianceAlignmentDetail__CoverageItem"
                key={item.key}
              >
                <div
                  className="ComplianceAlignmentDetail__H2 ComplianceAlignmentDetail__H2--coverages"
                  key={item.key}
                >
                  {item.name}{' '}
                  <div className="ComplianceAlignmentDetail__Count">
                    {item.values.length}
                  </div>
                </div>
                <p>
                  {item.values
                    .filter(Boolean)
                    .sort()
                    .join(' / ')}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
