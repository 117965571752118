/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';

@observer
export class Slide14 extends AnimatedSlide {
  // Animation players
  players = {
    vpn: null,
    sensor: null,
    host: null,
  };

  // Run the first animation
  componentDidMount() {
    const { vpn, sensor, host } = this.players;

    this.steps = [
      () => {
        this.animateProgress(5000);
        this.autoNext(1000);
      },
      () => {
        this.autoNext(1000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [{ targets: vpn, opacity: 1, duration: 200, begin: activateShimmer }],

      //
      // Step 2
      //
      [{ targets: sensor, opacity: 1, duration: 200, begin: activateShimmer }],

      //
      // Step 3
      //
      [{ targets: host, opacity: 1, duration: 200, begin: activateShimmer }],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-14 ${className}`}>
        {children}
        <div className="content">
          {/* PSensore Vitals */}
          <div
            className="player sensor"
            data-active="0"
            ref={node => (this.players.sensor = node)}
          >
            <PlayerTextBlock
              title={'Sensor Vitals'}
              showDots={true}
              className={'green-top'}
              width="300px"
              sections={[
                {
                  title: ['Status: Online'],
                  colorCells: 1,
                  color: 'green',
                },
                {
                  lines: 3,
                  animate: true,
                  delay: 200,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                  animate: true,
                  delay: 400,
                },
              ]}
            />
          </div>

          {/* Host Info */}
          <div
            className="player host"
            data-active="0"
            ref={node => (this.players.host = node)}
          >
            <PlayerTextBlock
              title={'Host Info'}
              width="300px"
              sections={[
                {
                  title: ['HOST', 'NA-VPN', 'IP', '10.1.108.5'],
                  grid: 2,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                  animate: true,
                  delay: 200,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                  animate: true,
                  delay: 400,
                },
              ]}
            />
          </div>

          {/* VPN */}
          <div
            className="player vpn"
            data-active="1"
            ref={node => (this.players.vpn = node)}
          >
            <Card IconComponent={Computer} text={'NA-VPN'} />
          </div>
        </div>
      </div>
    );
  }
}
