/** @jsx createElement */
// SVG
import { Directory } from '../svg/directory';
import { InternetExplorer } from '../svg/internet-explorer';
import { Word } from '../svg/word';
import { Chrome } from '../svg/chrome';
import { Cloud } from '../svg/cloud';
import { CMD } from '../svg/cmd';
import { PowerShell } from '../svg/power-shell';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';

@observer
export class Slide19 extends AnimatedSlide {
  // Animation players
  players = {
    process1: null,
    process2: null,
    process3: null,
    process4: null,
    process5: null,
    netconn: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      process1,
      process2,
      process3,
      process4,
      process5,
      netconn,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(5000);
        this.autoNext(1000);
      },
      // Step 2
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: process1,
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: process2,
          opacity: 1,
          duration: 500,
          delay: 200,
          begin: activateShimmer,
        },
        {
          targets: process3,
          opacity: 1,
          duration: 500,
          delay: 400,
          begin: activateShimmer,
        },
        {
          targets: process4,
          opacity: 1,
          duration: 500,
          delay: 600,
          begin: activateShimmer,
        },
        {
          targets: process5,
          opacity: 1,
          duration: 500,
          delay: 800,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: netconn,
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-19 ${className}`}>
        {children}
        <div className="content">
          {/* Process1 */}
          <div
            className="player process process1"
            data-active="0"
            ref={node => (this.players.process1 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Chrome}
              title={'CHROME.EXE'}
              className={'right'}
            />
            <PlayerDots className={'right'} rotate={'26deg'} width={'290px'} />
          </div>

          {/* Process2 */}
          <div
            className="player process process2"
            data-active="0"
            ref={node => (this.players.process2 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={CMD}
              title={'CMD.EXE'}
              className={'right'}
            />
            <PlayerDots className={'right'} rotate={'14deg'} width={'300px'} />
          </div>

          {/* Process3 */}
          <div
            className="player process process3"
            data-active="0"
            ref={node => (this.players.process3 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Word}
              title={'WINWORD.EXE'}
              className={'right'}
            />
            <PlayerDots className={'right'} rotate={'0deg'} width={'290px'} />
          </div>

          {/* Process4 */}
          <div
            className="player process process4"
            data-active="0"
            ref={node => (this.players.process4 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Cloud}
              title={'ONEDRIVE.EXE'}
              className={'right'}
            />
            <PlayerDots className={'right'} rotate={'-14deg'} width={'300px'} />
          </div>

          {/* Process5 */}
          <div
            className="player process process5"
            data-active="0"
            ref={node => (this.players.process5 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={CMD}
              title={'CMD.EXE'}
              className={'right'}
            />
            <PlayerDots className={'right'} rotate={'-26deg'} width={'290px'} />
          </div>

          {/* Netconn info */}
          <div
            className="player netconn"
            data-active="0"
            ref={node => (this.players.netconn = node)}
          >
            <PlayerTextBlock
              title={'Netconn Info'}
              className={''}
              sections={[
                {
                  title: [
                    'IP',
                    '118.114.77.92',
                    'Port',
                    'TCP / 80',
                    'Dest',
                    'Win22.googlle.cc',
                  ],
                  grid: 2,
                  animate: true,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
