/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Chrome extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#C53D43',
      size = 35,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        width={size}
        height={size}
        viewBox={`0 0 41 41`}
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd" clipRule="evenodd">
          <path
            fillOpacity={opacity}
            d="M38.0047 11.3259H18.7516V11.3344C17.9522 11.384 14.9241 11.8241 12.288 15.5759C11.738 16.713 11.1651 17.8729 11.0658 19.481L4.08432 7.8619C5.78049 5.587 10.6606 0.371601 19.9567 0.00358893C19.9567 0.00358893 31.752 -0.527337 38.0047 11.3259ZM38.6877 12.8245C39.8267 15.6804 41.5194 22.257 37.392 30.1056C37.392 30.1056 31.967 40.5573 18.6136 40.0734C18.6073 40.0843 18.6041 40.0898 18.6041 40.0898C18.6041 40.0898 18.4589 40.0925 18.1924 40.0786L26.5156 26.2265C26.5456 26.1969 26.5756 26.1669 26.6056 26.1367C27.4964 24.5942 28.0863 23.5728 28.0863 23.5728C28.0863 23.5728 29.9878 20.516 27.7425 15.6769C27.0746 14.6934 26.3995 13.6823 25.2031 12.8245H38.6877ZM3.19653 9.20835L11.7411 23.4289L11.7717 23.4106C12.4715 24.6232 12.9159 25.3933 12.9159 25.3933C12.9159 25.3933 14.6118 28.5684 19.9257 29.0438C21.091 28.9588 22.2834 28.881 23.5977 28.3015L16.6265 39.9036C13.4719 39.3878 7.18988 37.4498 2.60525 30.1853C2.60525 30.1853 -3.63282 20.4173 3.19653 9.20835ZM19.9073 27.6408C15.8042 27.6408 12.4779 24.3146 12.4779 20.2114C12.4779 16.1083 15.8042 12.782 19.9073 12.782C24.0105 12.782 27.3367 16.1083 27.3367 20.2114C27.3367 24.3146 24.0105 27.6408 19.9073 27.6408Z"
            fill="#50C2E3"
          />
        </g>
      </svg>
    );
  }
}
