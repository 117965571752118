/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import './home.scss';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerDonut } from '../players/donut';
import { PlayerCircleIcon } from '../players/circle-icon';

// SVG
import { Card } from '../svg/card';
import { Alert } from '../svg/alert';
import { Warning } from '../svg/warning';
import { USA } from '../svg/usa';
import { USAScanner } from '../svg/usa-scanner';
import { observable } from 'mobx';
import { Email } from '../svg/email';
import { Skull } from '../svg/skull';
import { Bug } from '../svg/bug';
import { Computer } from '../svg/computer';

@observer
export class Slide03 extends AnimatedSlide {
  // Animation players
  players = {
    terminal: null,
    amp: null,
    whois: null,
    geolocation: null,
    alert: null,
    border: null,
    donut: null,
    usa: null,
    iconSkull: null,
    iconPhishing: null,
    iconMalware: null,
  };

  @observable
  donut = {
    color: 'blue',
  };

  // Scanner stuff
  @observable
  scanner = {
    node: null, // The node
    index: 0, // Starting index of positions
    hold: 100, // How long to hold each point
    timer: null, // The timer
    positions: [
      // Max top = 115px
      // Max left = 245px
      { top: '5px', left: '5px' },
      { top: '85px', left: '55px' },
      { top: '45px', left: '35px' },
      { top: '115px', left: '115px' },
      { top: '55px', left: '185px' },
      { top: '25px', left: '135px' },
      { top: '145px', left: '125px' },
      { top: '65px', left: '205px' },
      { top: '5px', left: '245px' },
      { top: '45px', left: '195px' },
      { top: '85px', left: '25px' },
      { top: '25px', left: '165px' },
    ],
  };

  // Animate the scanner
  animateScanner() {
    let node = ReactDOM.findDOMNode(this.scanner.node);
    let positions = this.scanner.positions;
    let index = this.scanner.index;
    this.scanner.timer = setInterval(() => {
      index++;
      if (index >= positions.length) index = 0;
      node.style.top = positions[index].top;
      node.style.left = positions[index].left;
    }, this.scanner.hold);
  }

  // Run the first animation
  componentDidMount() {
    // Save the border since I can't bind it in the DOM like the others
    this.players.border = this.players.alert.querySelector('.border');

    const {
      terminal,
      amp,
      whois,
      geolocation,
      alert,
      border,
      donut,
      usa,
      iconSkull,
      iconMalware,
      iconPhishing,
    } = this.players;

    this.steps = [
      //
      // Step 1
      //
      () => {
        this.animateProgress(7000);

        this.donut.color = 'blue';
        let node = ReactDOM.findDOMNode(this.scanner.node);
        if (node) {
          let dot = node.querySelector('.dot');
          dot.style.fill = 'transparent';
        }
        this.animateScanner();

        this.autoNext(4000);
      },

      //
      // Step 2
      //
      () => {
        this.donut.color = 'blue';
        let node = ReactDOM.findDOMNode(this.scanner.node);
        if (node) {
          let dot = node.querySelector('.dot');
          dot.style.fill = '#50C2E3';
        }
        clearInterval(this.scanner.timer);

        this.autoNext(3000);
      },

      //
      // Step 3
      //
      () => {
        this.donut.color = 'red';
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: terminal,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
        },
        {
          targets: amp,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 80,
          duration: 500,
          delay: 1000,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: whois,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 80,
          duration: 500,
          delay: 1500,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: geolocation,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 800,
          duration: 500,
          delay: 2000,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: usa,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 820,
          duration: 500,
          delay: 2500,
          begin: activate,
          complete: shimmer,
        },
      ],

      //
      // Step 2
      //
      [
        { targets: terminal, opacity: 1, duration: 500, begin: activate },
        { targets: alert, opacity: 0, duration: 1000, end: deactivate },
        { targets: amp, opacity: 1 },
        { targets: whois, opacity: 1 },
        { targets: geolocation, opacity: 1 },
        { targets: usa, opacity: 1 },

        // Icons
        { targets: iconSkull, opacity: 0, end: deactivate },
        { targets: iconPhishing, opacity: 0, end: deactivate },
        { targets: iconMalware, opacity: 0, end: deactivate },
      ],

      //
      // Step 3
      //
      [
        { targets: terminal, opacity: 0.2 },
        { targets: amp, opacity: 0.2 },
        { targets: whois, opacity: 0.2 },
        { targets: geolocation, opacity: 0.2 },
        { targets: usa, opacity: 0.2 },
        { targets: alert, opacity: 1, duration: 1000, begin: activate },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },

        // Icons
        {
          targets: iconSkull,
          opacity: [0, 1],
          left: 200,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
        },
        {
          targets: iconPhishing,
          opacity: [0, 1],
          left: 200,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
        },
        {
          targets: iconMalware,
          opacity: [0, 1],
          left: 850,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`Inspect ${className}`}>
        {children}
        <div className="content">
          {/* Malicious traits */}
          <div
            className="player alert toppest"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              subtitle={'Malicious Traits'}
              title={'Warning'}
              IconComponent={Warning}
            />
          </div>

          {/* Icon malicious activity */}
          <div
            className="player icon-skull toppest"
            data-active="0"
            ref={node => (this.players.iconSkull = node)}
          >
            <PlayerCircleIcon
              align="right"
              showDots={true}
              rotate="25deg"
              title="Malicious Activity"
              icon={<Skull />}
            />
          </div>

          {/* Icon phishing activity */}
          <div
            className="player icon-email toppest"
            data-active="0"
            ref={node => (this.players.iconPhishing = node)}
          >
            <PlayerCircleIcon
              align="right"
              showDots={true}
              rotate="-25deg"
              title="Phishing"
              icon={<Email size={35} />}
            />
          </div>

          {/* Icon malware activity */}
          <div
            className="player icon-bug toppest"
            data-active="0"
            ref={node => (this.players.iconMalware = node)}
          >
            <PlayerCircleIcon
              align="left"
              showDots={true}
              rotate="0deg"
              title="Malware"
              icon={<Bug />}
            />
          </div>

          {/* Computer terminal icon */}
          <div
            className="player terminal fade-behind-alert"
            data-active="1"
            ref={node => (this.players.terminal = node)}
          >
            <Card IconComponent={Computer} text={'10.0.5.38'} />
          </div>

          {/* Animated donut */}
          <div
            className={`player donut fade-behind-alert ${this.donut.color}`}
            data-active="1"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>

          {/* AMP */}
          <div
            className="player amp"
            data-active="0"
            ref={node => (this.players.amp = node)}
          >
            <PlayerTextBlock
              box={'NO ACTIVE AMP RULE'}
              title={'AMP'}
              sections={[
                {
                  lines: 2,
                  animate: true,
                  delay: 1500,
                },
              ]}
            />
          </div>

          {/* WHOIS */}
          <div
            className="player whois"
            data-active="0"
            ref={node => (this.players.whois = node)}
          >
            <PlayerTextBlock
              title={'WHOIS'}
              sections={[
                {
                  lines: 3,
                  animate: true,
                  delay: 1500,
                },
              ]}
            />
          </div>

          {/* GEOLOCATION */}
          <div
            className="player geolocation"
            data-active="0"
            ref={node => (this.players.geolocation = node)}
          >
            <PlayerTextBlock
              title={'GEOLOCATION'}
              sections={[
                {
                  lines: 2,
                  lineColumns: true,
                  animate: true,
                  delay: 1500,
                },
              ]}
            />
          </div>

          {/* USA */}
          <div
            className="player usa fade-behind-alert"
            data-active="0"
            ref={node => (this.players.usa = node)}
          >
            <USAScanner ref={node => (this.scanner.node = node)} />
            <USA />
          </div>
        </div>
      </div>
    );
  }
}
