/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Crosshair extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 48,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 9.14937V13.5864C17.753 14.4014 14.401 17.7534 13.586 22.0004H9.149C10.044 15.3304 15.329 10.0444 22 9.14937ZM38.851 22.0004H34.414C33.599 17.7534 30.247 14.4014 26 13.5864V9.14937C32.67 10.0444 37.956 15.3304 38.851 22.0004ZM26 38.8504V34.4144C30.247 33.5984 33.599 30.2474 34.414 26.0004H38.851C37.956 32.6704 32.67 37.9564 26 38.8504ZM22 26.0004V30.2974C19.962 29.6484 18.351 28.0384 17.702 26.0004H22ZM22 22.0004H17.702C18.351 19.9624 19.962 18.3514 22 17.7024V22.0004ZM26 22.0004V17.7024C28.038 18.3514 29.649 19.9624 30.298 22.0004H26ZM26 26.0004H30.298C29.649 28.0384 28.038 29.6484 26 30.2974V26.0004ZM9.149 26.0004H13.586C14.401 30.2474 17.753 33.5984 22 34.4144V38.8504C15.329 37.9564 10.044 32.6704 9.149 26.0004ZM2 26.0004H5.107C6.041 34.8814 13.118 41.9594 22 42.8924V46.0004C22 47.1044 22.895 48.0004 24 48.0004C25.104 48.0004 26 47.1044 26 46.0004V42.8924C34.882 41.9594 41.959 34.8814 42.892 26.0004H46C47.104 26.0004 48 25.1044 48 24.0004C48 22.8954 47.104 22.0004 46 22.0004H42.892C41.959 13.1184 34.882 6.04137 26 5.10737V2.00037C26 0.895366 25.104 0.000366211 24 0.000366211C22.895 0.000366211 22 0.895366 22 2.00037V5.10737C13.118 6.04137 6.041 13.1184 5.107 22.0004H2C0.895 22.0004 0 22.8954 0 24.0004C0 25.1044 0.895 26.0004 2 26.0004Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
