/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import anime from 'animejs';
import './data-moving.scss';

@observer
export class PlayerDataMoving extends Component {
  dots = null;
  square = null;
  dotAnimation = null;
  squareAnimation = null;
  interval = null;
  unmounted = false;

  defaults = {
    dotCount: 30,
    duration: 1000,
    wait: 0,
    color: 'blue',
  };

  startAnimation() {
    const {
      dotCount = this.defaults.dotCount,
      duration = this.defaults.duration,
    } = this.props;

    let unit = 100 / dotCount;

    // Animate dots
    let delay = duration / dotCount;

    let dots = ReactDOM.findDOMNode(this.dots);
    let index = 0;

    if (dots === null) return;

    this.interval = setInterval(() => {
      dots.style.right = 100 - index * unit + '%';
      index++;
      if (index > dotCount * 2) index = 0;
    }, delay);
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  componentDidMount() {
    const { wait = this.defaults.wait } = this.props;

    // Wait to start until specified amount of time has passed
    this.sleep(wait).then(() => {
      if (!this.unmounted) {
        this.startAnimation.call(this);
      }
    });
  }

  componentWillUnmount() {
    this.unmounted = true;
    clearInterval(this.interval);
  }

  render() {
    const {
      children,
      className = '',
      color = this.defaults.color,
      dots = this.defaults.dotCount,
    } = this.props;

    // Generate array
    const dotArray = Array.from(Array(dots).keys());
    let unit = 100 / dots;

    return (
      <div className={`player--dataMoving ${className} ${color}`}>
        <div
          className="player--dataMoving-dots"
          ref={node => (this.dots = node)}
        >
          <span
            className={'player--dataMoving-square'}
            ref={node => (this.square = node)}
          />
          {dotArray.map(value => {
            let styles = {
              opacity: (unit * value) / 100,
              left: unit * value + '%',
            };
            return (
              <span
                key={value}
                className={'player--dataMoving-dot'}
                style={styles}
              />
            );
          })}
        </div>
      </div>
    );
  }
}
