/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { getColor } from '../components/helpers';
import { AnimatedSlide } from '../components/animated-slide';

// SCSS
import './compare-network-to-network.scss';

// Players
import { PlayerNetworkScan } from '../components/players/network-scan';
import { PlayerPath } from '../components/players/path';
import { PlayerIconLoad } from '../components/players/icon-load';

// SVG
import { Shodan } from '../components/svg/shodan';

@observer
export class Template__CompareNetworkToNetwork extends AnimatedSlide {
  // Animation players
  players = {
    networkLeft: null,
    networkRight: null,
    icon: null,
    label: null,
    linesLeft: null,
    linesRight: null,
  };

  icon = {
    IconComponent: Shodan,
    color: this.colors.blue,
    duration: 2000,
    size: 120,
    strokeWidth: 2,
    strokeDashoffset: '20 100',
  };

  componentWillUnmount() {
    // Unmount
  }

  // Run the first animation
  componentDidMount() {
    const {
      networkLeft,
      networkRight,
      icon,
      label,
      linesLeft,
      linesRight,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(icon, 0, 1000),
        this.fadeIn(linesLeft, 0, 500),
        this.fadeIn(linesRight, 0, 500),
        this.fadeIn(networkLeft, 2000, 500),
        this.fadeIn(networkRight, 2000, 500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  revealPreset(delay = 0, duration = 10000) {
    return {
      delay: delay + 1500,
      duration: duration,
    };
  }

  render() {
    const { children, className = '', icon = {}, label = '' } = this.props;

    // Preselect the indexes of the computers to get highlighted
    var baddies = '0,1,3,4,5,6,8,9,10,13,14,17,18,19,20,21,22,23,24,25,26,27,28,29,31,32,33,34,35'.split(
      ','
    );

    // Allow the user to pass in an icon
    Object.assign(this.icon, icon);

    // Thin line definition
    let thinLine = {
      strokeWidth: 2,
      strokeDashArray: '2,2',
    };

    let thickLine = {
      strokeWidth: 4,
      strokeDashArray: '4,4',
    };

    return (
      <div className={`Template__CompareNetworkToNetwork ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}

          {/* Network left */}
          <div
            className="player networkLeft"
            data-active="0"
            ref={node => (this.players.networkLeft = node)}
          >
            <PlayerNetworkScan
              delay={2000}
              columns={6}
              rows={6}
              width={184}
              gap={10}
              borderColor={this.colors.blue}
              borderOpacity={1}
            />
          </div>

          {/* Network right */}
          <div
            className="player networkRight"
            data-active="0"
            ref={node => (this.players.networkRight = node)}
          >
            <PlayerNetworkScan
              delay={2000}
              columns={6}
              rows={6}
              width={184}
              gap={10}
              borderColor={this.colors.blue}
              borderOpacity={1}
              indexes={baddies}
              vulnerable={true}
              random={true}
              duration={12000}
            />
          </div>

          {/* Icon */}
          <div
            className="player icon"
            data-active="0"
            ref={node => (this.players.icon = node)}
          >
            <label>{label}</label>
            <PlayerIconLoad
              strokeWidth={this.icon.strokeWidth}
              size={this.icon.size}
              start={{
                IconComponent: this.icon.IconComponent,
                color: this.icon.color,
                size: 70,
              }}
              loading={{
                delay: 0,
                duration: this.icon.duration,
                strokeDashoffset: this.icon.strokeDashoffset,
              }}
            />
          </div>

          {/* Left paths */}
          <div
            className="player left"
            data-active="0"
            ref={node => (this.players.linesLeft = node)}
          >
            {/* Top */}
            <PlayerPath
              points={['0,45', '45,0', '218,0', '314,100'].reverse()}
              gradient={{ preset: 'blueWhite', direction: 'right' }}
              dots={thinLine}
              reveal={this.revealPreset(500, 1500)}
            />
            <PlayerPath
              points={['0,45', '45,0'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(1500, 500)}
            />
            <PlayerPath
              points={['0,45', '45,0'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(1300, 500)}
            />
            <PlayerPath
              points={['0,45', '45,0'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(1100, 500)}
            />
            <PlayerPath
              points={['0,45', '45,0'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(900, 500)}
            />
            <PlayerPath
              points={['0,45', '45,0'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(700, 500)}
            />
            {/* Middle */}
            <PlayerPath
              points={['0,0', '128,0']}
              gradient={{ preset: 'blueWhite', direction: 'right' }}
              dots={thickLine}
              reveal={this.revealPreset(1800, 1000)}
            />
            {/* Middle inside 1 */}
            <PlayerPath
              points={['0,0', '14,14']}
              dots={thinLine}
              reveal={this.revealPreset(1700, 300)}
            />
            <PlayerPath
              points={['0,14', '14,0']}
              dots={thinLine}
              reveal={this.revealPreset(1700, 300)}
            />
            {/* Middle inside 2 */}
            <PlayerPath
              points={['0,0', '39,39']}
              dots={thinLine}
              reveal={this.revealPreset(1900, 400)}
            />
            <PlayerPath
              points={['0,39', '39,0']}
              dots={thinLine}
              reveal={this.revealPreset(1900, 400)}
            />
            {/* Middle inside 3 */}
            <PlayerPath
              points={['0,0', '64,64']}
              dots={thinLine}
              reveal={this.revealPreset(2100, 500)}
            />
            <PlayerPath
              points={['0,64', '64,0']}
              dots={thinLine}
              reveal={this.revealPreset(2100, 500)}
            />
            {/* Bottom section */}
            <PlayerPath
              points={['0,56', '45,100', '218,100', '314,0'].reverse()}
              gradient={{ preset: 'blueWhite', direction: 'right' }}
              dots={thinLine}
              reveal={this.revealPreset(500, 1500)}
            />
            <PlayerPath
              points={['0,0', '45,45'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(1500, 500)}
            />
            <PlayerPath
              points={['0,0', '45,45'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(1300, 500)}
            />
            <PlayerPath
              points={['0,0', '45,45'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(1100, 500)}
            />
            <PlayerPath
              points={['0,0', '45,45'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(900, 500)}
            />
            <PlayerPath
              points={['0,0', '45,45'].reverse()}
              dots={thinLine}
              reveal={this.revealPreset(700, 500)}
            />
          </div>

          {/* Right paths */}
          <div
            className="player right"
            data-active="0"
            ref={node => (this.players.linesRight = node)}
          >
            {/* Top */}
            <PlayerPath
              points={['0,100', '96,0', '270,0', '314,45']}
              gradient={{ preset: 'blueWhite' }}
              dots={thinLine}
              reveal={this.revealPreset(500, 1500)}
            />
            <PlayerPath
              points={['0,0', '45,45']}
              dots={thinLine}
              reveal={this.revealPreset(1500, 500)}
            />
            <PlayerPath
              points={['0,0', '45,45']}
              dots={thinLine}
              reveal={this.revealPreset(1500, 500)}
            />
            <PlayerPath
              points={['0,0', '45,45']}
              dots={thinLine}
              reveal={this.revealPreset(1500, 500)}
            />
            <PlayerPath
              points={['0,0', '45,45']}
              dots={thinLine}
              reveal={this.revealPreset(1500, 500)}
            />
            <PlayerPath
              points={['0,0', '45,45']}
              dots={thinLine}
              reveal={this.revealPreset(1500, 500)}
            />
            {/* Middle */}
            <PlayerPath
              points={['0,0', '128,0'].reverse()}
              gradient={{ preset: 'blueWhite' }}
              dots={thickLine}
              reveal={this.revealPreset(1800, 1000)}
            />
            {/* Middle inside 1 */}
            <PlayerPath
              points={['14,0', '0,14']}
              dots={thinLine}
              reveal={this.revealPreset(1700, 300)}
            />
            <PlayerPath
              points={['14,14', '0,0']}
              dots={thinLine}
              reveal={this.revealPreset(1700, 300)}
            />
            {/* Middle inside 2 */}
            <PlayerPath
              points={['39,0', '0,39']}
              dots={thinLine}
              reveal={this.revealPreset(1900, 400)}
            />
            <PlayerPath
              points={['39,39', '0,0']}
              dots={thinLine}
              reveal={this.revealPreset(1900, 400)}
            />
            {/* Middle inside 3 */}
            <PlayerPath
              points={['64,0', '0,64']}
              dots={thinLine}
              reveal={this.revealPreset(2100, 500)}
            />
            <PlayerPath
              points={['64,64', '0,0']}
              dots={thinLine}
              reveal={this.revealPreset(2100, 500)}
            />
            {/* Bottom section */}
            <PlayerPath
              points={['0,0', '96,100', '270,100', '314,56']}
              gradient={{ preset: 'blueWhite' }}
              dots={thinLine}
              reveal={this.revealPreset(500, 1500)}
            />
            <PlayerPath
              points={['0,45', '45,0']}
              dots={thinLine}
              reveal={this.revealPreset(1500, 500)}
            />
            <PlayerPath
              points={['0,45', '45,0']}
              dots={thinLine}
              reveal={this.revealPreset(1300, 500)}
            />
            <PlayerPath
              points={['0,45', '45,0']}
              dots={thinLine}
              reveal={this.revealPreset(1100, 500)}
            />
            <PlayerPath
              points={['0,45', '45,0']}
              dots={thinLine}
              reveal={this.revealPreset(900, 500)}
            />
            <PlayerPath
              points={['0,45', '45,0']}
              dots={thinLine}
              reveal={this.revealPreset(700, 500)}
            />
          </div>

          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
