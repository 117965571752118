/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class EsentireIcon extends Component {
  render() {
    const {
      children,
      className = '',
      color = 'white',
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={`${className} EsentireIcon`}
        {...{ x, y }}
        width="82"
        height="12"
        viewBox="0 0 82 12"
        fill="none"
        // xmlns="http://www.w3.org/2000/svg"
        // xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5347 8.07177C20.5347 8.65458 20.4176 9.1729 20.1835 9.62593C19.9495 10.079 19.6326 10.4603 19.2329 10.7677C18.8332 11.0758 18.3674 11.3075 17.8348 11.4643C17.3013 11.6212 16.7368 11.7 16.1397 11.7C15.2663 11.7 14.4717 11.5957 13.7583 11.3855C13.0441 11.1761 12.3992 10.7748 11.8235 10.1825L13.6126 8.37193C13.9112 8.67369 14.2949 8.88308 14.7639 9.00172C15.2328 9.12035 15.697 9.17927 16.1548 9.17847C17.2416 9.17847 17.7854 8.83372 17.7854 8.14422C17.7846 7.85282 17.7106 7.6267 17.5609 7.46507C17.4009 7.30344 17.1501 7.20153 16.8093 7.15854L15.4343 6.96507C14.4215 6.81459 13.6604 6.4762 13.1484 5.94833C12.6253 5.41011 12.3642 4.6394 12.3642 3.63779C12.3634 3.10992 12.4597 2.62504 12.6508 2.18315C12.8427 1.74127 13.1166 1.3591 13.474 1.03505C13.8307 0.711797 14.2647 0.458609 14.7766 0.275486C15.2878 0.0923624 15.8682 4.52384e-06 16.5187 4.52384e-06C17.3395 -0.000791665 18.0609 0.103509 18.6851 0.312111C19.3085 0.520712 19.8651 0.871035 20.3555 1.36308L18.5983 3.1338C18.4168 2.95068 18.2249 2.81374 18.0227 2.72217C17.8196 2.63061 17.623 2.56373 17.4311 2.52074C17.2392 2.47774 17.0577 2.45625 16.8873 2.45625C16.7169 2.45625 16.568 2.45625 16.4399 2.45625C16.1946 2.45625 15.9868 2.48889 15.8164 2.55338C15.646 2.61867 15.5043 2.70227 15.3929 2.80418C15.2814 2.90689 15.1986 3.02075 15.1452 3.14495C15.0919 3.26916 15.0656 3.39575 15.0656 3.52473C15.0656 3.60117 15.0816 3.68716 15.1134 3.78429C15.1452 3.88143 15.2042 3.97299 15.2894 4.05898C15.3642 4.13461 15.4629 4.20547 15.5855 4.26997C15.7082 4.33446 15.8706 4.37745 16.0728 4.39895L17.4478 4.59242C18.4494 4.73255 19.1955 5.05023 19.6859 5.54626C19.9956 5.84881 20.2138 6.21028 20.342 6.63146C20.4702 7.05185 20.5347 7.53195 20.5347 8.07177Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.2764 11.5998L23.2717 0.0940508L31.009 0.0908661L31.0098 2.6116L26.0703 2.61399L26.0711 4.53679L30.2917 4.53519L30.2925 7.05593L26.0719 7.05752L26.0727 9.07745L31.0122 9.07506L31.0138 11.5966L23.2764 11.5998Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.3404 11.5923L36.693 5.80882L36.6954 11.5939L33.8976 11.5947L33.8928 0.0889991L36.3387 0.0882029L39.9861 5.87172L39.9837 0.0866105L42.7815 0.0850182L42.7863 11.5915L40.3404 11.5923Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.9099 2.60285L50.9139 11.5878L48.1161 11.5886L48.1121 2.60444L45.3151 2.60524L45.3135 0.0845016L53.7061 0.0805206L53.7069 2.60125L50.9099 2.60285Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.2439 11.5851L56.2391 0.0794122L59.0361 0.0778198L59.0417 11.5843L56.2439 11.5851Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.8369 3.77547C67.8369 3.47371 67.7302 3.2046 67.5168 2.96734C67.3034 2.73087 67.0049 2.61224 66.6211 2.61224L65.0391 2.61303L65.0399 4.94029L66.6227 4.9395C67.0065 4.9395 67.3042 4.82086 67.5176 4.5836C67.7302 4.34633 67.8369 4.07722 67.8369 3.77547ZM68.0001 11.5805L65.9842 7.2994H65.0407L65.0431 11.5821L62.2453 11.5829L62.2405 0.0771698L66.7485 0.0755775C67.3767 0.0747813 67.934 0.179878 68.4189 0.389276C68.9038 0.59947 69.3114 0.876543 69.6418 1.22129C69.9723 1.56604 70.2207 1.95936 70.3863 2.40045C70.5511 2.84233 70.6339 3.30014 70.6347 3.77387C70.6347 4.16242 70.5814 4.51752 70.4755 4.84077C70.3688 5.16402 70.2278 5.44985 70.0519 5.69747C69.8759 5.94588 69.6761 6.15846 69.4524 6.33601C69.2286 6.51356 69.0049 6.66245 68.7812 6.78108L71.2454 11.5789L68.0001 11.5805Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.5702 11.5782L73.5654 0.0724597L81.302 0.0692749L81.3036 2.59001L76.364 2.5924L76.3648 4.51519L80.5854 4.5136L80.5862 7.03434L76.3656 7.03593L76.3664 9.05586L81.306 9.05347L81.3076 11.575L73.5702 11.5782Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.75627 4.0807C2.75627 3.7272 2.80325 3.44535 2.89799 3.23674C2.99194 3.02814 3.11615 2.85935 3.27061 2.73037C3.33112 2.67702 3.40198 2.62846 3.48319 2.58546C3.5644 2.54326 3.67587 2.51062 3.81759 2.48912C3.95931 2.46763 4.1496 2.4517 4.39005 2.44135C4.6305 2.4302 4.53098 2.4302 4.77063 2.44135C5.01108 2.4517 5.20376 2.46763 5.34946 2.48912C5.49596 2.51062 5.60503 2.54326 5.67749 2.58546C5.75074 2.62846 5.8216 2.67702 5.89007 2.73037C6.04453 2.85935 6.16874 3.02814 6.26269 3.23674C6.35743 3.44535 6.40441 3.7272 6.40441 4.0807L6.40361 4.52577H2.75547L2.75627 4.0807ZM9.15922 3.94057C9.15922 3.39041 9.06448 2.8904 8.92275 2.40313C8.78103 1.91746 8.63931 1.70647 8.32163 1.34261C8.0565 1.03847 7.58277 0.665054 7.30012 0.512185C7.01668 0.360113 6.66078 0.231131 6.53339 0.192914C6.24039 0.105333 6.01507 0.0193443 5.61539 0.0193443H3.67268C3.46647 0.0193443 2.95532 0.10374 2.69974 0.167435C2.40276 0.241481 2.08827 0.39833 1.80483 0.550402C1.52218 0.703271 1.16628 0.997861 0.910705 1.25344C0.56914 1.595 0.399552 1.89278 0.233148 2.40313C0.0770951 2.88562 0.00145721 3.39041 0.00145721 3.94057V7.33712C-0.00331992 7.44301 0.00464194 7.68027 0.0110115 7.79254C0.0619675 8.74 0.440157 9.45896 0.659905 9.84113C0.711658 9.93269 1.59224 11.5927 4.4004 11.5927H9.15922V9.02663L4.95136 9.02504C3.6281 9.02504 2.75547 8.79653 2.75547 7.52741L2.75627 7.06562H9.15922V3.94057Z"
          fill={color}
        />
      </svg>
    );
  }
}
