/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Template__TeamSendsAdvisory } from '../../templates/team-sends-advisory';

@observer
export class Slide06 extends Component {
  componentDidMount() {
    // Do something
  }

  render() {
    const { children } = this.props;
    return (
      <div className={`slide-06`}>
        {children}
        <Template__TeamSendsAdvisory
          title={{
            text: ['Critical Apache', 'Zero-Day Vulnerability'],
            fontSize: 60,
            lineHeight: 40,
            letterSpacing: -4,
          }}
        />
      </div>
    );
  }
}
