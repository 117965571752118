/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// SVG
import { Bug } from '../svg/bug';
import { CheckShield } from '../svg/check-shield';
import { ShieldBlank } from '../svg/shield-blank';
import { Eye } from '../svg/eye';

@observer
export class Slide02 extends AnimatedSlide {
  // Animation players
  players = {
    card1: null,
    card2: null,
    card3: null,
    card4: null,
  };

  // Run the first animation
  componentDidMount() {
    const { card1, card2, card3, card4 } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(1500);
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(card1, 0),
        this.fadeIn(card2, 500),
        this.fadeIn(card3, 1000),
        this.fadeIn(card4, 1500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-02 ${className}`}>
        {children}
        <div className="content">
          {/* -------------------CONTENT------------------- */}
          {/* Card 1 */}
          <div
            className="player card1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <Stage
              Icon={Bug}
              stage={'01'}
              title={'High-Value Vulnerability Emerges'}
            />
          </div>

          {/* Card 2 */}
          <div
            className="player card2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <Stage
              Icon={ShieldBlank}
              stage={'02'}
              title={'Audit of External Facing Systems'}
            />
          </div>

          {/* Card 3 */}
          <div
            className="player card3"
            data-active="0"
            ref={node => (this.players.card3 = node)}
          >
            <Stage
              Icon={Eye}
              stage={'03'}
              title={
                'New Detection Engineering and Proactive Global Threat Hunting'
              }
            />
          </div>

          {/* Card 4 */}
          <div
            className="player card4"
            data-active="0"
            ref={node => (this.players.card4 = node)}
          >
            <Stage
              Icon={CheckShield}
              stage={'04'}
              title={
                '24/7 Monitoring and Investigation, and Customer Notification'
              }
            />
          </div>
          {/* -------------------CONTENT------------------- */}
        </div>
      </div>
    );
  }
}
