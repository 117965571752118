/** @jsx createElement */
import { observer } from 'mobx-react';
import { getColor, uid } from '../helpers';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import anime from 'animejs';
import './loading-message.scss';

// SVG
import { Warning } from '../svg/warning';
import { Loading } from '../svg/loading';
import { Check } from '../svg/check';
import {
  collapseTextChangeRangesAcrossMultipleVersions,
  isThisTypeNode,
} from 'typescript';

@observer
export class PlayerLoadingMessage extends Component {
  _isMounted = false;
  stage = 'first';
  duration = 2000;
  timer = null;
  autostart = true;
  node = null;

  wrapperStyles = {};

  start = {
    IconComponent: Loading,
    text: 'LOADING',
    color: getColor('white'),
    node: null,
  };
  end = {
    IconComponent: Check,
    text: 'COMPLETE',
    color: getColor('blue'),
    node: null,
  };
  complete = null;

  // Unmount
  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this.timer);
  }

  // Mount
  componentDidMount() {
    this._isMounted = true;
    if (this.autostart) {
      this.timer = setTimeout(this.transition, this.duration);
    }
  }

  // Return an icon
  getIcon(Icon, color) {
    return <Icon size={20} color={color} />;
  }

  // Transition after the sepcified time
  transition() {
    if (!this._isMounted) return;
    this.node.classList.add('shimmer');
    this.start.node.style.opacity = 0;
    this.end.node.style.opacity = 1;
    if (typeof this.complete === 'function') {
      this.complete();
    }
  }

  // Render
  render() {
    const {
      className = '',
      duration = 2000,
      width = 200,
      start = {},
      end = {},
      complete = null,
    } = this.props;

    // Properties
    Object.assign(this.start, start);
    Object.assign(this.end, end);
    this.complete = complete;

    // Set styles
    this.wrapperStyles.width = parseInt(width).toString() + 'px';
    this.duration = parseInt(duration);

    // First styles
    let firstStyles = {
      background: `rgba(${start.color}), 0.1`,
    };

    // Last styles
    let lastStyles = {
      borderColor: end.color,
    };

    // Render
    return (
      <div
        className={`Player__LoadingMessage ${className} ${this.stage}`}
        style={this.wrapperStyles}
        ref={node => (this.node = node)}
      >
        <div
          className="Player__LoadingMessage__Message"
          style={firstStyles}
          ref={node => (this.start.node = node)}
        >
          {this.getIcon(this.start.IconComponent, this.start.color)}
          {start.text}
        </div>
        <div
          className="Player__LoadingMessage__Message"
          style={lastStyles}
          ref={node => (this.end.node = node)}
        >
          {this.getIcon(this.end.IconComponent, this.end.color)}
          {end.text}
        </div>
      </div>
    );
  }
}
