/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { AnimatedSlide } from '../components/animated-slide';
import { getColor } from '../components/helpers';

// SCSS
import './teams-scan-world.scss';

// Players
import { PlayerPath } from '../components/players/path';
import { PlayerIconLoad } from '../components/players/icon-load';

// SVG
import { Shield } from '../components/svg/shield';
import { TRU } from '../components/svg/tru';
import { EsentireE } from '../components/svg/esentire-e';
import { Proxyshell } from '../components/svg/proxyshell';
import { World } from '../components/svg/world';

@observer
export class Template__TeamsScanWorld extends AnimatedSlide {
  // Animation players
  players = {
    team1: null,
    team2: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
    product: null,
    world: null,
  };

  // Team shield
  team1 = {
    icon: <TRU size={60} color={this.colors.white} opacity={1} />,
    text: 'TRU Team',
    stroke: this.colors.blue,
    strokeOpacity: 1,
    letterSpacing: 0,
  };

  // Team shield
  team2 = {
    icon: <EsentireE size={50} color={this.colors.white} opacity={1} />,
    text: 'SOC Analysts',
    stroke: this.colors.blue,
    strokeOpacity: 1,
    letterSpacing: 0,
    iconOffsetY: -10,
  };

  product = {
    icon: <Proxyshell size={70} color={this.colors.blue} opacity={1} />,
    color: this.colors.blue,
    duration: 2000,
    size: 120,
    strokeWidth: 2,
    strokeDashoffset: '20 100',
    title: 'ProxyShell TTPs',
  };

  // Run the first animation
  componentDidMount() {
    const {
      team1,
      team2,
      path1,
      path2,
      path3,
      path4,
      product,
      world,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(team1, 500),
        this.fadeIn(team2, 1000),
        this.fadeIn(product, 1500),
        this.fadeIn(world, 2000),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '', team1 = {}, team2 = {} } = this.props;

    Object.assign(this.team1, team1);
    Object.assign(this.team2, team2);

    return (
      <div className={`Template__TeamsScanWorld ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* Teams */}
          <div
            className="player team1"
            data-active="0"
            ref={node => (this.players.team1 = node)}
          >
            <Shield {...this.team1} />
          </div>
          <div
            className="player team2"
            data-active="0"
            ref={node => (this.players.team2 = node)}
          >
            <Shield {...this.team2} />
          </div>

          {/* Icon */}
          <div
            className="player product"
            data-active="0"
            ref={node => (this.players.product = node)}
          >
            <label>{this.product.title}</label>
            <PlayerIconLoad
              strokeWidth={this.product.strokeWidth}
              size={this.product.size}
              start={{
                icon: this.product.icon,
              }}
              loading={{
                delay: 0,
                duration: this.product.duration,
                strokeDashoffset: this.product.strokeDashoffset,
              }}
            />
          </div>

          {/* Paths */}
          <div
            className="player path1"
            data-active="1"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,0', '40,0', '150,85', '190,85']}
              reveal={this.revealPreset(2000, 1000)}
              gradient={{ preset: 'blueWhite', direction: 'right' }}
            />
          </div>
          <div
            className="player path2"
            data-active="1"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,85', '40,85', '150,0', '190,0']}
              reveal={this.revealPreset(3000, 1000)}
              gradient={{ preset: 'blueWhite', direction: 'right' }}
            />
          </div>
          <div
            className="player path3"
            data-active="1"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,0', '50,0']}
              reveal={this.revealPreset(3000, 500)}
              dots={{ stroke: this.colors.white, opacity: 0.5 }}
            />
          </div>
          <div
            className="player path4"
            data-active="1"
            ref={node => (this.players.path4 = node)}
          >
            <PlayerPath
              points={['0,0', '50,0'].reverse()}
              reveal={this.revealPreset(4000, 500)}
              dots={{ stroke: this.colors.white, opacity: 0.5 }}
            />
          </div>

          {/* World */}
          <div
            className="player world"
            data-active="0"
            ref={node => (this.players.world = node)}
          >
            <World size={600} animation={{ type: 'scan' }} />
          </div>

          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
