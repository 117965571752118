/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Skulls extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#C53D43',
      size = 35,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        width={size}
        height={size}
        viewBox={`0 0 60 60`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          fillOpacity={opacity}
        >
          <path d="M36.1049 18.913C37.8261 18.913 39.2264 17.499 39.2264 15.7609C39.2264 14.0228 37.8261 12.6087 36.1049 12.6087C34.3837 12.6087 32.9833 14.0228 32.9833 15.7609C32.9833 17.499 34.3837 18.913 36.1049 18.913Z" />
          <path d="M24.8674 12.6087C23.1462 12.6087 21.7458 14.0228 21.7458 15.7609C21.7458 17.499 23.1462 18.913 24.8674 18.913C26.5886 18.913 27.9889 17.499 27.9889 15.7609C27.9889 14.0228 26.5886 12.6087 24.8674 12.6087Z" />
          <path d="M31.7347 15.7609C31.7347 13.3274 33.695 11.3478 36.1049 11.3478C38.5147 11.3478 40.475 13.3274 40.475 15.7609C40.475 18.1943 38.5147 20.1739 36.1049 20.1739C33.695 20.1739 31.7347 18.1943 31.7347 15.7609ZM31.1229 20.1739H29.8618C29.6452 20.1739 29.4441 20.0605 29.3305 19.8751C29.2169 19.6891 29.2069 19.4571 29.3031 19.2617L29.9274 18.0008C30.139 17.5734 30.8332 17.5734 31.0442 18.0008L31.6186 19.1608C31.6991 19.2668 31.7472 19.3991 31.7472 19.5435C31.7472 19.8915 31.4675 20.1739 31.1229 20.1739ZM24.8674 20.1739C22.4575 20.1739 20.4972 18.1943 20.4972 15.7609C20.4972 13.3274 22.4575 11.3478 24.8674 11.3478C27.2772 11.3478 29.2375 13.3274 29.2375 15.7609C29.2375 18.1943 27.2772 20.1739 24.8674 20.1739ZM41.7136 11.3478C39.2745 11.3478 36.8028 9.59396 36.8028 5.67391C36.8028 5.32591 37.0819 5.04348 37.4271 5.04348C37.7718 5.04348 38.0514 5.32591 38.0514 5.67391C38.0514 8.92759 39.9481 10.087 41.7236 10.087L42.7231 10.1021C41.5688 4.34685 36.5213 0 30.4861 0C24.4566 0 19.4116 4.33865 18.2516 10.087H19.2486C21.0241 10.087 22.9208 8.92759 22.9208 5.67391C22.9208 5.32591 23.2005 5.04348 23.5451 5.04348C23.8903 5.04348 24.1694 5.32591 24.1694 5.67391C24.1694 9.59396 21.6978 11.3478 19.2486 11.3478H18.0631C18.0225 11.7627 18 12.1825 18 12.6087V15.7609C18 19.3726 20.7476 22.3477 24.2431 22.6673V29H27.9889V22.0652C27.9889 21.7172 28.2686 21.4348 28.6132 21.4348C28.9578 21.4348 29.2375 21.7172 29.2375 22.0652V29H31.7347V22.0652C31.7347 21.7172 32.0144 21.4348 32.359 21.4348C32.7036 21.4348 32.9833 21.7172 32.9833 22.0652V29H36.7292V22.6673C40.2247 22.3477 42.9722 19.3726 42.9722 15.7609V12.6087C42.9722 12.1895 42.9497 11.7753 42.9098 11.3667L41.7136 11.3478Z" />
          <path d="M36.1049 49.913C37.8261 49.913 39.2264 48.499 39.2264 46.7609C39.2264 45.0228 37.8261 43.6087 36.1049 43.6087C34.3837 43.6087 32.9833 45.0228 32.9833 46.7609C32.9833 48.499 34.3837 49.913 36.1049 49.913Z" />
          <path d="M24.8674 43.6087C23.1462 43.6087 21.7458 45.0228 21.7458 46.7609C21.7458 48.499 23.1462 49.913 24.8674 49.913C26.5886 49.913 27.9889 48.499 27.9889 46.7609C27.9889 45.0228 26.5886 43.6087 24.8674 43.6087Z" />
          <path d="M31.7347 46.7609C31.7347 44.3274 33.695 42.3478 36.1049 42.3478C38.5147 42.3478 40.475 44.3274 40.475 46.7609C40.475 49.1943 38.5147 51.1739 36.1049 51.1739C33.695 51.1739 31.7347 49.1943 31.7347 46.7609ZM31.1229 51.1739H29.8618C29.6452 51.1739 29.4441 51.0605 29.3305 50.8751C29.2169 50.6891 29.2069 50.4571 29.3031 50.2617L29.9274 49.0008C30.139 48.5734 30.8332 48.5734 31.0442 49.0008L31.6186 50.1608C31.6991 50.2668 31.7472 50.3991 31.7472 50.5435C31.7472 50.8915 31.4675 51.1739 31.1229 51.1739ZM24.8674 51.1739C22.4575 51.1739 20.4972 49.1943 20.4972 46.7609C20.4972 44.3274 22.4575 42.3478 24.8674 42.3478C27.2772 42.3478 29.2375 44.3274 29.2375 46.7609C29.2375 49.1943 27.2772 51.1739 24.8674 51.1739ZM41.7136 42.3478C39.2745 42.3478 36.8028 40.594 36.8028 36.6739C36.8028 36.3259 37.0819 36.0435 37.4271 36.0435C37.7718 36.0435 38.0514 36.3259 38.0514 36.6739C38.0514 39.9276 39.9481 41.087 41.7236 41.087L42.7231 41.1021C41.5688 35.3468 36.5213 31 30.4861 31C24.4566 31 19.4116 35.3387 18.2516 41.087H19.2486C21.0241 41.087 22.9208 39.9276 22.9208 36.6739C22.9208 36.3259 23.2005 36.0435 23.5451 36.0435C23.8903 36.0435 24.1694 36.3259 24.1694 36.6739C24.1694 40.594 21.6978 42.3478 19.2486 42.3478H18.0631C18.0225 42.7627 18 43.1825 18 43.6087V46.7609C18 50.3726 20.7476 53.3477 24.2431 53.6673V60H27.9889V53.0652C27.9889 52.7172 28.2686 52.4348 28.6132 52.4348C28.9578 52.4348 29.2375 52.7172 29.2375 53.0652V60H31.7347V53.0652C31.7347 52.7172 32.0144 52.4348 32.359 52.4348C32.7036 52.4348 32.9833 52.7172 32.9833 53.0652V60H36.7292V53.6673C40.2247 53.3477 42.9722 50.3726 42.9722 46.7609V43.6087C42.9722 43.1895 42.9497 42.7753 42.9098 42.3667L41.7136 42.3478Z" />
        </g>
      </svg>
    );
  }
}
