import { observer } from 'mobx-react';
import * as React from 'react';
import { Component } from 'react';
import {
  BarChart,
  YAxis,
  XAxis,
  CartesianGrid,
  Bar,
  LabelList,
  Tooltip,
} from 'recharts';

const formatCurrency = new Intl.NumberFormat('en-US', {
  currency: 'USD',
}).format;

export interface IProps extends Pick<HTMLElement, 'className'> {
  /** Data */
  data: any[];
  /** This is required, and will default to 500 */
  width: number;
  /** This is required, and will default to 500 */
  height: number;
}

@observer
export class SOCCalculatorChart extends Component<IProps> {
  private renderCustomizedLabel = (drawComparisonLine = false) => props => {
    const { x, y, width, height, index, value } = props;

    let comparisonLine: any = null;
    if (drawComparisonLine) {
      const data = this.props.data[index];
      if (data) {
        const inHouseHeight = (data.total / value) * height;
        if (inHouseHeight > height) {
          const right = x + width / 2;
          const top = y - inHouseHeight + height - 15;
          const bottom = y - 30;
          const left = x - width / 2;

          comparisonLine = (
            <g>
              <line
                strokeWidth="2"
                x1={right}
                y1={bottom}
                x2={right}
                y2={top}
                stroke="white"
              />
              <line
                strokeWidth="2"
                x1={right}
                y1={top}
                x2={left}
                y2={top}
                stroke="white"
              />
              <line
                strokeWidth="2"
                x1={right}
                y1={bottom}
                x2={right - 5}
                y2={bottom - 5}
                stroke="white"
              />
              <line
                strokeWidth="2"
                x1={right}
                y1={bottom}
                x2={right + 5}
                y2={bottom - 5}
                stroke="white"
              />
            </g>
          );
        }
      }
    }

    return (
      <g>
        <text
          x={x + width / 2}
          y={y - 10}
          textAnchor="middle"
          fill="white"
          fontSize={14}
        >
          ${formatCurrency(value)}
        </text>
        {comparisonLine}
      </g>
    );
  };

  private generateVerticalCoordinates = props => {
    let width = Number(props.width);
    const offset = (props.offset || {}) as { left: number };
    const left = Number(offset.left);
    width = width - left;
    return [left + width / 3, left + (width / 3) * 2];
  };

  render() {
    const { children, className = '', width = 500, height = 500, data = [] } = {
      ...this.props,
    };

    return (
      <BarChart
        className={`BarChart Graph ${className}`}
        {...{ width, height }}
        data={data}
        barSize={50}
        barGap={50}
      >
        <YAxis
          axisLine={false}
          tickLine={false}
          width={100}
          tickFormatter={tick => `$${formatCurrency(tick)}`}
          domain={[
            0,
            max => (Math.floor((max + max / 10) / 100000) + 1) * 100000, // add 10% to the max value and round to nearest 100k
          ]}
        />
        <XAxis tickLine={false} dataKey="label" />
        <CartesianGrid
          verticalCoordinatesGenerator={this.generateVerticalCoordinates}
          stroke="rgba(255, 255, 255, 0.25)"
        />
        <Tooltip
          isAnimationActive={false}
          content={this.renderTooltip}
          cursor={false}
        />
        <Bar
          dataKey="intelligence"
          stackId="a"
          fill="#F36C21"
          stroke="#1F2224"
          isAnimationActive={false}
        />
        <Bar
          dataKey="staffing"
          stackId="a"
          fill="#50C2E3"
          stroke="#1F2224"
          isAnimationActive={false}
        />
        <Bar
          dataKey="tools"
          stackId="a"
          fill="#54D5AE"
          stroke="#1F2224"
          isAnimationActive={false}
        />
        <Bar
          dataKey="maintenance"
          stackId="a"
          fill="#FFE600"
          stroke="#1F2224"
          isAnimationActive={false}
        >
          <LabelList dataKey="total" content={this.renderCustomizedLabel()} />
        </Bar>
        <Bar
          dataKey="esentire"
          fill="#BF262C"
          stroke="#1F2224"
          isAnimationActive={false}
        >
          <LabelList
            dataKey="esentire"
            content={this.renderCustomizedLabel(true)}
          />
        </Bar>
        {children}
      </BarChart>
    );
  }

  renderTooltip = data => {
    if (!(data && data.payload && data.payload.length)) return;

    return (
      <div className="ProbabilityInstantChart__Tooltip">
        <div className="Tooltip__Header">{data.label}</div>
        <span className="Tooltip__TH">Category</span>
        <span className="Tooltip__TH">Cost</span>
        {data.payload.map((series, i) => {
          return (
            <React.Fragment key={`${series.name}`}>
              <span className="Tooltip__Line" />
              <span
                className="Tooltip__Dot"
                style={{ background: series.fill }}
              />
              <span className="Tooltip__NameCell">{series.name}</span>
              <span className="Tooltip__ValueCell">
                ${formatCurrency(series.value)}
              </span>
            </React.Fragment>
          );
        })}
      </div>
    );
  };
}
