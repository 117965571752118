/** @jsx createElement */
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { createElement, Component } from 'react';
import { random } from 'lodash';
import './text-lines.scss';

@observer
export class PlayerTextLines extends Component {
  @observable values = [];

  lines = null;
  interval = null;

  defaults = {
    duration: 50,
    characters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    rows: 2,
    columns: 4,
    group: 2,
  };

  componentWillMount() {
    const { characters, rows, columns, group } = this.props;
    this.defaults.characters = characters || this.defaults.characters;
    this.defaults.rows = rows || this.defaults.rows;
    this.defaults.columns = columns || this.defaults.columns;
    this.defaults.group = group || this.defaults.group;
    this.animateCharacters();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  animateCharacters() {
    const {
      rows = this.defaults.rows,
      duration = this.defaults.duration,
      group = this.defaults.group,
      columns = this.defaults.columns,
    } = this.props;

    let total = parseInt(rows) * parseInt(columns) * parseInt(group);

    this.values = this.makeArray(total);

    this.interval = setInterval(() => {
      // If we want them all changing at the same time
      this.values.forEach((value, index) => {
        this.values[index] = this.getCharacter();
      });
      // If we want them random
      // this.values[random(0, total-1)] = characters[random(0, characters.length)];
    }, duration);
  }

  stopAnimation() {
    clearInterval(this.interval);
  }

  getCharacter() {
    let string = this.defaults.characters;
    return string[random(0, string.length)];
  }

  makeArray(length) {
    return Array.from({ length: length }, (v, i) => i);
  }

  render() {
    const { className = '', group = '1', values = [] } = this.props;

    return (
      <div
        className={`player--textLines ${className} group-${group}`}
        ref={node => (this.lines = node)}
      >
        {this.values.map((entry, key) => {
          return (
            <div key={key} className={`player--textLines-group`}>
              {entry}
            </div>
          );
        })}
      </div>
    );
  }
}
