import classnames from 'classnames';
import * as React from 'react';
import {
  MitreDataStore,
  MitreDataMode,
  AttackType,
  FilterType,
} from '../../data/mitre-data.store';
import { observer } from 'mobx-react';
import './mitre-mapping-chart.scss';
import { InfoI } from '../svg/info-i';
import { MitreSelect } from './mitre-select';
import { observable } from 'mobx';
import { ScrollView } from '../scroll-view/scroll-view';

export const ATTACK_OPTIONS = [
  { label: 'None', value: AttackType.NONE },
  { label: 'Cobalt Strike', value: AttackType.COBALT_STRIKE },
  { label: 'Dark Comet', value: AttackType.DARK_COMET },
  { label: 'Ransomware', value: AttackType.RANSOMWARE },
  { label: 'Wannacry', value: AttackType.WANNACRY },
  { label: 'Dridex', value: AttackType.DRIDEX },
  { label: 'Trickbot', value: AttackType.TRICKBOT },
  { label: 'Solar Winds', value: AttackType.SOLARWINDS },
  { label: 'Ryuk', value: AttackType.RYUK },
];

@observer
export class MitreMappingChart extends React.Component {
  @observable tooltip = null;
  @observable tooltipBox = null;

  handleReset = () => {
    MitreDataStore.filters = [];
    MitreDataStore.attack = AttackType.NONE;
  };

  handleChangeAttack = option => {
    MitreDataStore.attack = option.value;
  };

  handleShowInfo = (title, info) => {
    return e => {
      this.tooltip = { title, info };
      const box = JSON.parse(
        JSON.stringify(e.currentTarget.getBoundingClientRect())
      );
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      this.tooltipBox = {};

      // Position the box to the left of the tooltip icon
      if (box.left > screenWidth / 2) {
        this.tooltipBox.right = screenWidth - box.left;
      }

      // Position the box to the right of the tooltip icon
      else {
        this.tooltipBox.left = box.right;
      }

      // Position the box above the tooltip icon
      if (box.top > screenHeight / 2) {
        this.tooltipBox.bottom = screenHeight - box.top;
      }

      // Position the box below the tooltip icon
      else {
        this.tooltipBox.top = box.bottom;
      }
    };
  };

  render() {
    const { className, containerProps } = this.props;
    const data = MitreDataStore.chartData;
    const columnCount = MitreDataStore.columnCount;
    const attack = MitreDataStore.attack;
    const filters = MitreDataStore.filters;

    return (
      <div
        className={classnames('MitreMappingChart', className)}
        {...containerProps}
      >
        <div className="MitreMappingChart__ChartHeader">
          {data.columns.map((col, i) =>
            this.renderHeaderColumn(col, columnCount, i)
          )}
        </div>
        <ScrollView
          className="MitreMappingChart__Chart"
          contentClassName="MitreMappingChart__Scroll"
        >
          {data.columns.map((col, i) => this.renderColumn(col, columnCount, i))}
        </ScrollView>
        <div className="MitreMappingChart__Footer">
          {MitreDataStore.mode === MitreDataMode.ENTERPRISE
            ? this.renderControls(filters, attack)
            : this.renderCloudControls(filters)}
        </div>
        {this.renderTooltip()}
      </div>
    );
  }

  renderCloudControls() {
    return (
      <div className="MitreMappingChart__Controls">
        <div className="MitreMappingChart__Container">
          <div className="MitreMappingChart__LeftContainer">
            <div className="MitreMappingChart__ControlLabel">
              eSentire MDR for Log
            </div>
          </div>
          <div className="MitreMappingChart__CenterContainer">
            {this.renderButton(
              'MitreMappingChart--blue',
              FilterType.AWS,
              'AWS'
            )}
            {this.renderButton(
              'MitreMappingChart--red',
              FilterType.Azure,
              'Azure'
            )}
            {this.renderButton(
              'MitreMappingChart--green',
              FilterType.GCP,
              'GCP'
            )}
            {this.renderButton(
              'MitreMappingChart--yellow',
              FilterType.O365,
              'Office 365'
            )}
            {this.renderButton(
              'MitreMappingChart--dark-blue',
              FilterType.GSuite,
              'GSuite'
            )}
          </div>
        </div>
      </div>
    );
  }

  renderControls(filters, attack) {
    return (
      <div className="MitreMappingChart__Controls">
        <div className="MitreMappingChart__Container">
          <div className="MitreMappingChart__LeftContainer">
            <div className="MitreMappingChart__ControlLabel">
              Product Coverage
            </div>
            {this.renderButton(
              'MitreMappingChart--blue',
              FilterType.esEndpoint,
              'eSentire MDR for Endpoint'
            )}
            {this.renderButton(
              'MitreMappingChart--red',
              FilterType.esNetwork,
              'eSentire MDR for Network'
            )}
            {this.renderButton(
              'MitreMappingChart--green',
              FilterType.esLog,
              'eSentire MDR for Log'
            )}
            {this.renderButton(
              'MitreMappingChart--yellow',
              FilterType.esInsider,
              'eSentire MDR for Insider Threat'
            )}

            <div className="MitreMappingChart__Bar" />
            <div className="MitreMappingChart__ControlLabel">Attack Type</div>
            <MitreSelect
              className={`MitreMappingChart__Select`}
              value={attack}
              options={ATTACK_OPTIONS}
              onChange={this.handleChangeAttack}
              label=""
            />
          </div>
          <div className="MitreMappingChart__RightContainer">
            <div
              className="MitreMappingChart__ResetButton"
              onClick={this.handleReset}
            >
              Reset
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderButton(dotColor, filterToggle, label) {
    return (
      <div
        className={classnames(
          'MitreMappingChart__FilterButton',
          MitreDataStore.hasFilter(filterToggle) ? dotColor : ''
        )}
        onClick={() => MitreDataStore.toggleFilter(filterToggle)}
      >
        <div
          className={classnames(
            'MitreMappingChart__Dot',
            MitreDataStore.hasFilter(filterToggle) ? '' : dotColor
          )}
        />
        <div className="MitreMappingChart__FilterButtonLabel">{label}</div>
      </div>
    );
  }

  renderHeaderColumn = (column, columnCount, key) => {
    const count = columnCount.get(column.name);

    return (
      <div key={key} className="MitreMappingChart__Column">
        <div className="MitreMappingChart__Header">
          <div
            className={classnames(
              'MitreMappingChart__Count',
              MitreDataStore.showCount ? '' : 'MitreMappingChart--hide'
            )}
          >
            <span>{count[0]}</span> / <span>{count[1]}</span>
          </div>
          <div className="MitreMappingChart__HeaderTitle">{column.name}</div>
        </div>
      </div>
    );
  };

  renderColumn = (column, columnCount, key) => {
    return (
      <div key={key} className="MitreMappingChart__Column">
        {column.cells.map((cell, i) => this.renderCell(cell, i))}
      </div>
    );
  };

  renderCell = (cell, key) => {
    const filterSet = MitreDataStore.filterSet;
    const cellFilterSet = new Set(cell.filters);
    const attack = MitreDataStore.attack;
    let isAttackFilter = attack !== AttackType.NONE;
    let isFilter = MitreDataStore.filters.length > 0;
    let info = '';
    let fadeCell = false;
    let hideCell = false;
    let warnCell = false;
    let unfadeCell = false;
    const attackIndex = cell.attacks.indexOf(attack);

    let hasFilter = false;
    filterSet.forEach(
      filter => (hasFilter = hasFilter || cellFilterSet.has(filter))
    );
    const hasAttack = attackIndex >= 0;

    // If there is a filter and an attack filter, then we hide the cell if
    // neither attack nor filter is available on the cell. We warn on cells that
    // have the attack, but lack the filter
    if (isFilter && isAttackFilter) {
      if (hasAttack) {
        if (!hasFilter) fadeCell = true;
        else unfadeCell = true;
        info = cell.attackInfo[attackIndex];
      } else {
        hideCell = true;
      }
    }

    // If we are only showing filters, then we fade the cell if the cell does
    // not have the filter
    else if (isFilter) {
      if (!hasFilter) fadeCell = true;
    }

    // If we are showing attacks, then we fade the cell if it does not have the
    // attack and we warn the cell if it does have the attack.
    else if (isAttackFilter) {
      if (hasAttack) {
        warnCell = true;
        info = cell.attackInfo[attackIndex];
      } else fadeCell = true;
    }

    return (
      <div
        key={key}
        className={classnames(
          'MitreMappingChart__Cell',
          unfadeCell
            ? 'MitreMappingChart--unfade'
            : fadeCell
            ? 'MitreMappingChart--fade'
            : warnCell
            ? 'MitreMappingChart--warn'
            : hideCell
            ? 'MitreMappingChart--shrink'
            : ''
        )}
      >
        <div
          className={classnames(
            'MitreMappingChart__Info',
            !hasAttack ? 'MitreMappingChart--hide' : ''
          )}
          onClick={this.handleShowInfo(cell.title, info)}
        >
          <InfoI size={10} color={'rgba(255, 255, 255, 0.6)'} />
        </div>
        <div className="MitreMappingChart__Title">{cell.title}</div>
        <div className="MitreMappingChart__Filters">
          {MitreDataStore.mode === MitreDataMode.ENTERPRISE ? (
            <div className="MitreMappingChart__FilterBars">
              <div
                className={classnames(
                  'MitreMappingChart__FilterBar',
                  cellFilterSet.has(FilterType.esEndpoint) &&
                    filterSet.has(FilterType.esEndpoint)
                    ? 'MitreMappingChart--blue'
                    : ''
                )}
              />
              <div
                className={classnames(
                  'MitreMappingChart__FilterBar',
                  cellFilterSet.has(FilterType.esNetwork) &&
                    filterSet.has(FilterType.esNetwork)
                    ? 'MitreMappingChart--red'
                    : ''
                )}
              />
              <div
                className={classnames(
                  'MitreMappingChart__FilterBar',
                  cellFilterSet.has(FilterType.esLog) &&
                    filterSet.has(FilterType.esLog)
                    ? 'MitreMappingChart--green'
                    : ''
                )}
              />
              <div
                className={classnames(
                  'MitreMappingChart__FilterBar',
                  cellFilterSet.has(FilterType.esInsider) &&
                    filterSet.has(FilterType.esInsider)
                    ? 'MitreMappingChart--yellow'
                    : ''
                )}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  renderTooltip() {
    if (!this.tooltip) return null;

    return (
      <div
        className="MitreMappingChart__Cover"
        onClick={e => (this.tooltip = null)}
      >
        <div
          className="MitreMappingChart__Tooltip"
          onClick={e => e.stopPropagation()}
          style={{ ...this.tooltipBox }}
        >
          <h1>{this.tooltip.title}</h1>
          <p>{this.tooltip.info}</p>
        </div>
      </div>
    );
  }
}
