/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Document extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 38,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 38 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 26.0534C0 28.2299 1.73171 30.0007 3.86025 30.0007H33.1982C35.3267 30.0007 37.0584 28.2299 37.0584 26.0534V9.47461H0V26.0534Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.441 6.31572C14.5894 6.31572 13.8969 5.60757 13.8969 4.73679C13.8969 3.86601 14.5894 3.15786 15.441 3.15786C16.2926 3.15786 16.9851 3.86601 16.9851 4.73679C16.9851 5.60757 16.2926 6.31572 15.441 6.31572ZM10.8087 6.31572C9.95714 6.31572 9.26461 5.60757 9.26461 4.73679C9.26461 3.86601 9.95714 3.15786 10.8087 3.15786C11.6603 3.15786 12.3528 3.86601 12.3528 4.73679C12.3528 5.60757 11.6603 6.31572 10.8087 6.31572ZM6.1764 6.31572C5.32483 6.31572 4.6323 5.60757 4.6323 4.73679C4.6323 3.86601 5.32483 3.15786 6.1764 3.15786C7.02798 3.15786 7.72051 3.86601 7.72051 4.73679C7.72051 5.60757 7.02798 6.31572 6.1764 6.31572ZM33.1982 0H3.86025C1.73171 0 0 1.77077 0 3.94733V7.89465H37.0584V3.94733C37.0584 1.77077 35.3267 0 33.1982 0Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
