/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import {
  getColor,
  rgba,
  activate,
  deactivate,
  shimmer,
  activateShimmer,
} from '../helpers';
import ReactDOM from 'react-dom';
import anime from 'animejs';
import './info-block.scss';

// SVG
import { Warning } from '../svg/warning';

@observer
export class PlayerInfoBlock extends Component {
  // Default wreapper styles
  wrapperStyles = {
    width: 300,
  };

  // Gap between stuff
  gap = 10;

  // Section references
  sectionRefs = [];

  // Style presets
  cellStylePresets = {
    default: {
      background: rgba('white', 0.2),
      color: getColor('white'),
    },
    red: {
      background: rgba('red', 1),
      color: getColor('white'),
    },
    redFutura: {
      background: rgba('red', 1),
      color: getColor('white'),
      fontFamily: `Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif`,
    },
    blue: {
      background: rgba('blue', 1),
      color: getColor('white'),
    },
    orange: {
      background: rgba('orange', 1),
      color: getColor('white'),
    },
    redBorder: {
      background: 'none',
      color: getColor('white'),
      border: `2px solid ${getColor('red')}`,
    },
    blueBorder: {
      background: 'none',
      color: getColor('white'),
      border: `2px solid ${getColor('blue')}`,
    },
  };

  animation = {
    delay: 0,
    duration: 200,
    increment: 200,
  };

  increment = 0;

  // Component mounted
  componentDidMount() {
    // Animate sections
    this.animateSections();
  }

  animateSections() {
    for (let i = 0; i < this.sectionRefs.length; i++) {
      let section = this.sectionRefs[i];
      anime({
        targets: section.node,
        opacity: 1,
        easing: 'easeOutQuad',
        duration: this.animation.duration,
        delay: this.animation.delay + this.increment,
        begin: activate,
        complete: activateShimmer,
      });
      this.increment += this.animation.increment;
    }
  }

  // Type function
  typeTitle(section, index) {
    return section.content.map((value, key) => {
      return this.renderContents(value, 'Sections__Section Type__Title');
    });
  }

  typeCells(section, index) {
    return (
      <div
        className="Sections__Section Type__Cells"
        key={index}
        ref={node => (this.sectionRefs[index].node = node)}
      >
        {this.renderCells(section.content, 'Type__Cells__Cell')}
      </div>
    );
  }

  typeGrid(section, index) {
    let gridStyles = {};
    if (!('columns' in section)) {
      if (Array.isArray(section.content) && section.content.length <= 1) {
        section.columns = 1;
      } else {
        section.columns = 2;
      }
    }
    gridStyles.gridTemplateColumns = `repeat(${section.columns}, 1fr)`;
    gridStyles.gap = section.gap || this.gap;
    return (
      <div
        className="Sections__Section Type__Grid"
        key={index}
        style={gridStyles}
        ref={node => (this.sectionRefs[index].node = node)}
      >
        {this.renderCells(section.content, `Type__Grid__Item`)}
      </div>
    );
  }

  typeHeader(section, index) {
    // Set default color
    if (!('color' in section)) {
      section.color = 'blue';
    }
    let headerStyles = {};
    let blockStyles = {};
    headerStyles.borderColor = getColor(section.color);
    headerStyles.color = getColor(section.color);
    blockStyles.background = getColor(section.color);
    let hasIcon = 'no-icon';
    let icon = (
      <span className="Type__Header__Block" style={blockStyles}></span>
    );
    if ('icon' in section) {
      hasIcon = 'has-icon';
      icon = section.icon;
    }
    return (
      <div
        className={`Sections__Section Type__Header ${hasIcon}`.trim()}
        key={index}
        style={headerStyles}
        ref={node => (this.sectionRefs[index].node = node)}
      >
        {icon}
        {this.renderContents(section.content, 'Type__Header__Cell')}
      </div>
    );
  }

  typeSpacer(section, index) {
    return (
      <div
        className="Sections__Section Type__Spacer"
        key={index}
        ref={node => (this.sectionRefs[index].node = node)}
      ></div>
    );
  }

  typeAlert(section, index) {
    return (
      <div
        className="Sections__Section Type__Alert"
        key={index}
        ref={node => (this.sectionRefs[index].node = node)}
      >
        <Warning size={14} />
        {section.content}
      </div>
    );
  }

  // Type rendering functions
  types = {
    title: (section, index) => this.typeTitle(section, index),
    cells: (section, index) => this.typeCells(section, index),
    grid: (section, index) => this.typeGrid(section, index),
    header: (section, index) => this.typeHeader(section, index),
    spacer: (section, index) => this.typeSpacer(section, index),
    alert: (section, index) => this.typeAlert(section, index),
    list: function(section, index) {
      return (
        <div
          className="Sections__Section Type__List"
          key={index}
          ref={node => (this.sectionRefs[index].node = node)}
        >
          {section.content.map((value, key) => {
            return (
              <div className="Type__List__Item" key={key}>
                {value}
              </div>
            );
          })}
        </div>
      );
    },
    text: function(section, index) {
      return (
        <div
          className="Sections__Section Type__Text"
          key={index}
          ref={node => (this.sectionRefs[index].node = node)}
        >
          {section.content.map((value, key) => {
            return <div key={key}>{value}</div>;
          })}
        </div>
      );
    },
    links: function(section, index) {
      return (
        <div
          className="Sections__Section Type__Links"
          key={index}
          ref={node => (this.sectionRefs[index].node = node)}
        >
          {section.content.map((value, key) => {
            return (
              <div className="Type__Links__Link" key={key}>
                {value}
              </div>
            );
          })}
        </div>
      );
    },
  };

  // Render multiple cells
  renderCells(cells, className = '') {
    if (Array.isArray(cells)) {
      return cells.map((cell, index) =>
        this.renderCell(cell, index, className)
      );
    }
    if (typeof cells === 'object') {
      return this.renderCell(cells, 0, className);
    }
    if (typeof cells === 'string') {
      return this.renderCell(
        {
          html: cells,
        },
        0,
        className
      );
    }
  }

  renderContents(content, className = '') {
    if (Array.isArray(content)) {
      return content.map((cell, index) =>
        this.renderContent(cell, index, className)
      );
    }
    if (typeof content === 'object') {
      return this.renderContent(content, 0, className);
    }
    if (typeof content === 'string') {
      return this.renderContent(
        {
          html: content,
        },
        0,
        className
      );
    }
  }

  renderContent(content, key = 0, className = '') {
    // Create style if doesn't exist
    if (typeof content === 'string') {
      content = { html: content };
    }
    let contentStyles = {};
    // Apply style presets if provided
    if ('preset' in content && content.preset in this.cellStylePresets) {
      Object.assign(contentStyles, this.cellStylePresets[content.preset]);
    }
    // Merge user provided styles
    if ('style' in content) {
      Object.assign(contentStyles, content.style);
    }
    return (
      <div className={className} style={contentStyles} key={key}>
        {content.html}
      </div>
    );
  }

  // Render a single cell in the cells type
  renderCell(cell, key = 0, addClassName = 'Type__Cells__Cell') {
    const { className = '' } = cell;
    // Create style if doesn't exist
    if (typeof cell === 'string') {
      cell = { html: cell };
    }
    let cellStyles = {};
    // Apply style presets if provided
    if ('preset' in cell && cell.preset in this.cellStylePresets) {
      Object.assign(cellStyles, this.cellStylePresets[cell.preset]);
    } else {
      Object.assign(cellStyles, this.cellStylePresets.default);
    }
    // Merge user provided styles
    if ('style' in cell) {
      Object.assign(cellStyles, cell.style);
    }
    return (
      <div
        className={`${addClassName} ${className}`}
        style={cellStyles}
        key={key}
      >
        {cell.html}
      </div>
    );
  }

  renderSection(section, key) {
    if (!(section.type in this.types)) return '';
    // Apply animation defaults
    this.sectionRefs[key] = {
      node: null,
    };
    // Return the correction section
    return this.types[section.type](section, key);
  }

  renderSections(sections) {
    return sections.map((section, key) => {
      if (typeof section.content === 'string')
        section.content = [section.content];
      return this.renderSection(section, key);
    });
  }

  // Render
  render() {
    const {
      className = '',
      width = false,
      gap = 0,
      style = {},
      sections = [],
      animation = {},
    } = this.props;

    let { IconComponent = null } = this.props;
    this.gap = gap;

    // Allow styles to be passed in
    Object.assign(this.wrapperStyles, style);

    // Assign animation
    Object.assign(this.animation, animation);

    // Set styles
    if (width) this.wrapperStyles.width = parseInt(width) + 'px';

    // CSS Classes
    let classList = new Array();
    classList.push('Player__InfoBlock');
    classList.push(className);

    // Get number of sections for css grid
    let sectionStyles = {};
    sectionStyles.gridTemplateRows = `repeat(${sections.length}, auto)`;
    sectionStyles.gap = gap;

    // Render
    return (
      <div className={classList.join(' ')} style={this.wrapperStyles}>
        {IconComponent && (
          <div className="Player__InfoBlock__Icon">
            <IconComponent size={14} />
          </div>
        )}
        <div className="Player__InfoBlock__Sections" style={sectionStyles}>
          {this.renderSections(sections)}
        </div>
      </div>
    );
  }
}
