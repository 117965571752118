/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import { EsentireIcon } from './svg/esentire-icon';
import { RouteManager } from 'vega-router';
import { useCases } from '../data/use-cases';
import { AppActions } from './app-actions';
import { BackArrow } from './svg/back-arrow';
import { HamburgerIcon } from './svg/hamburger-icon';
import { ArrowRight } from './svg/arrow-right';
import { ChevronRight } from './svg/chevron-right';
import { getColor } from './helpers';
import './use-case-picker.scss';

@observer
export class UseCasePicker extends Component {
  handleClickUseCase = useCase => () => {
    if (useCase.attributes.indexOf('disabled') > -1) return;
    RouteManager.transitionTo(`/${useCase.key}/slides`);
  };

  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.setShortcutName('Use Cases');
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
  }

  navigateTo = path => () => {
    RouteManager.transitionTo(`/${path}`);
  };

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`UseCasePicker ${className}`}>
        <EsentireIcon className="UseCasePicker__Logo" />
        <button
          className="UseCasePicker__BackButton HamburgerButton"
          onClick={() => RouteManager.transitionTo('/')}
        >
          <ChevronRight color="#fafafa" opacity={0.7} />
        </button>
        <div className="UseCasePicker__UseCases">
          <div className="UseCasePicker__UseCases__Scroller">
            {useCases.map(this.renderUseCase).reverse()}
          </div>
        </div>
      </div>
    );
  }

  renderProducts(products) {
    return (
      <div className="UseCasePicker__Product">
        <span className="UseCasePicker__Product__Text">eSentire</span>
        {products.map((value, index) => {
          return (
            <span className="UseCasePicker__Product__Label" key={index}>
              {value}
            </span>
          );
        })}
      </div>
    );
  }

  renderUseCase = useCase => {
    if (!useCase) return null;

    const {
      icon: Icon,
      title = '',
      product = '',
      attributes = [],
      key,
    } = useCase;

    return (
      <div
        className={`UseCasePicker__UseCase ${attributes
          .map(name => `UseCasePicker__UseCase--${name}`)
          .join(' ')}`}
        onClick={this.handleClickUseCase(useCase)}
        key={key}
      >
        <div className="UseCasePicker__UseCaseContent">
          {typeof Icon === 'function' ? (
            <Icon color="#50C2E3" size={45} />
          ) : (
            Icon
          )}
          <div className="UseCasePicker__Title">{title}</div>
          {this.renderProducts(useCase.products)}
          <div className="UseCasePicker__ArrowButton">
            <ChevronRight color={getColor('blue')} size={20} />
          </div>
        </div>
      </div>
    );
  };
}
