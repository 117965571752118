/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// Players
import { PlayerInfoBlock } from '../players/info-block';
import { PlayerReveal } from '../players/reveal';
import { PlayerPath } from '../players/path';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Skull } from '../svg/skull';
import { Blackhat } from '../svg/blackhat';

@observer
export class Slide03 extends AnimatedSlide {
  // Animation players
  players = {
    card: null,
    info: null,
    reveal: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
    path5: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      card,
      info,
      reveal,
      path1,
      path2,
      path3,
      path4,
      path5,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(1500);
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(card, 0),
        this.fadeIn(path1, 200),
        this.fadeIn(info, 500),
        this.fadeIn(reveal, 1000),
        this.fadeIn(path2, 1000),
        this.fadeIn(path3, 1200),
        this.fadeIn(path4, 1400),
        this.fadeIn(path5, 1600),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-03 ${className}`}>
        {children}
        <div className="content">
          {/* -------------------CONTENT------------------- */}
          {/* Card */}
          <div
            className="player card"
            data-active="0"
            ref={node => (this.players.card = node)}
          >
            <Card IconComponent={Person} text={'Researcher'} delay={200} />
          </div>
          {/* Info Review */}
          <div
            className="player info"
            data-active="0"
            ref={node => (this.players.info = node)}
          >
            <PlayerInfoBlock
              gap={10}
              width={310}
              animation={{
                delay: 500,
              }}
              sections={[
                {
                  type: 'header',
                  color: 'blue',
                  icon: <Blackhat size={40} color={this.colors.blue} />,
                  content: 'pWN20WN 2021',
                },
                {
                  type: 'grid',
                  content: [
                    {
                      html: 'UNAUTHORIZED ATTACKER',
                      preset: 'redBorder',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'EXECUTE' },
                    {
                      preset: 'red',
                      html: 'ARBITRARY COMMANDS',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'ENVIRONMENT' },
                    {
                      preset: 'red',
                      html: 'MICROSOFT EXCHANGE SERVER',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'PORT' },
                    {
                      preset: 'red',
                      html: '443',
                    },
                  ],
                },
              ]}
            />
          </div>
          {/* Reveal */}
          <div
            className="player reveal"
            data-active="0"
            ref={node => (this.players.reveal = node)}
          >
            <div className="skull-boxes">
              <div className="skull-box">
                <Skull size={14} color={this.colors.white} />
                cve-2021-34473
              </div>
              <div className="skull-box">
                <Skull size={14} color={this.colors.white} />
                cve-2021-34523
              </div>
              <div className="skull-box">
                <Skull size={14} color={this.colors.white} />
                cve-2021-31207
              </div>
            </div>
          </div>
          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,63', '20,63', '80,3', '100,3']}
              gradient={{
                direction: 270,
                preset: 'blueWhite',
              }}
              reveal={{
                delay: 500,
              }}
              dots={{
                strokeWidth: 2,
                strokeDashArray: '2,2',
              }}
              data={false}
            />
          </div>
          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,26', '20,26', '50,3', '100,3']}
              dots={{
                stroke: this.colors.red,
                strokeWidth: 2,
                strokeDashArray: '2,2',
              }}
              reveal={{
                delay: 1400,
              }}
              data={false}
            />
          </div>
          {/* Path 3 */}
          <div
            className="player path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,26', '110,26', '140,3', '190,3']}
              dots={{
                stroke: this.colors.red,
                strokeWidth: 2,
                strokeDashArray: '2,2',
              }}
              reveal={{
                delay: 1600,
              }}
              data={false}
            />
          </div>
          {/* Path 4 */}
          <div
            className="player path4"
            data-active="0"
            ref={node => (this.players.path4 = node)}
          >
            <PlayerPath
              points={['0,26', '35,26', '65,3', '115,3']}
              dots={{
                stroke: this.colors.red,
                strokeWidth: 2,
                strokeDashArray: '2,2',
              }}
              reveal={{
                delay: 1800,
              }}
              data={false}
            />
          </div>
          {/* Path 5 */}
          <div
            className="player path5"
            data-active="0"
            ref={node => (this.players.path5 = node)}
          >
            <PlayerPath
              points={['0,26', '243,26', '273,3', '314,3']}
              dots={{
                stroke: this.colors.red,
                strokeWidth: 2,
                strokeDashArray: '2,2',
              }}
              reveal={{
                delay: 2000,
              }}
              data={false}
            />
          </div>
          {/* -------------------CONTENT------------------- */}
        </div>
      </div>
    );
  }
}
