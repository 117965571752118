/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Alert } from '../svg/alert';
import { Computer } from '../svg/computer';
import { Shield } from '../svg/shield';
import { Server } from '../svg/server';
import { ComputerGroup } from '../svg/computer-group';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import { Badge } from '../badge';
import { Warning } from '../svg/warning';

@observer
export class Slide02 extends AnimatedSlide {
  // Animation players
  players = {
    computerGroup: null,
    dotsLeft: null,
    shield: null,
    dotsRight: null,
    computer: null,
    alert: null,
  };

  // Run the first animation
  componentDidMount() {
    // Save the border since I can't bind it in the DOM like the others
    this.players.border = this.players.alert.querySelector('.border');

    const {
      computerGroup,
      dotsLeft,
      shield,
      dotsRight,
      computer,
      alert,
      border,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(7000);
        this.autoNext(3000);
      },
      // Step 2
      () => {
        this.autoNext(4000);
      },
      // Step 3
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: dotsLeft,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: shield,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: computerGroup,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: dotsRight,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },
        {
          targets: computer,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 1000,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: dotsRight,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 1000,
          delay: 0,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        { targets: shield, easing: 'easeOutQuad', opacity: 0.2, duration: 100 },
        {
          targets: computerGroup,
          easing: 'easeOutQuad',
          opacity: 0.2,
          duration: 500,
        },
        {
          targets: computer,
          easing: 'easeOutQuad',
          opacity: 0.2,
          duration: 500,
        },
        {
          targets: dotsLeft,
          easing: 'easeOutQuad',
          opacity: 0.2,
          duration: 200,
        },
        {
          targets: dotsRight,
          easing: 'easeOutQuad',
          opacity: 0.2,
          duration: 200,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-02 ${className}`}>
        {children}
        <div className="content">
          {/* Bad file detected */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'Warning'}
              IconComponent={Warning}
              subtitle={'Download Activity +'}
              line2={'Possible Beaconing'}
            />
          </div>

          {/* computer */}
          <div
            className="player computerGroup"
            data-active="1"
            ref={node => (this.players.computerGroup = node)}
          >
            <ComputerGroup color={'#ffffff'} />
          </div>

          {/* computer */}
          <div
            className="player computer"
            data-active="0"
            ref={node => (this.players.computer = node)}
          >
            <Card IconComponent={Computer} text={'10.0.5.38'} />
          </div>

          {/* Animated dots left */}
          <div
            className="player dots-left"
            data-active="1"
            ref={node => (this.players.dotsLeft = node)}
          >
            <PlayerDots className={'in-out'} />
          </div>

          {/* Animated dots right */}
          <div
            className="player dots-right"
            data-active="0"
            ref={node => (this.players.dotsRight = node)}
          >
            <PlayerDots className={'in-out red-dots'} />
          </div>

          {/* Shield */}
          <div
            className="player shield"
            data-active="1"
            ref={node => (this.players.shield = node)}
          >
            <Shield IconComponent={Server} text="AMP" />
          </div>
        </div>
      </div>
    );
  }
}
