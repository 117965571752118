/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import './slide-show.scss';
import { observable, computed } from 'mobx';
import { AppState } from './app-actions';
import { KeyboardShortcuts } from './keyboard-shortcuts';
import { Chrome } from './chrome';
import { RouteManager } from 'vega-router';
import { SwipeHandler } from './swipe-handler';
import { useCases } from '../data/use-cases';

const dimensions = {
  width: 1112,
  height: 814,
};

@observer
export class SlideShow extends Component {
  currentSlidRef = null;

  @observable currentSlideIndex = 0;
  @observable currentScreenshotIndex = 0;
  @observable useCases = useCases;
  @observable showAppScreenshot = false;
  @observable style = {};

  componentDidMount() {
    AppActions.setShortcutName('Use Cases');
  }

  get currentUseCase() {
    return (
      this.useCases &&
      this.useCases.find(useCase => useCase.key === AppState.useCaseKey)
    );
  }

  @computed
  get currentSlide() {
    return (
      this.currentUseCase &&
      this.currentUseCase.slides &&
      this.currentUseCase.slides.length > this.currentSlideIndex &&
      this.currentUseCase.slides[this.currentSlideIndex]
    );
  }

  get currentSlideSeconds() {
    if (
      this.currentSlide &&
      (this.currentSlide.timelineSummary || this.currentSlide.slideOverview)
    ) {
      return undefined;
    }
    if (this.currentSlide) {
      const [days = 0, hours = 0, minutes = 0, seconds = 0] = (
        this.currentSlide.time || '0:0:0:0'
      ).split(':');
      return (
        Number(days) * 24 * 60 * 60 +
        Number(hours) * 60 * 60 +
        Number(minutes) * 60 +
        Number(seconds)
      );
    }
    return 0;
  }

  get totalSeconds() {
    if (this.currentUseCase && this.currentUseCase.slides) {
      const progressSlides = this.currentUseCase.slides.filter(
        slide => !slide.timelineSummary
      );
      const lastSlide = progressSlides[progressSlides.length - 1];
      if (lastSlide) {
        const [days = 0, hours = 0, minutes = 0, seconds = 0] = (
          lastSlide.time || '0:0:0:0'
        ).split(':');
        return (
          Number(days) * 24 * 60 * 60 +
          Number(hours) * 60 * 60 +
          Number(minutes) * 60 +
          Number(seconds)
        );
      }
    }
    return 0;
  }

  get currentSlideProgress() {
    if (
      this.currentSlide &&
      (this.currentSlide.timelineSummary || this.currentSlide.slideOverview)
    ) {
      return undefined;
    }
    if (this.totalSeconds) {
      return this.currentSlideSeconds / this.totalSeconds;
    }
    return 0;
  }

  componentDidMount() {
    this.checkRoute();

    window.addEventListener('resize', this.handleResize);
    window.addEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
    setTimeout(this.handleResize, 500);
  }

  componentDidUpdate() {
    this.checkRoute();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
  }

  handleOrientationChange = () => {
    setTimeout(this.handleResize, 500);
  };

  handleResize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const scale = Math.min(
      windowWidth / dimensions.width,
      windowHeight / dimensions.height
    );
    this.style = {
      transform: `scale(${scale})`,
      left: `${windowWidth / 2 - dimensions.width / 2}px`,
      top: `${windowHeight / 2 - dimensions.height / 2}px`,
    };
  };

  checkRoute = () => {
    const slide =
      Number(this.props.params && this.props.params.slideNumber) || 1;
    this.gotoSlide(slide - 1);
  };

  gotoSlide = index => {
    index = Number(index);
    if (
      this.currentUseCase &&
      index >= 0 &&
      index < this.currentUseCase.slides.length
    ) {
      this.currentSlideIndex = index;

      this.currentUseCase.slides.forEach((slide, i) => {
        slide.selected = i === index;
        slide.active = i <= index;
      });
    }
  };

  transitiontoScreenshot = index => {
    // Do not do anything if there are not multiple screenshots
    if (!(this.currentSlide.appScreenshotUrl instanceof Array)) return;

    // Get the total number of screenshots
    let totalScreenshots = this.currentSlide.appScreenshotUrl.length - 1;
    index = Number(index);

    // Don't proceed if at the end
    if (index > totalScreenshots) return;
    if (index < 0) return;

    // Move the screenshot
    this.currentScreenshotIndex = index;
  };

  screenshotBy = offset => {
    offset = Number(offset);
    this.transitiontoScreenshot(this.currentScreenshotIndex + offset);
  };

  transitiontoSlide = index => {
    index = Number(index);
    if (
      this.currentUseCase &&
      index >= 0 &&
      index < this.currentUseCase.slides.length
    ) {
      RouteManager.transitionTo(`/${AppState.useCaseKey}/slides/${index + 1}`);
    }
  };

  slideBy = offset => {
    offset = Number(offset);
    this.transitiontoSlide(this.currentSlideIndex + offset);
  };

  handleSlideChange = clickedSlide => {
    this.transitiontoSlide(this.currentUseCase.slides.indexOf(clickedSlide));
  };

  handleSwipeUp = () => {
    // Make sure screenshots can only be displayed if they exist
    if (AppState.appScreenshotEnabled && this.currentSlide.appScreenshotUrl) {
      this.currentScreenshotIndex = 0;
      this.showAppScreenshot = true;
    }
  };

  handleSwipeDown = () => {
    if (AppState.appScreenshotEnabled) {
      this.showAppScreenshot = false;
    }
  };

  handleSwipeLeft = () => {
    // Prevent slide progression while slide is up
    if (this.showAppScreenshot) {
      this.screenshotBy(1);
      return;
    }

    //
    // I'm commenting this out just in-case the customer requests it back.
    //
    // if (this.currentSlidRef && !this.showAppScreenshot) {
    //   if (
    //     this.currentSlidRef.animationIndex <
    //     this.currentSlidRef.animations.length - 1
    //   ) {
    //     this.currentSlidRef.next();
    //     return;
    //   }
    // }

    this.slideBy(1);
  };

  handleSwipeRight = () => {
    // Prevent slide progression while slide is up
    if (this.showAppScreenshot) {
      this.screenshotBy(-1);
      return;
    }

    //
    // I'm commenting this out just in-case the customer requests it back.
    //
    // if (this.currentSlidRef && !this.showAppScreenshot) {
    //   if (this.currentSlidRef.animationIndex > 0) {
    //     this.currentSlidRef.previous();
    //     return;
    //   }
    // }

    this.slideBy(-1);
  };

  render() {
    const { children, className = '' } = this.props;

    return (
      <SwipeHandler
        className={`SlideShow ${className}`}
        onSwipeUp={this.handleSwipeUp}
        onSwipeDown={this.handleSwipeDown}
        onSwipeLeft={this.handleSwipeLeft}
        onSwipeRight={this.handleSwipeRight}
      >
        <KeyboardShortcuts
          debug={true}
          shortcuts={{
            ArrowLeft: this.handleSwipeRight,
            ArrowRight: this.handleSwipeLeft,
            ArrowUp: this.handleSwipeUp,
            ArrowDown: this.handleSwipeDown,
            1: () => this.transitiontoSlide(0),
            2: () => this.transitiontoSlide(1),
            3: () => this.transitiontoSlide(2),
            4: () => this.transitiontoSlide(3),
            5: () => this.transitiontoSlide(4),
            6: () => this.transitiontoSlide(5),
            7: () => this.transitiontoSlide(6),
            8: () => this.transitiontoSlide(7),
            9: () => this.transitiontoSlide(8),
          }}
        />
        <Chrome
          slides={(this.currentUseCase && this.currentUseCase.slides) || []}
          onSlideChange={this.handleSlideChange}
          {...this.currentSlide}
          showTimer={this.currentSlide && this.currentSlide.time !== null}
          seconds={this.currentSlideSeconds}
          progress={this.currentSlideProgress}
          useCaseKey={AppState.useCaseKey}
          showAppScreenshot={this.showAppScreenshot}
          currentScreenshotIndex={this.currentScreenshotIndex}
          setScreenshotIndex={this.transitiontoScreenshot}
          onNextSlide={this.handleSwipeLeft}
          screenShotIndex={this.currentScreenshotIndex}
          onPreviousSlide={this.handleSwipeRight}
        />
        {this.currentSlide && (
          <div
            className={`Slide ${this.currentUseCase.key} ${
              this.showAppScreenshot && this.currentSlide.appScreenshotUrl
                ? 'Slide--disable-animations'
                : ''
            }`}
            style={{
              transform: this.style.transform,
              left: this.style.left,
              top: this.style.top,
            }}
          >
            <this.currentSlide.component
              ref={ref => (this.currentSlidRef = ref)}
            />
          </div>
        )}
        {children}
      </SwipeHandler>
    );
  }
}
