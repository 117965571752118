/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, Fragment } from 'react';
import { observable, toJS, transaction } from 'mobx';
import {
  BackgroundGridState,
  BackgroundGridActions,
} from './background-grid-actions';
import anime from 'animejs';
import './background-grid.scss';

const { ceil, sqrt, floor, round, random } = Math;

@observer
export class BackgroundGrid extends Component {
  previousTile = null;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
    setTimeout(this.handleResize, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
  }

  handleOrientationChange = () => {
    setTimeout(this.handleResize, 500);
  };

  handleResize = () => {
    BackgroundGridActions.setSizeInPixels(
      window.innerWidth,
      window.innerHeight
    );
  };

  render() {
    const { className = '', hidden = false } = this.props;

    const { width, height, tiles, tileSize } = BackgroundGridState;

    return (
      <div
        className={`BackgroundGrid ${className} ${
          hidden ? 'BackgroundGrid--hidden' : ''
        }`}
        style={{
          display: 'block',
          overflow: 'hidden',
        }}
        ref={n => (this.element = n)}
      >
        <div className={'BackgroundGrid__Gradient'} />
        {tiles.map((row, i) => (
          // I don't need the rows. They're just there to speed things up for
          // the debug console. It doesn't like dealing with hundreds of
          // elements.
          <div
            key={i}
            style={{
              position: 'relative',
            }}
            className="row"
          >
            {row.map(tile => (
              <Fragment key={tile.key}>
                <span
                  className={`
                    cell
                    d-${tile.column * tile.row}
                    x-${tile.column}
                    y-${tile.row}
                    r-${round(random() * 500)} ${tile.className}`}
                  style={{
                    transform: `
                      translate(${tile.column * 40}px, ${tile.row * 40}px)
                    `,
                  }}
                />
                <span
                  className={`
                    over-cell
                    d-${tile.column * tile.row}
                    x-${tile.column}
                    y-${tile.row}
                    r-${round(random() * 500)} ${tile.className}`}
                  style={{
                    transform: `
                      translate(${tile.column * 40}px, ${tile.row * 40}px)
                    `,
                  }}
                />
              </Fragment>
            ))}
          </div>
        ))}
      </div>
    );
  }
}
