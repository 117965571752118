/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import './stage.scss';
import { Bug } from './svg/bug';

@observer
export class Stage extends Component {
  render() {
    const {
      children,
      className = '',
      stage = '01',
      Icon = Bug,
      title = '',
    } = this.props;

    return (
      <div className={`Stage ${className}`}>
        <div className={'Stage__Label'}>STAGE</div>
        <div className={'Stage__Stage'}>{stage}</div>
        <div className={'Stage__Line'} />
        <div className="Stage__Icon">
          <Icon size={'40'} color={'#50C2E3'} />
        </div>
        <div className={'Stage__Title'}>{title}</div>
      </div>
    );
  }
}
