/** @jsx createElement */
// SVG
import { Alert } from '../svg/alert';
import { Document } from '../svg/document';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { AppActions } from '../app-actions';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerCircleIcon } from '../players/circle-icon';

@observer
export class Slide22 extends AnimatedSlide {
  // Animation players
  players = {
    title: null,
    alert: null,
    timeline: null,
    domains: null,
    hashes: null,
    border: null,
    chain: null,
    bad: null,
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');

    const {
      alert,
      timeline,
      domains,
      hashes,
      border,
      title,
      chain,
      bad,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      // Step 2
      () => {
        this.autoNext(4000);
      },
      // Step 3
      () => {
        this.autoNext(500);
      },
      // Step 4
      () => {
        this.autoNext(500);
      },
      // Step 5
      () => {
        this.autoNext(500);
      },
      // Step 6
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: title,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: 1,
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: deactivate,
        },
        {
          targets: timeline,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: chain,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activateShimmer,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: domains,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 5
      //
      [
        {
          targets: hashes,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 6
      //
      [
        { targets: chain, easing: 'easeOutQuad', opacity: 0.2, duration: 500 },
        {
          targets: bad,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  componentWillUnmount() {
    AppActions.showRightArrow();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-22 ${className}`}>
        {children}
        <div className="content">
          {/* Title */}
          <div
            className="player title"
            data-active="0"
            ref={node => (this.players.title = node)}
          >
            <h1>eSentire Investigation Analysis</h1>
          </div>

          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'Message from eSentire'}
              subtitle={'Investigation Analysis'}
              color={'#50C2E3'}
            />
          </div>

          {/* Process Chain */}
          <div
            className="player timeline"
            data-active="0"
            ref={node => (this.players.timeline = node)}
          >
            <PlayerTextBlock title={'Process Chain'} width={'300px'} />
          </div>

          {/* File Modifications */}
          <div
            className="player domains"
            data-active="0"
            ref={node => (this.players.domains = node)}
          >
            <PlayerTextBlock
              title={'FILE MODIFICATIONS'}
              width={'250px'}
              sections={[
                {
                  lines: 3,
                  lineColumns: 2,
                },
                {
                  lines: 3,
                },
              ]}
            />
          </div>

          {/* Endpoint Modifications */}
          <div
            className="player hashes"
            data-active="0"
            ref={node => (this.players.hashes = node)}
          >
            <PlayerTextBlock
              title={'ENDPOINT MODIFICATIONS'}
              width={'250px'}
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Process chain files */}
          <div
            className="player chain"
            data-active="0"
            ref={node => (this.players.chain = node)}
          >
            <PlayerCircleIcon
              color={'#ffffff'}
              showTitle={true}
              Icon={Document}
              IconSize={20}
              color={'#50C2E3'}
              title={'A.EXE'}
            />
            <PlayerCircleIcon
              color={'#ffffff'}
              showTitle={true}
              Icon={Document}
              IconSize={20}
              color={'#50C2E3'}
              title={'COMMAND.EXE'}
            />
            <PlayerCircleIcon
              color={'#ffffff'}
              showTitle={true}
              Icon={Document}
              IconSize={20}
              color={'#50C2E3'}
              title={'NOTEPAD.EXE'}
            />
            <PlayerCircleIcon
              color={'#ffffff'}
              showTitle={true}
              Icon={Document}
              IconSize={20}
              color={'#50C2E3'}
              title={'DHCPSRV.EXE'}
            />
            <PlayerCircleIcon
              color={'#ffffff'}
              showTitle={true}
              Icon={Document}
              IconSize={20}
              color={'#50C2E3'}
              title={'MANAGE.EXE'}
            />
            <PlayerCircleIcon
              color={'#ffffff'}
              showTitle={true}
              Icon={Document}
              IconSize={20}
              color={'#50C2E3'}
              title={'NEXUS.EXE'}
            />
            <div className="chain--lines">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>

          {/* Bad chain file */}
          <div
            className="player bad"
            data-active="0"
            ref={node => (this.players.bad = node)}
          >
            <PlayerCircleIcon
              color={'#C53D43'}
              showTitle={true}
              Icon={Document}
              IconSize={20}
              title={'A.EXE'}
            />
          </div>
        </div>
      </div>
    );
  }
}
