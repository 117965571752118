/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Info extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 19,
      x = 0,
      y = 0,
      opacity = 0.4,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g opacity={opacity}>
          <circle cx="9.5" cy="9.5" r="9" stroke={color} />
          <path
            d="M8.604 11.084H10.416C10.416 9.812 12.432 9.596 12.432 7.676C12.432 6.26 11.184 5.456 9.384 5.456C7.764 5.456 6.636 6.08 5.988 7.064L7.44 7.916C7.824 7.364 8.412 7.028 9.18 7.028C9.96 7.028 10.476 7.4 10.476 7.988C10.476 9.032 8.604 9.404 8.604 11.084ZM9.516 14.096C10.188 14.096 10.668 13.604 10.668 13.004C10.668 12.392 10.188 11.936 9.516 11.936C8.844 11.936 8.364 12.392 8.364 13.004C8.364 13.604 8.844 14.096 9.516 14.096Z"
            fill={color}
          />
        </g>
      </svg>
    );
  }
}
