/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Lock extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 52,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 36 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillOpacity={opacity}
          fillRule="evenodd"
          fill={color}
          clipRule="evenodd"
          d="M8 12C8 6.486 12.486 2 18 2C23.514 2 28 6.486 28 12V18H8V12ZM16 30C16 28.897 16.897 28 18 28C19.103 28 20 28.897 20 30C20 30.736 19.595 31.375 19 31.722V37C19 37.552 18.552 38 18 38C17.448 38 17 37.552 17 37V31.722C16.405 31.375 16 30.736 16 30ZM0 48H36V18H30V12C30 5.383 24.617 0 18 0C11.383 0 6 5.383 6 12V18H0V48Z"
        />
      </svg>
    );
  }
}
