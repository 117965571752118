/**
 * Sets data-active: true on all the targets of an animation
 *
 * @param {AnimeJS.Animation} animation
 */
export function activate(animation) {
  animation.animatables.forEach(animatable =>
    animatable.target.setAttribute('data-active', 1)
  );
}

export function activateShimmer(animation) {
  animation.animatables.forEach(animatable =>
    animatable.target.setAttribute('data-active', 1)
  );
  shimmer(animation);
}

/**
 * Sets data-active: false on all the targets of an animation
 *
 * @param {AnimeJS.Animation} animation
 */
export function deactivate(animation) {
  animation.animatables.forEach(animatable =>
    animatable.target.setAttribute('data-active', 0)
  );
}

/**
 * Designed for use with anime.complete or anime.begin
 *
 * @param {AnimeJS.Animation} animation
 */
export function shimmer(animation) {
  animation.animatables.forEach(animatable => {
    animatable.target.classList.add('shimmer');
    animatable.target.dataset.removeShimmer = setTimeout(() => {
      animatable.target.classList.remove('shimmer');
    }, 500);
  });
}

/**
 * Convert hex color into rgb color
 *
 * @param {String} color
 */
function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

/**
 * Provide quick access to colors system wide
 *
 * @param {String} color
 * @param {Boolean} rgb
 */
export function getColor(color, rgb = false) {
  color = color.toLowerCase();
  let theColor = '';
  let c = {
    blue: '#50C2E3',
    red: '#C53D43',
    green: '#54D5AE',
    white: '#ffffff',
    orange: '#F36C21',
    yellow: '#ffe600',
    mellowyellow: '#FFDF6B',
    purple: '#263C91',
    deepblue: '#2D87DB',
  };
  theColor = color in c ? c[color] : color;
  if (rgb) {
    let rgb = hexToRgb(theColor);
    return rgb.r + ',' + rgb.g + ',' + rgb.b;
  }
  return theColor;
}

/**
 * Provide quick access to colors system wide
 *
 * @param {String} color
 * @param {Float} alpha
 */
export function rgba(color, alpha = 1) {
  return `rgba(${getColor(color, true)}, ${alpha})`;
}

/**
 * Provide quick access to colors system wide
 *
 * @param {Number} idStrLen
 */
export function uid(idStrLen = 10) {
  // always start with a letter -- base 36 makes for a nice shortcut
  let idStr = (Math.floor(Math.random() * 25) + 10).toString(36) + '_';
  // add a timestamp in milliseconds (base 36 again) as the base
  idStr += new Date().getTime().toString(36) + '_';
  // similar to above, complete the Id using random, alphanumeric characters
  do {
    idStr += Math.floor(Math.random() * 35).toString(36);
  } while (idStr.length < idStrLen);

  return idStr;
}
