/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class SignalNetwork extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 2,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity={opacity}
        >
          <path d="M5 19.9131H55.4348M12.5666 8.56516V13.6086M12.5666 27.4783V32.5218M12.5666 46.3914V51.4349M5 40.0869H55.4348M8.25386 1H52.1809C53.978 1 55.4348 2.4568 55.4348 4.25386V55.7461C55.4348 57.5432 53.978 59 52.1809 59H8.25386C6.4568 59 5 57.5432 5 55.7461V4.25386C5 2.4568 6.4568 1 8.25386 1Z" />
        </g>
      </svg>
    );
  }
}
