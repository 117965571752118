/** @jsx createElement */
import { createElement, Component } from 'react';
import { KILLCHAIN_PRODUCTS } from '../killchain-actions';
import { Close } from '../../svg/close';
import { Check } from '../../svg/check';
import './services-menu.scss';

const isSelected = (item, selected) => {
  return (
    item && selected && selected.find(selected => selected.value === item.value)
  );
};

export class ServicesMenu extends Component {
  render() {
    const {
      selectedProducts,
      onSelectNone,
      onSelectAll,
      updateSelectedProducts,
      onClose,
    } = this.props;

    return (
      <div className="ServicesMenu FadeIn">
        <div className="ServicesMenu__Services">
          Services
          <div className="ServicesMenu__ServicesMarker" />
        </div>
        <div className="ServicesMenu__Close" onClick={onClose}>
          <Close bold size={20} />
        </div>
        <div className="ServicesMenu__Buttons">
          {KILLCHAIN_PRODUCTS.map(product => (
            <div
              key={product.value}
              className={`ServicesMenu__Button ${
                isSelected(product, selectedProducts)
                  ? 'ServicesMenu__Button--selected'
                  : ''
              }`}
              onClick={() => updateSelectedProducts(product)}
            >
              {isSelected(product, selectedProducts) ? (
                <Check size={20} />
              ) : (
                <Close bold size={20} color="#f05938" />
              )}{' '}
              {product.label}
            </div>
          ))}
        </div>
        <div className="ServicesMenu__Bulk">
          <div
            className="ServicesMenu__BulkButton ServicesMenu__BulkButton--all"
            onClick={onSelectAll}
          >
            Select All
          </div>
          <div
            className="ServicesMenu__BulkButton ServicesMenu__BulkButton--none"
            onClick={onSelectNone}
          >
            Select None
          </div>
        </div>
      </div>
    );
  }
}
