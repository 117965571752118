/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Signal extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 46,
      x = 0,
      y = 0,
      opacity = 0.3,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd" clipRule="evenodd" opacity={opacity}>
          <path d="M7.78443 33.5736C7.05974 34.2872 7.06159 35.4408 7.78259 36.1544C8.50543 36.8681 9.67566 36.8681 10.3967 36.1544C15.7764 30.8485 22.9235 27.9263 30.5198 27.9263C38.1179 27.9263 45.2632 30.8485 50.6374 36.1544C51.3602 36.8681 52.5286 36.8681 53.2514 36.1544C53.6119 35.7985 53.7931 35.3312 53.7931 34.864C53.7931 34.3967 53.6119 33.9295 53.2514 33.5735C47.1784 27.5777 39.107 24.2759 30.5198 24.2759C21.9362 24.2759 13.863 27.5759 7.78443 33.5736Z" />
          <path d="M0.54453 25.2132C-0.18151 25.9462 -0.18151 27.1311 0.54453 27.8641C1.27057 28.5971 2.44412 28.5971 3.17016 27.8641C10.3359 20.6296 19.8654 16.6459 30.0021 16.6459C40.1369 16.6459 49.6646 20.6296 56.8303 27.864C57.5563 28.597 58.7299 28.597 59.4559 27.864C59.818 27.4985 60 27.0186 60 26.5386C60 26.0587 59.818 25.5788 59.4559 25.2132C51.5902 17.2702 41.1285 12.8966 30.0021 12.8966C18.8738 12.8966 8.41028 17.2702 0.54453 25.2132Z" />
          <path d="M13.9854 42.6815C13.2683 43.4414 13.2702 44.6698 13.9854 45.4298C14.7024 46.1898 15.8633 46.1898 16.5803 45.4298C20.1656 41.6338 24.9319 39.5425 30.0026 39.5425C35.0697 39.5425 39.836 41.6338 43.4231 45.4317C44.1401 46.1897 45.301 46.1898 46.0162 45.4298C46.3738 45.0508 46.5517 44.5532 46.5517 44.0556C46.5517 43.558 46.372 43.0585 46.0144 42.6795C41.7359 38.1508 36.0472 35.6552 30.0026 35.6552C23.9508 35.6532 18.2639 38.1508 13.9854 42.6815Z" />
        </g>
      </svg>
    );
  }
}
