/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Template__TeamSendsAdvisory } from '../../templates/team-sends-advisory';

@observer
export class Slide07 extends Component {
  componentDidMount() {
    // Do something
  }

  render() {
    const { children } = this.props;
    return (
      <div className={`slide-07`}>
        {children}
        <Template__TeamSendsAdvisory
          title={{
            text: 'SQUIRRELWAFFLE MALWARE',
          }}
        />
      </div>
    );
  }
}
