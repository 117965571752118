/** @jsx createElement */
// SVG
import { World } from '../svg/world';
import { USAScanner } from '../svg/usa-scanner';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerChart } from '../players/chart';
import { observable } from 'mobx';
import ReactDOM from 'react-dom';

@observer
export class Slide07 extends AnimatedSlide {
  // Animation players
  players = {
    chart: null,
  };

  // Run the first animation
  componentDidMount() {
    const { chart } = this.players;

    this.steps = [
      () => {
        this.animateProgress(4000);
      },
      () => {
        // Nothing yet
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [],
      //
      // Step 2
      //
      [],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-07 ${className}`}>
        {children}
        <div className="content">
          {/*-------PLAYERS START-------*/}
          {/* Chart */}
          <div
            className="player chart"
            data-active="1"
            ref={node => (this.players.chart = node)}
          >
            <PlayerChart />
          </div>

          {/*-------PLAYERS END-------*/}
        </div>
      </div>
    );
  }
}
