/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class SignalLog extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 2,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity={opacity}
        >
          <path d="M51.8699 16.5042V50.6405C51.8699 50.8511 51.7862 51.053 51.6373 51.2019C51.4885 51.3508 51.2865 51.4344 51.076 51.4344H17.3604C17.1499 51.4344 16.948 51.3508 16.7991 51.2019C16.6502 51.053 16.5666 50.8511 16.5666 50.6405V1.79387C16.5666 1.58332 16.6502 1.3814 16.7991 1.23252C16.948 1.08364 17.1499 1 17.3604 1H36.183M44.2715 56.4755V58.2061C44.2715 58.4167 44.1879 58.6186 44.039 58.7675C43.8901 58.9164 43.6882 59 43.4777 59H9.79387C9.58332 59 9.3814 58.9164 9.23252 58.7675C9.08364 58.6186 9 58.4167 9 58.2061V9.35945C9 9.1489 9.08364 8.94698 9.23252 8.7981C9.3814 8.64922 9.58332 8.56558 9.79387 8.56558H11.4848M44.3054 37.5657V42.6067M51.87 16.1311H36.7389V1L51.87 16.1311Z" />
        </g>
      </svg>
    );
  }
}
