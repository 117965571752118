/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class SkullScore extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#F36C21',
      base = '#ffffff',
      size = 90,
      x = 0,
      y = 0,
      score = 0,
      opacity = 1,
    } = this.props;

    let baseWidth = 90;
    let baseHeight = 18;
    let height = (size / baseWidth) * baseHeight;

    return (
      <svg
        {...{ x, y }}
        width={size}
        height={height}
        className="Icon__SkullScore"
        viewBox={`0 0 90 18`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          fillOpacity={opacity}
        >
          <path
            fill={score >= 1 ? color : base}
            fillOpacity={opacity}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9929 8.27246C13.9976 8.18213 14 8.09131 14 8C14 5.23877 11.7615 3 9 3C6.23853 3 4 5.23877 4 8C4 8.16895 4.0083 8.33545 4.02466 8.5H4C4 8.5 4 11 4.5 11.5C5 12 6.5 12.5 6.5 13C6.5 13.5 6.5 14.5 7.5 15C8 14.5 8 14 8 14C8 14 8.5 15 9 15C9.5 15 10 14 10 14C10 14 10 14.5 10.5 15C11.5 14.5 11.5 13.5 11.5 13C11.5 12.7002 12.04 12.4004 12.5801 12.1006C12.9399 11.9004 13.3 11.7002 13.5 11.5C14 11 14 8.5 14 8.5H13.9753C13.9829 8.4248 13.9888 8.34912 13.9929 8.27246ZM8.5 10C8.5 9.5 6.5 9 6 9C5.5 9 5 9.5 5 10C5 10.5 5.5 11.5 6.5 11.5C7.5 11.5 8.5 10.5 8.5 10ZM12 9C11.5 9 9.5 9.5 9.5 10C9.5 10.5 10.5 11.5 11.5 11.5C12.5 11.5 13 10.5 13 10C13 9.5 12.5 9 12 9ZM10 12.5C10 12 9.5 11 9 11C8.5 11 8 12 8 12.5C8 13 10 13 10 12.5Z"
          />
          <path
            fill={score >= 2 ? color : base}
            fillOpacity={opacity}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.9929 8.27246C31.9976 8.18213 32 8.09131 32 8C32 5.23877 29.7615 3 27 3C24.2385 3 22 5.23877 22 8C22 8.16895 22.0083 8.33545 22.0247 8.5H22C22 8.5 22 11 22.5 11.5C23 12 24.5 12.5 24.5 13C24.5 13.5 24.5 14.5 25.5 15C26 14.5 26 14 26 14C26 14 26.5 15 27 15C27.5 15 28 14 28 14C28 14 28 14.5 28.5 15C29.5 14.5 29.5 13.5 29.5 13C29.5 12.7002 30.04 12.4004 30.5801 12.1006C30.9399 11.9004 31.3 11.7002 31.5 11.5C32 11 32 8.5 32 8.5H31.9753C31.9829 8.4248 31.9888 8.34912 31.9929 8.27246ZM26.5 10C26.5 9.5 24.5 9 24 9C23.5 9 23 9.5 23 10C23 10.5 23.5 11.5 24.5 11.5C25.5 11.5 26.5 10.5 26.5 10ZM30 9C29.5 9 27.5 9.5 27.5 10C27.5 10.5 28.5 11.5 29.5 11.5C30.5 11.5 31 10.5 31 10C31 9.5 30.5 9 30 9ZM28 12.5C28 12 27.5 11 27 11C26.5 11 26 12 26 12.5C26 13 28 13 28 12.5Z"
          />
          <path
            fill={score >= 3 ? color : base}
            fillOpacity={opacity}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.9929 8.27246C49.9976 8.18213 50 8.09131 50 8C50 5.23877 47.7615 3 45 3C42.2385 3 40 5.23877 40 8C40 8.16895 40.0083 8.33545 40.0247 8.5H40C40 8.5 40 11 40.5 11.5C41 12 42.5 12.5 42.5 13C42.5 13.5 42.5 14.5 43.5 15C44 14.5 44 14 44 14C44 14 44.5 15 45 15C45.5 15 46 14 46 14C46 14 46 14.5 46.5 15C47.5 14.5 47.5 13.5 47.5 13C47.5 12.7002 48.04 12.4004 48.5801 12.1006C48.9399 11.9004 49.3 11.7002 49.5 11.5C50 11 50 8.5 50 8.5H49.9753C49.9829 8.4248 49.9888 8.34912 49.9929 8.27246ZM44.5 10C44.5 9.5 42.5 9 42 9C41.5 9 41 9.5 41 10C41 10.5 41.5 11.5 42.5 11.5C43.5 11.5 44.5 10.5 44.5 10ZM48 9C47.5 9 45.5 9.5 45.5 10C45.5 10.5 46.5 11.5 47.5 11.5C48.5 11.5 49 10.5 49 10C49 9.5 48.5 9 48 9ZM46 12.5C46 12 45.5 11 45 11C44.5 11 44 12 44 12.5C44 13 46 13 46 12.5Z"
          />
          <path
            fill={score >= 4 ? color : base}
            fillOpacity={opacity}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.9929 8.27246C67.9976 8.18213 68 8.09131 68 8C68 5.23877 65.7615 3 63 3C60.2385 3 58 5.23877 58 8C58 8.16895 58.0083 8.33545 58.0247 8.5H58C58 8.5 58 11 58.5 11.5C59 12 60.5 12.5 60.5 13C60.5 13.5 60.5 14.5 61.5 15C62 14.5 62 14 62 14C62 14 62.5 15 63 15C63.5 15 64 14 64 14C64 14 64 14.5 64.5 15C65.5 14.5 65.5 13.5 65.5 13C65.5 12.7002 66.04 12.4004 66.5801 12.1006C66.9399 11.9004 67.3 11.7002 67.5 11.5C68 11 68 8.5 68 8.5H67.9753C67.9829 8.4248 67.9888 8.34912 67.9929 8.27246ZM62.5 10C62.5 9.5 60.5 9 60 9C59.5 9 59 9.5 59 10C59 10.5 59.5 11.5 60.5 11.5C61.5 11.5 62.5 10.5 62.5 10ZM66 9C65.5 9 63.5 9.5 63.5 10C63.5 10.5 64.5 11.5 65.5 11.5C66.5 11.5 67 10.5 67 10C67 9.5 66.5 9 66 9ZM64 12.5C64 12 63.5 11 63 11C62.5 11 62 12 62 12.5C62 13 64 13 64 12.5Z"
          />
          <path
            fill={score >= 5 ? color : base}
            fillOpacity={opacity}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M85.9929 8.27246C85.9976 8.18213 86 8.09131 86 8C86 5.23877 83.7615 3 81 3C78.2385 3 76 5.23877 76 8C76 8.16895 76.0083 8.33545 76.0247 8.5H76C76 8.5 76 11 76.5 11.5C77 12 78.5 12.5 78.5 13C78.5 13.5 78.5 14.5 79.5 15C80 14.5 80 14 80 14C80 14 80.5 15 81 15C81.5 15 82 14 82 14C82 14 82 14.5 82.5 15C83.5 14.5 83.5 13.5 83.5 13C83.5 12.7002 84.04 12.4004 84.5801 12.1006C84.9399 11.9004 85.3 11.7002 85.5 11.5C86 11 86 8.5 86 8.5H85.9753C85.9829 8.4248 85.9888 8.34912 85.9929 8.27246ZM80.5 10C80.5 9.5 78.5 9 78 9C77.5 9 77 9.5 77 10C77 10.5 77.5 11.5 78.5 11.5C79.5 11.5 80.5 10.5 80.5 10ZM84 9C83.5 9 81.5 9.5 81.5 10C81.5 10.5 82.5 11.5 83.5 11.5C84.5 11.5 85 10.5 85 10C85 9.5 84.5 9 84 9ZM82 12.5C82 12 81.5 11 81 11C80.5 11 80 12 80 12.5C80 13 82 13 82 12.5Z"
          />
        </g>
      </svg>
    );
  }
}
