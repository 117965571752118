/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Laptop extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 70,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 65 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.83332 32.2906C10.5406 32.2906 11.2188 32.0097 11.7189 31.5096C12.219 31.0095 12.5 30.3312 12.5 29.6239V7.44794C12.5 6.97621 12.6874 6.5238 13.0209 6.19023C13.3545 5.85667 13.8069 5.66927 14.2787 5.66927H50.7213C51.1931 5.66927 51.6455 5.85667 51.979 6.19023C52.3126 6.5238 52.5 6.97621 52.5 7.44794V29.6239C52.5 30.3312 52.7809 31.0095 53.281 31.5096C53.7811 32.0097 54.4594 32.2906 55.1667 32.2906C55.8739 32.2906 56.5522 32.0097 57.0523 31.5096C57.5524 31.0095 57.8333 30.3312 57.8333 29.6239V7.44794C57.8319 5.56215 57.0822 3.75401 55.7487 2.42055C54.4153 1.0871 52.6071 0.33735 50.7213 0.335938H14.2787C12.3929 0.33735 10.5847 1.0871 9.25127 2.42055C7.91782 3.75401 7.16807 5.56215 7.16666 7.44794V29.6239C7.16666 30.3312 7.44761 31.0095 7.9477 31.5096C8.4478 32.0097 9.12608 32.2906 9.83332 32.2906Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M64.2173 36.8479C64.0926 36.6884 63.9331 36.5594 63.7511 36.4707C63.569 36.382 63.3692 36.3359 63.1667 36.3359H41.8333C41.4797 36.3359 41.1406 36.4764 40.8905 36.7265C40.6405 36.9765 40.5 37.3156 40.5 37.6693C40.5 39.5626 37.2147 41.6693 32.5 41.6693C27.7853 41.6693 24.5 39.5626 24.5 37.6693C24.5 37.3156 24.3595 36.9765 24.1095 36.7265C23.8594 36.4764 23.5203 36.3359 23.1667 36.3359H1.83333C1.6308 36.336 1.43095 36.3822 1.24893 36.471C1.06692 36.5598 0.907534 36.689 0.782872 36.8486C0.658211 37.0082 0.57155 37.1941 0.529467 37.3922C0.487384 37.5903 0.490986 37.7954 0.539998 37.9919C1.37036 41.329 3.29411 44.2919 6.00451 46.4083C8.7149 48.5248 12.0559 49.6727 15.4947 49.6693H49.5053C52.9441 49.6727 56.2851 48.5248 58.9955 46.4083C61.7059 44.2919 63.6296 41.329 64.46 37.9919C64.5091 37.7953 64.5128 37.5901 64.4708 37.3919C64.4287 37.1937 64.3421 37.0077 64.2173 36.8479Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
