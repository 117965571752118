/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Sun extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 13,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd" clipRule="evenodd">
          <path d="M26.3765 16C26.3765 21.4547 21.9547 25.8765 16.5 25.8765C11.0453 25.8765 6.62346 21.4547 6.62346 16C6.62346 10.5453 11.0453 6.12346 16.5 6.12346C21.9547 6.12346 26.3765 10.5453 26.3765 16Z" />
          <path d="M16.5 0L20.5201 7.03704H12.4799L16.5 0Z" />
          <path d="M16.5 32L20.5201 24.963H12.4799L16.5 32Z" />
          <path d="M0.5 16L7.53704 11.9799V20.0201L0.5 16Z" />
          <path d="M32.5 16L25.463 11.9799V20.0201L32.5 16Z" />
          <path d="M5.18629 27.3137L7.31961 19.4951L13.0048 25.1804L5.18629 27.3137Z" />
          <path d="M27.8137 4.68628L19.9952 6.8196L25.6804 12.5048L27.8137 4.68628Z" />
          <path d="M5.18629 4.68628L13.0048 6.8196L7.31961 12.5048L5.18629 4.68628Z" />
          <path d="M27.8137 27.3137L25.6804 19.4951L19.9952 25.1804L27.8137 27.3137Z" />
        </g>
      </svg>
    );
  }
}
