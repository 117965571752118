/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { StoryBlock } from '../story-block';
import { PlayerDemoGoals } from '../players/demo-goals';

// Players
import { PlayerPath } from '../players/path';

// SVG
import { CheckCircle } from '../svg/check-circle';
import { Bell } from '../svg/bell';
import { CheckShield } from '../svg/check-shield';
import { Eye } from '../svg/eye';
import { Target } from '../svg/target';
import { Loading } from '../svg/loading';
import { Email } from '../svg/email';

@observer
export class Slide01 extends AnimatedSlide {
  // Animation players
  players = {
    card1: null,
    card2: null,
    card3: null,
    card4: null,
    card5: null,
    card6: null,
  };

  // Run the first animation
  componentDidMount() {
    const { card1, card2, card3, card4, card5, card6, lines } = this.players;

    this.steps = [
      () => {
        this.animateProgress(3000);
        this.autoNext(200);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {},
    ];

    // transform
    const growAnimation = [1.1, 1];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        {
          targets: card1,
          opacity: 1,
          scale: growAnimation,
          easing: 'easeOutQuad',
          delay: 0,
          duration: 200,
          begin: activateShimmer,
          complete: activateShimmer,
        },
        {
          targets: card2,
          opacity: 1,
          scale: growAnimation,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 200,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: card3,
          opacity: 1,
          scale: growAnimation,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 200,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: lines,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1200,
          duration: 300,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: card4,
          opacity: 1,
          scale: growAnimation,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 200,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: card5,
          opacity: 1,
          scale: growAnimation,
          easing: 'easeOutQuad',
          delay: 2000,
          duration: 200,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: card6,
          opacity: 1,
          scale: growAnimation,
          easing: 'easeOutQuad',
          delay: 2500,
          duration: 200,
          begin: activate,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-01 ${className}`}>
        {children}
        <div className="content">
          {/* Story block 1 */}
          <div
            className="player block1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <StoryBlock
              IconComponent={Bell}
              title={'TRU team releases security advisory'}
              color={this.colors.red}
              description={
                "In November 2021, eSentire's Threat Response Unit (TRU) released a security advisory on eSentire.com on the evolution of the SQUIRRELWAFFLE malware. SQUIRRELWAFFLE has been used similar to Emotet, being leveraged to gain a foothold into a target's environments, and then to deploy additional malware such as Qakbot and Cobalt Strike."
              }
            />
          </div>

          {/* Story block 2 */}
          <div
            className="player block2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <StoryBlock
              IconComponent={Target}
              title={'TRU team develops hypothesis-driven threat hunt'}
              color={this.colors.blue}
              description={
                "As a result of original threat research conducted by eSentire TRU, the team develops a hypothesis-driven threat hunt ingesting signal data from across eSentire's customer base to proactively detect an Indicator of Compromise (IoC) for SQUIRRELWAFFLE malware."
              }
            />
          </div>

          {/* Story block 3 */}
          <div
            className="player block3"
            data-active="0"
            ref={node => (this.players.card3 = node)}
          >
            <StoryBlock
              IconComponent={Loading}
              title={'Proactive hunt for SQUIRRELWAFFLE'}
              color={this.colors.blue}
              description={
                "On November 1st, 2021, TRU's global threat hunting tool was engaged in the proactive threat hunt for SQUIRRELWAFFLE across our client base and received a positive hit for a threat in a client's environment."
              }
            />
          </div>

          {/* Lines */}
          <div
            className="player lines"
            data-active="0"
            ref={node => (this.players.lines = node)}
          >
            <PlayerPath points={['0,400', '35,400', '35,3', '70,3']} />
          </div>

          {/* Story block 4 */}
          <div
            className="player block4"
            data-active="0"
            ref={node => (this.players.card4 = node)}
          >
            <StoryBlock
              IconComponent={Eye}
              title={'SQUIRRELWAFFLE detected'}
              color={this.colors.blue}
              description={
                'This multi-signal detection was driven by MDR for Network and for Endpoint. This resulted in a discovery of a SQUIRRELWAFFLE infection impacting client endpoints that went undetected by their endpoint technology.'
              }
            />
          </div>

          {/* Story block 5 */}
          <div
            className="player block5"
            data-active="0"
            ref={node => (this.players.card5 = node)}
          >
            <StoryBlock
              IconComponent={CheckShield}
              title={"SOC analysts rapidly respond on the customer's behalf"}
              color={this.colors.blue}
              description={
                "eSentire SOC cyber analysts investigated and responded rapidly on the customer's behalf to the infected hosts where SQUIRRELWAFFLE payloads were identified. The team worked with the client to isolate and contain the hosts where lateral movement occurred, returning them to service once the threat was remediated."
              }
            />
          </div>

          {/* Story block 6 */}
          <div
            className="player block6"
            data-active="0"
            ref={node => (this.players.card6 = node)}
          >
            <StoryBlock
              IconComponent={Email}
              title={'FBI informs customer 4 days later'}
              color={this.colors.blue}
              description={
                '4 days later the FBI and a competitor informed our customer of a possible compromise across their network. After a thorough retroactive threat hunt, it was determined that the threat was the SQUIRRELWAFFLE infection that eSentire had caught and was remediated 4 days earlier.'
              }
            />
          </div>
        </div>
      </div>
    );
  }
}
