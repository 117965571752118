/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import { Router } from 'vega-router';
import {
  SOCCalculatorActions,
  SOCCalculatorState,
} from '../soc-calculator-actions';
import './left-nav.scss';

@observer
export class SOCCalculatorLeftNav extends Router {
  changeInput = (type, asNumber) => value => {
    value = value.currentTarget.value;

    if (asNumber) {
      if (!value) value = '0';
      const check = Number.parseFloat(value);
      if (isNaN(check)) return;
      value = check;
    }

    SOCCalculatorActions.updateField(type, value);
  };

  render() {
    const { className = '' } = this.props;

    const environment = [
      {
        field: 'preventionEndpoints',
        label: 'Endpoints (EPP)',
      },
      {
        field: 'edrEndpoints',
        label: 'Endpoints (EDR)',
      },
      {
        field: 'sensors',
        label: 'Sensors (Locations)',
      },
      {
        field: 'dailyGbIngest',
        label: 'Daily GB Ingest',
      },
      {
        field: 'ipAddresses',
        label: 'IP Addresses',
      },
      {
        field: 'feeds',
        label: 'Threat Intel Feeds',
      },
    ];

    const team = [
      {
        field: 'analysts',
        label: 'Core Analysts (24x7)',
      },
      {
        field: 'secOpsManagers',
        label: 'Sec Ops Managers',
      },
      {
        field: 'intelligenceAnalysts',
        label: 'Intelligence Analysts',
      },
      {
        field: 'intelligenceManagers',
        label: 'Intelligence Managers',
      },
      {
        field: 'engineers',
        label: 'Network Security Engineers',
      },
      {
        field: 'admins',
        label: 'Network Security Admins',
      },
    ];

    const implementation = [
      {
        field: 'implementationHours',
        label: 'Implementation Hours',
      },
      {
        field: 'implementationCostPerHour',
        label: 'Cost Per Hour',
      },
    ];

    return (
      <div className={`SOCCalculatorLeftNav ${className}`}>
        <div className="SOCCalculatorLeftNav__Header">Your Environment</div>
        {environment.map((item, i) => {
          return (
            <SOCCalculatorLeftNavItem
              key={i}
              className="SOCCalculatorLeftNav__Item"
              field={item.field}
              value={SOCCalculatorState[item.field].toString()}
              onChange={this.changeInput(item.field, true)}
              label={item.label}
            />
          );
        })}
        <div className="SOCCalculatorLeftNav__Header">Your Team</div>
        {team.map((item, i) => {
          return (
            <SOCCalculatorLeftNavItem
              key={i}
              className="SOCCalculatorLeftNav__Item"
              field={item.field}
              value={SOCCalculatorState[item.field].toString()}
              onChange={this.changeInput(item.field, true)}
              label={item.label}
            />
          );
        })}
        <div className="SOCCalculatorLeftNav__Header">Implementation</div>
        {implementation.map((item, i) => {
          return (
            <SOCCalculatorLeftNavItem
              key={i}
              className="SOCCalculatorLeftNav__Item"
              field={item.field}
              value={SOCCalculatorState[item.field].toString()}
              onChange={this.changeInput(item.field, true)}
              label={item.label}
            />
          );
        })}
      </div>
    );
  }
}

class SOCCalculatorLeftNavItem extends Component {
  render() {
    const {
      className = '',
      field = '',
      label = '',
      value = '',
      onChange,
    } = this.props;

    return (
      <div
        className={`SOCCalculatorLeftNavItem ${className}`}
        tabIndex={-1}
        onFocus={() => {
          this.refs.input.focus();
        }}
      >
        <input
          ref="input"
          name={field}
          type="number"
          value={value}
          onChange={onChange}
        />
        <div className="SOCCalculatorLeftNavItem__Label">{label}</div>
      </div>
    );
  }
}
