/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Check extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#ffffff',
      size = 100,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 15 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 8.97656L13.8281 0.148438L15 1.32031L5 11.3203L0.351562 6.67188L1.52344 5.5L5 8.97656Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
