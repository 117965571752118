/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';
import anime from 'animejs';

// Players
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Alert } from '../svg/alert';
import { Warning } from '../svg/warning';

@observer
export class Slide06 extends AnimatedSlide {
  // Animation players
  players = {
    team: null,
    info1: null,
    info2: null,
    info3: null,
    dots1: null,
    dots2: null,
    alert: null,
    border: null,
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');
    const {
      team,
      info1,
      info2,
      info3,
      dots1,
      dots2,
      alert,
      border,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(10000);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(8000);
      },
      // Step 2
      () => {
        this.autoNext(500);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: team,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: dots1,
          width: [0, 100],
          delay: 500,
          duration: 500,
        },
        {
          targets: dots2,
          width: [0, 120],
          delay: 1000,
          duration: 500,
        },
        {
          targets: info1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: info2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: info3,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 3000,
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2
      //
      [
        {
          targets: team,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
          begin: shimmer,
          complete: deactivate,
        },
        {
          targets: info1,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
          begin: shimmer,
          complete: deactivate,
        },
        {
          targets: info2,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
          begin: shimmer,
          complete: deactivate,
        },
        {
          targets: info3,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
          begin: shimmer,
          complete: deactivate,
        },
        {
          targets: alert,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-06 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TRU Team */}
          <div
            className="player team"
            data-active="0"
            ref={node => (this.players.team = node)}
          >
            <Card IconComponent={Person} text="TRU TEAM" />
            <div
              ref={node => (this.players.dots1 = node)}
              className={'animated-dotted-line reverse ants-1'}
            ></div>
            <div
              ref={node => (this.players.dots2 = node)}
              className={'animated-dotted-line reverse ants-2'}
            ></div>
          </div>
          {/* Info1 */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerTextBlock
              title={'TRIAGE ANALYSIS'}
              width={340}
              className={''}
              compact={true}
              sections={[
                {
                  title: ['REDDIT'],
                  animate: true,
                  delay: 0,
                },
                {
                  title: ['epp confirmed to block this activity'],
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['last activity', 'jul 02, 2021 @ 4:34:28 pm gmt'],
                  grid: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['process', 'cert.exe'],
                  animate: true,
                  delay: 400,
                },
              ]}
            />
          </div>
          {/* Info2 */}
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerTextBlock
              title={'PROCESS TREE'}
              width={340}
              compact={true}
              className={''}
              sections={[
                {
                  title: ['agentmon.exe'],
                  link: true,
                  animate: true,
                  delay: 0,
                },
                {
                  title: ['cmd.exe'],
                  link: true,
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['cmd.exe'],
                  link: true,
                  animate: true,
                  delay: 200,
                },
              ]}
            />
          </div>
          {/* Info3 */}
          <div
            className="player info3"
            data-active="0"
            ref={node => (this.players.info3 = node)}
          >
            <PlayerTextBlock
              title={'RECOMMENDATION'}
              width={320}
              compact={true}
              className={''}
              sections={[
                {
                  title: ['PROVIDE SECURITY ADVISORY TO ALL CLIENTS'],
                  color: 'blue',
                  colorCells: 2,
                },
              ]}
            />
          </div>
          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'recommendation'}
              subtitle={'Security Advisory'}
              line2={'to all clients'}
              IconComponent={Warning}
              color={this.colors.blue}
            />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
