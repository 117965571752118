/** @jsx createElement */
// SVG
import { Alert } from '../svg/alert';
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Email } from '../svg/email';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

@observer
export class Slide20 extends AnimatedSlide {
  // Animation players
  players = {
    client: null,
    email: null,
    alert: null,
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');

    const { client, email, alert, border } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(5000);
        this.autoNext(2000);
      },
      // Step 2
      () => {
        this.autoNext(2000);
      },
      // Step 3
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        { targets: client, easing: 'easeOutQuad', opacity: 1, duration: 500 },
        {
          targets: email,
          easing: 'easeOutQuad',
          opacity: 0,
          translateX: -400,
          duration: 500,
        },
      ],

      //
      // Step 2
      //
      [
        { targets: client, easing: 'easeOutQuad', opacity: 1, duration: 500 },
        {
          targets: email,
          easing: 'easeOutQuad',
          opacity: [0, 1, 1, 0],
          translateX: 100,
          duration: 2000,
          begin: activateShimmer,
        },
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 0,
          begin: deactivate,
        },
      ],

      //
      // Step 3
      //
      [
        { targets: email, easing: 'easeOutQuad', opacity: 0, duration: 500 },
        { targets: client, easing: 'easeOutQuad', opacity: 0.1, duration: 500 },
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-20 ${className}`}>
        {children}
        <div className="content">
          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'Alert from eSentire'}
              IconComponent={Email}
              subtitle={'Additional Investigation'}
              line2={'Ongoing'}
              color={'#54D5AE'}
            />
          </div>

          {/* Email */}
          <div
            className="player email"
            data-active="0"
            ref={node => (this.players.email = node)}
          >
            <Email size="40" color={'#ffffff'} />
          </div>

          {/* Client */}
          <div
            className="player client"
            data-active="1"
            ref={node => (this.players.client = node)}
          >
            <Card IconComponent={Person} text="CLIENT" />
          </div>
        </div>
      </div>
    );
  }
}
