/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import './timer.scss';
import anime from 'animejs';
import { observable } from 'mobx';

const { floor } = Math;

const TICK_COUNT = 25;
const SQUARE_SIZE = 40;
const TICK_WIDTH = SQUARE_SIZE / 8;
const TICK_HEIGHT = SQUARE_SIZE / 50;

@observer
export class Timer extends Component {
  @observable progress = 0;

  componentDidMount() {
    this.animate({});
  }

  componentDidUpdate(prevProps) {
    this.animate(prevProps);
  }

  animate(prevProps) {
    if (prevProps.progress !== this.props.progress) {
      anime({
        targets: this,
        progress: this.props.progress,
        easing: 'linear',
        duration: 500,
      });
    }

    if (prevProps.progress !== this.props.progress) {
      anime({
        targets: this,
        seconds: this.props.seconds,
        round: 1,
        easing: 'linear',
        duration: 500,
      });
    }
  }

  render() {
    const { children, className = '', seconds, progress } = this.props;

    if (seconds === undefined && progress === undefined) return null;

    return (
      <div className={`Timer ${className}`}>
        {this.renderProgress(progress)}
        {this.renderTime()}
        {children}
      </div>
    );
  }

  renderProgress(progress) {
    if (progress === undefined) return null;

    return (
      <div
        className="Progress"
        style={{ width: SQUARE_SIZE, height: SQUARE_SIZE }}
      >
        {new Array(TICK_COUNT).fill(0).map((_, i) => {
          const percent = i / TICK_COUNT;
          const filledClass = `Progress_Tick--${
            percent + 0.01 > this.progress ? 'un' : ''
          }filled`;

          return (
            <span
              key={i}
              className={`Progress_Tick ${filledClass}`}
              style={{
                position: 'absolute',
                width: TICK_WIDTH,
                height: TICK_HEIGHT,
                transformOrigin: 'top left',
                transform: `
                  translate(${SQUARE_SIZE / 2}px, ${SQUARE_SIZE / 2}px)
                  rotate(${percent}turn)
                  translate(${(TICK_COUNT * TICK_HEIGHT) / 2}px, 0px)
                  `,
              }}
            />
          );
        })}
      </div>
    );
  }

  renderTime() {
    const { labels = ['Hrs', 'Mins', 'Secs'] } = this.props;
    if (this.props.seconds === undefined) return null;

    const days = floor(this.props.seconds / (3600 * 24));
    const hours = floor((this.props.seconds % (3600 * 24)) / 3600);
    const minutes = floor((this.props.seconds % 3600) / 60);
    const seconds = floor(this.props.seconds % 60);

    return (
      <div className="Time">
        {days > 0 && (
          <span className="Time__Column">
            <span className="Time__Days Time__Digit">
              {days.toString(10).padStart(2, '0')}
            </span>
            <label>DAYS</label>
          </span>
        )}
        <span className="Time__Column">
          <span className="Time__Hours Time__Digit">
            {hours.toString(10).padStart(2, '0')}
          </span>
          <label>HRS</label>
        </span>
        <span className="Time__Column">
          <span className="Time__Minutes Time__Digit">
            {minutes.toString(10).padStart(2, '0')}
          </span>
          <label>MINS</label>
        </span>
        {days <= 0 && (
          <span className="Time__Column">
            <span className="Time__Seconds Time__Digit">
              {seconds.toString(10).padStart(2, '0')}
            </span>
            <label>SECS</label>
          </span>
        )}
      </div>
    );
  }
}
