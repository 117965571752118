/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// Players
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';

@observer
export class Slide03 extends AnimatedSlide {
  // Animation players
  players = {
    team: null,
    info1: null,
    info2: null,
    dots1: null,
    dots2: null,
  };

  // Run the first animation
  componentDidMount() {
    const { team, info1, info2, dots1, dots2 } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(1500);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(200);
      },
      // Step 2
      () => {
        this.autoNext(200);
      },
      // Step 3
      () => {
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: team,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: dots1,
          width: [0, 100],
          delay: 500,
          duration: 500,
        },
        {
          targets: dots2,
          width: [0, 120],
          delay: 1000,
          duration: 500,
        },
        {
          targets: info1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: info2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 3000,
          duration: 500,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-03 ${className}`}>
        {children}
        <div className="content">
          {/* TRU Team */}
          <div
            className="player team"
            data-active="0"
            ref={node => (this.players.team = node)}
          >
            <Card IconComponent={Person} text="TRU TEAM" />
            <div
              ref={node => (this.players.dots1 = node)}
              className={'animated-dotted-line reverse ants-1'}
            ></div>
            <div
              ref={node => (this.players.dots2 = node)}
              className={'animated-dotted-line reverse ants-2'}
            ></div>
          </div>
          {/* Info1 */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerTextBlock
              title={'THREAT_RESPONSE_UNIT'}
              width={'340px'}
              className={''}
              compact={true}
              sections={[
                {
                  title: ['REDDIT'],
                },
                {
                  title: [
                    'https://redit.com/r/msp/comments/ocggbv/critical_ransomware_incident_in_progress/',
                  ],
                  multiline: true,
                  className: 'normal-text link',
                  animate: true,
                  delay: 0,
                },
                {
                  title: ['AUTHOR', 'MARK LOMAN'],
                  grid: 2,
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['EXE', 'AGENT.EXE (DROPPED)'],
                  grid: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['DLL', 'MPSVC.DLL'],
                  grid: 2,
                  animate: true,
                  delay: 600,
                },
                {
                  title: ['OFFICIAL RECOMMENDATION'],
                  animate: true,
                  delay: 800,
                },
                {
                  title: [
                    'IMMEDIATELY shutdown your VSA server until you receive further notice from us',
                  ],
                  multiline: true,
                  className: 'normal-text link',
                  animate: true,
                  delay: 1000,
                },
              ]}
            />
          </div>
          {/* Info2 */}
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerTextBlock
              title={'DETECTED'}
              width={300}
              className={'red-top'}
              sections={[
                {
                  title: ['supply chain attack'],
                  color: 'red',
                  colorCells: true,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
