/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import { PlayerSparks } from '../players/sparks';

// SVG
import { Card } from '../svg/card';
import { Skull } from '../svg/skull';

@observer
export class Slide02 extends AnimatedSlide {
  // Animation players
  players = {
    attacker: null,
    shield: null,
    shieldBorder: null,
    cloud: null,
    aws: null,
    dots: null,
    sparks: null,
    skull: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      attacker,
      shield,
      shieldBorder,
      cloud,
      aws,
      dots,
      sparks,
      skull,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(7000);
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(6000);
      },
      // Step 3
      () => {
        this.autoNext(500);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        // Do nothing step 1
      ],

      //
      // Step 2
      //
      [
        // Attacker animates from center to left
        {
          targets: attacker,
          opacity: 1,
          delay: 0,
          translateX: -300,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: shield,
          opacity: 1,
          delay: 200,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: dots,
          opacity: 1,
          delay: 200,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: sparks,
          opacity: 1,
          delay: 200,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: cloud,
          opacity: 1,
          delay: 200,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: aws,
          opacity: 1,
          delay: 200,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: shieldBorder,
          borderWidth: 0,
          delay: 200,
          duration: 6000,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: dots,
          opacity: 0,
          delay: 0,
          duration: 200,
          complete: deactivate,
        },
        {
          targets: sparks,
          opacity: 0,
          delay: 0,
          duration: 200,
          complete: deactivate,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: aws,
          opacity: 0,
          delay: 0,
          duration: 200,
          complete: deactivate,
        },
        {
          targets: attacker,
          opacity: 0,
          delay: 0,
          duration: 200,
          begin: shimmer,
          complete: deactivate,
        },
        {
          targets: skull,
          opacity: 1,
          scale: 2,
          delay: 0,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: cloud,
          scale: 2,
          delay: 0,
          duration: 500,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-02 ${className}`}>
        {children}
        <div className="content">
          {/*-------PLAYERS START-------*/}
          {/* Skull */}
          <div
            className="player attacker"
            data-active="1"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text={'ATTACKER'} />
          </div>

          {/* Dots */}
          <div
            className="player dots"
            data-active="0"
            ref={node => (this.players.dots = node)}
          >
            <PlayerDots
              duration={1}
              dots={3}
              size={4}
              width={140}
              color={'white'}
              animation={'noFade'}
            />
          </div>

          {/* Sparks */}
          <div
            className="player sparks"
            data-active="0"
            ref={node => (this.players.sparks = node)}
          >
            <PlayerSparks
              duration={1}
              dots={2}
              size={4}
              round={false}
              width={50}
              iteration={'infinite'}
              rotate={100}
              color={'rgba(255, 255, 255, 0.5)'}
            />
          </div>

          {/* Shield */}
          <div
            className="player shield"
            data-active="0"
            ref={node => (this.players.shield = node)}
          >
            <div ref={node => (this.players.shieldBorder = node)} />
          </div>

          {/* Cloud */}
          <div
            className="player cloud"
            data-active="0"
            ref={node => (this.players.cloud = node)}
          >
            <svg
              width="116"
              height="65"
              viewBox="0 0 116 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M98.9007 24.7647C96.212 24.2412 93.4445 24.2412 90.7557 24.7647C90.3946 18.9005 88.0449 13.3228 84.08 8.91759C80.1151 4.51234 74.7623 1.5322 68.8718 0.450546C62.9814 -0.631104 56.8912 0.247773 51.5687 2.94757C46.2461 5.64736 41.9964 10.0132 39.4946 15.3517C35.3385 13.1629 30.6663 12.0889 25.9508 12.2384C18.9222 12.4866 12.2717 15.4214 7.42225 20.4151C2.57282 25.4088 -0.0904147 32.0647 0.00234422 38.9588C0.0951031 45.8529 2.93649 52.4376 7.91859 57.3042C12.9007 62.1708 19.6278 64.9326 26.6607 64.9989H95.0711C100.261 65.0509 105.282 63.1912 109.14 59.7879C112.998 56.3845 115.413 51.6855 115.906 46.6204C116.399 41.5554 114.935 36.4934 111.803 32.436C108.672 28.3786 104.1 25.6213 98.9941 24.7098L98.9007 24.7647Z"
                fill="white"
                fillOpacity="0.02"
              />
              <path
                d="M98.5185 26.7278L99.2619 26.8726L99.3525 26.8193C103.688 27.7639 107.541 30.1874 110.22 33.658C113.044 37.3158 114.359 41.8722 113.916 46.4266C113.472 50.9814 111.3 55.2157 107.817 58.288C104.333 61.3613 99.7919 63.0461 95.0911 62.999L95.0811 62.9989H95.0711H26.6704C20.1509 62.9352 13.9231 60.3736 9.31612 55.8735C4.7083 51.3725 2.08772 45.2908 2.00216 38.9319C1.91661 32.573 4.37258 26.4263 8.85704 21.8084C13.3419 17.1901 19.5003 14.4683 26.0178 14.2373C30.3876 14.0994 34.7153 15.0951 38.5627 17.1213L40.4166 18.0976L41.3056 16.2004C43.6156 11.2713 47.5434 7.23188 52.4734 4.73123C57.404 2.23027 63.0493 1.41479 68.5106 2.41766C73.9717 3.42046 78.9272 6.1822 82.5935 10.2556C86.259 14.3282 88.4264 19.4787 88.7595 24.8877L88.8997 27.1637L91.138 26.7278C93.5743 26.2535 96.0822 26.2535 98.5185 26.7278Z"
                stroke="white"
                strokeOpacity="0.1"
                strokeWidth="4"
              />
            </svg>
          </div>

          {/* AWS */}
          <div
            className="player aws"
            data-active="0"
            ref={node => (this.players.aws = node)}
          >
            <span>AWS</span>
          </div>

          {/* Skull */}
          <div
            className="player skull"
            data-active="0"
            ref={node => (this.players.skull = node)}
          >
            <Skull siz={60} color={'#ffffff'} />
          </div>
          {/*-------PLAYERS END-------*/}
        </div>
      </div>
    );
  }
}
