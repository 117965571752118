/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { AnimatedSlide } from '../components/animated-slide';
import { getColor } from '../components/helpers';

// SCSS
import './team-scans-customers-with-product.scss';

// Players
import { PlayerWaves } from '../components/players/waves';
import { PlayerPath } from '../components/players/path';
import { PlayerIconLoad } from '../components/players/icon-load';
import { PlayerNetworkScan } from '../components/players/network-scan';

// SVG
import { Shield } from '../components/svg/shield';
import { TRU } from '../components/svg/tru';
import { Person } from '../components/svg/person';
import { Soc } from '../components/svg/soc';
import { EsentireE } from '../components/svg/esentire-e';
import { Shodan } from '../components/svg/shodan';

@observer
export class Template__TeamScansCustomersWithProduct extends AnimatedSlide {
  // Animation players
  players = {
    team1: null,
    team2: null,
    product: null,
    network: null,
    path1: null,
    path2: null,
    path3: null,
  };

  team1 = {
    icon: TRU,
    text: 'TRU TEAM',
  };

  team2 = {
    icon: EsentireE,
    text: 'SOC Analysts',
  };

  product = {
    IconComponent: Shodan,
    color: this.colors.blue,
    duration: 2000,
    size: 120,
    strokeWidth: 2,
    strokeDashoffset: '20 100',
  };

  // Run the first animation
  componentDidMount() {
    const {
      team1,
      team2,
      product,
      network,
      path1,
      path2,
      path3,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(team1, 0),
        this.fadeIn(path1, 500),
        this.fadeIn(team2, 1000),
        this.fadeIn(path2, 1500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '', label = '' } = this.props;

    // Preselect the indexes of the computers to get highlighted
    var baddies = '0,1,3,4,5,6,8,9,10,13,14,17,18,19,20,21,22,23,24,25,26,27,28,29,31,32,33,34,35'.split(
      ','
    );

    // Thin line definition
    let thinLine = {
      strokeWidth: 2,
      strokeDashArray: '2,2',
    };

    let thickLine = {
      strokeWidth: 4,
      strokeDashArray: '4,4',
    };

    return (
      <div className={`Template__TeamScansCustomersWithProduct ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}

          {/* Team 1 */}
          <div
            className="player team1"
            data-active="0"
            ref={node => (this.players.team1 = node)}
          >
            <Shield
              IconComponent={this.team1.icon}
              text={this.team1.text}
              letterSpacing={0}
              IconOpacity={1}
              IconSize={50}
              iconOffsetY={-10}
              gradient={true}
            />
          </div>

          {/* Team 2 */}
          <div
            className="player team2"
            data-active="0"
            ref={node => (this.players.team2 = node)}
          >
            <Shield
              IconComponent={this.team2.icon}
              letterSpacing={0}
              IconOpacity={1}
              IconSize={50}
              iconOffsetY={-10}
              text={this.team2.text}
              gradient={true}
            />
          </div>

          {/* Icon */}
          <div
            className="player product"
            data-active="1"
            ref={node => (this.players.product = node)}
          >
            <label>{label}</label>
            <PlayerIconLoad
              strokeWidth={this.product.strokeWidth}
              size={this.product.size}
              start={{
                IconComponent: this.product.IconComponent,
                color: this.product.color,
                size: 70,
              }}
              loading={{
                delay: 0,
                duration: this.product.duration,
                strokeDashoffset: this.product.strokeDashoffset,
              }}
            />
          </div>

          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,0', '30,0', '110,80', '154,80']}
              reveal={{
                delay: 1500,
                duration: 1000,
              }}
              gradient={{
                preset: 'blueWhite',
                direction: 'right',
              }}
            />
          </div>

          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,80', '30,80', '110,0', '154,0']}
              reveal={{
                delay: 2000,
                duration: 1000,
              }}
              gradient={{
                preset: 'blueWhite',
                direction: 'right',
              }}
            />
          </div>

          {/* Right paths */}
          <div
            className="player right"
            data-active="1"
            ref={node => (this.players.linesRight = node)}
          >
            {/* Top */}
            <PlayerPath
              points={['0,100', '96,0', '270,0', '314,45']}
              gradient={{ preset: 'blueWhite' }}
              dots={thinLine}
            />
            <PlayerPath points={['0,0', '45,45']} dots={thinLine} />
            <PlayerPath points={['0,0', '45,45']} dots={thinLine} />
            <PlayerPath points={['0,0', '45,45']} dots={thinLine} />
            <PlayerPath points={['0,0', '45,45']} dots={thinLine} />
            <PlayerPath points={['0,0', '45,45']} dots={thinLine} />
            {/* Middle */}
            <PlayerPath
              points={['0,0', '128,0']}
              data={{
                duration: 1000,
              }}
              gradient={{ preset: 'blueWhite' }}
              dots={thickLine}
            />
            {/* Middle inside 1 */}
            <PlayerPath points={['14,0', '0,14'].reverse()} dots={thinLine} />
            <PlayerPath points={['14,14', '0,0'].reverse()} dots={thinLine} />
            {/* Middle inside 2 */}
            <PlayerPath points={['39,0', '0,39'].reverse()} dots={thinLine} />
            <PlayerPath points={['39,39', '0,0'].reverse()} dots={thinLine} />
            {/* Middle inside 3 */}
            <PlayerPath points={['64,0', '0,64'].reverse()} dots={thinLine} />
            <PlayerPath points={['64,64', '0,0'].reverse()} dots={thinLine} />
            {/* Bottom section */}
            <PlayerPath
              points={['0,0', '96,100', '270,100', '314,56']}
              gradient={{ preset: 'blueWhite' }}
              dots={thinLine}
            />
            <PlayerPath points={['0,45', '45,0']} dots={thinLine} />
            <PlayerPath points={['0,45', '45,0']} dots={thinLine} />
            <PlayerPath points={['0,45', '45,0']} dots={thinLine} />
            <PlayerPath points={['0,45', '45,0']} dots={thinLine} />
            <PlayerPath points={['0,45', '45,0']} dots={thinLine} />
          </div>

          {/* Network right */}
          <div
            className="player network"
            data-active="1"
            ref={node => (this.players.network = node)}
          >
            <PlayerNetworkScan
              columns={6}
              rows={6}
              width={184}
              gap={10}
              borderColor={this.colors.blue}
              borderOpacity={1}
              indexes={baddies}
              remediate={true}
              vulnerable={true}
              duration={6000}
              random={true}
              instant={true}
            />
          </div>

          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
