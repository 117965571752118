import { observer } from 'mobx-react';
import * as React from 'react';
import {
  LineChart,
  YAxis,
  CartesianGrid,
  XAxis,
  Line,
  Tooltip,
} from 'recharts';
import { Component } from 'react';
import './probability-instant-chart.scss';
import { ChartShell } from './chart-shell.jsx';
import { autorun, observable, toJS } from 'mobx';
import {
  Scenario,
  probabilityCalculator,
  IProbabilityCalculatorSeries,
  IProbabilityCalculatorData,
} from '../../data/graph-data-transforms';
import { generateColor } from '../../data/color-generator';
import Color from 'color';

const formatPercent = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 0,
}).format;

const shortMonth = new Intl.DateTimeFormat(undefined, {
  month: 'short',
}).format;

const longMonth = new Intl.DateTimeFormat(undefined, {
  month: 'long',
}).format;

const get = (object, path: string[]) =>
  path.reduce(
    (object, key) => (object && key in object ? object[key] : undefined),
    object
  );

export type SeriesData = IProbabilityCalculatorSeries & {
  color: Color;
  key: string;
};

export type SetOptional<T, U extends keyof T> = Pick<T, Exclude<keyof T, U>> &
  Pick<Partial<T>, U>;
export type InputSeriesData = SetOptional<SeriesData, 'color' | 'key'>;

export interface IProps
  extends Pick<Partial<React.HTMLAttributes<{}>>, 'className'> {
  /** The data for the chart */
  data?: any;
  width?: number;
  height?: number;
  colors?: Color[];
  /** What sections should be displayed on the chart */
  series: InputSeriesData[];
  sensorCount?: number;
  scenario: Scenario;
  showCumulative?: boolean;
}

@observer
export class ProbabilityInstantChart extends Component<IProps> {
  _dispose: () => void;

  @observable
  filteredData: {
    series: IProbabilityCalculatorSeries[];
    data: IProbabilityCalculatorData[];
  } = {
    series: [],
    data: [],
  };

  lastRun: any;

  componentDidMount() {
    this._dispose = autorun(() => {
      const { series = [], sensorCount = 1, scenario } = this.props;

      // For each series we need to gather compounded series for each filter

      (async () => {
        // Calculate the probability data from the system
        this.filteredData = await probabilityCalculator({
          series: series,
          sensorCount,
          scenario,
          isCumulative: false,
          includeCompounded: this.props.showCumulative || false,
        });
      })();
    });
  }

  componentWillUnmount() {
    try {
      this._dispose();
    } catch {
      console.warn('Error cleaning up Probability Chart');
    }
  }

  render() {
    const {
      children,
      className = '',
      data = toJS(this.filteredData),
      width = 900,
      height = 500,
      colors = [],
    } = this.props;

    const { data: seriesData, series: querySeries } = data;

    // Make sure the series values are defaulted
    const allSeries = this.props.series.map((series, i) => ({
      ...series,
      key: series.key || `${series.filter} - ${series.attackType}`,
      color: series.color || colors[i] || generateColor(i),
    }));

    // Include any extra series the data returned provided for the query
    querySeries.forEach(item => {
      if (
        !this.props.series.find(
          check =>
            check.filter === item.filter && check.attackType === item.attackType
        )
      ) {
        const index = allSeries.length;
        allSeries.push({
          ...item,
          key: `${item.filter} - ${item.attackType}`,
          color: colors[index] || generateColor(index),
        });
      }
    });

    const scenario = {
      [Scenario.AVERAGE]: 'value',
      [Scenario.BEST]: 'min',
      [Scenario.WORST]: 'max',
    }[this.props.scenario || 0];

    const cleanData = seriesData.map(row =>
      allSeries.reduce((row, series) => {
        const { filter, attackType, key } = series;
        return {
          ...row,
          [key]: get(row, [filter, attackType, scenario]),
        };
      }, row)
    );

    return (
      <ChartShell
        className={`ProbabilityInstantChart ${className}`}
        leftSection="Probability of Incident"
      >
        <LineChart
          className="LineChart Graph"
          {...{ width, height, data: cleanData }}
        >
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={formatPercent}
          />
          <XAxis
            dataKey="month"
            type="number"
            scale="time"
            tickFormatter={shortMonth}
            domain={
              seriesData &&
              seriesData[0] && [
                seriesData[0].month,
                seriesData[seriesData.length - 1].month,
              ]
            }
            axisLine={false}
            tickLine={false}
          />
          <Tooltip
            isAnimationActive={false}
            content={data => this.renderTooltip(data, allSeries, scenario)}
            cursor={false}
          />
          <CartesianGrid vertical={false} stroke="rgba(255, 255, 255, 0.5)" />
          {allSeries.map((series, i) => {
            return (
              <Line
                dot={false}
                activeDot={true}
                key={series.key}
                stroke={`${series.color}`}
                dataKey={series.key}
                strokeWidth={3}
              />
            );
          })}
          {children}
        </LineChart>
      </ChartShell>
    );
  }

  renderTooltip(data, allSeries, scenario) {
    if (!(data && data.payload && data.payload.length)) return;

    return (
      <div className="ProbabilityInstantChart__Tooltip">
        <div className="Tooltip__Header">
          {longMonth(get(data, ['payload', '0', 'payload', 'month']))}
        </div>
        <span className="Tooltip__TH">Indicent Type</span>
        <span className="Tooltip__TH">Probability</span>
        <span className="Tooltip__TH">Confidence</span>
        {allSeries.map((series, i) => {
          return (
            <React.Fragment key={series.key}>
              <span className="Tooltip__Line" />
              <span
                className="Tooltip__Dot"
                style={{ background: series.color }}
              />
              <span className="Tooltip__NameCell">{series.key}</span>
              <span className="Tooltip__ValueCell">
                {formatPercent(get(data, ['payload', i, 'value']))}
              </span>
              <span className="Tooltip__ConfidenceCell">
                {formatPercent(
                  // TODO: Clean up the way we get confidence into the instant
                  // probability tooltip
                  get(data, [
                    'payload',
                    i,
                    'payload',
                    series.filter,
                    series.attackType,
                    'confidence',
                  ])
                )}
              </span>
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
