/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { getColor } from '../components/helpers';
import { AnimatedSlide } from '../components/animated-slide';

// SCSS
import './signals-threat-hunt.scss';

// Players
import { PlayerSignals } from '../components/players/signals';
import { PlayerNetworkScan } from '../components/players/network-scan';
import { PlayerPath } from '../components/players/path';

// SVG
import { Shield } from '../components/svg/shield';
import { Greynoise } from '../components/svg/greynoise';
import { Atlas } from '../components/svg/atlas';
import { SignalEndpoint } from '../components/svg/signal-endpoint';
import { SignalNetwork } from '../components/svg/signal-network';
import { SignalLog } from '../components/svg/signal-log';

@observer
export class Template__SignalsThreatHunt extends AnimatedSlide {
  // Animation players
  players = {
    customers: null,
    signals: null,
    greynoise: null,
    amp: null,
    path1: null,
    path2: null,
    path3: null,
  };

  // Icons to use
  icons = [
    {
      IconComponent: SignalEndpoint,
      title: 'Endpoint',
    },
    {
      IconComponent: SignalNetwork,
      title: 'Network',
    },
    {
      IconComponent: SignalLog,
      title: 'Log',
    },
  ];

  // Shield1
  shield1 = {
    icon: Greynoise,
    text: 'GREYNOISE',
    size: 55,
    opacity: 1,
    gradient: true,
  };

  // Shield2
  shield2 = {
    icon: Atlas,
    text: 'AMP',
    size: 55,
    opacity: 1,
    gradient: false,
    stroke: getColor('blue'),
    strokeOpacity: 1,
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;

    const {
      customers,
      signals,
      greynoise,
      amp,
      path1,
      path2,
      path3,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(customers),
        this.fadeIn(path1, 200),
        this.fadeIn(signals, 500),
        this.fadeIn(path2, 700),
        this.fadeIn(greynoise, 1000),
        this.fadeIn(path3, 1200),
        this.fadeIn(amp, 1500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const {
      children,
      className = '',
      icons = [],
      shield1 = null,
      shield2 = null,
    } = this.props;

    // Allow icons to be passed in
    if (icons.length) {
      this.icons = icons;
    }

    if (typeof shield1 === 'object') {
      Object.assign(this.shield1, shield1);
    }

    if (typeof shield2 === 'object') {
      Object.assign(this.shield2, shield2);
    }

    let whiteDots1 = {
      stroke: this.colors.white,
      opacity: 0.5,
      animationDuration: '25s',
    };
    let whiteDots2 = {
      stroke: this.colors.white,
      opacity: 0.6,
      animationDuration: '20s',
    };
    let whiteDots3 = {
      stroke: this.colors.white,
      opacity: 0.7,
      animationDuration: '15s',
    };
    let whiteDots4 = {
      stroke: this.colors.white,
      opacity: 0.8,
      animationDuration: '10s',
    };

    return (
      <div className={`Template__SignalsThreatHunt ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* Customers */}
          <div
            className="player customers"
            data-active="0"
            ref={node => (this.players.customers = node)}
          >
            <div className="player-title">
              PROACTIVE THREAT HUNT
              <br />
              ACROSS 1200+ CUSTOMERS
            </div>
            <PlayerNetworkScan columns={6} rows={5} width={180} gap={6} />
          </div>
          {/* Signals */}
          <div
            className="player signals"
            data-active="0"
            ref={node => (this.players.signals = node)}
          >
            <div className="player-title">SIGNALS</div>
            <PlayerSignals delay={1500} icons={this.icons} />
          </div>
          {/* Greynoise */}
          <div
            className="player greynoise"
            data-active="0"
            ref={node => (this.players.greynoise = node)}
          >
            <Shield
              IconComponent={this.shield1.icon}
              text={this.shield1.text}
              IconSize={this.shield1.size}
              IconOpacity={this.shield1.opacity}
              gradient={this.shield1.gradient}
            />
          </div>
          {/* AMP */}
          <div
            className="player amp"
            data-active="0"
            ref={node => (this.players.amp = node)}
          >
            <Shield
              IconComponent={this.shield2.icon}
              text={this.shield2.text}
              IconSize={this.shield2.size}
              IconOpacity={this.shield2.opacity}
              stroke={this.shield2.stroke}
              strokeOpacity={this.shield2.strokeOpacity}
            />
          </div>
          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,3', '56,3']}
              dots={whiteDots1}
              reveal={{ delay: 700, duration: 800 }}
            />
            <PlayerPath
              points={['0,3', '54,3']}
              dots={whiteDots2}
              reveal={{ delay: 700, duration: 700 }}
            />
            <PlayerPath
              points={['0,3', '52,3']}
              dots={whiteDots3}
              reveal={{ delay: 700, duration: 600 }}
            />
            <PlayerPath
              points={['0,3', '50,3']}
              dots={whiteDots4}
              reveal={{ delay: 700, duration: 500 }}
            />
            <PlayerPath
              points={['0,3', '52,3']}
              dots={whiteDots3}
              reveal={{ delay: 700, duration: 600 }}
            />
            <PlayerPath
              points={['0,3', '54,3']}
              dots={whiteDots2}
              reveal={{ delay: 700, duration: 700 }}
            />
            <PlayerPath
              points={['0,3', '56,3']}
              dots={whiteDots1}
              reveal={{ delay: 700, duration: 800 }}
            />
          </div>
          {/* Path 1 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath points={['0,3', '56,3']} reveal={{ delay: 1500 }} />
          </div>
          {/* Path 3 */}
          <div
            className="player path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath points={['0,3', '56,3']} reveal={{ delay: 2000 }} />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
