/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';
import { Alert } from '../svg/alert';

// Icons
import { Directory } from '../svg/directory';
import { InternetExplorer } from '../svg/internet-explorer';
import { Word } from '../svg/word';
import { PowerShell } from '../svg/power-shell';
import { CMD } from '../svg/cmd';
import { Download } from '../svg/download';

@observer
export class Slide11 extends AnimatedSlide {
  // Animation players
  players = {
    explorer: null,
    word: null,
    cmd: null,
    powershell: null,
    exe: null,
    process: null,
    network: null,
    activity: null,
    border: null,
  };

  // Run the first animation
  componentDidMount() {
    // Get alert's border
    this.players.border = this.players.alert.querySelector('.border');

    const {
      explorer,
      word,
      cmd,
      powershell,
      exe,
      process,
      network,
      activity,
      alert,
      border,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(6000);
        this.autoNext(2000);
      },
      // Step 2
      () => {
        powershell.classList.remove('hide-title');
        this.autoNext(500);
      },
      // Step 3
      () => {
        powershell.classList.add('hide-title');
        this.autoNext(3000);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: powershell,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
        },
        {
          targets: explorer,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
        },
        {
          targets: word,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
        },
        {
          targets: cmd,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
        },
        {
          targets: exe,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: powershell,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 651,
          scale: 1,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: explorer,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 200,
          begin: activate,
        },
        {
          targets: word,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 200,
          begin: activate,
        },
        {
          targets: cmd,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 200,
          begin: activate,
        },
        {
          targets: exe,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 200,
          begin: activate,
        },

        {
          targets: process,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          delay: 0,
          complete: deactivate,
        },
        {
          targets: network,
          network: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          delay: 0,
          begin: deactivate,
        },
        {
          targets: activity,
          activity: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          delay: 0,
          begin: deactivate,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: powershell,
          easing: 'easeOutQuad',
          scale: 1.5,
          opacity: 1,
          left: 200,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: explorer,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          complete: deactivate,
        },
        {
          targets: word,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          complete: deactivate,
        },
        {
          targets: cmd,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          complete: deactivate,
        },
        {
          targets: exe,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          complete: deactivate,
        },

        {
          targets: process,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: network,
          network: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 500,
          begin: activateShimmer,
        },
        {
          targets: activity,
          activity: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },

        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          complete: deactivate,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },

        {
          targets: powershell,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 200,
          delay: 0,
        },
        {
          targets: process,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 200,
          delay: 0,
        },
        {
          targets: network,
          network: 'easeOutQuad',
          opacity: 0.1,
          duration: 200,
          delay: 0,
        },
        {
          targets: activity,
          activity: 'easeOutQuad',
          opacity: 0.1,
          duration: 200,
          delay: 0,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`ProcessLocation2 ${className}`}>
        {children}
        <div className="content">
          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'Confirmed'}
              subtitle={'Payload Downloaded'}
              IconComponent={Download}
            />
          </div>

          {/* Process */}
          <div
            className="player process"
            data-active="0"
            ref={node => (this.players.process = node)}
          >
            <PlayerTextBlock
              title={'Process'}
              showDots={true}
              sections={[
                {
                  title: 'Powershell.exe',
                },
              ]}
            />
          </div>

          <div
            className="player network"
            data-active="0"
            ref={node => (this.players.network = node)}
          >
            <PlayerTextBlock
              title={'Network Connection'}
              width={'240px'}
              sections={[
                {
                  title: ['IP:', '192.187.29.106', 'PORT: ', 'TCP/80'],
                  grid: 2,
                },
                {
                  title: 'WWW.COHOMESDENVER.COM',
                },
              ]}
            />
          </div>

          {/* Process text box */}
          <div
            className="player activity"
            data-active="0"
            ref={node => (this.players.activity = node)}
          >
            <PlayerTextBlock
              title={'Application Activity'}
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineCoumns: 2,
                },
              ]}
            />
          </div>

          {/* Folder */}
          <div
            className="player explorer"
            data-active="1"
            ref={node => (this.players.explorer = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Directory}
              title={'EXPLORER.EXE'}
              align={'bottom'}
            />
          </div>

          {/* Word */}
          <div
            className="player word"
            data-active="1"
            ref={node => (this.players.word = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Word}
              title={'WINWORD.eXE'}
              align={'bottom'}
            />
          </div>

          {/* CMD */}
          <div
            className="player cmd"
            data-active="1"
            ref={node => (this.players.cmd = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={CMD}
              title={'CMD.EXE'}
              align={'bottom'}
            />
          </div>

          {/* PowerShell */}
          <div
            className="player powershell"
            data-active="1"
            ref={node => (this.players.powershell = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={PowerShell}
              title={'POWERSHELL.eXE'}
              align={'bottom'}
            />
          </div>

          {/* 238789.EXE */}
          <div
            className="player exe"
            data-active="1"
            ref={node => (this.players.exe = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={CMD}
              title={'238789.EXE'}
              align={'bottom'}
            />
          </div>
        </div>
      </div>
    );
  }
}
