/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Directory extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 32,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 32 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.4153 6.04004H6.96027C5.74269 6.04004 4.71698 7.03948 4.71698 8.27014V26.551H20.4938H29.4153C30.6329 26.551 31.6512 25.5142 31.6512 24.2761V8.27014C31.6512 7.03948 30.6403 6.04004 29.4153 6.04004Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M15.129 4.84535L12.5168 1.58598C12.0961 1.06388 11.4689 0.743164 10.8048 0.743164H3.1894C1.96445 0.743164 0.953491 1.7799 0.953491 3.01801V24.2823C0.953491 25.5129 1.96445 26.5571 3.1894 26.5571H3.53623V8.26881C3.53623 6.37435 5.09325 4.84535 6.9602 4.84535H15.129Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
