/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes, createRef } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// SCSS
import '../message.scss';

// Players
import { PlayerPath } from '../players/path';
import { PlayerIconLoad } from '../players/icon-load';
import { PlayerLoadingMessage } from '../players/loading-message';

// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { Person } from '../svg/person';
import { Check } from '../svg/check';

@observer
export class Slide11 extends AnimatedSlide {
  message = createRef();

  // Animation players
  players = {
    card: null,
    computer1: null,
    computer2: null,
    computer3: null,
    computer4: null,
    computer5: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
    path5: null,
    path6: null,
    path7: null,
    path8: null,
    path9: null,
    path10: null,
    loading: null,
  };

  changeMessage() {
    if (!this.message.current) return;
    this.message.current.transition();
  }

  fadeIn(target, delay) {
    return {
      targets: target,
      opacity: 1,
      easing: 'easeOutQuad',
      delay: delay,
      duration: 500,
      begin: activate,
      complete: activateShimmer,
    };
  }

  // Run the first animation
  componentDidMount() {
    const {
      card,
      computer1,
      computer2,
      computer3,
      computer4,
      computer5,
      path1,
      path2,
      path3,
      path4,
      path5,
      path6,
      path7,
      path8,
      path9,
      path10,
      loading,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(9000);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(1000);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(card, 0),
        this.fadeIn(path1, 200),
        this.fadeIn(computer1, 300),
        this.fadeIn(path2, 500),
        this.fadeIn(path3, 500),
        this.fadeIn(computer2, 600),
        this.fadeIn(path4, 800),
        this.fadeIn(path5, 1000),
        this.fadeIn(computer3, 1100),
        this.fadeIn(path6, 1300),
        this.fadeIn(path7, 1500),
        this.fadeIn(computer4, 1600),
        this.fadeIn(path8, 1800),
        this.fadeIn(path9, 2000),
        this.fadeIn(computer5, 2100),
        this.fadeIn(path10, 2300),
        this.fadeIn(loading, 1000),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let start = {
      IconComponent: Computer,
      stroke: this.colors.white,
      opacity: 0.2,
    };
    let endGood = {
      IconComponent: Check,
      stroke: this.colors.blue,
      opacity: 1,
    };
    let strokeWidth = 3;

    return (
      <div className={`slide-11 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TRU Team */}
          <div
            className="player card"
            data-active="0"
            ref={node => (this.players.card = node)}
          >
            <Card IconComponent={Person} text="TRU TEAM" />
          </div>
          {/* --------------- PATH GROUP 1 ---------------*/}
          {/* Path 1 */}
          <div
            className="player terminal path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,100', '60,100', '60,3', '220,3']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
            />
          </div>
          {/* Path 2 */}
          <div
            className="player terminal path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath points={['220,3', '66,3', '66,100', '0,100']} />
          </div>
          {/* Computer 1 */}
          <div
            className="player terminal computer1"
            data-active="0"
            ref={node => (this.players.computer1 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: 2000,
                duration: 2000,
              }}
              end={endGood}
            />
          </div>
          {/* --------------- PATH GROUP 2 ---------------*/}
          {/* Path 3 */}
          <div
            className="player terminal path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,60', '120,60', '120,3', '340,3']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
            />
          </div>
          {/* Path 4 */}
          <div
            className="player terminal path4"
            data-active="0"
            ref={node => (this.players.path4 = node)}
          >
            <PlayerPath points={['340,3', '126,3', '126,60', '0,60']} />
          </div>
          {/* Computer 2 */}
          <div
            className="player terminal computer2"
            data-active="0"
            ref={node => (this.players.computer2 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: 3000,
                duration: 2000,
              }}
              end={endGood}
            />
          </div>
          {/* --------------- PATH GROUP 3 ---------------*/}
          {/* Path 5 */}
          <div
            className="player terminal path5"
            data-active="0"
            ref={node => (this.players.path5 = node)}
          >
            <PlayerPath
              points={['0,3', '280,3']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
            />
          </div>
          {/* Path 6 */}
          <div
            className="player terminal path6"
            data-active="0"
            ref={node => (this.players.path6 = node)}
          >
            <PlayerPath points={['280,3', '0,3']} />
          </div>
          {/* Computer 3 */}
          <div
            className="player terminal computer3"
            data-active="0"
            ref={node => (this.players.computer3 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: 4000,
                duration: 2000,
              }}
              end={endGood}
            />
          </div>
          {/* --------------- PATH GROUP 4 ---------------*/}
          {/* Path 7 */}
          <div
            className="player terminal path7"
            data-active="0"
            ref={node => (this.players.path7 = node)}
          >
            <PlayerPath
              points={['0,3', '126,3', '126,60', '340,60']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
            />
          </div>
          {/* Path 8 */}
          <div
            className="player terminal path8"
            data-active="0"
            ref={node => (this.players.path8 = node)}
          >
            <PlayerPath points={['340,60', '120,60', '120,3', '0,3']} />
          </div>
          {/* Computer 4 */}
          <div
            className="player terminal computer4"
            data-active="0"
            ref={node => (this.players.computer4 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: 5000,
                duration: 2000,
              }}
              end={endGood}
            />
          </div>
          {/* --------------- PATH GROUP 5 ---------------*/}
          {/* Path 1 */}
          <div
            className="player terminal path9"
            data-active="0"
            ref={node => (this.players.path9 = node)}
          >
            <PlayerPath
              points={['0,3', '66,3', '66,100', '220,100']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
            />
          </div>
          {/* Path 2 */}
          <div
            className="player terminal path10"
            data-active="0"
            ref={node => (this.players.path10 = node)}
          >
            <PlayerPath points={['220,100', '60,100', '60,3', '0,3']} />
          </div>
          {/* Computer 5 */}
          <div
            className="player terminal computer5"
            data-active="0"
            ref={node => (this.players.computer5 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: 6000,
                duration: 2000,
              }}
              end={endGood}
              complete={() => this.changeMessage()}
            />
          </div>
          {/* Loading */}
          <div
            className="player loading"
            data-active="0"
            ref={node => (this.players.loading = node)}
          >
            <PlayerLoadingMessage
              width={230}
              start={{
                text: 'SEARCHING QAKBOT',
              }}
              end={{
                text: 'NO HITS DETECTED',
              }}
              ref={this.message}
            />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
