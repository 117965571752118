/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './cloudshark.scss';
import anime from 'animejs';
import ReactDOM from 'react-dom';
import { IconCloudShark } from '../svg/cloudshark';

@observer
export class PlayerCloudShark extends Component {
  interval = null;
  pulses = null;

  defaults = {
    duration: 1000,
    wait: 10000,
  };

  componentDidMount() {
    const { wait = this.defaults.wait } = this.props;

    // Get pulses
    let pulses = ReactDOM.findDOMNode(this.pulses);

    // Don't add the class until told
    setTimeout(() => {
      pulses.classList.add('pulse-on');
    }, wait);
  }

  render() {
    const { children, className = '', color = '#C53D43' } = this.props;

    return (
      <div className={`player--cloudShark ${className}`}>
        <div className={'player--cloudShark-icon'}>
          <IconCloudShark size="50" />
        </div>
        <div
          className={'player--cloudShark-circle'}
          ref={node => (this.pulses = node)}
        >
          <div className="player--cloudShark-pulse" />
          <div className="player--cloudShark-pulse" />
          <div className="player--cloudShark-pulse" />
          <div className="player--cloudShark-pulse" />
          <div className="player--cloudShark-pulse" />
          <div className="player--cloudShark-pulse" />
        </div>
      </div>
    );
  }
}
