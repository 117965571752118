/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class ShieldBlank extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} opacity={opacity}>
          <path d="M11 27.8182V16.0367L29 8.18212L47 16.0367V27.8182C47 38.8951 39.2786 49.2242 29 51.9377C18.7214 49.2242 11 38.8951 11 27.8182Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5 10.9091L29.5455 0L54.0909 10.9091V27.2727C54.0909 42.4091 43.6182 56.5636 29.5455 60C15.4727 56.5636 5 42.4091 5 27.2727V10.9091ZM9 14.7273L29 6L49 14.7273V27.8182C49 39.9273 40.4667 51.2509 29 54C17.5333 51.2509 9 39.9273 9 27.8182V14.7273Z"
          />
        </g>
      </svg>
    );
  }
}
