/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Person extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 68,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 68 68"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.0179 54.4538C62.9354 50.2917 55.8294 47.6779 45.9935 44.0612C44.8602 43.6447 43.6928 43.2154 42.5 42.7706V35.9465C43.7452 34.8911 46.4072 32.1343 46.7202 27.6335C47.362 27.0895 47.8692 26.1502 48.1624 24.9078C48.6342 22.9103 48.399 20.5431 46.9866 19.1363C47.0687 18.9267 47.1608 18.6957 47.2472 18.4776C48.3338 15.7392 50.3554 10.642 49.5564 6.64275C48.5761 1.73967 41.6118 0 35.9465 0C31.5506 0 26.2126 1.10642 24.5296 4.1395C22.8367 4.32792 21.5305 5.02775 20.6394 6.22908C18.1772 9.54833 19.9637 15.4431 20.9242 18.6108C20.9723 18.768 21.0219 18.9323 21.0687 19.0839C19.6095 20.4836 19.3616 22.8848 19.839 24.9078C20.1322 26.1502 20.6394 27.0895 21.2812 27.6335C21.5942 32.1343 24.2562 34.8911 25.5 35.9465V42.7706C24.3072 43.2154 23.1398 43.6461 22.0022 44.064C12.1678 47.6793 5.06317 50.2917 2.9835 54.4538C0.0339999 60.3514 0 66.3312 0 66.5833C0 67.3653 0.634667 68 1.41667 68H66.5833C67.3667 68 68 67.3653 68 66.5833C68 66.3312 67.9674 60.3514 65.0179 54.4538Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
