/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import './slide-process-location.scss';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';

// Icons
import { Directory } from '../svg/directory';
import { InternetExplorer } from '../svg/internet-explorer';
import { Word } from '../svg/word';

@observer
export class Slide10 extends AnimatedSlide {
  // Animation players
  players = {
    folder: null,
    process1: null,
    process2: null,
    process3: null,
    process4: null,
    process5: null,
    subProcess1: null,
    subProcess2: null,
    subProcess3: null,
    ie: null,
    process: null,
    network: null,
    info: null,
    connectors: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      folder,
      process1,
      process2,
      process3,
      process4,
      process5,
      subProcess1,
      subProcess2,
      subProcess3,
      ie,
      process,
      network,
      info,
      connectors,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(7000);
        this.autoNext(3000);
        connectors.classList.remove('highlight-path');
      },
      // Step 2
      () => {
        this.autoNext(2000);
        connectors.classList.add('highlight-path');
        subProcess1.classList.remove('hide-title');
      },
      // Step 3
      () => {
        subProcess1.classList.add('hide-title');
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: folder,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: process1,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: process3,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: process4,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: process5,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: subProcess2,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: subProcess3,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: process1,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 500,
          begin: activate,
        },
        {
          targets: process3,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 500,
          begin: activate,
        },
        {
          targets: process4,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 500,
          begin: activate,
        },
        {
          targets: process5,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 500,
          begin: activate,
        },
        {
          targets: subProcess2,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 500,
          begin: activate,
        },
        {
          targets: subProcess3,
          easing: 'easeOutQuad',
          opacity: 0.1,
          duration: 500,
          begin: activate,
        },

        // Highlighted route
        {
          targets: connectors,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: folder,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: process2,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: subProcess1,
          opacity: 1,
          scale: 1,
          left: 760,
          top: 196,
          duration: 500,
          begin: activateShimmer,
        },

        // Deactivate
        { targets: process, opacity: 0, duration: 500, complete: deactivate },
        { targets: network, opacity: 0, duration: 500, complete: deactivate },
        { targets: info, opacity: 0, duration: 500, complete: deactivate },
      ],

      //
      // Step 3
      //
      [
        {
          targets: subProcess1,
          opacity: 1,
          scale: 1.5,
          left: 140,
          top: 376,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: process,
          opacity: 1,
          duration: 500,
          translateX: [-50, 0],
          begin: activateShimmer,
        },
        {
          targets: network,
          opacity: 1,
          duration: 500,
          translateX: [-50, 0],
          delay: 500,
          begin: activateShimmer,
        },
        {
          targets: info,
          opacity: 1,
          duration: 500,
          translateX: [-50, 0],
          delay: 1000,
          begin: activateShimmer,
        },

        // Deactivate everyone
        {
          targets: connectors,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: activate,
        },
        {
          targets: folder,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: activate,
        },
        {
          targets: process1,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: deactivate,
        },
        {
          targets: process2,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: deactivate,
        },
        {
          targets: process3,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: deactivate,
        },
        {
          targets: process4,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: deactivate,
        },
        {
          targets: process5,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: deactivate,
        },
        {
          targets: subProcess2,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: deactivate,
        },
        {
          targets: subProcess3,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          begin: deactivate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`ProcessLocation ${className}`}>
        {children}
        <div className="content">
          {/* Process information */}
          <div
            className="player info"
            data-active="0"
            ref={node => (this.players.info = node)}
          >
            <PlayerTextBlock
              title={'Process information'}
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Network text box */}
          <div
            className="player network"
            data-active="0"
            ref={node => (this.players.network = node)}
          >
            <PlayerTextBlock
              title={'Network Connection'}
              sections={[
                {
                  title: ['IP:', '45.40.148.106', 'PORT: ', 'TCP/80'],
                  grid: 2,
                },
                {
                  title: 'WWW.EASYPROPERTY.COM',
                },
              ]}
            />
          </div>

          {/* Process text box */}
          <div
            className="player process"
            data-active="0"
            ref={node => (this.players.process = node)}
          >
            <PlayerTextBlock
              title={'Process'}
              showDots={true}
              sections={[
                {
                  title: 'IEEXPLORER.EXE',
                },
              ]}
            />
          </div>

          {/* Folder */}
          <div
            className="player folder"
            data-active="1"
            ref={node => (this.players.folder = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Directory}
              title={'EXPLORER.EXE'}
            />
          </div>

          {/* Process1 */}
          <div
            className="player process1"
            data-active="1"
            ref={node => (this.players.process1 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={InternetExplorer}
              title={'EXPLORER.EXE'}
            />
          </div>

          {/* Process2 */}
          <div
            className="player process2"
            data-active="1"
            ref={node => (this.players.process2 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={InternetExplorer}
              title={'EXPLORER.EXE'}
            />
          </div>

          {/* Process3 */}
          <div
            className="player process3"
            data-active="1"
            ref={node => (this.players.process3 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={InternetExplorer}
              title={'EXPLORER.EXE'}
            />
          </div>

          {/* Process4 */}
          <div
            className="player process4"
            data-active="1"
            ref={node => (this.players.process4 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Word}
              title={'WINWORD.EXE'}
            />
          </div>

          {/* Process5 */}
          <div
            className="player process5"
            data-active="1"
            ref={node => (this.players.process5 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Word}
              title={'WINWORD.EXE'}
            />
          </div>

          {/* SubProcess1 */}
          <div
            className="player subProcess3"
            data-active="1"
            ref={node => (this.players.subProcess1 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={InternetExplorer}
              title={'EXPLORER.EXE'}
            />
          </div>

          {/* SubProcess2 */}
          <div
            className="player subProcess4"
            data-active="1"
            ref={node => (this.players.subProcess2 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={InternetExplorer}
              title={'EXPLORER.EXE'}
            />
          </div>

          {/* SubProcess3 */}
          <div
            className="player subProcess5"
            data-active="1"
            ref={node => (this.players.subProcess3 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={InternetExplorer}
              title={'EXPLORER.EXE'}
            />
          </div>

          <div
            className={'connector-tree'}
            ref={node => (this.players.connectors = node)}
          >
            <div className="connectors right">
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <div className="connectors left">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
