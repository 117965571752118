/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Clock extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 18,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 9C0 4.03763 4.03725 0 9 0C13.9624 0 18 4.03763 18 9C18 13.9627 13.9624 18 9 18C4.03725 18 0 13.9627 0 9ZM8.40535 10.1009L14.1553 12.6634L14.9695 10.8366L9.81241 8.53834V3.625H7.81241L7.81241 9.1875V9.83666L8.40535 10.1009Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
