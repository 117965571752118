/** @jsx createElement */
import { observer } from 'mobx-react';
import { getColor } from './helpers';
import { isValidElement, createElement, Component } from 'react';
import './story-block.scss';
import { Bug } from './svg/bug';

@observer
export class StoryBlock extends Component {
  color = getColor('blue');

  icon = {
    size: 20,
    color: getColor('blue'),
  };

  renderIcon() {
    const { IconComponent = Bug, icon = null } = this.props;

    // If an icon is actually passed in
    if (icon && isValidElement(icon)) {
      return icon;
    }

    return (
      <IconComponent
        size={this.icon.size}
        color={this.color ? this.color : this.icon.color}
      />
    );
  }

  render() {
    const {
      children,
      className = '',
      description = '',
      title = '',
      color = getColor('blue'),
    } = this.props;

    if (color) this.color = color;

    return (
      <div
        className={`StoryBlock ${className} ${
          description ? '' : 'no-description'
        }`}
        style={{ borderColor: this.color ? this.color : this.icon.color }}
      >
        <div className={'StoryBlock__Title'}>
          {title}
          <div className="StoryBlock__Icon">{this.renderIcon()}</div>
        </div>
        <div className={'StoryBlock__Description'}>{description}</div>
      </div>
    );
  }
}
