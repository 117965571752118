/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class InternalNetwork extends Component {
  renderText(text) {
    let startingY = 40;
    let lineHeight = 12;

    // Render just one line of text
    if (!Array.isArray(text)) return this.renderTextLine(text, startingY);

    // Render multiple lines of text
    startingY = startingY - lineHeight * (text.length - 1);
    return text.map((value, index) => {
      return this.renderTextLine(value, startingY + index * lineHeight, index);
    });
  }

  renderTextLine(text, y, index) {
    const {
      letterSpacing = 2,
      fontSize = 12,
      fillOpacity,
      color = '#ffffff',
      uppercase = true,
    } = this.props;
    return (
      <text
        key={index}
        x="50%"
        y={y}
        textAnchor="middle"
        fontFamily="Blender Thin"
        fontSize={fontSize.toString().replace(/px/g, '') + 'px'}
        letterSpacing={letterSpacing.toString().replace(/px/g, '') + 'px'}
        fill={color}
        fillOpacity={fillOpacity}
      >
        {uppercase ? text.toUpperCase() : text}
      </text>
    );
  }

  render() {
    const {
      children,
      className = '',
      x = 0,
      y = 0,
      text = '',
      opacity = 1,
      color = '#ffffff',
      stroke = '#ffffff',
      strokeOpacity = 0.1,
    } = this.props;

    return (
      <svg
        className={`NavDot ${className}`}
        {...{ x, y }}
        width="200"
        height="200"
        viewBox="0 0 200 200"
        stroke={stroke}
        strokeOpacity={strokeOpacity}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="99.5"
          cy="99.5"
          r="99.5"
          fill="#ffffff"
          fillOpacity="0.03"
        />
        <circle
          cx="99.5"
          cy="99.5"
          r="97.5"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeWidth="4"
        />
        <g fill={color} fillRule="evenodd" clipRule="evenodd" stroke="none">
          {this.renderText(text)}
          <path d="M86.8464 56.5654H109.924V70.6683H86.8464V56.5654ZM84.9233 73.2313H111.847C112.202 73.2313 112.488 72.9442 112.488 72.5903V56.5642C112.488 55.15 111.338 54 109.924 54H86.8464C85.4323 54 84.2822 55.15 84.2822 56.5642V72.5903C84.2822 72.9442 84.5688 73.2313 84.9233 73.2313Z" />
          <path d="M113.129 73.8711H101.59C101.236 73.8711 100.949 74.1583 100.949 74.5121V75.1532H95.8209V74.5121C95.8209 74.1583 95.5343 73.8711 95.1798 73.8711H83.641C83.2865 73.8711 83 74.1583 83 74.5121V77.0763C83 78.1366 83.8628 78.9995 84.9231 78.9995H111.847C112.907 78.9995 113.77 78.1366 113.77 77.0763V74.5121C113.77 74.1583 113.484 73.8711 113.129 73.8711Z" />
          <path d="M45.8464 56.5654H68.924V70.6683H45.8464V56.5654ZM43.9233 73.2313H70.8472C71.2017 73.2313 71.4882 72.9442 71.4882 72.5903V56.5642C71.4882 55.15 70.3382 54 68.924 54H45.8464C44.4323 54 43.2822 55.15 43.2822 56.5642V72.5903C43.2822 72.9442 43.5688 73.2313 43.9233 73.2313Z" />
          <path d="M72.1291 73.8711H60.5903C60.2358 73.8711 59.9493 74.1583 59.9493 74.5121V75.1532H54.8209V74.5121C54.8209 74.1583 54.5343 73.8711 54.1798 73.8711H42.641C42.2865 73.8711 42 74.1583 42 74.5121V77.0763C42 78.1366 42.8628 78.9995 43.9231 78.9995H70.847C71.9073 78.9995 72.7701 78.1366 72.7701 77.0763V74.5121C72.7701 74.1583 72.4836 73.8711 72.1291 73.8711Z" />
          <path d="M127.846 56.5654H150.924V70.6683H127.846V56.5654ZM125.923 73.2313H152.847C153.202 73.2313 153.488 72.9442 153.488 72.5903V56.5642C153.488 55.15 152.338 54 150.924 54H127.846C126.432 54 125.282 55.15 125.282 56.5642V72.5903C125.282 72.9442 125.569 73.2313 125.923 73.2313Z" />
          <path d="M154.129 73.8711H142.59C142.236 73.8711 141.949 74.1583 141.949 74.5121V75.1532H136.821V74.5121C136.821 74.1583 136.534 73.8711 136.18 73.8711H124.641C124.287 73.8711 124 74.1583 124 74.5121V77.0763C124 78.1366 124.863 78.9995 125.923 78.9995H152.847C153.907 78.9995 154.77 78.1366 154.77 77.0763V74.5121C154.77 74.1583 154.484 73.8711 154.129 73.8711Z" />
          <path d="M86.8464 91.5654H109.924V105.668H86.8464V91.5654ZM84.9233 108.231H111.847C112.202 108.231 112.488 107.944 112.488 107.59V91.5642C112.488 90.15 111.338 89 109.924 89H86.8464C85.4323 89 84.2822 90.15 84.2822 91.5642V107.59C84.2822 107.944 84.5688 108.231 84.9233 108.231Z" />
          <path d="M113.129 108.871H101.59C101.236 108.871 100.949 109.158 100.949 109.512V110.153H95.8209V109.512C95.8209 109.158 95.5343 108.871 95.1798 108.871H83.641C83.2865 108.871 83 109.158 83 109.512V112.076C83 113.137 83.8628 113.999 84.9231 113.999H111.847C112.907 113.999 113.77 113.137 113.77 112.076V109.512C113.77 109.158 113.484 108.871 113.129 108.871Z" />
          <path d="M45.8463 91.5644H68.9236V105.667H45.8463V91.5644ZM43.9233 108.231H70.8467C71.2012 108.231 71.4877 107.944 71.4877 107.59V91.5641C71.4877 90.15 70.3377 89 68.9236 89H45.8464C44.4322 89 43.2822 90.15 43.2822 91.5641V107.59C43.2822 107.944 43.5688 108.231 43.9233 108.231Z" />
          <path d="M72.1286 108.872H60.59C60.2355 108.872 59.9489 109.159 59.9489 109.513V110.154H54.8207V109.513C54.8207 109.159 54.5341 108.872 54.1796 108.872H42.641C42.2865 108.872 42 109.159 42 109.513V112.077C42 113.137 42.8628 114 43.9231 114H70.8465C71.9068 114 72.7696 113.137 72.7696 112.077V109.513C72.7696 109.159 72.4831 108.872 72.1286 108.872Z" />
          <path d="M127.846 91.5644H150.924V105.667H127.846V91.5644ZM125.923 108.231H152.847C153.201 108.231 153.488 107.944 153.488 107.59V91.5641C153.488 90.15 152.338 89 150.924 89H127.846C126.432 89 125.282 90.15 125.282 91.5641V107.59C125.282 107.944 125.569 108.231 125.923 108.231Z" />
          <path d="M154.129 108.872H142.59C142.235 108.872 141.949 109.159 141.949 109.513V110.154H136.821V109.513C136.821 109.159 136.534 108.872 136.18 108.872H124.641C124.287 108.872 124 109.159 124 109.513V112.077C124 113.137 124.863 114 125.923 114H152.847C153.907 114 154.77 113.137 154.77 112.077V109.513C154.77 109.159 154.483 108.872 154.129 108.872Z" />
          <path d="M86.8464 126.565H109.924V140.668H86.8464V126.565ZM84.9233 143.231H111.847C112.202 143.231 112.488 142.944 112.488 142.59V126.564C112.488 125.15 111.338 124 109.924 124H86.8464C85.4323 124 84.2822 125.15 84.2822 126.564V142.59C84.2822 142.944 84.5688 143.231 84.9233 143.231Z" />
          <path d="M113.129 143.871H101.59C101.236 143.871 100.949 144.158 100.949 144.512V145.153H95.8209V144.512C95.8209 144.158 95.5343 143.871 95.1798 143.871H83.641C83.2865 143.871 83 144.158 83 144.512V147.076C83 148.137 83.8628 148.999 84.9231 148.999H111.847C112.907 148.999 113.77 148.137 113.77 147.076V144.512C113.77 144.158 113.484 143.871 113.129 143.871Z" />
          <path d="M45.8464 126.565H68.924V140.668H45.8464V126.565ZM43.9233 143.231H70.8472C71.2017 143.231 71.4882 142.944 71.4882 142.59V126.564C71.4882 125.15 70.3382 124 68.924 124H45.8464C44.4323 124 43.2822 125.15 43.2822 126.564V142.59C43.2822 142.944 43.5688 143.231 43.9233 143.231Z" />
          <path d="M72.1291 143.871H60.5903C60.2358 143.871 59.9493 144.158 59.9493 144.512V145.153H54.8209V144.512C54.8209 144.158 54.5343 143.871 54.1798 143.871H42.641C42.2865 143.871 42 144.158 42 144.512V147.076C42 148.137 42.8628 148.999 43.9231 148.999H70.847C71.9073 148.999 72.7701 148.137 72.7701 147.076V144.512C72.7701 144.158 72.4836 143.871 72.1291 143.871Z" />
          <path d="M127.847 126.565H150.926V140.669H127.847V126.565ZM125.923 143.233H152.849C153.204 143.233 153.49 142.945 153.49 142.592V126.564C153.49 125.15 152.34 124 150.926 124H127.847C126.432 124 125.282 125.15 125.282 126.564V142.592C125.282 142.945 125.569 143.233 125.923 143.233Z" />
          <path d="M154.131 143.872H142.592C142.237 143.872 141.95 144.159 141.95 144.513V145.154H136.822V144.513C136.822 144.159 136.535 143.872 136.181 143.872H124.641C124.287 143.872 124 144.159 124 144.513V147.077C124 148.137 124.863 149 125.923 149H152.849C153.909 149 154.772 148.137 154.772 147.077V144.513C154.772 144.159 154.486 143.872 154.131 143.872Z" />
        </g>
      </svg>
    );
  }
}
