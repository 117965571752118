/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// SCSS
import '../message.scss';

// Players
import { PlayerPath } from '../players/path';
import { PlayerIconLoad } from '../players/icon-load';

// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { Person } from '../svg/person';
import { Check } from '../svg/check';

@observer
export class Slide09 extends AnimatedSlide {
  // Animation players
  players = {
    card: null,
    computer1: null,
    computer2: null,
    computer3: null,
    computer4: null,
    computer5: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
    path5: null,
    path6: null,
    path7: null,
    path8: null,
    path9: null,
    path10: null,
  };

  fadeIn(target, delay) {
    return {
      targets: target,
      opacity: 1,
      easing: 'easeOutQuad',
      delay: delay,
      duration: 500,
      begin: activateShimmer,
      complete: shimmer,
    };
  }

  // Run the first animation
  componentDidMount() {
    const {
      card,
      computer1,
      computer2,
      computer3,
      computer4,
      computer5,
      path1,
      path2,
      path3,
      path4,
      path5,
      path6,
      path7,
      path8,
      path9,
      path10,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(1000);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(card, 0),
        this.fadeIn(path1, 200),
        this.fadeIn(computer1, 300),
        this.fadeIn(path2, 500),
        this.fadeIn(path3, 500),
        this.fadeIn(computer2, 600),
        this.fadeIn(path4, 800),
        this.fadeIn(path5, 1000),
        this.fadeIn(computer3, 1100),
        this.fadeIn(path6, 1300),
        this.fadeIn(path7, 1500),
        this.fadeIn(computer4, 1600),
        this.fadeIn(path8, 1800),
        this.fadeIn(path9, 2000),
        this.fadeIn(computer5, 2100),
        this.fadeIn(path10, 2300),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let reveal1 = { delay: 400, radius: 3, strokeWidth: 4 };
    let reveal2 = { delay: 700 + 500, radius: 3, strokeWidth: 4 };
    let reveal3 = { delay: 700, radius: 3, strokeWidth: 4 };
    let reveal4 = { delay: 1000 + 500, radius: 3, strokeWidth: 4 };
    let reveal5 = { delay: 1200, radius: 3, strokeWidth: 4 };
    let reveal6 = { delay: 1500 + 500, radius: 3, strokeWidth: 4 };
    let reveal7 = { delay: 1800, radius: 3, strokeWidth: 4 };
    let reveal8 = { delay: 2000 + 500, radius: 3, strokeWidth: 4 };
    let reveal9 = { delay: 2200, radius: 3, strokeWidth: 4 };
    let reveal10 = { delay: 2500 + 500, radius: 3, strokeWidth: 4 };

    let start = {
      IconComponent: Computer,
      stroke: this.colors.white,
      opacity: 0.2,
    };
    let endGood = {
      IconComponent: Check,
      stroke: this.colors.blue,
      opacity: 1,
    };
    let endBad = {
      IconComponent: Computer,
      stroke: this.colors.red,
      opacity: 1,
    };
    let strokeWidth = 3;

    return (
      <div className={`slide-09 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TRU Team */}
          <div
            className="player card"
            data-active="0"
            ref={node => (this.players.card = node)}
          >
            <Card IconComponent={Person} text="TRU TEAM" />
          </div>
          {/* --------------- PATH GROUP 1 ---------------*/}
          {/* Path 1 */}
          <div
            className="player terminal path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,100', '60,100', '60,3', '220,3']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
              reveal={reveal1}
            />
          </div>
          {/* Path 2 */}
          <div
            className="player terminal path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['220,3', '66,3', '66,100', '0,100']}
              reveal={reveal2}
            />
          </div>
          {/* Computer 1 */}
          <div
            className="player terminal computer1"
            data-active="0"
            ref={node => (this.players.computer1 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: 0,
                duration: 1200,
              }}
            />
          </div>
          {/* --------------- PATH GROUP 2 ---------------*/}
          {/* Path 3 */}
          <div
            className="player terminal path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,60', '120,60', '120,3', '340,3']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
              reveal={reveal3}
            />
          </div>
          {/* Path 4 */}
          <div
            className="player terminal path4"
            data-active="0"
            ref={node => (this.players.path4 = node)}
          >
            <PlayerPath
              points={['340,3', '126,3', '126,60', '0,60']}
              reveal={reveal4}
            />
          </div>
          {/* Computer 2 */}
          <div
            className="player terminal computer2"
            data-active="0"
            ref={node => (this.players.computer2 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: -50,
              }}
            />
          </div>
          {/* --------------- PATH GROUP 3 ---------------*/}
          {/* Path 5 */}
          <div
            className="player terminal path5"
            data-active="0"
            ref={node => (this.players.path5 = node)}
          >
            <PlayerPath
              points={['0,3', '280,3']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
              reveal={reveal5}
            />
          </div>
          {/* Path 6 */}
          <div
            className="player terminal path6"
            data-active="0"
            ref={node => (this.players.path6 = node)}
          >
            <PlayerPath points={['280,3', '0,3']} reveal={reveal6} />
          </div>
          {/* Computer 3 */}
          <div
            className="player terminal computer3"
            data-active="0"
            ref={node => (this.players.computer3 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: -100,
                duration: 1100,
              }}
            />
          </div>
          {/* --------------- PATH GROUP 4 ---------------*/}
          {/* Path 7 */}
          <div
            className="player terminal path7"
            data-active="0"
            ref={node => (this.players.path7 = node)}
          >
            <PlayerPath
              points={['0,3', '126,3', '126,60', '340,60']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
              reveal={reveal7}
            />
          </div>
          {/* Path 8 */}
          <div
            className="player terminal path8"
            data-active="0"
            ref={node => (this.players.path8 = node)}
          >
            <PlayerPath
              points={['340,60', '120,60', '120,3', '0,3']}
              reveal={reveal8}
            />
          </div>
          {/* Computer 4 */}
          <div
            className="player terminal computer4"
            data-active="0"
            ref={node => (this.players.computer4 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: -150,
              }}
            />
          </div>
          {/* --------------- PATH GROUP 5 ---------------*/}
          {/* Path 9 */}
          <div
            className="player terminal path9"
            data-active="0"
            ref={node => (this.players.path9 = node)}
          >
            <PlayerPath
              points={['0,3', '66,3', '66,100', '220,100']}
              dots={{
                stroke: this.colors.white,
                opacity: 0.5,
              }}
              reveal={reveal9}
            />
          </div>
          {/* Path 10 */}
          <div
            className="player terminal path10"
            data-active="0"
            ref={node => (this.players.path10 = node)}
          >
            <PlayerPath
              points={['220,100', '60,100', '60,3', '0,3']}
              reveal={reveal10}
            />
          </div>
          {/* Computer 5 */}
          <div
            className="player terminal computer5"
            data-active="0"
            ref={node => (this.players.computer5 = node)}
          >
            <PlayerIconLoad
              strokeWidth={strokeWidth}
              start={start}
              loading={{
                delay: -500,
              }}
            />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
