/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class InfoI extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 19,
      x = 0,
      y = 0,
      opacity = 0.4,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <path
          d="M5 0C4.0111 0 3.0444 0.293245 2.22215 0.842652C1.39991 1.39206 0.759043 2.17295 0.380605 3.08658C0.00216646 4.00021 -0.0968503 5.00555 0.0960758 5.97545C0.289002 6.94536 0.765206 7.83627 1.46447 8.53553C2.16373 9.2348 3.05465 9.711 4.02455 9.90393C4.99446 10.0969 5.99979 9.99784 6.91342 9.6194C7.82705 9.24096 8.60794 8.6001 9.15735 7.77785C9.70676 6.95561 10 5.98891 10 5C9.99849 3.67438 9.47122 2.40349 8.53387 1.46614C7.59651 0.528782 6.32562 0.0015124 5 0V0ZM5.53572 7.5C5.53572 7.64208 5.47928 7.77834 5.37881 7.87881C5.27834 7.97927 5.14208 8.03571 5 8.03571C4.85792 8.03571 4.72166 7.97927 4.6212 7.87881C4.52073 7.77834 4.46429 7.64208 4.46429 7.5V5C4.46429 4.85792 4.52073 4.72166 4.6212 4.62119C4.72166 4.52073 4.85792 4.46429 5 4.46429C5.14208 4.46429 5.27834 4.52073 5.37881 4.62119C5.47928 4.72166 5.53572 4.85792 5.53572 5V7.5ZM5 3.57143C4.85873 3.57143 4.72063 3.52954 4.60317 3.45105C4.4857 3.37256 4.39415 3.26101 4.34009 3.13049C4.28603 2.99997 4.27188 2.85635 4.29944 2.71779C4.327 2.57923 4.39503 2.45196 4.49493 2.35207C4.59482 2.25217 4.72209 2.18414 4.86065 2.15658C4.99921 2.12902 5.14283 2.14317 5.27335 2.19723C5.40387 2.25129 5.51542 2.34284 5.59391 2.46031C5.6724 2.57777 5.71429 2.71587 5.71429 2.85714C5.71429 3.04658 5.63903 3.22826 5.50508 3.36222C5.37112 3.49617 5.18944 3.57143 5 3.57143Z"
          fill={color}
        />
      </svg>
    );
  }
}
