/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Page } from '../svg/page';
import { Code } from '../svg/code';
import { Warning } from '../svg/warning';
import { Alert } from '../svg/alert';

@observer
export class Slide03 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    analyzing: null,
    code: null,
    alert: null,
    border: null,
  };

  // Run the first animation
  componentDidMount() {
    // Save the border since I can't bind it in the DOM like the others
    this.players.border = this.players.alert.querySelector('.border');

    const { code, donut, analyzing, alert, border } = this.players;

    this.steps = [
      // Step 1
      () => {
        // Do nothing
        this.animateProgress(5000);
        this.autoNext(4000);
      },

      // Step 2
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: code,
          opacity: 1,
          delay: 0,
          translateX: -10,
          translateY: -20,
          scale: 3,
          duration: 0,
        },
        {
          targets: analyzing,
          opacity: 1,
          left: 800,
          duration: 200,
          delay: 1000,
          begin: activate,
          complete: shimmer,
        },
      ],

      //
      // Step 2
      //
      [
        { targets: code, opacity: 0.1, duration: 500 },
        { targets: analyzing, opacity: 0.1, duration: 500 },
        { targets: donut, opacity: 0.5, duration: 500 },
        { targets: alert, opacity: 1, duration: 500, begin: activateShimmer },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-03 ${className}`}>
        {children}
        <div className="content">
          {/* Bad file detected */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'Endpoint WARNING'}
              IconComponent={Warning}
              subtitle={'Suspicious Powershell'}
              line2={'Command'}
            />
          </div>

          {/* Text block */}
          <div
            className="player analyzing"
            data-active="0"
            ref={node => (this.players.analyzing = node)}
          >
            <PlayerTextBlock
              title="Bluesteel"
              actionTitle="Analyzing Powershell Commands"
              className={'wrap red-top'}
            />
          </div>

          {/* Code */}
          <div
            className="player code"
            data-active="1"
            ref={node => (this.players.code = node)}
          >
            <Code size={'60'} color={'#C53D43'} />
          </div>

          {/* Donut */}
          <div
            className="player donut red"
            data-active="1"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
