import { Slide01 } from '../../../components/log/slide-01';
import { Slide02 } from '../../../components/log/slide-02';
import { Slide03 } from '../../../components/log/slide-03';
import { Slide04 } from '../../../components/log/slide-04';
import { Slide05 } from '../../../components/log/slide-05';
import { Slide06 } from '../../../components/log/slide-06';
import { Slide07 } from '../../../components/log/slide-07';
import { Slide08 } from '../../../components/log/slide-08';
import { Slide09 } from '../../../components/log/slide-09';
import { Slide10 } from '../../../components/log/slide-10';
import { Slide11 } from '../../../components/log/slide-11';
import { Slide12 } from '../../../components/log/slide-12';

import { Bug } from '../../../components/svg/bug';
import { Eye } from '../../../components/svg/eye';
import { Bell } from '../../../components/svg/bell';
import { Clock } from '../../../components/svg/clock';
import { CheckShield } from '../../../components/svg/check-shield';
import { ShieldBlank } from '../../../components/svg/shield-blank';
import { EmailAlert } from '../../../components/svg/email-alert';

export const LOG_CATEGORY = {
  STAGE1: 'Identification of Log4j Vulnerability',
  STAGE2: 'Coordination, Threat Hunt and Threat Disruption',
  STAGE3: 'Rapid Customer Threat Advisory',
  STAGE4: 'Ongoing Monitoring and Awareness',
};

export const LOG_COLUMNS = [
  [LOG_CATEGORY.STAGE1],
  [LOG_CATEGORY.STAGE2],
  [LOG_CATEGORY.STAGE3],
  [LOG_CATEGORY.STAGE4],
];

export const LOG_CATEGORY_ICONS = {
  [LOG_CATEGORY.STAGE1]: Bug,
  [LOG_CATEGORY.STAGE2]: ShieldBlank,
  [LOG_CATEGORY.STAGE3]: EmailAlert,
  [LOG_CATEGORY.STAGE4]: CheckShield,
};

export const logSlides = [
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Log4j',
    subtitle: 'Scenario Overview',
    component: Slide01,
  },
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Log4j',
    subtitle: 'Scenario Overview',
    component: Slide02,
  },
  {
    title: 'Log4j',
    subtitle: 'TRU Identifies Log4j Vulnerabilities During Daily Triage',
    time: '00:00:00:00',
    component: Slide03,
    icon: Bug,
    category: LOG_CATEGORY.STAGE1,
    appScreenshotUrl: [require('../../../assets/log/screen-01-01.png')],
    description:
      "Every morning eSentire's TRU team reviews the latest technical news and vendor advisories and selects the top threats to proactively triage in the threat landscape. An internal threat brief is then distributed to the entire internal team. Log4j immediately stood out and was identified as a significant threat due to it's ubiquity across most software applications and the possibility of mass exploitation. On Friday, December 10th at 9:30 am, eSentire's TRU team identifies indicators associated with Log4j vulnerabilities during this triage. It is also determined that malware deployment is possible and there were already instructions on the web on how this vulnerabiltiy could be exploited simply and quickly. Therefore, the Log4j vulnerability is immediately escalated by the TRU team to identify gaps in detections and begin proactive threat hunting.",
  },
  {
    title: 'Log4j',
    subtitle:
      'TRU Establishes Communication Channel for Cross Team Coordination and Response',
    product: 'MDR for Endpoint, MDR for Log & MDR for Network',
    time: '00:00:30:00',
    component: Slide04,
    icon: Eye,
    category: LOG_CATEGORY.STAGE2,
    appScreenshotUrl: [
      require('../../../assets/log/screen-02-01.png'),
      require('../../../assets/log/screen-02-02.png'),
      require('../../../assets/log/screen-02-03.png'),
      require('../../../assets/log/screen-02-04.png'),
    ],
    description:
      "Within a half hour of identifying the vulnerability, eSentire TRU establishes a communication channel for cross team coordination and response actions between TRU's Elite Threat Hunters and SOC Cyber Analysts.",
  },
  {
    title: 'Log4j',
    subtitle: 'Proactive Threat Hunt Begins',
    product: 'MDR for Endpoint, MDR for Log & MDR for Network',
    time: '00:00:54:00',
    component: Slide05,
    category: LOG_CATEGORY.STAGE2,
    appScreenshotUrl: [
      require('../../../assets/log/screen-03-00.png'),
      require('../../../assets/log/screen-03-01.png'),
      require('../../../assets/log/screen-03-02.png'),
      require('../../../assets/log/screen-03-03.png'),
    ],
    description:
      'By not even 10:30am the TRU team begins a proactive threat hunt that ingests Network, Endpoint, and Log data across the entire customer base for malicious exploit strings, domains, IPs, and TTPs. TRU communicates results to SOC Cyber Analysts who triage and investigate any positive hits. Using Greynoise, a honeypot as a service tool, TRU identifies malicious IPs doing wide scale scanning for the Log4j vulnerability. These malicious IP addresses, as well as malicious IPs identified in threat hunting activities are pushed to our global block list in AMP.',
  },
  {
    title: 'Log4j',
    subtitle: 'Customer Threat Intelligence Advisory',
    time: '00:01:14:00',
    component: Slide06,
    category: LOG_CATEGORY.STAGE3,
    icon: Bell,
    clientCommunication: true,
    appScreenshotUrl: [require('../../../assets/log/screen-04-01.png')],
    description:
      'Just over an hour after escalating the Log4j vulnerability and beginning triage and threat hunting, the TRU team distributes a Global Threat Advisory to customers that includes more details on Log4j, actions we are taking and recommended actions they should take to mitigate their risk including patching this vulnerability.',
  },
  {
    title: 'Log4j',
    subtitle: 'Log4j Detection and Response Runbooks Deployed',
    product: 'MDR for Log',
    time: '02:20:23:00',
    component: Slide07,
    category: LOG_CATEGORY.STAGE4,
    appScreenshotUrl: [
      require('../../../assets/log/screen-05-01.png'),
      require('../../../assets/log/screen-05-02.png'),
    ],
    description:
      'After 24/7 threat hunting and investigation for impacted customers throughout the weekend, on Monday TRU and the SOC push into production a new MDR for Log detection with investigation and response runbooks for Log4j to all customers. This runbook formalizes hunting queries used up to this point and provides a standard playbook for SOC Cyber Analysts to immediately action response.',
  },
  {
    title: 'Log4j',
    subtitle: 'Customer Threat Intelligence Advisory Update',
    time: '03:01:55:00',
    component: Slide08,
    category: LOG_CATEGORY.STAGE4,
    icon: Bell,
    clientCommunication: true,
    appScreenshotUrl: require('../../../assets/log/screen-06-02.png'),
    description:
      'The TRU team updates the Global Threat Advisory with the latest observations, actions we are taking and recommended actions customers should take to mitigate their risk.',
  },
  {
    title: 'Log4j',
    subtitle: 'Successful Log4j Exploitation Observed',
    product: 'MDR for Endpoint',
    time: '04:01:30:00',
    component: Slide09,
    category: LOG_CATEGORY.STAGE4,
    appScreenshotUrl: [
      require('../../../assets/log/screen-07-02.png'),
      require('../../../assets/log/screen-07-01.png'),
    ],
    description:
      'TRU and SOC identify successful exploitation events. In one case, an exploitation led to an attempted installation of Cobalt Strike malware which was detected and contained by MDR for Endpoint using our BlueSteel machine learning technology. BlueSteel analyzes all PowerShell commands from customer endpoints and classifies them as either malicious or benign. Overall, TRU saw approximately 144,000 exploit attempts of the Log4j vulnerability.',
  },
  {
    title: 'Log4j',
    subtitle:
      'Ad-Hoc Scans Initiated For Managed Vulnerability Service Customers',
    product: 'Managed Vulnerability Service',
    time: '04:07:00:00',
    component: Slide10,
    category: LOG_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/log/screen-08-01.png'),
    description:
      "By December 14th, eSentire's Managed Vulnerability Service team began proactive ad-hoc scans with plug-ins for Log4j CVEs in order to quickly identify, investigate and prioritize customer vulnerabilities. Identified vulnerabilities were remediated.",
  },
  {
    title: 'Log4j',
    subtitle: 'Inform Customer Base of eSentire Risk Mitigation Actions',
    time: '08:01:30:00',
    product: 'Threat Response Unit',
    component: Slide11,
    icon: Eye,
    category: LOG_CATEGORY.STAGE4,
    appScreenshotUrl: [
      require('../../../assets/log/screen-09-01.png'),
      require('../../../assets/log/screen-09-02.png'),
    ],
    description:
      'The TRU Team hosts a Threat Intelligence Webinar for customers with a review of the Log4j exploitation and current threat landscape with recommendations on how to protect their business.',
  },
  // Timeline
  {
    title: 'Log4j',
    subtitle: 'Timeline Summary',
    component: Slide12,
    icon: Clock,
    timelineSummary: true,
    category: LOG_CATEGORY.STAGE4,
  },
];
