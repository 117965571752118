/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Template__Webinar } from '../../templates/webinar';

@observer
export class Slide15 extends Component {
  componentDidMount() {
    // Do something
  }

  render() {
    const { children } = this.props;
    return (
      <div className={`slide-15`}>
        {children}
        <Template__Webinar />
      </div>
    );
  }
}
