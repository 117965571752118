/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Target extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 0.3,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd" clipRule="evenodd">
          <path d="M33 22H27V27H22V33H27V38H33V33H38V27H33V22Z" />
          <path d="M27 0H33V4.17121C44.962 5.54552 54.4545 15.038 55.8288 27H60V33H55.8288C54.4545 44.962 44.962 54.4545 33 55.8288V60H27V55.8288C15.038 54.4545 5.54551 44.962 4.17121 33H0V27H4.17121C5.54551 15.038 15.038 5.54551 27 4.17121V0ZM27 49.7765C18.3561 48.4763 11.5237 41.6439 10.2235 33H16V27H10.2235C11.5238 18.3561 18.3561 11.5238 27 10.2235V16H33V10.2235C41.6439 11.5238 48.4762 18.3561 49.7765 27H44V33H49.7765C48.4763 41.6439 41.6439 48.4763 33 49.7765V44H27V49.7765Z" />
        </g>
      </svg>
    );
  }
}
