/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDataMoving } from '../players/data-moving';
import { PlayerTextLines } from '../players/text-lines';
import { PlayerTextBlock } from '../players/text-block';
import { Alert } from '../svg/alert';
import { Warning } from '../svg/warning';

// SVG
import { Page } from '../svg/page';

@observer
export class Slide08 extends AnimatedSlide {
  // Animation players
  players = {
    dataLeft: null,
    tcp: null,
    file: null,
    seq: null,
    http: null,
    alert: null,
    border: null,
  };

  // Run the first animation
  componentDidMount() {
    // Save the border since I can't bind it in the DOM like the others
    this.players.border = this.players.alert.querySelector('.border');

    const { dataLeft, tcp, file, seq, http, alert, border } = this.players;

    this.steps = [
      // Slide 1
      () => {
        this.animateProgress(4000);
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(2000);
      },
      // Step 3
      () => {
        this.players.http
          .querySelector('.player--textBlock')
          .classList.add('red-top');
        this.autoNext(3000);
      },
      // Step 2
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        {
          targets: tcp,
          easing: 'easeOutQuad',
          opacity: 1,
          top: 230,
          duration: 200,
          delay: 0,
        },
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 1,
          top: 310,
          duration: 200,
          delay: 0,
        },
        {
          targets: seq,
          easing: 'easeOutQuad',
          opacity: 1,
          top: 410,
          duration: 200,
          delay: 0,
        },
        {
          targets: http,
          easing: 'easeOutQuad',
          opacity: 1,
          top: 470,
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: tcp,
          easing: 'easeOutQuad',
          opacity: 0,
          top: 130,
          duration: 200,
          delay: 0,
        },
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 0,
          top: 210,
          duration: 200,
          delay: 0,
        },
        {
          targets: seq,
          easing: 'easeOutQuad',
          opacity: 0,
          top: 310,
          duration: 200,
          delay: 0,
        },
        {
          targets: http,
          easing: 'easeOutQuad',
          top: 380,
          duration: 200,
          delay: 0,
          complete: shimmer,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: dataLeft,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 0,
          begin: shimmer,
        },
        {
          targets: http,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 0,
          begin: shimmer,
        },
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let tcpSettings = {
      title: 'TCP STREAM',
      sections: [
        {
          title: '50993 - http [rst,ack]',
        },
      ],
    };

    let seqSettings = {
      sections: [
        {
          title: 'Seq=1 Ack=1 Win=0 Lens=0',
        },
      ],
    };

    let httpSettings = {
      title: 'HTTP REQUEST',
      sections: [
        {
          title: 'Hjhqmbxyinislkkt.1j43kf.top',
        },
      ],
    };

    let fileSettings = {
      sections: [
        {
          title: ['DESTINATION', '8.253.140.248'],
          grid: 2,
          lines: 3,
          lineColumns: 2,
        },
      ],
    };

    return (
      <div className={`slide-08 ${className}`}>
        {children}
        <div className="content">
          {/* Bad file detected */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'WARNING'}
              subtitle={'Suspicious HTTP activity'}
              IconComponent={Warning}
            />
          </div>

          {/* Text Block */}
          <div
            className="player http"
            data-active="0"
            ref={node => (this.players.http = node)}
          >
            <PlayerTextBlock {...httpSettings} />
          </div>

          {/* Text Block */}
          <div
            className="player seq"
            data-active="1"
            ref={node => (this.players.seq = node)}
          >
            <PlayerTextBlock {...seqSettings} />
          </div>

          {/* File found */}
          <div
            className="player file"
            data-active="1"
            ref={node => (this.players.file = node)}
          >
            <PlayerTextBlock {...fileSettings} />
          </div>

          {/* Text Block */}
          <div
            className="player tcp"
            data-active="1"
            ref={node => (this.players.tcp = node)}
          >
            <PlayerTextBlock {...tcpSettings} />
          </div>

          {/* Left data moving */}
          <div
            className="player data-left"
            data-active="1"
            ref={node => (this.players.dataLeft = node)}
          >
            <PlayerDataMoving wait="0" />
          </div>
        </div>
      </div>
    );
  }
}
