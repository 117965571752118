import { Component, useEffect, useRef } from 'react';
import { observable } from 'mobx';
import anime from 'animejs';
import {
  activate,
  deactivate,
  shimmer,
  activateShimmer,
  getColor,
} from './helpers';
import { AppActions } from './app-actions';

export class AnimatedSlide extends Component {
  _isMounted = false;
  @observable animationIndex = 0;
  animations = [];
  timelines = [];
  steps = [];
  colors = {
    blue: '#50C2E3',
    red: '#C53D43',
    green: '#54D5AE',
    white: '#ffffff',
    orange: '#F36C21',
    yellow: '#ffe600',
    black: '#151719',
  };
  clickables = [];

  // Timer to skip slides
  skipSlide = null;
  progressTime = null;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  addClickable(element) {
    if (Array.isArray(element)) {
      element.forEach(e => {
        this.clickables.push(e);
      });
    } else {
      this.clickables.push(element);
    }
  }

  /**
   * Generates the slide class name based on the name of the Class of the slide
   */
  slideClass() {
    const slideName = this.constructor.name;
    const groups = slideName.match(/[a-z]+|[^a-z]+/gi);

    return groups.map(t => t.toLocaleLowerCase()).join('-');
  }

  addRemoveClass(node, className, wait = 1000) {
    node.classList.add(className);
    setTimeout(() => {
      node.classList.remove(className);
    }, wait);
  }

  // Set the number of seconds for the progress bar to fill up
  animateProgress(ms) {
    this.progressTime = ms;
  }

  clickDocument(e) {
    this.clickables.forEach(clickable => {
      var box = clickable.getBoundingClientRect();
      if (
        e.pageX >= box.x &&
        e.pageX < box.x + box.width &&
        e.pageY >= box.y &&
        e.pageY < box.y + box.height
      ) {
        clickable.click();
      }
    });
  }

  onLastSlide(index) {
    AppActions.pulseRightArrow();
  }

  onFirstSlide(index) {
    AppActions.stopRightArrow();

    // Bind click for clickthrough elements
    document.addEventListener('click', e => {
      this.clickDocument(e);
    });

    // Progress indicator
    let progress = document.querySelector('.Chrome__SlideProgress div span');
    let progressWrapper = document.querySelector('.Chrome__SlideProgress div');
    if (progress) {
      progress.style.width = '0%';
      progressWrapper.classList.remove('fade-out');
      anime.remove(progress); // Remove the animation just incase
      if (this.progressTime) {
        anime({
          targets: progress,
          width: '100%',
          easing: 'linear',
          duration: this.progressTime,
          complete: () => {
            progressWrapper.classList.add('fade-out');
          },
        });
      }
    }
  }

  // Sorry, I know this is terrible, but didn't know another way
  checkSlide(index) {
    let length = parseInt(this.animations.length);
    if (index === 0) this.onFirstSlide(index);
    if (index + 1 === length) this.onLastSlide(index);
  }

  autoNext(wait) {
    clearTimeout(this.skipSlide);
    this.skipSlide = setTimeout(this.next, wait);
  }

  resolveAfterSeconds(seconds) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, seconds);
    });
  }

  fadeIn(target, delay = 0, duration = 200) {
    return {
      targets: target,
      opacity: 1,
      easing: 'easeOutQuad',
      duration: duration,
      delay: delay,
      begin: activate,
      complete: activateShimmer,
    };
  }

  revealPreset(delay = 0, duration = 10000) {
    return {
      delay: delay,
      duration: duration,
    };
  }

  getPx(size) {
    return size.toString().replace(/px/g, '') + 'px';
  }

  shimmer(target) {
    return {
      targets: target,
      easing: 'easeOutQuad',
      duration: 200,
      begin: shimmer,
    };
  }

  next = () => {
    this.play(++this.animationIndex);
  };

  play = (index = this.animationIndex) => {
    if (typeof this.steps[index] == 'function') {
      this.steps[index]();
    }
    this.checkSlide(index);
    // This code will start the animation regardless of what's running
    // this.animations[index].forEach(anime);

    // This will remove existing animations from the element before starting a new one
    this.animations.length > index &&
      this.animations[index].forEach(settings => {
        anime.remove(settings.targets);
        if (!settings.easing) settings.easing = 'easeOutQuad';
        anime(settings);
      });
  };

  previous = () => {
    this.play(--this.animationIndex);
  };

  render() {
    return this.props.children;
  }
}
