import Color from 'color';

const colors = ['#50C2E3', '#F36C21', '#C53D43', '#FFFFFF'];

class Store {
  /**
   * Return a themed color at the specified index
   *
   * @param {number} index The index. This not a super important value. It just
   * ensures unique colors
   *
   * @retunr {Color} The color
   */
  color(index) {
    return Color(colors[index % colors.length]);
  }

  /** Formats percents natively in the browser */
  percentFormatter = Intl.NumberFormat(undefined, {
    style: 'percent',
    maximumFractionDigits: 0,
  });
}

export const ChartShellStore = new Store();
window.ChartShellStore = ChartShellStore;
