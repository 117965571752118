/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { Router } from 'vega-router';
import { KeyValueSet } from '../../key-value-set';
import {
  SOCCalculatorActions,
  SOCCalculatorState,
} from '../soc-calculator-actions';
import { Info } from '../../svg/info';
import { Popup } from '../../popup';
import './summary.scss';

const formatCurrency = new Intl.NumberFormat('en-US', {
  currency: 'USD',
}).format;

@observer
export class SOCCalculatorSummary extends Router {
  changeInput = (type, asNumber) => value => {
    value = value.currentTarget.value;

    if (asNumber) {
      if (!value) value = '0';
      const check = Number.parseFloat(value);
      if (isNaN(check)) return;
      value = check;
    }

    SOCCalculatorActions.updateField(type, value);
  };

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`SOCCalculatorSummary ${className}`}>
        <div className="SOCCalculatorSummary__Section SOCCalculatorSummary__SecurityTools">
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__SectionHeader">
              Security Tools
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Next-Gen Antivirus (EPP)
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Endpoint Forensics (EDR)
            </div>
            <div className="SOCCalculatorSummary__SectionCell">IDS/IPS</div>
            <div className="SOCCalculatorSummary__SectionCell">
              Siem Platform
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Vulnerability Scanning / MGMT
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Threat Intel Feeds
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__SectionHeader">
              Annual Total
            </div>
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__TotalText">
              ${formatCurrency(SOCCalculatorState.year1SecurityTools)}
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__SectionHeader">
              3-Year Total
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              <KeyValueSet
                set={[
                  {
                    key: 'Security Tools',
                    value: SOCCalculatorState.year1SecurityTools * 3,
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorSummary__Section SOCCalculatorSummary__SecurityOperations">
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__SectionHeader">
              <div>
                Sec Ops Tools Per Analyst{' '}
                <Popup position="top-left" trigger={<Info />} width={400}>
                  Security Operations Tools Include:
                  <p>
                    Workflow / Automation
                    <br />
                    Investigation Tools
                    <br />
                    Response Tools
                    <br />
                    Threat Management
                    <br />
                    Total Cost of Operations Tools
                  </p>
                </Popup>
              </div>
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Total Cost of Operations Tools
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__TotalText">
              ${formatCurrency(SOCCalculatorState.year1OperationTools)}
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell">
              <KeyValueSet
                set={[
                  {
                    key: 'Sec Ops Tools',
                    value: SOCCalculatorState.year1OperationTools * 3,
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorSummary__Section SOCCalculatorSummary__Personnel">
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__SectionHeader">
              Personnel
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Core Analyst 24x7 Coverage
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Security Operations Manager
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Intelligence Analysts
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Intelligence Managers
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Network Security Engineer
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Network Security Administrator
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__TotalText">
              ${formatCurrency(SOCCalculatorState.year1Personnel)}
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell">
              <KeyValueSet
                set={[
                  {
                    key: 'Personnel',
                    value: SOCCalculatorState.year1Personnel * 3,
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorSummary__Section SOCCalculatorSummary__OperationalExpenses">
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__SectionHeader">
              Operational Expenses
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Product Maintenance (Labor)
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Implementation &amp; Integration
            </div>
            <div className="SOCCalculatorSummary__SectionCell">
              Product Maintenance (Hardware)
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell">
              <KeyValueSet
                set={[
                  {
                    key: 'Implementation',
                    value: SOCCalculatorState.year1Implementation,
                    isCurrency: true,
                  },
                  {
                    key: 'Annual',
                    value: SOCCalculatorState.year1Maintenance,
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell">
              <KeyValueSet
                set={[
                  {
                    key: 'Opex',
                    value:
                      SOCCalculatorState.year1Implementation +
                      SOCCalculatorState.year1Maintenance * 3,
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorSummary__Section SOCCalculatorSummary__InHouseCost">
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__LargeText">
              Total 24x7 In-House Costs
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell">
              <KeyValueSet
                set={[
                  {
                    key: 'Annual',
                    value: SOCCalculatorState.year2InHouse,
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn SOCCalculatorSummary__SectionColumn--blue">
            <div className="SOCCalculatorSummary__SectionCell">
              <KeyValueSet
                set={[
                  {
                    key: '3-Year Total',
                    value: SOCCalculatorState.yearTotalInHouse,
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="SOCCalculatorSummary__Section SOCCalculatorSummary__EsentireCost">
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__LargeText SOCCalculatorSummary__WhiteText">
              Esentire Cost
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__SectionCell--editable">
              <KeyValueSet
                set={[
                  {
                    key: 'Year 1',
                    value: SOCCalculatorState.year1EsentireCost.toString(),
                    isCurrency: true,
                    isEditable: true,
                    onChange: this.changeInput('year1EsentireCost', true),
                  },
                ]}
              />
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__SectionCell--editable">
              <KeyValueSet
                set={[
                  {
                    key: 'Year 2',
                    value: SOCCalculatorState.year2EsentireCost.toString(),
                    isCurrency: true,
                    isEditable: true,
                    onChange: this.changeInput('year2EsentireCost', true),
                  },
                ]}
              />
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn">
            <div className="SOCCalculatorSummary__SectionCell SOCCalculatorSummary__SectionCell--editable">
              <KeyValueSet
                set={[
                  {
                    key: 'Year 3',
                    value: SOCCalculatorState.year3EsentireCost.toString(),
                    isCurrency: true,
                    isEditable: true,
                    onChange: this.changeInput('year3EsentireCost', true),
                  },
                ]}
              />
            </div>
          </div>
          <div className="SOCCalculatorSummary__SectionColumn SOCCalculatorSummary__SectionColumn--white">
            <div className="SOCCalculatorSummary__SectionCell">
              <div className="SOCCalculatorSummary__Delta">
                {-1 * SOCCalculatorState.yearTotalSavings}%
              </div>
              <KeyValueSet
                set={[
                  {
                    key: '3-Year Total',
                    value: SOCCalculatorState.yearTotalEsentireCost.toString(),
                    isCurrency: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
