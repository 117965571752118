/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { getColor } from '../components/helpers';
import { AnimatedSlide } from '../components/animated-slide';

// SCSS
import './cross-team-communication.scss';

// Players
import { PlayerCircle } from '../components/players/circle';
import { PlayerPath } from '../components/players/path';

// SVG
import { Team } from '../components/svg/team';

@observer
export class Template__CrossTeamCommunication extends AnimatedSlide {
  children = {
    team1: createRef(),
    team2: createRef(),
    team3: createRef(),
  };

  // Animation players
  players = {
    team1: null,
    team2: null,
    team3: null,
    circle: null,
    path1: null,
    path2: null,
    path3: null,
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;

    const { team1, team2, team3, circle, path1, path2, path3 } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      // Step 1 - Reveal teams
      () => {
        this.autoNext(2000);
      },
      // Step 2 - Enable communication
      () => {
        this.autoNext(500);
      },
      // Step 3
      () => {
        if (!this._isMounted) return;
        this.children.team1.current.blue();
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 4
      () => {
        if (!this._isMounted) return;
        this.children.team2.current.blue();
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 5
      () => {
        if (!this._isMounted) return;
        this.children.team3.current.blue();
        this.forceUpdate();
        this.autoNext(1000);
      },
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [this.fadeIn(team1), this.fadeIn(team2, 200), this.fadeIn(team3, 400)],
      //
      // Step 2
      //
      [this.fadeIn(circle)],
      //
      // Step 3
      //
      [this.shimmer([circle, team1]), this.fadeIn(path1)],
      //
      // Step 4
      //
      [this.shimmer([circle, team2]), this.fadeIn(path2)],
      //
      // Step 5
      //
      [this.shimmer([circle, team3]), this.fadeIn(path3)],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const {
      children,
      className = '',
      team1 = 'Elite Threat Hunters',
      team2 = 'SOC Cyber Analysts',
      team3 = 'Executive Leadership',
    } = this.props;

    return (
      <div className={`Template__CrossTeamCommunication ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* Team 1 */}
          <div
            className="player team1"
            data-active="0"
            ref={node => (this.players.team1 = node)}
          >
            <Team text={team1} ref={this.children.team1} />
          </div>
          {/* Team 2 */}
          <div
            className="player team2"
            data-active="0"
            ref={node => (this.players.team2 = node)}
          >
            <Team text={team2} ref={this.children.team2} />
          </div>
          {/* Team 3 */}
          <div
            className="player team3"
            data-active="0"
            ref={node => (this.players.team3 = node)}
          >
            <Team text={team3} ref={this.children.team3} />
          </div>
          {/* circle */}
          <div
            className="player circle"
            data-active="0"
            ref={node => (this.players.circle = node)}
          >
            <PlayerCircle size={80} strokeWidth={4} />
          </div>
          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath className={'path1'} points={['0,20', '0,0']} />
          </div>
          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath className={'path2'} points={['20,0', '0,12']} />
          </div>
          {/* Path 3 */}
          <div
            className="player path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath className={'path3'} points={['0,0', '20,12']} />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
