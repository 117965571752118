/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Proxyshell extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 2,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g stroke={color} strokeWidth={0} opacity={opacity} fill={color}>
          <path d="M22.3431 0H12C9.23858 0 7 2.23858 7 5V11H27.8C29.5673 11 31 12.4327 31 14.2V24.9722C32.2275 23.8736 33 22.277 33 20.5V9L25.1716 1.17157C24.4214 0.421427 23.404 0 22.3431 0Z" />
          <path d="M7 43H27.8C29.5673 43 31 41.5673 31 39.8V26.2453C32.8135 24.9804 34 22.8787 34 20.5V9.5L41.1116 1.94391C41.6785 1.34155 42.469 0.999999 43.2962 0.999999H56C58.2091 0.999999 60 2.79086 60 5V18.5L52 26.5H40C36.134 26.5 33 29.634 33 33.5V46L25.8787 53.1213C25.3161 53.6839 24.553 54 23.7574 54H11C8.79086 54 7 52.2091 7 50V43Z" />
          <path d="M55 54H44.6569C43.596 54 42.5786 53.5786 41.8284 52.8284L34 45V33.5C34 30.1863 36.6863 27.5 40 27.5H51L58.8284 35.3284C59.5786 36.0786 60 37.096 60 38.1569V49C60 51.7614 57.7614 54 55 54Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 12C1.34315 12 0 13.3431 0 15V39C0 40.6569 1.34315 42 3 42H27C28.6569 42 30 40.6569 30 39V15C30 13.3431 28.6569 12 27 12H3ZM20.5 18V21H12.5V25.5H20V29H12.5V33.5H21V37H8.5V18H20.5Z"
          />
        </g>
      </svg>
    );
  }
}
