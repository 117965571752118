/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Gear extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 18,
      x = 0,
      y = 0,
      opacity = 0.4,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.99828 12.0002C7.34415 12.0002 5.99828 10.6543 5.99828 9.00021C5.99828 7.34609 7.34415 6.00022 8.99828 6.00022C10.6524 6.00022 11.9983 7.34609 11.9983 9.00021C11.9983 10.6543 10.6524 12.0002 8.99828 12.0002ZM17.625 7.5H15.4838C15.3341 6.9075 15.1429 6.24975 14.9003 5.75138L16.4246 4.22662C16.4947 4.1565 16.5345 4.06125 16.5345 3.96187C16.5345 3.86212 16.4947 3.76688 16.4246 3.69638L14.3029 1.57538C14.1562 1.42875 13.9189 1.42875 13.7726 1.57538L12.2479 3.09975C11.7491 2.85675 11.0918 2.66587 10.5 2.51662V0.375C10.5 0.167625 10.332 0 10.125 0H7.875C7.668 0 7.5 0.167625 7.5 0.375V2.51662C6.9075 2.66587 6.25012 2.85675 5.75175 3.09975L4.22662 1.57538C4.08038 1.42875 3.843 1.42875 3.69638 1.57538L1.575 3.69638C1.42875 3.843 1.42875 4.08038 1.575 4.22662L3.09975 5.75175C2.85787 6.24863 2.66625 6.90675 2.5155 7.5H0.375C0.168 7.5 0 7.66762 0 7.875V10.125C0 10.3324 0.168 10.5 0.375 10.5H2.5155C2.66625 11.0933 2.85787 11.7514 3.09975 12.2482L1.57463 13.7734C1.5045 13.8435 1.46475 13.9388 1.46475 14.0381C1.46475 14.1379 1.5045 14.2331 1.57463 14.3036L3.69638 16.4246C3.83738 16.5656 4.08637 16.5653 4.22662 16.4246L5.751 14.8995C6.24863 15.1421 6.90675 15.3337 7.5 15.4841V17.625C7.5 17.8324 7.668 18 7.875 18H10.125C10.332 18 10.5 17.8324 10.5 17.625V15.4841C11.0929 15.3337 11.751 15.1421 12.2479 14.9003L13.773 16.4246C13.9196 16.5712 14.157 16.5712 14.3036 16.4246L16.4246 14.3036C16.5712 14.157 16.5712 13.9196 16.4246 13.7734L14.9003 12.2482C15.1421 11.7514 15.3337 11.0929 15.4841 10.5H17.625C17.832 10.5 18 10.3324 18 10.125V7.875C18 7.66762 17.832 7.5 17.625 7.5Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
