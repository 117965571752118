/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Loading extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 40,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 4,
      delay = 0,
      duration = 1000,
      dash = '15 50',
    } = this.props;

    let center = size / 2;
    let radius = center - strokeWidth / 2;

    // Get circumfrance
    let cir = 2 * Math.PI * radius;

    // Begin at a different time
    let begin = parseInt(delay).toString() + 'ms';

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g strokeWidth={strokeWidth} opacity={opacity}>
          <circle
            strokeOpacity={0.2}
            stroke={'#ffffff'}
            cx={center}
            cy={center}
            r={radius}
          />
          <circle
            strokeOpacity={1}
            cx={center}
            cy={center}
            r={radius}
            stroke={color}
            strokeDasharray={dash}
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur={parseInt(duration).toString() + 'ms'}
              from={`0 ${center} ${center}`}
              to={`360 ${center} ${center}`}
              repeatCount="indefinite"
              begin={begin}
            />
          </circle>
        </g>
      </svg>
    );
  }
}
