/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class USAScanner extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#ffffff',
      size = 37,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={`USAScanner ${className}`}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 37 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          className={'square'}
          stroke={color}
          strokeWidth={'1'}
          x="4.5"
          y="4.5"
          width="28"
          height="28"
          fillOpacity={opacity}
        />
        <path
          className={'corner'}
          fill={color}
          fillOpacity={opacity}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.13696 0H0V0.359985V1V8.28003H1V1H8.13696V0Z"
          fillOpacity={opacity}
        />
        <path
          className={'corner'}
          fill={color}
          fillOpacity={opacity}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 28H0V35.64V36V36.64H8V35.64H1V28Z"
          fillOpacity={opacity}
        />
        <path
          className={'corner'}
          fill={color}
          fillOpacity={opacity}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36 0H28.863V1H36V8H37V0H36.863H36Z"
          fillOpacity={opacity}
        />
        <path
          className={'corner'}
          fill={color}
          fillOpacity={opacity}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M37 29H36V36H29V37H36H37V36V29Z"
          fillOpacity={opacity}
        />
        <path
          className={'dot'}
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.5 15C20.4329 15 22 16.5671 22 18.5C22 20.4329 20.4329 22 18.5 22C16.5671 22 15 20.4329 15 18.5C15 16.5671 16.5671 15 18.5 15Z"
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
