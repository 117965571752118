/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import {
  getColor,
  activate,
  deactivate,
  shimmer,
  activateShimmer,
} from '../helpers';
import './reveal.scss';
import anime from 'animejs';
import ReactDOM from 'react-dom';

// Default icon is a computer
import { Computer } from '../svg/computer';
@observer
export class PlayerReveal extends Component {
  defaults = {};

  // Pixel presets, can be overridden
  pixels = {
    total: 14,
    columns: 14,
    color: '#50C2E3',
    spacing: 2,
    margin: 2,
    border: 4,
    wait: 50, // Miliseconds between each pixel's movement
  };

  // Store the actual pixel boxes
  pixelBoxes = [];

  width = 0;
  height = 0;
  innerHeight = 0;
  innerWidth = 0;
  size = 0;
  rows = 0;
  delay = 1000;
  animation = null;
  iconSize = 0;
  icon = null; // Node for the icon
  screen = null; // Node for the screen
  self = null;
  alert = false;
  complete = null; // Completion callback
  autostart = true;
  _finished = false;

  // Array to store the pixel positions
  positions = [];

  // Have just one interval instead of one for each box
  pixelAnimation = null;

  componentDidMount() {
    this.self = ReactDOM.findDOMNode(this);
    this.icon.style.height = '0px';
    this.screen.style.height = '0px';
    if (this.autostart) {
      this.animation = setTimeout(() => this.animatePixels(), this.delay);
    }
  }

  getID() {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, '')
      .substr(2, 10);
  }

  /*
  Create the pixel elements
  */
  drawPixels() {
    return this.pixelBoxes.map((box, index) => {
      let style = {
        width: (this.size - this.pixels.spacing).toString() + 'px',
        height: (this.size - this.pixels.spacing).toString() + 'px',
        background: this.pixels.color,
        opacity: box.opacity,
        left: box.x + 'px',
        top: box.y + 'px',
      };
      return (
        <span
          className="pixelBox"
          style={style}
          ref={node => (this.pixelBoxes[index].node = node)}
          key={index}
        ></span>
      );
    });
  }

  animatePixels() {
    this.pixelAnimation = setInterval(() => {
      for (let i = 0; i < this.pixelBoxes.length; i++) {
        this.animatePixel(i);
      }
    }, this.pixels.wait);
  }

  revealIcon(pixel) {
    if (!pixel.first) return;
    this.icon.style.height = pixel.y.toString() + 'px';
    this.screen.style.height = pixel.y.toString() + 'px';
  }

  /*
  Animate pixels going down the page
  */
  animatePixel(i) {
    let pixel = this.pixelBoxes[i];
    if (!pixel.node) return;
    if (pixel.direction == 'forward') {
      pixel.column += 1;
      if (pixel.column >= this.pixels.columns) {
        pixel.column = this.pixels.columns;
        pixel.direction = 'backward';
        pixel.y += pixel.size;
        this.revealIcon(pixel);
      }
    }
    if (pixel.direction == 'backward') {
      pixel.column -= 1;
      if (pixel.column < 0) {
        pixel.column = 0;
        pixel.direction = 'forward';
        pixel.y += pixel.size;
        this.revealIcon(pixel);
      }
    }
    pixel.x = pixel.column * pixel.size;
    pixel.node.style.left = pixel.x.toString() + 'px';
    pixel.node.style.top = pixel.y.toString() + 'px';
    if (pixel.y > pixel.height) {
      // Clear the pixel animation when the last pixel clears the bottom
      if (pixel.last) clearInterval(this.pixelAnimation);
      // Show the full image when the first pixel reaches the bottom
      if (pixel.first) this.finalReveal(pixel);
    }
  }

  finalReveal(pixel) {
    // Ensure it only runs once
    if (this._finished) return;
    this.screen.style.opacity = 0;
    // Turn the border red
    if (this.alert) this.self.style.borderColor = getColor('red');
    if (typeof this.complete === 'function') {
      this.complete();
    }
    this._finished = true;
  }

  /*
  Generate all the pixels that will travel down the page
  until the icon is revealed
  */
  generatePixels() {
    // Calculate total columns and rows
    let width = this.width - this.pixels.border * 2 - this.pixels.margin * 2;
    let height = this.height - this.pixels.border * 2 - this.pixels.margin * 2;
    this.size =
      width / this.pixels.columns + this.pixels.spacing / this.pixels.columns;
    this.rows = height / this.size;

    // Create each pixel and starting positions
    for (let i = 0; i < this.pixels.total; i++) {
      this.pixelBoxes[i] = {
        column: (i + 1) * -1,
        x: i * (this.size * -1) - this.size, // Start just outside view
        y: 0,
        node: null,
        timer: null,
        width: width,
        height: height,
        size: this.size,
        direction: 'forward',
        first: i == 0 ? true : false,
        last: i == this.pixels.total - 1 ? true : false,
        opacity: parseFloat(
          parseFloat((this.pixels.total - i) * (1 / this.pixels.total)).toFixed(
            2
          )
        ),
      };
    }
    return this.drawPixels();
  }

  render() {
    const {
      children,
      width = 160,
      height = 160,
      delay = 1000, // Miliseconds before animation starts
      className = '',
      IconComponent = Computer,
      IconColor = '#ffffff',
      alert = false,
      IconOpacity = 1,
      IconScale = 0.5,
      IconSize = false,
      text = false,
      pixels = {},
      styles = {},
      complete = null,
    } = this.props;

    // Assign properties to pixels
    Object.assign(this.pixels, pixels);

    // Store dimensions
    this.width = width;
    this.height = height;
    this.delay = delay;
    this.alert = alert;
    this.complete = complete;

    // Styles
    let wrapperStyles = {
      width: this.width.toString().replace(/px/g, '') + 'px',
      height: this.height.toString().replace(/px/g, '') + 'px',
    };
    Object.assign(wrapperStyles, styles);

    // Inner dimensions
    this.innerWidth =
      this.width - this.pixels.margin * 2 - this.pixels.border * 2;
    this.innerHeight =
      this.height - this.pixels.margin * 2 - this.pixels.border * 2;

    const pixelStyles = {
      width: this.innerWidth.toString() + 'px',
      height: this.innerHeight.toString() + 'px',
      left: (this.pixels.margin + this.pixels.border).toString() + 'px',
      top: (this.pixels.margin + this.pixels.border).toString() + 'px',
    };

    // IconSize is default half of the width
    let iconSize = IconSize ? IconSize : this.width * IconScale;

    // Based on scale
    let iconStyles = {
      top: ((this.innerHeight - iconSize) / 2).toString() + 'px',
    };
    let textStyles = {
      top:
        (height - this.pixels.border - this.pixels.margin - 40).toString() +
        'px',
    };

    // If there is a label
    if (text) {
      iconStyles.top =
        ((this.innerHeight - iconSize) / 2 - 15).toString() + 'px';
    }

    return (
      <div className={`Player__Revealer ${className}`} style={wrapperStyles}>
        <div
          className={`Player__Revealer__Icon`}
          style={pixelStyles}
          ref={node => (this.icon = node)}
        >
          <span className={`svg`} style={iconStyles}>
            <IconComponent
              size={iconSize}
              color={IconColor}
              opacity={IconOpacity}
            />
          </span>
          {text && (
            <div className="Player__Revealer__Icon__Text" style={textStyles}>
              <div className="line"></div>
              <div className="label">{text}</div>
            </div>
          )}
        </div>
        <div
          className={`Player__Revealer__Screen`}
          style={pixelStyles}
          ref={node => (this.screen = node)}
        ></div>
        <div className={'Player__Revealer__Pixels'} style={pixelStyles}>
          {this.generatePixels()}
        </div>
      </div>
    );
  }
}
