/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { getColor } from '../components/helpers';
import { AnimatedSlide } from '../components/animated-slide';

// SCSS
import './global-scan-info.scss';

// Players
import { PlayerInfoBlock } from '../components/players/info-block';
import { PlayerPath } from '../components/players/path';

// SVG
import { World } from '../components/svg/world';

@observer
export class Template__GlobalScanInfo extends AnimatedSlide {
  // Animation players
  players = {
    world: null,
    info: null,
  };

  sections = [
    {
      type: 'header',
      color: 'blue',
      content: 'Information Section',
    },
  ];

  componentWillUnmount() {
    // Unmount
  }

  // Run the first animation
  componentDidMount() {
    const { world, info } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [this.fadeIn(world, 0), this.fadeIn(info, 500)],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '', sections = null } = this.props;

    // Let the user pass in sections
    if (Array.isArray(sections)) {
      this.sections = sections;
    }

    return (
      <div className={`Template__GlobalScanInfo ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}

          {/* World */}
          <div
            className="player world"
            data-active="0"
            ref={node => (this.players.world = node)}
          >
            <World size={800} animation={{ type: 'scan' }} />
          </div>

          {/* Information block */}
          <div
            className="player info"
            data-active="0"
            ref={node => (this.players.info = node)}
          >
            <PlayerInfoBlock
              gap={10}
              width={310}
              animation={{
                delay: 500,
                increment: 1000,
              }}
              sections={this.sections}
            />
          </div>

          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
