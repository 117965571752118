/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { Router, RouteManager } from 'vega-router';
import { ComplianceAlignmentSummary } from './summary/summary';
import { ComplianceAlignmentDetail } from './detail/detail';
import {
  ComplianceAlignmentState,
  ComplianceAlignmentActions,
} from './compliance-alignment-actions';
import { AppActions } from '../app-actions';
import { HamburgerIcon } from '../svg/hamburger-icon';
import { EsentireIcon } from '../svg/esentire-icon';
import { ChartDataStore } from '../../data/compliance-alignment-data-transform';
import './compliance-alignment.scss';

@observer
export class ComplianceAlignment extends Router {
  constructor(props) {
    super(props);

    this.addRoute('detail', /^\/compliance\/detail/, ComplianceAlignmentDetail);

    this.contentFrame = null;
    this.contentFrameContainer = null;

    // Must be last
    this.addRoute('summary', /^\/(.*)/, ComplianceAlignmentSummary);
  }

  componentDidMount() {
    AppActions.setShortcutName('Compliance Alignment Tool');
    AppActions.hideBackgroundGrid();

    ComplianceAlignmentActions.getComplianceAlignmentData();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
  }

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`ComplianceAlignment ${className}`}>
        <div className="ComplianceAlignment__MainMenu">
          <button
            className="HamburgerButton"
            onClick={() => RouteManager.transitionTo('/')}
          >
            <HamburgerIcon color="#fafafa" opacity={0.7} />
          </button>
        </div>
        <div className="ComplianceAlignment__Logo">
          <EsentireIcon />
        </div>
        <div className="ComplianceAlignment__Title">
          {ComplianceAlignmentState.title}
        </div>
        <div className="ComplianceAlignment__Subtitle capitalize">
          {ComplianceAlignmentState.subtitle}
        </div>
        {ChartDataStore.loadingComplianceAlignmentData ? (
          <div className="ComplianceAlignment__Middle__Loader">
            <div className="ComplianceAlignment__Middle__Spinner" />
            <div className="ComplianceAlignment__Middle__LoaderLabel">
              Loading Data
            </div>
          </div>
        ) : (
          <div className="ComplianceAlignment__Middle FadeIn">
            {super.render()}
          </div>
        )}
      </div>
    );
  }
}
