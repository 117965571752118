/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './map-blip.scss';

@observer
export class PlayerMapBlip extends Component {
  componentDidMount() {}

  render() {
    // Get parameters
    const { className = '', size = 15, color = '#50C2E3' } = this.props;

    // Get width & height
    let width = size.toString().replace(/px/g, '') + 'px';
    let height = size.toString().replace(/px/g, '') + 'px';

    return (
      <div
        className={`Player__MapBlip ${className}`}
        style={{ width: width, height: height, background: color }}
      >
        <span
          className="Player__MapBlip__Ring"
          style={{
            width: width,
            height: height,
            borderColor: color,
          }}
        ></span>
        <span
          className="Player__MapBlip__Ring"
          style={{
            width: width,
            height: height,
            borderColor: color,
          }}
        ></span>
        <span
          className="Player__MapBlip__Ring"
          style={{
            width: width,
            height: height,
            borderColor: color,
          }}
        ></span>
        <span
          className="Player__MapBlip__Ring"
          style={{
            width: width,
            height: height,
            borderColor: color,
          }}
        ></span>
      </div>
    );
  }
}
