/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './barrier.scss';
import anime from 'animejs';
import ReactDOM from 'react-dom';
import { PlayerDots } from './dots';

@observer
export class PlayerBarrier extends Component {
  defaults = {
    color: '#50C2E3',
    width: 160,
    height: 300,
  };

  componentDidMount() {
    const {} = this.props;
  }

  render() {
    const {
      children,
      color = this.defaults.color,
      width = this.defaults.width,
      height = this.defaults.height,
      className = '',
    } = this.props;

    return (
      <div
        className={`Player__Barrier ${className}`}
        style={{ width: width + 'px', height: height + 'px' }}
      >
        <PlayerDots
          color={color}
          width={width}
          dots={10}
          reverse={true}
          className={'left-to-right'}
        />
        <div className={'Player__Barrier__Particles'}>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
          <div>
            <span></span>
          </div>
        </div>
        <div className={'Player__Barrier__Shield shield1'}></div>
        <div className={'Player__Barrier__Shield shield2'}></div>
      </div>
    );
  }
}
