/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import './button.scss';

@observer
export class Button extends Component {
  render() {
    const {
      children,
      className = '',
      onClick,
      type = 'button',
      icon: Icon,
      invert = false,
    } = this.props;

    const iconClass = Icon ? ' Button--with-icon' : '';
    const invertedClass = invert ? ' Button--inverted' : '';

    return (
      <button
        type={type}
        className={`Button ${className}${iconClass}${invertedClass}`}
        onClick={onClick}
      >
        {Icon && <Icon color="#50C2E3" size={13} />}
        {children}
      </button>
    );
  }
}
