/** @jsx createElement */
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { Router } from 'vega-router';
import { SOCCalculatorState } from '../soc-calculator-actions';
import { SOCCalculatorChart } from '../../charts/soc-calculator-chart';
import './graph.scss';

@observer
export class SOCCalculatorGraph extends Router {
  static HORIZONTAL_PADDING = 210;
  static VERTICAL_PADDING = 318;

  @observable height = 540;
  @observable width = 520;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
    setTimeout(this.handleResize, 500);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
  }

  handleOrientationChange = () => {
    setTimeout(this.handleResize, 500);
  };

  handleResize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    this.width = Math.max(
      0,
      windowWidth - SOCCalculatorGraph.HORIZONTAL_PADDING
    );
    this.height = Math.max(
      0,
      windowHeight - SOCCalculatorGraph.VERTICAL_PADDING
    );
  };

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`SOCCalculatorGraph ${className}`}>
        <SOCCalculatorChart
          className="SOCCalculatorGraph__Chart"
          width={this.width}
          height={this.height}
          data={[
            {
              label: 'Year 1',
              intelligence: SOCCalculatorState.year1SecurityTools,
              staffing: SOCCalculatorState.year1Personnel,
              tools: SOCCalculatorState.year1OperationTools,
              maintenance:
                SOCCalculatorState.year1MaintenanceLabor +
                SOCCalculatorState.year1MaintenanceHardware +
                SOCCalculatorState.year1Implementation,
              total: SOCCalculatorState.year1InHouse,
              esentire: SOCCalculatorState.year1EsentireCost,
            },
            {
              label: 'Year 2',
              intelligence: SOCCalculatorState.year1SecurityTools,
              staffing: SOCCalculatorState.year1Personnel,
              tools: SOCCalculatorState.year1OperationTools,
              maintenance:
                SOCCalculatorState.year1MaintenanceLabor +
                SOCCalculatorState.year1MaintenanceHardware,
              total: SOCCalculatorState.year2InHouse,
              esentire: SOCCalculatorState.year2EsentireCost,
            },
            {
              label: 'Year 3',
              intelligence: SOCCalculatorState.year1SecurityTools,
              staffing: SOCCalculatorState.year1Personnel,
              tools: SOCCalculatorState.year1OperationTools,
              maintenance:
                SOCCalculatorState.year1MaintenanceLabor +
                SOCCalculatorState.year1MaintenanceHardware,
              total: SOCCalculatorState.year2InHouse,
              esentire: SOCCalculatorState.year3EsentireCost,
            },
          ]}
        />
        <div className="SOCCalculatorGraph__Legend">
          <div className="SOCCalculatorGraph__LegendItem">
            <div className="SOCCalculatorGraph__LegendColor SOCCalculatorGraph__LegendColor--orange" />
            <div className="SOCCalculatorGraph__LegendLabel SOCCalculatorGraph__LegendLabel--orange">
              Technology &amp; Threat Intelligence
            </div>
          </div>
          <div className="SOCCalculatorGraph__LegendItem">
            <div className="SOCCalculatorGraph__LegendColor SOCCalculatorGraph__LegendColor--blue" />
            <div className="SOCCalculatorGraph__LegendLabel SOCCalculatorGraph__LegendLabel--blue">
              Staffing
            </div>
          </div>
          <div className="SOCCalculatorGraph__LegendItem">
            <div className="SOCCalculatorGraph__LegendColor SOCCalculatorGraph__LegendColor--green" />
            <div className="SOCCalculatorGraph__LegendLabel SOCCalculatorGraph__LegendLabel--green">
              Operational Tools
            </div>
          </div>
          <div className="SOCCalculatorGraph__LegendItem">
            <div className="SOCCalculatorGraph__LegendColor SOCCalculatorGraph__LegendColor--yellow" />
            <div className="SOCCalculatorGraph__LegendLabel SOCCalculatorGraph__LegendLabel--yellow">
              Implementation &amp; Maintenance
            </div>
          </div>
          <div className="SOCCalculatorGraph__LegendItem SOCCalculatorGraph__LegendItem--black">
            <div className="SOCCalculatorGraph__LegendColor SOCCalculatorGraph__LegendColor--red" />
            <div className="SOCCalculatorGraph__LegendLabel SOCCalculatorGraph__LegendLabel--red">
              Esentire
            </div>
          </div>
        </div>
      </div>
    );
  }
}
