/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Skull } from '../svg/skull';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';

@observer
export class Slide15 extends AnimatedSlide {
  // Animation players
  players = {
    user: null,
    aws: null,
    detection: null,
  };

  // Run the first animation
  componentDidMount() {
    const { user, aws, detection } = this.players;

    this.steps = [
      () => {
        this.animateProgress(4000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: user,
          opacity: 1,
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: aws,
          opacity: 1,
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },
        {
          targets: detection,
          opacity: 1,
          duration: 200,
          delay: 2000,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-15 ${className}`}>
        {children}
        <div className="content">
          {/* AWS ACCESS INFO */}
          <div
            className="player aws"
            data-active="0"
            ref={node => (this.players.aws = node)}
          >
            <PlayerTextBlock
              title={'AWS ACCESS INFO'}
              width={'250px'}
              showDots={true}
              className={''}
              sections={[
                {
                  title: ['LOGIN', '0'],
                  grid: 2,
                },
              ]}
            />
          </div>

          {/* DETECTION SETTINGS */}
          <div
            className="player detection"
            data-active="0"
            ref={node => (this.players.detection = node)}
          >
            <PlayerTextBlock
              title={'DETECTION SETTINGS'}
              width={'250px'}
              showDots={false}
              className={''}
              sections={[
                {
                  title: ['FREQ', 'REAL TIME', 'LOGINS', '> 0'],
                  grid: 2,
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* User */}
          <div
            className="player user"
            data-active="0"
            ref={node => (this.players.user = node)}
          >
            <Card IconComponent={Skull} IconSize={60} text={'EV1L-US3R'} />
          </div>
        </div>
      </div>
    );
  }
}
