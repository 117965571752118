/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerDots } from '../players/dots';
import { Badge } from '../badge';

// Icons
import { DiskDrive } from '../svg/disk-drive';
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';

@observer
export class Slide20 extends AnimatedSlide {
  // Animation players
  players = {
    drive: null,
    dotsLeft: null,
    dotsRight: null,
    computerLeft: null,
    computerRight: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      drive,
      dotsLeft,
      dotsRight,
      computerLeft,
      computerRight,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: drive,
          opacity: 1,
          delay: 0,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: computerLeft,
          opacity: 1,
          delay: 500,
          translateX: [100, 0],
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: computerRight,
          opacity: 1,
          delay: 500,
          translateX: [-100, 0],
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: dotsLeft,
          opacity: 1,
          delay: 800,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: dotsRight,
          opacity: 1,
          delay: 800,
          duration: 200,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-20 ${className}`}>
        {children}
        <div className="content">
          {/* Disk drive */}
          <div
            className="player drive"
            data-active="0"
            ref={node => (this.players.drive = node)}
          >
            <DiskDrive />
          </div>

          {/* Dots left */}
          <div
            className="player dots-left"
            data-active="0"
            ref={node => (this.players.dotsLeft = node)}
          >
            <PlayerDots className={'reverse-direction'} />
          </div>

          {/* Dots right */}
          <div
            className="player dots-right"
            data-active="0"
            ref={node => (this.players.dotsRight = node)}
          >
            <PlayerDots className={''} />
          </div>

          {/* Computer left */}
          <div
            className="player computer-left"
            data-active="0"
            ref={node => (this.players.computerLeft = node)}
          >
            <Card IconComponent={Computer} text={'118.114.77.92'} />
          </div>

          {/* Computer Right */}
          <div
            className="player computer-right"
            data-active="0"
            ref={node => (this.players.computerRight = node)}
          >
            <Card IconComponent={Computer} text={'192.168.10.27'} />
          </div>
        </div>
      </div>
    );
  }
}
