/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// Players
import { PlayerIconLoad } from '../players/icon-load';

// SVG
import { Bug } from '../svg/bug';
import { Loading } from '../svg/loading';
import { Check } from '../svg/check';
import { Person } from '../svg/person';
import { Thumb } from '../svg/thumb';

@observer
export class Slide14 extends AnimatedSlide {
  // Create a ref to the message component
  children = {
    icon: createRef(),
  };

  // Animation players
  players = {
    calendar: null,
    grower1: null,
    grower2: null,
    event1: null,
    event2: null,
    event3: null,
    event4: null,
    label1: null,
    label2: null,
    label3: null,
    loading1: null,
    loading2: null,
    customer: null,
    month1: null,
    month2: null,
    month3: null,
    month4: null,
    month5: null,
  };

  fadeIn(target, delay) {
    return {
      targets: target,
      opacity: 1,
      easing: 'easeOutQuad',
      delay: delay,
      duration: 500,
      begin: activate,
      complete: activateShimmer,
    };
  }

  fadeOut(target, delay) {
    return {
      targets: target,
      opacity: 0,
      easing: 'easeOutQuad',
      delay: delay,
      duration: 500,
      begin: shimmer,
      complete: deactivate,
    };
  }

  changeIcon() {
    if (!this.children.icon.current) return;
    this.children.icon.current.changeIcon();
  }

  // Run the first animation
  componentDidMount() {
    const {
      calendar,
      grower1,
      grower2,
      event1,
      event2,
      event3,
      event4,
      label1,
      label2,
      label3,
      loading1,
      loading2,
      customer,
      month1,
      month2,
      month3,
      month4,
      month5,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(9000);
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(9000);
      },
      // Step 3
      () => {
        this.changeIcon();
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [this.fadeIn(calendar, 200), this.fadeIn(customer, 800)],

      //
      // Step 2
      //
      [
        // grow timelines
        {
          targets: [grower1, grower2],
          width: ['0%', '82%'],
          easing: 'linear',
          delay: 0,
          duration: 8000,
        },
        // Turn off first loader
        this.fadeOut(loading1, 500),
        // First event
        {
          targets: event1,
          width: [0, 30],
          easing: 'linear',
          delay: 0,
          duration: 500,
        },
        this.fadeIn(label1, 1000),
        {
          targets: event2,
          width: [0, 30],
          easing: 'linear',
          delay: 100,
          duration: 500,
        },
        {
          targets: event3,
          width: [0, 30],
          easing: 'linear',
          delay: 7800,
          duration: 500,
        },
        this.fadeIn(label2, 9000),
        {
          targets: event4,
          width: [0, 30],
          easing: 'linear',
          delay: 7400,
          duration: 500,
        },
        this.fadeIn(label3, 8500),
        this.fadeOut(loading2, 8500),
      ],

      //
      // Step 3
      //
      [
        // Do nothing
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-14 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TIMELINE */}
          <div
            className="player calendar"
            data-active="0"
            ref={node => (this.players.calendar = node)}
          >
            <div className="months">
              <div className="month" ref={node => (this.players.month1 = node)}>
                NOV 1
              </div>
              <div className="month" ref={node => (this.players.month2 = node)}>
                NOV 2
              </div>
              <div className="month" ref={node => (this.players.month3 = node)}>
                NOV 3
              </div>
              <div className="month" ref={node => (this.players.month4 = node)}>
                NOV 4
              </div>
              <div className="month" ref={node => (this.players.month5 = node)}>
                NOV 5
              </div>
            </div>
            <div className="timelines">
              <div className="timeline timeline1">
                <label>THREAT DETECTED</label>
                <div
                  className="event bug"
                  ref={node => (this.players.event1 = node)}
                >
                  <Bug size={12} color={this.colors.white} />
                </div>
              </div>
              <div className="timeline timeline2">
                <label>ESENTIRE</label>
                <div
                  className="grower"
                  ref={node => (this.players.grower1 = node)}
                ></div>
                <div
                  className="loading"
                  ref={node => (this.players.loading1 = node)}
                >
                  <Loading size={20} />
                </div>
                <div
                  className="event check event1"
                  ref={node => (this.players.event2 = node)}
                >
                  <Check size={12} color={this.colors.black} />
                </div>
                <div
                  className="event check event2"
                  ref={node => (this.players.event3 = node)}
                >
                  <Check size={12} color={this.colors.black} />
                </div>
                <div
                  className="player label label1"
                  ref={node => (this.players.label1 = node)}
                  data-active="0"
                >
                  Customer notified and threat remediated
                </div>
                <div
                  className="player label label2"
                  ref={node => (this.players.label2 = node)}
                  data-active="0"
                >
                  Retroactive threat hunt executed
                </div>
              </div>
              <div className="timeline timeline3">
                <label>COMPETITOR & FBI</label>
                <div
                  className="grower"
                  ref={node => (this.players.grower2 = node)}
                ></div>
                <div
                  className="loading"
                  ref={node => (this.players.loading2 = node)}
                >
                  <Loading size={20} />
                </div>
                <div
                  className="event check"
                  ref={node => (this.players.event4 = node)}
                >
                  <Check size={12} color={this.colors.black} />
                </div>
                <div
                  className="player label"
                  ref={node => (this.players.label3 = node)}
                  data-active="0"
                >
                  Sends tip to customer
                </div>
              </div>
            </div>
          </div>
          {/* TIMELINE */}
          <div
            className="player customer"
            data-active="0"
            ref={node => (this.players.customer = node)}
          >
            <PlayerIconLoad
              strokeWidth={2}
              start={{
                IconComponent: Person,
                stroke: this.colors.blue,
                opacity: 1,
              }}
              size={100}
              end={{
                IconComponent: Thumb,
                stroke: this.colors.green,
              }}
              ref={this.children.icon}
            />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
