/** @jsx createElement */
// SVG
import { Page } from '../svg/page';
import { Alert } from '../svg/alert';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

@observer
export class Slide15 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    page: null,
    alert: null,
    status: null,
    file: null,
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');

    const { donut, page, alert, status, file, border } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(5000);
        donut.classList.remove('red');
        this.autoNext(2000);
      },
      // Step 2
      () => {
        donut.classList.add('red');
        this.autoNext(2000);
      },
      // Step 3
      () => {
        this.animations[1][0].delay = 0; // Remove alert delay
        this.animations[1][1].delay = 0; // Remove border delay
        this.animations[1][2].delay = 0; // Remove page delay
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        { targets: page, opacity: 1, duration: 1000, begin: activate },

        // Deactivate
        { targets: alert, opacity: 0, duration: 500, complete: deactivate },
        { targets: border, opacity: 0, duration: 500, delay: 0 },
      ],

      //
      // Step 2
      //
      [
        {
          targets: alert,
          opacity: 1,
          duration: 500,
          delay: 1000,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: 1,
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          delay: 1000,
          easing: 'easeInOutSine',
        },

        // Dim stuff behind the alert
        {
          targets: page,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 1000,
        },

        // Deactivate
        {
          targets: status,
          easing: 'easeOutQuad',
          opacity: 0,
          translateX: 50,
          duration: 500,
          complete: deactivate,
        },
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 0,
          translateX: -50,
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: status,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: 0,
          delay: 0,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: 0,
          delay: 500,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`SandboxFindings ${className}`}>
        {children}
        <div className="content">
          {/* Status */}
          <div
            className="player status"
            data-active="0"
            ref={node => (this.players.status = node)}
          >
            <PlayerTextBlock
              title="Status"
              width={'280px'}
              className={'red-top'}
              sections={[
                {
                  title: [
                    'STATUS',
                    'MALICIOUS',
                    'REPEAT',
                    '100 / 100',
                    'SCAN',
                    '44%',
                  ],
                  grid: 2,
                  color: 'red',
                  colorCells: 4,
                },
              ]}
            />
          </div>

          {/* File mod */}
          <div
            className="player file"
            data-active="0"
            ref={node => (this.players.file = node)}
          >
            <PlayerTextBlock
              title="File mod"
              width={'280px'}
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert title="Confirmed" subtitle="Win32.Emotet Trojan" />
          </div>

          {/* Page */}
          <div
            className="player page"
            data-active="1"
            ref={node => (this.players.page = node)}
          >
            <Page size={'100'} />
          </div>

          {/* Donut */}
          <div
            className="player donut"
            data-active="1"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
