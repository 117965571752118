/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Code } from '../svg/code';
import { USA } from '../svg/usa';

@observer
export class Slide11 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    analyzing: null,
    code: null,
    bro: null,
    suricata: null,
    geo: null,
    usa: null,
  };

  // Run the first animation
  componentDidMount() {
    const { donut, analyzing, code, bro, suricata, geo, usa } = this.players;

    this.steps = [
      // Step 1
      () => {
        // Do nothing
        this.animateProgress(3000);
        this.autoNext(2000);
      },
      // Step 2
      () => {
        let dotsMax = 130;
        let dots = document.querySelectorAll('.player.usa svg path');
        let delay = 20;
        for (let i = 0; i <= dotsMax; i++) {
          let patience = i * delay;
          dots[i].timeout = setTimeout(() => {
            dots[i].setAttribute('fill', '#50C2E3');
            dots[i].setAttribute('fill-opacity', '1');
          }, patience);
        }
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: bro,
          opacity: 1,
          delay: 0,
          duration: 200,
          delay: 500,
          begin: activateShimmer,
        },
        {
          targets: suricata,
          opacity: 1,
          delay: 0,
          duration: 200,
          delay: 700,
          begin: activateShimmer,
        },
        {
          targets: geo,
          opacity: 1,
          delay: 0,
          duration: 200,
          delay: 900,
          begin: activateShimmer,
        },
        {
          targets: usa,
          opacity: 1,
          delay: 0,
          duration: 200,
          delay: 1100,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    const lines2 = {
      title: 'IP GEO DATA',
      sections: [
        {
          lines: 2,
          lineColumns: 2,
        },
      ],
    };

    const lines3 = {
      title: 'SURICATA - COMPLETE',
      sections: [
        {
          lines: 3,
          lineColumns: 2,
        },
      ],
    };

    const lines4 = {
      title: 'BRO - COMPLETE',
      sections: [
        {
          lines: 4,
          lineColumns: 2,
        },
      ],
    };

    return (
      <div className={`slide-11 ${className}`}>
        {children}
        <div className="content">
          {/* USA */}
          <div
            className="player usa"
            data-active="0"
            ref={node => (this.players.usa = node)}
          >
            <USA />
          </div>

          {/* Text block */}
          <div
            className="player geo"
            data-active="0"
            ref={node => (this.players.geo = node)}
          >
            <PlayerTextBlock {...lines2} />
          </div>

          {/* Text block */}
          <div
            className="player suricata"
            data-active="0"
            ref={node => (this.players.suricata = node)}
          >
            <PlayerTextBlock {...lines3} />
          </div>

          {/* Text block */}
          <div
            className="player bro"
            data-active="0"
            ref={node => (this.players.bro = node)}
          >
            <PlayerTextBlock {...lines4} />
          </div>

          {/* Code */}
          <div
            className="player code"
            data-active="1"
            ref={node => (this.players.code = node)}
          >
            <Code size={'45'} />
          </div>

          {/* Donut */}
          <div
            className="player donut"
            data-active="1"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
