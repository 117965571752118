/** @jsx createElement */
import { observer, renderReporter } from 'mobx-react';
import { findDOMNode, createElement, Component, HTMLAttributes } from 'react';
import { uid, getColor } from '../helpers';
import './box-scanner.scss';
import anime from 'animejs';

@observer
export class PlayerBoxScanner extends Component {
  target = {
    width: 160,
    height: 40,
  };

  // Animation timing
  boxInterval = 600;

  // Store all corner references
  containers = [];
  corners = {};
  boxes = {};
  circles = {};
  circleContainers = [];
  crosshairContainers = [];
  crosshairs = [];

  // Keep track of total border width as it builds
  borderWidth = 0;
  totalObjects = 0;
  delayTimer = null;

  // Default boxes
  defaultBoxes = [
    {
      border: 8,
      offset: 20,
      corner: 0.5,
      opacity: 0.2,
    },
    {
      border: 10,
      offset: 40,
      corner: 0.4,
      opacity: 0.15,
    },
    {
      border: 6,
      offset: 60,
      corner: 0.2,
      opacity: 0.1,
    },
  ];
  defaultCircles = [
    {
      offset: 20,
      stroke: getColor('blue'),
      strokeWidth: 15,
      strokeDasharray: '2 4',
      close: 3000,
      delay: 500,
      rotate: -360,
      duration: 10000,
      opacity: 0.7,
    },
    {
      offset: 60,
      stroke: getColor('white'),
      strokeDasharray: '100 90',
      rotate: -360,
      strokeWidth: 4,
      duration: 2000,
      close: 3000,
      delay: 500,
      opacity: 0.5,
    },
    {
      offset: 60,
      stroke: getColor('white'),
      strokeWidth: 4,
      close: 3000,
      delay: 500,
      opacity: 0.1,
    },
    {
      offset: 90,
      stroke: getColor('blue'),
      strokeDasharray: '120 60',
      rotate: 360,
      strokeWidth: 12,
      duration: 5000,
      close: 3000,
      delay: 800,
      opacity: 0.1,
    },
    {
      offset: 90,
      stroke: getColor('blue'),
      strokeWidth: 12,
      close: 3000,
      delay: 900,
      opacity: 0.05,
    },
  ];
  defaultCrosshairs = [
    {
      offset: 120,
      length: 80,
      count: 4,
      stroke: getColor('white'),
      opacity: 0.2,
      strokeWidth: 1,
      delay: 0,
      close: 4000,
      duration: 1000,
    },
    {
      offset: 80,
      length: 20,
      count: 32,
      stroke: getColor('blue'),
      opacity: 0.2,
      strokeWidth: 1,
      delay: 200,
      close: 3900,
      duration: 1500,
    },
  ];

  animateCrosshairs() {
    async function fadeInContainer(container) {
      const fadeCrosshair = anime({
        targets: container,
        opacity: 1,
        scale: [0.7, 1],
        easing: 'easeOutQuad',
        delay: container.dataset.delay,
        duration: 500,
      }).finished;
      await Promise.all([fadeCrosshair]);
    }

    async function closeContainer(container, crosshairs) {
      const fadeCircle = anime({
        targets: container,
        opacity: 0,
        scale: [1, 0.5],
        easing: 'easeInOutQuint',
        delay: container.dataset.close,
        duration: 1000,
        complete: () => {
          anime.remove(crosshairs);
        },
      }).finished;
      await Promise.all([fadeCircle]);
    }

    async function pulseLines(container, crosshairs) {
      let interval = parseInt(container.dataset.interval);
      let duration = parseInt(container.dataset.duration);
      let direction = container.dataset.direction;
      let lineAnimations = [];
      if (direction == 'reverse') crosshairs.reverse();
      crosshairs.map((crosshair, i) => {
        setTimeout(() => {
          lineAnimations[i] = anime({
            targets: crosshair,
            opacity: [container.dataset.opacity, 1, container.dataset.opacity],
            duration: duration,
            easing: 'easeInOutQuad',
            loop: true,
          }).finished;
        }, i * interval);
      });
    }

    // Animation sequence for each box
    Object.keys(this.crosshairs).forEach((crosshairID, i) => {
      let crosshairs = this.crosshairs[crosshairID];
      let container = this.crosshairContainers[crosshairID];
      // Fade the crosshair container in and out
      fadeInContainer(container).then(() =>
        closeContainer(container, crosshairs)
      );
      // Animate each of the crosshairs individually
      pulseLines(container, crosshairs);
    });
  }

  animateCircles() {
    async function fadeInBox(container, circle) {
      const fadeCircle = anime({
        targets: container,
        opacity: 1,
        scale: [1.5, 1],
        easing: 'easeOutQuad',
        delay: circle.dataset.delay,
        duration: 500,
      }).finished;
      // Also start the dash moving
      if (circle.dataset.rotate) {
        anime({
          targets: circle,
          rotate: circle.dataset.rotate,
          duration: circle.dataset.duration,
          easing: circle.dataset.easing,
          loop: true,
        });
      }
      await Promise.all([fadeCircle]);
    }

    async function closeCircle(container, circle) {
      const fadeCircle = anime({
        targets: container,
        opacity: 0,
        scale: [1, 0.8],
        easing: 'easeInOutQuint',
        delay: circle.dataset.close,
        duration: 1000,
      }).finished;
      await Promise.all([fadeCircle]);
    }

    // Animation sequence for each box
    Object.keys(this.circles).forEach((circleID, i) => {
      let circle = this.circles[circleID];
      let container = this.circleContainers[circleID];
      fadeInBox(container, circle).then(() => closeCircle(container, circle));
    });
  }

  animateBoxes() {
    async function fadeInBox(container, delay = 0) {
      const fadeBox = anime({
        targets: container,
        opacity: 1,
        scale: [0.7, 1],
        easing: 'easeOutQuad',
        delay: delay,
        duration: 400,
      }).finished;
      await Promise.all([fadeBox]);
    }

    // easeInOutExpo, easeInOutQuint, easeOutElastic, easeOutCirc
    async function rotateBox(container) {
      const rotateAnimation = anime({
        targets: container,
        rotate: [45, 270, 360],
        duration: 1000,
        easing: 'easeInOutQuad',
      }).finished;
      await Promise.all([rotateAnimation]);
    }

    async function cornerFill(corners) {
      let cornerAnimations = [];
      corners.forEach((corner, i) => {
        cornerAnimations[i] = anime({
          targets: corner,
          points: [
            { value: corner.dataset.start },
            { value: corner.dataset.end },
          ],
          easing: 'easeInOutQuint',
          duration: 500,
        }).finished;
      });
    }

    async function squeeze(box) {
      let squeezeAnimation = anime({
        targets: box,
        d: [{ value: box.dataset.start }, { value: box.dataset.end }],
        duration: 1000,
        easing: 'easeInOutQuint',
      }).finished;
      await Promise.all([squeezeAnimation]);
    }

    async function glow(box) {
      let currentOpacity = box.getAttribute('opacity');
      let glowAnimation = anime({
        targets: box,
        opacity: [currentOpacity, 0.7, currentOpacity],
        duration: 2000,
        fill: getColor('blue'),
        easing: 'easeInOutQuad',
        direction: 'alternate',
        loop: true,
      }).finished;
      await Promise.all([glowAnimation]);
    }

    // Animation sequence for each box
    Object.keys(this.corners).forEach((boxID, i) => {
      let container = this.containers[boxID];
      let box = this.boxes[boxID];
      let corners = this.corners[boxID];
      fadeInBox(container, this.boxInterval * i)
        .then(() => rotateBox(container))
        .then(() => cornerFill(corners))
        .then(() => squeeze(box))
        .then(() => glow(box));
    });
  }

  componentWillUnmount() {
    clearTimeout(this.delayTimer);
  }

  componentDidMount() {
    const { delay = 0 } = this.props;

    this.delayTimer = setTimeout(() => {
      this.animateBoxes();
      this.animateCircles();
      this.animateCrosshairs();
    }, delay);
  }

  getMaskBoxPoints(box, position = 1, overrides = {}) {
    let x = 0;
    let y = 0;
    let b = Object.assign({}, box, overrides);
    let w = (b.width / 2) * b.corner;
    let h = (b.height / 2) * b.corner;
    switch (position) {
      case 1:
        break;
      case 2:
        x = b.width - (b.width / 2) * b.corner;
        break;
      case 3:
        x = b.width - (b.width / 2) * b.corner;
        y = b.height - (b.height / 2) * b.corner;
        break;
      case 4:
        y = b.width - (b.height / 2) * b.corner;
        break;
    }
    return this.getBoxPoints(x, y, w, h);
  }

  getBoxPath(x, y, width, height, cap = true) {
    let points = [];
    points.push(`${x},${y}`);
    points.push(`${x + width},${y}`);
    points.push(`${x + width},${y + height}`);
    points.push(`${x},${y + height}`);
    return 'M' + points.join(' ') + (cap ? 'Z' : '');
  }

  getBoxPoints(x, y, width, height) {
    let points = [];
    points.push(`${x},${y}`);
    points.push(`${x + width},${y}`);
    points.push(`${x + width},${y + height}`);
    points.push(`${x},${y + height}`);
    return points.join(' ');
  }

  getCornerMask(box) {
    // Corners
    let boxes = [];
    for (let i = 1; i <= 4; i++) {
      boxes.push({
        start: this.getMaskBoxPoints(box, i),
        end: this.getMaskBoxPoints(box, i, { corner: 1 }),
      });
    }
    this.corners[box.id] = [];
    return (
      <mask
        id={box.maskID}
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
      >
        {boxes.map((b, i) => {
          return (
            <polygon
              className={'Player__BoxScanner__Corner'}
              data-end={b.end}
              data-start={b.start}
              data-x={b.endX}
              data-y={b.endY}
              points={b.start}
              fill="#ffffff"
              key={i}
              ref={node => (this.corners[box.id][i] = node)}
            />
          );
        })}
      </mask>
    );
  }

  getUrlId(id) {
    return `url(#${id})`;
  }

  getBoxWithinBox(x, y, width, height, border) {
    let points = [];
    points.push(this.getBoxPath(x, y, width, height, false));
    points.push(
      this.getBoxPath(
        x + border,
        y + border,
        width - border * 2,
        height - border * 2
      )
    );
    return points.join(' ');
  }

  makeCircle(settings) {
    let circle = {
      size: 180,
      fill: 'transparent',
      easing: 'linear',
      stroke: getColor('blue'),
      opacity: 0.5,
      strokeDasharray: '',
      strokeWidth: 4,
      scale: 0.8,
      duration: 4000,
      delay: 500,
      close: 4000,
      rotate: '', // Do not rotate by default
    };

    this.totalObjects++;
    circle.id = 'the_circle_' + uid(10);

    Object.assign(circle, settings);

    circle.r = circle.size / 2;
    let left = (circle.size / 2) * -1;
    let top = (circle.size / 2) * -1;

    return (
      <svg
        width={circle.size}
        height={circle.size}
        viewBox={`0 0 ${circle.size} ${circle.size}`}
        style={{ overflow: 'visible', left: left, top: top }}
        fill="none"
        ref={node => (this.circleContainers[circle.id] = node)}
        key={this.totalObjects}
      >
        <circle
          r={circle.r}
          cx={circle.r}
          cy={circle.r}
          stroke={circle.stroke}
          strokeWidth={circle.strokeWidth}
          strokeDasharray={circle.strokeDasharray}
          opacity={circle.opacity}
          id={circle.id}
          data-duration={circle.duration}
          data-easing={circle.easing}
          data-delay={circle.delay}
          data-rotate={circle.rotate}
          data-close={circle.close}
          ref={node => (this.circles[circle.id] = node)}
        ></circle>
      </svg>
    );
  }

  makeBox(settings) {
    let box = {
      border: 10,
      color: '#ffffff',
      opacity: 0.5,
      corner: 0.5,
      width: 0,
      height: 0,
      x: 0,
      y: 0,
    };

    this.totalObjects++;
    box.id = 'the_box_' + uid(11);

    Object.assign(box, settings);

    // Create an ending box
    let finale = {
      border: box.border / 2,
    };

    // Keep track of the total border width
    this.borderWidth += finale.border * 2;

    // Create the finale coordinates
    finale.width = this.target.width + this.borderWidth;
    finale.height = this.target.height + this.borderWidth;
    finale.x = (box.width - finale.width) / 2;
    finale.y = (box.height - finale.height) / 2;

    // Generate a mask ID
    box.maskID = `${box.id}__mask`;

    let left = (box.width / 2) * -1;
    let top = (box.height / 2) * -1;

    return (
      <svg
        width={box.width}
        height={box.height}
        viewBox={`0 0 ${box.width} ${box.height}`}
        style={{ overflow: 'visible', left: left, top: top }}
        fill="none"
        ref={node => (this.containers[box.id] = node)}
        key={this.totalObjects}
      >
        {this.getCornerMask(box)}
        <g
          fill={box.color}
          fillRule="evenodd"
          clipRule="evenodd"
          mask={this.getUrlId(box.maskID)}
          id={box.id}
        >
          <path
            className={'Player__BoxScanner__Box'}
            data-start={this.getBoxWithinBox(
              box.x,
              box.y,
              box.width,
              box.height,
              box.border
            )}
            data-end={this.getBoxWithinBox(
              finale.x,
              finale.y,
              finale.width,
              finale.height,
              finale.border
            )}
            d={this.getBoxWithinBox(
              box.x,
              box.y,
              box.width,
              box.height,
              box.border
            )}
            opacity={box.opacity}
            ref={node => (this.boxes[box.id] = node)}
          />
        </g>
      </svg>
    );
  }

  makeCrosshair(settings) {
    let crosshair = {
      stroke: '#ffffff',
      count: 4,
      length: 20,
      strokeWidth: 2,
      opacity: 0.2,
      delay: 0,
      close: 4000,
      duration: 2000,
    };

    this.totalObjects++;
    crosshair.id = 'the_crosshair_' + uid(12);

    Object.assign(crosshair, settings);

    let left = (crosshair.width / 2) * -1;
    let top = (crosshair.height / 2) * -1;
    let radiusOuter = crosshair.width / 2;
    let radiusInner = radiusOuter - crosshair.length / 2;

    // x = radius * Math.cos(deg);
    // y = radius * Math.sin(deg);
    let deg = 180 / crosshair.count;
    let step = (2 * Math.PI) / crosshair.count;
    let lines = [...Array(crosshair.count).keys()].map(i => {
      let d = i * step;
      return {
        deg: d,
        x1: radiusOuter * Math.cos(d),
        y1: radiusOuter * Math.sin(d),
        x2: radiusInner * Math.cos(d),
        y2: radiusInner * Math.sin(d),
        offset: crosshair.width / 2,
      };
    });

    // Store the crosshairs
    this.crosshairs[crosshair.id] = [];

    return (
      <svg
        width={crosshair.width}
        height={crosshair.height}
        viewBox={`0 0 ${crosshair.width} ${crosshair.height}`}
        style={{ overflow: 'visible', left: left, top: top }}
        fill="none"
        ref={node => (this.crosshairContainers[crosshair.id] = node)}
        data-close={crosshair.close}
        data-delay={crosshair.delay}
        data-duration={crosshair.duration}
        data-interval={crosshair.duration / crosshair.count}
        data-opacity={crosshair.opacity}
        data-direction={crosshair.direction}
        key={this.totalObjects}
      >
        {lines.map((line, i) => {
          return (
            <path
              key={i}
              d={`M${line.x1 + line.offset},${line.y1 + line.offset} ${line.x2 +
                line.offset},${line.y2 + line.offset}`}
              data-deg={line.deg}
              strokeWidth={crosshair.strokeWidth}
              stroke={crosshair.stroke}
              opacity={crosshair.opacity}
              ref={node => (this.crosshairs[crosshair.id][i] = node)}
            />
          );
        })}
      </svg>
    );
  }

  renderCrosshairs(crosshairs, size) {
    return (
      <div className={'Player__BoxScanner__Crosshairs'}>
        {crosshairs.map(crosshair => {
          if ('offset' in crosshair) {
            crosshair.width = size + crosshair.offset;
            crosshair.height = size + crosshair.offset;
          }
          return this.makeCrosshair(crosshair);
        })}
      </div>
    );
  }

  renderBoxes(boxes, size) {
    return (
      <div className={'Player__BoxScanner__Boxes'}>
        {boxes.map(box => {
          if ('offset' in box) {
            box.width = size + box.offset;
            box.height = size + box.offset;
          }
          return this.makeBox(box);
        })}
      </div>
    );
  }

  renderCircles(circles, size) {
    return (
      <div className={'Player__BoxScanner__Circles'}>
        {circles.map(circle => {
          if ('offset' in circle) {
            circle.size = size + circle.offset;
          }
          return this.makeCircle(circle);
        })}
      </div>
    );
  }

  render() {
    const {
      children,
      className = '',
      width = 180,
      height = 50,
      boxes = null,
      circles = null,
      crosshairs = null,
      offset = true,
    } = this.props;

    // Set the target's dimensions
    this.target.width = width;
    this.target.height = height;

    // Get the animation shapes
    let theBoxes = Array.isArray(boxes) ? boxes : this.defaultBoxes;
    let theCircles = Array.isArray(circles) ? circles : this.defaultCircles;
    let theCrosshairs = Array.isArray(crosshairs)
      ? crosshairs
      : this.defaultCrosshairs;

    // Get largest dimension
    let size = Math.max(this.target.width, this.target.height);

    // Offset positioning by the dimensions of the target object
    let offsetStyles = {};
    if (offset) {
      offsetStyles.left = this.target.width / 2;
      offsetStyles.top = this.target.height / 2;
    }

    return (
      <div className="Player__BoxScanner">
        <div className="Player__BoxScanner__Offset" style={offsetStyles}>
          {this.renderBoxes(theBoxes, size)}
          {this.renderCircles(theCircles, size)}
          {this.renderCrosshairs(theCrosshairs, size)}
        </div>
      </div>
    );
  }
}
