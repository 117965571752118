import { Slide01 } from '../../../components/malicious/slide-01';
import { Slide02 } from '../../../components/malicious/slide-02';
import { Slide03 } from '../../../components/malicious/slide-03';
import { Slide04 } from '../../../components/malicious/slide-04';
import { Slide05 } from '../../../components/malicious/slide-05';
import { Slide06 } from '../../../components/malicious/slide-06';
import { Slide07 } from '../../../components/malicious/slide-07';
import { Slide08 } from '../../../components/malicious/slide-08';
import { Slide09 } from '../../../components/malicious/slide-09';
import { Slide10 } from '../../../components/malicious/slide-10';
import { Slide11 } from '../../../components/malicious/slide-11';
import { Slide12 } from '../../../components/malicious/slide-12';
import { Slide13 } from '../../../components/malicious/slide-13';
import { Slide14 } from '../../../components/malicious/slide-14';
import { Slide15 } from '../../../components/malicious/slide-15';
import { Slide16 } from '../../../components/malicious/slide-16';
import { Slide17 } from '../../../components/malicious/slide-17';
import { Slide18 } from '../../../components/malicious/slide-18';
import { Slide19 } from '../../../components/malicious/slide-19';
import { Slide20 } from '../../../components/malicious/slide-20';
import { Slide21 } from '../../../components/malicious/slide-21';
import { Slide22 } from '../../../components/malicious/slide-22';
import { Slide23 } from '../../../components/malicious/slide-23';
import { Slide24 } from '../../../components/malicious/slide-24';
import { Slide25 } from '../../../components/malicious/slide-25';
import { Slide26 } from '../../../components/malicious/slide-26';
import { Slide27 } from '../../../components/malicious/slide-27';
import { Bug } from '../../../components/svg/bug';
import { Eye } from '../../../components/svg/eye';
import { CheckShield } from '../../../components/svg/check-shield';
import { Bell } from '../../../components/svg/bell';
import { Clock } from '../../../components/svg/clock';
import { CATEGORY } from '../../category';

export const genericPhishingSlides = [
  // Slide 01
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Phishing: Malicious Document',
    subtitle: ' Scenario Overview',
    component: Slide01,
  },
  // Slide 02
  {
    title: 'Phishing: Malicious Document',
    subtitle: ' Suspicious File & Blocked Download Attempt',
    product: 'eSentire MDR for Network',
    time: '00:00:00:10',
    component: Slide02,
    icon: Bug,
    description:
      'In this scenario, an end user at 10.0.5.38 has been successfully phished, they have clicked on the link and downloaded a potentially malicious Word document.  The Word document has an embedded script which has attempted to reach out to a Command and Control (CnC) server to download additional payload, but that has been successfully blocked based on eSentire’s global threat intelligence (AMP).',
    appScreenshotUrl: require('../../../assets/malicious/slide-1.jpg'),

    category: CATEGORY.INFECTION,
  },
  // Slide 03
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Inspect IP Address',
    product: 'eSentire MDR for Network',
    time: '00:00:00:13',
    icon: Eye,
    component: Slide03,
    description:
      'The SOC Analyst reviews the past history of the IP address associated with the initial download of the malicious Word document.  The IP address is associated with a hosting provider, is not currently part of our global blacklist (AMP) but has had malicious traits historically across various domains and URLs based on our 3rd party threat feeds.  The Analyst is able to quickly see that this IP address is only associated with a single (1) event across our entire customer base.',
    appScreenshotUrl: require('../../../assets/malicious/slide-2.jpg'),

    category: CATEGORY.INFECTION,
  },
  // Slide 04
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Inital Analysis',
    product: 'eSentire MDR for Network',
    time: '00:00:00:25',
    component: Slide04,
    description:
      'The SOC Analyst concludes their review of the contextual intelligence we have on the external IP address and begins to dig further into the investigation to see exactly what has happened to determine if this is a real threat or not.',
    appScreenshotUrl: require('../../../assets/malicious/slide-3.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 05
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Inital Analysis',
    product: 'eSentire MDR for Network',
    time: '00:00:00:32',
    component: Slide05,
    description:
      'The SOC Analyst reviews the summary of network activity associated with this indicator from within the investigation portal.  The SOC Analyst has full access to all client information including our escalation process, as defined by the customer, and any potentially related events.',
    appScreenshotUrl: require('../../../assets/malicious/slide-4.jpg'),
  },
  // Slide 06
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Inspect PCAP',
    product: 'eSentire MDR for Network',
    time: '00:00:00:37',
    component: Slide06,
    description:
      'The SOC Analyst utilizes the full packet capture associated with this event to analyze in integrated packet analyzing platform; Cloudshark.',
    appScreenshotUrl: require('../../../assets/malicious/slide-5.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 07
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Inspect PCAP',
    product: 'eSentire MDR for Network',
    time: '00:00:00:48',
    component: Slide07,
    description:
      'Using key indicators, the SOC Analyst locates the specific communication and (http) request associated with the potentially malicious document.',
    appScreenshotUrl: require('../../../assets/malicious/slide-6.jpg'),
  },
  // Slide 08
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'File Extraction',
    product: 'eSentire MDR for Network',
    time: '00:00:00:50',
    component: Slide08,
    description:
      'The SOC Analyst is able to successfully retrieve the potentially malicious document from the packet capture stored on the customer sensor.  Alternatively, the Analyst can retrieve a live version of the file by navigating to the (phishing) link that delivered the Word document (if still online).',
    appScreenshotUrl: require('../../../assets/malicious/slide-7.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 09
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Initiate Sandbox Analysis',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:01:15',
    component: Slide09,
    description:
      'With the obtained copy of the file, the SOC Analyst will submit the file to the sandbox for thorough analysis.  Since this customer also has eSentire MDR for Endpoint, while the SOC Analyst waits for the sandbox analysis to complete, they can pivot to eSentire MDR for Endpoint to continue the rapid investigation.',
    appScreenshotUrl: require('../../../assets/malicious/slide-8.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 10
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Locate Malicious Process',
    product: 'MDR for Endpoint',
    time: '00:00:01:40',
    component: Slide10,
    description:
      'By correlating the information collected by our eSentire MDR for Network platform that saw the suspicious download, the SOC Analyst is able to locate and identify the process responsible for downloading the suspicious file and explore the associated process tree.',
    appScreenshotUrl: require('../../../assets/malicious/slide-9.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 11
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Locate Malicious Process',
    product: 'MDR for Endpoint',
    time: '00:00:02:00',
    component: Slide11,
    description:
      'The SOC Analyst is able to confirm that the file was opened post download and executed a Powershell command. By reviewing the Powershell data, the SOC Analyst can determine the contacted a CnC and the downloaded payload.',
    appScreenshotUrl: require('../../../assets/malicious/slide-10.jpg'),
  },
  // Slide 12
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Binary Analysis',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:02:10',
    component: Slide12,
    description:
      'The binary data provides a reputation summary from multiple anti-viruses, which endpoints the binary was detected on and where the binary was located.',
    appScreenshotUrl: require('../../../assets/malicious/slide-11.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 13
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Binary Reputation Review',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:02:30',
    component: Slide13,
    description:
      'By reviewing the binary’s reputation, the SOC Analyst can confirm the file is associated with the Win32.Emotet Trojan.',
    appScreenshotUrl: require('../../../assets/malicious/slide-12.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 14
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Inspect Process Activity',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:02:50',
    component: Slide14,
    description:
      'By following the processes activity, the SOC analyst can determine the process created copies of itself in an attempt to evade detection.',
    appScreenshotUrl: require('../../../assets/malicious/slide-13.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 15
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Review Sandbox Analysis',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:03:03',
    component: Slide15,
    description:
      'After the sandbox analysis is complete, the SOC Analyst is able to confirm similar finding(s) for the malicious activity from the detonation of the suspicious payload within the sandbox environment.',
    appScreenshotUrl: require('../../../assets/malicious/slide-14.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 16
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Review Sandbox Analysis',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:03:15',
    component: Slide16,
    description:
      'The SOC analyst confirms the outbound malicious activity and the document spawning new processes.',
    appScreenshotUrl: require('../../../assets/malicious/slide-15.jpg'),
  },
  // Slide 17
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Review Sandbox Analysis',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:03:30',
    component: Slide17,
    description:
      'The SOC Analyst confirms the CnC communication and the additional eSentire MDR for Network related signals or indicators.',
    appScreenshotUrl: require('../../../assets/malicious/slide-16.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 18
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Review Sandbox Analysis',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:03:45',
    component: Slide18,
    description:
      'The SOC Analyst identifies the malicious documents payload, accompanied with the various hashes. Note: The SHA256 hash matches the binaries reputation analysis.',
    appScreenshotUrl: require('../../../assets/malicious/slide-17.jpg'),
    category: CATEGORY.INVESTIGATION,
  },
  // Slide 19
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Host Isolation',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:04:00',
    icon: CheckShield,
    component: Slide19,
    description:
      'At this point, the SOC Analyst has confirmed through thorough analysis and investigation, that the machine is infected and proceeds to isolate the machine from the customer’s network.',
    appScreenshotUrl: require('../../../assets/malicious/slide-18.jpg'),
    category: CATEGORY.CONTAINMENT,
  },
  // Slide 20
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Disrupt Malicious IPs',
    product: 'eSentire MDR for Network',
    time: '00:00:04:15',
    component: Slide20,
    description:
      "The SOC analyst implements disruption for all related malicious external IPs on the client's sensor.",
    appScreenshotUrl: require('../../../assets/malicious/slide-19.jpg'),
    category: CATEGORY.CONTAINMENT,
  },
  // Slide 21
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Disrupt Malicious IPs',
    product: 'eSentire MDR for Network',
    time: '00:00:04:35',
    component: Slide21,
    description:
      'The SOC Analyst then continues updating the eSentire global blacklist (AMP) with the newly discovered intelligence about the malicious (CnC) file server. This helps ensure eSentire Security Network Effects, to automatically push proactive detection knowledge to our global customer base and protect them from this particular threat.',
    appScreenshotUrl: require('../../../assets/malicious/slide-20.jpg'),
  },
  // Slide 22
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Alert Client',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:04:55',
    icon: Bell,
    clientCommunication: true,
    component: Slide22,
    description:
      'The SOC Analyst sends the initial alert to the client with a (brief) summary of finding(s).',
    // appScreenshotUrl: require('../../../assets/malicious/slide-21.jpg'),
    category: CATEGORY.ESCALATION,
    emailDemo: {
      title: '',
      start: [
        'eSentire has detected Malware behavior from this machine.\n[US Federal Agency/CERT Incident Category: CAT 3] ',
        'Please open a Priority 3 Ticket for desktop technicians to investigate the situation. ',
        'We recommend:',
        '\ta) The system be isolated from the rest of the infrastructure immediately\n\tb) A manual Anti-Virus scan be performed\n\tc) A manual Anti-Malware scan be performed (using at least one tool)\n\td) Anti-Virus signatures are verified to be up-to-date\n\te) Re-image the machine if applicable ',
      ],
      table: [
        ['Local IP Address:', '10.0.5.38'],
        ['Local Hostname:', 'Trader-04'],
        [
          'Malware / File hash Detected:',
          '941BEB4B319484820C82FBBEF9FDA6B7 / 238789.exe\n2E23B47857DFE07896C6136C3E045A35 / Purchases 2017.doc',
        ],
        [
          'Evidence:',
          'This machine was detected downloading a malicious document called "Purchases 2017.doc" from hxxp://www[dot]easy123property[doWscan/Invoice.\n\nAdditional evidence, indicates that when the user opened this file, it downloaded a malicious file from cohomesdenver[dot]com (IP 198.187.29.106).\n\nInitial download location: "C:\\userpublic\\238789.exe" ',
        ],
      ],
      finish: [
        'As a precaution against further access, we are now temporarily disrupting traffic with all external malicious host(s) and the internal machine has been isolated',
        'Also, please acknowledge receipt of this alert.',
      ],
    },
  },
  // Slide 23
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Alert Escalation',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:05:15',
    component: Slide23,
    description:
      'Since this is a critical event, the SOC initiates the escalation process.  This process ensures that we get positive confirmation from the customer that they have acknowledged the threat and are taking the necessary next steps per the SOC Analysts recommendations.',
    appScreenshotUrl: require('../../../assets/malicious/slide-22.jpg'),
    category: CATEGORY.ESCALATION,
  },
  // Slide 24
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Alert Escalation',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:08:45',
    component: Slide24,
    description:
      'Due to the criticality of the event, the SOC Analyst calls the customer and the client acknowledges the alert over the phone.  The customer requests some additional (detailed) information on the incident.',
    appScreenshotUrl: require('../../../assets/malicious/slide-23.jpg'),
  },
  // Slide 25
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Deep Dive Investigation',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:09:00',
    component: Slide25,
    clientCommunication: true,
    description:
      'The SOC Analyst sends the follow-up communication to the client to confirm that additional investigation has been initiated and to confirm which information was requested.',
    // appScreenshotUrl: require('../../../assets/malicious/slide-24.jpg'),
    category: CATEGORY.ESCALATION,
    emailDemo: {
      title:
        'ESENTIRE ALERT: ESENTIRE CAMBRIDGE - THREAT MALWARE - 10.0.5.38 - Acknowledgement Required - Ticket 1263843',
      start: [
        'Hi Matt, ',
        'Thank you for your call. As discussed, I have isolated the second infected machine (Finance-07) and opened a ticket for the SOC to provide the following additional information: ',
        '\t• Timeline of this incident \n\t• Malicious IPs and domains contacted \n\t• Malicious file locations and hashes. ',
        'Please let us know if you have any additional questions. ',
        'Best Regards,\nNickolas Davie\nAnalyst, SOC Customer Care',
        'eSentire Inc.',
        'Direct-to-SOC Toll Free North America +1 844 552 5837\nDirect-to-SOC Outside of North America +353 21 4757102\nUnited Kingdom Toll Free 0800 044 3242\nInternational +1 519 651 2200 x 5700 ',
        'www.esentire.com\n@esentire ',
        'This email is confidential and maybe legally privileged. It is intended as confidential communication only for the person(s) named above. Any other use or disclosure is prohibited. If you have received this message in error, please delete it disregord its contents and telephone us immediately at +1 519.651.2200. ',
      ],
    },
  },
  // Slide 26
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Deep Dive Investigation',
    product: 'eSentire MDR for Endpoint  +  eSentire MDR for Network',
    time: '00:00:09:00',
    component: Slide26,
    clientCommunication: true,
    description:
      'The SOC Analyst performs their investigation and provides client with their findings.',
    // appScreenshotUrl: require('../../../assets/malicious/slide-25.jpg'),
    emailDemo: {
      title:
        'RE: ESENTIRE ALERT: ESENTIRE CAMBRIDGE - THREAT MALWARE - 10.0.5.38 - Acknowledgement Required - Ticket 1263843 ',
      start: [
        'Hi Matt, ',
        {
          markdown: `
- Timeline of this incident (for Trader-04)
  - 12:25:06 - malicious document downloaded from 45.40.148.106 (www\\[dot\\]easy123property\\[dot\\]com/scan/Invoice) called "Purchases 2017.doc"
  - 12:25:35 - The user opened the file.
  - 12:25:45 - The malicious document contacted 198.187.29.106 (cohomesdenver\\[dot\\]com) and downloaded a malicious executable to "c:\\users\\public\\238789.exe". This file was confirmed to be associated with Trojan Win32.Emotet.
  - 12:25:53 - 238789.exe process copied itself to c:\\windows\\system32\\screenmgmt.exe and launched the new executable using services.exe before deleting itself.
  - 12:26:29 - screenmgmt.exe attempted to contact the CnC at 188.225.33.111 (cbtgjwqf\\[dot\\]com), which was disrupted by our global blacklist (AMP).
  - 12:27:03 - screenmgmt.exe contacted the CnC at 173.255.217.114 (opevfsa\\[dot\\]ru) over port 443. Unfortunately as this was over an encrypted channel, we are unable to confirm the specific request but is likely related to the future detection evasion.
  - 12:27:05 - screenmgmt.exe process copied itself to c:\\windows\\system32\\dhcpsrv.exe and launched the new executable using services.exe before deleting itself. dhcpsrv.exe appears to be running on the machine but hasn\'t performed any malicious activity.
  - Note similar activity occurred on Finance-07.
- Malicious IPs and domains contacted o 45.40.148.106 (www\\[dot\\]easy123property\\[dot\\]corn)
  - 198.187.29.106 (cohomesdenver\\[dot\\]com)
  - 188.225.33.111 (cbtgjwqf\\[dot\\]com)
  - 173.255.217.114 (opevfsa\\[dot\\]ru)
- Malicious file locations and hashes.
  - MD5: 941BEB4B319484820C82FBBEF9FDA6B7
    - c:\\users\\public\\238789.exe — Trader-04
    - c:\\users\\public\\516721.exe — Finance-07
    - c:\\windows\\system32\\screenmgmt.exe — Both machines
    - c:\\windows\\system32\\dhcpsrv.exe — Both machines
  - MD5: 2E231347857DFE07896C6136C3E045A35
    - c:\\users\\public\\appdata\\local\\microsoft\\windows\\temporary internet files\\contentie5 \\7s6brm54 \\purchases 2017[1].doc —Trader-04
    - c:\\users\\finance-07\\appdata\\local\\microsoft\\windows\\temporary internet files\\contentie5\\s2af6d5h\\purchases 2017[1].doc — Finance-07
          `,
        },
        'Please let us know if you have any additional questions.',
      ],
    },
  },
  // Slide 27
  {
    title: 'Phishing: Malicious Document',
    subtitle: 'Timeline Summary',
    component: Slide27,
    icon: Clock,
    timelineSummary: true,
    category: CATEGORY.ESCALATION,
  },
];
