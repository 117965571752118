import classnames from 'classnames';
import * as React from 'react';
import './email-demo.scss';
import ReactMarkdown from 'react-markdown';

/**
 * This component is to render a mock email that is used to depict an email
 * demo the system would send.
 *
 * You can view this component on slides that have a swipe up gesture to show
 * the demo.
 */
export class EmailDemo extends React.Component {
  render() {
    const {
      className,
      containerProps,
      title,
      start,
      table,
      finish,
    } = this.props;

    return (
      <div className={classnames('EmailDemo', className)} {...containerProps}>
        <div className="EmailDemo__Email">
          <div className="EmailDemo__Title">
            <div className="EmailDemo__Dot" />
            <div className="EmailDemo__Dot" />
            <div className="EmailDemo__Dot" />
            <div className="EmailDemo__TitleLabel">{title}</div>
          </div>
          <div className="EmailDemo__Content">
            {Array.isArray(start) ? (
              start.map(row =>
                row.markdown ? (
                  <ReactMarkdown source={row.markdown} />
                ) : (
                  <p>{row}</p>
                )
              )
            ) : (
              <p>{start}</p>
            )}
            {table ? (
              <div className="EmailDemo__Table">
                {table.map((row, i) => (
                  <div className="EmailDemo__Row">
                    <div
                      className={classnames(
                        'EmailDemo__RowLeft',
                        i % 2 === 0 ? 'EmailDemo--dark' : ''
                      )}
                    >
                      {row[0]}
                    </div>
                    <div
                      className={classnames(
                        'EmailDemo__RowRight',
                        i % 2 === 0 ? 'EmailDemo--dark' : ''
                      )}
                    >
                      {row[1]}
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {Array.isArray(finish) ? (
              finish.map(row =>
                row.markdown ? (
                  <ReactMarkdown src={row.markdown} />
                ) : (
                  <p>{row}</p>
                )
              )
            ) : (
              <p>{finish}</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}
