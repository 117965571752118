/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// SVG
import { Warning } from '../svg/warning';
import { Alert } from '../svg/alert';

@observer
export class Slide03 extends AnimatedSlide {
  // Animation players
  players = {
    password: null,
    alert: null,
    border: null,
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');
    const { password, alert, border } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(8000);
        this.autoNext(1000);
      },
      // Step 2
      () => {
        password.classList.add('type');
        setTimeout(() => {
          password.classList.add('error');
        }, 1000);
        this.autoNext(2000);
      },
      // Step 3
      () => {
        password.classList.remove('type', 'error');
        this.autoNext(500);
      },
      // Step 4
      () => {
        password.classList.add('type');
        setTimeout(() => {
          password.classList.add('error');
        }, 1000);
        this.autoNext(2000);
      },
      // Step 5
      () => {
        password.classList.remove('type', 'error');
        this.autoNext(500);
      },
      // Step 6
      () => {
        password.classList.add('type');
        setTimeout(() => {
          password.classList.add('error');
        }, 1000);
        this.autoNext(2000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [],

      //
      // Step 3
      //
      [],

      //
      // Step 4
      //
      [],

      //
      // Step 5
      //
      [],

      //
      // Step 6
      //
      [],

      //
      // Step 7
      //
      [
        {
          targets: password,
          opacity: 0,
          duration: 200,
          begin: shimmer,
          complete: deactivate,
        },
        { targets: alert, opacity: 1, duration: 500, begin: activateShimmer },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-03 ${className}`}>
        {children}
        <div className="content">
          {/*-------PLAYERS START-------*/}
          {/* Skull */}
          <div
            className="player password"
            data-active="1"
            ref={node => (this.players.password = node)}
          >
            <h3>PASSWORD IS INCORRECT</h3>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>

          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'Brute Force Attempt'}
              subtitle={'31 Failed Login Attempts'}
              IconComponent={Warning}
            />
          </div>
          {/*-------PLAYERS END-------*/}
        </div>
      </div>
    );
  }
}
