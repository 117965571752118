/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import ReactDOM from 'react-dom';

// SVG
import { Card } from '../svg/card';
import { Skull } from '../svg/skull';

@observer
export class Slide17 extends AnimatedSlide {
  // Animation players
  players = {
    attacker: null,
    info1: null,
    info2: null,
    dots1: null,
    dots2: null,
  };

  // Run the first animation
  componentDidMount() {
    const { attacker, info1, info2, dots1, dots2 } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 0
      () => {
        this.autoNext(4000);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Pause for effect
      ],

      //
      // Step 1
      //
      [
        {
          targets: attacker,
          opacity: 1,
          delay: 0,
          translateX: -300,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: dots1,
          width: [0, 100],
          delay: 500,
          duration: 500,
        },
        {
          targets: dots2,
          width: [0, 120],
          delay: 1000,
          duration: 500,
        },
        {
          targets: info1,
          opacity: 1,
          delay: 1500,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: info2,
          opacity: 1,
          delay: 2000,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
      ],

      //
      // Step 2
      //
      [
        // Do nothing
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-17 ${className}`}>
        {children}
        <div className="content">
          {/* Attacker */}
          <div
            className="player attacker"
            data-active="0"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text={'Attacker'} />
            <div
              ref={node => (this.players.dots1 = node)}
              className={'animated-dotted-line reverse ants-1'}
            ></div>
            <div
              ref={node => (this.players.dots2 = node)}
              className={'animated-dotted-line reverse ants-2'}
            ></div>
          </div>

          {/* Log compare */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerTextBlock
              title={'Log Compare'}
              sections={[
                {
                  title: ['REMOVE VMWARE CARBON BLACK'],
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['EVENTCODE', '11725'],
                  grid: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['EVENTCODE', '3'],
                  grid: 2,
                  animate: true,
                  delay: 600,
                },
              ]}
            />
          </div>
          {/* Failed */}
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerTextBlock
              title={'Failed'}
              className={'red-top'}
              sections={[
                {
                  title: [
                    'VMWARE CARBON BLACK CLOUD SENSOR 64-BIT REMOVAL FAILED',
                  ],
                  color: 'red',
                  colorCells: 1,
                  animate: true,
                  delay: 500,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
