/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Close extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 50,
      x = 0,
      y = 0,
      bold = false,
      opacity = bold ? 1 : 0.5,
    } = this.props;

    if (bold) {
      return (
        <svg
          className={className}
          {...{ x, y }}
          width={size}
          height={size}
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.3203 1.85156L7.67188 6.5L12.3203 11.1484L11.1484 12.3203L6.5 7.67188L1.85156 12.3203L0.679688 11.1484L5.32812 6.5L0.679688 1.85156L1.85156 0.679688L6.5 5.32812L11.1484 0.679688L12.3203 1.85156Z"
            fill={color}
            fillOpacity={opacity}
          />
        </svg>
      );
    }

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24.6762 23.8064L36.3014 12.1813L37.5823 13.4622L25.9572 25.0874L38.114 37.2442L36.7847 38.5735L24.6279 26.4166L13.462 37.5826L12.181 36.3016L23.347 25.1357L11.1659 12.9547L12.4952 11.6254L24.6762 23.8064Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
