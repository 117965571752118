/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import { observable } from 'mobx';
import { flatMap, toPairs } from 'lodash';

const state = observable({
  history: [],
  shortcuts: [],
  watchers: [],
});

function handleKeyDown(event) {
  // Rotate this key into the history
  state.history = state.history.slice(-9).concat(event.key);
  const textHistory = state.history.slice().join('');
  // Check the shortcuts!
  const match = state.shortcuts
    .slice()
    .reverse()
    .find(
      shortcut =>
        shortcut.action &&
        shortcut.chord === textHistory.slice(-shortcut.chord.length)
    );
  if (match) match.action();
}

@observer
export class KeyboardShortcuts extends Component {
  shortcuts = [];

  componentDidMount() {
    if (state.watchers.length === 0)
      document.addEventListener('keydown', handleKeyDown);
    state.watchers.push(this);

    // Install the shortcuts
    this.shortcuts = this.props.shortcuts;
    this.updateAllShortcuts();
  }

  componentDidUpdate() {
    this.shortcuts = this.props.shortcuts;
    this.updateAllShortcuts();
  }

  componentWillUnmount() {
    state.watchers.remove(this);
    if (state.watchers.length === 0)
      document.removeEventListener('keydown', handleKeyDown);
    this.updateAllShortcuts();
  }

  updateAllShortcuts() {
    state.shortcuts = flatMap(state.watchers, watcher =>
      toPairs(watcher.shortcuts || {}).map(([chord, action]) => {
        return { chord, action, owner: watcher };
      })
    );
  }

  render() {
    const { className = '' } = this.props;

    return (
      <span
        className={`KeyboardShortcuts ${className}`}
        style={{ display: 'none' }}
      />
    );
  }
}
