/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './chart.scss';
import anime from 'animejs';
import ReactDOM from 'react-dom';

@observer
export class PlayerChart extends Component {
  componentDidMount() {
    const {} = this.props;
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`player--chart ${className}`}>
        <div className="chart-series">
          <div className="series-data">
            <div className="blue" style={{ height: '10%' }} />
            <div className="red" style={{ height: '30%' }} />
            <label>Jan 12</label>
          </div>
          <div className="series-data">
            <div className="blue" style={{ height: '10%' }} />
            <div className="red" style={{ height: '40%' }} />
            <label>Jan 13</label>
          </div>
          <div className="series-data">
            <div className="blue" style={{ height: '10%' }} />
            <div className="red" style={{ height: '20%' }} />
            <label>Jan 14</label>
          </div>
          <div className="series-data">
            <div className="blue" style={{ height: '10%' }} />
            <div className="red" style={{ height: '15%' }} />
            <label>Jan 15</label>
          </div>
          <div className="series-data">
            <div className="blue" style={{ height: '10%' }} />
            <div className="red" style={{ height: '100%' }} />
            <label>Jan 16</label>
          </div>
        </div>
        <div className="series-legend">
          <span className={'blue'}>Success</span>
          <span className={'red'}>Failed</span>
        </div>
      </div>
    );
  }
}
