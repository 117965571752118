/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Kaseya extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 19,
      x = 0,
      y = 0,
      opacity = 1,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g opacity={opacity} fill={color}>
          <path d="M20.9993 24.9322C20.9993 24.9322 25.839 22.07 33.6205 19.2556C37.7722 17.7768 41.9239 17.0135 44.8894 16.6558C43.2287 16.1787 41.6629 15.7494 40.5004 15.4155C36.7995 14.3899 31.4378 13.1258 26.954 12.4341C26.7642 13.5312 26.2185 15.2724 24.9374 17.7768C23.1581 21.2591 20.9993 24.9322 20.9993 24.9322ZM18.8166 28.9392C17.9389 29.2016 14.9971 36.8102 13.5024 41.0081C16.2544 42.9162 19.1013 44.6335 21.7584 45.7783C27.0726 48.1158 28.7333 47.1617 32.0309 45.6591C34.4034 44.6096 38.1043 42.0814 41.3308 39.6962C37.3926 37.1441 32.4343 33.9957 29.5874 32.6362C24.2732 30.0841 20.0977 28.5576 18.8166 28.9392ZM23.3717 12.0525C19.2911 11.7662 18.6743 12.7203 17.8677 13.245C15.78 14.3899 7.09695 19.5895 2.92151 23.2626C-1.06413 26.7449 -0.376134 28.9392 1.47434 31.1097C2.99268 32.8747 5.60233 34.9736 9.7066 38.2174C9.89639 38.3605 10.0862 38.5037 10.276 38.6468C11.9367 34.9736 15.4953 27.0311 17.2509 23.3103C19.3148 18.9694 22.138 14.1514 23.3717 12.0525ZM58.9578 21.2114C57.5343 20.472 56.2769 20.0665 55.1619 19.6849C54.5925 19.5179 53.1454 19.0886 51.3186 18.54C48.0684 18.9694 42.6831 19.7803 37.4875 21.0206C32.0784 22.3086 23.0158 26.7449 23.0158 26.7449C23.0158 26.7449 34.4982 30.4657 45.2215 36.7387C45.6011 36.4524 45.9095 36.1901 46.2179 35.9277C49.5393 33.1848 58.0088 26.1009 59.8356 23.048C60.2389 22.3086 59.883 21.6884 58.9578 21.2114Z" />
        </g>
      </svg>
    );
  }
}
