/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './network-scan.scss';
import { Computer } from '../svg/computer';
import { transformAuthInfo } from 'passport/lib';
import { getColor, rgba } from '../helpers';

@observer
export class PlayerNetworkScan extends Component {
  // Settings
  settings = {};

  // Array of computer refs
  computerIndexes = new Array();
  computers = new Array();
  currentIndex = 0;
  timer = null;

  // Store indexes of computers that have and have not been remediated
  remediated = new Array();

  componentDidMount() {
    this.animateComputers();
  }

  getNextIndex(loop = true) {
    if (this.currentIndex >= this.computerIndexes.length) {
      if (loop) {
        this.currentIndex = 0;
      } else {
        clearInterval(this.timer);
      }
    }
    return this.computerIndexes[this.currentIndex];
  }

  shuffleIndexes(array) {
    return array
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
  }

  addRemoveClass(node) {
    if (!node) return;
    const { remediate = false, vulnerable = false } = this.props;
    node.classList.add('on');
    setTimeout(() => {
      if (!node) return;
      node.classList.remove('on');
      if (remediate) {
        node.classList.add('remediated');
      }
      if (vulnerable) {
        node.classList.add('vulnerable');
      }
    }, 500);
  }

  addClassAfter(node, className, wait = 500) {
    if (!node) return;
    setTimeout(() => {
      if (!node) return;
      node.classList.add(className);
    }, wait);
  }

  replaceClassAfter(node, class1, class2, wait = 500) {
    if (!node) return;
    node.classList.remove(class1);
    setTimeout(() => {
      if (!node) return;
      node.classList.add(class2);
    }, wait);
  }

  animateComputers() {
    const {
      delay = 0,
      remediate = false,
      random = false,
      indexes = false,
      vulnerable = false,
      instant = false,
    } = this.props;
    let wait = this.settings.duration / this.computers.length;
    let loop = true;
    if (indexes) {
      this.computerIndexes = indexes;
    }
    // Randomize the scan, default is linear
    if (random) {
      this.computerIndexes = this.shuffleIndexes(this.computerIndexes);
    }
    if (remediate || vulnerable) {
      loop = false;
    }
    // Skip the animation
    if (instant) {
      this.computerIndexes.map((index, i) => {
        if (remediate) {
          this.computers[index].classList.add('remediated');
        }
        if (vulnerable) {
          this.computers[index].classList.add('vulnerable');
        }
      });
      if (remediate && vulnerable) {
        setTimeout(() => {
          this.timer = setInterval(() => {
            let index = this.getNextIndex(false);
            this.currentIndex++;
            this.replaceClassAfter(
              this.computers[index],
              'vulnerable',
              'remediated'
            );
          }, this.settings.duration / this.computerIndexes.length);
        }, delay);
      }
      return;
    }
    setTimeout(() => {
      this.timer = setInterval(() => {
        let index = this.getNextIndex(loop);
        this.currentIndex++;
        this.addRemoveClass(this.computers[index]);
      }, wait);
    }, delay);
  }

  renderComputers() {
    let totalComputers = this.settings.columns * this.settings.rows;
    this.computerIndexes = Array.from(Array(totalComputers).keys());
    return this.computerIndexes.map((value, key) => {
      return (
        <span
          key={key}
          className={`Player__NetworkScan__Computer`}
          ref={node => (this.computers[key] = node)}
        >
          <Computer size={this.settings.size} color={this.settings.color} />
        </span>
      );
    });
  }

  render() {
    const {
      className = '',
      color = getColor('white'),
      borderColor = getColor('white'),
      borderWidth = 4,
      borderOpacity = 0.2,
      width = 180,
      columns = 4,
      rows = 4,
      duration = 2000,
      padding = 20,
      instant = false,
      gap = 10,
    } = this.props;

    this.settings.width = width;
    this.settings.color = color;
    this.settings.columns = columns;
    this.settings.rows = rows;
    this.settings.duration = duration;
    this.settings.padding = padding;
    this.settings.gap = gap;

    // Calculate the width
    this.settings.size = (width - padding * 2) / columns - gap;

    let gridStyles = {
      gridTemplateColumns: `repeat(${this.settings.columns}, 1fr)`,
      gap: this.settings.gap,
    };

    let wrapperStyles = {
      width: this.settings.width,
      padding: this.settings.padding,
      borderWidth: borderWidth,
      borderColor: rgba(borderColor, borderOpacity),
    };

    return (
      <div
        className={`Player__NetworkScan ${this.settings.className}`}
        style={wrapperStyles}
      >
        <div className={`Player__NetworkScan__Computers`} style={gridStyles}>
          {this.renderComputers()}
        </div>
      </div>
    );
  }
}
