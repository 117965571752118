import { Bell } from '../components/svg/bell';
import { Bug } from '../components/svg/bug';
import { CheckShield } from '../components/svg/check-shield';
import { Eye } from '../components/svg/eye';

export const CATEGORY = {
  INFECTION: 'infection',
  INVESTIGATION: 'investigation',
  CONTAINMENT: 'containment',
  ESCALATION: 'escalation',
};

export const CATEGORY_COLUMNS = [
  [CATEGORY.INFECTION],
  [CATEGORY.INVESTIGATION],
  [CATEGORY.CONTAINMENT],
  [CATEGORY.ESCALATION],
];

export const CATEGORY_ICONS = {
  [CATEGORY.INFECTION]: Bug,
  [CATEGORY.INVESTIGATION]: Eye,
  [CATEGORY.CONTAINMENT]: CheckShield,
  [CATEGORY.ESCALATION]: Bell,
};
