/** @jsx createElement */
import { observer } from 'mobx-react';
import { getColor } from './helpers';
import { createElement, Component } from 'react';
import { EsentireIcon } from './svg/esentire-icon';
import { RouteManager } from 'vega-router';
import { AppActions } from './app-actions';
import { Warning } from './svg/warning';
import { DoubleArrowRight } from './svg/double-arrow-right';
import { Money } from './svg/money';
import { Check } from './svg/check';
import { Chain } from './svg/chain';
import { SkullShield } from './svg/skull-shield';
import './navigation.scss';

@observer
export class Navigation extends Component {
  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.setShortcutName('Navigation');
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
  }

  navigateTo = path => () => {
    RouteManager.transitionTo(`/${path}`);
  };

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`Navigation ${className}`}>
        <EsentireIcon className="Navigation__Logo" />
        <div className="Navigation__Items">
          <div
            className="Navigation__Item Navigation__Item--use-cases"
            onClick={this.navigateTo('use-cases')}
          >
            <div className="Navigation__ItemContent">
              <div className="Navigation__ItemIcon">
                <DoubleArrowRight color={getColor('blue')} size={20} />
              </div>
              Demos &amp; Use Cases
            </div>
          </div>
          <div
            className="Navigation__Item Navigation__Item--soc"
            onClick={this.navigateTo('soc')}
          >
            <div className="Navigation__ItemContent">
              <div className="Navigation__ItemIcon">
                <Money color={getColor('blue')} size={30} />
              </div>
              DIY SOC Calculator
            </div>
          </div>
          <div
            className="Navigation__Item Navigation__Item--compliance"
            onClick={this.navigateTo('compliance')}
          >
            <div className="Navigation__ItemContent">
              <div className="Navigation__ItemIcon">
                <Check color={getColor('blue')} size={20} />
              </div>
              Compliance Alignment
            </div>
          </div>
          <div
            className="Navigation__Item Navigation__Item--mitre"
            onClick={this.navigateTo('mitre')}
          >
            <div className="Navigation__ItemContent">
              <div className="Navigation__ItemIcon">
                <SkullShield color={getColor('blue')} size={20} />
              </div>
              Mitre Mapping
            </div>
          </div>
          <div
            className="Navigation__Item Navigation__Item--risk"
            onClick={this.navigateTo('risk')}
          >
            <div className="Navigation__ItemContent">
              <div className="Navigation__ItemIcon">
                <Warning color={getColor('blue')} size={20} />
              </div>
              Risk Analysis
            </div>
          </div>
          <div
            className="Navigation__Item Navigation__Item--killchain"
            onClick={this.navigateTo('killchain')}
          >
            <div className="Navigation__ItemContent">
              <div className="Navigation__ItemIcon">
                <Chain color={getColor('blue')} size={20} />
              </div>
              Killchain Coverage
            </div>
          </div>
        </div>
      </div>
    );
  }
}
