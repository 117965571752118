/** @jsx createElement */
import { observer } from 'mobx-react';
import { getColor } from '../helpers';
import { createElement, Component, HTMLAttributes } from 'react';
import './signals.scss';

// Icons and stuff
import { SignalCloud } from '../svg/signal-cloud';
import { SignalEndpoint } from '../svg/signal-endpoint';
import { SignalNetwork } from '../svg/signal-network';
import { SignalLog } from '../svg/signal-log';
import { transformAuthInfo } from 'passport/lib';
import { PlayerIconLoad } from '../players/icon-load';
import { PlayerPath } from '../players/path';

@observer
export class PlayerSignals extends Component {
  // Settings
  settings = {};

  points = {
    two: [],
    three: [
      ['0,0', '80,60', '125,60'],
      ['0,3', '120,3'],
      ['0,60', '80,3', '125,3'],
    ],
    four: [
      ['0,0', '50,60', '110,60'],
      ['0,0', '100,30', '160,30'],
      ['0,30', '100,0', '160,0'],
      ['0,60', '50,0', '110,0'],
    ],
  };

  defaultIcons = [
    {
      title: 'Network',
      IconComponent: SignalNetwork,
      color: getColor('blue'),
      duration: 1200,
    },
    {
      title: 'Endpoint',
      IconComponent: SignalEndpoint,
      color: getColor('blue'),
      duration: 1000,
    },
    {
      title: 'Log',
      IconComponent: SignalLog,
      color: getColor('blue'),
      duration: 1100,
    },
    {
      title: 'Cloud',
      IconComponent: SignalCloud,
      color: getColor('blue'),
      duration: 1300,
    },
  ];

  // Icons to use
  icons = [];
  count = 'four';

  componentDidMount() {}

  renderIcon(icon, index) {
    const { delay = 0 } = this.props;
    let points = this.points[this.count][index];
    if (!('duration' in icon)) {
      icon.duration = Math.floor(Math.random() * (1500 - 1000)) + 1000;
    }
    if (!('color' in icon)) {
      icon.color = getColor('blue');
    }
    if (!('size' in icon)) {
      icon.size = 60;
    }
    if (!('strokeWidth' in icon)) {
      icon.strokeWidth = 2;
    }
    return (
      <div
        className="Player__Signals__Signal"
        ref={node => (this.icons[index].node = node)}
        key={index}
      >
        <PlayerIconLoad
          strokeWidth={icon.strokeWidth}
          size={icon.size}
          start={{
            IconComponent: icon.IconComponent,
            color: icon.color,
          }}
          loading={{
            delay: 0,
            duration: icon.duration,
          }}
        />
        <PlayerPath
          points={points}
          dots={{
            strokeWidth: 3,
            strokeDashArray: '3,3',
          }}
          gradient={{
            preset: 'blueWhite',
          }}
          reveal={{
            delay: delay,
            duration: 1000,
          }}
        />
        <label>{icon.title}</label>
      </div>
    );
  }

  render() {
    const { className = '', icons = [] } = this.props;

    // Get icons if passed in
    if (icons.length) {
      this.icons = icons;
    } else {
      this.icons = this.defaultIcons;
    }

    // Get class
    if (icons.length == 2) {
      this.count = 'two';
    }
    // Get class
    if (icons.length == 3) {
      this.count = 'three';
    }

    return (
      <div className={`Player__Signals ${className} ${this.count}`}>
        {this.icons.map((icon, index) => this.renderIcon(icon, index))}
      </div>
    );
  }
}
