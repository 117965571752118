/** @jsx createElement */
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Email } from '../svg/email';
import { Alert } from '../svg/alert';
import { EmailAlert } from '../svg/email-alert';
// Other
import { observer } from 'mobx-react';
import { createElement } from 'react';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Page061 } from './pages/page-06-1';

@observer
export class Slide26 extends AnimatedSlide {
  // Animation players
  players = {
    client: null,
    alert: null,
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');

    const { client, alert, border } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(5000);
        this.autoNext(2000);
      },
      // Step 3
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [{ targets: client, easing: 'easeOutQuad', opacity: 1, duration: 500 }],

      //
      // Step 3
      //
      [
        { targets: client, easing: 'easeOutQuad', opacity: 0.1, duration: 500 },
        {
          targets: alert,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-26 ${className}`}>
        {children}
        <div className="content">
          {/* Client */}
          <div
            className="player client"
            data-active="1"
            ref={node => (this.players.client = node)}
          >
            <Card IconComponent={Person} text="CLIENT" />
          </div>

          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              color="#54D5AE"
              title={'Client Request'}
              IconComponent={EmailAlert}
              subtitle={'Deeper Investigation'}
            />
          </div>
        </div>
      </div>
    );
  }
}
