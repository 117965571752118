/** @jsx createElement */
import { createElement, Component } from 'react';

export class SwipeHandler extends Component {
  static SWIPE_MAX_MS = 1500;
  static SWIPE_MIN_PX = 20;

  lastMouseX = null;
  lastMouseY = null;
  lastMouseDownTime = null;

  handleMouseDown = e => {
    const touchEventData = e.changedTouches ? e.changedTouches[0] : e;

    this.lastMouseX = touchEventData.pageX;
    this.lastMouseY = touchEventData.pageY;
    this.lastMouseDownTime = Date.now();
  };

  handleMouseUp = e => {
    const touchEventData = e.changedTouches ? e.changedTouches[0] : e;

    const timeElapsed = Date.now() - this.lastMouseDownTime;
    if (timeElapsed > SwipeHandler.SWIPE_MAX_MS) return;

    const xMovement = touchEventData.pageX - this.lastMouseX;
    const yMovement = touchEventData.pageY - this.lastMouseY;

    let direction = '';
    if (Math.abs(xMovement) > Math.abs(yMovement)) {
      if (Math.abs(xMovement) < SwipeHandler.SWIPE_MIN_PX) return;
      direction = xMovement > 0 ? 'right' : 'left';
    } else {
      if (Math.abs(yMovement) < SwipeHandler.SWIPE_MIN_PX) return;
      direction = yMovement > 0 ? 'down' : 'up';
    }

    switch (direction) {
      case 'up':
        this.props.onSwipeUp && this.props.onSwipeUp(e);
        break;
      case 'down':
        this.props.onSwipeDown && this.props.onSwipeDown(e);
        break;
      case 'left':
        this.props.onSwipeLeft && this.props.onSwipeLeft(e);
        break;
      case 'right':
        this.props.onSwipeRight && this.props.onSwipeRight(e);
        break;
    }
  };

  render() {
    const { children, className = '' } = this.props;

    return (
      <div
        className={`SwipeHandler ${className}`}
        onTouchStart={this.handleMouseDown}
        onTouchEnd={this.handleMouseUp}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
      >
        {children}
      </div>
    );
  }
}
