/** @jsx createElement */
import { createElement, Component } from 'react';
import './select.scss';
import { DropDown } from './svg/drop-down';

export class Select extends Component {
  onBlur = () => {
    // If nested in a popup, remove focus from select,
    // but apply to the container to maintain focus on ascendants
    this.props.nested && this.container && this.container.focus();
  };

  onChange = e => {
    const option = this.props.options.find(
      option => option.value.toString() === e.currentTarget.value
    );
    this.props.onChange(option, e);
  };

  render() {
    const {
      className = '',
      label = '',
      value = '',
      options = [],
      width = null,
    } = this.props;

    const selectedOption =
      value && options.find(option => option.value === value.value);

    return (
      <div
        className={`Select ${className}`}
        tabIndex={-1}
        ref={node => (this.container = node)}
      >
        <span className={'Select__Caption'}>{label}</span>
        <label className={`Select__Label`}>
          <select
            className={`Select__Input`}
            value={(selectedOption && selectedOption.value.toString()) || ''}
            onChange={this.onChange}
            onBlur={this.onBlur}
            style={{
              width: width ? `${width}px` : '100%',
            }}
          >
            {options.map(option => {
              return (
                <option
                  className={`Select__Option`}
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              );
            })}
          </select>
          <span className={'Select__Label__Arrow'}>
            <DropDown />
          </span>
        </label>
      </div>
    );
  }
}
