/** @jsx createElement */
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { AppActions } from '../app-actions';
import { PlayerTimeline } from '../players/timeline';

@observer
export class Slide19 extends AnimatedSlide {
  // Animation players
  players = {
    timeline: null,
    events: null,
  };

  timeline = {
    unit: 'day', // day, hour, minute
    units: 12,
    start: 0,
    width: 1000,
    details: [
      // Step 1
      {
        start: '00:00:00:00',
        end: '00:00:24:00',
        description:
          "eSentire's TRU identifies public reports of active attacks and triage begins.",
        active: true,
      },
      // Step 2
      {
        start: '00:00:24:00',
        description: 'Kaseya Issues Public Advisory.',
      },
      // Step 3
      {
        start: '00:00:27:00',
        description:
          'Threat Hunt Identifies Impacted Customer Within 30 Minutes of First Public Reports.',
      },
      // Step 4
      {
        start: '00:01:05:00',
        description:
          'TRU Security Advisory Drafted & Cross-Functional Team is Formed.',
      },
      // Step 5
      {
        start: '00:01:10:00',
        description: 'Indicators Added to the Threat Intelligence Platform.',
      },
      // Step 6
      {
        start: '00:01:21:00',
        description: 'SOC Blocks C2 IP Using AMP.',
      },
      // Step 7
      {
        start: '00:02:36:00',
        description: 'Security Advisory Sent to Customers.',
      },
      // Step 8
      {
        start: '00:03:03:00',
        description:
          'Additional impacted customers identified via MDR for Log threat hunts.',
      },
      // Step 9
      {
        start: '00:03:26:00',
        description: 'By 11pm TRU bans hashes across all customers.',
      },
      // Step 10
      {
        start: '00:07:24:00',
        description: 'Kaseya releases detection tool.',
      },
      // Step 11
      {
        start: '02:17:24:00', // 65:24:00
        description: 'TRU mines Kaseya detection tool for hunting leads.',
      },
      // Step 12
      {
        start: '02:21:46:00', // 69:46
        description:
          'The TRU team finalizes detection and investigation runbook for Kaseya.',
      },
      // Step 13
      {
        start: '08:22:24:00', // 214:22
        description:
          'Kaseya releases patch and begins restoring SaaS infrastructure.',
      },
      // Step 14
      {
        start: '10:01:18:00', // 241:18
        description: 'Second Security Advisory Sent to Customers.',
      },
      // Step 15
      {
        start: '10:19:24:00', // 259:24
        end: '10:20:00:00', // Approximate ending
        description: 'TRU Team Threat Intelligence Webinar with Customers.',
      },
    ],
  };

  // Run the first animation
  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.hideRightArrow();
    AppActions.disableAppScreenshot();

    this.steps = [
      // Step 1
      () => {},
    ];

    this.animations = [
      // Step 1
      [],
    ];

    // Play the first animation
    this.play();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
    AppActions.showRightArrow();
    AppActions.enableAppScreenshot();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-19 timeline-slide ${className}`}>
        {children}
        <div className="content">
          <PlayerTimeline
            events={this.timeline.details}
            unit={this.timeline.unit}
            width={this.timeline.width}
            units={this.timeline.units}
            start={this.timeline.start}
            alternate={this.timeline.alternate}
          />
        </div>
      </div>
    );
  }
}
