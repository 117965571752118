/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';

// Icons
import { Directory } from '../svg/directory';
import { InternetExplorer } from '../svg/internet-explorer';
import { Word } from '../svg/word';
import { Chrome } from '../svg/chrome';
import { Cloud } from '../svg/cloud';
import { CMD } from '../svg/cmd';
import { PowerShell } from '../svg/power-shell';

@observer
export class Slide04 extends AnimatedSlide {
  // Animation players
  players = {
    folder: null,
    process1: null,
    process2: null,
    process3: null,
    process4: null,
    process5: null,
    subProcess1: null,
    subProcess2: null,
    subProcess3: null,
    ie: null,
    process: null,
    info: null,
    connectors: null,
    cmd: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      folder,
      process1,
      process2,
      process3,
      process4,
      process5,
      subProcess1,
      incident,
      process,
      info,
      connectors,
      cmd,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(7000);
        this.autoNext(3000);
        connectors.classList.remove('highlight-path');
      },
      // Step 2
      () => {
        // this.autoNext(2000);
        connectors.classList.add('highlight-path');
        subProcess1.classList.remove('hide-title');
      },
      // Step 3
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        { targets: folder, opacity: 1, duration: 500, begin: activate },
        { targets: process1, opacity: 1, duration: 500, begin: activate },
        { targets: process3, opacity: 1, duration: 500, begin: activate },
        { targets: process4, opacity: 1, duration: 500, begin: activate },
        { targets: process5, opacity: 1, duration: 500, begin: activate },
        {
          targets: incident,
          opacity: 1,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: cmd,
          opacity: 1,
          duration: 200,
          delay: 2000,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        { targets: process1, opacity: 0.1, duration: 500, begin: activate },
        { targets: process3, opacity: 0.1, duration: 500, begin: activate },
        { targets: process4, opacity: 0.1, duration: 500, begin: activate },
        { targets: process5, opacity: 0.1, duration: 500, begin: activate },

        // Highlighted route
        {
          targets: connectors,
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        { targets: folder, opacity: 1, duration: 500, begin: activateShimmer },
        {
          targets: process2,
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: subProcess1,
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-04 ${className}`}>
        {children}
        <div className="content">
          {/* Incident info */}
          <div
            className="player incident"
            data-active="0"
            ref={node => (this.players.incident = node)}
          >
            <PlayerTextBlock
              title={'Incident Info'}
              sections={[
                {
                  title: ['PROCESS', 'POWERSHELL'],
                  grid: 2,
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['USER', 'PTHOMAS', 'HOST', 'LEGAL-GT6'],
                  grid: 2,
                  animate: true,
                  delay: 200,
                },
              ]}
            />
          </div>

          {/* CMD */}
          <div
            className="player cmd"
            data-active="0"
            ref={node => (this.players.cmd = node)}
          >
            <PlayerTextBlock
              title={'Command Line'}
              sections={[
                {
                  lines: 4,
                  animate: true,
                  delay: 2000,
                },
              ]}
            />
          </div>

          {/* Folder */}
          <div
            className="player folder"
            data-active="1"
            ref={node => (this.players.folder = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Directory}
              title={'EXPLORER.EXE'}
            />
          </div>

          {/* Process1 */}
          <div
            className="player process1"
            data-active="1"
            ref={node => (this.players.process1 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Chrome}
              title={'CHROME.EXE'}
            />
          </div>

          {/* Process2 */}
          <div
            className="player process2"
            data-active="1"
            ref={node => (this.players.process2 = node)}
          >
            <PlayerCircleIcon color={'#50C2E3'} Icon={CMD} title={'CMD.EXE'} />
          </div>

          {/* Process3 */}
          <div
            className="player process3"
            data-active="1"
            ref={node => (this.players.process3 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Word}
              title={'WINWORD.EXE'}
            />
          </div>

          {/* Process4 */}
          <div
            className="player process4"
            data-active="1"
            ref={node => (this.players.process4 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Cloud}
              title={'ONEDRIVE.EXE'}
            />
          </div>

          {/* Process5 */}
          <div
            className="player process5"
            data-active="1"
            ref={node => (this.players.process5 = node)}
          >
            <PlayerCircleIcon color={'#50C2E3'} Icon={CMD} title={'CMD.EXE'} />
          </div>

          {/* SubProcess1 */}
          <div
            className="player subProcess1"
            data-active="1"
            ref={node => (this.players.subProcess1 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={PowerShell}
              title={'POWERSHELL.EXE'}
            />
          </div>

          <div
            className={'connector-tree'}
            ref={node => (this.players.connectors = node)}
          >
            <div className="connectors right">
              <span />
            </div>
            <div className="connectors left">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
