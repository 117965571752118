/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class IconCloudShark extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#ffffff',
      size = 35,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        width={size}
        height={size}
        viewBox={`0 0 50 47`}
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd" clipRule="evenodd">
          <path
            d="M3.72664 31.9609C8.10428 30.5098 11.9563 29.8293 13.6388 29.5748C13.6478 29.5457 13.6571 29.5155 13.667 29.485C13.8469 28.9085 14.0889 28.0708 14.3307 27.0434C14.8159 24.9895 15.298 22.1782 15.2969 19.2097C15.2977 16.8727 14.9981 14.4432 14.2008 12.2028L13.1674 9.29856L16.0558 10.4392C24.632 13.8245 30.0459 18.9093 33.2915 23.1521C35.6207 26.1948 36.8465 28.8042 37.3673 30.0785C39.0183 30.3675 42.8543 31.1929 47.2655 33.1967C48.9697 31.2203 50 28.6565 50 25.8536C50 19.6035 44.8841 14.5366 38.5732 14.5366C38.5136 14.5366 38.4549 14.5403 38.3956 14.5411C38.4967 14.1325 38.556 13.7076 38.556 13.2676C38.556 10.3136 36.1379 7.91877 33.1551 7.91877C32.5137 7.91877 31.9009 8.03541 31.3298 8.23876C29.5426 3.42976 24.8763 0 19.4006 0C12.3817 0 6.69186 5.63497 6.69186 12.5862C6.69186 13.3082 6.75654 14.0144 6.87431 14.7028C2.87795 16.0423 0 19.7842 0 24.1943C0 27.3273 1.45253 30.1238 3.72664 31.9609Z"
            fillOpacity={opacity}
          />
          <path
            d="M36.3759 32.7502C36.2237 32.7272 36.1615 32.7202 36.1615 32.7202L35.2614 32.6103L34.9875 31.7547C34.9869 31.7547 34.9875 31.7547 34.9816 31.7382C34.9756 31.7208 34.9655 31.6912 34.9511 31.6501C34.9214 31.5678 34.8745 31.44 34.807 31.2713C34.6731 30.9337 34.4594 30.4328 34.1509 29.8062C33.5347 28.5532 32.5388 26.7977 31.0411 24.842C28.386 21.3781 24.1733 17.2874 17.6577 14.188C17.9909 15.8827 18.1209 17.5797 18.1217 19.2098C18.1141 25.7354 16.0543 31.235 16.0275 31.322L15.7278 32.1272L14.8673 32.2277C14.8673 32.2277 14.7554 32.2402 14.5205 32.2727C14.2852 32.3051 13.9367 32.3566 13.4936 32.4299C12.6062 32.5762 11.3378 32.8095 9.83385 33.158C8.87359 33.3795 7.81618 33.6488 6.70172 33.97C6.69579 34.1174 6.69183 34.2653 6.69183 34.4141C6.69183 41.3654 12.3816 47.0001 19.4005 47.0001C23.356 47.0001 26.8891 45.2102 29.2197 42.4039C30.2173 43.0123 31.3916 43.3642 32.649 43.3642C36.1728 43.3642 39.0451 40.608 39.1914 37.1539C41.3176 37.0417 43.2881 36.3517 44.9499 35.2426C40.7493 33.4914 37.2593 32.8813 36.3759 32.7502Z"
            fillOpacity={opacity}
          />
        </g>
      </svg>
    );
  }
}
