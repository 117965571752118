/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { activate } from '../helpers';
import { ArrowRight } from '../svg/arrow-right';
import { Page061 } from './pages/page-06-1';
import { Page062 } from './pages/page-06-2';

@observer
export class Slide08 extends AnimatedSlide {
  // Animation players
  players = {};

  // Run the first animation
  componentDidMount() {
    const { page, page2, arrow, label } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
        this.autoNext(2000);
      },
      () => {
        this.autoNext(1000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: page,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 1000,
          translateY: -50,
        },
      ],
      //
      // Step 2
      //
      [
        {
          targets: page,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 0,
        },
        {
          targets: page2,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activate,
        },
      ],
      //
      // Step 3
      //
      [
        {
          targets: arrow,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 0,
          translateY: 10,
          rotate: 90,
          begin: activate,
        },
        {
          targets: label,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 200,
          translateY: 10,
          begin: activate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-08 ${className}`}>
        {children}
        <div className="content">
          {/* page1 */}
          <div
            className="player page"
            data-active="1"
            ref={node => (this.players.page = node)}
          >
            <Page061 />
          </div>

          {/* page 2 */}
          <div
            className="player page2"
            data-active="0"
            ref={node => (this.players.page2 = node)}
          >
            <Page062 />
          </div>

          {/* arrow */}
          <div
            className="player arrow"
            data-active="0"
            ref={node => (this.players.arrow = node)}
          >
            <ArrowRight color="#F36C21" opacity={1} size={11} />
          </div>

          {/* label */}
          <div
            className="player label"
            data-active="0"
            ref={node => (this.players.label = node)}
          >
            <div className="labelbox">ThreatCase generated</div>
          </div>
        </div>
      </div>
    );
  }
}
