/** @jsx createElement */
// SVG
import { ComputerGroup } from '../svg/computer-group';
import { Shield } from '../svg/shield';
import { Server } from '../svg/server';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import { Badge } from '../badge';
import { DiskDrive } from '../svg/disk-drive';

@observer
export class Slide24 extends AnimatedSlide {
  // Animation players
  players = {
    computer: null,
    dots: null,
    disk: null,
    badge1: null,
    badge2: null,
    badge3: null,
    badge4: null,
    badge5: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      computer,
      dots,
      disk,
      badge1,
      badge2,
      badge3,
      badge4,
      badge5,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(7000);
        this.autoNext(3000);
      },
      // Step 2
      () => {
        badge2.classList.remove('dimmed', 'shimmer');
        // this.autoNext(4000);
      },
      // Step 3
      () => {
        badge2.classList.add('shimmer');
        setTimeout(() => {
          badge2.classList.add('dimmed');
        }, 200);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        { targets: computer, opacity: 1, duration: 500 },
        {
          targets: dots,
          opacity: 1,
          duration: 200,
          delay: 500,
          begin: activateShimmer,
        },
        {
          targets: disk,
          opacity: 1,
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: badge1,
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
        {
          targets: badge2,
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
        {
          targets: badge3,
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
        {
          targets: badge4,
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
        {
          targets: badge5,
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
      ],

      //
      // Step 2
      //
      [
        { targets: disk, opacity: 1, duration: 100, delay: 0, begin: shimmer },
        {
          targets: badge1,
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: badge2,
          opacity: 1,
          duration: 500,
          delay: 50,
          begin: activateShimmer,
        },
        {
          targets: badge3,
          opacity: 1,
          duration: 500,
          delay: 100,
          begin: activateShimmer,
        },
        {
          targets: badge4,
          opacity: 1,
          duration: 500,
          delay: 150,
          begin: activateShimmer,
        },
        {
          targets: badge5,
          opacity: 1,
          duration: 500,
          delay: 200,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        // Nothing really needed
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-24 ${className}`}>
        {children}
        <div className="content">
          {/* Badge 1 */}
          <div
            className="player badge badge1"
            data-active="0"
            ref={node => (this.players.badge1 = node)}
          >
            <Badge text={'6.144.167.23'} className={'blue'} />
            <PlayerDots width={330} rotate={'-26deg'} />
          </div>

          {/* Badge 2 */}
          <div
            className="player badge badge2"
            data-active="0"
            ref={node => (this.players.badge2 = node)}
          >
            <Badge text={'118.114.77.92'} className={'red'} />
            <PlayerDots rotate={'-14deg'} width={300} className={'red-dots'} />
          </div>

          {/* Badge 3 */}
          <div
            className="player badge badge3"
            data-active="0"
            ref={node => (this.players.badge3 = node)}
          >
            <Badge text={'6.144.167.23'} className={'blue'} />
            <PlayerDots rotate={'0deg'} width={290} />
          </div>

          {/* Badge 4 */}
          <div
            className="player badge badge4"
            data-active="0"
            ref={node => (this.players.badge4 = node)}
          >
            <Badge text={'6.144.167.23'} className={'blue'} />
            <PlayerDots rotate={'14deg'} width={300} />
          </div>

          {/* Badge 5 */}
          <div
            className="player badge badge5"
            data-active="0"
            ref={node => (this.players.badge5 = node)}
          >
            <Badge text={'198.187.28.0'} className={'blue'} />
            <PlayerDots rotate={'26deg'} width={330} className={''} />
          </div>

          {/* computer */}
          <div
            className="player computer"
            data-active="1"
            ref={node => (this.players.computer = node)}
          >
            <ComputerGroup color={'#ffffff'} />
          </div>

          {/* Animated dots */}
          <div
            className="player dots"
            data-active="0"
            ref={node => (this.players.dots = node)}
          >
            <PlayerDots className={'reverse-direction'} />
          </div>

          {/* Disk drive */}
          <div
            className="player disk"
            data-active="0"
            ref={node => (this.players.disk = node)}
          >
            <DiskDrive size={80} />
          </div>
        </div>
      </div>
    );
  }
}
