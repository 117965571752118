import { Slide01 } from '../../../components/sunwalker/slide-01';
import { Slide02 } from '../../../components/sunwalker/slide-02';
import { Slide03 } from '../../../components/sunwalker/slide-03';
import { Slide04 } from '../../../components/sunwalker/slide-04';
import { Slide05 } from '../../../components/sunwalker/slide-05';
import { Slide06 } from '../../../components/sunwalker/slide-06';
import { Slide07 } from '../../../components/sunwalker/slide-07';
import { Slide08 } from '../../../components/sunwalker/slide-08';
import { Slide09 } from '../../../components/sunwalker/slide-09';
import { Slide10 } from '../../../components/sunwalker/slide-10';
import { Slide11 } from '../../../components/sunwalker/slide-11';
import { Slide12 } from '../../../components/sunwalker/slide-12';
import { Slide13 } from '../../../components/sunwalker/slide-13';
import { Slide14 } from '../../../components/sunwalker/slide-14';
import { Slide15 } from '../../../components/sunwalker/slide-15';
import { Slide16 } from '../../../components/sunwalker/slide-16';
import { Slide17 } from '../../../components/sunwalker/slide-17';
import { Slide18 } from '../../../components/sunwalker/slide-18';
import { Slide19 } from '../../../components/sunwalker/slide-19';
import { Slide20 } from '../../../components/sunwalker/slide-20';
import { Slide21 } from '../../../components/sunwalker/slide-21';
import { Slide22 } from '../../../components/sunwalker/slide-22';

import { Bug } from '../../../components/svg/bug';
import { Eye } from '../../../components/svg/eye';
import { CheckShield } from '../../../components/svg/check-shield';
import { Bell } from '../../../components/svg/bell';
import { Clock } from '../../../components/svg/clock';
import { Shield } from '../../../components/svg/shield';

export const SUNWALKER_CATEGORY = {
  STAGE1: 'Initial Access + Lateral Movement',
  STAGE2: 'Investigation + Analysis',
  STAGE3: 'Endpoint Containment',
  STAGE4: 'Client Alert + Response',
};

export const SUNWALKER_COLUMNS = [
  [SUNWALKER_CATEGORY.STAGE1],
  [SUNWALKER_CATEGORY.STAGE2, SUNWALKER_CATEGORY.STAGE3],
  [SUNWALKER_CATEGORY.STAGE4],
];

export const SUNWALKER_CATEGORY_ICONS = {
  [SUNWALKER_CATEGORY.STAGE1]: Bug,
  [SUNWALKER_CATEGORY.STAGE2]: Eye,
  [SUNWALKER_CATEGORY.STAGE3]: CheckShield,
  [SUNWALKER_CATEGORY.STAGE4]: Bell,
};

export const sunwalkerSlides = [
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Sunwalker',
    subtitle: 'Scenario Overview',
    component: Slide01,
  },
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Sunwalker',
    subtitle: 'Scenario Overview',
    component: Slide02,
  },
  {
    title: 'Sunwalker',
    subtitle: 'Initial Access',
    product: 'MDR for endpoint',
    time: '00:00:00:00',
    component: Slide03,
    icon: Bug,
    category: SUNWALKER_CATEGORY.STAGE1,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-1.png'),
    description:
      'The adversary accesses the client network via VPN using compromised credentials for the system administrator. The unsecured admin credentials have a high level of privilege and multi-factor authentication (MFA) is not in place. This adversary activity begins on a Sunday afternoon, by design, and the initial access is not detected.',
  },
  {
    title: 'Sunwalker',
    subtitle: 'Reconnaissance',
    product: 'MDR for Endpoint',
    time: '00:00:01:00',
    component: Slide04,
    icon: Eye,
    category: SUNWALKER_CATEGORY.STAGE1,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-2.png'),
    description:
      "The adversary is observed performing a port scan inside the client's network, which is often the first step used by attackers before they execute an attack. Port scanning identifies open ports on the network that may enable unauthorized access.",
  },
  {
    title: 'Sunwalker',
    subtitle: 'Lateral Movement',
    product: 'MDR for endpoint',
    time: '00:00:53:00',
    component: Slide05,
    category: SUNWALKER_CATEGORY.STAGE1,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-3.png'),
    description:
      'The adversary is able to move laterally in the environment by accessing the domain controller over RDP using stolen credentials. Most ransomware actors are keenly attuned to remote access vulnerabilities, especially in the current work from home and remote work environment.',
  },
  {
    title: 'Sunwalker',
    subtitle: 'Execution',
    product: 'MDR for endpoint',
    time: '00:00:57:00',
    component: Slide06,
    category: SUNWALKER_CATEGORY.STAGE1,
    description:
      'Malicious PowerShell commands are executed on an internal server, which often evades detection. Bad actors use PowerShell as an attack vector because it can be difficult to detect. PowerShell is a part of the Windows operating system, and malicious activity can easily blend in with legitimate administrative activity.',
  },
  {
    title: 'Sunwalker',
    subtitle: 'Persistence',
    product: 'MDR for endpoint',
    time: '00:01:07:00',
    component: Slide07,
    category: SUNWALKER_CATEGORY.STAGE1,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-5.png'),
    description:
      'Log data shows the adversary created a new account in the domain to gain further domain rights.',
  },
  {
    title: 'Sunwalker',
    subtitle: 'Credential Extraction',
    product: 'MDR for endpoint',
    time: '00:01:28:00',
    component: Slide08,
    category: SUNWALKER_CATEGORY.STAGE2,
    appScreenshotUrl: [
      require('../../../assets/sunwalker/screen-06-01.png'),
      require('../../../assets/sunwalker/screen-06-02.png'),
    ],
    description:
      'The adversary extracts credentials from the server using Mimikatz, a well known post-exploitation tool that dumps passwords from memory. eSentire MDR for Endpoint detects the malicious PowerShell commands running Mimikatz activity and eSentire SOC cyber analysts begin their investigation.',
  },
  {
    title: 'Sunwalker',
    subtitle: 'SOC Alert And Host Isolation',
    product: 'MDR for endpoint',
    time: '00:01:57:00',
    component: Slide09,
    category: SUNWALKER_CATEGORY.STAGE3,
    clientCommunication: true,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-7.png'),
    description:
      "eSentire SOC cyber analysts investigate and respond rapidly to isolate the host on the client's behalf. The SOC alerts the client's team about credential dumping activity on the Windows server, notifies them the host has been isolated, and provides recommendations on next steps for remediation and further forensic investigation. The cyber analysts request acknowledgement of receipt from the client.",
  },
  {
    title: 'Sunwalker',
    subtitle: 'Access Removed',
    product: 'MDR for endpoint',
    time: '00:02:51:00',
    component: Slide10,
    category: SUNWALKER_CATEGORY.STAGE4,
    icon: CheckShield,
    description:
      "eSentire SOC cyber analysts work to shut down the VPN Tunnel and cut off the adversary's access to the environment.",
  },
  {
    title: 'Sunwalker',
    subtitle: 'Adversary Regains Access',
    product: 'Log data',
    time: '00:02:53:00',
    component: Slide11,
    category: SUNWALKER_CATEGORY.STAGE4,
    icon: Bell,
    description:
      'Within two minutes the adversary is able to re-establish VPN connection using a second compromised administrator account with a high level of privilege. It remains a challenge that multi-factor authentication (MFA) is not in place.',
  },
  {
    title: 'Sunwalker',
    subtitle: 'Access Removed',
    product: 'Log data',
    time: '00:03:16:00',
    component: Slide12,
    category: SUNWALKER_CATEGORY.STAGE4,
    description:
      "A second VPN Tunnel is discovered and eSentire SOC cyber analysts work to cut off the adversary's access to the environment again.",
  },
  {
    title: 'Sunwalker',
    subtitle: 'Persistent Attacker Returns A Third Time',
    product: 'MDR for Endpoint',
    time: '00:07:33:00',
    component: Slide13,
    category: SUNWALKER_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-11.png'),
    description:
      "The adversary returns after hours on Sunday night, establishes a connection to a file server using a second compromised administrator account. The adversary attempts to deploy Suncrypt ransomware to multiple internal hosts using administrative credentials and PsExec. Suncrypt delivers its malware payload via a PowerShell command script and when executed the malware calls out and begins sending information regarding the victim’s machine as well as other data. The action is blocked by the customer's VMWare Carbon Black Defense agent.",
  },
  {
    title: 'Sunwalker',
    subtitle: 'Sandbox Analysis Of Suncrypt Ransomware',
    product: 'MDR for Endpoint',
    time: '00:07:43:00',
    component: Slide14,
    category: SUNWALKER_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-12.png'),
    description:
      'eSentire SOC cyber analysts retrieve a copy of the blocked ransomware payload and analyze it in the malware sandbox. Our threat hunting experts indicate it is the Suncrypt ransomware variant.',
  },
  {
    title: 'Sunwalker',
    subtitle: 'Threat Intelligence Lookup On Suncrypt C2',
    product: 'MDR for Endpoint',
    time: '00:07:46:00',
    component: Slide15,
    category: SUNWALKER_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-13.png'),
    description:
      "SOC cyber analysts review historical threat data on the C2 IP in eSentire's Threat Intelligence Platform compiled from threat research from incidents across our customer base and associations with known threat actor groups and nation states. Threat intelligence indicates the IP is associated with known ransomware activity.",
  },
  {
    title: 'Sunwalker',
    subtitle: 'eSentire SOC Blocks C2 IP Using AMP',
    product: 'MDR for Network',
    time: '00:07:53:00',
    component: Slide16,
    category: SUNWALKER_CATEGORY.STAGE4,
    appScreenshotUrl: [
      require('../../../assets/sunwalker/screen-14.png'),
      require('../../../assets/sunwalker/screen-14-02.png'),
      require('../../../assets/sunwalker/screen-14-03.png'),
      require('../../../assets/sunwalker/screen-14-04.png'),
    ],
    description:
      "Now that the investigation has confirmed the ransomware threat, our SOC cyber analyst blocks the C2 IP across all client networks and environments. eSentire's Atlas XDR platform automatically protects all client assets against these malicious ransomware-related IOCs and IPs, leveraging our global IP blocklist and amplifying Security Network Effects to our entire customer base.",
  },
  {
    title: 'Sunwalker',
    subtitle: 'Attacker Attempts To Disable Defenses',
    product: 'MDR for Endpoint',
    time: '00:07:55:00',
    component: Slide17,
    category: SUNWALKER_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-15.png'),
    description:
      'Once the adversary realizes MDR for Endpoint has detected and blocked the ransomware payload, they attempt to remove the VMware Carbon Black agent on the file server. The attempt fails.',
  },
  {
    title: 'Sunwalker',
    subtitle: 'Second Ransomware Variant Is Blocked',
    product: 'MDR for Endpoint',
    time: '00:08:10:00',
    component: Slide18,
    category: SUNWALKER_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-16.png'),
    description:
      "Thirty minutes later the adversary uploads a Netwalker ransomware binary on the file server. eSentire SOC cyber analysts deduce that the adversary has pivoted to working with a different ransomware group associated with Netwalker here, potentially as an affiliate partner where they have agreed to split a share of the profits. Security researchers have shown that SunCrypt occasionally opens a partner program. They attempt to execute against the host list and the payload is blocked by customer's VMWare Carbon Black Defense.",
  },
  {
    title: 'Sunwalker',
    subtitle: 'SOC Bans Ransomware Across All Endpoints',
    product: 'MDR for Endpoint',
    time: '00:11:47:00',
    component: Slide19,
    category: SUNWALKER_CATEGORY.STAGE4,
    appScreenshotUrl: require('../../../assets/sunwalker/screen-17.png'),
    description:
      'eSentire SOC cyber analysts ban the Suncrypt binary in MDR for Endpoint, effectively protecting all of the customer’s endpoints. Now that an IoC is created in the Threat Intelligence Platform, it automates threat hunts across the entire customer base for these banned Suncrypt Ransomware hashes.',
  },
  {
    title: 'Sunwalker',
    subtitle: 'SOC Bans Ransomware Across All Endpoints',
    product: 'MDR for Endpoint',
    time: '00:14:16:00',
    component: Slide20,
    category: SUNWALKER_CATEGORY.STAGE4,
    description:
      "eSentire SOC cyber analysts ban the Netwalker binary in MDR for Endpoint, effectively protecting all of the customer's endpoints. Now that an IoC is created in the Threat Intelligence Platform, it automates threat hunts across the entire customer base for these banned Netwalker Ransomware hashes.",
  },
  {
    title: 'Sunwalker',
    subtitle: 'Post-Incident Follow-Up',
    product: 'MDR for Endpoint',
    time: '00:20:13:00',
    clientCommunication: true,
    component: Slide21,
    category: SUNWALKER_CATEGORY.STAGE4,
    description:
      'eSentire SOC cyber analysts complete a post-incident review of logs and security events with the client, updating them on findings, as well as response & remediation actions.',
  },
  // Timeline
  {
    title: 'Sunwalker',
    subtitle: 'Timeline Summary',
    component: Slide22,
    icon: Clock,
    timelineSummary: true,
    category: SUNWALKER_CATEGORY.ESCALATION,
  },
];
