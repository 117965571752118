/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';

@observer
export class Slide05 extends AnimatedSlide {
  // Animation players
  players = {
    login: null,
    client: null,
  };

  // Run the first animation
  componentDidMount() {
    const { login, client } = this.players;

    this.steps = [
      () => {
        this.animateProgress(3000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: client,
          opacity: 1,
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: login,
          opacity: 1,
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-05 ${className}`}>
        {children}
        <div className="content">
          {/* login */}
          <div
            className="player login"
            data-active="0"
            ref={node => (this.players.login = node)}
          >
            <PlayerTextBlock
              title={'Login Attempts'}
              width={'250px'}
              showDots={true}
              className={''}
              sections={[
                {
                  title: [
                    'IP',
                    '213.112.147.15',
                    'GEO',
                    'Stockholm',
                    'COUNT',
                    '32',
                    'IP',
                    '52.129.34.145',
                    'GEO',
                    'Cambridge',
                    'COUJNT',
                    '54',
                  ],
                  grid: 2,
                },
                {
                  title: ['LOGIN', 'SUCCESSFUL'],
                  grid: 2,
                  colorCells: 2,
                  color: 'red',
                },
              ]}
            />
          </div>

          {/* Client */}
          <div
            className="player client"
            data-active="0"
            ref={node => (this.players.client = node)}
          >
            <Card IconComponent={Person} IconSize={60} text={'DTHOMAS'} />
          </div>
        </div>
      </div>
    );
  }
}
