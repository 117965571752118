import { string } from 'prop-types';
import { observable } from 'mobx';
const Papa = require('papaparse');

export const GLOBAL_FILTER_OPTION = 'Global';

// Get the timezone offset for shifting the time into our timezone.
// This should be a two-digit number like 06, so that the format later
// will be GMT-0600.
const timeZone = String(new Date().getTimezoneOffset() / 60).padStart(2, '0');

const papaParseConfig = {
  // It's a remote file
  download: true,
  // Set to false to disable turning each row into an object with header keys
  header: true,
  // Set to false to disable converting columns into their true types (e.g,
  // string to number)
  dynamicTyping: true,

  // Transforms each value, in this case making all values lowercase
  transform: (value, column) => {
    if (/^\d\d\d\d-\d\d-\d\d/.test(value)) {
      // NOTE: We fix a safari issue by using slashed instead of dashes. This
      // will otherwise produce invalid dates.
      return new Date(`${value.split('-').join('/')} GMT-${timeZone}00`);
    }

    const check = value.trim();

    if (check === '.*') {
      return GLOBAL_FILTER_OPTION;
    }

    return check;
  },
};

const monthYearFormat = new Intl.DateTimeFormat(undefined, {
  month: 'short',
  year: 'numeric',
}).format;

const shortMonth = new Intl.DateTimeFormat(undefined, {
  month: 'short',
}).format;

export const ChartDataStore = (() => {
  /** Will hold the result of the promise */
  let _riskData;
  /** Will hold the result of processing asset data */
  let _assetData;

  class ChartDataStore {
    @observable loadingRiskData = false;
    @observable loadingAssetData = false;

    /**
     * Process the risk data initially to give each record an object structure to make it more
     * sensible to work with
     */
    async processRiskData() {
      if (!_riskData) {
        this.loadingRiskData = true;

        _riskData = new Promise(resolve => {
          Papa.parse(require('./extract_risk_final_calculations.csv'), {
            ...papaParseConfig,

            complete: rawRiskData => {
              this.loadingRiskData = false;
              resolve(rawRiskData.data);
            },
          });
        });
      }

      return _riskData;
    }

    /**
     * Process the asset data initially to give each record an object structure to make it more
     * sensible to work with
     */
    async processAssetData() {
      if (!_assetData) {
        this.loadingAssetData = true;

        _assetData = new Promise(resolve => {
          Papa.parse(
            require('./extract_impact_assets_and_tickets_by_client.csv'),
            {
              ...papaParseConfig,
              complete: rawAssetData => {
                this.loadingAssetData = false;
                resolve(rawAssetData.data);
              },
            }
          );
        });
      }

      return _assetData;
    }
  }

  return new ChartDataStore();
})();

/**
 * Retrieves the header names of each column within the data
 */
export function getHeader(data: any[]) {
  return data[0].map(label => label.trim());
}

/**
 * This retrieves all unique values of a single column in the data.
 *
 * @param {any[]} data The dataset to trudge through
 * @param {string} column The name of the column to retrieve unique names from
 * @param {(record): boolean} test A test callback to filter valid columns
 */
export function unique(data: any[], column: string, test?: Function) {
  const items = new Set();

  if (test) {
    data.forEach(record => {
      const item = record[column];

      if (item && test(record)) {
        items.add(item);
      }
    });
  } else {
    data.forEach(record => {
      const item = record[column];
      if (item) items.add(item);
    });
  }

  return [...items.values()];
}

/**
 * Performs a query on the data and returns all records that pass the conditional
 *
 * @param {any[]} data The dataset to trudge through
 * @param {(record): boolean} test
 */
export function query(data: any[], test: Function) {
  return data.filter(test);
}

/**
 * This is the formula for calculated probability relative to sensor counts.
 *
 * @param inputProbability Input probability value 0 - 1
 * @param sensors Number of sensors
 */
export function projectToSensorCount(
  inputProbability: number,
  sensors: number
) {
  inputProbability = 1 - inputProbability;
  inputProbability = Math.pow(inputProbability, sensors);
  inputProbability = 1 - inputProbability;
  inputProbability = inputProbability;
  return inputProbability;
}

/**
 * Retrieves a list of all unique attack types in the asset data set.
 */
export async function getAssetDataAttackTypes() {
  const assetData = await ChartDataStore.processAssetData();
  const priority = ['Malicious Code', 'Phishing', 'Known Exploit'];

  return unique(assetData, 'classification').sort((a: string, b: string) => {
    const p1Index = priority.indexOf(a);
    const p2Index = priority.indexOf(b);

    if (p1Index > -1 && p2Index > -1) {
      return p1Index - p2Index;
    } else if (p1Index > -1) {
      return -1;
    } else if (p2Index) {
      return 1;
    } else {
      return a.localeCompare(b);
    }
  });
}

/**
 * Retrieves a list of all unique countries in the asset data set.
 */
export async function getAssetDataCountries() {
  const assetData = await ChartDataStore.processAssetData();

  return unique(assetData, 'country');
}

/**
 * Retrieves a list of all unique countries in the risk dataset
 */
export async function getRiskDataCountries() {
  const riskData = await ChartDataStore.processRiskData();

  return unique(
    riskData,
    'group_name',
    record => record.group_type === 'Country'
  ).sort();
}

/**
 * Get the categories available within the risk data
 */
export async function getRiskDataCategories() {
  const riskData = await ChartDataStore.processRiskData();

  return unique(riskData, 'group_type');
}

/**
 * Retrieves a list of all industries found within the risk data.
 */
export async function getRiskDataFilters(category?: string) {
  let demoFilter: string[] | null = [];

  if (category === 'Industry') {
    demoFilter = getDemoIndustryFilter();
  } else if (category === 'Country') {
    demoFilter = null;
  }

  const riskData = await ChartDataStore.processRiskData();
  const confidence = new Map();

  unique(riskData, 'group_name', record => {
    if (
      (!category || record.group_type === category) &&
      (!demoFilter || demoFilter.indexOf(record.group_name) > -1)
    ) {
      confidence.set(
        record.group_name,
        Math.max(record.confidence, confidence.get(record.group_name) || 0)
      );
    }

    return false;
  });

  // Sort by confidence DESC
  const industries = Array.from(confidence.entries());
  industries.sort((a, b) => b[1] - a[1]);

  // Return just the names
  return industries.map(i => i[0]);
}

export async function getRiskDataAttackTypes() {
  const riskData = await ChartDataStore.processRiskData();
  const attackFilter = new Set(['Malware', 'Exploit', 'Phishing']);

  return unique(
    riskData,
    'attack_type',
    record =>
      record.group_type === 'Industry' && attackFilter.has(record.attack_type)
  );
}

export interface IRiskRateTableOptions {
  /** The category of information we are picking */
  category: string;
  /** Indicate the reference date to use (usually today) */
  date: Date;
  /** number of sensors */
  sensorCount: number;
  /** Indicate the span of information to reveal */
  span: 'month' | 'year';
  /** The industry to retrieve the table information */
  filter: string;
}

export async function riskRatesTable(options: IRiskRateTableOptions) {
  const { category, date, filter, span, sensorCount } = options;

  const attackFilter = new Set(['Malware', 'Exploit', 'Phishing']);
  const riskData = await ChartDataStore.processRiskData();
  const intervalSpan = span === 'month' ? '{month}' : 'Dec';
  const month = date.getMonth();
  let year = date.getFullYear();

  if (span === 'year') {
    let maxYear = 0;
    riskData.forEach(record => {
      if (record.interval_span === 'Dec') {
        maxYear = Math.max(maxYear, record.end_time.getFullYear());
      }
    });

    year = Math.min(year, maxYear);
  }

  const queryData = query(
    riskData,
    record =>
      record.group_name === filter &&
      record.group_type === category &&
      record.interval_span === intervalSpan &&
      attackFilter.has(record.attack_type) &&
      ((span === 'year' && record.end_time.getFullYear() === year) ||
        span === 'month')
  );

  // Find the highest confidence entries
  const output = {};
  // Also find the nearest record to the current date
  const nearest = {};
  const checkDate: any = new Date();
  checkDate.setFullYear(year);
  checkDate.setMonth(month);

  queryData.forEach(record => {
    const attack = record.attack_type;

    if (!output[attack]) {
      nearest[attack] = Math.abs(record.end_time - checkDate);

      output[attack] = {
        confidence: record.confidence,
        min:
          Math.round(
            projectToSensorCount(record.lower_prevalence_bound, sensorCount) *
              100
          ) / 100,
        max:
          Math.round(
            projectToSensorCount(record.upper_prevalence_bound, sensorCount) *
              100
          ) / 100,
        mean:
          Math.round(
            projectToSensorCount(
              record.postive_samples / record.sample_size,
              sensorCount
            ) * 100
          ) / 100,
      };
    } else if (output[attack].confidence <= record.confidence) {
      const currentNearest = nearest[attack] || Number.MAX_SAFE_INTEGER;
      const check = (nearest[attack] = Math.abs(record.end_time - checkDate));

      if (check <= currentNearest) {
        nearest[attack] = check;
        output[attack] = {
          dateRange: [record.start_time, record.end_time],
          date: record.end_time,
          confidence: record.confidence,
          min:
            Math.round(
              projectToSensorCount(record.lower_prevalence_bound, sensorCount) *
                100
            ) / 100,
          max:
            Math.round(
              projectToSensorCount(record.upper_prevalence_bound, sensorCount) *
                100
            ) / 100,
          mean:
            Math.round(
              projectToSensorCount(
                record.postive_samples / record.sample_size,
                sensorCount
              ) * 100
            ) / 100,
        };
      }
    }
  });

  return output;
}

export interface IComparativeRatesOptions {
  /** The category to filter by */
  category: string;
  /** The filter for which to retrieve comparative industries for risk rates */
  filter: string;
}

/**
 * Retrieves industries that should be weighed against a provided filter when getting
 * riskRate data
 */
export async function getComparativeRates(options: IComparativeRatesOptions) {
  // We only need comparison relatively so we don't care what sensor count is present
  const riskData = await riskRates({
    category: options.category,
    sensors: 1,
  });

  const attackTypes = await getRiskDataAttackTypes();
  let allRiskAverage = 0;

  // Get all risk data industries and calculate total risk (simple addition)
  const totals = riskData.map(risk => {
    let total = 0;
    attackTypes.forEach(type => {
      total += risk[type];
    });

    // Use all risk total to find the mean of risk so we can find an average filter
    allRiskAverage += total;

    return {
      filter: risk.filter,
      total,
    };
  });

  // Sort descending
  totals.sort((a, b) => b.total - a.total);
  // Average our average
  allRiskAverage /= totals.length;

  // We now pick the top and bottom and most average filter that is NOT the input filter
  // Instead of being 100% accurate, we'll make it a fuzzy selection so we get some variety everytime
  // we render the page
  let range = Math.floor(totals.length / 3);
  let maxIndex = Math.floor(Math.random() * range);
  let minIndex = range * 2 + Math.floor(Math.random() * range) - 1;
  let avgIndex = range + Math.floor(Math.random() * range);
  let max = totals[maxIndex];
  let min = totals[minIndex];
  let average = totals[avgIndex];

  if (max.filter === options.filter) {
    max = totals[maxIndex + 1];
  }

  if (min.filter === options.filter) {
    min = totals[minIndex - 1];
  }

  if (average.filter === options.filter) {
    average = totals[avgIndex - 1];
  }

  if (average.filter === max.filter) {
    average = totals[avgIndex + 1];
  }

  if (average.filter === min.filter) {
    average = totals[avgIndex + 1];
  }

  return [options.filter, max.filter, average.filter, min.filter];
}

export interface IRiskRateOptions {
  /** This is the category to filter the risk rates on */
  category: string;
  /** The number of sensors specified */
  sensors: number;
}

/**
 * This provides the data for the risk rates chart
 */
export async function riskRates(options: IRiskRateOptions) {
  const { sensors } = options;
  const riskData = await ChartDataStore.processRiskData();
  const countries = await getRiskDataCountries();

  // Only include these groups for now
  const attackFilter = new Set(['Malware', 'Exploit', 'Phishing']);
  let industryFilter;

  if (options.category === 'Industry') {
    industryFilter = new Set(getDemoIndustryFilter());
  } else {
    industryFilter = new Set(countries);
  }

  // First extract records with no group name of ".*" and get the row for
  // similar group name that has the smallest sample_size
  const groups = new Set<string>();

  const queryData = query(riskData, (row: any) => {
    const { group_type, group_name, attack_type } = row;

    if (group_type === options.category) {
      if (attackFilter.has(attack_type) && industryFilter.has(group_name)) {
        groups.add(group_name);
        return true;
      }
    }

    return false;
  });

  const means = {};

  // Analyze each group category found and extract the metrics from the chart
  groups.forEach(group_name => {
    means[group_name] = means[group_name] || {};

    queryData.forEach(row => {
      const {
        interval_span,
        confidence,
        postive_samples,
        sample_size,
        attack_type,
      } = row;

      if (row.group_name === group_name) {
        if (interval_span === 'Dec' && confidence > 0.95) {
          const value =
            Math.round(
              projectToSensorCount(postive_samples / sample_size, sensors) * 100
            ) / 100;
          means[group_name][attack_type] = value;
        } else {
          // Here what I'm doing is registering that the group_name and
          // attack_type exist, so that data processing later will be easier to
          // process. However, I don't want to explicitly set the value to zero,
          // because if it has a previous value, it needs to keep that. Having
          // the values even if they're zero, will prevent bugs in the chart
          means[group_name][attack_type] = means[group_name][attack_type] || 0;
        }
      }
    });
  });

  const output: ({ filter: string } & {
    [attackType: string]: number;
  })[] = [];

  // Massage the data to something more useable
  for (const industry in means) {
    output.push({ filter: industry, ...means[industry] });
  }

  return output;
}

export enum Scenario {
  /** Indicates the average probability of an event happening */
  AVERAGE,
  /** Indicates lowest probabilities of events happening */
  BEST,
  /** Indicates the highest probability of an event happening */
  WORST,
}

export enum Interval {
  INSTANTANEOUS,
  CUMULATIVE,
}

export interface IProbabilityCalculatorData {
  /** Data unique key. Combo of industry:attack */
  key: string;
  /** Industry name of the data point */
  industry: string;
  /** Attack type of the data point */
  attack: string;
  /** Name of the month for the data point */
  month: string;
  /** The rendered value of the data point */
  value: number;
  /** Additional confidence metric for the data point */
  confidence: number;
}

/**
 * Indicates which graph lines we want to output in the data.
 * Each line represents an industry and number of attacks for a
 * given attack type over time.
 */
export interface IProbabilityCalculatorSeries {
  /** The category of the series (ie - Industry, Country) */
  category: string;
  /** Name of the industry to include */
  filter: string;
  /** The attack types to include for the industry */
  attackType: string;
}

/**
 * Options to feed into the method for calculating data output for
 * the probability calculator.
 */
export interface IProbabilityCalculatorDataOptions {
  /**
   * Name of the industry data is desired. Use
   * unique('group_name', record => record.group_type === 'industry')
   * for list of industries.
   *
   * This is a list of all industries desired in the data
   */
  series: IProbabilityCalculatorSeries[];
  /** Number of sensors */
  sensorCount: number;
  /**
   * This makes the data reflect the lower probability range to
   * the higher probability range (BEST) will output the lowest chance
   * of something happening and a (WORST) will show the highest
   */
  scenario: Scenario;
  /**
   * This defines if we are looking for cumulative or interval data
   */
  isCumulative: boolean;
  /**
   * This indicates the data should include compounded results
   */
  includeCompounded: boolean;
}

/**
 * This computes and determines which compounded data series to include based on the series
 * included already.
 */
function createCompoundedSeries(series: IProbabilityCalculatorSeries[]) {
  const filterToSeries = new Map<string, Map<string, string[]>>();
  const out: IProbabilityCalculatorSeries[] = [];

  series.forEach(singleSeries => {
    let category = filterToSeries.get(singleSeries.category);

    if (!category) {
      category = new Map<string, string[]>();
      filterToSeries.set(singleSeries.category, category);
    }

    category.set(singleSeries.filter, category.get(singleSeries.filter) || []);
    (category.get(singleSeries.filter) || []).push(singleSeries.attackType);
  });

  filterToSeries.forEach((category, categoryName) => {
    category.forEach((check, filter) => {
      // We must have more than two attack types to generate a combined risk bar
      if (check.length < 2) return;

      // Create the combined items IN THIS ORDER
      const combined: string[] = [];
      if (check.indexOf('Malware') > -1) combined.push('malware');
      if (check.indexOf('Phishing') > -1) combined.push('phishing');
      if (check.indexOf('Exploit') > -1) combined.push('exploit');

      // Ensure we have results to work with
      if (combined.length > 1) {
        out.push({
          category: categoryName,
          filter,
          attackType: combined.join('_or_'),
        });
      }
    });
  });

  return out;
}

const compoundedAttackTypes = new Set([
  'malware_or_phishing',
  'malware_or_exploit',
  'phishing_or_exploit',
  'malware_or_phishing_or_exploit',
]);

/**
 * Looks through an object for compounded attack type name keys and renames them
 * to Compounded Risk.
 */
function renameCompoundedAttackTypes(obj: { [key: string]: any }) {
  let didRename = false;

  Object.keys(obj).forEach(name => {
    if (compoundedAttackTypes.has(name)) {
      const contents = obj[name];
      delete obj[name];
      obj['Compounded Risk'] = contents;
      didRename = true;
    }
  });

  return didRename;
}

/**
 * Takes the input series and provides all of the series that will be provided for the calculator data
 */
export function probabilityCalculatorSeries(
  series: IProbabilityCalculatorSeries[],
  includeCompounded: boolean
) {
  // If the compounded data is to be included, calculate the series that will provided those results
  // and add them to the input series.
  if (includeCompounded) {
    series = series.concat(createCompoundedSeries(series)).map(item => ({
      category: item.category,
      filter: item.filter,
      attackType: compoundedAttackTypes.has(item.attackType)
        ? 'Compounded Risk'
        : item.attackType,
    }));
  }

  return series;
}

/**
 * This provides the data for the Instantaneous values of the probability
 * calculator chart
 */
export async function probabilityCalculator(
  options: IProbabilityCalculatorDataOptions
) {
  let {
    series,
    sensorCount = 1,
    isCumulative = false,
    includeCompounded = false,
  } = options;

  // Get all of the output series this data will reveal
  const allSeries = probabilityCalculatorSeries(series, includeCompounded);

  // If the compounded data is to be included, calculate the series that will provided those results
  // and add them to the input series.
  if (includeCompounded) {
    series = series.concat(createCompoundedSeries(series));
  }

  // Get all attack types so we can default the attack type data for each entry
  const attackTypes = await getRiskDataAttackTypes();

  // Gather the unique attack types we want from our data per each filter and category
  const uniqueSeries = {};
  series.forEach(item => {
    const categoryName = item.category;
    const filterName = item.filter;

    const filters = uniqueSeries[categoryName] || {};
    uniqueSeries[categoryName] = filters;

    const attacks = filters[filterName] || {};
    filters[filterName] = attacks;
    // Map the attack name to the proper data type attack name
    attacks[item.attackType] = true;
  });

  // Retrieve the transformed data we need to process for information
  const riskData = await ChartDataStore.processRiskData();

  // Filter the data
  let queryData;

  if (!isCumulative) {
    queryData = query(
      riskData,
      record =>
        // This means the data for each element is month by month
        record.interval_span === '{month}' &&
        // This ensures the data is in the right category for the series
        uniqueSeries[record.group_type] &&
        // This sees if the data record applies to one of our industries.
        uniqueSeries[record.group_type][record.group_name] &&
        // Filter out any attack types not included as a series request
        uniqueSeries[record.group_type][record.group_name][
          record.attack_type
        ] &&
        // record.confidence > 0.98 &&
        true
    );
  } else {
    const intervalFilter = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    // The cumulative information for each month bucket is already present within the data.
    // We must retrieve the records that represent a larger timeframe to see the calculated cumulative threat
    queryData = query(
      riskData,
      record =>
        // This grabs records who are processed for month over month accumulation
        intervalFilter.indexOf(record.interval_span) > -1 &&
        // This ensures the data is in the right category for the series
        uniqueSeries[record.group_type] &&
        // This sees if the data record applies to one of our industries.
        uniqueSeries[record.group_type][record.group_name] &&
        // Filter out any attack types not included as a series request
        uniqueSeries[record.group_type][record.group_name][
          record.attack_type
        ] &&
        // record.confidence > 0.98 &&
        // record.start_time &&
        true
    );
  }

  const calculated: { [key: string]: { [key: string]: any } } = {
    combined: {
      combined: {},
    },
  };

  // Process the records for the proper values for the chart
  // We process our records into objects to dedup situations in the data should
  // the condition arise
  queryData.forEach(record => {
    const observed = record.postive_samples / record.sample_size;
    let observedValue = 0;
    let minValue = 0;
    let maxValue = 0;

    // Calculated the ranges of data
    minValue =
      Math.round(
        projectToSensorCount(record.lower_prevalence_bound, sensorCount) * 100
      ) / 100;
    maxValue =
      Math.round(
        projectToSensorCount(record.upper_prevalence_bound, sensorCount) * 100
      ) / 100;
    observedValue =
      Math.round(projectToSensorCount(observed, sensorCount) * 100) / 100;

    // Make sure a record exists for the industry indicated in this record
    let industry = calculated[record.group_name];

    if (!industry) {
      industry = {};
      calculated[record.group_name] = industry;
    }

    // The month this is calculated for is the start month, unless
    // the end month is greater than the start month. We put the month
    // and year together to make a proper key.
    let date = new Date(record.start_time);
    let monthNumber = date.getMonth();
    let yearNumber = date.getFullYear();

    let month = new Date(yearNumber, monthNumber).toISOString();

    if (record.end_time) {
      date = new Date(record.end_time);
      monthNumber = date.getMonth();
      yearNumber = date.getFullYear();

      let endMonth = new Date(yearNumber, monthNumber).toISOString();
      if (endMonth !== month) month = endMonth;
    }

    // Create the record for the attack itself
    let attackCategories = industry[record.attack_type];

    if (!attackCategories) {
      attackCategories = {};
      industry[record.attack_type] = attackCategories;
    }

    let values = attackCategories[month];

    if (!values) {
      values = {
        value: 0,
        min: 0,
        max: 0,
        confidence: 0,
      };
      attackCategories[month] = values;
    }

    values.min = isNaN(minValue) ? 0 : minValue;
    values.max = isNaN(maxValue) ? 0 : maxValue;
    values.value = isNaN(observedValue) ? 0 : observedValue;
    values.confidence = isNaN(record.confidence) ? 0 : record.confidence;
  });

  // Map all of the results to chart style data points
  const output: { series: IProbabilityCalculatorSeries[]; data: any[] } = {
    series: [],
    data: [],
  };
  const outMonths = {};
  const allIndustries = new Set();

  Object.keys(calculated).forEach(industryName => {
    const industry = calculated[industryName];

    Object.keys(industry).forEach(attackType => {
      const months = industry[attackType];

      Object.keys(months).forEach(month => {
        const values = months[month];

        const outMonth = outMonths[month] || {};
        outMonths[month] = outMonth;
        outMonth[industryName] = outMonth[industryName] || {};
        allIndustries.add(industryName);

        outMonth[industryName][attackType] = {
          ...values,
        };

        // Default the attack types that may not exist on the object
        attackTypes.forEach(type => {
          outMonth[industryName][type] = outMonth[industryName][type] || {
            value: 0,
            min: 0,
            max: 0,
            confidence: 0,
          };
        });
      });
    });
  });

  Object.keys(outMonths).forEach(month => {
    const outMonth = outMonths[month];

    // Each month needs to have the industry defaulted in case the industry does not exist
    // for a given month
    allIndustries.forEach(name => {
      outMonth[name] = outMonth[name] || {};

      attackTypes.forEach(type => {
        outMonth[name][type] = outMonth[name][type] || {
          value: 0,
          min: 0,
          max: 0,
          confidence: 0,
        };
      });

      // Make sure any compounded name is simply compounded risk
      renameCompoundedAttackTypes(outMonth[name]);
    });

    output.data.push({
      month: new Date(month).getTime(),
      ...outMonth,
    });
  });

  // Sort by date
  output.data.sort((a, b) => a.month - b.month);
  // Include all series used in this dataset
  output.series = allSeries;

  return output;
}

export interface IAssetVulnerabilityOptions {
  /** This filters the data based on the attack type that takes place */
  attackTypeFilter?: string;
  filter: string;
  maxBuckets: number;
  category: string;
  /**
   * When this is set, this will trim any buckets that are filled
   * with too small of a percentage of clients from the beginning and
   * the end of the list of buckets. This will make sure the bulk of
   * the buckets are spread across a greater number of buckets and are not clustered
   * due to outliers. The trimmed items will be aggregated into the end buckets so they
   * are not lost from the data.
   * Default is 0.005 (half a percent)
   */
  trimSmallBucketValues?: number;
}

type AssetVulnerabilityResponse = {
  filter: string;
  bucketStart: number;
  bucketEnd: number;
} & {
  [attackType: string]: number;
};

export type AssetVulnerabilityOutput = {
  filter: string;
  totalClients: number;
  buckets: (AssetVulnerabilityResponse & {
    name: string;
    attackTypes: Set<string>;
  })[];
};

/**
 * This provides the data for the asset vulnerabilities chart
 */
export async function assetVulnerabilities(
  options: IAssetVulnerabilityOptions
): Promise<AssetVulnerabilityOutput> {
  let { category, attackTypeFilter, filter, maxBuckets } = options;

  const assetData = await ChartDataStore.processAssetData();
  let queryData;

  if (category === 'Country') {
    queryData = query(
      assetData,
      record =>
        (record.country === options.filter ||
          filter === GLOBAL_FILTER_OPTION) &&
        record.impacted_assets > 0 &&
        attackTypeFilter !== undefined &&
        record.classification === attackTypeFilter
    );
  } else {
    queryData = query(
      assetData,
      record =>
        (record.industry === filter || filter === GLOBAL_FILTER_OPTION) &&
        record.impacted_assets > 0 &&
        attackTypeFilter !== undefined &&
        record.classification === attackTypeFilter
    );
  }

  // Get every attack type within the specified industry
  const attackTypes = unique(assetData, 'classification');
  // Get the total number of clients in the system for each attackType
  const totalClients = {};
  attackTypes.forEach(
    type =>
      (totalClients[type] = unique(
        queryData,
        'client',
        record => record.classification === type
      ).length)
  );
  // Calculate the total assets impacted for each client under the current filters
  const clients = {};
  let maxAssetsImpacted = 0;

  queryData.forEach(record => {
    const clientData = clients[record.client] || {
      impactedAssets: 0,
      attackType: '',
    };
    clients[record.client] = clientData;

    if (record.impacted_assets && !isNaN(record.impacted_assets)) {
      clientData.impactedAssets += record.impacted_assets;
      clientData.attackType = record.classification;
      maxAssetsImpacted = Math.max(
        maxAssetsImpacted,
        clientData.impactedAssets
      );
    }
  });

  // This determines how large a bucket will be to sift our data into
  let bucketSizes = Math.floor(maxAssetsImpacted / maxBuckets);

  if (bucketSizes < 1) {
    bucketSizes = 1;
    maxBuckets = maxAssetsImpacted;
  }

  // Now massage the data into our chart input
  const output: AssetVulnerabilityOutput = {
    filter,
    totalClients: 0,
    buckets: [] as (AssetVulnerabilityResponse & {
      name: string;
      attackTypes: Set<string>;
    })[],
  };

  // Initialize each bucket for each hunk of data
  for (let i = 0; i < maxBuckets - 1; ++i) {
    const startBucketVal = i * bucketSizes;

    // Make defaults for each attack type
    const attacks = {};
    attackTypes.forEach(type => {
      attacks[type] = 0;
    });

    // Make our bucket with the proper sizing
    output.buckets.push({
      name:
        bucketSizes > 1
          ? `${startBucketVal + 1} - ${startBucketVal + bucketSizes}`
          : `${i + 1}`,
      bucketStart: startBucketVal + 1,
      bucketEnd: startBucketVal + bucketSizes,
      attackTypes: new Set(),
      filter,
      ...attacks,
    });
  }

  // Make defaults for each attack type
  const attacks = {};
  attackTypes.forEach(type => {
    attacks[type] = 0;
  });

  // Make the final bucket to indicate all items over the value
  output.buckets.push({
    name: `${(maxBuckets - 1) * bucketSizes + 1}+`,
    bucketStart: (maxBuckets - 1) * bucketSizes + 1,
    bucketEnd: (maxBuckets - 1) * bucketSizes + 1,
    attackTypes: new Set(),
    filter,
    ...attacks,
  });

  // Now sift each client into a bucket
  for (const client in clients) {
    const clientData = clients[client];
    let bucketIndex = Math.floor((clientData.impactedAssets - 1) / bucketSizes);
    bucketIndex = Math.min(output.buckets.length - 1, bucketIndex);
    const bucketData = output.buckets[bucketIndex];

    if (!bucketData) {
      console.warn('Client did not fall within a bucket', clientData);
      continue;
    }

    bucketData.attackTypes.add(clientData.attackType);
    bucketData[clientData.attackType] =
      (bucketData[clientData.attackType] || 0) + 1;

    output.totalClients++;
  }

  // Now each bucket has the number of clients impacted, we can convert each bucket value
  // to the percent of clients total affected within that bucket range
  output.buckets.forEach(bucket => {
    bucket.attackTypes.forEach(type => {
      bucket[type] /= totalClients[type];
    });
  });

  return trimAssetBuckets(output, attackTypes, totalClients, clients, options);
}

/**
 * This method trims the buckets at the ends of the bucket list that contains too small of client
 * percentages to display and aggregates the clients into the end most bucket. This then redistributes
 * across new bucket quanitities to prevent the data from getting too 'compressed' from outliers.
 */
function trimAssetBuckets(
  output: AssetVulnerabilityOutput,
  attackTypes: string[],
  totalClients: { [attack: string]: number },
  clients: {
    [key: number]: {
      impactedAssets: number;
      attackType: string;
    };
  },
  options: IAssetVulnerabilityOptions
) {
  let { filter, maxBuckets, trimSmallBucketValues = 0.005 } = options;

  // We determine which of the buckets
  let endBucket = output.buckets.length - 1;
  let startBucket = 0;

  // Start from the end and find the first bucket that has enough clients to exceed the threshold
  for (let i = output.buckets.length - 1; i >= 0; --i) {
    const bucket = output.buckets[i];
    let totalPercentage = 0;

    bucket.attackTypes.forEach(type => {
      totalPercentage += bucket[type];
    });

    if (totalPercentage > trimSmallBucketValues) {
      endBucket = i;
      break;
    }
  }

  // Now start from the beginning and find the first bucket that has enough clients to exceed the threshold
  for (let i = 0, iMax = output.buckets.length; i < iMax; ++i) {
    const bucket = output.buckets[i];
    let totalPercentage = 0;

    bucket.attackTypes.forEach(type => {
      totalPercentage += bucket[type];
    });

    if (totalPercentage > trimSmallBucketValues) {
      startBucket = i;
      break;
    }
  }

  // If nothing is trimmed, then we return the buckets
  if (endBucket === output.buckets.length - 1 && startBucket === 0) {
    return output;
  }

  // We now get the start and end bucket found to produce a new range of buckets. We must still honor
  // the max buckets
  const start = output.buckets[startBucket].bucketStart;
  const range = output.buckets[endBucket].bucketEnd - start;

  let bucketSizes = Math.floor(range / maxBuckets);

  if (bucketSizes < 1) {
    bucketSizes = 1;
    maxBuckets = range;
  }

  // Clear the existing buckets
  output.buckets = [];

  // Now initialize the new buckets
  // Initialize each bucket for each hunk of data
  for (let i = 0; i < maxBuckets - 1; ++i) {
    const startBucketVal = i * bucketSizes + start;

    // Make defaults for each attack type
    const attacks = {};
    attackTypes.forEach(type => {
      attacks[type] = 0;
    });

    // Make our bucket with the proper sizing
    output.buckets.push({
      name:
        bucketSizes > 1
          ? `${startBucketVal + 1} - ${startBucketVal + bucketSizes}`
          : `${startBucketVal + 1}`,
      bucketStart: startBucketVal + 1,
      bucketEnd: startBucketVal + bucketSizes,
      attackTypes: new Set(),
      filter: filter,
      ...attacks,
    });
  }

  // Make defaults for each attack type
  const attacks = {};
  attackTypes.forEach(type => {
    attacks[type] = 0;
  });

  // Make the final bucket to indicate all items over the value
  output.buckets.push({
    name: `${(maxBuckets - 1) * bucketSizes + start + 1}+`,
    bucketStart: (maxBuckets - 1) * bucketSizes + start + 1,
    bucketEnd: (maxBuckets - 1) * bucketSizes + start + 1,
    attackTypes: new Set(),
    filter: filter,
    ...attacks,
  });

  // Adjust the first bucket to include anything above zero
  output.buckets[0].bucketStart = 0;
  output.buckets[0].name = `${output.buckets[0].bucketEnd}-`;

  for (const client in clients) {
    const clientData = clients[client];
    let bucketIndex = Math.floor(
      (clientData.impactedAssets - start - 1) / bucketSizes
    );
    bucketIndex = Math.min(output.buckets.length - 1, bucketIndex);

    // On the trim pass we aggregate items that were trimmed out into the end most buckets
    if (bucketIndex < 0) bucketIndex = 0;
    if (bucketIndex > output.buckets.length - 1)
      bucketIndex = output.buckets.length - 1;

    const bucketData = output.buckets[bucketIndex];

    if (!bucketData) {
      console.warn('Client did not fall within a bucket', clientData);
      continue;
    }

    bucketData.attackTypes.add(clientData.attackType);
    bucketData[clientData.attackType] =
      (bucketData[clientData.attackType] || 0) + 1;
  }

  // Now each bucket has the number of clients impacted, we can convert each bucket value
  // to the percent of clients total affected within that bucket range
  output.buckets.forEach(bucket => {
    bucket.attackTypes.forEach(type => {
      bucket[type] /= totalClients[type];
    });
  });

  // We must recurse and re-analyze with the new bucket format until we no longer need to trim
  return output;
}

export async function getCountries() {
  const assetCountries = (await getAssetDataCountries()).map(c =>
    c.toLowerCase()
  );

  return [
    { value: 'AF', name: 'Afghanistan' },
    { value: 'AX', name: 'Åland Islands' },
    { value: 'AL', name: 'Albania' },
    { value: 'DZ', name: 'Algeria' },
    { value: 'AS', name: 'American Samoa' },
    { value: 'AD', name: 'Andorra' },
    { value: 'AO', name: 'Angola' },
    { value: 'AI', name: 'Anguilla' },
    { value: 'AQ', name: 'Antarctica' },
    { value: 'AG', name: 'Antigua and Barbuda' },
    { value: 'AR', name: 'Argentina' },
    { value: 'AM', name: 'Armenia' },
    { value: 'AW', name: 'Aruba' },
    { value: 'AU', name: 'Australia' },
    { value: 'AT', name: 'Austria' },
    { value: 'AZ', name: 'Azerbaijan' },
    { value: 'BS', name: 'Bahamas' },
    { value: 'BH', name: 'Bahrain' },
    { value: 'BD', name: 'Bangladesh' },
    { value: 'BB', name: 'Barbados' },
    { value: 'BY', name: 'Belarus' },
    { value: 'BE', name: 'Belgium' },
    { value: 'BZ', name: 'Belize' },
    { value: 'BJ', name: 'Benin' },
    { value: 'BM', name: 'Bermuda' },
    { value: 'BT', name: 'Bhutan' },
    { value: 'BO', name: 'Bolivia, Plurinational State of' },
    { value: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { value: 'BA', name: 'Bosnia and Herzegovina' },
    { value: 'BW', name: 'Botswana' },
    { value: 'BV', name: 'Bouvet Island' },
    { value: 'BR', name: 'Brazil' },
    { value: 'IO', name: 'British Indian Ocean Territory' },
    { value: 'BN', name: 'Brunei Darussalam' },
    { value: 'BG', name: 'Bulgaria' },
    { value: 'BF', name: 'Burkina Faso' },
    { value: 'BI', name: 'Burundi' },
    { value: 'KH', name: 'Cambodia' },
    { value: 'CM', name: 'Cameroon' },
    { value: 'CA', name: 'Canada' },
    { value: 'CV', name: 'Cape Verde' },
    { value: 'KY', name: 'Cayman Islands' },
    { value: 'CF', name: 'Central African Republic' },
    { value: 'TD', name: 'Chad' },
    { value: 'CL', name: 'Chile' },
    { value: 'CN', name: 'China' },
    { value: 'CX', name: 'Christmas Island' },
    { value: 'CC', name: 'Cocos (Keeling) Islands' },
    { value: 'CO', name: 'Colombia' },
    { value: 'KM', name: 'Comoros' },
    { value: 'CG', name: 'Congo' },
    { value: 'CD', name: 'Congo, the Democratic Republic of the' },
    { value: 'CK', name: 'Cook Islands' },
    { value: 'CR', name: 'Costa Rica' },
    { value: 'CI', name: "Côte d'Ivoire" },
    { value: 'HR', name: 'Croatia' },
    { value: 'CU', name: 'Cuba' },
    { value: 'CW', name: 'Curaçao' },
    { value: 'CY', name: 'Cyprus' },
    { value: 'CZ', name: 'Czech Republic' },
    { value: 'DK', name: 'Denmark' },
    { value: 'DJ', name: 'Djibouti' },
    { value: 'DM', name: 'Dominica' },
    { value: 'DO', name: 'Dominican Republic' },
    { value: 'EC', name: 'Ecuador' },
    { value: 'EG', name: 'Egypt' },
    { value: 'SV', name: 'El Salvador' },
    { value: 'GQ', name: 'Equatorial Guinea' },
    { value: 'ER', name: 'Eritrea' },
    { value: 'EE', name: 'Estonia' },
    { value: 'ET', name: 'Ethiopia' },
    { value: 'FK', name: 'Falkland Islands (Malvinas)' },
    { value: 'FO', name: 'Faroe Islands' },
    { value: 'FJ', name: 'Fiji' },
    { value: 'FI', name: 'Finland' },
    { value: 'FR', name: 'France' },
    { value: 'GF', name: 'French Guiana' },
    { value: 'PF', name: 'French Polynesia' },
    { value: 'TF', name: 'French Southern Territories' },
    { value: 'GA', name: 'Gabon' },
    { value: 'GM', name: 'Gambia' },
    { value: 'GE', name: 'Georgia' },
    { value: 'DE', name: 'Germany' },
    { value: 'GH', name: 'Ghana' },
    { value: 'GI', name: 'Gibraltar' },
    { value: 'GR', name: 'Greece' },
    { value: 'GL', name: 'Greenland' },
    { value: 'GD', name: 'Grenada' },
    { value: 'GP', name: 'Guadeloupe' },
    { value: 'GU', name: 'Guam' },
    { value: 'GT', name: 'Guatemala' },
    { value: 'GG', name: 'Guernsey' },
    { value: 'GN', name: 'Guinea' },
    { value: 'GW', name: 'Guinea-Bissau' },
    { value: 'GY', name: 'Guyana' },
    { value: 'HT', name: 'Haiti' },
    { value: 'HM', name: 'Heard Island and McDonald Islands' },
    { value: 'VA', name: 'Holy See (Vatican City State)' },
    { value: 'HN', name: 'Honduras' },
    { value: 'HK', name: 'Hong Kong' },
    { value: 'HU', name: 'Hungary' },
    { value: 'IS', name: 'Iceland' },
    { value: 'IN', name: 'India' },
    { value: 'ID', name: 'Indonesia' },
    { value: 'IR', name: 'Iran, Islamic Republic of' },
    { value: 'IQ', name: 'Iraq' },
    { value: 'IE', name: 'Ireland' },
    { value: 'IM', name: 'Isle of Man' },
    { value: 'IL', name: 'Israel' },
    { value: 'IT', name: 'Italy' },
    { value: 'JM', name: 'Jamaica' },
    { value: 'JP', name: 'Japan' },
    { value: 'JE', name: 'Jersey' },
    { value: 'JO', name: 'Jordan' },
    { value: 'KZ', name: 'Kazakhstan' },
    { value: 'KE', name: 'Kenya' },
    { value: 'KI', name: 'Kiribati' },
    { value: 'KP', name: "Korea, Democratic People's Republic of" },
    { value: 'KR', name: 'Korea, Republic of' },
    { value: 'KW', name: 'Kuwait' },
    { value: 'KG', name: 'Kyrgyzstan' },
    { value: 'LA', name: "Lao People's Democratic Republic" },
    { value: 'LV', name: 'Latvia' },
    { value: 'LB', name: 'Lebanon' },
    { value: 'LS', name: 'Lesotho' },
    { value: 'LR', name: 'Liberia' },
    { value: 'LY', name: 'Libya' },
    { value: 'LI', name: 'Liechtenstein' },
    { value: 'LT', name: 'Lithuania' },
    { value: 'LU', name: 'Luxembourg' },
    { value: 'MO', name: 'Macao' },
    { value: 'MK', name: 'Macedonia, the former Yugoslav Republic of' },
    { value: 'MG', name: 'Madagascar' },
    { value: 'MW', name: 'Malawi' },
    { value: 'MY', name: 'Malaysia' },
    { value: 'MV', name: 'Maldives' },
    { value: 'ML', name: 'Mali' },
    { value: 'MT', name: 'Malta' },
    { value: 'MH', name: 'Marshall Islands' },
    { value: 'MQ', name: 'Martinique' },
    { value: 'MR', name: 'Mauritania' },
    { value: 'MU', name: 'Mauritius' },
    { value: 'YT', name: 'Mayotte' },
    { value: 'MX', name: 'Mexico' },
    { value: 'FM', name: 'Micronesia, Federated States of' },
    { value: 'MD', name: 'Moldova, Republic of' },
    { value: 'MC', name: 'Monaco' },
    { value: 'MN', name: 'Mongolia' },
    { value: 'ME', name: 'Montenegro' },
    { value: 'MS', name: 'Montserrat' },
    { value: 'MA', name: 'Morocco' },
    { value: 'MZ', name: 'Mozambique' },
    { value: 'MM', name: 'Myanmar' },
    { value: 'NA', name: 'Namibia' },
    { value: 'NR', name: 'Nauru' },
    { value: 'NP', name: 'Nepal' },
    { value: 'NL', name: 'Netherlands' },
    { value: 'NC', name: 'New Caledonia' },
    { value: 'NZ', name: 'New Zealand' },
    { value: 'NI', name: 'Nicaragua' },
    { value: 'NE', name: 'Niger' },
    { value: 'NG', name: 'Nigeria' },
    { value: 'NU', name: 'Niue' },
    { value: 'NF', name: 'Norfolk Island' },
    { value: 'MP', name: 'Northern Mariana Islands' },
    { value: 'NO', name: 'Norway' },
    { value: 'OM', name: 'Oman' },
    { value: 'PK', name: 'Pakistan' },
    { value: 'PW', name: 'Palau' },
    { value: 'PS', name: 'Palestinian Territory, Occupied' },
    { value: 'PA', name: 'Panama' },
    { value: 'PG', name: 'Papua New Guinea' },
    { value: 'PY', name: 'Paraguay' },
    { value: 'PE', name: 'Peru' },
    { value: 'PH', name: 'Philippines' },
    { value: 'PN', name: 'Pitcairn' },
    { value: 'PL', name: 'Poland' },
    { value: 'PT', name: 'Portugal' },
    { value: 'PR', name: 'Puerto Rico' },
    { value: 'QA', name: 'Qatar' },
    { value: 'RE', name: 'Réunion' },
    { value: 'RO', name: 'Romania' },
    { value: 'RU', name: 'Russian Federation' },
    { value: 'RW', name: 'Rwanda' },
    { value: 'BL', name: 'Saint Barthélemy' },
    { value: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { value: 'KN', name: 'Saint Kitts and Nevis' },
    { value: 'LC', name: 'Saint Lucia' },
    { value: 'MF', name: 'Saint Martin (French part)' },
    { value: 'PM', name: 'Saint Pierre and Miquelon' },
    { value: 'VC', name: 'Saint Vincent and the Grenadines' },
    { value: 'WS', name: 'Samoa' },
    { value: 'SM', name: 'San Marino' },
    { value: 'ST', name: 'Sao Tome and Principe' },
    { value: 'SA', name: 'Saudi Arabia' },
    { value: 'SN', name: 'Senegal' },
    { value: 'RS', name: 'Serbia' },
    { value: 'SC', name: 'Seychelles' },
    { value: 'SL', name: 'Sierra Leone' },
    { value: 'SG', name: 'Singapore' },
    { value: 'SX', name: 'Sint Maarten (Dutch part)' },
    { value: 'SK', name: 'Slovakia' },
    { value: 'SI', name: 'Slovenia' },
    { value: 'SB', name: 'Solomon Islands' },
    { value: 'SO', name: 'Somalia' },
    { value: 'ZA', name: 'South Africa' },
    { value: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', name: 'South Sudan' },
    { value: 'ES', name: 'Spain' },
    { value: 'LK', name: 'Sri Lanka' },
    { value: 'SD', name: 'Sudan' },
    { value: 'SR', name: 'Suriname' },
    { value: 'SJ', name: 'Svalbard and Jan Mayen' },
    { value: 'SZ', name: 'Swaziland' },
    { value: 'SE', name: 'Sweden' },
    { value: 'CH', name: 'Switzerland' },
    { value: 'SY', name: 'Syrian Arab Republic' },
    { value: 'TW', name: 'Taiwan, Province of China' },
    { value: 'TJ', name: 'Tajikistan' },
    { value: 'TZ', name: 'Tanzania, United Republic of' },
    { value: 'TH', name: 'Thailand' },
    { value: 'TL', name: 'Timor-Leste' },
    { value: 'TG', name: 'Togo' },
    { value: 'TK', name: 'Tokelau' },
    { value: 'TO', name: 'Tonga' },
    { value: 'TT', name: 'Trinidad and Tobago' },
    { value: 'TN', name: 'Tunisia' },
    { value: 'TR', name: 'Turkey' },
    { value: 'TM', name: 'Turkmenistan' },
    { value: 'TC', name: 'Turks and Caicos Islands' },
    { value: 'TV', name: 'Tuvalu' },
    { value: 'UG', name: 'Uganda' },
    { value: 'UA', name: 'Ukraine' },
    { value: 'AE', name: 'United Arab Emirates' },
    { value: 'GB', name: 'United Kingdom' },
    { value: 'US', name: 'United States' },
    { value: 'UM', name: 'United States Minor Outlying Islands' },
    { value: 'UY', name: 'Uruguay' },
    { value: 'UZ', name: 'Uzbekistan' },
    { value: 'VU', name: 'Vanuatu' },
    { value: 'VE', name: 'Venezuela, Bolivarian Republic of' },
    { value: 'VN', name: 'Viet Nam' },
    { value: 'VG', name: 'Virgin Islands, British' },
    { value: 'VI', name: 'Virgin Islands, U.S.' },
    { value: 'WF', name: 'Wallis and Futuna' },
    { value: 'EH', name: 'Western Sahara' },
    { value: 'YE', name: 'Yemen' },
    { value: 'ZM', name: 'Zambia' },
    { value: 'ZW', name: 'Zimbabwe' },
    { value: '??', name: 'Unknown' },
  ].filter(pair => {
    return assetCountries.indexOf(pair.name.toLowerCase()) > -1;
  });
}

function getDemoIndustryFilter() {
  return [
    'Finance',
    'Legal',
    'Technology',
    'Insurance',
    'Mining',
    'Healthcare',
    'Pharmaceuticals',
    'Manufacturing',
    'Construction',
    'Non-Profit',
    'Retail',
    GLOBAL_FILTER_OPTION,
  ];
}
