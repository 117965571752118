/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Code2 extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      size = 41,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 47 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.5609 10.4395L36.5609 0.4395C35.9749 -0.1465 35.0259 -0.1465 34.4399 0.4395C33.8539 1.0245 33.8539 1.9755 34.4399 2.5605L43.3789 11.5005L34.4399 20.4395C33.8539 21.0245 33.8539 21.9755 34.4399 22.5605C34.7329 22.8535 35.1169 23.0005 35.4999 23.0005C35.8839 23.0005 36.2679 22.8535 36.5609 22.5605L46.5609 12.5605C47.1469 11.9755 47.1469 11.0245 46.5609 10.4395Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.6215 11.4998L12.5605 2.56075C13.1465 1.97575 13.1465 1.02375 12.5605 0.43875C11.9755 -0.14625 11.0255 -0.14625 10.4395 0.43875L0.4395 10.4388C-0.1465 11.0238 -0.1465 11.9757 0.4395 12.5608L10.4395 22.5607C10.7325 22.8537 11.1165 22.9998 11.5005 22.9998C11.8845 22.9998 12.2675 22.8537 12.5605 22.5607C13.1465 21.9757 13.1465 21.0237 12.5605 20.4387L3.6215 11.4998Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5004 8.5C21.8464 8.5 20.5004 9.846 20.5004 11.5C20.5004 13.154 21.8464 14.5 23.5004 14.5C25.1544 14.5 26.5004 13.154 26.5004 11.5C26.5004 9.846 25.1544 8.5 23.5004 8.5Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.5004 11.5C36.5004 9.846 35.1544 8.5 33.5004 8.5C31.8464 8.5 30.5004 9.846 30.5004 11.5C30.5004 13.154 31.8464 14.5 33.5004 14.5C35.1544 14.5 36.5004 13.154 36.5004 11.5Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5004 8.5C11.8464 8.5 10.5004 9.846 10.5004 11.5C10.5004 13.154 11.8464 14.5 13.5004 14.5C15.1544 14.5 16.5004 13.154 16.5004 11.5C16.5004 9.846 15.1544 8.5 13.5004 8.5Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
