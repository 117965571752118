/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Log4j extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 19,
      x = 0,
      y = 0,
      opacity = 1,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g opacity={opacity} fill={color}>
          <path d="M29.6552 7.81611C21.2644 7.58622 17.5862 12.6437 19.7701 14.023C20.9318 14.7567 22.2887 14.3846 23.8926 13.9447C25.3039 13.5577 26.9065 13.1182 28.7356 13.3334C32.6437 13.7931 34.3678 16.5517 34.2529 18.5058C34.1379 20.4598 32.1839 23.0037 27.8161 23.3333C25.7111 23.4922 24.3268 23.1242 23.2646 22.8418C22.1228 22.5382 21.353 22.3335 20.4598 22.9885C18.7356 24.2529 23.5632 29.0805 29.1954 29.0805C34.8276 29.0805 36.2069 27.931 37.1264 26.2069C37.5816 25.3535 36.0091 25.6265 33.9701 25.9806C31.8899 26.3418 29.3242 26.7874 27.931 26.2069C26.309 25.5311 27.9455 25.2526 30.1301 24.8809C31.661 24.6204 33.4611 24.3141 34.5977 23.7931C37.3563 22.5287 40.5747 20.4598 40.5747 16.5517C40.5747 12.6437 38.046 8.04599 29.6552 7.81611Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM30 55.1724C43.9023 55.1724 55.1724 43.9023 55.1724 30H39.1447C39.962 29.4305 40.5834 28.8615 40.9195 28.3908C42.069 26.7816 42.069 24.4828 41.0345 23.3333C41.1264 23.2414 41.2513 23.1315 41.3998 23.0009C42.5512 21.9877 45.1199 19.7276 44.7126 14.9425C44.2529 9.54023 38.8506 4.48276 29.8851 4.48276C20.9195 4.48276 15.0575 9.08046 15.0575 12.2989C15.0575 15.5172 17.5862 16.2916 20.3448 16.4368C22.0002 16.5239 23.1175 16.134 24.1934 15.7585C24.9106 15.5082 25.6093 15.2644 26.4368 15.1724C28.5058 14.9425 31.0345 16.2069 31.1494 17.7011C31.2644 19.1954 29.4253 20.9195 26.7816 21.0345C25.2998 21.0989 23.854 20.6216 22.5456 20.1897C21.5198 19.851 20.5783 19.5402 19.7701 19.5402C17.931 19.5402 15.8621 20.5747 15.7471 22.8736C15.6322 25.1724 17.5862 27.2414 19.4253 29.0805C19.7347 29.3899 20.1483 29.6993 20.6424 30H4.82759C4.82759 43.9023 16.0977 55.1724 30 55.1724Z"
          />
        </g>
      </svg>
    );
  }
}
