/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import anime from 'animejs';
import './donut.scss';
import classnames from 'classnames';

@observer
export class PlayerDonut extends Component {
  // The donut
  donut = null;
  ticks = null;
  circle = null;
  animateDonut = null;

  // params
  numberOfTicks = 90;
  duration = 3000;
  delay = null;
  translateY = -800;

  // Create element
  createTick(i) {
    let el = document.createElement('div');
    const rotate = (360 / this.numberOfTicks) * i;
    el.classList.add('donut--tick');
    el.style.transform =
      'rotate(' + rotate + 'deg) translateY(' + this.translateY + '%)';

    let duration = this.duration;
    let delay = this.duration / this.numberOfTicks;

    // Add to animation
    this.animateDonut.add({
      begin: function() {
        anime({
          targets: el,
          opacity: [0, 1, 0, 1],
          duration: duration,
          // easing: 'linear', // A little less transparent space between glowy parts
          easing: 'easeInOutSine',
          delay: function(el, i, l) {
            return i * delay;
          },
        });
      },
    });

    // Append to DOM
    this.ticks.appendChild(el);
  }

  componentDidMount() {
    // Animate tick backgrounds
    this.delay = this.duration / this.numberOfTicks;
    let animateDonut = (this.animateDonut = anime.timeline({
      duration: this.delay,
      offset: -(this.duration / 2),
      complete: function() {
        animateDonut.restart();
      },
    }));

    // Animate the circle into being
    anime({
      targets: this.donut,
      duration: 3000,
      opacity: [0, 1],
      easing: 'linear',
    });

    // Create ticks
    for (let i = 0; i < this.numberOfTicks; i++) {
      this.createTick(i);
    }
  }

  render() {
    return (
      <div
        className={classnames('donut', this.props.className)}
        ref={node => (this.donut = node)}
      >
        <div className={'donut--ticks'} ref={node => (this.ticks = node)} />
        <div className={'donut--circle'} ref={node => (this.circle = node)} />
      </div>
    );
  }
}
