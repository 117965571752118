/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// Players
import { PlayerProgressBar } from '../players/progress-bar';

// SVG
import { KaseyaLogo } from '../svg/kaseya-logo';
import { Check } from '../svg/check';

@observer
export class Slide16 extends AnimatedSlide {
  // Animation players
  players = {
    kaseya: null,
    progress: null,
    title: null,
    checkmark: null,
  };

  delay = 2000;
  duration = 7000;
  text = 'RESTORING CLOUD INFRASTRUCTURE';

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;
    const { kaseya, title, progress, checkmark } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(12000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(this.duration + this.delay);
      },
      // Step 2
      () => {
        if (!this._isMounted) return;
        this.text = 'CLOUD INFRASTRUCTURE RESTORED';
        title.classList.add('restored');
        this.forceUpdate();
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: kaseya,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: title,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: progress,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2
      //
      [
        {
          targets: checkmark,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-16 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* Kaseya Logo */}
          <div
            className="player kaseya"
            data-active="0"
            ref={node => (this.players.kaseya = node)}
          >
            <KaseyaLogo size={150} />
          </div>
          {/* Title */}
          <div
            className="player title"
            data-active="0"
            ref={node => (this.players.title = node)}
          >
            {this.text}
          </div>
          {/* Progress */}
          <div
            className="player progress"
            data-active="0"
            ref={node => (this.players.progress = node)}
          >
            <PlayerProgressBar delay={this.delay} duration={this.duration} />
          </div>
          {/* Check */}
          <div
            className="player checkmark"
            data-active="0"
            ref={node => (this.players.checkmark = node)}
          >
            <Check size={60} color={this.colors.blue} />
          </div>
          {/* ------------------ END OF CONTENT ------------------*/}
        </div>
      </div>
    );
  }
}
