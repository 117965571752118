/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import ReactDOM from 'react-dom';
import anime from 'animejs';

// SVG
import { Alert } from '../svg/alert';
import { Email } from '../svg/email';

@observer
export class Slide21 extends AnimatedSlide {
  // Animation players
  players = {
    title: null,
    message: null,
    info1: null,
    info2: null,
    info3: null,
    border: null,
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.message.querySelector('.border');
    const { title, message, info1, info2, info3, border } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(4000);
      },
      // Step 2
      () => {
        // this.autoNext(2000);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Pause for effect
      ],

      //
      // Step 1
      //
      [
        {
          targets: title,
          opacity: 1,
          delay: 0,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: message,
          opacity: 1,
          delay: 0,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          delay: 500,
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: message,
          opacity: 0,
          delay: 0,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: info1,
          opacity: 1,
          delay: 0,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: info2,
          opacity: 1,
          delay: 500,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: info3,
          opacity: 1,
          delay: 1000,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-21 ${className}`}>
        {children}
        <div className="content">
          {/* Title */}
          <div
            className="player title"
            data-active="0"
            ref={node => (this.players.title = node)}
          >
            <h1>eSentire Investigation Analysis</h1>
          </div>
          {/* Alert */}
          <div
            className="player message"
            data-active="0"
            ref={node => (this.players.message = node)}
          >
            <Alert
              title={'MESSAGE FROM ESENTIRE'}
              subtitle={'Suncrypt Ransomware'}
              IconComponent={Email}
              color={this.colors.blue}
            />
          </div>
          {/* Incident timeline */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerTextBlock
              title={'Incident timeline'}
              showTimeline={true}
              width={'300px'}
              sections={[
                {
                  lines: 3,
                  lineColumns: 2,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
                {
                  lines: 3,
                  lineColumns: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Malicious IPs + Domains */}
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerTextBlock
              title={'Malicious IPs + Domains'}
              width={'250px'}
              sections={[
                {
                  title: ['IP', '45.40.148.106'],
                  grid: 2,
                },
                {
                  title: ['WWW.EASY123PROPERTY.COM'],
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Malicious Files + Hashes */}
          <div
            className="player info3"
            data-active="0"
            ref={node => (this.players.info3 = node)}
          >
            <PlayerTextBlock
              title={'Malicious Files + Hashes'}
              width={'250px'}
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
