/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerDots } from '../players/dots';

// SVG
import { Code } from '../svg/code';
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { Skull } from '../svg/skull';
import { PowerShell } from '../svg/power-shell';

@observer
export class Slide02 extends AnimatedSlide {
  // Animation players
  players = {
    dotsLeft: null,
    dotsRight: null,
    attacker: null,
    powershell: null,
    donut: null,
    analyzing: null,
    code: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      code,
      donut,
      analyzing,
      dotsLeft,
      dotsRight,
      attacker,
      powershell,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        // Do nothing
        this.animateProgress(7000);
        this.autoNext(6000);
      },
      // Step 2
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        // Attacker animates from center to left
        {
          targets: attacker,
          opacity: 1,
          delay: 0,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: attacker,
          opacity: 1,
          delay: 2000,
          translateX: -350,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: dotsLeft,
          opacity: 1,
          delay: 2500,
          duration: 200,
          begin: activateShimmer,
        },
        // Powershell appears
        {
          targets: powershell,
          opacity: 1,
          delay: 2700,
          duration: 200,
          begin: activateShimmer,
        },
        // Code fades in on right
        {
          targets: code,
          opacity: 1,
          delay: 3000,
          translateX: 350,
          scale: 2,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: dotsRight,
          opacity: 1,
          delay: 3000,
          duration: 200,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        // Fade out other guys
        {
          targets: attacker,
          opacity: 0,
          delay: 0,
          duration: 500,
          complete: deactivate,
        },
        {
          targets: dotsLeft,
          opacity: 0,
          delay: 0,
          duration: 500,
          complete: deactivate,
        },
        {
          targets: dotsRight,
          opacity: 0,
          delay: 0,
          duration: 500,
          complete: deactivate,
        },
        {
          targets: powershell,
          opacity: 0,
          delay: 0,
          duration: 500,
          complete: deactivate,
        },
        // Animate code back to center
        {
          targets: code,
          opacity: 1,
          delay: 0,
          translateX: -10,
          translateY: -20,
          scale: 3,
          duration: 500,
        },
        // Dont appears
        {
          targets: donut,
          opacity: [0, 1],
          delay: 0,
          duration: 500,
          begin: activateShimmer,
        },
        // Analyzing appears
        {
          targets: analyzing,
          opacity: 1,
          delay: 1000,
          left: 800,
          duration: 200,
          begin: activate,
          complete: shimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-02 ${className}`}>
        {children}
        <div className="content">
          {/* Skull */}
          <div
            className="player attacker"
            data-active="1"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text={'ATTACKER'} />
          </div>

          {/* Powershell */}
          <div
            className="player powershell"
            data-active="0"
            ref={node => (this.players.powershell = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={PowerShell}
              IconSize={'40'}
              showTitle={false}
            />
          </div>

          {/* Dots left */}
          <div
            className="player dots-left"
            data-active="0"
            ref={node => (this.players.dotsLeft = node)}
          >
            <PlayerDots
              className={'white-dots small-dots in-out square-dots'}
            />
          </div>

          {/* Dots right */}
          <div
            className="player dots-right"
            data-active="0"
            ref={node => (this.players.dotsRight = node)}
          >
            <PlayerDots
              className={'white-dots small-dots in-out square-dots'}
            />
          </div>

          {/* Text block */}
          <div
            className="player analyzing"
            data-active="0"
            ref={node => (this.players.analyzing = node)}
          >
            <PlayerTextBlock
              title="Bluesteel"
              actionTitle="Analyzing powershell command"
              className={'wrap'}
            />
          </div>

          {/* Code */}
          <div
            className="player code"
            data-active="0"
            ref={node => (this.players.code = node)}
          >
            <Code size={'60'} />
          </div>

          {/* Donut */}
          <div
            className="player donut"
            data-active="0"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
