/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import { AppState, AppActions } from './app-actions';
import './slide-list.scss';
import { Clock } from './svg/clock';
import { Close } from './svg/close';
import { RouteManager } from 'vega-router';
import { useCases } from '../data/use-cases';
import { Button } from './button';

const { floor } = Math;

@observer
export class SlideList extends Component {
  stepNumber = 0;

  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.setShortcutName('Use Cases');
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
  }

  onClose = () => {
    if (window.history.length > 1) {
      window.history.back();
    } else {
      RouteManager.transitionTo('/');
    }
  };

  handleSlideClick = (useCaseKey, i) => event => {
    RouteManager.transitionTo(`/${useCaseKey}/slides/${i}`);
  };

  getDisplayFromTime(time) {
    let [days = 0, hours = 0, minutes = 0, seconds = 0] = (
      time || '0:0:0:0'
    ).split(':');
    const totalSeconds =
      Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds);

    hours = floor(totalSeconds / (60 * 60)).toString(10);
    minutes = floor((totalSeconds / 60) % 60).toString(10);
    seconds = floor(totalSeconds % 60).toString(10);

    let str = '';
    if (Number(days)) str += `${days} days `;
    if (Number(hours)) str += `${hours} hrs `;
    if (Number(minutes)) str += `${minutes} mins `;
    if (Number(seconds)) str += `${seconds} secs`;
    if (!Number(hours) && !Number(minutes) && !Number(seconds)) str += '0 secs';

    return str;
  }

  renderColumns() {}

  renderRows() {}

  render() {
    const { children, className = '' } = this.props;

    const useCase = useCases.find(
      useCase => useCase.key === AppState.useCaseKey
    );
    const timelineSummarySlideIndex =
      useCase && useCase.slides.findIndex(slide => slide.timelineSummary);

    // New method with column support
    return (
      <div className={`SlideList ${className} ${useCase.key}`}>
        <h1 className="SlideList__Header">Events</h1>
        {timelineSummarySlideIndex >= 0 && (
          <Button
            className="SlideList__ViewTimeline"
            icon={Clock}
            onClick={this.handleSlideClick(
              useCase.key,
              timelineSummarySlideIndex + 1
            )}
          >
            View Timeline Summary
          </Button>
        )}
        <a className="SlideList__Close" onClick={this.onClose}>
          <Close />
        </a>
        <div className="SlideList__Main">
          {useCase &&
            Object.keys(useCase.COLUMNS).map((key, i) => {
              const rows = useCase.COLUMNS[key];
              return (
                <div className="SlideList__Column" key={key}>
                  {Object.keys(rows).map(categoryKey => {
                    this.stepNumber += 1;
                    const category = rows[categoryKey];
                    const Icon = useCase.CATEGORY_ICONS[category];
                    return (
                      <div className="SlideList__Row" key={category}>
                        <h3>
                          Stage {String(this.stepNumber).padStart(2, '0')}
                        </h3>
                        <h2>{category}</h2>
                        {Icon && <Icon size={20} color={'#50C2E3'} />}
                        <div className="SlideList__Slides">
                          {useCase &&
                            useCase.slides
                              .map((slide, index) =>
                                Object.assign(slide, { index })
                              )
                              .filter(
                                slide =>
                                  slide.category === category &&
                                  !slide.timelineSummary
                              )
                              .map((slide, i) => (
                                <div
                                  key={i}
                                  className="SlideList__Slide"
                                  onClick={this.handleSlideClick(
                                    useCase.key,
                                    slide.index + 1
                                  )}
                                >
                                  {slide.time !== null ? (
                                    <h4>
                                      {this.getDisplayFromTime(slide.time)}
                                    </h4>
                                  ) : null}
                                  <p>{slide.subtitle}</p>
                                </div>
                              ))}
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          {children}
        </div>
      </div>
    );
  }
}
