/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { StoryBlock } from '../story-block';

// SVG
import { Bug } from '../svg/bug';
import { Code2 } from '../svg/code2';
import { Key } from '../svg/key';
import { CheckShield } from '../svg/check-shield';
import { Check } from '../svg/check';
import { PlayerDemoGoals } from '../players/demo-goals';

@observer
export class Slide01 extends AnimatedSlide {
  // Animation players
  players = {
    card1: null,
    card2: null,
    card3: null,
    card4: null,
    card5: null,
  };

  // Run the first animation
  componentDidMount() {
    const { card1, card2, card3, card4, card5 } = this.players;

    this.steps = [
      () => {
        this.animateProgress(3000);
        this.autoNext(200);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        {
          targets: card1,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: card2,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: card3,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 5
      //
      [
        {
          targets: card4,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 6
      //
      [
        {
          targets: card5,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-01 ${className}`}>
        {children}
        <div className="content">
          {/* Story block 1 */}
          <div
            className="player block1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <StoryBlock
              IconComponent={Bug}
              title={'Sunwalker Ransomware Attack'}
              color={'#C53D43'}
              description={
                'A threat actor set sights upon an online education institution. The attack began on a Sunday afternoon, a vulnerable time for many organizations, and lasted 12 hours. Initial breach access was undetected.'
              }
            />
          </div>

          {/* Story block 2 */}
          <div
            className="player block2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <StoryBlock
              IconComponent={Code2}
              title={'Accessed the network'}
              color={'#C53D43'}
              description={
                'The adversary accessed the client network via VPN using compromised credentials for system administrator, so it appeared legitimate.'
              }
            />
          </div>

          {/* Story block 3 */}
          <div
            className="player block3"
            data-active="0"
            ref={node => (this.players.card3 = node)}
          >
            <StoryBlock
              IconComponent={Key}
              title={'Ransomware payload'}
              color={'#C53D43'}
              description={
                'The unsecured admin credentials in this case had a high level of privilege and multi-factor authentication (MFA) was not in place. The attacker was persistant, attempting to deliver two different ransomware payloads.'
              }
            />
          </div>

          {/* Story block 4 */}
          <div
            className="player block4"
            data-active="0"
            ref={node => (this.players.card4 = node)}
          >
            <div className={'animated-dotted-line ants-1'}></div>
            <div className={'animated-dotted-line ants-2'}></div>
            <div className={'animated-dotted-line ants-3'}></div>
            <StoryBlock
              IconComponent={CheckShield}
              title={'eSentire MDR'}
              color={'#50C2E3'}
              description={
                "Fortunately for the target, they were protected by eSentire’s Managed Detection and Response (MDR), which meant that the attacker encountered a combination of multi-signal detection capabilities and human-led investigation from eSentire's SOC Cyber Analysts and Elite Threat Hunters."
              }
            />
          </div>

          {/* Story block 5 */}
          <div
            className="player block5"
            data-active="0"
            ref={node => (this.players.card5 = node)}
          >
            <PlayerDemoGoals
              title={'In this attack demo, we can see how'}
              goals={[
                'A layered defense that included MDR for Network and Endpoint combined to provide broad attack surface visibility and complete response to this breach.',
                'Log provides critical visibility, data correlation, deep investigation and enhances threat detection across network environments and endpoints.',
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
