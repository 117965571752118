/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import { Router, RouteManager } from 'vega-router';
import {
  KILLCHAIN_DATA,
  KILLCHAIN_PRODUCTS,
  KillchainState,
  KillchainActions,
} from '../killchain-actions';
import { Close } from '../../svg/close';
import { DotIndicator } from '../../dot-indicator';
import { ServicesMenu } from './services-menu';
import './overview.scss';

const isSelected = (item, selected) => {
  return (
    item && selected && selected.find(selected => selected.value === item.value)
  );
};

@observer
export class KillchainOverview extends Router {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
    };
  }

  componentDidMount() {
    KillchainState.title = 'Killchain';
    KillchainState.subtitle = '';
  }

  onToggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu,
    });
  };

  onSelectNone = () => {
    KillchainActions.clearSelectedProducts();
  };

  onSelectAll = () => {
    KillchainActions.resetSelectedProducts();
  };

  onUpdateSelectedProducts = product => {
    KillchainActions.updateSelectedItems('selectedProducts', product);
  };

  render() {
    const { className = '' } = this.props;
    const { showMenu } = this.state;

    const noEsnetworkClassName = !isSelected(
      KILLCHAIN_PRODUCTS.find(
        product => product.value === 'eSentire MDR for Network'
      ),
      KillchainState.selectedProducts
    )
      ? ' KillchainOverview--no-esnetwork'
      : '';
    const noEsendpointClassName = !isSelected(
      KILLCHAIN_PRODUCTS.find(
        product => product.value === 'eSentire MDR for Endpoint'
      ),
      KillchainState.selectedProducts
    )
      ? ' KillchainOverview--no-esendpoint'
      : '';
    const noEslogClassName = !isSelected(
      KILLCHAIN_PRODUCTS.find(
        product => product.value === 'eSentire MDR for Log'
      ),
      KillchainState.selectedProducts
    )
      ? ' KillchainOverview--no-eslog'
      : '';
    const noMvsClassName = !isSelected(
      KILLCHAIN_PRODUCTS.find(product => product.value === 'mvs'),
      KillchainState.selectedProducts
    )
      ? ' KillchainOverview--no-mvs'
      : '';
    const noCloudPrismaClassName = !isSelected(
      KILLCHAIN_PRODUCTS.find(product => product.value === 'prisma'),
      KillchainState.selectedProducts
    )
      ? ' KillchainOverview--no-prisma'
      : '';

    const noEslogAndNoCloudPrismaClassName =
      noEslogClassName && noCloudPrismaClassName;

    return (
      <div className={`KillchainOverview ${className}`}>
        {/**
         * SERVICES
         */}

        <div
          className="KillchainOverview__Cell KillchainOverview__Services"
          onClick={this.onToggleMenu}
        >
          {KillchainState.unselectedProducts.length
            ? `(${KillchainState.unselectedProducts
                .map(product => `No ${product.label}`)
                .join(', ')})`
            : 'Services'}
          <div className="KillchainOverview__ServicesMarker" />
          {KillchainState.selectedProducts.length !==
            KILLCHAIN_PRODUCTS.length && (
            <div className="KillchainOverview__ServicesIndicator" />
          )}
        </div>
        <div className="KillchainOverview__Response">
          <div className="KillchainOverview__CellHeader">
            Managed Detection &amp; Response
          </div>
          <div className="KillchainOverview__CellProducts">
            <div className={noEsnetworkClassName}>
              {noEsnetworkClassName
                ? 'No eSentire MDR for Network'
                : 'eSentire MDR for Network'}
            </div>
            <div className={noEsendpointClassName}>
              {noEsendpointClassName
                ? 'No eSentire MDR for Endpoint'
                : 'eSentire MDR for Endpoint'}
            </div>
            <div>
              <span>
                eSentire MDR for Cloud
                <br />
                <span className="text-muted">(IaaS)</span>
              </span>
            </div>
          </div>
        </div>
        <div className="KillchainOverview__Detection">
          <div className="KillchainOverview__CellHeader">Managed Detection</div>
          <div className="KillchainOverview__CellProducts">
            <div>
              <span>
                eSentire MDR for Cloud
                <br />
                <span className="text-muted">(SaaS)</span>
              </span>
            </div>
            <div className={noEslogClassName}>
              {noEslogClassName
                ? 'No eSentire MDR for Log'
                : 'eSentire MDR for Log'}
            </div>
          </div>
        </div>
        <div className="KillchainOverview__Awareness">
          <div className="KillchainOverview__CellHeader">Awareness</div>
          <div className="KillchainOverview__CellProducts">
            <div className={noMvsClassName}>
              {noMvsClassName
                ? 'No Managed Vulnerability Service'
                : 'Managed Vulnerability Service'}
            </div>
          </div>
        </div>

        {/**
         * SERVICE COMPONENTS
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1">
          Service Components
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1"></div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1"></div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1">
          <span>
            <span className={noEslogClassName ? 'text-red' : ''}>
              eSentire MDR for Log
            </span>
            ,<span className={noMvsClassName ? 'text-red' : ''}>MVS</span>, IaaS
            Protection
          </span>
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-1${noEslogClassName}`}
        >
          {noEslogClassName
            ? 'No eSentire MDR for Log'
            : 'eSentire MDR for Log'}
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1"></div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1"></div>

        {/**
         * TECHNOLOGIES
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1">
          Technologies
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1">
          eSentire Sensor
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1">
          Carbon Black or Crowdstrike
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1">
          <span>
            SumoLogic, Tenable,{' '}
            <span className={noCloudPrismaClassName ? 'text-red' : ''}>
              Cloud Prisma
            </span>
          </span>
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1">
          SumoLogic
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1">
          SumoLogic
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-1">
          Tenable
        </div>

        {/**
         * CORE COMPETENCY
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-1">
          Core Competency
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-1">
          Things in motion
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-1">
          Process visibility
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-1">
          Cloud infrastructure
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-1">
          Cloud Applications
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-1">
          Breadth &amp; Integrations
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-1">
          Vulnerability visbility
        </div>

        {/**
         * DEPTH OF VISIBILITY
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-2">
          Depth of Visibility
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-2">
          <DotIndicator
            value={
              noEsnetworkClassName
                ? null
                : KILLCHAIN_DATA.depthOfVisibility.esnetwork
            }
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-2">
          <DotIndicator
            value={
              noEsendpointClassName
                ? null
                : KILLCHAIN_DATA.depthOfVisibility.esendpoint
            }
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-2${noEslogClassName}${noCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              KILLCHAIN_DATA.depthOfVisibility.escloudi -
              (noEslogClassName ? 0.5 : 0) -
              (noCloudPrismaClassName ? 0.5 : 0)
            }
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-2${noEslogAndNoCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              noEslogClassName
                ? null
                : KILLCHAIN_DATA.depthOfVisibility.esclouds
            }
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Varies">
          Varies based on log sources and use cases
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Adds">
          Adds vulnerability awareness
        </div>

        {/**
         * SPEED OF DETECTION
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-3">
          Speed of Detection
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-3">
          <DotIndicator
            value={
              noEsnetworkClassName
                ? null
                : KILLCHAIN_DATA.speedOfDetection.esnetwork
            }
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-3">
          <DotIndicator
            value={
              noEsendpointClassName
                ? null
                : KILLCHAIN_DATA.speedOfDetection.esendpoint
            }
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-3${noEslogAndNoCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={KILLCHAIN_DATA.speedOfDetection.escloudi}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-3${noEslogAndNoCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              noEslogClassName ? null : KILLCHAIN_DATA.speedOfDetection.esclouds
            }
          />
        </div>

        {/**
         * SPEED OF RESPONSE
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-4">
          Speed of Response
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-4">
          <DotIndicator
            value={
              noEsnetworkClassName
                ? null
                : KILLCHAIN_DATA.speedOfResponse.esnetwork
            }
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-4">
          <DotIndicator
            value={
              noEsendpointClassName
                ? null
                : KILLCHAIN_DATA.speedOfResponse.esendpoint
            }
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-4${noEslogAndNoCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={KILLCHAIN_DATA.speedOfResponse.escloudi}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-4${noEslogAndNoCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              noEslogClassName ? null : KILLCHAIN_DATA.speedOfResponse.esclouds
            }
          />
        </div>

        {/**
         * AUTOMATED RESPONSE
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-5">
          Automated Response
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-5${noEsnetworkClassName}`}
        >
          {noEsnetworkClassName ? <Close bold size={10} /> : 'Yes'}
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-5${noEsendpointClassName}`}
        >
          {noEsendpointClassName ? <Close bold size={10} /> : 'Yes'}
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-5${noCloudPrismaClassName}`}
        >
          {noCloudPrismaClassName ? <Close bold size={10} /> : 'Limited'}
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-5 KillchainOverview__Cell--opacity">
          -
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-5 KillchainOverview__Cell--opacity">
          -
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-5 KillchainOverview__Cell--opacity">
          -
        </div>

        {/**
         * MANAGED CONTAINMENT
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-6">
          Managed Containment
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-6${noEsnetworkClassName}`}
        >
          {noEsnetworkClassName ? <Close bold size={10} /> : 'Disruption'}
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--blue-6${noEsendpointClassName}`}
        >
          {noEsendpointClassName ? <Close bold size={10} /> : 'Isolation'}
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-6 KillchainOverview__Cell--opacity">
          -
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-6 KillchainOverview__Cell--opacity">
          -
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-6 KillchainOverview__Cell--opacity">
          -
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--blue-6 KillchainOverview__Cell--opacity">
          -
        </div>

        {/**
         * EXTERNAL RECON
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-3">
          External Recon
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEsnetworkClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEsnetworkClassName)}
            value={KILLCHAIN_DATA.externalRecon.esnetwork}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              KILLCHAIN_DATA.externalRecon.escloudi -
              (noCloudPrismaClassName ? 1.5 : 0)
            }
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.externalRecon.eslog}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noMvsClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noMvsClassName)}
            value={KILLCHAIN_DATA.externalRecon.mvs}
          />
        </div>

        {/**
         * WEAPONIZATION
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-3">
          Weaponization
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>

        {/**
         * DELIVERY
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-3">
          Delivery
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEsnetworkClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEsnetworkClassName)}
            value={KILLCHAIN_DATA.delivery.esnetwork}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEsendpointClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEsendpointClassName)}
            value={KILLCHAIN_DATA.delivery.esendpoint}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              KILLCHAIN_DATA.delivery.escloudi -
              (noCloudPrismaClassName ? 0.5 : 0)
            }
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.delivery.esclouds}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.delivery.eslog}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>

        {/**
         * EXPLOITATION
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-3">
          Exploitation
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEsnetworkClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEsnetworkClassName)}
            value={KILLCHAIN_DATA.exploitation.esnetwork}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEsendpointClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEsendpointClassName)}
            value={KILLCHAIN_DATA.exploitation.esendpoint}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              KILLCHAIN_DATA.exploitation.escloudi -
              (noCloudPrismaClassName ? 0.5 : 0)
            }
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.exploitation.esclouds}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.exploitation.eslog}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>

        {/**
         * INSTALLATION
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-3">
          Installation
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEsendpointClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEsendpointClassName)}
            value={KILLCHAIN_DATA.installation.esendpoint}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.installation.escloudi}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.installation.eslog}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>

        {/**
         * INTERNAL RECON
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-3">
          Internal Recon
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2">
          {noEsnetworkClassName ? (
            ''
          ) : (
            <DotIndicator value={KILLCHAIN_DATA.internalRecon.esnetwork} />
          )}
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              KILLCHAIN_DATA.internalRecon.escloudi -
              (noCloudPrismaClassName ? 0.5 : 0)
            }
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.internalRecon.esclouds}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.internalRecon.eslog}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noMvsClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noMvsClassName)}
            value={KILLCHAIN_DATA.internalRecon.mvs}
          />
        </div>

        {/**
         * COMMAND & CONTROL
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-3">
          Command &amp; Control
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEsnetworkClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEsnetworkClassName)}
            value={KILLCHAIN_DATA.commandAndControl.esnetwork}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}${noCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              KILLCHAIN_DATA.commandAndControl.escloudi -
              (noEslogClassName ? 0.5 : 0) -
              (noCloudPrismaClassName ? 0.5 : 0)
            }
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.commandAndControl.eslog}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>

        {/**
         * DATA COLLECTION
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-3">
          Data Collection
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}${noCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              KILLCHAIN_DATA.dataCollection.escloudi -
              (noEslogClassName ? 1 : 0) -
              (noCloudPrismaClassName ? 1 : 0)
            }
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.dataCollection.esclouds}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.dataCollection.eslog}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>

        {/**
         * EXFILTRATION
         */}

        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-3">
          Exfiltration
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEsnetworkClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEsnetworkClassName)}
            value={KILLCHAIN_DATA.exfiltration.esnetwork}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}${noCloudPrismaClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogAndNoCloudPrismaClassName)}
            value={
              KILLCHAIN_DATA.exfiltration.escloudi -
              (noEslogClassName ? 1 : 0) -
              (noCloudPrismaClassName ? 1 : 0)
            }
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.exfiltration.esclouds}
          />
        </div>
        <div
          className={`KillchainOverview__Cell KillchainOverview__Cell--grey-2${noEslogClassName}`}
        >
          <DotIndicator
            disabled={Boolean(noEslogClassName)}
            value={KILLCHAIN_DATA.exfiltration.eslog}
          />
        </div>
        <div className="KillchainOverview__Cell KillchainOverview__Cell--grey-2"></div>

        {/**
         * SERVICES MENU
         */}

        {showMenu && (
          <ServicesMenu
            selectedProducts={KillchainState.selectedProducts}
            onSelectNone={this.onSelectNone}
            onSelectAll={this.onSelectAll}
            updateSelectedProducts={this.onUpdateSelectedProducts}
            onClose={this.onToggleMenu}
          />
        )}
      </div>
    );
  }
}
