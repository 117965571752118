/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Dropbox extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      size = 32,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 32 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M7.99966 0L0 5.11765L7.99966 10.2353L16.0007 5.11765L7.99966 0Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            d="M24.0003 0L16.0007 5.11765L24.0003 10.2353L32 5.11765L24.0003 0Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            d="M0 15.353L7.99966 20.4706L16.0007 15.353L7.99966 10.2354L0 15.353Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            d="M24.0003 10.2354L16.0007 15.353L24.0003 20.4706L32 15.353L24.0003 10.2354Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            d="M7.99963 22.1762L16.0007 27.2939L24.0003 22.1762L16.0007 17.0586L7.99963 22.1762Z"
            fill={color}
            fillOpacity={opacity}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="32"
              height="27.2941"
              fill={color}
              fillOpacity={opacity}
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
