/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Server extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 44,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 40 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.001 0C8.786 0 0.001 3.953 0 9C0 12.17 8.922 16 20.001 16C29.158 16 37.251 13.477 40.001 10.844V9C40.001 3.953 31.216 0 20.001 0Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 12.7246V17.9996C0 21.1696 8.921 24.9996 20 24.9996C29.158 24.9996 37.252 22.4766 40 19.8426L40.001 13.3376C35.729 16.2106 27.566 17.9996 20.001 17.9996C12.071 17.9996 3.725 15.9856 0 12.7246Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 21.7246V26.9996C0 30.1696 8.921 33.9996 20 33.9996C29.158 33.9996 37.251 31.4766 40 28.8436V22.3366C35.728 25.2106 27.566 26.9996 20 26.9996C12.071 26.9996 3.725 24.9856 0 21.7246Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 30.7246V34.9996C0 40.0466 8.785 43.9996 20 43.9996C31.215 43.9996 40 40.0466 40 34.9996V31.3376C35.728 34.2106 27.566 35.9996 20 35.9996C12.071 35.9996 3.725 33.9856 0 30.7246Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
