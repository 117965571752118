/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { getColor } from '../components/helpers';
import { AnimatedSlide } from '../components/animated-slide';

// SCSS
import './scan-benign-or-malicious.scss';

// Players
import { PlayerDataScan } from '../components/players/data-scan';
import { PlayerPath } from '../components/players/path';

// SVG
import { Shield } from '../components/svg/shield';
import { MachineLearning } from '../components/svg/machine-learning';
import { PlayerCounter } from '../components/players/counter';

@observer
export class Template__ScanBenignOrMalicious extends AnimatedSlide {
  // Animation players
  players = {
    shield: null,
    scanner: null,
    malicious: null,
    benign: null,
    log: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
  };

  shield = {
    icon: MachineLearning,
    title: 'BLUESTEEL',
    gradient: true,
    size: 55,
    opacity: 1,
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;

    const {
      shield,
      scanner,
      malicious,
      benign,
      log,
      path1,
      path2,
      path3,
      path4,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(6000);
        this.autoNext(500);
      },
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      [
        this.fadeIn(shield, 0),
        this.fadeIn(path1, 500),
        this.fadeIn(scanner, 1000),
        this.fadeIn(path2, 1000),
        this.fadeIn(path4, 1000),
        this.fadeIn(malicious, 2700),
        this.fadeIn(benign, 2700),
        this.fadeIn(path3, 2700),
        this.fadeIn(log, 3200),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let offset = 500;

    return (
      <div className={`Template__ScanBenignOrMalicious ${className}`}>
        {children}
        <div className="content">
          {/* --------------- CONTENT ---------------*/}
          {/* Shield */}
          <div
            className="player shield"
            data-active="0"
            ref={node => (this.players.shield = node)}
          >
            <Shield
              IconComponent={this.shield.icon}
              text={this.shield.title}
              IconSize={this.shield.size}
              IconOpacity={this.shield.opacity}
              gradient={this.shield.gradient}
            />
          </div>
          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,3', '100,3']}
              reveal={this.revealPreset(500 + offset, 1000)}
            />
          </div>
          {/* Data scan */}
          <div
            className="player scanner"
            data-active="0"
            ref={node => (this.players.scanner = node)}
          >
            <div className="player-title">POWERSHELL COMMANDS</div>
            <PlayerDataScan
              columns={[
                '20px 1fr 1fr',
                '20px 1fr 1fr 1fr',
                '20px 1fr 1fr 1fr 1fr',
                '20px 1fr 1fr 1fr 1fr 1fr',
                '20px 1fr 1fr 1fr 1fr 1fr 1fr',
                '20px 2fr 1fr',
                '20px 2fr 1.5fr 1fr',
                '20px 1fr 1.5fr 2fr 1fr',
                '20px 2fr 3fr 1fr 1fr 2fr',
                '20px 3fr 1fr 1fr 2fr 1fr 1fr',
              ]}
              gap={4}
              width={360}
              height={260}
              rows={40}
              increment={0.1}
            />
          </div>
          {/* Malicious counter */}
          <div
            className="player malicious"
            data-active="0"
            ref={node => (this.players.malicious = node)}
          >
            <div className="NumberBox red">
              <label>Malicious</label>
              <PlayerCounter
                start={768000}
                end={980210}
                duration={8000}
                delay={3700}
                fontSize={14}
              />
            </div>
          </div>
          {/* Log4j counter */}
          <div
            className="player log"
            data-active="0"
            ref={node => (this.players.log = node)}
          >
            <div className="NumberBox big">
              <label>
                Log4j
                <br />
                EXPLOIT ATTEMPTS
              </label>
              <PlayerCounter
                start={139000}
                end={144000}
                duration={8000}
                delay={3700}
                fontSize={30}
              />
            </div>
          </div>
          {/* Benign counter */}
          <div
            className="player benign"
            data-active="0"
            ref={node => (this.players.benign = node)}
          >
            <div className="NumberBox blue">
              <label>Benign</label>
              <PlayerCounter
                start={927100}
                end={1200597}
                duration={8000}
                delay={3700}
                fontSize={14}
              />
            </div>
          </div>
          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,140', '50,140', '50,3', '180,3', '180,28']}
              gradient={{
                preset: 'blueRed',
                direction: 45,
              }}
              reveal={this.revealPreset(2000 + offset, 1000)}
            />
          </div>
          {/* Path 3 */}
          <div
            className="player path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['3,0', '3,30']}
              dots={{
                stroke: getColor('red'),
              }}
              reveal={this.revealPreset(3000 + offset, 500)}
            />
          </div>
          {/* Path 4 */}
          <div
            className="player path4"
            data-active="0"
            ref={node => (this.players.path4 = node)}
          >
            <PlayerPath
              points={['0,3', '50,3', '50,140', '180,140', '180,112']}
              reveal={this.revealPreset(2000 + offset, 1000)}
            />
          </div>
          {/* --------------- CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
