/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './sparks.scss';
import { settings } from 'cluster';

@observer
export class PlayerSparks extends Component {
  wrapper = null;

  componentDidMount() {}

  renderDots() {
    const {
      color,
      animation,
      size,
      dots,
      duration,
      round,
      iteration,
    } = this.settings();

    // Set color
    let c = null;
    if (color) {
      c = color;
      if (color == 'red') c = '#C53D43';
      if (color == 'white') c = '#ffffff';
      if (color == 'green') c = '#54D5AE';
      if (color == 'blue') c = '#50C2E3';
    }

    let spans = [];
    let wait = duration / dots;
    let space = 100 / dots;
    let seed = Math.floor(Math.random() * 10) / 10;
    for (let i = 0; i < dots; i++) {
      let styleSettings = {
        animationDelay: `${i * wait * -1 + seed}s`,
        animationDuration: `${duration}s`,
        animationIterationCount: iteration,
        left: `${i * space}%`,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: round ? '50%' : '0',
      };
      if (animation) styleSettings.animationName = animation;
      if (c) styleSettings.backgroundColor = c;
      spans.push(<span key={i} style={styleSettings} />);
    }
    return spans;
  }

  renderTracks() {
    const { degrees, tracks, width } = this.settings();

    let distance = degrees / (tracks - 1);
    let divs = [];
    for (let i = 0; i < tracks; i++) {
      let rotation = i * distance;
      let trackStyles = {
        transform: `rotate(${rotation}deg)`,
        width: `${width}px`,
      };
      divs.push(
        <div key={i} className={'spark-track'} style={trackStyles}>
          {this.renderDots()}
        </div>
      );
    }
    return <div className={'spark-tracks'}>{divs}</div>;
  }

  settings() {
    let defaults = {
      className: '',
      reverse: false,
      rotate: 0,
      degrees: 140,
      tracks: 5,
      width: 50,
      color: null,
      animation: 'sparksMove',
      iteration: 'infinite',
      size: 4,
      dots: 2,
      duration: 2,
      round: true,
    };
    return Object.assign({}, defaults, this.props);
  }

  render() {
    // Get parameters
    let settings = this.settings();

    let wrapperStyles = {};
    if (Math.abs(settings.rotate))
      wrapperStyles.transform = `rotate(${settings.rotate}deg)`;

    let reverseClass = settings.reverse ? 'reverse-direction' : '';

    return (
      <div
        className={`player--sparks ${settings.className}`}
        style={wrapperStyles}
        ref={node => (this.wrapper = node)}
      >
        {this.renderTracks(settings)}
      </div>
    );
  }
}
