/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class GoogleDrive extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      size = 37,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 37 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            opacity="0.6"
            d="M2.74181 27.4257L4.3412 30.1539C4.67354 30.7283 5.15128 31.1796 5.7121 31.5078L11.4242 21.7437H0C0 22.3795 0.16617 23.0154 0.498511 23.5898L2.74181 27.4257Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            d="M18.1333 10.2563L12.4212 0.492188C11.8604 0.820393 11.3827 1.27167 11.0503 1.84603L0.498511 19.8973C0.172282 20.4593 0.000434354 21.0957 0 21.7435H11.4242L18.1333 10.2563Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            opacity="0.6"
            d="M30.5545 31.5078C31.1154 31.1796 31.5931 30.7283 31.9255 30.1539L32.5901 29.0257L35.7681 23.5898C36.1005 23.0154 36.2667 22.3795 36.2667 21.7437H24.8416L27.2727 26.4616L30.5545 31.5078Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            opacity="0.6"
            d="M18.1333 10.2564L23.8454 0.492308C23.2846 0.164103 22.6407 0 21.976 0H14.2906C13.6259 0 12.982 0.184615 12.4212 0.492308L18.1333 10.2564Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            opacity="0.3"
            d="M24.8425 21.7437H11.4242L5.7121 31.5078C6.27292 31.836 6.91683 32.0001 7.58151 32.0001H28.6851C29.3498 32.0001 29.9937 31.8154 30.5546 31.5078L24.8425 21.7437Z"
            fill={color}
            fillOpacity={opacity}
          />
          <path
            opacity="0.8"
            d="M30.4922 10.8717L25.2163 1.84603C24.884 1.27167 24.4062 0.820393 23.8454 0.492188L18.1333 10.2563L24.8424 21.7435H36.2459C36.2459 21.1076 36.0797 20.4717 35.7473 19.8973L30.4922 10.8717Z"
            fill={color}
            fillOpacity={opacity}
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect
              width="36.2667"
              height="32"
              fill={color}
              fillOpacity={opacity}
            />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
