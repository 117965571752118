/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDataMoving } from '../players/data-moving';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Page } from '../svg/page';

@observer
export class Slide21 extends AnimatedSlide {
  // Animation players
  players = {
    dataLeft: null,
    data: null,
    packet: null,
  };

  // Run the first animation
  componentDidMount() {
    const { dataLeft, packet, data } = this.players;

    this.steps = [
      // Slide 1
      () => {
        this.animateProgress(2000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: packet,
          translateY: [80, 0],
          duration: 200,
          delay: 1000,
          begin: shimmer,
        },
        {
          targets: data,
          opacity: 1,
          translateY: [80, 0],
          duration: 200,
          delay: 1100,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-21 ${className}`}>
        {children}
        <div className="content">
          {/* Packet capture review */}
          <div
            className="player packet"
            data-active="1"
            ref={node => (this.players.packet = node)}
          >
            <PlayerTextBlock
              title={'Packet capture review'}
              sections={[
                {
                  title: [
                    'Source',
                    '192.168.10.27',
                    'Destination',
                    '118.114.77.92',
                  ],
                  grid: 2,
                },
              ]}
            />
          </div>

          {/* Data transferred */}
          <div
            className="player data"
            data-active="0"
            ref={node => (this.players.data = node)}
          >
            <PlayerTextBlock
              title={'Data transferred'}
              sections={[
                {
                  title: ['Ports', '49838 / 80'],
                  grid: 2,
                },
                {
                  lines: 8,
                  lineColumns: 2,
                  animate: true,
                  delay: 400,
                },
              ]}
            />
          </div>

          {/* Left data moving */}
          <div
            className="player data-left"
            data-active="1"
            ref={node => (this.players.dataLeft = node)}
          >
            <PlayerDataMoving wait="0" />
          </div>
        </div>
      </div>
    );
  }
}
