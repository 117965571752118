/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, isValidElement } from 'react';
import { AnimatedSlide } from '../components/animated-slide';
import { getColor } from '../components/helpers';

// SCSS
import './team-isolates-host.scss';

// Players
import { PlayerPath } from '../components/players/path';

// SVG
import { Shield } from '../components/svg/shield';
import { EsentireE } from '../components/svg/esentire-e';
import { Computer } from '../components/svg/computer';

@observer
export class Template__TeamIsolatesHost extends AnimatedSlide {
  // Animation players
  players = {
    team: null,
    icon: null,
    path: null,
    ring: null,
    connections: null,
  };

  // Team shield
  team = {
    icon: <EsentireE size={50} color={this.colors.white} opacity={1} />,
    text: 'SOC Analyst',
    stroke: this.colors.blue,
    iconOffsetY: -10,
    strokeOpacity: 1,
    letterSpacing: 0,
  };

  path = {
    points: ['0,0', '220,0'],
    gradient: {
      direction: 'right',
      preset: 'blueWhite',
    },
    dots: {
      strokeWidth: 4,
      strokeDashArray: '4, 4',
    },
    reveal: {
      delay: 1000,
    },
    data: false,
  };

  // Dot styles
  dots = {
    opacity: 1,
    strokeLinecap: 'round',
    strokeWidth: 6,
    strokeDashArray: '0, 20',
    animationDuration: '4s',
  };

  // Dot gradient
  gradient = [
    {
      offset: 0,
      stopColor: this.colors.blue,
      stopOpacity: 1,
    },
    {
      offset: 1,
      stopColor: this.colors.blue,
      stopOpacity: 0,
    },
  ];

  // Run the first animation
  componentDidMount() {
    const { team, icon, path, connections, ring } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 2
      () => {
        this.autoNext(4000);
      },
      // Step 3
      () => {
        ring.classList.add('smaller');
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(team, 0),
        this.fadeIn(icon, 1000),
        this.fadeIn(connections, 1200),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const {
      children,
      className = '',
      team = {},
      path = {},
      dots = {},
    } = this.props;

    Object.assign(this.team, team);
    Object.assign(this.path, path);
    Object.assign(this.dots, dots);

    let lineChangeDelay = 4500;
    let lineReveal = null;

    return (
      <div className={`Template__TeamIsolatesHost ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}

          {/* Team */}
          <div
            className="player team"
            data-active="0"
            ref={node => (this.players.team = node)}
          >
            <Shield {...this.team} />
          </div>

          {/* Path */}
          <div
            className="player path"
            data-active="1"
            ref={node => (this.players.path = node)}
          >
            <PlayerPath {...this.path} />
          </div>

          {/* Icon */}
          <div
            className="player icon"
            data-active="0"
            ref={node => (this.players.icon = node)}
          >
            <Computer size={100} color={this.colors.white} opacity={1} />
          </div>

          {/* Connections */}
          <div
            className="player connections"
            data-active="0"
            ref={node => (this.players.connections = node)}
          >
            <div
              className="connections-ring"
              ref={node => (this.players.ring = node)}
            ></div>
            <div className="connections-wrapper">
              <PlayerPath
                points={['160,160', '0,0']}
                pointsChange={{
                  points: ['160,160', '111,111'],
                  delay: lineChangeDelay,
                }}
                reveal={lineReveal}
                gradient={{ colors: this.gradient, direction: 135 }}
                dots={this.dots}
              />
              <PlayerPath
                points={['3,160', '3,0']}
                pointsChange={{
                  points: ['3,160', '3,80'],
                  delay: lineChangeDelay,
                }}
                reveal={lineReveal}
                gradient={{ colors: this.gradient, direction: 'up' }}
                dots={this.dots}
              />
              <PlayerPath
                points={['0,160', '160,0']}
                pointsChange={{
                  points: ['0,160', '49,111'],
                  delay: lineChangeDelay,
                }}
                reveal={lineReveal}
                gradient={{ colors: this.gradient, direction: 45 }}
                dots={this.dots}
              />
              <PlayerPath
                points={['0,0', '160,0']}
                pointsChange={{
                  points: ['0,0', '80,0'],
                  delay: lineChangeDelay,
                }}
                reveal={lineReveal}
                gradient={{ colors: this.gradient, direction: 'right' }}
                dots={this.dots}
              />
              <PlayerPath
                points={['0,0', '160,160']}
                pointsChange={{
                  points: ['0,0', '49,49'],
                  delay: lineChangeDelay,
                }}
                reveal={lineReveal}
                gradient={{ colors: this.gradient, direction: 270 }}
                dots={this.dots}
              />
              <PlayerPath
                points={['3,0', '3,160']}
                pointsChange={{
                  points: ['3,0', '3,80'],
                  delay: lineChangeDelay,
                }}
                reveal={lineReveal}
                gradient={{ colors: this.gradient, direction: 'down' }}
                dots={this.dots}
              />
              <PlayerPath
                points={['160,0', '0,160']}
                pointsChange={{
                  points: ['160,0', '111,49'],
                  delay: lineChangeDelay,
                }}
                reveal={lineReveal}
                gradient={{ colors: this.gradient }}
                dots={this.dots}
              />
            </div>
          </div>

          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
