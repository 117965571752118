import { observer } from 'mobx-react';
import * as React from 'react';
import { Component } from 'react';
import './risk-chart.scss';
import { ChartShell } from './chart-shell.jsx';
import { ChartShellStore } from './chart-shell-store';
import {
  BarChart,
  YAxis,
  XAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  Bar,
} from 'recharts';
import { Color } from 'csstype';
import { generateColor } from '../../data/color-generator';
import { autorun, observable, toJS } from 'mobx';
import {
  riskRates,
  getRiskDataAttackTypes,
} from '../../data/graph-data-transforms';

const formatPercent = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 0,
}).format;

export interface IProps extends Pick<HTMLElement, 'className'> {
  /** Data filter category */
  category: string;
  /** The colors for the data series */
  colors?: Color[];
  /** This is required, and will default to 500 */
  width: number;
  /** This is required, and will default to 500 */
  height: number;
  /**
   * The industries to highlight. If not provided, will default to the first
   * four industries in the data
   */
  industries: string[];
  /** Total sensors in use */
  sensorCount: number;
}

@observer
export class RiskChart extends Component<IProps> {
  private _dispose: () => void;
  @observable private attackTypes: string[] = [];
  @observable private data: any[] = [];

  async componentDidMount() {
    this.attackTypes = await getRiskDataAttackTypes();

    this._dispose = autorun(() => {
      (async () => {
        const { category = 'Industry', sensorCount = 1 } = this.props;

        this.data = await riskRates({
          category: category,
          sensors: sensorCount,
        });
      })();
    });
  }

  componentWillUnmount() {
    try {
      this._dispose();
    } catch {}
  }

  render() {
    const {
      category,
      children,
      className = '',
      colors = [],
      width = 500,
      height = 500,
      industries = [],
    } = { ...this.props };
    const data = this.data;

    if (!industries.length) {
      industries.push(...data.map(d => d.filter).slice(0, 4));
    }

    const filtered = industries.map(
      filter => data.find(d => d.filter === filter) || { filter }
    );

    return (
      <ChartShell
        className={className}
        topSection={`Yearly ${category} Risk Rates of Experiencing an Incident`}
        leftSection="Cumulative Probability of Experiencing 1 or More Incidents per Sensor"
      >
        <BarChart
          className="BarChart Graph"
          {...{ width, height }}
          data={filtered}
          barCategoryGap={20}
          margin={{ bottom: 20 }}
        >
          <YAxis
            axisLine={false}
            tickLine={false}
            domain={[0, 'dataMax']}
            tickFormatter={formatPercent}
          />
          <XAxis tickLine={false} dataKey={'filter'} />
          <CartesianGrid vertical={false} stroke="rgba(255, 255, 255, 0.5)" />
          <Legend iconType="circle" wrapperStyle={{ bottom: 0 }} />
          <Tooltip
            isAnimationActive={false}
            content={this.renderTooltip}
            cursor={false}
          />
          {this.attackTypes.map((name, i) => (
            <Bar
              key={name}
              dataKey={name}
              fill={`${colors[i] || generateColor(i)}`}
              minPointSize={3}
            />
          ))}
          {children}
        </BarChart>
      </ChartShell>
    );
  }

  renderTooltip = data => {
    if (!(data && data.payload && data.payload.length)) return;

    return (
      <div className="ProbabilityInstantChart__Tooltip">
        <div className="Tooltip__Header">{data.label}</div>
        <span className="Tooltip__TH">Attack Type</span>
        <span className="Tooltip__TH">Probability</span>
        {data.payload.map((series, i) => {
          return (
            <React.Fragment key={`${series.name}`}>
              <span className="Tooltip__Line" />
              <span
                className="Tooltip__Dot"
                style={{ background: series.fill }}
              />
              <span className="Tooltip__NameCell">{series.name}</span>
              <span className="Tooltip__ValueCell">
                {formatPercent(series.value)}
              </span>
            </React.Fragment>
          );
        })}
      </div>
    );
  };
}
