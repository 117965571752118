/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Chip extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#C53D43',
      size = 35,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        width={size}
        height={size}
        viewBox={`0 0 48 48`}
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd" clipRule="evenodd">
          <path
            d="M36 31C36 33.757 33.757 36 31 36H17C14.243 36 12 33.757 12 31V17C12 14.243 14.243 12 17 12H31C33.757 12 36 14.243 36 17V31ZM47 25C47.553 25 48 24.552 48 24C48 23.448 47.553 23 47 23H40V16H47C47.553 16 48 15.552 48 15C48 14.448 47.553 14 47 14H40V11C40 9.346 38.654 8 37 8H34V1C34 0.448 33.553 0 33 0C32.447 0 32 0.448 32 1V8H25V1C25 0.448 24.553 0 24 0C23.447 0 23 0.448 23 1V8H16V1C16 0.448 15.553 0 15 0C14.447 0 14 0.448 14 1V8H11C9.346 8 8 9.346 8 11V14H1C0.447 14 0 14.448 0 15C0 15.552 0.447 16 1 16H8V23H1C0.447 23 0 23.448 0 24C0 24.552 0.447 25 1 25H8V32H1C0.447 32 0 32.448 0 33C0 33.552 0.447 34 1 34H8V37C8 38.654 9.346 40 11 40H14V47C14 47.552 14.447 48 15 48C15.553 48 16 47.552 16 47V40H23V47C23 47.552 23.447 48 24 48C24.553 48 25 47.552 25 47V40H32V47C32 47.552 32.447 48 33 48C33.553 48 34 47.552 34 47V40H37C38.654 40 40 38.654 40 37V34H47C47.553 34 48 33.552 48 33C48 32.448 47.553 32 47 32H40V25H47Z"
            fillOpacity={opacity}
          />
          <path
            d="M19 20H27C27.553 20 28 19.552 28 19C28 18.448 27.553 18 27 18H19C18.447 18 18 18.448 18 19C18 19.552 18.447 20 19 20Z"
            fillOpacity={opacity}
          />
          <path
            d="M23 22H19C18.447 22 18 22.448 18 23C18 23.552 18.447 24 19 24H23C23.553 24 24 23.552 24 23C24 22.448 23.553 22 23 22Z"
            fillOpacity={opacity}
          />
          <path
            d="M29 28H25C24.447 28 24 28.448 24 29C24 29.552 24.447 30 25 30H29C29.553 30 30 29.552 30 29C30 28.448 29.553 28 29 28Z"
            fillOpacity={opacity}
          />
        </g>
      </svg>
    );
  }
}
