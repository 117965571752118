/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { getColor } from '../components/helpers';
import { AnimatedSlide } from '../components/animated-slide';

// SCSS
import './team-scanning-customers.scss';

// Players
import { PlayerNetworkScan } from '../components/players/network-scan';
import { PlayerPath } from '../components/players/path';
import { PlayerIconLoad } from '../components/players/icon-load';

// SVG
import { TRU } from '../components/svg/tru';
import { Shield } from '../components/svg/shield';
import { SignalLog } from '../components/svg/signal-log';

@observer
export class Template__TeamScanningCustomers extends AnimatedSlide {
  // Animation players
  players = {
    team: null,
    mdr: null,
    customers: null,
    path: null,
    paths: null,
  };

  // Starting path delay
  pathDelay = 500;

  // Shield1
  shield = {
    icon: TRU,
    text: 'TRU TEAM',
    size: 50,
    opacity: 1,
    stroke: getColor('blue'),
    strokeOpacity: 1,
  };

  product = {
    icon: SignalLog,
    title: 'MDR FOR LOG',
    left: 0,
    top: 0,
  };

  scanner = {
    columns: 4,
    rows: 4,
    width: 180,
    gap: 16,
    padding: 17,
    borderColor: getColor('blue'),
    borderOpacity: 1,
    random: false,
    remediate: false,
    delay: 3500,
    duration: 2000,
  };

  // Run the first animation
  componentDidMount() {
    const { team, mdr, customers, path, paths } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(1000);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(team, 0),
        this.fadeIn(path, 0),
        this.fadeIn(mdr, 1000),
        this.fadeIn(paths, 1500),
        this.fadeIn(customers, 3500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  revealPreset(delay, duration = 1000) {
    let offset = 1700;
    return {
      delay: delay + offset,
      duration: duration,
      strokeWidth: 2,
      radius: 2,
    };
  }

  render() {
    const {
      children,
      className = '',
      shield = {},
      product = {},
      scanner = {},
    } = this.props;

    Object.assign(this.shield, shield);
    Object.assign(this.product, product);
    Object.assign(this.scanner, scanner);

    let gradientPreset = { preset: 'blueWhite' };
    let dotPreset = {
      stroke: getColor('white'),
      opacity: 1,
      strokeWidth: 2,
      strokeDashArray: '2,3',
    };

    return (
      <div className={`Template__TeamScanningCustomers ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TRU Team */}
          <div
            className="player team"
            data-active="0"
            ref={node => (this.players.team = node)}
          >
            <Shield
              IconComponent={this.shield.icon}
              text={this.shield.text}
              IconSize={this.shield.size}
              IconOpacity={this.shield.opacity}
              stroke={this.shield.stroke}
              strokeOpacity={this.shield.strokeOpacity}
            />
          </div>
          {/* Path */}
          <div
            className="player path"
            data-active="0"
            ref={node => (this.players.path = node)}
          >
            <PlayerPath
              points={['0,3', '180,3']}
              gradient={{
                direction: 'right',
                preset: 'blueWhite',
                opacity: 1,
              }}
              reveal={{
                strokeWidth: 5,
                duration: 1000,
                delay: 500,
              }}
            />
          </div>
          {/* MDR for Log */}
          <div
            className="player mdr"
            data-active="0"
            ref={node => (this.players.mdr = node)}
          >
            <div className="player-title">{this.product.title}</div>
            <PlayerIconLoad
              strokeWidth={2}
              size={120}
              start={{
                IconComponent: this.product.icon,
                color: getColor('blue'),
                strokeWidth: 2,
                left: this.product.left,
                top: this.product.top,
                size: 80,
              }}
              loading={{
                delay: 0,
                strokeDashoffset: '20,160',
                duration: 2000,
              }}
            />
          </div>
          {/* Paths */}
          <div
            className="player terminal paths"
            data-active="0"
            ref={node => (this.players.paths = node)}
          >
            {/* Top */}
            <PlayerPath
              points={['0,70', '76,70', '76,3', '328,3', '328,40']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(600, 2000)}
            />
            <PlayerPath
              points={['0,70', '70,70', '70,3', '272,3', '272,30']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(800, 1800)}
            />
            <PlayerPath
              points={['0,70', '71,70', '71,3', '223,3', '223,20']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(1000, 1600)}
            />
            <PlayerPath
              points={['0,70', '75,70', '75,3', '177,3', '177,10']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(1200, 1400)}
            />
            {/* Center */}
            <PlayerPath
              points={['0,40', '82,40', '82,3', '144,3']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(1400)}
            />
            <PlayerPath
              points={['0,15', '90,15', '90,3', '144,3']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(1600)}
            />
            <PlayerPath
              points={['0,3', '90,3', '90,22', '144,22']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(1600)}
            />
            <PlayerPath
              points={['0,3', '82,3', '82,50', '144,50']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(1400)}
            />
            {/* Bottom */}
            <PlayerPath
              points={['0,3', '75,3', '75,90', '177,90', '177,80']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(1200, 1400)}
            />
            <PlayerPath
              points={['0,3', '71,3', '71,90', '223,90', '223,70']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(1000, 1600)}
            />
            <PlayerPath
              points={['0,3', '70,3', '70,90', '272,90', '272,60']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(800, 1800)}
            />
            <PlayerPath
              points={['0,3', '76,3', '76,90', '328,90', '328,50']}
              dots={dotPreset}
              gradient={gradientPreset}
              reveal={this.revealPreset(600, 2000)}
            />
          </div>
          <div
            className="player customers"
            data-active="0"
            ref={node => (this.players.customers = node)}
          >
            <PlayerNetworkScan
              columns={this.scanner.columns}
              rows={this.scanner.rows}
              width={this.scanner.width}
              gap={this.scanner.gap}
              padding={this.scanner.padding}
              borderColor={this.scanner.borderColor}
              borderOpacity={this.scanner.borderOpacity}
              random={this.scanner.random}
              remediate={this.scanner.remediate}
              duration={this.scanner.duration}
              delay={this.scanner.delay}
            />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
