import { observable, reaction, computed } from 'mobx';
import {
  getRiskDataAttackTypes,
  getRiskDataCategories,
  getRiskDataFilters,
  riskRatesTable,
  getComparativeRates,
  GLOBAL_FILTER_OPTION,
} from '../../data/graph-data-transforms';

export const SENSORS_OPTIONS = [...new Array(10)].map((item, i) => {
  return {
    label: i + 1,
    value: i + 1,
  };
});

class State {
  @observable title = 'Risk Rates';
  @observable attackTypes = [];
  @observable categories = [];
  @observable filters = [];
  @observable comparativeIndustries = [];

  @observable category = null;
  @observable filter = null;
  @observable
  sensors = {
    label: 1,
    value: 1,
  };

  @observable tableYear = {};
  @observable tableMonth = {};

  @observable showProbabilityModal = false;
  @observable showProbabilityPickerModal = false;
  @observable showImpactModal = false;

  @computed
  get categoryOptions() {
    return RiskAnalysisToolState.categories.map(category => ({
      label: category,
      value: category,
    }));
  }

  @computed
  get filterOptions() {
    return RiskAnalysisToolState.filters.map(industry => {
      return {
        label: industry === '.*' ? GLOBAL_FILTER_OPTION : industry,
        value: industry,
      };
    });
  }
}

export const RiskAnalysisToolState = new State();

export class RiskAnalysisToolActions {
  static async getRiskDataAttackTypes() {
    RiskAnalysisToolState.attackTypes = await getRiskDataAttackTypes();
  }

  static async initFilterSelection() {
    if (
      !RiskAnalysisToolState.categories ||
      RiskAnalysisToolState.categories.length <= 0
    ) {
      await RiskAnalysisToolActions.getCategories();
    }

    await RiskAnalysisToolActions.getRiskDataFilters();
    await RiskAnalysisToolActions.getRiskDataAttackTypes();
  }

  static async getRiskDataFilters() {
    if (RiskAnalysisToolState.category) {
      RiskAnalysisToolState.filters = (
        await getRiskDataFilters(RiskAnalysisToolState.category.value)
      ).sort();
    }

    if (!RiskAnalysisToolState.filter) {
      RiskAnalysisToolState.filter = {
        label: RiskAnalysisToolState.filters[0],
        value: RiskAnalysisToolState.filters[0],
      };
    }
  }

  static async getCategories() {
    RiskAnalysisToolState.categories = await getRiskDataCategories();

    if (RiskAnalysisToolState.categories.length) {
      if (!RiskAnalysisToolState.category) {
        const category = RiskAnalysisToolState.categories[0];
        RiskAnalysisToolState.category = {
          label: category,
          value: category,
        };
      }
    } else {
      RiskAnalysisToolState.category = null;
    }
  }

  static updateField(name, value) {
    RiskAnalysisToolState[name] = value;
  }

  static showProbabilityModal() {
    RiskAnalysisToolActions.closeModal();
    RiskAnalysisToolState.showProbabilityModal = true;
  }

  static showImpactModal() {
    RiskAnalysisToolActions.closeModal();
    RiskAnalysisToolState.showImpactModal = true;
  }

  static closeModal() {
    RiskAnalysisToolState.showProbabilityModal = false;
    RiskAnalysisToolState.showImpactModal = false;
    RiskAnalysisToolState.showProbabilityPickerModal = false;
  }
}

reaction(
  () => [RiskAnalysisToolState.category],
  async () => {
    await RiskAnalysisToolActions.getRiskDataFilters();

    if (RiskAnalysisToolState.category) {
      if (RiskAnalysisToolState.category.value === 'Industry') {
        RiskAnalysisToolState.filter = {
          label: 'Technology',
          value: 'Technology',
        };
      } else if (RiskAnalysisToolState.category.value === 'Country') {
        RiskAnalysisToolState.filter = {
          label: 'United States',
          value: 'United States',
        };
      }
    }
  }
);

reaction(
  () => [RiskAnalysisToolState.filter, RiskAnalysisToolState.sensors],
  async () => {
    if (!RiskAnalysisToolState.filter) return;

    RiskAnalysisToolState.comparativeIndustries = await getComparativeRates({
      category: RiskAnalysisToolState.category.value,
      filter: RiskAnalysisToolState.filter.value,
    });
    RiskAnalysisToolState.tableYear = await riskRatesTable({
      category: RiskAnalysisToolState.category.value,
      filter: RiskAnalysisToolState.filter.value,
      sensorCount: RiskAnalysisToolState.sensors.value,
      span: 'year',
      date: new Date(),
    });
    RiskAnalysisToolState.tableMonth = await riskRatesTable({
      category: RiskAnalysisToolState.category.value,
      filter: RiskAnalysisToolState.filter.value,
      sensorCount: RiskAnalysisToolState.sensors.value,
      span: 'month',
      date: new Date(),
    });
  }
);
