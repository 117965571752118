/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class DoubleArrowRight extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#C53D43',
      opacity = 1,
      x = 0,
      y = 0,
      size = 24,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 21 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.33195 0.560547L12.3925 9L6.33195 17.4395H0.894453L6.955 9L0.894453 0.560547H6.33195ZM14.828 0.560547L20.832 9L14.828 17.4395H9.39055L15.3945 9L9.39055 0.560547H14.828Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
