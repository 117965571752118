/** @jsx createElement */
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import { PlayerCircleIcon } from '../players/circle-icon';
import { Download2 } from '../svg/download-2';
import { Laptop } from '../svg/laptop';
import { Code } from '../svg/code';
import { Transfer } from '../svg/transfer';

@observer
export class Slide06 extends AnimatedSlide {
  // Animation players
  players = {};

  // Run the first animation
  componentDidMount() {
    const { dotsLeft, dotsRight, computer, transfer } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
        this.autoNext(3000);
      },
      // Step 2
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: computer,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 1000,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: transfer,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 400,
          begin: activateShimmer,
        },
        {
          targets: dotsLeft,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 200,
          begin: activateShimmer,
        },
        {
          targets: dotsRight,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 600,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-06 ${className}`}>
        {children}
        <div className="content">
          {/* computer */}
          <div
            className="player computer"
            data-active="0"
            ref={node => (this.players.computer = node)}
          >
            <Card IconComponent={Computer} text={'TSMITH-DESKTOP'} />
          </div>

          {/* laptop */}
          <div
            className="player laptop"
            data-active="1"
            ref={node => (this.players.laptop = node)}
          >
            <Card IconComponent={Laptop} text={'EPRINDLE-NOTEBOOK'} />
          </div>

          {/* Animated dots left */}
          <div
            className="player dots-left"
            data-active="0"
            ref={node => (this.players.dotsLeft = node)}
          >
            <PlayerDots dots={6} className={'collection'} />
          </div>

          {/* Animated dots right 1 */}
          <div
            className="player dots-right"
            data-active="0"
            ref={node => (this.players.dotsRight = node)}
          >
            <PlayerDots dots={6} className={'collection'} />
          </div>

          {/* Download */}
          <div
            className="player transfer"
            data-active="0"
            ref={node => (this.players.transfer = node)}
          >
            <PlayerCircleIcon Icon={Transfer} />
          </div>
        </div>
      </div>
    );
  }
}
