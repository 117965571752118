/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';
import anime from 'animejs';

// Players
import { PlayerTextBlock } from '../players/text-block';
import { PlayerDots } from '../players/dots';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { SkullScore } from '../svg/skull-score';
import { Shield } from '../svg/shield';
import { MachineLearning } from '../svg/machine-learning';

@observer
export class Slide08 extends AnimatedSlide {
  // Animation players
  players = {
    team: null,
    info1: null,
    info2: null,
    dots1: null,
    dots2: null,
    data: null,
    intel: null,
    update: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      team,
      info1,
      info2,
      dots1,
      dots2,
      data,
      intel,
      update,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(10000);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(3500);
      },
      // Step 2
      () => {
        update.classList.add('show');
        this.autoNext(500);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: team,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: dots1,
          width: [0, 100],
          delay: 500,
          duration: 500,
        },
        {
          targets: dots2,
          width: [0, 120],
          delay: 1000,
          duration: 500,
        },
        {
          targets: info1,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: info2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: data,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 3500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: intel,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 3700,
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2
      //
      [
        {
          targets: update,
          opacity: 1,
          translateX: [0, 150],
          easing: 'linear',
          delay: 0,
          duration: 1000,
          loop: true,
          begin: activate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-08 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TRU Team */}
          <div
            className="player team"
            data-active="0"
            ref={node => (this.players.team = node)}
          >
            <Card IconComponent={Person} text="TRU TEAM" />
            <div
              ref={node => (this.players.dots1 = node)}
              className={'animated-dotted-line reverse ants-1'}
            ></div>
            <div
              ref={node => (this.players.dots2 = node)}
              className={'animated-dotted-line reverse ants-2'}
            ></div>
          </div>
          {/* Info1 */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerTextBlock
              title={'INDICATORS ANALYSIS'}
              width={310}
              compact={true}
              className={''}
              sections={[
                {
                  title: ['4youbeautysalon.com'],
                  animate: true,
                  className: 'normal-text',
                  grid: true,
                  compact: false,
                  delay: 0,
                },
                {
                  title: ['THREAT ASSESS', '436'],
                  colors: ['rgba(#ffffff, 0.2)', this.colors.orange],
                  animate: true,
                  compact: true,
                  delay: 200,
                },
                {
                  title: ['HUNTING LEADS', <SkullScore score={5} />],
                  animate: true,
                  grid: true,
                  delay: 400,
                },
                {
                  title: ['hybrid analysis', <SkullScore score={0} />],
                  animate: true,
                  grid: true,
                  delay: 600,
                },
                {
                  title: ['technical', <SkullScore score={3} />],
                  animate: true,
                  grid: true,
                  delay: 700,
                },
              ]}
            />
          </div>
          {/* Info2 */}
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerTextBlock
              title={'ASSOCIATED GROUPS'}
              width={310}
              compact={true}
              className={''}
              sections={[
                {
                  title: ['INCIDENT', 'DLL Side-Loading Technique Used in...'],
                  colors: ['rgba(#ffffff, 0.2)', this.colors.red],
                  grid: true,
                  compact: false,
                  animate: true,
                  delay: 0,
                },
                {
                  title: ['report', 'Kaseya Vuln 2021'],
                  colors: ['rgba(#ffffff, 0.2)', this.colors.red],
                  animate: true,
                  delay: 0,
                },
                {
                  title: ['threat', 'REvil Kaseya'],
                  colors: ['rgba(#ffffff, 0.2)', this.colors.red],
                  animate: true,
                  delay: 0,
                },
              ]}
            />
          </div>
          {/* Data dots */}
          <div
            className="player data"
            data-active="0"
            ref={node => (this.players.data = node)}
          >
            <PlayerDots width={150} className="left-to-right" />
            <span
              className="update"
              ref={node => (this.players.update = node)}
            ></span>
          </div>
          {/* Machine Learning */}
          <div
            className="player intel"
            data-active="0"
            ref={node => (this.players.intel = node)}
          >
            <Shield
              IconComponent={MachineLearning}
              IconSize={65}
              gradient={true}
              letterSpacing={1}
              text={'THREAT INTEL'}
            />
          </div>

          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
