import { Slide01 } from '../../../components/proxyshell/slide-01';
import { Slide02 } from '../../../components/proxyshell/slide-02';
import { Slide03 } from '../../../components/proxyshell/slide-03';
import { Slide04 } from '../../../components/proxyshell/slide-04';
import { Slide05 } from '../../../components/proxyshell/slide-05';
import { Slide06 } from '../../../components/proxyshell/slide-06';
import { Slide07 } from '../../../components/proxyshell/slide-07';
import { Slide08 } from '../../../components/proxyshell/slide-08';
import { Slide09 } from '../../../components/proxyshell/slide-09';
import { Slide10 } from '../../../components/proxyshell/slide-10';
import { Slide11 } from '../../../components/proxyshell/slide-11';
import { Slide12 } from '../../../components/proxyshell/slide-12';
import { Slide13 } from '../../../components/proxyshell/slide-13';
import { Slide14 } from '../../../components/proxyshell/slide-14';
import { Slide15 } from '../../../components/proxyshell/slide-15';
import { Slide16 } from '../../../components/proxyshell/slide-16';

import { Bug } from '../../../components/svg/bug';
import { Eye } from '../../../components/svg/eye';
import { Bell } from '../../../components/svg/bell';
import { Clock } from '../../../components/svg/clock';
import { CheckShield } from '../../../components/svg/check-shield';
import { ShieldBlank } from '../../../components/svg/shield-blank';
import { EmailAlert } from '../../../components/svg/email-alert';

export const PROXY_CATEGORY = {
  STAGE1: 'High-Value Vulnerability Emerges',
  STAGE2: 'Audit of External Facing Systems',
  STAGE3: 'New Detection Engineering and Proactive Global Threat Hunting',
  STAGE4: '24/7 Monitoring and Investigation, and Customer Notification',
};

export const PROXY_COLUMNS = [
  [PROXY_CATEGORY.STAGE1],
  [PROXY_CATEGORY.STAGE2],
  [PROXY_CATEGORY.STAGE3],
  [PROXY_CATEGORY.STAGE4],
];

export const PROXY_CATEGORY_ICONS = {
  [PROXY_CATEGORY.STAGE1]: Bug,
  [PROXY_CATEGORY.STAGE2]: ShieldBlank,
  [PROXY_CATEGORY.STAGE3]: EmailAlert,
  [PROXY_CATEGORY.STAGE4]: CheckShield,
};

export const proxySlides = [
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'proxyShell',
    subtitle: 'Scenario Overview',
    component: Slide01,
  },
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'proxyShell',
    subtitle: 'Scenario Overview',
    component: Slide02,
  },
  {
    title: 'proxyShell',
    subtitle: 'ProxyShell Vulnerability Details Emerge at Black Hat 2021',
    time: '00:00:00:00',
    component: Slide03,
    icon: Bug,
    category: PROXY_CATEGORY.STAGE1,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-01-01.png')],
    description:
      'A cybersecurity researcher revealed three ProxyShell vulnerabilities with technical details on how it could be exploited on Microsoft Exchange servers at Black Hat 2021.',
  },
  {
    title: 'proxyShell',
    subtitle:
      'Scans for Online Exchange Systems Identify Exploitation of ProxyShell is Likely',
    product: 'Threat Response Unit',
    time: '01:21:40:00',
    component: Slide04,
    icon: Eye,
    category: PROXY_CATEGORY.STAGE2,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-02-01.png')],
    description:
      'Over the weekend, the Threat Response Unit (TRU) team identifies reports through worldwide scans that indicate threat actors are auditing Internet-facing exchange servers for the ProxyShell vulnerability discussed at Black Hat, implying exploitation is very likely in the future.',
  },
  {
    title: 'proxyShell',
    subtitle:
      'The TRU Team Examines ProxyShell Scan Data for Impacted Customers',
    product: 'Threat Response Unit',
    time: '04:00:40:00',
    component: Slide05,
    category: PROXY_CATEGORY.STAGE2,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-03-01.png')],
    description:
      'Using the Internet fingerprinting service Shodan, TRU proactively begins comparing exposed vulnerable systems to customer assets to determine which customers may be impacted. This scan identifies 29 vulnerable eSentire customers. ',
  },
  {
    title: 'proxyShell',
    subtitle: '29 Customers Proactively Notified of Their Exposed Systems',
    product: 'Threat Response Unit, Security Operations Center',
    time: '04:22:02:00',
    component: Slide06,
    category: PROXY_CATEGORY.STAGE2,
    icon: Bell,
    clientCommunication: true,
    appScreenshotUrl: [
      require('../../../assets/proxyshell/screen-04-01.png'),
      require('../../../assets/proxyshell/screen-04-02.png'),
    ],
    description:
      'The TRU team coordinates with SOC cyber analysts using output from Shodan scans to notify customers with exposed systems and provide recommended actions to protect against this vulnerability and ensure their business operations are not impacted.',
  },
  {
    title: 'proxyShell',
    subtitle: 'Managed Vulnerability Service ProxyShell Plugins are Released',
    product: 'Managed Vulnerability Service',
    time: '05:14:40:00',
    component: Slide07,
    category: PROXY_CATEGORY.STAGE2,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-05-01.png')],
    description:
      "Plugins are released on eSentire's Managed Vulnerability Service (MVS) for identifying vulnerable assets and ah hoc scans begin for all MVS customers.",
  },
  {
    title: 'proxyShell',
    subtitle: 'Detection Engineering Backlog Item Created',
    time: '05:16:30:00',
    product: 'Threat Response Unit',
    component: Slide08,
    category: PROXY_CATEGORY.STAGE3,
    icon: Bell,
    appScreenshotUrl: require('../../../assets/proxyshell/screen-06-01.png'),
    description:
      'After gathering and testing sufficient information, the TRU team initiates detection engineering enhancements to protect all Managed Detection and Response customers.',
  },
  {
    title: 'proxyShell',
    subtitle: 'ProxyShell Hunt Group',
    product: 'Threat Response Unit, Security Operations Center',
    time: '05:19:15:00',
    component: Slide09,
    category: PROXY_CATEGORY.STAGE3,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-07-01.png')],
    description:
      'TRU and SOC cyber analysts coordinate to perform proactive global threat hunts on ProxyShell TTPs across all customer environments.',
  },
  {
    title: 'proxyShell',
    subtitle: 'ProxyShell Detection is Fully Deployed',
    product: 'MDR for Log, MDR for Endpoint',
    time: '08:00:01:00',
    component: Slide10,
    category: PROXY_CATEGORY.STAGE3,
    appScreenshotUrl: [
      require('../../../assets/proxyshell/screen-08-01.png'),
      require('../../../assets/proxyshell/screen-08-02.png'),
      require('../../../assets/proxyshell/screen-08-03.png'),
    ],
    description:
      'The TRU completes deployment of ProxyShell signatures and accompanying investigation runbook for the SOC cyber analysts to leverage if they recieve a positive hit for a ProxyShell exploit.',
  },
  {
    title: 'proxyShell',
    subtitle: 'The TRU Team Identifies PoC Exploit for ProxyShell',
    time: '10:16:13:00',
    product: 'Threat Response Unit',
    component: Slide11,
    icon: Eye,
    category: PROXY_CATEGORY.STAGE4,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-09-01.png')],
    description:
      'The TRU team identify a Proof-of-Concept (PoC) exploit for ProxyShell, greatly increasing risk of widespread exploitation.',
  },
  {
    title: 'proxyShell',
    subtitle:
      'The TRU Team Issues a Threat Advisory on ProxyShell Exploitation',
    time: '10:20:43:00',
    product: 'Threat Response Unit',
    component: Slide12,
    clientCommunication: true,
    category: PROXY_CATEGORY.STAGE4,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-10-01.png')],
    description:
      'The TRU team distributes a Threat Advisory to all customers on the increased risk of exploitation of ProxyShell vulnerabilities due to the emergence of PoC code.',
  },
  {
    title: 'proxyShell',
    subtitle:
      'SOC Cyber Analysts Identify an Exploit Attempt Targeting an eSentire Customer',
    time: '12:18:05:00',
    product: 'MDR for Endpoint',
    component: Slide13,
    category: PROXY_CATEGORY.STAGE4,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-11-01.png')],
    description: `eSentire SOC cyber analysts respond to a newly detected ProxyShell exploit leveraging the detection runbook. The team investigates and responds rapidly to isolate the host on the client's behalf.`,
  },
  {
    title: 'proxyShell',
    subtitle:
      'The TRU Team Issues a Threat Advisory Warning of Ongoing Exploitation',
    time: '18:23:29:00',
    product: 'Threat Response Unit',
    component: Slide14,
    category: PROXY_CATEGORY.STAGE4,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-12-01.png')],
    description: `A second customer Threat Advisory is issued by the TRU team after widespread exploitation of the ProxyShell vulnerability is observed to provide actionable intel to customers.`,
  },
  {
    title: 'proxyShell',
    subtitle:
      'The TRU Team Hosts a Customer Webinar on the ProxyShell Vulnerability and Exploit Attempts',
    time: '39:22:40:00',
    product: 'Threat Response Unit',
    component: Slide15,
    clientCommunication: true,
    category: PROXY_CATEGORY.STAGE4,
    appScreenshotUrl: [require('../../../assets/proxyshell/screen-13-01.png')],
    description: `The TRU team hosts a live webinar with customers to provide more information on the ProxyShell vulnerability, exploit attempts and the steps we took to protect their business from disruption.`,
  },
  // Timeline
  {
    title: 'proxyShell',
    subtitle: 'Timeline Summary',
    component: Slide16,
    icon: Clock,
    timelineSummary: true,
    category: PROXY_CATEGORY.STAGE4,
  },
];
