/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';

// Icons
import { Stack } from '../svg/stack';

@observer
export class Slide17 extends AnimatedSlide {
  // Animation players
  players = {
    stack: null,
    host: null,
    rule: null,
  };

  // Run the first animation
  componentDidMount() {
    const { stack, host, rule } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
        this.autoNext(1000);
      },
      // Step 2
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [],
      //
      // Step 2
      //
      [
        {
          targets: host,
          opacity: 1,
          delay: 0,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: rule,
          opacity: 1,
          delay: 500,
          duration: 200,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-17 ${className}`}>
        {children}
        <div className="content">
          {/* New blocking rule */}
          <div
            className="player rule"
            data-active="0"
            ref={node => (this.players.rule = node)}
          >
            <PlayerTextBlock
              className={'green-top'}
              title={'NEW BLOCKING RULE'}
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Host */}
          <div
            className="player host"
            data-active="0"
            ref={node => (this.players.host = node)}
          >
            <PlayerTextBlock
              title={'host'}
              showDots={true}
              sections={[
                {
                  title: '94.23.172.251',
                },
              ]}
            />
          </div>

          {/* stack */}
          <div
            className="player stack"
            data-active="1"
            ref={node => (this.players.stack = node)}
          >
            <PlayerCircleIcon
              color={'#ffffff'}
              Icon={Stack}
              IconSize={'50'}
              showTitle={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
