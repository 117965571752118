/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class SignalEndpoint extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 2,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity={opacity}
        >
          <path d="M4.78487 44.3029V10.9621C4.78484 10.4456 4.98848 9.94987 5.35162 9.58254C5.71476 9.21521 6.2081 9.00589 6.72459 9H53.2779C53.7944 9.00589 54.2877 9.21521 54.6509 9.58254C55.014 9.94987 55.2176 10.4456 55.2176 10.9621V44.3029M22.2623 44.3029H1V49.9728C1.00591 50.48 1.21256 50.9642 1.57472 51.3194C1.93688 51.6746 2.42503 51.8718 2.93226 51.8678H57.0654C57.5726 51.8718 58.0608 51.6746 58.4229 51.3194C58.7851 50.9642 58.9917 50.48 58.9976 49.9728V44.3029M37.7377 44.3029H59M37.5677 44.3029V46.8245H22.4379V44.3029M13.6114 16.5647V21.608" />
        </g>
      </svg>
    );
  }
}
