/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import ReactDOM from 'react-dom';

// SVG
import { Card } from '../svg/card';
import { Skull } from '../svg/skull';
import { ShieldKeys } from '../svg/shield-keys';
import { InternalNetwork } from '../svg/internal-network';

@observer
export class Slide12 extends AnimatedSlide {
  // Animation players
  players = {
    attacker: null,
    vpn: null,
    network: null,
    dots1: null,
    dots2: null,
    dots3: null,
    dots4: null,
  };

  keyColor1 = this.colors.red;
  keyColor2 = this.colors.green;

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;
    const { attacker, vpn, network, dots1, dots2, dots3, dots4 } = this.players;

    // Get keys
    // var key = ReactDOM.findDOMNode(vpn).childNodes[0].childNodes[3];

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(4000);
        this.autoNext(3000);
      },
      // Step 1 - Using second key
      () => {
        if (!this._isMounted) return;
        this.keyColor2 = this.colors.red;
        this.forceUpdate();
        this.autoNext(500);
      },
      // Step 2 - Connections come back
      () => {
        this.autoNext(500);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Pause for effect
      ],

      //
      // Step 1
      //
      [
        // Do nothing while key changes color
      ],

      //
      // Step 2 - Connections come back
      //
      [
        {
          targets: dots2,
          opacity: 0,
          delay: 0,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: dots4,
          opacity: 0,
          delay: 250,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: dots3,
          opacity: 0,
          delay: 500,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-12 ${className}`}>
        {children}
        <div className="content">
          {/* Attacker */}
          <div
            className="player attacker"
            data-active="1"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text={'Attacker'} />
          </div>

          {/* Shield with keys */}
          <div
            className="player vpn"
            data-active="1"
            ref={node => (this.players.vpn = node)}
          >
            <ShieldKeys key1={this.keyColor1} key2={this.keyColor2} />
          </div>

          {/* dots 1 */}
          <div
            className="player dots1"
            data-active="1"
            ref={node => (this.players.dots1 = node)}
          >
            <PlayerDots
              color={this.colors.red}
              width={160}
              dots={10}
              className={'in-out'}
            />
          </div>

          {/* Network */}
          <div
            className="player network"
            data-active="1"
            ref={node => (this.players.network = node)}
          >
            <InternalNetwork />
          </div>

          {/* dots 2 */}
          <div
            className="player dots2"
            data-active="1"
            ref={node => (this.players.dots2 = node)}
          >
            <PlayerDots
              color={this.colors.red}
              width={160}
              dots={10}
              className={'in-out'}
            />
          </div>

          {/* dots 3 */}
          <div
            className="player dots3"
            data-active="1"
            ref={node => (this.players.dots3 = node)}
          >
            <PlayerDots
              color={this.colors.green}
              width={160}
              dots={10}
              className={'in-out-reverse'}
            />
          </div>

          {/* dots 4 */}
          <div
            className="player dots4"
            data-active="1"
            ref={node => (this.players.dots4 = node)}
          >
            <PlayerDots
              color={this.colors.green}
              width={160}
              dots={10}
              className={'in-out-reverse'}
            />
          </div>
        </div>
      </div>
    );
  }
}
