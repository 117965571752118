/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Person } from './person';
import anime from 'animejs';
import { getColor } from '../helpers';

@observer
export class Card extends Component {
  border = null;

  defaults = {
    animate: true,
    duration: 1000,
    delay: 0,
    stroke: getColor('white'),
    strokeWidth: 4,
    strokeOpacity: 0.1,
  };

  componentDidMount() {
    // Animate stroke
    this.animateStroke();
  }

  animateStroke() {
    const {
      animate = this.defaults.animate,
      delay = this.defaults.delay,
      duration = this.defaults.duration,
      strokeWidth = this.defaults.strokeWidth,
      strokeOpacity = this.defaults.strokeOpacity,
    } = this.props;

    // Don't do it!
    if (!animate) return;

    // Animate
    anime({
      targets: this.border,
      strokeDashoffset: [anime.setDashoffset, 0],
      strokeWidth: [0, strokeWidth],
      strokeOpacity: [1, strokeOpacity],
      duration: duration,
      easing: 'easeOutQuad',
      delay: delay,
    });
  }

  render() {
    const {
      className = '',
      color = '#ffffff',
      text = 'Analyst',
      letterSpacing = 0,
      x = 0,
      y = 0,
      IconComponent = Person,
      iconSize = 68,
      stroke = this.defaults.stroke,
      strokeWidth = this.defaults.strokeWidth,
      strokeOpacity = this.defaults.strokeOpacity,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width="120"
        height="159"
        viewBox="0 0 120 159"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="26"
          y="118.734"
          width="69"
          height="2.06493"
          fill={color}
          fillOpacity="0.3"
        />
        <rect width="120" height="159" fill={color} fillOpacity="0.02" />
        <rect
          x="2"
          y="2"
          width="116"
          height="155"
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeWidth={strokeWidth}
          ref={node => (this.border = node)}
        />
        <svg x={`${(120 - iconSize) / 2}`} y={`${(120 - iconSize) / 2}`}>
          <IconComponent size={iconSize} color={color} opacity="0.4" />
        </svg>
        <text
          x="50%"
          y="141"
          textAnchor="middle"
          fontFamily="Blender Bold"
          fontSize="13px"
          letterSpacing={letterSpacing.toString().replace(/px/g, '') + 'px'}
          fill={color}
          fillOpacity={0.75}
        >
          {text.toUpperCase()}
        </text>
      </svg>
    );
  }
}
