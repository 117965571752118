/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './dots.scss';

@observer
export class PlayerDots extends Component {
  wrapper = null;

  componentDidMount() {}

  renderDots(dots, duration) {
    const { color = null, animation = '' } = this.props;
    // Set color
    let c = color;
    if (color) {
      if (color == 'red') c = '#C53D43';
      if (color == 'white') c = '#ffffff';
      if (color == 'green') c = '#54D5AE';
      if (color == 'blue') c = '#50C2E3';
    }

    let spans = [];
    let wait = duration / dots;
    let space = 100 / dots;
    for (let i = 0; i < dots; i++) {
      let delay = i * wait * -1;
      let left = i * space;
      let styleSettings = {
        animationDelay: `${delay}s`,
        animationDuration: `${duration}s`,
        left: `${left}%`,
      };
      if (animation) {
        // styleSettings.animationName = animation;
        // styleSettings.webkitAnimationName = animation;
      }
      if (c) styleSettings.backgroundColor = c;
      spans.push(
        <span
          key={i}
          className={animation.toLowerCase()}
          style={styleSettings}
        />
      );
    }
    return spans;
  }

  render() {
    // Get parameters
    const {
      className = '',
      reverse = false,
      animationReverse = false,
      rotate = '',
      width = null,
      duration = 3,
      dots = 12,
    } = this.props;

    let trackStyles = {};
    let w = width;
    if (width) {
      if (typeof width == 'string') w = width.replace('px', '');
      trackStyles.width = `${w}px`;
    }

    let wrapperStyles = {};
    if (rotate) wrapperStyles.transform = `rotate(${rotate})`;
    if (width) wrapperStyles.width = `${w}px`;

    let reverseClass = reverse ? 'reverse-direction' : '';
    let animationReverseClass = animationReverse ? 'animation-reverse' : '';

    return (
      <div
        className={`player--dots ${className} ${reverseClass} ${animationReverseClass}`}
        style={wrapperStyles}
        ref={node => (this.wrapper = node)}
      >
        <div className="player--dots-track" style={trackStyles}>
          {this.renderDots(dots, duration)}
        </div>
      </div>
    );
  }
}
