/** @jsx createElement */
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { createElement, Component } from 'react';
import { ProbabilityInstantChart } from '../../charts/probability-instant-chart';
import { Select } from '../../select';
import { Info } from '../../svg/info';
import { Gear } from '../../svg/gear';
import { Popup } from '../../popup';
import {
  RiskAnalysisToolState,
  RiskAnalysisToolActions,
} from '../risk-analysis-tool-actions';
import { NavDot } from '../../svg/nav-dot';
import { generateColor } from '../../../data/color-generator';
import {
  INTERVAL_OPTIONS,
  SCENARIO_OPTIONS,
  RiskAnalysisProbabilityState,
  RiskAnalysisProbabilityActions,
} from './probability-actions';
import './probability.scss';
import { Interval } from '../../../data/graph-data-transforms';
import { ProbabilityCumulativeChart } from '../../charts/probability-cumulative-chart';
import { probabilityCalculatorSeries } from '../../../data/graph-data-transforms';
import { Modal } from '../../modal';

@observer
export class RiskAnalysisProbability extends Component {
  static HORIZONTAL_PADDING = 80;
  static VERTICAL_PADDING = 360;

  @observable height = 480;
  @observable width = 1000;

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    window.addEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
    setTimeout(this.handleResize, 500);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener(
      'orientationchange',
      this.handleOrientationChange,
      true
    );
  }

  handleOrientationChange = () => {
    setTimeout(this.handleResize, 500);
  };

  handleResize = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    this.width = Math.max(
      0,
      windowWidth - RiskAnalysisProbability.HORIZONTAL_PADDING
    );
    this.height = Math.max(
      0,
      windowHeight - RiskAnalysisProbability.VERTICAL_PADDING
    );
  };

  changeSelect = type => value => {
    RiskAnalysisProbabilityActions.updateField(type, value);
  };

  changeCheckbox = filter => e => {
    RiskAnalysisProbabilityActions.updateFilter(
      filter,
      e.currentTarget.checked
    );
  };

  toggleAll = filterName => e => {
    RiskAnalysisToolState.attackTypes.forEach(attackType => {
      RiskAnalysisProbabilityActions.updateFilter(
        { filter: filterName, attackType },
        e.currentTarget.checked
      );
    });
  };

  clickInfoModal = () => {
    RiskAnalysisToolActions.showProbabilityModal();
  };

  closeModal() {
    RiskAnalysisToolActions.closeModal();
  }

  openModal() {
    RiskAnalysisToolState.showProbabilityPickerModal = true;
  }

  render() {
    const { className = '' } = this.props;

    const category = RiskAnalysisToolState.category || { value: '' };

    return (
      <div className={`RiskAnalysisProbability ${className}`}>
        <div className={`RiskAnalysisProbability__Row`}>
          <h2>Incident Probability</h2>
          <div
            className="RiskAnalysisProbability__Info"
            onClick={this.clickInfoModal}
          >
            <Info />
          </div>
          <Select
            className={`RiskAnalysisProbability__Select`}
            value={RiskAnalysisProbabilityState.interval}
            options={INTERVAL_OPTIONS}
            onChange={this.changeSelect('interval')}
            label="Interval"
          />
          <Select
            className={`RiskAnalysisProbability__Select`}
            value={RiskAnalysisProbabilityState.scenario}
            options={SCENARIO_OPTIONS}
            onChange={this.changeSelect('scenario')}
            label="Scenario"
          />
        </div>
        {RiskAnalysisToolState.showProbabilityPickerModal ? null : (
          <div className={`RiskAnalysisProbability__Chart`}>
            {RiskAnalysisProbabilityState.interval &&
              RiskAnalysisProbabilityState.interval.value ===
                Interval.INSTANTANEOUS && (
                <ProbabilityInstantChart
                  width={this.width}
                  height={this.height}
                  series={RiskAnalysisProbabilityState.filters.map(filter => ({
                    category: category.value,
                    filter: filter.filter,
                    attackType: filter.attackType,
                  }))}
                  scenario={
                    RiskAnalysisProbabilityState.scenario &&
                    RiskAnalysisProbabilityState.scenario.value
                  }
                  showCumulative={
                    RiskAnalysisProbabilityState.filters.length > 1
                  }
                  sensorCount={RiskAnalysisToolState.sensors.value}
                />
              )}
            {RiskAnalysisProbabilityState.interval &&
              RiskAnalysisProbabilityState.interval.value ===
                Interval.CUMULATIVE && (
                <ProbabilityCumulativeChart
                  width={this.width}
                  height={this.height}
                  series={RiskAnalysisProbabilityState.filters.map(filter => ({
                    category: category.value,
                    filter: filter.filter,
                    attackType: filter.attackType,
                  }))}
                  scenario={
                    RiskAnalysisProbabilityState.scenario &&
                    RiskAnalysisProbabilityState.scenario.value
                  }
                  showCumulative={
                    RiskAnalysisProbabilityState.filters.length > 1
                  }
                  sensorCount={RiskAnalysisToolState.sensors.value}
                />
              )}
          </div>
        )}
        <div className={`RiskAnalysisProbability__Legend`}>
          <div
            className="RiskAnalysisProbability__Gear"
            onClick={this.openModal}
          >
            <Gear />
          </div>
          <Modal
            show={RiskAnalysisToolState.showProbabilityPickerModal}
            onClose={this.closeModal}
            fullPage={true}
          >
            {this.renderPicker()}
          </Modal>
          <div className={`RiskAnalysisProbability__Legend__Filters`}>
            {probabilityCalculatorSeries(
              RiskAnalysisProbabilityState.filters || [],
              true
            ).map((filter, i) => {
              const color = generateColor(i);
              return (
                <div
                  className="RiskAnalysisProbability__Legend__Filters__Filter"
                  key={`${filter.filter}:${filter.attackType}`}
                >
                  <div>
                    <NavDot color={color.rgb().string()} />
                  </div>
                  <div>{filter.filter}</div>
                  <div>{filter.attackType}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  renderPicker() {
    if (!RiskAnalysisToolState.showProbabilityPickerModal) return null;

    return (
      <div className="Filters">
        <div className="Filters__Title">
          {`Filter by ${RiskAnalysisToolState.category &&
            RiskAnalysisToolState.category.value}`}
        </div>
        <div className="Filters__Body">
          <div className="Filters__Left">
            {RiskAnalysisToolState.filters.map(filter => (
              <label key={filter}>
                <input
                  type="checkbox"
                  onChange={this.toggleAll(filter)}
                  checked={Boolean(
                    RiskAnalysisToolState.attackTypes.find(attackType =>
                      RiskAnalysisProbabilityState.filters.find(
                        item =>
                          item.filter === filter &&
                          item.attackType === attackType
                      )
                    )
                  )}
                />
                <span className={'checkmark'} />
                {filter}
              </label>
            ))}
          </div>
          <div className="Filters__Right">
            {/* Make the top row containing the attack type names */}
            <div className="Filters--row">
              <div className="Filters--row-header" />
              {RiskAnalysisToolState.attackTypes.map(attackType => (
                <div key={attackType} className="Filters--row-column">
                  {attackType}
                </div>
              ))}
            </div>
            <div className="Filters__Right__Body">
              {/* Output each line of category with selections for each attack type */}
              {RiskAnalysisToolState.filters
                .filter(filter =>
                  RiskAnalysisToolState.attackTypes.find(attackType =>
                    RiskAnalysisProbabilityState.filters.find(
                      item =>
                        item.filter === filter && item.attackType === attackType
                    )
                  )
                )
                .map(filter => (
                  <div key={filter} className="Filters--row">
                    <div className="Filters--row-header" key={filter}>
                      {filter}
                    </div>
                    {RiskAnalysisToolState.attackTypes.map(attackType => (
                      <div key={attackType} className="Filters--row-column">
                        <label>
                          <input
                            type="checkbox"
                            onChange={this.changeCheckbox({
                              filter,
                              attackType: attackType,
                            })}
                            checked={Boolean(
                              RiskAnalysisProbabilityState.filters.find(
                                item =>
                                  item.filter === filter &&
                                  item.attackType === attackType
                              )
                            )}
                          />
                          <span className={'checkmark'} />
                        </label>
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
