/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { Warning } from '../svg/warning';
// Other
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerCircleIcon } from '../players/circle-icon';

@observer
export class Slide04 extends AnimatedSlide {
  // Animation players
  players = {
    incident: null,
  };

  // Run the first animation
  componentDidMount() {
    const { incident } = this.players;

    this.steps = [
      () => {
        this.animateProgress(7000);
        this.autoNext(200);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        {
          targets: incident,
          opacity: 1,
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-04 ${className}`}>
        {children}
        <div className="content">
          {/*-------PLAYERS START-------*/}
          {/* Incident info */}
          <div
            className="player incident"
            data-active="0"
            ref={node => (this.players.incident = node)}
          >
            <PlayerTextBlock
              title={'Incident info'}
              width={'250px'}
              className={''}
              sections={[
                {
                  title: [
                    'TYPE',
                    'BRUTE FORCE',
                    'USER',
                    'DTHOMAS',
                    'IP',
                    '213.112.147.15',
                    'LOGIN',
                    'SUCCESS',
                  ],
                  grid: 2,
                },
              ]}
            />
          </div>

          {/*-------PLAYERS END-------*/}
        </div>
      </div>
    );
  }
}
