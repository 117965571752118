/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import ReactDOM from 'react-dom';
import { Stage } from '../stage';
import { PlayerDonut } from '../players/donut';
import { PlayerProgressPie } from '../players/progress-pie';
import { PlayerDataScan } from '../players/data-scan';

// SVG
import { Computer } from '../svg/computer';

@observer
export class Slide04 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    scanner: null,
    ports: null,
    terminal1: null,
    terminal2: null,
    terminal3: null,
    terminal4: null,
    terminal5: null,
    terminal6: null,
    terminal7: null,
    terminal8: null,
  };

  // Progress references
  progress1 = 0;

  bars = [
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
  ];

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;
    const {
      donut,
      scanner,
      ports,
      terminal1,
      terminal2,
      terminal3,
      terminal4,
      terminal5,
      terminal6,
      terminal7,
      terminal8,
    } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(9000);
        this.autoNext(200);
        terminal1.classList.remove('show-computer');
        terminal2.classList.remove('show-computer');
        terminal3.classList.remove('show-computer');
        terminal4.classList.remove('show-computer');
        terminal5.classList.remove('show-computer');
        terminal6.classList.remove('show-computer');
        terminal7.classList.remove('show-computer');
        terminal8.classList.remove('show-computer');
      },
      // Step 1 - show termainl 1
      () => {
        this.autoNext(500);
      },
      // Step 1 - show termainl 1
      () => {
        this.autoNext(1000);
      },
      // Step 2
      () => {
        if (!this._isMounted) return;
        terminal1.classList.add('show-computer');
        this.bars[0].progress = 100;
        this.forceUpdate();
        this.autoNext(500);
      },
      // Step 3
      () => {
        if (!this._isMounted) return;
        terminal7.classList.add('show-computer');
        this.bars[6].progress = 100;
        this.forceUpdate();
        this.autoNext(1500);
      },
      // Step 4
      () => {
        if (!this._isMounted) return;
        terminal6.classList.add('show-computer');
        this.bars[5].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 5
      () => {
        if (!this._isMounted) return;
        terminal3.classList.add('show-computer');
        this.bars[2].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 6
      () => {
        if (!this._isMounted) return;
        terminal4.classList.add('show-computer');
        this.bars[3].progress = 100;
        this.forceUpdate();
        this.autoNext(500);
      },
      // Step 7
      () => {
        if (!this._isMounted) return;
        terminal8.classList.add('show-computer');
        this.bars[7].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 8
      () => {
        if (!this._isMounted) return;
        terminal2.classList.add('show-computer');
        this.bars[1].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Step 9
      () => {
        if (!this._isMounted) return;
        terminal5.classList.add('show-computer');
        this.bars[4].progress = 100;
        this.forceUpdate();
        this.autoNext(1000);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Do nothing
      ],

      //
      // Step 1 - attacker shows up
      //
      [
        {
          targets: donut,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: scanner,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 300,
          complete: activateShimmer,
        },
      ],

      //
      // Step 2 - Show terminals
      //
      [
        {
          targets: terminal1,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 0,
          complete: activateShimmer,
        },
        {
          targets: terminal2,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 100,
          complete: activateShimmer,
        },
        {
          targets: terminal3,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 300,
          complete: activateShimmer,
        },
        {
          targets: terminal4,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 400,
          complete: activateShimmer,
        },
        {
          targets: terminal5,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 500,
          complete: activateShimmer,
        },
        {
          targets: terminal6,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 600,
          complete: activateShimmer,
        },
        {
          targets: terminal7,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 700,
          complete: activateShimmer,
        },
        {
          targets: terminal8,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 800,
          complete: activateShimmer,
        },
      ],
      //
      // Step 3
      //
      [
        // Do nothing
      ],
      //
      // Step 4
      //
      [
        // Do nothing
      ],
      //
      // Step 5
      //
      [
        // Do nothing
      ],
      //
      // Step 6
      //
      [
        // Do nothing
      ],
      //
      // Step 7
      //
      [
        // Do nothing
      ],
      //
      // Step 8
      //
      [
        // Do nothing
      ],
      //
      // Step 9
      //
      [
        // Do nothing
      ],
      //
      // Step 10
      //
      [
        // Do nothing
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-04 ${className}`}>
        {children}
        <div className="content">
          {/* Donut analyzer */}
          <div
            className="player donut"
            data-active="0"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
          {/* Data scanner */}
          <div
            className="player scanner"
            data-active="0"
            ref={node => (this.players.scanner = node)}
          >
            <PlayerDataScan />
          </div>
          {/* Terminal 1 */}
          <div
            className="player terminal terminal1"
            data-active="0"
            ref={node => (this.players.terminal1 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[0].progress}
              color={this.bars[0].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line'}></div>
          </div>
          {/* Terminal 2 */}
          <div
            className="player terminal terminal2"
            data-active="0"
            ref={node => (this.players.terminal2 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[1].progress}
              color={this.bars[1].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line'}></div>
          </div>
          {/* Terminal 3 */}
          <div
            className="player terminal terminal3"
            data-active="0"
            ref={node => (this.players.terminal3 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[2].progress}
              color={this.bars[2].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line'}></div>
          </div>
          {/* Terminal 4 */}
          <div
            className="player terminal terminal4"
            data-active="0"
            ref={node => (this.players.terminal4 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[3].progress}
              color={this.bars[3].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line'}></div>
          </div>
          {/* Terminal 5 */}
          <div
            className="player terminal terminal5"
            data-active="0"
            ref={node => (this.players.terminal5 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[4].progress}
              color={this.bars[4].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line'}></div>
          </div>
          {/* Terminal 6 */}
          <div
            className="player terminal terminal6"
            data-active="0"
            ref={node => (this.players.terminal6 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[5].progress}
              color={this.bars[5].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line'}></div>
          </div>
          {/* Terminal 7 */}
          <div
            className="player terminal terminal7"
            data-active="0"
            ref={node => (this.players.terminal7 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[6].progress}
              color={this.bars[6].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line'}></div>
          </div>
          {/* Terminal 8 */}
          <div
            className="player terminal terminal8"
            data-active="0"
            ref={node => (this.players.terminal8 = node)}
          >
            <Computer size={30} className={'computer'} />
            <PlayerProgressPie
              progress={this.bars[7].progress}
              color={this.bars[7].color}
              size={70}
              stroke={3}
            />
            <div className={'animated-dotted-line'}></div>
          </div>
        </div>
      </div>
    );
  }
}
