/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { Router, RouteManager } from 'vega-router';
import { AppActions } from '../app-actions';
import { HamburgerIcon } from '../svg/hamburger-icon';
import { EsentireIcon } from '../svg/esentire-icon';
import { MitreMappingChart } from './mitre-mapping-chart';
import { MitreDataMode, MitreDataStore } from '../../data/mitre-data.store';

import './mitre-mapping.scss';

@observer
export class MitreMapping extends Router {
  constructor(props) {
    super(props);

    // this.addRoute('detail', /^\/compliance\/detail/, ComplianceAlignmentDetail);

    this.contentFrame = null;
    this.contentFrameContainer = null;

    // Must be last
    this.addRoute('chart', /^\/(.*)/, MitreMappingChart);
  }

  componentDidMount() {
    AppActions.setShortcutName('Mitre Mapping Tool');
    AppActions.hideBackgroundGrid();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
  }

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`MitreMapping ${className}`}>
        <div className="MitreMapping__MainMenu">
          <button
            className="HamburgerButton"
            onClick={() => RouteManager.transitionTo('/')}
          >
            <HamburgerIcon color="#fafafa" opacity={0.7} />
          </button>
        </div>
        <div className="MitreMapping__Logo">
          <EsentireIcon />
        </div>
        {this.renderModeControls()}
        <div className="MitreMapping__Title">Compliance Alignment</div>
        <div className="MitreMapping__Subtitle capitalize">MITRE Mapping</div>
        {MitreDataStore.loading ? (
          <div className="MitreMapping__Middle__Loader">
            <div className="MitreMapping__Middle__Spinner" />
            <div className="MitreMapping__Middle__LoaderLabel">
              Loading Data
            </div>
          </div>
        ) : (
          <div className="MitreMapping__Middle FadeIn">{super.render()}</div>
        )}
      </div>
    );
  }

  renderModeControls() {
    return (
      <div className="MitreMapping__ModeControlsContainer">
        <div className="MitreMapping__ModeControls">
          <div
            className={`MitreMapping__ModeButton ${
              MitreDataStore.mode === MitreDataMode.ENTERPRISE
                ? 'MitreMapping--selected-mode'
                : null
            }`}
            onClick={() => MitreDataStore.changeMode(MitreDataMode.ENTERPRISE)}
          >
            Enterprise
          </div>
          <div
            className={`MitreMapping__ModeButton ${
              MitreDataStore.mode === MitreDataMode.CLOUD
                ? 'MitreMapping--selected-mode'
                : null
            }`}
            onClick={() => MitreDataStore.changeMode(MitreDataMode.CLOUD)}
          >
            Cloud
          </div>
        </div>
      </div>
    );
  }
}
