/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import './slide-process-location.scss';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';

// Icons
import { Document } from '../svg/document';

@observer
export class Slide14 extends AnimatedSlide {
  // Animation players
  players = {
    process1: null,
    process2: null,
    process3: null,
    process4: null,
    process5: null,
    process6: null,
    process7: null,
    file: null,
    connectors: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      process1,
      process2,
      process3,
      process4,
      process5,
      process6,
      process7,
      file,
      connectors,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(5000);
        connectors.classList.remove('highlight-path');
        this.autoNext(3000);
      },
      // Step 2
      () => {
        connectors.classList.add('highlight-path');
        this.autoNext(3000);
      },
      // Step 3
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: process1,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 200,
          delay: 0,
          complete: deactivate,
        },

        // Fade back in
        { targets: process3, easing: 'easeOutQuad', opacity: 1, duration: 500 },
        { targets: process4, easing: 'easeOutQuad', opacity: 1, duration: 500 },
        { targets: process5, easing: 'easeOutQuad', opacity: 1, duration: 500 },
        { targets: process6, easing: 'easeOutQuad', opacity: 1, duration: 500 },
      ],

      //
      // Step 2
      //
      [
        {
          targets: process1,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: process2,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 100,
          begin: activateShimmer,
        },
        {
          targets: process7,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 200,
          begin: activateShimmer,
        },
        {
          targets: file,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 500,
          begin: activateShimmer,
        },

        // Fade out
        { targets: process3, easing: 'easeOutQuad', opacity: 0, duration: 500 },
        { targets: process4, easing: 'easeOutQuad', opacity: 0, duration: 500 },
        { targets: process5, easing: 'easeOutQuad', opacity: 0, duration: 500 },
        { targets: process6, easing: 'easeOutQuad', opacity: 0, duration: 500 },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`ProcessLocation PayloadActivity ${className}`}>
        {children}
        <div className="content">
          {/* Process information */}
          <div
            className="player file"
            data-active="0"
            ref={node => (this.players.file = node)}
          >
            <PlayerTextBlock
              title={'File mod'}
              className={'red-top'}
              width={'300px'}
              sections={[
                {
                  title: [
                    'DELETED',
                    'C:\\USERS\\PUBLIC\\239789.EXE',
                    'DELETED',
                    'C:\\WINDOWS\\SYSTEM32\\SCREENMGMT.EXE',
                  ],
                  grid: 2,
                },
              ]}
            />
          </div>

          {/* Process 1 */}
          <div
            className="player folder"
            data-active="1"
            ref={node => (this.players.process1 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Document}
              title={'SERVICES.EXE'}
            />
          </div>

          {/* Process 2 */}
          <div
            className="player process1"
            data-active="1"
            ref={node => (this.players.process2 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Document}
              title={'SCREENMGMT.EXE'}
            />
          </div>

          {/* Process 3 */}
          <div
            className="player process2"
            data-active="1"
            ref={node => (this.players.process3 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Document}
              title={'SC.EXE'}
            />
          </div>

          {/* Process 4 */}
          <div
            className="player process3"
            data-active="1"
            ref={node => (this.players.process4 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Document}
              title={'DHCPSRV.EXE'}
            />
          </div>

          {/* Process 5 */}
          <div
            className="player process4"
            data-active="1"
            ref={node => (this.players.process5 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Document}
              title={'FLTPROC.EXE'}
            />
          </div>

          {/* Process 6 */}
          <div
            className="player process5"
            data-active="1"
            ref={node => (this.players.process6 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Document}
              title={'SVCHOST.EXE'}
            />
          </div>

          {/* Process 7 */}
          <div
            className="player subProcess3"
            data-active="1"
            ref={node => (this.players.process7 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Document}
              title={'SCREENMGMT.EXE'}
            />
          </div>

          <div
            className={'connector-tree slide-payload-activity'}
            ref={node => (this.players.connectors = node)}
          >
            <div className="connectors right">
              <span />
            </div>
            <div className="connectors left">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
