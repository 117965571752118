import { observable, reaction } from 'mobx';
import { getAssetDataAttackTypes } from '../../../data/graph-data-transforms';

export const RiskAnalysisImpactState = observable({
  attackTypes: [],

  attackTypeOptions: [],

  attackType: null,
});

export class RiskAnalysisImpactActions {
  static async getAssetDataAttackTypes() {
    RiskAnalysisImpactState.attackTypes = await getAssetDataAttackTypes();
    if (RiskAnalysisImpactState.attackTypes.length) {
      if (!RiskAnalysisImpactState.attackType) {
        RiskAnalysisImpactState.attackType = {
          label: RiskAnalysisImpactState.attackTypes[0],
          value: RiskAnalysisImpactState.attackTypes[0],
        };
      }
    } else {
      RiskAnalysisImpactState.attackType = null;
    }
  }

  static updateField(name, value) {
    RiskAnalysisImpactState[name] = value;
  }
}

reaction(
  () => [RiskAnalysisImpactState.attackTypes],
  () => {
    RiskAnalysisImpactState.attackTypeOptions = RiskAnalysisImpactState.attackTypes.map(
      attackType => {
        return {
          label: attackType,
          value: attackType,
        };
      }
    );
  }
);
