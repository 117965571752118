/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { ReactDOM } from 'react-dom';
import anime from 'animejs';
import { AnimatedSlide } from '../animated-slide';

@observer
export class World extends Component {
  node = null;
  group = null;
  allPaths = null;
  paths = null;
  animation = {
    type: 'scan',
    max: 200,
  };
  timeline = null;

  animations = {
    scan: () => {
      // Remove attributes from the group
      this.group.removeAttribute('fill');
      this.group.removeAttribute('fill-opacity');
      // Set attributes on each path
      this.setAttributes(this.allPaths, 'fill-opacity', '0.2');
      this.setAttributes(this.allPaths, 'fill', '#ffffff');
      var duration = 1000;
      var redCount = 50;
      var total = this.paths.length - redCount;
      // Divide the animation into two halfs so it looks like it's always running instead of having a pause between
      anime({
        targets: this.paths.slice(-redCount),
        fillOpacity: [0.2, 1, 0.2],
        fill: ['#ffffff', '#C53D43', '#ffffff'],
        duration: duration,
        delay: function(el, i) {
          return i * 100;
        },
        easing: 'easeInOutSine',
        loop: true,
      });
      anime({
        targets: this.paths.slice(0, total / 2),
        fillOpacity: [0.2, 1, 0.2],
        fill: ['#ffffff', '#50C2E3', '#ffffff'],
        duration: duration,
        delay: function(el, i) {
          return i * 20;
        },
        easing: 'easeInOutSine',
        loop: true,
      });
      setTimeout(() => {
        anime({
          targets: this.paths.slice(total / 2, total),
          fillOpacity: [0.2, 1, 0.2],
          fill: ['#ffffff', '#50C2E3', '#ffffff'],
          duration: duration,
          delay: function(el, i) {
            return i * 20;
          },
          easing: 'easeInOutSine',
          loop: true,
        });
      }, duration * 0.5);
    },
  };

  shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  componentWillUnmount() {
    anime.remove(this.paths);
  }

  componentDidMount() {
    // Run animation
    const { animation = false } = this.props;
    if (typeof animation == 'object') {
      Object.assign(this.animation, animation);
      this.animate();
    }
  }

  // Set styles on multiple elements
  setAttributes(items, property, value) {
    if (NodeList.prototype.isPrototypeOf(items)) {
      items.forEach(item => {
        item.setAttribute(property, value);
      });
      return;
    }
    if (HTMLCollection.prototype.isPrototypeOf(items) || Array.isArray(items)) {
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        item.setAttribute(property, value);
      }
      return;
    }
    items.setAttribute(property, value);
  }

  animate() {
    this.group = this.node.querySelector('g');
    this.allPaths = Array.prototype.slice.call(
      this.node.querySelectorAll('path')
    );
    this.paths = this.shuffleArray(this.allPaths).slice(0, this.animation.max);
    if (this.animation.type in this.animations) {
      this.animations[this.animation.type]();
    }
  }

  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 34,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 862 862" // 862 485
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={node => (this.node = node)}
      >
        <g fill="white" fillOpacity="0.2">
          <path d="M258.954 481.736C258.954 483.286 257.697 484.543 256.147 484.543C254.597 484.543 253.34 483.286 253.34 481.736C253.34 480.186 254.597 478.929 256.147 478.929C257.697 478.929 258.954 480.186 258.954 481.736Z" />
          <path d="M252.112 481.736C252.112 483.286 250.855 484.543 249.305 484.543C247.755 484.543 246.499 483.286 246.499 481.736C246.499 480.186 247.755 478.929 249.305 478.929C250.855 478.929 252.112 480.186 252.112 481.736Z" />
          <path d="M252.112 474.894C252.112 476.444 250.855 477.701 249.305 477.701C247.755 477.701 246.499 476.444 246.499 474.894C246.499 473.344 247.755 472.087 249.305 472.087C250.855 472.087 252.112 473.344 252.112 474.894Z" />
          <path d="M272.638 468.052C272.638 469.602 271.381 470.859 269.831 470.859C268.281 470.859 267.024 469.602 267.024 468.052C267.024 466.502 268.281 465.246 269.831 465.246C271.381 465.246 272.638 466.502 272.638 468.052Z" />
          <path d="M245.27 474.894C245.27 476.444 244.013 477.701 242.463 477.701C240.914 477.701 239.657 476.444 239.657 474.894C239.657 473.344 240.914 472.087 242.463 472.087C244.013 472.087 245.27 473.344 245.27 474.894Z" />
          <path d="M245.27 468.052C245.27 469.602 244.013 470.859 242.463 470.859C240.914 470.859 239.657 469.602 239.657 468.052C239.657 466.502 240.914 465.246 242.463 465.246C244.013 465.246 245.27 466.502 245.27 468.052Z" />
          <path d="M238.428 468.052C238.428 469.602 237.172 470.859 235.622 470.859C234.072 470.859 232.815 469.602 232.815 468.052C232.815 466.502 234.072 465.246 235.622 465.246C237.172 465.246 238.428 466.502 238.428 468.052Z" />
          <path d="M238.428 461.21C238.428 462.76 237.172 464.017 235.622 464.017C234.072 464.017 232.815 462.76 232.815 461.21C232.815 459.66 234.072 458.404 235.622 458.404C237.172 458.404 238.428 459.66 238.428 461.21Z" />
          <path d="M245.27 461.21C245.27 462.76 244.013 464.017 242.463 464.017C240.914 464.017 239.657 462.76 239.657 461.21C239.657 459.66 240.914 458.404 242.463 458.404C244.013 458.404 245.27 459.66 245.27 461.21Z" />
          <path d="M245.27 454.369C245.27 455.919 244.013 457.176 242.463 457.176C240.914 457.176 239.657 455.919 239.657 454.369C239.657 452.819 240.914 451.562 242.463 451.562C244.013 451.562 245.27 452.819 245.27 454.369Z" />
          <path d="M252.112 454.369C252.112 455.919 250.855 457.176 249.305 457.176C247.755 457.176 246.499 455.919 246.499 454.369C246.499 452.819 247.755 451.562 249.305 451.562C250.855 451.562 252.112 452.819 252.112 454.369Z" />
          <path d="M238.428 454.369C238.428 455.919 237.172 457.176 235.622 457.176C234.072 457.176 232.815 455.919 232.815 454.369C232.815 452.819 234.072 451.562 235.622 451.562C237.172 451.562 238.428 452.819 238.428 454.369Z" />
          <path d="M238.428 447.527C238.428 449.077 237.172 450.333 235.622 450.333C234.072 450.333 232.815 449.077 232.815 447.527C232.815 445.977 234.072 444.72 235.622 444.72C237.172 444.72 238.428 445.977 238.428 447.527Z" />
          <path d="M238.428 440.685C238.428 442.235 237.172 443.492 235.622 443.492C234.072 443.492 232.815 442.235 232.815 440.685C232.815 439.135 234.072 437.878 235.622 437.878C237.172 437.878 238.428 439.135 238.428 440.685Z" />
          <path d="M238.428 433.843C238.428 435.393 237.172 436.65 235.622 436.65C234.072 436.65 232.815 435.393 232.815 433.843C232.815 432.293 234.072 431.036 235.622 431.036C237.172 431.036 238.428 432.293 238.428 433.843Z" />
          <path d="M238.428 427.001C238.428 428.551 237.172 429.808 235.622 429.808C234.072 429.808 232.815 428.551 232.815 427.001C232.815 425.451 234.072 424.195 235.622 424.195C237.172 424.195 238.428 425.451 238.428 427.001Z" />
          <path d="M238.428 420.159C238.428 421.709 237.172 422.966 235.622 422.966C234.072 422.966 232.815 421.709 232.815 420.159C232.815 418.609 234.072 417.353 235.622 417.353C237.172 417.353 238.428 418.609 238.428 420.159Z" />
          <path d="M231.586 420.159C231.586 421.709 230.33 422.966 228.78 422.966C227.23 422.966 225.973 421.709 225.973 420.159C225.973 418.609 227.23 417.353 228.78 417.353C230.33 417.353 231.586 418.609 231.586 420.159Z" />
          <path d="M238.428 413.318C238.428 414.868 237.172 416.125 235.622 416.125C234.072 416.125 232.815 414.868 232.815 413.318C232.815 411.768 234.072 410.511 235.622 410.511C237.172 410.511 238.428 411.768 238.428 413.318Z" />
          <path d="M238.428 406.475C238.428 408.025 237.172 409.282 235.622 409.282C234.072 409.282 232.815 408.025 232.815 406.475C232.815 404.926 234.072 403.669 235.622 403.669C237.172 403.669 238.428 404.926 238.428 406.475Z" />
          <path d="M238.428 399.634C238.428 401.184 237.172 402.441 235.622 402.441C234.072 402.441 232.815 401.184 232.815 399.634C232.815 398.084 234.072 396.827 235.622 396.827C237.172 396.827 238.428 398.084 238.428 399.634Z" />
          <path d="M238.428 392.792C238.428 394.342 237.172 395.599 235.622 395.599C234.072 395.599 232.815 394.342 232.815 392.792C232.815 391.242 234.072 389.985 235.622 389.985C237.172 389.985 238.428 391.242 238.428 392.792Z" />
          <path d="M238.428 385.95C238.428 387.5 237.172 388.757 235.622 388.757C234.072 388.757 232.815 387.5 232.815 385.95C232.815 384.4 234.072 383.144 235.622 383.144C237.172 383.144 238.428 384.4 238.428 385.95Z" />
          <path d="M238.428 379.109C238.428 380.658 237.172 381.915 235.622 381.915C234.072 381.915 232.815 380.658 232.815 379.109C232.815 377.559 234.072 376.302 235.622 376.302C237.172 376.302 238.428 377.559 238.428 379.109Z" />
          <path d="M245.27 379.109C245.27 380.658 244.013 381.915 242.463 381.915C240.914 381.915 239.657 380.658 239.657 379.109C239.657 377.559 240.914 376.302 242.463 376.302C244.013 376.302 245.27 377.559 245.27 379.109Z" />
          <path d="M252.112 379.109C252.112 380.658 250.855 381.915 249.305 381.915C247.755 381.915 246.499 380.658 246.499 379.109C246.499 377.559 247.755 376.302 249.305 376.302C250.855 376.302 252.112 377.559 252.112 379.109Z" />
          <path d="M258.954 379.109C258.954 380.658 257.697 381.915 256.147 381.915C254.597 381.915 253.34 380.658 253.34 379.109C253.34 377.559 254.597 376.302 256.147 376.302C257.697 376.302 258.954 377.559 258.954 379.109Z" />
          <path d="M265.796 379.109C265.796 380.658 264.539 381.915 262.989 381.915C261.439 381.915 260.182 380.658 260.182 379.109C260.182 377.559 261.439 376.302 262.989 376.302C264.539 376.302 265.796 377.559 265.796 379.109Z" />
          <path d="M272.638 379.109C272.638 380.658 271.381 381.915 269.831 381.915C268.281 381.915 267.024 380.658 267.024 379.109C267.024 377.559 268.281 376.302 269.831 376.302C271.381 376.302 272.638 377.559 272.638 379.109Z" />
          <path d="M279.479 379.109C279.479 380.658 278.223 381.915 276.673 381.915C275.123 381.915 273.866 380.658 273.866 379.109C273.866 377.559 275.123 376.302 276.673 376.302C278.223 376.302 279.479 377.559 279.479 379.109Z" />
          <path d="M286.321 379.109C286.321 380.658 285.065 381.915 283.515 381.915C281.965 381.915 280.708 380.658 280.708 379.109C280.708 377.559 281.965 376.302 283.515 376.302C285.065 376.302 286.321 377.559 286.321 379.109Z" />
          <path d="M293.163 379.109C293.163 380.658 291.906 381.915 290.356 381.915C288.806 381.915 287.55 380.658 287.55 379.109C287.55 377.559 288.806 376.302 290.356 376.302C291.906 376.302 293.163 377.559 293.163 379.109Z" />
          <path d="M293.163 372.267C293.163 373.816 291.906 375.073 290.356 375.073C288.806 375.073 287.55 373.816 287.55 372.267C287.55 370.717 288.806 369.46 290.356 369.46C291.906 369.46 293.163 370.717 293.163 372.267Z" />
          <path d="M286.321 372.267C286.321 373.816 285.065 375.073 283.515 375.073C281.965 375.073 280.708 373.816 280.708 372.267C280.708 370.717 281.965 369.46 283.515 369.46C285.065 369.46 286.321 370.717 286.321 372.267Z" />
          <path d="M279.479 372.267C279.479 373.816 278.223 375.073 276.673 375.073C275.123 375.073 273.866 373.816 273.866 372.267C273.866 370.717 275.123 369.46 276.673 369.46C278.223 369.46 279.479 370.717 279.479 372.267Z" />
          <path d="M272.638 372.267C272.638 373.816 271.381 375.073 269.831 375.073C268.281 375.073 267.024 373.816 267.024 372.267C267.024 370.717 268.281 369.46 269.831 369.46C271.381 369.46 272.638 370.717 272.638 372.267Z" />
          <path d="M265.796 372.267C265.796 373.816 264.539 375.073 262.989 375.073C261.439 375.073 260.182 373.816 260.182 372.267C260.182 370.717 261.439 369.46 262.989 369.46C264.539 369.46 265.796 370.717 265.796 372.267Z" />
          <path d="M258.954 372.267C258.954 373.816 257.697 375.073 256.147 375.073C254.597 375.073 253.34 373.816 253.34 372.267C253.34 370.717 254.597 369.46 256.147 369.46C257.697 369.46 258.954 370.717 258.954 372.267Z" />
          <path d="M252.112 372.267C252.112 373.816 250.855 375.073 249.305 375.073C247.755 375.073 246.499 373.816 246.499 372.267C246.499 370.717 247.755 369.46 249.305 369.46C250.855 369.46 252.112 370.717 252.112 372.267Z" />
          <path d="M245.27 372.267C245.27 373.816 244.013 375.073 242.463 375.073C240.914 375.073 239.657 373.816 239.657 372.267C239.657 370.717 240.914 369.46 242.463 369.46C244.013 369.46 245.27 370.717 245.27 372.267Z" />
          <path d="M238.428 372.267C238.428 373.816 237.172 375.073 235.622 375.073C234.072 375.073 232.815 373.816 232.815 372.267C232.815 370.717 234.072 369.46 235.622 369.46C237.172 369.46 238.428 370.717 238.428 372.267Z" />
          <path d="M238.428 365.424C238.428 366.974 237.172 368.231 235.622 368.231C234.072 368.231 232.815 366.974 232.815 365.424C232.815 363.875 234.072 362.618 235.622 362.618C237.172 362.618 238.428 363.875 238.428 365.424Z" />
          <path d="M231.586 365.424C231.586 366.974 230.33 368.231 228.78 368.231C227.23 368.231 225.973 366.974 225.973 365.424C225.973 363.875 227.23 362.618 228.78 362.618C230.33 362.618 231.586 363.875 231.586 365.424Z" />
          <path d="M238.428 358.583C238.428 360.133 237.172 361.39 235.622 361.39C234.072 361.39 232.815 360.133 232.815 358.583C232.815 357.033 234.072 355.776 235.622 355.776C237.172 355.776 238.428 357.033 238.428 358.583Z" />
          <path d="M231.586 358.583C231.586 360.133 230.33 361.39 228.78 361.39C227.23 361.39 225.973 360.133 225.973 358.583C225.973 357.033 227.23 355.776 228.78 355.776C230.33 355.776 231.586 357.033 231.586 358.583Z" />
          <path d="M224.745 358.583C224.745 360.133 223.488 361.39 221.938 361.39C220.388 361.39 219.131 360.133 219.131 358.583C219.131 357.033 220.388 355.776 221.938 355.776C223.488 355.776 224.745 357.033 224.745 358.583Z" />
          <path d="M224.745 351.741C224.745 353.291 223.488 354.548 221.938 354.548C220.388 354.548 219.131 353.291 219.131 351.741C219.131 350.191 220.388 348.934 221.938 348.934C223.488 348.934 224.745 350.191 224.745 351.741Z" />
          <path d="M217.903 351.741C217.903 353.291 216.646 354.548 215.096 354.548C213.546 354.548 212.289 353.291 212.289 351.741C212.289 350.191 213.546 348.934 215.096 348.934C216.646 348.934 217.903 350.191 217.903 351.741Z" />
          <path d="M217.903 344.899C217.903 346.449 216.646 347.706 215.096 347.706C213.546 347.706 212.289 346.449 212.289 344.899C212.289 343.349 213.546 342.093 215.096 342.093C216.646 342.093 217.903 343.349 217.903 344.899Z" />
          <path d="M217.903 338.057C217.903 339.607 216.646 340.864 215.096 340.864C213.546 340.864 212.289 339.607 212.289 338.057C212.289 336.507 213.546 335.25 215.096 335.25C216.646 335.25 217.903 336.507 217.903 338.057Z" />
          <path d="M211.061 338.057C211.061 339.607 209.804 340.864 208.254 340.864C206.704 340.864 205.448 339.607 205.448 338.057C205.448 336.507 206.704 335.25 208.254 335.25C209.804 335.25 211.061 336.507 211.061 338.057Z" />
          <path d="M211.061 331.215C211.061 332.765 209.804 334.022 208.254 334.022C206.704 334.022 205.448 332.765 205.448 331.215C205.448 329.666 206.704 328.409 208.254 328.409C209.804 328.409 211.061 329.666 211.061 331.215Z" />
          <path d="M217.903 331.215C217.903 332.765 216.646 334.022 215.096 334.022C213.546 334.022 212.289 332.765 212.289 331.215C212.289 329.666 213.546 328.409 215.096 328.409C216.646 328.409 217.903 329.666 217.903 331.215Z" />
          <path d="M224.745 331.215C224.745 332.765 223.488 334.022 221.938 334.022C220.388 334.022 219.131 332.765 219.131 331.215C219.131 329.666 220.388 328.409 221.938 328.409C223.488 328.409 224.745 329.666 224.745 331.215Z" />
          <path d="M224.745 338.057C224.745 339.607 223.488 340.864 221.938 340.864C220.388 340.864 219.131 339.607 219.131 338.057C219.131 336.507 220.388 335.25 221.938 335.25C223.488 335.25 224.745 336.507 224.745 338.057Z" />
          <path d="M224.745 344.899C224.745 346.449 223.488 347.706 221.938 347.706C220.388 347.706 219.131 346.449 219.131 344.899C219.131 343.349 220.388 342.093 221.938 342.093C223.488 342.093 224.745 343.349 224.745 344.899Z" />
          <path d="M231.586 344.899C231.586 346.449 230.33 347.706 228.78 347.706C227.23 347.706 225.973 346.449 225.973 344.899C225.973 343.349 227.23 342.093 228.78 342.093C230.33 342.093 231.586 343.349 231.586 344.899Z" />
          <path d="M231.586 351.741C231.586 353.291 230.33 354.548 228.78 354.548C227.23 354.548 225.973 353.291 225.973 351.741C225.973 350.191 227.23 348.934 228.78 348.934C230.33 348.934 231.586 350.191 231.586 351.741Z" />
          <path d="M238.428 351.741C238.428 353.291 237.172 354.548 235.622 354.548C234.072 354.548 232.815 353.291 232.815 351.741C232.815 350.191 234.072 348.934 235.622 348.934C237.172 348.934 238.428 350.191 238.428 351.741Z" />
          <path d="M245.27 351.741C245.27 353.291 244.013 354.548 242.463 354.548C240.914 354.548 239.657 353.291 239.657 351.741C239.657 350.191 240.914 348.934 242.463 348.934C244.013 348.934 245.27 350.191 245.27 351.741Z" />
          <path d="M245.27 344.899C245.27 346.449 244.013 347.706 242.463 347.706C240.914 347.706 239.657 346.449 239.657 344.899C239.657 343.349 240.914 342.093 242.463 342.093C244.013 342.093 245.27 343.349 245.27 344.899Z" />
          <path d="M245.27 338.057C245.27 339.607 244.013 340.864 242.463 340.864C240.914 340.864 239.657 339.607 239.657 338.057C239.657 336.507 240.914 335.25 242.463 335.25C244.013 335.25 245.27 336.507 245.27 338.057Z" />
          <path d="M245.27 331.215C245.27 332.765 244.013 334.022 242.463 334.022C240.914 334.022 239.657 332.765 239.657 331.215C239.657 329.666 240.914 328.409 242.463 328.409C244.013 328.409 245.27 329.666 245.27 331.215Z" />
          <path d="M245.27 324.373C245.27 325.923 244.013 327.18 242.463 327.18C240.914 327.18 239.657 325.923 239.657 324.373C239.657 322.824 240.914 321.567 242.463 321.567C244.013 321.567 245.27 322.824 245.27 324.373Z" />
          <path d="M245.27 317.532C245.27 319.082 244.013 320.339 242.463 320.339C240.914 320.339 239.657 319.082 239.657 317.532C239.657 315.982 240.914 314.725 242.463 314.725C244.013 314.725 245.27 315.982 245.27 317.532Z" />
          <path d="M238.428 317.532C238.428 319.082 237.172 320.339 235.622 320.339C234.072 320.339 232.815 319.082 232.815 317.532C232.815 315.982 234.072 314.725 235.622 314.725C237.172 314.725 238.428 315.982 238.428 317.532Z" />
          <path d="M238.428 310.69C238.428 312.24 237.172 313.497 235.622 313.497C234.072 313.497 232.815 312.24 232.815 310.69C232.815 309.14 234.072 307.883 235.622 307.883C237.172 307.883 238.428 309.14 238.428 310.69Z" />
          <path d="M231.586 310.69C231.586 312.24 230.33 313.497 228.78 313.497C227.23 313.497 225.973 312.24 225.973 310.69C225.973 309.14 227.23 307.883 228.78 307.883C230.33 307.883 231.586 309.14 231.586 310.69Z" />
          <path d="M224.745 310.69C224.745 312.24 223.488 313.497 221.938 313.497C220.388 313.497 219.131 312.24 219.131 310.69C219.131 309.14 220.388 307.883 221.938 307.883C223.488 307.883 224.745 309.14 224.745 310.69Z" />
          <path d="M217.903 310.69C217.903 312.24 216.646 313.497 215.096 313.497C213.546 313.497 212.289 312.24 212.289 310.69C212.289 309.14 213.546 307.883 215.096 307.883C216.646 307.883 217.903 309.14 217.903 310.69Z" />
          <path d="M217.903 317.532C217.903 319.082 216.646 320.339 215.096 320.339C213.546 320.339 212.289 319.082 212.289 317.532C212.289 315.982 213.546 314.725 215.096 314.725C216.646 314.725 217.903 315.982 217.903 317.532Z" />
          <path d="M224.745 317.532C224.745 319.082 223.488 320.339 221.938 320.339C220.388 320.339 219.131 319.082 219.131 317.532C219.131 315.982 220.388 314.725 221.938 314.725C223.488 314.725 224.745 315.982 224.745 317.532Z" />
          <path d="M231.586 317.532C231.586 319.082 230.33 320.339 228.78 320.339C227.23 320.339 225.973 319.082 225.973 317.532C225.973 315.982 227.23 314.725 228.78 314.725C230.33 314.725 231.586 315.982 231.586 317.532Z" />
          <path d="M231.586 324.373C231.586 325.923 230.33 327.18 228.78 327.18C227.23 327.18 225.973 325.923 225.973 324.373C225.973 322.824 227.23 321.567 228.78 321.567C230.33 321.567 231.586 322.824 231.586 324.373Z" />
          <path d="M238.428 324.373C238.428 325.923 237.172 327.18 235.622 327.18C234.072 327.18 232.815 325.923 232.815 324.373C232.815 322.824 234.072 321.567 235.622 321.567C237.172 321.567 238.428 322.824 238.428 324.373Z" />
          <path d="M238.428 331.215C238.428 332.765 237.172 334.022 235.622 334.022C234.072 334.022 232.815 332.765 232.815 331.215C232.815 329.666 234.072 328.409 235.622 328.409C237.172 328.409 238.428 329.666 238.428 331.215Z" />
          <path d="M231.586 331.215C231.586 332.765 230.33 334.022 228.78 334.022C227.23 334.022 225.973 332.765 225.973 331.215C225.973 329.666 227.23 328.409 228.78 328.409C230.33 328.409 231.586 329.666 231.586 331.215Z" />
          <path d="M231.586 338.057C231.586 339.607 230.33 340.864 228.78 340.864C227.23 340.864 225.973 339.607 225.973 338.057C225.973 336.507 227.23 335.25 228.78 335.25C230.33 335.25 231.586 336.507 231.586 338.057Z" />
          <path d="M238.428 338.057C238.428 339.607 237.172 340.864 235.622 340.864C234.072 340.864 232.815 339.607 232.815 338.057C232.815 336.507 234.072 335.25 235.622 335.25C237.172 335.25 238.428 336.507 238.428 338.057Z" />
          <path d="M238.428 344.899C238.428 346.449 237.172 347.706 235.622 347.706C234.072 347.706 232.815 346.449 232.815 344.899C232.815 343.349 234.072 342.093 235.622 342.093C237.172 342.093 238.428 343.349 238.428 344.899Z" />
          <path d="M224.745 324.373C224.745 325.923 223.488 327.18 221.938 327.18C220.388 327.18 219.131 325.923 219.131 324.373C219.131 322.824 220.388 321.567 221.938 321.567C223.488 321.567 224.745 322.824 224.745 324.373Z" />
          <path d="M217.903 324.373C217.903 325.923 216.646 327.18 215.096 327.18C213.546 327.18 212.289 325.923 212.289 324.373C212.289 322.824 213.546 321.567 215.096 321.567C216.646 321.567 217.903 322.824 217.903 324.373Z" />
          <path d="M211.061 317.532C211.061 319.082 209.804 320.339 208.254 320.339C206.704 320.339 205.448 319.082 205.448 317.532C205.448 315.982 206.704 314.725 208.254 314.725C209.804 314.725 211.061 315.982 211.061 317.532Z" />
          <path d="M245.27 358.583C245.27 360.133 244.013 361.39 242.463 361.39C240.914 361.39 239.657 360.133 239.657 358.583C239.657 357.033 240.914 355.776 242.463 355.776C244.013 355.776 245.27 357.033 245.27 358.583Z" />
          <path d="M245.27 365.424C245.27 366.974 244.013 368.231 242.463 368.231C240.914 368.231 239.657 366.974 239.657 365.424C239.657 363.875 240.914 362.618 242.463 362.618C244.013 362.618 245.27 363.875 245.27 365.424Z" />
          <path d="M252.112 365.424C252.112 366.974 250.855 368.231 249.305 368.231C247.755 368.231 246.499 366.974 246.499 365.424C246.499 363.875 247.755 362.618 249.305 362.618C250.855 362.618 252.112 363.875 252.112 365.424Z" />
          <path d="M258.954 365.424C258.954 366.974 257.697 368.231 256.147 368.231C254.597 368.231 253.34 366.974 253.34 365.424C253.34 363.875 254.597 362.618 256.147 362.618C257.697 362.618 258.954 363.875 258.954 365.424Z" />
          <path d="M265.796 365.424C265.796 366.974 264.539 368.231 262.989 368.231C261.439 368.231 260.182 366.974 260.182 365.424C260.182 363.875 261.439 362.618 262.989 362.618C264.539 362.618 265.796 363.875 265.796 365.424Z" />
          <path d="M272.638 365.424C272.638 366.974 271.381 368.231 269.831 368.231C268.281 368.231 267.024 366.974 267.024 365.424C267.024 363.875 268.281 362.618 269.831 362.618C271.381 362.618 272.638 363.875 272.638 365.424Z" />
          <path d="M279.479 365.424C279.479 366.974 278.223 368.231 276.673 368.231C275.123 368.231 273.866 366.974 273.866 365.424C273.866 363.875 275.123 362.618 276.673 362.618C278.223 362.618 279.479 363.875 279.479 365.424Z" />
          <path d="M286.321 365.424C286.321 366.974 285.065 368.231 283.515 368.231C281.965 368.231 280.708 366.974 280.708 365.424C280.708 363.875 281.965 362.618 283.515 362.618C285.065 362.618 286.321 363.875 286.321 365.424Z" />
          <path d="M293.163 365.424C293.163 366.974 291.906 368.231 290.356 368.231C288.806 368.231 287.55 366.974 287.55 365.424C287.55 363.875 288.806 362.618 290.356 362.618C291.906 362.618 293.163 363.875 293.163 365.424Z" />
          <path d="M300.005 365.424C300.005 366.974 298.748 368.231 297.198 368.231C295.648 368.231 294.391 366.974 294.391 365.424C294.391 363.875 295.648 362.618 297.198 362.618C298.748 362.618 300.005 363.875 300.005 365.424Z" />
          <path d="M300.005 358.583C300.005 360.133 298.748 361.39 297.198 361.39C295.648 361.39 294.391 360.133 294.391 358.583C294.391 357.033 295.648 355.776 297.198 355.776C298.748 355.776 300.005 357.033 300.005 358.583Z" />
          <path d="M300.005 351.741C300.005 353.291 298.748 354.548 297.198 354.548C295.648 354.548 294.391 353.291 294.391 351.741C294.391 350.191 295.648 348.934 297.198 348.934C298.748 348.934 300.005 350.191 300.005 351.741Z" />
          <path d="M300.005 344.899C300.005 346.449 298.748 347.706 297.198 347.706C295.648 347.706 294.391 346.449 294.391 344.899C294.391 343.349 295.648 342.093 297.198 342.093C298.748 342.093 300.005 343.349 300.005 344.899Z" />
          <path d="M300.005 338.057C300.005 339.607 298.748 340.864 297.198 340.864C295.648 340.864 294.391 339.607 294.391 338.057C294.391 336.507 295.648 335.25 297.198 335.25C298.748 335.25 300.005 336.507 300.005 338.057Z" />
          <path d="M306.847 338.057C306.847 339.607 305.59 340.864 304.04 340.864C302.49 340.864 301.233 339.607 301.233 338.057C301.233 336.507 302.49 335.25 304.04 335.25C305.59 335.25 306.847 336.507 306.847 338.057Z" />
          <path d="M306.847 331.215C306.847 332.765 305.59 334.022 304.04 334.022C302.49 334.022 301.233 332.765 301.233 331.215C301.233 329.666 302.49 328.409 304.04 328.409C305.59 328.409 306.847 329.666 306.847 331.215Z" />
          <path d="M300.005 331.215C300.005 332.765 298.748 334.022 297.198 334.022C295.648 334.022 294.391 332.765 294.391 331.215C294.391 329.666 295.648 328.409 297.198 328.409C298.748 328.409 300.005 329.666 300.005 331.215Z" />
          <path d="M293.163 331.215C293.163 332.765 291.906 334.022 290.356 334.022C288.806 334.022 287.55 332.765 287.55 331.215C287.55 329.666 288.806 328.409 290.356 328.409C291.906 328.409 293.163 329.666 293.163 331.215Z" />
          <path d="M293.163 324.373C293.163 325.923 291.906 327.18 290.356 327.18C288.806 327.18 287.55 325.923 287.55 324.373C287.55 322.824 288.806 321.567 290.356 321.567C291.906 321.567 293.163 322.824 293.163 324.373Z" />
          <path d="M286.321 324.373C286.321 325.923 285.065 327.18 283.515 327.18C281.965 327.18 280.708 325.923 280.708 324.373C280.708 322.824 281.965 321.567 283.515 321.567C285.065 321.567 286.321 322.824 286.321 324.373Z" />
          <path d="M279.479 324.373C279.479 325.923 278.223 327.18 276.673 327.18C275.123 327.18 273.866 325.923 273.866 324.373C273.866 322.824 275.123 321.567 276.673 321.567C278.223 321.567 279.479 322.824 279.479 324.373Z" />
          <path d="M279.479 317.532C279.479 319.082 278.223 320.339 276.673 320.339C275.123 320.339 273.866 319.082 273.866 317.532C273.866 315.982 275.123 314.725 276.673 314.725C278.223 314.725 279.479 315.982 279.479 317.532Z" />
          <path d="M279.479 310.69C279.479 312.24 278.223 313.497 276.673 313.497C275.123 313.497 273.866 312.24 273.866 310.69C273.866 309.14 275.123 307.883 276.673 307.883C278.223 307.883 279.479 309.14 279.479 310.69Z" />
          <path d="M272.638 310.69C272.638 312.24 271.381 313.497 269.831 313.497C268.281 313.497 267.024 312.24 267.024 310.69C267.024 309.14 268.281 307.883 269.831 307.883C271.381 307.883 272.638 309.14 272.638 310.69Z" />
          <path d="M265.796 310.69C265.796 312.24 264.539 313.497 262.989 313.497C261.439 313.497 260.182 312.24 260.182 310.69C260.182 309.14 261.439 307.883 262.989 307.883C264.539 307.883 265.796 309.14 265.796 310.69Z" />
          <path d="M258.954 310.69C258.954 312.24 257.697 313.497 256.147 313.497C254.597 313.497 253.34 312.24 253.34 310.69C253.34 309.14 254.597 307.883 256.147 307.883C257.697 307.883 258.954 309.14 258.954 310.69Z" />
          <path d="M258.954 303.848C258.954 305.398 257.697 306.655 256.147 306.655C254.597 306.655 253.34 305.398 253.34 303.848C253.34 302.298 254.597 301.041 256.147 301.041C257.697 301.041 258.954 302.298 258.954 303.848Z" />
          <path d="M252.112 303.848C252.112 305.398 250.855 306.655 249.305 306.655C247.755 306.655 246.499 305.398 246.499 303.848C246.499 302.298 247.755 301.041 249.305 301.041C250.855 301.041 252.112 302.298 252.112 303.848Z" />
          <path d="M252.112 297.006C252.112 298.556 250.855 299.813 249.305 299.813C247.755 299.813 246.499 298.556 246.499 297.006C246.499 295.456 247.755 294.199 249.305 294.199C250.855 294.199 252.112 295.456 252.112 297.006Z" />
          <path d="M245.27 297.006C245.27 298.556 244.013 299.813 242.463 299.813C240.914 299.813 239.657 298.556 239.657 297.006C239.657 295.456 240.914 294.199 242.463 294.199C244.013 294.199 245.27 295.456 245.27 297.006Z" />
          <path d="M238.428 297.006C238.428 298.556 237.172 299.813 235.622 299.813C234.072 299.813 232.815 298.556 232.815 297.006C232.815 295.456 234.072 294.199 235.622 294.199C237.172 294.199 238.428 295.456 238.428 297.006Z" />
          <path d="M231.586 297.006C231.586 298.556 230.33 299.813 228.78 299.813C227.23 299.813 225.973 298.556 225.973 297.006C225.973 295.456 227.23 294.199 228.78 294.199C230.33 294.199 231.586 295.456 231.586 297.006Z" />
          <path d="M224.745 297.006C224.745 298.556 223.488 299.813 221.938 299.813C220.388 299.813 219.131 298.556 219.131 297.006C219.131 295.456 220.388 294.199 221.938 294.199C223.488 294.199 224.745 295.456 224.745 297.006Z" />
          <path d="M217.903 297.006C217.903 298.556 216.646 299.813 215.096 299.813C213.546 299.813 212.289 298.556 212.289 297.006C212.289 295.456 213.546 294.199 215.096 294.199C216.646 294.199 217.903 295.456 217.903 297.006Z" />
          <path d="M211.061 297.006C211.061 298.556 209.804 299.813 208.254 299.813C206.704 299.813 205.448 298.556 205.448 297.006C205.448 295.456 206.704 294.199 208.254 294.199C209.804 294.199 211.061 295.456 211.061 297.006Z" />
          <path d="M204.219 297.006C204.219 298.556 202.962 299.813 201.412 299.813C199.863 299.813 198.606 298.556 198.606 297.006C198.606 295.456 199.863 294.199 201.412 294.199C202.962 294.199 204.219 295.456 204.219 297.006Z" />
          <path d="M197.377 290.164C197.377 291.714 196.12 292.971 194.571 292.971C193.021 292.971 191.764 291.714 191.764 290.164C191.764 288.615 193.021 287.358 194.571 287.358C196.12 287.358 197.377 288.615 197.377 290.164Z" />
          <path d="M197.377 283.323C197.377 284.873 196.12 286.129 194.571 286.129C193.021 286.129 191.764 284.873 191.764 283.323C191.764 281.773 193.021 280.516 194.571 280.516C196.12 280.516 197.377 281.773 197.377 283.323Z" />
          <path d="M190.535 283.323C190.535 284.873 189.279 286.129 187.729 286.129C186.179 286.129 184.922 284.873 184.922 283.323C184.922 281.773 186.179 280.516 187.729 280.516C189.279 280.516 190.535 281.773 190.535 283.323Z" />
          <path d="M183.694 283.323C183.694 284.873 182.437 286.129 180.887 286.129C179.337 286.129 178.08 284.873 178.08 283.323C178.08 281.773 179.337 280.516 180.887 280.516C182.437 280.516 183.694 281.773 183.694 283.323Z" />
          <path d="M183.694 276.481C183.694 278.031 182.437 279.288 180.887 279.288C179.337 279.288 178.08 278.031 178.08 276.481C178.08 274.931 179.337 273.674 180.887 273.674C182.437 273.674 183.694 274.931 183.694 276.481Z" />
          <path d="M176.852 276.481C176.852 278.031 175.595 279.288 174.045 279.288C172.495 279.288 171.238 278.031 171.238 276.481C171.238 274.931 172.495 273.674 174.045 273.674C175.595 273.674 176.852 274.931 176.852 276.481Z" />
          <path d="M170.01 276.481C170.01 278.031 168.753 279.288 167.203 279.288C165.653 279.288 164.396 278.031 164.396 276.481C164.396 274.931 165.653 273.674 167.203 273.674C168.753 273.674 170.01 274.931 170.01 276.481Z" />
          <path d="M163.168 269.639C163.168 271.189 161.911 272.445 160.361 272.445C158.811 272.445 157.555 271.189 157.555 269.639C157.555 268.089 158.811 266.832 160.361 266.832C161.911 266.832 163.168 268.089 163.168 269.639Z" />
          <path d="M163.168 276.481C163.168 278.031 161.911 279.288 160.361 279.288C158.811 279.288 157.555 278.031 157.555 276.481C157.555 274.931 158.811 273.674 160.361 273.674C161.911 273.674 163.168 274.931 163.168 276.481Z" />
          <path d="M156.326 269.639C156.326 271.189 155.069 272.445 153.52 272.445C151.97 272.445 150.713 271.189 150.713 269.639C150.713 268.089 151.97 266.832 153.52 266.832C155.069 266.832 156.326 268.089 156.326 269.639Z" />
          <path d="M149.484 269.639C149.484 271.189 148.228 272.445 146.678 272.445C145.128 272.445 143.871 271.189 143.871 269.639C143.871 268.089 145.128 266.832 146.678 266.832C148.228 266.832 149.484 268.089 149.484 269.639Z" />
          <path d="M149.484 262.797C149.484 264.347 148.228 265.604 146.678 265.604C145.128 265.604 143.871 264.347 143.871 262.797C143.871 261.247 145.128 259.99 146.678 259.99C148.228 259.99 149.484 261.247 149.484 262.797Z" />
          <path d="M149.484 255.955C149.484 257.505 148.228 258.762 146.678 258.762C145.128 258.762 143.871 257.505 143.871 255.955C143.871 254.405 145.128 253.148 146.678 253.148C148.228 253.148 149.484 254.405 149.484 255.955Z" />
          <path d="M156.326 255.955C156.326 257.505 155.069 258.762 153.52 258.762C151.97 258.762 150.713 257.505 150.713 255.955C150.713 254.405 151.97 253.148 153.52 253.148C155.069 253.148 156.326 254.405 156.326 255.955Z" />
          <path d="M156.326 249.113C156.326 250.663 155.069 251.92 153.52 251.92C151.97 251.92 150.713 250.663 150.713 249.113C150.713 247.563 151.97 246.307 153.52 246.307C155.069 246.307 156.326 247.563 156.326 249.113Z" />
          <path d="M156.326 242.272C156.326 243.822 155.069 245.078 153.52 245.078C151.97 245.078 150.713 243.822 150.713 242.272C150.713 240.722 151.97 239.465 153.52 239.465C155.069 239.465 156.326 240.722 156.326 242.272Z" />
          <path d="M156.326 235.43C156.326 236.98 155.069 238.236 153.52 238.236C151.97 238.236 150.713 236.98 150.713 235.43C150.713 233.88 151.97 232.623 153.52 232.623C155.069 232.623 156.326 233.88 156.326 235.43Z" />
          <path d="M149.484 235.43C149.484 236.98 148.228 238.236 146.678 238.236C145.128 238.236 143.871 236.98 143.871 235.43C143.871 233.88 145.128 232.623 146.678 232.623C148.228 232.623 149.484 233.88 149.484 235.43Z" />
          <path d="M149.484 242.272C149.484 243.822 148.228 245.078 146.678 245.078C145.128 245.078 143.871 243.822 143.871 242.272C143.871 240.722 145.128 239.465 146.678 239.465C148.228 239.465 149.484 240.722 149.484 242.272Z" />
          <path d="M149.484 249.113C149.484 250.663 148.228 251.92 146.678 251.92C145.128 251.92 143.871 250.663 143.871 249.113C143.871 247.563 145.128 246.307 146.678 246.307C148.228 246.307 149.484 247.563 149.484 249.113Z" />
          <path d="M142.643 249.113C142.643 250.663 141.386 251.92 139.836 251.92C138.286 251.92 137.029 250.663 137.029 249.113C137.029 247.563 138.286 246.307 139.836 246.307C141.386 246.307 142.643 247.563 142.643 249.113Z" />
          <path d="M142.643 242.272C142.643 243.822 141.386 245.078 139.836 245.078C138.286 245.078 137.029 243.822 137.029 242.272C137.029 240.722 138.286 239.465 139.836 239.465C141.386 239.465 142.643 240.722 142.643 242.272Z" />
          <path d="M142.643 235.43C142.643 236.98 141.386 238.236 139.836 238.236C138.286 238.236 137.029 236.98 137.029 235.43C137.029 233.88 138.286 232.623 139.836 232.623C141.386 232.623 142.643 233.88 142.643 235.43Z" />
          <path d="M135.801 235.43C135.801 236.98 134.544 238.236 132.994 238.236C131.444 238.236 130.187 236.98 130.187 235.43C130.187 233.88 131.444 232.623 132.994 232.623C134.544 232.623 135.801 233.88 135.801 235.43Z" />
          <path d="M135.801 228.588C135.801 230.137 134.544 231.394 132.994 231.394C131.444 231.394 130.187 230.137 130.187 228.588C130.187 227.038 131.444 225.781 132.994 225.781C134.544 225.781 135.801 227.038 135.801 228.588Z" />
          <path d="M142.643 228.588C142.643 230.137 141.386 231.394 139.836 231.394C138.286 231.394 137.029 230.137 137.029 228.588C137.029 227.038 138.286 225.781 139.836 225.781C141.386 225.781 142.643 227.038 142.643 228.588Z" />
          <path d="M149.484 228.588C149.484 230.137 148.228 231.394 146.678 231.394C145.128 231.394 143.871 230.137 143.871 228.588C143.871 227.038 145.128 225.781 146.678 225.781C148.228 225.781 149.484 227.038 149.484 228.588Z" />
          <path d="M156.326 228.588C156.326 230.137 155.069 231.394 153.52 231.394C151.97 231.394 150.713 230.137 150.713 228.588C150.713 227.038 151.97 225.781 153.52 225.781C155.069 225.781 156.326 227.038 156.326 228.588Z" />
          <path d="M163.168 228.588C163.168 230.137 161.911 231.394 160.361 231.394C158.811 231.394 157.555 230.137 157.555 228.588C157.555 227.038 158.811 225.781 160.361 225.781C161.911 225.781 163.168 227.038 163.168 228.588Z" />
          <path d="M170.01 228.588C170.01 230.137 168.753 231.394 167.203 231.394C165.653 231.394 164.396 230.137 164.396 228.588C164.396 227.038 165.653 225.781 167.203 225.781C168.753 225.781 170.01 227.038 170.01 228.588Z" />
          <path d="M176.852 228.588C176.852 230.137 175.595 231.394 174.045 231.394C172.495 231.394 171.238 230.137 171.238 228.588C171.238 227.038 172.495 225.781 174.045 225.781C175.595 225.781 176.852 227.038 176.852 228.588Z" />
          <path d="M183.694 228.588C183.694 230.137 182.437 231.394 180.887 231.394C179.337 231.394 178.08 230.137 178.08 228.588C178.08 227.038 179.337 225.781 180.887 225.781C182.437 225.781 183.694 227.038 183.694 228.588Z" />
          <path d="M190.535 228.588C190.535 230.137 189.279 231.394 187.729 231.394C186.179 231.394 184.922 230.137 184.922 228.588C184.922 227.038 186.179 225.781 187.729 225.781C189.279 225.781 190.535 227.038 190.535 228.588Z" />
          <path d="M197.377 228.588C197.377 230.137 196.12 231.394 194.571 231.394C193.021 231.394 191.764 230.137 191.764 228.588C191.764 227.038 193.021 225.781 194.571 225.781C196.12 225.781 197.377 227.038 197.377 228.588Z" />
          <path d="M204.219 228.588C204.219 230.137 202.962 231.394 201.412 231.394C199.863 231.394 198.606 230.137 198.606 228.588C198.606 227.038 199.863 225.781 201.412 225.781C202.962 225.781 204.219 227.038 204.219 228.588Z" />
          <path d="M211.061 228.588C211.061 230.137 209.804 231.394 208.254 231.394C206.704 231.394 205.448 230.137 205.448 228.588C205.448 227.038 206.704 225.781 208.254 225.781C209.804 225.781 211.061 227.038 211.061 228.588Z" />
          <path d="M211.061 221.746C211.061 223.296 209.804 224.553 208.254 224.553C206.704 224.553 205.448 223.296 205.448 221.746C205.448 220.196 206.704 218.939 208.254 218.939C209.804 218.939 211.061 220.196 211.061 221.746Z" />
          <path d="M211.061 214.904C211.061 216.454 209.804 217.711 208.254 217.711C206.704 217.711 205.448 216.454 205.448 214.904C205.448 213.354 206.704 212.097 208.254 212.097C209.804 212.097 211.061 213.354 211.061 214.904Z" />
          <path d="M211.061 208.062C211.061 209.612 209.804 210.869 208.254 210.869C206.704 210.869 205.448 209.612 205.448 208.062C205.448 206.512 206.704 205.256 208.254 205.256C209.804 205.256 211.061 206.512 211.061 208.062Z" />
          <path d="M217.903 208.062C217.903 209.612 216.646 210.869 215.096 210.869C213.546 210.869 212.289 209.612 212.289 208.062C212.289 206.512 213.546 205.256 215.096 205.256C216.646 205.256 217.903 206.512 217.903 208.062Z" />
          <path d="M217.903 201.22C217.903 202.77 216.646 204.027 215.096 204.027C213.546 204.027 212.289 202.77 212.289 201.22C212.289 199.67 213.546 198.413 215.096 198.413C216.646 198.413 217.903 199.67 217.903 201.22Z" />
          <path d="M217.903 221.746C217.903 223.296 216.646 224.553 215.096 224.553C213.546 224.553 212.289 223.296 212.289 221.746C212.289 220.196 213.546 218.939 215.096 218.939C216.646 218.939 217.903 220.196 217.903 221.746Z" />
          <path d="M217.903 214.904C217.903 216.454 216.646 217.711 215.096 217.711C213.546 217.711 212.289 216.454 212.289 214.904C212.289 213.354 213.546 212.097 215.096 212.097C216.646 212.097 217.903 213.354 217.903 214.904Z" />
          <path d="M224.745 214.904C224.745 216.454 223.488 217.711 221.938 217.711C220.388 217.711 219.131 216.454 219.131 214.904C219.131 213.354 220.388 212.097 221.938 212.097C223.488 212.097 224.745 213.354 224.745 214.904Z" />
          <path d="M224.745 208.062C224.745 209.612 223.488 210.869 221.938 210.869C220.388 210.869 219.131 209.612 219.131 208.062C219.131 206.512 220.388 205.256 221.938 205.256C223.488 205.256 224.745 206.512 224.745 208.062Z" />
          <path d="M224.745 201.22C224.745 202.77 223.488 204.027 221.938 204.027C220.388 204.027 219.131 202.77 219.131 201.22C219.131 199.67 220.388 198.413 221.938 198.413C223.488 198.413 224.745 199.67 224.745 201.22Z" />
          <path d="M231.586 208.062C231.586 209.612 230.33 210.869 228.78 210.869C227.23 210.869 225.973 209.612 225.973 208.062C225.973 206.512 227.23 205.256 228.78 205.256C230.33 205.256 231.586 206.512 231.586 208.062Z" />
          <path d="M231.586 201.22C231.586 202.77 230.33 204.027 228.78 204.027C227.23 204.027 225.973 202.77 225.973 201.22C225.973 199.67 227.23 198.413 228.78 198.413C230.33 198.413 231.586 199.67 231.586 201.22Z" />
          <path d="M231.586 194.379C231.586 195.929 230.33 197.185 228.78 197.185C227.23 197.185 225.973 195.929 225.973 194.379C225.973 192.829 227.23 191.572 228.78 191.572C230.33 191.572 231.586 192.829 231.586 194.379Z" />
          <path d="M238.428 194.379C238.428 195.929 237.172 197.185 235.622 197.185C234.072 197.185 232.815 195.929 232.815 194.379C232.815 192.829 234.072 191.572 235.622 191.572C237.172 191.572 238.428 192.829 238.428 194.379Z" />
          <path d="M238.428 187.537C238.428 189.087 237.172 190.344 235.622 190.344C234.072 190.344 232.815 189.087 232.815 187.537C232.815 185.987 234.072 184.73 235.622 184.73C237.172 184.73 238.428 185.987 238.428 187.537Z" />
          <path d="M238.428 180.695C238.428 182.245 237.172 183.502 235.622 183.502C234.072 183.502 232.815 182.245 232.815 180.695C232.815 179.145 234.072 177.888 235.622 177.888C237.172 177.888 238.428 179.145 238.428 180.695Z" />
          <path d="M231.586 180.695C231.586 182.245 230.33 183.502 228.78 183.502C227.23 183.502 225.973 182.245 225.973 180.695C225.973 179.145 227.23 177.888 228.78 177.888C230.33 177.888 231.586 179.145 231.586 180.695Z" />
          <path d="M231.586 173.853C231.586 175.403 230.33 176.66 228.78 176.66C227.23 176.66 225.973 175.403 225.973 173.853C225.973 172.303 227.23 171.046 228.78 171.046C230.33 171.046 231.586 172.303 231.586 173.853Z" />
          <path d="M231.586 167.011C231.586 168.561 230.33 169.818 228.78 169.818C227.23 169.818 225.973 168.561 225.973 167.011C225.973 165.461 227.23 164.204 228.78 164.204C230.33 164.204 231.586 165.461 231.586 167.011Z" />
          <path d="M231.586 160.169C231.586 161.719 230.33 162.976 228.78 162.976C227.23 162.976 225.973 161.719 225.973 160.169C225.973 158.619 227.23 157.363 228.78 157.363C230.33 157.363 231.586 158.619 231.586 160.169Z" />
          <path d="M238.428 160.169C238.428 161.719 237.172 162.976 235.622 162.976C234.072 162.976 232.815 161.719 232.815 160.169C232.815 158.619 234.072 157.363 235.622 157.363C237.172 157.363 238.428 158.619 238.428 160.169Z" />
          <path d="M238.428 153.328C238.428 154.877 237.172 156.134 235.622 156.134C234.072 156.134 232.815 154.877 232.815 153.328C232.815 151.778 234.072 150.521 235.622 150.521C237.172 150.521 238.428 151.778 238.428 153.328Z" />
          <path d="M245.27 153.328C245.27 154.877 244.013 156.134 242.463 156.134C240.914 156.134 239.657 154.877 239.657 153.328C239.657 151.778 240.914 150.521 242.463 150.521C244.013 150.521 245.27 151.778 245.27 153.328Z" />
          <path d="M252.112 153.328C252.112 154.877 250.855 156.134 249.305 156.134C247.755 156.134 246.499 154.877 246.499 153.328C246.499 151.778 247.755 150.521 249.305 150.521C250.855 150.521 252.112 151.778 252.112 153.328Z" />
          <path d="M258.954 153.328C258.954 154.877 257.697 156.134 256.147 156.134C254.597 156.134 253.34 154.877 253.34 153.328C253.34 151.778 254.597 150.521 256.147 150.521C257.697 150.521 258.954 151.778 258.954 153.328Z" />
          <path d="M258.954 160.169C258.954 161.719 257.697 162.976 256.147 162.976C254.597 162.976 253.34 161.719 253.34 160.169C253.34 158.619 254.597 157.363 256.147 157.363C257.697 157.363 258.954 158.619 258.954 160.169Z" />
          <path d="M252.112 160.169C252.112 161.719 250.855 162.976 249.305 162.976C247.755 162.976 246.499 161.719 246.499 160.169C246.499 158.619 247.755 157.363 249.305 157.363C250.855 157.363 252.112 158.619 252.112 160.169Z" />
          <path d="M245.27 160.169C245.27 161.719 244.013 162.976 242.463 162.976C240.914 162.976 239.657 161.719 239.657 160.169C239.657 158.619 240.914 157.363 242.463 157.363C244.013 157.363 245.27 158.619 245.27 160.169Z" />
          <path d="M245.27 167.011C245.27 168.561 244.013 169.818 242.463 169.818C240.914 169.818 239.657 168.561 239.657 167.011C239.657 165.461 240.914 164.204 242.463 164.204C244.013 164.204 245.27 165.461 245.27 167.011Z" />
          <path d="M238.428 167.011C238.428 168.561 237.172 169.818 235.622 169.818C234.072 169.818 232.815 168.561 232.815 167.011C232.815 165.461 234.072 164.204 235.622 164.204C237.172 164.204 238.428 165.461 238.428 167.011Z" />
          <path d="M238.428 173.853C238.428 175.403 237.172 176.66 235.622 176.66C234.072 176.66 232.815 175.403 232.815 173.853C232.815 172.303 234.072 171.046 235.622 171.046C237.172 171.046 238.428 172.303 238.428 173.853Z" />
          <path d="M245.27 173.853C245.27 175.403 244.013 176.66 242.463 176.66C240.914 176.66 239.657 175.403 239.657 173.853C239.657 172.303 240.914 171.046 242.463 171.046C244.013 171.046 245.27 172.303 245.27 173.853Z" />
          <path d="M252.112 173.853C252.112 175.403 250.855 176.66 249.305 176.66C247.755 176.66 246.499 175.403 246.499 173.853C246.499 172.303 247.755 171.046 249.305 171.046C250.855 171.046 252.112 172.303 252.112 173.853Z" />
          <path d="M252.112 167.011C252.112 168.561 250.855 169.818 249.305 169.818C247.755 169.818 246.499 168.561 246.499 167.011C246.499 165.461 247.755 164.204 249.305 164.204C250.855 164.204 252.112 165.461 252.112 167.011Z" />
          <path d="M258.954 167.011C258.954 168.561 257.697 169.818 256.147 169.818C254.597 169.818 253.34 168.561 253.34 167.011C253.34 165.461 254.597 164.204 256.147 164.204C257.697 164.204 258.954 165.461 258.954 167.011Z" />
          <path d="M265.796 167.011C265.796 168.561 264.539 169.818 262.989 169.818C261.439 169.818 260.182 168.561 260.182 167.011C260.182 165.461 261.439 164.204 262.989 164.204C264.539 164.204 265.796 165.461 265.796 167.011Z" />
          <path d="M272.638 167.011C272.638 168.561 271.381 169.818 269.831 169.818C268.281 169.818 267.024 168.561 267.024 167.011C267.024 165.461 268.281 164.204 269.831 164.204C271.381 164.204 272.638 165.461 272.638 167.011Z" />
          <path d="M279.479 167.011C279.479 168.561 278.223 169.818 276.673 169.818C275.123 169.818 273.866 168.561 273.866 167.011C273.866 165.461 275.123 164.204 276.673 164.204C278.223 164.204 279.479 165.461 279.479 167.011Z" />
          <path d="M272.638 180.695C272.638 182.245 271.381 183.502 269.831 183.502C268.281 183.502 267.024 182.245 267.024 180.695C267.024 179.145 268.281 177.888 269.831 177.888C271.381 177.888 272.638 179.145 272.638 180.695Z" />
          <path d="M279.479 180.695C279.479 182.245 278.223 183.502 276.673 183.502C275.123 183.502 273.866 182.245 273.866 180.695C273.866 179.145 275.123 177.888 276.673 177.888C278.223 177.888 279.479 179.145 279.479 180.695Z" />
          <path d="M245.27 146.486C245.27 148.036 244.013 149.292 242.463 149.292C240.914 149.292 239.657 148.036 239.657 146.486C239.657 144.936 240.914 143.679 242.463 143.679C244.013 143.679 245.27 144.936 245.27 146.486Z" />
          <path d="M245.27 139.644C245.27 141.194 244.013 142.451 242.463 142.451C240.914 142.451 239.657 141.194 239.657 139.644C239.657 138.094 240.914 136.837 242.463 136.837C244.013 136.837 245.27 138.094 245.27 139.644Z" />
          <path d="M245.27 132.802C245.27 134.352 244.013 135.608 242.463 135.608C240.914 135.608 239.657 134.352 239.657 132.802C239.657 131.252 240.914 129.995 242.463 129.995C244.013 129.995 245.27 131.252 245.27 132.802Z" />
          <path d="M252.112 132.802C252.112 134.352 250.855 135.608 249.305 135.608C247.755 135.608 246.499 134.352 246.499 132.802C246.499 131.252 247.755 129.995 249.305 129.995C250.855 129.995 252.112 131.252 252.112 132.802Z" />
          <path d="M252.112 139.644C252.112 141.194 250.855 142.451 249.305 142.451C247.755 142.451 246.499 141.194 246.499 139.644C246.499 138.094 247.755 136.837 249.305 136.837C250.855 136.837 252.112 138.094 252.112 139.644Z" />
          <path d="M252.112 146.486C252.112 148.036 250.855 149.292 249.305 149.292C247.755 149.292 246.499 148.036 246.499 146.486C246.499 144.936 247.755 143.679 249.305 143.679C250.855 143.679 252.112 144.936 252.112 146.486Z" />
          <path d="M258.954 146.486C258.954 148.036 257.697 149.292 256.147 149.292C254.597 149.292 253.34 148.036 253.34 146.486C253.34 144.936 254.597 143.679 256.147 143.679C257.697 143.679 258.954 144.936 258.954 146.486Z" />
          <path d="M265.796 146.486C265.796 148.036 264.539 149.292 262.989 149.292C261.439 149.292 260.182 148.036 260.182 146.486C260.182 144.936 261.439 143.679 262.989 143.679C264.539 143.679 265.796 144.936 265.796 146.486Z" />
          <path d="M265.796 153.328C265.796 154.877 264.539 156.134 262.989 156.134C261.439 156.134 260.182 154.877 260.182 153.328C260.182 151.778 261.439 150.521 262.989 150.521C264.539 150.521 265.796 151.778 265.796 153.328Z" />
          <path d="M265.796 160.169C265.796 161.719 264.539 162.976 262.989 162.976C261.439 162.976 260.182 161.719 260.182 160.169C260.182 158.619 261.439 157.363 262.989 157.363C264.539 157.363 265.796 158.619 265.796 160.169Z" />
          <path d="M272.638 160.169C272.638 161.719 271.381 162.976 269.831 162.976C268.281 162.976 267.024 161.719 267.024 160.169C267.024 158.619 268.281 157.363 269.831 157.363C271.381 157.363 272.638 158.619 272.638 160.169Z" />
          <path d="M265.796 139.644C265.796 141.194 264.539 142.451 262.989 142.451C261.439 142.451 260.182 141.194 260.182 139.644C260.182 138.094 261.439 136.837 262.989 136.837C264.539 136.837 265.796 138.094 265.796 139.644Z" />
          <path d="M245.27 125.96C245.27 127.51 244.013 128.767 242.463 128.767C240.914 128.767 239.657 127.51 239.657 125.96C239.657 124.41 240.914 123.153 242.463 123.153C244.013 123.153 245.27 124.41 245.27 125.96Z" />
          <path d="M231.586 119.118C231.586 120.668 230.33 121.925 228.78 121.925C227.23 121.925 225.973 120.668 225.973 119.118C225.973 117.568 227.23 116.312 228.78 116.312C230.33 116.312 231.586 117.568 231.586 119.118Z" />
          <path d="M238.428 139.644C238.428 141.194 237.172 142.451 235.622 142.451C234.072 142.451 232.815 141.194 232.815 139.644C232.815 138.094 234.072 136.837 235.622 136.837C237.172 136.837 238.428 138.094 238.428 139.644Z" />
          <path d="M238.428 146.486C238.428 148.036 237.172 149.292 235.622 149.292C234.072 149.292 232.815 148.036 232.815 146.486C232.815 144.936 234.072 143.679 235.622 143.679C237.172 143.679 238.428 144.936 238.428 146.486Z" />
          <path d="M224.745 173.853C224.745 175.403 223.488 176.66 221.938 176.66C220.388 176.66 219.131 175.403 219.131 173.853C219.131 172.303 220.388 171.046 221.938 171.046C223.488 171.046 224.745 172.303 224.745 173.853Z" />
          <path d="M231.586 187.537C231.586 189.087 230.33 190.344 228.78 190.344C227.23 190.344 225.973 189.087 225.973 187.537C225.973 185.987 227.23 184.73 228.78 184.73C230.33 184.73 231.586 185.987 231.586 187.537Z" />
          <path d="M224.745 187.537C224.745 189.087 223.488 190.344 221.938 190.344C220.388 190.344 219.131 189.087 219.131 187.537C219.131 185.987 220.388 184.73 221.938 184.73C223.488 184.73 224.745 185.987 224.745 187.537Z" />
          <path d="M224.745 180.695C224.745 182.245 223.488 183.502 221.938 183.502C220.388 183.502 219.131 182.245 219.131 180.695C219.131 179.145 220.388 177.888 221.938 177.888C223.488 177.888 224.745 179.145 224.745 180.695Z" />
          <path d="M217.903 180.695C217.903 182.245 216.646 183.502 215.096 183.502C213.546 183.502 212.289 182.245 212.289 180.695C212.289 179.145 213.546 177.888 215.096 177.888C216.646 177.888 217.903 179.145 217.903 180.695Z" />
          <path d="M211.061 180.695C211.061 182.245 209.804 183.502 208.254 183.502C206.704 183.502 205.448 182.245 205.448 180.695C205.448 179.145 206.704 177.888 208.254 177.888C209.804 177.888 211.061 179.145 211.061 180.695Z" />
          <path d="M211.061 173.853C211.061 175.403 209.804 176.66 208.254 176.66C206.704 176.66 205.448 175.403 205.448 173.853C205.448 172.303 206.704 171.046 208.254 171.046C209.804 171.046 211.061 172.303 211.061 173.853Z" />
          <path d="M204.219 173.853C204.219 175.403 202.962 176.66 201.412 176.66C199.863 176.66 198.606 175.403 198.606 173.853C198.606 172.303 199.863 171.046 201.412 171.046C202.962 171.046 204.219 172.303 204.219 173.853Z" />
          <path d="M217.903 173.853C217.903 175.403 216.646 176.66 215.096 176.66C213.546 176.66 212.289 175.403 212.289 173.853C212.289 172.303 213.546 171.046 215.096 171.046C216.646 171.046 217.903 172.303 217.903 173.853Z" />
          <path d="M217.903 167.011C217.903 168.561 216.646 169.818 215.096 169.818C213.546 169.818 212.289 168.561 212.289 167.011C212.289 165.461 213.546 164.204 215.096 164.204C216.646 164.204 217.903 165.461 217.903 167.011Z" />
          <path d="M211.061 167.011C211.061 168.561 209.804 169.818 208.254 169.818C206.704 169.818 205.448 168.561 205.448 167.011C205.448 165.461 206.704 164.204 208.254 164.204C209.804 164.204 211.061 165.461 211.061 167.011Z" />
          <path d="M211.061 160.169C211.061 161.719 209.804 162.976 208.254 162.976C206.704 162.976 205.448 161.719 205.448 160.169C205.448 158.619 206.704 157.363 208.254 157.363C209.804 157.363 211.061 158.619 211.061 160.169Z" />
          <path d="M204.219 160.169C204.219 161.719 202.962 162.976 201.412 162.976C199.863 162.976 198.606 161.719 198.606 160.169C198.606 158.619 199.863 157.363 201.412 157.363C202.962 157.363 204.219 158.619 204.219 160.169Z" />
          <path d="M197.377 160.169C197.377 161.719 196.12 162.976 194.571 162.976C193.021 162.976 191.764 161.719 191.764 160.169C191.764 158.619 193.021 157.363 194.571 157.363C196.12 157.363 197.377 158.619 197.377 160.169Z" />
          <path d="M190.535 160.169C190.535 161.719 189.279 162.976 187.729 162.976C186.179 162.976 184.922 161.719 184.922 160.169C184.922 158.619 186.179 157.363 187.729 157.363C189.279 157.363 190.535 158.619 190.535 160.169Z" />
          <path d="M190.535 167.011C190.535 168.561 189.279 169.818 187.729 169.818C186.179 169.818 184.922 168.561 184.922 167.011C184.922 165.461 186.179 164.204 187.729 164.204C189.279 164.204 190.535 165.461 190.535 167.011Z" />
          <path d="M217.903 160.169C217.903 161.719 216.646 162.976 215.096 162.976C213.546 162.976 212.289 161.719 212.289 160.169C212.289 158.619 213.546 157.363 215.096 157.363C216.646 157.363 217.903 158.619 217.903 160.169Z" />
          <path d="M217.903 153.328C217.903 154.877 216.646 156.134 215.096 156.134C213.546 156.134 212.289 154.877 212.289 153.328C212.289 151.778 213.546 150.521 215.096 150.521C216.646 150.521 217.903 151.778 217.903 153.328Z" />
          <path d="M211.061 153.328C211.061 154.877 209.804 156.134 208.254 156.134C206.704 156.134 205.448 154.877 205.448 153.328C205.448 151.778 206.704 150.521 208.254 150.521C209.804 150.521 211.061 151.778 211.061 153.328Z" />
          <path d="M204.219 153.328C204.219 154.877 202.962 156.134 201.412 156.134C199.863 156.134 198.606 154.877 198.606 153.328C198.606 151.778 199.863 150.521 201.412 150.521C202.962 150.521 204.219 151.778 204.219 153.328Z" />
          <path d="M204.219 146.486C204.219 148.036 202.962 149.292 201.412 149.292C199.863 149.292 198.606 148.036 198.606 146.486C198.606 144.936 199.863 143.679 201.412 143.679C202.962 143.679 204.219 144.936 204.219 146.486Z" />
          <path d="M197.377 146.486C197.377 148.036 196.12 149.292 194.571 149.292C193.021 149.292 191.764 148.036 191.764 146.486C191.764 144.936 193.021 143.679 194.571 143.679C196.12 143.679 197.377 144.936 197.377 146.486Z" />
          <path d="M190.535 146.486C190.535 148.036 189.279 149.292 187.729 149.292C186.179 149.292 184.922 148.036 184.922 146.486C184.922 144.936 186.179 143.679 187.729 143.679C189.279 143.679 190.535 144.936 190.535 146.486Z" />
          <path d="M190.535 153.328C190.535 154.877 189.279 156.134 187.729 156.134C186.179 156.134 184.922 154.877 184.922 153.328C184.922 151.778 186.179 150.521 187.729 150.521C189.279 150.521 190.535 151.778 190.535 153.328Z" />
          <path d="M183.694 146.486C183.694 148.036 182.437 149.292 180.887 149.292C179.337 149.292 178.08 148.036 178.08 146.486C178.08 144.936 179.337 143.679 180.887 143.679C182.437 143.679 183.694 144.936 183.694 146.486Z" />
          <path d="M176.852 146.486C176.852 148.036 175.595 149.292 174.045 149.292C172.495 149.292 171.238 148.036 171.238 146.486C171.238 144.936 172.495 143.679 174.045 143.679C175.595 143.679 176.852 144.936 176.852 146.486Z" />
          <path d="M197.377 139.644C197.377 141.194 196.12 142.451 194.571 142.451C193.021 142.451 191.764 141.194 191.764 139.644C191.764 138.094 193.021 136.837 194.571 136.837C196.12 136.837 197.377 138.094 197.377 139.644Z" />
          <path d="M183.694 139.644C183.694 141.194 182.437 142.451 180.887 142.451C179.337 142.451 178.08 141.194 178.08 139.644C178.08 138.094 179.337 136.837 180.887 136.837C182.437 136.837 183.694 138.094 183.694 139.644Z" />
          <path d="M197.377 132.802C197.377 134.352 196.12 135.608 194.571 135.608C193.021 135.608 191.764 134.352 191.764 132.802C191.764 131.252 193.021 129.995 194.571 129.995C196.12 129.995 197.377 131.252 197.377 132.802Z" />
          <path d="M190.535 132.802C190.535 134.352 189.279 135.608 187.729 135.608C186.179 135.608 184.922 134.352 184.922 132.802C184.922 131.252 186.179 129.995 187.729 129.995C189.279 129.995 190.535 131.252 190.535 132.802Z" />
          <path d="M197.377 125.96C197.377 127.51 196.12 128.767 194.571 128.767C193.021 128.767 191.764 127.51 191.764 125.96C191.764 124.41 193.021 123.153 194.571 123.153C196.12 123.153 197.377 124.41 197.377 125.96Z" />
          <path d="M197.377 119.118C197.377 120.668 196.12 121.925 194.571 121.925C193.021 121.925 191.764 120.668 191.764 119.118C191.764 117.568 193.021 116.312 194.571 116.312C196.12 116.312 197.377 117.568 197.377 119.118Z" />
          <path d="M190.535 119.118C190.535 120.668 189.279 121.925 187.729 121.925C186.179 121.925 184.922 120.668 184.922 119.118C184.922 117.568 186.179 116.312 187.729 116.312C189.279 116.312 190.535 117.568 190.535 119.118Z" />
          <path d="M245.27 187.537C245.27 189.087 244.013 190.344 242.463 190.344C240.914 190.344 239.657 189.087 239.657 187.537C239.657 185.987 240.914 184.73 242.463 184.73C244.013 184.73 245.27 185.987 245.27 187.537Z" />
          <path d="M252.112 187.537C252.112 189.087 250.855 190.344 249.305 190.344C247.755 190.344 246.499 189.087 246.499 187.537C246.499 185.987 247.755 184.73 249.305 184.73C250.855 184.73 252.112 185.987 252.112 187.537Z" />
          <path d="M252.112 180.695C252.112 182.245 250.855 183.502 249.305 183.502C247.755 183.502 246.499 182.245 246.499 180.695C246.499 179.145 247.755 177.888 249.305 177.888C250.855 177.888 252.112 179.145 252.112 180.695Z" />
          <path d="M258.954 194.379C258.954 195.929 257.697 197.185 256.147 197.185C254.597 197.185 253.34 195.929 253.34 194.379C253.34 192.829 254.597 191.572 256.147 191.572C257.697 191.572 258.954 192.829 258.954 194.379Z" />
          <path d="M265.796 187.537C265.796 189.087 264.539 190.344 262.989 190.344C261.439 190.344 260.182 189.087 260.182 187.537C260.182 185.987 261.439 184.73 262.989 184.73C264.539 184.73 265.796 185.987 265.796 187.537Z" />
          <path d="M128.959 228.588C128.959 230.137 127.702 231.394 126.152 231.394C124.602 231.394 123.345 230.137 123.345 228.588C123.345 227.038 124.602 225.781 126.152 225.781C127.702 225.781 128.959 227.038 128.959 228.588Z" />
          <path d="M128.959 221.746C128.959 223.296 127.702 224.553 126.152 224.553C124.602 224.553 123.345 223.296 123.345 221.746C123.345 220.196 124.602 218.939 126.152 218.939C127.702 218.939 128.959 220.196 128.959 221.746Z" />
          <path d="M122.117 221.746C122.117 223.296 120.86 224.553 119.31 224.553C117.76 224.553 116.504 223.296 116.504 221.746C116.504 220.196 117.76 218.939 119.31 218.939C120.86 218.939 122.117 220.196 122.117 221.746Z" />
          <path d="M122.117 228.588C122.117 230.137 120.86 231.394 119.31 231.394C117.76 231.394 116.504 230.137 116.504 228.588C116.504 227.038 117.76 225.781 119.31 225.781C120.86 225.781 122.117 227.038 122.117 228.588Z" />
          <path d="M122.117 235.43C122.117 236.98 120.86 238.236 119.31 238.236C117.76 238.236 116.504 236.98 116.504 235.43C116.504 233.88 117.76 232.623 119.31 232.623C120.86 232.623 122.117 233.88 122.117 235.43Z" />
          <path d="M122.117 242.272C122.117 243.822 120.86 245.078 119.31 245.078C117.76 245.078 116.504 243.822 116.504 242.272C116.504 240.722 117.76 239.465 119.31 239.465C120.86 239.465 122.117 240.722 122.117 242.272Z" />
          <path d="M19.4893 262.797C19.4893 264.347 18.2325 265.604 16.6826 265.604C15.1326 265.604 13.8759 264.347 13.8759 262.797C13.8759 261.247 15.1326 259.99 16.6826 259.99C18.2325 259.99 19.4893 261.247 19.4893 262.797Z" />
          <path d="M12.6474 255.955C12.6474 257.505 11.3906 258.762 9.84067 258.762C8.29073 258.762 7.03394 257.505 7.03394 255.955C7.03394 254.405 8.29073 253.148 9.84067 253.148C11.3906 253.148 12.6474 254.405 12.6474 255.955Z" />
          <path d="M128.959 242.272C128.959 243.822 127.702 245.078 126.152 245.078C124.602 245.078 123.345 243.822 123.345 242.272C123.345 240.722 124.602 239.465 126.152 239.465C127.702 239.465 128.959 240.722 128.959 242.272Z" />
          <path d="M128.959 249.113C128.959 250.663 127.702 251.92 126.152 251.92C124.602 251.92 123.345 250.663 123.345 249.113C123.345 247.563 124.602 246.307 126.152 246.307C127.702 246.307 128.959 247.563 128.959 249.113Z" />
          <path d="M135.801 255.955C135.801 257.505 134.544 258.762 132.994 258.762C131.444 258.762 130.187 257.505 130.187 255.955C130.187 254.405 131.444 253.148 132.994 253.148C134.544 253.148 135.801 254.405 135.801 255.955Z" />
          <path d="M122.117 214.904C122.117 216.454 120.86 217.711 119.31 217.711C117.76 217.711 116.504 216.454 116.504 214.904C116.504 213.354 117.76 212.097 119.31 212.097C120.86 212.097 122.117 213.354 122.117 214.904Z" />
          <path d="M115.275 214.904C115.275 216.454 114.018 217.711 112.468 217.711C110.918 217.711 109.662 216.454 109.662 214.904C109.662 213.354 110.918 212.097 112.468 212.097C114.018 212.097 115.275 213.354 115.275 214.904Z" />
          <path d="M115.275 208.062C115.275 209.612 114.018 210.869 112.468 210.869C110.918 210.869 109.662 209.612 109.662 208.062C109.662 206.512 110.918 205.256 112.468 205.256C114.018 205.256 115.275 206.512 115.275 208.062Z" />
          <path d="M115.275 201.22C115.275 202.77 114.018 204.027 112.468 204.027C110.918 204.027 109.662 202.77 109.662 201.22C109.662 199.67 110.918 198.413 112.468 198.413C114.018 198.413 115.275 199.67 115.275 201.22Z" />
          <path d="M115.275 194.379C115.275 195.929 114.018 197.185 112.468 197.185C110.918 197.185 109.662 195.929 109.662 194.379C109.662 192.829 110.918 191.572 112.468 191.572C114.018 191.572 115.275 192.829 115.275 194.379Z" />
          <path d="M115.275 187.537C115.275 189.087 114.018 190.344 112.468 190.344C110.918 190.344 109.662 189.087 109.662 187.537C109.662 185.987 110.918 184.73 112.468 184.73C114.018 184.73 115.275 185.987 115.275 187.537Z" />
          <path d="M122.117 187.537C122.117 189.087 120.86 190.344 119.31 190.344C117.76 190.344 116.504 189.087 116.504 187.537C116.504 185.987 117.76 184.73 119.31 184.73C120.86 184.73 122.117 185.987 122.117 187.537Z" />
          <path d="M122.117 180.695C122.117 182.245 120.86 183.502 119.31 183.502C117.76 183.502 116.504 182.245 116.504 180.695C116.504 179.145 117.76 177.888 119.31 177.888C120.86 177.888 122.117 179.145 122.117 180.695Z" />
          <path d="M115.275 180.695C115.275 182.245 114.018 183.502 112.468 183.502C110.918 183.502 109.662 182.245 109.662 180.695C109.662 179.145 110.918 177.888 112.468 177.888C114.018 177.888 115.275 179.145 115.275 180.695Z" />
          <path d="M128.959 180.695C128.959 182.245 127.702 183.502 126.152 183.502C124.602 183.502 123.345 182.245 123.345 180.695C123.345 179.145 124.602 177.888 126.152 177.888C127.702 177.888 128.959 179.145 128.959 180.695Z" />
          <path d="M135.801 180.695C135.801 182.245 134.544 183.502 132.994 183.502C131.444 183.502 130.187 182.245 130.187 180.695C130.187 179.145 131.444 177.888 132.994 177.888C134.544 177.888 135.801 179.145 135.801 180.695Z" />
          <path d="M142.643 180.695C142.643 182.245 141.386 183.502 139.836 183.502C138.286 183.502 137.029 182.245 137.029 180.695C137.029 179.145 138.286 177.888 139.836 177.888C141.386 177.888 142.643 179.145 142.643 180.695Z" />
          <path d="M149.484 180.695C149.484 182.245 148.228 183.502 146.678 183.502C145.128 183.502 143.871 182.245 143.871 180.695C143.871 179.145 145.128 177.888 146.678 177.888C148.228 177.888 149.484 179.145 149.484 180.695Z" />
          <path d="M156.326 180.695C156.326 182.245 155.069 183.502 153.52 183.502C151.97 183.502 150.713 182.245 150.713 180.695C150.713 179.145 151.97 177.888 153.52 177.888C155.069 177.888 156.326 179.145 156.326 180.695Z" />
          <path d="M163.168 180.695C163.168 182.245 161.911 183.502 160.361 183.502C158.811 183.502 157.555 182.245 157.555 180.695C157.555 179.145 158.811 177.888 160.361 177.888C161.911 177.888 163.168 179.145 163.168 180.695Z" />
          <path d="M170.01 180.695C170.01 182.245 168.753 183.502 167.203 183.502C165.653 183.502 164.396 182.245 164.396 180.695C164.396 179.145 165.653 177.888 167.203 177.888C168.753 177.888 170.01 179.145 170.01 180.695Z" />
          <path d="M176.852 180.695C176.852 182.245 175.595 183.502 174.045 183.502C172.495 183.502 171.238 182.245 171.238 180.695C171.238 179.145 172.495 177.888 174.045 177.888C175.595 177.888 176.852 179.145 176.852 180.695Z" />
          <path d="M183.694 180.695C183.694 182.245 182.437 183.502 180.887 183.502C179.337 183.502 178.08 182.245 178.08 180.695C178.08 179.145 179.337 177.888 180.887 177.888C182.437 177.888 183.694 179.145 183.694 180.695Z" />
          <path d="M190.535 180.695C190.535 182.245 189.279 183.502 187.729 183.502C186.179 183.502 184.922 182.245 184.922 180.695C184.922 179.145 186.179 177.888 187.729 177.888C189.279 177.888 190.535 179.145 190.535 180.695Z" />
          <path d="M190.535 173.853C190.535 175.403 189.279 176.66 187.729 176.66C186.179 176.66 184.922 175.403 184.922 173.853C184.922 172.303 186.179 171.046 187.729 171.046C189.279 171.046 190.535 172.303 190.535 173.853Z" />
          <path d="M197.377 173.853C197.377 175.403 196.12 176.66 194.571 176.66C193.021 176.66 191.764 175.403 191.764 173.853C191.764 172.303 193.021 171.046 194.571 171.046C196.12 171.046 197.377 172.303 197.377 173.853Z" />
          <path d="M183.694 173.853C183.694 175.403 182.437 176.66 180.887 176.66C179.337 176.66 178.08 175.403 178.08 173.853C178.08 172.303 179.337 171.046 180.887 171.046C182.437 171.046 183.694 172.303 183.694 173.853Z" />
          <path d="M176.852 173.853C176.852 175.403 175.595 176.66 174.045 176.66C172.495 176.66 171.238 175.403 171.238 173.853C171.238 172.303 172.495 171.046 174.045 171.046C175.595 171.046 176.852 172.303 176.852 173.853Z" />
          <path d="M170.01 173.853C170.01 175.403 168.753 176.66 167.203 176.66C165.653 176.66 164.396 175.403 164.396 173.853C164.396 172.303 165.653 171.046 167.203 171.046C168.753 171.046 170.01 172.303 170.01 173.853Z" />
          <path d="M163.168 173.853C163.168 175.403 161.911 176.66 160.361 176.66C158.811 176.66 157.555 175.403 157.555 173.853C157.555 172.303 158.811 171.046 160.361 171.046C161.911 171.046 163.168 172.303 163.168 173.853Z" />
          <path d="M156.326 173.853C156.326 175.403 155.069 176.66 153.52 176.66C151.97 176.66 150.713 175.403 150.713 173.853C150.713 172.303 151.97 171.046 153.52 171.046C155.069 171.046 156.326 172.303 156.326 173.853Z" />
          <path d="M149.484 173.853C149.484 175.403 148.228 176.66 146.678 176.66C145.128 176.66 143.871 175.403 143.871 173.853C143.871 172.303 145.128 171.046 146.678 171.046C148.228 171.046 149.484 172.303 149.484 173.853Z" />
          <path d="M142.643 173.853C142.643 175.403 141.386 176.66 139.836 176.66C138.286 176.66 137.029 175.403 137.029 173.853C137.029 172.303 138.286 171.046 139.836 171.046C141.386 171.046 142.643 172.303 142.643 173.853Z" />
          <path d="M135.801 173.853C135.801 175.403 134.544 176.66 132.994 176.66C131.444 176.66 130.187 175.403 130.187 173.853C130.187 172.303 131.444 171.046 132.994 171.046C134.544 171.046 135.801 172.303 135.801 173.853Z" />
          <path d="M128.959 173.853C128.959 175.403 127.702 176.66 126.152 176.66C124.602 176.66 123.345 175.403 123.345 173.853C123.345 172.303 124.602 171.046 126.152 171.046C127.702 171.046 128.959 172.303 128.959 173.853Z" />
          <path d="M122.117 173.853C122.117 175.403 120.86 176.66 119.31 176.66C117.76 176.66 116.504 175.403 116.504 173.853C116.504 172.303 117.76 171.046 119.31 171.046C120.86 171.046 122.117 172.303 122.117 173.853Z" />
          <path d="M122.117 167.011C122.117 168.561 120.86 169.818 119.31 169.818C117.76 169.818 116.504 168.561 116.504 167.011C116.504 165.461 117.76 164.204 119.31 164.204C120.86 164.204 122.117 165.461 122.117 167.011Z" />
          <path d="M122.117 160.169C122.117 161.719 120.86 162.976 119.31 162.976C117.76 162.976 116.504 161.719 116.504 160.169C116.504 158.619 117.76 157.363 119.31 157.363C120.86 157.363 122.117 158.619 122.117 160.169Z" />
          <path d="M122.117 153.328C122.117 154.877 120.86 156.134 119.31 156.134C117.76 156.134 116.504 154.877 116.504 153.328C116.504 151.778 117.76 150.521 119.31 150.521C120.86 150.521 122.117 151.778 122.117 153.328Z" />
          <path d="M115.275 153.328C115.275 154.877 114.018 156.134 112.468 156.134C110.918 156.134 109.662 154.877 109.662 153.328C109.662 151.778 110.918 150.521 112.468 150.521C114.018 150.521 115.275 151.778 115.275 153.328Z" />
          <path d="M115.275 146.486C115.275 148.036 114.018 149.292 112.468 149.292C110.918 149.292 109.662 148.036 109.662 146.486C109.662 144.936 110.918 143.679 112.468 143.679C114.018 143.679 115.275 144.936 115.275 146.486Z" />
          <path d="M115.275 139.644C115.275 141.194 114.018 142.451 112.468 142.451C110.918 142.451 109.662 141.194 109.662 139.644C109.662 138.094 110.918 136.837 112.468 136.837C114.018 136.837 115.275 138.094 115.275 139.644Z" />
          <path d="M115.275 132.802C115.275 134.352 114.018 135.608 112.468 135.608C110.918 135.608 109.662 134.352 109.662 132.802C109.662 131.252 110.918 129.995 112.468 129.995C114.018 129.995 115.275 131.252 115.275 132.802Z" />
          <path d="M108.433 132.802C108.433 134.352 107.176 135.608 105.627 135.608C104.077 135.608 102.82 134.352 102.82 132.802C102.82 131.252 104.077 129.995 105.627 129.995C107.176 129.995 108.433 131.252 108.433 132.802Z" />
          <path d="M108.433 125.96C108.433 127.51 107.176 128.767 105.627 128.767C104.077 128.767 102.82 127.51 102.82 125.96C102.82 124.41 104.077 123.153 105.627 123.153C107.176 123.153 108.433 124.41 108.433 125.96Z" />
          <path d="M108.433 119.118C108.433 120.668 107.176 121.925 105.627 121.925C104.077 121.925 102.82 120.668 102.82 119.118C102.82 117.568 104.077 116.312 105.627 116.312C107.176 116.312 108.433 117.568 108.433 119.118Z" />
          <path d="M108.433 112.277C108.433 113.826 107.176 115.083 105.627 115.083C104.077 115.083 102.82 113.826 102.82 112.277C102.82 110.727 104.077 109.47 105.627 109.47C107.176 109.47 108.433 110.727 108.433 112.277Z" />
          <path d="M101.591 112.277C101.591 113.826 100.335 115.083 98.7848 115.083C97.2348 115.083 95.978 113.826 95.978 112.277C95.978 110.727 97.2348 109.47 98.7848 109.47C100.335 109.47 101.591 110.727 101.591 112.277Z" />
          <path d="M101.591 105.435C101.591 106.985 100.335 108.241 98.7848 108.241C97.2348 108.241 95.978 106.985 95.978 105.435C95.978 103.885 97.2348 102.628 98.7848 102.628C100.335 102.628 101.591 103.885 101.591 105.435Z" />
          <path d="M94.7496 105.435C94.7496 106.985 93.4928 108.241 91.9428 108.241C90.3929 108.241 89.1361 106.985 89.1361 105.435C89.1361 103.885 90.3929 102.628 91.9428 102.628C93.4928 102.628 94.7496 103.885 94.7496 105.435Z" />
          <path d="M94.7496 98.5927C94.7496 100.143 93.4928 101.399 91.9428 101.399C90.3929 101.399 89.1361 100.143 89.1361 98.5927C89.1361 97.0427 90.3929 95.7859 91.9428 95.7859C93.4928 95.7859 94.7496 97.0427 94.7496 98.5927Z" />
          <path d="M87.9078 98.5927C87.9078 100.143 86.651 101.399 85.101 101.399C83.5511 101.399 82.2943 100.143 82.2943 98.5927C82.2943 97.0427 83.5511 95.7859 85.101 95.7859C86.651 95.7859 87.9078 97.0427 87.9078 98.5927Z" />
          <path d="M87.9078 91.7506C87.9078 93.3006 86.651 94.5574 85.101 94.5574C83.5511 94.5574 82.2943 93.3006 82.2943 91.7506C82.2943 90.2007 83.5511 88.9439 85.101 88.9439C86.651 88.9439 87.9078 90.2007 87.9078 91.7506Z" />
          <path d="M81.0659 91.7506C81.0659 93.3006 79.8091 94.5574 78.2591 94.5574C76.7092 94.5574 75.4524 93.3006 75.4524 91.7506C75.4524 90.2007 76.7092 88.9439 78.2591 88.9439C79.8091 88.9439 81.0659 90.2007 81.0659 91.7506Z" />
          <path d="M81.0659 98.5927C81.0659 100.143 79.8091 101.399 78.2591 101.399C76.7092 101.399 75.4524 100.143 75.4524 98.5927C75.4524 97.0427 76.7092 95.7859 78.2591 95.7859C79.8091 95.7859 81.0659 97.0427 81.0659 98.5927Z" />
          <path d="M81.0659 105.435C81.0659 106.985 79.8091 108.241 78.2591 108.241C76.7092 108.241 75.4524 106.985 75.4524 105.435C75.4524 103.885 76.7092 102.628 78.2591 102.628C79.8091 102.628 81.0659 103.885 81.0659 105.435Z" />
          <path d="M74.2241 105.435C74.2241 106.985 72.9673 108.241 71.4173 108.241C69.8674 108.241 68.6106 106.985 68.6106 105.435C68.6106 103.885 69.8674 102.628 71.4173 102.628C72.9673 102.628 74.2241 103.885 74.2241 105.435Z" />
          <path d="M74.2241 98.5927C74.2241 100.143 72.9673 101.399 71.4173 101.399C69.8674 101.399 68.6106 100.143 68.6106 98.5927C68.6106 97.0427 69.8674 95.7859 71.4173 95.7859C72.9673 95.7859 74.2241 97.0427 74.2241 98.5927Z" />
          <path d="M74.2241 91.7506C74.2241 93.3006 72.9673 94.5574 71.4173 94.5574C69.8674 94.5574 68.6106 93.3006 68.6106 91.7506C68.6106 90.2007 69.8674 88.9439 71.4173 88.9439C72.9673 88.9439 74.2241 90.2007 74.2241 91.7506Z" />
          <path d="M67.3823 91.7506C67.3823 93.3006 66.1255 94.5574 64.5755 94.5574C63.0256 94.5574 61.7688 93.3006 61.7688 91.7506C61.7688 90.2007 63.0256 88.9439 64.5755 88.9439C66.1255 88.9439 67.3823 90.2007 67.3823 91.7506Z" />
          <path d="M67.3823 98.5927C67.3823 100.143 66.1255 101.399 64.5755 101.399C63.0256 101.399 61.7688 100.143 61.7688 98.5927C61.7688 97.0427 63.0256 95.7859 64.5755 95.7859C66.1255 95.7859 67.3823 97.0427 67.3823 98.5927Z" />
          <path d="M67.3823 105.435C67.3823 106.985 66.1255 108.241 64.5755 108.241C63.0256 108.241 61.7688 106.985 61.7688 105.435C61.7688 103.885 63.0256 102.628 64.5755 102.628C66.1255 102.628 67.3823 103.885 67.3823 105.435Z" />
          <path d="M60.5403 105.435C60.5403 106.985 59.2836 108.241 57.7336 108.241C56.1837 108.241 54.9269 106.985 54.9269 105.435C54.9269 103.885 56.1837 102.628 57.7336 102.628C59.2836 102.628 60.5403 103.885 60.5403 105.435Z" />
          <path d="M53.6986 105.435C53.6986 106.985 52.4418 108.241 50.8918 108.241C49.3419 108.241 48.0851 106.985 48.0851 105.435C48.0851 103.885 49.3419 102.628 50.8918 102.628C52.4418 102.628 53.6986 103.885 53.6986 105.435Z" />
          <path d="M53.6986 98.5927C53.6986 100.143 52.4418 101.399 50.8918 101.399C49.3419 101.399 48.0851 100.143 48.0851 98.5927C48.0851 97.0427 49.3419 95.7859 50.8918 95.7859C52.4418 95.7859 53.6986 97.0427 53.6986 98.5927Z" />
          <path d="M53.6986 91.7506C53.6986 93.3006 52.4418 94.5574 50.8918 94.5574C49.3419 94.5574 48.0851 93.3006 48.0851 91.7506C48.0851 90.2007 49.3419 88.9439 50.8918 88.9439C52.4418 88.9439 53.6986 90.2007 53.6986 91.7506Z" />
          <path d="M60.5403 91.7506C60.5403 93.3006 59.2836 94.5574 57.7336 94.5574C56.1837 94.5574 54.9269 93.3006 54.9269 91.7506C54.9269 90.2007 56.1837 88.9439 57.7336 88.9439C59.2836 88.9439 60.5403 90.2007 60.5403 91.7506Z" />
          <path d="M60.5403 84.9091C60.5403 86.459 59.2836 87.7158 57.7336 87.7158C56.1837 87.7158 54.9269 86.459 54.9269 84.9091C54.9269 83.3591 56.1837 82.1024 57.7336 82.1024C59.2836 82.1024 60.5403 83.3591 60.5403 84.9091Z" />
          <path d="M67.3823 84.9091C67.3823 86.459 66.1255 87.7158 64.5755 87.7158C63.0256 87.7158 61.7688 86.459 61.7688 84.9091C61.7688 83.3591 63.0256 82.1024 64.5755 82.1024C66.1255 82.1024 67.3823 83.3591 67.3823 84.9091Z" />
          <path d="M74.2241 84.9091C74.2241 86.459 72.9673 87.7158 71.4173 87.7158C69.8674 87.7158 68.6106 86.459 68.6106 84.9091C68.6106 83.3591 69.8674 82.1024 71.4173 82.1024C72.9673 82.1024 74.2241 83.3591 74.2241 84.9091Z" />
          <path d="M74.2241 78.0673C74.2241 79.6172 72.9673 80.874 71.4173 80.874C69.8674 80.874 68.6106 79.6172 68.6106 78.0673C68.6106 76.5173 69.8674 75.2606 71.4173 75.2606C72.9673 75.2606 74.2241 76.5173 74.2241 78.0673Z" />
          <path d="M74.2241 71.2255C74.2241 72.7754 72.9673 74.0322 71.4173 74.0322C69.8674 74.0322 68.6106 72.7754 68.6106 71.2255C68.6106 69.6756 69.8674 68.4188 71.4173 68.4188C72.9673 68.4188 74.2241 69.6756 74.2241 71.2255Z" />
          <path d="M81.0659 71.2255C81.0659 72.7754 79.8091 74.0322 78.2591 74.0322C76.7092 74.0322 75.4524 72.7754 75.4524 71.2255C75.4524 69.6756 76.7092 68.4188 78.2591 68.4188C79.8091 68.4188 81.0659 69.6756 81.0659 71.2255Z" />
          <path d="M81.0659 64.3832C81.0659 65.9331 79.8091 67.1899 78.2591 67.1899C76.7092 67.1899 75.4524 65.9331 75.4524 64.3832C75.4524 62.8333 76.7092 61.5765 78.2591 61.5765C79.8091 61.5765 81.0659 62.8333 81.0659 64.3832Z" />
          <path d="M81.0659 57.5417C81.0659 59.0916 79.8091 60.3484 78.2591 60.3484C76.7092 60.3484 75.4524 59.0916 75.4524 57.5417C75.4524 55.9917 76.7092 54.7349 78.2591 54.7349C79.8091 54.7349 81.0659 55.9917 81.0659 57.5417Z" />
          <path d="M74.2241 57.5417C74.2241 59.0916 72.9673 60.3484 71.4173 60.3484C69.8674 60.3484 68.6106 59.0916 68.6106 57.5417C68.6106 55.9917 69.8674 54.7349 71.4173 54.7349C72.9673 54.7349 74.2241 55.9917 74.2241 57.5417Z" />
          <path d="M74.2241 50.6999C74.2241 52.2498 72.9673 53.5066 71.4173 53.5066C69.8674 53.5066 68.6106 52.2498 68.6106 50.6999C68.6106 49.1499 69.8674 47.8931 71.4173 47.8931C72.9673 47.8931 74.2241 49.1499 74.2241 50.6999Z" />
          <path d="M81.0659 50.6999C81.0659 52.2498 79.8091 53.5066 78.2591 53.5066C76.7092 53.5066 75.4524 52.2498 75.4524 50.6999C75.4524 49.1499 76.7092 47.8931 78.2591 47.8931C79.8091 47.8931 81.0659 49.1499 81.0659 50.6999Z" />
          <path d="M87.9078 50.6999C87.9078 52.2498 86.651 53.5066 85.101 53.5066C83.5511 53.5066 82.2943 52.2498 82.2943 50.6999C82.2943 49.1499 83.5511 47.8931 85.101 47.8931C86.651 47.8931 87.9078 49.1499 87.9078 50.6999Z" />
          <path d="M94.7496 50.6999C94.7496 52.2498 93.4928 53.5066 91.9428 53.5066C90.3929 53.5066 89.1361 52.2498 89.1361 50.6999C89.1361 49.1499 90.3929 47.8931 91.9428 47.8931C93.4928 47.8931 94.7496 49.1499 94.7496 50.6999Z" />
          <path d="M94.7496 43.8581C94.7496 45.408 93.4928 46.6648 91.9428 46.6648C90.3929 46.6648 89.1361 45.408 89.1361 43.8581C89.1361 42.3081 90.3929 41.0513 91.9428 41.0513C93.4928 41.0513 94.7496 42.3081 94.7496 43.8581Z" />
          <path d="M101.591 43.8581C101.591 45.408 100.335 46.6648 98.7848 46.6648C97.2348 46.6648 95.978 45.408 95.978 43.8581C95.978 42.3081 97.2348 41.0513 98.7848 41.0513C100.335 41.0513 101.591 42.3081 101.591 43.8581Z" />
          <path d="M101.591 50.6999C101.591 52.2498 100.335 53.5066 98.7848 53.5066C97.2348 53.5066 95.978 52.2498 95.978 50.6999C95.978 49.1499 97.2348 47.8931 98.7848 47.8931C100.335 47.8931 101.591 49.1499 101.591 50.6999Z" />
          <path d="M108.433 50.6999C108.433 52.2498 107.176 53.5066 105.627 53.5066C104.077 53.5066 102.82 52.2498 102.82 50.6999C102.82 49.1499 104.077 47.8931 105.627 47.8931C107.176 47.8931 108.433 49.1499 108.433 50.6999Z" />
          <path d="M108.433 57.5417C108.433 59.0916 107.176 60.3484 105.627 60.3484C104.077 60.3484 102.82 59.0916 102.82 57.5417C102.82 55.9917 104.077 54.7349 105.627 54.7349C107.176 54.7349 108.433 55.9917 108.433 57.5417Z" />
          <path d="M115.275 57.5417C115.275 59.0916 114.018 60.3484 112.468 60.3484C110.918 60.3484 109.662 59.0916 109.662 57.5417C109.662 55.9917 110.918 54.7349 112.468 54.7349C114.018 54.7349 115.275 55.9917 115.275 57.5417Z" />
          <path d="M122.117 57.5417C122.117 59.0916 120.86 60.3484 119.31 60.3484C117.76 60.3484 116.504 59.0916 116.504 57.5417C116.504 55.9917 117.76 54.7349 119.31 54.7349C120.86 54.7349 122.117 55.9917 122.117 57.5417Z" />
          <path d="M122.117 64.3832C122.117 65.9331 120.86 67.1899 119.31 67.1899C117.76 67.1899 116.504 65.9331 116.504 64.3832C116.504 62.8333 117.76 61.5765 119.31 61.5765C120.86 61.5765 122.117 62.8333 122.117 64.3832Z" />
          <path d="M128.959 64.3832C128.959 65.9331 127.702 67.1899 126.152 67.1899C124.602 67.1899 123.345 65.9331 123.345 64.3832C123.345 62.8333 124.602 61.5765 126.152 61.5765C127.702 61.5765 128.959 62.8333 128.959 64.3832Z" />
          <path d="M128.959 71.2255C128.959 72.7754 127.702 74.0322 126.152 74.0322C124.602 74.0322 123.345 72.7754 123.345 71.2255C123.345 69.6756 124.602 68.4188 126.152 68.4188C127.702 68.4188 128.959 69.6756 128.959 71.2255Z" />
          <path d="M122.117 71.2255C122.117 72.7754 120.86 74.0322 119.31 74.0322C117.76 74.0322 116.504 72.7754 116.504 71.2255C116.504 69.6756 117.76 68.4188 119.31 68.4188C120.86 68.4188 122.117 69.6756 122.117 71.2255Z" />
          <path d="M115.275 71.2255C115.275 72.7754 114.018 74.0322 112.468 74.0322C110.918 74.0322 109.662 72.7754 109.662 71.2255C109.662 69.6756 110.918 68.4188 112.468 68.4188C114.018 68.4188 115.275 69.6756 115.275 71.2255Z" />
          <path d="M115.275 64.3832C115.275 65.9331 114.018 67.1899 112.468 67.1899C110.918 67.1899 109.662 65.9331 109.662 64.3832C109.662 62.8333 110.918 61.5765 112.468 61.5765C114.018 61.5765 115.275 62.8333 115.275 64.3832Z" />
          <path d="M108.433 64.3832C108.433 65.9331 107.176 67.1899 105.627 67.1899C104.077 67.1899 102.82 65.9331 102.82 64.3832C102.82 62.8333 104.077 61.5765 105.627 61.5765C107.176 61.5765 108.433 62.8333 108.433 64.3832Z" />
          <path d="M101.591 64.3832C101.591 65.9331 100.335 67.1899 98.7848 67.1899C97.2348 67.1899 95.978 65.9331 95.978 64.3832C95.978 62.8333 97.2348 61.5765 98.7848 61.5765C100.335 61.5765 101.591 62.8333 101.591 64.3832Z" />
          <path d="M101.591 57.5417C101.591 59.0916 100.335 60.3484 98.7848 60.3484C97.2348 60.3484 95.978 59.0916 95.978 57.5417C95.978 55.9917 97.2348 54.7349 98.7848 54.7349C100.335 54.7349 101.591 55.9917 101.591 57.5417Z" />
          <path d="M94.7496 57.5417C94.7496 59.0916 93.4928 60.3484 91.9428 60.3484C90.3929 60.3484 89.1361 59.0916 89.1361 57.5417C89.1361 55.9917 90.3929 54.7349 91.9428 54.7349C93.4928 54.7349 94.7496 55.9917 94.7496 57.5417Z" />
          <path d="M87.9078 57.5417C87.9078 59.0916 86.651 60.3484 85.101 60.3484C83.5511 60.3484 82.2943 59.0916 82.2943 57.5417C82.2943 55.9917 83.5511 54.7349 85.101 54.7349C86.651 54.7349 87.9078 55.9917 87.9078 57.5417Z" />
          <path d="M87.9078 64.3832C87.9078 65.9331 86.651 67.1899 85.101 67.1899C83.5511 67.1899 82.2943 65.9331 82.2943 64.3832C82.2943 62.8333 83.5511 61.5765 85.101 61.5765C86.651 61.5765 87.9078 62.8333 87.9078 64.3832Z" />
          <path d="M94.7496 64.3832C94.7496 65.9331 93.4928 67.1899 91.9428 67.1899C90.3929 67.1899 89.1361 65.9331 89.1361 64.3832C89.1361 62.8333 90.3929 61.5765 91.9428 61.5765C93.4928 61.5765 94.7496 62.8333 94.7496 64.3832Z" />
          <path d="M94.7496 71.2255C94.7496 72.7754 93.4928 74.0322 91.9428 74.0322C90.3929 74.0322 89.1361 72.7754 89.1361 71.2255C89.1361 69.6756 90.3929 68.4188 91.9428 68.4188C93.4928 68.4188 94.7496 69.6756 94.7496 71.2255Z" />
          <path d="M87.9078 71.2255C87.9078 72.7754 86.651 74.0322 85.101 74.0322C83.5511 74.0322 82.2943 72.7754 82.2943 71.2255C82.2943 69.6756 83.5511 68.4188 85.101 68.4188C86.651 68.4188 87.9078 69.6756 87.9078 71.2255Z" />
          <path d="M87.9078 78.0673C87.9078 79.6172 86.651 80.874 85.101 80.874C83.5511 80.874 82.2943 79.6172 82.2943 78.0673C82.2943 76.5173 83.5511 75.2606 85.101 75.2606C86.651 75.2606 87.9078 76.5173 87.9078 78.0673Z" />
          <path d="M94.7496 78.0673C94.7496 79.6172 93.4928 80.874 91.9428 80.874C90.3929 80.874 89.1361 79.6172 89.1361 78.0673C89.1361 76.5173 90.3929 75.2606 91.9428 75.2606C93.4928 75.2606 94.7496 76.5173 94.7496 78.0673Z" />
          <path d="M101.591 78.0673C101.591 79.6172 100.335 80.874 98.7848 80.874C97.2348 80.874 95.978 79.6172 95.978 78.0673C95.978 76.5173 97.2348 75.2606 98.7848 75.2606C100.335 75.2606 101.591 76.5173 101.591 78.0673Z" />
          <path d="M101.591 71.2255C101.591 72.7754 100.335 74.0322 98.7848 74.0322C97.2348 74.0322 95.978 72.7754 95.978 71.2255C95.978 69.6756 97.2348 68.4188 98.7848 68.4188C100.335 68.4188 101.591 69.6756 101.591 71.2255Z" />
          <path d="M108.433 71.2255C108.433 72.7754 107.176 74.0322 105.627 74.0322C104.077 74.0322 102.82 72.7754 102.82 71.2255C102.82 69.6756 104.077 68.4188 105.627 68.4188C107.176 68.4188 108.433 69.6756 108.433 71.2255Z" />
          <path d="M108.433 78.0673C108.433 79.6172 107.176 80.874 105.627 80.874C104.077 80.874 102.82 79.6172 102.82 78.0673C102.82 76.5173 104.077 75.2606 105.627 75.2606C107.176 75.2606 108.433 76.5173 108.433 78.0673Z" />
          <path d="M115.275 78.0673C115.275 79.6172 114.018 80.874 112.468 80.874C110.918 80.874 109.662 79.6172 109.662 78.0673C109.662 76.5173 110.918 75.2606 112.468 75.2606C114.018 75.2606 115.275 76.5173 115.275 78.0673Z" />
          <path d="M122.117 78.0673C122.117 79.6172 120.86 80.874 119.31 80.874C117.76 80.874 116.504 79.6172 116.504 78.0673C116.504 76.5173 117.76 75.2606 119.31 75.2606C120.86 75.2606 122.117 76.5173 122.117 78.0673Z" />
          <path d="M128.959 78.0673C128.959 79.6172 127.702 80.874 126.152 80.874C124.602 80.874 123.345 79.6172 123.345 78.0673C123.345 76.5173 124.602 75.2606 126.152 75.2606C127.702 75.2606 128.959 76.5173 128.959 78.0673Z" />
          <path d="M135.801 78.0673C135.801 79.6172 134.544 80.874 132.994 80.874C131.444 80.874 130.187 79.6172 130.187 78.0673C130.187 76.5173 131.444 75.2606 132.994 75.2606C134.544 75.2606 135.801 76.5173 135.801 78.0673Z" />
          <path d="M142.643 78.0673C142.643 79.6172 141.386 80.874 139.836 80.874C138.286 80.874 137.029 79.6172 137.029 78.0673C137.029 76.5173 138.286 75.2606 139.836 75.2606C141.386 75.2606 142.643 76.5173 142.643 78.0673Z" />
          <path d="M142.643 71.2255C142.643 72.7754 141.386 74.0322 139.836 74.0322C138.286 74.0322 137.029 72.7754 137.029 71.2255C137.029 69.6756 138.286 68.4188 139.836 68.4188C141.386 68.4188 142.643 69.6756 142.643 71.2255Z" />
          <path d="M149.484 71.2255C149.484 72.7754 148.228 74.0322 146.678 74.0322C145.128 74.0322 143.871 72.7754 143.871 71.2255C143.871 69.6756 145.128 68.4188 146.678 68.4188C148.228 68.4188 149.484 69.6756 149.484 71.2255Z" />
          <path d="M156.326 64.3832C156.326 65.9331 155.069 67.1899 153.52 67.1899C151.97 67.1899 150.713 65.9331 150.713 64.3832C150.713 62.8333 151.97 61.5765 153.52 61.5765C155.069 61.5765 156.326 62.8333 156.326 64.3832Z" />
          <path d="M149.484 64.3832C149.484 65.9331 148.228 67.1899 146.678 67.1899C145.128 67.1899 143.871 65.9331 143.871 64.3832C143.871 62.8333 145.128 61.5765 146.678 61.5765C148.228 61.5765 149.484 62.8333 149.484 64.3832Z" />
          <path d="M156.326 71.2255C156.326 72.7754 155.069 74.0322 153.52 74.0322C151.97 74.0322 150.713 72.7754 150.713 71.2255C150.713 69.6756 151.97 68.4188 153.52 68.4188C155.069 68.4188 156.326 69.6756 156.326 71.2255Z" />
          <path d="M149.484 78.0673C149.484 79.6172 148.228 80.874 146.678 80.874C145.128 80.874 143.871 79.6172 143.871 78.0673C143.871 76.5173 145.128 75.2606 146.678 75.2606C148.228 75.2606 149.484 76.5173 149.484 78.0673Z" />
          <path d="M156.326 78.0673C156.326 79.6172 155.069 80.874 153.52 80.874C151.97 80.874 150.713 79.6172 150.713 78.0673C150.713 76.5173 151.97 75.2606 153.52 75.2606C155.069 75.2606 156.326 76.5173 156.326 78.0673Z" />
          <path d="M156.326 84.9091C156.326 86.459 155.069 87.7158 153.52 87.7158C151.97 87.7158 150.713 86.459 150.713 84.9091C150.713 83.3591 151.97 82.1024 153.52 82.1024C155.069 82.1024 156.326 83.3591 156.326 84.9091Z" />
          <path d="M163.168 78.0673C163.168 79.6172 161.911 80.874 160.361 80.874C158.811 80.874 157.555 79.6172 157.555 78.0673C157.555 76.5173 158.811 75.2606 160.361 75.2606C161.911 75.2606 163.168 76.5173 163.168 78.0673Z" />
          <path d="M163.168 84.9091C163.168 86.459 161.911 87.7158 160.361 87.7158C158.811 87.7158 157.555 86.459 157.555 84.9091C157.555 83.3591 158.811 82.1024 160.361 82.1024C161.911 82.1024 163.168 83.3591 163.168 84.9091Z" />
          <path d="M170.01 78.0673C170.01 79.6172 168.753 80.874 167.203 80.874C165.653 80.874 164.396 79.6172 164.396 78.0673C164.396 76.5173 165.653 75.2606 167.203 75.2606C168.753 75.2606 170.01 76.5173 170.01 78.0673Z" />
          <path d="M183.694 78.0673C183.694 79.6172 182.437 80.874 180.887 80.874C179.337 80.874 178.08 79.6172 178.08 78.0673C178.08 76.5173 179.337 75.2606 180.887 75.2606C182.437 75.2606 183.694 76.5173 183.694 78.0673Z" />
          <path d="M183.694 71.2255C183.694 72.7754 182.437 74.0322 180.887 74.0322C179.337 74.0322 178.08 72.7754 178.08 71.2255C178.08 69.6756 179.337 68.4188 180.887 68.4188C182.437 68.4188 183.694 69.6756 183.694 71.2255Z" />
          <path d="M176.852 71.2255C176.852 72.7754 175.595 74.0322 174.045 74.0322C172.495 74.0322 171.238 72.7754 171.238 71.2255C171.238 69.6756 172.495 68.4188 174.045 68.4188C175.595 68.4188 176.852 69.6756 176.852 71.2255Z" />
          <path d="M190.535 78.0673C190.535 79.6172 189.279 80.874 187.729 80.874C186.179 80.874 184.922 79.6172 184.922 78.0673C184.922 76.5173 186.179 75.2606 187.729 75.2606C189.279 75.2606 190.535 76.5173 190.535 78.0673Z" />
          <path d="M190.535 71.2255C190.535 72.7754 189.279 74.0322 187.729 74.0322C186.179 74.0322 184.922 72.7754 184.922 71.2255C184.922 69.6756 186.179 68.4188 187.729 68.4188C189.279 68.4188 190.535 69.6756 190.535 71.2255Z" />
          <path d="M197.377 71.2255C197.377 72.7754 196.12 74.0322 194.571 74.0322C193.021 74.0322 191.764 72.7754 191.764 71.2255C191.764 69.6756 193.021 68.4188 194.571 68.4188C196.12 68.4188 197.377 69.6756 197.377 71.2255Z" />
          <path d="M204.219 78.0673C204.219 79.6172 202.962 80.874 201.412 80.874C199.863 80.874 198.606 79.6172 198.606 78.0673C198.606 76.5173 199.863 75.2606 201.412 75.2606C202.962 75.2606 204.219 76.5173 204.219 78.0673Z" />
          <path d="M204.219 64.3832C204.219 65.9331 202.962 67.1899 201.412 67.1899C199.863 67.1899 198.606 65.9331 198.606 64.3832C198.606 62.8333 199.863 61.5765 201.412 61.5765C202.962 61.5765 204.219 62.8333 204.219 64.3832Z" />
          <path d="M211.061 57.5417C211.061 59.0916 209.804 60.3484 208.254 60.3484C206.704 60.3484 205.448 59.0916 205.448 57.5417C205.448 55.9917 206.704 54.7349 208.254 54.7349C209.804 54.7349 211.061 55.9917 211.061 57.5417Z" />
          <path d="M204.219 37.0163C204.219 38.5662 202.962 39.823 201.412 39.823C199.863 39.823 198.606 38.5662 198.606 37.0163C198.606 35.4663 199.863 34.2095 201.412 34.2095C202.962 34.2095 204.219 35.4663 204.219 37.0163Z" />
          <path d="M204.219 43.8581C204.219 45.408 202.962 46.6648 201.412 46.6648C199.863 46.6648 198.606 45.408 198.606 43.8581C198.606 42.3081 199.863 41.0513 201.412 41.0513C202.962 41.0513 204.219 42.3081 204.219 43.8581Z" />
          <path d="M204.219 23.3324C204.219 24.8824 202.962 26.1392 201.412 26.1392C199.863 26.1392 198.606 24.8824 198.606 23.3324C198.606 21.7825 199.863 20.5257 201.412 20.5257C202.962 20.5257 204.219 21.7825 204.219 23.3324Z" />
          <path d="M217.903 23.3324C217.903 24.8824 216.646 26.1392 215.096 26.1392C213.546 26.1392 212.289 24.8824 212.289 23.3324C212.289 21.7825 213.546 20.5257 215.096 20.5257C216.646 20.5257 217.903 21.7825 217.903 23.3324Z" />
          <path d="M224.745 16.4906C224.745 18.0406 223.488 19.2974 221.938 19.2974C220.388 19.2974 219.131 18.0406 219.131 16.4906C219.131 14.9407 220.388 13.6839 221.938 13.6839C223.488 13.6839 224.745 14.9407 224.745 16.4906Z" />
          <path d="M238.428 16.4906C238.428 18.0406 237.172 19.2974 235.622 19.2974C234.072 19.2974 232.815 18.0406 232.815 16.4906C232.815 14.9407 234.072 13.6839 235.622 13.6839C237.172 13.6839 238.428 14.9407 238.428 16.4906Z" />
          <path d="M238.428 23.3324C238.428 24.8824 237.172 26.1392 235.622 26.1392C234.072 26.1392 232.815 24.8824 232.815 23.3324C232.815 21.7825 234.072 20.5257 235.622 20.5257C237.172 20.5257 238.428 21.7825 238.428 23.3324Z" />
          <path d="M245.27 30.1742C245.27 31.7242 244.013 32.981 242.463 32.981C240.914 32.981 239.657 31.7242 239.657 30.1742C239.657 28.6243 240.914 27.3675 242.463 27.3675C244.013 27.3675 245.27 28.6243 245.27 30.1742Z" />
          <path d="M258.954 23.3324C258.954 24.8824 257.697 26.1392 256.147 26.1392C254.597 26.1392 253.34 24.8824 253.34 23.3324C253.34 21.7825 254.597 20.5257 256.147 20.5257C257.697 20.5257 258.954 21.7825 258.954 23.3324Z" />
          <path d="M258.954 16.4906C258.954 18.0406 257.697 19.2974 256.147 19.2974C254.597 19.2974 253.34 18.0406 253.34 16.4906C253.34 14.9407 254.597 13.6839 256.147 13.6839C257.697 13.6839 258.954 14.9407 258.954 16.4906Z" />
          <path d="M265.796 16.4906C265.796 18.0406 264.539 19.2974 262.989 19.2974C261.439 19.2974 260.182 18.0406 260.182 16.4906C260.182 14.9407 261.439 13.6839 262.989 13.6839C264.539 13.6839 265.796 14.9407 265.796 16.4906Z" />
          <path d="M265.796 9.64883C265.796 11.1988 264.539 12.4556 262.989 12.4556C261.439 12.4556 260.182 11.1988 260.182 9.64883C260.182 8.09889 261.439 6.8421 262.989 6.8421C264.539 6.8421 265.796 8.09889 265.796 9.64883Z" />
          <path d="M272.638 9.64883C272.638 11.1988 271.381 12.4556 269.831 12.4556C268.281 12.4556 267.024 11.1988 267.024 9.64883C267.024 8.09889 268.281 6.8421 269.831 6.8421C271.381 6.8421 272.638 8.09889 272.638 9.64883Z" />
          <path d="M279.479 9.64883C279.479 11.1988 278.223 12.4556 276.673 12.4556C275.123 12.4556 273.866 11.1988 273.866 9.64883C273.866 8.09889 275.123 6.8421 276.673 6.8421C278.223 6.8421 279.479 8.09889 279.479 9.64883Z" />
          <path d="M279.479 2.80679C279.479 4.35673 278.223 5.61353 276.673 5.61353C275.123 5.61353 273.866 4.35673 273.866 2.80679C273.866 1.25685 275.123 5.53131e-05 276.673 5.53131e-05C278.223 5.53131e-05 279.479 1.25685 279.479 2.80679Z" />
          <path d="M286.321 2.80679C286.321 4.35673 285.065 5.61353 283.515 5.61353C281.965 5.61353 280.708 4.35673 280.708 2.80679C280.708 1.25685 281.965 5.53131e-05 283.515 5.53131e-05C285.065 5.53131e-05 286.321 1.25685 286.321 2.80679Z" />
          <path d="M286.321 9.64883C286.321 11.1988 285.065 12.4556 283.515 12.4556C281.965 12.4556 280.708 11.1988 280.708 9.64883C280.708 8.09889 281.965 6.8421 283.515 6.8421C285.065 6.8421 286.321 8.09889 286.321 9.64883Z" />
          <path d="M293.163 9.64883C293.163 11.1988 291.906 12.4556 290.356 12.4556C288.806 12.4556 287.55 11.1988 287.55 9.64883C287.55 8.09889 288.806 6.8421 290.356 6.8421C291.906 6.8421 293.163 8.09889 293.163 9.64883Z" />
          <path d="M293.163 2.80679C293.163 4.35673 291.906 5.61353 290.356 5.61353C288.806 5.61353 287.55 4.35673 287.55 2.80679C287.55 1.25685 288.806 5.53131e-05 290.356 5.53131e-05C291.906 5.53131e-05 293.163 1.25685 293.163 2.80679Z" />
          <path d="M300.005 2.80679C300.005 4.35673 298.748 5.61353 297.198 5.61353C295.648 5.61353 294.391 4.35673 294.391 2.80679C294.391 1.25685 295.648 5.53131e-05 297.198 5.53131e-05C298.748 5.53131e-05 300.005 1.25685 300.005 2.80679Z" />
          <path d="M306.847 2.80679C306.847 4.35673 305.59 5.61353 304.04 5.61353C302.49 5.61353 301.233 4.35673 301.233 2.80679C301.233 1.25685 302.49 5.53131e-05 304.04 5.53131e-05C305.59 5.53131e-05 306.847 1.25685 306.847 2.80679Z" />
          <path d="M306.847 9.64883C306.847 11.1988 305.59 12.4556 304.04 12.4556C302.49 12.4556 301.233 11.1988 301.233 9.64883C301.233 8.09889 302.49 6.8421 304.04 6.8421C305.59 6.8421 306.847 8.09889 306.847 9.64883Z" />
          <path d="M313.689 9.64883C313.689 11.1988 312.432 12.4556 310.882 12.4556C309.332 12.4556 308.075 11.1988 308.075 9.64883C308.075 8.09889 309.332 6.8421 310.882 6.8421C312.432 6.8421 313.689 8.09889 313.689 9.64883Z" />
          <path d="M313.689 23.3324C313.689 24.8824 312.432 26.1392 310.882 26.1392C309.332 26.1392 308.075 24.8824 308.075 23.3324C308.075 21.7825 309.332 20.5257 310.882 20.5257C312.432 20.5257 313.689 21.7825 313.689 23.3324Z" />
          <path d="M306.847 23.3324C306.847 24.8824 305.59 26.1392 304.04 26.1392C302.49 26.1392 301.233 24.8824 301.233 23.3324C301.233 21.7825 302.49 20.5257 304.04 20.5257C305.59 20.5257 306.847 21.7825 306.847 23.3324Z" />
          <path d="M306.847 30.1742C306.847 31.7242 305.59 32.981 304.04 32.981C302.49 32.981 301.233 31.7242 301.233 30.1742C301.233 28.6243 302.49 27.3675 304.04 27.3675C305.59 27.3675 306.847 28.6243 306.847 30.1742Z" />
          <path d="M306.847 37.0163C306.847 38.5662 305.59 39.823 304.04 39.823C302.49 39.823 301.233 38.5662 301.233 37.0163C301.233 35.4663 302.49 34.2095 304.04 34.2095C305.59 34.2095 306.847 35.4663 306.847 37.0163Z" />
          <path d="M300.005 37.0163C300.005 38.5662 298.748 39.823 297.198 39.823C295.648 39.823 294.391 38.5662 294.391 37.0163C294.391 35.4663 295.648 34.2095 297.198 34.2095C298.748 34.2095 300.005 35.4663 300.005 37.0163Z" />
          <path d="M293.163 37.0163C293.163 38.5662 291.906 39.823 290.356 39.823C288.806 39.823 287.55 38.5662 287.55 37.0163C287.55 35.4663 288.806 34.2095 290.356 34.2095C291.906 34.2095 293.163 35.4663 293.163 37.0163Z" />
          <path d="M293.163 43.8581C293.163 45.408 291.906 46.6648 290.356 46.6648C288.806 46.6648 287.55 45.408 287.55 43.8581C287.55 42.3081 288.806 41.0513 290.356 41.0513C291.906 41.0513 293.163 42.3081 293.163 43.8581Z" />
          <path d="M300.005 43.8581C300.005 45.408 298.748 46.6648 297.198 46.6648C295.648 46.6648 294.391 45.408 294.391 43.8581C294.391 42.3081 295.648 41.0513 297.198 41.0513C298.748 41.0513 300.005 42.3081 300.005 43.8581Z" />
          <path d="M300.005 50.6999C300.005 52.2498 298.748 53.5066 297.198 53.5066C295.648 53.5066 294.391 52.2498 294.391 50.6999C294.391 49.1499 295.648 47.8931 297.198 47.8931C298.748 47.8931 300.005 49.1499 300.005 50.6999Z" />
          <path d="M306.847 50.6999C306.847 52.2498 305.59 53.5066 304.04 53.5066C302.49 53.5066 301.233 52.2498 301.233 50.6999C301.233 49.1499 302.49 47.8931 304.04 47.8931C305.59 47.8931 306.847 49.1499 306.847 50.6999Z" />
          <path d="M306.847 57.5417C306.847 59.0916 305.59 60.3484 304.04 60.3484C302.49 60.3484 301.233 59.0916 301.233 57.5417C301.233 55.9917 302.49 54.7349 304.04 54.7349C305.59 54.7349 306.847 55.9917 306.847 57.5417Z" />
          <path d="M306.847 64.3832C306.847 65.9331 305.59 67.1899 304.04 67.1899C302.49 67.1899 301.233 65.9331 301.233 64.3832C301.233 62.8333 302.49 61.5765 304.04 61.5765C305.59 61.5765 306.847 62.8333 306.847 64.3832Z" />
          <path d="M306.847 71.2255C306.847 72.7754 305.59 74.0322 304.04 74.0322C302.49 74.0322 301.233 72.7754 301.233 71.2255C301.233 69.6756 302.49 68.4188 304.04 68.4188C305.59 68.4188 306.847 69.6756 306.847 71.2255Z" />
          <path d="M306.847 78.0673C306.847 79.6172 305.59 80.874 304.04 80.874C302.49 80.874 301.233 79.6172 301.233 78.0673C301.233 76.5173 302.49 75.2606 304.04 75.2606C305.59 75.2606 306.847 76.5173 306.847 78.0673Z" />
          <path d="M306.847 84.9091C306.847 86.459 305.59 87.7158 304.04 87.7158C302.49 87.7158 301.233 86.459 301.233 84.9091C301.233 83.3591 302.49 82.1024 304.04 82.1024C305.59 82.1024 306.847 83.3591 306.847 84.9091Z" />
          <path d="M306.847 91.7506C306.847 93.3006 305.59 94.5574 304.04 94.5574C302.49 94.5574 301.233 93.3006 301.233 91.7506C301.233 90.2007 302.49 88.9439 304.04 88.9439C305.59 88.9439 306.847 90.2007 306.847 91.7506Z" />
          <path d="M313.689 91.7506C313.689 93.3006 312.432 94.5574 310.882 94.5574C309.332 94.5574 308.075 93.3006 308.075 91.7506C308.075 90.2007 309.332 88.9439 310.882 88.9439C312.432 88.9439 313.689 90.2007 313.689 91.7506Z" />
          <path d="M313.689 98.5927C313.689 100.143 312.432 101.399 310.882 101.399C309.332 101.399 308.075 100.143 308.075 98.5927C308.075 97.0427 309.332 95.7859 310.882 95.7859C312.432 95.7859 313.689 97.0427 313.689 98.5927Z" />
          <path d="M313.689 105.435C313.689 106.985 312.432 108.241 310.882 108.241C309.332 108.241 308.075 106.985 308.075 105.435C308.075 103.885 309.332 102.628 310.882 102.628C312.432 102.628 313.689 103.885 313.689 105.435Z" />
          <path d="M306.847 105.435C306.847 106.985 305.59 108.241 304.04 108.241C302.49 108.241 301.233 106.985 301.233 105.435C301.233 103.885 302.49 102.628 304.04 102.628C305.59 102.628 306.847 103.885 306.847 105.435Z" />
          <path d="M306.847 112.277C306.847 113.826 305.59 115.083 304.04 115.083C302.49 115.083 301.233 113.826 301.233 112.277C301.233 110.727 302.49 109.47 304.04 109.47C305.59 109.47 306.847 110.727 306.847 112.277Z" />
          <path d="M306.847 119.118C306.847 120.668 305.59 121.925 304.04 121.925C302.49 121.925 301.233 120.668 301.233 119.118C301.233 117.568 302.49 116.312 304.04 116.312C305.59 116.312 306.847 117.568 306.847 119.118Z" />
          <path d="M306.847 125.96C306.847 127.51 305.59 128.767 304.04 128.767C302.49 128.767 301.233 127.51 301.233 125.96C301.233 124.41 302.49 123.153 304.04 123.153C305.59 123.153 306.847 124.41 306.847 125.96Z" />
          <path d="M306.847 132.802C306.847 134.352 305.59 135.608 304.04 135.608C302.49 135.608 301.233 134.352 301.233 132.802C301.233 131.252 302.49 129.995 304.04 129.995C305.59 129.995 306.847 131.252 306.847 132.802Z" />
          <path d="M313.689 132.802C313.689 134.352 312.432 135.608 310.882 135.608C309.332 135.608 308.075 134.352 308.075 132.802C308.075 131.252 309.332 129.995 310.882 129.995C312.432 129.995 313.689 131.252 313.689 132.802Z" />
          <path d="M313.689 125.96C313.689 127.51 312.432 128.767 310.882 128.767C309.332 128.767 308.075 127.51 308.075 125.96C308.075 124.41 309.332 123.153 310.882 123.153C312.432 123.153 313.689 124.41 313.689 125.96Z" />
          <path d="M313.689 119.118C313.689 120.668 312.432 121.925 310.882 121.925C309.332 121.925 308.075 120.668 308.075 119.118C308.075 117.568 309.332 116.312 310.882 116.312C312.432 116.312 313.689 117.568 313.689 119.118Z" />
          <path d="M320.531 119.118C320.531 120.668 319.274 121.925 317.724 121.925C316.174 121.925 314.917 120.668 314.917 119.118C314.917 117.568 316.174 116.312 317.724 116.312C319.274 116.312 320.531 117.568 320.531 119.118Z" />
          <path d="M320.531 112.277C320.531 113.826 319.274 115.083 317.724 115.083C316.174 115.083 314.917 113.826 314.917 112.277C314.917 110.727 316.174 109.47 317.724 109.47C319.274 109.47 320.531 110.727 320.531 112.277Z" />
          <path d="M327.372 112.277C327.372 113.826 326.116 115.083 324.566 115.083C323.016 115.083 321.759 113.826 321.759 112.277C321.759 110.727 323.016 109.47 324.566 109.47C326.116 109.47 327.372 110.727 327.372 112.277Z" />
          <path d="M334.214 112.277C334.214 113.826 332.957 115.083 331.407 115.083C329.857 115.083 328.601 113.826 328.601 112.277C328.601 110.727 329.857 109.47 331.407 109.47C332.957 109.47 334.214 110.727 334.214 112.277Z" />
          <path d="M334.214 105.435C334.214 106.985 332.957 108.241 331.407 108.241C329.857 108.241 328.601 106.985 328.601 105.435C328.601 103.885 329.857 102.628 331.407 102.628C332.957 102.628 334.214 103.885 334.214 105.435Z" />
          <path d="M341.056 105.435C341.056 106.985 339.799 108.241 338.249 108.241C336.699 108.241 335.443 106.985 335.443 105.435C335.443 103.885 336.699 102.628 338.249 102.628C339.799 102.628 341.056 103.885 341.056 105.435Z" />
          <path d="M341.056 98.5927C341.056 100.143 339.799 101.399 338.249 101.399C336.699 101.399 335.443 100.143 335.443 98.5927C335.443 97.0427 336.699 95.7859 338.249 95.7859C339.799 95.7859 341.056 97.0427 341.056 98.5927Z" />
          <path d="M347.898 98.5927C347.898 100.143 346.641 101.399 345.091 101.399C343.541 101.399 342.284 100.143 342.284 98.5927C342.284 97.0427 343.541 95.7859 345.091 95.7859C346.641 95.7859 347.898 97.0427 347.898 98.5927Z" />
          <path d="M347.898 91.7506C347.898 93.3006 346.641 94.5574 345.091 94.5574C343.541 94.5574 342.284 93.3006 342.284 91.7506C342.284 90.2007 343.541 88.9439 345.091 88.9439C346.641 88.9439 347.898 90.2007 347.898 91.7506Z" />
          <path d="M347.898 84.9091C347.898 86.459 346.641 87.7158 345.091 87.7158C343.541 87.7158 342.284 86.459 342.284 84.9091C342.284 83.3591 343.541 82.1024 345.091 82.1024C346.641 82.1024 347.898 83.3591 347.898 84.9091Z" />
          <path d="M354.74 84.9091C354.74 86.459 353.483 87.7158 351.933 87.7158C350.383 87.7158 349.126 86.459 349.126 84.9091C349.126 83.3591 350.383 82.1024 351.933 82.1024C353.483 82.1024 354.74 83.3591 354.74 84.9091Z" />
          <path d="M361.582 91.7506C361.582 93.3006 360.325 94.5574 358.775 94.5574C357.225 94.5574 355.968 93.3006 355.968 91.7506C355.968 90.2007 357.225 88.9439 358.775 88.9439C360.325 88.9439 361.582 90.2007 361.582 91.7506Z" />
          <path d="M354.74 78.0673C354.74 79.6172 353.483 80.874 351.933 80.874C350.383 80.874 349.126 79.6172 349.126 78.0673C349.126 76.5173 350.383 75.2606 351.933 75.2606C353.483 75.2606 354.74 76.5173 354.74 78.0673Z" />
          <path d="M354.74 71.2255C354.74 72.7754 353.483 74.0322 351.933 74.0322C350.383 74.0322 349.126 72.7754 349.126 71.2255C349.126 69.6756 350.383 68.4188 351.933 68.4188C353.483 68.4188 354.74 69.6756 354.74 71.2255Z" />
          <path d="M361.582 71.2255C361.582 72.7754 360.325 74.0322 358.775 74.0322C357.225 74.0322 355.968 72.7754 355.968 71.2255C355.968 69.6756 357.225 68.4188 358.775 68.4188C360.325 68.4188 361.582 69.6756 361.582 71.2255Z" />
          <path d="M361.582 64.3832C361.582 65.9331 360.325 67.1899 358.775 67.1899C357.225 67.1899 355.968 65.9331 355.968 64.3832C355.968 62.8333 357.225 61.5765 358.775 61.5765C360.325 61.5765 361.582 62.8333 361.582 64.3832Z" />
          <path d="M361.582 57.5417C361.582 59.0916 360.325 60.3484 358.775 60.3484C357.225 60.3484 355.968 59.0916 355.968 57.5417C355.968 55.9917 357.225 54.7349 358.775 54.7349C360.325 54.7349 361.582 55.9917 361.582 57.5417Z" />
          <path d="M368.424 57.5417C368.424 59.0916 367.167 60.3484 365.617 60.3484C364.067 60.3484 362.81 59.0916 362.81 57.5417C362.81 55.9917 364.067 54.7349 365.617 54.7349C367.167 54.7349 368.424 55.9917 368.424 57.5417Z" />
          <path d="M368.424 50.6999C368.424 52.2498 367.167 53.5066 365.617 53.5066C364.067 53.5066 362.81 52.2498 362.81 50.6999C362.81 49.1499 364.067 47.8931 365.617 47.8931C367.167 47.8931 368.424 49.1499 368.424 50.6999Z" />
          <path d="M368.424 43.8581C368.424 45.408 367.167 46.6648 365.617 46.6648C364.067 46.6648 362.81 45.408 362.81 43.8581C362.81 42.3081 364.067 41.0513 365.617 41.0513C367.167 41.0513 368.424 42.3081 368.424 43.8581Z" />
          <path d="M375.265 43.8581C375.265 45.408 374.009 46.6648 372.459 46.6648C370.909 46.6648 369.652 45.408 369.652 43.8581C369.652 42.3081 370.909 41.0513 372.459 41.0513C374.009 41.0513 375.265 42.3081 375.265 43.8581Z" />
          <path d="M361.582 43.8581C361.582 45.408 360.325 46.6648 358.775 46.6648C357.225 46.6648 355.968 45.408 355.968 43.8581C355.968 42.3081 357.225 41.0513 358.775 41.0513C360.325 41.0513 361.582 42.3081 361.582 43.8581Z" />
          <path d="M361.582 37.0163C361.582 38.5662 360.325 39.823 358.775 39.823C357.225 39.823 355.968 38.5662 355.968 37.0163C355.968 35.4663 357.225 34.2095 358.775 34.2095C360.325 34.2095 361.582 35.4663 361.582 37.0163Z" />
          <path d="M354.74 37.0163C354.74 38.5662 353.483 39.823 351.933 39.823C350.383 39.823 349.126 38.5662 349.126 37.0163C349.126 35.4663 350.383 34.2095 351.933 34.2095C353.483 34.2095 354.74 35.4663 354.74 37.0163Z" />
          <path d="M347.898 37.0163C347.898 38.5662 346.641 39.823 345.091 39.823C343.541 39.823 342.284 38.5662 342.284 37.0163C342.284 35.4663 343.541 34.2095 345.091 34.2095C346.641 34.2095 347.898 35.4663 347.898 37.0163Z" />
          <path d="M347.898 30.1742C347.898 31.7242 346.641 32.981 345.091 32.981C343.541 32.981 342.284 31.7242 342.284 30.1742C342.284 28.6243 343.541 27.3675 345.091 27.3675C346.641 27.3675 347.898 28.6243 347.898 30.1742Z" />
          <path d="M341.056 30.1742C341.056 31.7242 339.799 32.981 338.249 32.981C336.699 32.981 335.443 31.7242 335.443 30.1742C335.443 28.6243 336.699 27.3675 338.249 27.3675C339.799 27.3675 341.056 28.6243 341.056 30.1742Z" />
          <path d="M334.214 30.1742C334.214 31.7242 332.957 32.981 331.407 32.981C329.857 32.981 328.601 31.7242 328.601 30.1742C328.601 28.6243 329.857 27.3675 331.407 27.3675C332.957 27.3675 334.214 28.6243 334.214 30.1742Z" />
          <path d="M327.372 30.1742C327.372 31.7242 326.116 32.981 324.566 32.981C323.016 32.981 321.759 31.7242 321.759 30.1742C321.759 28.6243 323.016 27.3675 324.566 27.3675C326.116 27.3675 327.372 28.6243 327.372 30.1742Z" />
          <path d="M327.372 37.0163C327.372 38.5662 326.116 39.823 324.566 39.823C323.016 39.823 321.759 38.5662 321.759 37.0163C321.759 35.4663 323.016 34.2095 324.566 34.2095C326.116 34.2095 327.372 35.4663 327.372 37.0163Z" />
          <path d="M320.531 37.0163C320.531 38.5662 319.274 39.823 317.724 39.823C316.174 39.823 314.917 38.5662 314.917 37.0163C314.917 35.4663 316.174 34.2095 317.724 34.2095C319.274 34.2095 320.531 35.4663 320.531 37.0163Z" />
          <path d="M320.531 43.8581C320.531 45.408 319.274 46.6648 317.724 46.6648C316.174 46.6648 314.917 45.408 314.917 43.8581C314.917 42.3081 316.174 41.0513 317.724 41.0513C319.274 41.0513 320.531 42.3081 320.531 43.8581Z" />
          <path d="M320.531 50.6999C320.531 52.2498 319.274 53.5066 317.724 53.5066C316.174 53.5066 314.917 52.2498 314.917 50.6999C314.917 49.1499 316.174 47.8931 317.724 47.8931C319.274 47.8931 320.531 49.1499 320.531 50.6999Z" />
          <path d="M313.689 50.6999C313.689 52.2498 312.432 53.5066 310.882 53.5066C309.332 53.5066 308.075 52.2498 308.075 50.6999C308.075 49.1499 309.332 47.8931 310.882 47.8931C312.432 47.8931 313.689 49.1499 313.689 50.6999Z" />
          <path d="M313.689 57.5417C313.689 59.0916 312.432 60.3484 310.882 60.3484C309.332 60.3484 308.075 59.0916 308.075 57.5417C308.075 55.9917 309.332 54.7349 310.882 54.7349C312.432 54.7349 313.689 55.9917 313.689 57.5417Z" />
          <path d="M313.689 64.3832C313.689 65.9331 312.432 67.1899 310.882 67.1899C309.332 67.1899 308.075 65.9331 308.075 64.3832C308.075 62.8333 309.332 61.5765 310.882 61.5765C312.432 61.5765 313.689 62.8333 313.689 64.3832Z" />
          <path d="M313.689 71.2255C313.689 72.7754 312.432 74.0322 310.882 74.0322C309.332 74.0322 308.075 72.7754 308.075 71.2255C308.075 69.6756 309.332 68.4188 310.882 68.4188C312.432 68.4188 313.689 69.6756 313.689 71.2255Z" />
          <path d="M313.689 78.0673C313.689 79.6172 312.432 80.874 310.882 80.874C309.332 80.874 308.075 79.6172 308.075 78.0673C308.075 76.5173 309.332 75.2606 310.882 75.2606C312.432 75.2606 313.689 76.5173 313.689 78.0673Z" />
          <path d="M313.689 84.9091C313.689 86.459 312.432 87.7158 310.882 87.7158C309.332 87.7158 308.075 86.459 308.075 84.9091C308.075 83.3591 309.332 82.1024 310.882 82.1024C312.432 82.1024 313.689 83.3591 313.689 84.9091Z" />
          <path d="M320.531 84.9091C320.531 86.459 319.274 87.7158 317.724 87.7158C316.174 87.7158 314.917 86.459 314.917 84.9091C314.917 83.3591 316.174 82.1024 317.724 82.1024C319.274 82.1024 320.531 83.3591 320.531 84.9091Z" />
          <path d="M320.531 91.7506C320.531 93.3006 319.274 94.5574 317.724 94.5574C316.174 94.5574 314.917 93.3006 314.917 91.7506C314.917 90.2007 316.174 88.9439 317.724 88.9439C319.274 88.9439 320.531 90.2007 320.531 91.7506Z" />
          <path d="M320.531 98.5927C320.531 100.143 319.274 101.399 317.724 101.399C316.174 101.399 314.917 100.143 314.917 98.5927C314.917 97.0427 316.174 95.7859 317.724 95.7859C319.274 95.7859 320.531 97.0427 320.531 98.5927Z" />
          <path d="M320.531 105.435C320.531 106.985 319.274 108.241 317.724 108.241C316.174 108.241 314.917 106.985 314.917 105.435C314.917 103.885 316.174 102.628 317.724 102.628C319.274 102.628 320.531 103.885 320.531 105.435Z" />
          <path d="M327.372 105.435C327.372 106.985 326.116 108.241 324.566 108.241C323.016 108.241 321.759 106.985 321.759 105.435C321.759 103.885 323.016 102.628 324.566 102.628C326.116 102.628 327.372 103.885 327.372 105.435Z" />
          <path d="M327.372 98.5927C327.372 100.143 326.116 101.399 324.566 101.399C323.016 101.399 321.759 100.143 321.759 98.5927C321.759 97.0427 323.016 95.7859 324.566 95.7859C326.116 95.7859 327.372 97.0427 327.372 98.5927Z" />
          <path d="M334.214 98.5927C334.214 100.143 332.957 101.399 331.407 101.399C329.857 101.399 328.601 100.143 328.601 98.5927C328.601 97.0427 329.857 95.7859 331.407 95.7859C332.957 95.7859 334.214 97.0427 334.214 98.5927Z" />
          <path d="M334.214 91.7506C334.214 93.3006 332.957 94.5574 331.407 94.5574C329.857 94.5574 328.601 93.3006 328.601 91.7506C328.601 90.2007 329.857 88.9439 331.407 88.9439C332.957 88.9439 334.214 90.2007 334.214 91.7506Z" />
          <path d="M341.056 91.7506C341.056 93.3006 339.799 94.5574 338.249 94.5574C336.699 94.5574 335.443 93.3006 335.443 91.7506C335.443 90.2007 336.699 88.9439 338.249 88.9439C339.799 88.9439 341.056 90.2007 341.056 91.7506Z" />
          <path d="M341.056 84.9091C341.056 86.459 339.799 87.7158 338.249 87.7158C336.699 87.7158 335.443 86.459 335.443 84.9091C335.443 83.3591 336.699 82.1024 338.249 82.1024C339.799 82.1024 341.056 83.3591 341.056 84.9091Z" />
          <path d="M341.056 78.0673C341.056 79.6172 339.799 80.874 338.249 80.874C336.699 80.874 335.443 79.6172 335.443 78.0673C335.443 76.5173 336.699 75.2606 338.249 75.2606C339.799 75.2606 341.056 76.5173 341.056 78.0673Z" />
          <path d="M341.056 71.2255C341.056 72.7754 339.799 74.0322 338.249 74.0322C336.699 74.0322 335.443 72.7754 335.443 71.2255C335.443 69.6756 336.699 68.4188 338.249 68.4188C339.799 68.4188 341.056 69.6756 341.056 71.2255Z" />
          <path d="M347.898 71.2255C347.898 72.7754 346.641 74.0322 345.091 74.0322C343.541 74.0322 342.284 72.7754 342.284 71.2255C342.284 69.6756 343.541 68.4188 345.091 68.4188C346.641 68.4188 347.898 69.6756 347.898 71.2255Z" />
          <path d="M347.898 64.3832C347.898 65.9331 346.641 67.1899 345.091 67.1899C343.541 67.1899 342.284 65.9331 342.284 64.3832C342.284 62.8333 343.541 61.5765 345.091 61.5765C346.641 61.5765 347.898 62.8333 347.898 64.3832Z" />
          <path d="M354.74 64.3832C354.74 65.9331 353.483 67.1899 351.933 67.1899C350.383 67.1899 349.126 65.9331 349.126 64.3832C349.126 62.8333 350.383 61.5765 351.933 61.5765C353.483 61.5765 354.74 62.8333 354.74 64.3832Z" />
          <path d="M354.74 57.5417C354.74 59.0916 353.483 60.3484 351.933 60.3484C350.383 60.3484 349.126 59.0916 349.126 57.5417C349.126 55.9917 350.383 54.7349 351.933 54.7349C353.483 54.7349 354.74 55.9917 354.74 57.5417Z" />
          <path d="M354.74 50.6999C354.74 52.2498 353.483 53.5066 351.933 53.5066C350.383 53.5066 349.126 52.2498 349.126 50.6999C349.126 49.1499 350.383 47.8931 351.933 47.8931C353.483 47.8931 354.74 49.1499 354.74 50.6999Z" />
          <path d="M354.74 43.8581C354.74 45.408 353.483 46.6648 351.933 46.6648C350.383 46.6648 349.126 45.408 349.126 43.8581C349.126 42.3081 350.383 41.0513 351.933 41.0513C353.483 41.0513 354.74 42.3081 354.74 43.8581Z" />
          <path d="M347.898 43.8581C347.898 45.408 346.641 46.6648 345.091 46.6648C343.541 46.6648 342.284 45.408 342.284 43.8581C342.284 42.3081 343.541 41.0513 345.091 41.0513C346.641 41.0513 347.898 42.3081 347.898 43.8581Z" />
          <path d="M341.056 43.8581C341.056 45.408 339.799 46.6648 338.249 46.6648C336.699 46.6648 335.443 45.408 335.443 43.8581C335.443 42.3081 336.699 41.0513 338.249 41.0513C339.799 41.0513 341.056 42.3081 341.056 43.8581Z" />
          <path d="M341.056 37.0163C341.056 38.5662 339.799 39.823 338.249 39.823C336.699 39.823 335.443 38.5662 335.443 37.0163C335.443 35.4663 336.699 34.2095 338.249 34.2095C339.799 34.2095 341.056 35.4663 341.056 37.0163Z" />
          <path d="M334.214 37.0163C334.214 38.5662 332.957 39.823 331.407 39.823C329.857 39.823 328.601 38.5662 328.601 37.0163C328.601 35.4663 329.857 34.2095 331.407 34.2095C332.957 34.2095 334.214 35.4663 334.214 37.0163Z" />
          <path d="M334.214 43.8581C334.214 45.408 332.957 46.6648 331.407 46.6648C329.857 46.6648 328.601 45.408 328.601 43.8581C328.601 42.3081 329.857 41.0513 331.407 41.0513C332.957 41.0513 334.214 42.3081 334.214 43.8581Z" />
          <path d="M327.372 43.8581C327.372 45.408 326.116 46.6648 324.566 46.6648C323.016 46.6648 321.759 45.408 321.759 43.8581C321.759 42.3081 323.016 41.0513 324.566 41.0513C326.116 41.0513 327.372 42.3081 327.372 43.8581Z" />
          <path d="M327.372 50.6999C327.372 52.2498 326.116 53.5066 324.566 53.5066C323.016 53.5066 321.759 52.2498 321.759 50.6999C321.759 49.1499 323.016 47.8931 324.566 47.8931C326.116 47.8931 327.372 49.1499 327.372 50.6999Z" />
          <path d="M327.372 57.5417C327.372 59.0916 326.116 60.3484 324.566 60.3484C323.016 60.3484 321.759 59.0916 321.759 57.5417C321.759 55.9917 323.016 54.7349 324.566 54.7349C326.116 54.7349 327.372 55.9917 327.372 57.5417Z" />
          <path d="M320.531 57.5417C320.531 59.0916 319.274 60.3484 317.724 60.3484C316.174 60.3484 314.917 59.0916 314.917 57.5417C314.917 55.9917 316.174 54.7349 317.724 54.7349C319.274 54.7349 320.531 55.9917 320.531 57.5417Z" />
          <path d="M320.531 64.3832C320.531 65.9331 319.274 67.1899 317.724 67.1899C316.174 67.1899 314.917 65.9331 314.917 64.3832C314.917 62.8333 316.174 61.5765 317.724 61.5765C319.274 61.5765 320.531 62.8333 320.531 64.3832Z" />
          <path d="M320.531 71.2255C320.531 72.7754 319.274 74.0322 317.724 74.0322C316.174 74.0322 314.917 72.7754 314.917 71.2255C314.917 69.6756 316.174 68.4188 317.724 68.4188C319.274 68.4188 320.531 69.6756 320.531 71.2255Z" />
          <path d="M320.531 78.0673C320.531 79.6172 319.274 80.874 317.724 80.874C316.174 80.874 314.917 79.6172 314.917 78.0673C314.917 76.5173 316.174 75.2606 317.724 75.2606C319.274 75.2606 320.531 76.5173 320.531 78.0673Z" />
          <path d="M327.372 78.0673C327.372 79.6172 326.116 80.874 324.566 80.874C323.016 80.874 321.759 79.6172 321.759 78.0673C321.759 76.5173 323.016 75.2606 324.566 75.2606C326.116 75.2606 327.372 76.5173 327.372 78.0673Z" />
          <path d="M327.372 84.9091C327.372 86.459 326.116 87.7158 324.566 87.7158C323.016 87.7158 321.759 86.459 321.759 84.9091C321.759 83.3591 323.016 82.1024 324.566 82.1024C326.116 82.1024 327.372 83.3591 327.372 84.9091Z" />
          <path d="M334.214 84.9091C334.214 86.459 332.957 87.7158 331.407 87.7158C329.857 87.7158 328.601 86.459 328.601 84.9091C328.601 83.3591 329.857 82.1024 331.407 82.1024C332.957 82.1024 334.214 83.3591 334.214 84.9091Z" />
          <path d="M334.214 78.0673C334.214 79.6172 332.957 80.874 331.407 80.874C329.857 80.874 328.601 79.6172 328.601 78.0673C328.601 76.5173 329.857 75.2606 331.407 75.2606C332.957 75.2606 334.214 76.5173 334.214 78.0673Z" />
          <path d="M334.214 71.2255C334.214 72.7754 332.957 74.0322 331.407 74.0322C329.857 74.0322 328.601 72.7754 328.601 71.2255C328.601 69.6756 329.857 68.4188 331.407 68.4188C332.957 68.4188 334.214 69.6756 334.214 71.2255Z" />
          <path d="M334.214 64.3832C334.214 65.9331 332.957 67.1899 331.407 67.1899C329.857 67.1899 328.601 65.9331 328.601 64.3832C328.601 62.8333 329.857 61.5765 331.407 61.5765C332.957 61.5765 334.214 62.8333 334.214 64.3832Z" />
          <path d="M341.056 64.3832C341.056 65.9331 339.799 67.1899 338.249 67.1899C336.699 67.1899 335.443 65.9331 335.443 64.3832C335.443 62.8333 336.699 61.5765 338.249 61.5765C339.799 61.5765 341.056 62.8333 341.056 64.3832Z" />
          <path d="M341.056 57.5417C341.056 59.0916 339.799 60.3484 338.249 60.3484C336.699 60.3484 335.443 59.0916 335.443 57.5417C335.443 55.9917 336.699 54.7349 338.249 54.7349C339.799 54.7349 341.056 55.9917 341.056 57.5417Z" />
          <path d="M347.898 57.5417C347.898 59.0916 346.641 60.3484 345.091 60.3484C343.541 60.3484 342.284 59.0916 342.284 57.5417C342.284 55.9917 343.541 54.7349 345.091 54.7349C346.641 54.7349 347.898 55.9917 347.898 57.5417Z" />
          <path d="M347.898 50.6999C347.898 52.2498 346.641 53.5066 345.091 53.5066C343.541 53.5066 342.284 52.2498 342.284 50.6999C342.284 49.1499 343.541 47.8931 345.091 47.8931C346.641 47.8931 347.898 49.1499 347.898 50.6999Z" />
          <path d="M341.056 50.6999C341.056 52.2498 339.799 53.5066 338.249 53.5066C336.699 53.5066 335.443 52.2498 335.443 50.6999C335.443 49.1499 336.699 47.8931 338.249 47.8931C339.799 47.8931 341.056 49.1499 341.056 50.6999Z" />
          <path d="M334.214 50.6999C334.214 52.2498 332.957 53.5066 331.407 53.5066C329.857 53.5066 328.601 52.2498 328.601 50.6999C328.601 49.1499 329.857 47.8931 331.407 47.8931C332.957 47.8931 334.214 49.1499 334.214 50.6999Z" />
          <path d="M334.214 57.5417C334.214 59.0916 332.957 60.3484 331.407 60.3484C329.857 60.3484 328.601 59.0916 328.601 57.5417C328.601 55.9917 329.857 54.7349 331.407 54.7349C332.957 54.7349 334.214 55.9917 334.214 57.5417Z" />
          <path d="M327.372 64.3832C327.372 65.9331 326.116 67.1899 324.566 67.1899C323.016 67.1899 321.759 65.9331 321.759 64.3832C321.759 62.8333 323.016 61.5765 324.566 61.5765C326.116 61.5765 327.372 62.8333 327.372 64.3832Z" />
          <path d="M327.372 71.2255C327.372 72.7754 326.116 74.0322 324.566 74.0322C323.016 74.0322 321.759 72.7754 321.759 71.2255C321.759 69.6756 323.016 68.4188 324.566 68.4188C326.116 68.4188 327.372 69.6756 327.372 71.2255Z" />
          <path d="M327.372 91.7506C327.372 93.3006 326.116 94.5574 324.566 94.5574C323.016 94.5574 321.759 93.3006 321.759 91.7506C321.759 90.2007 323.016 88.9439 324.566 88.9439C326.116 88.9439 327.372 90.2007 327.372 91.7506Z" />
          <path d="M361.582 50.6999C361.582 52.2498 360.325 53.5066 358.775 53.5066C357.225 53.5066 355.968 52.2498 355.968 50.6999C355.968 49.1499 357.225 47.8931 358.775 47.8931C360.325 47.8931 361.582 49.1499 361.582 50.6999Z" />
          <path d="M347.898 78.0673C347.898 79.6172 346.641 80.874 345.091 80.874C343.541 80.874 342.284 79.6172 342.284 78.0673C342.284 76.5173 343.541 75.2606 345.091 75.2606C346.641 75.2606 347.898 76.5173 347.898 78.0673Z" />
          <path d="M368.424 37.0163C368.424 38.5662 367.167 39.823 365.617 39.823C364.067 39.823 362.81 38.5662 362.81 37.0163C362.81 35.4663 364.067 34.2095 365.617 34.2095C367.167 34.2095 368.424 35.4663 368.424 37.0163Z" />
          <path d="M375.265 37.0163C375.265 38.5662 374.009 39.823 372.459 39.823C370.909 39.823 369.652 38.5662 369.652 37.0163C369.652 35.4663 370.909 34.2095 372.459 34.2095C374.009 34.2095 375.265 35.4663 375.265 37.0163Z" />
          <path d="M368.424 71.2255C368.424 72.7754 367.167 74.0322 365.617 74.0322C364.067 74.0322 362.81 72.7754 362.81 71.2255C362.81 69.6756 364.067 68.4188 365.617 68.4188C367.167 68.4188 368.424 69.6756 368.424 71.2255Z" />
          <path d="M354.74 98.5927C354.74 100.143 353.483 101.399 351.933 101.399C350.383 101.399 349.126 100.143 349.126 98.5927C349.126 97.0427 350.383 95.7859 351.933 95.7859C353.483 95.7859 354.74 97.0427 354.74 98.5927Z" />
          <path d="M361.582 119.118C361.582 120.668 360.325 121.925 358.775 121.925C357.225 121.925 355.968 120.668 355.968 119.118C355.968 117.568 357.225 116.312 358.775 116.312C360.325 116.312 361.582 117.568 361.582 119.118Z" />
          <path d="M368.424 125.96C368.424 127.51 367.167 128.767 365.617 128.767C364.067 128.767 362.81 127.51 362.81 125.96C362.81 124.41 364.067 123.153 365.617 123.153C367.167 123.153 368.424 124.41 368.424 125.96Z" />
          <path d="M375.265 119.118C375.265 120.668 374.009 121.925 372.459 121.925C370.909 121.925 369.652 120.668 369.652 119.118C369.652 117.568 370.909 116.312 372.459 116.312C374.009 116.312 375.265 117.568 375.265 119.118Z" />
          <path d="M388.949 167.011C388.949 168.561 387.692 169.818 386.142 169.818C384.592 169.818 383.335 168.561 383.335 167.011C383.335 165.461 384.592 164.204 386.142 164.204C387.692 164.204 388.949 165.461 388.949 167.011Z" />
          <path d="M402.633 167.011C402.633 168.561 401.376 169.818 399.826 169.818C398.276 169.818 397.019 168.561 397.019 167.011C397.019 165.461 398.276 164.204 399.826 164.204C401.376 164.204 402.633 165.461 402.633 167.011Z" />
          <path d="M402.633 160.169C402.633 161.719 401.376 162.976 399.826 162.976C398.276 162.976 397.019 161.719 397.019 160.169C397.019 158.619 398.276 157.363 399.826 157.363C401.376 157.363 402.633 158.619 402.633 160.169Z" />
          <path d="M395.791 153.328C395.791 154.877 394.534 156.134 392.984 156.134C391.434 156.134 390.177 154.877 390.177 153.328C390.177 151.778 391.434 150.521 392.984 150.521C394.534 150.521 395.791 151.778 395.791 153.328Z" />
          <path d="M402.633 173.853C402.633 175.403 401.376 176.66 399.826 176.66C398.276 176.66 397.019 175.403 397.019 173.853C397.019 172.303 398.276 171.046 399.826 171.046C401.376 171.046 402.633 172.303 402.633 173.853Z" />
          <path d="M409.474 173.853C409.474 175.403 408.218 176.66 406.668 176.66C405.118 176.66 403.861 175.403 403.861 173.853C403.861 172.303 405.118 171.046 406.668 171.046C408.218 171.046 409.474 172.303 409.474 173.853Z" />
          <path d="M409.474 187.537C409.474 189.087 408.218 190.344 406.668 190.344C405.118 190.344 403.861 189.087 403.861 187.537C403.861 185.987 405.118 184.73 406.668 184.73C408.218 184.73 409.474 185.987 409.474 187.537Z" />
          <path d="M402.633 187.537C402.633 189.087 401.376 190.344 399.826 190.344C398.276 190.344 397.019 189.087 397.019 187.537C397.019 185.987 398.276 184.73 399.826 184.73C401.376 184.73 402.633 185.987 402.633 187.537Z" />
          <path d="M402.633 201.22C402.633 202.77 401.376 204.027 399.826 204.027C398.276 204.027 397.019 202.77 397.019 201.22C397.019 199.67 398.276 198.413 399.826 198.413C401.376 198.413 402.633 199.67 402.633 201.22Z" />
          <path d="M395.791 201.22C395.791 202.77 394.534 204.027 392.984 204.027C391.434 204.027 390.177 202.77 390.177 201.22C390.177 199.67 391.434 198.413 392.984 198.413C394.534 198.413 395.791 199.67 395.791 201.22Z" />
          <path d="M395.791 208.062C395.791 209.612 394.534 210.869 392.984 210.869C391.434 210.869 390.177 209.612 390.177 208.062C390.177 206.512 391.434 205.256 392.984 205.256C394.534 205.256 395.791 206.512 395.791 208.062Z" />
          <path d="M388.949 208.062C388.949 209.612 387.692 210.869 386.142 210.869C384.592 210.869 383.335 209.612 383.335 208.062C383.335 206.512 384.592 205.256 386.142 205.256C387.692 205.256 388.949 206.512 388.949 208.062Z" />
          <path d="M388.949 214.904C388.949 216.454 387.692 217.711 386.142 217.711C384.592 217.711 383.335 216.454 383.335 214.904C383.335 213.354 384.592 212.097 386.142 212.097C387.692 212.097 388.949 213.354 388.949 214.904Z" />
          <path d="M395.791 214.904C395.791 216.454 394.534 217.711 392.984 217.711C391.434 217.711 390.177 216.454 390.177 214.904C390.177 213.354 391.434 212.097 392.984 212.097C394.534 212.097 395.791 213.354 395.791 214.904Z" />
          <path d="M395.791 221.746C395.791 223.296 394.534 224.553 392.984 224.553C391.434 224.553 390.177 223.296 390.177 221.746C390.177 220.196 391.434 218.939 392.984 218.939C394.534 218.939 395.791 220.196 395.791 221.746Z" />
          <path d="M402.633 214.904C402.633 216.454 401.376 217.711 399.826 217.711C398.276 217.711 397.019 216.454 397.019 214.904C397.019 213.354 398.276 212.097 399.826 212.097C401.376 212.097 402.633 213.354 402.633 214.904Z" />
          <path d="M402.633 208.062C402.633 209.612 401.376 210.869 399.826 210.869C398.276 210.869 397.019 209.612 397.019 208.062C397.019 206.512 398.276 205.256 399.826 205.256C401.376 205.256 402.633 206.512 402.633 208.062Z" />
          <path d="M409.474 208.062C409.474 209.612 408.218 210.869 406.668 210.869C405.118 210.869 403.861 209.612 403.861 208.062C403.861 206.512 405.118 205.256 406.668 205.256C408.218 205.256 409.474 206.512 409.474 208.062Z" />
          <path d="M409.474 201.22C409.474 202.77 408.218 204.027 406.668 204.027C405.118 204.027 403.861 202.77 403.861 201.22C403.861 199.67 405.118 198.413 406.668 198.413C408.218 198.413 409.474 199.67 409.474 201.22Z" />
          <path d="M416.316 194.379C416.316 195.929 415.06 197.185 413.51 197.185C411.96 197.185 410.703 195.929 410.703 194.379C410.703 192.829 411.96 191.572 413.51 191.572C415.06 191.572 416.316 192.829 416.316 194.379Z" />
          <path d="M423.158 194.379C423.158 195.929 421.901 197.185 420.351 197.185C418.801 197.185 417.544 195.929 417.544 194.379C417.544 192.829 418.801 191.572 420.351 191.572C421.901 191.572 423.158 192.829 423.158 194.379Z" />
          <path d="M423.158 187.537C423.158 189.087 421.901 190.344 420.351 190.344C418.801 190.344 417.544 189.087 417.544 187.537C417.544 185.987 418.801 184.73 420.351 184.73C421.901 184.73 423.158 185.987 423.158 187.537Z" />
          <path d="M416.316 187.537C416.316 189.087 415.06 190.344 413.51 190.344C411.96 190.344 410.703 189.087 410.703 187.537C410.703 185.987 411.96 184.73 413.51 184.73C415.06 184.73 416.316 185.987 416.316 187.537Z" />
          <path d="M416.316 180.695C416.316 182.245 415.06 183.502 413.51 183.502C411.96 183.502 410.703 182.245 410.703 180.695C410.703 179.145 411.96 177.888 413.51 177.888C415.06 177.888 416.316 179.145 416.316 180.695Z" />
          <path d="M423.158 180.695C423.158 182.245 421.901 183.502 420.351 183.502C418.801 183.502 417.544 182.245 417.544 180.695C417.544 179.145 418.801 177.888 420.351 177.888C421.901 177.888 423.158 179.145 423.158 180.695Z" />
          <path d="M423.158 173.853C423.158 175.403 421.901 176.66 420.351 176.66C418.801 176.66 417.544 175.403 417.544 173.853C417.544 172.303 418.801 171.046 420.351 171.046C421.901 171.046 423.158 172.303 423.158 173.853Z" />
          <path d="M430 173.853C430 175.403 428.744 176.66 427.194 176.66C425.643 176.66 424.386 175.403 424.386 173.853C424.386 172.303 425.643 171.046 427.194 171.046C428.744 171.046 430 172.303 430 173.853Z" />
          <path d="M436.842 173.853C436.842 175.403 435.585 176.66 434.036 176.66C432.486 176.66 431.228 175.403 431.228 173.853C431.228 172.303 432.486 171.046 434.036 171.046C435.585 171.046 436.842 172.303 436.842 173.853Z" />
          <path d="M436.842 167.011C436.842 168.561 435.585 169.818 434.036 169.818C432.486 169.818 431.228 168.561 431.228 167.011C431.228 165.461 432.486 164.204 434.036 164.204C435.585 164.204 436.842 165.461 436.842 167.011Z" />
          <path d="M443.683 167.011C443.683 168.561 442.427 169.818 440.877 169.818C439.327 169.818 438.07 168.561 438.07 167.011C438.07 165.461 439.327 164.204 440.877 164.204C442.427 164.204 443.683 165.461 443.683 167.011Z" />
          <path d="M443.683 173.853C443.683 175.403 442.427 176.66 440.877 176.66C439.327 176.66 438.07 175.403 438.07 173.853C438.07 172.303 439.327 171.046 440.877 171.046C442.427 171.046 443.683 172.303 443.683 173.853Z" />
          <path d="M443.683 180.695C443.683 182.245 442.427 183.502 440.877 183.502C439.327 183.502 438.07 182.245 438.07 180.695C438.07 179.145 439.327 177.888 440.877 177.888C442.427 177.888 443.683 179.145 443.683 180.695Z" />
          <path d="M436.842 180.695C436.842 182.245 435.585 183.502 434.036 183.502C432.486 183.502 431.228 182.245 431.228 180.695C431.228 179.145 432.486 177.888 434.036 177.888C435.585 177.888 436.842 179.145 436.842 180.695Z" />
          <path d="M443.683 187.537C443.683 189.087 442.427 190.344 440.877 190.344C439.327 190.344 438.07 189.087 438.07 187.537C438.07 185.987 439.327 184.73 440.877 184.73C442.427 184.73 443.683 185.987 443.683 187.537Z" />
          <path d="M450.525 194.379C450.525 195.929 449.269 197.185 447.719 197.185C446.169 197.185 444.912 195.929 444.912 194.379C444.912 192.829 446.169 191.572 447.719 191.572C449.269 191.572 450.525 192.829 450.525 194.379Z" />
          <path d="M450.525 187.537C450.525 189.087 449.269 190.344 447.719 190.344C446.169 190.344 444.912 189.087 444.912 187.537C444.912 185.987 446.169 184.73 447.719 184.73C449.269 184.73 450.525 185.987 450.525 187.537Z" />
          <path d="M450.525 180.695C450.525 182.245 449.269 183.502 447.719 183.502C446.169 183.502 444.912 182.245 444.912 180.695C444.912 179.145 446.169 177.888 447.719 177.888C449.269 177.888 450.525 179.145 450.525 180.695Z" />
          <path d="M450.525 173.853C450.525 175.403 449.269 176.66 447.719 176.66C446.169 176.66 444.912 175.403 444.912 173.853C444.912 172.303 446.169 171.046 447.719 171.046C449.269 171.046 450.525 172.303 450.525 173.853Z" />
          <path d="M457.367 173.853C457.367 175.403 456.111 176.66 454.561 176.66C453.011 176.66 451.754 175.403 451.754 173.853C451.754 172.303 453.011 171.046 454.561 171.046C456.111 171.046 457.367 172.303 457.367 173.853Z" />
          <path d="M457.367 167.011C457.367 168.561 456.111 169.818 454.561 169.818C453.011 169.818 451.754 168.561 451.754 167.011C451.754 165.461 453.011 164.204 454.561 164.204C456.111 164.204 457.367 165.461 457.367 167.011Z" />
          <path d="M457.367 160.169C457.367 161.719 456.111 162.976 454.561 162.976C453.011 162.976 451.754 161.719 451.754 160.169C451.754 158.619 453.011 157.363 454.561 157.363C456.111 157.363 457.367 158.619 457.367 160.169Z" />
          <path d="M457.367 153.328C457.367 154.877 456.111 156.134 454.561 156.134C453.011 156.134 451.754 154.877 451.754 153.328C451.754 151.778 453.011 150.521 454.561 150.521C456.111 150.521 457.367 151.778 457.367 153.328Z" />
          <path d="M464.209 153.328C464.209 154.877 462.953 156.134 461.403 156.134C459.853 156.134 458.595 154.877 458.595 153.328C458.595 151.778 459.853 150.521 461.403 150.521C462.953 150.521 464.209 151.778 464.209 153.328Z" />
          <path d="M464.209 160.169C464.209 161.719 462.953 162.976 461.403 162.976C459.853 162.976 458.595 161.719 458.595 160.169C458.595 158.619 459.853 157.363 461.403 157.363C462.953 157.363 464.209 158.619 464.209 160.169Z" />
          <path d="M464.209 167.011C464.209 168.561 462.953 169.818 461.403 169.818C459.853 169.818 458.595 168.561 458.595 167.011C458.595 165.461 459.853 164.204 461.403 164.204C462.953 164.204 464.209 165.461 464.209 167.011Z" />
          <path d="M464.209 173.853C464.209 175.403 462.953 176.66 461.403 176.66C459.853 176.66 458.595 175.403 458.595 173.853C458.595 172.303 459.853 171.046 461.403 171.046C462.953 171.046 464.209 172.303 464.209 173.853Z" />
          <path d="M464.209 180.695C464.209 182.245 462.953 183.502 461.403 183.502C459.853 183.502 458.595 182.245 458.595 180.695C458.595 179.145 459.853 177.888 461.403 177.888C462.953 177.888 464.209 179.145 464.209 180.695Z" />
          <path d="M457.367 180.695C457.367 182.245 456.111 183.502 454.561 183.502C453.011 183.502 451.754 182.245 451.754 180.695C451.754 179.145 453.011 177.888 454.561 177.888C456.111 177.888 457.367 179.145 457.367 180.695Z" />
          <path d="M457.367 187.537C457.367 189.087 456.111 190.344 454.561 190.344C453.011 190.344 451.754 189.087 451.754 187.537C451.754 185.987 453.011 184.73 454.561 184.73C456.111 184.73 457.367 185.987 457.367 187.537Z" />
          <path d="M457.367 194.379C457.367 195.929 456.111 197.185 454.561 197.185C453.011 197.185 451.754 195.929 451.754 194.379C451.754 192.829 453.011 191.572 454.561 191.572C456.111 191.572 457.367 192.829 457.367 194.379Z" />
          <path d="M464.209 194.379C464.209 195.929 462.953 197.185 461.403 197.185C459.853 197.185 458.595 195.929 458.595 194.379C458.595 192.829 459.853 191.572 461.403 191.572C462.953 191.572 464.209 192.829 464.209 194.379Z" />
          <path d="M464.209 187.537C464.209 189.087 462.953 190.344 461.403 190.344C459.853 190.344 458.595 189.087 458.595 187.537C458.595 185.987 459.853 184.73 461.403 184.73C462.953 184.73 464.209 185.987 464.209 187.537Z" />
          <path d="M471.051 187.537C471.051 189.087 469.794 190.344 468.245 190.344C466.695 190.344 465.437 189.087 465.437 187.537C465.437 185.987 466.695 184.73 468.245 184.73C469.794 184.73 471.051 185.987 471.051 187.537Z" />
          <path d="M471.051 194.379C471.051 195.929 469.794 197.185 468.245 197.185C466.695 197.185 465.437 195.929 465.437 194.379C465.437 192.829 466.695 191.572 468.245 191.572C469.794 191.572 471.051 192.829 471.051 194.379Z" />
          <path d="M477.893 187.537C477.893 189.087 476.636 190.344 475.086 190.344C473.536 190.344 472.279 189.087 472.279 187.537C472.279 185.987 473.536 184.73 475.086 184.73C476.636 184.73 477.893 185.987 477.893 187.537Z" />
          <path d="M477.893 180.695C477.893 182.245 476.636 183.502 475.086 183.502C473.536 183.502 472.279 182.245 472.279 180.695C472.279 179.145 473.536 177.888 475.086 177.888C476.636 177.888 477.893 179.145 477.893 180.695Z" />
          <path d="M471.051 180.695C471.051 182.245 469.794 183.502 468.245 183.502C466.695 183.502 465.437 182.245 465.437 180.695C465.437 179.145 466.695 177.888 468.245 177.888C469.794 177.888 471.051 179.145 471.051 180.695Z" />
          <path d="M471.051 173.853C471.051 175.403 469.794 176.66 468.245 176.66C466.695 176.66 465.437 175.403 465.437 173.853C465.437 172.303 466.695 171.046 468.245 171.046C469.794 171.046 471.051 172.303 471.051 173.853Z" />
          <path d="M471.051 167.011C471.051 168.561 469.794 169.818 468.245 169.818C466.695 169.818 465.437 168.561 465.437 167.011C465.437 165.461 466.695 164.204 468.245 164.204C469.794 164.204 471.051 165.461 471.051 167.011Z" />
          <path d="M471.051 160.169C471.051 161.719 469.794 162.976 468.245 162.976C466.695 162.976 465.437 161.719 465.437 160.169C465.437 158.619 466.695 157.363 468.245 157.363C469.794 157.363 471.051 158.619 471.051 160.169Z" />
          <path d="M471.051 153.328C471.051 154.877 469.794 156.134 468.245 156.134C466.695 156.134 465.437 154.877 465.437 153.328C465.437 151.778 466.695 150.521 468.245 150.521C469.794 150.521 471.051 151.778 471.051 153.328Z" />
          <path d="M477.893 153.328C477.893 154.877 476.636 156.134 475.086 156.134C473.536 156.134 472.279 154.877 472.279 153.328C472.279 151.778 473.536 150.521 475.086 150.521C476.636 150.521 477.893 151.778 477.893 153.328Z" />
          <path d="M484.734 153.328C484.734 154.877 483.478 156.134 481.928 156.134C480.378 156.134 479.121 154.877 479.121 153.328C479.121 151.778 480.378 150.521 481.928 150.521C483.478 150.521 484.734 151.778 484.734 153.328Z" />
          <path d="M491.576 153.328C491.576 154.877 490.32 156.134 488.77 156.134C487.22 156.134 485.963 154.877 485.963 153.328C485.963 151.778 487.22 150.521 488.77 150.521C490.32 150.521 491.576 151.778 491.576 153.328Z" />
          <path d="M491.576 160.169C491.576 161.719 490.32 162.976 488.77 162.976C487.22 162.976 485.963 161.719 485.963 160.169C485.963 158.619 487.22 157.363 488.77 157.363C490.32 157.363 491.576 158.619 491.576 160.169Z" />
          <path d="M484.734 160.169C484.734 161.719 483.478 162.976 481.928 162.976C480.378 162.976 479.121 161.719 479.121 160.169C479.121 158.619 480.378 157.363 481.928 157.363C483.478 157.363 484.734 158.619 484.734 160.169Z" />
          <path d="M477.893 160.169C477.893 161.719 476.636 162.976 475.086 162.976C473.536 162.976 472.279 161.719 472.279 160.169C472.279 158.619 473.536 157.363 475.086 157.363C476.636 157.363 477.893 158.619 477.893 160.169Z" />
          <path d="M477.893 167.011C477.893 168.561 476.636 169.818 475.086 169.818C473.536 169.818 472.279 168.561 472.279 167.011C472.279 165.461 473.536 164.204 475.086 164.204C476.636 164.204 477.893 165.461 477.893 167.011Z" />
          <path d="M484.734 167.011C484.734 168.561 483.478 169.818 481.928 169.818C480.378 169.818 479.121 168.561 479.121 167.011C479.121 165.461 480.378 164.204 481.928 164.204C483.478 164.204 484.734 165.461 484.734 167.011Z" />
          <path d="M484.734 173.853C484.734 175.403 483.478 176.66 481.928 176.66C480.378 176.66 479.121 175.403 479.121 173.853C479.121 172.303 480.378 171.046 481.928 171.046C483.478 171.046 484.734 172.303 484.734 173.853Z" />
          <path d="M477.893 173.853C477.893 175.403 476.636 176.66 475.086 176.66C473.536 176.66 472.279 175.403 472.279 173.853C472.279 172.303 473.536 171.046 475.086 171.046C476.636 171.046 477.893 172.303 477.893 173.853Z" />
          <path d="M484.734 180.695C484.734 182.245 483.478 183.502 481.928 183.502C480.378 183.502 479.121 182.245 479.121 180.695C479.121 179.145 480.378 177.888 481.928 177.888C483.478 177.888 484.734 179.145 484.734 180.695Z" />
          <path d="M491.576 187.537C491.576 189.087 490.32 190.344 488.77 190.344C487.22 190.344 485.963 189.087 485.963 187.537C485.963 185.987 487.22 184.73 488.77 184.73C490.32 184.73 491.576 185.987 491.576 187.537Z" />
          <path d="M491.576 180.695C491.576 182.245 490.32 183.502 488.77 183.502C487.22 183.502 485.963 182.245 485.963 180.695C485.963 179.145 487.22 177.888 488.77 177.888C490.32 177.888 491.576 179.145 491.576 180.695Z" />
          <path d="M491.576 173.853C491.576 175.403 490.32 176.66 488.77 176.66C487.22 176.66 485.963 175.403 485.963 173.853C485.963 172.303 487.22 171.046 488.77 171.046C490.32 171.046 491.576 172.303 491.576 173.853Z" />
          <path d="M491.576 167.011C491.576 168.561 490.32 169.818 488.77 169.818C487.22 169.818 485.963 168.561 485.963 167.011C485.963 165.461 487.22 164.204 488.77 164.204C490.32 164.204 491.576 165.461 491.576 167.011Z" />
          <path d="M498.418 167.011C498.418 168.561 497.162 169.818 495.612 169.818C494.062 169.818 492.805 168.561 492.805 167.011C492.805 165.461 494.062 164.204 495.612 164.204C497.162 164.204 498.418 165.461 498.418 167.011Z" />
          <path d="M498.418 160.169C498.418 161.719 497.162 162.976 495.612 162.976C494.062 162.976 492.805 161.719 492.805 160.169C492.805 158.619 494.062 157.363 495.612 157.363C497.162 157.363 498.418 158.619 498.418 160.169Z" />
          <path d="M498.418 153.328C498.418 154.877 497.162 156.134 495.612 156.134C494.062 156.134 492.805 154.877 492.805 153.328C492.805 151.778 494.062 150.521 495.612 150.521C497.162 150.521 498.418 151.778 498.418 153.328Z" />
          <path d="M498.418 146.486C498.418 148.036 497.162 149.292 495.612 149.292C494.062 149.292 492.805 148.036 492.805 146.486C492.805 144.936 494.062 143.679 495.612 143.679C497.162 143.679 498.418 144.936 498.418 146.486Z" />
          <path d="M498.418 139.644C498.418 141.194 497.162 142.451 495.612 142.451C494.062 142.451 492.805 141.194 492.805 139.644C492.805 138.094 494.062 136.837 495.612 136.837C497.162 136.837 498.418 138.094 498.418 139.644Z" />
          <path d="M491.576 139.644C491.576 141.194 490.32 142.451 488.77 142.451C487.22 142.451 485.963 141.194 485.963 139.644C485.963 138.094 487.22 136.837 488.77 136.837C490.32 136.837 491.576 138.094 491.576 139.644Z" />
          <path d="M491.576 132.802C491.576 134.352 490.32 135.608 488.77 135.608C487.22 135.608 485.963 134.352 485.963 132.802C485.963 131.252 487.22 129.995 488.77 129.995C490.32 129.995 491.576 131.252 491.576 132.802Z" />
          <path d="M491.576 125.96C491.576 127.51 490.32 128.767 488.77 128.767C487.22 128.767 485.963 127.51 485.963 125.96C485.963 124.41 487.22 123.153 488.77 123.153C490.32 123.153 491.576 124.41 491.576 125.96Z" />
          <path d="M498.418 125.96C498.418 127.51 497.162 128.767 495.612 128.767C494.062 128.767 492.805 127.51 492.805 125.96C492.805 124.41 494.062 123.153 495.612 123.153C497.162 123.153 498.418 124.41 498.418 125.96Z" />
          <path d="M498.418 132.802C498.418 134.352 497.162 135.608 495.612 135.608C494.062 135.608 492.805 134.352 492.805 132.802C492.805 131.252 494.062 129.995 495.612 129.995C497.162 129.995 498.418 131.252 498.418 132.802Z" />
          <path d="M505.26 132.802C505.26 134.352 504.004 135.608 502.454 135.608C500.904 135.608 499.646 134.352 499.646 132.802C499.646 131.252 500.904 129.995 502.454 129.995C504.004 129.995 505.26 131.252 505.26 132.802Z" />
          <path d="M505.26 139.644C505.26 141.194 504.004 142.451 502.454 142.451C500.904 142.451 499.646 141.194 499.646 139.644C499.646 138.094 500.904 136.837 502.454 136.837C504.004 136.837 505.26 138.094 505.26 139.644Z" />
          <path d="M505.26 146.486C505.26 148.036 504.004 149.292 502.454 149.292C500.904 149.292 499.646 148.036 499.646 146.486C499.646 144.936 500.904 143.679 502.454 143.679C504.004 143.679 505.26 144.936 505.26 146.486Z" />
          <path d="M505.26 153.328C505.26 154.877 504.004 156.134 502.454 156.134C500.904 156.134 499.646 154.877 499.646 153.328C499.646 151.778 500.904 150.521 502.454 150.521C504.004 150.521 505.26 151.778 505.26 153.328Z" />
          <path d="M505.26 160.169C505.26 161.719 504.004 162.976 502.454 162.976C500.904 162.976 499.646 161.719 499.646 160.169C499.646 158.619 500.904 157.363 502.454 157.363C504.004 157.363 505.26 158.619 505.26 160.169Z" />
          <path d="M505.26 167.011C505.26 168.561 504.004 169.818 502.454 169.818C500.904 169.818 499.646 168.561 499.646 167.011C499.646 165.461 500.904 164.204 502.454 164.204C504.004 164.204 505.26 165.461 505.26 167.011Z" />
          <path d="M505.26 173.853C505.26 175.403 504.004 176.66 502.454 176.66C500.904 176.66 499.646 175.403 499.646 173.853C499.646 172.303 500.904 171.046 502.454 171.046C504.004 171.046 505.26 172.303 505.26 173.853Z" />
          <path d="M498.418 173.853C498.418 175.403 497.162 176.66 495.612 176.66C494.062 176.66 492.805 175.403 492.805 173.853C492.805 172.303 494.062 171.046 495.612 171.046C497.162 171.046 498.418 172.303 498.418 173.853Z" />
          <path d="M498.418 180.695C498.418 182.245 497.162 183.502 495.612 183.502C494.062 183.502 492.805 182.245 492.805 180.695C492.805 179.145 494.062 177.888 495.612 177.888C497.162 177.888 498.418 179.145 498.418 180.695Z" />
          <path d="M505.26 180.695C505.26 182.245 504.004 183.502 502.454 183.502C500.904 183.502 499.646 182.245 499.646 180.695C499.646 179.145 500.904 177.888 502.454 177.888C504.004 177.888 505.26 179.145 505.26 180.695Z" />
          <path d="M505.26 187.537C505.26 189.087 504.004 190.344 502.454 190.344C500.904 190.344 499.646 189.087 499.646 187.537C499.646 185.987 500.904 184.73 502.454 184.73C504.004 184.73 505.26 185.987 505.26 187.537Z" />
          <path d="M505.26 194.379C505.26 195.929 504.004 197.185 502.454 197.185C500.904 197.185 499.646 195.929 499.646 194.379C499.646 192.829 500.904 191.572 502.454 191.572C504.004 191.572 505.26 192.829 505.26 194.379Z" />
          <path d="M498.418 194.379C498.418 195.929 497.162 197.185 495.612 197.185C494.062 197.185 492.805 195.929 492.805 194.379C492.805 192.829 494.062 191.572 495.612 191.572C497.162 191.572 498.418 192.829 498.418 194.379Z" />
          <path d="M491.576 194.379C491.576 195.929 490.32 197.185 488.77 197.185C487.22 197.185 485.963 195.929 485.963 194.379C485.963 192.829 487.22 191.572 488.77 191.572C490.32 191.572 491.576 192.829 491.576 194.379Z" />
          <path d="M512.102 201.22C512.102 202.77 510.845 204.027 509.296 204.027C507.746 204.027 506.488 202.77 506.488 201.22C506.488 199.67 507.746 198.413 509.296 198.413C510.845 198.413 512.102 199.67 512.102 201.22Z" />
          <path d="M512.102 208.062C512.102 209.612 510.845 210.869 509.296 210.869C507.746 210.869 506.488 209.612 506.488 208.062C506.488 206.512 507.746 205.256 509.296 205.256C510.845 205.256 512.102 206.512 512.102 208.062Z" />
          <path d="M512.102 214.904C512.102 216.454 510.845 217.711 509.296 217.711C507.746 217.711 506.488 216.454 506.488 214.904C506.488 213.354 507.746 212.097 509.296 212.097C510.845 212.097 512.102 213.354 512.102 214.904Z" />
          <path d="M505.26 214.904C505.26 216.454 504.004 217.711 502.454 217.711C500.904 217.711 499.646 216.454 499.646 214.904C499.646 213.354 500.904 212.097 502.454 212.097C504.004 212.097 505.26 213.354 505.26 214.904Z" />
          <path d="M498.418 208.062C498.418 209.612 497.162 210.869 495.612 210.869C494.062 210.869 492.805 209.612 492.805 208.062C492.805 206.512 494.062 205.256 495.612 205.256C497.162 205.256 498.418 206.512 498.418 208.062Z" />
          <path d="M491.576 208.062C491.576 209.612 490.32 210.869 488.77 210.869C487.22 210.869 485.963 209.612 485.963 208.062C485.963 206.512 487.22 205.256 488.77 205.256C490.32 205.256 491.576 206.512 491.576 208.062Z" />
          <path d="M484.734 208.062C484.734 209.612 483.478 210.869 481.928 210.869C480.378 210.869 479.121 209.612 479.121 208.062C479.121 206.512 480.378 205.256 481.928 205.256C483.478 205.256 484.734 206.512 484.734 208.062Z" />
          <path d="M484.734 214.904C484.734 216.454 483.478 217.711 481.928 217.711C480.378 217.711 479.121 216.454 479.121 214.904C479.121 213.354 480.378 212.097 481.928 212.097C483.478 212.097 484.734 213.354 484.734 214.904Z" />
          <path d="M491.576 214.904C491.576 216.454 490.32 217.711 488.77 217.711C487.22 217.711 485.963 216.454 485.963 214.904C485.963 213.354 487.22 212.097 488.77 212.097C490.32 212.097 491.576 213.354 491.576 214.904Z" />
          <path d="M498.418 214.904C498.418 216.454 497.162 217.711 495.612 217.711C494.062 217.711 492.805 216.454 492.805 214.904C492.805 213.354 494.062 212.097 495.612 212.097C497.162 212.097 498.418 213.354 498.418 214.904Z" />
          <path d="M498.418 221.746C498.418 223.296 497.162 224.553 495.612 224.553C494.062 224.553 492.805 223.296 492.805 221.746C492.805 220.196 494.062 218.939 495.612 218.939C497.162 218.939 498.418 220.196 498.418 221.746Z" />
          <path d="M491.576 228.588C491.576 230.137 490.32 231.394 488.77 231.394C487.22 231.394 485.963 230.137 485.963 228.588C485.963 227.038 487.22 225.781 488.77 225.781C490.32 225.781 491.576 227.038 491.576 228.588Z" />
          <path d="M498.418 228.588C498.418 230.137 497.162 231.394 495.612 231.394C494.062 231.394 492.805 230.137 492.805 228.588C492.805 227.038 494.062 225.781 495.612 225.781C497.162 225.781 498.418 227.038 498.418 228.588Z" />
          <path d="M498.418 235.43C498.418 236.98 497.162 238.236 495.612 238.236C494.062 238.236 492.805 236.98 492.805 235.43C492.805 233.88 494.062 232.623 495.612 232.623C497.162 232.623 498.418 233.88 498.418 235.43Z" />
          <path d="M491.576 242.272C491.576 243.822 490.32 245.078 488.77 245.078C487.22 245.078 485.963 243.822 485.963 242.272C485.963 240.722 487.22 239.465 488.77 239.465C490.32 239.465 491.576 240.722 491.576 242.272Z" />
          <path d="M484.734 242.272C484.734 243.822 483.478 245.078 481.928 245.078C480.378 245.078 479.121 243.822 479.121 242.272C479.121 240.722 480.378 239.465 481.928 239.465C483.478 239.465 484.734 240.722 484.734 242.272Z" />
          <path d="M477.893 242.272C477.893 243.822 476.636 245.078 475.086 245.078C473.536 245.078 472.279 243.822 472.279 242.272C472.279 240.722 473.536 239.465 475.086 239.465C476.636 239.465 477.893 240.722 477.893 242.272Z" />
          <path d="M471.051 242.272C471.051 243.822 469.794 245.078 468.245 245.078C466.695 245.078 465.437 243.822 465.437 242.272C465.437 240.722 466.695 239.465 468.245 239.465C469.794 239.465 471.051 240.722 471.051 242.272Z" />
          <path d="M464.209 242.272C464.209 243.822 462.953 245.078 461.403 245.078C459.853 245.078 458.595 243.822 458.595 242.272C458.595 240.722 459.853 239.465 461.403 239.465C462.953 239.465 464.209 240.722 464.209 242.272Z" />
          <path d="M464.209 235.43C464.209 236.98 462.953 238.236 461.403 238.236C459.853 238.236 458.595 236.98 458.595 235.43C458.595 233.88 459.853 232.623 461.403 232.623C462.953 232.623 464.209 233.88 464.209 235.43Z" />
          <path d="M457.367 242.272C457.367 243.822 456.111 245.078 454.561 245.078C453.011 245.078 451.754 243.822 451.754 242.272C451.754 240.722 453.011 239.465 454.561 239.465C456.111 239.465 457.367 240.722 457.367 242.272Z" />
          <path d="M450.525 242.272C450.525 243.822 449.269 245.078 447.719 245.078C446.169 245.078 444.912 243.822 444.912 242.272C444.912 240.722 446.169 239.465 447.719 239.465C449.269 239.465 450.525 240.722 450.525 242.272Z" />
          <path d="M443.683 242.272C443.683 243.822 442.427 245.078 440.877 245.078C439.327 245.078 438.07 243.822 438.07 242.272C438.07 240.722 439.327 239.465 440.877 239.465C442.427 239.465 443.683 240.722 443.683 242.272Z" />
          <path d="M443.683 235.43C443.683 236.98 442.427 238.236 440.877 238.236C439.327 238.236 438.07 236.98 438.07 235.43C438.07 233.88 439.327 232.623 440.877 232.623C442.427 232.623 443.683 233.88 443.683 235.43Z" />
          <path d="M436.842 235.43C436.842 236.98 435.585 238.236 434.036 238.236C432.486 238.236 431.228 236.98 431.228 235.43C431.228 233.88 432.486 232.623 434.036 232.623C435.585 232.623 436.842 233.88 436.842 235.43Z" />
          <path d="M430 235.43C430 236.98 428.744 238.236 427.194 238.236C425.644 238.236 424.386 236.98 424.386 235.43C424.386 233.88 425.644 232.623 427.194 232.623C428.744 232.623 430 233.88 430 235.43Z" />
          <path d="M430 228.588C430 230.137 428.744 231.394 427.194 231.394C425.644 231.394 424.386 230.137 424.386 228.588C424.386 227.038 425.644 225.781 427.194 225.781C428.744 225.781 430 227.038 430 228.588Z" />
          <path d="M423.158 228.588C423.158 230.137 421.902 231.394 420.352 231.394C418.802 231.394 417.544 230.137 417.544 228.588C417.544 227.038 418.802 225.781 420.352 225.781C421.902 225.781 423.158 227.038 423.158 228.588Z" />
          <path d="M423.158 221.746C423.158 223.296 421.902 224.553 420.352 224.553C418.802 224.553 417.544 223.296 417.544 221.746C417.544 220.196 418.802 218.939 420.352 218.939C421.902 218.939 423.158 220.196 423.158 221.746Z" />
          <path d="M416.316 228.588C416.316 230.137 415.06 231.394 413.51 231.394C411.96 231.394 410.702 230.137 410.702 228.588C410.702 227.038 411.96 225.781 413.51 225.781C415.06 225.781 416.316 227.038 416.316 228.588Z" />
          <path d="M409.474 228.588C409.474 230.137 408.218 231.394 406.668 231.394C405.118 231.394 403.861 230.137 403.861 228.588C403.861 227.038 405.118 225.781 406.668 225.781C408.218 225.781 409.474 227.038 409.474 228.588Z" />
          <path d="M402.633 228.588C402.633 230.137 401.376 231.394 399.826 231.394C398.276 231.394 397.019 230.137 397.019 228.588C397.019 227.038 398.276 225.781 399.826 225.781C401.376 225.781 402.633 227.038 402.633 228.588Z" />
          <path d="M395.791 228.588C395.791 230.137 394.534 231.394 392.984 231.394C391.434 231.394 390.177 230.137 390.177 228.588C390.177 227.038 391.434 225.781 392.984 225.781C394.534 225.781 395.791 227.038 395.791 228.588Z" />
          <path d="M395.791 235.43C395.791 236.98 394.534 238.236 392.984 238.236C391.434 238.236 390.177 236.98 390.177 235.43C390.177 233.88 391.434 232.623 392.984 232.623C394.534 232.623 395.791 233.88 395.791 235.43Z" />
          <path d="M388.949 235.43C388.949 236.98 387.692 238.236 386.142 238.236C384.592 238.236 383.335 236.98 383.335 235.43C383.335 233.88 384.592 232.623 386.142 232.623C387.692 232.623 388.949 233.88 388.949 235.43Z" />
          <path d="M388.949 242.272C388.949 243.822 387.692 245.078 386.142 245.078C384.592 245.078 383.335 243.822 383.335 242.272C383.335 240.722 384.592 239.465 386.142 239.465C387.692 239.465 388.949 240.722 388.949 242.272Z" />
          <path d="M388.949 249.113C388.949 250.663 387.692 251.92 386.142 251.92C384.592 251.92 383.335 250.663 383.335 249.113C383.335 247.563 384.592 246.307 386.142 246.307C387.692 246.307 388.949 247.563 388.949 249.113Z" />
          <path d="M382.107 249.113C382.107 250.663 380.85 251.92 379.301 251.92C377.751 251.92 376.493 250.663 376.493 249.113C376.493 247.563 377.751 246.307 379.301 246.307C380.85 246.307 382.107 247.563 382.107 249.113Z" />
          <path d="M382.107 255.955C382.107 257.505 380.85 258.762 379.301 258.762C377.751 258.762 376.493 257.505 376.493 255.955C376.493 254.405 377.751 253.148 379.301 253.148C380.85 253.148 382.107 254.405 382.107 255.955Z" />
          <path d="M375.265 255.955C375.265 257.505 374.009 258.762 372.459 258.762C370.909 258.762 369.651 257.505 369.651 255.955C369.651 254.405 370.909 253.148 372.459 253.148C374.009 253.148 375.265 254.405 375.265 255.955Z" />
          <path d="M375.265 262.797C375.265 264.347 374.009 265.604 372.459 265.604C370.909 265.604 369.651 264.347 369.651 262.797C369.651 261.247 370.909 259.99 372.459 259.99C374.009 259.99 375.265 261.247 375.265 262.797Z" />
          <path d="M368.423 262.797C368.423 264.347 367.167 265.604 365.617 265.604C364.067 265.604 362.81 264.347 362.81 262.797C362.81 261.247 364.067 259.99 365.617 259.99C367.167 259.99 368.423 261.247 368.423 262.797Z" />
          <path d="M375.265 269.639C375.265 271.189 374.009 272.445 372.459 272.445C370.909 272.445 369.651 271.189 369.651 269.639C369.651 268.089 370.909 266.832 372.459 266.832C374.009 266.832 375.265 268.089 375.265 269.639Z" />
          <path d="M368.423 269.639C368.423 271.189 367.167 272.445 365.617 272.445C364.067 272.445 362.81 271.189 362.81 269.639C362.81 268.089 364.067 266.832 365.617 266.832C367.167 266.832 368.423 268.089 368.423 269.639Z" />
          <path d="M375.265 276.481C375.265 278.031 374.009 279.288 372.459 279.288C370.909 279.288 369.651 278.031 369.651 276.481C369.651 274.931 370.909 273.674 372.459 273.674C374.009 273.674 375.265 274.931 375.265 276.481Z" />
          <path d="M368.423 283.323C368.423 284.873 367.167 286.129 365.617 286.129C364.067 286.129 362.81 284.873 362.81 283.323C362.81 281.773 364.067 280.516 365.617 280.516C367.167 280.516 368.423 281.773 368.423 283.323Z" />
          <path d="M375.265 283.323C375.265 284.873 374.009 286.129 372.459 286.129C370.909 286.129 369.651 284.873 369.651 283.323C369.651 281.773 370.909 280.516 372.459 280.516C374.009 280.516 375.265 281.773 375.265 283.323Z" />
          <path d="M375.265 290.164C375.265 291.714 374.009 292.971 372.459 292.971C370.909 292.971 369.651 291.714 369.651 290.164C369.651 288.615 370.909 287.358 372.459 287.358C374.009 287.358 375.265 288.615 375.265 290.164Z" />
          <path d="M382.107 290.164C382.107 291.714 380.85 292.971 379.301 292.971C377.751 292.971 376.493 291.714 376.493 290.164C376.493 288.615 377.751 287.358 379.301 287.358C380.85 287.358 382.107 288.615 382.107 290.164Z" />
          <path d="M382.107 297.006C382.107 298.556 380.85 299.813 379.301 299.813C377.751 299.813 376.493 298.556 376.493 297.006C376.493 295.456 377.751 294.199 379.301 294.199C380.85 294.199 382.107 295.456 382.107 297.006Z" />
          <path d="M388.949 297.006C388.949 298.556 387.692 299.813 386.142 299.813C384.592 299.813 383.335 298.556 383.335 297.006C383.335 295.456 384.592 294.199 386.142 294.199C387.692 294.199 388.949 295.456 388.949 297.006Z" />
          <path d="M388.949 303.848C388.949 305.398 387.692 306.655 386.142 306.655C384.592 306.655 383.335 305.398 383.335 303.848C383.335 302.298 384.592 301.041 386.142 301.041C387.692 301.041 388.949 302.298 388.949 303.848Z" />
          <path d="M395.791 297.006C395.791 298.556 394.534 299.813 392.984 299.813C391.434 299.813 390.177 298.556 390.177 297.006C390.177 295.456 391.434 294.199 392.984 294.199C394.534 294.199 395.791 295.456 395.791 297.006Z" />
          <path d="M395.791 303.848C395.791 305.398 394.534 306.655 392.984 306.655C391.434 306.655 390.177 305.398 390.177 303.848C390.177 302.298 391.434 301.041 392.984 301.041C394.534 301.041 395.791 302.298 395.791 303.848Z" />
          <path d="M402.633 297.006C402.633 298.556 401.376 299.813 399.826 299.813C398.276 299.813 397.019 298.556 397.019 297.006C397.019 295.456 398.276 294.199 399.826 294.199C401.376 294.199 402.633 295.456 402.633 297.006Z" />
          <path d="M402.633 303.848C402.633 305.398 401.376 306.655 399.826 306.655C398.276 306.655 397.019 305.398 397.019 303.848C397.019 302.298 398.276 301.041 399.826 301.041C401.376 301.041 402.633 302.298 402.633 303.848Z" />
          <path d="M409.474 297.006C409.474 298.556 408.218 299.813 406.668 299.813C405.118 299.813 403.861 298.556 403.861 297.006C403.861 295.456 405.118 294.199 406.668 294.199C408.218 294.199 409.474 295.456 409.474 297.006Z" />
          <path d="M416.316 297.006C416.316 298.556 415.06 299.813 413.51 299.813C411.96 299.813 410.702 298.556 410.702 297.006C410.702 295.456 411.96 294.199 413.51 294.199C415.06 294.199 416.316 295.456 416.316 297.006Z" />
          <path d="M423.158 297.006C423.158 298.556 421.902 299.813 420.352 299.813C418.802 299.813 417.544 298.556 417.544 297.006C417.544 295.456 418.802 294.199 420.352 294.199C421.902 294.199 423.158 295.456 423.158 297.006Z" />
          <path d="M430 297.006C430 298.556 428.744 299.813 427.194 299.813C425.644 299.813 424.386 298.556 424.386 297.006C424.386 295.456 425.644 294.199 427.194 294.199C428.744 294.199 430 295.456 430 297.006Z" />
          <path d="M430 303.848C430 305.398 428.744 306.655 427.194 306.655C425.644 306.655 424.386 305.398 424.386 303.848C424.386 302.298 425.644 301.041 427.194 301.041C428.744 301.041 430 302.298 430 303.848Z" />
          <path d="M436.842 303.848C436.842 305.398 435.585 306.655 434.036 306.655C432.486 306.655 431.228 305.398 431.228 303.848C431.228 302.298 432.486 301.041 434.036 301.041C435.585 301.041 436.842 302.298 436.842 303.848Z" />
          <path d="M436.842 310.69C436.842 312.24 435.585 313.497 434.036 313.497C432.486 313.497 431.228 312.24 431.228 310.69C431.228 309.14 432.486 307.883 434.036 307.883C435.585 307.883 436.842 309.14 436.842 310.69Z" />
          <path d="M436.842 317.532C436.842 319.082 435.585 320.339 434.036 320.339C432.486 320.339 431.228 319.082 431.228 317.532C431.228 315.982 432.486 314.725 434.036 314.725C435.585 314.725 436.842 315.982 436.842 317.532Z" />
          <path d="M436.842 324.373C436.842 325.923 435.585 327.18 434.036 327.18C432.486 327.18 431.228 325.923 431.228 324.373C431.228 322.824 432.486 321.567 434.036 321.567C435.585 321.567 436.842 322.824 436.842 324.373Z" />
          <path d="M443.683 324.373C443.683 325.923 442.427 327.18 440.877 327.18C439.327 327.18 438.07 325.923 438.07 324.373C438.07 322.824 439.327 321.567 440.877 321.567C442.427 321.567 443.683 322.824 443.683 324.373Z" />
          <path d="M443.683 331.215C443.683 332.765 442.427 334.022 440.877 334.022C439.327 334.022 438.07 332.765 438.07 331.215C438.07 329.666 439.327 328.409 440.877 328.409C442.427 328.409 443.683 329.666 443.683 331.215Z" />
          <path d="M450.525 331.215C450.525 332.765 449.269 334.022 447.719 334.022C446.169 334.022 444.912 332.765 444.912 331.215C444.912 329.666 446.169 328.409 447.719 328.409C449.269 328.409 450.525 329.666 450.525 331.215Z" />
          <path d="M450.525 338.057C450.525 339.607 449.269 340.864 447.719 340.864C446.169 340.864 444.912 339.607 444.912 338.057C444.912 336.507 446.169 335.25 447.719 335.25C449.269 335.25 450.525 336.507 450.525 338.057Z" />
          <path d="M443.683 338.057C443.683 339.607 442.427 340.864 440.877 340.864C439.327 340.864 438.07 339.607 438.07 338.057C438.07 336.507 439.327 335.25 440.877 335.25C442.427 335.25 443.683 336.507 443.683 338.057Z" />
          <path d="M450.525 344.899C450.525 346.449 449.269 347.706 447.719 347.706C446.169 347.706 444.912 346.449 444.912 344.899C444.912 343.349 446.169 342.093 447.719 342.093C449.269 342.093 450.525 343.349 450.525 344.899Z" />
          <path d="M443.683 344.899C443.683 346.449 442.427 347.706 440.877 347.706C439.327 347.706 438.07 346.449 438.07 344.899C438.07 343.349 439.327 342.093 440.877 342.093C442.427 342.093 443.683 343.349 443.683 344.899Z" />
          <path d="M450.525 351.741C450.525 353.291 449.269 354.548 447.719 354.548C446.169 354.548 444.912 353.291 444.912 351.741C444.912 350.191 446.169 348.934 447.719 348.934C449.269 348.934 450.525 350.191 450.525 351.741Z" />
          <path d="M450.525 358.583C450.525 360.133 449.269 361.39 447.719 361.39C446.169 361.39 444.912 360.133 444.912 358.583C444.912 357.033 446.169 355.776 447.719 355.776C449.269 355.776 450.525 357.033 450.525 358.583Z" />
          <path d="M443.683 358.583C443.683 360.133 442.427 361.39 440.877 361.39C439.327 361.39 438.07 360.133 438.07 358.583C438.07 357.033 439.327 355.776 440.877 355.776C442.427 355.776 443.683 357.033 443.683 358.583Z" />
          <path d="M443.683 365.424C443.683 366.974 442.427 368.231 440.877 368.231C439.327 368.231 438.07 366.974 438.07 365.424C438.07 363.875 439.327 362.618 440.877 362.618C442.427 362.618 443.683 363.875 443.683 365.424Z" />
          <path d="M450.525 365.424C450.525 366.974 449.269 368.231 447.719 368.231C446.169 368.231 444.912 366.974 444.912 365.424C444.912 363.875 446.169 362.618 447.719 362.618C449.269 362.618 450.525 363.875 450.525 365.424Z" />
          <path d="M450.525 372.267C450.525 373.816 449.269 375.073 447.719 375.073C446.169 375.073 444.912 373.816 444.912 372.267C444.912 370.717 446.169 369.46 447.719 369.46C449.269 369.46 450.525 370.717 450.525 372.267Z" />
          <path d="M443.683 372.267C443.683 373.816 442.427 375.073 440.877 375.073C439.327 375.073 438.07 373.816 438.07 372.267C438.07 370.717 439.327 369.46 440.877 369.46C442.427 369.46 443.683 370.717 443.683 372.267Z" />
          <path d="M450.525 379.109C450.525 380.658 449.269 381.915 447.719 381.915C446.169 381.915 444.912 380.658 444.912 379.109C444.912 377.559 446.169 376.302 447.719 376.302C449.269 376.302 450.525 377.559 450.525 379.109Z" />
          <path d="M450.525 385.95C450.525 387.5 449.269 388.757 447.719 388.757C446.169 388.757 444.912 387.5 444.912 385.95C444.912 384.4 446.169 383.144 447.719 383.144C449.269 383.144 450.525 384.4 450.525 385.95Z" />
          <path d="M457.367 392.792C457.367 394.342 456.111 395.599 454.561 395.599C453.011 395.599 451.754 394.342 451.754 392.792C451.754 391.242 453.011 389.985 454.561 389.985C456.111 389.985 457.367 391.242 457.367 392.792Z" />
          <path d="M450.525 392.792C450.525 394.342 449.269 395.599 447.719 395.599C446.169 395.599 444.912 394.342 444.912 392.792C444.912 391.242 446.169 389.985 447.719 389.985C449.269 389.985 450.525 391.242 450.525 392.792Z" />
          <path d="M457.367 399.634C457.367 401.184 456.111 402.441 454.561 402.441C453.011 402.441 451.754 401.184 451.754 399.634C451.754 398.084 453.011 396.827 454.561 396.827C456.111 396.827 457.367 398.084 457.367 399.634Z" />
          <path d="M457.367 406.475C457.367 408.025 456.111 409.282 454.561 409.282C453.011 409.282 451.754 408.025 451.754 406.475C451.754 404.926 453.011 403.669 454.561 403.669C456.111 403.669 457.367 404.926 457.367 406.475Z" />
          <path d="M464.209 406.475C464.209 408.025 462.953 409.282 461.403 409.282C459.853 409.282 458.595 408.025 458.595 406.475C458.595 404.926 459.853 403.669 461.403 403.669C462.953 403.669 464.209 404.926 464.209 406.475Z" />
          <path d="M464.209 399.634C464.209 401.184 462.953 402.441 461.403 402.441C459.853 402.441 458.595 401.184 458.595 399.634C458.595 398.084 459.853 396.827 461.403 396.827C462.953 396.827 464.209 398.084 464.209 399.634Z" />
          <path d="M471.051 399.634C471.051 401.184 469.794 402.441 468.245 402.441C466.695 402.441 465.437 401.184 465.437 399.634C465.437 398.084 466.695 396.827 468.245 396.827C469.794 396.827 471.051 398.084 471.051 399.634Z" />
          <path d="M471.051 406.475C471.051 408.025 469.794 409.282 468.245 409.282C466.695 409.282 465.437 408.025 465.437 406.475C465.437 404.926 466.695 403.669 468.245 403.669C469.794 403.669 471.051 404.926 471.051 406.475Z" />
          <path d="M477.893 399.634C477.893 401.184 476.636 402.441 475.086 402.441C473.536 402.441 472.279 401.184 472.279 399.634C472.279 398.084 473.536 396.827 475.086 396.827C476.636 396.827 477.893 398.084 477.893 399.634Z" />
          <path d="M477.893 392.792C477.893 394.342 476.636 395.599 475.086 395.599C473.536 395.599 472.279 394.342 472.279 392.792C472.279 391.242 473.536 389.985 475.086 389.985C476.636 389.985 477.893 391.242 477.893 392.792Z" />
          <path d="M484.734 392.792C484.734 394.342 483.478 395.599 481.928 395.599C480.378 395.599 479.121 394.342 479.121 392.792C479.121 391.242 480.378 389.985 481.928 389.985C483.478 389.985 484.734 391.242 484.734 392.792Z" />
          <path d="M484.734 385.95C484.734 387.5 483.478 388.757 481.928 388.757C480.378 388.757 479.121 387.5 479.121 385.95C479.121 384.4 480.378 383.144 481.928 383.144C483.478 383.144 484.734 384.4 484.734 385.95Z" />
          <path d="M484.734 379.109C484.734 380.658 483.478 381.915 481.928 381.915C480.378 381.915 479.121 380.658 479.121 379.109C479.121 377.559 480.378 376.302 481.928 376.302C483.478 376.302 484.734 377.559 484.734 379.109Z" />
          <path d="M491.576 379.109C491.576 380.658 490.32 381.915 488.77 381.915C487.22 381.915 485.963 380.658 485.963 379.109C485.963 377.559 487.22 376.302 488.77 376.302C490.32 376.302 491.576 377.559 491.576 379.109Z" />
          <path d="M491.576 372.267C491.576 373.816 490.32 375.073 488.77 375.073C487.22 375.073 485.963 373.816 485.963 372.267C485.963 370.717 487.22 369.46 488.77 369.46C490.32 369.46 491.576 370.717 491.576 372.267Z" />
          <path d="M491.576 365.424C491.576 366.974 490.32 368.231 488.77 368.231C487.22 368.231 485.963 366.974 485.963 365.424C485.963 363.875 487.22 362.618 488.77 362.618C490.32 362.618 491.576 363.875 491.576 365.424Z" />
          <path d="M498.418 365.424C498.418 366.974 497.162 368.231 495.612 368.231C494.062 368.231 492.805 366.974 492.805 365.424C492.805 363.875 494.062 362.618 495.612 362.618C497.162 362.618 498.418 363.875 498.418 365.424Z" />
          <path d="M525.786 365.424C525.786 366.974 524.53 368.231 522.98 368.231C521.43 368.231 520.172 366.974 520.172 365.424C520.172 363.875 521.43 362.618 522.98 362.618C524.53 362.618 525.786 363.875 525.786 365.424Z" />
          <path d="M532.628 365.424C532.628 366.974 531.371 368.231 529.821 368.231C528.271 368.231 527.014 366.974 527.014 365.424C527.014 363.875 528.271 362.618 529.821 362.618C531.371 362.618 532.628 363.875 532.628 365.424Z" />
          <path d="M532.628 358.583C532.628 360.133 531.371 361.39 529.821 361.39C528.271 361.39 527.014 360.133 527.014 358.583C527.014 357.033 528.271 355.776 529.821 355.776C531.371 355.776 532.628 357.033 532.628 358.583Z" />
          <path d="M525.786 372.267C525.786 373.816 524.53 375.073 522.98 375.073C521.43 375.073 520.172 373.816 520.172 372.267C520.172 370.717 521.43 369.46 522.98 369.46C524.53 369.46 525.786 370.717 525.786 372.267Z" />
          <path d="M525.786 379.109C525.786 380.658 524.53 381.915 522.98 381.915C521.43 381.915 520.172 380.658 520.172 379.109C520.172 377.559 521.43 376.302 522.98 376.302C524.53 376.302 525.786 377.559 525.786 379.109Z" />
          <path d="M518.944 379.109C518.944 380.658 517.687 381.915 516.137 381.915C514.587 381.915 513.33 380.658 513.33 379.109C513.33 377.559 514.587 376.302 516.137 376.302C517.687 376.302 518.944 377.559 518.944 379.109Z" />
          <path d="M498.418 358.583C498.418 360.133 497.162 361.39 495.612 361.39C494.062 361.39 492.805 360.133 492.805 358.583C492.805 357.033 494.062 355.776 495.612 355.776C497.162 355.776 498.418 357.033 498.418 358.583Z" />
          <path d="M505.26 358.583C505.26 360.133 504.004 361.39 502.454 361.39C500.904 361.39 499.646 360.133 499.646 358.583C499.646 357.033 500.904 355.776 502.454 355.776C504.004 355.776 505.26 357.033 505.26 358.583Z" />
          <path d="M505.26 351.741C505.26 353.291 504.004 354.548 502.454 354.548C500.904 354.548 499.646 353.291 499.646 351.741C499.646 350.191 500.904 348.934 502.454 348.934C504.004 348.934 505.26 350.191 505.26 351.741Z" />
          <path d="M505.26 344.899C505.26 346.449 504.004 347.706 502.454 347.706C500.904 347.706 499.646 346.449 499.646 344.899C499.646 343.349 500.904 342.093 502.454 342.093C504.004 342.093 505.26 343.349 505.26 344.899Z" />
          <path d="M498.418 344.899C498.418 346.449 497.162 347.706 495.612 347.706C494.062 347.706 492.805 346.449 492.805 344.899C492.805 343.349 494.062 342.093 495.612 342.093C497.162 342.093 498.418 343.349 498.418 344.899Z" />
          <path d="M505.26 338.057C505.26 339.607 504.004 340.864 502.454 340.864C500.904 340.864 499.646 339.607 499.646 338.057C499.646 336.507 500.904 335.25 502.454 335.25C504.004 335.25 505.26 336.507 505.26 338.057Z" />
          <path d="M505.26 331.215C505.26 332.765 504.004 334.022 502.454 334.022C500.904 334.022 499.646 332.765 499.646 331.215C499.646 329.666 500.904 328.409 502.454 328.409C504.004 328.409 505.26 329.666 505.26 331.215Z" />
          <path d="M505.26 324.373C505.26 325.923 504.004 327.18 502.454 327.18C500.904 327.18 499.646 325.923 499.646 324.373C499.646 322.824 500.904 321.567 502.454 321.567C504.004 321.567 505.26 322.824 505.26 324.373Z" />
          <path d="M505.26 317.532C505.26 319.082 504.004 320.339 502.454 320.339C500.904 320.339 499.646 319.082 499.646 317.532C499.646 315.982 500.904 314.725 502.454 314.725C504.004 314.725 505.26 315.982 505.26 317.532Z" />
          <path d="M512.102 317.532C512.102 319.082 510.845 320.339 509.296 320.339C507.746 320.339 506.488 319.082 506.488 317.532C506.488 315.982 507.746 314.725 509.296 314.725C510.845 314.725 512.102 315.982 512.102 317.532Z" />
          <path d="M512.102 310.69C512.102 312.24 510.845 313.497 509.296 313.497C507.746 313.497 506.488 312.24 506.488 310.69C506.488 309.14 507.746 307.883 509.296 307.883C510.845 307.883 512.102 309.14 512.102 310.69Z" />
          <path d="M518.944 310.69C518.944 312.24 517.687 313.497 516.137 313.497C514.587 313.497 513.33 312.24 513.33 310.69C513.33 309.14 514.587 307.883 516.137 307.883C517.687 307.883 518.944 309.14 518.944 310.69Z" />
          <path d="M518.944 303.848C518.944 305.398 517.687 306.655 516.137 306.655C514.587 306.655 513.33 305.398 513.33 303.848C513.33 302.298 514.587 301.041 516.137 301.041C517.687 301.041 518.944 302.298 518.944 303.848Z" />
          <path d="M525.786 303.848C525.786 305.398 524.53 306.655 522.98 306.655C521.43 306.655 520.172 305.398 520.172 303.848C520.172 302.298 521.43 301.041 522.98 301.041C524.53 301.041 525.786 302.298 525.786 303.848Z" />
          <path d="M525.786 297.006C525.786 298.556 524.53 299.813 522.98 299.813C521.43 299.813 520.172 298.556 520.172 297.006C520.172 295.456 521.43 294.199 522.98 294.199C524.53 294.199 525.786 295.456 525.786 297.006Z" />
          <path d="M518.944 297.006C518.944 298.556 517.687 299.813 516.137 299.813C514.587 299.813 513.33 298.556 513.33 297.006C513.33 295.456 514.587 294.199 516.137 294.199C517.687 294.199 518.944 295.456 518.944 297.006Z" />
          <path d="M512.102 297.006C512.102 298.556 510.845 299.813 509.296 299.813C507.746 299.813 506.488 298.556 506.488 297.006C506.488 295.456 507.746 294.199 509.296 294.199C510.845 294.199 512.102 295.456 512.102 297.006Z" />
          <path d="M512.102 290.164C512.102 291.714 510.845 292.971 509.296 292.971C507.746 292.971 506.488 291.714 506.488 290.164C506.488 288.615 507.746 287.358 509.296 287.358C510.845 287.358 512.102 288.615 512.102 290.164Z" />
          <path d="M518.944 283.323C518.944 284.873 517.687 286.129 516.137 286.129C514.587 286.129 513.33 284.873 513.33 283.323C513.33 281.773 514.587 280.516 516.137 280.516C517.687 280.516 518.944 281.773 518.944 283.323Z" />
          <path d="M525.786 276.481C525.786 278.031 524.53 279.288 522.98 279.288C521.43 279.288 520.172 278.031 520.172 276.481C520.172 274.931 521.43 273.674 522.98 273.674C524.53 273.674 525.786 274.931 525.786 276.481Z" />
          <path d="M532.628 276.481C532.628 278.031 531.371 279.288 529.821 279.288C528.271 279.288 527.014 278.031 527.014 276.481C527.014 274.931 528.271 273.674 529.821 273.674C531.371 273.674 532.628 274.931 532.628 276.481Z" />
          <path d="M539.469 276.481C539.469 278.031 538.213 279.288 536.663 279.288C535.113 279.288 533.856 278.031 533.856 276.481C533.856 274.931 535.113 273.674 536.663 273.674C538.213 273.674 539.469 274.931 539.469 276.481Z" />
          <path d="M539.469 269.639C539.469 271.189 538.213 272.445 536.663 272.445C535.113 272.445 533.856 271.189 533.856 269.639C533.856 268.089 535.113 266.832 536.663 266.832C538.213 266.832 539.469 268.089 539.469 269.639Z" />
          <path d="M546.311 269.639C546.311 271.189 545.055 272.445 543.505 272.445C541.955 272.445 540.698 271.189 540.698 269.639C540.698 268.089 541.955 266.832 543.505 266.832C545.055 266.832 546.311 268.089 546.311 269.639Z" />
          <path d="M546.311 262.797C546.311 264.347 545.055 265.604 543.505 265.604C541.955 265.604 540.698 264.347 540.698 262.797C540.698 261.247 541.955 259.99 543.505 259.99C545.055 259.99 546.311 261.247 546.311 262.797Z" />
          <path d="M546.311 255.955C546.311 257.505 545.055 258.762 543.505 258.762C541.955 258.762 540.698 257.505 540.698 255.955C540.698 254.405 541.955 253.148 543.505 253.148C545.055 253.148 546.311 254.405 546.311 255.955Z" />
          <path d="M539.469 262.797C539.469 264.347 538.213 265.604 536.663 265.604C535.113 265.604 533.856 264.347 533.856 262.797C533.856 261.247 535.113 259.99 536.663 259.99C538.213 259.99 539.469 261.247 539.469 262.797Z" />
          <path d="M532.628 262.797C532.628 264.347 531.371 265.604 529.821 265.604C528.271 265.604 527.014 264.347 527.014 262.797C527.014 261.247 528.271 259.99 529.821 259.99C531.371 259.99 532.628 261.247 532.628 262.797Z" />
          <path d="M532.628 255.955C532.628 257.505 531.371 258.762 529.821 258.762C528.271 258.762 527.014 257.505 527.014 255.955C527.014 254.405 528.271 253.148 529.821 253.148C531.371 253.148 532.628 254.405 532.628 255.955Z" />
          <path d="M525.786 255.955C525.786 257.505 524.53 258.762 522.98 258.762C521.43 258.762 520.172 257.505 520.172 255.955C520.172 254.405 521.43 253.148 522.98 253.148C524.53 253.148 525.786 254.405 525.786 255.955Z" />
          <path d="M525.786 249.113C525.786 250.663 524.53 251.92 522.98 251.92C521.43 251.92 520.172 250.663 520.172 249.113C520.172 247.563 521.43 246.307 522.98 246.307C524.53 246.307 525.786 247.563 525.786 249.113Z" />
          <path d="M525.786 242.272C525.786 243.822 524.53 245.078 522.98 245.078C521.43 245.078 520.172 243.822 520.172 242.272C520.172 240.722 521.43 239.465 522.98 239.465C524.53 239.465 525.786 240.722 525.786 242.272Z" />
          <path d="M518.944 242.272C518.944 243.822 517.687 245.078 516.137 245.078C514.587 245.078 513.33 243.822 513.33 242.272C513.33 240.722 514.587 239.465 516.137 239.465C517.687 239.465 518.944 240.722 518.944 242.272Z" />
          <path d="M518.944 235.43C518.944 236.98 517.687 238.236 516.137 238.236C514.587 238.236 513.33 236.98 513.33 235.43C513.33 233.88 514.587 232.623 516.137 232.623C517.687 232.623 518.944 233.88 518.944 235.43Z" />
          <path d="M512.102 235.43C512.102 236.98 510.845 238.236 509.296 238.236C507.746 238.236 506.488 236.98 506.488 235.43C506.488 233.88 507.746 232.623 509.296 232.623C510.845 232.623 512.102 233.88 512.102 235.43Z" />
          <path d="M512.102 242.272C512.102 243.822 510.845 245.078 509.296 245.078C507.746 245.078 506.488 243.822 506.488 242.272C506.488 240.722 507.746 239.465 509.296 239.465C510.845 239.465 512.102 240.722 512.102 242.272Z" />
          <path d="M505.26 242.272C505.26 243.822 504.004 245.078 502.454 245.078C500.904 245.078 499.646 243.822 499.646 242.272C499.646 240.722 500.904 239.465 502.454 239.465C504.004 239.465 505.26 240.722 505.26 242.272Z" />
          <path d="M498.418 242.272C498.418 243.822 497.162 245.078 495.612 245.078C494.062 245.078 492.805 243.822 492.805 242.272C492.805 240.722 494.062 239.465 495.612 239.465C497.162 239.465 498.418 240.722 498.418 242.272Z" />
          <path d="M505.26 249.113C505.26 250.663 504.004 251.92 502.454 251.92C500.904 251.92 499.646 250.663 499.646 249.113C499.646 247.563 500.904 246.307 502.454 246.307C504.004 246.307 505.26 247.563 505.26 249.113Z" />
          <path d="M512.102 249.113C512.102 250.663 510.845 251.92 509.296 251.92C507.746 251.92 506.488 250.663 506.488 249.113C506.488 247.563 507.746 246.307 509.296 246.307C510.845 246.307 512.102 247.563 512.102 249.113Z" />
          <path d="M512.102 255.955C512.102 257.505 510.845 258.762 509.296 258.762C507.746 258.762 506.488 257.505 506.488 255.955C506.488 254.405 507.746 253.148 509.296 253.148C510.845 253.148 512.102 254.405 512.102 255.955Z" />
          <path d="M518.944 255.955C518.944 257.505 517.687 258.762 516.137 258.762C514.587 258.762 513.33 257.505 513.33 255.955C513.33 254.405 514.587 253.148 516.137 253.148C517.687 253.148 518.944 254.405 518.944 255.955Z" />
          <path d="M518.944 249.113C518.944 250.663 517.687 251.92 516.137 251.92C514.587 251.92 513.33 250.663 513.33 249.113C513.33 247.563 514.587 246.307 516.137 246.307C517.687 246.307 518.944 247.563 518.944 249.113Z" />
          <path d="M512.102 262.797C512.102 264.347 510.845 265.604 509.296 265.604C507.746 265.604 506.488 264.347 506.488 262.797C506.488 261.247 507.746 259.99 509.296 259.99C510.845 259.99 512.102 261.247 512.102 262.797Z" />
          <path d="M518.944 262.797C518.944 264.347 517.687 265.604 516.137 265.604C514.587 265.604 513.33 264.347 513.33 262.797C513.33 261.247 514.587 259.99 516.137 259.99C517.687 259.99 518.944 261.247 518.944 262.797Z" />
          <path d="M518.944 269.639C518.944 271.189 517.687 272.445 516.137 272.445C514.587 272.445 513.33 271.189 513.33 269.639C513.33 268.089 514.587 266.832 516.137 266.832C517.687 266.832 518.944 268.089 518.944 269.639Z" />
          <path d="M525.786 269.639C525.786 271.189 524.53 272.445 522.98 272.445C521.43 272.445 520.172 271.189 520.172 269.639C520.172 268.089 521.43 266.832 522.98 266.832C524.53 266.832 525.786 268.089 525.786 269.639Z" />
          <path d="M525.786 262.797C525.786 264.347 524.53 265.604 522.98 265.604C521.43 265.604 520.172 264.347 520.172 262.797C520.172 261.247 521.43 259.99 522.98 259.99C524.53 259.99 525.786 261.247 525.786 262.797Z" />
          <path d="M532.628 269.639C532.628 271.189 531.371 272.445 529.821 272.445C528.271 272.445 527.014 271.189 527.014 269.639C527.014 268.089 528.271 266.832 529.821 266.832C531.371 266.832 532.628 268.089 532.628 269.639Z" />
          <path d="M553.153 262.797C553.153 264.347 551.897 265.604 550.347 265.604C548.797 265.604 547.539 264.347 547.539 262.797C547.539 261.247 548.797 259.99 550.347 259.99C551.897 259.99 553.153 261.247 553.153 262.797Z" />
          <path d="M505.26 290.164C505.26 291.714 504.004 292.971 502.454 292.971C500.904 292.971 499.646 291.714 499.646 290.164C499.646 288.615 500.904 287.358 502.454 287.358C504.004 287.358 505.26 288.615 505.26 290.164Z" />
          <path d="M505.26 283.323C505.26 284.873 504.004 286.129 502.454 286.129C500.904 286.129 499.646 284.873 499.646 283.323C499.646 281.773 500.904 280.516 502.454 280.516C504.004 280.516 505.26 281.773 505.26 283.323Z" />
          <path d="M505.26 276.481C505.26 278.031 504.004 279.288 502.454 279.288C500.904 279.288 499.646 278.031 499.646 276.481C499.646 274.931 500.904 273.674 502.454 273.674C504.004 273.674 505.26 274.931 505.26 276.481Z" />
          <path d="M498.418 283.323C498.418 284.873 497.162 286.129 495.612 286.129C494.062 286.129 492.805 284.873 492.805 283.323C492.805 281.773 494.062 280.516 495.612 280.516C497.162 280.516 498.418 281.773 498.418 283.323Z" />
          <path d="M498.418 276.481C498.418 278.031 497.162 279.288 495.612 279.288C494.062 279.288 492.805 278.031 492.805 276.481C492.805 274.931 494.062 273.674 495.612 273.674C497.162 273.674 498.418 274.931 498.418 276.481Z" />
          <path d="M498.418 269.639C498.418 271.189 497.162 272.445 495.612 272.445C494.062 272.445 492.805 271.189 492.805 269.639C492.805 268.089 494.062 266.832 495.612 266.832C497.162 266.832 498.418 268.089 498.418 269.639Z" />
          <path d="M498.418 262.797C498.418 264.347 497.162 265.604 495.612 265.604C494.062 265.604 492.805 264.347 492.805 262.797C492.805 261.247 494.062 259.99 495.612 259.99C497.162 259.99 498.418 261.247 498.418 262.797Z" />
          <path d="M491.576 262.797C491.576 264.347 490.32 265.604 488.77 265.604C487.22 265.604 485.963 264.347 485.963 262.797C485.963 261.247 487.22 259.99 488.77 259.99C490.32 259.99 491.576 261.247 491.576 262.797Z" />
          <path d="M491.576 255.955C491.576 257.505 490.32 258.762 488.77 258.762C487.22 258.762 485.963 257.505 485.963 255.955C485.963 254.405 487.22 253.148 488.77 253.148C490.32 253.148 491.576 254.405 491.576 255.955Z" />
          <path d="M491.576 249.113C491.576 250.663 490.32 251.92 488.77 251.92C487.22 251.92 485.963 250.663 485.963 249.113C485.963 247.563 487.22 246.307 488.77 246.307C490.32 246.307 491.576 247.563 491.576 249.113Z" />
          <path d="M484.734 249.113C484.734 250.663 483.478 251.92 481.928 251.92C480.378 251.92 479.121 250.663 479.121 249.113C479.121 247.563 480.378 246.307 481.928 246.307C483.478 246.307 484.734 247.563 484.734 249.113Z" />
          <path d="M477.893 249.113C477.893 250.663 476.636 251.92 475.086 251.92C473.536 251.92 472.279 250.663 472.279 249.113C472.279 247.563 473.536 246.307 475.086 246.307C476.636 246.307 477.893 247.563 477.893 249.113Z" />
          <path d="M471.051 249.113C471.051 250.663 469.794 251.92 468.245 251.92C466.695 251.92 465.437 250.663 465.437 249.113C465.437 247.563 466.695 246.307 468.245 246.307C469.794 246.307 471.051 247.563 471.051 249.113Z" />
          <path d="M464.209 249.113C464.209 250.663 462.953 251.92 461.403 251.92C459.853 251.92 458.595 250.663 458.595 249.113C458.595 247.563 459.853 246.307 461.403 246.307C462.953 246.307 464.209 247.563 464.209 249.113Z" />
          <path d="M457.367 249.113C457.367 250.663 456.111 251.92 454.561 251.92C453.011 251.92 451.754 250.663 451.754 249.113C451.754 247.563 453.011 246.307 454.561 246.307C456.111 246.307 457.367 247.563 457.367 249.113Z" />
          <path d="M450.525 249.113C450.525 250.663 449.269 251.92 447.719 251.92C446.169 251.92 444.912 250.663 444.912 249.113C444.912 247.563 446.169 246.307 447.719 246.307C449.269 246.307 450.525 247.563 450.525 249.113Z" />
          <path d="M443.683 249.113C443.683 250.663 442.427 251.92 440.877 251.92C439.327 251.92 438.07 250.663 438.07 249.113C438.07 247.563 439.327 246.307 440.877 246.307C442.427 246.307 443.683 247.563 443.683 249.113Z" />
          <path d="M436.842 249.113C436.842 250.663 435.585 251.92 434.036 251.92C432.486 251.92 431.228 250.663 431.228 249.113C431.228 247.563 432.486 246.307 434.036 246.307C435.585 246.307 436.842 247.563 436.842 249.113Z" />
          <path d="M436.842 242.272C436.842 243.822 435.585 245.078 434.036 245.078C432.486 245.078 431.228 243.822 431.228 242.272C431.228 240.722 432.486 239.465 434.036 239.465C435.585 239.465 436.842 240.722 436.842 242.272Z" />
          <path d="M430 242.272C430 243.822 428.744 245.078 427.194 245.078C425.644 245.078 424.386 243.822 424.386 242.272C424.386 240.722 425.644 239.465 427.194 239.465C428.744 239.465 430 240.722 430 242.272Z" />
          <path d="M423.158 242.272C423.158 243.822 421.902 245.078 420.352 245.078C418.802 245.078 417.544 243.822 417.544 242.272C417.544 240.722 418.802 239.465 420.352 239.465C421.902 239.465 423.158 240.722 423.158 242.272Z" />
          <path d="M423.158 235.43C423.158 236.98 421.902 238.236 420.352 238.236C418.802 238.236 417.544 236.98 417.544 235.43C417.544 233.88 418.802 232.623 420.352 232.623C421.902 232.623 423.158 233.88 423.158 235.43Z" />
          <path d="M416.316 235.43C416.316 236.98 415.06 238.236 413.51 238.236C411.96 238.236 410.702 236.98 410.702 235.43C410.702 233.88 411.96 232.623 413.51 232.623C415.06 232.623 416.316 233.88 416.316 235.43Z" />
          <path d="M409.474 235.43C409.474 236.98 408.218 238.236 406.668 238.236C405.118 238.236 403.861 236.98 403.861 235.43C403.861 233.88 405.118 232.623 406.668 232.623C408.218 232.623 409.474 233.88 409.474 235.43Z" />
          <path d="M402.633 235.43C402.633 236.98 401.376 238.236 399.826 238.236C398.276 238.236 397.019 236.98 397.019 235.43C397.019 233.88 398.276 232.623 399.826 232.623C401.376 232.623 402.633 233.88 402.633 235.43Z" />
          <path d="M402.633 242.272C402.633 243.822 401.376 245.078 399.826 245.078C398.276 245.078 397.019 243.822 397.019 242.272C397.019 240.722 398.276 239.465 399.826 239.465C401.376 239.465 402.633 240.722 402.633 242.272Z" />
          <path d="M395.791 242.272C395.791 243.822 394.534 245.078 392.984 245.078C391.434 245.078 390.177 243.822 390.177 242.272C390.177 240.722 391.434 239.465 392.984 239.465C394.534 239.465 395.791 240.722 395.791 242.272Z" />
          <path d="M395.791 249.113C395.791 250.663 394.534 251.92 392.984 251.92C391.434 251.92 390.177 250.663 390.177 249.113C390.177 247.563 391.434 246.307 392.984 246.307C394.534 246.307 395.791 247.563 395.791 249.113Z" />
          <path d="M395.791 255.955C395.791 257.505 394.534 258.762 392.984 258.762C391.434 258.762 390.177 257.505 390.177 255.955C390.177 254.405 391.434 253.148 392.984 253.148C394.534 253.148 395.791 254.405 395.791 255.955Z" />
          <path d="M388.949 255.955C388.949 257.505 387.692 258.762 386.142 258.762C384.592 258.762 383.335 257.505 383.335 255.955C383.335 254.405 384.592 253.148 386.142 253.148C387.692 253.148 388.949 254.405 388.949 255.955Z" />
          <path d="M388.949 262.797C388.949 264.347 387.692 265.604 386.142 265.604C384.592 265.604 383.335 264.347 383.335 262.797C383.335 261.247 384.592 259.99 386.142 259.99C387.692 259.99 388.949 261.247 388.949 262.797Z" />
          <path d="M382.107 262.797C382.107 264.347 380.85 265.604 379.301 265.604C377.751 265.604 376.493 264.347 376.493 262.797C376.493 261.247 377.751 259.99 379.301 259.99C380.85 259.99 382.107 261.247 382.107 262.797Z" />
          <path d="M382.107 269.639C382.107 271.189 380.85 272.445 379.301 272.445C377.751 272.445 376.493 271.189 376.493 269.639C376.493 268.089 377.751 266.832 379.301 266.832C380.85 266.832 382.107 268.089 382.107 269.639Z" />
          <path d="M382.107 276.481C382.107 278.031 380.85 279.288 379.301 279.288C377.751 279.288 376.493 278.031 376.493 276.481C376.493 274.931 377.751 273.674 379.301 273.674C380.85 273.674 382.107 274.931 382.107 276.481Z" />
          <path d="M382.107 283.323C382.107 284.873 380.85 286.129 379.301 286.129C377.751 286.129 376.493 284.873 376.493 283.323C376.493 281.773 377.751 280.516 379.301 280.516C380.85 280.516 382.107 281.773 382.107 283.323Z" />
          <path d="M388.949 283.323C388.949 284.873 387.692 286.129 386.142 286.129C384.592 286.129 383.335 284.873 383.335 283.323C383.335 281.773 384.592 280.516 386.142 280.516C387.692 280.516 388.949 281.773 388.949 283.323Z" />
          <path d="M395.791 283.323C395.791 284.873 394.534 286.129 392.984 286.129C391.434 286.129 390.177 284.873 390.177 283.323C390.177 281.773 391.434 280.516 392.984 280.516C394.534 280.516 395.791 281.773 395.791 283.323Z" />
          <path d="M402.633 283.323C402.633 284.873 401.376 286.129 399.826 286.129C398.276 286.129 397.019 284.873 397.019 283.323C397.019 281.773 398.276 280.516 399.826 280.516C401.376 280.516 402.633 281.773 402.633 283.323Z" />
          <path d="M409.474 283.323C409.474 284.873 408.218 286.129 406.668 286.129C405.118 286.129 403.861 284.873 403.861 283.323C403.861 281.773 405.118 280.516 406.668 280.516C408.218 280.516 409.474 281.773 409.474 283.323Z" />
          <path d="M416.316 283.323C416.316 284.873 415.06 286.129 413.51 286.129C411.96 286.129 410.702 284.873 410.702 283.323C410.702 281.773 411.96 280.516 413.51 280.516C415.06 280.516 416.316 281.773 416.316 283.323Z" />
          <path d="M416.316 290.164C416.316 291.714 415.06 292.971 413.51 292.971C411.96 292.971 410.702 291.714 410.702 290.164C410.702 288.615 411.96 287.358 413.51 287.358C415.06 287.358 416.316 288.615 416.316 290.164Z" />
          <path d="M423.158 290.164C423.158 291.714 421.902 292.971 420.352 292.971C418.802 292.971 417.544 291.714 417.544 290.164C417.544 288.615 418.802 287.358 420.352 287.358C421.902 287.358 423.158 288.615 423.158 290.164Z" />
          <path d="M430 290.164C430 291.714 428.744 292.971 427.194 292.971C425.644 292.971 424.386 291.714 424.386 290.164C424.386 288.615 425.644 287.358 427.194 287.358C428.744 287.358 430 288.615 430 290.164Z" />
          <path d="M436.842 290.164C436.842 291.714 435.585 292.971 434.036 292.971C432.486 292.971 431.228 291.714 431.228 290.164C431.228 288.615 432.486 287.358 434.036 287.358C435.585 287.358 436.842 288.615 436.842 290.164Z" />
          <path d="M436.842 297.006C436.842 298.556 435.585 299.813 434.036 299.813C432.486 299.813 431.228 298.556 431.228 297.006C431.228 295.456 432.486 294.199 434.036 294.199C435.585 294.199 436.842 295.456 436.842 297.006Z" />
          <path d="M443.683 297.006C443.683 298.556 442.427 299.813 440.877 299.813C439.327 299.813 438.07 298.556 438.07 297.006C438.07 295.456 439.327 294.199 440.877 294.199C442.427 294.199 443.683 295.456 443.683 297.006Z" />
          <path d="M443.683 303.848C443.683 305.398 442.427 306.655 440.877 306.655C439.327 306.655 438.07 305.398 438.07 303.848C438.07 302.298 439.327 301.041 440.877 301.041C442.427 301.041 443.683 302.298 443.683 303.848Z" />
          <path d="M443.683 310.69C443.683 312.24 442.427 313.497 440.877 313.497C439.327 313.497 438.07 312.24 438.07 310.69C438.07 309.14 439.327 307.883 440.877 307.883C442.427 307.883 443.683 309.14 443.683 310.69Z" />
          <path d="M443.683 317.532C443.683 319.082 442.427 320.339 440.877 320.339C439.327 320.339 438.07 319.082 438.07 317.532C438.07 315.982 439.327 314.725 440.877 314.725C442.427 314.725 443.683 315.982 443.683 317.532Z" />
          <path d="M450.525 317.532C450.525 319.082 449.269 320.339 447.719 320.339C446.169 320.339 444.912 319.082 444.912 317.532C444.912 315.982 446.169 314.725 447.719 314.725C449.269 314.725 450.525 315.982 450.525 317.532Z" />
          <path d="M450.525 324.373C450.525 325.923 449.269 327.18 447.719 327.18C446.169 327.18 444.912 325.923 444.912 324.373C444.912 322.824 446.169 321.567 447.719 321.567C449.269 321.567 450.525 322.824 450.525 324.373Z" />
          <path d="M457.367 324.373C457.367 325.923 456.111 327.18 454.561 327.18C453.011 327.18 451.754 325.923 451.754 324.373C451.754 322.824 453.011 321.567 454.561 321.567C456.111 321.567 457.367 322.824 457.367 324.373Z" />
          <path d="M457.367 331.215C457.367 332.765 456.111 334.022 454.561 334.022C453.011 334.022 451.754 332.765 451.754 331.215C451.754 329.666 453.011 328.409 454.561 328.409C456.111 328.409 457.367 329.666 457.367 331.215Z" />
          <path d="M457.367 338.057C457.367 339.607 456.111 340.864 454.561 340.864C453.011 340.864 451.754 339.607 451.754 338.057C451.754 336.507 453.011 335.25 454.561 335.25C456.111 335.25 457.367 336.507 457.367 338.057Z" />
          <path d="M457.367 344.899C457.367 346.449 456.111 347.706 454.561 347.706C453.011 347.706 451.754 346.449 451.754 344.899C451.754 343.349 453.011 342.093 454.561 342.093C456.111 342.093 457.367 343.349 457.367 344.899Z" />
          <path d="M457.367 351.741C457.367 353.291 456.111 354.548 454.561 354.548C453.011 354.548 451.754 353.291 451.754 351.741C451.754 350.191 453.011 348.934 454.561 348.934C456.111 348.934 457.367 350.191 457.367 351.741Z" />
          <path d="M457.367 358.583C457.367 360.133 456.111 361.39 454.561 361.39C453.011 361.39 451.754 360.133 451.754 358.583C451.754 357.033 453.011 355.776 454.561 355.776C456.111 355.776 457.367 357.033 457.367 358.583Z" />
          <path d="M457.367 365.424C457.367 366.974 456.111 368.231 454.561 368.231C453.011 368.231 451.754 366.974 451.754 365.424C451.754 363.875 453.011 362.618 454.561 362.618C456.111 362.618 457.367 363.875 457.367 365.424Z" />
          <path d="M457.367 372.267C457.367 373.816 456.111 375.073 454.561 375.073C453.011 375.073 451.754 373.816 451.754 372.267C451.754 370.717 453.011 369.46 454.561 369.46C456.111 369.46 457.367 370.717 457.367 372.267Z" />
          <path d="M457.367 379.109C457.367 380.658 456.111 381.915 454.561 381.915C453.011 381.915 451.754 380.658 451.754 379.109C451.754 377.559 453.011 376.302 454.561 376.302C456.111 376.302 457.367 377.559 457.367 379.109Z" />
          <path d="M457.367 385.95C457.367 387.5 456.111 388.757 454.561 388.757C453.011 388.757 451.754 387.5 451.754 385.95C451.754 384.4 453.011 383.144 454.561 383.144C456.111 383.144 457.367 384.4 457.367 385.95Z" />
          <path d="M464.209 385.95C464.209 387.5 462.953 388.757 461.403 388.757C459.853 388.757 458.595 387.5 458.595 385.95C458.595 384.4 459.853 383.144 461.403 383.144C462.953 383.144 464.209 384.4 464.209 385.95Z" />
          <path d="M464.209 392.792C464.209 394.342 462.953 395.599 461.403 395.599C459.853 395.599 458.595 394.342 458.595 392.792C458.595 391.242 459.853 389.985 461.403 389.985C462.953 389.985 464.209 391.242 464.209 392.792Z" />
          <path d="M471.051 392.792C471.051 394.342 469.794 395.599 468.245 395.599C466.695 395.599 465.437 394.342 465.437 392.792C465.437 391.242 466.695 389.985 468.245 389.985C469.794 389.985 471.051 391.242 471.051 392.792Z" />
          <path d="M471.051 385.95C471.051 387.5 469.794 388.757 468.245 388.757C466.695 388.757 465.437 387.5 465.437 385.95C465.437 384.4 466.695 383.144 468.245 383.144C469.794 383.144 471.051 384.4 471.051 385.95Z" />
          <path d="M477.893 385.95C477.893 387.5 476.636 388.757 475.086 388.757C473.536 388.757 472.279 387.5 472.279 385.95C472.279 384.4 473.536 383.144 475.086 383.144C476.636 383.144 477.893 384.4 477.893 385.95Z" />
          <path d="M477.893 379.109C477.893 380.658 476.636 381.915 475.086 381.915C473.536 381.915 472.279 380.658 472.279 379.109C472.279 377.559 473.536 376.302 475.086 376.302C476.636 376.302 477.893 377.559 477.893 379.109Z" />
          <path d="M477.893 372.267C477.893 373.816 476.636 375.073 475.086 375.073C473.536 375.073 472.279 373.816 472.279 372.267C472.279 370.717 473.536 369.46 475.086 369.46C476.636 369.46 477.893 370.717 477.893 372.267Z" />
          <path d="M484.734 372.267C484.734 373.816 483.478 375.073 481.928 375.073C480.378 375.073 479.121 373.816 479.121 372.267C479.121 370.717 480.378 369.46 481.928 369.46C483.478 369.46 484.734 370.717 484.734 372.267Z" />
          <path d="M484.734 365.424C484.734 366.974 483.478 368.231 481.928 368.231C480.378 368.231 479.121 366.974 479.121 365.424C479.121 363.875 480.378 362.618 481.928 362.618C483.478 362.618 484.734 363.875 484.734 365.424Z" />
          <path d="M484.734 358.583C484.734 360.133 483.478 361.39 481.928 361.39C480.378 361.39 479.121 360.133 479.121 358.583C479.121 357.033 480.378 355.776 481.928 355.776C483.478 355.776 484.734 357.033 484.734 358.583Z" />
          <path d="M491.576 358.583C491.576 360.133 490.32 361.39 488.77 361.39C487.22 361.39 485.963 360.133 485.963 358.583C485.963 357.033 487.22 355.776 488.77 355.776C490.32 355.776 491.576 357.033 491.576 358.583Z" />
          <path d="M491.576 351.741C491.576 353.291 490.32 354.548 488.77 354.548C487.22 354.548 485.963 353.291 485.963 351.741C485.963 350.191 487.22 348.934 488.77 348.934C490.32 348.934 491.576 350.191 491.576 351.741Z" />
          <path d="M491.576 344.899C491.576 346.449 490.32 347.706 488.77 347.706C487.22 347.706 485.963 346.449 485.963 344.899C485.963 343.349 487.22 342.093 488.77 342.093C490.32 342.093 491.576 343.349 491.576 344.899Z" />
          <path d="M491.576 338.057C491.576 339.607 490.32 340.864 488.77 340.864C487.22 340.864 485.963 339.607 485.963 338.057C485.963 336.507 487.22 335.25 488.77 335.25C490.32 335.25 491.576 336.507 491.576 338.057Z" />
          <path d="M498.418 338.057C498.418 339.607 497.162 340.864 495.612 340.864C494.062 340.864 492.805 339.607 492.805 338.057C492.805 336.507 494.062 335.25 495.612 335.25C497.162 335.25 498.418 336.507 498.418 338.057Z" />
          <path d="M498.418 331.215C498.418 332.765 497.162 334.022 495.612 334.022C494.062 334.022 492.805 332.765 492.805 331.215C492.805 329.666 494.062 328.409 495.612 328.409C497.162 328.409 498.418 329.666 498.418 331.215Z" />
          <path d="M491.576 331.215C491.576 332.765 490.32 334.022 488.77 334.022C487.22 334.022 485.963 332.765 485.963 331.215C485.963 329.666 487.22 328.409 488.77 328.409C490.32 328.409 491.576 329.666 491.576 331.215Z" />
          <path d="M498.418 324.373C498.418 325.923 497.162 327.18 495.612 327.18C494.062 327.18 492.805 325.923 492.805 324.373C492.805 322.824 494.062 321.567 495.612 321.567C497.162 321.567 498.418 322.824 498.418 324.373Z" />
          <path d="M498.418 317.532C498.418 319.082 497.162 320.339 495.612 320.339C494.062 320.339 492.805 319.082 492.805 317.532C492.805 315.982 494.062 314.725 495.612 314.725C497.162 314.725 498.418 315.982 498.418 317.532Z" />
          <path d="M498.418 310.69C498.418 312.24 497.162 313.497 495.612 313.497C494.062 313.497 492.805 312.24 492.805 310.69C492.805 309.14 494.062 307.883 495.612 307.883C497.162 307.883 498.418 309.14 498.418 310.69Z" />
          <path d="M505.26 310.69C505.26 312.24 504.004 313.497 502.454 313.497C500.904 313.497 499.646 312.24 499.646 310.69C499.646 309.14 500.904 307.883 502.454 307.883C504.004 307.883 505.26 309.14 505.26 310.69Z" />
          <path d="M505.26 303.848C505.26 305.398 504.004 306.655 502.454 306.655C500.904 306.655 499.646 305.398 499.646 303.848C499.646 302.298 500.904 301.041 502.454 301.041C504.004 301.041 505.26 302.298 505.26 303.848Z" />
          <path d="M512.102 303.848C512.102 305.398 510.845 306.655 509.296 306.655C507.746 306.655 506.488 305.398 506.488 303.848C506.488 302.298 507.746 301.041 509.296 301.041C510.845 301.041 512.102 302.298 512.102 303.848Z" />
          <path d="M505.26 297.006C505.26 298.556 504.004 299.813 502.454 299.813C500.904 299.813 499.646 298.556 499.646 297.006C499.646 295.456 500.904 294.199 502.454 294.199C504.004 294.199 505.26 295.456 505.26 297.006Z" />
          <path d="M498.418 297.006C498.418 298.556 497.162 299.813 495.612 299.813C494.062 299.813 492.805 298.556 492.805 297.006C492.805 295.456 494.062 294.199 495.612 294.199C497.162 294.199 498.418 295.456 498.418 297.006Z" />
          <path d="M498.418 303.848C498.418 305.398 497.162 306.655 495.612 306.655C494.062 306.655 492.805 305.398 492.805 303.848C492.805 302.298 494.062 301.041 495.612 301.041C497.162 301.041 498.418 302.298 498.418 303.848Z" />
          <path d="M491.576 303.848C491.576 305.398 490.32 306.655 488.77 306.655C487.22 306.655 485.963 305.398 485.963 303.848C485.963 302.298 487.22 301.041 488.77 301.041C490.32 301.041 491.576 302.298 491.576 303.848Z" />
          <path d="M491.576 297.006C491.576 298.556 490.32 299.813 488.77 299.813C487.22 299.813 485.963 298.556 485.963 297.006C485.963 295.456 487.22 294.199 488.77 294.199C490.32 294.199 491.576 295.456 491.576 297.006Z" />
          <path d="M491.576 290.164C491.576 291.714 490.32 292.971 488.77 292.971C487.22 292.971 485.963 291.714 485.963 290.164C485.963 288.615 487.22 287.358 488.77 287.358C490.32 287.358 491.576 288.615 491.576 290.164Z" />
          <path d="M498.418 290.164C498.418 291.714 497.162 292.971 495.612 292.971C494.062 292.971 492.805 291.714 492.805 290.164C492.805 288.615 494.062 287.358 495.612 287.358C497.162 287.358 498.418 288.615 498.418 290.164Z" />
          <path d="M491.576 283.323C491.576 284.873 490.32 286.129 488.77 286.129C487.22 286.129 485.963 284.873 485.963 283.323C485.963 281.773 487.22 280.516 488.77 280.516C490.32 280.516 491.576 281.773 491.576 283.323Z" />
          <path d="M491.576 276.481C491.576 278.031 490.32 279.288 488.77 279.288C487.22 279.288 485.963 278.031 485.963 276.481C485.963 274.931 487.22 273.674 488.77 273.674C490.32 273.674 491.576 274.931 491.576 276.481Z" />
          <path d="M491.576 269.639C491.576 271.189 490.32 272.445 488.77 272.445C487.22 272.445 485.963 271.189 485.963 269.639C485.963 268.089 487.22 266.832 488.77 266.832C490.32 266.832 491.576 268.089 491.576 269.639Z" />
          <path d="M484.734 269.639C484.734 271.189 483.478 272.445 481.928 272.445C480.378 272.445 479.121 271.189 479.121 269.639C479.121 268.089 480.378 266.832 481.928 266.832C483.478 266.832 484.734 268.089 484.734 269.639Z" />
          <path d="M484.734 262.797C484.734 264.347 483.478 265.604 481.928 265.604C480.378 265.604 479.121 264.347 479.121 262.797C479.121 261.247 480.378 259.99 481.928 259.99C483.478 259.99 484.734 261.247 484.734 262.797Z" />
          <path d="M484.734 255.955C484.734 257.505 483.478 258.762 481.928 258.762C480.378 258.762 479.121 257.505 479.121 255.955C479.121 254.405 480.378 253.148 481.928 253.148C483.478 253.148 484.734 254.405 484.734 255.955Z" />
          <path d="M477.893 255.955C477.893 257.505 476.636 258.762 475.086 258.762C473.536 258.762 472.279 257.505 472.279 255.955C472.279 254.405 473.536 253.148 475.086 253.148C476.636 253.148 477.893 254.405 477.893 255.955Z" />
          <path d="M471.051 255.955C471.051 257.505 469.794 258.762 468.245 258.762C466.695 258.762 465.437 257.505 465.437 255.955C465.437 254.405 466.695 253.148 468.245 253.148C469.794 253.148 471.051 254.405 471.051 255.955Z" />
          <path d="M464.209 255.955C464.209 257.505 462.953 258.762 461.403 258.762C459.853 258.762 458.595 257.505 458.595 255.955C458.595 254.405 459.853 253.148 461.403 253.148C462.953 253.148 464.209 254.405 464.209 255.955Z" />
          <path d="M457.367 255.955C457.367 257.505 456.111 258.762 454.561 258.762C453.011 258.762 451.754 257.505 451.754 255.955C451.754 254.405 453.011 253.148 454.561 253.148C456.111 253.148 457.367 254.405 457.367 255.955Z" />
          <path d="M450.525 255.955C450.525 257.505 449.269 258.762 447.719 258.762C446.169 258.762 444.912 257.505 444.912 255.955C444.912 254.405 446.169 253.148 447.719 253.148C449.269 253.148 450.525 254.405 450.525 255.955Z" />
          <path d="M443.683 255.955C443.683 257.505 442.427 258.762 440.877 258.762C439.327 258.762 438.07 257.505 438.07 255.955C438.07 254.405 439.327 253.148 440.877 253.148C442.427 253.148 443.683 254.405 443.683 255.955Z" />
          <path d="M436.842 255.955C436.842 257.505 435.585 258.762 434.036 258.762C432.486 258.762 431.228 257.505 431.228 255.955C431.228 254.405 432.486 253.148 434.036 253.148C435.585 253.148 436.842 254.405 436.842 255.955Z" />
          <path d="M430 255.955C430 257.505 428.744 258.762 427.194 258.762C425.644 258.762 424.386 257.505 424.386 255.955C424.386 254.405 425.644 253.148 427.194 253.148C428.744 253.148 430 254.405 430 255.955Z" />
          <path d="M430 249.113C430 250.663 428.744 251.92 427.194 251.92C425.644 251.92 424.386 250.663 424.386 249.113C424.386 247.563 425.644 246.307 427.194 246.307C428.744 246.307 430 247.563 430 249.113Z" />
          <path d="M423.158 249.113C423.158 250.663 421.902 251.92 420.352 251.92C418.802 251.92 417.544 250.663 417.544 249.113C417.544 247.563 418.802 246.307 420.352 246.307C421.902 246.307 423.158 247.563 423.158 249.113Z" />
          <path d="M416.316 249.113C416.316 250.663 415.06 251.92 413.51 251.92C411.96 251.92 410.702 250.663 410.702 249.113C410.702 247.563 411.96 246.307 413.51 246.307C415.06 246.307 416.316 247.563 416.316 249.113Z" />
          <path d="M416.316 242.272C416.316 243.822 415.06 245.078 413.51 245.078C411.96 245.078 410.702 243.822 410.702 242.272C410.702 240.722 411.96 239.465 413.51 239.465C415.06 239.465 416.316 240.722 416.316 242.272Z" />
          <path d="M409.474 242.272C409.474 243.822 408.218 245.078 406.668 245.078C405.118 245.078 403.861 243.822 403.861 242.272C403.861 240.722 405.118 239.465 406.668 239.465C408.218 239.465 409.474 240.722 409.474 242.272Z" />
          <path d="M409.474 249.113C409.474 250.663 408.218 251.92 406.668 251.92C405.118 251.92 403.861 250.663 403.861 249.113C403.861 247.563 405.118 246.307 406.668 246.307C408.218 246.307 409.474 247.563 409.474 249.113Z" />
          <path d="M402.633 249.113C402.633 250.663 401.376 251.92 399.826 251.92C398.276 251.92 397.019 250.663 397.019 249.113C397.019 247.563 398.276 246.307 399.826 246.307C401.376 246.307 402.633 247.563 402.633 249.113Z" />
          <path d="M402.633 255.955C402.633 257.505 401.376 258.762 399.826 258.762C398.276 258.762 397.019 257.505 397.019 255.955C397.019 254.405 398.276 253.148 399.826 253.148C401.376 253.148 402.633 254.405 402.633 255.955Z" />
          <path d="M402.633 262.797C402.633 264.347 401.376 265.604 399.826 265.604C398.276 265.604 397.019 264.347 397.019 262.797C397.019 261.247 398.276 259.99 399.826 259.99C401.376 259.99 402.633 261.247 402.633 262.797Z" />
          <path d="M395.791 262.797C395.791 264.347 394.534 265.604 392.984 265.604C391.434 265.604 390.177 264.347 390.177 262.797C390.177 261.247 391.434 259.99 392.984 259.99C394.534 259.99 395.791 261.247 395.791 262.797Z" />
          <path d="M395.791 269.639C395.791 271.189 394.534 272.445 392.984 272.445C391.434 272.445 390.177 271.189 390.177 269.639C390.177 268.089 391.434 266.832 392.984 266.832C394.534 266.832 395.791 268.089 395.791 269.639Z" />
          <path d="M388.949 269.639C388.949 271.189 387.692 272.445 386.142 272.445C384.592 272.445 383.335 271.189 383.335 269.639C383.335 268.089 384.592 266.832 386.142 266.832C387.692 266.832 388.949 268.089 388.949 269.639Z" />
          <path d="M388.949 276.481C388.949 278.031 387.692 279.288 386.142 279.288C384.592 279.288 383.335 278.031 383.335 276.481C383.335 274.931 384.592 273.674 386.142 273.674C387.692 273.674 388.949 274.931 388.949 276.481Z" />
          <path d="M395.791 276.481C395.791 278.031 394.534 279.288 392.984 279.288C391.434 279.288 390.177 278.031 390.177 276.481C390.177 274.931 391.434 273.674 392.984 273.674C394.534 273.674 395.791 274.931 395.791 276.481Z" />
          <path d="M402.633 276.481C402.633 278.031 401.376 279.288 399.826 279.288C398.276 279.288 397.019 278.031 397.019 276.481C397.019 274.931 398.276 273.674 399.826 273.674C401.376 273.674 402.633 274.931 402.633 276.481Z" />
          <path d="M409.474 276.481C409.474 278.031 408.218 279.288 406.668 279.288C405.118 279.288 403.861 278.031 403.861 276.481C403.861 274.931 405.118 273.674 406.668 273.674C408.218 273.674 409.474 274.931 409.474 276.481Z" />
          <path d="M416.316 276.481C416.316 278.031 415.06 279.288 413.51 279.288C411.96 279.288 410.702 278.031 410.702 276.481C410.702 274.931 411.96 273.674 413.51 273.674C415.06 273.674 416.316 274.931 416.316 276.481Z" />
          <path d="M423.158 276.481C423.158 278.031 421.902 279.288 420.352 279.288C418.802 279.288 417.544 278.031 417.544 276.481C417.544 274.931 418.802 273.674 420.352 273.674C421.902 273.674 423.158 274.931 423.158 276.481Z" />
          <path d="M430 276.481C430 278.031 428.744 279.288 427.194 279.288C425.644 279.288 424.386 278.031 424.386 276.481C424.386 274.931 425.644 273.674 427.194 273.674C428.744 273.674 430 274.931 430 276.481Z" />
          <path d="M423.158 283.323C423.158 284.873 421.902 286.129 420.352 286.129C418.802 286.129 417.544 284.873 417.544 283.323C417.544 281.773 418.802 280.516 420.352 280.516C421.902 280.516 423.158 281.773 423.158 283.323Z" />
          <path d="M430 283.323C430 284.873 428.744 286.129 427.194 286.129C425.644 286.129 424.386 284.873 424.386 283.323C424.386 281.773 425.644 280.516 427.194 280.516C428.744 280.516 430 281.773 430 283.323Z" />
          <path d="M436.842 283.323C436.842 284.873 435.585 286.129 434.036 286.129C432.486 286.129 431.228 284.873 431.228 283.323C431.228 281.773 432.486 280.516 434.036 280.516C435.585 280.516 436.842 281.773 436.842 283.323Z" />
          <path d="M443.683 283.323C443.683 284.873 442.427 286.129 440.877 286.129C439.327 286.129 438.07 284.873 438.07 283.323C438.07 281.773 439.327 280.516 440.877 280.516C442.427 280.516 443.683 281.773 443.683 283.323Z" />
          <path d="M443.683 290.164C443.683 291.714 442.427 292.971 440.877 292.971C439.327 292.971 438.07 291.714 438.07 290.164C438.07 288.615 439.327 287.358 440.877 287.358C442.427 287.358 443.683 288.615 443.683 290.164Z" />
          <path d="M450.525 290.164C450.525 291.714 449.269 292.971 447.719 292.971C446.169 292.971 444.912 291.714 444.912 290.164C444.912 288.615 446.169 287.358 447.719 287.358C449.269 287.358 450.525 288.615 450.525 290.164Z" />
          <path d="M450.525 297.006C450.525 298.556 449.269 299.813 447.719 299.813C446.169 299.813 444.912 298.556 444.912 297.006C444.912 295.456 446.169 294.199 447.719 294.199C449.269 294.199 450.525 295.456 450.525 297.006Z" />
          <path d="M450.525 303.848C450.525 305.398 449.269 306.655 447.719 306.655C446.169 306.655 444.912 305.398 444.912 303.848C444.912 302.298 446.169 301.041 447.719 301.041C449.269 301.041 450.525 302.298 450.525 303.848Z" />
          <path d="M450.525 310.69C450.525 312.24 449.269 313.497 447.719 313.497C446.169 313.497 444.912 312.24 444.912 310.69C444.912 309.14 446.169 307.883 447.719 307.883C449.269 307.883 450.525 309.14 450.525 310.69Z" />
          <path d="M457.367 310.69C457.367 312.24 456.111 313.497 454.561 313.497C453.011 313.497 451.754 312.24 451.754 310.69C451.754 309.14 453.011 307.883 454.561 307.883C456.111 307.883 457.367 309.14 457.367 310.69Z" />
          <path d="M457.367 317.532C457.367 319.082 456.111 320.339 454.561 320.339C453.011 320.339 451.754 319.082 451.754 317.532C451.754 315.982 453.011 314.725 454.561 314.725C456.111 314.725 457.367 315.982 457.367 317.532Z" />
          <path d="M464.209 317.532C464.209 319.082 462.953 320.339 461.403 320.339C459.853 320.339 458.595 319.082 458.595 317.532C458.595 315.982 459.853 314.725 461.403 314.725C462.953 314.725 464.209 315.982 464.209 317.532Z" />
          <path d="M464.209 324.373C464.209 325.923 462.953 327.18 461.403 327.18C459.853 327.18 458.595 325.923 458.595 324.373C458.595 322.824 459.853 321.567 461.403 321.567C462.953 321.567 464.209 322.824 464.209 324.373Z" />
          <path d="M464.209 331.215C464.209 332.765 462.953 334.022 461.403 334.022C459.853 334.022 458.595 332.765 458.595 331.215C458.595 329.666 459.853 328.409 461.403 328.409C462.953 328.409 464.209 329.666 464.209 331.215Z" />
          <path d="M464.209 338.057C464.209 339.607 462.953 340.864 461.403 340.864C459.853 340.864 458.595 339.607 458.595 338.057C458.595 336.507 459.853 335.25 461.403 335.25C462.953 335.25 464.209 336.507 464.209 338.057Z" />
          <path d="M464.209 344.899C464.209 346.449 462.953 347.706 461.403 347.706C459.853 347.706 458.595 346.449 458.595 344.899C458.595 343.349 459.853 342.093 461.403 342.093C462.953 342.093 464.209 343.349 464.209 344.899Z" />
          <path d="M464.209 351.741C464.209 353.291 462.953 354.548 461.403 354.548C459.853 354.548 458.595 353.291 458.595 351.741C458.595 350.191 459.853 348.934 461.403 348.934C462.953 348.934 464.209 350.191 464.209 351.741Z" />
          <path d="M464.209 358.583C464.209 360.133 462.953 361.39 461.403 361.39C459.853 361.39 458.595 360.133 458.595 358.583C458.595 357.033 459.853 355.776 461.403 355.776C462.953 355.776 464.209 357.033 464.209 358.583Z" />
          <path d="M464.209 365.424C464.209 366.974 462.953 368.231 461.403 368.231C459.853 368.231 458.595 366.974 458.595 365.424C458.595 363.875 459.853 362.618 461.403 362.618C462.953 362.618 464.209 363.875 464.209 365.424Z" />
          <path d="M464.209 372.267C464.209 373.816 462.953 375.073 461.403 375.073C459.853 375.073 458.595 373.816 458.595 372.267C458.595 370.717 459.853 369.46 461.403 369.46C462.953 369.46 464.209 370.717 464.209 372.267Z" />
          <path d="M464.209 379.109C464.209 380.658 462.953 381.915 461.403 381.915C459.853 381.915 458.595 380.658 458.595 379.109C458.595 377.559 459.853 376.302 461.403 376.302C462.953 376.302 464.209 377.559 464.209 379.109Z" />
          <path d="M471.051 379.109C471.051 380.658 469.794 381.915 468.245 381.915C466.695 381.915 465.437 380.658 465.437 379.109C465.437 377.559 466.695 376.302 468.245 376.302C469.794 376.302 471.051 377.559 471.051 379.109Z" />
          <path d="M471.051 372.267C471.051 373.816 469.794 375.073 468.245 375.073C466.695 375.073 465.437 373.816 465.437 372.267C465.437 370.717 466.695 369.46 468.245 369.46C469.794 369.46 471.051 370.717 471.051 372.267Z" />
          <path d="M471.051 365.424C471.051 366.974 469.794 368.231 468.245 368.231C466.695 368.231 465.437 366.974 465.437 365.424C465.437 363.875 466.695 362.618 468.245 362.618C469.794 362.618 471.051 363.875 471.051 365.424Z" />
          <path d="M477.893 365.424C477.893 366.974 476.636 368.231 475.086 368.231C473.536 368.231 472.279 366.974 472.279 365.424C472.279 363.875 473.536 362.618 475.086 362.618C476.636 362.618 477.893 363.875 477.893 365.424Z" />
          <path d="M471.051 358.583C471.051 360.133 469.794 361.39 468.245 361.39C466.695 361.39 465.437 360.133 465.437 358.583C465.437 357.033 466.695 355.776 468.245 355.776C469.794 355.776 471.051 357.033 471.051 358.583Z" />
          <path d="M471.051 351.741C471.051 353.291 469.794 354.548 468.245 354.548C466.695 354.548 465.437 353.291 465.437 351.741C465.437 350.191 466.695 348.934 468.245 348.934C469.794 348.934 471.051 350.191 471.051 351.741Z" />
          <path d="M471.051 344.899C471.051 346.449 469.794 347.706 468.245 347.706C466.695 347.706 465.437 346.449 465.437 344.899C465.437 343.349 466.695 342.093 468.245 342.093C469.794 342.093 471.051 343.349 471.051 344.899Z" />
          <path d="M471.051 338.057C471.051 339.607 469.794 340.864 468.245 340.864C466.695 340.864 465.437 339.607 465.437 338.057C465.437 336.507 466.695 335.25 468.245 335.25C469.794 335.25 471.051 336.507 471.051 338.057Z" />
          <path d="M471.051 331.215C471.051 332.765 469.794 334.022 468.245 334.022C466.695 334.022 465.437 332.765 465.437 331.215C465.437 329.666 466.695 328.409 468.245 328.409C469.794 328.409 471.051 329.666 471.051 331.215Z" />
          <path d="M471.051 324.373C471.051 325.923 469.794 327.18 468.245 327.18C466.695 327.18 465.437 325.923 465.437 324.373C465.437 322.824 466.695 321.567 468.245 321.567C469.794 321.567 471.051 322.824 471.051 324.373Z" />
          <path d="M471.051 317.532C471.051 319.082 469.794 320.339 468.245 320.339C466.695 320.339 465.437 319.082 465.437 317.532C465.437 315.982 466.695 314.725 468.245 314.725C469.794 314.725 471.051 315.982 471.051 317.532Z" />
          <path d="M477.893 317.532C477.893 319.082 476.636 320.339 475.086 320.339C473.536 320.339 472.279 319.082 472.279 317.532C472.279 315.982 473.536 314.725 475.086 314.725C476.636 314.725 477.893 315.982 477.893 317.532Z" />
          <path d="M477.893 310.69C477.893 312.24 476.636 313.497 475.086 313.497C473.536 313.497 472.279 312.24 472.279 310.69C472.279 309.14 473.536 307.883 475.086 307.883C476.636 307.883 477.893 309.14 477.893 310.69Z" />
          <path d="M477.893 303.848C477.893 305.398 476.636 306.655 475.086 306.655C473.536 306.655 472.279 305.398 472.279 303.848C472.279 302.298 473.536 301.041 475.086 301.041C476.636 301.041 477.893 302.298 477.893 303.848Z" />
          <path d="M477.893 297.006C477.893 298.556 476.636 299.813 475.086 299.813C473.536 299.813 472.279 298.556 472.279 297.006C472.279 295.456 473.536 294.199 475.086 294.199C476.636 294.199 477.893 295.456 477.893 297.006Z" />
          <path d="M484.734 297.006C484.734 298.556 483.478 299.813 481.928 299.813C480.378 299.813 479.121 298.556 479.121 297.006C479.121 295.456 480.378 294.199 481.928 294.199C483.478 294.199 484.734 295.456 484.734 297.006Z" />
          <path d="M484.734 290.164C484.734 291.714 483.478 292.971 481.928 292.971C480.378 292.971 479.121 291.714 479.121 290.164C479.121 288.615 480.378 287.358 481.928 287.358C483.478 287.358 484.734 288.615 484.734 290.164Z" />
          <path d="M484.734 283.323C484.734 284.873 483.478 286.129 481.928 286.129C480.378 286.129 479.121 284.873 479.121 283.323C479.121 281.773 480.378 280.516 481.928 280.516C483.478 280.516 484.734 281.773 484.734 283.323Z" />
          <path d="M484.734 276.481C484.734 278.031 483.478 279.288 481.928 279.288C480.378 279.288 479.121 278.031 479.121 276.481C479.121 274.931 480.378 273.674 481.928 273.674C483.478 273.674 484.734 274.931 484.734 276.481Z" />
          <path d="M477.893 276.481C477.893 278.031 476.636 279.288 475.086 279.288C473.536 279.288 472.279 278.031 472.279 276.481C472.279 274.931 473.536 273.674 475.086 273.674C476.636 273.674 477.893 274.931 477.893 276.481Z" />
          <path d="M477.893 269.639C477.893 271.189 476.636 272.445 475.086 272.445C473.536 272.445 472.279 271.189 472.279 269.639C472.279 268.089 473.536 266.832 475.086 266.832C476.636 266.832 477.893 268.089 477.893 269.639Z" />
          <path d="M477.893 262.797C477.893 264.347 476.636 265.604 475.086 265.604C473.536 265.604 472.279 264.347 472.279 262.797C472.279 261.247 473.536 259.99 475.086 259.99C476.636 259.99 477.893 261.247 477.893 262.797Z" />
          <path d="M471.051 262.797C471.051 264.347 469.794 265.604 468.245 265.604C466.695 265.604 465.437 264.347 465.437 262.797C465.437 261.247 466.695 259.99 468.245 259.99C469.794 259.99 471.051 261.247 471.051 262.797Z" />
          <path d="M464.209 262.797C464.209 264.347 462.953 265.604 461.403 265.604C459.853 265.604 458.595 264.347 458.595 262.797C458.595 261.247 459.853 259.99 461.403 259.99C462.953 259.99 464.209 261.247 464.209 262.797Z" />
          <path d="M457.367 262.797C457.367 264.347 456.111 265.604 454.561 265.604C453.011 265.604 451.754 264.347 451.754 262.797C451.754 261.247 453.011 259.99 454.561 259.99C456.111 259.99 457.367 261.247 457.367 262.797Z" />
          <path d="M450.525 262.797C450.525 264.347 449.269 265.604 447.719 265.604C446.169 265.604 444.912 264.347 444.912 262.797C444.912 261.247 446.169 259.99 447.719 259.99C449.269 259.99 450.525 261.247 450.525 262.797Z" />
          <path d="M443.683 262.797C443.683 264.347 442.427 265.604 440.877 265.604C439.327 265.604 438.07 264.347 438.07 262.797C438.07 261.247 439.327 259.99 440.877 259.99C442.427 259.99 443.683 261.247 443.683 262.797Z" />
          <path d="M436.842 262.797C436.842 264.347 435.585 265.604 434.036 265.604C432.486 265.604 431.228 264.347 431.228 262.797C431.228 261.247 432.486 259.99 434.036 259.99C435.585 259.99 436.842 261.247 436.842 262.797Z" />
          <path d="M430 262.797C430 264.347 428.744 265.604 427.194 265.604C425.644 265.604 424.386 264.347 424.386 262.797C424.386 261.247 425.644 259.99 427.194 259.99C428.744 259.99 430 261.247 430 262.797Z" />
          <path d="M423.158 262.797C423.158 264.347 421.902 265.604 420.352 265.604C418.802 265.604 417.544 264.347 417.544 262.797C417.544 261.247 418.802 259.99 420.352 259.99C421.902 259.99 423.158 261.247 423.158 262.797Z" />
          <path d="M423.158 255.955C423.158 257.505 421.902 258.762 420.352 258.762C418.802 258.762 417.544 257.505 417.544 255.955C417.544 254.405 418.802 253.148 420.352 253.148C421.902 253.148 423.158 254.405 423.158 255.955Z" />
          <path d="M416.316 255.955C416.316 257.505 415.06 258.762 413.51 258.762C411.96 258.762 410.702 257.505 410.702 255.955C410.702 254.405 411.96 253.148 413.51 253.148C415.06 253.148 416.316 254.405 416.316 255.955Z" />
          <path d="M409.474 255.955C409.474 257.505 408.218 258.762 406.668 258.762C405.118 258.762 403.861 257.505 403.861 255.955C403.861 254.405 405.118 253.148 406.668 253.148C408.218 253.148 409.474 254.405 409.474 255.955Z" />
          <path d="M409.474 262.797C409.474 264.347 408.218 265.604 406.668 265.604C405.118 265.604 403.861 264.347 403.861 262.797C403.861 261.247 405.118 259.99 406.668 259.99C408.218 259.99 409.474 261.247 409.474 262.797Z" />
          <path d="M416.316 262.797C416.316 264.347 415.06 265.604 413.51 265.604C411.96 265.604 410.702 264.347 410.702 262.797C410.702 261.247 411.96 259.99 413.51 259.99C415.06 259.99 416.316 261.247 416.316 262.797Z" />
          <path d="M402.633 269.639C402.633 271.189 401.376 272.445 399.826 272.445C398.276 272.445 397.019 271.189 397.019 269.639C397.019 268.089 398.276 266.832 399.826 266.832C401.376 266.832 402.633 268.089 402.633 269.639Z" />
          <path d="M409.474 269.639C409.474 271.189 408.218 272.445 406.668 272.445C405.118 272.445 403.861 271.189 403.861 269.639C403.861 268.089 405.118 266.832 406.668 266.832C408.218 266.832 409.474 268.089 409.474 269.639Z" />
          <path d="M416.316 269.639C416.316 271.189 415.06 272.445 413.51 272.445C411.96 272.445 410.702 271.189 410.702 269.639C410.702 268.089 411.96 266.832 413.51 266.832C415.06 266.832 416.316 268.089 416.316 269.639Z" />
          <path d="M423.158 269.639C423.158 271.189 421.902 272.445 420.352 272.445C418.802 272.445 417.544 271.189 417.544 269.639C417.544 268.089 418.802 266.832 420.352 266.832C421.902 266.832 423.158 268.089 423.158 269.639Z" />
          <path d="M430 269.639C430 271.189 428.744 272.445 427.194 272.445C425.644 272.445 424.386 271.189 424.386 269.639C424.386 268.089 425.644 266.832 427.194 266.832C428.744 266.832 430 268.089 430 269.639Z" />
          <path d="M436.842 269.639C436.842 271.189 435.585 272.445 434.036 272.445C432.486 272.445 431.228 271.189 431.228 269.639C431.228 268.089 432.486 266.832 434.036 266.832C435.585 266.832 436.842 268.089 436.842 269.639Z" />
          <path d="M443.683 269.639C443.683 271.189 442.427 272.445 440.877 272.445C439.327 272.445 438.07 271.189 438.07 269.639C438.07 268.089 439.327 266.832 440.877 266.832C442.427 266.832 443.683 268.089 443.683 269.639Z" />
          <path d="M450.525 269.639C450.525 271.189 449.269 272.445 447.719 272.445C446.169 272.445 444.912 271.189 444.912 269.639C444.912 268.089 446.169 266.832 447.719 266.832C449.269 266.832 450.525 268.089 450.525 269.639Z" />
          <path d="M457.367 269.639C457.367 271.189 456.111 272.445 454.561 272.445C453.011 272.445 451.754 271.189 451.754 269.639C451.754 268.089 453.011 266.832 454.561 266.832C456.111 266.832 457.367 268.089 457.367 269.639Z" />
          <path d="M464.209 269.639C464.209 271.189 462.953 272.445 461.403 272.445C459.853 272.445 458.595 271.189 458.595 269.639C458.595 268.089 459.853 266.832 461.403 266.832C462.953 266.832 464.209 268.089 464.209 269.639Z" />
          <path d="M471.051 269.639C471.051 271.189 469.794 272.445 468.245 272.445C466.695 272.445 465.437 271.189 465.437 269.639C465.437 268.089 466.695 266.832 468.245 266.832C469.794 266.832 471.051 268.089 471.051 269.639Z" />
          <path d="M471.051 276.481C471.051 278.031 469.794 279.288 468.245 279.288C466.695 279.288 465.437 278.031 465.437 276.481C465.437 274.931 466.695 273.674 468.245 273.674C469.794 273.674 471.051 274.931 471.051 276.481Z" />
          <path d="M471.051 283.323C471.051 284.873 469.794 286.129 468.245 286.129C466.695 286.129 465.437 284.873 465.437 283.323C465.437 281.773 466.695 280.516 468.245 280.516C469.794 280.516 471.051 281.773 471.051 283.323Z" />
          <path d="M471.051 290.164C471.051 291.714 469.794 292.971 468.245 292.971C466.695 292.971 465.437 291.714 465.437 290.164C465.437 288.615 466.695 287.358 468.245 287.358C469.794 287.358 471.051 288.615 471.051 290.164Z" />
          <path d="M477.893 290.164C477.893 291.714 476.636 292.971 475.086 292.971C473.536 292.971 472.279 291.714 472.279 290.164C472.279 288.615 473.536 287.358 475.086 287.358C476.636 287.358 477.893 288.615 477.893 290.164Z" />
          <path d="M477.893 283.323C477.893 284.873 476.636 286.129 475.086 286.129C473.536 286.129 472.279 284.873 472.279 283.323C472.279 281.773 473.536 280.516 475.086 280.516C476.636 280.516 477.893 281.773 477.893 283.323Z" />
          <path d="M471.051 297.006C471.051 298.556 469.794 299.813 468.245 299.813C466.695 299.813 465.437 298.556 465.437 297.006C465.437 295.456 466.695 294.199 468.245 294.199C469.794 294.199 471.051 295.456 471.051 297.006Z" />
          <path d="M471.051 303.848C471.051 305.398 469.794 306.655 468.245 306.655C466.695 306.655 465.437 305.398 465.437 303.848C465.437 302.298 466.695 301.041 468.245 301.041C469.794 301.041 471.051 302.298 471.051 303.848Z" />
          <path d="M471.051 310.69C471.051 312.24 469.794 313.497 468.245 313.497C466.695 313.497 465.437 312.24 465.437 310.69C465.437 309.14 466.695 307.883 468.245 307.883C469.794 307.883 471.051 309.14 471.051 310.69Z" />
          <path d="M464.209 310.69C464.209 312.24 462.953 313.497 461.403 313.497C459.853 313.497 458.595 312.24 458.595 310.69C458.595 309.14 459.853 307.883 461.403 307.883C462.953 307.883 464.209 309.14 464.209 310.69Z" />
          <path d="M464.209 303.848C464.209 305.398 462.953 306.655 461.403 306.655C459.853 306.655 458.595 305.398 458.595 303.848C458.595 302.298 459.853 301.041 461.403 301.041C462.953 301.041 464.209 302.298 464.209 303.848Z" />
          <path d="M457.367 303.848C457.367 305.398 456.111 306.655 454.561 306.655C453.011 306.655 451.754 305.398 451.754 303.848C451.754 302.298 453.011 301.041 454.561 301.041C456.111 301.041 457.367 302.298 457.367 303.848Z" />
          <path d="M457.367 297.006C457.367 298.556 456.111 299.813 454.561 299.813C453.011 299.813 451.754 298.556 451.754 297.006C451.754 295.456 453.011 294.199 454.561 294.199C456.111 294.199 457.367 295.456 457.367 297.006Z" />
          <path d="M464.209 297.006C464.209 298.556 462.953 299.813 461.403 299.813C459.853 299.813 458.595 298.556 458.595 297.006C458.595 295.456 459.853 294.199 461.403 294.199C462.953 294.199 464.209 295.456 464.209 297.006Z" />
          <path d="M464.209 290.164C464.209 291.714 462.953 292.971 461.403 292.971C459.853 292.971 458.595 291.714 458.595 290.164C458.595 288.615 459.853 287.358 461.403 287.358C462.953 287.358 464.209 288.615 464.209 290.164Z" />
          <path d="M457.367 290.164C457.367 291.714 456.111 292.971 454.561 292.971C453.011 292.971 451.754 291.714 451.754 290.164C451.754 288.615 453.011 287.358 454.561 287.358C456.111 287.358 457.367 288.615 457.367 290.164Z" />
          <path d="M457.367 283.323C457.367 284.873 456.111 286.129 454.561 286.129C453.011 286.129 451.754 284.873 451.754 283.323C451.754 281.773 453.011 280.516 454.561 280.516C456.111 280.516 457.367 281.773 457.367 283.323Z" />
          <path d="M464.209 283.323C464.209 284.873 462.953 286.129 461.403 286.129C459.853 286.129 458.595 284.873 458.595 283.323C458.595 281.773 459.853 280.516 461.403 280.516C462.953 280.516 464.209 281.773 464.209 283.323Z" />
          <path d="M464.209 276.481C464.209 278.031 462.953 279.288 461.403 279.288C459.853 279.288 458.595 278.031 458.595 276.481C458.595 274.931 459.853 273.674 461.403 273.674C462.953 273.674 464.209 274.931 464.209 276.481Z" />
          <path d="M457.367 276.481C457.367 278.031 456.111 279.288 454.561 279.288C453.011 279.288 451.754 278.031 451.754 276.481C451.754 274.931 453.011 273.674 454.561 273.674C456.111 273.674 457.367 274.931 457.367 276.481Z" />
          <path d="M450.525 276.481C450.525 278.031 449.269 279.288 447.719 279.288C446.169 279.288 444.912 278.031 444.912 276.481C444.912 274.931 446.169 273.674 447.719 273.674C449.269 273.674 450.525 274.931 450.525 276.481Z" />
          <path d="M450.525 283.323C450.525 284.873 449.269 286.129 447.719 286.129C446.169 286.129 444.912 284.873 444.912 283.323C444.912 281.773 446.169 280.516 447.719 280.516C449.269 280.516 450.525 281.773 450.525 283.323Z" />
          <path d="M443.683 276.481C443.683 278.031 442.427 279.288 440.877 279.288C439.327 279.288 438.07 278.031 438.07 276.481C438.07 274.931 439.327 273.674 440.877 273.674C442.427 273.674 443.683 274.931 443.683 276.481Z" />
          <path d="M436.842 276.481C436.842 278.031 435.585 279.288 434.036 279.288C432.486 279.288 431.228 278.031 431.228 276.481C431.228 274.931 432.486 273.674 434.036 273.674C435.585 273.674 436.842 274.931 436.842 276.481Z" />
          <path d="M477.893 358.583C477.893 360.133 476.636 361.39 475.086 361.39C473.536 361.39 472.279 360.133 472.279 358.583C472.279 357.033 473.536 355.776 475.086 355.776C476.636 355.776 477.893 357.033 477.893 358.583Z" />
          <path d="M477.893 351.741C477.893 353.291 476.636 354.548 475.086 354.548C473.536 354.548 472.279 353.291 472.279 351.741C472.279 350.191 473.536 348.934 475.086 348.934C476.636 348.934 477.893 350.191 477.893 351.741Z" />
          <path d="M484.734 351.741C484.734 353.291 483.478 354.548 481.928 354.548C480.378 354.548 479.121 353.291 479.121 351.741C479.121 350.191 480.378 348.934 481.928 348.934C483.478 348.934 484.734 350.191 484.734 351.741Z" />
          <path d="M484.734 344.899C484.734 346.449 483.478 347.706 481.928 347.706C480.378 347.706 479.121 346.449 479.121 344.899C479.121 343.349 480.378 342.093 481.928 342.093C483.478 342.093 484.734 343.349 484.734 344.899Z" />
          <path d="M477.893 344.899C477.893 346.449 476.636 347.706 475.086 347.706C473.536 347.706 472.279 346.449 472.279 344.899C472.279 343.349 473.536 342.093 475.086 342.093C476.636 342.093 477.893 343.349 477.893 344.899Z" />
          <path d="M477.893 338.057C477.893 339.607 476.636 340.864 475.086 340.864C473.536 340.864 472.279 339.607 472.279 338.057C472.279 336.507 473.536 335.25 475.086 335.25C476.636 335.25 477.893 336.507 477.893 338.057Z" />
          <path d="M477.893 331.215C477.893 332.765 476.636 334.022 475.086 334.022C473.536 334.022 472.279 332.765 472.279 331.215C472.279 329.666 473.536 328.409 475.086 328.409C476.636 328.409 477.893 329.666 477.893 331.215Z" />
          <path d="M477.893 324.373C477.893 325.923 476.636 327.18 475.086 327.18C473.536 327.18 472.279 325.923 472.279 324.373C472.279 322.824 473.536 321.567 475.086 321.567C476.636 321.567 477.893 322.824 477.893 324.373Z" />
          <path d="M484.734 324.373C484.734 325.923 483.478 327.18 481.928 327.18C480.378 327.18 479.121 325.923 479.121 324.373C479.121 322.824 480.378 321.567 481.928 321.567C483.478 321.567 484.734 322.824 484.734 324.373Z" />
          <path d="M484.734 317.532C484.734 319.082 483.478 320.339 481.928 320.339C480.378 320.339 479.121 319.082 479.121 317.532C479.121 315.982 480.378 314.725 481.928 314.725C483.478 314.725 484.734 315.982 484.734 317.532Z" />
          <path d="M484.734 310.69C484.734 312.24 483.478 313.497 481.928 313.497C480.378 313.497 479.121 312.24 479.121 310.69C479.121 309.14 480.378 307.883 481.928 307.883C483.478 307.883 484.734 309.14 484.734 310.69Z" />
          <path d="M491.576 310.69C491.576 312.24 490.32 313.497 488.77 313.497C487.22 313.497 485.963 312.24 485.963 310.69C485.963 309.14 487.22 307.883 488.77 307.883C490.32 307.883 491.576 309.14 491.576 310.69Z" />
          <path d="M484.734 303.848C484.734 305.398 483.478 306.655 481.928 306.655C480.378 306.655 479.121 305.398 479.121 303.848C479.121 302.298 480.378 301.041 481.928 301.041C483.478 301.041 484.734 302.298 484.734 303.848Z" />
          <path d="M388.949 290.164C388.949 291.714 387.692 292.971 386.142 292.971C384.592 292.971 383.335 291.714 383.335 290.164C383.335 288.615 384.592 287.358 386.142 287.358C387.692 287.358 388.949 288.615 388.949 290.164Z" />
          <path d="M532.628 297.006C532.628 298.556 531.371 299.813 529.821 299.813C528.271 299.813 527.014 298.556 527.014 297.006C527.014 295.456 528.271 294.199 529.821 294.199C531.371 294.199 532.628 295.456 532.628 297.006Z" />
          <path d="M539.469 290.164C539.469 291.714 538.213 292.971 536.663 292.971C535.113 292.971 533.856 291.714 533.856 290.164C533.856 288.615 535.113 287.358 536.663 287.358C538.213 287.358 539.469 288.615 539.469 290.164Z" />
          <path d="M423.158 303.848C423.158 305.398 421.902 306.655 420.352 306.655C418.802 306.655 417.544 305.398 417.544 303.848C417.544 302.298 418.802 301.041 420.352 301.041C421.902 301.041 423.158 302.298 423.158 303.848Z" />
          <path d="M409.474 290.164C409.474 291.714 408.218 292.971 406.668 292.971C405.118 292.971 403.861 291.714 403.861 290.164C403.861 288.615 405.118 287.358 406.668 287.358C408.218 287.358 409.474 288.615 409.474 290.164Z" />
          <path d="M402.633 290.164C402.633 291.714 401.376 292.971 399.826 292.971C398.276 292.971 397.019 291.714 397.019 290.164C397.019 288.615 398.276 287.358 399.826 287.358C401.376 287.358 402.633 288.615 402.633 290.164Z" />
          <path d="M395.791 290.164C395.791 291.714 394.534 292.971 392.984 292.971C391.434 292.971 390.177 291.714 390.177 290.164C390.177 288.615 391.434 287.358 392.984 287.358C394.534 287.358 395.791 288.615 395.791 290.164Z" />
          <path d="M430 221.746C430 223.296 428.744 224.553 427.194 224.553C425.644 224.553 424.386 223.296 424.386 221.746C424.386 220.196 425.644 218.939 427.194 218.939C428.744 218.939 430 220.196 430 221.746Z" />
          <path d="M505.26 221.746C505.26 223.296 504.004 224.553 502.454 224.553C500.904 224.553 499.646 223.296 499.646 221.746C499.646 220.196 500.904 218.939 502.454 218.939C504.004 218.939 505.26 220.196 505.26 221.746Z" />
          <path d="M512.102 221.746C512.102 223.296 510.845 224.553 509.296 224.553C507.746 224.553 506.488 223.296 506.488 221.746C506.488 220.196 507.746 218.939 509.296 218.939C510.845 218.939 512.102 220.196 512.102 221.746Z" />
          <path d="M518.944 221.746C518.944 223.296 517.687 224.553 516.137 224.553C514.587 224.553 513.33 223.296 513.33 221.746C513.33 220.196 514.587 218.939 516.137 218.939C517.687 218.939 518.944 220.196 518.944 221.746Z" />
          <path d="M518.944 214.904C518.944 216.454 517.687 217.711 516.137 217.711C514.587 217.711 513.33 216.454 513.33 214.904C513.33 213.354 514.587 212.097 516.137 212.097C517.687 212.097 518.944 213.354 518.944 214.904Z" />
          <path d="M518.944 208.062C518.944 209.612 517.687 210.869 516.137 210.869C514.587 210.869 513.33 209.612 513.33 208.062C513.33 206.512 514.587 205.256 516.137 205.256C517.687 205.256 518.944 206.512 518.944 208.062Z" />
          <path d="M525.786 208.062C525.786 209.612 524.53 210.869 522.98 210.869C521.43 210.869 520.172 209.612 520.172 208.062C520.172 206.512 521.43 205.256 522.98 205.256C524.53 205.256 525.786 206.512 525.786 208.062Z" />
          <path d="M518.944 201.22C518.944 202.77 517.687 204.027 516.137 204.027C514.587 204.027 513.33 202.77 513.33 201.22C513.33 199.67 514.587 198.413 516.137 198.413C517.687 198.413 518.944 199.67 518.944 201.22Z" />
          <path d="M512.102 194.379C512.102 195.929 510.845 197.185 509.296 197.185C507.746 197.185 506.488 195.929 506.488 194.379C506.488 192.829 507.746 191.572 509.296 191.572C510.845 191.572 512.102 192.829 512.102 194.379Z" />
          <path d="M512.102 187.537C512.102 189.087 510.845 190.344 509.296 190.344C507.746 190.344 506.488 189.087 506.488 187.537C506.488 185.987 507.746 184.73 509.296 184.73C510.845 184.73 512.102 185.987 512.102 187.537Z" />
          <path d="M518.944 187.537C518.944 189.087 517.687 190.344 516.137 190.344C514.587 190.344 513.33 189.087 513.33 187.537C513.33 185.987 514.587 184.73 516.137 184.73C517.687 184.73 518.944 185.987 518.944 187.537Z" />
          <path d="M518.944 180.695C518.944 182.245 517.687 183.502 516.137 183.502C514.587 183.502 513.33 182.245 513.33 180.695C513.33 179.145 514.587 177.888 516.137 177.888C517.687 177.888 518.944 179.145 518.944 180.695Z" />
          <path d="M525.786 180.695C525.786 182.245 524.53 183.502 522.98 183.502C521.43 183.502 520.172 182.245 520.172 180.695C520.172 179.145 521.43 177.888 522.98 177.888C524.53 177.888 525.786 179.145 525.786 180.695Z" />
          <path d="M532.628 180.695C532.628 182.245 531.371 183.502 529.821 183.502C528.271 183.502 527.014 182.245 527.014 180.695C527.014 179.145 528.271 177.888 529.821 177.888C531.371 177.888 532.628 179.145 532.628 180.695Z" />
          <path d="M539.469 180.695C539.469 182.245 538.213 183.502 536.663 183.502C535.113 183.502 533.856 182.245 533.856 180.695C533.856 179.145 535.113 177.888 536.663 177.888C538.213 177.888 539.469 179.145 539.469 180.695Z" />
          <path d="M539.469 187.537C539.469 189.087 538.213 190.344 536.663 190.344C535.113 190.344 533.856 189.087 533.856 187.537C533.856 185.987 535.113 184.73 536.663 184.73C538.213 184.73 539.469 185.987 539.469 187.537Z" />
          <path d="M539.469 194.379C539.469 195.929 538.213 197.185 536.663 197.185C535.113 197.185 533.856 195.929 533.856 194.379C533.856 192.829 535.113 191.572 536.663 191.572C538.213 191.572 539.469 192.829 539.469 194.379Z" />
          <path d="M532.628 194.379C532.628 195.929 531.371 197.185 529.821 197.185C528.271 197.185 527.014 195.929 527.014 194.379C527.014 192.829 528.271 191.572 529.821 191.572C531.371 191.572 532.628 192.829 532.628 194.379Z" />
          <path d="M539.469 201.22C539.469 202.77 538.213 204.027 536.663 204.027C535.113 204.027 533.856 202.77 533.856 201.22C533.856 199.67 535.113 198.413 536.663 198.413C538.213 198.413 539.469 199.67 539.469 201.22Z" />
          <path d="M539.469 208.062C539.469 209.612 538.213 210.869 536.663 210.869C535.113 210.869 533.856 209.612 533.856 208.062C533.856 206.512 535.113 205.256 536.663 205.256C538.213 205.256 539.469 206.512 539.469 208.062Z" />
          <path d="M546.311 208.062C546.311 209.612 545.055 210.869 543.505 210.869C541.955 210.869 540.698 209.612 540.698 208.062C540.698 206.512 541.955 205.256 543.505 205.256C545.055 205.256 546.311 206.512 546.311 208.062Z" />
          <path d="M546.311 214.904C546.311 216.454 545.055 217.711 543.505 217.711C541.955 217.711 540.698 216.454 540.698 214.904C540.698 213.354 541.955 212.097 543.505 212.097C545.055 212.097 546.311 213.354 546.311 214.904Z" />
          <path d="M546.311 221.746C546.311 223.296 545.055 224.553 543.505 224.553C541.955 224.553 540.698 223.296 540.698 221.746C540.698 220.196 541.955 218.939 543.505 218.939C545.055 218.939 546.311 220.196 546.311 221.746Z" />
          <path d="M546.311 228.588C546.311 230.137 545.055 231.394 543.505 231.394C541.955 231.394 540.698 230.137 540.698 228.588C540.698 227.038 541.955 225.781 543.505 225.781C545.055 225.781 546.311 227.038 546.311 228.588Z" />
          <path d="M539.469 228.588C539.469 230.137 538.213 231.394 536.663 231.394C535.113 231.394 533.856 230.137 533.856 228.588C533.856 227.038 535.113 225.781 536.663 225.781C538.213 225.781 539.469 227.038 539.469 228.588Z" />
          <path d="M539.469 221.746C539.469 223.296 538.213 224.553 536.663 224.553C535.113 224.553 533.856 223.296 533.856 221.746C533.856 220.196 535.113 218.939 536.663 218.939C538.213 218.939 539.469 220.196 539.469 221.746Z" />
          <path d="M532.628 221.746C532.628 223.296 531.371 224.553 529.821 224.553C528.271 224.553 527.014 223.296 527.014 221.746C527.014 220.196 528.271 218.939 529.821 218.939C531.371 218.939 532.628 220.196 532.628 221.746Z" />
          <path d="M525.786 221.746C525.786 223.296 524.53 224.553 522.98 224.553C521.43 224.553 520.172 223.296 520.172 221.746C520.172 220.196 521.43 218.939 522.98 218.939C524.53 218.939 525.786 220.196 525.786 221.746Z" />
          <path d="M525.786 228.588C525.786 230.137 524.53 231.394 522.98 231.394C521.43 231.394 520.172 230.137 520.172 228.588C520.172 227.038 521.43 225.781 522.98 225.781C524.53 225.781 525.786 227.038 525.786 228.588Z" />
          <path d="M532.628 228.588C532.628 230.137 531.371 231.394 529.821 231.394C528.271 231.394 527.014 230.137 527.014 228.588C527.014 227.038 528.271 225.781 529.821 225.781C531.371 225.781 532.628 227.038 532.628 228.588Z" />
          <path d="M532.628 235.43C532.628 236.98 531.371 238.236 529.821 238.236C528.271 238.236 527.014 236.98 527.014 235.43C527.014 233.88 528.271 232.623 529.821 232.623C531.371 232.623 532.628 233.88 532.628 235.43Z" />
          <path d="M525.786 235.43C525.786 236.98 524.53 238.236 522.98 238.236C521.43 238.236 520.172 236.98 520.172 235.43C520.172 233.88 521.43 232.623 522.98 232.623C524.53 232.623 525.786 233.88 525.786 235.43Z" />
          <path d="M539.469 235.43C539.469 236.98 538.213 238.236 536.663 238.236C535.113 238.236 533.856 236.98 533.856 235.43C533.856 233.88 535.113 232.623 536.663 232.623C538.213 232.623 539.469 233.88 539.469 235.43Z" />
          <path d="M539.469 242.272C539.469 243.822 538.213 245.078 536.663 245.078C535.113 245.078 533.856 243.822 533.856 242.272C533.856 240.722 535.113 239.465 536.663 239.465C538.213 239.465 539.469 240.722 539.469 242.272Z" />
          <path d="M546.311 242.272C546.311 243.822 545.055 245.078 543.505 245.078C541.955 245.078 540.698 243.822 540.698 242.272C540.698 240.722 541.955 239.465 543.505 239.465C545.055 239.465 546.311 240.722 546.311 242.272Z" />
          <path d="M546.311 235.43C546.311 236.98 545.055 238.236 543.505 238.236C541.955 238.236 540.698 236.98 540.698 235.43C540.698 233.88 541.955 232.623 543.505 232.623C545.055 232.623 546.311 233.88 546.311 235.43Z" />
          <path d="M553.153 235.43C553.153 236.98 551.897 238.236 550.347 238.236C548.797 238.236 547.539 236.98 547.539 235.43C547.539 233.88 548.797 232.623 550.347 232.623C551.897 232.623 553.153 233.88 553.153 235.43Z" />
          <path d="M553.153 242.272C553.153 243.822 551.897 245.078 550.347 245.078C548.797 245.078 547.539 243.822 547.539 242.272C547.539 240.722 548.797 239.465 550.347 239.465C551.897 239.465 553.153 240.722 553.153 242.272Z" />
          <path d="M559.995 242.272C559.995 243.822 558.739 245.078 557.189 245.078C555.639 245.078 554.381 243.822 554.381 242.272C554.381 240.722 555.639 239.465 557.189 239.465C558.739 239.465 559.995 240.722 559.995 242.272Z" />
          <path d="M559.995 249.113C559.995 250.663 558.739 251.92 557.189 251.92C555.639 251.92 554.381 250.663 554.381 249.113C554.381 247.563 555.639 246.307 557.189 246.307C558.739 246.307 559.995 247.563 559.995 249.113Z" />
          <path d="M566.837 249.113C566.837 250.663 565.58 251.92 564.03 251.92C562.48 251.92 561.223 250.663 561.223 249.113C561.223 247.563 562.48 246.307 564.03 246.307C565.58 246.307 566.837 247.563 566.837 249.113Z" />
          <path d="M573.678 249.113C573.678 250.663 572.422 251.92 570.872 251.92C569.322 251.92 568.065 250.663 568.065 249.113C568.065 247.563 569.322 246.307 570.872 246.307C572.422 246.307 573.678 247.563 573.678 249.113Z" />
          <path d="M580.52 249.113C580.52 250.663 579.264 251.92 577.714 251.92C576.164 251.92 574.907 250.663 574.907 249.113C574.907 247.563 576.164 246.307 577.714 246.307C579.264 246.307 580.52 247.563 580.52 249.113Z" />
          <path d="M587.362 249.113C587.362 250.663 586.106 251.92 584.556 251.92C583.006 251.92 581.749 250.663 581.749 249.113C581.749 247.563 583.006 246.307 584.556 246.307C586.106 246.307 587.362 247.563 587.362 249.113Z" />
          <path d="M587.362 255.955C587.362 257.505 586.106 258.762 584.556 258.762C583.006 258.762 581.749 257.505 581.749 255.955C581.749 254.405 583.006 253.148 584.556 253.148C586.106 253.148 587.362 254.405 587.362 255.955Z" />
          <path d="M580.52 255.955C580.52 257.505 579.264 258.762 577.714 258.762C576.164 258.762 574.907 257.505 574.907 255.955C574.907 254.405 576.164 253.148 577.714 253.148C579.264 253.148 580.52 254.405 580.52 255.955Z" />
          <path d="M587.362 262.797C587.362 264.347 586.106 265.604 584.556 265.604C583.006 265.604 581.749 264.347 581.749 262.797C581.749 261.247 583.006 259.99 584.556 259.99C586.106 259.99 587.362 261.247 587.362 262.797Z" />
          <path d="M594.204 255.955C594.204 257.505 592.948 258.762 591.398 258.762C589.848 258.762 588.591 257.505 588.591 255.955C588.591 254.405 589.848 253.148 591.398 253.148C592.948 253.148 594.204 254.405 594.204 255.955Z" />
          <path d="M601.046 255.955C601.046 257.505 599.79 258.762 598.24 258.762C596.69 258.762 595.432 257.505 595.432 255.955C595.432 254.405 596.69 253.148 598.24 253.148C599.79 253.148 601.046 254.405 601.046 255.955Z" />
          <path d="M601.046 262.797C601.046 264.347 599.79 265.604 598.24 265.604C596.69 265.604 595.432 264.347 595.432 262.797C595.432 261.247 596.69 259.99 598.24 259.99C599.79 259.99 601.046 261.247 601.046 262.797Z" />
          <path d="M594.204 262.797C594.204 264.347 592.948 265.604 591.398 265.604C589.848 265.604 588.591 264.347 588.591 262.797C588.591 261.247 589.848 259.99 591.398 259.99C592.948 259.99 594.204 261.247 594.204 262.797Z" />
          <path d="M601.046 269.639C601.046 271.189 599.79 272.445 598.24 272.445C596.69 272.445 595.432 271.189 595.432 269.639C595.432 268.089 596.69 266.832 598.24 266.832C599.79 266.832 601.046 268.089 601.046 269.639Z" />
          <path d="M594.204 269.639C594.204 271.189 592.948 272.445 591.398 272.445C589.848 272.445 588.591 271.189 588.591 269.639C588.591 268.089 589.848 266.832 591.398 266.832C592.948 266.832 594.204 268.089 594.204 269.639Z" />
          <path d="M601.046 276.481C601.046 278.031 599.79 279.288 598.24 279.288C596.69 279.288 595.432 278.031 595.432 276.481C595.432 274.931 596.69 273.674 598.24 273.674C599.79 273.674 601.046 274.931 601.046 276.481Z" />
          <path d="M601.046 283.323C601.046 284.873 599.79 286.129 598.24 286.129C596.69 286.129 595.432 284.873 595.432 283.323C595.432 281.773 596.69 280.516 598.24 280.516C599.79 280.516 601.046 281.773 601.046 283.323Z" />
          <path d="M607.888 283.323C607.888 284.873 606.631 286.129 605.081 286.129C603.531 286.129 602.274 284.873 602.274 283.323C602.274 281.773 603.531 280.516 605.081 280.516C606.631 280.516 607.888 281.773 607.888 283.323Z" />
          <path d="M607.888 276.481C607.888 278.031 606.631 279.288 605.081 279.288C603.531 279.288 602.274 278.031 602.274 276.481C602.274 274.931 603.531 273.674 605.081 273.674C606.631 273.674 607.888 274.931 607.888 276.481Z" />
          <path d="M607.888 269.639C607.888 271.189 606.631 272.445 605.081 272.445C603.531 272.445 602.274 271.189 602.274 269.639C602.274 268.089 603.531 266.832 605.081 266.832C606.631 266.832 607.888 268.089 607.888 269.639Z" />
          <path d="M614.73 269.639C614.73 271.189 613.473 272.445 611.923 272.445C610.374 272.445 609.116 271.189 609.116 269.639C609.116 268.089 610.374 266.832 611.923 266.832C613.473 266.832 614.73 268.089 614.73 269.639Z" />
          <path d="M614.73 262.797C614.73 264.347 613.473 265.604 611.923 265.604C610.374 265.604 609.116 264.347 609.116 262.797C609.116 261.247 610.374 259.99 611.923 259.99C613.473 259.99 614.73 261.247 614.73 262.797Z" />
          <path d="M621.571 262.797C621.571 264.347 620.315 265.604 618.765 265.604C617.215 265.604 615.958 264.347 615.958 262.797C615.958 261.247 617.215 259.99 618.765 259.99C620.315 259.99 621.571 261.247 621.571 262.797Z" />
          <path d="M621.571 255.955C621.571 257.505 620.315 258.762 618.765 258.762C617.215 258.762 615.958 257.505 615.958 255.955C615.958 254.405 617.215 253.148 618.765 253.148C620.315 253.148 621.571 254.405 621.571 255.955Z" />
          <path d="M628.413 255.955C628.413 257.505 627.157 258.762 625.607 258.762C624.057 258.762 622.8 257.505 622.8 255.955C622.8 254.405 624.057 253.148 625.607 253.148C627.157 253.148 628.413 254.405 628.413 255.955Z" />
          <path d="M635.255 255.955C635.255 257.505 633.999 258.762 632.449 258.762C630.899 258.762 629.642 257.505 629.642 255.955C629.642 254.405 630.899 253.148 632.449 253.148C633.999 253.148 635.255 254.405 635.255 255.955Z" />
          <path d="M642.097 255.955C642.097 257.505 640.841 258.762 639.291 258.762C637.741 258.762 636.483 257.505 636.483 255.955C636.483 254.405 637.741 253.148 639.291 253.148C640.841 253.148 642.097 254.405 642.097 255.955Z" />
          <path d="M648.939 255.955C648.939 257.505 647.682 258.762 646.132 258.762C644.583 258.762 643.325 257.505 643.325 255.955C643.325 254.405 644.583 253.148 646.132 253.148C647.682 253.148 648.939 254.405 648.939 255.955Z" />
          <path d="M648.939 262.797C648.939 264.347 647.682 265.604 646.132 265.604C644.583 265.604 643.325 264.347 643.325 262.797C643.325 261.247 644.583 259.99 646.132 259.99C647.682 259.99 648.939 261.247 648.939 262.797Z" />
          <path d="M648.939 269.639C648.939 271.189 647.682 272.445 646.132 272.445C644.583 272.445 643.325 271.189 643.325 269.639C643.325 268.089 644.583 266.832 646.132 266.832C647.682 266.832 648.939 268.089 648.939 269.639Z" />
          <path d="M655.78 269.639C655.78 271.189 654.524 272.445 652.974 272.445C651.424 272.445 650.167 271.189 650.167 269.639C650.167 268.089 651.424 266.832 652.974 266.832C654.524 266.832 655.78 268.089 655.78 269.639Z" />
          <path d="M662.623 269.639C662.623 271.189 661.366 272.445 659.816 272.445C658.266 272.445 657.009 271.189 657.009 269.639C657.009 268.089 658.266 266.832 659.816 266.832C661.366 266.832 662.623 268.089 662.623 269.639Z" />
          <path d="M662.623 276.481C662.623 278.031 661.366 279.288 659.816 279.288C658.266 279.288 657.009 278.031 657.009 276.481C657.009 274.931 658.266 273.674 659.816 273.674C661.366 273.674 662.623 274.931 662.623 276.481Z" />
          <path d="M662.623 290.164C662.623 291.714 661.366 292.971 659.816 292.971C658.266 292.971 657.009 291.714 657.009 290.164C657.009 288.615 658.266 287.358 659.816 287.358C661.366 287.358 662.623 288.615 662.623 290.164Z" />
          <path d="M662.623 297.006C662.623 298.556 661.366 299.813 659.816 299.813C658.266 299.813 657.009 298.556 657.009 297.006C657.009 295.456 658.266 294.199 659.816 294.199C661.366 294.199 662.623 295.456 662.623 297.006Z" />
          <path d="M669.464 303.848C669.464 305.398 668.208 306.655 666.658 306.655C665.108 306.655 663.851 305.398 663.851 303.848C663.851 302.298 665.108 301.041 666.658 301.041C668.208 301.041 669.464 302.298 669.464 303.848Z" />
          <path d="M669.464 310.69C669.464 312.24 668.208 313.497 666.658 313.497C665.108 313.497 663.851 312.24 663.851 310.69C663.851 309.14 665.108 307.883 666.658 307.883C668.208 307.883 669.464 309.14 669.464 310.69Z" />
          <path d="M669.464 317.532C669.464 319.082 668.208 320.339 666.658 320.339C665.108 320.339 663.851 319.082 663.851 317.532C663.851 315.982 665.108 314.725 666.658 314.725C668.208 314.725 669.464 315.982 669.464 317.532Z" />
          <path d="M669.464 324.373C669.464 325.923 668.208 327.18 666.658 327.18C665.108 327.18 663.851 325.923 663.851 324.373C663.851 322.824 665.108 321.567 666.658 321.567C668.208 321.567 669.464 322.824 669.464 324.373Z" />
          <path d="M676.306 324.373C676.306 325.923 675.05 327.18 673.5 327.18C671.95 327.18 670.693 325.923 670.693 324.373C670.693 322.824 671.95 321.567 673.5 321.567C675.05 321.567 676.306 322.824 676.306 324.373Z" />
          <path d="M689.99 324.373C689.99 325.923 688.733 327.18 687.183 327.18C685.634 327.18 684.376 325.923 684.376 324.373C684.376 322.824 685.634 321.567 687.183 321.567C688.733 321.567 689.99 322.824 689.99 324.373Z" />
          <path d="M689.99 317.532C689.99 319.082 688.733 320.339 687.183 320.339C685.634 320.339 684.376 319.082 684.376 317.532C684.376 315.982 685.634 314.725 687.183 314.725C688.733 314.725 689.99 315.982 689.99 317.532Z" />
          <path d="M696.832 317.532C696.832 319.082 695.575 320.339 694.026 320.339C692.476 320.339 691.218 319.082 691.218 317.532C691.218 315.982 692.476 314.725 694.026 314.725C695.575 314.725 696.832 315.982 696.832 317.532Z" />
          <path d="M703.674 317.532C703.674 319.082 702.417 320.339 700.867 320.339C699.317 320.339 698.06 319.082 698.06 317.532C698.06 315.982 699.317 314.725 700.867 314.725C702.417 314.725 703.674 315.982 703.674 317.532Z" />
          <path d="M703.674 310.69C703.674 312.24 702.417 313.497 700.867 313.497C699.317 313.497 698.06 312.24 698.06 310.69C698.06 309.14 699.317 307.883 700.867 307.883C702.417 307.883 703.674 309.14 703.674 310.69Z" />
          <path d="M710.515 303.848C710.515 305.398 709.259 306.655 707.709 306.655C706.159 306.655 704.902 305.398 704.902 303.848C704.902 302.298 706.159 301.041 707.709 301.041C709.259 301.041 710.515 302.298 710.515 303.848Z" />
          <path d="M703.674 324.373C703.674 325.923 702.417 327.18 700.867 327.18C699.317 327.18 698.06 325.923 698.06 324.373C698.06 322.824 699.317 321.567 700.867 321.567C702.417 321.567 703.674 322.824 703.674 324.373Z" />
          <path d="M717.357 324.373C717.357 325.923 716.101 327.18 714.551 327.18C713.001 327.18 711.744 325.923 711.744 324.373C711.744 322.824 713.001 321.567 714.551 321.567C716.101 321.567 717.357 322.824 717.357 324.373Z" />
          <path d="M724.199 317.532C724.199 319.082 722.943 320.339 721.393 320.339C719.843 320.339 718.585 319.082 718.585 317.532C718.585 315.982 719.843 314.725 721.393 314.725C722.943 314.725 724.199 315.982 724.199 317.532Z" />
          <path d="M737.883 317.532C737.883 319.082 736.626 320.339 735.077 320.339C733.527 320.339 732.269 319.082 732.269 317.532C732.269 315.982 733.527 314.725 735.077 314.725C736.626 314.725 737.883 315.982 737.883 317.532Z" />
          <path d="M744.725 324.373C744.725 325.923 743.468 327.18 741.918 327.18C740.368 327.18 739.111 325.923 739.111 324.373C739.111 322.824 740.368 321.567 741.918 321.567C743.468 321.567 744.725 322.824 744.725 324.373Z" />
          <path d="M751.566 324.373C751.566 325.923 750.31 327.18 748.76 327.18C747.21 327.18 745.953 325.923 745.953 324.373C745.953 322.824 747.21 321.567 748.76 321.567C750.31 321.567 751.566 322.824 751.566 324.373Z" />
          <path d="M751.566 331.215C751.566 332.765 750.31 334.022 748.76 334.022C747.21 334.022 745.953 332.765 745.953 331.215C745.953 329.666 747.21 328.409 748.76 328.409C750.31 328.409 751.566 329.666 751.566 331.215Z" />
          <path d="M758.408 331.215C758.408 332.765 757.152 334.022 755.602 334.022C754.052 334.022 752.795 332.765 752.795 331.215C752.795 329.666 754.052 328.409 755.602 328.409C757.152 328.409 758.408 329.666 758.408 331.215Z" />
          <path d="M758.408 324.373C758.408 325.923 757.152 327.18 755.602 327.18C754.052 327.18 752.795 325.923 752.795 324.373C752.795 322.824 754.052 321.567 755.602 321.567C757.152 321.567 758.408 322.824 758.408 324.373Z" />
          <path d="M765.25 331.215C765.25 332.765 763.994 334.022 762.444 334.022C760.894 334.022 759.637 332.765 759.637 331.215C759.637 329.666 760.894 328.409 762.444 328.409C763.994 328.409 765.25 329.666 765.25 331.215Z" />
          <path d="M772.092 331.215C772.092 332.765 770.836 334.022 769.286 334.022C767.736 334.022 766.479 332.765 766.479 331.215C766.479 329.666 767.736 328.409 769.286 328.409C770.836 328.409 772.092 329.666 772.092 331.215Z" />
          <path d="M772.092 338.057C772.092 339.607 770.836 340.864 769.286 340.864C767.736 340.864 766.479 339.607 766.479 338.057C766.479 336.507 767.736 335.25 769.286 335.25C770.836 335.25 772.092 336.507 772.092 338.057Z" />
          <path d="M772.092 351.741C772.092 353.291 770.836 354.548 769.286 354.548C767.736 354.548 766.479 353.291 766.479 351.741C766.479 350.191 767.736 348.934 769.286 348.934C770.836 348.934 772.092 350.191 772.092 351.741Z" />
          <path d="M772.092 358.583C772.092 360.133 770.836 361.39 769.286 361.39C767.736 361.39 766.479 360.133 766.479 358.583C766.479 357.033 767.736 355.776 769.286 355.776C770.836 355.776 772.092 357.033 772.092 358.583Z" />
          <path d="M772.092 365.424C772.092 366.974 770.836 368.231 769.286 368.231C767.736 368.231 766.479 366.974 766.479 365.424C766.479 363.875 767.736 362.618 769.286 362.618C770.836 362.618 772.092 363.875 772.092 365.424Z" />
          <path d="M772.092 372.267C772.092 373.816 770.836 375.073 769.286 375.073C767.736 375.073 766.479 373.816 766.479 372.267C766.479 370.717 767.736 369.46 769.286 369.46C770.836 369.46 772.092 370.717 772.092 372.267Z" />
          <path d="M772.092 379.109C772.092 380.658 770.836 381.915 769.286 381.915C767.736 381.915 766.479 380.658 766.479 379.109C766.479 377.559 767.736 376.302 769.286 376.302C770.836 376.302 772.092 377.559 772.092 379.109Z" />
          <path d="M778.934 379.109C778.934 380.658 777.678 381.915 776.128 381.915C774.578 381.915 773.32 380.658 773.32 379.109C773.32 377.559 774.578 376.302 776.128 376.302C777.678 376.302 778.934 377.559 778.934 379.109Z" />
          <path d="M778.934 372.267C778.934 373.816 777.678 375.073 776.128 375.073C774.578 375.073 773.32 373.816 773.32 372.267C773.32 370.717 774.578 369.46 776.128 369.46C777.678 369.46 778.934 370.717 778.934 372.267Z" />
          <path d="M778.934 385.95C778.934 387.5 777.678 388.757 776.128 388.757C774.578 388.757 773.32 387.5 773.32 385.95C773.32 384.4 774.578 383.144 776.128 383.144C777.678 383.144 778.934 384.4 778.934 385.95Z" />
          <path d="M785.776 385.95C785.776 387.5 784.519 388.757 782.969 388.757C781.419 388.757 780.162 387.5 780.162 385.95C780.162 384.4 781.419 383.144 782.969 383.144C784.519 383.144 785.776 384.4 785.776 385.95Z" />
          <path d="M785.776 392.792C785.776 394.342 784.519 395.599 782.969 395.599C781.419 395.599 780.162 394.342 780.162 392.792C780.162 391.242 781.419 389.985 782.969 389.985C784.519 389.985 785.776 391.242 785.776 392.792Z" />
          <path d="M792.617 392.792C792.617 394.342 791.361 395.599 789.811 395.599C788.261 395.599 787.004 394.342 787.004 392.792C787.004 391.242 788.261 389.985 789.811 389.985C791.361 389.985 792.617 391.242 792.617 392.792Z" />
          <path d="M785.776 399.634C785.776 401.184 784.519 402.441 782.969 402.441C781.419 402.441 780.162 401.184 780.162 399.634C780.162 398.084 781.419 396.827 782.969 396.827C784.519 396.827 785.776 398.084 785.776 399.634Z" />
          <path d="M792.617 399.634C792.617 401.184 791.361 402.441 789.811 402.441C788.261 402.441 787.004 401.184 787.004 399.634C787.004 398.084 788.261 396.827 789.811 396.827C791.361 396.827 792.617 398.084 792.617 399.634Z" />
          <path d="M785.776 406.475C785.776 408.025 784.519 409.282 782.969 409.282C781.419 409.282 780.162 408.025 780.162 406.475C780.162 404.926 781.419 403.669 782.969 403.669C784.519 403.669 785.776 404.926 785.776 406.475Z" />
          <path d="M785.776 413.318C785.776 414.868 784.519 416.125 782.969 416.125C781.419 416.125 780.162 414.868 780.162 413.318C780.162 411.768 781.419 410.511 782.969 410.511C784.519 410.511 785.776 411.768 785.776 413.318Z" />
          <path d="M778.934 413.318C778.934 414.868 777.678 416.125 776.128 416.125C774.578 416.125 773.32 414.868 773.32 413.318C773.32 411.768 774.578 410.511 776.128 410.511C777.678 410.511 778.934 411.768 778.934 413.318Z" />
          <path d="M778.934 420.159C778.934 421.709 777.678 422.966 776.128 422.966C774.578 422.966 773.32 421.709 773.32 420.159C773.32 418.609 774.578 417.353 776.128 417.353C777.678 417.353 778.934 418.609 778.934 420.159Z" />
          <path d="M772.092 420.159C772.092 421.709 770.836 422.966 769.286 422.966C767.736 422.966 766.479 421.709 766.479 420.159C766.479 418.609 767.736 417.353 769.286 417.353C770.836 417.353 772.092 418.609 772.092 420.159Z" />
          <path d="M772.092 427.001C772.092 428.551 770.836 429.808 769.286 429.808C767.736 429.808 766.479 428.551 766.479 427.001C766.479 425.451 767.736 424.195 769.286 424.195C770.836 424.195 772.092 425.451 772.092 427.001Z" />
          <path d="M778.934 427.001C778.934 428.551 777.678 429.808 776.128 429.808C774.578 429.808 773.32 428.551 773.32 427.001C773.32 425.451 774.578 424.195 776.128 424.195C777.678 424.195 778.934 425.451 778.934 427.001Z" />
          <path d="M772.092 433.843C772.092 435.393 770.836 436.65 769.286 436.65C767.736 436.65 766.479 435.393 766.479 433.843C766.479 432.293 767.736 431.036 769.286 431.036C770.836 431.036 772.092 432.293 772.092 433.843Z" />
          <path d="M765.25 433.843C765.25 435.393 763.994 436.65 762.444 436.65C760.894 436.65 759.637 435.393 759.637 433.843C759.637 432.293 760.894 431.036 762.444 431.036C763.994 431.036 765.25 432.293 765.25 433.843Z" />
          <path d="M765.25 447.527C765.25 449.077 763.994 450.333 762.444 450.333C760.894 450.333 759.637 449.077 759.637 447.527C759.637 445.977 760.894 444.72 762.444 444.72C763.994 444.72 765.25 445.977 765.25 447.527Z" />
          <path d="M765.25 454.369C765.25 455.919 763.994 457.176 762.444 457.176C760.894 457.176 759.637 455.919 759.637 454.369C759.637 452.819 760.894 451.562 762.444 451.562C763.994 451.562 765.25 452.819 765.25 454.369Z" />
          <path d="M813.143 474.894C813.143 476.444 811.887 477.701 810.337 477.701C808.787 477.701 807.53 476.444 807.53 474.894C807.53 473.344 808.787 472.087 810.337 472.087C811.887 472.087 813.143 473.344 813.143 474.894Z" />
          <path d="M813.143 468.052C813.143 469.602 811.887 470.859 810.337 470.859C808.787 470.859 807.53 469.602 807.53 468.052C807.53 466.502 808.787 465.246 810.337 465.246C811.887 465.246 813.143 466.502 813.143 468.052Z" />
          <path d="M819.985 468.052C819.985 469.602 818.729 470.859 817.179 470.859C815.629 470.859 814.371 469.602 814.371 468.052C814.371 466.502 815.629 465.246 817.179 465.246C818.729 465.246 819.985 466.502 819.985 468.052Z" />
          <path d="M826.827 461.21C826.827 462.76 825.57 464.017 824.02 464.017C822.47 464.017 821.213 462.76 821.213 461.21C821.213 459.66 822.47 458.404 824.02 458.404C825.57 458.404 826.827 459.66 826.827 461.21Z" />
          <path d="M833.669 454.369C833.669 455.919 832.412 457.176 830.862 457.176C829.312 457.176 828.055 455.919 828.055 454.369C828.055 452.819 829.312 451.562 830.862 451.562C832.412 451.562 833.669 452.819 833.669 454.369Z" />
          <path d="M840.51 447.527C840.51 449.077 839.254 450.333 837.704 450.333C836.154 450.333 834.897 449.077 834.897 447.527C834.897 445.977 836.154 444.72 837.704 444.72C839.254 444.72 840.51 445.977 840.51 447.527Z" />
          <path d="M840.51 440.685C840.51 442.235 839.254 443.492 837.704 443.492C836.154 443.492 834.897 442.235 834.897 440.685C834.897 439.135 836.154 437.878 837.704 437.878C839.254 437.878 840.51 439.135 840.51 440.685Z" />
          <path d="M840.51 433.843C840.51 435.393 839.254 436.65 837.704 436.65C836.154 436.65 834.897 435.393 834.897 433.843C834.897 432.293 836.154 431.036 837.704 431.036C839.254 431.036 840.51 432.293 840.51 433.843Z" />
          <path d="M765.25 427.001C765.25 428.551 763.994 429.808 762.444 429.808C760.894 429.808 759.637 428.551 759.637 427.001C759.637 425.451 760.894 424.195 762.444 424.195C763.994 424.195 765.25 425.451 765.25 427.001Z" />
          <path d="M758.408 427.001C758.408 428.551 757.152 429.808 755.602 429.808C754.052 429.808 752.795 428.551 752.795 427.001C752.795 425.451 754.052 424.195 755.602 424.195C757.152 424.195 758.408 425.451 758.408 427.001Z" />
          <path d="M758.408 433.843C758.408 435.393 757.152 436.65 755.602 436.65C754.052 436.65 752.795 435.393 752.795 433.843C752.795 432.293 754.052 431.036 755.602 431.036C757.152 431.036 758.408 432.293 758.408 433.843Z" />
          <path d="M758.408 420.159C758.408 421.709 757.152 422.966 755.602 422.966C754.052 422.966 752.795 421.709 752.795 420.159C752.795 418.609 754.052 417.353 755.602 417.353C757.152 417.353 758.408 418.609 758.408 420.159Z" />
          <path d="M751.566 420.159C751.566 421.709 750.31 422.966 748.76 422.966C747.21 422.966 745.953 421.709 745.953 420.159C745.953 418.609 747.21 417.353 748.76 417.353C750.31 417.353 751.566 418.609 751.566 420.159Z" />
          <path d="M751.566 413.318C751.566 414.868 750.31 416.125 748.76 416.125C747.21 416.125 745.953 414.868 745.953 413.318C745.953 411.768 747.21 410.511 748.76 410.511C750.31 410.511 751.566 411.768 751.566 413.318Z" />
          <path d="M744.725 413.318C744.725 414.868 743.468 416.125 741.918 416.125C740.368 416.125 739.111 414.868 739.111 413.318C739.111 411.768 740.368 410.511 741.918 410.511C743.468 410.511 744.725 411.768 744.725 413.318Z" />
          <path d="M744.725 406.475C744.725 408.025 743.468 409.282 741.918 409.282C740.368 409.282 739.111 408.025 739.111 406.475C739.111 404.926 740.368 403.669 741.918 403.669C743.468 403.669 744.725 404.926 744.725 406.475Z" />
          <path d="M737.883 406.475C737.883 408.025 736.626 409.282 735.077 409.282C733.527 409.282 732.269 408.025 732.269 406.475C732.269 404.926 733.527 403.669 735.077 403.669C736.626 403.669 737.883 404.926 737.883 406.475Z" />
          <path d="M731.041 406.475C731.041 408.025 729.785 409.282 728.235 409.282C726.685 409.282 725.427 408.025 725.427 406.475C725.427 404.926 726.685 403.669 728.235 403.669C729.785 403.669 731.041 404.926 731.041 406.475Z" />
          <path d="M724.199 406.475C724.199 408.025 722.943 409.282 721.393 409.282C719.843 409.282 718.585 408.025 718.585 406.475C718.585 404.926 719.843 403.669 721.393 403.669C722.943 403.669 724.199 404.926 724.199 406.475Z" />
          <path d="M717.357 406.475C717.357 408.025 716.101 409.282 714.551 409.282C713.001 409.282 711.744 408.025 711.744 406.475C711.744 404.926 713.001 403.669 714.551 403.669C716.101 403.669 717.357 404.926 717.357 406.475Z" />
          <path d="M710.515 406.475C710.515 408.025 709.259 409.282 707.709 409.282C706.159 409.282 704.902 408.025 704.902 406.475C704.902 404.926 706.159 403.669 707.709 403.669C709.259 403.669 710.515 404.926 710.515 406.475Z" />
          <path d="M710.515 413.318C710.515 414.868 709.259 416.125 707.709 416.125C706.159 416.125 704.902 414.868 704.902 413.318C704.902 411.768 706.159 410.511 707.709 410.511C709.259 410.511 710.515 411.768 710.515 413.318Z" />
          <path d="M703.674 413.318C703.674 414.868 702.417 416.125 700.867 416.125C699.317 416.125 698.06 414.868 698.06 413.318C698.06 411.768 699.317 410.511 700.867 410.511C702.417 410.511 703.674 411.768 703.674 413.318Z" />
          <path d="M696.832 413.318C696.832 414.868 695.575 416.125 694.026 416.125C692.476 416.125 691.218 414.868 691.218 413.318C691.218 411.768 692.476 410.511 694.026 410.511C695.575 410.511 696.832 411.768 696.832 413.318Z" />
          <path d="M696.832 406.475C696.832 408.025 695.575 409.282 694.026 409.282C692.476 409.282 691.218 408.025 691.218 406.475C691.218 404.926 692.476 403.669 694.026 403.669C695.575 403.669 696.832 404.926 696.832 406.475Z" />
          <path d="M696.832 399.634C696.832 401.184 695.575 402.441 694.026 402.441C692.476 402.441 691.218 401.184 691.218 399.634C691.218 398.084 692.476 396.827 694.026 396.827C695.575 396.827 696.832 398.084 696.832 399.634Z" />
          <path d="M696.832 392.792C696.832 394.342 695.575 395.599 694.026 395.599C692.476 395.599 691.218 394.342 691.218 392.792C691.218 391.242 692.476 389.985 694.026 389.985C695.575 389.985 696.832 391.242 696.832 392.792Z" />
          <path d="M696.832 385.95C696.832 387.5 695.575 388.757 694.026 388.757C692.476 388.757 691.218 387.5 691.218 385.95C691.218 384.4 692.476 383.144 694.026 383.144C695.575 383.144 696.832 384.4 696.832 385.95Z" />
          <path d="M703.674 379.109C703.674 380.658 702.417 381.915 700.867 381.915C699.317 381.915 698.06 380.658 698.06 379.109C698.06 377.559 699.317 376.302 700.867 376.302C702.417 376.302 703.674 377.559 703.674 379.109Z" />
          <path d="M710.515 379.109C710.515 380.658 709.259 381.915 707.709 381.915C706.159 381.915 704.902 380.658 704.902 379.109C704.902 377.559 706.159 376.302 707.709 376.302C709.259 376.302 710.515 377.559 710.515 379.109Z" />
          <path d="M717.357 379.109C717.357 380.658 716.101 381.915 714.551 381.915C713.001 381.915 711.744 380.658 711.744 379.109C711.744 377.559 713.001 376.302 714.551 376.302C716.101 376.302 717.357 377.559 717.357 379.109Z" />
          <path d="M717.357 372.267C717.357 373.816 716.101 375.073 714.551 375.073C713.001 375.073 711.744 373.816 711.744 372.267C711.744 370.717 713.001 369.46 714.551 369.46C716.101 369.46 717.357 370.717 717.357 372.267Z" />
          <path d="M724.199 372.267C724.199 373.816 722.943 375.073 721.393 375.073C719.843 375.073 718.585 373.816 718.585 372.267C718.585 370.717 719.843 369.46 721.393 369.46C722.943 369.46 724.199 370.717 724.199 372.267Z" />
          <path d="M731.041 372.267C731.041 373.816 729.785 375.073 728.235 375.073C726.685 375.073 725.427 373.816 725.427 372.267C725.427 370.717 726.685 369.46 728.235 369.46C729.785 369.46 731.041 370.717 731.041 372.267Z" />
          <path d="M731.041 365.424C731.041 366.974 729.785 368.231 728.235 368.231C726.685 368.231 725.427 366.974 725.427 365.424C725.427 363.875 726.685 362.618 728.235 362.618C729.785 362.618 731.041 363.875 731.041 365.424Z" />
          <path d="M724.199 365.424C724.199 366.974 722.943 368.231 721.393 368.231C719.843 368.231 718.585 366.974 718.585 365.424C718.585 363.875 719.843 362.618 721.393 362.618C722.943 362.618 724.199 363.875 724.199 365.424Z" />
          <path d="M731.041 358.583C731.041 360.133 729.785 361.39 728.235 361.39C726.685 361.39 725.427 360.133 725.427 358.583C725.427 357.033 726.685 355.776 728.235 355.776C729.785 355.776 731.041 357.033 731.041 358.583Z" />
          <path d="M737.883 365.424C737.883 366.974 736.626 368.231 735.077 368.231C733.527 368.231 732.269 366.974 732.269 365.424C732.269 363.875 733.527 362.618 735.077 362.618C736.626 362.618 737.883 363.875 737.883 365.424Z" />
          <path d="M744.725 365.424C744.725 366.974 743.468 368.231 741.918 368.231C740.368 368.231 739.111 366.974 739.111 365.424C739.111 363.875 740.368 362.618 741.918 362.618C743.468 362.618 744.725 363.875 744.725 365.424Z" />
          <path d="M744.725 358.583C744.725 360.133 743.468 361.39 741.918 361.39C740.368 361.39 739.111 360.133 739.111 358.583C739.111 357.033 740.368 355.776 741.918 355.776C743.468 355.776 744.725 357.033 744.725 358.583Z" />
          <path d="M744.725 351.741C744.725 353.291 743.468 354.548 741.918 354.548C740.368 354.548 739.111 353.291 739.111 351.741C739.111 350.191 740.368 348.934 741.918 348.934C743.468 348.934 744.725 350.191 744.725 351.741Z" />
          <path d="M751.566 351.741C751.566 353.291 750.31 354.548 748.76 354.548C747.21 354.548 745.953 353.291 745.953 351.741C745.953 350.191 747.21 348.934 748.76 348.934C750.31 348.934 751.566 350.191 751.566 351.741Z" />
          <path d="M751.566 358.583C751.566 360.133 750.31 361.39 748.76 361.39C747.21 361.39 745.953 360.133 745.953 358.583C745.953 357.033 747.21 355.776 748.76 355.776C750.31 355.776 751.566 357.033 751.566 358.583Z" />
          <path d="M751.566 365.424C751.566 366.974 750.31 368.231 748.76 368.231C747.21 368.231 745.953 366.974 745.953 365.424C745.953 363.875 747.21 362.618 748.76 362.618C750.31 362.618 751.566 363.875 751.566 365.424Z" />
          <path d="M758.408 365.424C758.408 366.974 757.152 368.231 755.602 368.231C754.052 368.231 752.795 366.974 752.795 365.424C752.795 363.875 754.052 362.618 755.602 362.618C757.152 362.618 758.408 363.875 758.408 365.424Z" />
          <path d="M758.408 372.267C758.408 373.816 757.152 375.073 755.602 375.073C754.052 375.073 752.795 373.816 752.795 372.267C752.795 370.717 754.052 369.46 755.602 369.46C757.152 369.46 758.408 370.717 758.408 372.267Z" />
          <path d="M765.25 372.267C765.25 373.816 763.994 375.073 762.444 375.073C760.894 375.073 759.637 373.816 759.637 372.267C759.637 370.717 760.894 369.46 762.444 369.46C763.994 369.46 765.25 370.717 765.25 372.267Z" />
          <path d="M765.25 379.109C765.25 380.658 763.994 381.915 762.444 381.915C760.894 381.915 759.637 380.658 759.637 379.109C759.637 377.559 760.894 376.302 762.444 376.302C763.994 376.302 765.25 377.559 765.25 379.109Z" />
          <path d="M765.25 385.95C765.25 387.5 763.994 388.757 762.444 388.757C760.894 388.757 759.637 387.5 759.637 385.95C759.637 384.4 760.894 383.144 762.444 383.144C763.994 383.144 765.25 384.4 765.25 385.95Z" />
          <path d="M772.092 385.95C772.092 387.5 770.836 388.757 769.286 388.757C767.736 388.757 766.479 387.5 766.479 385.95C766.479 384.4 767.736 383.144 769.286 383.144C770.836 383.144 772.092 384.4 772.092 385.95Z" />
          <path d="M772.092 392.792C772.092 394.342 770.836 395.599 769.286 395.599C767.736 395.599 766.479 394.342 766.479 392.792C766.479 391.242 767.736 389.985 769.286 389.985C770.836 389.985 772.092 391.242 772.092 392.792Z" />
          <path d="M778.934 392.792C778.934 394.342 777.678 395.599 776.128 395.599C774.578 395.599 773.32 394.342 773.32 392.792C773.32 391.242 774.578 389.985 776.128 389.985C777.678 389.985 778.934 391.242 778.934 392.792Z" />
          <path d="M778.934 399.634C778.934 401.184 777.678 402.441 776.128 402.441C774.578 402.441 773.32 401.184 773.32 399.634C773.32 398.084 774.578 396.827 776.128 396.827C777.678 396.827 778.934 398.084 778.934 399.634Z" />
          <path d="M778.934 406.475C778.934 408.025 777.678 409.282 776.128 409.282C774.578 409.282 773.32 408.025 773.32 406.475C773.32 404.926 774.578 403.669 776.128 403.669C777.678 403.669 778.934 404.926 778.934 406.475Z" />
          <path d="M772.092 406.475C772.092 408.025 770.836 409.282 769.286 409.282C767.736 409.282 766.479 408.025 766.479 406.475C766.479 404.926 767.736 403.669 769.286 403.669C770.836 403.669 772.092 404.926 772.092 406.475Z" />
          <path d="M772.092 413.318C772.092 414.868 770.836 416.125 769.286 416.125C767.736 416.125 766.479 414.868 766.479 413.318C766.479 411.768 767.736 410.511 769.286 410.511C770.836 410.511 772.092 411.768 772.092 413.318Z" />
          <path d="M765.25 413.318C765.25 414.868 763.994 416.125 762.444 416.125C760.894 416.125 759.637 414.868 759.637 413.318C759.637 411.768 760.894 410.511 762.444 410.511C763.994 410.511 765.25 411.768 765.25 413.318Z" />
          <path d="M765.25 420.159C765.25 421.709 763.994 422.966 762.444 422.966C760.894 422.966 759.637 421.709 759.637 420.159C759.637 418.609 760.894 417.353 762.444 417.353C763.994 417.353 765.25 418.609 765.25 420.159Z" />
          <path d="M758.408 413.318C758.408 414.868 757.152 416.125 755.602 416.125C754.052 416.125 752.795 414.868 752.795 413.318C752.795 411.768 754.052 410.511 755.602 410.511C757.152 410.511 758.408 411.768 758.408 413.318Z" />
          <path d="M758.408 406.475C758.408 408.025 757.152 409.282 755.602 409.282C754.052 409.282 752.795 408.025 752.795 406.475C752.795 404.926 754.052 403.669 755.602 403.669C757.152 403.669 758.408 404.926 758.408 406.475Z" />
          <path d="M765.25 406.475C765.25 408.025 763.994 409.282 762.444 409.282C760.894 409.282 759.637 408.025 759.637 406.475C759.637 404.926 760.894 403.669 762.444 403.669C763.994 403.669 765.25 404.926 765.25 406.475Z" />
          <path d="M765.25 399.634C765.25 401.184 763.994 402.441 762.444 402.441C760.894 402.441 759.637 401.184 759.637 399.634C759.637 398.084 760.894 396.827 762.444 396.827C763.994 396.827 765.25 398.084 765.25 399.634Z" />
          <path d="M772.092 399.634C772.092 401.184 770.836 402.441 769.286 402.441C767.736 402.441 766.479 401.184 766.479 399.634C766.479 398.084 767.736 396.827 769.286 396.827C770.836 396.827 772.092 398.084 772.092 399.634Z" />
          <path d="M765.25 392.792C765.25 394.342 763.994 395.599 762.444 395.599C760.894 395.599 759.637 394.342 759.637 392.792C759.637 391.242 760.894 389.985 762.444 389.985C763.994 389.985 765.25 391.242 765.25 392.792Z" />
          <path d="M758.408 392.792C758.408 394.342 757.152 395.599 755.602 395.599C754.052 395.599 752.795 394.342 752.795 392.792C752.795 391.242 754.052 389.985 755.602 389.985C757.152 389.985 758.408 391.242 758.408 392.792Z" />
          <path d="M758.408 385.95C758.408 387.5 757.152 388.757 755.602 388.757C754.052 388.757 752.795 387.5 752.795 385.95C752.795 384.4 754.052 383.144 755.602 383.144C757.152 383.144 758.408 384.4 758.408 385.95Z" />
          <path d="M758.408 379.109C758.408 380.658 757.152 381.915 755.602 381.915C754.052 381.915 752.795 380.658 752.795 379.109C752.795 377.559 754.052 376.302 755.602 376.302C757.152 376.302 758.408 377.559 758.408 379.109Z" />
          <path d="M751.566 379.109C751.566 380.658 750.31 381.915 748.76 381.915C747.21 381.915 745.953 380.658 745.953 379.109C745.953 377.559 747.21 376.302 748.76 376.302C750.31 376.302 751.566 377.559 751.566 379.109Z" />
          <path d="M744.725 379.109C744.725 380.658 743.468 381.915 741.918 381.915C740.368 381.915 739.111 380.658 739.111 379.109C739.111 377.559 740.368 376.302 741.918 376.302C743.468 376.302 744.725 377.559 744.725 379.109Z" />
          <path d="M751.566 372.267C751.566 373.816 750.31 375.073 748.76 375.073C747.21 375.073 745.953 373.816 745.953 372.267C745.953 370.717 747.21 369.46 748.76 369.46C750.31 369.46 751.566 370.717 751.566 372.267Z" />
          <path d="M744.725 372.267C744.725 373.816 743.468 375.073 741.918 375.073C740.368 375.073 739.111 373.816 739.111 372.267C739.111 370.717 740.368 369.46 741.918 369.46C743.468 369.46 744.725 370.717 744.725 372.267Z" />
          <path d="M737.883 372.267C737.883 373.816 736.626 375.073 735.077 375.073C733.527 375.073 732.269 373.816 732.269 372.267C732.269 370.717 733.527 369.46 735.077 369.46C736.626 369.46 737.883 370.717 737.883 372.267Z" />
          <path d="M737.883 379.109C737.883 380.658 736.626 381.915 735.077 381.915C733.527 381.915 732.269 380.658 732.269 379.109C732.269 377.559 733.527 376.302 735.077 376.302C736.626 376.302 737.883 377.559 737.883 379.109Z" />
          <path d="M731.041 379.109C731.041 380.658 729.785 381.915 728.235 381.915C726.685 381.915 725.427 380.658 725.427 379.109C725.427 377.559 726.685 376.302 728.235 376.302C729.785 376.302 731.041 377.559 731.041 379.109Z" />
          <path d="M724.199 379.109C724.199 380.658 722.943 381.915 721.393 381.915C719.843 381.915 718.585 380.658 718.585 379.109C718.585 377.559 719.843 376.302 721.393 376.302C722.943 376.302 724.199 377.559 724.199 379.109Z" />
          <path d="M724.199 385.95C724.199 387.5 722.943 388.757 721.393 388.757C719.843 388.757 718.585 387.5 718.585 385.95C718.585 384.4 719.843 383.144 721.393 383.144C722.943 383.144 724.199 384.4 724.199 385.95Z" />
          <path d="M717.357 385.95C717.357 387.5 716.101 388.757 714.551 388.757C713.001 388.757 711.744 387.5 711.744 385.95C711.744 384.4 713.001 383.144 714.551 383.144C716.101 383.144 717.357 384.4 717.357 385.95Z" />
          <path d="M710.515 385.95C710.515 387.5 709.259 388.757 707.709 388.757C706.159 388.757 704.902 387.5 704.902 385.95C704.902 384.4 706.159 383.144 707.709 383.144C709.259 383.144 710.515 384.4 710.515 385.95Z" />
          <path d="M703.674 385.95C703.674 387.5 702.417 388.757 700.867 388.757C699.317 388.757 698.06 387.5 698.06 385.95C698.06 384.4 699.317 383.144 700.867 383.144C702.417 383.144 703.674 384.4 703.674 385.95Z" />
          <path d="M703.674 392.792C703.674 394.342 702.417 395.599 700.867 395.599C699.317 395.599 698.06 394.342 698.06 392.792C698.06 391.242 699.317 389.985 700.867 389.985C702.417 389.985 703.674 391.242 703.674 392.792Z" />
          <path d="M703.674 399.634C703.674 401.184 702.417 402.441 700.867 402.441C699.317 402.441 698.06 401.184 698.06 399.634C698.06 398.084 699.317 396.827 700.867 396.827C702.417 396.827 703.674 398.084 703.674 399.634Z" />
          <path d="M703.674 406.475C703.674 408.025 702.417 409.282 700.867 409.282C699.317 409.282 698.06 408.025 698.06 406.475C698.06 404.926 699.317 403.669 700.867 403.669C702.417 403.669 703.674 404.926 703.674 406.475Z" />
          <path d="M710.515 399.634C710.515 401.184 709.259 402.441 707.709 402.441C706.159 402.441 704.902 401.184 704.902 399.634C704.902 398.084 706.159 396.827 707.709 396.827C709.259 396.827 710.515 398.084 710.515 399.634Z" />
          <path d="M710.515 392.792C710.515 394.342 709.259 395.599 707.709 395.599C706.159 395.599 704.902 394.342 704.902 392.792C704.902 391.242 706.159 389.985 707.709 389.985C709.259 389.985 710.515 391.242 710.515 392.792Z" />
          <path d="M717.357 392.792C717.357 394.342 716.101 395.599 714.551 395.599C713.001 395.599 711.744 394.342 711.744 392.792C711.744 391.242 713.001 389.985 714.551 389.985C716.101 389.985 717.357 391.242 717.357 392.792Z" />
          <path d="M724.199 392.792C724.199 394.342 722.943 395.599 721.393 395.599C719.843 395.599 718.585 394.342 718.585 392.792C718.585 391.242 719.843 389.985 721.393 389.985C722.943 389.985 724.199 391.242 724.199 392.792Z" />
          <path d="M731.041 392.792C731.041 394.342 729.785 395.599 728.235 395.599C726.685 395.599 725.427 394.342 725.427 392.792C725.427 391.242 726.685 389.985 728.235 389.985C729.785 389.985 731.041 391.242 731.041 392.792Z" />
          <path d="M731.041 385.95C731.041 387.5 729.785 388.757 728.235 388.757C726.685 388.757 725.427 387.5 725.427 385.95C725.427 384.4 726.685 383.144 728.235 383.144C729.785 383.144 731.041 384.4 731.041 385.95Z" />
          <path d="M737.883 385.95C737.883 387.5 736.626 388.757 735.077 388.757C733.527 388.757 732.269 387.5 732.269 385.95C732.269 384.4 733.527 383.144 735.077 383.144C736.626 383.144 737.883 384.4 737.883 385.95Z" />
          <path d="M744.725 385.95C744.725 387.5 743.468 388.757 741.918 388.757C740.368 388.757 739.111 387.5 739.111 385.95C739.111 384.4 740.368 383.144 741.918 383.144C743.468 383.144 744.725 384.4 744.725 385.95Z" />
          <path d="M751.566 385.95C751.566 387.5 750.31 388.757 748.76 388.757C747.21 388.757 745.953 387.5 745.953 385.95C745.953 384.4 747.21 383.144 748.76 383.144C750.31 383.144 751.566 384.4 751.566 385.95Z" />
          <path d="M751.566 392.792C751.566 394.342 750.31 395.599 748.76 395.599C747.21 395.599 745.953 394.342 745.953 392.792C745.953 391.242 747.21 389.985 748.76 389.985C750.31 389.985 751.566 391.242 751.566 392.792Z" />
          <path d="M758.408 399.634C758.408 401.184 757.152 402.441 755.602 402.441C754.052 402.441 752.795 401.184 752.795 399.634C752.795 398.084 754.052 396.827 755.602 396.827C757.152 396.827 758.408 398.084 758.408 399.634Z" />
          <path d="M751.566 399.634C751.566 401.184 750.31 402.441 748.76 402.441C747.21 402.441 745.953 401.184 745.953 399.634C745.953 398.084 747.21 396.827 748.76 396.827C750.31 396.827 751.566 398.084 751.566 399.634Z" />
          <path d="M751.566 406.475C751.566 408.025 750.31 409.282 748.76 409.282C747.21 409.282 745.953 408.025 745.953 406.475C745.953 404.926 747.21 403.669 748.76 403.669C750.31 403.669 751.566 404.926 751.566 406.475Z" />
          <path d="M744.725 399.634C744.725 401.184 743.468 402.441 741.918 402.441C740.368 402.441 739.111 401.184 739.111 399.634C739.111 398.084 740.368 396.827 741.918 396.827C743.468 396.827 744.725 398.084 744.725 399.634Z" />
          <path d="M744.725 392.792C744.725 394.342 743.468 395.599 741.918 395.599C740.368 395.599 739.111 394.342 739.111 392.792C739.111 391.242 740.368 389.985 741.918 389.985C743.468 389.985 744.725 391.242 744.725 392.792Z" />
          <path d="M737.883 392.792C737.883 394.342 736.626 395.599 735.077 395.599C733.527 395.599 732.269 394.342 732.269 392.792C732.269 391.242 733.527 389.985 735.077 389.985C736.626 389.985 737.883 391.242 737.883 392.792Z" />
          <path d="M737.883 399.634C737.883 401.184 736.626 402.441 735.077 402.441C733.527 402.441 732.269 401.184 732.269 399.634C732.269 398.084 733.527 396.827 735.077 396.827C736.626 396.827 737.883 398.084 737.883 399.634Z" />
          <path d="M731.041 399.634C731.041 401.184 729.785 402.441 728.235 402.441C726.685 402.441 725.427 401.184 725.427 399.634C725.427 398.084 726.685 396.827 728.235 396.827C729.785 396.827 731.041 398.084 731.041 399.634Z" />
          <path d="M724.199 399.634C724.199 401.184 722.943 402.441 721.393 402.441C719.843 402.441 718.585 401.184 718.585 399.634C718.585 398.084 719.843 396.827 721.393 396.827C722.943 396.827 724.199 398.084 724.199 399.634Z" />
          <path d="M717.357 399.634C717.357 401.184 716.101 402.441 714.551 402.441C713.001 402.441 711.744 401.184 711.744 399.634C711.744 398.084 713.001 396.827 714.551 396.827C716.101 396.827 717.357 398.084 717.357 399.634Z" />
          <path d="M765.25 338.057C765.25 339.607 763.994 340.864 762.444 340.864C760.894 340.864 759.637 339.607 759.637 338.057C759.637 336.507 760.894 335.25 762.444 335.25C763.994 335.25 765.25 336.507 765.25 338.057Z" />
          <path d="M778.934 338.057C778.934 339.607 777.678 340.864 776.128 340.864C774.578 340.864 773.32 339.607 773.32 338.057C773.32 336.507 774.578 335.25 776.128 335.25C777.678 335.25 778.934 336.507 778.934 338.057Z" />
          <path d="M792.617 331.215C792.617 332.765 791.361 334.022 789.811 334.022C788.261 334.022 787.004 332.765 787.004 331.215C787.004 329.666 788.261 328.409 789.811 328.409C791.361 328.409 792.617 329.666 792.617 331.215Z" />
          <path d="M806.301 338.057C806.301 339.607 805.045 340.864 803.495 340.864C801.945 340.864 800.688 339.607 800.688 338.057C800.688 336.507 801.945 335.25 803.495 335.25C805.045 335.25 806.301 336.507 806.301 338.057Z" />
          <path d="M813.143 338.057C813.143 339.607 811.887 340.864 810.337 340.864C808.787 340.864 807.53 339.607 807.53 338.057C807.53 336.507 808.787 335.25 810.337 335.25C811.887 335.25 813.143 336.507 813.143 338.057Z" />
          <path d="M819.985 344.899C819.985 346.449 818.729 347.706 817.179 347.706C815.629 347.706 814.371 346.449 814.371 344.899C814.371 343.349 815.629 342.093 817.179 342.093C818.729 342.093 819.985 343.349 819.985 344.899Z" />
          <path d="M833.669 365.424C833.669 366.974 832.412 368.231 830.862 368.231C829.312 368.231 828.055 366.974 828.055 365.424C828.055 363.875 829.312 362.618 830.862 362.618C832.412 362.618 833.669 363.875 833.669 365.424Z" />
          <path d="M861.036 365.424C861.036 366.974 859.78 368.231 858.23 368.231C856.68 368.231 855.422 366.974 855.422 365.424C855.422 363.875 856.68 362.618 858.23 362.618C859.78 362.618 861.036 363.875 861.036 365.424Z" />
          <path d="M826.827 379.109C826.827 380.658 825.57 381.915 824.02 381.915C822.47 381.915 821.213 380.658 821.213 379.109C821.213 377.559 822.47 376.302 824.02 376.302C825.57 376.302 826.827 377.559 826.827 379.109Z" />
          <path d="M785.776 344.899C785.776 346.449 784.519 347.706 782.969 347.706C781.419 347.706 780.162 346.449 780.162 344.899C780.162 343.349 781.419 342.093 782.969 342.093C784.519 342.093 785.776 343.349 785.776 344.899Z" />
          <path d="M717.357 331.215C717.357 332.765 716.101 334.022 714.551 334.022C713.001 334.022 711.744 332.765 711.744 331.215C711.744 329.666 713.001 328.409 714.551 328.409C716.101 328.409 717.357 329.666 717.357 331.215Z" />
          <path d="M696.832 324.373C696.832 325.923 695.575 327.18 694.026 327.18C692.476 327.18 691.218 325.923 691.218 324.373C691.218 322.824 692.476 321.567 694.026 321.567C695.575 321.567 696.832 322.824 696.832 324.373Z" />
          <path d="M676.306 331.215C676.306 332.765 675.05 334.022 673.5 334.022C671.95 334.022 670.693 332.765 670.693 331.215C670.693 329.666 671.95 328.409 673.5 328.409C675.05 328.409 676.306 329.666 676.306 331.215Z" />
          <path d="M683.148 338.057C683.148 339.607 681.892 340.864 680.342 340.864C678.792 340.864 677.534 339.607 677.534 338.057C677.534 336.507 678.792 335.25 680.342 335.25C681.892 335.25 683.148 336.507 683.148 338.057Z" />
          <path d="M689.99 338.057C689.99 339.607 688.733 340.864 687.183 340.864C685.634 340.864 684.376 339.607 684.376 338.057C684.376 336.507 685.634 335.25 687.183 335.25C688.733 335.25 689.99 336.507 689.99 338.057Z" />
          <path d="M696.832 338.057C696.832 339.607 695.575 340.864 694.026 340.864C692.476 340.864 691.218 339.607 691.218 338.057C691.218 336.507 692.476 335.25 694.026 335.25C695.575 335.25 696.832 336.507 696.832 338.057Z" />
          <path d="M710.515 344.899C710.515 346.449 709.259 347.706 707.709 347.706C706.159 347.706 704.902 346.449 704.902 344.899C704.902 343.349 706.159 342.093 707.709 342.093C709.259 342.093 710.515 343.349 710.515 344.899Z" />
          <path d="M717.357 344.899C717.357 346.449 716.101 347.706 714.551 347.706C713.001 347.706 711.744 346.449 711.744 344.899C711.744 343.349 713.001 342.093 714.551 342.093C716.101 342.093 717.357 343.349 717.357 344.899Z" />
          <path d="M724.199 344.899C724.199 346.449 722.943 347.706 721.393 347.706C719.843 347.706 718.585 346.449 718.585 344.899C718.585 343.349 719.843 342.093 721.393 342.093C722.943 342.093 724.199 343.349 724.199 344.899Z" />
          <path d="M662.623 317.532C662.623 319.082 661.366 320.339 659.816 320.339C658.266 320.339 657.009 319.082 657.009 317.532C657.009 315.982 658.266 314.725 659.816 314.725C661.366 314.725 662.623 315.982 662.623 317.532Z" />
          <path d="M655.78 310.69C655.78 312.24 654.524 313.497 652.974 313.497C651.424 313.497 650.167 312.24 650.167 310.69C650.167 309.14 651.424 307.883 652.974 307.883C654.524 307.883 655.78 309.14 655.78 310.69Z" />
          <path d="M669.464 276.481C669.464 278.031 668.208 279.288 666.658 279.288C665.108 279.288 663.851 278.031 663.851 276.481C663.851 274.931 665.108 273.674 666.658 273.674C668.208 273.674 669.464 274.931 669.464 276.481Z" />
          <path d="M676.306 276.481C676.306 278.031 675.05 279.288 673.5 279.288C671.95 279.288 670.693 278.031 670.693 276.481C670.693 274.931 671.95 273.674 673.5 273.674C675.05 273.674 676.306 274.931 676.306 276.481Z" />
          <path d="M669.464 269.639C669.464 271.189 668.208 272.445 666.658 272.445C665.108 272.445 663.851 271.189 663.851 269.639C663.851 268.089 665.108 266.832 666.658 266.832C668.208 266.832 669.464 268.089 669.464 269.639Z" />
          <path d="M669.464 262.797C669.464 264.347 668.208 265.604 666.658 265.604C665.108 265.604 663.851 264.347 663.851 262.797C663.851 261.247 665.108 259.99 666.658 259.99C668.208 259.99 669.464 261.247 669.464 262.797Z" />
          <path d="M662.623 262.797C662.623 264.347 661.366 265.604 659.816 265.604C658.266 265.604 657.009 264.347 657.009 262.797C657.009 261.247 658.266 259.99 659.816 259.99C661.366 259.99 662.623 261.247 662.623 262.797Z" />
          <path d="M655.78 262.797C655.78 264.347 654.524 265.604 652.974 265.604C651.424 265.604 650.167 264.347 650.167 262.797C650.167 261.247 651.424 259.99 652.974 259.99C654.524 259.99 655.78 261.247 655.78 262.797Z" />
          <path d="M655.78 255.955C655.78 257.505 654.524 258.762 652.974 258.762C651.424 258.762 650.167 257.505 650.167 255.955C650.167 254.405 651.424 253.148 652.974 253.148C654.524 253.148 655.78 254.405 655.78 255.955Z" />
          <path d="M662.623 255.955C662.623 257.505 661.366 258.762 659.816 258.762C658.266 258.762 657.009 257.505 657.009 255.955C657.009 254.405 658.266 253.148 659.816 253.148C661.366 253.148 662.623 254.405 662.623 255.955Z" />
          <path d="M669.464 255.955C669.464 257.505 668.208 258.762 666.658 258.762C665.108 258.762 663.851 257.505 663.851 255.955C663.851 254.405 665.108 253.148 666.658 253.148C668.208 253.148 669.464 254.405 669.464 255.955Z" />
          <path d="M676.306 255.955C676.306 257.505 675.05 258.762 673.5 258.762C671.95 258.762 670.693 257.505 670.693 255.955C670.693 254.405 671.95 253.148 673.5 253.148C675.05 253.148 676.306 254.405 676.306 255.955Z" />
          <path d="M683.148 255.955C683.148 257.505 681.892 258.762 680.342 258.762C678.792 258.762 677.534 257.505 677.534 255.955C677.534 254.405 678.792 253.148 680.342 253.148C681.892 253.148 683.148 254.405 683.148 255.955Z" />
          <path d="M689.99 255.955C689.99 257.505 688.733 258.762 687.183 258.762C685.634 258.762 684.376 257.505 684.376 255.955C684.376 254.405 685.634 253.148 687.183 253.148C688.733 253.148 689.99 254.405 689.99 255.955Z" />
          <path d="M689.99 269.639C689.99 271.189 688.733 272.445 687.183 272.445C685.634 272.445 684.376 271.189 684.376 269.639C684.376 268.089 685.634 266.832 687.183 266.832C688.733 266.832 689.99 268.089 689.99 269.639Z" />
          <path d="M689.99 249.113C689.99 250.663 688.733 251.92 687.183 251.92C685.634 251.92 684.376 250.663 684.376 249.113C684.376 247.563 685.634 246.307 687.183 246.307C688.733 246.307 689.99 247.563 689.99 249.113Z" />
          <path d="M696.832 249.113C696.832 250.663 695.575 251.92 694.026 251.92C692.476 251.92 691.218 250.663 691.218 249.113C691.218 247.563 692.476 246.307 694.026 246.307C695.575 246.307 696.832 247.563 696.832 249.113Z" />
          <path d="M703.674 249.113C703.674 250.663 702.417 251.92 700.867 251.92C699.317 251.92 698.06 250.663 698.06 249.113C698.06 247.563 699.317 246.307 700.867 246.307C702.417 246.307 703.674 247.563 703.674 249.113Z" />
          <path d="M710.515 255.955C710.515 257.505 709.259 258.762 707.709 258.762C706.159 258.762 704.902 257.505 704.902 255.955C704.902 254.405 706.159 253.148 707.709 253.148C709.259 253.148 710.515 254.405 710.515 255.955Z" />
          <path d="M717.357 276.481C717.357 278.031 716.101 279.288 714.551 279.288C713.001 279.288 711.744 278.031 711.744 276.481C711.744 274.931 713.001 273.674 714.551 273.674C716.101 273.674 717.357 274.931 717.357 276.481Z" />
          <path d="M717.357 283.323C717.357 284.873 716.101 286.129 714.551 286.129C713.001 286.129 711.744 284.873 711.744 283.323C711.744 281.773 713.001 280.516 714.551 280.516C716.101 280.516 717.357 281.773 717.357 283.323Z" />
          <path d="M724.199 290.164C724.199 291.714 722.943 292.971 721.393 292.971C719.843 292.971 718.585 291.714 718.585 290.164C718.585 288.615 719.843 287.358 721.393 287.358C722.943 287.358 724.199 288.615 724.199 290.164Z" />
          <path d="M724.199 297.006C724.199 298.556 722.943 299.813 721.393 299.813C719.843 299.813 718.585 298.556 718.585 297.006C718.585 295.456 719.843 294.199 721.393 294.199C722.943 294.199 724.199 295.456 724.199 297.006Z" />
          <path d="M731.041 297.006C731.041 298.556 729.785 299.813 728.235 299.813C726.685 299.813 725.427 298.556 725.427 297.006C725.427 295.456 726.685 294.199 728.235 294.199C729.785 294.199 731.041 295.456 731.041 297.006Z" />
          <path d="M703.674 242.272C703.674 243.822 702.417 245.078 700.867 245.078C699.317 245.078 698.06 243.822 698.06 242.272C698.06 240.722 699.317 239.465 700.867 239.465C702.417 239.465 703.674 240.722 703.674 242.272Z" />
          <path d="M703.674 235.43C703.674 236.98 702.417 238.236 700.867 238.236C699.317 238.236 698.06 236.98 698.06 235.43C698.06 233.88 699.317 232.623 700.867 232.623C702.417 232.623 703.674 233.88 703.674 235.43Z" />
          <path d="M710.515 235.43C710.515 236.98 709.259 238.236 707.709 238.236C706.159 238.236 704.902 236.98 704.902 235.43C704.902 233.88 706.159 232.623 707.709 232.623C709.259 232.623 710.515 233.88 710.515 235.43Z" />
          <path d="M703.674 228.588C703.674 230.137 702.417 231.394 700.867 231.394C699.317 231.394 698.06 230.137 698.06 228.588C698.06 227.038 699.317 225.781 700.867 225.781C702.417 225.781 703.674 227.038 703.674 228.588Z" />
          <path d="M696.832 228.588C696.832 230.137 695.575 231.394 694.026 231.394C692.476 231.394 691.218 230.137 691.218 228.588C691.218 227.038 692.476 225.781 694.026 225.781C695.575 225.781 696.832 227.038 696.832 228.588Z" />
          <path d="M696.832 221.746C696.832 223.296 695.575 224.553 694.026 224.553C692.476 224.553 691.218 223.296 691.218 221.746C691.218 220.196 692.476 218.939 694.026 218.939C695.575 218.939 696.832 220.196 696.832 221.746Z" />
          <path d="M696.832 214.904C696.832 216.454 695.575 217.711 694.026 217.711C692.476 217.711 691.218 216.454 691.218 214.904C691.218 213.354 692.476 212.097 694.026 212.097C695.575 212.097 696.832 213.354 696.832 214.904Z" />
          <path d="M689.99 214.904C689.99 216.454 688.733 217.711 687.183 217.711C685.634 217.711 684.376 216.454 684.376 214.904C684.376 213.354 685.634 212.097 687.183 212.097C688.733 212.097 689.99 213.354 689.99 214.904Z" />
          <path d="M689.99 208.062C689.99 209.612 688.733 210.869 687.183 210.869C685.634 210.869 684.376 209.612 684.376 208.062C684.376 206.512 685.634 205.256 687.183 205.256C688.733 205.256 689.99 206.512 689.99 208.062Z" />
          <path d="M689.99 201.22C689.99 202.77 688.733 204.027 687.183 204.027C685.634 204.027 684.376 202.77 684.376 201.22C684.376 199.67 685.634 198.413 687.183 198.413C688.733 198.413 689.99 199.67 689.99 201.22Z" />
          <path d="M696.832 201.22C696.832 202.77 695.575 204.027 694.026 204.027C692.476 204.027 691.218 202.77 691.218 201.22C691.218 199.67 692.476 198.413 694.026 198.413C695.575 198.413 696.832 199.67 696.832 201.22Z" />
          <path d="M689.99 194.379C689.99 195.929 688.733 197.185 687.183 197.185C685.634 197.185 684.376 195.929 684.376 194.379C684.376 192.829 685.634 191.572 687.183 191.572C688.733 191.572 689.99 192.829 689.99 194.379Z" />
          <path d="M696.832 194.379C696.832 195.929 695.575 197.185 694.026 197.185C692.476 197.185 691.218 195.929 691.218 194.379C691.218 192.829 692.476 191.572 694.026 191.572C695.575 191.572 696.832 192.829 696.832 194.379Z" />
          <path d="M703.674 194.379C703.674 195.929 702.417 197.185 700.867 197.185C699.317 197.185 698.06 195.929 698.06 194.379C698.06 192.829 699.317 191.572 700.867 191.572C702.417 191.572 703.674 192.829 703.674 194.379Z" />
          <path d="M710.515 194.379C710.515 195.929 709.259 197.185 707.709 197.185C706.159 197.185 704.902 195.929 704.902 194.379C704.902 192.829 706.159 191.572 707.709 191.572C709.259 191.572 710.515 192.829 710.515 194.379Z" />
          <path d="M717.357 194.379C717.357 195.929 716.101 197.185 714.551 197.185C713.001 197.185 711.744 195.929 711.744 194.379C711.744 192.829 713.001 191.572 714.551 191.572C716.101 191.572 717.357 192.829 717.357 194.379Z" />
          <path d="M717.357 187.537C717.357 189.087 716.101 190.344 714.551 190.344C713.001 190.344 711.744 189.087 711.744 187.537C711.744 185.987 713.001 184.73 714.551 184.73C716.101 184.73 717.357 185.987 717.357 187.537Z" />
          <path d="M717.357 180.695C717.357 182.245 716.101 183.502 714.551 183.502C713.001 183.502 711.744 182.245 711.744 180.695C711.744 179.145 713.001 177.888 714.551 177.888C716.101 177.888 717.357 179.145 717.357 180.695Z" />
          <path d="M724.199 180.695C724.199 182.245 722.943 183.502 721.393 183.502C719.843 183.502 718.585 182.245 718.585 180.695C718.585 179.145 719.843 177.888 721.393 177.888C722.943 177.888 724.199 179.145 724.199 180.695Z" />
          <path d="M731.041 180.695C731.041 182.245 729.785 183.502 728.235 183.502C726.685 183.502 725.427 182.245 725.427 180.695C725.427 179.145 726.685 177.888 728.235 177.888C729.785 177.888 731.041 179.145 731.041 180.695Z" />
          <path d="M731.041 173.853C731.041 175.403 729.785 176.66 728.235 176.66C726.685 176.66 725.427 175.403 725.427 173.853C725.427 172.303 726.685 171.046 728.235 171.046C729.785 171.046 731.041 172.303 731.041 173.853Z" />
          <path d="M731.041 167.011C731.041 168.561 729.785 169.818 728.235 169.818C726.685 169.818 725.427 168.561 725.427 167.011C725.427 165.461 726.685 164.204 728.235 164.204C729.785 164.204 731.041 165.461 731.041 167.011Z" />
          <path d="M731.041 160.169C731.041 161.719 729.785 162.976 728.235 162.976C726.685 162.976 725.427 161.719 725.427 160.169C725.427 158.619 726.685 157.363 728.235 157.363C729.785 157.363 731.041 158.619 731.041 160.169Z" />
          <path d="M731.041 153.328C731.041 154.877 729.785 156.134 728.235 156.134C726.685 156.134 725.427 154.877 725.427 153.328C725.427 151.778 726.685 150.521 728.235 150.521C729.785 150.521 731.041 151.778 731.041 153.328Z" />
          <path d="M731.041 146.486C731.041 148.036 729.785 149.292 728.235 149.292C726.685 149.292 725.427 148.036 725.427 146.486C725.427 144.936 726.685 143.679 728.235 143.679C729.785 143.679 731.041 144.936 731.041 146.486Z" />
          <path d="M737.883 146.486C737.883 148.036 736.626 149.292 735.077 149.292C733.527 149.292 732.269 148.036 732.269 146.486C732.269 144.936 733.527 143.679 735.077 143.679C736.626 143.679 737.883 144.936 737.883 146.486Z" />
          <path d="M744.725 153.328C744.725 154.877 743.468 156.134 741.918 156.134C740.368 156.134 739.111 154.877 739.111 153.328C739.111 151.778 740.368 150.521 741.918 150.521C743.468 150.521 744.725 151.778 744.725 153.328Z" />
          <path d="M744.725 160.169C744.725 161.719 743.468 162.976 741.918 162.976C740.368 162.976 739.111 161.719 739.111 160.169C739.111 158.619 740.368 157.363 741.918 157.363C743.468 157.363 744.725 158.619 744.725 160.169Z" />
          <path d="M744.725 167.011C744.725 168.561 743.468 169.818 741.918 169.818C740.368 169.818 739.111 168.561 739.111 167.011C739.111 165.461 740.368 164.204 741.918 164.204C743.468 164.204 744.725 165.461 744.725 167.011Z" />
          <path d="M751.566 180.695C751.566 182.245 750.31 183.502 748.76 183.502C747.21 183.502 745.953 182.245 745.953 180.695C745.953 179.145 747.21 177.888 748.76 177.888C750.31 177.888 751.566 179.145 751.566 180.695Z" />
          <path d="M758.408 180.695C758.408 182.245 757.152 183.502 755.602 183.502C754.052 183.502 752.795 182.245 752.795 180.695C752.795 179.145 754.052 177.888 755.602 177.888C757.152 177.888 758.408 179.145 758.408 180.695Z" />
          <path d="M765.25 173.853C765.25 175.403 763.994 176.66 762.444 176.66C760.894 176.66 759.637 175.403 759.637 173.853C759.637 172.303 760.894 171.046 762.444 171.046C763.994 171.046 765.25 172.303 765.25 173.853Z" />
          <path d="M772.092 160.169C772.092 161.719 770.836 162.976 769.286 162.976C767.736 162.976 766.479 161.719 766.479 160.169C766.479 158.619 767.736 157.363 769.286 157.363C770.836 157.363 772.092 158.619 772.092 160.169Z" />
          <path d="M751.566 187.537C751.566 189.087 750.31 190.344 748.76 190.344C747.21 190.344 745.953 189.087 745.953 187.537C745.953 185.987 747.21 184.73 748.76 184.73C750.31 184.73 751.566 185.987 751.566 187.537Z" />
          <path d="M751.566 194.379C751.566 195.929 750.31 197.185 748.76 197.185C747.21 197.185 745.953 195.929 745.953 194.379C745.953 192.829 747.21 191.572 748.76 191.572C750.31 191.572 751.566 192.829 751.566 194.379Z" />
          <path d="M751.566 201.22C751.566 202.77 750.31 204.027 748.76 204.027C747.21 204.027 745.953 202.77 745.953 201.22C745.953 199.67 747.21 198.413 748.76 198.413C750.31 198.413 751.566 199.67 751.566 201.22Z" />
          <path d="M751.566 208.062C751.566 209.612 750.31 210.869 748.76 210.869C747.21 210.869 745.953 209.612 745.953 208.062C745.953 206.512 747.21 205.256 748.76 205.256C750.31 205.256 751.566 206.512 751.566 208.062Z" />
          <path d="M744.725 214.904C744.725 216.454 743.468 217.711 741.918 217.711C740.368 217.711 739.111 216.454 739.111 214.904C739.111 213.354 740.368 212.097 741.918 212.097C743.468 212.097 744.725 213.354 744.725 214.904Z" />
          <path d="M737.883 221.746C737.883 223.296 736.626 224.553 735.077 224.553C733.527 224.553 732.269 223.296 732.269 221.746C732.269 220.196 733.527 218.939 735.077 218.939C736.626 218.939 737.883 220.196 737.883 221.746Z" />
          <path d="M731.041 228.588C731.041 230.137 729.785 231.394 728.235 231.394C726.685 231.394 725.427 230.137 725.427 228.588C725.427 227.038 726.685 225.781 728.235 225.781C729.785 225.781 731.041 227.038 731.041 228.588Z" />
          <path d="M724.199 146.486C724.199 148.036 722.943 149.292 721.393 149.292C719.843 149.292 718.585 148.036 718.585 146.486C718.585 144.936 719.843 143.679 721.393 143.679C722.943 143.679 724.199 144.936 724.199 146.486Z" />
          <path d="M717.357 146.486C717.357 148.036 716.101 149.292 714.551 149.292C713.001 149.292 711.744 148.036 711.744 146.486C711.744 144.936 713.001 143.679 714.551 143.679C716.101 143.679 717.357 144.936 717.357 146.486Z" />
          <path d="M717.357 153.328C717.357 154.877 716.101 156.134 714.551 156.134C713.001 156.134 711.744 154.877 711.744 153.328C711.744 151.778 713.001 150.521 714.551 150.521C716.101 150.521 717.357 151.778 717.357 153.328Z" />
          <path d="M724.199 153.328C724.199 154.877 722.943 156.134 721.393 156.134C719.843 156.134 718.585 154.877 718.585 153.328C718.585 151.778 719.843 150.521 721.393 150.521C722.943 150.521 724.199 151.778 724.199 153.328Z" />
          <path d="M724.199 160.169C724.199 161.719 722.943 162.976 721.393 162.976C719.843 162.976 718.585 161.719 718.585 160.169C718.585 158.619 719.843 157.363 721.393 157.363C722.943 157.363 724.199 158.619 724.199 160.169Z" />
          <path d="M724.199 167.011C724.199 168.561 722.943 169.818 721.393 169.818C719.843 169.818 718.585 168.561 718.585 167.011C718.585 165.461 719.843 164.204 721.393 164.204C722.943 164.204 724.199 165.461 724.199 167.011Z" />
          <path d="M724.199 173.853C724.199 175.403 722.943 176.66 721.393 176.66C719.843 176.66 718.585 175.403 718.585 173.853C718.585 172.303 719.843 171.046 721.393 171.046C722.943 171.046 724.199 172.303 724.199 173.853Z" />
          <path d="M717.357 173.853C717.357 175.403 716.101 176.66 714.551 176.66C713.001 176.66 711.744 175.403 711.744 173.853C711.744 172.303 713.001 171.046 714.551 171.046C716.101 171.046 717.357 172.303 717.357 173.853Z" />
          <path d="M717.357 167.011C717.357 168.561 716.101 169.818 714.551 169.818C713.001 169.818 711.744 168.561 711.744 167.011C711.744 165.461 713.001 164.204 714.551 164.204C716.101 164.204 717.357 165.461 717.357 167.011Z" />
          <path d="M717.357 160.169C717.357 161.719 716.101 162.976 714.551 162.976C713.001 162.976 711.744 161.719 711.744 160.169C711.744 158.619 713.001 157.363 714.551 157.363C716.101 157.363 717.357 158.619 717.357 160.169Z" />
          <path d="M710.515 160.169C710.515 161.719 709.259 162.976 707.709 162.976C706.159 162.976 704.902 161.719 704.902 160.169C704.902 158.619 706.159 157.363 707.709 157.363C709.259 157.363 710.515 158.619 710.515 160.169Z" />
          <path d="M710.515 167.011C710.515 168.561 709.259 169.818 707.709 169.818C706.159 169.818 704.902 168.561 704.902 167.011C704.902 165.461 706.159 164.204 707.709 164.204C709.259 164.204 710.515 165.461 710.515 167.011Z" />
          <path d="M710.515 173.853C710.515 175.403 709.259 176.66 707.709 176.66C706.159 176.66 704.902 175.403 704.902 173.853C704.902 172.303 706.159 171.046 707.709 171.046C709.259 171.046 710.515 172.303 710.515 173.853Z" />
          <path d="M710.515 180.695C710.515 182.245 709.259 183.502 707.709 183.502C706.159 183.502 704.902 182.245 704.902 180.695C704.902 179.145 706.159 177.888 707.709 177.888C709.259 177.888 710.515 179.145 710.515 180.695Z" />
          <path d="M710.515 187.537C710.515 189.087 709.259 190.344 707.709 190.344C706.159 190.344 704.902 189.087 704.902 187.537C704.902 185.987 706.159 184.73 707.709 184.73C709.259 184.73 710.515 185.987 710.515 187.537Z" />
          <path d="M703.674 187.537C703.674 189.087 702.417 190.344 700.867 190.344C699.317 190.344 698.06 189.087 698.06 187.537C698.06 185.987 699.317 184.73 700.867 184.73C702.417 184.73 703.674 185.987 703.674 187.537Z" />
          <path d="M696.832 187.537C696.832 189.087 695.575 190.344 694.026 190.344C692.476 190.344 691.218 189.087 691.218 187.537C691.218 185.987 692.476 184.73 694.026 184.73C695.575 184.73 696.832 185.987 696.832 187.537Z" />
          <path d="M689.99 187.537C689.99 189.087 688.733 190.344 687.183 190.344C685.634 190.344 684.376 189.087 684.376 187.537C684.376 185.987 685.634 184.73 687.183 184.73C688.733 184.73 689.99 185.987 689.99 187.537Z" />
          <path d="M683.148 187.537C683.148 189.087 681.892 190.344 680.342 190.344C678.792 190.344 677.534 189.087 677.534 187.537C677.534 185.987 678.792 184.73 680.342 184.73C681.892 184.73 683.148 185.987 683.148 187.537Z" />
          <path d="M676.306 187.537C676.306 189.087 675.05 190.344 673.5 190.344C671.95 190.344 670.693 189.087 670.693 187.537C670.693 185.987 671.95 184.73 673.5 184.73C675.05 184.73 676.306 185.987 676.306 187.537Z" />
          <path d="M676.306 194.379C676.306 195.929 675.05 197.185 673.5 197.185C671.95 197.185 670.693 195.929 670.693 194.379C670.693 192.829 671.95 191.572 673.5 191.572C675.05 191.572 676.306 192.829 676.306 194.379Z" />
          <path d="M676.306 201.22C676.306 202.77 675.05 204.027 673.5 204.027C671.95 204.027 670.693 202.77 670.693 201.22C670.693 199.67 671.95 198.413 673.5 198.413C675.05 198.413 676.306 199.67 676.306 201.22Z" />
          <path d="M676.306 208.062C676.306 209.612 675.05 210.869 673.5 210.869C671.95 210.869 670.693 209.612 670.693 208.062C670.693 206.512 671.95 205.256 673.5 205.256C675.05 205.256 676.306 206.512 676.306 208.062Z" />
          <path d="M676.306 214.904C676.306 216.454 675.05 217.711 673.5 217.711C671.95 217.711 670.693 216.454 670.693 214.904C670.693 213.354 671.95 212.097 673.5 212.097C675.05 212.097 676.306 213.354 676.306 214.904Z" />
          <path d="M676.306 221.746C676.306 223.296 675.05 224.553 673.5 224.553C671.95 224.553 670.693 223.296 670.693 221.746C670.693 220.196 671.95 218.939 673.5 218.939C675.05 218.939 676.306 220.196 676.306 221.746Z" />
          <path d="M676.306 228.588C676.306 230.137 675.05 231.394 673.5 231.394C671.95 231.394 670.693 230.137 670.693 228.588C670.693 227.038 671.95 225.781 673.5 225.781C675.05 225.781 676.306 227.038 676.306 228.588Z" />
          <path d="M676.306 235.43C676.306 236.98 675.05 238.236 673.5 238.236C671.95 238.236 670.693 236.98 670.693 235.43C670.693 233.88 671.95 232.623 673.5 232.623C675.05 232.623 676.306 233.88 676.306 235.43Z" />
          <path d="M669.464 235.43C669.464 236.98 668.208 238.236 666.658 238.236C665.108 238.236 663.851 236.98 663.851 235.43C663.851 233.88 665.108 232.623 666.658 232.623C668.208 232.623 669.464 233.88 669.464 235.43Z" />
          <path d="M669.464 242.272C669.464 243.822 668.208 245.078 666.658 245.078C665.108 245.078 663.851 243.822 663.851 242.272C663.851 240.722 665.108 239.465 666.658 239.465C668.208 239.465 669.464 240.722 669.464 242.272Z" />
          <path d="M662.623 242.272C662.623 243.822 661.366 245.078 659.816 245.078C658.266 245.078 657.009 243.822 657.009 242.272C657.009 240.722 658.266 239.465 659.816 239.465C661.366 239.465 662.623 240.722 662.623 242.272Z" />
          <path d="M655.78 242.272C655.78 243.822 654.524 245.078 652.974 245.078C651.424 245.078 650.167 243.822 650.167 242.272C650.167 240.722 651.424 239.465 652.974 239.465C654.524 239.465 655.78 240.722 655.78 242.272Z" />
          <path d="M655.78 249.113C655.78 250.663 654.524 251.92 652.974 251.92C651.424 251.92 650.167 250.663 650.167 249.113C650.167 247.563 651.424 246.307 652.974 246.307C654.524 246.307 655.78 247.563 655.78 249.113Z" />
          <path d="M648.939 249.113C648.939 250.663 647.682 251.92 646.132 251.92C644.583 251.92 643.325 250.663 643.325 249.113C643.325 247.563 644.583 246.307 646.132 246.307C647.682 246.307 648.939 247.563 648.939 249.113Z" />
          <path d="M642.097 249.113C642.097 250.663 640.841 251.92 639.291 251.92C637.741 251.92 636.483 250.663 636.483 249.113C636.483 247.563 637.741 246.307 639.291 246.307C640.841 246.307 642.097 247.563 642.097 249.113Z" />
          <path d="M635.255 249.113C635.255 250.663 633.999 251.92 632.449 251.92C630.899 251.92 629.642 250.663 629.642 249.113C629.642 247.563 630.899 246.307 632.449 246.307C633.999 246.307 635.255 247.563 635.255 249.113Z" />
          <path d="M628.413 249.113C628.413 250.663 627.157 251.92 625.607 251.92C624.057 251.92 622.8 250.663 622.8 249.113C622.8 247.563 624.057 246.307 625.607 246.307C627.157 246.307 628.413 247.563 628.413 249.113Z" />
          <path d="M621.571 249.113C621.571 250.663 620.315 251.92 618.765 251.92C617.215 251.92 615.958 250.663 615.958 249.113C615.958 247.563 617.215 246.307 618.765 246.307C620.315 246.307 621.571 247.563 621.571 249.113Z" />
          <path d="M614.73 249.113C614.73 250.663 613.473 251.92 611.923 251.92C610.374 251.92 609.116 250.663 609.116 249.113C609.116 247.563 610.374 246.307 611.923 246.307C613.473 246.307 614.73 247.563 614.73 249.113Z" />
          <path d="M607.888 249.113C607.888 250.663 606.631 251.92 605.081 251.92C603.531 251.92 602.274 250.663 602.274 249.113C602.274 247.563 603.531 246.307 605.081 246.307C606.631 246.307 607.888 247.563 607.888 249.113Z" />
          <path d="M607.888 255.955C607.888 257.505 606.631 258.762 605.081 258.762C603.531 258.762 602.274 257.505 602.274 255.955C602.274 254.405 603.531 253.148 605.081 253.148C606.631 253.148 607.888 254.405 607.888 255.955Z" />
          <path d="M607.888 262.797C607.888 264.347 606.631 265.604 605.081 265.604C603.531 265.604 602.274 264.347 602.274 262.797C602.274 261.247 603.531 259.99 605.081 259.99C606.631 259.99 607.888 261.247 607.888 262.797Z" />
          <path d="M614.73 255.955C614.73 257.505 613.473 258.762 611.923 258.762C610.374 258.762 609.116 257.505 609.116 255.955C609.116 254.405 610.374 253.148 611.923 253.148C613.473 253.148 614.73 254.405 614.73 255.955Z" />
          <path d="M607.888 242.272C607.888 243.822 606.631 245.078 605.081 245.078C603.531 245.078 602.274 243.822 602.274 242.272C602.274 240.722 603.531 239.465 605.081 239.465C606.631 239.465 607.888 240.722 607.888 242.272Z" />
          <path d="M601.046 242.272C601.046 243.822 599.79 245.078 598.24 245.078C596.69 245.078 595.432 243.822 595.432 242.272C595.432 240.722 596.69 239.465 598.24 239.465C599.79 239.465 601.046 240.722 601.046 242.272Z" />
          <path d="M601.046 249.113C601.046 250.663 599.79 251.92 598.24 251.92C596.69 251.92 595.432 250.663 595.432 249.113C595.432 247.563 596.69 246.307 598.24 246.307C599.79 246.307 601.046 247.563 601.046 249.113Z" />
          <path d="M594.204 249.113C594.204 250.663 592.948 251.92 591.398 251.92C589.848 251.92 588.591 250.663 588.591 249.113C588.591 247.563 589.848 246.307 591.398 246.307C592.948 246.307 594.204 247.563 594.204 249.113Z" />
          <path d="M594.204 242.272C594.204 243.822 592.948 245.078 591.398 245.078C589.848 245.078 588.591 243.822 588.591 242.272C588.591 240.722 589.848 239.465 591.398 239.465C592.948 239.465 594.204 240.722 594.204 242.272Z" />
          <path d="M587.362 242.272C587.362 243.822 586.106 245.078 584.556 245.078C583.006 245.078 581.749 243.822 581.749 242.272C581.749 240.722 583.006 239.465 584.556 239.465C586.106 239.465 587.362 240.722 587.362 242.272Z" />
          <path d="M580.52 242.272C580.52 243.822 579.264 245.078 577.714 245.078C576.164 245.078 574.907 243.822 574.907 242.272C574.907 240.722 576.164 239.465 577.714 239.465C579.264 239.465 580.52 240.722 580.52 242.272Z" />
          <path d="M573.678 242.272C573.678 243.822 572.422 245.078 570.872 245.078C569.322 245.078 568.065 243.822 568.065 242.272C568.065 240.722 569.322 239.465 570.872 239.465C572.422 239.465 573.678 240.722 573.678 242.272Z" />
          <path d="M566.837 242.272C566.837 243.822 565.58 245.078 564.03 245.078C562.48 245.078 561.223 243.822 561.223 242.272C561.223 240.722 562.48 239.465 564.03 239.465C565.58 239.465 566.837 240.722 566.837 242.272Z" />
          <path d="M566.837 235.43C566.837 236.98 565.58 238.236 564.03 238.236C562.48 238.236 561.223 236.98 561.223 235.43C561.223 233.88 562.48 232.623 564.03 232.623C565.58 232.623 566.837 233.88 566.837 235.43Z" />
          <path d="M559.995 235.43C559.995 236.98 558.739 238.236 557.189 238.236C555.639 238.236 554.381 236.98 554.381 235.43C554.381 233.88 555.639 232.623 557.189 232.623C558.739 232.623 559.995 233.88 559.995 235.43Z" />
          <path d="M553.153 228.588C553.153 230.137 551.897 231.394 550.347 231.394C548.797 231.394 547.539 230.137 547.539 228.588C547.539 227.038 548.797 225.781 550.347 225.781C551.897 225.781 553.153 227.038 553.153 228.588Z" />
          <path d="M553.153 221.746C553.153 223.296 551.897 224.553 550.347 224.553C548.797 224.553 547.539 223.296 547.539 221.746C547.539 220.196 548.797 218.939 550.347 218.939C551.897 218.939 553.153 220.196 553.153 221.746Z" />
          <path d="M553.153 214.904C553.153 216.454 551.897 217.711 550.347 217.711C548.797 217.711 547.539 216.454 547.539 214.904C547.539 213.354 548.797 212.097 550.347 212.097C551.897 212.097 553.153 213.354 553.153 214.904Z" />
          <path d="M553.153 208.062C553.153 209.612 551.897 210.869 550.347 210.869C548.797 210.869 547.539 209.612 547.539 208.062C547.539 206.512 548.797 205.256 550.347 205.256C551.897 205.256 553.153 206.512 553.153 208.062Z" />
          <path d="M553.153 201.22C553.153 202.77 551.897 204.027 550.347 204.027C548.797 204.027 547.539 202.77 547.539 201.22C547.539 199.67 548.797 198.413 550.347 198.413C551.897 198.413 553.153 199.67 553.153 201.22Z" />
          <path d="M546.311 201.22C546.311 202.77 545.055 204.027 543.505 204.027C541.955 204.027 540.698 202.77 540.698 201.22C540.698 199.67 541.955 198.413 543.505 198.413C545.055 198.413 546.311 199.67 546.311 201.22Z" />
          <path d="M546.311 194.379C546.311 195.929 545.055 197.185 543.505 197.185C541.955 197.185 540.698 195.929 540.698 194.379C540.698 192.829 541.955 191.572 543.505 191.572C545.055 191.572 546.311 192.829 546.311 194.379Z" />
          <path d="M546.311 187.537C546.311 189.087 545.055 190.344 543.505 190.344C541.955 190.344 540.698 189.087 540.698 187.537C540.698 185.987 541.955 184.73 543.505 184.73C545.055 184.73 546.311 185.987 546.311 187.537Z" />
          <path d="M546.311 180.695C546.311 182.245 545.055 183.502 543.505 183.502C541.955 183.502 540.698 182.245 540.698 180.695C540.698 179.145 541.955 177.888 543.505 177.888C545.055 177.888 546.311 179.145 546.311 180.695Z" />
          <path d="M553.153 180.695C553.153 182.245 551.897 183.502 550.347 183.502C548.797 183.502 547.539 182.245 547.539 180.695C547.539 179.145 548.797 177.888 550.347 177.888C551.897 177.888 553.153 179.145 553.153 180.695Z" />
          <path d="M559.995 180.695C559.995 182.245 558.739 183.502 557.189 183.502C555.639 183.502 554.381 182.245 554.381 180.695C554.381 179.145 555.639 177.888 557.189 177.888C558.739 177.888 559.995 179.145 559.995 180.695Z" />
          <path d="M559.995 187.537C559.995 189.087 558.739 190.344 557.189 190.344C555.639 190.344 554.381 189.087 554.381 187.537C554.381 185.987 555.639 184.73 557.189 184.73C558.739 184.73 559.995 185.987 559.995 187.537Z" />
          <path d="M553.153 187.537C553.153 189.087 551.897 190.344 550.347 190.344C548.797 190.344 547.539 189.087 547.539 187.537C547.539 185.987 548.797 184.73 550.347 184.73C551.897 184.73 553.153 185.987 553.153 187.537Z" />
          <path d="M559.995 194.379C559.995 195.929 558.739 197.185 557.189 197.185C555.639 197.185 554.381 195.929 554.381 194.379C554.381 192.829 555.639 191.572 557.189 191.572C558.739 191.572 559.995 192.829 559.995 194.379Z" />
          <path d="M559.995 201.22C559.995 202.77 558.739 204.027 557.189 204.027C555.639 204.027 554.381 202.77 554.381 201.22C554.381 199.67 555.639 198.413 557.189 198.413C558.739 198.413 559.995 199.67 559.995 201.22Z" />
          <path d="M559.995 208.062C559.995 209.612 558.739 210.869 557.189 210.869C555.639 210.869 554.381 209.612 554.381 208.062C554.381 206.512 555.639 205.256 557.189 205.256C558.739 205.256 559.995 206.512 559.995 208.062Z" />
          <path d="M559.995 214.904C559.995 216.454 558.739 217.711 557.189 217.711C555.639 217.711 554.381 216.454 554.381 214.904C554.381 213.354 555.639 212.097 557.189 212.097C558.739 212.097 559.995 213.354 559.995 214.904Z" />
          <path d="M559.995 221.746C559.995 223.296 558.739 224.553 557.189 224.553C555.639 224.553 554.381 223.296 554.381 221.746C554.381 220.196 555.639 218.939 557.189 218.939C558.739 218.939 559.995 220.196 559.995 221.746Z" />
          <path d="M559.995 228.588C559.995 230.137 558.739 231.394 557.189 231.394C555.639 231.394 554.381 230.137 554.381 228.588C554.381 227.038 555.639 225.781 557.189 225.781C558.739 225.781 559.995 227.038 559.995 228.588Z" />
          <path d="M566.837 228.588C566.837 230.137 565.58 231.394 564.03 231.394C562.48 231.394 561.223 230.137 561.223 228.588C561.223 227.038 562.48 225.781 564.03 225.781C565.58 225.781 566.837 227.038 566.837 228.588Z" />
          <path d="M573.678 228.588C573.678 230.137 572.422 231.394 570.872 231.394C569.322 231.394 568.065 230.137 568.065 228.588C568.065 227.038 569.322 225.781 570.872 225.781C572.422 225.781 573.678 227.038 573.678 228.588Z" />
          <path d="M580.52 228.588C580.52 230.137 579.264 231.394 577.714 231.394C576.164 231.394 574.907 230.137 574.907 228.588C574.907 227.038 576.164 225.781 577.714 225.781C579.264 225.781 580.52 227.038 580.52 228.588Z" />
          <path d="M587.362 228.588C587.362 230.137 586.106 231.394 584.556 231.394C583.006 231.394 581.749 230.137 581.749 228.588C581.749 227.038 583.006 225.781 584.556 225.781C586.106 225.781 587.362 227.038 587.362 228.588Z" />
          <path d="M594.204 228.588C594.204 230.137 592.948 231.394 591.398 231.394C589.848 231.394 588.591 230.137 588.591 228.588C588.591 227.038 589.848 225.781 591.398 225.781C592.948 225.781 594.204 227.038 594.204 228.588Z" />
          <path d="M594.204 235.43C594.204 236.98 592.948 238.236 591.398 238.236C589.848 238.236 588.591 236.98 588.591 235.43C588.591 233.88 589.848 232.623 591.398 232.623C592.948 232.623 594.204 233.88 594.204 235.43Z" />
          <path d="M601.046 235.43C601.046 236.98 599.79 238.236 598.24 238.236C596.69 238.236 595.432 236.98 595.432 235.43C595.432 233.88 596.69 232.623 598.24 232.623C599.79 232.623 601.046 233.88 601.046 235.43Z" />
          <path d="M607.888 235.43C607.888 236.98 606.631 238.236 605.081 238.236C603.531 238.236 602.274 236.98 602.274 235.43C602.274 233.88 603.531 232.623 605.081 232.623C606.631 232.623 607.888 233.88 607.888 235.43Z" />
          <path d="M614.73 235.43C614.73 236.98 613.473 238.236 611.923 238.236C610.374 238.236 609.116 236.98 609.116 235.43C609.116 233.88 610.374 232.623 611.923 232.623C613.473 232.623 614.73 233.88 614.73 235.43Z" />
          <path d="M614.73 242.272C614.73 243.822 613.473 245.078 611.923 245.078C610.374 245.078 609.116 243.822 609.116 242.272C609.116 240.722 610.374 239.465 611.923 239.465C613.473 239.465 614.73 240.722 614.73 242.272Z" />
          <path d="M621.571 242.272C621.571 243.822 620.315 245.078 618.765 245.078C617.215 245.078 615.958 243.822 615.958 242.272C615.958 240.722 617.215 239.465 618.765 239.465C620.315 239.465 621.571 240.722 621.571 242.272Z" />
          <path d="M628.413 242.272C628.413 243.822 627.157 245.078 625.607 245.078C624.057 245.078 622.8 243.822 622.8 242.272C622.8 240.722 624.057 239.465 625.607 239.465C627.157 239.465 628.413 240.722 628.413 242.272Z" />
          <path d="M635.255 242.272C635.255 243.822 633.999 245.078 632.449 245.078C630.899 245.078 629.642 243.822 629.642 242.272C629.642 240.722 630.899 239.465 632.449 239.465C633.999 239.465 635.255 240.722 635.255 242.272Z" />
          <path d="M642.097 242.272C642.097 243.822 640.841 245.078 639.291 245.078C637.741 245.078 636.483 243.822 636.483 242.272C636.483 240.722 637.741 239.465 639.291 239.465C640.841 239.465 642.097 240.722 642.097 242.272Z" />
          <path d="M648.939 242.272C648.939 243.822 647.682 245.078 646.132 245.078C644.583 245.078 643.325 243.822 643.325 242.272C643.325 240.722 644.583 239.465 646.132 239.465C647.682 239.465 648.939 240.722 648.939 242.272Z" />
          <path d="M648.939 235.43C648.939 236.98 647.682 238.236 646.132 238.236C644.583 238.236 643.325 236.98 643.325 235.43C643.325 233.88 644.583 232.623 646.132 232.623C647.682 232.623 648.939 233.88 648.939 235.43Z" />
          <path d="M655.78 235.43C655.78 236.98 654.524 238.236 652.974 238.236C651.424 238.236 650.167 236.98 650.167 235.43C650.167 233.88 651.424 232.623 652.974 232.623C654.524 232.623 655.78 233.88 655.78 235.43Z" />
          <path d="M662.623 235.43C662.623 236.98 661.366 238.236 659.816 238.236C658.266 238.236 657.009 236.98 657.009 235.43C657.009 233.88 658.266 232.623 659.816 232.623C661.366 232.623 662.623 233.88 662.623 235.43Z" />
          <path d="M662.623 228.588C662.623 230.137 661.366 231.394 659.816 231.394C658.266 231.394 657.009 230.137 657.009 228.588C657.009 227.038 658.266 225.781 659.816 225.781C661.366 225.781 662.623 227.038 662.623 228.588Z" />
          <path d="M669.464 228.588C669.464 230.137 668.208 231.394 666.658 231.394C665.108 231.394 663.851 230.137 663.851 228.588C663.851 227.038 665.108 225.781 666.658 225.781C668.208 225.781 669.464 227.038 669.464 228.588Z" />
          <path d="M669.464 221.746C669.464 223.296 668.208 224.553 666.658 224.553C665.108 224.553 663.851 223.296 663.851 221.746C663.851 220.196 665.108 218.939 666.658 218.939C668.208 218.939 669.464 220.196 669.464 221.746Z" />
          <path d="M669.464 214.904C669.464 216.454 668.208 217.711 666.658 217.711C665.108 217.711 663.851 216.454 663.851 214.904C663.851 213.354 665.108 212.097 666.658 212.097C668.208 212.097 669.464 213.354 669.464 214.904Z" />
          <path d="M669.464 208.062C669.464 209.612 668.208 210.869 666.658 210.869C665.108 210.869 663.851 209.612 663.851 208.062C663.851 206.512 665.108 205.256 666.658 205.256C668.208 205.256 669.464 206.512 669.464 208.062Z" />
          <path d="M669.464 201.22C669.464 202.77 668.208 204.027 666.658 204.027C665.108 204.027 663.851 202.77 663.851 201.22C663.851 199.67 665.108 198.413 666.658 198.413C668.208 198.413 669.464 199.67 669.464 201.22Z" />
          <path d="M669.464 194.379C669.464 195.929 668.208 197.185 666.658 197.185C665.108 197.185 663.851 195.929 663.851 194.379C663.851 192.829 665.108 191.572 666.658 191.572C668.208 191.572 669.464 192.829 669.464 194.379Z" />
          <path d="M662.623 194.379C662.623 195.929 661.366 197.185 659.816 197.185C658.266 197.185 657.009 195.929 657.009 194.379C657.009 192.829 658.266 191.572 659.816 191.572C661.366 191.572 662.623 192.829 662.623 194.379Z" />
          <path d="M662.623 201.22C662.623 202.77 661.366 204.027 659.816 204.027C658.266 204.027 657.009 202.77 657.009 201.22C657.009 199.67 658.266 198.413 659.816 198.413C661.366 198.413 662.623 199.67 662.623 201.22Z" />
          <path d="M662.623 208.062C662.623 209.612 661.366 210.869 659.816 210.869C658.266 210.869 657.009 209.612 657.009 208.062C657.009 206.512 658.266 205.256 659.816 205.256C661.366 205.256 662.623 206.512 662.623 208.062Z" />
          <path d="M662.623 214.904C662.623 216.454 661.366 217.711 659.816 217.711C658.266 217.711 657.009 216.454 657.009 214.904C657.009 213.354 658.266 212.097 659.816 212.097C661.366 212.097 662.623 213.354 662.623 214.904Z" />
          <path d="M662.623 221.746C662.623 223.296 661.366 224.553 659.816 224.553C658.266 224.553 657.009 223.296 657.009 221.746C657.009 220.196 658.266 218.939 659.816 218.939C661.366 218.939 662.623 220.196 662.623 221.746Z" />
          <path d="M655.78 221.746C655.78 223.296 654.524 224.553 652.974 224.553C651.424 224.553 650.167 223.296 650.167 221.746C650.167 220.196 651.424 218.939 652.974 218.939C654.524 218.939 655.78 220.196 655.78 221.746Z" />
          <path d="M655.78 228.588C655.78 230.137 654.524 231.394 652.974 231.394C651.424 231.394 650.167 230.137 650.167 228.588C650.167 227.038 651.424 225.781 652.974 225.781C654.524 225.781 655.78 227.038 655.78 228.588Z" />
          <path d="M648.939 228.588C648.939 230.137 647.682 231.394 646.132 231.394C644.583 231.394 643.325 230.137 643.325 228.588C643.325 227.038 644.583 225.781 646.132 225.781C647.682 225.781 648.939 227.038 648.939 228.588Z" />
          <path d="M642.097 228.588C642.097 230.137 640.841 231.394 639.291 231.394C637.741 231.394 636.483 230.137 636.483 228.588C636.483 227.038 637.741 225.781 639.291 225.781C640.841 225.781 642.097 227.038 642.097 228.588Z" />
          <path d="M642.097 235.43C642.097 236.98 640.841 238.236 639.291 238.236C637.741 238.236 636.483 236.98 636.483 235.43C636.483 233.88 637.741 232.623 639.291 232.623C640.841 232.623 642.097 233.88 642.097 235.43Z" />
          <path d="M635.255 235.43C635.255 236.98 633.999 238.236 632.449 238.236C630.899 238.236 629.642 236.98 629.642 235.43C629.642 233.88 630.899 232.623 632.449 232.623C633.999 232.623 635.255 233.88 635.255 235.43Z" />
          <path d="M628.413 235.43C628.413 236.98 627.157 238.236 625.607 238.236C624.057 238.236 622.8 236.98 622.8 235.43C622.8 233.88 624.057 232.623 625.607 232.623C627.157 232.623 628.413 233.88 628.413 235.43Z" />
          <path d="M621.571 235.43C621.571 236.98 620.315 238.236 618.765 238.236C617.215 238.236 615.958 236.98 615.958 235.43C615.958 233.88 617.215 232.623 618.765 232.623C620.315 232.623 621.571 233.88 621.571 235.43Z" />
          <path d="M621.571 228.588C621.571 230.137 620.315 231.394 618.765 231.394C617.215 231.394 615.958 230.137 615.958 228.588C615.958 227.038 617.215 225.781 618.765 225.781C620.315 225.781 621.571 227.038 621.571 228.588Z" />
          <path d="M614.73 228.588C614.73 230.137 613.473 231.394 611.923 231.394C610.374 231.394 609.116 230.137 609.116 228.588C609.116 227.038 610.374 225.781 611.923 225.781C613.473 225.781 614.73 227.038 614.73 228.588Z" />
          <path d="M607.888 228.588C607.888 230.137 606.631 231.394 605.081 231.394C603.531 231.394 602.274 230.137 602.274 228.588C602.274 227.038 603.531 225.781 605.081 225.781C606.631 225.781 607.888 227.038 607.888 228.588Z" />
          <path d="M601.046 228.588C601.046 230.137 599.79 231.394 598.24 231.394C596.69 231.394 595.432 230.137 595.432 228.588C595.432 227.038 596.69 225.781 598.24 225.781C599.79 225.781 601.046 227.038 601.046 228.588Z" />
          <path d="M614.73 221.746C614.73 223.296 613.473 224.553 611.923 224.553C610.374 224.553 609.116 223.296 609.116 221.746C609.116 220.196 610.374 218.939 611.923 218.939C613.473 218.939 614.73 220.196 614.73 221.746Z" />
          <path d="M621.571 221.746C621.571 223.296 620.315 224.553 618.765 224.553C617.215 224.553 615.958 223.296 615.958 221.746C615.958 220.196 617.215 218.939 618.765 218.939C620.315 218.939 621.571 220.196 621.571 221.746Z" />
          <path d="M628.413 221.746C628.413 223.296 627.157 224.553 625.607 224.553C624.057 224.553 622.8 223.296 622.8 221.746C622.8 220.196 624.057 218.939 625.607 218.939C627.157 218.939 628.413 220.196 628.413 221.746Z" />
          <path d="M628.413 228.588C628.413 230.137 627.157 231.394 625.607 231.394C624.057 231.394 622.8 230.137 622.8 228.588C622.8 227.038 624.057 225.781 625.607 225.781C627.157 225.781 628.413 227.038 628.413 228.588Z" />
          <path d="M635.255 228.588C635.255 230.137 633.999 231.394 632.449 231.394C630.899 231.394 629.642 230.137 629.642 228.588C629.642 227.038 630.899 225.781 632.449 225.781C633.999 225.781 635.255 227.038 635.255 228.588Z" />
          <path d="M635.255 221.746C635.255 223.296 633.999 224.553 632.449 224.553C630.899 224.553 629.642 223.296 629.642 221.746C629.642 220.196 630.899 218.939 632.449 218.939C633.999 218.939 635.255 220.196 635.255 221.746Z" />
          <path d="M642.097 221.746C642.097 223.296 640.841 224.553 639.291 224.553C637.741 224.553 636.483 223.296 636.483 221.746C636.483 220.196 637.741 218.939 639.291 218.939C640.841 218.939 642.097 220.196 642.097 221.746Z" />
          <path d="M648.939 221.746C648.939 223.296 647.682 224.553 646.132 224.553C644.583 224.553 643.325 223.296 643.325 221.746C643.325 220.196 644.583 218.939 646.132 218.939C647.682 218.939 648.939 220.196 648.939 221.746Z" />
          <path d="M648.939 214.904C648.939 216.454 647.682 217.711 646.132 217.711C644.583 217.711 643.325 216.454 643.325 214.904C643.325 213.354 644.583 212.097 646.132 212.097C647.682 212.097 648.939 213.354 648.939 214.904Z" />
          <path d="M655.78 214.904C655.78 216.454 654.524 217.711 652.974 217.711C651.424 217.711 650.167 216.454 650.167 214.904C650.167 213.354 651.424 212.097 652.974 212.097C654.524 212.097 655.78 213.354 655.78 214.904Z" />
          <path d="M655.78 208.062C655.78 209.612 654.524 210.869 652.974 210.869C651.424 210.869 650.167 209.612 650.167 208.062C650.167 206.512 651.424 205.256 652.974 205.256C654.524 205.256 655.78 206.512 655.78 208.062Z" />
          <path d="M655.78 201.22C655.78 202.77 654.524 204.027 652.974 204.027C651.424 204.027 650.167 202.77 650.167 201.22C650.167 199.67 651.424 198.413 652.974 198.413C654.524 198.413 655.78 199.67 655.78 201.22Z" />
          <path d="M655.78 194.379C655.78 195.929 654.524 197.185 652.974 197.185C651.424 197.185 650.167 195.929 650.167 194.379C650.167 192.829 651.424 191.572 652.974 191.572C654.524 191.572 655.78 192.829 655.78 194.379Z" />
          <path d="M648.939 194.379C648.939 195.929 647.682 197.185 646.132 197.185C644.583 197.185 643.325 195.929 643.325 194.379C643.325 192.829 644.583 191.572 646.132 191.572C647.682 191.572 648.939 192.829 648.939 194.379Z" />
          <path d="M648.939 201.22C648.939 202.77 647.682 204.027 646.132 204.027C644.583 204.027 643.325 202.77 643.325 201.22C643.325 199.67 644.583 198.413 646.132 198.413C647.682 198.413 648.939 199.67 648.939 201.22Z" />
          <path d="M648.939 208.062C648.939 209.612 647.682 210.869 646.132 210.869C644.583 210.869 643.325 209.612 643.325 208.062C643.325 206.512 644.583 205.256 646.132 205.256C647.682 205.256 648.939 206.512 648.939 208.062Z" />
          <path d="M642.097 208.062C642.097 209.612 640.841 210.869 639.291 210.869C637.741 210.869 636.483 209.612 636.483 208.062C636.483 206.512 637.741 205.256 639.291 205.256C640.841 205.256 642.097 206.512 642.097 208.062Z" />
          <path d="M642.097 214.904C642.097 216.454 640.841 217.711 639.291 217.711C637.741 217.711 636.483 216.454 636.483 214.904C636.483 213.354 637.741 212.097 639.291 212.097C640.841 212.097 642.097 213.354 642.097 214.904Z" />
          <path d="M635.255 214.904C635.255 216.454 633.999 217.711 632.449 217.711C630.899 217.711 629.642 216.454 629.642 214.904C629.642 213.354 630.899 212.097 632.449 212.097C633.999 212.097 635.255 213.354 635.255 214.904Z" />
          <path d="M628.413 214.904C628.413 216.454 627.157 217.711 625.607 217.711C624.057 217.711 622.8 216.454 622.8 214.904C622.8 213.354 624.057 212.097 625.607 212.097C627.157 212.097 628.413 213.354 628.413 214.904Z" />
          <path d="M621.571 214.904C621.571 216.454 620.315 217.711 618.765 217.711C617.215 217.711 615.958 216.454 615.958 214.904C615.958 213.354 617.215 212.097 618.765 212.097C620.315 212.097 621.571 213.354 621.571 214.904Z" />
          <path d="M614.73 214.904C614.73 216.454 613.473 217.711 611.923 217.711C610.374 217.711 609.116 216.454 609.116 214.904C609.116 213.354 610.374 212.097 611.923 212.097C613.473 212.097 614.73 213.354 614.73 214.904Z" />
          <path d="M614.73 208.062C614.73 209.612 613.473 210.869 611.923 210.869C610.374 210.869 609.116 209.612 609.116 208.062C609.116 206.512 610.374 205.256 611.923 205.256C613.473 205.256 614.73 206.512 614.73 208.062Z" />
          <path d="M621.571 208.062C621.571 209.612 620.315 210.869 618.765 210.869C617.215 210.869 615.958 209.612 615.958 208.062C615.958 206.512 617.215 205.256 618.765 205.256C620.315 205.256 621.571 206.512 621.571 208.062Z" />
          <path d="M628.413 208.062C628.413 209.612 627.157 210.869 625.607 210.869C624.057 210.869 622.8 209.612 622.8 208.062C622.8 206.512 624.057 205.256 625.607 205.256C627.157 205.256 628.413 206.512 628.413 208.062Z" />
          <path d="M635.255 208.062C635.255 209.612 633.999 210.869 632.449 210.869C630.899 210.869 629.642 209.612 629.642 208.062C629.642 206.512 630.899 205.256 632.449 205.256C633.999 205.256 635.255 206.512 635.255 208.062Z" />
          <path d="M635.255 201.22C635.255 202.77 633.999 204.027 632.449 204.027C630.899 204.027 629.642 202.77 629.642 201.22C629.642 199.67 630.899 198.413 632.449 198.413C633.999 198.413 635.255 199.67 635.255 201.22Z" />
          <path d="M642.097 201.22C642.097 202.77 640.841 204.027 639.291 204.027C637.741 204.027 636.483 202.77 636.483 201.22C636.483 199.67 637.741 198.413 639.291 198.413C640.841 198.413 642.097 199.67 642.097 201.22Z" />
          <path d="M642.097 194.379C642.097 195.929 640.841 197.185 639.291 197.185C637.741 197.185 636.483 195.929 636.483 194.379C636.483 192.829 637.741 191.572 639.291 191.572C640.841 191.572 642.097 192.829 642.097 194.379Z" />
          <path d="M642.097 187.537C642.097 189.087 640.841 190.344 639.291 190.344C637.741 190.344 636.483 189.087 636.483 187.537C636.483 185.987 637.741 184.73 639.291 184.73C640.841 184.73 642.097 185.987 642.097 187.537Z" />
          <path d="M648.939 187.537C648.939 189.087 647.682 190.344 646.132 190.344C644.583 190.344 643.325 189.087 643.325 187.537C643.325 185.987 644.583 184.73 646.132 184.73C647.682 184.73 648.939 185.987 648.939 187.537Z" />
          <path d="M655.78 187.537C655.78 189.087 654.524 190.344 652.974 190.344C651.424 190.344 650.167 189.087 650.167 187.537C650.167 185.987 651.424 184.73 652.974 184.73C654.524 184.73 655.78 185.987 655.78 187.537Z" />
          <path d="M662.623 187.537C662.623 189.087 661.366 190.344 659.816 190.344C658.266 190.344 657.009 189.087 657.009 187.537C657.009 185.987 658.266 184.73 659.816 184.73C661.366 184.73 662.623 185.987 662.623 187.537Z" />
          <path d="M669.464 187.537C669.464 189.087 668.208 190.344 666.658 190.344C665.108 190.344 663.851 189.087 663.851 187.537C663.851 185.987 665.108 184.73 666.658 184.73C668.208 184.73 669.464 185.987 669.464 187.537Z" />
          <path d="M669.464 180.695C669.464 182.245 668.208 183.502 666.658 183.502C665.108 183.502 663.851 182.245 663.851 180.695C663.851 179.145 665.108 177.888 666.658 177.888C668.208 177.888 669.464 179.145 669.464 180.695Z" />
          <path d="M676.306 180.695C676.306 182.245 675.05 183.502 673.5 183.502C671.95 183.502 670.693 182.245 670.693 180.695C670.693 179.145 671.95 177.888 673.5 177.888C675.05 177.888 676.306 179.145 676.306 180.695Z" />
          <path d="M683.148 180.695C683.148 182.245 681.892 183.502 680.342 183.502C678.792 183.502 677.534 182.245 677.534 180.695C677.534 179.145 678.792 177.888 680.342 177.888C681.892 177.888 683.148 179.145 683.148 180.695Z" />
          <path d="M689.99 180.695C689.99 182.245 688.733 183.502 687.183 183.502C685.634 183.502 684.376 182.245 684.376 180.695C684.376 179.145 685.634 177.888 687.183 177.888C688.733 177.888 689.99 179.145 689.99 180.695Z" />
          <path d="M696.832 180.695C696.832 182.245 695.575 183.502 694.026 183.502C692.476 183.502 691.218 182.245 691.218 180.695C691.218 179.145 692.476 177.888 694.026 177.888C695.575 177.888 696.832 179.145 696.832 180.695Z" />
          <path d="M703.674 180.695C703.674 182.245 702.417 183.502 700.867 183.502C699.317 183.502 698.06 182.245 698.06 180.695C698.06 179.145 699.317 177.888 700.867 177.888C702.417 177.888 703.674 179.145 703.674 180.695Z" />
          <path d="M703.674 173.853C703.674 175.403 702.417 176.66 700.867 176.66C699.317 176.66 698.06 175.403 698.06 173.853C698.06 172.303 699.317 171.046 700.867 171.046C702.417 171.046 703.674 172.303 703.674 173.853Z" />
          <path d="M703.674 167.011C703.674 168.561 702.417 169.818 700.867 169.818C699.317 169.818 698.06 168.561 698.06 167.011C698.06 165.461 699.317 164.204 700.867 164.204C702.417 164.204 703.674 165.461 703.674 167.011Z" />
          <path d="M703.674 160.169C703.674 161.719 702.417 162.976 700.867 162.976C699.317 162.976 698.06 161.719 698.06 160.169C698.06 158.619 699.317 157.363 700.867 157.363C702.417 157.363 703.674 158.619 703.674 160.169Z" />
          <path d="M703.674 153.328C703.674 154.877 702.417 156.134 700.867 156.134C699.317 156.134 698.06 154.877 698.06 153.328C698.06 151.778 699.317 150.521 700.867 150.521C702.417 150.521 703.674 151.778 703.674 153.328Z" />
          <path d="M703.674 146.486C703.674 148.036 702.417 149.292 700.867 149.292C699.317 149.292 698.06 148.036 698.06 146.486C698.06 144.936 699.317 143.679 700.867 143.679C702.417 143.679 703.674 144.936 703.674 146.486Z" />
          <path d="M703.674 139.644C703.674 141.194 702.417 142.451 700.867 142.451C699.317 142.451 698.06 141.194 698.06 139.644C698.06 138.094 699.317 136.837 700.867 136.837C702.417 136.837 703.674 138.094 703.674 139.644Z" />
          <path d="M703.674 132.802C703.674 134.352 702.417 135.608 700.867 135.608C699.317 135.608 698.06 134.352 698.06 132.802C698.06 131.252 699.317 129.995 700.867 129.995C702.417 129.995 703.674 131.252 703.674 132.802Z" />
          <path d="M703.674 125.96C703.674 127.51 702.417 128.767 700.867 128.767C699.317 128.767 698.06 127.51 698.06 125.96C698.06 124.41 699.317 123.153 700.867 123.153C702.417 123.153 703.674 124.41 703.674 125.96Z" />
          <path d="M703.674 119.118C703.674 120.668 702.417 121.925 700.867 121.925C699.317 121.925 698.06 120.668 698.06 119.118C698.06 117.568 699.317 116.312 700.867 116.312C702.417 116.312 703.674 117.568 703.674 119.118Z" />
          <path d="M710.515 119.118C710.515 120.668 709.259 121.925 707.709 121.925C706.159 121.925 704.902 120.668 704.902 119.118C704.902 117.568 706.159 116.312 707.709 116.312C709.259 116.312 710.515 117.568 710.515 119.118Z" />
          <path d="M710.515 112.277C710.515 113.826 709.259 115.083 707.709 115.083C706.159 115.083 704.902 113.826 704.902 112.277C704.902 110.727 706.159 109.47 707.709 109.47C709.259 109.47 710.515 110.727 710.515 112.277Z" />
          <path d="M710.515 105.435C710.515 106.985 709.259 108.241 707.709 108.241C706.159 108.241 704.902 106.985 704.902 105.435C704.902 103.885 706.159 102.628 707.709 102.628C709.259 102.628 710.515 103.885 710.515 105.435Z" />
          <path d="M717.357 105.435C717.357 106.985 716.101 108.241 714.551 108.241C713.001 108.241 711.744 106.985 711.744 105.435C711.744 103.885 713.001 102.628 714.551 102.628C716.101 102.628 717.357 103.885 717.357 105.435Z" />
          <path d="M724.199 98.5927C724.199 100.143 722.943 101.399 721.393 101.399C719.843 101.399 718.585 100.143 718.585 98.5927C718.585 97.0427 719.843 95.7859 721.393 95.7859C722.943 95.7859 724.199 97.0427 724.199 98.5927Z" />
          <path d="M731.041 98.5927C731.041 100.143 729.785 101.399 728.235 101.399C726.685 101.399 725.427 100.143 725.427 98.5927C725.427 97.0427 726.685 95.7859 728.235 95.7859C729.785 95.7859 731.041 97.0427 731.041 98.5927Z" />
          <path d="M737.883 98.5927C737.883 100.143 736.626 101.399 735.077 101.399C733.527 101.399 732.269 100.143 732.269 98.5927C732.269 97.0427 733.527 95.7859 735.077 95.7859C736.626 95.7859 737.883 97.0427 737.883 98.5927Z" />
          <path d="M737.883 91.7506C737.883 93.3006 736.626 94.5574 735.077 94.5574C733.527 94.5574 732.269 93.3006 732.269 91.7506C732.269 90.2007 733.527 88.9439 735.077 88.9439C736.626 88.9439 737.883 90.2007 737.883 91.7506Z" />
          <path d="M737.883 84.9091C737.883 86.459 736.626 87.7158 735.077 87.7158C733.527 87.7158 732.269 86.459 732.269 84.9091C732.269 83.3591 733.527 82.1024 735.077 82.1024C736.626 82.1024 737.883 83.3591 737.883 84.9091Z" />
          <path d="M737.883 78.0673C737.883 79.6172 736.626 80.874 735.077 80.874C733.527 80.874 732.269 79.6172 732.269 78.0673C732.269 76.5173 733.527 75.2606 735.077 75.2606C736.626 75.2606 737.883 76.5173 737.883 78.0673Z" />
          <path d="M744.725 78.0673C744.725 79.6172 743.468 80.874 741.918 80.874C740.368 80.874 739.111 79.6172 739.111 78.0673C739.111 76.5173 740.368 75.2606 741.918 75.2606C743.468 75.2606 744.725 76.5173 744.725 78.0673Z" />
          <path d="M751.566 78.0673C751.566 79.6172 750.31 80.874 748.76 80.874C747.21 80.874 745.953 79.6172 745.953 78.0673C745.953 76.5173 747.21 75.2606 748.76 75.2606C750.31 75.2606 751.566 76.5173 751.566 78.0673Z" />
          <path d="M758.408 78.0673C758.408 79.6172 757.152 80.874 755.602 80.874C754.052 80.874 752.795 79.6172 752.795 78.0673C752.795 76.5173 754.052 75.2606 755.602 75.2606C757.152 75.2606 758.408 76.5173 758.408 78.0673Z" />
          <path d="M758.408 71.2255C758.408 72.7754 757.152 74.0322 755.602 74.0322C754.052 74.0322 752.795 72.7754 752.795 71.2255C752.795 69.6756 754.052 68.4188 755.602 68.4188C757.152 68.4188 758.408 69.6756 758.408 71.2255Z" />
          <path d="M765.25 71.2255C765.25 72.7754 763.994 74.0322 762.444 74.0322C760.894 74.0322 759.637 72.7754 759.637 71.2255C759.637 69.6756 760.894 68.4188 762.444 68.4188C763.994 68.4188 765.25 69.6756 765.25 71.2255Z" />
          <path d="M765.25 64.3832C765.25 65.9331 763.994 67.1899 762.444 67.1899C760.894 67.1899 759.637 65.9331 759.637 64.3832C759.637 62.8333 760.894 61.5765 762.444 61.5765C763.994 61.5765 765.25 62.8333 765.25 64.3832Z" />
          <path d="M758.408 64.3832C758.408 65.9331 757.152 67.1899 755.602 67.1899C754.052 67.1899 752.795 65.9331 752.795 64.3832C752.795 62.8333 754.052 61.5765 755.602 61.5765C757.152 61.5765 758.408 62.8333 758.408 64.3832Z" />
          <path d="M751.566 64.3832C751.566 65.9331 750.31 67.1899 748.76 67.1899C747.21 67.1899 745.953 65.9331 745.953 64.3832C745.953 62.8333 747.21 61.5765 748.76 61.5765C750.31 61.5765 751.566 62.8333 751.566 64.3832Z" />
          <path d="M751.566 71.2255C751.566 72.7754 750.31 74.0322 748.76 74.0322C747.21 74.0322 745.953 72.7754 745.953 71.2255C745.953 69.6756 747.21 68.4188 748.76 68.4188C750.31 68.4188 751.566 69.6756 751.566 71.2255Z" />
          <path d="M744.725 71.2255C744.725 72.7754 743.468 74.0322 741.918 74.0322C740.368 74.0322 739.111 72.7754 739.111 71.2255C739.111 69.6756 740.368 68.4188 741.918 68.4188C743.468 68.4188 744.725 69.6756 744.725 71.2255Z" />
          <path d="M744.725 64.3832C744.725 65.9331 743.468 67.1899 741.918 67.1899C740.368 67.1899 739.111 65.9331 739.111 64.3832C739.111 62.8333 740.368 61.5765 741.918 61.5765C743.468 61.5765 744.725 62.8333 744.725 64.3832Z" />
          <path d="M737.883 64.3832C737.883 65.9331 736.626 67.1899 735.077 67.1899C733.527 67.1899 732.269 65.9331 732.269 64.3832C732.269 62.8333 733.527 61.5765 735.077 61.5765C736.626 61.5765 737.883 62.8333 737.883 64.3832Z" />
          <path d="M737.883 71.2255C737.883 72.7754 736.626 74.0322 735.077 74.0322C733.527 74.0322 732.269 72.7754 732.269 71.2255C732.269 69.6756 733.527 68.4188 735.077 68.4188C736.626 68.4188 737.883 69.6756 737.883 71.2255Z" />
          <path d="M731.041 71.2255C731.041 72.7754 729.785 74.0322 728.235 74.0322C726.685 74.0322 725.427 72.7754 725.427 71.2255C725.427 69.6756 726.685 68.4188 728.235 68.4188C729.785 68.4188 731.041 69.6756 731.041 71.2255Z" />
          <path d="M731.041 64.3832C731.041 65.9331 729.785 67.1899 728.235 67.1899C726.685 67.1899 725.427 65.9331 725.427 64.3832C725.427 62.8333 726.685 61.5765 728.235 61.5765C729.785 61.5765 731.041 62.8333 731.041 64.3832Z" />
          <path d="M724.199 64.3832C724.199 65.9331 722.943 67.1899 721.393 67.1899C719.843 67.1899 718.585 65.9331 718.585 64.3832C718.585 62.8333 719.843 61.5765 721.393 61.5765C722.943 61.5765 724.199 62.8333 724.199 64.3832Z" />
          <path d="M724.199 71.2255C724.199 72.7754 722.943 74.0322 721.393 74.0322C719.843 74.0322 718.585 72.7754 718.585 71.2255C718.585 69.6756 719.843 68.4188 721.393 68.4188C722.943 68.4188 724.199 69.6756 724.199 71.2255Z" />
          <path d="M724.199 78.0673C724.199 79.6172 722.943 80.874 721.393 80.874C719.843 80.874 718.585 79.6172 718.585 78.0673C718.585 76.5173 719.843 75.2606 721.393 75.2606C722.943 75.2606 724.199 76.5173 724.199 78.0673Z" />
          <path d="M731.041 78.0673C731.041 79.6172 729.785 80.874 728.235 80.874C726.685 80.874 725.427 79.6172 725.427 78.0673C725.427 76.5173 726.685 75.2606 728.235 75.2606C729.785 75.2606 731.041 76.5173 731.041 78.0673Z" />
          <path d="M731.041 84.9091C731.041 86.459 729.785 87.7158 728.235 87.7158C726.685 87.7158 725.427 86.459 725.427 84.9091C725.427 83.3591 726.685 82.1024 728.235 82.1024C729.785 82.1024 731.041 83.3591 731.041 84.9091Z" />
          <path d="M731.041 91.7506C731.041 93.3006 729.785 94.5574 728.235 94.5574C726.685 94.5574 725.427 93.3006 725.427 91.7506C725.427 90.2007 726.685 88.9439 728.235 88.9439C729.785 88.9439 731.041 90.2007 731.041 91.7506Z" />
          <path d="M724.199 91.7506C724.199 93.3006 722.943 94.5574 721.393 94.5574C719.843 94.5574 718.585 93.3006 718.585 91.7506C718.585 90.2007 719.843 88.9439 721.393 88.9439C722.943 88.9439 724.199 90.2007 724.199 91.7506Z" />
          <path d="M724.199 84.9091C724.199 86.459 722.943 87.7158 721.393 87.7158C719.843 87.7158 718.585 86.459 718.585 84.9091C718.585 83.3591 719.843 82.1024 721.393 82.1024C722.943 82.1024 724.199 83.3591 724.199 84.9091Z" />
          <path d="M703.674 105.435C703.674 106.985 702.417 108.241 700.867 108.241C699.317 108.241 698.06 106.985 698.06 105.435C698.06 103.885 699.317 102.628 700.867 102.628C702.417 102.628 703.674 103.885 703.674 105.435Z" />
          <path d="M696.832 105.435C696.832 106.985 695.575 108.241 694.026 108.241C692.476 108.241 691.218 106.985 691.218 105.435C691.218 103.885 692.476 102.628 694.026 102.628C695.575 102.628 696.832 103.885 696.832 105.435Z" />
          <path d="M689.99 105.435C689.99 106.985 688.733 108.241 687.183 108.241C685.634 108.241 684.376 106.985 684.376 105.435C684.376 103.885 685.634 102.628 687.183 102.628C688.733 102.628 689.99 103.885 689.99 105.435Z" />
          <path d="M689.99 112.277C689.99 113.826 688.733 115.083 687.183 115.083C685.634 115.083 684.376 113.826 684.376 112.277C684.376 110.727 685.634 109.47 687.183 109.47C688.733 109.47 689.99 110.727 689.99 112.277Z" />
          <path d="M689.99 119.118C689.99 120.668 688.733 121.925 687.183 121.925C685.634 121.925 684.376 120.668 684.376 119.118C684.376 117.568 685.634 116.312 687.183 116.312C688.733 116.312 689.99 117.568 689.99 119.118Z" />
          <path d="M696.832 119.118C696.832 120.668 695.575 121.925 694.026 121.925C692.476 121.925 691.218 120.668 691.218 119.118C691.218 117.568 692.476 116.312 694.026 116.312C695.575 116.312 696.832 117.568 696.832 119.118Z" />
          <path d="M696.832 112.277C696.832 113.826 695.575 115.083 694.026 115.083C692.476 115.083 691.218 113.826 691.218 112.277C691.218 110.727 692.476 109.47 694.026 109.47C695.575 109.47 696.832 110.727 696.832 112.277Z" />
          <path d="M703.674 112.277C703.674 113.826 702.417 115.083 700.867 115.083C699.317 115.083 698.06 113.826 698.06 112.277C698.06 110.727 699.317 109.47 700.867 109.47C702.417 109.47 703.674 110.727 703.674 112.277Z" />
          <path d="M607.888 208.062C607.888 209.612 606.631 210.869 605.081 210.869C603.531 210.869 602.274 209.612 602.274 208.062C602.274 206.512 603.531 205.256 605.081 205.256C606.631 205.256 607.888 206.512 607.888 208.062Z" />
          <path d="M601.046 208.062C601.046 209.612 599.79 210.869 598.24 210.869C596.69 210.869 595.432 209.612 595.432 208.062C595.432 206.512 596.69 205.256 598.24 205.256C599.79 205.256 601.046 206.512 601.046 208.062Z" />
          <path d="M594.204 208.062C594.204 209.612 592.948 210.869 591.398 210.869C589.848 210.869 588.591 209.612 588.591 208.062C588.591 206.512 589.848 205.256 591.398 205.256C592.948 205.256 594.204 206.512 594.204 208.062Z" />
          <path d="M587.362 208.062C587.362 209.612 586.106 210.869 584.556 210.869C583.006 210.869 581.749 209.612 581.749 208.062C581.749 206.512 583.006 205.256 584.556 205.256C586.106 205.256 587.362 206.512 587.362 208.062Z" />
          <path d="M580.52 208.062C580.52 209.612 579.264 210.869 577.714 210.869C576.164 210.869 574.907 209.612 574.907 208.062C574.907 206.512 576.164 205.256 577.714 205.256C579.264 205.256 580.52 206.512 580.52 208.062Z" />
          <path d="M573.678 208.062C573.678 209.612 572.422 210.869 570.872 210.869C569.322 210.869 568.065 209.612 568.065 208.062C568.065 206.512 569.322 205.256 570.872 205.256C572.422 205.256 573.678 206.512 573.678 208.062Z" />
          <path d="M566.837 208.062C566.837 209.612 565.58 210.869 564.03 210.869C562.48 210.869 561.223 209.612 561.223 208.062C561.223 206.512 562.48 205.256 564.03 205.256C565.58 205.256 566.837 206.512 566.837 208.062Z" />
          <path d="M566.837 201.22C566.837 202.77 565.58 204.027 564.03 204.027C562.48 204.027 561.223 202.77 561.223 201.22C561.223 199.67 562.48 198.413 564.03 198.413C565.58 198.413 566.837 199.67 566.837 201.22Z" />
          <path d="M566.837 194.379C566.837 195.929 565.58 197.185 564.03 197.185C562.48 197.185 561.223 195.929 561.223 194.379C561.223 192.829 562.48 191.572 564.03 191.572C565.58 191.572 566.837 192.829 566.837 194.379Z" />
          <path d="M566.837 187.537C566.837 189.087 565.58 190.344 564.03 190.344C562.48 190.344 561.223 189.087 561.223 187.537C561.223 185.987 562.48 184.73 564.03 184.73C565.58 184.73 566.837 185.987 566.837 187.537Z" />
          <path d="M566.837 180.695C566.837 182.245 565.58 183.502 564.03 183.502C562.48 183.502 561.223 182.245 561.223 180.695C561.223 179.145 562.48 177.888 564.03 177.888C565.58 177.888 566.837 179.145 566.837 180.695Z" />
          <path d="M573.678 180.695C573.678 182.245 572.422 183.502 570.872 183.502C569.322 183.502 568.065 182.245 568.065 180.695C568.065 179.145 569.322 177.888 570.872 177.888C572.422 177.888 573.678 179.145 573.678 180.695Z" />
          <path d="M573.678 187.537C573.678 189.087 572.422 190.344 570.872 190.344C569.322 190.344 568.065 189.087 568.065 187.537C568.065 185.987 569.322 184.73 570.872 184.73C572.422 184.73 573.678 185.987 573.678 187.537Z" />
          <path d="M573.678 235.43C573.678 236.98 572.422 238.236 570.872 238.236C569.322 238.236 568.065 236.98 568.065 235.43C568.065 233.88 569.322 232.623 570.872 232.623C572.422 232.623 573.678 233.88 573.678 235.43Z" />
          <path d="M580.52 235.43C580.52 236.98 579.264 238.236 577.714 238.236C576.164 238.236 574.907 236.98 574.907 235.43C574.907 233.88 576.164 232.623 577.714 232.623C579.264 232.623 580.52 233.88 580.52 235.43Z" />
          <path d="M587.362 235.43C587.362 236.98 586.106 238.236 584.556 238.236C583.006 238.236 581.749 236.98 581.749 235.43C581.749 233.88 583.006 232.623 584.556 232.623C586.106 232.623 587.362 233.88 587.362 235.43Z" />
          <path d="M683.148 194.379C683.148 195.929 681.892 197.185 680.342 197.185C678.792 197.185 677.534 195.929 677.534 194.379C677.534 192.829 678.792 191.572 680.342 191.572C681.892 191.572 683.148 192.829 683.148 194.379Z" />
          <path d="M683.148 201.22C683.148 202.77 681.892 204.027 680.342 204.027C678.792 204.027 677.534 202.77 677.534 201.22C677.534 199.67 678.792 198.413 680.342 198.413C681.892 198.413 683.148 199.67 683.148 201.22Z" />
          <path d="M683.148 208.062C683.148 209.612 681.892 210.869 680.342 210.869C678.792 210.869 677.534 209.612 677.534 208.062C677.534 206.512 678.792 205.256 680.342 205.256C681.892 205.256 683.148 206.512 683.148 208.062Z" />
          <path d="M683.148 214.904C683.148 216.454 681.892 217.711 680.342 217.711C678.792 217.711 677.534 216.454 677.534 214.904C677.534 213.354 678.792 212.097 680.342 212.097C681.892 212.097 683.148 213.354 683.148 214.904Z" />
          <path d="M683.148 221.746C683.148 223.296 681.892 224.553 680.342 224.553C678.792 224.553 677.534 223.296 677.534 221.746C677.534 220.196 678.792 218.939 680.342 218.939C681.892 218.939 683.148 220.196 683.148 221.746Z" />
          <path d="M689.99 221.746C689.99 223.296 688.733 224.553 687.183 224.553C685.634 224.553 684.376 223.296 684.376 221.746C684.376 220.196 685.634 218.939 687.183 218.939C688.733 218.939 689.99 220.196 689.99 221.746Z" />
          <path d="M689.99 228.588C689.99 230.137 688.733 231.394 687.183 231.394C685.634 231.394 684.376 230.137 684.376 228.588C684.376 227.038 685.634 225.781 687.183 225.781C688.733 225.781 689.99 227.038 689.99 228.588Z" />
          <path d="M689.99 235.43C689.99 236.98 688.733 238.236 687.183 238.236C685.634 238.236 684.376 236.98 684.376 235.43C684.376 233.88 685.634 232.623 687.183 232.623C688.733 232.623 689.99 233.88 689.99 235.43Z" />
          <path d="M689.99 242.272C689.99 243.822 688.733 245.078 687.183 245.078C685.634 245.078 684.376 243.822 684.376 242.272C684.376 240.722 685.634 239.465 687.183 239.465C688.733 239.465 689.99 240.722 689.99 242.272Z" />
          <path d="M696.832 242.272C696.832 243.822 695.575 245.078 694.026 245.078C692.476 245.078 691.218 243.822 691.218 242.272C691.218 240.722 692.476 239.465 694.026 239.465C695.575 239.465 696.832 240.722 696.832 242.272Z" />
          <path d="M696.832 235.43C696.832 236.98 695.575 238.236 694.026 238.236C692.476 238.236 691.218 236.98 691.218 235.43C691.218 233.88 692.476 232.623 694.026 232.623C695.575 232.623 696.832 233.88 696.832 235.43Z" />
          <path d="M683.148 235.43C683.148 236.98 681.892 238.236 680.342 238.236C678.792 238.236 677.534 236.98 677.534 235.43C677.534 233.88 678.792 232.623 680.342 232.623C681.892 232.623 683.148 233.88 683.148 235.43Z" />
          <path d="M683.148 228.588C683.148 230.137 681.892 231.394 680.342 231.394C678.792 231.394 677.534 230.137 677.534 228.588C677.534 227.038 678.792 225.781 680.342 225.781C681.892 225.781 683.148 227.038 683.148 228.588Z" />
          <path d="M683.148 249.113C683.148 250.663 681.892 251.92 680.342 251.92C678.792 251.92 677.534 250.663 677.534 249.113C677.534 247.563 678.792 246.307 680.342 246.307C681.892 246.307 683.148 247.563 683.148 249.113Z" />
          <path d="M683.148 242.272C683.148 243.822 681.892 245.078 680.342 245.078C678.792 245.078 677.534 243.822 677.534 242.272C677.534 240.722 678.792 239.465 680.342 239.465C681.892 239.465 683.148 240.722 683.148 242.272Z" />
          <path d="M676.306 242.272C676.306 243.822 675.05 245.078 673.5 245.078C671.95 245.078 670.693 243.822 670.693 242.272C670.693 240.722 671.95 239.465 673.5 239.465C675.05 239.465 676.306 240.722 676.306 242.272Z" />
          <path d="M676.306 249.113C676.306 250.663 675.05 251.92 673.5 251.92C671.95 251.92 670.693 250.663 670.693 249.113C670.693 247.563 671.95 246.307 673.5 246.307C675.05 246.307 676.306 247.563 676.306 249.113Z" />
          <path d="M669.464 249.113C669.464 250.663 668.208 251.92 666.658 251.92C665.108 251.92 663.851 250.663 663.851 249.113C663.851 247.563 665.108 246.307 666.658 246.307C668.208 246.307 669.464 247.563 669.464 249.113Z" />
          <path d="M662.623 249.113C662.623 250.663 661.366 251.92 659.816 251.92C658.266 251.92 657.009 250.663 657.009 249.113C657.009 247.563 658.266 246.307 659.816 246.307C661.366 246.307 662.623 247.563 662.623 249.113Z" />
          <path d="M737.883 160.169C737.883 161.719 736.626 162.976 735.077 162.976C733.527 162.976 732.269 161.719 732.269 160.169C732.269 158.619 733.527 157.363 735.077 157.363C736.626 157.363 737.883 158.619 737.883 160.169Z" />
          <path d="M717.357 201.22C717.357 202.77 716.101 204.027 714.551 204.027C713.001 204.027 711.744 202.77 711.744 201.22C711.744 199.67 713.001 198.413 714.551 198.413C716.101 198.413 717.357 199.67 717.357 201.22Z" />
          <path d="M724.199 208.062C724.199 209.612 722.943 210.869 721.393 210.869C719.843 210.869 718.585 209.612 718.585 208.062C718.585 206.512 719.843 205.256 721.393 205.256C722.943 205.256 724.199 206.512 724.199 208.062Z" />
          <path d="M724.199 214.904C724.199 216.454 722.943 217.711 721.393 217.711C719.843 217.711 718.585 216.454 718.585 214.904C718.585 213.354 719.843 212.097 721.393 212.097C722.943 212.097 724.199 213.354 724.199 214.904Z" />
          <path d="M703.674 214.904C703.674 216.454 702.417 217.711 700.867 217.711C699.317 217.711 698.06 216.454 698.06 214.904C698.06 213.354 699.317 212.097 700.867 212.097C702.417 212.097 703.674 213.354 703.674 214.904Z" />
          <path d="M696.832 255.955C696.832 257.505 695.575 258.762 694.026 258.762C692.476 258.762 691.218 257.505 691.218 255.955C691.218 254.405 692.476 253.148 694.026 253.148C695.575 253.148 696.832 254.405 696.832 255.955Z" />
          <path d="M669.464 283.323C669.464 284.873 668.208 286.129 666.658 286.129C665.108 286.129 663.851 284.873 663.851 283.323C663.851 281.773 665.108 280.516 666.658 280.516C668.208 280.516 669.464 281.773 669.464 283.323Z" />
          <path d="M676.306 283.323C676.306 284.873 675.05 286.129 673.5 286.129C671.95 286.129 670.693 284.873 670.693 283.323C670.693 281.773 671.95 280.516 673.5 280.516C675.05 280.516 676.306 281.773 676.306 283.323Z" />
          <path d="M683.148 283.323C683.148 284.873 681.892 286.129 680.342 286.129C678.792 286.129 677.534 284.873 677.534 283.323C677.534 281.773 678.792 280.516 680.342 280.516C681.892 280.516 683.148 281.773 683.148 283.323Z" />
          <path d="M683.148 290.164C683.148 291.714 681.892 292.971 680.342 292.971C678.792 292.971 677.534 291.714 677.534 290.164C677.534 288.615 678.792 287.358 680.342 287.358C681.892 287.358 683.148 288.615 683.148 290.164Z" />
          <path d="M676.306 290.164C676.306 291.714 675.05 292.971 673.5 292.971C671.95 292.971 670.693 291.714 670.693 290.164C670.693 288.615 671.95 287.358 673.5 287.358C675.05 287.358 676.306 288.615 676.306 290.164Z" />
          <path d="M607.888 290.164C607.888 291.714 606.631 292.971 605.081 292.971C603.531 292.971 602.274 291.714 602.274 290.164C602.274 288.615 603.531 287.358 605.081 287.358C606.631 287.358 607.888 288.615 607.888 290.164Z" />
          <path d="M614.73 297.006C614.73 298.556 613.473 299.813 611.923 299.813C610.374 299.813 609.116 298.556 609.116 297.006C609.116 295.456 610.374 294.199 611.923 294.199C613.473 294.199 614.73 295.456 614.73 297.006Z" />
          <path d="M518.944 228.588C518.944 230.137 517.687 231.394 516.137 231.394C514.587 231.394 513.33 230.137 513.33 228.588C513.33 227.038 514.587 225.781 516.137 225.781C517.687 225.781 518.944 227.038 518.944 228.588Z" />
          <path d="M512.102 228.588C512.102 230.137 510.845 231.394 509.296 231.394C507.746 231.394 506.488 230.137 506.488 228.588C506.488 227.038 507.746 225.781 509.296 225.781C510.845 225.781 512.102 227.038 512.102 228.588Z" />
          <path d="M505.26 228.588C505.26 230.137 504.004 231.394 502.454 231.394C500.904 231.394 499.646 230.137 499.646 228.588C499.646 227.038 500.904 225.781 502.454 225.781C504.004 225.781 505.26 227.038 505.26 228.588Z" />
          <path d="M505.26 235.43C505.26 236.98 504.004 238.236 502.454 238.236C500.904 238.236 499.646 236.98 499.646 235.43C499.646 233.88 500.904 232.623 502.454 232.623C504.004 232.623 505.26 233.88 505.26 235.43Z" />
          <path d="M498.418 119.118C498.418 120.668 497.162 121.925 495.612 121.925C494.062 121.925 492.805 120.668 492.805 119.118C492.805 117.568 494.062 116.312 495.612 116.312C497.162 116.312 498.418 117.568 498.418 119.118Z" />
          <path d="M505.26 119.118C505.26 120.668 504.004 121.925 502.454 121.925C500.904 121.925 499.646 120.668 499.646 119.118C499.646 117.568 500.904 116.312 502.454 116.312C504.004 116.312 505.26 117.568 505.26 119.118Z" />
          <path d="M505.26 112.277C505.26 113.826 504.004 115.083 502.454 115.083C500.904 115.083 499.646 113.826 499.646 112.277C499.646 110.727 500.904 109.47 502.454 109.47C504.004 109.47 505.26 110.727 505.26 112.277Z" />
          <path d="M512.102 112.277C512.102 113.826 510.845 115.083 509.296 115.083C507.746 115.083 506.488 113.826 506.488 112.277C506.488 110.727 507.746 109.47 509.296 109.47C510.845 109.47 512.102 110.727 512.102 112.277Z" />
          <path d="M518.944 112.277C518.944 113.826 517.687 115.083 516.137 115.083C514.587 115.083 513.33 113.826 513.33 112.277C513.33 110.727 514.587 109.47 516.137 109.47C517.687 109.47 518.944 110.727 518.944 112.277Z" />
          <path d="M525.786 112.277C525.786 113.826 524.53 115.083 522.98 115.083C521.43 115.083 520.172 113.826 520.172 112.277C520.172 110.727 521.43 109.47 522.98 109.47C524.53 109.47 525.786 110.727 525.786 112.277Z" />
          <path d="M532.628 112.277C532.628 113.826 531.371 115.083 529.821 115.083C528.271 115.083 527.014 113.826 527.014 112.277C527.014 110.727 528.271 109.47 529.821 109.47C531.371 109.47 532.628 110.727 532.628 112.277Z" />
          <path d="M539.469 112.277C539.469 113.826 538.213 115.083 536.663 115.083C535.113 115.083 533.856 113.826 533.856 112.277C533.856 110.727 535.113 109.47 536.663 109.47C538.213 109.47 539.469 110.727 539.469 112.277Z" />
          <path d="M539.469 119.118C539.469 120.668 538.213 121.925 536.663 121.925C535.113 121.925 533.856 120.668 533.856 119.118C533.856 117.568 535.113 116.312 536.663 116.312C538.213 116.312 539.469 117.568 539.469 119.118Z" />
          <path d="M532.628 119.118C532.628 120.668 531.371 121.925 529.821 121.925C528.271 121.925 527.014 120.668 527.014 119.118C527.014 117.568 528.271 116.312 529.821 116.312C531.371 116.312 532.628 117.568 532.628 119.118Z" />
          <path d="M525.786 119.118C525.786 120.668 524.53 121.925 522.98 121.925C521.43 121.925 520.172 120.668 520.172 119.118C520.172 117.568 521.43 116.312 522.98 116.312C524.53 116.312 525.786 117.568 525.786 119.118Z" />
          <path d="M518.944 119.118C518.944 120.668 517.687 121.925 516.137 121.925C514.587 121.925 513.33 120.668 513.33 119.118C513.33 117.568 514.587 116.312 516.137 116.312C517.687 116.312 518.944 117.568 518.944 119.118Z" />
          <path d="M512.102 119.118C512.102 120.668 510.845 121.925 509.296 121.925C507.746 121.925 506.488 120.668 506.488 119.118C506.488 117.568 507.746 116.312 509.296 116.312C510.845 116.312 512.102 117.568 512.102 119.118Z" />
          <path d="M512.102 105.435C512.102 106.985 510.845 108.241 509.296 108.241C507.746 108.241 506.488 106.985 506.488 105.435C506.488 103.885 507.746 102.628 509.296 102.628C510.845 102.628 512.102 103.885 512.102 105.435Z" />
          <path d="M518.944 105.435C518.944 106.985 517.687 108.241 516.137 108.241C514.587 108.241 513.33 106.985 513.33 105.435C513.33 103.885 514.587 102.628 516.137 102.628C517.687 102.628 518.944 103.885 518.944 105.435Z" />
          <path d="M525.786 105.435C525.786 106.985 524.53 108.241 522.98 108.241C521.43 108.241 520.172 106.985 520.172 105.435C520.172 103.885 521.43 102.628 522.98 102.628C524.53 102.628 525.786 103.885 525.786 105.435Z" />
          <path d="M532.628 105.435C532.628 106.985 531.371 108.241 529.821 108.241C528.271 108.241 527.014 106.985 527.014 105.435C527.014 103.885 528.271 102.628 529.821 102.628C531.371 102.628 532.628 103.885 532.628 105.435Z" />
          <path d="M532.628 98.5927C532.628 100.143 531.371 101.399 529.821 101.399C528.271 101.399 527.014 100.143 527.014 98.5927C527.014 97.0427 528.271 95.7859 529.821 95.7859C531.371 95.7859 532.628 97.0427 532.628 98.5927Z" />
          <path d="M539.469 98.5927C539.469 100.143 538.213 101.399 536.663 101.399C535.113 101.399 533.856 100.143 533.856 98.5927C533.856 97.0427 535.113 95.7859 536.663 95.7859C538.213 95.7859 539.469 97.0427 539.469 98.5927Z" />
          <path d="M539.469 105.435C539.469 106.985 538.213 108.241 536.663 108.241C535.113 108.241 533.856 106.985 533.856 105.435C533.856 103.885 535.113 102.628 536.663 102.628C538.213 102.628 539.469 103.885 539.469 105.435Z" />
          <path d="M546.311 105.435C546.311 106.985 545.055 108.241 543.505 108.241C541.955 108.241 540.698 106.985 540.698 105.435C540.698 103.885 541.955 102.628 543.505 102.628C545.055 102.628 546.311 103.885 546.311 105.435Z" />
          <path d="M546.311 112.277C546.311 113.826 545.055 115.083 543.505 115.083C541.955 115.083 540.698 113.826 540.698 112.277C540.698 110.727 541.955 109.47 543.505 109.47C545.055 109.47 546.311 110.727 546.311 112.277Z" />
          <path d="M553.153 112.277C553.153 113.826 551.897 115.083 550.347 115.083C548.797 115.083 547.539 113.826 547.539 112.277C547.539 110.727 548.797 109.47 550.347 109.47C551.897 109.47 553.153 110.727 553.153 112.277Z" />
          <path d="M559.995 112.277C559.995 113.826 558.739 115.083 557.189 115.083C555.639 115.083 554.381 113.826 554.381 112.277C554.381 110.727 555.639 109.47 557.189 109.47C558.739 109.47 559.995 110.727 559.995 112.277Z" />
          <path d="M566.837 112.277C566.837 113.826 565.58 115.083 564.03 115.083C562.48 115.083 561.223 113.826 561.223 112.277C561.223 110.727 562.48 109.47 564.03 109.47C565.58 109.47 566.837 110.727 566.837 112.277Z" />
          <path d="M566.837 105.435C566.837 106.985 565.58 108.241 564.03 108.241C562.48 108.241 561.223 106.985 561.223 105.435C561.223 103.885 562.48 102.628 564.03 102.628C565.58 102.628 566.837 103.885 566.837 105.435Z" />
          <path d="M559.995 105.435C559.995 106.985 558.739 108.241 557.189 108.241C555.639 108.241 554.381 106.985 554.381 105.435C554.381 103.885 555.639 102.628 557.189 102.628C558.739 102.628 559.995 103.885 559.995 105.435Z" />
          <path d="M559.995 98.5927C559.995 100.143 558.739 101.399 557.189 101.399C555.639 101.399 554.381 100.143 554.381 98.5927C554.381 97.0427 555.639 95.7859 557.189 95.7859C558.739 95.7859 559.995 97.0427 559.995 98.5927Z" />
          <path d="M573.678 98.5927C573.678 100.143 572.422 101.399 570.872 101.399C569.322 101.399 568.065 100.143 568.065 98.5927C568.065 97.0427 569.322 95.7859 570.872 95.7859C572.422 95.7859 573.678 97.0427 573.678 98.5927Z" />
          <path d="M573.678 91.7506C573.678 93.3006 572.422 94.5574 570.872 94.5574C569.322 94.5574 568.065 93.3006 568.065 91.7506C568.065 90.2007 569.322 88.9439 570.872 88.9439C572.422 88.9439 573.678 90.2007 573.678 91.7506Z" />
          <path d="M580.52 91.7506C580.52 93.3006 579.264 94.5574 577.714 94.5574C576.164 94.5574 574.907 93.3006 574.907 91.7506C574.907 90.2007 576.164 88.9439 577.714 88.9439C579.264 88.9439 580.52 90.2007 580.52 91.7506Z" />
          <path d="M580.52 84.9091C580.52 86.459 579.264 87.7158 577.714 87.7158C576.164 87.7158 574.907 86.459 574.907 84.9091C574.907 83.3591 576.164 82.1024 577.714 82.1024C579.264 82.1024 580.52 83.3591 580.52 84.9091Z" />
          <path d="M580.52 78.0673C580.52 79.6172 579.264 80.874 577.714 80.874C576.164 80.874 574.907 79.6172 574.907 78.0673C574.907 76.5173 576.164 75.2606 577.714 75.2606C579.264 75.2606 580.52 76.5173 580.52 78.0673Z" />
          <path d="M573.678 78.0673C573.678 79.6172 572.422 80.874 570.872 80.874C569.322 80.874 568.065 79.6172 568.065 78.0673C568.065 76.5173 569.322 75.2606 570.872 75.2606C572.422 75.2606 573.678 76.5173 573.678 78.0673Z" />
          <path d="M573.678 71.2255C573.678 72.7754 572.422 74.0322 570.872 74.0322C569.322 74.0322 568.065 72.7754 568.065 71.2255C568.065 69.6756 569.322 68.4188 570.872 68.4188C572.422 68.4188 573.678 69.6756 573.678 71.2255Z" />
          <path d="M566.837 71.2255C566.837 72.7754 565.58 74.0322 564.03 74.0322C562.48 74.0322 561.223 72.7754 561.223 71.2255C561.223 69.6756 562.48 68.4188 564.03 68.4188C565.58 68.4188 566.837 69.6756 566.837 71.2255Z" />
          <path d="M559.995 64.3832C559.995 65.9331 558.739 67.1899 557.189 67.1899C555.639 67.1899 554.381 65.9331 554.381 64.3832C554.381 62.8333 555.639 61.5765 557.189 61.5765C558.739 61.5765 559.995 62.8333 559.995 64.3832Z" />
          <path d="M573.678 64.3832C573.678 65.9331 572.422 67.1899 570.872 67.1899C569.322 67.1899 568.065 65.9331 568.065 64.3832C568.065 62.8333 569.322 61.5765 570.872 61.5765C572.422 61.5765 573.678 62.8333 573.678 64.3832Z" />
          <path d="M573.678 57.5417C573.678 59.0916 572.422 60.3484 570.872 60.3484C569.322 60.3484 568.065 59.0916 568.065 57.5417C568.065 55.9917 569.322 54.7349 570.872 54.7349C572.422 54.7349 573.678 55.9917 573.678 57.5417Z" />
          <path d="M573.678 50.6999C573.678 52.2498 572.422 53.5066 570.872 53.5066C569.322 53.5066 568.065 52.2498 568.065 50.6999C568.065 49.1499 569.322 47.8931 570.872 47.8931C572.422 47.8931 573.678 49.1499 573.678 50.6999Z" />
          <path d="M580.52 50.6999C580.52 52.2498 579.264 53.5066 577.714 53.5066C576.164 53.5066 574.907 52.2498 574.907 50.6999C574.907 49.1499 576.164 47.8931 577.714 47.8931C579.264 47.8931 580.52 49.1499 580.52 50.6999Z" />
          <path d="M580.52 43.8581C580.52 45.408 579.264 46.6648 577.714 46.6648C576.164 46.6648 574.907 45.408 574.907 43.8581C574.907 42.3081 576.164 41.0513 577.714 41.0513C579.264 41.0513 580.52 42.3081 580.52 43.8581Z" />
          <path d="M587.362 50.6999C587.362 52.2498 586.106 53.5066 584.556 53.5066C583.006 53.5066 581.749 52.2498 581.749 50.6999C581.749 49.1499 583.006 47.8931 584.556 47.8931C586.106 47.8931 587.362 49.1499 587.362 50.6999Z" />
          <path d="M587.362 43.8581C587.362 45.408 586.106 46.6648 584.556 46.6648C583.006 46.6648 581.749 45.408 581.749 43.8581C581.749 42.3081 583.006 41.0513 584.556 41.0513C586.106 41.0513 587.362 42.3081 587.362 43.8581Z" />
          <path d="M594.204 43.8581C594.204 45.408 592.948 46.6648 591.398 46.6648C589.848 46.6648 588.591 45.408 588.591 43.8581C588.591 42.3081 589.848 41.0513 591.398 41.0513C592.948 41.0513 594.204 42.3081 594.204 43.8581Z" />
          <path d="M587.362 37.0163C587.362 38.5662 586.106 39.823 584.556 39.823C583.006 39.823 581.749 38.5662 581.749 37.0163C581.749 35.4663 583.006 34.2095 584.556 34.2095C586.106 34.2095 587.362 35.4663 587.362 37.0163Z" />
          <path d="M587.362 30.1742C587.362 31.7242 586.106 32.981 584.556 32.981C583.006 32.981 581.749 31.7242 581.749 30.1742C581.749 28.6243 583.006 27.3675 584.556 27.3675C586.106 27.3675 587.362 28.6243 587.362 30.1742Z" />
          <path d="M580.52 23.3324C580.52 24.8824 579.264 26.1392 577.714 26.1392C576.164 26.1392 574.907 24.8824 574.907 23.3324C574.907 21.7825 576.164 20.5257 577.714 20.5257C579.264 20.5257 580.52 21.7825 580.52 23.3324Z" />
          <path d="M566.837 16.4906C566.837 18.0406 565.58 19.2974 564.03 19.2974C562.48 19.2974 561.223 18.0406 561.223 16.4906C561.223 14.9407 562.48 13.6839 564.03 13.6839C565.58 13.6839 566.837 14.9407 566.837 16.4906Z" />
          <path d="M559.995 16.4906C559.995 18.0406 558.739 19.2974 557.189 19.2974C555.639 19.2974 554.381 18.0406 554.381 16.4906C554.381 14.9407 555.639 13.6839 557.189 13.6839C558.739 13.6839 559.995 14.9407 559.995 16.4906Z" />
          <path d="M553.153 9.64883C553.153 11.1988 551.897 12.4556 550.347 12.4556C548.797 12.4556 547.539 11.1988 547.539 9.64883C547.539 8.09889 548.797 6.8421 550.347 6.8421C551.897 6.8421 553.153 8.09889 553.153 9.64883Z" />
          <path d="M594.204 37.0163C594.204 38.5662 592.948 39.823 591.398 39.823C589.848 39.823 588.591 38.5662 588.591 37.0163C588.591 35.4663 589.848 34.2095 591.398 34.2095C592.948 34.2095 594.204 35.4663 594.204 37.0163Z" />
          <path d="M601.046 37.0163C601.046 38.5662 599.79 39.823 598.24 39.823C596.69 39.823 595.432 38.5662 595.432 37.0163C595.432 35.4663 596.69 34.2095 598.24 34.2095C599.79 34.2095 601.046 35.4663 601.046 37.0163Z" />
          <path d="M601.046 30.1742C601.046 31.7242 599.79 32.981 598.24 32.981C596.69 32.981 595.432 31.7242 595.432 30.1742C595.432 28.6243 596.69 27.3675 598.24 27.3675C599.79 27.3675 601.046 28.6243 601.046 30.1742Z" />
          <path d="M601.046 43.8581C601.046 45.408 599.79 46.6648 598.24 46.6648C596.69 46.6648 595.432 45.408 595.432 43.8581C595.432 42.3081 596.69 41.0513 598.24 41.0513C599.79 41.0513 601.046 42.3081 601.046 43.8581Z" />
          <path d="M601.046 50.6999C601.046 52.2498 599.79 53.5066 598.24 53.5066C596.69 53.5066 595.432 52.2498 595.432 50.6999C595.432 49.1499 596.69 47.8931 598.24 47.8931C599.79 47.8931 601.046 49.1499 601.046 50.6999Z" />
          <path d="M601.046 57.5417C601.046 59.0916 599.79 60.3484 598.24 60.3484C596.69 60.3484 595.432 59.0916 595.432 57.5417C595.432 55.9917 596.69 54.7349 598.24 54.7349C599.79 54.7349 601.046 55.9917 601.046 57.5417Z" />
          <path d="M601.046 64.3832C601.046 65.9331 599.79 67.1899 598.24 67.1899C596.69 67.1899 595.432 65.9331 595.432 64.3832C595.432 62.8333 596.69 61.5765 598.24 61.5765C599.79 61.5765 601.046 62.8333 601.046 64.3832Z" />
          <path d="M607.888 64.3832C607.888 65.9331 606.631 67.1899 605.081 67.1899C603.531 67.1899 602.274 65.9331 602.274 64.3832C602.274 62.8333 603.531 61.5765 605.081 61.5765C606.631 61.5765 607.888 62.8333 607.888 64.3832Z" />
          <path d="M614.73 64.3832C614.73 65.9331 613.473 67.1899 611.923 67.1899C610.374 67.1899 609.116 65.9331 609.116 64.3832C609.116 62.8333 610.374 61.5765 611.923 61.5765C613.473 61.5765 614.73 62.8333 614.73 64.3832Z" />
          <path d="M614.73 57.5417C614.73 59.0916 613.473 60.3484 611.923 60.3484C610.374 60.3484 609.116 59.0916 609.116 57.5417C609.116 55.9917 610.374 54.7349 611.923 54.7349C613.473 54.7349 614.73 55.9917 614.73 57.5417Z" />
          <path d="M621.571 57.5417C621.571 59.0916 620.315 60.3484 618.765 60.3484C617.215 60.3484 615.958 59.0916 615.958 57.5417C615.958 55.9917 617.215 54.7349 618.765 54.7349C620.315 54.7349 621.571 55.9917 621.571 57.5417Z" />
          <path d="M628.413 57.5417C628.413 59.0916 627.157 60.3484 625.607 60.3484C624.057 60.3484 622.8 59.0916 622.8 57.5417C622.8 55.9917 624.057 54.7349 625.607 54.7349C627.157 54.7349 628.413 55.9917 628.413 57.5417Z" />
          <path d="M628.413 50.6999C628.413 52.2498 627.157 53.5066 625.607 53.5066C624.057 53.5066 622.8 52.2498 622.8 50.6999C622.8 49.1499 624.057 47.8931 625.607 47.8931C627.157 47.8931 628.413 49.1499 628.413 50.6999Z" />
          <path d="M635.255 57.5417C635.255 59.0916 633.999 60.3484 632.449 60.3484C630.899 60.3484 629.642 59.0916 629.642 57.5417C629.642 55.9917 630.899 54.7349 632.449 54.7349C633.999 54.7349 635.255 55.9917 635.255 57.5417Z" />
          <path d="M642.097 57.5417C642.097 59.0916 640.841 60.3484 639.291 60.3484C637.741 60.3484 636.483 59.0916 636.483 57.5417C636.483 55.9917 637.741 54.7349 639.291 54.7349C640.841 54.7349 642.097 55.9917 642.097 57.5417Z" />
          <path d="M642.097 50.6999C642.097 52.2498 640.841 53.5066 639.291 53.5066C637.741 53.5066 636.483 52.2498 636.483 50.6999C636.483 49.1499 637.741 47.8931 639.291 47.8931C640.841 47.8931 642.097 49.1499 642.097 50.6999Z" />
          <path d="M648.939 50.6999C648.939 52.2498 647.682 53.5066 646.132 53.5066C644.583 53.5066 643.325 52.2498 643.325 50.6999C643.325 49.1499 644.583 47.8931 646.132 47.8931C647.682 47.8931 648.939 49.1499 648.939 50.6999Z" />
          <path d="M648.939 57.5417C648.939 59.0916 647.682 60.3484 646.132 60.3484C644.583 60.3484 643.325 59.0916 643.325 57.5417C643.325 55.9917 644.583 54.7349 646.132 54.7349C647.682 54.7349 648.939 55.9917 648.939 57.5417Z" />
          <path d="M655.78 57.5417C655.78 59.0916 654.524 60.3484 652.974 60.3484C651.424 60.3484 650.167 59.0916 650.167 57.5417C650.167 55.9917 651.424 54.7349 652.974 54.7349C654.524 54.7349 655.78 55.9917 655.78 57.5417Z" />
          <path d="M655.78 64.3832C655.78 65.9331 654.524 67.1899 652.974 67.1899C651.424 67.1899 650.167 65.9331 650.167 64.3832C650.167 62.8333 651.424 61.5765 652.974 61.5765C654.524 61.5765 655.78 62.8333 655.78 64.3832Z" />
          <path d="M662.623 64.3832C662.623 65.9331 661.366 67.1899 659.816 67.1899C658.266 67.1899 657.009 65.9331 657.009 64.3832C657.009 62.8333 658.266 61.5765 659.816 61.5765C661.366 61.5765 662.623 62.8333 662.623 64.3832Z" />
          <path d="M669.464 64.3832C669.464 65.9331 668.208 67.1899 666.658 67.1899C665.108 67.1899 663.851 65.9331 663.851 64.3832C663.851 62.8333 665.108 61.5765 666.658 61.5765C668.208 61.5765 669.464 62.8333 669.464 64.3832Z" />
          <path d="M669.464 57.5417C669.464 59.0916 668.208 60.3484 666.658 60.3484C665.108 60.3484 663.851 59.0916 663.851 57.5417C663.851 55.9917 665.108 54.7349 666.658 54.7349C668.208 54.7349 669.464 55.9917 669.464 57.5417Z" />
          <path d="M676.306 57.5417C676.306 59.0916 675.05 60.3484 673.5 60.3484C671.95 60.3484 670.693 59.0916 670.693 57.5417C670.693 55.9917 671.95 54.7349 673.5 54.7349C675.05 54.7349 676.306 55.9917 676.306 57.5417Z" />
          <path d="M676.306 50.6999C676.306 52.2498 675.05 53.5066 673.5 53.5066C671.95 53.5066 670.693 52.2498 670.693 50.6999C670.693 49.1499 671.95 47.8931 673.5 47.8931C675.05 47.8931 676.306 49.1499 676.306 50.6999Z" />
          <path d="M683.148 50.6999C683.148 52.2498 681.892 53.5066 680.342 53.5066C678.792 53.5066 677.534 52.2498 677.534 50.6999C677.534 49.1499 678.792 47.8931 680.342 47.8931C681.892 47.8931 683.148 49.1499 683.148 50.6999Z" />
          <path d="M683.148 43.8581C683.148 45.408 681.892 46.6648 680.342 46.6648C678.792 46.6648 677.534 45.408 677.534 43.8581C677.534 42.3081 678.792 41.0513 680.342 41.0513C681.892 41.0513 683.148 42.3081 683.148 43.8581Z" />
          <path d="M669.464 30.1742C669.464 31.7242 668.208 32.981 666.658 32.981C665.108 32.981 663.851 31.7242 663.851 30.1742C663.851 28.6243 665.108 27.3675 666.658 27.3675C668.208 27.3675 669.464 28.6243 669.464 30.1742Z" />
          <path d="M655.78 23.3324C655.78 24.8824 654.524 26.1392 652.974 26.1392C651.424 26.1392 650.167 24.8824 650.167 23.3324C650.167 21.7825 651.424 20.5257 652.974 20.5257C654.524 20.5257 655.78 21.7825 655.78 23.3324Z" />
          <path d="M662.623 23.3324C662.623 24.8824 661.366 26.1392 659.816 26.1392C658.266 26.1392 657.009 24.8824 657.009 23.3324C657.009 21.7825 658.266 20.5257 659.816 20.5257C661.366 20.5257 662.623 21.7825 662.623 23.3324Z" />
          <path d="M662.623 16.4906C662.623 18.0406 661.366 19.2974 659.816 19.2974C658.266 19.2974 657.009 18.0406 657.009 16.4906C657.009 14.9407 658.266 13.6839 659.816 13.6839C661.366 13.6839 662.623 14.9407 662.623 16.4906Z" />
          <path d="M676.306 16.4906C676.306 18.0406 675.05 19.2974 673.5 19.2974C671.95 19.2974 670.693 18.0406 670.693 16.4906C670.693 14.9407 671.95 13.6839 673.5 13.6839C675.05 13.6839 676.306 14.9407 676.306 16.4906Z" />
          <path d="M689.99 43.8581C689.99 45.408 688.733 46.6648 687.183 46.6648C685.634 46.6648 684.376 45.408 684.376 43.8581C684.376 42.3081 685.634 41.0513 687.183 41.0513C688.733 41.0513 689.99 42.3081 689.99 43.8581Z" />
          <path d="M689.99 37.0163C689.99 38.5662 688.733 39.823 687.183 39.823C685.634 39.823 684.376 38.5662 684.376 37.0163C684.376 35.4663 685.634 34.2095 687.183 34.2095C688.733 34.2095 689.99 35.4663 689.99 37.0163Z" />
          <path d="M689.99 50.6999C689.99 52.2498 688.733 53.5066 687.183 53.5066C685.634 53.5066 684.376 52.2498 684.376 50.6999C684.376 49.1499 685.634 47.8931 687.183 47.8931C688.733 47.8931 689.99 49.1499 689.99 50.6999Z" />
          <path d="M696.832 50.6999C696.832 52.2498 695.575 53.5066 694.026 53.5066C692.476 53.5066 691.218 52.2498 691.218 50.6999C691.218 49.1499 692.476 47.8931 694.026 47.8931C695.575 47.8931 696.832 49.1499 696.832 50.6999Z" />
          <path d="M696.832 43.8581C696.832 45.408 695.575 46.6648 694.026 46.6648C692.476 46.6648 691.218 45.408 691.218 43.8581C691.218 42.3081 692.476 41.0513 694.026 41.0513C695.575 41.0513 696.832 42.3081 696.832 43.8581Z" />
          <path d="M703.674 50.6999C703.674 52.2498 702.417 53.5066 700.867 53.5066C699.317 53.5066 698.06 52.2498 698.06 50.6999C698.06 49.1499 699.317 47.8931 700.867 47.8931C702.417 47.8931 703.674 49.1499 703.674 50.6999Z" />
          <path d="M710.515 50.6999C710.515 52.2498 709.259 53.5066 707.709 53.5066C706.159 53.5066 704.902 52.2498 704.902 50.6999C704.902 49.1499 706.159 47.8931 707.709 47.8931C709.259 47.8931 710.515 49.1499 710.515 50.6999Z" />
          <path d="M710.515 43.8581C710.515 45.408 709.259 46.6648 707.709 46.6648C706.159 46.6648 704.902 45.408 704.902 43.8581C704.902 42.3081 706.159 41.0513 707.709 41.0513C709.259 41.0513 710.515 42.3081 710.515 43.8581Z" />
          <path d="M717.357 43.8581C717.357 45.408 716.101 46.6648 714.551 46.6648C713.001 46.6648 711.744 45.408 711.744 43.8581C711.744 42.3081 713.001 41.0513 714.551 41.0513C716.101 41.0513 717.357 42.3081 717.357 43.8581Z" />
          <path d="M717.357 50.6999C717.357 52.2498 716.101 53.5066 714.551 53.5066C713.001 53.5066 711.744 52.2498 711.744 50.6999C711.744 49.1499 713.001 47.8931 714.551 47.8931C716.101 47.8931 717.357 49.1499 717.357 50.6999Z" />
          <path d="M724.199 50.6999C724.199 52.2498 722.943 53.5066 721.393 53.5066C719.843 53.5066 718.585 52.2498 718.585 50.6999C718.585 49.1499 719.843 47.8931 721.393 47.8931C722.943 47.8931 724.199 49.1499 724.199 50.6999Z" />
          <path d="M731.041 50.6999C731.041 52.2498 729.785 53.5066 728.235 53.5066C726.685 53.5066 725.427 52.2498 725.427 50.6999C725.427 49.1499 726.685 47.8931 728.235 47.8931C729.785 47.8931 731.041 49.1499 731.041 50.6999Z" />
          <path d="M737.883 50.6999C737.883 52.2498 736.626 53.5066 735.077 53.5066C733.527 53.5066 732.269 52.2498 732.269 50.6999C732.269 49.1499 733.527 47.8931 735.077 47.8931C736.626 47.8931 737.883 49.1499 737.883 50.6999Z" />
          <path d="M737.883 43.8581C737.883 45.408 736.626 46.6648 735.077 46.6648C733.527 46.6648 732.269 45.408 732.269 43.8581C732.269 42.3081 733.527 41.0513 735.077 41.0513C736.626 41.0513 737.883 42.3081 737.883 43.8581Z" />
          <path d="M744.725 43.8581C744.725 45.408 743.468 46.6648 741.918 46.6648C740.368 46.6648 739.111 45.408 739.111 43.8581C739.111 42.3081 740.368 41.0513 741.918 41.0513C743.468 41.0513 744.725 42.3081 744.725 43.8581Z" />
          <path d="M744.725 50.6999C744.725 52.2498 743.468 53.5066 741.918 53.5066C740.368 53.5066 739.111 52.2498 739.111 50.6999C739.111 49.1499 740.368 47.8931 741.918 47.8931C743.468 47.8931 744.725 49.1499 744.725 50.6999Z" />
          <path d="M751.566 50.6999C751.566 52.2498 750.31 53.5066 748.76 53.5066C747.21 53.5066 745.953 52.2498 745.953 50.6999C745.953 49.1499 747.21 47.8931 748.76 47.8931C750.31 47.8931 751.566 49.1499 751.566 50.6999Z" />
          <path d="M751.566 43.8581C751.566 45.408 750.31 46.6648 748.76 46.6648C747.21 46.6648 745.953 45.408 745.953 43.8581C745.953 42.3081 747.21 41.0513 748.76 41.0513C750.31 41.0513 751.566 42.3081 751.566 43.8581Z" />
          <path d="M758.408 43.8581C758.408 45.408 757.152 46.6648 755.602 46.6648C754.052 46.6648 752.795 45.408 752.795 43.8581C752.795 42.3081 754.052 41.0513 755.602 41.0513C757.152 41.0513 758.408 42.3081 758.408 43.8581Z" />
          <path d="M765.25 43.8581C765.25 45.408 763.994 46.6648 762.444 46.6648C760.894 46.6648 759.637 45.408 759.637 43.8581C759.637 42.3081 760.894 41.0513 762.444 41.0513C763.994 41.0513 765.25 42.3081 765.25 43.8581Z" />
          <path d="M772.092 43.8581C772.092 45.408 770.836 46.6648 769.286 46.6648C767.736 46.6648 766.479 45.408 766.479 43.8581C766.479 42.3081 767.736 41.0513 769.286 41.0513C770.836 41.0513 772.092 42.3081 772.092 43.8581Z" />
          <path d="M772.092 50.6999C772.092 52.2498 770.836 53.5066 769.286 53.5066C767.736 53.5066 766.479 52.2498 766.479 50.6999C766.479 49.1499 767.736 47.8931 769.286 47.8931C770.836 47.8931 772.092 49.1499 772.092 50.6999Z" />
          <path d="M778.934 50.6999C778.934 52.2498 777.678 53.5066 776.128 53.5066C774.578 53.5066 773.32 52.2498 773.32 50.6999C773.32 49.1499 774.578 47.8931 776.128 47.8931C777.678 47.8931 778.934 49.1499 778.934 50.6999Z" />
          <path d="M765.25 50.6999C765.25 52.2498 763.994 53.5066 762.444 53.5066C760.894 53.5066 759.637 52.2498 759.637 50.6999C759.637 49.1499 760.894 47.8931 762.444 47.8931C763.994 47.8931 765.25 49.1499 765.25 50.6999Z" />
          <path d="M758.408 50.6999C758.408 52.2498 757.152 53.5066 755.602 53.5066C754.052 53.5066 752.795 52.2498 752.795 50.6999C752.795 49.1499 754.052 47.8931 755.602 47.8931C757.152 47.8931 758.408 49.1499 758.408 50.6999Z" />
          <path d="M758.408 57.5417C758.408 59.0916 757.152 60.3484 755.602 60.3484C754.052 60.3484 752.795 59.0916 752.795 57.5417C752.795 55.9917 754.052 54.7349 755.602 54.7349C757.152 54.7349 758.408 55.9917 758.408 57.5417Z" />
          <path d="M751.566 57.5417C751.566 59.0916 750.31 60.3484 748.76 60.3484C747.21 60.3484 745.953 59.0916 745.953 57.5417C745.953 55.9917 747.21 54.7349 748.76 54.7349C750.31 54.7349 751.566 55.9917 751.566 57.5417Z" />
          <path d="M744.725 57.5417C744.725 59.0916 743.468 60.3484 741.918 60.3484C740.368 60.3484 739.111 59.0916 739.111 57.5417C739.111 55.9917 740.368 54.7349 741.918 54.7349C743.468 54.7349 744.725 55.9917 744.725 57.5417Z" />
          <path d="M737.883 57.5417C737.883 59.0916 736.626 60.3484 735.077 60.3484C733.527 60.3484 732.269 59.0916 732.269 57.5417C732.269 55.9917 733.527 54.7349 735.077 54.7349C736.626 54.7349 737.883 55.9917 737.883 57.5417Z" />
          <path d="M731.041 57.5417C731.041 59.0916 729.785 60.3484 728.235 60.3484C726.685 60.3484 725.427 59.0916 725.427 57.5417C725.427 55.9917 726.685 54.7349 728.235 54.7349C729.785 54.7349 731.041 55.9917 731.041 57.5417Z" />
          <path d="M724.199 57.5417C724.199 59.0916 722.943 60.3484 721.393 60.3484C719.843 60.3484 718.585 59.0916 718.585 57.5417C718.585 55.9917 719.843 54.7349 721.393 54.7349C722.943 54.7349 724.199 55.9917 724.199 57.5417Z" />
          <path d="M717.357 57.5417C717.357 59.0916 716.101 60.3484 714.551 60.3484C713.001 60.3484 711.744 59.0916 711.744 57.5417C711.744 55.9917 713.001 54.7349 714.551 54.7349C716.101 54.7349 717.357 55.9917 717.357 57.5417Z" />
          <path d="M710.515 57.5417C710.515 59.0916 709.259 60.3484 707.709 60.3484C706.159 60.3484 704.902 59.0916 704.902 57.5417C704.902 55.9917 706.159 54.7349 707.709 54.7349C709.259 54.7349 710.515 55.9917 710.515 57.5417Z" />
          <path d="M710.515 64.3832C710.515 65.9331 709.259 67.1899 707.709 67.1899C706.159 67.1899 704.902 65.9331 704.902 64.3832C704.902 62.8333 706.159 61.5765 707.709 61.5765C709.259 61.5765 710.515 62.8333 710.515 64.3832Z" />
          <path d="M710.515 71.2255C710.515 72.7754 709.259 74.0322 707.709 74.0322C706.159 74.0322 704.902 72.7754 704.902 71.2255C704.902 69.6756 706.159 68.4188 707.709 68.4188C709.259 68.4188 710.515 69.6756 710.515 71.2255Z" />
          <path d="M710.515 78.0673C710.515 79.6172 709.259 80.874 707.709 80.874C706.159 80.874 704.902 79.6172 704.902 78.0673C704.902 76.5173 706.159 75.2606 707.709 75.2606C709.259 75.2606 710.515 76.5173 710.515 78.0673Z" />
          <path d="M710.515 84.9091C710.515 86.459 709.259 87.7158 707.709 87.7158C706.159 87.7158 704.902 86.459 704.902 84.9091C704.902 83.3591 706.159 82.1024 707.709 82.1024C709.259 82.1024 710.515 83.3591 710.515 84.9091Z" />
          <path d="M710.515 91.7506C710.515 93.3006 709.259 94.5574 707.709 94.5574C706.159 94.5574 704.902 93.3006 704.902 91.7506C704.902 90.2007 706.159 88.9439 707.709 88.9439C709.259 88.9439 710.515 90.2007 710.515 91.7506Z" />
          <path d="M703.674 91.7506C703.674 93.3006 702.417 94.5574 700.867 94.5574C699.317 94.5574 698.06 93.3006 698.06 91.7506C698.06 90.2007 699.317 88.9439 700.867 88.9439C702.417 88.9439 703.674 90.2007 703.674 91.7506Z" />
          <path d="M703.674 84.9091C703.674 86.459 702.417 87.7158 700.867 87.7158C699.317 87.7158 698.06 86.459 698.06 84.9091C698.06 83.3591 699.317 82.1024 700.867 82.1024C702.417 82.1024 703.674 83.3591 703.674 84.9091Z" />
          <path d="M703.674 78.0673C703.674 79.6172 702.417 80.874 700.867 80.874C699.317 80.874 698.06 79.6172 698.06 78.0673C698.06 76.5173 699.317 75.2606 700.867 75.2606C702.417 75.2606 703.674 76.5173 703.674 78.0673Z" />
          <path d="M703.674 71.2255C703.674 72.7754 702.417 74.0322 700.867 74.0322C699.317 74.0322 698.06 72.7754 698.06 71.2255C698.06 69.6756 699.317 68.4188 700.867 68.4188C702.417 68.4188 703.674 69.6756 703.674 71.2255Z" />
          <path d="M703.674 64.3832C703.674 65.9331 702.417 67.1899 700.867 67.1899C699.317 67.1899 698.06 65.9331 698.06 64.3832C698.06 62.8333 699.317 61.5765 700.867 61.5765C702.417 61.5765 703.674 62.8333 703.674 64.3832Z" />
          <path d="M703.674 57.5417C703.674 59.0916 702.417 60.3484 700.867 60.3484C699.317 60.3484 698.06 59.0916 698.06 57.5417C698.06 55.9917 699.317 54.7349 700.867 54.7349C702.417 54.7349 703.674 55.9917 703.674 57.5417Z" />
          <path d="M696.832 57.5417C696.832 59.0916 695.575 60.3484 694.026 60.3484C692.476 60.3484 691.218 59.0916 691.218 57.5417C691.218 55.9917 692.476 54.7349 694.026 54.7349C695.575 54.7349 696.832 55.9917 696.832 57.5417Z" />
          <path d="M689.99 57.5417C689.99 59.0916 688.733 60.3484 687.183 60.3484C685.634 60.3484 684.376 59.0916 684.376 57.5417C684.376 55.9917 685.634 54.7349 687.183 54.7349C688.733 54.7349 689.99 55.9917 689.99 57.5417Z" />
          <path d="M689.99 64.3832C689.99 65.9331 688.733 67.1899 687.183 67.1899C685.634 67.1899 684.376 65.9331 684.376 64.3832C684.376 62.8333 685.634 61.5765 687.183 61.5765C688.733 61.5765 689.99 62.8333 689.99 64.3832Z" />
          <path d="M696.832 64.3832C696.832 65.9331 695.575 67.1899 694.026 67.1899C692.476 67.1899 691.218 65.9331 691.218 64.3832C691.218 62.8333 692.476 61.5765 694.026 61.5765C695.575 61.5765 696.832 62.8333 696.832 64.3832Z" />
          <path d="M696.832 71.2255C696.832 72.7754 695.575 74.0322 694.026 74.0322C692.476 74.0322 691.218 72.7754 691.218 71.2255C691.218 69.6756 692.476 68.4188 694.026 68.4188C695.575 68.4188 696.832 69.6756 696.832 71.2255Z" />
          <path d="M696.832 78.0673C696.832 79.6172 695.575 80.874 694.026 80.874C692.476 80.874 691.218 79.6172 691.218 78.0673C691.218 76.5173 692.476 75.2606 694.026 75.2606C695.575 75.2606 696.832 76.5173 696.832 78.0673Z" />
          <path d="M696.832 84.9091C696.832 86.459 695.575 87.7158 694.026 87.7158C692.476 87.7158 691.218 86.459 691.218 84.9091C691.218 83.3591 692.476 82.1024 694.026 82.1024C695.575 82.1024 696.832 83.3591 696.832 84.9091Z" />
          <path d="M696.832 91.7506C696.832 93.3006 695.575 94.5574 694.026 94.5574C692.476 94.5574 691.218 93.3006 691.218 91.7506C691.218 90.2007 692.476 88.9439 694.026 88.9439C695.575 88.9439 696.832 90.2007 696.832 91.7506Z" />
          <path d="M689.99 91.7506C689.99 93.3006 688.733 94.5574 687.183 94.5574C685.634 94.5574 684.376 93.3006 684.376 91.7506C684.376 90.2007 685.634 88.9439 687.183 88.9439C688.733 88.9439 689.99 90.2007 689.99 91.7506Z" />
          <path d="M689.99 84.9091C689.99 86.459 688.733 87.7158 687.183 87.7158C685.634 87.7158 684.376 86.459 684.376 84.9091C684.376 83.3591 685.634 82.1024 687.183 82.1024C688.733 82.1024 689.99 83.3591 689.99 84.9091Z" />
          <path d="M689.99 78.0673C689.99 79.6172 688.733 80.874 687.183 80.874C685.634 80.874 684.376 79.6172 684.376 78.0673C684.376 76.5173 685.634 75.2606 687.183 75.2606C688.733 75.2606 689.99 76.5173 689.99 78.0673Z" />
          <path d="M689.99 71.2255C689.99 72.7754 688.733 74.0322 687.183 74.0322C685.634 74.0322 684.376 72.7754 684.376 71.2255C684.376 69.6756 685.634 68.4188 687.183 68.4188C688.733 68.4188 689.99 69.6756 689.99 71.2255Z" />
          <path d="M683.148 71.2255C683.148 72.7754 681.892 74.0322 680.342 74.0322C678.792 74.0322 677.534 72.7754 677.534 71.2255C677.534 69.6756 678.792 68.4188 680.342 68.4188C681.892 68.4188 683.148 69.6756 683.148 71.2255Z" />
          <path d="M683.148 64.3832C683.148 65.9331 681.892 67.1899 680.342 67.1899C678.792 67.1899 677.534 65.9331 677.534 64.3832C677.534 62.8333 678.792 61.5765 680.342 61.5765C681.892 61.5765 683.148 62.8333 683.148 64.3832Z" />
          <path d="M683.148 57.5417C683.148 59.0916 681.892 60.3484 680.342 60.3484C678.792 60.3484 677.534 59.0916 677.534 57.5417C677.534 55.9917 678.792 54.7349 680.342 54.7349C681.892 54.7349 683.148 55.9917 683.148 57.5417Z" />
          <path d="M676.306 64.3832C676.306 65.9331 675.05 67.1899 673.5 67.1899C671.95 67.1899 670.693 65.9331 670.693 64.3832C670.693 62.8333 671.95 61.5765 673.5 61.5765C675.05 61.5765 676.306 62.8333 676.306 64.3832Z" />
          <path d="M676.306 71.2255C676.306 72.7754 675.05 74.0322 673.5 74.0322C671.95 74.0322 670.693 72.7754 670.693 71.2255C670.693 69.6756 671.95 68.4188 673.5 68.4188C675.05 68.4188 676.306 69.6756 676.306 71.2255Z" />
          <path d="M676.306 78.0673C676.306 79.6172 675.05 80.874 673.5 80.874C671.95 80.874 670.693 79.6172 670.693 78.0673C670.693 76.5173 671.95 75.2606 673.5 75.2606C675.05 75.2606 676.306 76.5173 676.306 78.0673Z" />
          <path d="M683.148 78.0673C683.148 79.6172 681.892 80.874 680.342 80.874C678.792 80.874 677.534 79.6172 677.534 78.0673C677.534 76.5173 678.792 75.2606 680.342 75.2606C681.892 75.2606 683.148 76.5173 683.148 78.0673Z" />
          <path d="M683.148 84.9091C683.148 86.459 681.892 87.7158 680.342 87.7158C678.792 87.7158 677.534 86.459 677.534 84.9091C677.534 83.3591 678.792 82.1024 680.342 82.1024C681.892 82.1024 683.148 83.3591 683.148 84.9091Z" />
          <path d="M683.148 91.7506C683.148 93.3006 681.892 94.5574 680.342 94.5574C678.792 94.5574 677.534 93.3006 677.534 91.7506C677.534 90.2007 678.792 88.9439 680.342 88.9439C681.892 88.9439 683.148 90.2007 683.148 91.7506Z" />
          <path d="M676.306 91.7506C676.306 93.3006 675.05 94.5574 673.5 94.5574C671.95 94.5574 670.693 93.3006 670.693 91.7506C670.693 90.2007 671.95 88.9439 673.5 88.9439C675.05 88.9439 676.306 90.2007 676.306 91.7506Z" />
          <path d="M676.306 84.9091C676.306 86.459 675.05 87.7158 673.5 87.7158C671.95 87.7158 670.693 86.459 670.693 84.9091C670.693 83.3591 671.95 82.1024 673.5 82.1024C675.05 82.1024 676.306 83.3591 676.306 84.9091Z" />
          <path d="M669.464 84.9091C669.464 86.459 668.208 87.7158 666.658 87.7158C665.108 87.7158 663.851 86.459 663.851 84.9091C663.851 83.3591 665.108 82.1024 666.658 82.1024C668.208 82.1024 669.464 83.3591 669.464 84.9091Z" />
          <path d="M669.464 78.0673C669.464 79.6172 668.208 80.874 666.658 80.874C665.108 80.874 663.851 79.6172 663.851 78.0673C663.851 76.5173 665.108 75.2606 666.658 75.2606C668.208 75.2606 669.464 76.5173 669.464 78.0673Z" />
          <path d="M669.464 71.2255C669.464 72.7754 668.208 74.0322 666.658 74.0322C665.108 74.0322 663.851 72.7754 663.851 71.2255C663.851 69.6756 665.108 68.4188 666.658 68.4188C668.208 68.4188 669.464 69.6756 669.464 71.2255Z" />
          <path d="M662.623 71.2255C662.623 72.7754 661.366 74.0322 659.816 74.0322C658.266 74.0322 657.009 72.7754 657.009 71.2255C657.009 69.6756 658.266 68.4188 659.816 68.4188C661.366 68.4188 662.623 69.6756 662.623 71.2255Z" />
          <path d="M655.78 71.2255C655.78 72.7754 654.524 74.0322 652.974 74.0322C651.424 74.0322 650.167 72.7754 650.167 71.2255C650.167 69.6756 651.424 68.4188 652.974 68.4188C654.524 68.4188 655.78 69.6756 655.78 71.2255Z" />
          <path d="M648.939 71.2255C648.939 72.7754 647.682 74.0322 646.132 74.0322C644.583 74.0322 643.325 72.7754 643.325 71.2255C643.325 69.6756 644.583 68.4188 646.132 68.4188C647.682 68.4188 648.939 69.6756 648.939 71.2255Z" />
          <path d="M648.939 64.3832C648.939 65.9331 647.682 67.1899 646.132 67.1899C644.583 67.1899 643.325 65.9331 643.325 64.3832C643.325 62.8333 644.583 61.5765 646.132 61.5765C647.682 61.5765 648.939 62.8333 648.939 64.3832Z" />
          <path d="M642.097 64.3832C642.097 65.9331 640.841 67.1899 639.291 67.1899C637.741 67.1899 636.483 65.9331 636.483 64.3832C636.483 62.8333 637.741 61.5765 639.291 61.5765C640.841 61.5765 642.097 62.8333 642.097 64.3832Z" />
          <path d="M635.255 64.3832C635.255 65.9331 633.999 67.1899 632.449 67.1899C630.899 67.1899 629.642 65.9331 629.642 64.3832C629.642 62.8333 630.899 61.5765 632.449 61.5765C633.999 61.5765 635.255 62.8333 635.255 64.3832Z" />
          <path d="M628.413 64.3832C628.413 65.9331 627.157 67.1899 625.607 67.1899C624.057 67.1899 622.8 65.9331 622.8 64.3832C622.8 62.8333 624.057 61.5765 625.607 61.5765C627.157 61.5765 628.413 62.8333 628.413 64.3832Z" />
          <path d="M621.571 64.3832C621.571 65.9331 620.315 67.1899 618.765 67.1899C617.215 67.1899 615.958 65.9331 615.958 64.3832C615.958 62.8333 617.215 61.5765 618.765 61.5765C620.315 61.5765 621.571 62.8333 621.571 64.3832Z" />
          <path d="M621.571 71.2255C621.571 72.7754 620.315 74.0322 618.765 74.0322C617.215 74.0322 615.958 72.7754 615.958 71.2255C615.958 69.6756 617.215 68.4188 618.765 68.4188C620.315 68.4188 621.571 69.6756 621.571 71.2255Z" />
          <path d="M614.73 71.2255C614.73 72.7754 613.473 74.0322 611.923 74.0322C610.374 74.0322 609.116 72.7754 609.116 71.2255C609.116 69.6756 610.374 68.4188 611.923 68.4188C613.473 68.4188 614.73 69.6756 614.73 71.2255Z" />
          <path d="M607.888 71.2255C607.888 72.7754 606.631 74.0322 605.081 74.0322C603.531 74.0322 602.274 72.7754 602.274 71.2255C602.274 69.6756 603.531 68.4188 605.081 68.4188C606.631 68.4188 607.888 69.6756 607.888 71.2255Z" />
          <path d="M601.046 71.2255C601.046 72.7754 599.79 74.0322 598.24 74.0322C596.69 74.0322 595.432 72.7754 595.432 71.2255C595.432 69.6756 596.69 68.4188 598.24 68.4188C599.79 68.4188 601.046 69.6756 601.046 71.2255Z" />
          <path d="M594.204 71.2255C594.204 72.7754 592.948 74.0322 591.398 74.0322C589.848 74.0322 588.591 72.7754 588.591 71.2255C588.591 69.6756 589.848 68.4188 591.398 68.4188C592.948 68.4188 594.204 69.6756 594.204 71.2255Z" />
          <path d="M594.204 64.3832C594.204 65.9331 592.948 67.1899 591.398 67.1899C589.848 67.1899 588.591 65.9331 588.591 64.3832C588.591 62.8333 589.848 61.5765 591.398 61.5765C592.948 61.5765 594.204 62.8333 594.204 64.3832Z" />
          <path d="M594.204 57.5417C594.204 59.0916 592.948 60.3484 591.398 60.3484C589.848 60.3484 588.591 59.0916 588.591 57.5417C588.591 55.9917 589.848 54.7349 591.398 54.7349C592.948 54.7349 594.204 55.9917 594.204 57.5417Z" />
          <path d="M587.362 57.5417C587.362 59.0916 586.106 60.3484 584.556 60.3484C583.006 60.3484 581.749 59.0916 581.749 57.5417C581.749 55.9917 583.006 54.7349 584.556 54.7349C586.106 54.7349 587.362 55.9917 587.362 57.5417Z" />
          <path d="M580.52 57.5417C580.52 59.0916 579.264 60.3484 577.714 60.3484C576.164 60.3484 574.907 59.0916 574.907 57.5417C574.907 55.9917 576.164 54.7349 577.714 54.7349C579.264 54.7349 580.52 55.9917 580.52 57.5417Z" />
          <path d="M580.52 64.3832C580.52 65.9331 579.264 67.1899 577.714 67.1899C576.164 67.1899 574.907 65.9331 574.907 64.3832C574.907 62.8333 576.164 61.5765 577.714 61.5765C579.264 61.5765 580.52 62.8333 580.52 64.3832Z" />
          <path d="M587.362 64.3832C587.362 65.9331 586.106 67.1899 584.556 67.1899C583.006 67.1899 581.749 65.9331 581.749 64.3832C581.749 62.8333 583.006 61.5765 584.556 61.5765C586.106 61.5765 587.362 62.8333 587.362 64.3832Z" />
          <path d="M587.362 71.2255C587.362 72.7754 586.106 74.0322 584.556 74.0322C583.006 74.0322 581.749 72.7754 581.749 71.2255C581.749 69.6756 583.006 68.4188 584.556 68.4188C586.106 68.4188 587.362 69.6756 587.362 71.2255Z" />
          <path d="M580.52 71.2255C580.52 72.7754 579.264 74.0322 577.714 74.0322C576.164 74.0322 574.907 72.7754 574.907 71.2255C574.907 69.6756 576.164 68.4188 577.714 68.4188C579.264 68.4188 580.52 69.6756 580.52 71.2255Z" />
          <path d="M587.362 78.0673C587.362 79.6172 586.106 80.874 584.556 80.874C583.006 80.874 581.749 79.6172 581.749 78.0673C581.749 76.5173 583.006 75.2606 584.556 75.2606C586.106 75.2606 587.362 76.5173 587.362 78.0673Z" />
          <path d="M587.362 84.9091C587.362 86.459 586.106 87.7158 584.556 87.7158C583.006 87.7158 581.749 86.459 581.749 84.9091C581.749 83.3591 583.006 82.1024 584.556 82.1024C586.106 82.1024 587.362 83.3591 587.362 84.9091Z" />
          <path d="M587.362 91.7506C587.362 93.3006 586.106 94.5574 584.556 94.5574C583.006 94.5574 581.749 93.3006 581.749 91.7506C581.749 90.2007 583.006 88.9439 584.556 88.9439C586.106 88.9439 587.362 90.2007 587.362 91.7506Z" />
          <path d="M587.362 98.5927C587.362 100.143 586.106 101.399 584.556 101.399C583.006 101.399 581.749 100.143 581.749 98.5927C581.749 97.0427 583.006 95.7859 584.556 95.7859C586.106 95.7859 587.362 97.0427 587.362 98.5927Z" />
          <path d="M580.52 98.5927C580.52 100.143 579.264 101.399 577.714 101.399C576.164 101.399 574.907 100.143 574.907 98.5927C574.907 97.0427 576.164 95.7859 577.714 95.7859C579.264 95.7859 580.52 97.0427 580.52 98.5927Z" />
          <path d="M580.52 105.435C580.52 106.985 579.264 108.241 577.714 108.241C576.164 108.241 574.907 106.985 574.907 105.435C574.907 103.885 576.164 102.628 577.714 102.628C579.264 102.628 580.52 103.885 580.52 105.435Z" />
          <path d="M573.678 105.435C573.678 106.985 572.422 108.241 570.872 108.241C569.322 108.241 568.065 106.985 568.065 105.435C568.065 103.885 569.322 102.628 570.872 102.628C572.422 102.628 573.678 103.885 573.678 105.435Z" />
          <path d="M573.678 112.277C573.678 113.826 572.422 115.083 570.872 115.083C569.322 115.083 568.065 113.826 568.065 112.277C568.065 110.727 569.322 109.47 570.872 109.47C572.422 109.47 573.678 110.727 573.678 112.277Z" />
          <path d="M573.678 119.118C573.678 120.668 572.422 121.925 570.872 121.925C569.322 121.925 568.065 120.668 568.065 119.118C568.065 117.568 569.322 116.312 570.872 116.312C572.422 116.312 573.678 117.568 573.678 119.118Z" />
          <path d="M566.837 119.118C566.837 120.668 565.58 121.925 564.03 121.925C562.48 121.925 561.223 120.668 561.223 119.118C561.223 117.568 562.48 116.312 564.03 116.312C565.58 116.312 566.837 117.568 566.837 119.118Z" />
          <path d="M566.837 125.96C566.837 127.51 565.58 128.767 564.03 128.767C562.48 128.767 561.223 127.51 561.223 125.96C561.223 124.41 562.48 123.153 564.03 123.153C565.58 123.153 566.837 124.41 566.837 125.96Z" />
          <path d="M566.837 132.802C566.837 134.352 565.58 135.608 564.03 135.608C562.48 135.608 561.223 134.352 561.223 132.802C561.223 131.252 562.48 129.995 564.03 129.995C565.58 129.995 566.837 131.252 566.837 132.802Z" />
          <path d="M566.837 139.644C566.837 141.194 565.58 142.451 564.03 142.451C562.48 142.451 561.223 141.194 561.223 139.644C561.223 138.094 562.48 136.837 564.03 136.837C565.58 136.837 566.837 138.094 566.837 139.644Z" />
          <path d="M566.837 146.486C566.837 148.036 565.58 149.292 564.03 149.292C562.48 149.292 561.223 148.036 561.223 146.486C561.223 144.936 562.48 143.679 564.03 143.679C565.58 143.679 566.837 144.936 566.837 146.486Z" />
          <path d="M566.837 153.328C566.837 154.877 565.58 156.134 564.03 156.134C562.48 156.134 561.223 154.877 561.223 153.328C561.223 151.778 562.48 150.521 564.03 150.521C565.58 150.521 566.837 151.778 566.837 153.328Z" />
          <path d="M573.678 153.328C573.678 154.877 572.422 156.134 570.872 156.134C569.322 156.134 568.065 154.877 568.065 153.328C568.065 151.778 569.322 150.521 570.872 150.521C572.422 150.521 573.678 151.778 573.678 153.328Z" />
          <path d="M580.52 153.328C580.52 154.877 579.264 156.134 577.714 156.134C576.164 156.134 574.907 154.877 574.907 153.328C574.907 151.778 576.164 150.521 577.714 150.521C579.264 150.521 580.52 151.778 580.52 153.328Z" />
          <path d="M587.362 153.328C587.362 154.877 586.106 156.134 584.556 156.134C583.006 156.134 581.749 154.877 581.749 153.328C581.749 151.778 583.006 150.521 584.556 150.521C586.106 150.521 587.362 151.778 587.362 153.328Z" />
          <path d="M594.204 153.328C594.204 154.877 592.948 156.134 591.398 156.134C589.848 156.134 588.591 154.877 588.591 153.328C588.591 151.778 589.848 150.521 591.398 150.521C592.948 150.521 594.204 151.778 594.204 153.328Z" />
          <path d="M594.204 146.486C594.204 148.036 592.948 149.292 591.398 149.292C589.848 149.292 588.591 148.036 588.591 146.486C588.591 144.936 589.848 143.679 591.398 143.679C592.948 143.679 594.204 144.936 594.204 146.486Z" />
          <path d="M594.204 139.644C594.204 141.194 592.948 142.451 591.398 142.451C589.848 142.451 588.591 141.194 588.591 139.644C588.591 138.094 589.848 136.837 591.398 136.837C592.948 136.837 594.204 138.094 594.204 139.644Z" />
          <path d="M594.204 132.802C594.204 134.352 592.948 135.608 591.398 135.608C589.848 135.608 588.591 134.352 588.591 132.802C588.591 131.252 589.848 129.995 591.398 129.995C592.948 129.995 594.204 131.252 594.204 132.802Z" />
          <path d="M594.204 125.96C594.204 127.51 592.948 128.767 591.398 128.767C589.848 128.767 588.591 127.51 588.591 125.96C588.591 124.41 589.848 123.153 591.398 123.153C592.948 123.153 594.204 124.41 594.204 125.96Z" />
          <path d="M594.204 119.118C594.204 120.668 592.948 121.925 591.398 121.925C589.848 121.925 588.591 120.668 588.591 119.118C588.591 117.568 589.848 116.312 591.398 116.312C592.948 116.312 594.204 117.568 594.204 119.118Z" />
          <path d="M594.204 112.277C594.204 113.826 592.948 115.083 591.398 115.083C589.848 115.083 588.591 113.826 588.591 112.277C588.591 110.727 589.848 109.47 591.398 109.47C592.948 109.47 594.204 110.727 594.204 112.277Z" />
          <path d="M601.046 112.277C601.046 113.826 599.79 115.083 598.24 115.083C596.69 115.083 595.432 113.826 595.432 112.277C595.432 110.727 596.69 109.47 598.24 109.47C599.79 109.47 601.046 110.727 601.046 112.277Z" />
          <path d="M607.888 112.277C607.888 113.826 606.631 115.083 605.081 115.083C603.531 115.083 602.274 113.826 602.274 112.277C602.274 110.727 603.531 109.47 605.081 109.47C606.631 109.47 607.888 110.727 607.888 112.277Z" />
          <path d="M614.73 112.277C614.73 113.826 613.473 115.083 611.923 115.083C610.374 115.083 609.116 113.826 609.116 112.277C609.116 110.727 610.374 109.47 611.923 109.47C613.473 109.47 614.73 110.727 614.73 112.277Z" />
          <path d="M621.571 112.277C621.571 113.826 620.315 115.083 618.765 115.083C617.215 115.083 615.958 113.826 615.958 112.277C615.958 110.727 617.215 109.47 618.765 109.47C620.315 109.47 621.571 110.727 621.571 112.277Z" />
          <path d="M628.413 112.277C628.413 113.826 627.157 115.083 625.607 115.083C624.057 115.083 622.8 113.826 622.8 112.277C622.8 110.727 624.057 109.47 625.607 109.47C627.157 109.47 628.413 110.727 628.413 112.277Z" />
          <path d="M635.255 112.277C635.255 113.826 633.999 115.083 632.449 115.083C630.899 115.083 629.642 113.826 629.642 112.277C629.642 110.727 630.899 109.47 632.449 109.47C633.999 109.47 635.255 110.727 635.255 112.277Z" />
          <path d="M642.097 112.277C642.097 113.826 640.841 115.083 639.291 115.083C637.741 115.083 636.483 113.826 636.483 112.277C636.483 110.727 637.741 109.47 639.291 109.47C640.841 109.47 642.097 110.727 642.097 112.277Z" />
          <path d="M648.939 112.277C648.939 113.826 647.682 115.083 646.132 115.083C644.583 115.083 643.325 113.826 643.325 112.277C643.325 110.727 644.583 109.47 646.132 109.47C647.682 109.47 648.939 110.727 648.939 112.277Z" />
          <path d="M655.78 112.277C655.78 113.826 654.524 115.083 652.974 115.083C651.424 115.083 650.167 113.826 650.167 112.277C650.167 110.727 651.424 109.47 652.974 109.47C654.524 109.47 655.78 110.727 655.78 112.277Z" />
          <path d="M662.623 112.277C662.623 113.826 661.366 115.083 659.816 115.083C658.266 115.083 657.009 113.826 657.009 112.277C657.009 110.727 658.266 109.47 659.816 109.47C661.366 109.47 662.623 110.727 662.623 112.277Z" />
          <path d="M662.623 119.118C662.623 120.668 661.366 121.925 659.816 121.925C658.266 121.925 657.009 120.668 657.009 119.118C657.009 117.568 658.266 116.312 659.816 116.312C661.366 116.312 662.623 117.568 662.623 119.118Z" />
          <path d="M662.623 125.96C662.623 127.51 661.366 128.767 659.816 128.767C658.266 128.767 657.009 127.51 657.009 125.96C657.009 124.41 658.266 123.153 659.816 123.153C661.366 123.153 662.623 124.41 662.623 125.96Z" />
          <path d="M662.623 132.802C662.623 134.352 661.366 135.608 659.816 135.608C658.266 135.608 657.009 134.352 657.009 132.802C657.009 131.252 658.266 129.995 659.816 129.995C661.366 129.995 662.623 131.252 662.623 132.802Z" />
          <path d="M669.464 132.802C669.464 134.352 668.208 135.608 666.658 135.608C665.108 135.608 663.851 134.352 663.851 132.802C663.851 131.252 665.108 129.995 666.658 129.995C668.208 129.995 669.464 131.252 669.464 132.802Z" />
          <path d="M676.306 132.802C676.306 134.352 675.05 135.608 673.5 135.608C671.95 135.608 670.693 134.352 670.693 132.802C670.693 131.252 671.95 129.995 673.5 129.995C675.05 129.995 676.306 131.252 676.306 132.802Z" />
          <path d="M676.306 125.96C676.306 127.51 675.05 128.767 673.5 128.767C671.95 128.767 670.693 127.51 670.693 125.96C670.693 124.41 671.95 123.153 673.5 123.153C675.05 123.153 676.306 124.41 676.306 125.96Z" />
          <path d="M669.464 125.96C669.464 127.51 668.208 128.767 666.658 128.767C665.108 128.767 663.851 127.51 663.851 125.96C663.851 124.41 665.108 123.153 666.658 123.153C668.208 123.153 669.464 124.41 669.464 125.96Z" />
          <path d="M669.464 119.118C669.464 120.668 668.208 121.925 666.658 121.925C665.108 121.925 663.851 120.668 663.851 119.118C663.851 117.568 665.108 116.312 666.658 116.312C668.208 116.312 669.464 117.568 669.464 119.118Z" />
          <path d="M676.306 119.118C676.306 120.668 675.05 121.925 673.5 121.925C671.95 121.925 670.693 120.668 670.693 119.118C670.693 117.568 671.95 116.312 673.5 116.312C675.05 116.312 676.306 117.568 676.306 119.118Z" />
          <path d="M676.306 112.277C676.306 113.826 675.05 115.083 673.5 115.083C671.95 115.083 670.693 113.826 670.693 112.277C670.693 110.727 671.95 109.47 673.5 109.47C675.05 109.47 676.306 110.727 676.306 112.277Z" />
          <path d="M669.464 112.277C669.464 113.826 668.208 115.083 666.658 115.083C665.108 115.083 663.851 113.826 663.851 112.277C663.851 110.727 665.108 109.47 666.658 109.47C668.208 109.47 669.464 110.727 669.464 112.277Z" />
          <path d="M669.464 105.435C669.464 106.985 668.208 108.241 666.658 108.241C665.108 108.241 663.851 106.985 663.851 105.435C663.851 103.885 665.108 102.628 666.658 102.628C668.208 102.628 669.464 103.885 669.464 105.435Z" />
          <path d="M676.306 105.435C676.306 106.985 675.05 108.241 673.5 108.241C671.95 108.241 670.693 106.985 670.693 105.435C670.693 103.885 671.95 102.628 673.5 102.628C675.05 102.628 676.306 103.885 676.306 105.435Z" />
          <path d="M676.306 98.5927C676.306 100.143 675.05 101.399 673.5 101.399C671.95 101.399 670.693 100.143 670.693 98.5927C670.693 97.0427 671.95 95.7859 673.5 95.7859C675.05 95.7859 676.306 97.0427 676.306 98.5927Z" />
          <path d="M669.464 98.5927C669.464 100.143 668.208 101.399 666.658 101.399C665.108 101.399 663.851 100.143 663.851 98.5927C663.851 97.0427 665.108 95.7859 666.658 95.7859C668.208 95.7859 669.464 97.0427 669.464 98.5927Z" />
          <path d="M669.464 91.7506C669.464 93.3006 668.208 94.5574 666.658 94.5574C665.108 94.5574 663.851 93.3006 663.851 91.7506C663.851 90.2007 665.108 88.9439 666.658 88.9439C668.208 88.9439 669.464 90.2007 669.464 91.7506Z" />
          <path d="M662.623 91.7506C662.623 93.3006 661.366 94.5574 659.816 94.5574C658.266 94.5574 657.009 93.3006 657.009 91.7506C657.009 90.2007 658.266 88.9439 659.816 88.9439C661.366 88.9439 662.623 90.2007 662.623 91.7506Z" />
          <path d="M662.623 84.9091C662.623 86.459 661.366 87.7158 659.816 87.7158C658.266 87.7158 657.009 86.459 657.009 84.9091C657.009 83.3591 658.266 82.1024 659.816 82.1024C661.366 82.1024 662.623 83.3591 662.623 84.9091Z" />
          <path d="M662.623 78.0673C662.623 79.6172 661.366 80.874 659.816 80.874C658.266 80.874 657.009 79.6172 657.009 78.0673C657.009 76.5173 658.266 75.2606 659.816 75.2606C661.366 75.2606 662.623 76.5173 662.623 78.0673Z" />
          <path d="M655.78 78.0673C655.78 79.6172 654.524 80.874 652.974 80.874C651.424 80.874 650.167 79.6172 650.167 78.0673C650.167 76.5173 651.424 75.2606 652.974 75.2606C654.524 75.2606 655.78 76.5173 655.78 78.0673Z" />
          <path d="M648.939 78.0673C648.939 79.6172 647.682 80.874 646.132 80.874C644.583 80.874 643.325 79.6172 643.325 78.0673C643.325 76.5173 644.583 75.2606 646.132 75.2606C647.682 75.2606 648.939 76.5173 648.939 78.0673Z" />
          <path d="M642.097 78.0673C642.097 79.6172 640.841 80.874 639.291 80.874C637.741 80.874 636.483 79.6172 636.483 78.0673C636.483 76.5173 637.741 75.2606 639.291 75.2606C640.841 75.2606 642.097 76.5173 642.097 78.0673Z" />
          <path d="M642.097 71.2255C642.097 72.7754 640.841 74.0322 639.291 74.0322C637.741 74.0322 636.483 72.7754 636.483 71.2255C636.483 69.6756 637.741 68.4188 639.291 68.4188C640.841 68.4188 642.097 69.6756 642.097 71.2255Z" />
          <path d="M635.255 71.2255C635.255 72.7754 633.999 74.0322 632.449 74.0322C630.899 74.0322 629.642 72.7754 629.642 71.2255C629.642 69.6756 630.899 68.4188 632.449 68.4188C633.999 68.4188 635.255 69.6756 635.255 71.2255Z" />
          <path d="M628.413 71.2255C628.413 72.7754 627.157 74.0322 625.607 74.0322C624.057 74.0322 622.8 72.7754 622.8 71.2255C622.8 69.6756 624.057 68.4188 625.607 68.4188C627.157 68.4188 628.413 69.6756 628.413 71.2255Z" />
          <path d="M628.413 78.0673C628.413 79.6172 627.157 80.874 625.607 80.874C624.057 80.874 622.8 79.6172 622.8 78.0673C622.8 76.5173 624.057 75.2606 625.607 75.2606C627.157 75.2606 628.413 76.5173 628.413 78.0673Z" />
          <path d="M635.255 78.0673C635.255 79.6172 633.999 80.874 632.449 80.874C630.899 80.874 629.642 79.6172 629.642 78.0673C629.642 76.5173 630.899 75.2606 632.449 75.2606C633.999 75.2606 635.255 76.5173 635.255 78.0673Z" />
          <path d="M635.255 84.9091C635.255 86.459 633.999 87.7158 632.449 87.7158C630.899 87.7158 629.642 86.459 629.642 84.9091C629.642 83.3591 630.899 82.1024 632.449 82.1024C633.999 82.1024 635.255 83.3591 635.255 84.9091Z" />
          <path d="M642.097 84.9091C642.097 86.459 640.841 87.7158 639.291 87.7158C637.741 87.7158 636.483 86.459 636.483 84.9091C636.483 83.3591 637.741 82.1024 639.291 82.1024C640.841 82.1024 642.097 83.3591 642.097 84.9091Z" />
          <path d="M648.939 84.9091C648.939 86.459 647.682 87.7158 646.132 87.7158C644.583 87.7158 643.325 86.459 643.325 84.9091C643.325 83.3591 644.583 82.1024 646.132 82.1024C647.682 82.1024 648.939 83.3591 648.939 84.9091Z" />
          <path d="M655.78 84.9091C655.78 86.459 654.524 87.7158 652.974 87.7158C651.424 87.7158 650.167 86.459 650.167 84.9091C650.167 83.3591 651.424 82.1024 652.974 82.1024C654.524 82.1024 655.78 83.3591 655.78 84.9091Z" />
          <path d="M655.78 91.7506C655.78 93.3006 654.524 94.5574 652.974 94.5574C651.424 94.5574 650.167 93.3006 650.167 91.7506C650.167 90.2007 651.424 88.9439 652.974 88.9439C654.524 88.9439 655.78 90.2007 655.78 91.7506Z" />
          <path d="M655.78 98.5927C655.78 100.143 654.524 101.399 652.974 101.399C651.424 101.399 650.167 100.143 650.167 98.5927C650.167 97.0427 651.424 95.7859 652.974 95.7859C654.524 95.7859 655.78 97.0427 655.78 98.5927Z" />
          <path d="M662.623 98.5927C662.623 100.143 661.366 101.399 659.816 101.399C658.266 101.399 657.009 100.143 657.009 98.5927C657.009 97.0427 658.266 95.7859 659.816 95.7859C661.366 95.7859 662.623 97.0427 662.623 98.5927Z" />
          <path d="M662.623 105.435C662.623 106.985 661.366 108.241 659.816 108.241C658.266 108.241 657.009 106.985 657.009 105.435C657.009 103.885 658.266 102.628 659.816 102.628C661.366 102.628 662.623 103.885 662.623 105.435Z" />
          <path d="M655.78 105.435C655.78 106.985 654.524 108.241 652.974 108.241C651.424 108.241 650.167 106.985 650.167 105.435C650.167 103.885 651.424 102.628 652.974 102.628C654.524 102.628 655.78 103.885 655.78 105.435Z" />
          <path d="M648.939 105.435C648.939 106.985 647.682 108.241 646.132 108.241C644.583 108.241 643.325 106.985 643.325 105.435C643.325 103.885 644.583 102.628 646.132 102.628C647.682 102.628 648.939 103.885 648.939 105.435Z" />
          <path d="M648.939 98.5927C648.939 100.143 647.682 101.399 646.132 101.399C644.583 101.399 643.325 100.143 643.325 98.5927C643.325 97.0427 644.583 95.7859 646.132 95.7859C647.682 95.7859 648.939 97.0427 648.939 98.5927Z" />
          <path d="M648.939 91.7506C648.939 93.3006 647.682 94.5574 646.132 94.5574C644.583 94.5574 643.325 93.3006 643.325 91.7506C643.325 90.2007 644.583 88.9439 646.132 88.9439C647.682 88.9439 648.939 90.2007 648.939 91.7506Z" />
          <path d="M642.097 91.7506C642.097 93.3006 640.841 94.5574 639.291 94.5574C637.741 94.5574 636.483 93.3006 636.483 91.7506C636.483 90.2007 637.741 88.9439 639.291 88.9439C640.841 88.9439 642.097 90.2007 642.097 91.7506Z" />
          <path d="M642.097 98.5927C642.097 100.143 640.841 101.399 639.291 101.399C637.741 101.399 636.483 100.143 636.483 98.5927C636.483 97.0427 637.741 95.7859 639.291 95.7859C640.841 95.7859 642.097 97.0427 642.097 98.5927Z" />
          <path d="M642.097 105.435C642.097 106.985 640.841 108.241 639.291 108.241C637.741 108.241 636.483 106.985 636.483 105.435C636.483 103.885 637.741 102.628 639.291 102.628C640.841 102.628 642.097 103.885 642.097 105.435Z" />
          <path d="M635.255 105.435C635.255 106.985 633.999 108.241 632.449 108.241C630.899 108.241 629.642 106.985 629.642 105.435C629.642 103.885 630.899 102.628 632.449 102.628C633.999 102.628 635.255 103.885 635.255 105.435Z" />
          <path d="M635.255 98.5927C635.255 100.143 633.999 101.399 632.449 101.399C630.899 101.399 629.642 100.143 629.642 98.5927C629.642 97.0427 630.899 95.7859 632.449 95.7859C633.999 95.7859 635.255 97.0427 635.255 98.5927Z" />
          <path d="M635.255 91.7506C635.255 93.3006 633.999 94.5574 632.449 94.5574C630.899 94.5574 629.642 93.3006 629.642 91.7506C629.642 90.2007 630.899 88.9439 632.449 88.9439C633.999 88.9439 635.255 90.2007 635.255 91.7506Z" />
          <path d="M628.413 91.7506C628.413 93.3006 627.157 94.5574 625.607 94.5574C624.057 94.5574 622.8 93.3006 622.8 91.7506C622.8 90.2007 624.057 88.9439 625.607 88.9439C627.157 88.9439 628.413 90.2007 628.413 91.7506Z" />
          <path d="M628.413 84.9091C628.413 86.459 627.157 87.7158 625.607 87.7158C624.057 87.7158 622.8 86.459 622.8 84.9091C622.8 83.3591 624.057 82.1024 625.607 82.1024C627.157 82.1024 628.413 83.3591 628.413 84.9091Z" />
          <path d="M621.571 84.9091C621.571 86.459 620.315 87.7158 618.765 87.7158C617.215 87.7158 615.958 86.459 615.958 84.9091C615.958 83.3591 617.215 82.1024 618.765 82.1024C620.315 82.1024 621.571 83.3591 621.571 84.9091Z" />
          <path d="M621.571 78.0673C621.571 79.6172 620.315 80.874 618.765 80.874C617.215 80.874 615.958 79.6172 615.958 78.0673C615.958 76.5173 617.215 75.2606 618.765 75.2606C620.315 75.2606 621.571 76.5173 621.571 78.0673Z" />
          <path d="M614.73 78.0673C614.73 79.6172 613.473 80.874 611.923 80.874C610.374 80.874 609.116 79.6172 609.116 78.0673C609.116 76.5173 610.374 75.2606 611.923 75.2606C613.473 75.2606 614.73 76.5173 614.73 78.0673Z" />
          <path d="M607.888 78.0673C607.888 79.6172 606.631 80.874 605.081 80.874C603.531 80.874 602.274 79.6172 602.274 78.0673C602.274 76.5173 603.531 75.2606 605.081 75.2606C606.631 75.2606 607.888 76.5173 607.888 78.0673Z" />
          <path d="M601.046 78.0673C601.046 79.6172 599.79 80.874 598.24 80.874C596.69 80.874 595.432 79.6172 595.432 78.0673C595.432 76.5173 596.69 75.2606 598.24 75.2606C599.79 75.2606 601.046 76.5173 601.046 78.0673Z" />
          <path d="M594.204 78.0673C594.204 79.6172 592.948 80.874 591.398 80.874C589.848 80.874 588.591 79.6172 588.591 78.0673C588.591 76.5173 589.848 75.2606 591.398 75.2606C592.948 75.2606 594.204 76.5173 594.204 78.0673Z" />
          <path d="M594.204 84.9091C594.204 86.459 592.948 87.7158 591.398 87.7158C589.848 87.7158 588.591 86.459 588.591 84.9091C588.591 83.3591 589.848 82.1024 591.398 82.1024C592.948 82.1024 594.204 83.3591 594.204 84.9091Z" />
          <path d="M601.046 84.9091C601.046 86.459 599.79 87.7158 598.24 87.7158C596.69 87.7158 595.432 86.459 595.432 84.9091C595.432 83.3591 596.69 82.1024 598.24 82.1024C599.79 82.1024 601.046 83.3591 601.046 84.9091Z" />
          <path d="M607.888 84.9091C607.888 86.459 606.631 87.7158 605.081 87.7158C603.531 87.7158 602.274 86.459 602.274 84.9091C602.274 83.3591 603.531 82.1024 605.081 82.1024C606.631 82.1024 607.888 83.3591 607.888 84.9091Z" />
          <path d="M614.73 84.9091C614.73 86.459 613.473 87.7158 611.923 87.7158C610.374 87.7158 609.116 86.459 609.116 84.9091C609.116 83.3591 610.374 82.1024 611.923 82.1024C613.473 82.1024 614.73 83.3591 614.73 84.9091Z" />
          <path d="M614.73 91.7506C614.73 93.3006 613.473 94.5574 611.923 94.5574C610.374 94.5574 609.116 93.3006 609.116 91.7506C609.116 90.2007 610.374 88.9439 611.923 88.9439C613.473 88.9439 614.73 90.2007 614.73 91.7506Z" />
          <path d="M621.571 91.7506C621.571 93.3006 620.315 94.5574 618.765 94.5574C617.215 94.5574 615.958 93.3006 615.958 91.7506C615.958 90.2007 617.215 88.9439 618.765 88.9439C620.315 88.9439 621.571 90.2007 621.571 91.7506Z" />
          <path d="M621.571 98.5927C621.571 100.143 620.315 101.399 618.765 101.399C617.215 101.399 615.958 100.143 615.958 98.5927C615.958 97.0427 617.215 95.7859 618.765 95.7859C620.315 95.7859 621.571 97.0427 621.571 98.5927Z" />
          <path d="M628.413 98.5927C628.413 100.143 627.157 101.399 625.607 101.399C624.057 101.399 622.8 100.143 622.8 98.5927C622.8 97.0427 624.057 95.7859 625.607 95.7859C627.157 95.7859 628.413 97.0427 628.413 98.5927Z" />
          <path d="M628.413 105.435C628.413 106.985 627.157 108.241 625.607 108.241C624.057 108.241 622.8 106.985 622.8 105.435C622.8 103.885 624.057 102.628 625.607 102.628C627.157 102.628 628.413 103.885 628.413 105.435Z" />
          <path d="M621.571 105.435C621.571 106.985 620.315 108.241 618.765 108.241C617.215 108.241 615.958 106.985 615.958 105.435C615.958 103.885 617.215 102.628 618.765 102.628C620.315 102.628 621.571 103.885 621.571 105.435Z" />
          <path d="M614.73 105.435C614.73 106.985 613.473 108.241 611.923 108.241C610.374 108.241 609.116 106.985 609.116 105.435C609.116 103.885 610.374 102.628 611.923 102.628C613.473 102.628 614.73 103.885 614.73 105.435Z" />
          <path d="M607.888 105.435C607.888 106.985 606.631 108.241 605.081 108.241C603.531 108.241 602.274 106.985 602.274 105.435C602.274 103.885 603.531 102.628 605.081 102.628C606.631 102.628 607.888 103.885 607.888 105.435Z" />
          <path d="M601.046 105.435C601.046 106.985 599.79 108.241 598.24 108.241C596.69 108.241 595.432 106.985 595.432 105.435C595.432 103.885 596.69 102.628 598.24 102.628C599.79 102.628 601.046 103.885 601.046 105.435Z" />
          <path d="M601.046 98.5927C601.046 100.143 599.79 101.399 598.24 101.399C596.69 101.399 595.432 100.143 595.432 98.5927C595.432 97.0427 596.69 95.7859 598.24 95.7859C599.79 95.7859 601.046 97.0427 601.046 98.5927Z" />
          <path d="M601.046 91.7506C601.046 93.3006 599.79 94.5574 598.24 94.5574C596.69 94.5574 595.432 93.3006 595.432 91.7506C595.432 90.2007 596.69 88.9439 598.24 88.9439C599.79 88.9439 601.046 90.2007 601.046 91.7506Z" />
          <path d="M607.888 91.7506C607.888 93.3006 606.631 94.5574 605.081 94.5574C603.531 94.5574 602.274 93.3006 602.274 91.7506C602.274 90.2007 603.531 88.9439 605.081 88.9439C606.631 88.9439 607.888 90.2007 607.888 91.7506Z" />
          <path d="M607.888 98.5927C607.888 100.143 606.631 101.399 605.081 101.399C603.531 101.399 602.274 100.143 602.274 98.5927C602.274 97.0427 603.531 95.7859 605.081 95.7859C606.631 95.7859 607.888 97.0427 607.888 98.5927Z" />
          <path d="M614.73 98.5927C614.73 100.143 613.473 101.399 611.923 101.399C610.374 101.399 609.116 100.143 609.116 98.5927C609.116 97.0427 610.374 95.7859 611.923 95.7859C613.473 95.7859 614.73 97.0427 614.73 98.5927Z" />
          <path d="M594.204 91.7506C594.204 93.3006 592.948 94.5574 591.398 94.5574C589.848 94.5574 588.591 93.3006 588.591 91.7506C588.591 90.2007 589.848 88.9439 591.398 88.9439C592.948 88.9439 594.204 90.2007 594.204 91.7506Z" />
          <path d="M594.204 98.5927C594.204 100.143 592.948 101.399 591.398 101.399C589.848 101.399 588.591 100.143 588.591 98.5927C588.591 97.0427 589.848 95.7859 591.398 95.7859C592.948 95.7859 594.204 97.0427 594.204 98.5927Z" />
          <path d="M594.204 105.435C594.204 106.985 592.948 108.241 591.398 108.241C589.848 108.241 588.591 106.985 588.591 105.435C588.591 103.885 589.848 102.628 591.398 102.628C592.948 102.628 594.204 103.885 594.204 105.435Z" />
          <path d="M587.362 105.435C587.362 106.985 586.106 108.241 584.556 108.241C583.006 108.241 581.749 106.985 581.749 105.435C581.749 103.885 583.006 102.628 584.556 102.628C586.106 102.628 587.362 103.885 587.362 105.435Z" />
          <path d="M587.362 112.277C587.362 113.826 586.106 115.083 584.556 115.083C583.006 115.083 581.749 113.826 581.749 112.277C581.749 110.727 583.006 109.47 584.556 109.47C586.106 109.47 587.362 110.727 587.362 112.277Z" />
          <path d="M580.52 112.277C580.52 113.826 579.264 115.083 577.714 115.083C576.164 115.083 574.907 113.826 574.907 112.277C574.907 110.727 576.164 109.47 577.714 109.47C579.264 109.47 580.52 110.727 580.52 112.277Z" />
          <path d="M580.52 119.118C580.52 120.668 579.264 121.925 577.714 121.925C576.164 121.925 574.907 120.668 574.907 119.118C574.907 117.568 576.164 116.312 577.714 116.312C579.264 116.312 580.52 117.568 580.52 119.118Z" />
          <path d="M580.52 125.96C580.52 127.51 579.264 128.767 577.714 128.767C576.164 128.767 574.907 127.51 574.907 125.96C574.907 124.41 576.164 123.153 577.714 123.153C579.264 123.153 580.52 124.41 580.52 125.96Z" />
          <path d="M573.678 125.96C573.678 127.51 572.422 128.767 570.872 128.767C569.322 128.767 568.065 127.51 568.065 125.96C568.065 124.41 569.322 123.153 570.872 123.153C572.422 123.153 573.678 124.41 573.678 125.96Z" />
          <path d="M573.678 132.802C573.678 134.352 572.422 135.608 570.872 135.608C569.322 135.608 568.065 134.352 568.065 132.802C568.065 131.252 569.322 129.995 570.872 129.995C572.422 129.995 573.678 131.252 573.678 132.802Z" />
          <path d="M573.678 139.644C573.678 141.194 572.422 142.451 570.872 142.451C569.322 142.451 568.065 141.194 568.065 139.644C568.065 138.094 569.322 136.837 570.872 136.837C572.422 136.837 573.678 138.094 573.678 139.644Z" />
          <path d="M573.678 146.486C573.678 148.036 572.422 149.292 570.872 149.292C569.322 149.292 568.065 148.036 568.065 146.486C568.065 144.936 569.322 143.679 570.872 143.679C572.422 143.679 573.678 144.936 573.678 146.486Z" />
          <path d="M580.52 146.486C580.52 148.036 579.264 149.292 577.714 149.292C576.164 149.292 574.907 148.036 574.907 146.486C574.907 144.936 576.164 143.679 577.714 143.679C579.264 143.679 580.52 144.936 580.52 146.486Z" />
          <path d="M580.52 139.644C580.52 141.194 579.264 142.451 577.714 142.451C576.164 142.451 574.907 141.194 574.907 139.644C574.907 138.094 576.164 136.837 577.714 136.837C579.264 136.837 580.52 138.094 580.52 139.644Z" />
          <path d="M580.52 132.802C580.52 134.352 579.264 135.608 577.714 135.608C576.164 135.608 574.907 134.352 574.907 132.802C574.907 131.252 576.164 129.995 577.714 129.995C579.264 129.995 580.52 131.252 580.52 132.802Z" />
          <path d="M587.362 132.802C587.362 134.352 586.106 135.608 584.556 135.608C583.006 135.608 581.749 134.352 581.749 132.802C581.749 131.252 583.006 129.995 584.556 129.995C586.106 129.995 587.362 131.252 587.362 132.802Z" />
          <path d="M587.362 125.96C587.362 127.51 586.106 128.767 584.556 128.767C583.006 128.767 581.749 127.51 581.749 125.96C581.749 124.41 583.006 123.153 584.556 123.153C586.106 123.153 587.362 124.41 587.362 125.96Z" />
          <path d="M587.362 119.118C587.362 120.668 586.106 121.925 584.556 121.925C583.006 121.925 581.749 120.668 581.749 119.118C581.749 117.568 583.006 116.312 584.556 116.312C586.106 116.312 587.362 117.568 587.362 119.118Z" />
          <path d="M587.362 139.644C587.362 141.194 586.106 142.451 584.556 142.451C583.006 142.451 581.749 141.194 581.749 139.644C581.749 138.094 583.006 136.837 584.556 136.837C586.106 136.837 587.362 138.094 587.362 139.644Z" />
          <path d="M587.362 146.486C587.362 148.036 586.106 149.292 584.556 149.292C583.006 149.292 581.749 148.036 581.749 146.486C581.749 144.936 583.006 143.679 584.556 143.679C586.106 143.679 587.362 144.936 587.362 146.486Z" />
          <path d="M683.148 132.802C683.148 134.352 681.892 135.608 680.342 135.608C678.792 135.608 677.534 134.352 677.534 132.802C677.534 131.252 678.792 129.995 680.342 129.995C681.892 129.995 683.148 131.252 683.148 132.802Z" />
          <path d="M689.99 132.802C689.99 134.352 688.733 135.608 687.183 135.608C685.634 135.608 684.376 134.352 684.376 132.802C684.376 131.252 685.634 129.995 687.183 129.995C688.733 129.995 689.99 131.252 689.99 132.802Z" />
          <path d="M717.357 64.3832C717.357 65.9331 716.101 67.1899 714.551 67.1899C713.001 67.1899 711.744 65.9331 711.744 64.3832C711.744 62.8333 713.001 61.5765 714.551 61.5765C716.101 61.5765 717.357 62.8333 717.357 64.3832Z" />
          <path d="M717.357 71.2255C717.357 72.7754 716.101 74.0322 714.551 74.0322C713.001 74.0322 711.744 72.7754 711.744 71.2255C711.744 69.6756 713.001 68.4188 714.551 68.4188C716.101 68.4188 717.357 69.6756 717.357 71.2255Z" />
          <path d="M717.357 78.0673C717.357 79.6172 716.101 80.874 714.551 80.874C713.001 80.874 711.744 79.6172 711.744 78.0673C711.744 76.5173 713.001 75.2606 714.551 75.2606C716.101 75.2606 717.357 76.5173 717.357 78.0673Z" />
          <path d="M717.357 84.9091C717.357 86.459 716.101 87.7158 714.551 87.7158C713.001 87.7158 711.744 86.459 711.744 84.9091C711.744 83.3591 713.001 82.1024 714.551 82.1024C716.101 82.1024 717.357 83.3591 717.357 84.9091Z" />
          <path d="M717.357 91.7506C717.357 93.3006 716.101 94.5574 714.551 94.5574C713.001 94.5574 711.744 93.3006 711.744 91.7506C711.744 90.2007 713.001 88.9439 714.551 88.9439C716.101 88.9439 717.357 90.2007 717.357 91.7506Z" />
          <path d="M717.357 98.5927C717.357 100.143 716.101 101.399 714.551 101.399C713.001 101.399 711.744 100.143 711.744 98.5927C711.744 97.0427 713.001 95.7859 714.551 95.7859C716.101 95.7859 717.357 97.0427 717.357 98.5927Z" />
          <path d="M710.515 98.5927C710.515 100.143 709.259 101.399 707.709 101.399C706.159 101.399 704.902 100.143 704.902 98.5927C704.902 97.0427 706.159 95.7859 707.709 95.7859C709.259 95.7859 710.515 97.0427 710.515 98.5927Z" />
          <path d="M703.674 98.5927C703.674 100.143 702.417 101.399 700.867 101.399C699.317 101.399 698.06 100.143 698.06 98.5927C698.06 97.0427 699.317 95.7859 700.867 95.7859C702.417 95.7859 703.674 97.0427 703.674 98.5927Z" />
          <path d="M696.832 98.5927C696.832 100.143 695.575 101.399 694.026 101.399C692.476 101.399 691.218 100.143 691.218 98.5927C691.218 97.0427 692.476 95.7859 694.026 95.7859C695.575 95.7859 696.832 97.0427 696.832 98.5927Z" />
          <path d="M689.99 98.5927C689.99 100.143 688.733 101.399 687.183 101.399C685.634 101.399 684.376 100.143 684.376 98.5927C684.376 97.0427 685.634 95.7859 687.183 95.7859C688.733 95.7859 689.99 97.0427 689.99 98.5927Z" />
          <path d="M683.148 98.5927C683.148 100.143 681.892 101.399 680.342 101.399C678.792 101.399 677.534 100.143 677.534 98.5927C677.534 97.0427 678.792 95.7859 680.342 95.7859C681.892 95.7859 683.148 97.0427 683.148 98.5927Z" />
          <path d="M683.148 105.435C683.148 106.985 681.892 108.241 680.342 108.241C678.792 108.241 677.534 106.985 677.534 105.435C677.534 103.885 678.792 102.628 680.342 102.628C681.892 102.628 683.148 103.885 683.148 105.435Z" />
          <path d="M683.148 112.277C683.148 113.826 681.892 115.083 680.342 115.083C678.792 115.083 677.534 113.826 677.534 112.277C677.534 110.727 678.792 109.47 680.342 109.47C681.892 109.47 683.148 110.727 683.148 112.277Z" />
          <path d="M683.148 119.118C683.148 120.668 681.892 121.925 680.342 121.925C678.792 121.925 677.534 120.668 677.534 119.118C677.534 117.568 678.792 116.312 680.342 116.312C681.892 116.312 683.148 117.568 683.148 119.118Z" />
          <path d="M683.148 125.96C683.148 127.51 681.892 128.767 680.342 128.767C678.792 128.767 677.534 127.51 677.534 125.96C677.534 124.41 678.792 123.153 680.342 123.153C681.892 123.153 683.148 124.41 683.148 125.96Z" />
          <path d="M689.99 125.96C689.99 127.51 688.733 128.767 687.183 128.767C685.634 128.767 684.376 127.51 684.376 125.96C684.376 124.41 685.634 123.153 687.183 123.153C688.733 123.153 689.99 124.41 689.99 125.96Z" />
          <path d="M696.832 125.96C696.832 127.51 695.575 128.767 694.026 128.767C692.476 128.767 691.218 127.51 691.218 125.96C691.218 124.41 692.476 123.153 694.026 123.153C695.575 123.153 696.832 124.41 696.832 125.96Z" />
          <path d="M696.832 132.802C696.832 134.352 695.575 135.608 694.026 135.608C692.476 135.608 691.218 134.352 691.218 132.802C691.218 131.252 692.476 129.995 694.026 129.995C695.575 129.995 696.832 131.252 696.832 132.802Z" />
          <path d="M696.832 139.644C696.832 141.194 695.575 142.451 694.026 142.451C692.476 142.451 691.218 141.194 691.218 139.644C691.218 138.094 692.476 136.837 694.026 136.837C695.575 136.837 696.832 138.094 696.832 139.644Z" />
          <path d="M689.99 139.644C689.99 141.194 688.733 142.451 687.183 142.451C685.634 142.451 684.376 141.194 684.376 139.644C684.376 138.094 685.634 136.837 687.183 136.837C688.733 136.837 689.99 138.094 689.99 139.644Z" />
          <path d="M683.148 139.644C683.148 141.194 681.892 142.451 680.342 142.451C678.792 142.451 677.534 141.194 677.534 139.644C677.534 138.094 678.792 136.837 680.342 136.837C681.892 136.837 683.148 138.094 683.148 139.644Z" />
          <path d="M676.306 139.644C676.306 141.194 675.05 142.451 673.5 142.451C671.95 142.451 670.693 141.194 670.693 139.644C670.693 138.094 671.95 136.837 673.5 136.837C675.05 136.837 676.306 138.094 676.306 139.644Z" />
          <path d="M669.464 139.644C669.464 141.194 668.208 142.451 666.658 142.451C665.108 142.451 663.851 141.194 663.851 139.644C663.851 138.094 665.108 136.837 666.658 136.837C668.208 136.837 669.464 138.094 669.464 139.644Z" />
          <path d="M662.623 139.644C662.623 141.194 661.366 142.451 659.816 142.451C658.266 142.451 657.009 141.194 657.009 139.644C657.009 138.094 658.266 136.837 659.816 136.837C661.366 136.837 662.623 138.094 662.623 139.644Z" />
          <path d="M662.623 146.486C662.623 148.036 661.366 149.292 659.816 149.292C658.266 149.292 657.009 148.036 657.009 146.486C657.009 144.936 658.266 143.679 659.816 143.679C661.366 143.679 662.623 144.936 662.623 146.486Z" />
          <path d="M662.623 153.328C662.623 154.877 661.366 156.134 659.816 156.134C658.266 156.134 657.009 154.877 657.009 153.328C657.009 151.778 658.266 150.521 659.816 150.521C661.366 150.521 662.623 151.778 662.623 153.328Z" />
          <path d="M662.623 160.169C662.623 161.719 661.366 162.976 659.816 162.976C658.266 162.976 657.009 161.719 657.009 160.169C657.009 158.619 658.266 157.363 659.816 157.363C661.366 157.363 662.623 158.619 662.623 160.169Z" />
          <path d="M662.623 167.011C662.623 168.561 661.366 169.818 659.816 169.818C658.266 169.818 657.009 168.561 657.009 167.011C657.009 165.461 658.266 164.204 659.816 164.204C661.366 164.204 662.623 165.461 662.623 167.011Z" />
          <path d="M655.78 167.011C655.78 168.561 654.524 169.818 652.974 169.818C651.424 169.818 650.167 168.561 650.167 167.011C650.167 165.461 651.424 164.204 652.974 164.204C654.524 164.204 655.78 165.461 655.78 167.011Z" />
          <path d="M655.78 173.853C655.78 175.403 654.524 176.66 652.974 176.66C651.424 176.66 650.167 175.403 650.167 173.853C650.167 172.303 651.424 171.046 652.974 171.046C654.524 171.046 655.78 172.303 655.78 173.853Z" />
          <path d="M648.939 167.011C648.939 168.561 647.682 169.818 646.132 169.818C644.583 169.818 643.325 168.561 643.325 167.011C643.325 165.461 644.583 164.204 646.132 164.204C647.682 164.204 648.939 165.461 648.939 167.011Z" />
          <path d="M648.939 173.853C648.939 175.403 647.682 176.66 646.132 176.66C644.583 176.66 643.325 175.403 643.325 173.853C643.325 172.303 644.583 171.046 646.132 171.046C647.682 171.046 648.939 172.303 648.939 173.853Z" />
          <path d="M642.097 173.853C642.097 175.403 640.841 176.66 639.291 176.66C637.741 176.66 636.483 175.403 636.483 173.853C636.483 172.303 637.741 171.046 639.291 171.046C640.841 171.046 642.097 172.303 642.097 173.853Z" />
          <path d="M635.255 173.853C635.255 175.403 633.999 176.66 632.449 176.66C630.899 176.66 629.642 175.403 629.642 173.853C629.642 172.303 630.899 171.046 632.449 171.046C633.999 171.046 635.255 172.303 635.255 173.853Z" />
          <path d="M635.255 167.011C635.255 168.561 633.999 169.818 632.449 169.818C630.899 169.818 629.642 168.561 629.642 167.011C629.642 165.461 630.899 164.204 632.449 164.204C633.999 164.204 635.255 165.461 635.255 167.011Z" />
          <path d="M635.255 160.169C635.255 161.719 633.999 162.976 632.449 162.976C630.899 162.976 629.642 161.719 629.642 160.169C629.642 158.619 630.899 157.363 632.449 157.363C633.999 157.363 635.255 158.619 635.255 160.169Z" />
          <path d="M642.097 160.169C642.097 161.719 640.841 162.976 639.291 162.976C637.741 162.976 636.483 161.719 636.483 160.169C636.483 158.619 637.741 157.363 639.291 157.363C640.841 157.363 642.097 158.619 642.097 160.169Z" />
          <path d="M642.097 153.328C642.097 154.877 640.841 156.134 639.291 156.134C637.741 156.134 636.483 154.877 636.483 153.328C636.483 151.778 637.741 150.521 639.291 150.521C640.841 150.521 642.097 151.778 642.097 153.328Z" />
          <path d="M635.255 153.328C635.255 154.877 633.999 156.134 632.449 156.134C630.899 156.134 629.642 154.877 629.642 153.328C629.642 151.778 630.899 150.521 632.449 150.521C633.999 150.521 635.255 151.778 635.255 153.328Z" />
          <path d="M628.413 153.328C628.413 154.877 627.157 156.134 625.607 156.134C624.057 156.134 622.8 154.877 622.8 153.328C622.8 151.778 624.057 150.521 625.607 150.521C627.157 150.521 628.413 151.778 628.413 153.328Z" />
          <path d="M628.413 146.486C628.413 148.036 627.157 149.292 625.607 149.292C624.057 149.292 622.8 148.036 622.8 146.486C622.8 144.936 624.057 143.679 625.607 143.679C627.157 143.679 628.413 144.936 628.413 146.486Z" />
          <path d="M635.255 146.486C635.255 148.036 633.999 149.292 632.449 149.292C630.899 149.292 629.642 148.036 629.642 146.486C629.642 144.936 630.899 143.679 632.449 143.679C633.999 143.679 635.255 144.936 635.255 146.486Z" />
          <path d="M635.255 139.644C635.255 141.194 633.999 142.451 632.449 142.451C630.899 142.451 629.642 141.194 629.642 139.644C629.642 138.094 630.899 136.837 632.449 136.837C633.999 136.837 635.255 138.094 635.255 139.644Z" />
          <path d="M635.255 132.802C635.255 134.352 633.999 135.608 632.449 135.608C630.899 135.608 629.642 134.352 629.642 132.802C629.642 131.252 630.899 129.995 632.449 129.995C633.999 129.995 635.255 131.252 635.255 132.802Z" />
          <path d="M642.097 132.802C642.097 134.352 640.841 135.608 639.291 135.608C637.741 135.608 636.483 134.352 636.483 132.802C636.483 131.252 637.741 129.995 639.291 129.995C640.841 129.995 642.097 131.252 642.097 132.802Z" />
          <path d="M642.097 139.644C642.097 141.194 640.841 142.451 639.291 142.451C637.741 142.451 636.483 141.194 636.483 139.644C636.483 138.094 637.741 136.837 639.291 136.837C640.841 136.837 642.097 138.094 642.097 139.644Z" />
          <path d="M648.939 132.802C648.939 134.352 647.682 135.608 646.132 135.608C644.583 135.608 643.325 134.352 643.325 132.802C643.325 131.252 644.583 129.995 646.132 129.995C647.682 129.995 648.939 131.252 648.939 132.802Z" />
          <path d="M648.939 139.644C648.939 141.194 647.682 142.451 646.132 142.451C644.583 142.451 643.325 141.194 643.325 139.644C643.325 138.094 644.583 136.837 646.132 136.837C647.682 136.837 648.939 138.094 648.939 139.644Z" />
          <path d="M648.939 146.486C648.939 148.036 647.682 149.292 646.132 149.292C644.583 149.292 643.325 148.036 643.325 146.486C643.325 144.936 644.583 143.679 646.132 143.679C647.682 143.679 648.939 144.936 648.939 146.486Z" />
          <path d="M648.939 153.328C648.939 154.877 647.682 156.134 646.132 156.134C644.583 156.134 643.325 154.877 643.325 153.328C643.325 151.778 644.583 150.521 646.132 150.521C647.682 150.521 648.939 151.778 648.939 153.328Z" />
          <path d="M655.78 146.486C655.78 148.036 654.524 149.292 652.974 149.292C651.424 149.292 650.167 148.036 650.167 146.486C650.167 144.936 651.424 143.679 652.974 143.679C654.524 143.679 655.78 144.936 655.78 146.486Z" />
          <path d="M655.78 139.644C655.78 141.194 654.524 142.451 652.974 142.451C651.424 142.451 650.167 141.194 650.167 139.644C650.167 138.094 651.424 136.837 652.974 136.837C654.524 136.837 655.78 138.094 655.78 139.644Z" />
          <path d="M655.78 132.802C655.78 134.352 654.524 135.608 652.974 135.608C651.424 135.608 650.167 134.352 650.167 132.802C650.167 131.252 651.424 129.995 652.974 129.995C654.524 129.995 655.78 131.252 655.78 132.802Z" />
          <path d="M655.78 125.96C655.78 127.51 654.524 128.767 652.974 128.767C651.424 128.767 650.167 127.51 650.167 125.96C650.167 124.41 651.424 123.153 652.974 123.153C654.524 123.153 655.78 124.41 655.78 125.96Z" />
          <path d="M655.78 119.118C655.78 120.668 654.524 121.925 652.974 121.925C651.424 121.925 650.167 120.668 650.167 119.118C650.167 117.568 651.424 116.312 652.974 116.312C654.524 116.312 655.78 117.568 655.78 119.118Z" />
          <path d="M648.939 119.118C648.939 120.668 647.682 121.925 646.132 121.925C644.583 121.925 643.325 120.668 643.325 119.118C643.325 117.568 644.583 116.312 646.132 116.312C647.682 116.312 648.939 117.568 648.939 119.118Z" />
          <path d="M642.097 119.118C642.097 120.668 640.841 121.925 639.291 121.925C637.741 121.925 636.483 120.668 636.483 119.118C636.483 117.568 637.741 116.312 639.291 116.312C640.841 116.312 642.097 117.568 642.097 119.118Z" />
          <path d="M635.255 119.118C635.255 120.668 633.999 121.925 632.449 121.925C630.899 121.925 629.642 120.668 629.642 119.118C629.642 117.568 630.899 116.312 632.449 116.312C633.999 116.312 635.255 117.568 635.255 119.118Z" />
          <path d="M628.413 119.118C628.413 120.668 627.157 121.925 625.607 121.925C624.057 121.925 622.8 120.668 622.8 119.118C622.8 117.568 624.057 116.312 625.607 116.312C627.157 116.312 628.413 117.568 628.413 119.118Z" />
          <path d="M621.571 119.118C621.571 120.668 620.315 121.925 618.765 121.925C617.215 121.925 615.958 120.668 615.958 119.118C615.958 117.568 617.215 116.312 618.765 116.312C620.315 116.312 621.571 117.568 621.571 119.118Z" />
          <path d="M614.73 119.118C614.73 120.668 613.473 121.925 611.923 121.925C610.374 121.925 609.116 120.668 609.116 119.118C609.116 117.568 610.374 116.312 611.923 116.312C613.473 116.312 614.73 117.568 614.73 119.118Z" />
          <path d="M607.888 119.118C607.888 120.668 606.631 121.925 605.081 121.925C603.531 121.925 602.274 120.668 602.274 119.118C602.274 117.568 603.531 116.312 605.081 116.312C606.631 116.312 607.888 117.568 607.888 119.118Z" />
          <path d="M607.888 125.96C607.888 127.51 606.631 128.767 605.081 128.767C603.531 128.767 602.274 127.51 602.274 125.96C602.274 124.41 603.531 123.153 605.081 123.153C606.631 123.153 607.888 124.41 607.888 125.96Z" />
          <path d="M607.888 132.802C607.888 134.352 606.631 135.608 605.081 135.608C603.531 135.608 602.274 134.352 602.274 132.802C602.274 131.252 603.531 129.995 605.081 129.995C606.631 129.995 607.888 131.252 607.888 132.802Z" />
          <path d="M607.888 139.644C607.888 141.194 606.631 142.451 605.081 142.451C603.531 142.451 602.274 141.194 602.274 139.644C602.274 138.094 603.531 136.837 605.081 136.837C606.631 136.837 607.888 138.094 607.888 139.644Z" />
          <path d="M607.888 146.486C607.888 148.036 606.631 149.292 605.081 149.292C603.531 149.292 602.274 148.036 602.274 146.486C602.274 144.936 603.531 143.679 605.081 143.679C606.631 143.679 607.888 144.936 607.888 146.486Z" />
          <path d="M607.888 153.328C607.888 154.877 606.631 156.134 605.081 156.134C603.531 156.134 602.274 154.877 602.274 153.328C602.274 151.778 603.531 150.521 605.081 150.521C606.631 150.521 607.888 151.778 607.888 153.328Z" />
          <path d="M607.888 160.169C607.888 161.719 606.631 162.976 605.081 162.976C603.531 162.976 602.274 161.719 602.274 160.169C602.274 158.619 603.531 157.363 605.081 157.363C606.631 157.363 607.888 158.619 607.888 160.169Z" />
          <path d="M607.888 167.011C607.888 168.561 606.631 169.818 605.081 169.818C603.531 169.818 602.274 168.561 602.274 167.011C602.274 165.461 603.531 164.204 605.081 164.204C606.631 164.204 607.888 165.461 607.888 167.011Z" />
          <path d="M607.888 173.853C607.888 175.403 606.631 176.66 605.081 176.66C603.531 176.66 602.274 175.403 602.274 173.853C602.274 172.303 603.531 171.046 605.081 171.046C606.631 171.046 607.888 172.303 607.888 173.853Z" />
          <path d="M607.888 180.695C607.888 182.245 606.631 183.502 605.081 183.502C603.531 183.502 602.274 182.245 602.274 180.695C602.274 179.145 603.531 177.888 605.081 177.888C606.631 177.888 607.888 179.145 607.888 180.695Z" />
          <path d="M601.046 180.695C601.046 182.245 599.79 183.502 598.24 183.502C596.69 183.502 595.432 182.245 595.432 180.695C595.432 179.145 596.69 177.888 598.24 177.888C599.79 177.888 601.046 179.145 601.046 180.695Z" />
          <path d="M594.204 180.695C594.204 182.245 592.948 183.502 591.398 183.502C589.848 183.502 588.591 182.245 588.591 180.695C588.591 179.145 589.848 177.888 591.398 177.888C592.948 177.888 594.204 179.145 594.204 180.695Z" />
          <path d="M587.362 180.695C587.362 182.245 586.106 183.502 584.556 183.502C583.006 183.502 581.749 182.245 581.749 180.695C581.749 179.145 583.006 177.888 584.556 177.888C586.106 177.888 587.362 179.145 587.362 180.695Z" />
          <path d="M580.52 180.695C580.52 182.245 579.264 183.502 577.714 183.502C576.164 183.502 574.907 182.245 574.907 180.695C574.907 179.145 576.164 177.888 577.714 177.888C579.264 177.888 580.52 179.145 580.52 180.695Z" />
          <path d="M580.52 187.537C580.52 189.087 579.264 190.344 577.714 190.344C576.164 190.344 574.907 189.087 574.907 187.537C574.907 185.987 576.164 184.73 577.714 184.73C579.264 184.73 580.52 185.987 580.52 187.537Z" />
          <path d="M594.204 187.537C594.204 189.087 592.948 190.344 591.398 190.344C589.848 190.344 588.591 189.087 588.591 187.537C588.591 185.987 589.848 184.73 591.398 184.73C592.948 184.73 594.204 185.987 594.204 187.537Z" />
          <path d="M594.204 194.379C594.204 195.929 592.948 197.185 591.398 197.185C589.848 197.185 588.591 195.929 588.591 194.379C588.591 192.829 589.848 191.572 591.398 191.572C592.948 191.572 594.204 192.829 594.204 194.379Z" />
          <path d="M587.362 194.379C587.362 195.929 586.106 197.185 584.556 197.185C583.006 197.185 581.749 195.929 581.749 194.379C581.749 192.829 583.006 191.572 584.556 191.572C586.106 191.572 587.362 192.829 587.362 194.379Z" />
          <path d="M601.046 194.379C601.046 195.929 599.79 197.185 598.24 197.185C596.69 197.185 595.432 195.929 595.432 194.379C595.432 192.829 596.69 191.572 598.24 191.572C599.79 191.572 601.046 192.829 601.046 194.379Z" />
          <path d="M601.046 187.537C601.046 189.087 599.79 190.344 598.24 190.344C596.69 190.344 595.432 189.087 595.432 187.537C595.432 185.987 596.69 184.73 598.24 184.73C599.79 184.73 601.046 185.987 601.046 187.537Z" />
          <path d="M607.888 187.537C607.888 189.087 606.631 190.344 605.081 190.344C603.531 190.344 602.274 189.087 602.274 187.537C602.274 185.987 603.531 184.73 605.081 184.73C606.631 184.73 607.888 185.987 607.888 187.537Z" />
          <path d="M607.888 194.379C607.888 195.929 606.631 197.185 605.081 197.185C603.531 197.185 602.274 195.929 602.274 194.379C602.274 192.829 603.531 191.572 605.081 191.572C606.631 191.572 607.888 192.829 607.888 194.379Z" />
          <path d="M614.73 194.379C614.73 195.929 613.473 197.185 611.923 197.185C610.374 197.185 609.116 195.929 609.116 194.379C609.116 192.829 610.374 191.572 611.923 191.572C613.473 191.572 614.73 192.829 614.73 194.379Z" />
          <path d="M621.571 194.379C621.571 195.929 620.315 197.185 618.765 197.185C617.215 197.185 615.958 195.929 615.958 194.379C615.958 192.829 617.215 191.572 618.765 191.572C620.315 191.572 621.571 192.829 621.571 194.379Z" />
          <path d="M621.571 187.537C621.571 189.087 620.315 190.344 618.765 190.344C617.215 190.344 615.958 189.087 615.958 187.537C615.958 185.987 617.215 184.73 618.765 184.73C620.315 184.73 621.571 185.987 621.571 187.537Z" />
          <path d="M628.413 187.537C628.413 189.087 627.157 190.344 625.607 190.344C624.057 190.344 622.8 189.087 622.8 187.537C622.8 185.987 624.057 184.73 625.607 184.73C627.157 184.73 628.413 185.987 628.413 187.537Z" />
          <path d="M628.413 180.695C628.413 182.245 627.157 183.502 625.607 183.502C624.057 183.502 622.8 182.245 622.8 180.695C622.8 179.145 624.057 177.888 625.607 177.888C627.157 177.888 628.413 179.145 628.413 180.695Z" />
          <path d="M628.413 173.853C628.413 175.403 627.157 176.66 625.607 176.66C624.057 176.66 622.8 175.403 622.8 173.853C622.8 172.303 624.057 171.046 625.607 171.046C627.157 171.046 628.413 172.303 628.413 173.853Z" />
          <path d="M628.413 167.011C628.413 168.561 627.157 169.818 625.607 169.818C624.057 169.818 622.8 168.561 622.8 167.011C622.8 165.461 624.057 164.204 625.607 164.204C627.157 164.204 628.413 165.461 628.413 167.011Z" />
          <path d="M628.413 160.169C628.413 161.719 627.157 162.976 625.607 162.976C624.057 162.976 622.8 161.719 622.8 160.169C622.8 158.619 624.057 157.363 625.607 157.363C627.157 157.363 628.413 158.619 628.413 160.169Z" />
          <path d="M621.571 160.169C621.571 161.719 620.315 162.976 618.765 162.976C617.215 162.976 615.958 161.719 615.958 160.169C615.958 158.619 617.215 157.363 618.765 157.363C620.315 157.363 621.571 158.619 621.571 160.169Z" />
          <path d="M621.571 153.328C621.571 154.877 620.315 156.134 618.765 156.134C617.215 156.134 615.958 154.877 615.958 153.328C615.958 151.778 617.215 150.521 618.765 150.521C620.315 150.521 621.571 151.778 621.571 153.328Z" />
          <path d="M621.571 146.486C621.571 148.036 620.315 149.292 618.765 149.292C617.215 149.292 615.958 148.036 615.958 146.486C615.958 144.936 617.215 143.679 618.765 143.679C620.315 143.679 621.571 144.936 621.571 146.486Z" />
          <path d="M621.571 139.644C621.571 141.194 620.315 142.451 618.765 142.451C617.215 142.451 615.958 141.194 615.958 139.644C615.958 138.094 617.215 136.837 618.765 136.837C620.315 136.837 621.571 138.094 621.571 139.644Z" />
          <path d="M628.413 139.644C628.413 141.194 627.157 142.451 625.607 142.451C624.057 142.451 622.8 141.194 622.8 139.644C622.8 138.094 624.057 136.837 625.607 136.837C627.157 136.837 628.413 138.094 628.413 139.644Z" />
          <path d="M628.413 132.802C628.413 134.352 627.157 135.608 625.607 135.608C624.057 135.608 622.8 134.352 622.8 132.802C622.8 131.252 624.057 129.995 625.607 129.995C627.157 129.995 628.413 131.252 628.413 132.802Z" />
          <path d="M628.413 125.96C628.413 127.51 627.157 128.767 625.607 128.767C624.057 128.767 622.8 127.51 622.8 125.96C622.8 124.41 624.057 123.153 625.607 123.153C627.157 123.153 628.413 124.41 628.413 125.96Z" />
          <path d="M621.571 125.96C621.571 127.51 620.315 128.767 618.765 128.767C617.215 128.767 615.958 127.51 615.958 125.96C615.958 124.41 617.215 123.153 618.765 123.153C620.315 123.153 621.571 124.41 621.571 125.96Z" />
          <path d="M614.73 125.96C614.73 127.51 613.473 128.767 611.923 128.767C610.374 128.767 609.116 127.51 609.116 125.96C609.116 124.41 610.374 123.153 611.923 123.153C613.473 123.153 614.73 124.41 614.73 125.96Z" />
          <path d="M614.73 132.802C614.73 134.352 613.473 135.608 611.923 135.608C610.374 135.608 609.116 134.352 609.116 132.802C609.116 131.252 610.374 129.995 611.923 129.995C613.473 129.995 614.73 131.252 614.73 132.802Z" />
          <path d="M621.571 132.802C621.571 134.352 620.315 135.608 618.765 135.608C617.215 135.608 615.958 134.352 615.958 132.802C615.958 131.252 617.215 129.995 618.765 129.995C620.315 129.995 621.571 131.252 621.571 132.802Z" />
          <path d="M614.73 139.644C614.73 141.194 613.473 142.451 611.923 142.451C610.374 142.451 609.116 141.194 609.116 139.644C609.116 138.094 610.374 136.837 611.923 136.837C613.473 136.837 614.73 138.094 614.73 139.644Z" />
          <path d="M614.73 146.486C614.73 148.036 613.473 149.292 611.923 149.292C610.374 149.292 609.116 148.036 609.116 146.486C609.116 144.936 610.374 143.679 611.923 143.679C613.473 143.679 614.73 144.936 614.73 146.486Z" />
          <path d="M614.73 153.328C614.73 154.877 613.473 156.134 611.923 156.134C610.374 156.134 609.116 154.877 609.116 153.328C609.116 151.778 610.374 150.521 611.923 150.521C613.473 150.521 614.73 151.778 614.73 153.328Z" />
          <path d="M614.73 160.169C614.73 161.719 613.473 162.976 611.923 162.976C610.374 162.976 609.116 161.719 609.116 160.169C609.116 158.619 610.374 157.363 611.923 157.363C613.473 157.363 614.73 158.619 614.73 160.169Z" />
          <path d="M614.73 167.011C614.73 168.561 613.473 169.818 611.923 169.818C610.374 169.818 609.116 168.561 609.116 167.011C609.116 165.461 610.374 164.204 611.923 164.204C613.473 164.204 614.73 165.461 614.73 167.011Z" />
          <path d="M614.73 173.853C614.73 175.403 613.473 176.66 611.923 176.66C610.374 176.66 609.116 175.403 609.116 173.853C609.116 172.303 610.374 171.046 611.923 171.046C613.473 171.046 614.73 172.303 614.73 173.853Z" />
          <path d="M614.73 180.695C614.73 182.245 613.473 183.502 611.923 183.502C610.374 183.502 609.116 182.245 609.116 180.695C609.116 179.145 610.374 177.888 611.923 177.888C613.473 177.888 614.73 179.145 614.73 180.695Z" />
          <path d="M614.73 187.537C614.73 189.087 613.473 190.344 611.923 190.344C610.374 190.344 609.116 189.087 609.116 187.537C609.116 185.987 610.374 184.73 611.923 184.73C613.473 184.73 614.73 185.987 614.73 187.537Z" />
          <path d="M621.571 180.695C621.571 182.245 620.315 183.502 618.765 183.502C617.215 183.502 615.958 182.245 615.958 180.695C615.958 179.145 617.215 177.888 618.765 177.888C620.315 177.888 621.571 179.145 621.571 180.695Z" />
          <path d="M621.571 173.853C621.571 175.403 620.315 176.66 618.765 176.66C617.215 176.66 615.958 175.403 615.958 173.853C615.958 172.303 617.215 171.046 618.765 171.046C620.315 171.046 621.571 172.303 621.571 173.853Z" />
          <path d="M621.571 167.011C621.571 168.561 620.315 169.818 618.765 169.818C617.215 169.818 615.958 168.561 615.958 167.011C615.958 165.461 617.215 164.204 618.765 164.204C620.315 164.204 621.571 165.461 621.571 167.011Z" />
          <path d="M635.255 125.96C635.255 127.51 633.999 128.767 632.449 128.767C630.899 128.767 629.642 127.51 629.642 125.96C629.642 124.41 630.899 123.153 632.449 123.153C633.999 123.153 635.255 124.41 635.255 125.96Z" />
          <path d="M642.097 125.96C642.097 127.51 640.841 128.767 639.291 128.767C637.741 128.767 636.483 127.51 636.483 125.96C636.483 124.41 637.741 123.153 639.291 123.153C640.841 123.153 642.097 124.41 642.097 125.96Z" />
          <path d="M648.939 125.96C648.939 127.51 647.682 128.767 646.132 128.767C644.583 128.767 643.325 127.51 643.325 125.96C643.325 124.41 644.583 123.153 646.132 123.153C647.682 123.153 648.939 124.41 648.939 125.96Z" />
          <path d="M580.52 194.379C580.52 195.929 579.264 197.185 577.714 197.185C576.164 197.185 574.907 195.929 574.907 194.379C574.907 192.829 576.164 191.572 577.714 191.572C579.264 191.572 580.52 192.829 580.52 194.379Z" />
          <path d="M573.678 194.379C573.678 195.929 572.422 197.185 570.872 197.185C569.322 197.185 568.065 195.929 568.065 194.379C568.065 192.829 569.322 191.572 570.872 191.572C572.422 191.572 573.678 192.829 573.678 194.379Z" />
          <path d="M573.678 201.22C573.678 202.77 572.422 204.027 570.872 204.027C569.322 204.027 568.065 202.77 568.065 201.22C568.065 199.67 569.322 198.413 570.872 198.413C572.422 198.413 573.678 199.67 573.678 201.22Z" />
          <path d="M580.52 201.22C580.52 202.77 579.264 204.027 577.714 204.027C576.164 204.027 574.907 202.77 574.907 201.22C574.907 199.67 576.164 198.413 577.714 198.413C579.264 198.413 580.52 199.67 580.52 201.22Z" />
          <path d="M587.362 201.22C587.362 202.77 586.106 204.027 584.556 204.027C583.006 204.027 581.749 202.77 581.749 201.22C581.749 199.67 583.006 198.413 584.556 198.413C586.106 198.413 587.362 199.67 587.362 201.22Z" />
          <path d="M594.204 201.22C594.204 202.77 592.948 204.027 591.398 204.027C589.848 204.027 588.591 202.77 588.591 201.22C588.591 199.67 589.848 198.413 591.398 198.413C592.948 198.413 594.204 199.67 594.204 201.22Z" />
          <path d="M601.046 201.22C601.046 202.77 599.79 204.027 598.24 204.027C596.69 204.027 595.432 202.77 595.432 201.22C595.432 199.67 596.69 198.413 598.24 198.413C599.79 198.413 601.046 199.67 601.046 201.22Z" />
          <path d="M607.888 201.22C607.888 202.77 606.631 204.027 605.081 204.027C603.531 204.027 602.274 202.77 602.274 201.22C602.274 199.67 603.531 198.413 605.081 198.413C606.631 198.413 607.888 199.67 607.888 201.22Z" />
          <path d="M614.73 201.22C614.73 202.77 613.473 204.027 611.923 204.027C610.374 204.027 609.116 202.77 609.116 201.22C609.116 199.67 610.374 198.413 611.923 198.413C613.473 198.413 614.73 199.67 614.73 201.22Z" />
          <path d="M621.571 201.22C621.571 202.77 620.315 204.027 618.765 204.027C617.215 204.027 615.958 202.77 615.958 201.22C615.958 199.67 617.215 198.413 618.765 198.413C620.315 198.413 621.571 199.67 621.571 201.22Z" />
          <path d="M628.413 201.22C628.413 202.77 627.157 204.027 625.607 204.027C624.057 204.027 622.8 202.77 622.8 201.22C622.8 199.67 624.057 198.413 625.607 198.413C627.157 198.413 628.413 199.67 628.413 201.22Z" />
          <path d="M628.413 194.379C628.413 195.929 627.157 197.185 625.607 197.185C624.057 197.185 622.8 195.929 622.8 194.379C622.8 192.829 624.057 191.572 625.607 191.572C627.157 191.572 628.413 192.829 628.413 194.379Z" />
          <path d="M635.255 194.379C635.255 195.929 633.999 197.185 632.449 197.185C630.899 197.185 629.642 195.929 629.642 194.379C629.642 192.829 630.899 191.572 632.449 191.572C633.999 191.572 635.255 192.829 635.255 194.379Z" />
          <path d="M635.255 187.537C635.255 189.087 633.999 190.344 632.449 190.344C630.899 190.344 629.642 189.087 629.642 187.537C629.642 185.987 630.899 184.73 632.449 184.73C633.999 184.73 635.255 185.987 635.255 187.537Z" />
          <path d="M635.255 180.695C635.255 182.245 633.999 183.502 632.449 183.502C630.899 183.502 629.642 182.245 629.642 180.695C629.642 179.145 630.899 177.888 632.449 177.888C633.999 177.888 635.255 179.145 635.255 180.695Z" />
          <path d="M642.097 180.695C642.097 182.245 640.841 183.502 639.291 183.502C637.741 183.502 636.483 182.245 636.483 180.695C636.483 179.145 637.741 177.888 639.291 177.888C640.841 177.888 642.097 179.145 642.097 180.695Z" />
          <path d="M648.939 180.695C648.939 182.245 647.682 183.502 646.132 183.502C644.583 183.502 643.325 182.245 643.325 180.695C643.325 179.145 644.583 177.888 646.132 177.888C647.682 177.888 648.939 179.145 648.939 180.695Z" />
          <path d="M655.78 180.695C655.78 182.245 654.524 183.502 652.974 183.502C651.424 183.502 650.167 182.245 650.167 180.695C650.167 179.145 651.424 177.888 652.974 177.888C654.524 177.888 655.78 179.145 655.78 180.695Z" />
          <path d="M662.623 180.695C662.623 182.245 661.366 183.502 659.816 183.502C658.266 183.502 657.009 182.245 657.009 180.695C657.009 179.145 658.266 177.888 659.816 177.888C661.366 177.888 662.623 179.145 662.623 180.695Z" />
          <path d="M662.623 173.853C662.623 175.403 661.366 176.66 659.816 176.66C658.266 176.66 657.009 175.403 657.009 173.853C657.009 172.303 658.266 171.046 659.816 171.046C661.366 171.046 662.623 172.303 662.623 173.853Z" />
          <path d="M669.464 173.853C669.464 175.403 668.208 176.66 666.658 176.66C665.108 176.66 663.851 175.403 663.851 173.853C663.851 172.303 665.108 171.046 666.658 171.046C668.208 171.046 669.464 172.303 669.464 173.853Z" />
          <path d="M676.306 173.853C676.306 175.403 675.05 176.66 673.5 176.66C671.95 176.66 670.693 175.403 670.693 173.853C670.693 172.303 671.95 171.046 673.5 171.046C675.05 171.046 676.306 172.303 676.306 173.853Z" />
          <path d="M683.148 173.853C683.148 175.403 681.892 176.66 680.342 176.66C678.792 176.66 677.534 175.403 677.534 173.853C677.534 172.303 678.792 171.046 680.342 171.046C681.892 171.046 683.148 172.303 683.148 173.853Z" />
          <path d="M689.99 173.853C689.99 175.403 688.733 176.66 687.183 176.66C685.634 176.66 684.376 175.403 684.376 173.853C684.376 172.303 685.634 171.046 687.183 171.046C688.733 171.046 689.99 172.303 689.99 173.853Z" />
          <path d="M696.832 173.853C696.832 175.403 695.575 176.66 694.026 176.66C692.476 176.66 691.218 175.403 691.218 173.853C691.218 172.303 692.476 171.046 694.026 171.046C695.575 171.046 696.832 172.303 696.832 173.853Z" />
          <path d="M696.832 167.011C696.832 168.561 695.575 169.818 694.026 169.818C692.476 169.818 691.218 168.561 691.218 167.011C691.218 165.461 692.476 164.204 694.026 164.204C695.575 164.204 696.832 165.461 696.832 167.011Z" />
          <path d="M696.832 160.169C696.832 161.719 695.575 162.976 694.026 162.976C692.476 162.976 691.218 161.719 691.218 160.169C691.218 158.619 692.476 157.363 694.026 157.363C695.575 157.363 696.832 158.619 696.832 160.169Z" />
          <path d="M696.832 153.328C696.832 154.877 695.575 156.134 694.026 156.134C692.476 156.134 691.218 154.877 691.218 153.328C691.218 151.778 692.476 150.521 694.026 150.521C695.575 150.521 696.832 151.778 696.832 153.328Z" />
          <path d="M696.832 146.486C696.832 148.036 695.575 149.292 694.026 149.292C692.476 149.292 691.218 148.036 691.218 146.486C691.218 144.936 692.476 143.679 694.026 143.679C695.575 143.679 696.832 144.936 696.832 146.486Z" />
          <path d="M689.99 146.486C689.99 148.036 688.733 149.292 687.183 149.292C685.634 149.292 684.376 148.036 684.376 146.486C684.376 144.936 685.634 143.679 687.183 143.679C688.733 143.679 689.99 144.936 689.99 146.486Z" />
          <path d="M683.148 146.486C683.148 148.036 681.892 149.292 680.342 149.292C678.792 149.292 677.534 148.036 677.534 146.486C677.534 144.936 678.792 143.679 680.342 143.679C681.892 143.679 683.148 144.936 683.148 146.486Z" />
          <path d="M676.306 146.486C676.306 148.036 675.05 149.292 673.5 149.292C671.95 149.292 670.693 148.036 670.693 146.486C670.693 144.936 671.95 143.679 673.5 143.679C675.05 143.679 676.306 144.936 676.306 146.486Z" />
          <path d="M669.464 146.486C669.464 148.036 668.208 149.292 666.658 149.292C665.108 149.292 663.851 148.036 663.851 146.486C663.851 144.936 665.108 143.679 666.658 143.679C668.208 143.679 669.464 144.936 669.464 146.486Z" />
          <path d="M669.464 153.328C669.464 154.877 668.208 156.134 666.658 156.134C665.108 156.134 663.851 154.877 663.851 153.328C663.851 151.778 665.108 150.521 666.658 150.521C668.208 150.521 669.464 151.778 669.464 153.328Z" />
          <path d="M669.464 160.169C669.464 161.719 668.208 162.976 666.658 162.976C665.108 162.976 663.851 161.719 663.851 160.169C663.851 158.619 665.108 157.363 666.658 157.363C668.208 157.363 669.464 158.619 669.464 160.169Z" />
          <path d="M669.464 167.011C669.464 168.561 668.208 169.818 666.658 169.818C665.108 169.818 663.851 168.561 663.851 167.011C663.851 165.461 665.108 164.204 666.658 164.204C668.208 164.204 669.464 165.461 669.464 167.011Z" />
          <path d="M676.306 167.011C676.306 168.561 675.05 169.818 673.5 169.818C671.95 169.818 670.693 168.561 670.693 167.011C670.693 165.461 671.95 164.204 673.5 164.204C675.05 164.204 676.306 165.461 676.306 167.011Z" />
          <path d="M683.148 167.011C683.148 168.561 681.892 169.818 680.342 169.818C678.792 169.818 677.534 168.561 677.534 167.011C677.534 165.461 678.792 164.204 680.342 164.204C681.892 164.204 683.148 165.461 683.148 167.011Z" />
          <path d="M689.99 167.011C689.99 168.561 688.733 169.818 687.183 169.818C685.634 169.818 684.376 168.561 684.376 167.011C684.376 165.461 685.634 164.204 687.183 164.204C688.733 164.204 689.99 165.461 689.99 167.011Z" />
          <path d="M689.99 160.169C689.99 161.719 688.733 162.976 687.183 162.976C685.634 162.976 684.376 161.719 684.376 160.169C684.376 158.619 685.634 157.363 687.183 157.363C688.733 157.363 689.99 158.619 689.99 160.169Z" />
          <path d="M689.99 153.328C689.99 154.877 688.733 156.134 687.183 156.134C685.634 156.134 684.376 154.877 684.376 153.328C684.376 151.778 685.634 150.521 687.183 150.521C688.733 150.521 689.99 151.778 689.99 153.328Z" />
          <path d="M683.148 153.328C683.148 154.877 681.892 156.134 680.342 156.134C678.792 156.134 677.534 154.877 677.534 153.328C677.534 151.778 678.792 150.521 680.342 150.521C681.892 150.521 683.148 151.778 683.148 153.328Z" />
          <path d="M676.306 153.328C676.306 154.877 675.05 156.134 673.5 156.134C671.95 156.134 670.693 154.877 670.693 153.328C670.693 151.778 671.95 150.521 673.5 150.521C675.05 150.521 676.306 151.778 676.306 153.328Z" />
          <path d="M676.306 160.169C676.306 161.719 675.05 162.976 673.5 162.976C671.95 162.976 670.693 161.719 670.693 160.169C670.693 158.619 671.95 157.363 673.5 157.363C675.05 157.363 676.306 158.619 676.306 160.169Z" />
          <path d="M683.148 160.169C683.148 161.719 681.892 162.976 680.342 162.976C678.792 162.976 677.534 161.719 677.534 160.169C677.534 158.619 678.792 157.363 680.342 157.363C681.892 157.363 683.148 158.619 683.148 160.169Z" />
          <path d="M601.046 119.118C601.046 120.668 599.79 121.925 598.24 121.925C596.69 121.925 595.432 120.668 595.432 119.118C595.432 117.568 596.69 116.312 598.24 116.312C599.79 116.312 601.046 117.568 601.046 119.118Z" />
          <path d="M601.046 125.96C601.046 127.51 599.79 128.767 598.24 128.767C596.69 128.767 595.432 127.51 595.432 125.96C595.432 124.41 596.69 123.153 598.24 123.153C599.79 123.153 601.046 124.41 601.046 125.96Z" />
          <path d="M601.046 132.802C601.046 134.352 599.79 135.608 598.24 135.608C596.69 135.608 595.432 134.352 595.432 132.802C595.432 131.252 596.69 129.995 598.24 129.995C599.79 129.995 601.046 131.252 601.046 132.802Z" />
          <path d="M601.046 139.644C601.046 141.194 599.79 142.451 598.24 142.451C596.69 142.451 595.432 141.194 595.432 139.644C595.432 138.094 596.69 136.837 598.24 136.837C599.79 136.837 601.046 138.094 601.046 139.644Z" />
          <path d="M601.046 146.486C601.046 148.036 599.79 149.292 598.24 149.292C596.69 149.292 595.432 148.036 595.432 146.486C595.432 144.936 596.69 143.679 598.24 143.679C599.79 143.679 601.046 144.936 601.046 146.486Z" />
          <path d="M601.046 153.328C601.046 154.877 599.79 156.134 598.24 156.134C596.69 156.134 595.432 154.877 595.432 153.328C595.432 151.778 596.69 150.521 598.24 150.521C599.79 150.521 601.046 151.778 601.046 153.328Z" />
          <path d="M601.046 160.169C601.046 161.719 599.79 162.976 598.24 162.976C596.69 162.976 595.432 161.719 595.432 160.169C595.432 158.619 596.69 157.363 598.24 157.363C599.79 157.363 601.046 158.619 601.046 160.169Z" />
          <path d="M594.204 160.169C594.204 161.719 592.948 162.976 591.398 162.976C589.848 162.976 588.591 161.719 588.591 160.169C588.591 158.619 589.848 157.363 591.398 157.363C592.948 157.363 594.204 158.619 594.204 160.169Z" />
          <path d="M587.362 160.169C587.362 161.719 586.106 162.976 584.556 162.976C583.006 162.976 581.749 161.719 581.749 160.169C581.749 158.619 583.006 157.363 584.556 157.363C586.106 157.363 587.362 158.619 587.362 160.169Z" />
          <path d="M580.52 160.169C580.52 161.719 579.264 162.976 577.714 162.976C576.164 162.976 574.907 161.719 574.907 160.169C574.907 158.619 576.164 157.363 577.714 157.363C579.264 157.363 580.52 158.619 580.52 160.169Z" />
          <path d="M573.678 160.169C573.678 161.719 572.422 162.976 570.872 162.976C569.322 162.976 568.065 161.719 568.065 160.169C568.065 158.619 569.322 157.363 570.872 157.363C572.422 157.363 573.678 158.619 573.678 160.169Z" />
          <path d="M566.837 160.169C566.837 161.719 565.58 162.976 564.03 162.976C562.48 162.976 561.223 161.719 561.223 160.169C561.223 158.619 562.48 157.363 564.03 157.363C565.58 157.363 566.837 158.619 566.837 160.169Z" />
          <path d="M559.995 160.169C559.995 161.719 558.739 162.976 557.189 162.976C555.639 162.976 554.381 161.719 554.381 160.169C554.381 158.619 555.639 157.363 557.189 157.363C558.739 157.363 559.995 158.619 559.995 160.169Z" />
          <path d="M559.995 153.328C559.995 154.877 558.739 156.134 557.189 156.134C555.639 156.134 554.381 154.877 554.381 153.328C554.381 151.778 555.639 150.521 557.189 150.521C558.739 150.521 559.995 151.778 559.995 153.328Z" />
          <path d="M559.995 146.486C559.995 148.036 558.739 149.292 557.189 149.292C555.639 149.292 554.381 148.036 554.381 146.486C554.381 144.936 555.639 143.679 557.189 143.679C558.739 143.679 559.995 144.936 559.995 146.486Z" />
          <path d="M559.995 139.644C559.995 141.194 558.739 142.451 557.189 142.451C555.639 142.451 554.381 141.194 554.381 139.644C554.381 138.094 555.639 136.837 557.189 136.837C558.739 136.837 559.995 138.094 559.995 139.644Z" />
          <path d="M559.995 132.802C559.995 134.352 558.739 135.608 557.189 135.608C555.639 135.608 554.381 134.352 554.381 132.802C554.381 131.252 555.639 129.995 557.189 129.995C558.739 129.995 559.995 131.252 559.995 132.802Z" />
          <path d="M559.995 125.96C559.995 127.51 558.739 128.767 557.189 128.767C555.639 128.767 554.381 127.51 554.381 125.96C554.381 124.41 555.639 123.153 557.189 123.153C558.739 123.153 559.995 124.41 559.995 125.96Z" />
          <path d="M559.995 119.118C559.995 120.668 558.739 121.925 557.189 121.925C555.639 121.925 554.381 120.668 554.381 119.118C554.381 117.568 555.639 116.312 557.189 116.312C558.739 116.312 559.995 117.568 559.995 119.118Z" />
          <path d="M553.153 119.118C553.153 120.668 551.897 121.925 550.347 121.925C548.797 121.925 547.539 120.668 547.539 119.118C547.539 117.568 548.797 116.312 550.347 116.312C551.897 116.312 553.153 117.568 553.153 119.118Z" />
          <path d="M546.311 119.118C546.311 120.668 545.055 121.925 543.505 121.925C541.955 121.925 540.698 120.668 540.698 119.118C540.698 117.568 541.955 116.312 543.505 116.312C545.055 116.312 546.311 117.568 546.311 119.118Z" />
          <path d="M546.311 125.96C546.311 127.51 545.055 128.767 543.505 128.767C541.955 128.767 540.698 127.51 540.698 125.96C540.698 124.41 541.955 123.153 543.505 123.153C545.055 123.153 546.311 124.41 546.311 125.96Z" />
          <path d="M539.469 125.96C539.469 127.51 538.213 128.767 536.663 128.767C535.113 128.767 533.856 127.51 533.856 125.96C533.856 124.41 535.113 123.153 536.663 123.153C538.213 123.153 539.469 124.41 539.469 125.96Z" />
          <path d="M532.628 125.96C532.628 127.51 531.371 128.767 529.821 128.767C528.271 128.767 527.014 127.51 527.014 125.96C527.014 124.41 528.271 123.153 529.821 123.153C531.371 123.153 532.628 124.41 532.628 125.96Z" />
          <path d="M525.786 125.96C525.786 127.51 524.53 128.767 522.98 128.767C521.43 128.767 520.172 127.51 520.172 125.96C520.172 124.41 521.43 123.153 522.98 123.153C524.53 123.153 525.786 124.41 525.786 125.96Z" />
          <path d="M518.944 125.96C518.944 127.51 517.687 128.767 516.137 128.767C514.587 128.767 513.33 127.51 513.33 125.96C513.33 124.41 514.587 123.153 516.137 123.153C517.687 123.153 518.944 124.41 518.944 125.96Z" />
          <path d="M512.102 125.96C512.102 127.51 510.845 128.767 509.296 128.767C507.746 128.767 506.488 127.51 506.488 125.96C506.488 124.41 507.746 123.153 509.296 123.153C510.845 123.153 512.102 124.41 512.102 125.96Z" />
          <path d="M505.26 125.96C505.26 127.51 504.004 128.767 502.454 128.767C500.904 128.767 499.646 127.51 499.646 125.96C499.646 124.41 500.904 123.153 502.454 123.153C504.004 123.153 505.26 124.41 505.26 125.96Z" />
          <path d="M512.102 132.802C512.102 134.352 510.845 135.608 509.296 135.608C507.746 135.608 506.488 134.352 506.488 132.802C506.488 131.252 507.746 129.995 509.296 129.995C510.845 129.995 512.102 131.252 512.102 132.802Z" />
          <path d="M512.102 139.644C512.102 141.194 510.845 142.451 509.296 142.451C507.746 142.451 506.488 141.194 506.488 139.644C506.488 138.094 507.746 136.837 509.296 136.837C510.845 136.837 512.102 138.094 512.102 139.644Z" />
          <path d="M512.102 146.486C512.102 148.036 510.845 149.292 509.296 149.292C507.746 149.292 506.488 148.036 506.488 146.486C506.488 144.936 507.746 143.679 509.296 143.679C510.845 143.679 512.102 144.936 512.102 146.486Z" />
          <path d="M512.102 153.328C512.102 154.877 510.845 156.134 509.296 156.134C507.746 156.134 506.488 154.877 506.488 153.328C506.488 151.778 507.746 150.521 509.296 150.521C510.845 150.521 512.102 151.778 512.102 153.328Z" />
          <path d="M512.102 160.169C512.102 161.719 510.845 162.976 509.296 162.976C507.746 162.976 506.488 161.719 506.488 160.169C506.488 158.619 507.746 157.363 509.296 157.363C510.845 157.363 512.102 158.619 512.102 160.169Z" />
          <path d="M512.102 167.011C512.102 168.561 510.845 169.818 509.296 169.818C507.746 169.818 506.488 168.561 506.488 167.011C506.488 165.461 507.746 164.204 509.296 164.204C510.845 164.204 512.102 165.461 512.102 167.011Z" />
          <path d="M512.102 173.853C512.102 175.403 510.845 176.66 509.296 176.66C507.746 176.66 506.488 175.403 506.488 173.853C506.488 172.303 507.746 171.046 509.296 171.046C510.845 171.046 512.102 172.303 512.102 173.853Z" />
          <path d="M512.102 180.695C512.102 182.245 510.845 183.502 509.296 183.502C507.746 183.502 506.488 182.245 506.488 180.695C506.488 179.145 507.746 177.888 509.296 177.888C510.845 177.888 512.102 179.145 512.102 180.695Z" />
          <path d="M518.944 173.853C518.944 175.403 517.687 176.66 516.137 176.66C514.587 176.66 513.33 175.403 513.33 173.853C513.33 172.303 514.587 171.046 516.137 171.046C517.687 171.046 518.944 172.303 518.944 173.853Z" />
          <path d="M525.786 173.853C525.786 175.403 524.53 176.66 522.98 176.66C521.43 176.66 520.172 175.403 520.172 173.853C520.172 172.303 521.43 171.046 522.98 171.046C524.53 171.046 525.786 172.303 525.786 173.853Z" />
          <path d="M532.628 173.853C532.628 175.403 531.371 176.66 529.821 176.66C528.271 176.66 527.014 175.403 527.014 173.853C527.014 172.303 528.271 171.046 529.821 171.046C531.371 171.046 532.628 172.303 532.628 173.853Z" />
          <path d="M539.469 173.853C539.469 175.403 538.213 176.66 536.663 176.66C535.113 176.66 533.856 175.403 533.856 173.853C533.856 172.303 535.113 171.046 536.663 171.046C538.213 171.046 539.469 172.303 539.469 173.853Z" />
          <path d="M546.311 173.853C546.311 175.403 545.055 176.66 543.505 176.66C541.955 176.66 540.698 175.403 540.698 173.853C540.698 172.303 541.955 171.046 543.505 171.046C545.055 171.046 546.311 172.303 546.311 173.853Z" />
          <path d="M553.153 173.853C553.153 175.403 551.897 176.66 550.347 176.66C548.797 176.66 547.539 175.403 547.539 173.853C547.539 172.303 548.797 171.046 550.347 171.046C551.897 171.046 553.153 172.303 553.153 173.853Z" />
          <path d="M559.995 173.853C559.995 175.403 558.739 176.66 557.189 176.66C555.639 176.66 554.381 175.403 554.381 173.853C554.381 172.303 555.639 171.046 557.189 171.046C558.739 171.046 559.995 172.303 559.995 173.853Z" />
          <path d="M566.837 173.853C566.837 175.403 565.58 176.66 564.03 176.66C562.48 176.66 561.223 175.403 561.223 173.853C561.223 172.303 562.48 171.046 564.03 171.046C565.58 171.046 566.837 172.303 566.837 173.853Z" />
          <path d="M573.678 173.853C573.678 175.403 572.422 176.66 570.872 176.66C569.322 176.66 568.065 175.403 568.065 173.853C568.065 172.303 569.322 171.046 570.872 171.046C572.422 171.046 573.678 172.303 573.678 173.853Z" />
          <path d="M580.52 173.853C580.52 175.403 579.264 176.66 577.714 176.66C576.164 176.66 574.907 175.403 574.907 173.853C574.907 172.303 576.164 171.046 577.714 171.046C579.264 171.046 580.52 172.303 580.52 173.853Z" />
          <path d="M587.362 173.853C587.362 175.403 586.106 176.66 584.556 176.66C583.006 176.66 581.749 175.403 581.749 173.853C581.749 172.303 583.006 171.046 584.556 171.046C586.106 171.046 587.362 172.303 587.362 173.853Z" />
          <path d="M594.204 173.853C594.204 175.403 592.948 176.66 591.398 176.66C589.848 176.66 588.591 175.403 588.591 173.853C588.591 172.303 589.848 171.046 591.398 171.046C592.948 171.046 594.204 172.303 594.204 173.853Z" />
          <path d="M601.046 173.853C601.046 175.403 599.79 176.66 598.24 176.66C596.69 176.66 595.432 175.403 595.432 173.853C595.432 172.303 596.69 171.046 598.24 171.046C599.79 171.046 601.046 172.303 601.046 173.853Z" />
          <path d="M601.046 167.011C601.046 168.561 599.79 169.818 598.24 169.818C596.69 169.818 595.432 168.561 595.432 167.011C595.432 165.461 596.69 164.204 598.24 164.204C599.79 164.204 601.046 165.461 601.046 167.011Z" />
          <path d="M594.204 167.011C594.204 168.561 592.948 169.818 591.398 169.818C589.848 169.818 588.591 168.561 588.591 167.011C588.591 165.461 589.848 164.204 591.398 164.204C592.948 164.204 594.204 165.461 594.204 167.011Z" />
          <path d="M587.362 167.011C587.362 168.561 586.106 169.818 584.556 169.818C583.006 169.818 581.749 168.561 581.749 167.011C581.749 165.461 583.006 164.204 584.556 164.204C586.106 164.204 587.362 165.461 587.362 167.011Z" />
          <path d="M580.52 167.011C580.52 168.561 579.264 169.818 577.714 169.818C576.164 169.818 574.907 168.561 574.907 167.011C574.907 165.461 576.164 164.204 577.714 164.204C579.264 164.204 580.52 165.461 580.52 167.011Z" />
          <path d="M573.678 167.011C573.678 168.561 572.422 169.818 570.872 169.818C569.322 169.818 568.065 168.561 568.065 167.011C568.065 165.461 569.322 164.204 570.872 164.204C572.422 164.204 573.678 165.461 573.678 167.011Z" />
          <path d="M566.837 167.011C566.837 168.561 565.58 169.818 564.03 169.818C562.48 169.818 561.223 168.561 561.223 167.011C561.223 165.461 562.48 164.204 564.03 164.204C565.58 164.204 566.837 165.461 566.837 167.011Z" />
          <path d="M559.995 167.011C559.995 168.561 558.739 169.818 557.189 169.818C555.639 169.818 554.381 168.561 554.381 167.011C554.381 165.461 555.639 164.204 557.189 164.204C558.739 164.204 559.995 165.461 559.995 167.011Z" />
          <path d="M553.153 167.011C553.153 168.561 551.897 169.818 550.347 169.818C548.797 169.818 547.539 168.561 547.539 167.011C547.539 165.461 548.797 164.204 550.347 164.204C551.897 164.204 553.153 165.461 553.153 167.011Z" />
          <path d="M553.153 160.169C553.153 161.719 551.897 162.976 550.347 162.976C548.797 162.976 547.539 161.719 547.539 160.169C547.539 158.619 548.797 157.363 550.347 157.363C551.897 157.363 553.153 158.619 553.153 160.169Z" />
          <path d="M553.153 153.328C553.153 154.877 551.897 156.134 550.347 156.134C548.797 156.134 547.539 154.877 547.539 153.328C547.539 151.778 548.797 150.521 550.347 150.521C551.897 150.521 553.153 151.778 553.153 153.328Z" />
          <path d="M553.153 146.486C553.153 148.036 551.897 149.292 550.347 149.292C548.797 149.292 547.539 148.036 547.539 146.486C547.539 144.936 548.797 143.679 550.347 143.679C551.897 143.679 553.153 144.936 553.153 146.486Z" />
          <path d="M553.153 139.644C553.153 141.194 551.897 142.451 550.347 142.451C548.797 142.451 547.539 141.194 547.539 139.644C547.539 138.094 548.797 136.837 550.347 136.837C551.897 136.837 553.153 138.094 553.153 139.644Z" />
          <path d="M553.153 132.802C553.153 134.352 551.897 135.608 550.347 135.608C548.797 135.608 547.539 134.352 547.539 132.802C547.539 131.252 548.797 129.995 550.347 129.995C551.897 129.995 553.153 131.252 553.153 132.802Z" />
          <path d="M553.153 125.96C553.153 127.51 551.897 128.767 550.347 128.767C548.797 128.767 547.539 127.51 547.539 125.96C547.539 124.41 548.797 123.153 550.347 123.153C551.897 123.153 553.153 124.41 553.153 125.96Z" />
          <path d="M546.311 132.802C546.311 134.352 545.055 135.608 543.505 135.608C541.955 135.608 540.698 134.352 540.698 132.802C540.698 131.252 541.955 129.995 543.505 129.995C545.055 129.995 546.311 131.252 546.311 132.802Z" />
          <path d="M546.311 139.644C546.311 141.194 545.055 142.451 543.505 142.451C541.955 142.451 540.698 141.194 540.698 139.644C540.698 138.094 541.955 136.837 543.505 136.837C545.055 136.837 546.311 138.094 546.311 139.644Z" />
          <path d="M546.311 146.486C546.311 148.036 545.055 149.292 543.505 149.292C541.955 149.292 540.698 148.036 540.698 146.486C540.698 144.936 541.955 143.679 543.505 143.679C545.055 143.679 546.311 144.936 546.311 146.486Z" />
          <path d="M546.311 153.328C546.311 154.877 545.055 156.134 543.505 156.134C541.955 156.134 540.698 154.877 540.698 153.328C540.698 151.778 541.955 150.521 543.505 150.521C545.055 150.521 546.311 151.778 546.311 153.328Z" />
          <path d="M546.311 160.169C546.311 161.719 545.055 162.976 543.505 162.976C541.955 162.976 540.698 161.719 540.698 160.169C540.698 158.619 541.955 157.363 543.505 157.363C545.055 157.363 546.311 158.619 546.311 160.169Z" />
          <path d="M546.311 167.011C546.311 168.561 545.055 169.818 543.505 169.818C541.955 169.818 540.698 168.561 540.698 167.011C540.698 165.461 541.955 164.204 543.505 164.204C545.055 164.204 546.311 165.461 546.311 167.011Z" />
          <path d="M539.469 167.011C539.469 168.561 538.213 169.818 536.663 169.818C535.113 169.818 533.856 168.561 533.856 167.011C533.856 165.461 535.113 164.204 536.663 164.204C538.213 164.204 539.469 165.461 539.469 167.011Z" />
          <path d="M539.469 160.169C539.469 161.719 538.213 162.976 536.663 162.976C535.113 162.976 533.856 161.719 533.856 160.169C533.856 158.619 535.113 157.363 536.663 157.363C538.213 157.363 539.469 158.619 539.469 160.169Z" />
          <path d="M539.469 153.328C539.469 154.877 538.213 156.134 536.663 156.134C535.113 156.134 533.856 154.877 533.856 153.328C533.856 151.778 535.113 150.521 536.663 150.521C538.213 150.521 539.469 151.778 539.469 153.328Z" />
          <path d="M539.469 146.486C539.469 148.036 538.213 149.292 536.663 149.292C535.113 149.292 533.856 148.036 533.856 146.486C533.856 144.936 535.113 143.679 536.663 143.679C538.213 143.679 539.469 144.936 539.469 146.486Z" />
          <path d="M539.469 139.644C539.469 141.194 538.213 142.451 536.663 142.451C535.113 142.451 533.856 141.194 533.856 139.644C533.856 138.094 535.113 136.837 536.663 136.837C538.213 136.837 539.469 138.094 539.469 139.644Z" />
          <path d="M539.469 132.802C539.469 134.352 538.213 135.608 536.663 135.608C535.113 135.608 533.856 134.352 533.856 132.802C533.856 131.252 535.113 129.995 536.663 129.995C538.213 129.995 539.469 131.252 539.469 132.802Z" />
          <path d="M532.628 132.802C532.628 134.352 531.371 135.608 529.821 135.608C528.271 135.608 527.014 134.352 527.014 132.802C527.014 131.252 528.271 129.995 529.821 129.995C531.371 129.995 532.628 131.252 532.628 132.802Z" />
          <path d="M525.786 132.802C525.786 134.352 524.53 135.608 522.98 135.608C521.43 135.608 520.172 134.352 520.172 132.802C520.172 131.252 521.43 129.995 522.98 129.995C524.53 129.995 525.786 131.252 525.786 132.802Z" />
          <path d="M518.944 132.802C518.944 134.352 517.687 135.608 516.137 135.608C514.587 135.608 513.33 134.352 513.33 132.802C513.33 131.252 514.587 129.995 516.137 129.995C517.687 129.995 518.944 131.252 518.944 132.802Z" />
          <path d="M518.944 139.644C518.944 141.194 517.687 142.451 516.137 142.451C514.587 142.451 513.33 141.194 513.33 139.644C513.33 138.094 514.587 136.837 516.137 136.837C517.687 136.837 518.944 138.094 518.944 139.644Z" />
          <path d="M518.944 146.486C518.944 148.036 517.687 149.292 516.137 149.292C514.587 149.292 513.33 148.036 513.33 146.486C513.33 144.936 514.587 143.679 516.137 143.679C517.687 143.679 518.944 144.936 518.944 146.486Z" />
          <path d="M525.786 146.486C525.786 148.036 524.53 149.292 522.98 149.292C521.43 149.292 520.172 148.036 520.172 146.486C520.172 144.936 521.43 143.679 522.98 143.679C524.53 143.679 525.786 144.936 525.786 146.486Z" />
          <path d="M525.786 139.644C525.786 141.194 524.53 142.451 522.98 142.451C521.43 142.451 520.172 141.194 520.172 139.644C520.172 138.094 521.43 136.837 522.98 136.837C524.53 136.837 525.786 138.094 525.786 139.644Z" />
          <path d="M532.628 139.644C532.628 141.194 531.371 142.451 529.821 142.451C528.271 142.451 527.014 141.194 527.014 139.644C527.014 138.094 528.271 136.837 529.821 136.837C531.371 136.837 532.628 138.094 532.628 139.644Z" />
          <path d="M532.628 146.486C532.628 148.036 531.371 149.292 529.821 149.292C528.271 149.292 527.014 148.036 527.014 146.486C527.014 144.936 528.271 143.679 529.821 143.679C531.371 143.679 532.628 144.936 532.628 146.486Z" />
          <path d="M532.628 153.328C532.628 154.877 531.371 156.134 529.821 156.134C528.271 156.134 527.014 154.877 527.014 153.328C527.014 151.778 528.271 150.521 529.821 150.521C531.371 150.521 532.628 151.778 532.628 153.328Z" />
          <path d="M532.628 160.169C532.628 161.719 531.371 162.976 529.821 162.976C528.271 162.976 527.014 161.719 527.014 160.169C527.014 158.619 528.271 157.363 529.821 157.363C531.371 157.363 532.628 158.619 532.628 160.169Z" />
          <path d="M532.628 167.011C532.628 168.561 531.371 169.818 529.821 169.818C528.271 169.818 527.014 168.561 527.014 167.011C527.014 165.461 528.271 164.204 529.821 164.204C531.371 164.204 532.628 165.461 532.628 167.011Z" />
          <path d="M525.786 167.011C525.786 168.561 524.53 169.818 522.98 169.818C521.43 169.818 520.172 168.561 520.172 167.011C520.172 165.461 521.43 164.204 522.98 164.204C524.53 164.204 525.786 165.461 525.786 167.011Z" />
          <path d="M525.786 160.169C525.786 161.719 524.53 162.976 522.98 162.976C521.43 162.976 520.172 161.719 520.172 160.169C520.172 158.619 521.43 157.363 522.98 157.363C524.53 157.363 525.786 158.619 525.786 160.169Z" />
          <path d="M525.786 153.328C525.786 154.877 524.53 156.134 522.98 156.134C521.43 156.134 520.172 154.877 520.172 153.328C520.172 151.778 521.43 150.521 522.98 150.521C524.53 150.521 525.786 151.778 525.786 153.328Z" />
          <path d="M518.944 153.328C518.944 154.877 517.687 156.134 516.137 156.134C514.587 156.134 513.33 154.877 513.33 153.328C513.33 151.778 514.587 150.521 516.137 150.521C517.687 150.521 518.944 151.778 518.944 153.328Z" />
          <path d="M518.944 160.169C518.944 161.719 517.687 162.976 516.137 162.976C514.587 162.976 513.33 161.719 513.33 160.169C513.33 158.619 514.587 157.363 516.137 157.363C517.687 157.363 518.944 158.619 518.944 160.169Z" />
          <path d="M518.944 167.011C518.944 168.561 517.687 169.818 516.137 169.818C514.587 169.818 513.33 168.561 513.33 167.011C513.33 165.461 514.587 164.204 516.137 164.204C517.687 164.204 518.944 165.461 518.944 167.011Z" />
          <path d="M765.25 57.5417C765.25 59.0916 763.994 60.3484 762.444 60.3484C760.894 60.3484 759.637 59.0916 759.637 57.5417C759.637 55.9917 760.894 54.7349 762.444 54.7349C763.994 54.7349 765.25 55.9917 765.25 57.5417Z" />
          <path d="M772.092 57.5417C772.092 59.0916 770.836 60.3484 769.286 60.3484C767.736 60.3484 766.479 59.0916 766.479 57.5417C766.479 55.9917 767.736 54.7349 769.286 54.7349C770.836 54.7349 772.092 55.9917 772.092 57.5417Z" />
          <path d="M772.092 64.3832C772.092 65.9331 770.836 67.1899 769.286 67.1899C767.736 67.1899 766.479 65.9331 766.479 64.3832C766.479 62.8333 767.736 61.5765 769.286 61.5765C770.836 61.5765 772.092 62.8333 772.092 64.3832Z" />
          <path d="M778.934 64.3832C778.934 65.9331 777.678 67.1899 776.128 67.1899C774.578 67.1899 773.32 65.9331 773.32 64.3832C773.32 62.8333 774.578 61.5765 776.128 61.5765C777.678 61.5765 778.934 62.8333 778.934 64.3832Z" />
          <path d="M772.092 71.2255C772.092 72.7754 770.836 74.0322 769.286 74.0322C767.736 74.0322 766.479 72.7754 766.479 71.2255C766.479 69.6756 767.736 68.4188 769.286 68.4188C770.836 68.4188 772.092 69.6756 772.092 71.2255Z" />
          <path d="M778.934 71.2255C778.934 72.7754 777.678 74.0322 776.128 74.0322C774.578 74.0322 773.32 72.7754 773.32 71.2255C773.32 69.6756 774.578 68.4188 776.128 68.4188C777.678 68.4188 778.934 69.6756 778.934 71.2255Z" />
          <path d="M785.776 71.2255C785.776 72.7754 784.519 74.0322 782.969 74.0322C781.419 74.0322 780.162 72.7754 780.162 71.2255C780.162 69.6756 781.419 68.4188 782.969 68.4188C784.519 68.4188 785.776 69.6756 785.776 71.2255Z" />
          <path d="M792.617 78.0673C792.617 79.6172 791.361 80.874 789.811 80.874C788.261 80.874 787.004 79.6172 787.004 78.0673C787.004 76.5173 788.261 75.2606 789.811 75.2606C791.361 75.2606 792.617 76.5173 792.617 78.0673Z" />
          <path d="M785.776 78.0673C785.776 79.6172 784.519 80.874 782.969 80.874C781.419 80.874 780.162 79.6172 780.162 78.0673C780.162 76.5173 781.419 75.2606 782.969 75.2606C784.519 75.2606 785.776 76.5173 785.776 78.0673Z" />
          <path d="M778.934 78.0673C778.934 79.6172 777.678 80.874 776.128 80.874C774.578 80.874 773.32 79.6172 773.32 78.0673C773.32 76.5173 774.578 75.2606 776.128 75.2606C777.678 75.2606 778.934 76.5173 778.934 78.0673Z" />
          <path d="M778.934 84.9091C778.934 86.459 777.678 87.7158 776.128 87.7158C774.578 87.7158 773.32 86.459 773.32 84.9091C773.32 83.3591 774.578 82.1024 776.128 82.1024C777.678 82.1024 778.934 83.3591 778.934 84.9091Z" />
          <path d="M778.934 91.7506C778.934 93.3006 777.678 94.5574 776.128 94.5574C774.578 94.5574 773.32 93.3006 773.32 91.7506C773.32 90.2007 774.578 88.9439 776.128 88.9439C777.678 88.9439 778.934 90.2007 778.934 91.7506Z" />
          <path d="M772.092 91.7506C772.092 93.3006 770.836 94.5574 769.286 94.5574C767.736 94.5574 766.479 93.3006 766.479 91.7506C766.479 90.2007 767.736 88.9439 769.286 88.9439C770.836 88.9439 772.092 90.2007 772.092 91.7506Z" />
          <path d="M765.25 98.5927C765.25 100.143 763.994 101.399 762.444 101.399C760.894 101.399 759.637 100.143 759.637 98.5927C759.637 97.0427 760.894 95.7859 762.444 95.7859C763.994 95.7859 765.25 97.0427 765.25 98.5927Z" />
          <path d="M765.25 105.435C765.25 106.985 763.994 108.241 762.444 108.241C760.894 108.241 759.637 106.985 759.637 105.435C759.637 103.885 760.894 102.628 762.444 102.628C763.994 102.628 765.25 103.885 765.25 105.435Z" />
          <path d="M765.25 112.277C765.25 113.826 763.994 115.083 762.444 115.083C760.894 115.083 759.637 113.826 759.637 112.277C759.637 110.727 760.894 109.47 762.444 109.47C763.994 109.47 765.25 110.727 765.25 112.277Z" />
          <path d="M772.092 112.277C772.092 113.826 770.836 115.083 769.286 115.083C767.736 115.083 766.479 113.826 766.479 112.277C766.479 110.727 767.736 109.47 769.286 109.47C770.836 109.47 772.092 110.727 772.092 112.277Z" />
          <path d="M772.092 119.118C772.092 120.668 770.836 121.925 769.286 121.925C767.736 121.925 766.479 120.668 766.479 119.118C766.479 117.568 767.736 116.312 769.286 116.312C770.836 116.312 772.092 117.568 772.092 119.118Z" />
          <path d="M772.092 125.96C772.092 127.51 770.836 128.767 769.286 128.767C767.736 128.767 766.479 127.51 766.479 125.96C766.479 124.41 767.736 123.153 769.286 123.153C770.836 123.153 772.092 124.41 772.092 125.96Z" />
          <path d="M778.934 125.96C778.934 127.51 777.678 128.767 776.128 128.767C774.578 128.767 773.32 127.51 773.32 125.96C773.32 124.41 774.578 123.153 776.128 123.153C777.678 123.153 778.934 124.41 778.934 125.96Z" />
          <path d="M792.617 125.96C792.617 127.51 791.361 128.767 789.811 128.767C788.261 128.767 787.004 127.51 787.004 125.96C787.004 124.41 788.261 123.153 789.811 123.153C791.361 123.153 792.617 124.41 792.617 125.96Z" />
          <path d="M772.092 132.802C772.092 134.352 770.836 135.608 769.286 135.608C767.736 135.608 766.479 134.352 766.479 132.802C766.479 131.252 767.736 129.995 769.286 129.995C770.836 129.995 772.092 131.252 772.092 132.802Z" />
          <path d="M772.092 139.644C772.092 141.194 770.836 142.451 769.286 142.451C767.736 142.451 766.479 141.194 766.479 139.644C766.479 138.094 767.736 136.837 769.286 136.837C770.836 136.837 772.092 138.094 772.092 139.644Z" />
          <path d="M772.092 146.486C772.092 148.036 770.836 149.292 769.286 149.292C767.736 149.292 766.479 148.036 766.479 146.486C766.479 144.936 767.736 143.679 769.286 143.679C770.836 143.679 772.092 144.936 772.092 146.486Z" />
          <path d="M765.25 125.96C765.25 127.51 763.994 128.767 762.444 128.767C760.894 128.767 759.637 127.51 759.637 125.96C759.637 124.41 760.894 123.153 762.444 123.153C763.994 123.153 765.25 124.41 765.25 125.96Z" />
          <path d="M765.25 119.118C765.25 120.668 763.994 121.925 762.444 121.925C760.894 121.925 759.637 120.668 759.637 119.118C759.637 117.568 760.894 116.312 762.444 116.312C763.994 116.312 765.25 117.568 765.25 119.118Z" />
          <path d="M772.092 84.9091C772.092 86.459 770.836 87.7158 769.286 87.7158C767.736 87.7158 766.479 86.459 766.479 84.9091C766.479 83.3591 767.736 82.1024 769.286 82.1024C770.836 82.1024 772.092 83.3591 772.092 84.9091Z" />
          <path d="M772.092 78.0673C772.092 79.6172 770.836 80.874 769.286 80.874C767.736 80.874 766.479 79.6172 766.479 78.0673C766.479 76.5173 767.736 75.2606 769.286 75.2606C770.836 75.2606 772.092 76.5173 772.092 78.0673Z" />
          <path d="M765.25 78.0673C765.25 79.6172 763.994 80.874 762.444 80.874C760.894 80.874 759.637 79.6172 759.637 78.0673C759.637 76.5173 760.894 75.2606 762.444 75.2606C763.994 75.2606 765.25 76.5173 765.25 78.0673Z" />
          <path d="M765.25 84.9091C765.25 86.459 763.994 87.7158 762.444 87.7158C760.894 87.7158 759.637 86.459 759.637 84.9091C759.637 83.3591 760.894 82.1024 762.444 82.1024C763.994 82.1024 765.25 83.3591 765.25 84.9091Z" />
          <path d="M758.408 84.9091C758.408 86.459 757.152 87.7158 755.602 87.7158C754.052 87.7158 752.795 86.459 752.795 84.9091C752.795 83.3591 754.052 82.1024 755.602 82.1024C757.152 82.1024 758.408 83.3591 758.408 84.9091Z" />
          <path d="M758.408 91.7506C758.408 93.3006 757.152 94.5574 755.602 94.5574C754.052 94.5574 752.795 93.3006 752.795 91.7506C752.795 90.2007 754.052 88.9439 755.602 88.9439C757.152 88.9439 758.408 90.2007 758.408 91.7506Z" />
          <path d="M751.566 84.9091C751.566 86.459 750.31 87.7158 748.76 87.7158C747.21 87.7158 745.953 86.459 745.953 84.9091C745.953 83.3591 747.21 82.1024 748.76 82.1024C750.31 82.1024 751.566 83.3591 751.566 84.9091Z" />
          <path d="M744.725 84.9091C744.725 86.459 743.468 87.7158 741.918 87.7158C740.368 87.7158 739.111 86.459 739.111 84.9091C739.111 83.3591 740.368 82.1024 741.918 82.1024C743.468 82.1024 744.725 83.3591 744.725 84.9091Z" />
          <path d="M744.725 91.7506C744.725 93.3006 743.468 94.5574 741.918 94.5574C740.368 94.5574 739.111 93.3006 739.111 91.7506C739.111 90.2007 740.368 88.9439 741.918 88.9439C743.468 88.9439 744.725 90.2007 744.725 91.7506Z" />
          <path d="M751.566 91.7506C751.566 93.3006 750.31 94.5574 748.76 94.5574C747.21 94.5574 745.953 93.3006 745.953 91.7506C745.953 90.2007 747.21 88.9439 748.76 88.9439C750.31 88.9439 751.566 90.2007 751.566 91.7506Z" />
          <path d="M744.725 98.5927C744.725 100.143 743.468 101.399 741.918 101.399C740.368 101.399 739.111 100.143 739.111 98.5927C739.111 97.0427 740.368 95.7859 741.918 95.7859C743.468 95.7859 744.725 97.0427 744.725 98.5927Z" />
          <path d="M744.725 105.435C744.725 106.985 743.468 108.241 741.918 108.241C740.368 108.241 739.111 106.985 739.111 105.435C739.111 103.885 740.368 102.628 741.918 102.628C743.468 102.628 744.725 103.885 744.725 105.435Z" />
          <path d="M737.883 105.435C737.883 106.985 736.626 108.241 735.077 108.241C733.527 108.241 732.269 106.985 732.269 105.435C732.269 103.885 733.527 102.628 735.077 102.628C736.626 102.628 737.883 103.885 737.883 105.435Z" />
          <path d="M731.041 105.435C731.041 106.985 729.785 108.241 728.235 108.241C726.685 108.241 725.427 106.985 725.427 105.435C725.427 103.885 726.685 102.628 728.235 102.628C729.785 102.628 731.041 103.885 731.041 105.435Z" />
          <path d="M724.199 105.435C724.199 106.985 722.943 108.241 721.393 108.241C719.843 108.241 718.585 106.985 718.585 105.435C718.585 103.885 719.843 102.628 721.393 102.628C722.943 102.628 724.199 103.885 724.199 105.435Z" />
          <path d="M724.199 112.277C724.199 113.826 722.943 115.083 721.393 115.083C719.843 115.083 718.585 113.826 718.585 112.277C718.585 110.727 719.843 109.47 721.393 109.47C722.943 109.47 724.199 110.727 724.199 112.277Z" />
          <path d="M717.357 112.277C717.357 113.826 716.101 115.083 714.551 115.083C713.001 115.083 711.744 113.826 711.744 112.277C711.744 110.727 713.001 109.47 714.551 109.47C716.101 109.47 717.357 110.727 717.357 112.277Z" />
          <path d="M717.357 119.118C717.357 120.668 716.101 121.925 714.551 121.925C713.001 121.925 711.744 120.668 711.744 119.118C711.744 117.568 713.001 116.312 714.551 116.312C716.101 116.312 717.357 117.568 717.357 119.118Z" />
          <path d="M717.357 125.96C717.357 127.51 716.101 128.767 714.551 128.767C713.001 128.767 711.744 127.51 711.744 125.96C711.744 124.41 713.001 123.153 714.551 123.153C716.101 123.153 717.357 124.41 717.357 125.96Z" />
          <path d="M710.515 125.96C710.515 127.51 709.259 128.767 707.709 128.767C706.159 128.767 704.902 127.51 704.902 125.96C704.902 124.41 706.159 123.153 707.709 123.153C709.259 123.153 710.515 124.41 710.515 125.96Z" />
          <path d="M710.515 132.802C710.515 134.352 709.259 135.608 707.709 135.608C706.159 135.608 704.902 134.352 704.902 132.802C704.902 131.252 706.159 129.995 707.709 129.995C709.259 129.995 710.515 131.252 710.515 132.802Z" />
          <path d="M717.357 132.802C717.357 134.352 716.101 135.608 714.551 135.608C713.001 135.608 711.744 134.352 711.744 132.802C711.744 131.252 713.001 129.995 714.551 129.995C716.101 129.995 717.357 131.252 717.357 132.802Z" />
          <path d="M710.515 139.644C710.515 141.194 709.259 142.451 707.709 142.451C706.159 142.451 704.902 141.194 704.902 139.644C704.902 138.094 706.159 136.837 707.709 136.837C709.259 136.837 710.515 138.094 710.515 139.644Z" />
          <path d="M717.357 139.644C717.357 141.194 716.101 142.451 714.551 142.451C713.001 142.451 711.744 141.194 711.744 139.644C711.744 138.094 713.001 136.837 714.551 136.837C716.101 136.837 717.357 138.094 717.357 139.644Z" />
          <path d="M710.515 146.486C710.515 148.036 709.259 149.292 707.709 149.292C706.159 149.292 704.902 148.036 704.902 146.486C704.902 144.936 706.159 143.679 707.709 143.679C709.259 143.679 710.515 144.936 710.515 146.486Z" />
          <path d="M710.515 153.328C710.515 154.877 709.259 156.134 707.709 156.134C706.159 156.134 704.902 154.877 704.902 153.328C704.902 151.778 706.159 150.521 707.709 150.521C709.259 150.521 710.515 151.778 710.515 153.328Z" />
          <path d="M778.934 57.5417C778.934 59.0916 777.678 60.3484 776.128 60.3484C774.578 60.3484 773.32 59.0916 773.32 57.5417C773.32 55.9917 774.578 54.7349 776.128 54.7349C777.678 54.7349 778.934 55.9917 778.934 57.5417Z" />
          <path d="M785.776 57.5417C785.776 59.0916 784.519 60.3484 782.969 60.3484C781.419 60.3484 780.162 59.0916 780.162 57.5417C780.162 55.9917 781.419 54.7349 782.969 54.7349C784.519 54.7349 785.776 55.9917 785.776 57.5417Z" />
          <path d="M751.566 37.0163C751.566 38.5662 750.31 39.823 748.76 39.823C747.21 39.823 745.953 38.5662 745.953 37.0163C745.953 35.4663 747.21 34.2095 748.76 34.2095C750.31 34.2095 751.566 35.4663 751.566 37.0163Z" />
          <path d="M758.408 37.0163C758.408 38.5662 757.152 39.823 755.602 39.823C754.052 39.823 752.795 38.5662 752.795 37.0163C752.795 35.4663 754.052 34.2095 755.602 34.2095C757.152 34.2095 758.408 35.4663 758.408 37.0163Z" />
          <path d="M765.25 37.0163C765.25 38.5662 763.994 39.823 762.444 39.823C760.894 39.823 759.637 38.5662 759.637 37.0163C759.637 35.4663 760.894 34.2095 762.444 34.2095C763.994 34.2095 765.25 35.4663 765.25 37.0163Z" />
          <path d="M772.092 37.0163C772.092 38.5662 770.836 39.823 769.286 39.823C767.736 39.823 766.479 38.5662 766.479 37.0163C766.479 35.4663 767.736 34.2095 769.286 34.2095C770.836 34.2095 772.092 35.4663 772.092 37.0163Z" />
          <path d="M778.934 37.0163C778.934 38.5662 777.678 39.823 776.128 39.823C774.578 39.823 773.32 38.5662 773.32 37.0163C773.32 35.4663 774.578 34.2095 776.128 34.2095C777.678 34.2095 778.934 35.4663 778.934 37.0163Z" />
          <path d="M778.934 43.8581C778.934 45.408 777.678 46.6648 776.128 46.6648C774.578 46.6648 773.32 45.408 773.32 43.8581C773.32 42.3081 774.578 41.0513 776.128 41.0513C777.678 41.0513 778.934 42.3081 778.934 43.8581Z" />
          <path d="M785.776 43.8581C785.776 45.408 784.519 46.6648 782.969 46.6648C781.419 46.6648 780.162 45.408 780.162 43.8581C780.162 42.3081 781.419 41.0513 782.969 41.0513C784.519 41.0513 785.776 42.3081 785.776 43.8581Z" />
          <path d="M792.617 43.8581C792.617 45.408 791.361 46.6648 789.811 46.6648C788.261 46.6648 787.004 45.408 787.004 43.8581C787.004 42.3081 788.261 41.0513 789.811 41.0513C791.361 41.0513 792.617 42.3081 792.617 43.8581Z" />
          <path d="M792.617 37.0163C792.617 38.5662 791.361 39.823 789.811 39.823C788.261 39.823 787.004 38.5662 787.004 37.0163C787.004 35.4663 788.261 34.2095 789.811 34.2095C791.361 34.2095 792.617 35.4663 792.617 37.0163Z" />
          <path d="M792.617 50.6999C792.617 52.2498 791.361 53.5066 789.811 53.5066C788.261 53.5066 787.004 52.2498 787.004 50.6999C787.004 49.1499 788.261 47.8931 789.811 47.8931C791.361 47.8931 792.617 49.1499 792.617 50.6999Z" />
          <path d="M607.888 43.8581C607.888 45.408 606.631 46.6648 605.081 46.6648C603.531 46.6648 602.274 45.408 602.274 43.8581C602.274 42.3081 603.531 41.0513 605.081 41.0513C606.631 41.0513 607.888 42.3081 607.888 43.8581Z" />
          <path d="M607.888 37.0163C607.888 38.5662 606.631 39.823 605.081 39.823C603.531 39.823 602.274 38.5662 602.274 37.0163C602.274 35.4663 603.531 34.2095 605.081 34.2095C606.631 34.2095 607.888 35.4663 607.888 37.0163Z" />
          <path d="M566.837 91.7506C566.837 93.3006 565.58 94.5574 564.03 94.5574C562.48 94.5574 561.223 93.3006 561.223 91.7506C561.223 90.2007 562.48 88.9439 564.03 88.9439C565.58 88.9439 566.837 90.2007 566.837 91.7506Z" />
          <path d="M566.837 84.9091C566.837 86.459 565.58 87.7158 564.03 87.7158C562.48 87.7158 561.223 86.459 561.223 84.9091C561.223 83.3591 562.48 82.1024 564.03 82.1024C565.58 82.1024 566.837 83.3591 566.837 84.9091Z" />
          <path d="M559.995 84.9091C559.995 86.459 558.739 87.7158 557.189 87.7158C555.639 87.7158 554.381 86.459 554.381 84.9091C554.381 83.3591 555.639 82.1024 557.189 82.1024C558.739 82.1024 559.995 83.3591 559.995 84.9091Z" />
          <path d="M559.995 78.0673C559.995 79.6172 558.739 80.874 557.189 80.874C555.639 80.874 554.381 79.6172 554.381 78.0673C554.381 76.5173 555.639 75.2606 557.189 75.2606C558.739 75.2606 559.995 76.5173 559.995 78.0673Z" />
          <path d="M553.153 78.0673C553.153 79.6172 551.897 80.874 550.347 80.874C548.797 80.874 547.539 79.6172 547.539 78.0673C547.539 76.5173 548.797 75.2606 550.347 75.2606C551.897 75.2606 553.153 76.5173 553.153 78.0673Z" />
          <path d="M553.153 98.5927C553.153 100.143 551.897 101.399 550.347 101.399C548.797 101.399 547.539 100.143 547.539 98.5927C547.539 97.0427 548.797 95.7859 550.347 95.7859C551.897 95.7859 553.153 97.0427 553.153 98.5927Z" />
          <path d="M546.311 91.7506C546.311 93.3006 545.055 94.5574 543.505 94.5574C541.955 94.5574 540.698 93.3006 540.698 91.7506C540.698 90.2007 541.955 88.9439 543.505 88.9439C545.055 88.9439 546.311 90.2007 546.311 91.7506Z" />
          <path d="M546.311 84.9091C546.311 86.459 545.055 87.7158 543.505 87.7158C541.955 87.7158 540.698 86.459 540.698 84.9091C540.698 83.3591 541.955 82.1024 543.505 82.1024C545.055 82.1024 546.311 83.3591 546.311 84.9091Z" />
          <path d="M539.469 84.9091C539.469 86.459 538.213 87.7158 536.663 87.7158C535.113 87.7158 533.856 86.459 533.856 84.9091C533.856 83.3591 535.113 82.1024 536.663 82.1024C538.213 82.1024 539.469 83.3591 539.469 84.9091Z" />
          <path d="M546.311 78.0673C546.311 79.6172 545.055 80.874 543.505 80.874C541.955 80.874 540.698 79.6172 540.698 78.0673C540.698 76.5173 541.955 75.2606 543.505 75.2606C545.055 75.2606 546.311 76.5173 546.311 78.0673Z" />
          <path d="M539.469 71.2255C539.469 72.7754 538.213 74.0322 536.663 74.0322C535.113 74.0322 533.856 72.7754 533.856 71.2255C533.856 69.6756 535.113 68.4188 536.663 68.4188C538.213 68.4188 539.469 69.6756 539.469 71.2255Z" />
          <path d="M525.786 50.6999C525.786 52.2498 524.53 53.5066 522.98 53.5066C521.43 53.5066 520.172 52.2498 520.172 50.6999C520.172 49.1499 521.43 47.8931 522.98 47.8931C524.53 47.8931 525.786 49.1499 525.786 50.6999Z" />
          <path d="M518.944 57.5417C518.944 59.0916 517.687 60.3484 516.137 60.3484C514.587 60.3484 513.33 59.0916 513.33 57.5417C513.33 55.9917 514.587 54.7349 516.137 54.7349C517.687 54.7349 518.944 55.9917 518.944 57.5417Z" />
          <path d="M512.102 64.3832C512.102 65.9331 510.845 67.1899 509.296 67.1899C507.746 67.1899 506.488 65.9331 506.488 64.3832C506.488 62.8333 507.746 61.5765 509.296 61.5765C510.845 61.5765 512.102 62.8333 512.102 64.3832Z" />
          <path d="M512.102 71.2255C512.102 72.7754 510.845 74.0322 509.296 74.0322C507.746 74.0322 506.488 72.7754 506.488 71.2255C506.488 69.6756 507.746 68.4188 509.296 68.4188C510.845 68.4188 512.102 69.6756 512.102 71.2255Z" />
          <path d="M512.102 78.0673C512.102 79.6172 510.845 80.874 509.296 80.874C507.746 80.874 506.488 79.6172 506.488 78.0673C506.488 76.5173 507.746 75.2606 509.296 75.2606C510.845 75.2606 512.102 76.5173 512.102 78.0673Z" />
          <path d="M512.102 84.9091C512.102 86.459 510.845 87.7158 509.296 87.7158C507.746 87.7158 506.488 86.459 506.488 84.9091C506.488 83.3591 507.746 82.1024 509.296 82.1024C510.845 82.1024 512.102 83.3591 512.102 84.9091Z" />
          <path d="M518.944 91.7506C518.944 93.3006 517.687 94.5574 516.137 94.5574C514.587 94.5574 513.33 93.3006 513.33 91.7506C513.33 90.2007 514.587 88.9439 516.137 88.9439C517.687 88.9439 518.944 90.2007 518.944 91.7506Z" />
          <path d="M498.418 105.435C498.418 106.985 497.162 108.241 495.612 108.241C494.062 108.241 492.805 106.985 492.805 105.435C492.805 103.885 494.062 102.628 495.612 102.628C497.162 102.628 498.418 103.885 498.418 105.435Z" />
          <path d="M505.26 98.5927C505.26 100.143 504.004 101.399 502.454 101.399C500.904 101.399 499.646 100.143 499.646 98.5927C499.646 97.0427 500.904 95.7859 502.454 95.7859C504.004 95.7859 505.26 97.0427 505.26 98.5927Z" />
          <path d="M464.209 146.486C464.209 148.036 462.953 149.292 461.403 149.292C459.853 149.292 458.595 148.036 458.595 146.486C458.595 144.936 459.853 143.679 461.403 143.679C462.953 143.679 464.209 144.936 464.209 146.486Z" />
          <path d="M457.367 201.22C457.367 202.77 456.111 204.027 454.561 204.027C453.011 204.027 451.754 202.77 451.754 201.22C451.754 199.67 453.011 198.413 454.561 198.413C456.111 198.413 457.367 199.67 457.367 201.22Z" />
          <path d="M457.367 208.062C457.367 209.612 456.111 210.869 454.561 210.869C453.011 210.869 451.754 209.612 451.754 208.062C451.754 206.512 453.011 205.256 454.561 205.256C456.111 205.256 457.367 206.512 457.367 208.062Z" />
          <path d="M464.209 214.904C464.209 216.454 462.953 217.711 461.403 217.711C459.853 217.711 458.595 216.454 458.595 214.904C458.595 213.354 459.853 212.097 461.403 212.097C462.953 212.097 464.209 213.354 464.209 214.904Z" />
          <path d="M464.209 201.22C464.209 202.77 462.953 204.027 461.403 204.027C459.853 204.027 458.595 202.77 458.595 201.22C458.595 199.67 459.853 198.413 461.403 198.413C462.953 198.413 464.209 199.67 464.209 201.22Z" />
          <path d="M471.051 201.22C471.051 202.77 469.794 204.027 468.245 204.027C466.695 204.027 465.437 202.77 465.437 201.22C465.437 199.67 466.695 198.413 468.245 198.413C469.794 198.413 471.051 199.67 471.051 201.22Z" />
          <path d="M471.051 208.062C471.051 209.612 469.794 210.869 468.245 210.869C466.695 210.869 465.437 209.612 465.437 208.062C465.437 206.512 466.695 205.256 468.245 205.256C469.794 205.256 471.051 206.512 471.051 208.062Z" />
          <path d="M477.893 214.904C477.893 216.454 476.636 217.711 475.086 217.711C473.536 217.711 472.279 216.454 472.279 214.904C472.279 213.354 473.536 212.097 475.086 212.097C476.636 212.097 477.893 213.354 477.893 214.904Z" />
          <path d="M450.525 167.011C450.525 168.561 449.269 169.818 447.719 169.818C446.169 169.818 444.912 168.561 444.912 167.011C444.912 165.461 446.169 164.204 447.719 164.204C449.269 164.204 450.525 165.461 450.525 167.011Z" />
          <path d="M430 167.011C430 168.561 428.744 169.818 427.194 169.818C425.643 169.818 424.386 168.561 424.386 167.011C424.386 165.461 425.643 164.204 427.194 164.204C428.744 164.204 430 165.461 430 167.011Z" />
          <path d="M430 160.169C430 161.719 428.744 162.976 427.194 162.976C425.643 162.976 424.386 161.719 424.386 160.169C424.386 158.619 425.643 157.363 427.194 157.363C428.744 157.363 430 158.619 430 160.169Z" />
          <path d="M436.842 153.328C436.842 154.877 435.585 156.134 434.036 156.134C432.486 156.134 431.228 154.877 431.228 153.328C431.228 151.778 432.486 150.521 434.036 150.521C435.585 150.521 436.842 151.778 436.842 153.328Z" />
          <path d="M436.842 146.486C436.842 148.036 435.585 149.292 434.036 149.292C432.486 149.292 431.228 148.036 431.228 146.486C431.228 144.936 432.486 143.679 434.036 143.679C435.585 143.679 436.842 144.936 436.842 146.486Z" />
          <path d="M443.683 146.486C443.683 148.036 442.427 149.292 440.877 149.292C439.327 149.292 438.07 148.036 438.07 146.486C438.07 144.936 439.327 143.679 440.877 143.679C442.427 143.679 443.683 144.936 443.683 146.486Z" />
          <path d="M436.842 139.644C436.842 141.194 435.585 142.451 434.036 142.451C432.486 142.451 431.228 141.194 431.228 139.644C431.228 138.094 432.486 136.837 434.036 136.837C435.585 136.837 436.842 138.094 436.842 139.644Z" />
          <path d="M430 139.644C430 141.194 428.744 142.451 427.194 142.451C425.644 142.451 424.386 141.194 424.386 139.644C424.386 138.094 425.644 136.837 427.194 136.837C428.744 136.837 430 138.094 430 139.644Z" />
          <path d="M423.158 146.486C423.158 148.036 421.902 149.292 420.352 149.292C418.802 149.292 417.544 148.036 417.544 146.486C417.544 144.936 418.802 143.679 420.352 143.679C421.902 143.679 423.158 144.936 423.158 146.486Z" />
          <path d="M423.158 139.644C423.158 141.194 421.902 142.451 420.352 142.451C418.802 142.451 417.544 141.194 417.544 139.644C417.544 138.094 418.802 136.837 420.352 136.837C421.902 136.837 423.158 138.094 423.158 139.644Z" />
          <path d="M430 132.802C430 134.352 428.744 135.608 427.194 135.608C425.644 135.608 424.386 134.352 424.386 132.802C424.386 131.252 425.644 129.995 427.194 129.995C428.744 129.995 430 131.252 430 132.802Z" />
          <path d="M436.842 132.802C436.842 134.352 435.585 135.608 434.036 135.608C432.486 135.608 431.228 134.352 431.228 132.802C431.228 131.252 432.486 129.995 434.036 129.995C435.585 129.995 436.842 131.252 436.842 132.802Z" />
          <path d="M443.683 132.802C443.683 134.352 442.427 135.608 440.877 135.608C439.327 135.608 438.07 134.352 438.07 132.802C438.07 131.252 439.327 129.995 440.877 129.995C442.427 129.995 443.683 131.252 443.683 132.802Z" />
          <path d="M443.683 125.96C443.683 127.51 442.427 128.767 440.877 128.767C439.327 128.767 438.07 127.51 438.07 125.96C438.07 124.41 439.327 123.153 440.877 123.153C442.427 123.153 443.683 124.41 443.683 125.96Z" />
          <path d="M436.842 125.96C436.842 127.51 435.585 128.767 434.036 128.767C432.486 128.767 431.228 127.51 431.228 125.96C431.228 124.41 432.486 123.153 434.036 123.153C435.585 123.153 436.842 124.41 436.842 125.96Z" />
          <path d="M436.842 119.118C436.842 120.668 435.585 121.925 434.036 121.925C432.486 121.925 431.228 120.668 431.228 119.118C431.228 117.568 432.486 116.312 434.036 116.312C435.585 116.312 436.842 117.568 436.842 119.118Z" />
          <path d="M443.683 119.118C443.683 120.668 442.427 121.925 440.877 121.925C439.327 121.925 438.07 120.668 438.07 119.118C438.07 117.568 439.327 116.312 440.877 116.312C442.427 116.312 443.683 117.568 443.683 119.118Z" />
          <path d="M450.525 119.118C450.525 120.668 449.269 121.925 447.719 121.925C446.169 121.925 444.912 120.668 444.912 119.118C444.912 117.568 446.169 116.312 447.719 116.312C449.269 116.312 450.525 117.568 450.525 119.118Z" />
          <path d="M450.525 112.277C450.525 113.826 449.269 115.083 447.719 115.083C446.169 115.083 444.912 113.826 444.912 112.277C444.912 110.727 446.169 109.47 447.719 109.47C449.269 109.47 450.525 110.727 450.525 112.277Z" />
          <path d="M443.683 112.277C443.683 113.826 442.427 115.083 440.877 115.083C439.327 115.083 438.07 113.826 438.07 112.277C438.07 110.727 439.327 109.47 440.877 109.47C442.427 109.47 443.683 110.727 443.683 112.277Z" />
          <path d="M450.525 105.435C450.525 106.985 449.269 108.241 447.719 108.241C446.169 108.241 444.912 106.985 444.912 105.435C444.912 103.885 446.169 102.628 447.719 102.628C449.269 102.628 450.525 103.885 450.525 105.435Z" />
          <path d="M457.367 105.435C457.367 106.985 456.111 108.241 454.561 108.241C453.011 108.241 451.754 106.985 451.754 105.435C451.754 103.885 453.011 102.628 454.561 102.628C456.111 102.628 457.367 103.885 457.367 105.435Z" />
          <path d="M457.367 98.5927C457.367 100.143 456.111 101.399 454.561 101.399C453.011 101.399 451.754 100.143 451.754 98.5927C451.754 97.0427 453.011 95.7859 454.561 95.7859C456.111 95.7859 457.367 97.0427 457.367 98.5927Z" />
          <path d="M464.209 98.5927C464.209 100.143 462.953 101.399 461.403 101.399C459.853 101.399 458.595 100.143 458.595 98.5927C458.595 97.0427 459.853 95.7859 461.403 95.7859C462.953 95.7859 464.209 97.0427 464.209 98.5927Z" />
          <path d="M464.209 105.435C464.209 106.985 462.953 108.241 461.403 108.241C459.853 108.241 458.595 106.985 458.595 105.435C458.595 103.885 459.853 102.628 461.403 102.628C462.953 102.628 464.209 103.885 464.209 105.435Z" />
          <path d="M471.051 105.435C471.051 106.985 469.794 108.241 468.245 108.241C466.695 108.241 465.437 106.985 465.437 105.435C465.437 103.885 466.695 102.628 468.245 102.628C469.794 102.628 471.051 103.885 471.051 105.435Z" />
          <path d="M477.893 105.435C477.893 106.985 476.636 108.241 475.086 108.241C473.536 108.241 472.279 106.985 472.279 105.435C472.279 103.885 473.536 102.628 475.086 102.628C476.636 102.628 477.893 103.885 477.893 105.435Z" />
          <path d="M477.893 112.277C477.893 113.826 476.636 115.083 475.086 115.083C473.536 115.083 472.279 113.826 472.279 112.277C472.279 110.727 473.536 109.47 475.086 109.47C476.636 109.47 477.893 110.727 477.893 112.277Z" />
          <path d="M484.734 112.277C484.734 113.826 483.478 115.083 481.928 115.083C480.378 115.083 479.121 113.826 479.121 112.277C479.121 110.727 480.378 109.47 481.928 109.47C483.478 109.47 484.734 110.727 484.734 112.277Z" />
          <path d="M471.051 112.277C471.051 113.826 469.794 115.083 468.245 115.083C466.695 115.083 465.437 113.826 465.437 112.277C465.437 110.727 466.695 109.47 468.245 109.47C469.794 109.47 471.051 110.727 471.051 112.277Z" />
          <path d="M464.209 112.277C464.209 113.826 462.953 115.083 461.403 115.083C459.853 115.083 458.595 113.826 458.595 112.277C458.595 110.727 459.853 109.47 461.403 109.47C462.953 109.47 464.209 110.727 464.209 112.277Z" />
          <path d="M457.367 112.277C457.367 113.826 456.111 115.083 454.561 115.083C453.011 115.083 451.754 113.826 451.754 112.277C451.754 110.727 453.011 109.47 454.561 109.47C456.111 109.47 457.367 110.727 457.367 112.277Z" />
          <path d="M464.209 119.118C464.209 120.668 462.953 121.925 461.403 121.925C459.853 121.925 458.595 120.668 458.595 119.118C458.595 117.568 459.853 116.312 461.403 116.312C462.953 116.312 464.209 117.568 464.209 119.118Z" />
          <path d="M464.209 125.96C464.209 127.51 462.953 128.767 461.403 128.767C459.853 128.767 458.595 127.51 458.595 125.96C458.595 124.41 459.853 123.153 461.403 123.153C462.953 123.153 464.209 124.41 464.209 125.96Z" />
          <path d="M457.367 132.802C457.367 134.352 456.111 135.608 454.561 135.608C453.011 135.608 451.754 134.352 451.754 132.802C451.754 131.252 453.011 129.995 454.561 129.995C456.111 129.995 457.367 131.252 457.367 132.802Z" />
          <path d="M457.367 139.644C457.367 141.194 456.111 142.451 454.561 142.451C453.011 142.451 451.754 141.194 451.754 139.644C451.754 138.094 453.011 136.837 454.561 136.837C456.111 136.837 457.367 138.094 457.367 139.644Z" />
          <path d="M464.209 139.644C464.209 141.194 462.953 142.451 461.403 142.451C459.853 142.451 458.595 141.194 458.595 139.644C458.595 138.094 459.853 136.837 461.403 136.837C462.953 136.837 464.209 138.094 464.209 139.644Z" />
          <path d="M471.051 139.644C471.051 141.194 469.794 142.451 468.245 142.451C466.695 142.451 465.437 141.194 465.437 139.644C465.437 138.094 466.695 136.837 468.245 136.837C469.794 136.837 471.051 138.094 471.051 139.644Z" />
          <path d="M471.051 146.486C471.051 148.036 469.794 149.292 468.245 149.292C466.695 149.292 465.437 148.036 465.437 146.486C465.437 144.936 466.695 143.679 468.245 143.679C469.794 143.679 471.051 144.936 471.051 146.486Z" />
          <path d="M477.893 146.486C477.893 148.036 476.636 149.292 475.086 149.292C473.536 149.292 472.279 148.036 472.279 146.486C472.279 144.936 473.536 143.679 475.086 143.679C476.636 143.679 477.893 144.936 477.893 146.486Z" />
          <path d="M484.734 146.486C484.734 148.036 483.478 149.292 481.928 149.292C480.378 149.292 479.121 148.036 479.121 146.486C479.121 144.936 480.378 143.679 481.928 143.679C483.478 143.679 484.734 144.936 484.734 146.486Z" />
          <path d="M484.734 139.644C484.734 141.194 483.478 142.451 481.928 142.451C480.378 142.451 479.121 141.194 479.121 139.644C479.121 138.094 480.378 136.837 481.928 136.837C483.478 136.837 484.734 138.094 484.734 139.644Z" />
          <path d="M477.893 132.802C477.893 134.352 476.636 135.608 475.086 135.608C473.536 135.608 472.279 134.352 472.279 132.802C472.279 131.252 473.536 129.995 475.086 129.995C476.636 129.995 477.893 131.252 477.893 132.802Z" />
          <path d="M477.893 125.96C477.893 127.51 476.636 128.767 475.086 128.767C473.536 128.767 472.279 127.51 472.279 125.96C472.279 124.41 473.536 123.153 475.086 123.153C476.636 123.153 477.893 124.41 477.893 125.96Z" />
          <path d="M471.051 119.118C471.051 120.668 469.794 121.925 468.245 121.925C466.695 121.925 465.437 120.668 465.437 119.118C465.437 117.568 466.695 116.312 468.245 116.312C469.794 116.312 471.051 117.568 471.051 119.118Z" />
          <path d="M471.051 125.96C471.051 127.51 469.794 128.767 468.245 128.767C466.695 128.767 465.437 127.51 465.437 125.96C465.437 124.41 466.695 123.153 468.245 123.153C469.794 123.153 471.051 124.41 471.051 125.96Z" />
          <path d="M430 180.695C430 182.245 428.744 183.502 427.194 183.502C425.643 183.502 424.386 182.245 424.386 180.695C424.386 179.145 425.643 177.888 427.194 177.888C428.744 177.888 430 179.145 430 180.695Z" />
          <path d="M430 187.537C430 189.087 428.744 190.344 427.194 190.344C425.643 190.344 424.386 189.087 424.386 187.537C424.386 185.987 425.643 184.73 427.194 184.73C428.744 184.73 430 185.987 430 187.537Z" />
          <path d="M436.842 187.537C436.842 189.087 435.585 190.344 434.036 190.344C432.486 190.344 431.228 189.087 431.228 187.537C431.228 185.987 432.486 184.73 434.036 184.73C435.585 184.73 436.842 185.987 436.842 187.537Z" />
          <path d="M436.842 194.379C436.842 195.929 435.585 197.185 434.036 197.185C432.486 197.185 431.228 195.929 431.228 194.379C431.228 192.829 432.486 191.572 434.036 191.572C435.585 191.572 436.842 192.829 436.842 194.379Z" />
          <path d="M430 194.379C430 195.929 428.744 197.185 427.194 197.185C425.643 197.185 424.386 195.929 424.386 194.379C424.386 192.829 425.643 191.572 427.194 191.572C428.744 191.572 430 192.829 430 194.379Z" />
          <path d="M430 208.062C430 209.612 428.744 210.869 427.194 210.869C425.643 210.869 424.386 209.612 424.386 208.062C424.386 206.512 425.643 205.256 427.194 205.256C428.744 205.256 430 206.512 430 208.062Z" />
          <path d="M436.842 201.22C436.842 202.77 435.585 204.027 434.036 204.027C432.486 204.027 431.228 202.77 431.228 201.22C431.228 199.67 432.486 198.413 434.036 198.413C435.585 198.413 436.842 199.67 436.842 201.22Z" />
          <path d="M443.683 208.062C443.683 209.612 442.427 210.869 440.877 210.869C439.327 210.869 438.07 209.612 438.07 208.062C438.07 206.512 439.327 205.256 440.877 205.256C442.427 205.256 443.683 206.512 443.683 208.062Z" />
          <path d="M450.525 214.904C450.525 216.454 449.269 217.711 447.719 217.711C446.169 217.711 444.912 216.454 444.912 214.904C444.912 213.354 446.169 212.097 447.719 212.097C449.269 212.097 450.525 213.354 450.525 214.904Z" />
          <path d="M443.683 221.746C443.683 223.296 442.427 224.553 440.877 224.553C439.327 224.553 438.07 223.296 438.07 221.746C438.07 220.196 439.327 218.939 440.877 218.939C442.427 218.939 443.683 220.196 443.683 221.746Z" />
          <path d="M423.158 201.22C423.158 202.77 421.901 204.027 420.351 204.027C418.801 204.027 417.544 202.77 417.544 201.22C417.544 199.67 418.801 198.413 420.351 198.413C421.901 198.413 423.158 199.67 423.158 201.22Z" />
          <path d="M409.474 194.379C409.474 195.929 408.218 197.185 406.668 197.185C405.118 197.185 403.861 195.929 403.861 194.379C403.861 192.829 405.118 191.572 406.668 191.572C408.218 191.572 409.474 192.829 409.474 194.379Z" />
          <path d="M313.689 112.277C313.689 113.826 312.432 115.083 310.882 115.083C309.332 115.083 308.075 113.826 308.075 112.277C308.075 110.727 309.332 109.47 310.882 109.47C312.432 109.47 313.689 110.727 313.689 112.277Z" />
          <path d="M300.005 112.277C300.005 113.826 298.748 115.083 297.198 115.083C295.648 115.083 294.391 113.826 294.391 112.277C294.391 110.727 295.648 109.47 297.198 109.47C298.748 109.47 300.005 110.727 300.005 112.277Z" />
          <path d="M300.005 91.7506C300.005 93.3006 298.748 94.5574 297.198 94.5574C295.648 94.5574 294.391 93.3006 294.391 91.7506C294.391 90.2007 295.648 88.9439 297.198 88.9439C298.748 88.9439 300.005 90.2007 300.005 91.7506Z" />
          <path d="M306.847 43.8581C306.847 45.408 305.59 46.6648 304.04 46.6648C302.49 46.6648 301.233 45.408 301.233 43.8581C301.233 42.3081 302.49 41.0513 304.04 41.0513C305.59 41.0513 306.847 42.3081 306.847 43.8581Z" />
          <path d="M313.689 43.8581C313.689 45.408 312.432 46.6648 310.882 46.6648C309.332 46.6648 308.075 45.408 308.075 43.8581C308.075 42.3081 309.332 41.0513 310.882 41.0513C312.432 41.0513 313.689 42.3081 313.689 43.8581Z" />
          <path d="M313.689 37.0163C313.689 38.5662 312.432 39.823 310.882 39.823C309.332 39.823 308.075 38.5662 308.075 37.0163C308.075 35.4663 309.332 34.2095 310.882 34.2095C312.432 34.2095 313.689 35.4663 313.689 37.0163Z" />
          <path d="M313.689 30.1742C313.689 31.7242 312.432 32.981 310.882 32.981C309.332 32.981 308.075 31.7242 308.075 30.1742C308.075 28.6243 309.332 27.3675 310.882 27.3675C312.432 27.3675 313.689 28.6243 313.689 30.1742Z" />
          <path d="M320.531 30.1742C320.531 31.7242 319.274 32.981 317.724 32.981C316.174 32.981 314.917 31.7242 314.917 30.1742C314.917 28.6243 316.174 27.3675 317.724 27.3675C319.274 27.3675 320.531 28.6243 320.531 30.1742Z" />
          <path d="M320.531 23.3324C320.531 24.8824 319.274 26.1392 317.724 26.1392C316.174 26.1392 314.917 24.8824 314.917 23.3324C314.917 21.7825 316.174 20.5257 317.724 20.5257C319.274 20.5257 320.531 21.7825 320.531 23.3324Z" />
          <path d="M327.372 23.3324C327.372 24.8824 326.116 26.1392 324.566 26.1392C323.016 26.1392 321.759 24.8824 321.759 23.3324C321.759 21.7825 323.016 20.5257 324.566 20.5257C326.116 20.5257 327.372 21.7825 327.372 23.3324Z" />
          <path d="M334.214 23.3324C334.214 24.8824 332.957 26.1392 331.407 26.1392C329.857 26.1392 328.601 24.8824 328.601 23.3324C328.601 21.7825 329.857 20.5257 331.407 20.5257C332.957 20.5257 334.214 21.7825 334.214 23.3324Z" />
          <path d="M341.056 23.3324C341.056 24.8824 339.799 26.1392 338.249 26.1392C336.699 26.1392 335.443 24.8824 335.443 23.3324C335.443 21.7825 336.699 20.5257 338.249 20.5257C339.799 20.5257 341.056 21.7825 341.056 23.3324Z" />
          <path d="M347.898 23.3324C347.898 24.8824 346.641 26.1392 345.091 26.1392C343.541 26.1392 342.284 24.8824 342.284 23.3324C342.284 21.7825 343.541 20.5257 345.091 20.5257C346.641 20.5257 347.898 21.7825 347.898 23.3324Z" />
          <path d="M354.74 23.3324C354.74 24.8824 353.483 26.1392 351.933 26.1392C350.383 26.1392 349.126 24.8824 349.126 23.3324C349.126 21.7825 350.383 20.5257 351.933 20.5257C353.483 20.5257 354.74 21.7825 354.74 23.3324Z" />
          <path d="M354.74 30.1742C354.74 31.7242 353.483 32.981 351.933 32.981C350.383 32.981 349.126 31.7242 349.126 30.1742C349.126 28.6243 350.383 27.3675 351.933 27.3675C353.483 27.3675 354.74 28.6243 354.74 30.1742Z" />
          <path d="M361.582 30.1742C361.582 31.7242 360.325 32.981 358.775 32.981C357.225 32.981 355.968 31.7242 355.968 30.1742C355.968 28.6243 357.225 27.3675 358.775 27.3675C360.325 27.3675 361.582 28.6243 361.582 30.1742Z" />
          <path d="M368.424 30.1742C368.424 31.7242 367.167 32.981 365.617 32.981C364.067 32.981 362.81 31.7242 362.81 30.1742C362.81 28.6243 364.067 27.3675 365.617 27.3675C367.167 27.3675 368.424 28.6243 368.424 30.1742Z" />
          <path d="M375.265 30.1742C375.265 31.7242 374.009 32.981 372.459 32.981C370.909 32.981 369.652 31.7242 369.652 30.1742C369.652 28.6243 370.909 27.3675 372.459 27.3675C374.009 27.3675 375.265 28.6243 375.265 30.1742Z" />
          <path d="M382.107 30.1742C382.107 31.7242 380.85 32.981 379.3 32.981C377.75 32.981 376.494 31.7242 376.494 30.1742C376.494 28.6243 377.75 27.3675 379.3 27.3675C380.85 27.3675 382.107 28.6243 382.107 30.1742Z" />
          <path d="M430 43.8581C430 45.408 428.744 46.6648 427.194 46.6648C425.643 46.6648 424.386 45.408 424.386 43.8581C424.386 42.3081 425.643 41.0513 427.194 41.0513C428.744 41.0513 430 42.3081 430 43.8581Z" />
          <path d="M436.842 43.8581C436.842 45.408 435.585 46.6648 434.036 46.6648C432.486 46.6648 431.228 45.408 431.228 43.8581C431.228 42.3081 432.486 41.0513 434.036 41.0513C435.585 41.0513 436.842 42.3081 436.842 43.8581Z" />
          <path d="M436.842 50.6999C436.842 52.2498 435.585 53.5066 434.036 53.5066C432.486 53.5066 431.228 52.2498 431.228 50.6999C431.228 49.1499 432.486 47.8931 434.036 47.8931C435.585 47.8931 436.842 49.1499 436.842 50.6999Z" />
          <path d="M436.842 57.5417C436.842 59.0916 435.585 60.3484 434.036 60.3484C432.486 60.3484 431.228 59.0916 431.228 57.5417C431.228 55.9917 432.486 54.7349 434.036 54.7349C435.585 54.7349 436.842 55.9917 436.842 57.5417Z" />
          <path d="M443.683 50.6999C443.683 52.2498 442.427 53.5066 440.877 53.5066C439.327 53.5066 438.07 52.2498 438.07 50.6999C438.07 49.1499 439.327 47.8931 440.877 47.8931C442.427 47.8931 443.683 49.1499 443.683 50.6999Z" />
          <path d="M450.525 37.0163C450.525 38.5662 449.269 39.823 447.719 39.823C446.169 39.823 444.912 38.5662 444.912 37.0163C444.912 35.4663 446.169 34.2095 447.719 34.2095C449.269 34.2095 450.525 35.4663 450.525 37.0163Z" />
          <path d="M484.734 30.1742C484.734 31.7242 483.478 32.981 481.928 32.981C480.378 32.981 479.121 31.7242 479.121 30.1742C479.121 28.6243 480.378 27.3675 481.928 27.3675C483.478 27.3675 484.734 28.6243 484.734 30.1742Z" />
          <path d="M498.418 30.1742C498.418 31.7242 497.162 32.981 495.612 32.981C494.062 32.981 492.805 31.7242 492.805 30.1742C492.805 28.6243 494.062 27.3675 495.612 27.3675C497.162 27.3675 498.418 28.6243 498.418 30.1742Z" />
          <path d="M498.418 23.3324C498.418 24.8824 497.162 26.1392 495.612 26.1392C494.062 26.1392 492.805 24.8824 492.805 23.3324C492.805 21.7825 494.062 20.5257 495.612 20.5257C497.162 20.5257 498.418 21.7825 498.418 23.3324Z" />
          <path d="M505.26 16.4906C505.26 18.0406 504.004 19.2974 502.454 19.2974C500.904 19.2974 499.646 18.0406 499.646 16.4906C499.646 14.9407 500.904 13.6839 502.454 13.6839C504.004 13.6839 505.26 14.9407 505.26 16.4906Z" />
          <path d="M443.683 37.0163C443.683 38.5662 442.427 39.823 440.877 39.823C439.327 39.823 438.07 38.5662 438.07 37.0163C438.07 35.4663 439.327 34.2095 440.877 34.2095C442.427 34.2095 443.683 35.4663 443.683 37.0163Z" />
          <path d="M368.424 23.3324C368.424 24.8824 367.167 26.1392 365.617 26.1392C364.067 26.1392 362.81 24.8824 362.81 23.3324C362.81 21.7825 364.067 20.5257 365.617 20.5257C367.167 20.5257 368.424 21.7825 368.424 23.3324Z" />
          <path d="M361.582 23.3324C361.582 24.8824 360.325 26.1392 358.775 26.1392C357.225 26.1392 355.968 24.8824 355.968 23.3324C355.968 21.7825 357.225 20.5257 358.775 20.5257C360.325 20.5257 361.582 21.7825 361.582 23.3324Z" />
          <path d="M347.898 16.4906C347.898 18.0406 346.641 19.2974 345.091 19.2974C343.541 19.2974 342.284 18.0406 342.284 16.4906C342.284 14.9407 343.541 13.6839 345.091 13.6839C346.641 13.6839 347.898 14.9407 347.898 16.4906Z" />
          <path d="M347.898 9.64883C347.898 11.1988 346.641 12.4556 345.091 12.4556C343.541 12.4556 342.284 11.1988 342.284 9.64883C342.284 8.09889 343.541 6.8421 345.091 6.8421C346.641 6.8421 347.898 8.09889 347.898 9.64883Z" />
          <path d="M354.74 9.64883C354.74 11.1988 353.483 12.4556 351.933 12.4556C350.383 12.4556 349.126 11.1988 349.126 9.64883C349.126 8.09889 350.383 6.8421 351.933 6.8421C353.483 6.8421 354.74 8.09889 354.74 9.64883Z" />
          <path d="M354.74 16.4906C354.74 18.0406 353.483 19.2974 351.933 19.2974C350.383 19.2974 349.126 18.0406 349.126 16.4906C349.126 14.9407 350.383 13.6839 351.933 13.6839C353.483 13.6839 354.74 14.9407 354.74 16.4906Z" />
          <path d="M361.582 16.4906C361.582 18.0406 360.325 19.2974 358.775 19.2974C357.225 19.2974 355.968 18.0406 355.968 16.4906C355.968 14.9407 357.225 13.6839 358.775 13.6839C360.325 13.6839 361.582 14.9407 361.582 16.4906Z" />
          <path d="M368.424 16.4906C368.424 18.0406 367.167 19.2974 365.617 19.2974C364.067 19.2974 362.81 18.0406 362.81 16.4906C362.81 14.9407 364.067 13.6839 365.617 13.6839C367.167 13.6839 368.424 14.9407 368.424 16.4906Z" />
          <path d="M368.424 9.64883C368.424 11.1988 367.167 12.4556 365.617 12.4556C364.067 12.4556 362.81 11.1988 362.81 9.64883C362.81 8.09889 364.067 6.8421 365.617 6.8421C367.167 6.8421 368.424 8.09889 368.424 9.64883Z" />
          <path d="M375.265 16.4906C375.265 18.0406 374.009 19.2974 372.459 19.2974C370.909 19.2974 369.652 18.0406 369.652 16.4906C369.652 14.9407 370.909 13.6839 372.459 13.6839C374.009 13.6839 375.265 14.9407 375.265 16.4906Z" />
          <path d="M334.214 16.4906C334.214 18.0406 332.957 19.2974 331.407 19.2974C329.857 19.2974 328.601 18.0406 328.601 16.4906C328.601 14.9407 329.857 13.6839 331.407 13.6839C332.957 13.6839 334.214 14.9407 334.214 16.4906Z" />
          <path d="M320.531 16.4906C320.531 18.0406 319.274 19.2974 317.724 19.2974C316.174 19.2974 314.917 18.0406 314.917 16.4906C314.917 14.9407 316.174 13.6839 317.724 13.6839C319.274 13.6839 320.531 14.9407 320.531 16.4906Z" />
          <path d="M293.163 50.6999C293.163 52.2498 291.906 53.5066 290.356 53.5066C288.806 53.5066 287.55 52.2498 287.55 50.6999C287.55 49.1499 288.806 47.8931 290.356 47.8931C291.906 47.8931 293.163 49.1499 293.163 50.6999Z" />
          <path d="M286.321 50.6999C286.321 52.2498 285.065 53.5066 283.515 53.5066C281.965 53.5066 280.708 52.2498 280.708 50.6999C280.708 49.1499 281.965 47.8931 283.515 47.8931C285.065 47.8931 286.321 49.1499 286.321 50.6999Z" />
          <path d="M300.005 9.64883C300.005 11.1988 298.748 12.4556 297.198 12.4556C295.648 12.4556 294.391 11.1988 294.391 9.64883C294.391 8.09889 295.648 6.8421 297.198 6.8421C298.748 6.8421 300.005 8.09889 300.005 9.64883Z" />
          <path d="M300.005 16.4906C300.005 18.0406 298.748 19.2974 297.198 19.2974C295.648 19.2974 294.391 18.0406 294.391 16.4906C294.391 14.9407 295.648 13.6839 297.198 13.6839C298.748 13.6839 300.005 14.9407 300.005 16.4906Z" />
          <path d="M293.163 16.4906C293.163 18.0406 291.906 19.2974 290.356 19.2974C288.806 19.2974 287.55 18.0406 287.55 16.4906C287.55 14.9407 288.806 13.6839 290.356 13.6839C291.906 13.6839 293.163 14.9407 293.163 16.4906Z" />
          <path d="M293.163 23.3324C293.163 24.8824 291.906 26.1392 290.356 26.1392C288.806 26.1392 287.55 24.8824 287.55 23.3324C287.55 21.7825 288.806 20.5257 290.356 20.5257C291.906 20.5257 293.163 21.7825 293.163 23.3324Z" />
          <path d="M286.321 23.3324C286.321 24.8824 285.065 26.1392 283.515 26.1392C281.965 26.1392 280.708 24.8824 280.708 23.3324C280.708 21.7825 281.965 20.5257 283.515 20.5257C285.065 20.5257 286.321 21.7825 286.321 23.3324Z" />
          <path d="M279.479 23.3324C279.479 24.8824 278.223 26.1392 276.673 26.1392C275.123 26.1392 273.866 24.8824 273.866 23.3324C273.866 21.7825 275.123 20.5257 276.673 20.5257C278.223 20.5257 279.479 21.7825 279.479 23.3324Z" />
          <path d="M279.479 30.1742C279.479 31.7242 278.223 32.981 276.673 32.981C275.123 32.981 273.866 31.7242 273.866 30.1742C273.866 28.6243 275.123 27.3675 276.673 27.3675C278.223 27.3675 279.479 28.6243 279.479 30.1742Z" />
          <path d="M272.638 30.1742C272.638 31.7242 271.381 32.981 269.831 32.981C268.281 32.981 267.024 31.7242 267.024 30.1742C267.024 28.6243 268.281 27.3675 269.831 27.3675C271.381 27.3675 272.638 28.6243 272.638 30.1742Z" />
          <path d="M265.796 30.1742C265.796 31.7242 264.539 32.981 262.989 32.981C261.439 32.981 260.182 31.7242 260.182 30.1742C260.182 28.6243 261.439 27.3675 262.989 27.3675C264.539 27.3675 265.796 28.6243 265.796 30.1742Z" />
          <path d="M272.638 37.0163C272.638 38.5662 271.381 39.823 269.831 39.823C268.281 39.823 267.024 38.5662 267.024 37.0163C267.024 35.4663 268.281 34.2095 269.831 34.2095C271.381 34.2095 272.638 35.4663 272.638 37.0163Z" />
          <path d="M265.796 43.8581C265.796 45.408 264.539 46.6648 262.989 46.6648C261.439 46.6648 260.182 45.408 260.182 43.8581C260.182 42.3081 261.439 41.0513 262.989 41.0513C264.539 41.0513 265.796 42.3081 265.796 43.8581Z" />
          <path d="M258.954 43.8581C258.954 45.408 257.697 46.6648 256.147 46.6648C254.597 46.6648 253.34 45.408 253.34 43.8581C253.34 42.3081 254.597 41.0513 256.147 41.0513C257.697 41.0513 258.954 42.3081 258.954 43.8581Z" />
          <path d="M258.954 57.5417C258.954 59.0916 257.697 60.3484 256.147 60.3484C254.597 60.3484 253.34 59.0916 253.34 57.5417C253.34 55.9917 254.597 54.7349 256.147 54.7349C257.697 54.7349 258.954 55.9917 258.954 57.5417Z" />
          <path d="M258.954 50.6999C258.954 52.2498 257.697 53.5066 256.147 53.5066C254.597 53.5066 253.34 52.2498 253.34 50.6999C253.34 49.1499 254.597 47.8931 256.147 47.8931C257.697 47.8931 258.954 49.1499 258.954 50.6999Z" />
          <path d="M252.112 50.6999C252.112 52.2498 250.855 53.5066 249.305 53.5066C247.755 53.5066 246.499 52.2498 246.499 50.6999C246.499 49.1499 247.755 47.8931 249.305 47.8931C250.855 47.8931 252.112 49.1499 252.112 50.6999Z" />
          <path d="M245.27 50.6999C245.27 52.2498 244.013 53.5066 242.463 53.5066C240.914 53.5066 239.657 52.2498 239.657 50.6999C239.657 49.1499 240.914 47.8931 242.463 47.8931C244.013 47.8931 245.27 49.1499 245.27 50.6999Z" />
          <path d="M245.27 43.8581C245.27 45.408 244.013 46.6648 242.463 46.6648C240.914 46.6648 239.657 45.408 239.657 43.8581C239.657 42.3081 240.914 41.0513 242.463 41.0513C244.013 41.0513 245.27 42.3081 245.27 43.8581Z" />
          <path d="M231.586 43.8581C231.586 45.408 230.33 46.6648 228.78 46.6648C227.23 46.6648 225.973 45.408 225.973 43.8581C225.973 42.3081 227.23 41.0513 228.78 41.0513C230.33 41.0513 231.586 42.3081 231.586 43.8581Z" />
          <path d="M231.586 37.0163C231.586 38.5662 230.33 39.823 228.78 39.823C227.23 39.823 225.973 38.5662 225.973 37.0163C225.973 35.4663 227.23 34.2095 228.78 34.2095C230.33 34.2095 231.586 35.4663 231.586 37.0163Z" />
          <path d="M272.638 23.3324C272.638 24.8824 271.381 26.1392 269.831 26.1392C268.281 26.1392 267.024 24.8824 267.024 23.3324C267.024 21.7825 268.281 20.5257 269.831 20.5257C271.381 20.5257 272.638 21.7825 272.638 23.3324Z" />
          <path d="M197.377 23.3324C197.377 24.8824 196.12 26.1392 194.571 26.1392C193.021 26.1392 191.764 24.8824 191.764 23.3324C191.764 21.7825 193.021 20.5257 194.571 20.5257C196.12 20.5257 197.377 21.7825 197.377 23.3324Z" />
          <path d="M190.535 30.1742C190.535 31.7242 189.279 32.981 187.729 32.981C186.179 32.981 184.922 31.7242 184.922 30.1742C184.922 28.6243 186.179 27.3675 187.729 27.3675C189.279 27.3675 190.535 28.6243 190.535 30.1742Z" />
          <path d="M211.061 43.8581C211.061 45.408 209.804 46.6648 208.254 46.6648C206.704 46.6648 205.448 45.408 205.448 43.8581C205.448 42.3081 206.704 41.0513 208.254 41.0513C209.804 41.0513 211.061 42.3081 211.061 43.8581Z" />
          <path d="M217.903 43.8581C217.903 45.408 216.646 46.6648 215.096 46.6648C213.546 46.6648 212.289 45.408 212.289 43.8581C212.289 42.3081 213.546 41.0513 215.096 41.0513C216.646 41.0513 217.903 42.3081 217.903 43.8581Z" />
          <path d="M217.903 37.0163C217.903 38.5662 216.646 39.823 215.096 39.823C213.546 39.823 212.289 38.5662 212.289 37.0163C212.289 35.4663 213.546 34.2095 215.096 34.2095C216.646 34.2095 217.903 35.4663 217.903 37.0163Z" />
          <path d="M190.535 64.3832C190.535 65.9331 189.279 67.1899 187.729 67.1899C186.179 67.1899 184.922 65.9331 184.922 64.3832C184.922 62.8333 186.179 61.5765 187.729 61.5765C189.279 61.5765 190.535 62.8333 190.535 64.3832Z" />
          <path d="M197.377 64.3832C197.377 65.9331 196.12 67.1899 194.571 67.1899C193.021 67.1899 191.764 65.9331 191.764 64.3832C191.764 62.8333 193.021 61.5765 194.571 61.5765C196.12 61.5765 197.377 62.8333 197.377 64.3832Z" />
          <path d="M183.694 64.3832C183.694 65.9331 182.437 67.1899 180.887 67.1899C179.337 67.1899 178.08 65.9331 178.08 64.3832C178.08 62.8333 179.337 61.5765 180.887 61.5765C182.437 61.5765 183.694 62.8333 183.694 64.3832Z" />
          <path d="M183.694 57.5417C183.694 59.0916 182.437 60.3484 180.887 60.3484C179.337 60.3484 178.08 59.0916 178.08 57.5417C178.08 55.9917 179.337 54.7349 180.887 54.7349C182.437 54.7349 183.694 55.9917 183.694 57.5417Z" />
          <path d="M183.694 43.8581C183.694 45.408 182.437 46.6648 180.887 46.6648C179.337 46.6648 178.08 45.408 178.08 43.8581C178.08 42.3081 179.337 41.0513 180.887 41.0513C182.437 41.0513 183.694 42.3081 183.694 43.8581Z" />
          <path d="M183.694 50.6999C183.694 52.2498 182.437 53.5066 180.887 53.5066C179.337 53.5066 178.08 52.2498 178.08 50.6999C178.08 49.1499 179.337 47.8931 180.887 47.8931C182.437 47.8931 183.694 49.1499 183.694 50.6999Z" />
          <path d="M190.535 50.6999C190.535 52.2498 189.279 53.5066 187.729 53.5066C186.179 53.5066 184.922 52.2498 184.922 50.6999C184.922 49.1499 186.179 47.8931 187.729 47.8931C189.279 47.8931 190.535 49.1499 190.535 50.6999Z" />
          <path d="M176.852 50.6999C176.852 52.2498 175.595 53.5066 174.045 53.5066C172.495 53.5066 171.238 52.2498 171.238 50.6999C171.238 49.1499 172.495 47.8931 174.045 47.8931C175.595 47.8931 176.852 49.1499 176.852 50.6999Z" />
          <path d="M170.01 57.5417C170.01 59.0916 168.753 60.3484 167.203 60.3484C165.653 60.3484 164.396 59.0916 164.396 57.5417C164.396 55.9917 165.653 54.7349 167.203 54.7349C168.753 54.7349 170.01 55.9917 170.01 57.5417Z" />
          <path d="M190.535 57.5417C190.535 59.0916 189.279 60.3484 187.729 60.3484C186.179 60.3484 184.922 59.0916 184.922 57.5417C184.922 55.9917 186.179 54.7349 187.729 54.7349C189.279 54.7349 190.535 55.9917 190.535 57.5417Z" />
          <path d="M170.01 84.9091C170.01 86.459 168.753 87.7158 167.203 87.7158C165.653 87.7158 164.396 86.459 164.396 84.9091C164.396 83.3591 165.653 82.1024 167.203 82.1024C168.753 82.1024 170.01 83.3591 170.01 84.9091Z" />
          <path d="M170.01 91.7506C170.01 93.3006 168.753 94.5574 167.203 94.5574C165.653 94.5574 164.396 93.3006 164.396 91.7506C164.396 90.2007 165.653 88.9439 167.203 88.9439C168.753 88.9439 170.01 90.2007 170.01 91.7506Z" />
          <path d="M176.852 91.7506C176.852 93.3006 175.595 94.5574 174.045 94.5574C172.495 94.5574 171.238 93.3006 171.238 91.7506C171.238 90.2007 172.495 88.9439 174.045 88.9439C175.595 88.9439 176.852 90.2007 176.852 91.7506Z" />
          <path d="M183.694 91.7506C183.694 93.3006 182.437 94.5574 180.887 94.5574C179.337 94.5574 178.08 93.3006 178.08 91.7506C178.08 90.2007 179.337 88.9439 180.887 88.9439C182.437 88.9439 183.694 90.2007 183.694 91.7506Z" />
          <path d="M183.694 84.9091C183.694 86.459 182.437 87.7158 180.887 87.7158C179.337 87.7158 178.08 86.459 178.08 84.9091C178.08 83.3591 179.337 82.1024 180.887 82.1024C182.437 82.1024 183.694 83.3591 183.694 84.9091Z" />
          <path d="M176.852 98.5927C176.852 100.143 175.595 101.399 174.045 101.399C172.495 101.399 171.238 100.143 171.238 98.5927C171.238 97.0427 172.495 95.7859 174.045 95.7859C175.595 95.7859 176.852 97.0427 176.852 98.5927Z" />
          <path d="M183.694 98.5927C183.694 100.143 182.437 101.399 180.887 101.399C179.337 101.399 178.08 100.143 178.08 98.5927C178.08 97.0427 179.337 95.7859 180.887 95.7859C182.437 95.7859 183.694 97.0427 183.694 98.5927Z" />
          <path d="M183.694 105.435C183.694 106.985 182.437 108.241 180.887 108.241C179.337 108.241 178.08 106.985 178.08 105.435C178.08 103.885 179.337 102.628 180.887 102.628C182.437 102.628 183.694 103.885 183.694 105.435Z" />
          <path d="M183.694 112.277C183.694 113.826 182.437 115.083 180.887 115.083C179.337 115.083 178.08 113.826 178.08 112.277C178.08 110.727 179.337 109.47 180.887 109.47C182.437 109.47 183.694 110.727 183.694 112.277Z" />
          <path d="M183.694 119.118C183.694 120.668 182.437 121.925 180.887 121.925C179.337 121.925 178.08 120.668 178.08 119.118C178.08 117.568 179.337 116.312 180.887 116.312C182.437 116.312 183.694 117.568 183.694 119.118Z" />
          <path d="M190.535 105.435C190.535 106.985 189.279 108.241 187.729 108.241C186.179 108.241 184.922 106.985 184.922 105.435C184.922 103.885 186.179 102.628 187.729 102.628C189.279 102.628 190.535 103.885 190.535 105.435Z" />
          <path d="M190.535 112.277C190.535 113.826 189.279 115.083 187.729 115.083C186.179 115.083 184.922 113.826 184.922 112.277C184.922 110.727 186.179 109.47 187.729 109.47C189.279 109.47 190.535 110.727 190.535 112.277Z" />
          <path d="M190.535 98.5927C190.535 100.143 189.279 101.399 187.729 101.399C186.179 101.399 184.922 100.143 184.922 98.5927C184.922 97.0427 186.179 95.7859 187.729 95.7859C189.279 95.7859 190.535 97.0427 190.535 98.5927Z" />
          <path d="M190.535 91.7506C190.535 93.3006 189.279 94.5574 187.729 94.5574C186.179 94.5574 184.922 93.3006 184.922 91.7506C184.922 90.2007 186.179 88.9439 187.729 88.9439C189.279 88.9439 190.535 90.2007 190.535 91.7506Z" />
          <path d="M190.535 84.9091C190.535 86.459 189.279 87.7158 187.729 87.7158C186.179 87.7158 184.922 86.459 184.922 84.9091C184.922 83.3591 186.179 82.1024 187.729 82.1024C189.279 82.1024 190.535 83.3591 190.535 84.9091Z" />
          <path d="M197.377 91.7506C197.377 93.3006 196.12 94.5574 194.571 94.5574C193.021 94.5574 191.764 93.3006 191.764 91.7506C191.764 90.2007 193.021 88.9439 194.571 88.9439C196.12 88.9439 197.377 90.2007 197.377 91.7506Z" />
          <path d="M204.219 91.7506C204.219 93.3006 202.962 94.5574 201.412 94.5574C199.863 94.5574 198.606 93.3006 198.606 91.7506C198.606 90.2007 199.863 88.9439 201.412 88.9439C202.962 88.9439 204.219 90.2007 204.219 91.7506Z" />
          <path d="M197.377 98.5927C197.377 100.143 196.12 101.399 194.571 101.399C193.021 101.399 191.764 100.143 191.764 98.5927C191.764 97.0427 193.021 95.7859 194.571 95.7859C196.12 95.7859 197.377 97.0427 197.377 98.5927Z" />
          <path d="M197.377 105.435C197.377 106.985 196.12 108.241 194.571 108.241C193.021 108.241 191.764 106.985 191.764 105.435C191.764 103.885 193.021 102.628 194.571 102.628C196.12 102.628 197.377 103.885 197.377 105.435Z" />
          <path d="M204.219 98.5927C204.219 100.143 202.962 101.399 201.412 101.399C199.863 101.399 198.606 100.143 198.606 98.5927C198.606 97.0427 199.863 95.7859 201.412 95.7859C202.962 95.7859 204.219 97.0427 204.219 98.5927Z" />
          <path d="M211.061 91.7506C211.061 93.3006 209.804 94.5574 208.254 94.5574C206.704 94.5574 205.448 93.3006 205.448 91.7506C205.448 90.2007 206.704 88.9439 208.254 88.9439C209.804 88.9439 211.061 90.2007 211.061 91.7506Z" />
          <path d="M211.061 84.9091C211.061 86.459 209.804 87.7158 208.254 87.7158C206.704 87.7158 205.448 86.459 205.448 84.9091C205.448 83.3591 206.704 82.1024 208.254 82.1024C209.804 82.1024 211.061 83.3591 211.061 84.9091Z" />
          <path d="M217.903 98.5927C217.903 100.143 216.646 101.399 215.096 101.399C213.546 101.399 212.289 100.143 212.289 98.5927C212.289 97.0427 213.546 95.7859 215.096 95.7859C216.646 95.7859 217.903 97.0427 217.903 98.5927Z" />
          <path d="M211.061 98.5927C211.061 100.143 209.804 101.399 208.254 101.399C206.704 101.399 205.448 100.143 205.448 98.5927C205.448 97.0427 206.704 95.7859 208.254 95.7859C209.804 95.7859 211.061 97.0427 211.061 98.5927Z" />
          <path d="M211.061 105.435C211.061 106.985 209.804 108.241 208.254 108.241C206.704 108.241 205.448 106.985 205.448 105.435C205.448 103.885 206.704 102.628 208.254 102.628C209.804 102.628 211.061 103.885 211.061 105.435Z" />
          <path d="M211.061 112.277C211.061 113.826 209.804 115.083 208.254 115.083C206.704 115.083 205.448 113.826 205.448 112.277C205.448 110.727 206.704 109.47 208.254 109.47C209.804 109.47 211.061 110.727 211.061 112.277Z" />
          <path d="M204.219 112.277C204.219 113.826 202.962 115.083 201.412 115.083C199.863 115.083 198.606 113.826 198.606 112.277C198.606 110.727 199.863 109.47 201.412 109.47C202.962 109.47 204.219 110.727 204.219 112.277Z" />
          <path d="M204.219 119.118C204.219 120.668 202.962 121.925 201.412 121.925C199.863 121.925 198.606 120.668 198.606 119.118C198.606 117.568 199.863 116.312 201.412 116.312C202.962 116.312 204.219 117.568 204.219 119.118Z" />
          <path d="M224.745 91.7506C224.745 93.3006 223.488 94.5574 221.938 94.5574C220.388 94.5574 219.131 93.3006 219.131 91.7506C219.131 90.2007 220.388 88.9439 221.938 88.9439C223.488 88.9439 224.745 90.2007 224.745 91.7506Z" />
          <path d="M217.903 91.7506C217.903 93.3006 216.646 94.5574 215.096 94.5574C213.546 94.5574 212.289 93.3006 212.289 91.7506C212.289 90.2007 213.546 88.9439 215.096 88.9439C216.646 88.9439 217.903 90.2007 217.903 91.7506Z" />
          <path d="M224.745 98.5927C224.745 100.143 223.488 101.399 221.938 101.399C220.388 101.399 219.131 100.143 219.131 98.5927C219.131 97.0427 220.388 95.7859 221.938 95.7859C223.488 95.7859 224.745 97.0427 224.745 98.5927Z" />
          <path d="M231.586 98.5927C231.586 100.143 230.33 101.399 228.78 101.399C227.23 101.399 225.973 100.143 225.973 98.5927C225.973 97.0427 227.23 95.7859 228.78 95.7859C230.33 95.7859 231.586 97.0427 231.586 98.5927Z" />
          <path d="M231.586 112.277C231.586 113.826 230.33 115.083 228.78 115.083C227.23 115.083 225.973 113.826 225.973 112.277C225.973 110.727 227.23 109.47 228.78 109.47C230.33 109.47 231.586 110.727 231.586 112.277Z" />
          <path d="M238.428 98.5927C238.428 100.143 237.172 101.399 235.622 101.399C234.072 101.399 232.815 100.143 232.815 98.5927C232.815 97.0427 234.072 95.7859 235.622 95.7859C237.172 95.7859 238.428 97.0427 238.428 98.5927Z" />
          <path d="M238.428 91.7506C238.428 93.3006 237.172 94.5574 235.622 94.5574C234.072 94.5574 232.815 93.3006 232.815 91.7506C232.815 90.2007 234.072 88.9439 235.622 88.9439C237.172 88.9439 238.428 90.2007 238.428 91.7506Z" />
          <path d="M224.745 78.0673C224.745 79.6172 223.488 80.874 221.938 80.874C220.388 80.874 219.131 79.6172 219.131 78.0673C219.131 76.5173 220.388 75.2606 221.938 75.2606C223.488 75.2606 224.745 76.5173 224.745 78.0673Z" />
          <path d="M238.428 78.0673C238.428 79.6172 237.172 80.874 235.622 80.874C234.072 80.874 232.815 79.6172 232.815 78.0673C232.815 76.5173 234.072 75.2606 235.622 75.2606C237.172 75.2606 238.428 76.5173 238.428 78.0673Z" />
          <path d="M245.27 78.0673C245.27 79.6172 244.013 80.874 242.463 80.874C240.914 80.874 239.657 79.6172 239.657 78.0673C239.657 76.5173 240.914 75.2606 242.463 75.2606C244.013 75.2606 245.27 76.5173 245.27 78.0673Z" />
          <path d="M245.27 84.9091C245.27 86.459 244.013 87.7158 242.463 87.7158C240.914 87.7158 239.657 86.459 239.657 84.9091C239.657 83.3591 240.914 82.1024 242.463 82.1024C244.013 82.1024 245.27 83.3591 245.27 84.9091Z" />
          <path d="M252.112 78.0673C252.112 79.6172 250.855 80.874 249.305 80.874C247.755 80.874 246.499 79.6172 246.499 78.0673C246.499 76.5173 247.755 75.2606 249.305 75.2606C250.855 75.2606 252.112 76.5173 252.112 78.0673Z" />
          <path d="M252.112 71.2255C252.112 72.7754 250.855 74.0322 249.305 74.0322C247.755 74.0322 246.499 72.7754 246.499 71.2255C246.499 69.6756 247.755 68.4188 249.305 68.4188C250.855 68.4188 252.112 69.6756 252.112 71.2255Z" />
          <path d="M258.954 71.2255C258.954 72.7754 257.697 74.0322 256.147 74.0322C254.597 74.0322 253.34 72.7754 253.34 71.2255C253.34 69.6756 254.597 68.4188 256.147 68.4188C257.697 68.4188 258.954 69.6756 258.954 71.2255Z" />
          <path d="M258.954 78.0673C258.954 79.6172 257.697 80.874 256.147 80.874C254.597 80.874 253.34 79.6172 253.34 78.0673C253.34 76.5173 254.597 75.2606 256.147 75.2606C257.697 75.2606 258.954 76.5173 258.954 78.0673Z" />
          <path d="M258.954 84.9091C258.954 86.459 257.697 87.7158 256.147 87.7158C254.597 87.7158 253.34 86.459 253.34 84.9091C253.34 83.3591 254.597 82.1024 256.147 82.1024C257.697 82.1024 258.954 83.3591 258.954 84.9091Z" />
          <path d="M265.796 84.9091C265.796 86.459 264.539 87.7158 262.989 87.7158C261.439 87.7158 260.182 86.459 260.182 84.9091C260.182 83.3591 261.439 82.1024 262.989 82.1024C264.539 82.1024 265.796 83.3591 265.796 84.9091Z" />
          <path d="M265.796 91.7506C265.796 93.3006 264.539 94.5574 262.989 94.5574C261.439 94.5574 260.182 93.3006 260.182 91.7506C260.182 90.2007 261.439 88.9439 262.989 88.9439C264.539 88.9439 265.796 90.2007 265.796 91.7506Z" />
          <path d="M272.638 91.7506C272.638 93.3006 271.381 94.5574 269.831 94.5574C268.281 94.5574 267.024 93.3006 267.024 91.7506C267.024 90.2007 268.281 88.9439 269.831 88.9439C271.381 88.9439 272.638 90.2007 272.638 91.7506Z" />
          <path d="M265.796 98.5927C265.796 100.143 264.539 101.399 262.989 101.399C261.439 101.399 260.182 100.143 260.182 98.5927C260.182 97.0427 261.439 95.7859 262.989 95.7859C264.539 95.7859 265.796 97.0427 265.796 98.5927Z" />
          <path d="M252.112 98.5927C252.112 100.143 250.855 101.399 249.305 101.399C247.755 101.399 246.499 100.143 246.499 98.5927C246.499 97.0427 247.755 95.7859 249.305 95.7859C250.855 95.7859 252.112 97.0427 252.112 98.5927Z" />
          <path d="M265.796 105.435C265.796 106.985 264.539 108.241 262.989 108.241C261.439 108.241 260.182 106.985 260.182 105.435C260.182 103.885 261.439 102.628 262.989 102.628C264.539 102.628 265.796 103.885 265.796 105.435Z" />
          <path d="M258.954 105.435C258.954 106.985 257.697 108.241 256.147 108.241C254.597 108.241 253.34 106.985 253.34 105.435C253.34 103.885 254.597 102.628 256.147 102.628C257.697 102.628 258.954 103.885 258.954 105.435Z" />
          <path d="M272.638 105.435C272.638 106.985 271.381 108.241 269.831 108.241C268.281 108.241 267.024 106.985 267.024 105.435C267.024 103.885 268.281 102.628 269.831 102.628C271.381 102.628 272.638 103.885 272.638 105.435Z" />
          <path d="M279.479 112.277C279.479 113.826 278.223 115.083 276.673 115.083C275.123 115.083 273.866 113.826 273.866 112.277C273.866 110.727 275.123 109.47 276.673 109.47C278.223 109.47 279.479 110.727 279.479 112.277Z" />
          <path d="M265.796 112.277C265.796 113.826 264.539 115.083 262.989 115.083C261.439 115.083 260.182 113.826 260.182 112.277C260.182 110.727 261.439 109.47 262.989 109.47C264.539 109.47 265.796 110.727 265.796 112.277Z" />
          <path d="M258.954 112.277C258.954 113.826 257.697 115.083 256.147 115.083C254.597 115.083 253.34 113.826 253.34 112.277C253.34 110.727 254.597 109.47 256.147 109.47C257.697 109.47 258.954 110.727 258.954 112.277Z" />
          <path d="M258.954 119.118C258.954 120.668 257.697 121.925 256.147 121.925C254.597 121.925 253.34 120.668 253.34 119.118C253.34 117.568 254.597 116.312 256.147 116.312C257.697 116.312 258.954 117.568 258.954 119.118Z" />
          <path d="M265.796 119.118C265.796 120.668 264.539 121.925 262.989 121.925C261.439 121.925 260.182 120.668 260.182 119.118C260.182 117.568 261.439 116.312 262.989 116.312C264.539 116.312 265.796 117.568 265.796 119.118Z" />
          <path d="M265.796 125.96C265.796 127.51 264.539 128.767 262.989 128.767C261.439 128.767 260.182 127.51 260.182 125.96C260.182 124.41 261.439 123.153 262.989 123.153C264.539 123.153 265.796 124.41 265.796 125.96Z" />
          <path d="M252.112 112.277C252.112 113.826 250.855 115.083 249.305 115.083C247.755 115.083 246.499 113.826 246.499 112.277C246.499 110.727 247.755 109.47 249.305 109.47C250.855 109.47 252.112 110.727 252.112 112.277Z" />
          <path d="M245.27 112.277C245.27 113.826 244.013 115.083 242.463 115.083C240.914 115.083 239.657 113.826 239.657 112.277C239.657 110.727 240.914 109.47 242.463 109.47C244.013 109.47 245.27 110.727 245.27 112.277Z" />
          <path d="M238.428 71.2255C238.428 72.7754 237.172 74.0322 235.622 74.0322C234.072 74.0322 232.815 72.7754 232.815 71.2255C232.815 69.6756 234.072 68.4188 235.622 68.4188C237.172 68.4188 238.428 69.6756 238.428 71.2255Z" />
          <path d="M245.27 64.3832C245.27 65.9331 244.013 67.1899 242.463 67.1899C240.914 67.1899 239.657 65.9331 239.657 64.3832C239.657 62.8333 240.914 61.5765 242.463 61.5765C244.013 61.5765 245.27 62.8333 245.27 64.3832Z" />
          <path d="M224.745 71.2255C224.745 72.7754 223.488 74.0322 221.938 74.0322C220.388 74.0322 219.131 72.7754 219.131 71.2255C219.131 69.6756 220.388 68.4188 221.938 68.4188C223.488 68.4188 224.745 69.6756 224.745 71.2255Z" />
          <path d="M217.903 64.3832C217.903 65.9331 216.646 67.1899 215.096 67.1899C213.546 67.1899 212.289 65.9331 212.289 64.3832C212.289 62.8333 213.546 61.5765 215.096 61.5765C216.646 61.5765 217.903 62.8333 217.903 64.3832Z" />
          <path d="M224.745 57.5417C224.745 59.0916 223.488 60.3484 221.938 60.3484C220.388 60.3484 219.131 59.0916 219.131 57.5417C219.131 55.9917 220.388 54.7349 221.938 54.7349C223.488 54.7349 224.745 55.9917 224.745 57.5417Z" />
          <path d="M238.428 57.5417C238.428 59.0916 237.172 60.3484 235.622 60.3484C234.072 60.3484 232.815 59.0916 232.815 57.5417C232.815 55.9917 234.072 54.7349 235.622 54.7349C237.172 54.7349 238.428 55.9917 238.428 57.5417Z" />
          <path d="M231.586 64.3832C231.586 65.9331 230.33 67.1899 228.78 67.1899C227.23 67.1899 225.973 65.9331 225.973 64.3832C225.973 62.8333 227.23 61.5765 228.78 61.5765C230.33 61.5765 231.586 62.8333 231.586 64.3832Z" />
          <path d="M217.903 105.435C217.903 106.985 216.646 108.241 215.096 108.241C213.546 108.241 212.289 106.985 212.289 105.435C212.289 103.885 213.546 102.628 215.096 102.628C216.646 102.628 217.903 103.885 217.903 105.435Z" />
          <path d="M224.745 112.277C224.745 113.826 223.488 115.083 221.938 115.083C220.388 115.083 219.131 113.826 219.131 112.277C219.131 110.727 220.388 109.47 221.938 109.47C223.488 109.47 224.745 110.727 224.745 112.277Z" />
          <path d="M163.168 71.2255C163.168 72.7754 161.911 74.0322 160.361 74.0322C158.811 74.0322 157.555 72.7754 157.555 71.2255C157.555 69.6756 158.811 68.4188 160.361 68.4188C161.911 68.4188 163.168 69.6756 163.168 71.2255Z" />
          <path d="M135.801 71.2255C135.801 72.7754 134.544 74.0322 132.994 74.0322C131.444 74.0322 130.187 72.7754 130.187 71.2255C130.187 69.6756 131.444 68.4188 132.994 68.4188C134.544 68.4188 135.801 69.6756 135.801 71.2255Z" />
          <path d="M128.959 84.9091C128.959 86.459 127.702 87.7158 126.152 87.7158C124.602 87.7158 123.345 86.459 123.345 84.9091C123.345 83.3591 124.602 82.1024 126.152 82.1024C127.702 82.1024 128.959 83.3591 128.959 84.9091Z" />
          <path d="M135.801 84.9091C135.801 86.459 134.544 87.7158 132.994 87.7158C131.444 87.7158 130.187 86.459 130.187 84.9091C130.187 83.3591 131.444 82.1024 132.994 82.1024C134.544 82.1024 135.801 83.3591 135.801 84.9091Z" />
          <path d="M142.643 84.9091C142.643 86.459 141.386 87.7158 139.836 87.7158C138.286 87.7158 137.029 86.459 137.029 84.9091C137.029 83.3591 138.286 82.1024 139.836 82.1024C141.386 82.1024 142.643 83.3591 142.643 84.9091Z" />
          <path d="M142.643 91.7506C142.643 93.3006 141.386 94.5574 139.836 94.5574C138.286 94.5574 137.029 93.3006 137.029 91.7506C137.029 90.2007 138.286 88.9439 139.836 88.9439C141.386 88.9439 142.643 90.2007 142.643 91.7506Z" />
          <path d="M149.484 91.7506C149.484 93.3006 148.228 94.5574 146.678 94.5574C145.128 94.5574 143.871 93.3006 143.871 91.7506C143.871 90.2007 145.128 88.9439 146.678 88.9439C148.228 88.9439 149.484 90.2007 149.484 91.7506Z" />
          <path d="M142.643 98.5927C142.643 100.143 141.386 101.399 139.836 101.399C138.286 101.399 137.029 100.143 137.029 98.5927C137.029 97.0427 138.286 95.7859 139.836 95.7859C141.386 95.7859 142.643 97.0427 142.643 98.5927Z" />
          <path d="M149.484 98.5927C149.484 100.143 148.228 101.399 146.678 101.399C145.128 101.399 143.871 100.143 143.871 98.5927C143.871 97.0427 145.128 95.7859 146.678 95.7859C148.228 95.7859 149.484 97.0427 149.484 98.5927Z" />
          <path d="M156.326 98.5927C156.326 100.143 155.069 101.399 153.52 101.399C151.97 101.399 150.713 100.143 150.713 98.5927C150.713 97.0427 151.97 95.7859 153.52 95.7859C155.069 95.7859 156.326 97.0427 156.326 98.5927Z" />
          <path d="M163.168 98.5927C163.168 100.143 161.911 101.399 160.361 101.399C158.811 101.399 157.555 100.143 157.555 98.5927C157.555 97.0427 158.811 95.7859 160.361 95.7859C161.911 95.7859 163.168 97.0427 163.168 98.5927Z" />
          <path d="M163.168 105.435C163.168 106.985 161.911 108.241 160.361 108.241C158.811 108.241 157.555 106.985 157.555 105.435C157.555 103.885 158.811 102.628 160.361 102.628C161.911 102.628 163.168 103.885 163.168 105.435Z" />
          <path d="M170.01 105.435C170.01 106.985 168.753 108.241 167.203 108.241C165.653 108.241 164.396 106.985 164.396 105.435C164.396 103.885 165.653 102.628 167.203 102.628C168.753 102.628 170.01 103.885 170.01 105.435Z" />
          <path d="M170.01 98.5927C170.01 100.143 168.753 101.399 167.203 101.399C165.653 101.399 164.396 100.143 164.396 98.5927C164.396 97.0427 165.653 95.7859 167.203 95.7859C168.753 95.7859 170.01 97.0427 170.01 98.5927Z" />
          <path d="M122.117 84.9091C122.117 86.459 120.86 87.7158 119.31 87.7158C117.76 87.7158 116.504 86.459 116.504 84.9091C116.504 83.3591 117.76 82.1024 119.31 82.1024C120.86 82.1024 122.117 83.3591 122.117 84.9091Z" />
          <path d="M115.275 84.9091C115.275 86.459 114.018 87.7158 112.468 87.7158C110.918 87.7158 109.662 86.459 109.662 84.9091C109.662 83.3591 110.918 82.1024 112.468 82.1024C114.018 82.1024 115.275 83.3591 115.275 84.9091Z" />
          <path d="M108.433 84.9091C108.433 86.459 107.176 87.7158 105.627 87.7158C104.077 87.7158 102.82 86.459 102.82 84.9091C102.82 83.3591 104.077 82.1024 105.627 82.1024C107.176 82.1024 108.433 83.3591 108.433 84.9091Z" />
          <path d="M108.433 91.7506C108.433 93.3006 107.176 94.5574 105.627 94.5574C104.077 94.5574 102.82 93.3006 102.82 91.7506C102.82 90.2007 104.077 88.9439 105.627 88.9439C107.176 88.9439 108.433 90.2007 108.433 91.7506Z" />
          <path d="M115.275 91.7506C115.275 93.3006 114.018 94.5574 112.468 94.5574C110.918 94.5574 109.662 93.3006 109.662 91.7506C109.662 90.2007 110.918 88.9439 112.468 88.9439C114.018 88.9439 115.275 90.2007 115.275 91.7506Z" />
          <path d="M122.117 91.7506C122.117 93.3006 120.86 94.5574 119.31 94.5574C117.76 94.5574 116.504 93.3006 116.504 91.7506C116.504 90.2007 117.76 88.9439 119.31 88.9439C120.86 88.9439 122.117 90.2007 122.117 91.7506Z" />
          <path d="M128.959 91.7506C128.959 93.3006 127.702 94.5574 126.152 94.5574C124.602 94.5574 123.345 93.3006 123.345 91.7506C123.345 90.2007 124.602 88.9439 126.152 88.9439C127.702 88.9439 128.959 90.2007 128.959 91.7506Z" />
          <path d="M135.801 91.7506C135.801 93.3006 134.544 94.5574 132.994 94.5574C131.444 94.5574 130.187 93.3006 130.187 91.7506C130.187 90.2007 131.444 88.9439 132.994 88.9439C134.544 88.9439 135.801 90.2007 135.801 91.7506Z" />
          <path d="M135.801 98.5927C135.801 100.143 134.544 101.399 132.994 101.399C131.444 101.399 130.187 100.143 130.187 98.5927C130.187 97.0427 131.444 95.7859 132.994 95.7859C134.544 95.7859 135.801 97.0427 135.801 98.5927Z" />
          <path d="M128.959 98.5927C128.959 100.143 127.702 101.399 126.152 101.399C124.602 101.399 123.345 100.143 123.345 98.5927C123.345 97.0427 124.602 95.7859 126.152 95.7859C127.702 95.7859 128.959 97.0427 128.959 98.5927Z" />
          <path d="M128.959 105.435C128.959 106.985 127.702 108.241 126.152 108.241C124.602 108.241 123.345 106.985 123.345 105.435C123.345 103.885 124.602 102.628 126.152 102.628C127.702 102.628 128.959 103.885 128.959 105.435Z" />
          <path d="M135.801 105.435C135.801 106.985 134.544 108.241 132.994 108.241C131.444 108.241 130.187 106.985 130.187 105.435C130.187 103.885 131.444 102.628 132.994 102.628C134.544 102.628 135.801 103.885 135.801 105.435Z" />
          <path d="M142.643 105.435C142.643 106.985 141.386 108.241 139.836 108.241C138.286 108.241 137.029 106.985 137.029 105.435C137.029 103.885 138.286 102.628 139.836 102.628C141.386 102.628 142.643 103.885 142.643 105.435Z" />
          <path d="M149.484 105.435C149.484 106.985 148.228 108.241 146.678 108.241C145.128 108.241 143.871 106.985 143.871 105.435C143.871 103.885 145.128 102.628 146.678 102.628C148.228 102.628 149.484 103.885 149.484 105.435Z" />
          <path d="M149.484 112.277C149.484 113.826 148.228 115.083 146.678 115.083C145.128 115.083 143.871 113.826 143.871 112.277C143.871 110.727 145.128 109.47 146.678 109.47C148.228 109.47 149.484 110.727 149.484 112.277Z" />
          <path d="M156.326 112.277C156.326 113.826 155.069 115.083 153.52 115.083C151.97 115.083 150.713 113.826 150.713 112.277C150.713 110.727 151.97 109.47 153.52 109.47C155.069 109.47 156.326 110.727 156.326 112.277Z" />
          <path d="M142.643 112.277C142.643 113.826 141.386 115.083 139.836 115.083C138.286 115.083 137.029 113.826 137.029 112.277C137.029 110.727 138.286 109.47 139.836 109.47C141.386 109.47 142.643 110.727 142.643 112.277Z" />
          <path d="M142.643 119.118C142.643 120.668 141.386 121.925 139.836 121.925C138.286 121.925 137.029 120.668 137.029 119.118C137.029 117.568 138.286 116.312 139.836 116.312C141.386 116.312 142.643 117.568 142.643 119.118Z" />
          <path d="M142.643 125.96C142.643 127.51 141.386 128.767 139.836 128.767C138.286 128.767 137.029 127.51 137.029 125.96C137.029 124.41 138.286 123.153 139.836 123.153C141.386 123.153 142.643 124.41 142.643 125.96Z" />
          <path d="M142.643 132.802C142.643 134.352 141.386 135.608 139.836 135.608C138.286 135.608 137.029 134.352 137.029 132.802C137.029 131.252 138.286 129.995 139.836 129.995C141.386 129.995 142.643 131.252 142.643 132.802Z" />
          <path d="M142.643 139.644C142.643 141.194 141.386 142.451 139.836 142.451C138.286 142.451 137.029 141.194 137.029 139.644C137.029 138.094 138.286 136.837 139.836 136.837C141.386 136.837 142.643 138.094 142.643 139.644Z" />
          <path d="M142.643 146.486C142.643 148.036 141.386 149.292 139.836 149.292C138.286 149.292 137.029 148.036 137.029 146.486C137.029 144.936 138.286 143.679 139.836 143.679C141.386 143.679 142.643 144.936 142.643 146.486Z" />
          <path d="M149.484 146.486C149.484 148.036 148.228 149.292 146.678 149.292C145.128 149.292 143.871 148.036 143.871 146.486C143.871 144.936 145.128 143.679 146.678 143.679C148.228 143.679 149.484 144.936 149.484 146.486Z" />
          <path d="M149.484 139.644C149.484 141.194 148.228 142.451 146.678 142.451C145.128 142.451 143.871 141.194 143.871 139.644C143.871 138.094 145.128 136.837 146.678 136.837C148.228 136.837 149.484 138.094 149.484 139.644Z" />
          <path d="M149.484 132.802C149.484 134.352 148.228 135.608 146.678 135.608C145.128 135.608 143.871 134.352 143.871 132.802C143.871 131.252 145.128 129.995 146.678 129.995C148.228 129.995 149.484 131.252 149.484 132.802Z" />
          <path d="M149.484 125.96C149.484 127.51 148.228 128.767 146.678 128.767C145.128 128.767 143.871 127.51 143.871 125.96C143.871 124.41 145.128 123.153 146.678 123.153C148.228 123.153 149.484 124.41 149.484 125.96Z" />
          <path d="M156.326 125.96C156.326 127.51 155.069 128.767 153.52 128.767C151.97 128.767 150.713 127.51 150.713 125.96C150.713 124.41 151.97 123.153 153.52 123.153C155.069 123.153 156.326 124.41 156.326 125.96Z" />
          <path d="M156.326 132.802C156.326 134.352 155.069 135.608 153.52 135.608C151.97 135.608 150.713 134.352 150.713 132.802C150.713 131.252 151.97 129.995 153.52 129.995C155.069 129.995 156.326 131.252 156.326 132.802Z" />
          <path d="M156.326 139.644C156.326 141.194 155.069 142.451 153.52 142.451C151.97 142.451 150.713 141.194 150.713 139.644C150.713 138.094 151.97 136.837 153.52 136.837C155.069 136.837 156.326 138.094 156.326 139.644Z" />
          <path d="M163.168 139.644C163.168 141.194 161.911 142.451 160.361 142.451C158.811 142.451 157.555 141.194 157.555 139.644C157.555 138.094 158.811 136.837 160.361 136.837C161.911 136.837 163.168 138.094 163.168 139.644Z" />
          <path d="M170.01 139.644C170.01 141.194 168.753 142.451 167.203 142.451C165.653 142.451 164.396 141.194 164.396 139.644C164.396 138.094 165.653 136.837 167.203 136.837C168.753 136.837 170.01 138.094 170.01 139.644Z" />
          <path d="M176.852 139.644C176.852 141.194 175.595 142.451 174.045 142.451C172.495 142.451 171.238 141.194 171.238 139.644C171.238 138.094 172.495 136.837 174.045 136.837C175.595 136.837 176.852 138.094 176.852 139.644Z" />
          <path d="M170.01 132.802C170.01 134.352 168.753 135.608 167.203 135.608C165.653 135.608 164.396 134.352 164.396 132.802C164.396 131.252 165.653 129.995 167.203 129.995C168.753 129.995 170.01 131.252 170.01 132.802Z" />
          <path d="M163.168 132.802C163.168 134.352 161.911 135.608 160.361 135.608C158.811 135.608 157.555 134.352 157.555 132.802C157.555 131.252 158.811 129.995 160.361 129.995C161.911 129.995 163.168 131.252 163.168 132.802Z" />
          <path d="M183.694 125.96C183.694 127.51 182.437 128.767 180.887 128.767C179.337 128.767 178.08 127.51 178.08 125.96C178.08 124.41 179.337 123.153 180.887 123.153C182.437 123.153 183.694 124.41 183.694 125.96Z" />
          <path d="M183.694 132.802C183.694 134.352 182.437 135.608 180.887 135.608C179.337 135.608 178.08 134.352 178.08 132.802C178.08 131.252 179.337 129.995 180.887 129.995C182.437 129.995 183.694 131.252 183.694 132.802Z" />
          <path d="M163.168 125.96C163.168 127.51 161.911 128.767 160.361 128.767C158.811 128.767 157.555 127.51 157.555 125.96C157.555 124.41 158.811 123.153 160.361 123.153C161.911 123.153 163.168 124.41 163.168 125.96Z" />
          <path d="M163.168 119.118C163.168 120.668 161.911 121.925 160.361 121.925C158.811 121.925 157.555 120.668 157.555 119.118C157.555 117.568 158.811 116.312 160.361 116.312C161.911 116.312 163.168 117.568 163.168 119.118Z" />
          <path d="M176.852 119.118C176.852 120.668 175.595 121.925 174.045 121.925C172.495 121.925 171.238 120.668 171.238 119.118C171.238 117.568 172.495 116.312 174.045 116.312C175.595 116.312 176.852 117.568 176.852 119.118Z" />
          <path d="M170.01 119.118C170.01 120.668 168.753 121.925 167.203 121.925C165.653 121.925 164.396 120.668 164.396 119.118C164.396 117.568 165.653 116.312 167.203 116.312C168.753 116.312 170.01 117.568 170.01 119.118Z" />
          <path d="M176.852 112.277C176.852 113.826 175.595 115.083 174.045 115.083C172.495 115.083 171.238 113.826 171.238 112.277C171.238 110.727 172.495 109.47 174.045 109.47C175.595 109.47 176.852 110.727 176.852 112.277Z" />
          <path d="M170.01 125.96C170.01 127.51 168.753 128.767 167.203 128.767C165.653 128.767 164.396 127.51 164.396 125.96C164.396 124.41 165.653 123.153 167.203 123.153C168.753 123.153 170.01 124.41 170.01 125.96Z" />
          <path d="M176.852 125.96C176.852 127.51 175.595 128.767 174.045 128.767C172.495 128.767 171.238 127.51 171.238 125.96C171.238 124.41 172.495 123.153 174.045 123.153C175.595 123.153 176.852 124.41 176.852 125.96Z" />
          <path d="M149.484 119.118C149.484 120.668 148.228 121.925 146.678 121.925C145.128 121.925 143.871 120.668 143.871 119.118C143.871 117.568 145.128 116.312 146.678 116.312C148.228 116.312 149.484 117.568 149.484 119.118Z" />
          <path d="M156.326 146.486C156.326 148.036 155.069 149.292 153.52 149.292C151.97 149.292 150.713 148.036 150.713 146.486C150.713 144.936 151.97 143.679 153.52 143.679C155.069 143.679 156.326 144.936 156.326 146.486Z" />
          <path d="M163.168 146.486C163.168 148.036 161.911 149.292 160.361 149.292C158.811 149.292 157.555 148.036 157.555 146.486C157.555 144.936 158.811 143.679 160.361 143.679C161.911 143.679 163.168 144.936 163.168 146.486Z" />
          <path d="M122.117 98.5927C122.117 100.143 120.86 101.399 119.31 101.399C117.76 101.399 116.504 100.143 116.504 98.5927C116.504 97.0427 117.76 95.7859 119.31 95.7859C120.86 95.7859 122.117 97.0427 122.117 98.5927Z" />
          <path d="M122.117 105.435C122.117 106.985 120.86 108.241 119.31 108.241C117.76 108.241 116.504 106.985 116.504 105.435C116.504 103.885 117.76 102.628 119.31 102.628C120.86 102.628 122.117 103.885 122.117 105.435Z" />
          <path d="M115.275 105.435C115.275 106.985 114.018 108.241 112.468 108.241C110.918 108.241 109.662 106.985 109.662 105.435C109.662 103.885 110.918 102.628 112.468 102.628C114.018 102.628 115.275 103.885 115.275 105.435Z" />
          <path d="M115.275 112.277C115.275 113.826 114.018 115.083 112.468 115.083C110.918 115.083 109.662 113.826 109.662 112.277C109.662 110.727 110.918 109.47 112.468 109.47C114.018 109.47 115.275 110.727 115.275 112.277Z" />
          <path d="M115.275 119.118C115.275 120.668 114.018 121.925 112.468 121.925C110.918 121.925 109.662 120.668 109.662 119.118C109.662 117.568 110.918 116.312 112.468 116.312C114.018 116.312 115.275 117.568 115.275 119.118Z" />
          <path d="M115.275 125.96C115.275 127.51 114.018 128.767 112.468 128.767C110.918 128.767 109.662 127.51 109.662 125.96C109.662 124.41 110.918 123.153 112.468 123.153C114.018 123.153 115.275 124.41 115.275 125.96Z" />
          <path d="M122.117 125.96C122.117 127.51 120.86 128.767 119.31 128.767C117.76 128.767 116.504 127.51 116.504 125.96C116.504 124.41 117.76 123.153 119.31 123.153C120.86 123.153 122.117 124.41 122.117 125.96Z" />
          <path d="M122.117 132.802C122.117 134.352 120.86 135.608 119.31 135.608C117.76 135.608 116.504 134.352 116.504 132.802C116.504 131.252 117.76 129.995 119.31 129.995C120.86 129.995 122.117 131.252 122.117 132.802Z" />
          <path d="M122.117 139.644C122.117 141.194 120.86 142.451 119.31 142.451C117.76 142.451 116.504 141.194 116.504 139.644C116.504 138.094 117.76 136.837 119.31 136.837C120.86 136.837 122.117 138.094 122.117 139.644Z" />
          <path d="M122.117 146.486C122.117 148.036 120.86 149.292 119.31 149.292C117.76 149.292 116.504 148.036 116.504 146.486C116.504 144.936 117.76 143.679 119.31 143.679C120.86 143.679 122.117 144.936 122.117 146.486Z" />
          <path d="M128.959 146.486C128.959 148.036 127.702 149.292 126.152 149.292C124.602 149.292 123.345 148.036 123.345 146.486C123.345 144.936 124.602 143.679 126.152 143.679C127.702 143.679 128.959 144.936 128.959 146.486Z" />
          <path d="M128.959 153.328C128.959 154.877 127.702 156.134 126.152 156.134C124.602 156.134 123.345 154.877 123.345 153.328C123.345 151.778 124.602 150.521 126.152 150.521C127.702 150.521 128.959 151.778 128.959 153.328Z" />
          <path d="M128.959 160.169C128.959 161.719 127.702 162.976 126.152 162.976C124.602 162.976 123.345 161.719 123.345 160.169C123.345 158.619 124.602 157.363 126.152 157.363C127.702 157.363 128.959 158.619 128.959 160.169Z" />
          <path d="M128.959 167.011C128.959 168.561 127.702 169.818 126.152 169.818C124.602 169.818 123.345 168.561 123.345 167.011C123.345 165.461 124.602 164.204 126.152 164.204C127.702 164.204 128.959 165.461 128.959 167.011Z" />
          <path d="M135.801 167.011C135.801 168.561 134.544 169.818 132.994 169.818C131.444 169.818 130.187 168.561 130.187 167.011C130.187 165.461 131.444 164.204 132.994 164.204C134.544 164.204 135.801 165.461 135.801 167.011Z" />
          <path d="M135.801 160.169C135.801 161.719 134.544 162.976 132.994 162.976C131.444 162.976 130.187 161.719 130.187 160.169C130.187 158.619 131.444 157.363 132.994 157.363C134.544 157.363 135.801 158.619 135.801 160.169Z" />
          <path d="M142.643 160.169C142.643 161.719 141.386 162.976 139.836 162.976C138.286 162.976 137.029 161.719 137.029 160.169C137.029 158.619 138.286 157.363 139.836 157.363C141.386 157.363 142.643 158.619 142.643 160.169Z" />
          <path d="M149.484 160.169C149.484 161.719 148.228 162.976 146.678 162.976C145.128 162.976 143.871 161.719 143.871 160.169C143.871 158.619 145.128 157.363 146.678 157.363C148.228 157.363 149.484 158.619 149.484 160.169Z" />
          <path d="M156.326 160.169C156.326 161.719 155.069 162.976 153.52 162.976C151.97 162.976 150.713 161.719 150.713 160.169C150.713 158.619 151.97 157.363 153.52 157.363C155.069 157.363 156.326 158.619 156.326 160.169Z" />
          <path d="M163.168 160.169C163.168 161.719 161.911 162.976 160.361 162.976C158.811 162.976 157.555 161.719 157.555 160.169C157.555 158.619 158.811 157.363 160.361 157.363C161.911 157.363 163.168 158.619 163.168 160.169Z" />
          <path d="M170.01 160.169C170.01 161.719 168.753 162.976 167.203 162.976C165.653 162.976 164.396 161.719 164.396 160.169C164.396 158.619 165.653 157.363 167.203 157.363C168.753 157.363 170.01 158.619 170.01 160.169Z" />
          <path d="M176.852 160.169C176.852 161.719 175.595 162.976 174.045 162.976C172.495 162.976 171.238 161.719 171.238 160.169C171.238 158.619 172.495 157.363 174.045 157.363C175.595 157.363 176.852 158.619 176.852 160.169Z" />
          <path d="M176.852 153.328C176.852 154.877 175.595 156.134 174.045 156.134C172.495 156.134 171.238 154.877 171.238 153.328C171.238 151.778 172.495 150.521 174.045 150.521C175.595 150.521 176.852 151.778 176.852 153.328Z" />
          <path d="M170.01 153.328C170.01 154.877 168.753 156.134 167.203 156.134C165.653 156.134 164.396 154.877 164.396 153.328C164.396 151.778 165.653 150.521 167.203 150.521C168.753 150.521 170.01 151.778 170.01 153.328Z" />
          <path d="M170.01 146.486C170.01 148.036 168.753 149.292 167.203 149.292C165.653 149.292 164.396 148.036 164.396 146.486C164.396 144.936 165.653 143.679 167.203 143.679C168.753 143.679 170.01 144.936 170.01 146.486Z" />
          <path d="M163.168 153.328C163.168 154.877 161.911 156.134 160.361 156.134C158.811 156.134 157.555 154.877 157.555 153.328C157.555 151.778 158.811 150.521 160.361 150.521C161.911 150.521 163.168 151.778 163.168 153.328Z" />
          <path d="M156.326 153.328C156.326 154.877 155.069 156.134 153.52 156.134C151.97 156.134 150.713 154.877 150.713 153.328C150.713 151.778 151.97 150.521 153.52 150.521C155.069 150.521 156.326 151.778 156.326 153.328Z" />
          <path d="M149.484 153.328C149.484 154.877 148.228 156.134 146.678 156.134C145.128 156.134 143.871 154.877 143.871 153.328C143.871 151.778 145.128 150.521 146.678 150.521C148.228 150.521 149.484 151.778 149.484 153.328Z" />
          <path d="M142.643 153.328C142.643 154.877 141.386 156.134 139.836 156.134C138.286 156.134 137.029 154.877 137.029 153.328C137.029 151.778 138.286 150.521 139.836 150.521C141.386 150.521 142.643 151.778 142.643 153.328Z" />
          <path d="M135.801 153.328C135.801 154.877 134.544 156.134 132.994 156.134C131.444 156.134 130.187 154.877 130.187 153.328C130.187 151.778 131.444 150.521 132.994 150.521C134.544 150.521 135.801 151.778 135.801 153.328Z" />
          <path d="M135.801 146.486C135.801 148.036 134.544 149.292 132.994 149.292C131.444 149.292 130.187 148.036 130.187 146.486C130.187 144.936 131.444 143.679 132.994 143.679C134.544 143.679 135.801 144.936 135.801 146.486Z" />
          <path d="M135.801 139.644C135.801 141.194 134.544 142.451 132.994 142.451C131.444 142.451 130.187 141.194 130.187 139.644C130.187 138.094 131.444 136.837 132.994 136.837C134.544 136.837 135.801 138.094 135.801 139.644Z" />
          <path d="M135.801 132.802C135.801 134.352 134.544 135.608 132.994 135.608C131.444 135.608 130.187 134.352 130.187 132.802C130.187 131.252 131.444 129.995 132.994 129.995C134.544 129.995 135.801 131.252 135.801 132.802Z" />
          <path d="M135.801 125.96C135.801 127.51 134.544 128.767 132.994 128.767C131.444 128.767 130.187 127.51 130.187 125.96C130.187 124.41 131.444 123.153 132.994 123.153C134.544 123.153 135.801 124.41 135.801 125.96Z" />
          <path d="M135.801 119.118C135.801 120.668 134.544 121.925 132.994 121.925C131.444 121.925 130.187 120.668 130.187 119.118C130.187 117.568 131.444 116.312 132.994 116.312C134.544 116.312 135.801 117.568 135.801 119.118Z" />
          <path d="M135.801 112.277C135.801 113.826 134.544 115.083 132.994 115.083C131.444 115.083 130.187 113.826 130.187 112.277C130.187 110.727 131.444 109.47 132.994 109.47C134.544 109.47 135.801 110.727 135.801 112.277Z" />
          <path d="M128.959 112.277C128.959 113.826 127.702 115.083 126.152 115.083C124.602 115.083 123.345 113.826 123.345 112.277C123.345 110.727 124.602 109.47 126.152 109.47C127.702 109.47 128.959 110.727 128.959 112.277Z" />
          <path d="M122.117 112.277C122.117 113.826 120.86 115.083 119.31 115.083C117.76 115.083 116.504 113.826 116.504 112.277C116.504 110.727 117.76 109.47 119.31 109.47C120.86 109.47 122.117 110.727 122.117 112.277Z" />
          <path d="M122.117 119.118C122.117 120.668 120.86 121.925 119.31 121.925C117.76 121.925 116.504 120.668 116.504 119.118C116.504 117.568 117.76 116.312 119.31 116.312C120.86 116.312 122.117 117.568 122.117 119.118Z" />
          <path d="M128.959 119.118C128.959 120.668 127.702 121.925 126.152 121.925C124.602 121.925 123.345 120.668 123.345 119.118C123.345 117.568 124.602 116.312 126.152 116.312C127.702 116.312 128.959 117.568 128.959 119.118Z" />
          <path d="M128.959 125.96C128.959 127.51 127.702 128.767 126.152 128.767C124.602 128.767 123.345 127.51 123.345 125.96C123.345 124.41 124.602 123.153 126.152 123.153C127.702 123.153 128.959 124.41 128.959 125.96Z" />
          <path d="M128.959 132.802C128.959 134.352 127.702 135.608 126.152 135.608C124.602 135.608 123.345 134.352 123.345 132.802C123.345 131.252 124.602 129.995 126.152 129.995C127.702 129.995 128.959 131.252 128.959 132.802Z" />
          <path d="M128.959 139.644C128.959 141.194 127.702 142.451 126.152 142.451C124.602 142.451 123.345 141.194 123.345 139.644C123.345 138.094 124.602 136.837 126.152 136.837C127.702 136.837 128.959 138.094 128.959 139.644Z" />
          <path d="M142.643 167.011C142.643 168.561 141.386 169.818 139.836 169.818C138.286 169.818 137.029 168.561 137.029 167.011C137.029 165.461 138.286 164.204 139.836 164.204C141.386 164.204 142.643 165.461 142.643 167.011Z" />
          <path d="M149.484 167.011C149.484 168.561 148.228 169.818 146.678 169.818C145.128 169.818 143.871 168.561 143.871 167.011C143.871 165.461 145.128 164.204 146.678 164.204C148.228 164.204 149.484 165.461 149.484 167.011Z" />
          <path d="M156.326 167.011C156.326 168.561 155.069 169.818 153.52 169.818C151.97 169.818 150.713 168.561 150.713 167.011C150.713 165.461 151.97 164.204 153.52 164.204C155.069 164.204 156.326 165.461 156.326 167.011Z" />
          <path d="M163.168 167.011C163.168 168.561 161.911 169.818 160.361 169.818C158.811 169.818 157.555 168.561 157.555 167.011C157.555 165.461 158.811 164.204 160.361 164.204C161.911 164.204 163.168 165.461 163.168 167.011Z" />
          <path d="M170.01 167.011C170.01 168.561 168.753 169.818 167.203 169.818C165.653 169.818 164.396 168.561 164.396 167.011C164.396 165.461 165.653 164.204 167.203 164.204C168.753 164.204 170.01 165.461 170.01 167.011Z" />
          <path d="M176.852 167.011C176.852 168.561 175.595 169.818 174.045 169.818C172.495 169.818 171.238 168.561 171.238 167.011C171.238 165.461 172.495 164.204 174.045 164.204C175.595 164.204 176.852 165.461 176.852 167.011Z" />
          <path d="M108.433 105.435C108.433 106.985 107.176 108.241 105.627 108.241C104.077 108.241 102.82 106.985 102.82 105.435C102.82 103.885 104.077 102.628 105.627 102.628C107.176 102.628 108.433 103.885 108.433 105.435Z" />
          <path d="M115.275 98.5927C115.275 100.143 114.018 101.399 112.468 101.399C110.918 101.399 109.662 100.143 109.662 98.5927C109.662 97.0427 110.918 95.7859 112.468 95.7859C114.018 95.7859 115.275 97.0427 115.275 98.5927Z" />
          <path d="M108.433 98.5927C108.433 100.143 107.176 101.399 105.627 101.399C104.077 101.399 102.82 100.143 102.82 98.5927C102.82 97.0427 104.077 95.7859 105.627 95.7859C107.176 95.7859 108.433 97.0427 108.433 98.5927Z" />
          <path d="M101.591 98.5927C101.591 100.143 100.335 101.399 98.7848 101.399C97.2348 101.399 95.978 100.143 95.978 98.5927C95.978 97.0427 97.2348 95.7859 98.7848 95.7859C100.335 95.7859 101.591 97.0427 101.591 98.5927Z" />
          <path d="M81.0659 78.0673C81.0659 79.6172 79.8091 80.874 78.2591 80.874C76.7092 80.874 75.4524 79.6172 75.4524 78.0673C75.4524 76.5173 76.7092 75.2606 78.2591 75.2606C79.8091 75.2606 81.0659 76.5173 81.0659 78.0673Z" />
          <path d="M67.3823 71.2255C67.3823 72.7754 66.1255 74.0322 64.5755 74.0322C63.0256 74.0322 61.7688 72.7754 61.7688 71.2255C61.7688 69.6756 63.0256 68.4188 64.5755 68.4188C66.1255 68.4188 67.3823 69.6756 67.3823 71.2255Z" />
          <path d="M60.5403 71.2255C60.5403 72.7754 59.2836 74.0322 57.7336 74.0322C56.1837 74.0322 54.9269 72.7754 54.9269 71.2255C54.9269 69.6756 56.1837 68.4188 57.7336 68.4188C59.2836 68.4188 60.5403 69.6756 60.5403 71.2255Z" />
          <path d="M46.8566 78.0673C46.8566 79.6172 45.5998 80.874 44.0499 80.874C42.5 80.874 41.2432 79.6172 41.2432 78.0673C41.2432 76.5173 42.5 75.2606 44.0499 75.2606C45.5998 75.2606 46.8566 76.5173 46.8566 78.0673Z" />
          <path d="M60.5403 64.3832C60.5403 65.9331 59.2836 67.1899 57.7336 67.1899C56.1837 67.1899 54.9269 65.9331 54.9269 64.3832C54.9269 62.8333 56.1837 61.5765 57.7336 61.5765C59.2836 61.5765 60.5403 62.8333 60.5403 64.3832Z" />
          <path d="M81.0659 84.9091C81.0659 86.459 79.8091 87.7158 78.2591 87.7158C76.7092 87.7158 75.4524 86.459 75.4524 84.9091C75.4524 83.3591 76.7092 82.1024 78.2591 82.1024C79.8091 82.1024 81.0659 83.3591 81.0659 84.9091Z" />
          <path d="M87.9078 84.9091C87.9078 86.459 86.651 87.7158 85.101 87.7158C83.5511 87.7158 82.2943 86.459 82.2943 84.9091C82.2943 83.3591 83.5511 82.1024 85.101 82.1024C86.651 82.1024 87.9078 83.3591 87.9078 84.9091Z" />
          <path d="M94.7496 84.9091C94.7496 86.459 93.4928 87.7158 91.9428 87.7158C90.3929 87.7158 89.1361 86.459 89.1361 84.9091C89.1361 83.3591 90.3929 82.1024 91.9428 82.1024C93.4928 82.1024 94.7496 83.3591 94.7496 84.9091Z" />
          <path d="M101.591 84.9091C101.591 86.459 100.335 87.7158 98.7848 87.7158C97.2348 87.7158 95.978 86.459 95.978 84.9091C95.978 83.3591 97.2348 82.1024 98.7848 82.1024C100.335 82.1024 101.591 83.3591 101.591 84.9091Z" />
          <path d="M101.591 91.7506C101.591 93.3006 100.335 94.5574 98.7848 94.5574C97.2348 94.5574 95.978 93.3006 95.978 91.7506C95.978 90.2007 97.2348 88.9439 98.7848 88.9439C100.335 88.9439 101.591 90.2007 101.591 91.7506Z" />
          <path d="M94.7496 91.7506C94.7496 93.3006 93.4928 94.5574 91.9428 94.5574C90.3929 94.5574 89.1361 93.3006 89.1361 91.7506C89.1361 90.2007 90.3929 88.9439 91.9428 88.9439C93.4928 88.9439 94.7496 90.2007 94.7496 91.7506Z" />
          <path d="M60.5403 98.5927C60.5403 100.143 59.2836 101.399 57.7336 101.399C56.1837 101.399 54.9269 100.143 54.9269 98.5927C54.9269 97.0427 56.1837 95.7859 57.7336 95.7859C59.2836 95.7859 60.5403 97.0427 60.5403 98.5927Z" />
          <path d="M46.8566 98.5927C46.8566 100.143 45.5998 101.399 44.0499 101.399C42.5 101.399 41.2432 100.143 41.2432 98.5927C41.2432 97.0427 42.5 95.7859 44.0499 95.7859C45.5998 95.7859 46.8566 97.0427 46.8566 98.5927Z" />
          <path d="M60.5403 112.277C60.5403 113.826 59.2836 115.083 57.7336 115.083C56.1837 115.083 54.9269 113.826 54.9269 112.277C54.9269 110.727 56.1837 109.47 57.7336 109.47C59.2836 109.47 60.5403 110.727 60.5403 112.277Z" />
          <path d="M53.6986 119.118C53.6986 120.668 52.4418 121.925 50.8918 121.925C49.3419 121.925 48.0851 120.668 48.0851 119.118C48.0851 117.568 49.3419 116.312 50.8918 116.312C52.4418 116.312 53.6986 117.568 53.6986 119.118Z" />
          <path d="M46.8566 119.118C46.8566 120.668 45.5998 121.925 44.0499 121.925C42.5 121.925 41.2432 120.668 41.2432 119.118C41.2432 117.568 42.5 116.312 44.0499 116.312C45.5998 116.312 46.8566 117.568 46.8566 119.118Z" />
          <path d="M40.0148 125.96C40.0148 127.51 38.758 128.767 37.2081 128.767C35.6582 128.767 34.4014 127.51 34.4014 125.96C34.4014 124.41 35.6582 123.153 37.2081 123.153C38.758 123.153 40.0148 124.41 40.0148 125.96Z" />
          <path d="M33.173 125.96C33.173 127.51 31.9162 128.767 30.3663 128.767C28.8164 128.767 27.5596 127.51 27.5596 125.96C27.5596 124.41 28.8164 123.153 30.3663 123.153C31.9162 123.153 33.173 124.41 33.173 125.96Z" />
          <path d="M26.3311 132.802C26.3311 134.352 25.0743 135.608 23.5244 135.608C21.9744 135.608 20.7177 134.352 20.7177 132.802C20.7177 131.252 21.9744 129.995 23.5244 129.995C25.0743 129.995 26.3311 131.252 26.3311 132.802Z" />
          <path d="M19.4893 132.802C19.4893 134.352 18.2325 135.608 16.6826 135.608C15.1326 135.608 13.8759 134.352 13.8759 132.802C13.8759 131.252 15.1326 129.995 16.6826 129.995C18.2325 129.995 19.4893 131.252 19.4893 132.802Z" />
          <path d="M12.6474 132.802C12.6474 134.352 11.3906 135.608 9.84067 135.608C8.29073 135.608 7.03394 134.352 7.03394 132.802C7.03394 131.252 8.29073 129.995 9.84067 129.995C11.3906 129.995 12.6474 131.252 12.6474 132.802Z" />
          <path d="M5.80561 132.802C5.80561 134.352 4.54881 135.608 2.99887 135.608C1.44893 135.608 0.192139 134.352 0.192139 132.802C0.192139 131.252 1.44893 129.995 2.99887 129.995C4.54881 129.995 5.80561 131.252 5.80561 132.802Z" />
          <path d="M128.959 187.537C128.959 189.087 127.702 190.344 126.152 190.344C124.602 190.344 123.345 189.087 123.345 187.537C123.345 185.987 124.602 184.73 126.152 184.73C127.702 184.73 128.959 185.987 128.959 187.537Z" />
          <path d="M128.959 194.379C128.959 195.929 127.702 197.185 126.152 197.185C124.602 197.185 123.345 195.929 123.345 194.379C123.345 192.829 124.602 191.572 126.152 191.572C127.702 191.572 128.959 192.829 128.959 194.379Z" />
          <path d="M128.959 201.22C128.959 202.77 127.702 204.027 126.152 204.027C124.602 204.027 123.345 202.77 123.345 201.22C123.345 199.67 124.602 198.413 126.152 198.413C127.702 198.413 128.959 199.67 128.959 201.22Z" />
          <path d="M135.801 201.22C135.801 202.77 134.544 204.027 132.994 204.027C131.444 204.027 130.187 202.77 130.187 201.22C130.187 199.67 131.444 198.413 132.994 198.413C134.544 198.413 135.801 199.67 135.801 201.22Z" />
          <path d="M142.643 201.22C142.643 202.77 141.386 204.027 139.836 204.027C138.286 204.027 137.029 202.77 137.029 201.22C137.029 199.67 138.286 198.413 139.836 198.413C141.386 198.413 142.643 199.67 142.643 201.22Z" />
          <path d="M149.484 201.22C149.484 202.77 148.228 204.027 146.678 204.027C145.128 204.027 143.871 202.77 143.871 201.22C143.871 199.67 145.128 198.413 146.678 198.413C148.228 198.413 149.484 199.67 149.484 201.22Z" />
          <path d="M156.326 201.22C156.326 202.77 155.069 204.027 153.52 204.027C151.97 204.027 150.713 202.77 150.713 201.22C150.713 199.67 151.97 198.413 153.52 198.413C155.069 198.413 156.326 199.67 156.326 201.22Z" />
          <path d="M163.168 201.22C163.168 202.77 161.911 204.027 160.361 204.027C158.811 204.027 157.555 202.77 157.555 201.22C157.555 199.67 158.811 198.413 160.361 198.413C161.911 198.413 163.168 199.67 163.168 201.22Z" />
          <path d="M170.01 201.22C170.01 202.77 168.753 204.027 167.203 204.027C165.653 204.027 164.396 202.77 164.396 201.22C164.396 199.67 165.653 198.413 167.203 198.413C168.753 198.413 170.01 199.67 170.01 201.22Z" />
          <path d="M176.852 201.22C176.852 202.77 175.595 204.027 174.045 204.027C172.495 204.027 171.238 202.77 171.238 201.22C171.238 199.67 172.495 198.413 174.045 198.413C175.595 198.413 176.852 199.67 176.852 201.22Z" />
          <path d="M183.694 201.22C183.694 202.77 182.437 204.027 180.887 204.027C179.337 204.027 178.08 202.77 178.08 201.22C178.08 199.67 179.337 198.413 180.887 198.413C182.437 198.413 183.694 199.67 183.694 201.22Z" />
          <path d="M190.535 201.22C190.535 202.77 189.279 204.027 187.729 204.027C186.179 204.027 184.922 202.77 184.922 201.22C184.922 199.67 186.179 198.413 187.729 198.413C189.279 198.413 190.535 199.67 190.535 201.22Z" />
          <path d="M190.535 194.379C190.535 195.929 189.279 197.185 187.729 197.185C186.179 197.185 184.922 195.929 184.922 194.379C184.922 192.829 186.179 191.572 187.729 191.572C189.279 191.572 190.535 192.829 190.535 194.379Z" />
          <path d="M197.377 194.379C197.377 195.929 196.12 197.185 194.571 197.185C193.021 197.185 191.764 195.929 191.764 194.379C191.764 192.829 193.021 191.572 194.571 191.572C196.12 191.572 197.377 192.829 197.377 194.379Z" />
          <path d="M197.377 187.537C197.377 189.087 196.12 190.344 194.571 190.344C193.021 190.344 191.764 189.087 191.764 187.537C191.764 185.987 193.021 184.73 194.571 184.73C196.12 184.73 197.377 185.987 197.377 187.537Z" />
          <path d="M190.535 187.537C190.535 189.087 189.279 190.344 187.729 190.344C186.179 190.344 184.922 189.087 184.922 187.537C184.922 185.987 186.179 184.73 187.729 184.73C189.279 184.73 190.535 185.987 190.535 187.537Z" />
          <path d="M183.694 187.537C183.694 189.087 182.437 190.344 180.887 190.344C179.337 190.344 178.08 189.087 178.08 187.537C178.08 185.987 179.337 184.73 180.887 184.73C182.437 184.73 183.694 185.987 183.694 187.537Z" />
          <path d="M176.852 187.537C176.852 189.087 175.595 190.344 174.045 190.344C172.495 190.344 171.238 189.087 171.238 187.537C171.238 185.987 172.495 184.73 174.045 184.73C175.595 184.73 176.852 185.987 176.852 187.537Z" />
          <path d="M170.01 187.537C170.01 189.087 168.753 190.344 167.203 190.344C165.653 190.344 164.396 189.087 164.396 187.537C164.396 185.987 165.653 184.73 167.203 184.73C168.753 184.73 170.01 185.987 170.01 187.537Z" />
          <path d="M163.168 187.537C163.168 189.087 161.911 190.344 160.361 190.344C158.811 190.344 157.555 189.087 157.555 187.537C157.555 185.987 158.811 184.73 160.361 184.73C161.911 184.73 163.168 185.987 163.168 187.537Z" />
          <path d="M156.326 187.537C156.326 189.087 155.069 190.344 153.52 190.344C151.97 190.344 150.713 189.087 150.713 187.537C150.713 185.987 151.97 184.73 153.52 184.73C155.069 184.73 156.326 185.987 156.326 187.537Z" />
          <path d="M149.484 187.537C149.484 189.087 148.228 190.344 146.678 190.344C145.128 190.344 143.871 189.087 143.871 187.537C143.871 185.987 145.128 184.73 146.678 184.73C148.228 184.73 149.484 185.987 149.484 187.537Z" />
          <path d="M142.643 187.537C142.643 189.087 141.386 190.344 139.836 190.344C138.286 190.344 137.029 189.087 137.029 187.537C137.029 185.987 138.286 184.73 139.836 184.73C141.386 184.73 142.643 185.987 142.643 187.537Z" />
          <path d="M135.801 187.537C135.801 189.087 134.544 190.344 132.994 190.344C131.444 190.344 130.187 189.087 130.187 187.537C130.187 185.987 131.444 184.73 132.994 184.73C134.544 184.73 135.801 185.987 135.801 187.537Z" />
          <path d="M135.801 194.379C135.801 195.929 134.544 197.185 132.994 197.185C131.444 197.185 130.187 195.929 130.187 194.379C130.187 192.829 131.444 191.572 132.994 191.572C134.544 191.572 135.801 192.829 135.801 194.379Z" />
          <path d="M142.643 194.379C142.643 195.929 141.386 197.185 139.836 197.185C138.286 197.185 137.029 195.929 137.029 194.379C137.029 192.829 138.286 191.572 139.836 191.572C141.386 191.572 142.643 192.829 142.643 194.379Z" />
          <path d="M149.484 194.379C149.484 195.929 148.228 197.185 146.678 197.185C145.128 197.185 143.871 195.929 143.871 194.379C143.871 192.829 145.128 191.572 146.678 191.572C148.228 191.572 149.484 192.829 149.484 194.379Z" />
          <path d="M156.326 194.379C156.326 195.929 155.069 197.185 153.52 197.185C151.97 197.185 150.713 195.929 150.713 194.379C150.713 192.829 151.97 191.572 153.52 191.572C155.069 191.572 156.326 192.829 156.326 194.379Z" />
          <path d="M163.168 194.379C163.168 195.929 161.911 197.185 160.361 197.185C158.811 197.185 157.555 195.929 157.555 194.379C157.555 192.829 158.811 191.572 160.361 191.572C161.911 191.572 163.168 192.829 163.168 194.379Z" />
          <path d="M170.01 194.379C170.01 195.929 168.753 197.185 167.203 197.185C165.653 197.185 164.396 195.929 164.396 194.379C164.396 192.829 165.653 191.572 167.203 191.572C168.753 191.572 170.01 192.829 170.01 194.379Z" />
          <path d="M176.852 194.379C176.852 195.929 175.595 197.185 174.045 197.185C172.495 197.185 171.238 195.929 171.238 194.379C171.238 192.829 172.495 191.572 174.045 191.572C175.595 191.572 176.852 192.829 176.852 194.379Z" />
          <path d="M183.694 194.379C183.694 195.929 182.437 197.185 180.887 197.185C179.337 197.185 178.08 195.929 178.08 194.379C178.08 192.829 179.337 191.572 180.887 191.572C182.437 191.572 183.694 192.829 183.694 194.379Z" />
          <path d="M197.377 201.22C197.377 202.77 196.12 204.027 194.571 204.027C193.021 204.027 191.764 202.77 191.764 201.22C191.764 199.67 193.021 198.413 194.571 198.413C196.12 198.413 197.377 199.67 197.377 201.22Z" />
          <path d="M197.377 208.062C197.377 209.612 196.12 210.869 194.571 210.869C193.021 210.869 191.764 209.612 191.764 208.062C191.764 206.512 193.021 205.256 194.571 205.256C196.12 205.256 197.377 206.512 197.377 208.062Z" />
          <path d="M190.535 208.062C190.535 209.612 189.279 210.869 187.729 210.869C186.179 210.869 184.922 209.612 184.922 208.062C184.922 206.512 186.179 205.256 187.729 205.256C189.279 205.256 190.535 206.512 190.535 208.062Z" />
          <path d="M183.694 208.062C183.694 209.612 182.437 210.869 180.887 210.869C179.337 210.869 178.08 209.612 178.08 208.062C178.08 206.512 179.337 205.256 180.887 205.256C182.437 205.256 183.694 206.512 183.694 208.062Z" />
          <path d="M176.852 208.062C176.852 209.612 175.595 210.869 174.045 210.869C172.495 210.869 171.238 209.612 171.238 208.062C171.238 206.512 172.495 205.256 174.045 205.256C175.595 205.256 176.852 206.512 176.852 208.062Z" />
          <path d="M170.01 208.062C170.01 209.612 168.753 210.869 167.203 210.869C165.653 210.869 164.396 209.612 164.396 208.062C164.396 206.512 165.653 205.256 167.203 205.256C168.753 205.256 170.01 206.512 170.01 208.062Z" />
          <path d="M163.168 208.062C163.168 209.612 161.911 210.869 160.361 210.869C158.811 210.869 157.555 209.612 157.555 208.062C157.555 206.512 158.811 205.256 160.361 205.256C161.911 205.256 163.168 206.512 163.168 208.062Z" />
          <path d="M156.326 208.062C156.326 209.612 155.069 210.869 153.52 210.869C151.97 210.869 150.713 209.612 150.713 208.062C150.713 206.512 151.97 205.256 153.52 205.256C155.069 205.256 156.326 206.512 156.326 208.062Z" />
          <path d="M149.484 208.062C149.484 209.612 148.228 210.869 146.678 210.869C145.128 210.869 143.871 209.612 143.871 208.062C143.871 206.512 145.128 205.256 146.678 205.256C148.228 205.256 149.484 206.512 149.484 208.062Z" />
          <path d="M142.643 208.062C142.643 209.612 141.386 210.869 139.836 210.869C138.286 210.869 137.029 209.612 137.029 208.062C137.029 206.512 138.286 205.256 139.836 205.256C141.386 205.256 142.643 206.512 142.643 208.062Z" />
          <path d="M135.801 208.062C135.801 209.612 134.544 210.869 132.994 210.869C131.444 210.869 130.187 209.612 130.187 208.062C130.187 206.512 131.444 205.256 132.994 205.256C134.544 205.256 135.801 206.512 135.801 208.062Z" />
          <path d="M204.219 208.062C204.219 209.612 202.962 210.869 201.412 210.869C199.863 210.869 198.606 209.612 198.606 208.062C198.606 206.512 199.863 205.256 201.412 205.256C202.962 205.256 204.219 206.512 204.219 208.062Z" />
          <path d="M204.219 201.22C204.219 202.77 202.962 204.027 201.412 204.027C199.863 204.027 198.606 202.77 198.606 201.22C198.606 199.67 199.863 198.413 201.412 198.413C202.962 198.413 204.219 199.67 204.219 201.22Z" />
          <path d="M211.061 194.379C211.061 195.929 209.804 197.185 208.254 197.185C206.704 197.185 205.448 195.929 205.448 194.379C205.448 192.829 206.704 191.572 208.254 191.572C209.804 191.572 211.061 192.829 211.061 194.379Z" />
          <path d="M211.061 187.537C211.061 189.087 209.804 190.344 208.254 190.344C206.704 190.344 205.448 189.087 205.448 187.537C205.448 185.987 206.704 184.73 208.254 184.73C209.804 184.73 211.061 185.987 211.061 187.537Z" />
          <path d="M122.117 194.379C122.117 195.929 120.86 197.185 119.31 197.185C117.76 197.185 116.504 195.929 116.504 194.379C116.504 192.829 117.76 191.572 119.31 191.572C120.86 191.572 122.117 192.829 122.117 194.379Z" />
          <path d="M122.117 201.22C122.117 202.77 120.86 204.027 119.31 204.027C117.76 204.027 116.504 202.77 116.504 201.22C116.504 199.67 117.76 198.413 119.31 198.413C120.86 198.413 122.117 199.67 122.117 201.22Z" />
          <path d="M122.117 208.062C122.117 209.612 120.86 210.869 119.31 210.869C117.76 210.869 116.504 209.612 116.504 208.062C116.504 206.512 117.76 205.256 119.31 205.256C120.86 205.256 122.117 206.512 122.117 208.062Z" />
          <path d="M128.959 208.062C128.959 209.612 127.702 210.869 126.152 210.869C124.602 210.869 123.345 209.612 123.345 208.062C123.345 206.512 124.602 205.256 126.152 205.256C127.702 205.256 128.959 206.512 128.959 208.062Z" />
          <path d="M128.959 214.904C128.959 216.454 127.702 217.711 126.152 217.711C124.602 217.711 123.345 216.454 123.345 214.904C123.345 213.354 124.602 212.097 126.152 212.097C127.702 212.097 128.959 213.354 128.959 214.904Z" />
          <path d="M135.801 214.904C135.801 216.454 134.544 217.711 132.994 217.711C131.444 217.711 130.187 216.454 130.187 214.904C130.187 213.354 131.444 212.097 132.994 212.097C134.544 212.097 135.801 213.354 135.801 214.904Z" />
          <path d="M142.643 214.904C142.643 216.454 141.386 217.711 139.836 217.711C138.286 217.711 137.029 216.454 137.029 214.904C137.029 213.354 138.286 212.097 139.836 212.097C141.386 212.097 142.643 213.354 142.643 214.904Z" />
          <path d="M149.484 214.904C149.484 216.454 148.228 217.711 146.678 217.711C145.128 217.711 143.871 216.454 143.871 214.904C143.871 213.354 145.128 212.097 146.678 212.097C148.228 212.097 149.484 213.354 149.484 214.904Z" />
          <path d="M156.326 214.904C156.326 216.454 155.069 217.711 153.52 217.711C151.97 217.711 150.713 216.454 150.713 214.904C150.713 213.354 151.97 212.097 153.52 212.097C155.069 212.097 156.326 213.354 156.326 214.904Z" />
          <path d="M163.168 214.904C163.168 216.454 161.911 217.711 160.361 217.711C158.811 217.711 157.555 216.454 157.555 214.904C157.555 213.354 158.811 212.097 160.361 212.097C161.911 212.097 163.168 213.354 163.168 214.904Z" />
          <path d="M170.01 214.904C170.01 216.454 168.753 217.711 167.203 217.711C165.653 217.711 164.396 216.454 164.396 214.904C164.396 213.354 165.653 212.097 167.203 212.097C168.753 212.097 170.01 213.354 170.01 214.904Z" />
          <path d="M176.852 214.904C176.852 216.454 175.595 217.711 174.045 217.711C172.495 217.711 171.238 216.454 171.238 214.904C171.238 213.354 172.495 212.097 174.045 212.097C175.595 212.097 176.852 213.354 176.852 214.904Z" />
          <path d="M183.694 214.904C183.694 216.454 182.437 217.711 180.887 217.711C179.337 217.711 178.08 216.454 178.08 214.904C178.08 213.354 179.337 212.097 180.887 212.097C182.437 212.097 183.694 213.354 183.694 214.904Z" />
          <path d="M190.535 214.904C190.535 216.454 189.279 217.711 187.729 217.711C186.179 217.711 184.922 216.454 184.922 214.904C184.922 213.354 186.179 212.097 187.729 212.097C189.279 212.097 190.535 213.354 190.535 214.904Z" />
          <path d="M197.377 214.904C197.377 216.454 196.12 217.711 194.571 217.711C193.021 217.711 191.764 216.454 191.764 214.904C191.764 213.354 193.021 212.097 194.571 212.097C196.12 212.097 197.377 213.354 197.377 214.904Z" />
          <path d="M204.219 214.904C204.219 216.454 202.962 217.711 201.412 217.711C199.863 217.711 198.606 216.454 198.606 214.904C198.606 213.354 199.863 212.097 201.412 212.097C202.962 212.097 204.219 213.354 204.219 214.904Z" />
          <path d="M204.219 221.746C204.219 223.296 202.962 224.553 201.412 224.553C199.863 224.553 198.606 223.296 198.606 221.746C198.606 220.196 199.863 218.939 201.412 218.939C202.962 218.939 204.219 220.196 204.219 221.746Z" />
          <path d="M197.377 221.746C197.377 223.296 196.12 224.553 194.571 224.553C193.021 224.553 191.764 223.296 191.764 221.746C191.764 220.196 193.021 218.939 194.571 218.939C196.12 218.939 197.377 220.196 197.377 221.746Z" />
          <path d="M190.535 221.746C190.535 223.296 189.279 224.553 187.729 224.553C186.179 224.553 184.922 223.296 184.922 221.746C184.922 220.196 186.179 218.939 187.729 218.939C189.279 218.939 190.535 220.196 190.535 221.746Z" />
          <path d="M183.694 221.746C183.694 223.296 182.437 224.553 180.887 224.553C179.337 224.553 178.08 223.296 178.08 221.746C178.08 220.196 179.337 218.939 180.887 218.939C182.437 218.939 183.694 220.196 183.694 221.746Z" />
          <path d="M176.852 221.746C176.852 223.296 175.595 224.553 174.045 224.553C172.495 224.553 171.238 223.296 171.238 221.746C171.238 220.196 172.495 218.939 174.045 218.939C175.595 218.939 176.852 220.196 176.852 221.746Z" />
          <path d="M170.01 221.746C170.01 223.296 168.753 224.553 167.203 224.553C165.653 224.553 164.396 223.296 164.396 221.746C164.396 220.196 165.653 218.939 167.203 218.939C168.753 218.939 170.01 220.196 170.01 221.746Z" />
          <path d="M163.168 221.746C163.168 223.296 161.911 224.553 160.361 224.553C158.811 224.553 157.555 223.296 157.555 221.746C157.555 220.196 158.811 218.939 160.361 218.939C161.911 218.939 163.168 220.196 163.168 221.746Z" />
          <path d="M156.326 221.746C156.326 223.296 155.069 224.553 153.52 224.553C151.97 224.553 150.713 223.296 150.713 221.746C150.713 220.196 151.97 218.939 153.52 218.939C155.069 218.939 156.326 220.196 156.326 221.746Z" />
          <path d="M149.484 221.746C149.484 223.296 148.228 224.553 146.678 224.553C145.128 224.553 143.871 223.296 143.871 221.746C143.871 220.196 145.128 218.939 146.678 218.939C148.228 218.939 149.484 220.196 149.484 221.746Z" />
          <path d="M142.643 221.746C142.643 223.296 141.386 224.553 139.836 224.553C138.286 224.553 137.029 223.296 137.029 221.746C137.029 220.196 138.286 218.939 139.836 218.939C141.386 218.939 142.643 220.196 142.643 221.746Z" />
          <path d="M135.801 221.746C135.801 223.296 134.544 224.553 132.994 224.553C131.444 224.553 130.187 223.296 130.187 221.746C130.187 220.196 131.444 218.939 132.994 218.939C134.544 218.939 135.801 220.196 135.801 221.746Z" />
          <path d="M108.433 201.22C108.433 202.77 107.176 204.027 105.627 204.027C104.077 204.027 102.82 202.77 102.82 201.22C102.82 199.67 104.077 198.413 105.627 198.413C107.176 198.413 108.433 199.67 108.433 201.22Z" />
          <path d="M135.801 242.272C135.801 243.822 134.544 245.078 132.994 245.078C131.444 245.078 130.187 243.822 130.187 242.272C130.187 240.722 131.444 239.465 132.994 239.465C134.544 239.465 135.801 240.722 135.801 242.272Z" />
          <path d="M156.326 262.797C156.326 264.347 155.069 265.604 153.52 265.604C151.97 265.604 150.713 264.347 150.713 262.797C150.713 261.247 151.97 259.99 153.52 259.99C155.069 259.99 156.326 261.247 156.326 262.797Z" />
          <path d="M163.168 262.797C163.168 264.347 161.911 265.604 160.361 265.604C158.811 265.604 157.555 264.347 157.555 262.797C157.555 261.247 158.811 259.99 160.361 259.99C161.911 259.99 163.168 261.247 163.168 262.797Z" />
          <path d="M163.168 255.955C163.168 257.505 161.911 258.762 160.361 258.762C158.811 258.762 157.555 257.505 157.555 255.955C157.555 254.405 158.811 253.148 160.361 253.148C161.911 253.148 163.168 254.405 163.168 255.955Z" />
          <path d="M163.168 249.113C163.168 250.663 161.911 251.92 160.361 251.92C158.811 251.92 157.555 250.663 157.555 249.113C157.555 247.563 158.811 246.307 160.361 246.307C161.911 246.307 163.168 247.563 163.168 249.113Z" />
          <path d="M163.168 242.272C163.168 243.822 161.911 245.078 160.361 245.078C158.811 245.078 157.555 243.822 157.555 242.272C157.555 240.722 158.811 239.465 160.361 239.465C161.911 239.465 163.168 240.722 163.168 242.272Z" />
          <path d="M170.01 242.272C170.01 243.822 168.753 245.078 167.203 245.078C165.653 245.078 164.396 243.822 164.396 242.272C164.396 240.722 165.653 239.465 167.203 239.465C168.753 239.465 170.01 240.722 170.01 242.272Z" />
          <path d="M163.168 235.43C163.168 236.98 161.911 238.236 160.361 238.236C158.811 238.236 157.555 236.98 157.555 235.43C157.555 233.88 158.811 232.623 160.361 232.623C161.911 232.623 163.168 233.88 163.168 235.43Z" />
          <path d="M170.01 235.43C170.01 236.98 168.753 238.236 167.203 238.236C165.653 238.236 164.396 236.98 164.396 235.43C164.396 233.88 165.653 232.623 167.203 232.623C168.753 232.623 170.01 233.88 170.01 235.43Z" />
          <path d="M176.852 235.43C176.852 236.98 175.595 238.236 174.045 238.236C172.495 238.236 171.238 236.98 171.238 235.43C171.238 233.88 172.495 232.623 174.045 232.623C175.595 232.623 176.852 233.88 176.852 235.43Z" />
          <path d="M183.694 235.43C183.694 236.98 182.437 238.236 180.887 238.236C179.337 238.236 178.08 236.98 178.08 235.43C178.08 233.88 179.337 232.623 180.887 232.623C182.437 232.623 183.694 233.88 183.694 235.43Z" />
          <path d="M190.535 235.43C190.535 236.98 189.279 238.236 187.729 238.236C186.179 238.236 184.922 236.98 184.922 235.43C184.922 233.88 186.179 232.623 187.729 232.623C189.279 232.623 190.535 233.88 190.535 235.43Z" />
          <path d="M197.377 235.43C197.377 236.98 196.12 238.236 194.571 238.236C193.021 238.236 191.764 236.98 191.764 235.43C191.764 233.88 193.021 232.623 194.571 232.623C196.12 232.623 197.377 233.88 197.377 235.43Z" />
          <path d="M204.219 235.43C204.219 236.98 202.962 238.236 201.412 238.236C199.863 238.236 198.606 236.98 198.606 235.43C198.606 233.88 199.863 232.623 201.412 232.623C202.962 232.623 204.219 233.88 204.219 235.43Z" />
          <path d="M204.219 242.272C204.219 243.822 202.962 245.078 201.412 245.078C199.863 245.078 198.606 243.822 198.606 242.272C198.606 240.722 199.863 239.465 201.412 239.465C202.962 239.465 204.219 240.722 204.219 242.272Z" />
          <path d="M211.061 249.113C211.061 250.663 209.804 251.92 208.254 251.92C206.704 251.92 205.448 250.663 205.448 249.113C205.448 247.563 206.704 246.307 208.254 246.307C209.804 246.307 211.061 247.563 211.061 249.113Z" />
          <path d="M224.745 255.955C224.745 257.505 223.488 258.762 221.938 258.762C220.388 258.762 219.131 257.505 219.131 255.955C219.131 254.405 220.388 253.148 221.938 253.148C223.488 253.148 224.745 254.405 224.745 255.955Z" />
          <path d="M211.061 262.797C211.061 264.347 209.804 265.604 208.254 265.604C206.704 265.604 205.448 264.347 205.448 262.797C205.448 261.247 206.704 259.99 208.254 259.99C209.804 259.99 211.061 261.247 211.061 262.797Z" />
          <path d="M204.219 262.797C204.219 264.347 202.962 265.604 201.412 265.604C199.863 265.604 198.606 264.347 198.606 262.797C198.606 261.247 199.863 259.99 201.412 259.99C202.962 259.99 204.219 261.247 204.219 262.797Z" />
          <path d="M217.903 262.797C217.903 264.347 216.646 265.604 215.096 265.604C213.546 265.604 212.289 264.347 212.289 262.797C212.289 261.247 213.546 259.99 215.096 259.99C216.646 259.99 217.903 261.247 217.903 262.797Z" />
          <path d="M231.586 269.639C231.586 271.189 230.33 272.445 228.78 272.445C227.23 272.445 225.973 271.189 225.973 269.639C225.973 268.089 227.23 266.832 228.78 266.832C230.33 266.832 231.586 268.089 231.586 269.639Z" />
          <path d="M245.27 269.639C245.27 271.189 244.013 272.445 242.463 272.445C240.914 272.445 239.657 271.189 239.657 269.639C239.657 268.089 240.914 266.832 242.463 266.832C244.013 266.832 245.27 268.089 245.27 269.639Z" />
          <path d="M183.694 269.639C183.694 271.189 182.437 272.445 180.887 272.445C179.337 272.445 178.08 271.189 178.08 269.639C178.08 268.089 179.337 266.832 180.887 266.832C182.437 266.832 183.694 268.089 183.694 269.639Z" />
          <path d="M190.535 262.797C190.535 264.347 189.279 265.604 187.729 265.604C186.179 265.604 184.922 264.347 184.922 262.797C184.922 261.247 186.179 259.99 187.729 259.99C189.279 259.99 190.535 261.247 190.535 262.797Z" />
          <path d="M217.903 303.848C217.903 305.398 216.646 306.655 215.096 306.655C213.546 306.655 212.289 305.398 212.289 303.848C212.289 302.298 213.546 301.041 215.096 301.041C216.646 301.041 217.903 302.298 217.903 303.848Z" />
          <path d="M224.745 303.848C224.745 305.398 223.488 306.655 221.938 306.655C220.388 306.655 219.131 305.398 219.131 303.848C219.131 302.298 220.388 301.041 221.938 301.041C223.488 301.041 224.745 302.298 224.745 303.848Z" />
          <path d="M231.586 303.848C231.586 305.398 230.33 306.655 228.78 306.655C227.23 306.655 225.973 305.398 225.973 303.848C225.973 302.298 227.23 301.041 228.78 301.041C230.33 301.041 231.586 302.298 231.586 303.848Z" />
          <path d="M238.428 303.848C238.428 305.398 237.172 306.655 235.622 306.655C234.072 306.655 232.815 305.398 232.815 303.848C232.815 302.298 234.072 301.041 235.622 301.041C237.172 301.041 238.428 302.298 238.428 303.848Z" />
          <path d="M245.27 303.848C245.27 305.398 244.013 306.655 242.463 306.655C240.914 306.655 239.657 305.398 239.657 303.848C239.657 302.298 240.914 301.041 242.463 301.041C244.013 301.041 245.27 302.298 245.27 303.848Z" />
          <path d="M245.27 310.69C245.27 312.24 244.013 313.497 242.463 313.497C240.914 313.497 239.657 312.24 239.657 310.69C239.657 309.14 240.914 307.883 242.463 307.883C244.013 307.883 245.27 309.14 245.27 310.69Z" />
          <path d="M252.112 310.69C252.112 312.24 250.855 313.497 249.305 313.497C247.755 313.497 246.499 312.24 246.499 310.69C246.499 309.14 247.755 307.883 249.305 307.883C250.855 307.883 252.112 309.14 252.112 310.69Z" />
          <path d="M252.112 317.532C252.112 319.082 250.855 320.339 249.305 320.339C247.755 320.339 246.499 319.082 246.499 317.532C246.499 315.982 247.755 314.725 249.305 314.725C250.855 314.725 252.112 315.982 252.112 317.532Z" />
          <path d="M252.112 324.373C252.112 325.923 250.855 327.18 249.305 327.18C247.755 327.18 246.499 325.923 246.499 324.373C246.499 322.824 247.755 321.567 249.305 321.567C250.855 321.567 252.112 322.824 252.112 324.373Z" />
          <path d="M252.112 331.215C252.112 332.765 250.855 334.022 249.305 334.022C247.755 334.022 246.499 332.765 246.499 331.215C246.499 329.666 247.755 328.409 249.305 328.409C250.855 328.409 252.112 329.666 252.112 331.215Z" />
          <path d="M252.112 338.057C252.112 339.607 250.855 340.864 249.305 340.864C247.755 340.864 246.499 339.607 246.499 338.057C246.499 336.507 247.755 335.25 249.305 335.25C250.855 335.25 252.112 336.507 252.112 338.057Z" />
          <path d="M252.112 344.899C252.112 346.449 250.855 347.706 249.305 347.706C247.755 347.706 246.499 346.449 246.499 344.899C246.499 343.349 247.755 342.093 249.305 342.093C250.855 342.093 252.112 343.349 252.112 344.899Z" />
          <path d="M252.112 351.741C252.112 353.291 250.855 354.548 249.305 354.548C247.755 354.548 246.499 353.291 246.499 351.741C246.499 350.191 247.755 348.934 249.305 348.934C250.855 348.934 252.112 350.191 252.112 351.741Z" />
          <path d="M252.112 358.583C252.112 360.133 250.855 361.39 249.305 361.39C247.755 361.39 246.499 360.133 246.499 358.583C246.499 357.033 247.755 355.776 249.305 355.776C250.855 355.776 252.112 357.033 252.112 358.583Z" />
          <path d="M258.954 358.583C258.954 360.133 257.697 361.39 256.147 361.39C254.597 361.39 253.34 360.133 253.34 358.583C253.34 357.033 254.597 355.776 256.147 355.776C257.697 355.776 258.954 357.033 258.954 358.583Z" />
          <path d="M258.954 351.741C258.954 353.291 257.697 354.548 256.147 354.548C254.597 354.548 253.34 353.291 253.34 351.741C253.34 350.191 254.597 348.934 256.147 348.934C257.697 348.934 258.954 350.191 258.954 351.741Z" />
          <path d="M258.954 344.899C258.954 346.449 257.697 347.706 256.147 347.706C254.597 347.706 253.34 346.449 253.34 344.899C253.34 343.349 254.597 342.093 256.147 342.093C257.697 342.093 258.954 343.349 258.954 344.899Z" />
          <path d="M258.954 338.057C258.954 339.607 257.697 340.864 256.147 340.864C254.597 340.864 253.34 339.607 253.34 338.057C253.34 336.507 254.597 335.25 256.147 335.25C257.697 335.25 258.954 336.507 258.954 338.057Z" />
          <path d="M258.954 331.215C258.954 332.765 257.697 334.022 256.147 334.022C254.597 334.022 253.34 332.765 253.34 331.215C253.34 329.666 254.597 328.409 256.147 328.409C257.697 328.409 258.954 329.666 258.954 331.215Z" />
          <path d="M258.954 324.373C258.954 325.923 257.697 327.18 256.147 327.18C254.597 327.18 253.34 325.923 253.34 324.373C253.34 322.824 254.597 321.567 256.147 321.567C257.697 321.567 258.954 322.824 258.954 324.373Z" />
          <path d="M258.954 317.532C258.954 319.082 257.697 320.339 256.147 320.339C254.597 320.339 253.34 319.082 253.34 317.532C253.34 315.982 254.597 314.725 256.147 314.725C257.697 314.725 258.954 315.982 258.954 317.532Z" />
          <path d="M265.796 317.532C265.796 319.082 264.539 320.339 262.989 320.339C261.439 320.339 260.182 319.082 260.182 317.532C260.182 315.982 261.439 314.725 262.989 314.725C264.539 314.725 265.796 315.982 265.796 317.532Z" />
          <path d="M265.796 324.373C265.796 325.923 264.539 327.18 262.989 327.18C261.439 327.18 260.182 325.923 260.182 324.373C260.182 322.824 261.439 321.567 262.989 321.567C264.539 321.567 265.796 322.824 265.796 324.373Z" />
          <path d="M265.796 331.215C265.796 332.765 264.539 334.022 262.989 334.022C261.439 334.022 260.182 332.765 260.182 331.215C260.182 329.666 261.439 328.409 262.989 328.409C264.539 328.409 265.796 329.666 265.796 331.215Z" />
          <path d="M265.796 338.057C265.796 339.607 264.539 340.864 262.989 340.864C261.439 340.864 260.182 339.607 260.182 338.057C260.182 336.507 261.439 335.25 262.989 335.25C264.539 335.25 265.796 336.507 265.796 338.057Z" />
          <path d="M265.796 344.899C265.796 346.449 264.539 347.706 262.989 347.706C261.439 347.706 260.182 346.449 260.182 344.899C260.182 343.349 261.439 342.093 262.989 342.093C264.539 342.093 265.796 343.349 265.796 344.899Z" />
          <path d="M265.796 351.741C265.796 353.291 264.539 354.548 262.989 354.548C261.439 354.548 260.182 353.291 260.182 351.741C260.182 350.191 261.439 348.934 262.989 348.934C264.539 348.934 265.796 350.191 265.796 351.741Z" />
          <path d="M265.796 358.583C265.796 360.133 264.539 361.39 262.989 361.39C261.439 361.39 260.182 360.133 260.182 358.583C260.182 357.033 261.439 355.776 262.989 355.776C264.539 355.776 265.796 357.033 265.796 358.583Z" />
          <path d="M272.638 317.532C272.638 319.082 271.381 320.339 269.831 320.339C268.281 320.339 267.024 319.082 267.024 317.532C267.024 315.982 268.281 314.725 269.831 314.725C271.381 314.725 272.638 315.982 272.638 317.532Z" />
          <path d="M272.638 324.373C272.638 325.923 271.381 327.18 269.831 327.18C268.281 327.18 267.024 325.923 267.024 324.373C267.024 322.824 268.281 321.567 269.831 321.567C271.381 321.567 272.638 322.824 272.638 324.373Z" />
          <path d="M272.638 331.215C272.638 332.765 271.381 334.022 269.831 334.022C268.281 334.022 267.024 332.765 267.024 331.215C267.024 329.666 268.281 328.409 269.831 328.409C271.381 328.409 272.638 329.666 272.638 331.215Z" />
          <path d="M272.638 338.057C272.638 339.607 271.381 340.864 269.831 340.864C268.281 340.864 267.024 339.607 267.024 338.057C267.024 336.507 268.281 335.25 269.831 335.25C271.381 335.25 272.638 336.507 272.638 338.057Z" />
          <path d="M272.638 344.899C272.638 346.449 271.381 347.706 269.831 347.706C268.281 347.706 267.024 346.449 267.024 344.899C267.024 343.349 268.281 342.093 269.831 342.093C271.381 342.093 272.638 343.349 272.638 344.899Z" />
          <path d="M272.638 351.741C272.638 353.291 271.381 354.548 269.831 354.548C268.281 354.548 267.024 353.291 267.024 351.741C267.024 350.191 268.281 348.934 269.831 348.934C271.381 348.934 272.638 350.191 272.638 351.741Z" />
          <path d="M272.638 358.583C272.638 360.133 271.381 361.39 269.831 361.39C268.281 361.39 267.024 360.133 267.024 358.583C267.024 357.033 268.281 355.776 269.831 355.776C271.381 355.776 272.638 357.033 272.638 358.583Z" />
          <path d="M279.479 358.583C279.479 360.133 278.223 361.39 276.673 361.39C275.123 361.39 273.866 360.133 273.866 358.583C273.866 357.033 275.123 355.776 276.673 355.776C278.223 355.776 279.479 357.033 279.479 358.583Z" />
          <path d="M286.321 358.583C286.321 360.133 285.065 361.39 283.515 361.39C281.965 361.39 280.708 360.133 280.708 358.583C280.708 357.033 281.965 355.776 283.515 355.776C285.065 355.776 286.321 357.033 286.321 358.583Z" />
          <path d="M293.163 358.583C293.163 360.133 291.906 361.39 290.356 361.39C288.806 361.39 287.55 360.133 287.55 358.583C287.55 357.033 288.806 355.776 290.356 355.776C291.906 355.776 293.163 357.033 293.163 358.583Z" />
          <path d="M293.163 351.741C293.163 353.291 291.906 354.548 290.356 354.548C288.806 354.548 287.55 353.291 287.55 351.741C287.55 350.191 288.806 348.934 290.356 348.934C291.906 348.934 293.163 350.191 293.163 351.741Z" />
          <path d="M293.163 344.899C293.163 346.449 291.906 347.706 290.356 347.706C288.806 347.706 287.55 346.449 287.55 344.899C287.55 343.349 288.806 342.093 290.356 342.093C291.906 342.093 293.163 343.349 293.163 344.899Z" />
          <path d="M293.163 338.057C293.163 339.607 291.906 340.864 290.356 340.864C288.806 340.864 287.55 339.607 287.55 338.057C287.55 336.507 288.806 335.25 290.356 335.25C291.906 335.25 293.163 336.507 293.163 338.057Z" />
          <path d="M286.321 338.057C286.321 339.607 285.065 340.864 283.515 340.864C281.965 340.864 280.708 339.607 280.708 338.057C280.708 336.507 281.965 335.25 283.515 335.25C285.065 335.25 286.321 336.507 286.321 338.057Z" />
          <path d="M286.321 331.215C286.321 332.765 285.065 334.022 283.515 334.022C281.965 334.022 280.708 332.765 280.708 331.215C280.708 329.666 281.965 328.409 283.515 328.409C285.065 328.409 286.321 329.666 286.321 331.215Z" />
          <path d="M279.479 331.215C279.479 332.765 278.223 334.022 276.673 334.022C275.123 334.022 273.866 332.765 273.866 331.215C273.866 329.666 275.123 328.409 276.673 328.409C278.223 328.409 279.479 329.666 279.479 331.215Z" />
          <path d="M279.479 338.057C279.479 339.607 278.223 340.864 276.673 340.864C275.123 340.864 273.866 339.607 273.866 338.057C273.866 336.507 275.123 335.25 276.673 335.25C278.223 335.25 279.479 336.507 279.479 338.057Z" />
          <path d="M279.479 344.899C279.479 346.449 278.223 347.706 276.673 347.706C275.123 347.706 273.866 346.449 273.866 344.899C273.866 343.349 275.123 342.093 276.673 342.093C278.223 342.093 279.479 343.349 279.479 344.899Z" />
          <path d="M279.479 351.741C279.479 353.291 278.223 354.548 276.673 354.548C275.123 354.548 273.866 353.291 273.866 351.741C273.866 350.191 275.123 348.934 276.673 348.934C278.223 348.934 279.479 350.191 279.479 351.741Z" />
          <path d="M286.321 351.741C286.321 353.291 285.065 354.548 283.515 354.548C281.965 354.548 280.708 353.291 280.708 351.741C280.708 350.191 281.965 348.934 283.515 348.934C285.065 348.934 286.321 350.191 286.321 351.741Z" />
          <path d="M286.321 344.899C286.321 346.449 285.065 347.706 283.515 347.706C281.965 347.706 280.708 346.449 280.708 344.899C280.708 343.349 281.965 342.093 283.515 342.093C285.065 342.093 286.321 343.349 286.321 344.899Z" />
          <path d="M224.745 290.164C224.745 291.714 223.488 292.971 221.938 292.971C220.388 292.971 219.131 291.714 219.131 290.164C219.131 288.615 220.388 287.358 221.938 287.358C223.488 287.358 224.745 288.615 224.745 290.164Z" />
          <path d="M313.689 331.215C313.689 332.765 312.432 334.022 310.882 334.022C309.332 334.022 308.075 332.765 308.075 331.215C308.075 329.666 309.332 328.409 310.882 328.409C312.432 328.409 313.689 329.666 313.689 331.215Z" />
          <path d="M176.852 317.532C176.852 319.082 175.595 320.339 174.045 320.339C172.495 320.339 171.238 319.082 171.238 317.532C171.238 315.982 172.495 314.725 174.045 314.725C175.595 314.725 176.852 315.982 176.852 317.532Z" />
          <path d="M211.061 324.373C211.061 325.923 209.804 327.18 208.254 327.18C206.704 327.18 205.448 325.923 205.448 324.373C205.448 322.824 206.704 321.567 208.254 321.567C209.804 321.567 211.061 322.824 211.061 324.373Z" />
          <path d="M300.005 372.267C300.005 373.816 298.748 375.073 297.198 375.073C295.648 375.073 294.391 373.816 294.391 372.267C294.391 370.717 295.648 369.46 297.198 369.46C298.748 369.46 300.005 370.717 300.005 372.267Z" />
          <path d="M306.847 372.267C306.847 373.816 305.59 375.073 304.04 375.073C302.49 375.073 301.233 373.816 301.233 372.267C301.233 370.717 302.49 369.46 304.04 369.46C305.59 369.46 306.847 370.717 306.847 372.267Z" />
          <path d="M306.847 365.424C306.847 366.974 305.59 368.231 304.04 368.231C302.49 368.231 301.233 366.974 301.233 365.424C301.233 363.875 302.49 362.618 304.04 362.618C305.59 362.618 306.847 363.875 306.847 365.424Z" />
          <path d="M306.847 358.583C306.847 360.133 305.59 361.39 304.04 361.39C302.49 361.39 301.233 360.133 301.233 358.583C301.233 357.033 302.49 355.776 304.04 355.776C305.59 355.776 306.847 357.033 306.847 358.583Z" />
          <path d="M306.847 351.741C306.847 353.291 305.59 354.548 304.04 354.548C302.49 354.548 301.233 353.291 301.233 351.741C301.233 350.191 302.49 348.934 304.04 348.934C305.59 348.934 306.847 350.191 306.847 351.741Z" />
          <path d="M306.847 344.899C306.847 346.449 305.59 347.706 304.04 347.706C302.49 347.706 301.233 346.449 301.233 344.899C301.233 343.349 302.49 342.093 304.04 342.093C305.59 342.093 306.847 343.349 306.847 344.899Z" />
          <path d="M313.689 344.899C313.689 346.449 312.432 347.706 310.882 347.706C309.332 347.706 308.075 346.449 308.075 344.899C308.075 343.349 309.332 342.093 310.882 342.093C312.432 342.093 313.689 343.349 313.689 344.899Z" />
          <path d="M313.689 338.057C313.689 339.607 312.432 340.864 310.882 340.864C309.332 340.864 308.075 339.607 308.075 338.057C308.075 336.507 309.332 335.25 310.882 335.25C312.432 335.25 313.689 336.507 313.689 338.057Z" />
          <path d="M320.531 338.057C320.531 339.607 319.274 340.864 317.724 340.864C316.174 340.864 314.917 339.607 314.917 338.057C314.917 336.507 316.174 335.25 317.724 335.25C319.274 335.25 320.531 336.507 320.531 338.057Z" />
          <path d="M245.27 385.95C245.27 387.5 244.013 388.757 242.463 388.757C240.914 388.757 239.657 387.5 239.657 385.95C239.657 384.4 240.914 383.144 242.463 383.144C244.013 383.144 245.27 384.4 245.27 385.95Z" />
          <path d="M245.27 392.792C245.27 394.342 244.013 395.599 242.463 395.599C240.914 395.599 239.657 394.342 239.657 392.792C239.657 391.242 240.914 389.985 242.463 389.985C244.013 389.985 245.27 391.242 245.27 392.792Z" />
          <path d="M252.112 392.792C252.112 394.342 250.855 395.599 249.305 395.599C247.755 395.599 246.499 394.342 246.499 392.792C246.499 391.242 247.755 389.985 249.305 389.985C250.855 389.985 252.112 391.242 252.112 392.792Z" />
          <path d="M258.954 392.792C258.954 394.342 257.697 395.599 256.147 395.599C254.597 395.599 253.34 394.342 253.34 392.792C253.34 391.242 254.597 389.985 256.147 389.985C257.697 389.985 258.954 391.242 258.954 392.792Z" />
          <path d="M265.796 392.792C265.796 394.342 264.539 395.599 262.989 395.599C261.439 395.599 260.182 394.342 260.182 392.792C260.182 391.242 261.439 389.985 262.989 389.985C264.539 389.985 265.796 391.242 265.796 392.792Z" />
          <path d="M252.112 385.95C252.112 387.5 250.855 388.757 249.305 388.757C247.755 388.757 246.499 387.5 246.499 385.95C246.499 384.4 247.755 383.144 249.305 383.144C250.855 383.144 252.112 384.4 252.112 385.95Z" />
          <path d="M258.954 385.95C258.954 387.5 257.697 388.757 256.147 388.757C254.597 388.757 253.34 387.5 253.34 385.95C253.34 384.4 254.597 383.144 256.147 383.144C257.697 383.144 258.954 384.4 258.954 385.95Z" />
          <path d="M265.796 385.95C265.796 387.5 264.539 388.757 262.989 388.757C261.439 388.757 260.182 387.5 260.182 385.95C260.182 384.4 261.439 383.144 262.989 383.144C264.539 383.144 265.796 384.4 265.796 385.95Z" />
          <path d="M245.27 413.318C245.27 414.868 244.013 416.125 242.463 416.125C240.914 416.125 239.657 414.868 239.657 413.318C239.657 411.768 240.914 410.511 242.463 410.511C244.013 410.511 245.27 411.768 245.27 413.318Z" />
          <path d="M245.27 420.159C245.27 421.709 244.013 422.966 242.463 422.966C240.914 422.966 239.657 421.709 239.657 420.159C239.657 418.609 240.914 417.353 242.463 417.353C244.013 417.353 245.27 418.609 245.27 420.159Z" />
          <path d="M245.27 427.001C245.27 428.551 244.013 429.808 242.463 429.808C240.914 429.808 239.657 428.551 239.657 427.001C239.657 425.451 240.914 424.195 242.463 424.195C244.013 424.195 245.27 425.451 245.27 427.001Z" />
          <path d="M252.112 427.001C252.112 428.551 250.855 429.808 249.305 429.808C247.755 429.808 246.499 428.551 246.499 427.001C246.499 425.451 247.755 424.195 249.305 424.195C250.855 424.195 252.112 425.451 252.112 427.001Z" />
          <path d="M252.112 420.159C252.112 421.709 250.855 422.966 249.305 422.966C247.755 422.966 246.499 421.709 246.499 420.159C246.499 418.609 247.755 417.353 249.305 417.353C250.855 417.353 252.112 418.609 252.112 420.159Z" />
          <path d="M252.112 413.318C252.112 414.868 250.855 416.125 249.305 416.125C247.755 416.125 246.499 414.868 246.499 413.318C246.499 411.768 247.755 410.511 249.305 410.511C250.855 410.511 252.112 411.768 252.112 413.318Z" />
          <path d="M258.954 413.318C258.954 414.868 257.697 416.125 256.147 416.125C254.597 416.125 253.34 414.868 253.34 413.318C253.34 411.768 254.597 410.511 256.147 410.511C257.697 410.511 258.954 411.768 258.954 413.318Z" />
          <path d="M258.954 420.159C258.954 421.709 257.697 422.966 256.147 422.966C254.597 422.966 253.34 421.709 253.34 420.159C253.34 418.609 254.597 417.353 256.147 417.353C257.697 417.353 258.954 418.609 258.954 420.159Z" />
          <path d="M265.796 420.159C265.796 421.709 264.539 422.966 262.989 422.966C261.439 422.966 260.182 421.709 260.182 420.159C260.182 418.609 261.439 417.353 262.989 417.353C264.539 417.353 265.796 418.609 265.796 420.159Z" />
          <path d="M265.796 413.318C265.796 414.868 264.539 416.125 262.989 416.125C261.439 416.125 260.182 414.868 260.182 413.318C260.182 411.768 261.439 410.511 262.989 410.511C264.539 410.511 265.796 411.768 265.796 413.318Z" />
          <path d="M265.796 406.475C265.796 408.025 264.539 409.282 262.989 409.282C261.439 409.282 260.182 408.025 260.182 406.475C260.182 404.926 261.439 403.669 262.989 403.669C264.539 403.669 265.796 404.926 265.796 406.475Z" />
          <path d="M272.638 406.475C272.638 408.025 271.381 409.282 269.831 409.282C268.281 409.282 267.024 408.025 267.024 406.475C267.024 404.926 268.281 403.669 269.831 403.669C271.381 403.669 272.638 404.926 272.638 406.475Z" />
          <path d="M279.479 406.475C279.479 408.025 278.223 409.282 276.673 409.282C275.123 409.282 273.866 408.025 273.866 406.475C273.866 404.926 275.123 403.669 276.673 403.669C278.223 403.669 279.479 404.926 279.479 406.475Z" />
          <path d="M279.479 399.634C279.479 401.184 278.223 402.441 276.673 402.441C275.123 402.441 273.866 401.184 273.866 399.634C273.866 398.084 275.123 396.827 276.673 396.827C278.223 396.827 279.479 398.084 279.479 399.634Z" />
          <path d="M279.479 392.792C279.479 394.342 278.223 395.599 276.673 395.599C275.123 395.599 273.866 394.342 273.866 392.792C273.866 391.242 275.123 389.985 276.673 389.985C278.223 389.985 279.479 391.242 279.479 392.792Z" />
          <path d="M286.321 392.792C286.321 394.342 285.065 395.599 283.515 395.599C281.965 395.599 280.708 394.342 280.708 392.792C280.708 391.242 281.965 389.985 283.515 389.985C285.065 389.985 286.321 391.242 286.321 392.792Z" />
          <path d="M286.321 385.95C286.321 387.5 285.065 388.757 283.515 388.757C281.965 388.757 280.708 387.5 280.708 385.95C280.708 384.4 281.965 383.144 283.515 383.144C285.065 383.144 286.321 384.4 286.321 385.95Z" />
          <path d="M279.479 385.95C279.479 387.5 278.223 388.757 276.673 388.757C275.123 388.757 273.866 387.5 273.866 385.95C273.866 384.4 275.123 383.144 276.673 383.144C278.223 383.144 279.479 384.4 279.479 385.95Z" />
          <path d="M272.638 385.95C272.638 387.5 271.381 388.757 269.831 388.757C268.281 388.757 267.024 387.5 267.024 385.95C267.024 384.4 268.281 383.144 269.831 383.144C271.381 383.144 272.638 384.4 272.638 385.95Z" />
          <path d="M272.638 392.792C272.638 394.342 271.381 395.599 269.831 395.599C268.281 395.599 267.024 394.342 267.024 392.792C267.024 391.242 268.281 389.985 269.831 389.985C271.381 389.985 272.638 391.242 272.638 392.792Z" />
          <path d="M272.638 399.634C272.638 401.184 271.381 402.441 269.831 402.441C268.281 402.441 267.024 401.184 267.024 399.634C267.024 398.084 268.281 396.827 269.831 396.827C271.381 396.827 272.638 398.084 272.638 399.634Z" />
          <path d="M265.796 399.634C265.796 401.184 264.539 402.441 262.989 402.441C261.439 402.441 260.182 401.184 260.182 399.634C260.182 398.084 261.439 396.827 262.989 396.827C264.539 396.827 265.796 398.084 265.796 399.634Z" />
          <path d="M258.954 399.634C258.954 401.184 257.697 402.441 256.147 402.441C254.597 402.441 253.34 401.184 253.34 399.634C253.34 398.084 254.597 396.827 256.147 396.827C257.697 396.827 258.954 398.084 258.954 399.634Z" />
          <path d="M252.112 399.634C252.112 401.184 250.855 402.441 249.305 402.441C247.755 402.441 246.499 401.184 246.499 399.634C246.499 398.084 247.755 396.827 249.305 396.827C250.855 396.827 252.112 398.084 252.112 399.634Z" />
          <path d="M245.27 399.634C245.27 401.184 244.013 402.441 242.463 402.441C240.914 402.441 239.657 401.184 239.657 399.634C239.657 398.084 240.914 396.827 242.463 396.827C244.013 396.827 245.27 398.084 245.27 399.634Z" />
          <path d="M245.27 406.475C245.27 408.025 244.013 409.282 242.463 409.282C240.914 409.282 239.657 408.025 239.657 406.475C239.657 404.926 240.914 403.669 242.463 403.669C244.013 403.669 245.27 404.926 245.27 406.475Z" />
          <path d="M252.112 406.475C252.112 408.025 250.855 409.282 249.305 409.282C247.755 409.282 246.499 408.025 246.499 406.475C246.499 404.926 247.755 403.669 249.305 403.669C250.855 403.669 252.112 404.926 252.112 406.475Z" />
          <path d="M258.954 406.475C258.954 408.025 257.697 409.282 256.147 409.282C254.597 409.282 253.34 408.025 253.34 406.475C253.34 404.926 254.597 403.669 256.147 403.669C257.697 403.669 258.954 404.926 258.954 406.475Z" />
          <path d="M258.954 427.001C258.954 428.551 257.697 429.808 256.147 429.808C254.597 429.808 253.34 428.551 253.34 427.001C253.34 425.451 254.597 424.195 256.147 424.195C257.697 424.195 258.954 425.451 258.954 427.001Z" />
          <path d="M245.27 447.527C245.27 449.077 244.013 450.333 242.463 450.333C240.914 450.333 239.657 449.077 239.657 447.527C239.657 445.977 240.914 444.72 242.463 444.72C244.013 444.72 245.27 445.977 245.27 447.527Z" />
          <path d="M245.27 440.685C245.27 442.235 244.013 443.492 242.463 443.492C240.914 443.492 239.657 442.235 239.657 440.685C239.657 439.135 240.914 437.878 242.463 437.878C244.013 437.878 245.27 439.135 245.27 440.685Z" />
          <path d="M252.112 440.685C252.112 442.235 250.855 443.492 249.305 443.492C247.755 443.492 246.499 442.235 246.499 440.685C246.499 439.135 247.755 437.878 249.305 437.878C250.855 437.878 252.112 439.135 252.112 440.685Z" />
          <path d="M252.112 433.843C252.112 435.393 250.855 436.65 249.305 436.65C247.755 436.65 246.499 435.393 246.499 433.843C246.499 432.293 247.755 431.036 249.305 431.036C250.855 431.036 252.112 432.293 252.112 433.843Z" />
          <path d="M245.27 433.843C245.27 435.393 244.013 436.65 242.463 436.65C240.914 436.65 239.657 435.393 239.657 433.843C239.657 432.293 240.914 431.036 242.463 431.036C244.013 431.036 245.27 432.293 245.27 433.843Z" />
          <path d="M566.925 221.746C566.925 223.296 565.668 224.553 564.118 224.553C562.568 224.553 561.311 223.296 561.311 221.746C561.311 220.196 562.568 218.939 564.118 218.939C565.668 218.939 566.925 220.196 566.925 221.746Z" />
          <path d="M566.925 214.904C566.925 216.454 565.668 217.711 564.118 217.711C562.568 217.711 561.311 216.454 561.311 214.904C561.311 213.355 562.568 212.098 564.118 212.098C565.668 212.098 566.925 213.355 566.925 214.904Z" />
          <path d="M573.766 214.904C573.766 216.454 572.51 217.711 570.96 217.711C569.41 217.711 568.153 216.454 568.153 214.904C568.153 213.355 569.41 212.098 570.96 212.098C572.51 212.098 573.766 213.355 573.766 214.904Z" />
          <path d="M573.766 221.746C573.766 223.296 572.51 224.553 570.96 224.553C569.41 224.553 568.153 223.296 568.153 221.746C568.153 220.196 569.41 218.939 570.96 218.939C572.51 218.939 573.766 220.196 573.766 221.746Z" />
          <path d="M580.476 214.904C580.476 216.454 579.22 217.711 577.67 217.711C576.12 217.711 574.863 216.454 574.863 214.904C574.863 213.355 576.12 212.098 577.67 212.098C579.22 212.098 580.476 213.355 580.476 214.904Z" />
          <path d="M580.476 221.746C580.476 223.296 579.22 224.553 577.67 224.553C576.12 224.553 574.863 223.296 574.863 221.746C574.863 220.196 576.12 218.939 577.67 218.939C579.22 218.939 580.476 220.196 580.476 221.746Z" />
          <path d="M587.318 221.746C587.318 223.296 586.062 224.553 584.512 224.553C582.962 224.553 581.704 223.296 581.704 221.746C581.704 220.196 582.962 218.939 584.512 218.939C586.062 218.939 587.318 220.196 587.318 221.746Z" />
          <path d="M587.318 214.904C587.318 216.454 586.062 217.711 584.512 217.711C582.962 217.711 581.704 216.454 581.704 214.904C581.704 213.355 582.962 212.098 584.512 212.098C586.062 212.098 587.318 213.355 587.318 214.904Z" />
          <path d="M594.16 214.904C594.16 216.454 592.904 217.711 591.354 217.711C589.804 217.711 588.546 216.454 588.546 214.904C588.546 213.355 589.804 212.098 591.354 212.098C592.904 212.098 594.16 213.355 594.16 214.904Z" />
          <path d="M594.16 221.746C594.16 223.296 592.904 224.553 591.354 224.553C589.804 224.553 588.546 223.296 588.546 221.746C588.546 220.196 589.804 218.939 591.354 218.939C592.904 218.939 594.16 220.196 594.16 221.746Z" />
          <path d="M601.09 221.746C601.09 223.296 599.833 224.553 598.283 224.553C596.733 224.553 595.476 223.296 595.476 221.746C595.476 220.196 596.733 218.939 598.283 218.939C599.833 218.939 601.09 220.196 601.09 221.746Z" />
          <path d="M601.09 214.904C601.09 216.454 599.833 217.711 598.283 217.711C596.733 217.711 595.476 216.454 595.476 214.904C595.476 213.355 596.733 212.098 598.283 212.098C599.833 212.098 601.09 213.355 601.09 214.904Z" />
          <path d="M607.931 214.904C607.931 216.454 606.675 217.711 605.125 217.711C603.575 217.711 602.318 216.454 602.318 214.904C602.318 213.355 603.575 212.098 605.125 212.098C606.675 212.098 607.931 213.355 607.931 214.904Z" />
          <path d="M607.931 221.746C607.931 223.296 606.675 224.553 605.125 224.553C603.575 224.553 602.318 223.296 602.318 221.746C602.318 220.196 603.575 218.939 605.125 218.939C606.675 218.939 607.931 220.196 607.931 221.746Z" />
        </g>
      </svg>
    );
  }
}
