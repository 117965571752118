/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './chrome.scss';
import { HamburgerIcon } from './svg/hamburger-icon';
import { EsentireIcon } from './svg/esentire-icon';
import { BottomNav } from './bottom-nav';
import { observable, computed } from 'mobx';
import { Timer } from './timer';
import { RouteManager } from 'vega-router';
import { AppState } from './app-actions';
import { EmailDemo } from './email-demo';

@observer
export class Chrome extends Component {
  currentScreenshotIndex = 0;

  renderDots(slideURL) {
    let length = 1;
    if (slideURL instanceof Array) {
      length = slideURL.length;
    }
    if (length <= 1) return;
    return (
      <div className="Screenshot__Slide__Dots">
        {Array(length)
          .fill(0)
          .map((value, key) => {
            let activeClass =
              key == this.currentScreenshotIndex ? ' active' : '';
            return (
              <span
                className={`Screenshot__Slide__Dots__Dot${activeClass}`}
                onClick={() => {
                  this.setScreenshotIndex(key);
                }}
                key={key}
              ></span>
            );
          })}
      </div>
    );
  }

  renderSlide(slideURL, key) {
    let slideStyles = {
      backgroundImage: `url(${slideURL.toString()})`,
    };
    return (
      <div className={`Screenshot__Slide`} key={key} style={slideStyles}></div>
    );
  }

  renderSlides(url, styles) {
    let slides = [];
    if (!(url instanceof Array)) {
      slides.push(url);
    }
    if (url instanceof Array) {
      slides = url;
    }
    return (
      <div className="Screenshot__Slides" style={styles}>
        {slides.map(this.renderSlide)}
      </div>
    );
  }

  renderDescription(value, index) {
    if (Array.isArray(value)) {
      return value.map((text, key) => {
        return (
          <span key={key} className="Chrome__Description__Block">
            {text}
          </span>
        );
      });
    }
    return <span key={0}>{value}</span>;
  }

  render() {
    const {
      children,
      className = '',
      useCaseKey = '',
      title = '',
      subtitle = '',
      product = '',
      description = '',
      slides,
      onSlideChange,
      seconds,
      progress,
      showAppScreenshot = false,
      screenShotIndex = 0,
      appScreenshotUrl = '',
      currentScreenshotIndex = 0,
      setScreenshotIndex = null,
      emailDemo,
      showTimer,
    } = this.props;

    this.setScreenshotIndex = setScreenshotIndex;

    // Set current screenshot index
    this.currentScreenshotIndex = currentScreenshotIndex;

    // eSentire's Threat Response Unit (TRU) identifies public reports of active attacks and triage begins.
    let titleLengthClass = subtitle.length > 70 ? 'smaller' : '';

    const emailDemoClassName = showAppScreenshot
      ? ' Chrome__Middle__EmailDemo--visible'
      : '';
    const appScreenshotClassName =
      showAppScreenshot && appScreenshotUrl
        ? ' Chrome__Middle__AppScreenshot--visible'
        : '';
    const descriptionClassName =
      showAppScreenshot && appScreenshotUrl
        ? ' Chrome__Description--hidden'
        : '';

    const hasScreenshot =
      showAppScreenshot && appScreenshotUrl ? ' has-screenshot' : '';

    const screenShotStyles = {
      transform: `translateX(${screenShotIndex * -100}%)`,
    };

    return (
      <div className={`Chrome ${className} ${hasScreenshot}`}>
        <div className="Chrome__MainMenu">
          <button
            className="HamburgerButton"
            onClick={() => RouteManager.transitionTo('/use-cases')}
          >
            <HamburgerIcon color="#fafafa" opacity={0.7} />
          </button>
        </div>
        <div className="Chrome__Logo">
          <EsentireIcon />
        </div>
        {title && <div className="Chrome__Title">{title}</div>}
        {subtitle && (
          <div className={`Chrome__Subtitle ${titleLengthClass}`}>
            {subtitle}
          </div>
        )}
        {product && (
          <div className="Chrome__Product">
            <span>Service</span> | <span>{product}</span>
          </div>
        )}
        {showTimer ? (
          <Timer
            className="Chrome__Timer"
            seconds={seconds}
            progress={progress}
          />
        ) : null}
        {description && (
          <div className={`Chrome__Description ${descriptionClassName}`}>
            {this.renderDescription(description)}
          </div>
        )}
        <div className="Chrome__SlideProgress">
          <div>
            <span />
          </div>
        </div>
        <div className="Chrome__BottomNav">
          <BottomNav
            useCaseKey={useCaseKey}
            {...{ slides }}
            onChange={onSlideChange}
          />
        </div>
        <div className="Chrome__Left">
          <span
            className="Chrome__Left__Arrow"
            onClick={() => this.props.onPreviousSlide()}
          />
        </div>
        <div className="Chrome__Middle">
          {appScreenshotUrl && (
            <div
              className={`Chrome__Middle__AppScreenshot${appScreenshotClassName}`}
            >
              {this.renderDots(appScreenshotUrl)}
              {this.renderSlides(appScreenshotUrl, screenShotStyles)}
            </div>
          )}
          {emailDemo && (
            <EmailDemo
              className={`Chrome__Middle__EmailDemo${emailDemoClassName}`}
              {...emailDemo}
            />
          )}
        </div>
        {AppState.rightArrowShow && (
          <div
            className="Chrome__Right"
            onClick={() => this.props.onNextSlide()}
          >
            <span
              className={`Chrome__Right__Arrow${
                AppState.rightArrowPulse ? ' pulse' : ''
              }`}
            />
          </div>
        )}
        {children}
      </div>
    );
  }
}
