/** @jsx createElement */
import { observer } from 'mobx-react';
import { getColor } from '../helpers';
import {
  isValidElement,
  createElement,
  Component,
  HTMLAttributes,
} from 'react';
import { Server } from './server';

@observer
export class Shield extends Component {
  // Gradients
  gradients = {
    default: [
      '#A166AB',
      '#5073B8',
      '#1098AD',
      '#50C2E3',
      '#50C2E3',
      '#6DBA82',
      '#07B39B',
      '#5073B8',
    ],
  };

  icon = {
    size: 45,
    opacity: 1,
    color: getColor('white'),
  };

  renderText(text) {
    let startingY = 44;
    let lineHeight = 12;

    // Render just one line of text
    if (!Array.isArray(text)) return this.renderTextLine(text, startingY);

    // Render multiple lines of text
    startingY = startingY - lineHeight * (text.length - 1);
    return text.map((value, index) => {
      return this.renderTextLine(value, startingY + index * lineHeight, index);
    });
  }

  renderTextLine(text, y, index) {
    const {
      letterSpacing = 2,
      fontSize = 12,
      fillOpacity,
      color = '#ffffff',
      uppercase = true,
    } = this.props;
    let yOffset = 0;
    if (this.icon.size >= 50) yOffset = -10;
    return (
      <text
        key={index}
        x="50%"
        y={parseInt(y) + yOffset}
        textAnchor="middle"
        fontFamily="Blender Bold"
        fontSize={fontSize.toString().replace(/px/g, '') + 'px'}
        letterSpacing={letterSpacing.toString().replace(/px/g, '') + 'px'}
        fill={color}
        fillOpacity={fillOpacity}
      >
        {uppercase ? text.toUpperCase() : text}
      </text>
    );
  }

  showIcon() {
    const { IconComponent = null, icon = null } = this.props;
    if (!IconComponent && !icon) return;
    if (icon && isValidElement(icon)) {
      return icon;
    }
    if (IconComponent && isValidElement(IconComponent)) {
      return icon;
    }
    return (
      <IconComponent
        size={this.icon.size}
        color={this.icon.color}
        opacity={this.icon.opacity}
      />
    );
  }

  render() {
    let {
      text = 'AMP',
      className = '',
      color = '#ffffff',
      stroke = '#ffffff',
      x = 0,
      y = 0,
      IconSize = 45,
      iconSize = null,
      iconOffsetY = 0,
      IconComponent = Server,
      iconComponent = null,
      IconOpacity = 0.4,
      iconOpacity = null,
      gradient = false,
      strokeOpacity = 0.2,
      letterSpacing = 2,
      strokeWidth = 4,
      icon = null,
    } = this.props;

    // FIXME: I'm lazy and don't want to change every instance in the app =)
    if (iconOpacity) IconOpacity = iconOpacity;
    if (iconComponent) IconComponent = iconComponent;
    this.icon.size = iconSize ? iconSize : IconSize;
    this.icon.opacity = iconOpacity ? iconOpacity : IconOpacity;

    // If an icon is actually passed in
    if (icon && isValidElement(icon)) {
      this.icon.size = icon.props.size;
    }

    // Calculate center for icon
    let width = 105;
    let height = 129;
    let iconX = (width - this.icon.size) / 2;
    let iconY = height - this.icon.size - 20 + iconOffsetY;

    let strokeStyles = {
      stroke: stroke,
      strokeOpacity: strokeOpacity,
      strokeWidth: strokeWidth,
    };

    // Use default colors
    let gradientColors = this.gradients['default'];

    // Uset gradient for stroke
    if (gradient) {
      if (typeof gradient === 'string' && gradient in this.gradients) {
        gradientColors = this.gradients[gradient];
      }
      strokeStyles.stroke = 'url(#radial_gradient)';
      strokeStyles.strokeOpacity = 1;
    }

    return (
      <svg
        {...{ x, y }}
        width="105"
        height="129"
        viewBox="0 0 105 129"
        fill="none"
        className={`Icon__Shield ${className}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M52.5 0L0 23.4545V58.6364C0 91.1796 22.4 121.612 52.5 129C82.6 121.612 105 91.1796 105 58.6364V23.4545L52.5 0Z"
          fill={color}
          fillOpacity="0.02"
        />
        <path
          d="M2 58.6364V24.7516L52.5 2.19051L103 24.7516V58.6364C103 90.1749 81.383 119.587 52.5 126.938C23.617 119.587 2 90.1749 2 58.6364Z"
          stroke={strokeStyles.stroke}
          strokeOpacity={strokeStyles.strokeOpacity}
          strokeWidth={strokeStyles.strokeWidth}
          className="Icon__Shield__Border"
        />
        <svg x={iconX} y={iconY}>
          {this.showIcon()}
        </svg>
        {this.renderText(text)}
        <defs>
          {gradient && (
            <radialGradient
              id="radial_gradient"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(53 65) rotate(90) scale(75.5 75.3096)"
            >
              <stop stopColor={gradientColors[0]}>
                <animate
                  attributeName="stop-color"
                  values={gradientColors.join(';')}
                  dur="2s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
            </radialGradient>
          )}
        </defs>
      </svg>
    );
  }
}
