/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Warning extends Component {
  render() {
    const {
      children,
      className = '',
      color = 'white',
      size = 100,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 83 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M43.0214 75.1429C41.0443 75.1429 39.4431 73.5436 39.4431 71.5647C39.4431 69.5913 41.0443 67.9864 43.0214 67.9864C44.9948 67.9864 46.5997 69.5913 46.5997 71.5647C46.5997 73.5436 44.9948 75.1429 43.0214 75.1429ZM41.1509 30.4138C41.1509 29.3813 41.9889 28.5433 43.0214 28.5433C44.0539 28.5433 44.8919 29.3813 44.8919 30.4138V59.0398C44.8919 60.0742 44.0539 60.9103 43.0214 60.9103C41.9889 60.9103 41.1509 60.0742 41.1509 59.0398V30.4138ZM85.6949 82.9522L44.6936 0.949744C44.0614 -0.316581 41.9814 -0.316581 41.3473 0.949744L0.19641 83.2515C-0.0916466 83.8314 -0.0617186 84.5197 0.278711 85.0715C0.619142 85.6233 1.22144 85.96 1.8705 85.96H84.1723C84.1854 85.9581 84.1966 85.9581 84.2097 85.96C85.2422 85.96 86.0802 85.122 86.0802 84.0895C86.0802 83.6612 85.9362 83.2665 85.6949 82.9522Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
