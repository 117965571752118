/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Quote extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 13,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.20253 0C2.78261 0 0 2.29158 0 5.1083C0 6.43848 0.608623 7.68467 1.71862 8.63909L0.473201 11.126C0.423839 11.2242 0.442188 11.3429 0.518686 11.4214C0.568565 11.4731 0.635759 11.5 0.704245 11.5C0.741202 11.5 0.778159 11.4922 0.813048 11.476L4.14019 9.9274C4.80101 10.1192 5.49415 10.2161 6.20253 10.2161C9.62244 10.2161 12.4051 7.92476 12.4051 5.1083C12.4051 2.29158 9.62244 0 6.20253 0Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
