/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import ReactDOM from 'react-dom';
import { observable } from 'mobx';
import { Stage } from '../stage';
import anime from 'animejs';

// SVG
import { Computer } from '../svg/computer';
import { Alert } from '../svg/alert';
import { EmailAlert } from '../svg/email-alert';

@observer
export class Slide06 extends AnimatedSlide {
  // Animation players
  players = {
    alert: null,
  };

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');
    // Constants
    const { alert, border } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(2000);
      },
      // Step 2
      () => {
        // Nothing needed
      },
    ];

    this.animations = [
      // Step 1
      [
        {
          targets: alert,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],

      // Step 2
      [
        // Just sit here and ponder life
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    this.copies = [];

    return (
      <div className={`slide-06 ${className}`}>
        {children}
        <div className="content">
          {/*-------START-------*/}
          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'ALERT FROM ESENTIRE'}
              subtitle={'Malware Detected'}
              IconComponent={EmailAlert}
              color={this.colors.blue}
            />
          </div>
          {/*-------END-------*/}
        </div>
      </div>
    );
  }
}
