/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Bug extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#C53D43',
      size = 35,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        width={size}
        height={size}
        viewBox={`0 0 30 34`}
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill={color} fillRule="evenodd" clipRule="evenodd">
          <path
            d="M30 9.44444H24.7313C23.8875 7.97111 22.725 6.70556 21.3188 5.74222L24.375 2.66333L21.7313 0L17.6625 4.09889C16.8 3.89111 15.9187 3.77778 15 3.77778C14.0813 3.77778 13.2 3.89111 12.3563 4.09889L8.26875 0L5.625 2.66333L8.6625 5.74222C7.275 6.70556 6.1125 7.97111 5.26875 9.44444H0V13.2222H3.91875C3.825 13.8456 3.75 14.4689 3.75 15.1111V17H0V20.7778H3.75V22.6667C3.75 23.3089 3.825 23.9322 3.91875 24.5556H0V28.3333H5.26875C7.21875 31.7144 10.8375 34 15 34C19.1625 34 22.7813 31.7144 24.7313 28.3333H30V24.5556H26.0812C26.175 23.9322 26.25 23.3089 26.25 22.6667V20.7778H30V17H26.25V15.1111C26.25 14.4689 26.175 13.8456 26.0812 13.2222H30V9.44444ZM18.75 24.5556H11.25V20.7778H18.75V24.5556ZM18.75 17H11.25V13.2222H18.75V17Z"
            fillOpacity={opacity}
          />
        </g>
      </svg>
    );
  }
}
