/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import './circle.scss';
import anime from 'animejs';
import ReactDOM from 'react-dom';

@observer
export class PlayerCircle extends Component {
  circle = null;

  defaults = {
    rotate: true,
    duration: 20000,
  };

  componentDidMount() {
    this.rotateCircle();
  }

  rotateCircle() {
    const {
      rotate = this.defaults.rotate,
      duration = this.defaults.duration,
    } = this.props;
    if (!rotate) return;
    anime({
      targets: this.circle,
      rotate: 360,
      duration: duration,
      easing: 'linear',
      loop: true,
      begin: shimmer,
    });
  }

  render() {
    const {
      children,
      color = '#50C2E3',
      size = 280,
      strokeWidth = 4,
      dash = 6,
      className = '',
    } = this.props;

    let s = size - strokeWidth / 2;
    let width = size;
    let height = size;
    let radius = size / 2 - strokeWidth;

    return (
      <div
        className={`Player__Circle ${className}`}
        style={{
          width: width,
          height: height,
        }}
      >
        <svg
          width={size}
          height={size}
          viewBox={`0 0 ${size} ${size}`}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            ref={node => (this.circle = node)}
            cx={radius + strokeWidth}
            cy={radius + strokeWidth}
            r={radius}
            stroke={color}
            strokeWidth={strokeWidth}
            strokeDasharray={`${dash} ${dash}`}
            strokeLinecap="butt"
            fill="transparent"
          />
        </svg>
      </div>
    );
  }
}
