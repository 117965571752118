import { observable } from 'mobx';

export const AppState = observable({
  initial: true,
  authed: false,
  authType: '',
  useCaseKey: '',
  alertShow: false,
  alertTitle: '',
  alertMessage: '',
  gridShow: true,
  rightArrowShow: true,
  rightArrowPulse: false,
  appScreenshotEnabled: true,
});

export class AppActions {
  static setUseCaseKey(key) {
    AppState.useCaseKey = key;
  }

  static clearUseCaseKey() {
    AppState.useCaseKey = '';
  }

  static showBackgroundGrid() {
    AppState.gridShow = true;
  }

  static hideBackgroundGrid() {
    AppState.gridShow = false;
  }

  static showRightArrow() {
    AppState.rightArrowShow = true;
  }

  static hideRightArrow() {
    AppState.rightArrowShow = false;
  }

  static pulseRightArrow() {
    AppState.rightArrowPulse = true;
  }

  static stopRightArrow() {
    AppState.rightArrowPulse = false;
  }

  static enableAppScreenshot() {
    AppState.appScreenshotEnabled = true;
  }

  static disableAppScreenshot() {
    AppState.appScreenshotEnabled = false;
  }

  static async login(password) {
    const res = await fetch(
      `/api/v1/auth/login?username=all&password=${password}`,
      {
        method: 'POST',
        body: JSON.stringify({ password }),
      }
    ).then(r => r.json());

    AppState.authed = res.success;
  }

  static async checkAuth() {
    const res = await fetch('/api/v1/auth/check');
    const json = await res.json();
    AppState.authed = json.success;
    AppState.initial = false;
    AppState.authType = json.authType;
  }

  static hideAlert() {
    AppState.alertShow = false;
  }

  static showAlert(title, message, ms = 2000) {
    AppState.alertTitle = title;
    AppState.alertMessage = message;
    AppState.alertShow = true;
  }

  /**
   * Set the name for the shortcut that gets created when someone adds this to
   * the home screen on an iPad.
   *
   * In the future, we might want this to affect the title as well, but for now
   * it only changes the shortcut name.
   * @param {string} name The new name
   */
  static setShortcutName(name) {
    let meta = document.querySelector('meta[name=apple-mobile-web-app-title]');

    if (!meta) {
      meta = document.createElement('meta');
      meta.name = 'apple-mobile-web-app-title';
      document.head.appendChild(meta);
    }

    meta.setAttribute('content', `eSentire | ${name}`);
  }
}
