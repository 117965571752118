/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Page } from '../svg/page';
import { Code } from '../svg/code';

@observer
export class Slide10 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    analyzing: null,
    code: null,
  };

  // Run the first animation
  componentDidMount() {
    const { code, donut, analyzing } = this.players;

    this.steps = [
      // Step 1
      () => {
        // Do nothing
        this.animateProgress(2000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: code,
          opacity: 1,
          scale: 3,
          top: 370,
          delay: 0,
          duration: 1000,
          begin: activate,
        },
        {
          targets: donut,
          opacity: [0, 1],
          delay: 0,
          duration: 1000,
          begin: activate,
        },
        {
          targets: analyzing,
          easing: 'easeOutQuad',
          opacity: 1,
          left: 800,
          duration: 200,
          delay: 1000,
          begin: activate,
          complete: shimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-10 ${className}`}>
        {children}
        <div className="content">
          {/* Text block */}
          <div
            className="player analyzing"
            data-active="0"
            ref={node => (this.players.analyzing = node)}
          >
            <PlayerTextBlock
              title="Sandbox"
              actionTitle="Replicating results"
              className={'wrap'}
            />
          </div>

          {/* Code */}
          <div
            className="player code"
            data-active="0"
            ref={node => (this.players.code = node)}
          >
            <Code size={'45'} />
          </div>

          {/* Donut */}
          <div
            className="player donut"
            data-active="0"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
