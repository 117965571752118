/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// Players
import { PlayerProgressPie } from '../players/progress-pie';

// SVG
import { Shield } from '../svg/shield';
import { Person } from '../svg/person';
import { MachineLearning } from '../svg/machine-learning';

@observer
export class Slide15 extends AnimatedSlide {
  // Animation players
  players = {
    shield: null,
    customer1: null,
    customer2: null,
    customer3: null,
    customer4: null,
    customer5: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
    path5: null,
    update1: null,
    update2: null,
    update3: null,
    update4: null,
    update5: null,
  };

  bars = [
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
    {
      color: this.colors.blue,
      progress: 0,
    },
  ];

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Run the first animation
  componentDidMount() {
    this._isMounted = true;
    const {
      shield,
      customer1,
      customer2,
      customer3,
      customer4,
      customer5,
      path1,
      path2,
      path3,
      path4,
      path5,
      update1,
      update2,
      update3,
      update4,
      update5,
    } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(2000);
      },
      // Update 1
      () => {
        path1.classList.add('blue');
        this.autoNext(2000);
      },
      // Update 2
      () => {
        if (!this._isMounted) return;
        this.bars[0].progress = 100;
        // path1.classList.remove('blue');
        path2.classList.add('blue');
        this.forceUpdate();
        this.autoNext(2000);
      },
      // Update 3
      () => {
        if (!this._isMounted) return;
        this.bars[1].progress = 100;
        // path2.classList.remove('blue');
        path3.classList.add('blue');
        this.forceUpdate();
        this.autoNext(2000);
      },
      // Update 4
      () => {
        if (!this._isMounted) return;
        this.bars[2].progress = 100;
        // path3.classList.remove('blue');
        path4.classList.add('blue');
        this.forceUpdate();
        this.autoNext(2000);
      },
      // Update 5
      () => {
        if (!this._isMounted) return;
        this.bars[3].progress = 100;
        // path4.classList.remove('blue');
        path5.classList.add('blue');
        this.forceUpdate();
        this.autoNext(2000);
      },
      // Update finish
      () => {
        if (!this._isMounted) return;
        this.bars[4].progress = 100;
        this.forceUpdate();
        // path5.classList.remove('blue');
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: shield,
          opacity: 1,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: path1,
          opacity: 1,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: customer1,
          opacity: 1,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: path2,
          opacity: 1,
          delay: 500,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: customer2,
          opacity: 1,
          delay: 700,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: path3,
          opacity: 1,
          delay: 1000,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: customer3,
          opacity: 1,
          delay: 1200,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: path4,
          opacity: 1,
          delay: 1500,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: customer4,
          opacity: 1,
          delay: 1700,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: path5,
          opacity: 1,
          delay: 2000,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: customer5,
          opacity: 1,
          delay: 2200,
          duration: 500,
          easing: 'easeOutQuad',
          begin: activate,
          complete: activateShimmer,
        },
      ],
      //
      // Update 1
      // 320px traveled
      [
        {
          targets: update1,
          translateX: [
            { value: '+=80px', duration: 500 }, // 0.25
            { value: '+=0px', duration: 625 }, // 0.3125
            { value: '+=140px', duration: 875 }, // 0.4375
          ],
          translateY: [
            { value: '+=0px', duration: 500 },
            { value: '-=100px', duration: 625 },
            { value: '+=0px', duration: 875 },
          ],
          loop: true,
          duration: 2000,
          easing: 'linear',
          begin: activate,
          complete: deactivate,
        },
      ],
      //
      // Update 2
      // 410px traveled
      [
        {
          targets: update2,
          translateX: [
            { value: '+=160px', duration: 780 },
            { value: '+=0px', duration: 293 },
            { value: '+=190px', duration: 927 },
          ],
          translateY: [
            { value: '+=0px', duration: 780 },
            { value: '-=60px', duration: 293 },
            { value: '+=0px', duration: 927 },
          ],
          loop: true,
          duration: 2000,
          easing: 'linear',
          begin: activate,
          complete: deactivate,
        },
      ],
      //
      // Update 3
      // 280px traveled
      [
        {
          targets: update3,
          translateX: [0, 280],
          loop: true,
          duration: 2000,
          easing: 'linear',
          begin: activate,
          complete: deactivate,
        },
      ],
      //
      // Update 4
      // 410px traveled
      [
        {
          targets: update4,
          translateX: [
            { value: '+=160px', duration: 780 }, // .390
            { value: '+=0px', duration: 293 }, // .146
            { value: '+=190px', duration: 927 }, // .463
          ],
          translateY: [
            { value: '+=0px', duration: 780 },
            { value: '+=60px', duration: 293 },
            { value: '+=0px', duration: 927 },
          ],
          loop: true,
          duration: 2000,
          easing: 'linear',
          begin: activate,
          complete: deactivate,
        },
      ],
      //
      // Update 5
      // 320px traveled
      [
        {
          targets: update5,
          translateX: [
            { value: '+=80px', duration: 500 },
            { value: '+=0px', duration: 625 },
            { value: '+=140px', duration: 875 },
          ],
          translateY: [
            { value: '+=0px', duration: 500 },
            { value: '+=100px', duration: 625 },
            { value: '+=0px', duration: 875 },
          ],
          loop: true,
          duration: 2000,
          easing: 'linear',
          begin: activate,
          complete: deactivate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-15 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* Shield */}
          <div
            className="player shield"
            data-active="0"
            ref={node => (this.players.shield = node)}
          >
            <Shield
              IconComponent={MachineLearning}
              IconSize={70}
              letterSpacing={1}
              className="pulse-blue"
              text={['THREAT INTEL']}
            />
          </div>
          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <div
              className={'update'}
              data-active="0"
              ref={node => (this.players.update1 = node)}
            ></div>
            <div className={'animated-dotted-line reverse ants-1'}></div>
            <div className={'animated-dotted-line ants-2'}></div>
            <div className={'animated-dotted-line reverse ants-3'}></div>
          </div>
          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <div
              className={'update'}
              data-active="0"
              ref={node => (this.players.update2 = node)}
            ></div>
            <div className={'animated-dotted-line reverse ants-1'}></div>
            <div className={'animated-dotted-line ants-2'}></div>
            <div className={'animated-dotted-line reverse ants-3'}></div>
          </div>
          {/* Path 3 */}
          <div
            className="player path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <div
              className={'update'}
              data-active="0"
              ref={node => (this.players.update3 = node)}
            ></div>
            <div className={'animated-dotted-line reverse ants-1'}></div>
          </div>
          {/* Path 4 */}
          <div
            className="player path4"
            data-active="0"
            ref={node => (this.players.path4 = node)}
          >
            <div
              className={'update'}
              data-active="0"
              ref={node => (this.players.update4 = node)}
            ></div>
            <div className={'animated-dotted-line reverse ants-1'}></div>
            <div className={'animated-dotted-line reverse ants-2'}></div>
            <div className={'animated-dotted-line reverse ants-3'}></div>
          </div>
          {/* Path 5 */}
          <div
            className="player path5"
            data-active="0"
            ref={node => (this.players.path5 = node)}
          >
            <div
              className={'update'}
              data-active="0"
              ref={node => (this.players.update5 = node)}
            ></div>
            <div className={'animated-dotted-line reverse ants-1'}></div>
            <div className={'animated-dotted-line reverse ants-2'}></div>
            <div className={'animated-dotted-line reverse ants-3'}></div>
          </div>
          {/* Customer 1 */}
          <div
            className="player customer customer1"
            data-active="0"
            ref={node => (this.players.customer1 = node)}
          >
            <Person size={30} />
            <PlayerProgressPie
              progress={this.bars[0].progress}
              color={this.bars[0].color}
              size={70}
              stroke={3}
            />
          </div>
          {/* Customer 2 */}
          <div
            className="player customer customer2"
            data-active="0"
            ref={node => (this.players.customer2 = node)}
          >
            <Person size={30} />
            <PlayerProgressPie
              progress={this.bars[1].progress}
              color={this.bars[1].color}
              size={70}
              stroke={3}
            />
          </div>
          {/* Customer 3 */}
          <div
            className="player customer customer3"
            data-active="0"
            ref={node => (this.players.customer3 = node)}
          >
            <Person size={30} />
            <PlayerProgressPie
              progress={this.bars[2].progress}
              color={this.bars[2].color}
              size={70}
              stroke={3}
            />
          </div>
          {/* Customer 4 */}
          <div
            className="player customer customer4"
            data-active="0"
            ref={node => (this.players.customer4 = node)}
          >
            <Person size={30} />
            <PlayerProgressPie
              progress={this.bars[3].progress}
              color={this.bars[3].color}
              size={70}
              stroke={3}
            />
          </div>
          {/* Customer 5 */}
          <div
            className="player customer customer5"
            data-active="0"
            ref={node => (this.players.customer5 = node)}
          >
            <Person size={30} />
            <PlayerProgressPie
              progress={this.bars[4].progress}
              color={this.bars[4].color}
              size={70}
              stroke={3}
            />
          </div>
          {/* ------------------ END OF CONTENT ------------------*/}
        </div>
      </div>
    );
  }
}
