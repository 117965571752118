/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { observable } from 'mobx';
import { Computer } from '../svg/computer';

@observer
export class Slide04 extends AnimatedSlide {
  // Animation players
  players = {
    terminal: null,
    donut: null,
    cymon: null,
    notation: null,
    activity: null,
  };

  @observable
  donut = {
    color: 'blue',
  };

  // Run the first animation
  componentDidMount() {
    const { terminal, donut, cymon, notations, activity } = this.players;

    this.steps = [
      //
      // Step 1
      //
      () => {
        this.animateProgress(6000);
        this.autoNext(2000);
      },

      //
      // Step 2
      //
      () => {
        this.autoNext(1000);
      },

      //
      // Step 3
      //
      () => {
        this.autoNext(1000);
      },

      //
      // Step 4
      //
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: terminal,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: cymon,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: notations,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: activity,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-04 ${className}`}>
        {children}
        <div className="content">
          {/* Cymon */}
          <div
            className="player cymon"
            data-active="0"
            ref={node => (this.players.cymon = node)}
          >
            <PlayerTextBlock
              title={'Cymon'}
              width="250px"
              box={'IP NOT FOUND (PRIVATE)'}
              sections={[
                {
                  lines: 2,
                },
              ]}
            />
          </div>

          {/* IP Notations */}
          <div
            className="player notations"
            data-active="0"
            ref={node => (this.players.notations = node)}
          >
            <PlayerTextBlock
              title={'IP Notations'}
              width="250px"
              sections={[
                {
                  title: 'User has access to sensitive financial information',
                  multiline: true,
                  lines: 3,
                },
              ]}
            />
          </div>

          {/* Activity */}
          <div
            className="player activity"
            data-active="0"
            ref={node => (this.players.activity = node)}
          >
            <PlayerTextBlock
              title={'Activity'}
              width="250px"
              sections={[
                {
                  title: ['POSSIBLE FILE DOWNLOAD'],
                  color: 'red',
                  colorCells: 1,
                },
                {
                  title: ['IP', '192.168.1.140'],
                  grid: 2,
                  lineColumns: 2,
                  lines: 3,
                },
                {
                  title: ['POSSIBLE BEACONING'],
                  color: 'red',
                  colorCells: 1,
                },
                {
                  title: ['IP', '192.168.1.140'],
                  grid: 2,
                  lineColumns: 2,
                  lines: 3,
                },
              ]}
            />
          </div>

          {/* Computer terminal icon */}
          <div
            className="player terminal"
            data-active="1"
            ref={node => (this.players.terminal = node)}
          >
            <Card IconComponent={Computer} text={'192.168.1.140'} />
          </div>

          {/* Animated donut */}
          <div
            className={`player donut ${this.donut.color}`}
            data-active="1"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
