/** @jsx createElement */
import { createElement, Component } from 'react';
import { Close } from './svg/close';
import './dot-indicator.scss';

export class DotIndicator extends Component {
  render() {
    const { value, disabled = false } = this.props;

    if (disabled) {
      return <Close bold size={10} />;
    }

    if (value === null) {
      return '-';
    }

    if (typeof value === 'undefined') {
      return null;
    }

    return (
      <div className="DotIndicator">
        <div
          className={`DotIndicator__Dot ${
            value >= 0.5 ? 'DotIndicator__Dot--half' : ''
          } ${value >= 1 ? 'DotIndicator__Dot--whole' : ''}`}
        >
          <div className="DotIndicator__HalfDot" />
        </div>
        <div
          className={`DotIndicator__Dot ${
            value >= 1.5 ? 'DotIndicator__Dot--half' : ''
          } ${value >= 2 ? 'DotIndicator__Dot--whole' : ''}`}
        >
          <div className="DotIndicator__HalfDot" />
        </div>
        <div
          className={`DotIndicator__Dot ${
            value >= 2.5 ? 'DotIndicator__Dot--half' : ''
          } ${value >= 3 ? 'DotIndicator__Dot--whole' : ''}`}
        >
          <div className="DotIndicator__HalfDot" />
        </div>
      </div>
    );
  }
}
