/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Desktop extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 139,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 139 127"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M127.417 86.5909H11.5833V11.5455H127.417V86.5909ZM69.5 101.023C66.3059 101.023 63.7083 98.4337 63.7083 95.25C63.7083 92.0663 66.3059 89.4773 69.5 89.4773C72.6941 89.4773 75.2917 92.0663 75.2917 95.25C75.2917 98.4337 72.6941 101.023 69.5 101.023ZM127.417 0H11.5833C5.19512 0 0 5.17814 0 11.5455V98.1364C0 104.504 5.19512 109.682 11.5833 109.682H51.0043L47.1442 121.227H28.9583C27.3569 121.227 26.0625 122.52 26.0625 124.114C26.0625 125.707 27.3569 127 28.9583 127H49.2292H89.7708H89.8288H110.042C111.643 127 112.938 125.707 112.938 124.114C112.938 122.52 111.643 121.227 110.042 121.227H91.8558L87.9957 109.682H127.417C133.805 109.682 139 104.504 139 98.1364V11.5455C139 5.17814 133.805 0 127.417 0Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
