import { uniq } from 'lodash';
import { observable, reaction, computed, toJS } from 'mobx';
import { getComplianceAlignmentData } from '../../data/compliance-alignment-data-transform';

class State {
  @observable data = {};
  @observable title = 'Compliance Alignment';
  @observable subtitle = '';
  @observable selectedFunctions = [];
  @observable selectedProducts = [];
  @observable selectedCoverages = [];
  @observable activeFunctions = [];
  @observable activeProducts = [];
  @observable activeCoverages = [];
  @observable activeCoverageValues = [];

  @computed get isFiltering() {
    return Boolean(
      this.selectedFunctions.length ||
        this.selectedProducts.length ||
        this.selectedCoverages.length
    );
  }
}

export const ComplianceAlignmentState = new State();

export class ComplianceAlignmentActions {
  static async getComplianceAlignmentData() {
    ComplianceAlignmentState.data = await getComplianceAlignmentData();
    updateActiveItems();
  }

  static updateSelectedItems(name, item) {
    if (
      ComplianceAlignmentState[name].find(selected => selected.key === item.key)
    ) {
      ComplianceAlignmentState[name] = ComplianceAlignmentState[name].filter(
        selected => selected.key !== item.key
      );
    } else {
      ComplianceAlignmentState[name] = ComplianceAlignmentState[name].concat(
        item
      );
    }
  }
}

// Update the active items any time the selected items change
reaction(
  () => [
    ComplianceAlignmentState.selectedFunctions,
    ComplianceAlignmentState.selectedProducts,
    ComplianceAlignmentState.selectedCoverages,
  ],
  updateActiveItems
);

function updateActiveItems() {
  // Get the active functions based on selected items
  ComplianceAlignmentState.activeFunctions = [];
  Object.values(ComplianceAlignmentState.data.functions).forEach(item => {
    if (ComplianceAlignmentState.selectedFunctions.length) {
      for (const fn of ComplianceAlignmentState.selectedFunctions) {
        if (item.key === fn.key) {
          ComplianceAlignmentState.activeFunctions.push(item);
          return;
        }
      }
    } else if (ComplianceAlignmentState.selectedProducts.length) {
      for (const product of ComplianceAlignmentState.selectedProducts) {
        if (item.products[product.key]) {
          if (ComplianceAlignmentState.selectedCoverages.length) {
            for (const coverage of ComplianceAlignmentState.selectedCoverages) {
              const products = Object.values(item.products).filter(
                product => item.products[product.key].coverages[coverage.key]
              );
              if (
                item.products[product.key].coverages[coverage.key] &&
                products.length
              ) {
                ComplianceAlignmentState.activeFunctions.push(item);
              }
            }
          } else {
            ComplianceAlignmentState.activeFunctions.push(item);
          }
          return;
        }
      }
    } else if (ComplianceAlignmentState.selectedCoverages.length) {
      for (const coverage of ComplianceAlignmentState.selectedCoverages) {
        const products = Object.values(item.products).filter(
          product => item.products[product.key].coverages[coverage.key]
        );
        if (products.length) {
          ComplianceAlignmentState.activeFunctions.push(item);
          return;
        }
      }
    } else {
      ComplianceAlignmentState.activeFunctions.push(item);
      return;
    }
  });

  // Get the active products based on selected items
  ComplianceAlignmentState.activeProducts = [];
  Object.values(ComplianceAlignmentState.data.products).forEach(item => {
    if (ComplianceAlignmentState.selectedProducts.length) {
      for (const product of ComplianceAlignmentState.selectedProducts) {
        if (item.key === product.key) {
          ComplianceAlignmentState.activeProducts.push(item);
          return;
        }
      }
    } else if (ComplianceAlignmentState.selectedFunctions.length) {
      for (const fn of ComplianceAlignmentState.selectedFunctions) {
        if (fn.products[item.key]) {
          if (ComplianceAlignmentState.selectedCoverages.length) {
            for (const coverage of ComplianceAlignmentState.selectedCoverages) {
              if (
                fn.products[item.key].coverages[coverage.key] &&
                item.coverages[coverage.key]
              ) {
                ComplianceAlignmentState.activeProducts.push(item);
                return;
              }
            }
          } else {
            ComplianceAlignmentState.activeProducts.push(item);
          }
          return;
        }
      }
    } else if (ComplianceAlignmentState.selectedCoverages.length) {
      for (const coverage of ComplianceAlignmentState.selectedCoverages) {
        if (item.coverages[coverage.key]) {
          ComplianceAlignmentState.activeProducts.push(item);
          return;
        }
      }
    } else {
      ComplianceAlignmentState.activeProducts.push(item);
      return;
    }
  });

  // Get the active coverages based on selected items
  ComplianceAlignmentState.activeCoverages = [];
  Object.values(ComplianceAlignmentState.data.coverages).forEach(item => {
    if (ComplianceAlignmentState.selectedCoverages.length) {
      for (const coverage of ComplianceAlignmentState.selectedCoverages) {
        if (item.key === coverage.key) {
          ComplianceAlignmentState.activeCoverages.push(item);
          return;
        }
      }
    } else if (ComplianceAlignmentState.selectedFunctions.length) {
      for (const fn of ComplianceAlignmentState.selectedFunctions) {
        const products = Object.values(fn.products).filter(
          product => fn.products[product.key].coverages[item.key]
        );
        if (products.length) {
          if (ComplianceAlignmentState.selectedProducts.length) {
            for (const product of ComplianceAlignmentState.selectedProducts) {
              if (fn.products[product.key] && product.coverages[item.key]) {
                ComplianceAlignmentState.activeCoverages.push(item);
                return;
              }
            }
          } else {
            ComplianceAlignmentState.activeCoverages.push(item);
            return;
          }
        }
      }
    } else if (ComplianceAlignmentState.selectedProducts.length) {
      for (const product of ComplianceAlignmentState.selectedProducts) {
        if (product.coverages[item.key]) {
          ComplianceAlignmentState.activeCoverages.push(item);
          return;
        }
      }
    } else {
      ComplianceAlignmentState.activeCoverages.push(item);
      return;
    }
  });

  // Consolidate the active coverage values into the active coverages
  ComplianceAlignmentState.activeCoverageValues = [];
  ComplianceAlignmentState.activeCoverages.forEach(activeCoverage => {
    activeCoverage.values = [];
    ComplianceAlignmentState.activeFunctions.forEach(fn => {
      Object.values(fn.products).forEach(product => {
        if (
          ComplianceAlignmentState.activeProducts.find(
            activeProduct => activeProduct.key === product.key
          )
        ) {
          activeCoverage.values = activeCoverage.values.concat(
            (
              ComplianceAlignmentState.data.products[product.key].coverages[
                activeCoverage.key
              ] || {}
            ).values || []
          );
        }
      });
    });
    activeCoverage.values = uniq(activeCoverage.values);

    // Calculate percentage of total coverage
    activeCoverage.percent =
      ComplianceAlignmentState.data.coverages[activeCoverage.key].total &&
      activeCoverage.values.length /
        ComplianceAlignmentState.data.coverages[activeCoverage.key].total;

    ComplianceAlignmentState.activeCoverageValues = ComplianceAlignmentState.activeCoverageValues.concat(
      activeCoverage.values
    );
  });

  ComplianceAlignmentState.activeCoverageValues = uniq(
    ComplianceAlignmentState.activeCoverageValues
  );

  console.log(toJS(ComplianceAlignmentState.activeProducts));
}

export const DESCRIPTIONS = {
  'eslog+':
    'A core eSentire Managed Detection and Response offering, eSentire MDR for Log provides enhanced visibility, data correlation, deep investigation and enhanced threat detection enabling stronger threat response across your hybrid environments.',
  esendpoint:
    'A core eSentire Managed Detection and Response offering, eSentire MDR for Endpoint is a fully managed endpoint detection and response (EDR) service that provides machine-learning powered protection and threat containment against advanced cyberattacks 24/7.',
  'MDR for Log':
    'MDR for Log is a cloud-native data analytics platform and co-managed service that provides centralized log management, and critical asset visibility across on-prem, hybrid, and cloud environments that facilitate timely response to advanced threats.',
  'managed-vulnerability-service':
    'Managed Vulnerability Service identifies vulnerabilities with precision across traditional and dynamic IT assets such as mobile devices, OT, IoT, virtual machines and cloud instances for full visibility across business environments.',
  vciso:
    'eSentire’s vCISO portfolio provides dedicated security experts to assess risks, develop cybersecurity roadmaps to address known gaps and build a comprehensive program that meets industry and business requirements, today and tomorrow.',
  'vulnerability-assessment':
    'A point-in-time vulnerability scan that deliberately probes a network or system to discover its weaknesses. Results are analyzed by security experts and prioritized by severity with remediation guidance.',
  esnetwork:
    'A core eSentire Managed Detection and Response (MDR) offering eSentire MDR for Network is a fully managed service that provides real-time 24/7 network monitoring, threat hunting, and network threat containment.',
  'risk-assessment':
    'eSentire’s Risk Assessment is a comprehensive appraisal of a customer’s security posture designed to identify risk across four key areas: organizational, programmatic (security), human and technical.',
  'security-awareness-training':
    'Keep employees up-to-date with the evolving threat landscape and the latest preventative measures with in-person or virtual training designed to mitigate the risk of human error and promote a security culture in customers.',
};
