/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class ShieldKeys extends Component {
  render() {
    const {
      x = 0,
      y = 0,
      className = '',
      text = 'VPN',
      color = '#ffffff',
      key1 = '#50C2E3',
      key2 = '#C53D43',
    } = this.props;

    // Render shield
    return (
      <svg
        className={className}
        {...{ x, y }}
        width="105"
        height="129"
        viewBox="0 0 105 129"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M52.5 0L0 23.4545V58.6364C0 91.1796 22.4 121.612 52.5 129C82.6 121.612 105 91.1796 105 58.6364V23.4545L52.5 0Z"
          fill="white"
          fillOpacity="0.02"
        />
        <path
          d="M2 58.6364V24.7516L52.5 2.19051L103 24.7516V58.6364C103 90.1749 81.383 119.587 52.5 126.938C23.617 119.587 2 90.1749 2 58.6364Z"
          stroke="white"
          strokeOpacity="0.1"
          strokeWidth="4"
        />
        <text
          x="50%"
          y="45"
          textAnchor="middle"
          fontFamily="Blender Bold"
          fontSize="20px"
          letterSpacing="0.3px"
          fill={color}
          fillOpacity={0.7}
        >
          {text.toUpperCase()}
        </text>
        {key1 && (
          <path
            className="key1"
            d="M30.8359 73.5502C34.617 77.3314 40.7691 77.3314 44.5502 73.5502C45.6045 72.4959 46.4011 71.2045 46.8771 69.7868L57.456 69.7873V74.3588L61.3583 74.3582V69.7868L64.3137 69.7868V75.5011L69.3583 75.5011V69.7868L71.9789 69.7868C73.6852 69.7868 75.0727 68.3993 75.0727 66.693C75.0727 65.0405 73.6315 63.5993 71.9789 63.5993L46.8771 63.5993C46.4011 62.1816 45.6045 60.8902 44.5502 59.8359C40.7691 56.0547 34.617 56.0547 30.8359 59.8359C27.0547 63.617 27.0547 69.7691 30.8359 73.5502ZM41.1216 63.2645C43.0125 65.1553 43.0125 68.2308 41.1216 70.1216C39.2308 72.0125 36.1553 72.0125 34.2645 70.1216C32.3736 68.2308 32.3736 65.1553 34.2645 63.2645C36.1553 61.3736 39.2308 61.3736 41.1216 63.2645Z"
            fill={key1}
          />
        )}
        {key2 && (
          <path
            className="key2"
            d="M30.8359 97.5502C34.617 101.331 40.7691 101.331 44.5502 97.5502C45.6045 96.4959 46.4011 95.2045 46.8771 93.7868L57.456 93.7873V98.3588L61.3583 98.3582V93.7868L64.3137 93.7868V99.5011L69.3583 99.5011V93.7868L71.9789 93.7868C73.6852 93.7868 75.0727 92.3993 75.0727 90.693C75.0727 89.0405 73.6315 87.5993 71.9789 87.5993L46.8771 87.5993C46.4011 86.1816 45.6045 84.8902 44.5502 83.8359C40.7691 80.0547 34.617 80.0547 30.8359 83.8359C27.0547 87.617 27.0547 93.7691 30.8359 97.5502ZM41.1216 87.2645C43.0125 89.1553 43.0125 92.2308 41.1216 94.1216C39.2308 96.0125 36.1553 96.0125 34.2645 94.1216C32.3736 92.2308 32.3736 89.1553 34.2645 87.2645C36.1553 85.3736 39.2308 85.3736 41.1216 87.2645Z"
            fill={key2}
          />
        )}
      </svg>
    );
  }
}
