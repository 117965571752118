/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import ReactDOM from 'react-dom';
import { PlayerDonut } from '../players/donut';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerDataScan } from '../players/data-scan';
import anime from 'animejs';

// SVG
import { Alert } from '../svg/alert';
import { Warning } from '../svg/warning';

@observer
export class Slide07 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    scanner: null,
    info: null,
    alert: null,
    border: null,
  };

  // Progress references
  progress1 = 0;

  // Run the first animation
  componentDidMount() {
    this.players.border = this.players.alert.querySelector('.border');
    const { donut, scanner, info, alert, border } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(7000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(2000);
      },
      // Step 3
      () => {
        this.autoNext(3000);
      },
      // Final step
      () => {
        // Showing alert
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Do nothing
      ],
      //
      // Step 1 - Donut
      //
      [
        {
          targets: donut,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2 - Execute powershell 1
      //
      [
        {
          targets: scanner,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 3 - Execute powershell 2
      //
      [
        {
          targets: info,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
      ],
      //
      // Step 4
      //
      [
        {
          targets: alert,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
        {
          targets: donut,
          opacity: 0.2,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: scanner,
          opacity: 0.2,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: info,
          opacity: 0.2,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-07 ${className}`}>
        {children}
        <div className="content">
          {/* Donut analyzer */}
          <div
            className="player donut"
            data-active="0"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
          {/* Data scanner */}
          <div
            className="player scanner"
            data-active="0"
            ref={node => (this.players.scanner = node)}
          >
            <PlayerDataScan width={180} height={180} increment={0.5} gap={4} />
          </div>
          {/* Execute Powershell 2nd time */}
          <div
            className="player info"
            data-active="0"
            ref={node => (this.players.info = node)}
          >
            <PlayerTextBlock
              title={'ANALYZING LOG DATA'}
              sections={[
                {
                  title: ['NEW ACCOUNT DETECTED'],
                  color: 'red',
                  colorCells: 1,
                  animate: true,
                  delay: 2000,
                },
              ]}
            />
          </div>
          {/* Alert */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'SUSPICIOUS ACTIVITY'}
              subtitle={'New Account Created'}
              IconComponent={Warning}
            />
          </div>
        </div>
      </div>
    );
  }
}
