/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// SCSS
import '../message.scss';

// Players
import { PlayerDots } from '../players/dots';
import { PlayerWaves } from '../players/waves';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';

@observer
export class Slide10 extends AnimatedSlide {
  // Animation players
  players = {
    team: null,
    message: null,
    data: null,
    update: null,
    waves: null,
  };

  // Run the first animation
  componentDidMount() {
    const { team, message, data, update, waves } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(1000);
      },
      // Step 2
      () => {
        update.classList.add('show');
        this.autoNext(1000);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: team,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: message,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: data,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: waves,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1700,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2
      //
      [
        {
          targets: update,
          opacity: 1,
          translateX: [0, 100],
          easing: 'linear',
          delay: 0,
          duration: 1000,
          loop: true,
          begin: activate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-10 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TRU Team */}
          <div
            className="player team"
            data-active="0"
            ref={node => (this.players.team = node)}
          >
            <Card IconComponent={Person} text="TRU TEAM" />
            <div
              ref={node => (this.players.dots = node)}
              className={'animated-dotted-line reverse ants-1'}
            ></div>
          </div>
          {/* Message */}
          <div
            className="player message"
            data-active="0"
            ref={node => (this.players.message = node)}
          >
            <div className="Kaseya__Message wordless">
              <h2>Kaseya Supply-Chain Attack Leads to Ransomware</h2>
              <div className="bricks">
                <div className="subtitle">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="title">
                  <span></span>
                </div>
                <div className="rows">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          {/* Data dots */}
          <div
            className="player data"
            data-active="0"
            ref={node => (this.players.data = node)}
          >
            <PlayerDots width={100} dots={8} className="left-to-right" />
            <span
              className="update"
              ref={node => (this.players.update = node)}
            ></span>
          </div>
          {/* Waves */}
          <div
            className="player waves"
            data-active="0"
            ref={node => (this.players.waves = node)}
          >
            <PlayerWaves />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
