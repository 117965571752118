import { computed, observable } from 'mobx';

export const KILLCHAIN_PRODUCTS = [
  {
    label: 'eSentire MDR for Network',
    value: 'eSentire MDR for Network',
  },
  {
    label: 'eSentire MDR for Endpoint',
    value: 'eSentire MDR for Endpoint',
  },
  {
    label: 'eSentire MDR for Log',
    value: 'eSentire MDR for Log',
  },
  {
    label: 'MVS',
    value: 'mvs',
  },
  {
    label: 'eSentire MDR for Cloud',
    value: 'prisma',
  },
];

export const KILLCHAIN_COVERAGE = {
  esnetwork: {
    initialAccess: 2,
    execution: undefined,
    persistence: 0.5,
    privilegeEscalation: undefined,
    defenseEvasion: undefined,
    credentialAccess: 1,
    discovery: 0.5,
    lateralMovement: 0.5,
    collection: undefined,
    exfiltration: undefined,
    commandAndControl: 1.5,
  },
  esendpoint: {
    initialAccess: 0,
    execution: 1.5,
    persistence: 1,
    privilegeEscalation: 1.5,
    defenseEvasion: 1,
    credentialAccess: 1,
    discovery: 3,
    lateralMovement: 1.5,
    collection: 2,
    exfiltration: 1.5,
    commandAndControl: 1.5,
  },
  eslog: {
    initialAccess: 1.5,
    execution: 1.5,
    persistence: 1,
    privilegeEscalation: 1,
    defenseEvasion: 1,
    credentialAccess: 1,
    discovery: 1.5,
    lateralMovement: 1.5,
    collection: 1,
    exfiltration: 1,
    commandAndControl: 1.5,
  },
};

export const KILLCHAIN_DATA = {
  depthOfVisibility: {
    esnetwork: 3,
    esendpoint: 3,
    escloudi: 2.5,
    esclouds: 1.5,
    eslog: undefined,
    mvs: undefined,
  },
  speedOfDetection: {
    esnetwork: 3,
    esendpoint: 2.5,
    escloudi: 1.5,
    esclouds: 1.5,
    eslog: undefined,
    mvs: undefined,
  },
  speedOfResponse: {
    esnetwork: 3,
    esendpoint: 2.5,
    escloudi: 1.5,
    esclouds: 1.5,
    eslog: undefined,
    mvs: undefined,
  },
  externalRecon: {
    esnetwork: 1,
    esendpoint: undefined,
    escloudi: 3,
    esclouds: undefined,
    eslog: 1.5,
    mvs: 3,
  },
  delivery: {
    esnetwork: 1.5,
    esendpoint: 3,
    escloudi: 1.5,
    esclouds: 2,
    eslog: 1.5,
    mvs: undefined,
  },
  exploitation: {
    esnetwork: 1.5,
    esendpoint: 3,
    escloudi: 2.5,
    esclouds: 2,
    eslog: 1.5,
    mvs: undefined,
  },
  installation: {
    esnetwork: undefined,
    esendpoint: 3,
    escloudi: 1.5,
    esclouds: undefined,
    eslog: 1.5,
    mvs: undefined,
  },
  internalRecon: {
    esnetwork: undefined,
    esendpoint: undefined,
    escloudi: 1.5,
    esclouds: 1.5,
    eslog: 1.5,
    mvs: 3,
  },
  commandAndControl: {
    esnetwork: 3,
    esendpoint: undefined,
    escloudi: 1.5,
    esclouds: undefined,
    eslog: 1.5,
    mvs: undefined,
  },
  dataCollection: {
    esnetwork: undefined,
    esendpoint: undefined,
    escloudi: 2.5,
    esclouds: 2,
    eslog: 1.5,
    mvs: undefined,
  },
  exfiltration: {
    esnetwork: 1.5,
    esendpoint: undefined,
    escloudi: 2.5,
    esclouds: 2,
    eslog: 1.5,
    mvs: undefined,
  },
};

export const KILLCHAIN_OUTCOMES = {
  esnetwork: {
    outcomes: [
      'Loss of data in motion visibility',
      'Loss of network containment capabilities',
      'Loss of visibility into policy violations',
      'Increases reliance on other security technologies to detect threat in other stages',
      'Limits forensic capabilities (no PCAP)',
    ],
    capabilities: [
      'Phishing',
      'Brute Force',
      'Service Exploit Attempts',
      'Drive-by Attacks',
      'Malicious Connections',
      'Malicious Executables',
      'Unauthorized Scanning Across the Firewall',
      'Remote Desktop Protocol',
      'Remote Access Tools',
      'Ransomware',
      'Advanced Persistent',
    ],
  },
  esendpoint: {
    outcomes: [
      'Loss of process visibility — results in client escalations when unusual traffic is seen on the network, or lack of capability to track down',
      'Loss of endpoint containment (prevention of east-west lateral movement)',
      'Increases reliance on other security technologies to detect threat in other stages, thereby increasing threat actor dwell time',
      'Limits forensic capabilities (no endpoint activity recording); unable to see some attacker activities or understand length of dwell time, thereby increasing risk and recovery time',
    ],
    capabilities: [
      'Ransomware',
      'Spyware',
      'Trojans',
      'Keyloggers',
      'Rootkits',
      'Credential Harvesting',
      'Eavesdropping',
      'Screen Scraping',
      'Currency Miners',
      'File-less (DLLs, Macro-based, WMI, Powershell)',
      'Zero-day Exploits',
      'Advanced Persistent',
      'Obfuscated Executables',
      'Lateral Movement',
      'Behavioral',
    ],
  },
  eslog: {
    outcomes: [
      'Loss of broad-level awareness and context',
      'Loss of ability to create customized attacker ser cases (runbooks) applicable to your unique risks',
      'Limits forensic capabilities (no ability to correlate with logs from existing services and security controls) thereby increasing threat actor dwell time',
      'Loss of ability to integrate and aggregate data from on-premises and cloud data in singular location',
      'Increases reliance on other security technologies to detect threats in stages of the kill chain extending threat actor dwell time',
      'Increases manual workload for log collection, retention, and compliance reporting',
    ],
    capabilities: [
      'Cloud (IaaS and SaaS) attacks across:',
      ['AWS', 'Azure', 'GCP', 'O365'],
      'Cloud-based and On-premises attacks including:',
      [
        'Unauthorized access',
        'Account, service or resource hijacking',
        'Insider (malicious or careless insiders)',
        'Lateral movement',
        'Data exfiltration',
        'Web application attacks',
        'Advanced Persistent Threats',
        'Phishing',
        'Exploits',
        'Malware (virus, worm, trojans)',
      ],
    ],
  },
  mvs: {
    outcomes: [
      'Loss of asset awareness • Loss of vulnerability awareness',
      'Loss of vulnerability risks and prioritization',
      'Extends time from vulnerability discovery to remediation',
      'Loss of measurable vulnerability management progress and risk mitigation',
      'Increases reliance on other security technologies to detect exploitations — reactive approach',
    ],
    capabilities: [
      'Exploitations of Cloud Services including:',
      ['Misconfigurations', 'Container', 'Vulnerabilities'],
      'Infrastructure, service and applications exploits',
    ],
  },
  prisma: {
    outcomes: [
      'Loss of insights and malicious activity across IaaS',
      'Loss of real-time awareness',
      'Loss of automated policy remediation',
      'Loss of customized IaaS-specific detections (runbooks)',
      'Limits forensic capabilities (no ability to correlate with logs from existing services and security controls) thereby increasing threat actor dwell time',
      'Increases reliance on other security technologies to detect threats in stages of the kill chain',
    ],
    capabilities: [
      'Cloud risks and attacks across AWS, Azure and GCP including:',
      [
        'Asset creation, usage and exploitation',
        'Misconfiguration and exploitation',
        'Vulnerabilities and exploitation',
        'Policy violations',
        'Unauthorized access',
        'Account, service or resource hijacking',
        'Insider (malicious or careless insiders)',
        'Lateral movement',
        'Behavior based',
        'Data exfiltration',
        'Advanced Persistent Threats (APTs)',
      ],
    ],
  },
};

class State {
  @observable title = 'Killchain';
  @observable subtitle = '';
  @observable selectedProducts = KILLCHAIN_PRODUCTS.slice();

  @computed get unselectedProducts() {
    return KILLCHAIN_PRODUCTS.filter(product => {
      return !this.selectedProducts.find(
        selectedProduct => selectedProduct.value === product.value
      );
    });
  }
}

export const KillchainState = new State();

export class KillchainActions {
  static updateSelectedItems(name, item) {
    if (KillchainState[name].find(selected => selected.value === item.value)) {
      KillchainState[name] = KillchainState[name].filter(
        selected => selected.value !== item.value
      );
    } else {
      KillchainState[name] = KillchainState[name].concat(item);
    }
  }

  static clearSelectedProducts() {
    KillchainState.selectedProducts = [];
  }

  static resetSelectedProducts() {
    KillchainState.selectedProducts = KILLCHAIN_PRODUCTS.slice();
  }
}
