/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './team.scss';

@observer
export class Team extends Component {
  node = null;

  blue() {
    this.node.classList.add('blue-border');
    this.node.classList.add('shimmer');
  }

  red() {
    this.node.classList.add('red-border');
    this.node.classList.add('shimmer');
  }

  render() {
    const {
      children,
      className = '',
      text = '',
      size = 220,
      x = 0,
      y = 0,
      stroke = '#ffffff',
      textColor = '#ffffff',
      fontSize = '14px',
      strokeOpacity = 0.1,
      strokeWidth = 4,
    } = this.props;

    let radius = size / 2 - strokeWidth;
    let cxy = radius + strokeWidth;

    return (
      <svg
        className={`Icon__Team ${className}`}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        ref={node => (this.node = node)}
      >
        <circle
          className="Icon__Team__Fill"
          cx={cxy}
          cy={cxy}
          r={radius}
          fill="white"
          fillOpacity="0.03"
        />
        <circle
          className="Icon__Team__Border"
          cx={cxy}
          cy={cxy}
          r={radius}
          stroke={stroke}
          strokeOpacity={strokeOpacity}
          strokeWidth={strokeWidth}
        />
        <g
          className="Icon__Team__People"
          fill="#888F93"
          fillRule="evenodd"
          clipRule="evenodd"
        >
          <path d="M82.0045 97.1135C81.4839 96.0729 79.7074 95.4195 77.2484 94.5153C76.965 94.4112 76.6732 94.3039 76.375 94.1926V92.4866C76.6863 92.2228 77.3518 91.5336 77.4301 90.4084C77.5905 90.2724 77.7173 90.0376 77.7906 89.727C77.9085 89.2276 77.8497 88.6358 77.4966 88.2841C77.5172 88.2317 77.5402 88.1739 77.5618 88.1194C77.8335 87.4348 78.3389 86.1605 78.1391 85.1607C77.894 83.9349 76.1529 83.5 74.7366 83.5C73.6376 83.5 72.3031 83.7766 71.8824 84.5349C71.4592 84.582 71.1326 84.7569 70.9099 85.0573C70.2943 85.8871 70.7409 87.3608 70.981 88.1527C70.9931 88.192 71.0055 88.2331 71.0172 88.271C70.6524 88.6209 70.5904 89.2212 70.7097 89.727C70.7831 90.0376 70.9099 90.2724 71.0703 90.4084C71.1486 91.5336 71.814 92.2228 72.125 92.4866V94.1926C71.8268 94.3039 71.535 94.4115 71.2506 94.516C68.7919 95.4198 67.0158 96.0729 66.4959 97.1135C65.7585 98.5879 65.75 100.083 65.75 100.146C65.75 100.341 65.9087 100.5 66.1042 100.5H82.3958C82.5917 100.5 82.75 100.341 82.75 100.146C82.75 100.083 82.7419 98.5879 82.0045 97.1135Z" />
          <path d="M44.5045 97.1135C43.9839 96.0729 42.2074 95.4195 39.7484 94.5153C39.465 94.4112 39.1732 94.3039 38.875 94.1926V92.4866C39.1863 92.2228 39.8518 91.5336 39.9301 90.4084C40.0905 90.2724 40.2173 90.0376 40.2906 89.727C40.4085 89.2276 40.3497 88.6358 39.9966 88.2841C40.0172 88.2317 40.0402 88.1739 40.0618 88.1194C40.3335 87.4348 40.8389 86.1605 40.6391 85.1607C40.394 83.9349 38.6529 83.5 37.2366 83.5C36.1376 83.5 34.8031 83.7766 34.3824 84.5349C33.9592 84.582 33.6326 84.7569 33.4099 85.0573C32.7943 85.8871 33.2409 87.3608 33.481 88.1527C33.4931 88.192 33.5055 88.2331 33.5172 88.271C33.1524 88.6209 33.0904 89.2212 33.2097 89.727C33.2831 90.0376 33.4099 90.2724 33.5703 90.4084C33.6486 91.5336 34.314 92.2228 34.625 92.4866V94.1926C34.3268 94.3039 34.035 94.4115 33.7506 94.516C31.2919 95.4198 29.5158 96.0729 28.9959 97.1135C28.2585 98.5879 28.25 100.083 28.25 100.146C28.25 100.341 28.4087 100.5 28.6042 100.5H44.8958C45.0917 100.5 45.25 100.341 45.25 100.146C45.25 100.083 45.2419 98.5879 44.5045 97.1135Z" />
          <path d="M82.0045 137.113C81.4839 136.073 79.7074 135.419 77.2484 134.515C76.965 134.411 76.6732 134.304 76.375 134.193V132.487C76.6863 132.223 77.3518 131.534 77.4301 130.408C77.5905 130.272 77.7173 130.038 77.7906 129.727C77.9085 129.228 77.8497 128.636 77.4966 128.284C77.5172 128.232 77.5402 128.174 77.5618 128.119C77.8335 127.435 78.3389 126.161 78.1391 125.161C77.894 123.935 76.1529 123.5 74.7366 123.5C73.6376 123.5 72.3031 123.777 71.8824 124.535C71.4592 124.582 71.1326 124.757 70.9099 125.057C70.2943 125.887 70.7409 127.361 70.981 128.153C70.9931 128.192 71.0055 128.233 71.0172 128.271C70.6524 128.621 70.5904 129.221 70.7097 129.727C70.7831 130.038 70.9099 130.272 71.0703 130.408C71.1486 131.534 71.814 132.223 72.125 132.487V134.193C71.8268 134.304 71.535 134.412 71.2506 134.516C68.7919 135.42 67.0158 136.073 66.4959 137.113C65.7585 138.588 65.75 140.083 65.75 140.146C65.75 140.341 65.9087 140.5 66.1042 140.5H82.3958C82.5917 140.5 82.75 140.341 82.75 140.146C82.75 140.083 82.7419 138.588 82.0045 137.113Z" />
          <path d="M44.5045 137.113C43.9839 136.073 42.2074 135.419 39.7484 134.515C39.465 134.411 39.1732 134.304 38.875 134.193V132.487C39.1863 132.223 39.8518 131.534 39.9301 130.408C40.0905 130.272 40.2173 130.038 40.2906 129.727C40.4085 129.228 40.3497 128.636 39.9966 128.284C40.0172 128.232 40.0402 128.174 40.0618 128.119C40.3335 127.435 40.8389 126.161 40.6391 125.161C40.394 123.935 38.6529 123.5 37.2366 123.5C36.1376 123.5 34.8031 123.777 34.3824 124.535C33.9592 124.582 33.6326 124.757 33.4099 125.057C32.7943 125.887 33.2409 127.361 33.481 128.153C33.4931 128.192 33.5055 128.233 33.5172 128.271C33.1524 128.621 33.0904 129.221 33.2097 129.727C33.2831 130.038 33.4099 130.272 33.5703 130.408C33.6486 131.534 34.314 132.223 34.625 132.487V134.193C34.3268 134.304 34.035 134.412 33.7506 134.516C31.2919 135.42 29.5158 136.073 28.9959 137.113C28.2585 138.588 28.25 140.083 28.25 140.146C28.25 140.341 28.4087 140.5 28.6042 140.5H44.8958C45.0917 140.5 45.25 140.341 45.25 140.146C45.25 140.083 45.2419 138.588 44.5045 137.113Z" />
          <path d="M82.0045 177.113C81.4839 176.073 79.7074 175.419 77.2484 174.515C76.965 174.411 76.6732 174.304 76.375 174.193V172.487C76.6863 172.223 77.3518 171.534 77.4301 170.408C77.5905 170.272 77.7173 170.038 77.7906 169.727C77.9085 169.228 77.8497 168.636 77.4966 168.284C77.5172 168.232 77.5402 168.174 77.5618 168.119C77.8335 167.435 78.3389 166.161 78.1391 165.161C77.894 163.935 76.1529 163.5 74.7366 163.5C73.6376 163.5 72.3031 163.777 71.8824 164.535C71.4592 164.582 71.1326 164.757 70.9099 165.057C70.2943 165.887 70.7409 167.361 70.981 168.153C70.9931 168.192 71.0055 168.233 71.0172 168.271C70.6524 168.621 70.5904 169.221 70.7097 169.727C70.7831 170.038 70.9099 170.272 71.0703 170.408C71.1486 171.534 71.814 172.223 72.125 172.487V174.193C71.8268 174.304 71.535 174.412 71.2506 174.516C68.7919 175.42 67.0158 176.073 66.4959 177.113C65.7585 178.588 65.75 180.083 65.75 180.146C65.75 180.341 65.9087 180.5 66.1042 180.5H82.3958C82.5917 180.5 82.75 180.341 82.75 180.146C82.75 180.083 82.7419 178.588 82.0045 177.113Z" />
          <path d="M119.504 137.113C118.984 136.073 117.207 135.419 114.748 134.515C114.465 134.411 114.173 134.304 113.875 134.193V132.487C114.186 132.223 114.852 131.534 114.93 130.408C115.09 130.272 115.217 130.038 115.291 129.727C115.409 129.228 115.35 128.636 114.997 128.284C115.017 128.232 115.04 128.174 115.062 128.119C115.333 127.435 115.839 126.161 115.639 125.161C115.394 123.935 113.653 123.5 112.237 123.5C111.138 123.5 109.803 123.777 109.382 124.535C108.959 124.582 108.633 124.757 108.41 125.057C107.794 125.887 108.241 127.361 108.481 128.153C108.493 128.192 108.505 128.233 108.517 128.271C108.152 128.621 108.09 129.221 108.21 129.727C108.283 130.038 108.41 130.272 108.57 130.408C108.649 131.534 109.314 132.223 109.625 132.487V134.193C109.327 134.304 109.035 134.412 108.751 134.516C106.292 135.42 104.516 136.073 103.996 137.113C103.258 138.588 103.25 140.083 103.25 140.146C103.25 140.341 103.409 140.5 103.604 140.5H119.896C120.092 140.5 120.25 140.341 120.25 140.146C120.25 140.083 120.242 138.588 119.504 137.113Z" />
          <path d="M119.504 97.1135C118.984 96.0729 117.207 95.4195 114.748 94.5153C114.465 94.4112 114.173 94.3039 113.875 94.1926V92.4866C114.186 92.2228 114.852 91.5336 114.93 90.4084C115.09 90.2724 115.217 90.0376 115.291 89.727C115.409 89.2276 115.35 88.6358 114.997 88.2841C115.017 88.2317 115.04 88.1739 115.062 88.1194C115.333 87.4348 115.839 86.1605 115.639 85.1607C115.394 83.9349 113.653 83.5 112.237 83.5C111.138 83.5 109.803 83.7766 109.382 84.5349C108.959 84.582 108.633 84.7569 108.41 85.0573C107.794 85.8871 108.241 87.3608 108.481 88.1527C108.493 88.192 108.505 88.2331 108.517 88.271C108.152 88.6209 108.09 89.2212 108.21 89.727C108.283 90.0376 108.41 90.2724 108.57 90.4084C108.649 91.5336 109.314 92.2228 109.625 92.4866V94.1926C109.327 94.3039 109.035 94.4115 108.751 94.516C106.292 95.4198 104.516 96.0729 103.996 97.1135C103.258 98.5879 103.25 100.083 103.25 100.146C103.25 100.341 103.409 100.5 103.604 100.5H119.896C120.092 100.5 120.25 100.341 120.25 100.146C120.25 100.083 120.242 98.5879 119.504 97.1135Z" />
          <path d="M157.004 137.113C156.484 136.073 154.707 135.419 152.248 134.515C151.965 134.411 151.673 134.304 151.375 134.193V132.487C151.686 132.223 152.352 131.534 152.43 130.408C152.59 130.272 152.717 130.038 152.791 129.727C152.909 129.228 152.85 128.636 152.497 128.284C152.517 128.232 152.54 128.174 152.562 128.119C152.833 127.435 153.339 126.161 153.139 125.161C152.894 123.935 151.153 123.5 149.737 123.5C148.638 123.5 147.303 123.777 146.882 124.535C146.459 124.582 146.133 124.757 145.91 125.057C145.294 125.887 145.741 127.361 145.981 128.153C145.993 128.192 146.005 128.233 146.017 128.271C145.652 128.621 145.59 129.221 145.71 129.727C145.783 130.038 145.91 130.272 146.07 130.408C146.149 131.534 146.814 132.223 147.125 132.487V134.193C146.827 134.304 146.535 134.412 146.251 134.516C143.792 135.42 142.016 136.073 141.496 137.113C140.758 138.588 140.75 140.083 140.75 140.146C140.75 140.341 140.909 140.5 141.104 140.5H157.396C157.592 140.5 157.75 140.341 157.75 140.146C157.75 140.083 157.742 138.588 157.004 137.113Z" />
          <path d="M194.504 137.113C193.984 136.073 192.207 135.419 189.748 134.515C189.465 134.411 189.173 134.304 188.875 134.193V132.487C189.186 132.223 189.852 131.534 189.93 130.408C190.09 130.272 190.217 130.038 190.291 129.727C190.409 129.228 190.35 128.636 189.997 128.284C190.017 128.232 190.04 128.174 190.062 128.119C190.333 127.435 190.839 126.161 190.639 125.161C190.394 123.935 188.653 123.5 187.237 123.5C186.138 123.5 184.803 123.777 184.382 124.535C183.959 124.582 183.633 124.757 183.41 125.057C182.794 125.887 183.241 127.361 183.481 128.153C183.493 128.192 183.505 128.233 183.517 128.271C183.152 128.621 183.09 129.221 183.21 129.727C183.283 130.038 183.41 130.272 183.57 130.408C183.649 131.534 184.314 132.223 184.625 132.487V134.193C184.327 134.304 184.035 134.412 183.751 134.516C181.292 135.42 179.516 136.073 178.996 137.113C178.258 138.588 178.25 140.083 178.25 140.146C178.25 140.341 178.409 140.5 178.604 140.5H194.896C195.092 140.5 195.25 140.341 195.25 140.146C195.25 140.083 195.242 138.588 194.504 137.113Z" />
          <path d="M119.504 177.113C118.984 176.073 117.207 175.419 114.748 174.515C114.465 174.411 114.173 174.304 113.875 174.193V172.487C114.186 172.223 114.852 171.534 114.93 170.408C115.09 170.272 115.217 170.038 115.291 169.727C115.409 169.228 115.35 168.636 114.997 168.284C115.017 168.232 115.04 168.174 115.062 168.119C115.333 167.435 115.839 166.161 115.639 165.161C115.394 163.935 113.653 163.5 112.237 163.5C111.138 163.5 109.803 163.777 109.382 164.535C108.959 164.582 108.633 164.757 108.41 165.057C107.794 165.887 108.241 167.361 108.481 168.153C108.493 168.192 108.505 168.233 108.517 168.271C108.152 168.621 108.09 169.221 108.21 169.727C108.283 170.038 108.41 170.272 108.57 170.408C108.649 171.534 109.314 172.223 109.625 172.487V174.193C109.327 174.304 109.035 174.412 108.751 174.516C106.292 175.42 104.516 176.073 103.996 177.113C103.258 178.588 103.25 180.083 103.25 180.146C103.25 180.341 103.409 180.5 103.604 180.5H119.896C120.092 180.5 120.25 180.341 120.25 180.146C120.25 180.083 120.242 178.588 119.504 177.113Z" />
          <path d="M157.004 97.1135C156.484 96.0729 154.707 95.4195 152.248 94.5153C151.965 94.4112 151.673 94.3039 151.375 94.1926V92.4866C151.686 92.2228 152.352 91.5336 152.43 90.4084C152.59 90.2724 152.717 90.0376 152.791 89.727C152.909 89.2276 152.85 88.6358 152.497 88.2841C152.517 88.2317 152.54 88.1739 152.562 88.1194C152.833 87.4348 153.339 86.1605 153.139 85.1607C152.894 83.9349 151.153 83.5 149.737 83.5C148.638 83.5 147.303 83.7766 146.882 84.5349C146.459 84.582 146.133 84.7569 145.91 85.0573C145.294 85.8871 145.741 87.3608 145.981 88.1527C145.993 88.192 146.005 88.2331 146.017 88.271C145.652 88.6209 145.59 89.2212 145.71 89.727C145.783 90.0376 145.91 90.2724 146.07 90.4084C146.149 91.5336 146.814 92.2228 147.125 92.4866V94.1926C146.827 94.3039 146.535 94.4115 146.251 94.516C143.792 95.4198 142.016 96.0729 141.496 97.1135C140.758 98.5879 140.75 100.083 140.75 100.146C140.75 100.341 140.909 100.5 141.104 100.5H157.396C157.592 100.5 157.75 100.341 157.75 100.146C157.75 100.083 157.742 98.5879 157.004 97.1135Z" />
          <path d="M194.504 97.1135C193.984 96.0729 192.207 95.4195 189.748 94.5153C189.465 94.4112 189.173 94.3039 188.875 94.1926V92.4866C189.186 92.2228 189.852 91.5336 189.93 90.4084C190.09 90.2724 190.217 90.0376 190.291 89.727C190.409 89.2276 190.35 88.6358 189.997 88.2841C190.017 88.2317 190.04 88.1739 190.062 88.1194C190.333 87.4348 190.839 86.1605 190.639 85.1607C190.394 83.9349 188.653 83.5 187.237 83.5C186.138 83.5 184.803 83.7766 184.382 84.5349C183.959 84.582 183.633 84.7569 183.41 85.0573C182.794 85.8871 183.241 87.3608 183.481 88.1527C183.493 88.192 183.505 88.2331 183.517 88.271C183.152 88.6209 183.09 89.2212 183.21 89.727C183.283 90.0376 183.41 90.2724 183.57 90.4084C183.649 91.5336 184.314 92.2228 184.625 92.4866V94.1926C184.327 94.3039 184.035 94.4115 183.751 94.516C181.292 95.4198 179.516 96.0729 178.996 97.1135C178.258 98.5879 178.25 100.083 178.25 100.146C178.25 100.341 178.409 100.5 178.604 100.5H194.896C195.092 100.5 195.25 100.341 195.25 100.146C195.25 100.083 195.242 98.5879 194.504 97.1135Z" />
          <path d="M157.004 177.113C156.484 176.073 154.707 175.419 152.248 174.515C151.965 174.411 151.673 174.304 151.375 174.193V172.487C151.686 172.223 152.352 171.534 152.43 170.408C152.59 170.272 152.717 170.038 152.791 169.727C152.909 169.228 152.85 168.636 152.497 168.284C152.517 168.232 152.54 168.174 152.562 168.119C152.833 167.435 153.339 166.161 153.139 165.161C152.894 163.935 151.153 163.5 149.737 163.5C148.638 163.5 147.303 163.777 146.882 164.535C146.459 164.582 146.133 164.757 145.91 165.057C145.294 165.887 145.741 167.361 145.981 168.153C145.993 168.192 146.005 168.233 146.017 168.271C145.652 168.621 145.59 169.221 145.71 169.727C145.783 170.038 145.91 170.272 146.07 170.408C146.149 171.534 146.814 172.223 147.125 172.487V174.193C146.827 174.304 146.535 174.412 146.251 174.516C143.792 175.42 142.016 176.073 141.496 177.113C140.758 178.588 140.75 180.083 140.75 180.146C140.75 180.341 140.909 180.5 141.104 180.5H157.396C157.592 180.5 157.75 180.341 157.75 180.146C157.75 180.083 157.742 178.588 157.004 177.113Z" />
        </g>
        <text
          x="50%"
          y="55px"
          textAnchor="middle"
          fontFamily="Blender Bold"
          fontSize={fontSize}
          letterSpacing={'0px'}
          fill={textColor}
          fillOpacity="1"
        >
          {text}
        </text>
      </svg>
    );
  }
}
