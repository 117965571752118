import { observer } from 'mobx-react';
import * as React from 'react';
import { Component } from 'react';
import { ProbabilityCumulativeChartStore } from './probability-cumulative-chart-store.js';
import './probability-cumulative-chart.scss';
import { ChartShell } from './chart-shell.jsx';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import { autorun, observable, toJS } from 'mobx';
import {
  probabilityCalculator,
  IProbabilityCalculatorSeries,
  Scenario,
  IProbabilityCalculatorData,
} from '../../data/graph-data-transforms';
import { generateColor } from '../../data/color-generator';
import Color from 'color';

const shortMonth = new Intl.DateTimeFormat(undefined, { month: 'short' })
  .format;
const longMonth = new Intl.DateTimeFormat(undefined, { month: 'long' }).format;
const formatPercent = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 0,
}).format;
const get = (data: any, path: string[]) =>
  path.reduce((data, key) => (data ? data[key] : undefined), data);

type SeriesData = IProbabilityCalculatorSeries & {
  color: Color;
  key: string;
};

type SetOptional<T, U extends keyof T> = Pick<T, Exclude<keyof T, U>> &
  Pick<Partial<T>, U>;

export type InputSeriesData = SetOptional<SeriesData, 'color' | 'key'>;

export interface IProps
  extends Pick<Partial<React.HTMLAttributes<{}>>, 'className'> {
  /** The data for the chart */
  data?: any;
  width?: number;
  height?: number;
  colors?: Color[];
  /** What sections should be displayed on the chart */
  series: InputSeriesData[];
  sensorCount?: number;
  scenario: Scenario;
  showCumulative?: boolean;
}

@observer
export class ProbabilityCumulativeChart extends Component<IProps> {
  @observable
  filteredData: {
    series: IProbabilityCalculatorSeries[];
    data: IProbabilityCalculatorData[];
  } = {
    series: [],
    data: [],
  };
  _dispose = () => {};

  componentDidMount() {
    this._dispose = autorun(() => {
      const { series = [], sensorCount = 1, scenario } = this.props;

      (async () => {
        // Calculate the probability data from the system
        this.filteredData = await probabilityCalculator({
          series: series,
          sensorCount,
          scenario,
          isCumulative: true,
          includeCompounded: this.props.showCumulative || false,
        });
      })();
    });
  }

  componentWillUnmount() {
    try {
      this._dispose();
    } catch (error) {}
  }

  render() {
    const {
      children,
      className = '',
      width = 900,
      height = 500,
      series,
      data = this.filteredData,
      colors = [],
      // Default to the store if not found in props
    } = this.props;
    const scenario = {
      [Scenario.AVERAGE]: 'value',
      [Scenario.BEST]: 'min',
      [Scenario.WORST]: 'max',
    }[this.props.scenario || 0];

    const { data: seriesData, series: querySeries } = data;

    // Make sure the series values are defaulted
    const allSeries = this.props.series.map((series, i) => ({
      ...series,
      key: series.key || `${series.filter} - ${series.attackType}`,
      color: series.color || colors[i] || generateColor(i),
    }));

    // Include any extra series the data returned provided for the query
    querySeries.forEach(item => {
      if (
        !this.props.series.find(
          check =>
            check.filter === item.filter && check.attackType === item.attackType
        )
      ) {
        const index = allSeries.length;
        allSeries.push({
          ...item,
          key: `${item.filter} - ${item.attackType}`,
          color: colors[index] || generateColor(index),
        });
      }
    });

    const cleanData = seriesData.map(row =>
      allSeries.reduce(
        (row, series) => ({
          ...row,
          [series.key]: get(row, [series.filter, series.attackType, scenario]),
        }),
        row
      )
    );

    return (
      <ChartShell className={className} leftSection="Probability of Incident">
        <LineChart {...{ data: cleanData, width, height }}>
          <YAxis
            tickLine={false}
            axisLine={false}
            tickFormatter={formatPercent}
          />
          <CartesianGrid vertical={false} stroke="rgba(255, 255, 255, 0.5)" />
          <XAxis
            dataKey={'month'}
            tickFormatter={shortMonth}
            tickLine={false}
            axisLine={false}
          />
          <Tooltip
            isAnimationActive={false}
            content={this.renderTooltip(allSeries, scenario)}
            cursor={false}
          />
          {allSeries.map(series => (
            <Line
              key={series.key}
              dot={false}
              dataKey={series.key}
              stroke={`${series.color}`}
              strokeWidth={3}
              strokeDasharray="3, 5"
            />
          ))}
          {children}
        </LineChart>
      </ChartShell>
    );
  }

  renderTooltip = (allSeries: SeriesData[], scenario) => data => {
    if (!(data && data.payload && data.payload.length)) return;
    const scenario = {
      [Scenario.AVERAGE]: 'value',
      [Scenario.BEST]: 'min',
      [Scenario.WORST]: 'max',
    }[this.props.scenario || Scenario.AVERAGE];

    return (
      <div className="ProbabilityInstantChart__Tooltip">
        <div className="Tooltip__Header">
          {longMonth(get(data, ['payload', '0', 'payload', 'month']))}
        </div>
        <span className="Tooltip__TH">Indicent Type</span>
        <span className="Tooltip__TH">Probability</span>
        <span className="Tooltip__TH">Confidence</span>
        {data.payload.map((series, i) => {
          const { key, color, filter, attackType } = allSeries[i];

          return (
            <React.Fragment key={key}>
              <span className="Tooltip__Line" />
              <span
                className="Tooltip__Dot"
                style={{ background: `${color}` }}
              />
              <span className="Tooltip__NameCell">{key}</span>
              <span className="Tooltip__ValueCell">
                {formatPercent(series.value)}
              </span>
              <span className="Tooltip__ConfidenceCell">
                {formatPercent(
                  get(series, ['payload', filter, attackType, 'confidence'])
                )}
              </span>
            </React.Fragment>
          );
        })}
      </div>
    );
  };
}
