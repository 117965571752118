/** @jsx createElement */
// SVG
import { Page } from '../svg/page';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerCircleIcon } from '../players/circle-icon';

@observer
export class Slide18 extends AnimatedSlide {
  // Animation players
  players = {
    page: null,
    payload: null,
    filepath: null,
    hash: null,
    results: null,
  };

  // Run the first animation
  componentDidMount() {
    const { page, payload, filepath, hash, results } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(9000);
        this.autoNext(2000);
      },
      // Step 2
      () => {
        this.autoNext(2000);
      },
      // Step 3
      () => {
        this.autoNext(2000);
      },
      // Step 4
      () => {
        this.autoNext(2000);
      },
      // Step 5
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: page,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },

        // Deactivate
        {
          targets: payload,
          easing: 'easeOutQuad',
          opacity: 0,
          translateX: -50,
          duration: 200,
          complete: deactivate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: payload,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: 0,
          duration: 200,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: filepath,
          easing: 'easeOutQuad',
          opacity: 0,
          translateX: -50,
          duration: 200,
          complete: deactivate,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: filepath,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: 0,
          duration: 200,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: hash,
          easing: 'easeOutQuad',
          opacity: 0,
          translateX: -50,
          duration: 200,
          complete: deactivate,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: hash,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: 0,
          duration: 200,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: results,
          easing: 'easeOutQuad',
          opacity: 0,
          translateX: -50,
          duration: 200,
          complete: deactivate,
        },
      ],

      //
      // Step 5
      //
      [
        {
          targets: results,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: 0,
          duration: 200,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`SandboxFindings4 ${className}`}>
        {children}
        <div className="content">
          {/* Sandbox results */}
          <div
            className="player results"
            data-active="0"
            ref={node => (this.players.results = node)}
          >
            <PlayerTextBlock
              title="Sandbox Results"
              className={'box-green'}
              box={'Binary match confirmed'}
            />
          </div>

          {/* Hash */}
          <div
            className="player hash"
            data-active="0"
            ref={node => (this.players.hash = node)}
          >
            <PlayerTextBlock
              title="Hash details"
              sections={[
                {
                  lines: 3,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Filepath */}
          <div
            className="player filepath"
            data-active="0"
            ref={node => (this.players.filepath = node)}
          >
            <PlayerTextBlock
              title="Filepath"
              sections={[
                {
                  title: '%PUBLIC%\\238789.EXE',
                },
              ]}
            />
          </div>

          {/* Document payload */}
          <div
            className="player payload"
            data-active="0"
            ref={node => (this.players.payload = node)}
          >
            <PlayerTextBlock
              title="Document Payload"
              showDots={true}
              sections={[
                {
                  title: '238789.EXE',
                },
              ]}
            />
          </div>

          {/* Page */}
          <div
            className="player page"
            data-active="1"
            ref={node => (this.players.page = node)}
          >
            <PlayerCircleIcon Icon={Page} showTitle={false} color={'#ffffff'} />
          </div>
        </div>
      </div>
    );
  }
}
