/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { AnimatedSlide } from '../components/animated-slide';
import { getColor } from '../components/helpers';

// SCSS
import './team-sends-advisory.scss';

// Players
import { PlayerWaves } from '../components/players/waves';
import { PlayerPath } from '../components/players/path';

// SVG
import { TRU } from '../components/svg/tru';
import { Shield } from '../components/svg/shield';

@observer
export class Template__TeamSendsAdvisory extends AnimatedSlide {
  // Animation players
  players = {
    team: null,
    message: null,
    path1: null,
    path2: null,
    waves: null,
  };

  title = {
    text: 'Message Title',
    fontSize: 60,
    lineHeight: 40,
    letterSpacing: -4,
    color: getColor('white'),
  };

  // Team shield
  team = {
    icon: <TRU size={60} color={this.colors.white} opacity={1} />,
    text: 'TRU Team',
    stroke: this.colors.blue,
    strokeOpacity: 1,
    delay: 700,
  };

  // Run the first animation
  componentDidMount() {
    const { team, message, waves, path1, path2 } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(1000);
      },
      // Step 2
      () => {
        this.autoNext(1000);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(team, 500),
        this.fadeIn(path1, 700),
        this.fadeIn(message, 1500),
        this.fadeIn(path2, 2000),
        this.fadeIn(waves, 3000),
      ],
    ];

    // Play the first animation
    this.play();
  }

  getTextWithBreaks(text) {
    if (Array.isArray(text)) {
      return text.map((value, key) => {
        return <div key={key}>{value}</div>;
      });
    }
    return text;
  }

  render() {
    const { children, className = '', title = {}, team = {} } = this.props;

    Object.assign(this.title, title);
    Object.assign(this.team, team);

    // Title styles
    let titleStyles = {
      fontSize: this.title.fontSize,
      letterSpacing: this.title.letterSpacing,
      lineHeight: this.getPx(this.title.lineHeight),
      color: getColor(this.title.color),
    };

    return (
      <div className={`Template__TeamSendsAdvisory ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* TRU Team */}
          <div
            className="player team"
            data-active="0"
            ref={node => (this.players.team = node)}
          >
            <Shield {...this.team} />
          </div>
          {/* Message */}
          <div
            className="player message"
            data-active="0"
            ref={node => (this.players.message = node)}
          >
            <div className="The__Message wordless">
              <h2 style={titleStyles}>
                {this.getTextWithBreaks(this.title.text)}
              </h2>
              <div className="bricks">
                <div className="subtitle">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="title">
                  <span></span>
                </div>
                <div className="rows">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          {/* Path 1 */}
          <div
            className="player terminal path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,3', '100,3']}
              gradient={{
                direction: 'right',
                preset: 'blueWhite',
              }}
              reveal={{
                delay: 1000,
                duration: 1000,
                strokeWidth: 5,
              }}
            />
          </div>
          {/* Path 2 */}
          <div
            className="player terminal path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,3', '100,3']}
              data={{
                delay: 2500,
                duration: 1000,
              }}
              gradient={{
                direction: 'left',
                preset: 'blueWhite',
              }}
              reveal={{
                delay: 2200,
                duration: 1000,
              }}
            />
          </div>
          {/* Waves */}
          <div
            className="player waves"
            data-active="0"
            ref={node => (this.players.waves = node)}
          >
            <PlayerWaves />
          </div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
