/** @jsx createElement */
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { AppActions } from '../app-actions';
import { PlayerTimeline } from '../players/timeline';

@observer
export class Slide23 extends AnimatedSlide {
  // Animation players
  players = {
    timeline: null,
    events: null,
  };

  timeline = {
    unit: 'minute',
    units: 9,
    start: 2,
    details: [
      {
        start: '2:34',
        description: 'Process created a suspicious file',
        active: true,
      },
      {
        start: '2:49',
        description:
          'Process encrypts and deletes the client IP Outlook mailbox',
      },
      {
        start: '2:58',
        description:
          'Process creates a ransomeware web page and text file on the client IP',
      },
      {
        start: '3:43',
        description:
          'Client IP makes outbound connections to 94.23.172.0/24 over UDP port 6893',
      },
      {
        start: '4:13',
        description:
          'Child process cmd.exe spawns taskkill.exe to make its presence',
      },
      {
        start: '4:29',
        description:
          'Netsh.exe enables Windows firewall profiles (testing for Admin privilege), and later resets the firewall to less secure settings',
      },
      {
        start: '4:38',
        description: 'Process opens Notepad to create ransom note',
      },
      {
        start: '4:51',
        description:
          'Mshta.exe opens the ransom web page saved on the client IP',
      },
      {
        start: '5:04',
        description:
          'Multiple processes are called in parallel to a.exe which inject themselves into .dll files on the system',
      },
      {
        start: '7:12',
        description:
          'Cuckoo analysis of "dropped files" reveals details of the ransomware web page',
      },
      {
        start: '8:00',
        description:
          "The ransomware note contains details to decrypt the user's files",
      },
      {
        start: '8:20',
        description:
          'HTTP analysis exposes a domain as a location to purchase the decryption keys',
      },
      {
        start: '8:31',
        description:
          'Behavioral analysis reveals the same activity detected by esENDPOINT',
      },
      {
        start: '8:35',
        description: 'Summary behaviors collected for inspection and reporting',
      },
      {
        start: '8:51',
        end: '8:59',
        description:
          'Preview of ransomware page created for inspection and reporting',
      },
    ],
  };

  // Run the first animation
  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.hideRightArrow();
    AppActions.disableAppScreenshot();

    this.steps = [
      // Step 1
      () => {},
    ];

    this.animations = [
      // Step 1
      [],
    ];

    // Play the first animation
    this.play();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
    AppActions.showRightArrow();
    AppActions.enableAppScreenshot();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-23 timeline-slide ${className}`}>
        {children}
        <div className="content">
          <PlayerTimeline
            events={this.timeline.details}
            unit={this.timeline.unit}
            units={this.timeline.units}
            start={this.timeline.start}
          />
        </div>
      </div>
    );
  }
}
