import * as React from 'react';
import { render } from 'react-dom';
import { App } from './components/app';

const root =
  document.querySelector('#root') ||
  document.body.appendChild(document.createElement('div'));
root.id = 'root';

render(<App />, root);
