/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDataMoving } from '../players/data-moving';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Page } from '../svg/page';
import { Computer } from '../svg/computer';

@observer
export class Slide08 extends AnimatedSlide {
  // Animation players
  players = {
    computer: null,
    page: null,
  };

  // Run the first animation
  componentDidMount() {
    const { computer, page } = this.players;

    this.steps = [
      // Slide 1
      () => {
        // Set initial position of the page icon to line up with final step of 'slide-pcap-2'
        // page.style.transform = 'translateY(18px) translateX(-70px)';
        this.animateProgress(2000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        /*
        left: 468px;
        top: 398px;
        */
        {
          targets: page,
          opacity: 1,
          left: [468, 505, 540],
          top: [398, 300, 398],
          scale: [1, 0.6, 1],
          easing: 'easeInOutQuad',
          delay: 0,
          duration: 1000,
          begin: activate,
        },
        {
          targets: computer,
          opacity: 1,
          delay: 750,
          duration: 1000,
          begin: activate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-file-extraction ${className}`}>
        {children}
        <div className="content">
          {/* Page */}
          <div
            className="player page"
            data-active="1"
            ref={node => (this.players.page = node)}
          >
            <Page size={'45'} />
          </div>

          {/* Computer */}
          <div
            className="player computer"
            data-active="0"
            ref={node => (this.players.computer = node)}
          >
            <Computer size={'140'} />
          </div>
        </div>
      </div>
    );
  }
}
