/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Email extends Component {
  render() {
    const {
      className = '',
      color = '#C53D43',
      size = 74,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 74 51"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.3598 3.25L37.715 31.2965C37.4083 31.5448 37.0367 31.6681 36.6667 31.6681C36.295 31.6681 35.9233 31.5448 35.6167 31.2965L0.971904 3.25C0.368575 4.25499 0.000244141 5.41499 0.000244141 6.66831V43.3347C0.000244141 47.0114 2.99022 50.0014 6.66687 50.0014H66.6665C70.3414 50.0014 73.3331 47.0114 73.3331 43.3347V6.66831C73.3331 5.41499 72.9631 4.25499 72.3598 3.25Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.9576 0.904994C68.9827 0.344998 67.8693 0 66.666 0H6.6664C5.46308 0 4.34975 0.344998 3.37476 0.904994L36.6662 27.8565L69.9576 0.904994Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
