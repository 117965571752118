/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Phone } from '../svg/phone';
import { CheckCircle } from '../svg/check-circle';
// Other
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerDots } from '../players/dots';

@observer
export class Slide11 extends AnimatedSlide {
  // Animation players
  players = {
    analyst: null,
    client: null,
    phone: null,
    dots: null,
    check: null,
  };

  @observable clientColor = '#ffffff';

  // Run the first animation
  componentDidMount() {
    const { analyst, client, phone, dots } = this.players;

    this.steps = [
      () => {
        this.animateProgress(3000);
        ReactDOM.findDOMNode(analyst).style.transform = 'translateX(50px)';
        ReactDOM.findDOMNode(analyst).style.opacity = '0';
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {
        this.autoNext(500);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: analyst,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: 0,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: client,
          easing: 'easeOutQuad',
          opacity: 0,
          translateX: -50,
          duration: 500,
          delay: 0,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: client,
          easing: 'easeOutQuad',
          opacity: 1,
          translateX: 0,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: dots,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 4
      //
      [
        {
          targets: phone,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-11 ${className}`}>
        {children}
        <div className="content">
          {/* Phone */}
          <div
            className="player phone"
            data-active="0"
            ref={node => (this.players.phone = node)}
          >
            <Phone color={'#626466'} opacity={1} />
          </div>

          {/* Client */}
          <div
            className="player client"
            data-active="0"
            ref={node => (this.players.client = node)}
          >
            <Card
              IconComponent={Person}
              text={'CLIENT'}
              color={this.clientColor}
            />
          </div>

          {/* SOC Analyst */}
          <div
            className="player analyst"
            data-active="1"
            ref={node => (this.players.analyst = node)}
          >
            <Card
              IconComponent={Person}
              text={'SOC ANALYST'}
              color={'#ffffff'}
            />
          </div>

          {/* Animated grey  dots */}
          <div
            className="player dots"
            data-active="1"
            ref={node => (this.players.dots = node)}
          >
            <PlayerDots
              className={'white-dots small-dots in-out square-dots'}
            />
          </div>
        </div>
      </div>
    );
  }
}
