/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';

// SVG
import { Card } from '../svg/card';
import { Skull } from '../svg/skull';
import { ShieldKeys } from '../svg/shield-keys';
import { InternalNetwork } from '../svg/internal-network';

@observer
export class Slide03 extends AnimatedSlide {
  // Animation players
  players = {
    attacker: null,
    vpn: null,
    network: null,
    dots1: null,
    dots2: null,
    dots3: null,
    dots4: null,
  };

  // Run the first animation
  componentDidMount() {
    const { attacker, vpn, network, dots1, dots2, dots3, dots4 } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(3000);
        this.autoNext(200);
      },
      // Step 1 - show attacker
      () => {
        this.autoNext(200);
      },
      // Step 2 - show vpn
      () => {
        this.autoNext(200);
      },
      // Step 3 - show first dots
      () => {
        this.autoNext(200);
      },
      // Step 4 - show network
      () => {
        this.autoNext(200);
      },
      // Step 5 - show remaining dots
      () => {
        this.autoNext(200);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Do nothing
      ],

      //
      // Step 1 - attacker shows up
      //
      [
        {
          targets: attacker,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 2 - attacker moves left
      //
      [
        {
          targets: attacker,
          opacity: 1,
          delay: 0,
          translateX: -300,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3 - Show VPN & keys
      //
      [
        {
          targets: vpn,
          opacity: 1,
          delay: 0,
          translateX: 0,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 4 - Show dots
      //
      [
        {
          targets: dots1,
          opacity: 1,
          delay: 0,
          translateX: 0,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 5 - Show network
      //
      [
        {
          targets: network,
          opacity: 1,
          delay: 0,
          translateX: 0,
          duration: 500,
          begin: activateShimmer,
        },
      ],

      //
      // Step 6 - Show remaining dots
      //
      [
        {
          targets: dots2,
          opacity: 1,
          delay: 0,
          translateX: 0,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: dots4,
          opacity: 1,
          delay: 500,
          translateX: 0,
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: dots3,
          opacity: 1,
          delay: 1000,
          translateX: 0,
          duration: 500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-03 ${className}`}>
        {children}
        <div className="content">
          {/* Attacker */}
          <div
            className="player attacker"
            data-active="0"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text={'Attacker'} />
          </div>

          {/* Shield with keys */}
          <div
            className="player vpn"
            data-active="0"
            ref={node => (this.players.vpn = node)}
          >
            <ShieldKeys key1={this.colors.blue} key2={false} />
          </div>

          {/* dots 1 */}
          <div
            className="player dots1"
            data-active="0"
            ref={node => (this.players.dots1 = node)}
          >
            <PlayerDots
              color={this.colors.red}
              width={160}
              dots={10}
              className={'in-out'}
            />
          </div>

          {/* Network */}
          <div
            className="player network"
            data-active="0"
            ref={node => (this.players.network = node)}
          >
            <InternalNetwork />
          </div>

          {/* dots 2 */}
          <div
            className="player dots2"
            data-active="0"
            ref={node => (this.players.dots2 = node)}
          >
            <PlayerDots
              color={this.colors.red}
              width={160}
              dots={10}
              className={'in-out'}
            />
          </div>

          {/* dots 3 */}
          <div
            className="player dots3"
            data-active="0"
            ref={node => (this.players.dots3 = node)}
          >
            <PlayerDots
              color={this.colors.blue}
              width={160}
              dots={10}
              className={'in-out-reverse'}
            />
          </div>

          {/* dots 4 */}
          <div
            className="player dots4"
            data-active="0"
            ref={node => (this.players.dots4 = node)}
          >
            <PlayerDots
              color={this.colors.blue}
              width={160}
              dots={10}
              className={'in-out-reverse'}
            />
          </div>
        </div>
      </div>
    );
  }
}
