/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Greynoise extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 19,
      x = 0,
      y = 0,
      opacity = 1,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g opacity={opacity} fill={color}>
          <path d="M50.6 30C50.6 33.7338 49.2608 37.3438 46.8256 40.1743C44.3904 43.0047 41.0207 44.8678 37.3287 45.4252C33.6367 45.9826 29.8673 45.1973 26.705 43.2119C23.5428 41.2265 21.1975 38.1727 20.0953 34.6053C18.993 31.0379 19.2068 27.1934 20.6979 23.7702C22.189 20.3471 24.8584 17.5722 28.2213 15.9498C31.5842 14.3273 35.4175 13.9648 39.0249 14.9282C42.6324 15.8916 45.7746 18.1168 47.8809 21.1999L46.6016 22.0739C44.7045 19.2971 41.8743 17.2928 38.6252 16.4251C35.3761 15.5574 31.9235 15.8839 28.8946 17.3452C25.8657 18.8065 23.4614 21.3058 22.1184 24.389C20.7754 27.4722 20.5828 30.9348 21.5756 34.1479C22.5684 37.361 24.6807 40.1115 27.5289 41.8997C30.3771 43.6879 33.7721 44.3952 37.0975 43.8932C40.4228 43.3912 43.4577 41.7131 45.6511 39.1638C47.8444 36.6145 49.0506 33.363 49.0506 30H50.6Z" />
          <path d="M55.4 30C55.4 33.3767 54.5618 36.7006 52.9606 39.6735C51.3594 42.6464 49.0453 45.1754 46.2258 47.0335C43.4063 48.8917 40.1697 50.0209 36.8063 50.3199C33.4429 50.6189 30.0578 50.0783 26.9548 48.7466L27.4828 47.5162C30.3822 48.7604 33.5451 49.2655 36.6877 48.9862C39.8304 48.7068 42.8546 47.6517 45.489 45.9155C48.1234 44.1793 50.2856 41.8164 51.7817 39.0386C53.2779 36.2608 54.061 33.1551 54.061 30H55.4Z" />
          <path d="M16.2742 38.0935C14.9346 34.9942 14.3853 31.6108 14.6755 28.2468C14.9656 24.8829 16.0862 21.6435 17.9368 18.8194C19.7873 15.9952 22.3099 13.6745 25.2783 12.0654C28.2467 10.4564 31.5681 9.60927 34.9445 9.60008L34.9481 10.939C31.7933 10.9476 28.6899 11.7391 25.9164 13.2426C23.1428 14.746 20.7858 16.9144 19.0567 19.5532C17.3277 22.192 16.2806 25.2187 16.0095 28.3619C15.7384 31.5051 16.2516 34.6664 17.5033 37.5623L16.2742 38.0935Z" />
          <path d="M45.8648 52.7375C42.3428 54.4205 38.478 55.2622 34.5751 55.1964C30.6722 55.1306 26.838 54.159 23.3747 52.3583C19.9114 50.5575 16.9138 47.9769 14.6183 44.8198C12.3227 41.6627 10.792 38.0154 10.1467 34.1657L11.5103 33.9371C12.1202 37.5757 13.5669 41.0228 15.7366 44.0066C17.9062 46.9905 20.7393 49.4296 24.0125 51.1315C27.2858 52.8335 30.9096 53.7518 34.5984 53.814C38.2871 53.8762 41.9399 53.0806 45.2687 51.49L45.8648 52.7375Z" />
          <path d="M9.90584 27.6928C10.296 23.449 11.7561 19.3735 14.1495 15.8474C16.5429 12.3213 19.7915 9.45972 23.5914 7.53037C27.3914 5.60102 31.6185 4.66688 35.8776 4.8153C40.1367 4.96372 44.2886 6.18985 47.945 8.37904L47.2348 9.56531C43.779 7.49624 39.8549 6.33737 35.8295 6.1971C31.8041 6.05682 27.8088 6.93971 24.2174 8.76321C20.6259 10.5867 17.5556 13.2912 15.2935 16.6239C13.0314 19.9565 11.6515 23.8084 11.2827 27.8194L9.90584 27.6928Z" />
          <path d="M38.1913 59.8298C33.4953 60.3322 28.747 59.7188 24.3328 58.0395C19.9187 56.3602 15.9634 53.6624 12.7887 50.1658C9.61408 46.6691 7.30984 42.4724 6.0635 37.917C4.81715 33.3616 4.66392 28.5763 5.61626 23.9505L7.22845 24.2825C6.32836 28.6544 6.47318 33.1772 7.65115 37.4826C8.82911 41.788 11.0069 45.7546 14.0074 49.0594C17.0078 52.3642 20.7461 54.9139 24.9181 56.501C29.0901 58.0882 33.5778 58.6679 38.0162 58.1931L38.1913 59.8298Z" />
          <path d="M14.0911 8.48678C18.2276 4.46647 23.4355 1.72394 29.0908 0.587739C34.7462 -0.548464 40.6091 -0.0301621 45.9774 2.08055L45.3751 3.6124C40.3014 1.61749 34.7601 1.12763 29.415 2.20149C24.07 3.27536 19.1479 5.86741 15.2383 9.66714L14.0911 8.48678Z" />
          <path d="M39.8 30H49.1V31.5H39.8V30Z" />
        </g>
      </svg>
    );
  }
}
