/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { Router, RouteManager } from 'vega-router';
import {
  KillchainActions,
  KillchainState,
  KILLCHAIN_COVERAGE,
  KILLCHAIN_OUTCOMES,
} from '../killchain-actions';
import { BackArrow } from '../../svg/back-arrow';
import { ArrowRight } from '../../svg/arrow-right';
import { DotIndicator } from '../../dot-indicator';
import './outcomes.scss';

@observer
export class KillchainOutcomes extends Router {
  componentDidMount() {
    KillchainState.title = 'Outcomes & Risks';
    KillchainState.subtitle = KillchainState.unselectedProducts
      .map(product => `No ${product.label}`)
      .join(', ');
  }

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`KillchainOutcomes ${className}`}>
        <div className="KillchainOutcomes__Header">
          <div
            className="KillchainOutcomes__Back"
            onClick={() => RouteManager.transitionTo('/killchain')}
          >
            <BackArrow size={24} opacity={0.5} /> Back to Killchain Overview
          </div>
        </div>
        {KillchainState.unselectedProducts.map((product, i) => (
          <div className="KillchainOutcomes__Section" key={i}>
            <div className="KillchainOutcomes__OutcomeSection">
              <div className="KillchainOutcomes__White">
                <h2>
                  {product.label}
                  <br />
                  Unique Outcomes &amp; Risks
                </h2>
                <ul>
                  {KILLCHAIN_OUTCOMES[product.value].outcomes.map((text, j) => (
                    <li key={j}>{text}</li>
                  ))}
                </ul>
              </div>
              <div className="KillchainOutcomes__Arrow">
                <ArrowRight bold size={70} color="#f05938" />
              </div>
              <div className="KillchainOutcomes__Orange">
                <h2>
                  Degrades MDR
                  <br />
                  Capabilities Against
                </h2>
                <ul>
                  {KILLCHAIN_OUTCOMES[product.value].capabilities.map(
                    (text, j) => {
                      if (Array.isArray(text)) {
                        return (
                          <ul key={j}>
                            {text.map((text, k) => (
                              <li key={k}>{text}</li>
                            ))}
                          </ul>
                        );
                      }

                      return <li key={j}>{text}</li>;
                    }
                  )}
                </ul>
              </div>
            </div>
            {KILLCHAIN_COVERAGE[product.value] ? (
              <div className="KillchainOutcomes__CoverageSection">
                <div className="KillchainOutcomes__CoverageTitle">
                  {product.label} MITRE ATT&amp;CK COVERAGE
                </div>
                <div className="KillchainOutcomes__CoverageSubtitle">
                  Complements existing security controls coverage if applicable
                </div>
                <div className="KillchainOutcomes__CoverageGrid">
                  <div className="KillchainOutcomes__CoverageHeader">Stage</div>
                  <div className="KillchainOutcomes__CoverageHeader">
                    Coverage
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Initial Access
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={KILLCHAIN_COVERAGE[product.value].initialAccess}
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Execution
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={KILLCHAIN_COVERAGE[product.value].execution}
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Persistence
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={KILLCHAIN_COVERAGE[product.value].persistence}
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Privilege Escalation
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={
                        KILLCHAIN_COVERAGE[product.value].privilegeEscalation
                      }
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Defense Evasion
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={KILLCHAIN_COVERAGE[product.value].defenseEvasion}
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Credential Access
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={KILLCHAIN_COVERAGE[product.value].credentialAccess}
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Discovery
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={KILLCHAIN_COVERAGE[product.value].discovery}
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Lateral Movement
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={KILLCHAIN_COVERAGE[product.value].lateralMovement}
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Collection
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={KILLCHAIN_COVERAGE[product.value].collection}
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Exfiltration
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={KILLCHAIN_COVERAGE[product.value].exfiltration}
                    />
                  </div>
                  <div className="KillchainOutcomes__CoverageLabel">
                    Command and Control
                  </div>
                  <div className="KillchainOutcomes__CoverageValue">
                    <DotIndicator
                      value={
                        KILLCHAIN_COVERAGE[product.value].commandAndControl
                      }
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="KillchainOutcomes__SectionDivider" />
            )}
          </div>
        ))}
      </div>
    );
  }
}
