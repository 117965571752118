/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './timeline.scss';
import { Skull } from '../svg/skull';
import { observable } from 'mobx';
import anime from 'animejs';
import { isJsxOpeningElement } from 'typescript';

@observer
export class PlayerTimeline extends Component {
  @observable selectedEventIndex = 0;
  eventsContainerElement = null;
  eventsScrollerElement = null;

  // The timeline's width in pixels
  events = [];
  windowWidth = 500;
  timelineWidth = 500;
  minuteStart = 0;
  minuteEnd = 0;
  unit = 'day';
  label = 'DAYS';
  pixelsPerMinute = 1;
  minBubbleWidth = 2; // Minimum pixel width for a bubble
  timelineColumns = null;
  alternate = false;

  // Convert MM:HH to decimal
  timeToDecimal(timeString) {
    // Timeline format requirement
    // 00:00:00:00
    // DAYS : HOURS : MINUTES : SECONDS
    let arr = timeString.split(':').reverse();

    // Break down the time
    let seconds = parseFloat(typeof arr[0] !== 'undefined' ? arr[0] : 0);
    let minutes = parseFloat(typeof arr[1] !== 'undefined' ? arr[1] : 0);
    let hours = parseFloat(typeof arr[2] !== 'undefined' ? arr[2] : 0);
    let days = parseFloat(typeof arr[3] !== 'undefined' ? arr[3] : 0);

    let s = parseInt((seconds / 6) * 10, 10) / 100;

    let totalMinutes = parseFloat(days * 24 * 60 + hours * 60 + minutes + s);

    return totalMinutes;
  }

  handleClickEvent = eventIndex => () => {
    this.selectedEventIndex = eventIndex;

    // TODO: Animate left scrolling here
    anime({
      targets: this.timelineColumns,
      translateX: this.events[eventIndex].translateX,
      duration: 1000,
      easing: 'easeOutQuad',
    });
  };

  isEven(value) {
    if (value % 2 == 0) return true;
    return false;
  }

  handleClickBubble = eventIndex => () => {
    // If a new event was selected, scroll the events list to display the selected event
    if (this.eventsContainerElement && this.selectedEventIndex !== eventIndex) {
      const avgItemHeight = this.props.events.length
        ? this.eventsScrollerElement.clientHeight / this.props.events.length
        : 0;
      const newScrollTop =
        avgItemHeight * eventIndex -
        this.eventsContainerElement.clientHeight / 2;

      // TODO: Luke, you could probably make this smooth scroll using the animation lib
      this.eventsContainerElement.scrollTop = newScrollTop;
    }

    this.selectedEventIndex = eventIndex;
  };

  renderDescription(value) {
    if (typeof value === 'string') {
      return value;
    }
    if (value instanceof Array) {
      return value.join(<span className="Description__Spacer" />);
    }
  }

  renderEvents(events) {
    let eventList = [];
    events.forEach((value, index) => {
      let selectedClass = index === this.selectedEventIndex ? 'active' : '';
      eventList.push(
        <div
          key={index}
          className={`Event ${selectedClass}`}
          onClick={this.handleClickEvent(index)}
        >
          <div className={'Event__Time'}>{value.start.substr(0, 8)}</div>
          <div className={'Event__Description'}>
            {this.renderDescription(value.description)}
          </div>
        </div>
      );
    });
    return (
      <div
        className={'Timeline__Events'}
        ref={node => (this.eventsContainerElement = node)}
      >
        <div
          className={'Timeline__Events__Scroller'}
          ref={node => (this.eventsScrollerElement = node)}
        >
          {eventList}
        </div>
      </div>
    );
  }

  renderBubbles() {
    let bubbles = [];

    let bubbleWindow = 540;
    let bubblePadding = 10;
    let bubbleHeight =
      (bubbleWindow - this.events.length * bubblePadding) / this.events.length;

    // Loop through every event
    this.events.forEach((value, index) => {
      // Get starting minute
      let minStart =
        parseFloat(this.timeToDecimal(value.start)) - this.minuteStart;

      // Get the ending minute
      let next =
        typeof this.events[index + 1] !== 'undefined'
          ? this.events[index + 1]
          : value;
      let endText = typeof value.end == 'undefined' ? next.start : value.end;
      let minEnd = parseFloat(this.timeToDecimal(endText)) - this.minuteStart;

      // Get starting x position for the bubble
      let startX = minStart * this.pixelsPerMinute;
      let width = (minEnd - minStart) * this.pixelsPerMinute;

      // Give each bubble a minimum width
      if (width < this.minBubbleWidth) width = this.minBubbleWidth;

      // Is it selected?
      let selectedClass = index === this.selectedEventIndex ? 'active' : '';

      // Save the starting X and width to the event
      this.events[index].left = startX;
      this.events[index].width = width;
      this.events[index].translateX =
        (this.timelineWidth - this.windowWidth) *
        (startX / this.timelineWidth) *
        -1;

      // Add bubble
      bubbles.push(
        <div
          key={index}
          className={`timeline-series-bubble ${selectedClass}`}
          style={{ height: bubbleHeight + 'px' }}
        >
          <a
            style={{
              left: startX + 'px',
              width: width + 'px',
            }}
            onClick={this.handleClickBubble(index)}
          />
        </div>
      );
    });

    // Return a bubble
    return (
      <div className={'timeline-series'}>
        <div className={'timeline-series-bubbles'}>{bubbles}</div>
      </div>
    );
  }

  renderColumns(start, units, alternate) {
    let lines = [];
    let width = this.timelineWidth / (units - start);
    for (let i = start; i <= units; i++) {
      if (alternate && !this.isEven(i)) {
        continue;
      }
      let left = width * (i - start);
      lines.push(
        <div
          key={i}
          className="timeline-points-point"
          style={{
            left: left + 'px',
          }}
        >
          <span>
            <b>{i}</b> {this.label}
          </span>
        </div>
      );
    }
    return <div className="timeline-points">{lines}</div>;
  }

  setParameters(start, units) {
    switch (this.unit.toLowerCase()) {
      case 'minute' || 'min' || 'm':
        this.minuteStart = start;
        this.minuteEnd = units;
        this.label = 'MINS';
        break;
      case 'hour' || 'h':
        this.minuteStart = start * 60;
        this.minuteEnd = units * 60;
        this.label = 'HRS';
        break;
      case 'day' || 'd':
        this.minuteStart = start * 1440;
        this.minuteEnd = units * 1440;
        this.label = 'DAYS';
        break;
    }
    this.pixelsPerMinute = parseFloat(
      this.timelineWidth / (this.minuteEnd - this.minuteStart)
    );
  }

  render() {
    const {
      children,
      unit = 'minute', // day, hour, minute
      units = 10, // Number of units
      start = 0, // Start on unit
      alternate = false,
      width = 500,
      events = [],
    } = this.props;

    // Save info
    this.events = events;
    this.alternate = alternate;
    this.timelineWidth = width;
    this.unit = unit.toLowerCase();

    // Set the stage
    this.setParameters(start, units);

    return (
      <div className="Timeline">
        {/* List of timeline events */}
        <div className={'player events'} data-active="1">
          <h1>Events</h1>
          {this.renderEvents(events)}
        </div>

        {/* Timeline */}
        <div className="Timeline__Scrolling">
          <div className="player timeline" data-active="1">
            <div
              className="timeline-columns"
              style={{ width: width + 'px' }}
              ref={node => (this.timelineColumns = node)}
            >
              {this.renderBubbles()}
              {this.renderColumns(start, units, alternate)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
