/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';
import { Alert } from '../svg/alert';

// Icons
import { Directory } from '../svg/directory';
import { InternetExplorer } from '../svg/internet-explorer';
import { Word } from '../svg/word';
import { PowerShell } from '../svg/power-shell';
import { CMD } from '../svg/cmd';

@observer
export class Slide13 extends AnimatedSlide {
  // Animation players
  players = {
    process1: null,
    process2: null,
    process3: null,
    local: null,
    remote: null,
  };

  // Run the first animation
  componentDidMount() {
    const { process1, process2, process3, local, remote } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
        this.autoNext(1500);
      },

      // Step 2
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: process1,
          opacity: 1,
          delay: 0,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: process2,
          opacity: 1,
          delay: 300,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: process3,
          opacity: 1,
          delay: 600,
          duration: 200,
          begin: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: local,
          opacity: 1,
          delay: 0,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: remote,
          opacity: 1,
          delay: 500,
          duration: 200,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-13 ${className}`}>
        {children}
        <div className="content">
          <div
            className="player remote"
            data-active="0"
            ref={node => (this.players.remote = node)}
          >
            <PlayerTextBlock
              title={'Remote IP'}
              width={'240px'}
              sections={[
                {
                  title: ['IP:', '10.1.108.5', 'PORT: ', '48973'],
                  grid: 2,
                },
              ]}
            />
          </div>

          {/* Process text box */}
          <div
            className="player local"
            data-active="0"
            ref={node => (this.players.local = node)}
          >
            <PlayerTextBlock
              title={'Local IP'}
              sections={[
                {
                  title: ['IP:', '192.168.10.27', 'PORT: ', '3389'],
                  grid: 2,
                },
              ]}
            />
          </div>

          {/* userinit.exe */}
          <div
            className="player process1"
            data-active="0"
            ref={node => (this.players.process1 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={CMD}
              title={'userinit.exe'}
              align={'bottom'}
            />
          </div>

          {/* Ntoskml.exe */}
          <div
            className="player process2"
            data-active="0"
            ref={node => (this.players.process2 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={CMD}
              title={'Ntoskml.exe'}
              align={'bottom'}
            />
          </div>

          {/* explorer.exe */}
          <div
            className="player process3"
            data-active="0"
            ref={node => (this.players.process3 = node)}
          >
            <PlayerCircleIcon
              color={'#50C2E3'}
              Icon={Directory}
              title={'explorer.exe'}
              align={'bottom'}
            />
          </div>
        </div>
      </div>
    );
  }
}
