/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class DropDown extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 11,
      x = 0,
      y = 0,
      opacity = 0.4,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 11 5"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.94078 0L5.25801 2.16846L2.57524 0L0 0L5.25801 4.25L10.516 0L7.94078 0Z"
          fill={color}
        />
      </svg>
    );
  }
}
