/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { Router, RouteManager } from 'vega-router';
import { KillchainState } from './killchain-actions';
import { KillchainOverview } from './overview/overview';
import { KillchainOutcomes } from './outcomes/outcomes';
import { AppActions } from '../app-actions';
import { HamburgerIcon } from '../svg/hamburger-icon';
import { EsentireIcon } from '../svg/esentire-icon';
import { ChartDataStore } from '../../data/compliance-alignment-data-transform';
import { Eye } from '../svg/eye';
import './killchain.scss';

@observer
export class Killchain extends Router {
  constructor(props) {
    super(props);

    this.contentFrame = null;
    this.contentFrameContainer = null;

    this.addRoute('outcomes', /^\/killchain\/outcomes/, KillchainOutcomes);

    this.contentFrame = null;
    this.contentFrameContainer = null;

    // Must be last
    this.addRoute('summary', /^\/(.*)/, KillchainOverview);
  }

  componentDidMount() {
    AppActions.setShortcutName('Killchain');
    AppActions.hideBackgroundGrid();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
  }

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`Killchain ${className}`}>
        <div className="Killchain__MainMenu">
          <button
            className="HamburgerButton"
            onClick={() => RouteManager.transitionTo('/')}
          >
            <HamburgerIcon color="#fafafa" opacity={0.7} />
          </button>
        </div>
        {this.route && this.route.name === 'summary' && (
          <div className="Killchain__BackgroundColor" />
        )}
        {this.route &&
          this.route.name === 'summary' &&
          KillchainState.unselectedProducts.length > 0 && (
            <div
              className="Killchain__OutcomesLink"
              onClick={() => RouteManager.transitionTo('/killchain/outcomes')}
            >
              <Eye size={24} /> Outcomes &amp; Risks
            </div>
          )}
        <div className="Killchain__Logo">
          <EsentireIcon />
        </div>
        <div className="Killchain__Title">{KillchainState.title}</div>
        <div className="Killchain__Subtitle">{KillchainState.subtitle}</div>
        {ChartDataStore.loadingKillchainData ? (
          <div className="Killchain__Middle__Loader">
            <div className="Killchain__Middle__Spinner" />
            <div className="Killchain__Middle__LoaderLabel">Loading Data</div>
          </div>
        ) : (
          <div className="Killchain__Middle FadeIn">{super.render()}</div>
        )}
      </div>
    );
  }
}
