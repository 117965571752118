/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import { Router, RouteManager } from 'vega-router';
import {
  ComplianceAlignmentActions,
  ComplianceAlignmentState,
} from '../compliance-alignment-actions';
import { ArrowRight } from '../../svg/arrow-right';
import { Eye } from '../../svg/eye';
import { FingerPrint } from '../../svg/finger-print';
import { Bell } from '../../svg/bell';
import { CheckShield } from '../../svg/check-shield';
import { Refresh } from '../../svg/refresh';
import { CheckCircle } from '../../svg/check-circle';
import './summary.scss';

/**
 * We have old spreadsheets we parsed to produce correct associations between
 * data. The safest way to produce different titles will be transforming the
 * labels on the fly.
 */
function getProductName(name) {
  switch (name.toLocaleLowerCase()) {
    case 'esendpoint':
      return 'eSentire MDR for Endpoint';
    case 'esnetwork':
      return 'eSentire MDR for Network';
    case 'eslog+':
      return 'eSentire MDR for Log';
    case 'esinsider':
      return 'eSentire MDR for Insider Threat';
    case 'escloud':
      return 'eSentire MDR for Cloud';

    default:
      return name;
  }
}

@observer
export class ComplianceAlignmentSummary extends Router {
  componentDidMount() {
    ComplianceAlignmentState.title = 'Compliance Alignment';
    ComplianceAlignmentState.subtitle = 'Coverage Selection';
  }

  isSelected(item, selected) {
    return (
      item && selected && selected.find(selected => selected.key === item.key)
    );
  }

  isActive(item, active) {
    return (
      !this.isDisabled(item, active) && ComplianceAlignmentState.isFiltering
    );
  }

  isDisabled(item, active) {
    return !item || !active || !active.find(active => active.key === item.key);
  }

  render() {
    const { className = '' } = this.props;

    const { data = {} } = ComplianceAlignmentState;
    const functions = Object.values(data.functions || {});
    const products = Object.values(data.products || {});
    const coverages = Object.values(data.coverages || {});

    return (
      <div className={`ComplianceAlignmentSummary ${className}`}>
        <div className="ComplianceAlignmentSummary__Functions">
          <div className="ComplianceAlignmentSummary__Header">Functions</div>
          {functions.map(item => {
            let icon = null;
            switch (item.key) {
              case 'identify':
                icon = <Eye size={30} />;
                break;
              case 'protect':
                icon = <FingerPrint size={30} />;
                break;
              case 'detect':
                icon = <Bell size={30} />;
                break;
              case 'respond':
                icon = <Refresh size={30} />;
                break;
              case 'recover':
                icon = <CheckShield size={30} />;
                break;
            }
            return (
              <div
                className={`ComplianceAlignmentSummary__FunctionsItem ${
                  this.isSelected(
                    item,
                    ComplianceAlignmentState.selectedFunctions
                  )
                    ? 'ComplianceAlignmentSummary__FunctionsItem--selected'
                    : ''
                } ${
                  this.isActive(item, ComplianceAlignmentState.activeFunctions)
                    ? 'ComplianceAlignmentSummary__FunctionsItem--active'
                    : ''
                } ${
                  this.isDisabled(
                    item,
                    ComplianceAlignmentState.activeFunctions
                  )
                    ? 'ComplianceAlignmentSummary__FunctionsItem--disabled'
                    : ''
                } ComplianceAlignmentSummary__FunctionsItem--${item.key}`}
                onClick={() =>
                  ComplianceAlignmentActions.updateSelectedItems(
                    'selectedFunctions',
                    item
                  )
                }
                key={item.key}
              >
                {icon}
                {item.name.toLowerCase()}
              </div>
            );
          })}
        </div>
        <div className="ComplianceAlignmentSummary__Product">
          <div className="ComplianceAlignmentSummary__Header">Product</div>
          {products.map(item => {
            return (
              <div
                className={`ComplianceAlignmentSummary__ProductItem ${
                  this.isSelected(
                    item,
                    ComplianceAlignmentState.selectedProducts
                  )
                    ? 'ComplianceAlignmentSummary__ProductItem--selected'
                    : ''
                } ${
                  this.isActive(item, ComplianceAlignmentState.activeProducts)
                    ? 'ComplianceAlignmentSummary__ProductItem--active'
                    : ''
                } ${
                  this.isDisabled(item, ComplianceAlignmentState.activeProducts)
                    ? 'ComplianceAlignmentSummary__ProductItem--disabled'
                    : ''
                }`}
                onClick={() =>
                  ComplianceAlignmentActions.updateSelectedItems(
                    'selectedProducts',
                    item
                  )
                }
                key={item.key}
              >
                {getProductName(item.name)}
              </div>
            );
          })}
        </div>
        <div className="ComplianceAlignmentSummary__Coverage">
          <div className="ComplianceAlignmentSummary__Header">
            Compliance Coverage
          </div>
          {coverages.map(item => {
            const isSelected = this.isSelected(
              item,
              ComplianceAlignmentState.selectedCoverages
            );
            const isActive = this.isActive(
              item,
              ComplianceAlignmentState.activeCoverages
            );
            const isDisabled = this.isDisabled(
              item,
              ComplianceAlignmentState.activeCoverages
            );
            const data = [{ value: item.percent }, { value: 1 - item.percent }];

            return (
              <div
                className={`ComplianceAlignmentSummary__CoverageItem ${
                  isSelected
                    ? 'ComplianceAlignmentSummary__CoverageItem--selected'
                    : ''
                } ${
                  isActive
                    ? 'ComplianceAlignmentSummary__CoverageItem--active'
                    : ''
                } ${
                  isDisabled
                    ? 'ComplianceAlignmentSummary__CoverageItem--disabled'
                    : ''
                }`}
                onClick={() =>
                  ComplianceAlignmentActions.updateSelectedItems(
                    'selectedCoverages',
                    item
                  )
                }
                key={item.key}
              >
                {item.name}
                {isActive && !isSelected && (
                  <div className="ComplianceAlignmentSummary__CoveragePercent">
                    {item.percent < 1 ? (
                      <PieChart
                        className="ComplianceAlignmentSummary__CoveragePie"
                        height={16}
                        width={16}
                      >
                        <Pie
                          dataKey="value"
                          outerRadius={8}
                          innerRadius={6}
                          data={[{ value: 1 }]}
                          fill="rgba(255, 255, 255, 0.2)"
                          stroke="none"
                          isAnimationActive={false}
                        ></Pie>
                        <Pie
                          dataKey="value"
                          outerRadius={8}
                          innerRadius={6}
                          data={data}
                          startAngle={450}
                          endAngle={90}
                        >
                          {data.map((item, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={index > 0 ? 'rgba(0, 0, 0, 0)' : '#50C2E3'}
                              stroke="none"
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    ) : (
                      <CheckCircle
                        background="white"
                        className="ComplianceAlignmentSummary__CoverageCheck"
                        size={16}
                        color="#54D5AE"
                      />
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div
          className={`ComplianceAlignmentSummary__Bottom ${
            ComplianceAlignmentState.isFiltering
              ? 'ComplianceAlignmentSummary__Bottom--visible'
              : ''
          }`}
        >
          <div className="ComplianceAlignmentSummary__BottomTitle">
            Coverage Summary
          </div>
          <div className="ComplianceAlignmentSummary__BottomItem">
            Functions
            <div className="ComplianceAlignmentSummary__BottomItemCount ComplianceAlignmentSummary__BottomItemCount--functions">
              {ComplianceAlignmentState.activeFunctions.length}
            </div>
          </div>
          <div className="ComplianceAlignmentSummary__BottomItem">
            Products
            <div className="ComplianceAlignmentSummary__BottomItemCount ComplianceAlignmentSummary__BottomItemCount--products">
              {ComplianceAlignmentState.activeProducts.length}
            </div>
          </div>
          <div className="ComplianceAlignmentSummary__BottomItem">
            Compliance Coverage
            <div className="ComplianceAlignmentSummary__BottomItemCount ComplianceAlignmentSummary__BottomItemCount--coverages">
              {ComplianceAlignmentState.activeCoverageValues.length}
            </div>
          </div>
          <div
            className="ComplianceAlignmentSummary__BottomDetail"
            onClick={() => RouteManager.transitionTo('/compliance/detail')}
          >
            View Coverage Detail{' '}
            <ArrowRight size={12} color="#fff" opacity={1} />
          </div>
        </div>
      </div>
    );
  }
}
