/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Template__TeamScanningCustomers } from '../../templates/team-scanning-customers';

import { Plugin } from '../svg/plugin';
import { MachineLearning } from '../svg/machine-learning';

@observer
export class Slide10 extends Component {
  componentDidMount() {
    // Do something
  }

  render() {
    const { children } = this.props;
    return (
      <div className={`slide-10`}>
        {children}
        <Template__TeamScanningCustomers
          shield={{
            icon: MachineLearning,
            text: 'MVS TEAM',
          }}
          product={{
            icon: Plugin,
            title: 'LOG4J CVE PLUGINS',
            left: 5,
            top: -2,
          }}
          scanner={{
            random: true,
            remediate: true,
            duration: 6000,
          }}
        />
      </div>
    );
  }
}
