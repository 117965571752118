/** @jsx createElement */
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import anime from 'animejs';
import { createElement, Component, HTMLAttributes } from 'react';
import './progress-pie.scss';

@observer
export class PlayerProgressPie extends Component {
  defaults = {
    background: '#ffffff',
    size: 75,
    stroke: 4,
    progress: 0,
  };

  render() {
    const {
      background = this.defaults.background,
      color = this.defaults.color,
      size = this.defaults.size,
      stroke = this.defaults.stroke,
      progress = this.defaults.progress,
    } = this.props;

    // Get the radius
    let radius = size / 2;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;

    const strokeDashoffset =
      this.circumference - (progress / 100) * this.circumference;

    return (
      <svg className={'progress-pie'} height={size} width={size}>
        <circle
          stroke={background}
          opacity={0.2}
          className="background"
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + ' ' + this.circumference}
          style={{ strokeDashoffset: 0 }}
          strokeWidth={stroke}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke={color}
          fill="transparent"
          strokeWidth={stroke}
          strokeDasharray={this.circumference + ' ' + this.circumference}
          style={{ strokeDashoffset }}
          strokeWidth={stroke}
          r={this.normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    );
  }
}
