/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { Warning } from '../svg/warning';
// Other
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerCircleIcon } from '../players/circle-icon';

@observer
export class Slide19 extends AnimatedSlide {
  // Animation players
  players = {
    warning: null,
    threat: null,
    info: null,
    client: null,
  };

  // Run the first animation
  componentDidMount() {
    const { warning, threat, info, client } = this.players;

    this.steps = [
      () => {
        this.animateProgress(7000);
        this.autoNext(2000);
      },
      () => {
        this.autoNext(2000);
      },
      () => {
        this.autoNext(2000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        {
          targets: warning,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 0,
          begin: activate,
        },
        {
          targets: threat,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 500,
          begin: activateShimmer,
        },
        {
          targets: info,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },
        {
          targets: client,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 1500,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-19 ${className}`}>
        {children}
        <div className="content">
          {/* Permanent filters */}
          <div
            className="player threat"
            data-active="0"
            ref={node => (this.players.threat = node)}
          >
            <PlayerTextBlock
              title={'Threat Virus Alert'}
              width={'250px'}
              showDots={true}
              className={'red-top'}
              sections={[
                {
                  title: ['ESNTR (CYCLOPS-9107)'],
                  color: 'red',
                  colorCells: 1,
                },
              ]}
            />
          </div>

          {/* Info */}
          <div
            className="player info"
            data-active="0"
            ref={node => (this.players.info = node)}
          >
            <PlayerTextBlock
              title={'Info'}
              width={'250px'}
              className={'red-top'}
              sections={[
                {
                  title: ['AUTO ESCALATION ALERT'],
                  color: 'red',
                  colorCells: 1,
                },
                {
                  title: ['IP', '10.0.5.38', 'IP', '45.40.148.106'],
                  grid: 2,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Client */}
          <div
            className="player client"
            data-active="0"
            ref={node => (this.players.client = node)}
          >
            <PlayerTextBlock
              title={'Client status'}
              className={'orange-top'}
              sections={[
                {
                  title: ['Awaiting Confirmation'],
                  color: 'orange',
                  colorCells: 1,
                },
              ]}
            />
          </div>

          {/* Warning icon */}
          <div
            className="player warning"
            data-active="1"
            ref={node => (this.players.warning = node)}
          >
            <PlayerCircleIcon
              showTitle={false}
              Icon={Warning}
              IconSize={60}
              color={'#C53D43'}
            />
          </div>
        </div>
      </div>
    );
  }
}
