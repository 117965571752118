/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Page } from './page';

@observer
export class Alert extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#C53D43',
      title = 'Warning',
      subtitle = 'Suspicious File Detected',
      line2 = null,
      IconComponent = Page,
      fill = null,
    } = this.props;

    const iconWidth = 100;

    // Do some offset calculations
    // to make the alert border expand to fit 2nd subtitle if needed
    const offset = line2 ? 30 : 0;
    const height = 235 + offset;
    const svgPathData = `M113 79H5V${230 + offset}.5H344V79H236`;
    const svgFillHeight = 141 + offset;

    return (
      <svg
        className={className}
        width="349"
        height={height}
        viewBox={`0 0 349 ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity={'0'}
          className={'border'}
          strokeDasharray={'0'}
          style={{ strokeDashoffset: '0' }}
          d={svgPathData}
          stroke={color}
          strokeWidth="10"
        />
        {fill && (
          <rect
            {...{
              x: '10',
              y: '84',
              width: '328',
              height: svgFillHeight,
              fill,
            }}
          />
        )}
        <g transform={`translate(${-iconWidth / 2})`}>
          <svg x="50%" y={20}>
            <IconComponent size={100} color="white" />
          </svg>
        </g>
        <text
          x="50%"
          y="150"
          fill={color}
          fontSize="16px"
          fontFamily="Blender Heavy"
          letterSpacing="3px"
          textAnchor="middle"
        >
          {title}
        </text>
        <text
          x="50%"
          y="185"
          fill="white"
          fontSize="20px"
          fontFamily="Blender Thin"
          letterSpacing="3px"
          textAnchor="middle"
        >
          {subtitle}
        </text>
        {line2 && (
          <text
            x="50%"
            y="205"
            fill="white"
            fontSize="20px"
            fontFamily="Blender Thin"
            letterSpacing="3px"
            textAnchor="middle"
          >
            {line2}
          </text>
        )}
      </svg>
    );
  }
}
