import { observer } from 'mobx-react';
import * as React from 'react';
import { Component } from 'react';
import './chart-shell-store.js';
import './chart-shell.scss';

@observer
export class ChartShell extends Component {
  render() {
    const {
      children,
      className = '',
      topSection,
      leftSection,
      bottomSection,
    } = this.props;

    return (
      <div className={`ChartShell ${className}`}>
        <div className="ChartShell__TopSection">{topSection}</div>
        <div className="ChartShell__LeftSection">{leftSection}</div>
        <div className="ChartShell__BottomSection">{bottomSection}</div>
        <div className="ChartShell__MainSection">{children}</div>
      </div>
    );
  }
}
