/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import ReactDOM from 'react-dom';
import { PlayerDonut } from '../players/donut';
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';
import anime from 'animejs';

// SVG
import { Card } from '../svg/card';
import { Skull } from '../svg/skull';
import { Code } from '../svg/code';
import { PowerShell } from '../svg/power-shell';

@observer
export class Slide08 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    attacker: null,
    powershell: null,
    code: null,
    info: null,
  };

  // Run the first animation
  componentDidMount() {
    const { donut, attacker, powershell, code, info } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(7000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(500);
      },
      // Step 2
      () => {
        this.autoNext(500);
      },
      // Step 3
      () => {
        this.autoNext(3000);
      },
      // Step 4
      () => {
        this.autoNext(2000);
      },
      // Step 5
      () => {
        // this.autoNext(500);
      },
      // Final step
      () => {
        // Showing alert
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Do nothing
      ],
      //
      // Step 1 - Attacker
      //
      [
        {
          targets: attacker,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: attacker,
          opacity: 1,
          translateX: -300,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          begin: activateShimmer,
        },
      ],
      //
      // Step 2 - Powershell
      //
      [
        {
          targets: powershell,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 3 - Code
      //
      [
        {
          targets: code,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 4 - Code
      //
      [
        {
          targets: code,
          opacity: 1,
          left: '506px',
          top: '370px',
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: powershell,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: attacker,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: donut,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          complete: activateShimmer,
        },
      ],
      //
      // Step 5 - Info
      //
      [
        {
          targets: info,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-08 ${className}`}>
        {children}
        <div className="content">
          {/* Attacker */}
          <div
            className="player attacker"
            data-active="0"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text={'Attacker'} />
            <div className={'animated-dotted-line reverse ants-1'}></div>
          </div>
          {/* Donut analyzer */}
          <div
            className="player donut"
            data-active="0"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
          {/* Text block - Analyzing */}
          <div
            className="player info"
            data-active="0"
            ref={node => (this.players.info = node)}
          >
            <PlayerTextBlock
              title="MIMIKATZ"
              actionTitle="Analyzing Activity"
              className={'wrap'}
            />
          </div>
          {/* Powershell */}
          <div
            className="player powershell"
            data-active="0"
            ref={node => (this.players.powershell = node)}
          >
            <PlayerCircleIcon
              color={this.colors.blue}
              size={100}
              IconSize={60}
              Icon={PowerShell}
            />
            <div className={'animated-dotted-line reverse ants-1'}></div>
          </div>
          {/* Code */}
          <div
            className="player code"
            data-active="0"
            ref={node => (this.players.code = node)}
          >
            <Code size={100} />
          </div>
        </div>
      </div>
    );
  }
}
