import { observable, reaction, computed } from 'mobx';

/**
 * Map of costs per service unit.
 * These values were pulled from an .xlsx file received from eSentire.
 */
const COSTS = {
  preventionEndpoints: 44,
  edrEndpoints: 30,
  sensors: 10000,
  dailyGbIngest: 150 * 12,
  ipAddresses: 35,
  feeds: 5000,
  operationTools: 25000,
  analysts: 111633,
  secOpsManagers: 124433,
  intelligenceAnalysts: 95875,
  intelligenceManagers: 167297,
  engineers: 116360,
  admins: 95418,
  labor: 0.2,
  hardware: 0.1,
};

class State {
  @observable preventionEndpoints = 0;
  @observable edrEndpoints = 0;
  @observable sensors = 0;
  @observable dailyGbIngest = 0;
  @observable ipAddresses = 0;
  @observable feeds = 0;
  @observable analysts = 0;
  @observable secOpsManagers = 0;
  @observable intelligenceAnalysts = 0;
  @observable intelligenceManagers = 0;
  @observable engineers = 0;
  @observable admins = 0;
  @observable year1EsentireCost = 0;
  @observable year2EsentireCost = 0;
  @observable year3EsentireCost = 0;
  @observable implementationHours = 0;
  @observable implementationCostPerHour = 0;

  @computed get yearTotalEsentireCost() {
    return (
      this.year1EsentireCost + this.year2EsentireCost + this.year3EsentireCost
    );
  }

  @computed get year1PreventionEndpoints() {
    return this.preventionEndpoints * COSTS.preventionEndpoints;
  }

  @computed get year1EdrEndpoints() {
    return this.edrEndpoints * COSTS.edrEndpoints;
  }

  @computed get year1Sensors() {
    return this.sensors * COSTS.sensors;
  }

  @computed get year1DailyGbIngest() {
    return this.dailyGbIngest * COSTS.dailyGbIngest;
  }

  @computed get year1IpAddresses() {
    return this.ipAddresses * COSTS.ipAddresses;
  }

  @computed get year1Feeds() {
    return this.feeds * COSTS.feeds * this.intelligenceAnalysts;
  }

  @computed get year1OperationTools() {
    return this.analysts * COSTS.operationTools;
  }

  @computed get year1Analysts() {
    return this.analysts * COSTS.analysts;
  }

  @computed get year1SecOpsManagers() {
    return this.secOpsManagers * COSTS.secOpsManagers;
  }

  @computed get year1IntelligenceAnalysts() {
    return this.intelligenceAnalysts * COSTS.intelligenceAnalysts;
  }

  @computed get year1IntelligenceManagers() {
    return this.intelligenceManagers * COSTS.intelligenceManagers;
  }

  @computed get year1Engineers() {
    return this.engineers * COSTS.engineers;
  }

  @computed get year1Admins() {
    return this.admins * COSTS.admins;
  }

  @computed get year1SecurityTools() {
    return (
      this.year1PreventionEndpoints +
      this.year1EdrEndpoints +
      this.year1Sensors +
      this.year1DailyGbIngest +
      this.year1IpAddresses +
      this.year1Feeds
    );
  }

  @computed get year1Implementation() {
    return this.implementationCostPerHour * this.implementationHours;
  }

  @computed get year1MaintenanceLabor() {
    return Math.floor(this.year1SecurityTools * COSTS.labor);
  }

  @computed get year1MaintenanceHardware() {
    return Math.floor(this.year1SecurityTools * COSTS.hardware);
  }

  @computed get year1Personnel() {
    return (
      this.year1Analysts +
      this.year1SecOpsManagers +
      this.year1IntelligenceAnalysts +
      this.year1IntelligenceManagers +
      this.year1Engineers +
      this.year1Admins
    );
  }

  @computed get year1Maintenance() {
    return this.year1MaintenanceLabor + this.year1MaintenanceHardware;
  }

  @computed get year1InHouse() {
    return (
      this.year1SecurityTools +
      this.year1OperationTools +
      this.year1Personnel +
      this.year1Maintenance +
      this.year1Implementation
    );
  }

  @computed get year2InHouse() {
    return (
      this.year1SecurityTools +
      this.year1OperationTools +
      this.year1Personnel +
      this.year1Maintenance
    );
  }

  @computed get yearTotalInHouse() {
    return this.year1InHouse + this.year2InHouse + this.year2InHouse;
  }

  @computed get yearTotalSavings() {
    const savings = this.yearTotalInHouse - this.yearTotalEsentireCost;
    if (this.yearTotalInHouse === 0) {
      return 0;
    }
    return Math.round((savings / this.yearTotalInHouse) * 100);
  }
}

export const SOCCalculatorState = new State();

export class SOCCalculatorActions {
  static updateField(name, value) {
    SOCCalculatorState[name] = value;
  }
}
