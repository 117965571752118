/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement } from 'react';
import anime from 'animejs';
import { activate, deactivate, shimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// SVG
import { Alert } from '../svg/alert';
import { Card } from '../svg/card';
import { Shield } from '../svg/shield';
import { Computer } from '../svg/computer';

@observer
export class Slide02 extends AnimatedSlide {
  // Animation players
  players = {
    alert: null,
    terminal: null,
    dots: null,
    amp: null,
    border: null,
  };

  // Run the first animation
  componentDidMount() {
    // Save the border since I can't bind it in the DOM like the others
    this.players.border = this.players.alert.querySelector('.border');

    const { terminal, amp, dots, alert, border } = this.players;

    this.steps = [
      () => {
        this.autoNext(3000);
        this.animateProgress(6000);
      },
      () => {
        this.autoNext(3000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: terminal,
          opacity: 1,
          left: 496,
          easing: 'easeOutQuad',
          duration: 500,
        },
        {
          targets: amp,
          opacity: 0,
          left: 504,
          easing: 'easeOutQuad',
          duration: 500,
        },
        { targets: dots, opacity: 0 },
      ],

      //
      // Step 2
      //
      [
        {
          targets: terminal,
          opacity: 1,
          left: 359,
          duration: 100,
          easing: 'easeOutQuad',
          complete: shimmer,
        },
        {
          targets: amp,
          opacity: 1,
          left: 640,
          duration: 100,
          easing: 'easeOutQuad',
          begin: activate,
          complete: shimmer,
        },
        {
          targets: dots,
          opacity: 1,
          duration: 200,
          easing: 'easeOutQuad',
          begin: activate,
          complete: shimmer,
        },
        {
          targets: alert,
          opacity: 0,
          duration: 500,
          easing: 'easeOutQuad',
          begin: deactivate,
          complete: shimmer,
        },
      ],

      //
      // Step 3
      //
      [
        { targets: terminal, opacity: 0.2 },
        { targets: amp, opacity: 0.2 },
        { targets: dots, opacity: 0.2 },
        { targets: alert, opacity: 1, begin: activate },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`Home ${className}`}>
        {children}
        <div className="content">
          {/* Bad file detected */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert />
          </div>

          {/* Computer terminal icon */}
          <div
            className="player terminal fade-behind-alert"
            data-active="1"
            ref={node => (this.players.terminal = node)}
          >
            <Card IconComponent={Computer} text={'10.0.5.38'} />
          </div>

          {/* Shield icon */}
          <div
            className="player amp fade-behind-alert"
            data-active="0"
            ref={node => (this.players.amp = node)}
          >
            <Shield />
          </div>

          {/* Animated red dots */}
          <div
            className="player dots fade-behind-alert"
            data-active="0"
            ref={node => (this.players.dots = node)}
          >
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </div>
        </div>
      </div>
    );
  }
}
