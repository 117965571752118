/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class PowerShell extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 59,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 59 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1324 43.6577C22.0312 43.657 19.93 43.6594 17.8287 43.6619C13.0912 43.6675 8.35364 43.6731 3.61638 43.6419C0.14861 43.6192 -0.481948 42.798 0.298671 39.3945C1.28275 35.1023 2.27862 30.8127 3.27448 26.5232L3.2745 26.5231C4.95677 19.277 6.63904 12.0309 8.26443 4.77206C8.95828 1.67268 10.455 0.302799 13.7641 0.333513C23.0675 0.420129 32.3721 0.407805 41.6765 0.395482C46.0497 0.38969 50.4228 0.383898 54.7958 0.388379C58.2258 0.391659 58.8017 1.12789 58.0318 4.61879C55.5319 15.9521 53.0162 27.282 50.4562 38.6019C49.4982 42.8383 48.3946 43.6467 43.953 43.6565C38.9831 43.667 34.013 43.6637 29.0431 43.6604H29.0429C27.406 43.6594 25.7692 43.6583 24.1324 43.6577ZM29.5153 20.5175L29.5152 20.5175L29.5152 20.5174C29.1798 20.1164 28.8252 19.6923 28.4564 19.2813C27.7028 18.4413 26.9509 17.5997 26.1989 16.7582L26.1989 16.7582C23.9346 14.224 21.6703 11.6897 19.3606 9.19804C17.9527 7.67907 17.504 6.20393 19.1948 4.70463C20.8656 3.22264 22.5074 3.35384 24.0401 5.05888L24.888 6.00182L24.8882 6.00208C28.8738 10.4349 32.8597 14.8681 36.9116 19.2396C38.5317 20.9881 38.1543 22.177 36.3699 23.4726C29.5208 28.4473 22.7104 33.476 15.9074 38.5142C14.3782 39.647 12.9204 39.9306 11.6851 38.2575C10.3224 36.412 11.5832 35.314 12.9968 34.2811C18.0522 30.5859 23.0979 26.8771 28.1218 23.139C28.2694 23.0292 28.4305 22.9291 28.5941 22.8274C29.2062 22.447 29.8527 22.0452 29.9514 21.0364C29.8101 20.8702 29.6646 20.6961 29.5153 20.5175ZM30.4523 38.9011C31.0072 38.8953 31.5622 38.8895 32.1169 38.8895C32.1169 38.8839 32.1169 38.8779 32.1172 38.8728C32.7391 38.8728 33.3616 38.8813 33.984 38.8898C35.4339 38.9096 36.8837 38.9294 38.3274 38.8418C39.965 38.7428 40.7697 37.5539 40.7789 36.0195C40.7866 34.6818 39.8423 33.8454 38.563 33.8203C34.5375 33.7413 30.5075 33.718 26.4835 33.8263C25.0194 33.8659 24.2837 34.9672 24.2658 36.4319C24.2474 37.981 25.1294 38.8028 26.5949 38.8693C27.8784 38.9278 29.1653 38.9144 30.4523 38.9011Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
