/** @jsx createElement */
import anime from 'animejs';
import { createElement } from 'react';
import { observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Popup } from '../popup';
import { Modal } from '../modal';
import { AnimatedSlide } from '../animated-slide';
import { Button } from '../button';

import './popups-tab.scss';

@observer
export class PopupsTab extends AnimatedSlide {
  @observable modal = false;

  clickModal = () => {
    this.modal = true;
  };

  closeModal = () => {
    this.modal = false;
  };

  render() {
    const { className = '' } = this.props;

    return (
      <div className={`PopupsTab ${className}`}>
        <h2>POPUPS</h2>
        <div className="PopupsTab__Popups">
          <div className="PopupsTab__Popups__Cell">
            <Popup position="top-left" trigger={<Button>top left</Button>}>
              This be thar popup. We can put whatever we want in here.
            </Popup>
          </div>
          <div className="PopupsTab__Popups__Cell">
            <Popup position="top-middle" trigger={<Button>top middle</Button>}>
              This be thar popup. We can put whatever we want in here.
            </Popup>
          </div>
          <div className="PopupsTab__Popups__Cell">
            <Popup position="top-right" trigger={<Button>top right</Button>}>
              This be thar popup. We can put whatever we want in here.
            </Popup>
          </div>
          <div className="PopupsTab__Popups__Cell">
            <Popup
              position="bottom-left"
              trigger={<Button>bottom left</Button>}
            >
              This be thar popup. We can put whatever we want in here.
            </Popup>
          </div>
          <div className="PopupsTab__Popups__Cell">
            <Popup
              position="bottom-middle"
              trigger={<Button>bottom middle</Button>}
            >
              This be thar popup. We can put whatever we want in here.
            </Popup>
          </div>
          <div className="PopupsTab__Popups__Cell">
            <Popup
              position="bottom-right"
              trigger={<Button>bottom right</Button>}
            >
              This be thar popup. We can put whatever we want in here.
            </Popup>
          </div>
        </div>
        <h2>MODALS</h2>
        <div className="PopupsTab__Modals">
          <Button onClick={this.clickModal}>show</Button>
          <Modal show={this.modal} onClose={this.closeModal}>
            <h3>Incident Probability</h3>
            <p>
              This tool simulates for you what the chance of discovering one or
              more incidents of each type on one or more of your sensors would
              have been if a monitoring program was instituted for a company of
              your specified size, filtered by selected industry/geography. It
              gives a raw breakdown of your probability for finding such a
              situation, but doesn’t really talk about what that means or how
              big the hole might be. The confidence of our projection is based
              on how big a sample size we have of people matching your filter
              conditions, but a good way to think about the interval is to treat
              the lower bounds as a “best case”, and the upper bounds as a
              “worst case”, with the observed rate being an “average case”. To
              get a better look at what the impact from these risks can look
              like, check out our Impact Calculator.
            </p>
          </Modal>
        </div>
      </div>
    );
  }
}
