/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class USA extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#ffffff',
      opacity = 0.2,
      x = 0,
      y = 0,
    } = this.props;

    return (
      <svg
        className={`USA ${className}`}
        {...{ x, y }}
        width="277"
        height="168"
        viewBox="0 0 277 168"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 0C35.505 0 37.1173 1.60992 37.1173 3.59574C37.1173 5.58155 35.505 7.19147 33.5163 7.19147C31.5276 7.19147 29.9153 5.58155 29.9153 3.59574C29.9153 1.60992 31.5276 0 33.5163 0Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 0C45.4774 0 47.0897 1.60992 47.0897 3.59574C47.0897 5.58155 45.4774 7.19147 43.4887 7.19147C41.4999 7.19147 39.8876 5.58155 39.8876 3.59574C39.8876 1.60992 41.4999 0 43.4887 0Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 0C55.4497 0 57.062 1.60992 57.062 3.59574C57.062 5.58155 55.4497 7.19147 53.461 7.19147C51.4717 7.19147 49.8599 5.58155 49.8599 3.59574C49.8599 1.60992 51.4717 0 53.461 0Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 0C65.4215 0 67.0338 1.60992 67.0338 3.59574C67.0338 5.58155 65.4215 7.19147 63.4328 7.19147C61.444 7.19147 59.8317 5.58155 59.8317 3.59574C59.8317 1.60992 61.444 0 63.4328 0Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 0C155.447 0 157.059 1.60992 157.059 3.59574C157.059 5.58155 155.447 7.19147 153.458 7.19147C151.469 7.19147 149.857 5.58155 149.857 3.59574C149.857 1.60992 151.469 0 153.458 0Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M273.399 0C275.388 0 277 1.60992 277 3.59574C277 5.58155 275.388 7.19147 273.399 7.19147C271.41 7.19147 269.798 5.58155 269.798 3.59574C269.798 1.60992 271.41 0 273.399 0Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5728 9.9563C15.5616 9.9563 17.1739 11.5662 17.1739 13.552C17.1739 15.5378 15.5616 17.1478 13.5728 17.1478C11.5841 17.1478 9.9718 15.5378 9.9718 13.552C9.9718 11.5662 11.5841 9.9563 13.5728 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 9.9563C25.5339 9.9563 27.1462 11.5662 27.1462 13.552C27.1462 15.5378 25.5339 17.1478 23.5452 17.1478C21.5564 17.1478 19.9442 15.5378 19.9442 13.552C19.9442 11.5662 21.5564 9.9563 23.5452 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 9.9563C35.505 9.9563 37.1173 11.5662 37.1173 13.552C37.1173 15.5378 35.505 17.1478 33.5163 17.1478C31.5276 17.1478 29.9153 15.5378 29.9153 13.552C29.9153 11.5662 31.5276 9.9563 33.5163 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 9.9563C45.4774 9.9563 47.0897 11.5662 47.0897 13.552C47.0897 15.5378 45.4774 17.1478 43.4887 17.1478C41.4999 17.1478 39.8876 15.5378 39.8876 13.552C39.8876 11.5662 41.4999 9.9563 43.4887 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 9.9563C55.4497 9.9563 57.062 11.5662 57.062 13.552C57.062 15.5378 55.4497 17.1478 53.461 17.1478C51.4717 17.1478 49.8599 15.5378 49.8599 13.552C49.8599 11.5662 51.4717 9.9563 53.461 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 9.9563C65.4215 9.9563 67.0338 11.5662 67.0338 13.552C67.0338 15.5378 65.4215 17.1478 63.4328 17.1478C61.444 17.1478 59.8317 15.5378 59.8317 13.552C59.8317 11.5662 61.444 9.9563 63.4328 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 9.9563C75.3938 9.9563 77.0061 11.5662 77.0061 13.552C77.0061 15.5378 75.3938 17.1478 73.4051 17.1478C71.4164 17.1478 69.8041 15.5378 69.8041 13.552C69.8041 11.5662 71.4164 9.9563 73.4051 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 9.9563C85.3661 9.9563 86.9784 11.5662 86.9784 13.552C86.9784 15.5378 85.3661 17.1478 83.3774 17.1478C81.3887 17.1478 79.7764 15.5378 79.7764 13.552C79.7764 11.5662 81.3887 9.9563 83.3774 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 9.9563C95.6152 9.9563 97.227 11.5662 97.227 13.552C97.227 15.5378 95.6152 17.1478 93.6259 17.1478C91.6372 17.1478 90.0249 15.5378 90.0249 13.552C90.0249 11.5662 91.6372 9.9563 93.6259 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 9.9563C105.587 9.9563 107.199 11.5662 107.199 13.552C107.199 15.5378 105.587 17.1478 103.598 17.1478C101.609 17.1478 99.9972 15.5378 99.9972 13.552C99.9972 11.5662 101.609 9.9563 103.598 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 9.9563C115.558 9.9563 117.17 11.5662 117.17 13.552C117.17 15.5378 115.558 17.1478 113.569 17.1478C111.581 17.1478 109.968 15.5378 109.968 13.552C109.968 11.5662 111.581 9.9563 113.569 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 9.9563C125.531 9.9563 127.143 11.5662 127.143 13.552C127.143 15.5378 125.531 17.1478 123.542 17.1478C121.553 17.1478 119.941 15.5378 119.941 13.552C119.941 11.5662 121.553 9.9563 123.542 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 9.9563C135.503 9.9563 137.115 11.5662 137.115 13.552C137.115 15.5378 135.503 17.1478 133.514 17.1478C131.525 17.1478 129.913 15.5378 129.913 13.552C129.913 11.5662 131.525 9.9563 133.514 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 9.9563C145.475 9.9563 147.087 11.5662 147.087 13.552C147.087 15.5378 145.475 17.1478 143.486 17.1478C141.497 17.1478 139.885 15.5378 139.885 13.552C139.885 11.5662 141.497 9.9563 143.486 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 9.9563C155.447 9.9563 157.059 11.5662 157.059 13.552C157.059 15.5378 155.447 17.1478 153.458 17.1478C151.469 17.1478 149.857 15.5378 149.857 13.552C149.857 11.5662 151.469 9.9563 153.458 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 9.9563C165.419 9.9563 167.031 11.5662 167.031 13.552C167.031 15.5378 165.419 17.1478 163.43 17.1478C161.442 17.1478 159.829 15.5378 159.829 13.552C159.829 11.5662 161.442 9.9563 163.43 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 9.9563C175.392 9.9563 177.004 11.5662 177.004 13.552C177.004 15.5378 175.392 17.1478 173.403 17.1478C171.414 17.1478 169.802 15.5378 169.802 13.552C169.802 11.5662 171.414 9.9563 173.403 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M263.427 9.9563C265.416 9.9563 267.028 11.5662 267.028 13.552C267.028 15.5378 265.416 17.1478 263.427 17.1478C261.438 17.1478 259.826 15.5378 259.826 13.552C259.826 11.5662 261.438 9.9563 263.427 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M273.399 9.9563C275.388 9.9563 277 11.5662 277 13.552C277 15.5378 275.388 17.1478 273.399 17.1478C271.41 17.1478 269.798 15.5378 269.798 13.552C269.798 11.5662 271.41 9.9563 273.399 9.9563Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5728 19.915C15.5616 19.915 17.1739 21.5244 17.1739 23.5108C17.1739 25.4966 15.5616 27.106 13.5728 27.106C11.5841 27.106 9.9718 25.4966 9.9718 23.5108C9.9718 21.5244 11.5841 19.915 13.5728 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 19.915C25.5339 19.915 27.1462 21.5244 27.1462 23.5108C27.1462 25.4966 25.5339 27.106 23.5452 27.106C21.5564 27.106 19.9442 25.4966 19.9442 23.5108C19.9442 21.5244 21.5564 19.915 23.5452 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 19.915C35.505 19.915 37.1173 21.5244 37.1173 23.5108C37.1173 25.4966 35.505 27.106 33.5163 27.106C31.5276 27.106 29.9153 25.4966 29.9153 23.5108C29.9153 21.5244 31.5276 19.915 33.5163 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 19.915C45.4774 19.915 47.0897 21.5244 47.0897 23.5108C47.0897 25.4966 45.4774 27.106 43.4887 27.106C41.4999 27.106 39.8876 25.4966 39.8876 23.5108C39.8876 21.5244 41.4999 19.915 43.4887 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 19.915C55.4497 19.915 57.062 21.5244 57.062 23.5108C57.062 25.4966 55.4497 27.106 53.461 27.106C51.4717 27.106 49.8599 25.4966 49.8599 23.5108C49.8599 21.5244 51.4717 19.915 53.461 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 19.915C65.4215 19.915 67.0338 21.5244 67.0338 23.5108C67.0338 25.4966 65.4215 27.106 63.4328 27.106C61.444 27.106 59.8317 25.4966 59.8317 23.5108C59.8317 21.5244 61.444 19.915 63.4328 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 19.915C75.3938 19.915 77.0061 21.5244 77.0061 23.5108C77.0061 25.4966 75.3938 27.106 73.4051 27.106C71.4164 27.106 69.8041 25.4966 69.8041 23.5108C69.8041 21.5244 71.4164 19.915 73.4051 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 19.915C85.3661 19.915 86.9784 21.5244 86.9784 23.5108C86.9784 25.4966 85.3661 27.106 83.3774 27.106C81.3887 27.106 79.7764 25.4966 79.7764 23.5108C79.7764 21.5244 81.3887 19.915 83.3774 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 19.915C95.6152 19.915 97.227 21.5244 97.227 23.5108C97.227 25.4966 95.6152 27.106 93.6259 27.106C91.6372 27.106 90.0249 25.4966 90.0249 23.5108C90.0249 21.5244 91.6372 19.915 93.6259 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 19.915C105.587 19.915 107.199 21.5244 107.199 23.5108C107.199 25.4966 105.587 27.106 103.598 27.106C101.609 27.106 99.9972 25.4966 99.9972 23.5108C99.9972 21.5244 101.609 19.915 103.598 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 19.915C115.558 19.915 117.17 21.5244 117.17 23.5108C117.17 25.4966 115.558 27.106 113.569 27.106C111.581 27.106 109.968 25.4966 109.968 23.5108C109.968 21.5244 111.581 19.915 113.569 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 19.915C125.531 19.915 127.143 21.5244 127.143 23.5108C127.143 25.4966 125.531 27.106 123.542 27.106C121.553 27.106 119.941 25.4966 119.941 23.5108C119.941 21.5244 121.553 19.915 123.542 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 19.915C135.503 19.915 137.115 21.5244 137.115 23.5108C137.115 25.4966 135.503 27.106 133.514 27.106C131.525 27.106 129.913 25.4966 129.913 23.5108C129.913 21.5244 131.525 19.915 133.514 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 19.915C145.475 19.915 147.087 21.5244 147.087 23.5108C147.087 25.4966 145.475 27.106 143.486 27.106C141.497 27.106 139.885 25.4966 139.885 23.5108C139.885 21.5244 141.497 19.915 143.486 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 19.915C155.447 19.915 157.059 21.5244 157.059 23.5108C157.059 25.4966 155.447 27.106 153.458 27.106C151.469 27.106 149.857 25.4966 149.857 23.5108C149.857 21.5244 151.469 19.915 153.458 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 19.915C165.419 19.915 167.031 21.5244 167.031 23.5108C167.031 25.4966 165.419 27.106 163.43 27.106C161.442 27.106 159.829 25.4966 159.829 23.5108C159.829 21.5244 161.442 19.915 163.43 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 19.915C175.392 19.915 177.004 21.5244 177.004 23.5108C177.004 25.4966 175.392 27.106 173.403 27.106C171.414 27.106 169.802 25.4966 169.802 23.5108C169.802 21.5244 171.414 19.915 173.403 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 19.915C185.363 19.915 186.975 21.5244 186.975 23.5108C186.975 25.4966 185.363 27.106 183.374 27.106C181.385 27.106 179.773 25.4966 179.773 23.5108C179.773 21.5244 181.385 19.915 183.374 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 19.915C195.612 19.915 197.225 21.5244 197.225 23.5108C197.225 25.4966 195.612 27.106 193.624 27.106C191.635 27.106 190.023 25.4966 190.023 23.5108C190.023 21.5244 191.635 19.915 193.624 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 19.915C245.471 19.915 247.084 21.5244 247.084 23.5108C247.084 25.4966 245.471 27.106 243.482 27.106C241.494 27.106 239.881 25.4966 239.881 23.5108C239.881 21.5244 241.494 19.915 243.482 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M253.455 19.915C255.444 19.915 257.056 21.5244 257.056 23.5108C257.056 25.4966 255.444 27.106 253.455 27.106C251.466 27.106 249.854 25.4966 249.854 23.5108C249.854 21.5244 251.466 19.915 253.455 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M263.427 19.915C265.416 19.915 267.028 21.5244 267.028 23.5108C267.028 25.4966 265.416 27.106 263.427 27.106C261.438 27.106 259.826 25.4966 259.826 23.5108C259.826 21.5244 261.438 19.915 263.427 19.915Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5728 30.1495C15.5616 30.1495 17.1739 31.7595 17.1739 33.7453C17.1739 35.7311 15.5616 37.341 13.5728 37.341C11.5841 37.341 9.9718 35.7311 9.9718 33.7453C9.9718 31.7595 11.5841 30.1495 13.5728 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 30.1495C25.5339 30.1495 27.1462 31.7595 27.1462 33.7453C27.1462 35.7311 25.5339 37.341 23.5452 37.341C21.5564 37.341 19.9442 35.7311 19.9442 33.7453C19.9442 31.7595 21.5564 30.1495 23.5452 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 30.1495C35.505 30.1495 37.1173 31.7595 37.1173 33.7453C37.1173 35.7311 35.505 37.341 33.5163 37.341C31.5276 37.341 29.9153 35.7311 29.9153 33.7453C29.9153 31.7595 31.5276 30.1495 33.5163 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 30.1495C45.4774 30.1495 47.0897 31.7595 47.0897 33.7453C47.0897 35.7311 45.4774 37.341 43.4887 37.341C41.4999 37.341 39.8876 35.7311 39.8876 33.7453C39.8876 31.7595 41.4999 30.1495 43.4887 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 30.1495C55.4497 30.1495 57.062 31.7595 57.062 33.7453C57.062 35.7311 55.4497 37.341 53.461 37.341C51.4717 37.341 49.8599 35.7311 49.8599 33.7453C49.8599 31.7595 51.4717 30.1495 53.461 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 30.1495C65.4215 30.1495 67.0338 31.7595 67.0338 33.7453C67.0338 35.7311 65.4215 37.341 63.4328 37.341C61.444 37.341 59.8317 35.7311 59.8317 33.7453C59.8317 31.7595 61.444 30.1495 63.4328 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 30.1495C75.3938 30.1495 77.0061 31.7595 77.0061 33.7453C77.0061 35.7311 75.3938 37.341 73.4051 37.341C71.4164 37.341 69.8041 35.7311 69.8041 33.7453C69.8041 31.7595 71.4164 30.1495 73.4051 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 30.1495C85.3661 30.1495 86.9784 31.7595 86.9784 33.7453C86.9784 35.7311 85.3661 37.341 83.3774 37.341C81.3887 37.341 79.7764 35.7311 79.7764 33.7453C79.7764 31.7595 81.3887 30.1495 83.3774 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 30.1495C95.6152 30.1495 97.227 31.7595 97.227 33.7453C97.227 35.7311 95.6152 37.341 93.6259 37.341C91.6372 37.341 90.0249 35.7311 90.0249 33.7453C90.0249 31.7595 91.6372 30.1495 93.6259 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 30.1495C105.587 30.1495 107.199 31.7595 107.199 33.7453C107.199 35.7311 105.587 37.341 103.598 37.341C101.609 37.341 99.9972 35.7311 99.9972 33.7453C99.9972 31.7595 101.609 30.1495 103.598 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 30.1495C115.558 30.1495 117.17 31.7595 117.17 33.7453C117.17 35.7311 115.558 37.341 113.569 37.341C111.581 37.341 109.968 35.7311 109.968 33.7453C109.968 31.7595 111.581 30.1495 113.569 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 30.1495C125.531 30.1495 127.143 31.7595 127.143 33.7453C127.143 35.7311 125.531 37.341 123.542 37.341C121.553 37.341 119.941 35.7311 119.941 33.7453C119.941 31.7595 121.553 30.1495 123.542 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 30.1495C135.503 30.1495 137.115 31.7595 137.115 33.7453C137.115 35.7311 135.503 37.341 133.514 37.341C131.525 37.341 129.913 35.7311 129.913 33.7453C129.913 31.7595 131.525 30.1495 133.514 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 30.1495C145.475 30.1495 147.087 31.7595 147.087 33.7453C147.087 35.7311 145.475 37.341 143.486 37.341C141.497 37.341 139.885 35.7311 139.885 33.7453C139.885 31.7595 141.497 30.1495 143.486 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 30.1495C155.447 30.1495 157.059 31.7595 157.059 33.7453C157.059 35.7311 155.447 37.341 153.458 37.341C151.469 37.341 149.857 35.7311 149.857 33.7453C149.857 31.7595 151.469 30.1495 153.458 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 30.1495C165.419 30.1495 167.031 31.7595 167.031 33.7453C167.031 35.7311 165.419 37.341 163.43 37.341C161.442 37.341 159.829 35.7311 159.829 33.7453C159.829 31.7595 161.442 30.1495 163.43 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 30.1495C175.392 30.1495 177.004 31.7595 177.004 33.7453C177.004 35.7311 175.392 37.341 173.403 37.341C171.414 37.341 169.802 35.7311 169.802 33.7453C169.802 31.7595 171.414 30.1495 173.403 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 30.1495C185.363 30.1495 186.975 31.7595 186.975 33.7453C186.975 35.7311 185.363 37.341 183.374 37.341C181.385 37.341 179.773 35.7311 179.773 33.7453C179.773 31.7595 181.385 30.1495 183.374 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 30.1495C205.584 30.1495 207.196 31.7595 207.196 33.7453C207.196 35.7311 205.584 37.341 203.595 37.341C201.606 37.341 199.994 35.7311 199.994 33.7453C199.994 31.7595 201.606 30.1495 203.595 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 30.1495C245.471 30.1495 247.084 31.7595 247.084 33.7453C247.084 35.7311 245.471 37.341 243.482 37.341C241.494 37.341 239.881 35.7311 239.881 33.7453C239.881 31.7595 241.494 30.1495 243.482 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M253.455 30.1495C255.444 30.1495 257.056 31.7595 257.056 33.7453C257.056 35.7311 255.444 37.341 253.455 37.341C251.466 37.341 249.854 35.7311 249.854 33.7453C249.854 31.7595 251.466 30.1495 253.455 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M263.427 30.1495C265.416 30.1495 267.028 31.7595 267.028 33.7453C267.028 35.7311 265.416 37.341 263.427 37.341C261.438 37.341 259.826 35.7311 259.826 33.7453C259.826 31.7595 261.438 30.1495 263.427 30.1495Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.60103 40.1058C5.58977 40.1058 7.20206 41.7158 7.20206 43.7016C7.20206 45.6874 5.58977 47.2973 3.60103 47.2973C1.61229 47.2973 0 45.6874 0 43.7016C0 41.7158 1.61229 40.1058 3.60103 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5728 40.1058C15.5616 40.1058 17.1739 41.7158 17.1739 43.7016C17.1739 45.6874 15.5616 47.2973 13.5728 47.2973C11.5841 47.2973 9.9718 45.6874 9.9718 43.7016C9.9718 41.7158 11.5841 40.1058 13.5728 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 40.1058C25.5339 40.1058 27.1462 41.7158 27.1462 43.7016C27.1462 45.6874 25.5339 47.2973 23.5452 47.2973C21.5564 47.2973 19.9442 45.6874 19.9442 43.7016C19.9442 41.7158 21.5564 40.1058 23.5452 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 40.1058C35.505 40.1058 37.1173 41.7158 37.1173 43.7016C37.1173 45.6874 35.505 47.2973 33.5163 47.2973C31.5276 47.2973 29.9153 45.6874 29.9153 43.7016C29.9153 41.7158 31.5276 40.1058 33.5163 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 40.1058C45.4774 40.1058 47.0897 41.7158 47.0897 43.7016C47.0897 45.6874 45.4774 47.2973 43.4887 47.2973C41.4999 47.2973 39.8876 45.6874 39.8876 43.7016C39.8876 41.7158 41.4999 40.1058 43.4887 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 40.1058C55.4497 40.1058 57.062 41.7158 57.062 43.7016C57.062 45.6874 55.4497 47.2973 53.461 47.2973C51.4717 47.2973 49.8599 45.6874 49.8599 43.7016C49.8599 41.7158 51.4717 40.1058 53.461 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 40.1058C65.4215 40.1058 67.0338 41.7158 67.0338 43.7016C67.0338 45.6874 65.4215 47.2973 63.4328 47.2973C61.444 47.2973 59.8317 45.6874 59.8317 43.7016C59.8317 41.7158 61.444 40.1058 63.4328 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 40.1058C75.3938 40.1058 77.0061 41.7158 77.0061 43.7016C77.0061 45.6874 75.3938 47.2973 73.4051 47.2973C71.4164 47.2973 69.8041 45.6874 69.8041 43.7016C69.8041 41.7158 71.4164 40.1058 73.4051 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 40.1058C85.3661 40.1058 86.9784 41.7158 86.9784 43.7016C86.9784 45.6874 85.3661 47.2973 83.3774 47.2973C81.3887 47.2973 79.7764 45.6874 79.7764 43.7016C79.7764 41.7158 81.3887 40.1058 83.3774 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 40.1058C95.6152 40.1058 97.227 41.7158 97.227 43.7016C97.227 45.6874 95.6152 47.2973 93.6259 47.2973C91.6372 47.2973 90.0249 45.6874 90.0249 43.7016C90.0249 41.7158 91.6372 40.1058 93.6259 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 40.1058C105.587 40.1058 107.199 41.7158 107.199 43.7016C107.199 45.6874 105.587 47.2973 103.598 47.2973C101.609 47.2973 99.9972 45.6874 99.9972 43.7016C99.9972 41.7158 101.609 40.1058 103.598 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 40.1058C115.558 40.1058 117.17 41.7158 117.17 43.7016C117.17 45.6874 115.558 47.2973 113.569 47.2973C111.581 47.2973 109.968 45.6874 109.968 43.7016C109.968 41.7158 111.581 40.1058 113.569 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 40.1058C125.531 40.1058 127.143 41.7158 127.143 43.7016C127.143 45.6874 125.531 47.2973 123.542 47.2973C121.553 47.2973 119.941 45.6874 119.941 43.7016C119.941 41.7158 121.553 40.1058 123.542 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 40.1058C135.503 40.1058 137.115 41.7158 137.115 43.7016C137.115 45.6874 135.503 47.2973 133.514 47.2973C131.525 47.2973 129.913 45.6874 129.913 43.7016C129.913 41.7158 131.525 40.1058 133.514 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 40.1058C145.475 40.1058 147.087 41.7158 147.087 43.7016C147.087 45.6874 145.475 47.2973 143.486 47.2973C141.497 47.2973 139.885 45.6874 139.885 43.7016C139.885 41.7158 141.497 40.1058 143.486 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 40.1058C155.447 40.1058 157.059 41.7158 157.059 43.7016C157.059 45.6874 155.447 47.2973 153.458 47.2973C151.469 47.2973 149.857 45.6874 149.857 43.7016C149.857 41.7158 151.469 40.1058 153.458 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 40.1058C165.419 40.1058 167.031 41.7158 167.031 43.7016C167.031 45.6874 165.419 47.2973 163.43 47.2973C161.442 47.2973 159.829 45.6874 159.829 43.7016C159.829 41.7158 161.442 40.1058 163.43 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 40.1058C175.392 40.1058 177.004 41.7158 177.004 43.7016C177.004 45.6874 175.392 47.2973 173.403 47.2973C171.414 47.2973 169.802 45.6874 169.802 43.7016C169.802 41.7158 171.414 40.1058 173.403 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 40.1058C185.363 40.1058 186.975 41.7158 186.975 43.7016C186.975 45.6874 185.363 47.2973 183.374 47.2973C181.385 47.2973 179.773 45.6874 179.773 43.7016C179.773 41.7158 181.385 40.1058 183.374 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 40.1058C195.612 40.1058 197.225 41.7158 197.225 43.7016C197.225 45.6874 195.612 47.2973 193.624 47.2973C191.635 47.2973 190.023 45.6874 190.023 43.7016C190.023 41.7158 191.635 40.1058 193.624 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 40.1058C205.584 40.1058 207.196 41.7158 207.196 43.7016C207.196 45.6874 205.584 47.2973 203.595 47.2973C201.606 47.2973 199.994 45.6874 199.994 43.7016C199.994 41.7158 201.606 40.1058 203.595 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 40.1058C215.556 40.1058 217.168 41.7158 217.168 43.7016C217.168 45.6874 215.556 47.2973 213.567 47.2973C211.578 47.2973 209.966 45.6874 209.966 43.7016C209.966 41.7158 211.578 40.1058 213.567 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 40.1058C235.5 40.1058 237.112 41.7158 237.112 43.7016C237.112 45.6874 235.5 47.2973 233.511 47.2973C231.523 47.2973 229.91 45.6874 229.91 43.7016C229.91 41.7158 231.523 40.1058 233.511 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 40.1058C245.471 40.1058 247.084 41.7158 247.084 43.7016C247.084 45.6874 245.471 47.2973 243.482 47.2973C241.494 47.2973 239.881 45.6874 239.881 43.7016C239.881 41.7158 241.494 40.1058 243.482 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M253.455 40.1058C255.444 40.1058 257.056 41.7158 257.056 43.7016C257.056 45.6874 255.444 47.2973 253.455 47.2973C251.466 47.2973 249.854 45.6874 249.854 43.7016C249.854 41.7158 251.466 40.1058 253.455 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M263.427 40.1058C265.416 40.1058 267.028 41.7158 267.028 43.7016C267.028 45.6874 265.416 47.2973 263.427 47.2973C261.438 47.2973 259.826 45.6874 259.826 43.7016C259.826 41.7158 261.438 40.1058 263.427 40.1058Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.60103 50.0646C5.58977 50.0646 7.20206 51.6745 7.20206 53.6603C7.20206 55.6461 5.58977 57.256 3.60103 57.256C1.61229 57.256 0 55.6461 0 53.6603C0 51.6745 1.61229 50.0646 3.60103 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5728 50.0646C15.5616 50.0646 17.1739 51.6745 17.1739 53.6603C17.1739 55.6461 15.5616 57.256 13.5728 57.256C11.5841 57.256 9.9718 55.6461 9.9718 53.6603C9.9718 51.6745 11.5841 50.0646 13.5728 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 50.0646C25.5339 50.0646 27.1462 51.6745 27.1462 53.6603C27.1462 55.6461 25.5339 57.256 23.5452 57.256C21.5564 57.256 19.9442 55.6461 19.9442 53.6603C19.9442 51.6745 21.5564 50.0646 23.5452 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 50.0646C35.505 50.0646 37.1173 51.6745 37.1173 53.6603C37.1173 55.6461 35.505 57.256 33.5163 57.256C31.5276 57.256 29.9153 55.6461 29.9153 53.6603C29.9153 51.6745 31.5276 50.0646 33.5163 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 50.0646C45.4774 50.0646 47.0897 51.6745 47.0897 53.6603C47.0897 55.6461 45.4774 57.256 43.4887 57.256C41.4999 57.256 39.8876 55.6461 39.8876 53.6603C39.8876 51.6745 41.4999 50.0646 43.4887 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 50.0646C55.4497 50.0646 57.062 51.6745 57.062 53.6603C57.062 55.6461 55.4497 57.256 53.461 57.256C51.4717 57.256 49.8599 55.6461 49.8599 53.6603C49.8599 51.6745 51.4717 50.0646 53.461 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 50.0646C65.4215 50.0646 67.0338 51.6745 67.0338 53.6603C67.0338 55.6461 65.4215 57.256 63.4328 57.256C61.444 57.256 59.8317 55.6461 59.8317 53.6603C59.8317 51.6745 61.444 50.0646 63.4328 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 50.0646C75.3938 50.0646 77.0061 51.6745 77.0061 53.6603C77.0061 55.6461 75.3938 57.256 73.4051 57.256C71.4164 57.256 69.8041 55.6461 69.8041 53.6603C69.8041 51.6745 71.4164 50.0646 73.4051 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 50.0646C85.3661 50.0646 86.9784 51.6745 86.9784 53.6603C86.9784 55.6461 85.3661 57.256 83.3774 57.256C81.3887 57.256 79.7764 55.6461 79.7764 53.6603C79.7764 51.6745 81.3887 50.0646 83.3774 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 50.0646C95.6152 50.0646 97.227 51.6745 97.227 53.6603C97.227 55.6461 95.6152 57.256 93.6259 57.256C91.6372 57.256 90.0249 55.6461 90.0249 53.6603C90.0249 51.6745 91.6372 50.0646 93.6259 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 50.0646C105.587 50.0646 107.199 51.6745 107.199 53.6603C107.199 55.6461 105.587 57.256 103.598 57.256C101.609 57.256 99.9972 55.6461 99.9972 53.6603C99.9972 51.6745 101.609 50.0646 103.598 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 50.0646C115.558 50.0646 117.17 51.6745 117.17 53.6603C117.17 55.6461 115.558 57.256 113.569 57.256C111.581 57.256 109.968 55.6461 109.968 53.6603C109.968 51.6745 111.581 50.0646 113.569 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 50.0646C125.531 50.0646 127.143 51.6745 127.143 53.6603C127.143 55.6461 125.531 57.256 123.542 57.256C121.553 57.256 119.941 55.6461 119.941 53.6603C119.941 51.6745 121.553 50.0646 123.542 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 50.0646C135.503 50.0646 137.115 51.6745 137.115 53.6603C137.115 55.6461 135.503 57.256 133.514 57.256C131.525 57.256 129.913 55.6461 129.913 53.6603C129.913 51.6745 131.525 50.0646 133.514 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 50.0646C145.475 50.0646 147.087 51.6745 147.087 53.6603C147.087 55.6461 145.475 57.256 143.486 57.256C141.497 57.256 139.885 55.6461 139.885 53.6603C139.885 51.6745 141.497 50.0646 143.486 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 50.0646C155.447 50.0646 157.059 51.6745 157.059 53.6603C157.059 55.6461 155.447 57.256 153.458 57.256C151.469 57.256 149.857 55.6461 149.857 53.6603C149.857 51.6745 151.469 50.0646 153.458 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 50.0646C165.419 50.0646 167.031 51.6745 167.031 53.6603C167.031 55.6461 165.419 57.256 163.43 57.256C161.442 57.256 159.829 55.6461 159.829 53.6603C159.829 51.6745 161.442 50.0646 163.43 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 50.0646C175.392 50.0646 177.004 51.6745 177.004 53.6603C177.004 55.6461 175.392 57.256 173.403 57.256C171.414 57.256 169.802 55.6461 169.802 53.6603C169.802 51.6745 171.414 50.0646 173.403 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 50.0646C185.363 50.0646 186.975 51.6745 186.975 53.6603C186.975 55.6461 185.363 57.256 183.374 57.256C181.385 57.256 179.773 55.6461 179.773 53.6603C179.773 51.6745 181.385 50.0646 183.374 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 50.0646C195.612 50.0646 197.225 51.6745 197.225 53.6603C197.225 55.6461 195.612 57.256 193.624 57.256C191.635 57.256 190.023 55.6461 190.023 53.6603C190.023 51.6745 191.635 50.0646 193.624 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 50.0646C205.584 50.0646 207.196 51.6745 207.196 53.6603C207.196 55.6461 205.584 57.256 203.595 57.256C201.606 57.256 199.994 55.6461 199.994 53.6603C199.994 51.6745 201.606 50.0646 203.595 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 50.0646C215.556 50.0646 217.168 51.6745 217.168 53.6603C217.168 55.6461 215.556 57.256 213.567 57.256C211.578 57.256 209.966 55.6461 209.966 53.6603C209.966 51.6745 211.578 50.0646 213.567 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.539 50.0646C225.528 50.0646 227.14 51.6745 227.14 53.6603C227.14 55.6461 225.528 57.256 223.539 57.256C221.55 57.256 219.938 55.6461 219.938 53.6603C219.938 51.6745 221.55 50.0646 223.539 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 50.0646C235.5 50.0646 237.112 51.6745 237.112 53.6603C237.112 55.6461 235.5 57.256 233.511 57.256C231.523 57.256 229.91 55.6461 229.91 53.6603C229.91 51.6745 231.523 50.0646 233.511 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 50.0646C245.471 50.0646 247.084 51.6745 247.084 53.6603C247.084 55.6461 245.471 57.256 243.482 57.256C241.494 57.256 239.881 55.6461 239.881 53.6603C239.881 51.6745 241.494 50.0646 243.482 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M253.455 50.0646C255.444 50.0646 257.056 51.6745 257.056 53.6603C257.056 55.6461 255.444 57.256 253.455 57.256C251.466 57.256 249.854 55.6461 249.854 53.6603C249.854 51.6745 251.466 50.0646 253.455 50.0646Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.60103 60.0204C5.58977 60.0204 7.20206 61.6303 7.20206 63.6161C7.20206 65.6019 5.58977 67.2119 3.60103 67.2119C1.61229 67.2119 0 65.6019 0 63.6161C0 61.6303 1.61229 60.0204 3.60103 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5728 60.0204C15.5616 60.0204 17.1739 61.6303 17.1739 63.6161C17.1739 65.6019 15.5616 67.2119 13.5728 67.2119C11.5841 67.2119 9.9718 65.6019 9.9718 63.6161C9.9718 61.6303 11.5841 60.0204 13.5728 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 60.0204C25.5339 60.0204 27.1462 61.6303 27.1462 63.6161C27.1462 65.6019 25.5339 67.2119 23.5452 67.2119C21.5564 67.2119 19.9442 65.6019 19.9442 63.6161C19.9442 61.6303 21.5564 60.0204 23.5452 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 60.0204C35.505 60.0204 37.1173 61.6303 37.1173 63.6161C37.1173 65.6019 35.505 67.2119 33.5163 67.2119C31.5276 67.2119 29.9153 65.6019 29.9153 63.6161C29.9153 61.6303 31.5276 60.0204 33.5163 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 60.0204C45.4774 60.0204 47.0897 61.6303 47.0897 63.6161C47.0897 65.6019 45.4774 67.2119 43.4887 67.2119C41.4999 67.2119 39.8876 65.6019 39.8876 63.6161C39.8876 61.6303 41.4999 60.0204 43.4887 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 60.0204C55.4497 60.0204 57.062 61.6303 57.062 63.6161C57.062 65.6019 55.4497 67.2119 53.461 67.2119C51.4717 67.2119 49.8599 65.6019 49.8599 63.6161C49.8599 61.6303 51.4717 60.0204 53.461 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 60.0204C65.4215 60.0204 67.0338 61.6303 67.0338 63.6161C67.0338 65.6019 65.4215 67.2119 63.4328 67.2119C61.444 67.2119 59.8317 65.6019 59.8317 63.6161C59.8317 61.6303 61.444 60.0204 63.4328 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 60.0204C75.3938 60.0204 77.0061 61.6303 77.0061 63.6161C77.0061 65.6019 75.3938 67.2119 73.4051 67.2119C71.4164 67.2119 69.8041 65.6019 69.8041 63.6161C69.8041 61.6303 71.4164 60.0204 73.4051 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 60.0204C85.3661 60.0204 86.9784 61.6303 86.9784 63.6161C86.9784 65.6019 85.3661 67.2119 83.3774 67.2119C81.3887 67.2119 79.7764 65.6019 79.7764 63.6161C79.7764 61.6303 81.3887 60.0204 83.3774 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 60.0204C95.6152 60.0204 97.227 61.6303 97.227 63.6161C97.227 65.6019 95.6152 67.2119 93.6259 67.2119C91.6372 67.2119 90.0249 65.6019 90.0249 63.6161C90.0249 61.6303 91.6372 60.0204 93.6259 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 60.0204C105.587 60.0204 107.199 61.6303 107.199 63.6161C107.199 65.6019 105.587 67.2119 103.598 67.2119C101.609 67.2119 99.9972 65.6019 99.9972 63.6161C99.9972 61.6303 101.609 60.0204 103.598 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 60.0204C115.558 60.0204 117.17 61.6303 117.17 63.6161C117.17 65.6019 115.558 67.2119 113.569 67.2119C111.581 67.2119 109.968 65.6019 109.968 63.6161C109.968 61.6303 111.581 60.0204 113.569 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 60.0204C125.531 60.0204 127.143 61.6303 127.143 63.6161C127.143 65.6019 125.531 67.2119 123.542 67.2119C121.553 67.2119 119.941 65.6019 119.941 63.6161C119.941 61.6303 121.553 60.0204 123.542 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 60.0204C135.503 60.0204 137.115 61.6303 137.115 63.6161C137.115 65.6019 135.503 67.2119 133.514 67.2119C131.525 67.2119 129.913 65.6019 129.913 63.6161C129.913 61.6303 131.525 60.0204 133.514 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 60.0204C145.475 60.0204 147.087 61.6303 147.087 63.6161C147.087 65.6019 145.475 67.2119 143.486 67.2119C141.497 67.2119 139.885 65.6019 139.885 63.6161C139.885 61.6303 141.497 60.0204 143.486 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 60.0204C155.447 60.0204 157.059 61.6303 157.059 63.6161C157.059 65.6019 155.447 67.2119 153.458 67.2119C151.469 67.2119 149.857 65.6019 149.857 63.6161C149.857 61.6303 151.469 60.0204 153.458 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 60.0204C165.419 60.0204 167.031 61.6303 167.031 63.6161C167.031 65.6019 165.419 67.2119 163.43 67.2119C161.442 67.2119 159.829 65.6019 159.829 63.6161C159.829 61.6303 161.442 60.0204 163.43 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 60.0204C175.392 60.0204 177.004 61.6303 177.004 63.6161C177.004 65.6019 175.392 67.2119 173.403 67.2119C171.414 67.2119 169.802 65.6019 169.802 63.6161C169.802 61.6303 171.414 60.0204 173.403 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 60.0204C185.363 60.0204 186.975 61.6303 186.975 63.6161C186.975 65.6019 185.363 67.2119 183.374 67.2119C181.385 67.2119 179.773 65.6019 179.773 63.6161C179.773 61.6303 181.385 60.0204 183.374 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 60.0204C195.612 60.0204 197.225 61.6303 197.225 63.6161C197.225 65.6019 195.612 67.2119 193.624 67.2119C191.635 67.2119 190.023 65.6019 190.023 63.6161C190.023 61.6303 191.635 60.0204 193.624 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 60.0204C205.584 60.0204 207.196 61.6303 207.196 63.6161C207.196 65.6019 205.584 67.2119 203.595 67.2119C201.606 67.2119 199.994 65.6019 199.994 63.6161C199.994 61.6303 201.606 60.0204 203.595 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 60.0204C215.556 60.0204 217.168 61.6303 217.168 63.6161C217.168 65.6019 215.556 67.2119 213.567 67.2119C211.578 67.2119 209.966 65.6019 209.966 63.6161C209.966 61.6303 211.578 60.0204 213.567 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.539 60.0204C225.528 60.0204 227.14 61.6303 227.14 63.6161C227.14 65.6019 225.528 67.2119 223.539 67.2119C221.55 67.2119 219.938 65.6019 219.938 63.6161C219.938 61.6303 221.55 60.0204 223.539 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 60.0204C235.5 60.0204 237.112 61.6303 237.112 63.6161C237.112 65.6019 235.5 67.2119 233.511 67.2119C231.523 67.2119 229.91 65.6019 229.91 63.6161C229.91 61.6303 231.523 60.0204 233.511 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 60.0204C245.471 60.0204 247.084 61.6303 247.084 63.6161C247.084 65.6019 245.471 67.2119 243.482 67.2119C241.494 67.2119 239.881 65.6019 239.881 63.6161C239.881 61.6303 241.494 60.0204 243.482 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M253.455 60.0204C255.444 60.0204 257.056 61.6303 257.056 63.6161C257.056 65.6019 255.444 67.2119 253.455 67.2119C251.466 67.2119 249.854 65.6019 249.854 63.6161C249.854 61.6303 251.466 60.0204 253.455 60.0204Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5728 69.979C15.5616 69.979 17.1739 71.5889 17.1739 73.5747C17.1739 75.5606 15.5616 77.1705 13.5728 77.1705C11.5841 77.1705 9.9718 75.5606 9.9718 73.5747C9.9718 71.5889 11.5841 69.979 13.5728 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 69.979C25.5339 69.979 27.1462 71.5889 27.1462 73.5747C27.1462 75.5606 25.5339 77.1705 23.5452 77.1705C21.5564 77.1705 19.9442 75.5606 19.9442 73.5747C19.9442 71.5889 21.5564 69.979 23.5452 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 69.979C35.505 69.979 37.1173 71.5889 37.1173 73.5747C37.1173 75.5606 35.505 77.1705 33.5163 77.1705C31.5276 77.1705 29.9153 75.5606 29.9153 73.5747C29.9153 71.5889 31.5276 69.979 33.5163 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 69.979C45.4774 69.979 47.0897 71.5889 47.0897 73.5747C47.0897 75.5606 45.4774 77.1705 43.4887 77.1705C41.4999 77.1705 39.8876 75.5606 39.8876 73.5747C39.8876 71.5889 41.4999 69.979 43.4887 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 69.979C55.4497 69.979 57.062 71.5889 57.062 73.5747C57.062 75.5606 55.4497 77.1705 53.461 77.1705C51.4717 77.1705 49.8599 75.5606 49.8599 73.5747C49.8599 71.5889 51.4717 69.979 53.461 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 69.979C65.4215 69.979 67.0338 71.5889 67.0338 73.5747C67.0338 75.5606 65.4215 77.1705 63.4328 77.1705C61.444 77.1705 59.8317 75.5606 59.8317 73.5747C59.8317 71.5889 61.444 69.979 63.4328 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 69.979C75.3938 69.979 77.0061 71.5889 77.0061 73.5747C77.0061 75.5606 75.3938 77.1705 73.4051 77.1705C71.4164 77.1705 69.8041 75.5606 69.8041 73.5747C69.8041 71.5889 71.4164 69.979 73.4051 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 69.979C85.3661 69.979 86.9784 71.5889 86.9784 73.5747C86.9784 75.5606 85.3661 77.1705 83.3774 77.1705C81.3887 77.1705 79.7764 75.5606 79.7764 73.5747C79.7764 71.5889 81.3887 69.979 83.3774 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 69.979C95.6152 69.979 97.227 71.5889 97.227 73.5747C97.227 75.5606 95.6152 77.1705 93.6259 77.1705C91.6372 77.1705 90.0249 75.5606 90.0249 73.5747C90.0249 71.5889 91.6372 69.979 93.6259 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 69.979C105.587 69.979 107.199 71.5889 107.199 73.5747C107.199 75.5606 105.587 77.1705 103.598 77.1705C101.609 77.1705 99.9972 75.5606 99.9972 73.5747C99.9972 71.5889 101.609 69.979 103.598 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 69.979C115.558 69.979 117.17 71.5889 117.17 73.5747C117.17 75.5606 115.558 77.1705 113.569 77.1705C111.581 77.1705 109.968 75.5606 109.968 73.5747C109.968 71.5889 111.581 69.979 113.569 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 69.979C125.531 69.979 127.143 71.5889 127.143 73.5747C127.143 75.5606 125.531 77.1705 123.542 77.1705C121.553 77.1705 119.941 75.5606 119.941 73.5747C119.941 71.5889 121.553 69.979 123.542 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 69.979C135.503 69.979 137.115 71.5889 137.115 73.5747C137.115 75.5606 135.503 77.1705 133.514 77.1705C131.525 77.1705 129.913 75.5606 129.913 73.5747C129.913 71.5889 131.525 69.979 133.514 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 69.979C145.475 69.979 147.087 71.5889 147.087 73.5747C147.087 75.5606 145.475 77.1705 143.486 77.1705C141.497 77.1705 139.885 75.5606 139.885 73.5747C139.885 71.5889 141.497 69.979 143.486 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 69.979C155.447 69.979 157.059 71.5889 157.059 73.5747C157.059 75.5606 155.447 77.1705 153.458 77.1705C151.469 77.1705 149.857 75.5606 149.857 73.5747C149.857 71.5889 151.469 69.979 153.458 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 69.979C165.419 69.979 167.031 71.5889 167.031 73.5747C167.031 75.5606 165.419 77.1705 163.43 77.1705C161.442 77.1705 159.829 75.5606 159.829 73.5747C159.829 71.5889 161.442 69.979 163.43 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 69.979C175.392 69.979 177.004 71.5889 177.004 73.5747C177.004 75.5606 175.392 77.1705 173.403 77.1705C171.414 77.1705 169.802 75.5606 169.802 73.5747C169.802 71.5889 171.414 69.979 173.403 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 69.979C185.363 69.979 186.975 71.5889 186.975 73.5747C186.975 75.5606 185.363 77.1705 183.374 77.1705C181.385 77.1705 179.773 75.5606 179.773 73.5747C179.773 71.5889 181.385 69.979 183.374 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 69.979C195.612 69.979 197.225 71.5889 197.225 73.5747C197.225 75.5606 195.612 77.1705 193.624 77.1705C191.635 77.1705 190.023 75.5606 190.023 73.5747C190.023 71.5889 191.635 69.979 193.624 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 69.979C205.584 69.979 207.196 71.5889 207.196 73.5747C207.196 75.5606 205.584 77.1705 203.595 77.1705C201.606 77.1705 199.994 75.5606 199.994 73.5747C199.994 71.5889 201.606 69.979 203.595 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 69.979C215.556 69.979 217.168 71.5889 217.168 73.5747C217.168 75.5606 215.556 77.1705 213.567 77.1705C211.578 77.1705 209.966 75.5606 209.966 73.5747C209.966 71.5889 211.578 69.979 213.567 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.539 69.979C225.528 69.979 227.14 71.5889 227.14 73.5747C227.14 75.5606 225.528 77.1705 223.539 77.1705C221.55 77.1705 219.938 75.5606 219.938 73.5747C219.938 71.5889 221.55 69.979 223.539 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 69.979C235.5 69.979 237.112 71.5889 237.112 73.5747C237.112 75.5606 235.5 77.1705 233.511 77.1705C231.523 77.1705 229.91 75.5606 229.91 73.5747C229.91 71.5889 231.523 69.979 233.511 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 69.979C245.471 69.979 247.084 71.5889 247.084 73.5747C247.084 75.5606 245.471 77.1705 243.482 77.1705C241.494 77.1705 239.881 75.5606 239.881 73.5747C239.881 71.5889 241.494 69.979 243.482 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M253.455 69.979C255.444 69.979 257.056 71.5889 257.056 73.5747C257.056 75.5606 255.444 77.1705 253.455 77.1705C251.466 77.1705 249.854 75.5606 249.854 73.5747C249.854 71.5889 251.466 69.979 253.455 69.979Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5728 79.9353C15.5616 79.9353 17.1739 81.5452 17.1739 83.531C17.1739 85.5169 15.5616 87.1268 13.5728 87.1268C11.5841 87.1268 9.9718 85.5169 9.9718 83.531C9.9718 81.5452 11.5841 79.9353 13.5728 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 79.9353C25.5339 79.9353 27.1462 81.5452 27.1462 83.531C27.1462 85.5169 25.5339 87.1268 23.5452 87.1268C21.5564 87.1268 19.9442 85.5169 19.9442 83.531C19.9442 81.5452 21.5564 79.9353 23.5452 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 79.9353C35.505 79.9353 37.1173 81.5452 37.1173 83.531C37.1173 85.5169 35.505 87.1268 33.5163 87.1268C31.5276 87.1268 29.9153 85.5169 29.9153 83.531C29.9153 81.5452 31.5276 79.9353 33.5163 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 79.9353C45.4774 79.9353 47.0897 81.5452 47.0897 83.531C47.0897 85.5169 45.4774 87.1268 43.4887 87.1268C41.4999 87.1268 39.8876 85.5169 39.8876 83.531C39.8876 81.5452 41.4999 79.9353 43.4887 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 79.9353C55.4497 79.9353 57.062 81.5452 57.062 83.531C57.062 85.5169 55.4497 87.1268 53.461 87.1268C51.4717 87.1268 49.8599 85.5169 49.8599 83.531C49.8599 81.5452 51.4717 79.9353 53.461 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 79.9353C65.4215 79.9353 67.0338 81.5452 67.0338 83.531C67.0338 85.5169 65.4215 87.1268 63.4328 87.1268C61.444 87.1268 59.8317 85.5169 59.8317 83.531C59.8317 81.5452 61.444 79.9353 63.4328 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 79.9353C75.3938 79.9353 77.0061 81.5452 77.0061 83.531C77.0061 85.5169 75.3938 87.1268 73.4051 87.1268C71.4164 87.1268 69.8041 85.5169 69.8041 83.531C69.8041 81.5452 71.4164 79.9353 73.4051 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 79.9353C85.3661 79.9353 86.9784 81.5452 86.9784 83.531C86.9784 85.5169 85.3661 87.1268 83.3774 87.1268C81.3887 87.1268 79.7764 85.5169 79.7764 83.531C79.7764 81.5452 81.3887 79.9353 83.3774 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 79.9353C95.6152 79.9353 97.227 81.5452 97.227 83.531C97.227 85.5169 95.6152 87.1268 93.6259 87.1268C91.6372 87.1268 90.0249 85.5169 90.0249 83.531C90.0249 81.5452 91.6372 79.9353 93.6259 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 79.9353C105.587 79.9353 107.199 81.5452 107.199 83.531C107.199 85.5169 105.587 87.1268 103.598 87.1268C101.609 87.1268 99.9972 85.5169 99.9972 83.531C99.9972 81.5452 101.609 79.9353 103.598 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 79.9353C115.558 79.9353 117.17 81.5452 117.17 83.531C117.17 85.5169 115.558 87.1268 113.569 87.1268C111.581 87.1268 109.968 85.5169 109.968 83.531C109.968 81.5452 111.581 79.9353 113.569 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 79.9353C125.531 79.9353 127.143 81.5452 127.143 83.531C127.143 85.5169 125.531 87.1268 123.542 87.1268C121.553 87.1268 119.941 85.5169 119.941 83.531C119.941 81.5452 121.553 79.9353 123.542 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 79.9353C135.503 79.9353 137.115 81.5452 137.115 83.531C137.115 85.5169 135.503 87.1268 133.514 87.1268C131.525 87.1268 129.913 85.5169 129.913 83.531C129.913 81.5452 131.525 79.9353 133.514 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 79.9353C145.475 79.9353 147.087 81.5452 147.087 83.531C147.087 85.5169 145.475 87.1268 143.486 87.1268C141.497 87.1268 139.885 85.5169 139.885 83.531C139.885 81.5452 141.497 79.9353 143.486 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 79.9353C155.447 79.9353 157.059 81.5452 157.059 83.531C157.059 85.5169 155.447 87.1268 153.458 87.1268C151.469 87.1268 149.857 85.5169 149.857 83.531C149.857 81.5452 151.469 79.9353 153.458 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 79.9353C165.419 79.9353 167.031 81.5452 167.031 83.531C167.031 85.5169 165.419 87.1268 163.43 87.1268C161.442 87.1268 159.829 85.5169 159.829 83.531C159.829 81.5452 161.442 79.9353 163.43 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 79.9353C175.392 79.9353 177.004 81.5452 177.004 83.531C177.004 85.5169 175.392 87.1268 173.403 87.1268C171.414 87.1268 169.802 85.5169 169.802 83.531C169.802 81.5452 171.414 79.9353 173.403 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 79.9353C185.363 79.9353 186.975 81.5452 186.975 83.531C186.975 85.5169 185.363 87.1268 183.374 87.1268C181.385 87.1268 179.773 85.5169 179.773 83.531C179.773 81.5452 181.385 79.9353 183.374 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 79.9353C195.612 79.9353 197.225 81.5452 197.225 83.531C197.225 85.5169 195.612 87.1268 193.624 87.1268C191.635 87.1268 190.023 85.5169 190.023 83.531C190.023 81.5452 191.635 79.9353 193.624 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 79.9353C205.584 79.9353 207.196 81.5452 207.196 83.531C207.196 85.5169 205.584 87.1268 203.595 87.1268C201.606 87.1268 199.994 85.5169 199.994 83.531C199.994 81.5452 201.606 79.9353 203.595 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 79.9353C215.556 79.9353 217.168 81.5452 217.168 83.531C217.168 85.5169 215.556 87.1268 213.567 87.1268C211.578 87.1268 209.966 85.5169 209.966 83.531C209.966 81.5452 211.578 79.9353 213.567 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.539 79.9353C225.528 79.9353 227.14 81.5452 227.14 83.531C227.14 85.5169 225.528 87.1268 223.539 87.1268C221.55 87.1268 219.938 85.5169 219.938 83.531C219.938 81.5452 221.55 79.9353 223.539 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 79.9353C235.5 79.9353 237.112 81.5452 237.112 83.531C237.112 85.5169 235.5 87.1268 233.511 87.1268C231.523 87.1268 229.91 85.5169 229.91 83.531C229.91 81.5452 231.523 79.9353 233.511 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 79.9353C245.471 79.9353 247.084 81.5452 247.084 83.531C247.084 85.5169 245.471 87.1268 243.482 87.1268C241.494 87.1268 239.881 85.5169 239.881 83.531C239.881 81.5452 241.494 79.9353 243.482 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M253.455 79.9353C255.444 79.9353 257.056 81.5452 257.056 83.531C257.056 85.5169 255.444 87.1268 253.455 87.1268C251.466 87.1268 249.854 85.5169 249.854 83.531C249.854 81.5452 251.466 79.9353 253.455 79.9353Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 89.8916C25.5339 89.8916 27.1462 91.5015 27.1462 93.4873C27.1462 95.4731 25.5339 97.0831 23.5452 97.0831C21.5564 97.0831 19.9442 95.4731 19.9442 93.4873C19.9442 91.5015 21.5564 89.8916 23.5452 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 89.8916C35.505 89.8916 37.1173 91.5015 37.1173 93.4873C37.1173 95.4731 35.505 97.0831 33.5163 97.0831C31.5276 97.0831 29.9153 95.4731 29.9153 93.4873C29.9153 91.5015 31.5276 89.8916 33.5163 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 89.8916C45.4774 89.8916 47.0897 91.5015 47.0897 93.4873C47.0897 95.4731 45.4774 97.0831 43.4887 97.0831C41.4999 97.0831 39.8876 95.4731 39.8876 93.4873C39.8876 91.5015 41.4999 89.8916 43.4887 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 89.8916C55.4497 89.8916 57.062 91.5015 57.062 93.4873C57.062 95.4731 55.4497 97.0831 53.461 97.0831C51.4717 97.0831 49.8599 95.4731 49.8599 93.4873C49.8599 91.5015 51.4717 89.8916 53.461 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 89.8916C65.4215 89.8916 67.0338 91.5015 67.0338 93.4873C67.0338 95.4731 65.4215 97.0831 63.4328 97.0831C61.444 97.0831 59.8317 95.4731 59.8317 93.4873C59.8317 91.5015 61.444 89.8916 63.4328 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 89.8916C75.3938 89.8916 77.0061 91.5015 77.0061 93.4873C77.0061 95.4731 75.3938 97.0831 73.4051 97.0831C71.4164 97.0831 69.8041 95.4731 69.8041 93.4873C69.8041 91.5015 71.4164 89.8916 73.4051 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 89.8916C85.3661 89.8916 86.9784 91.5015 86.9784 93.4873C86.9784 95.4731 85.3661 97.0831 83.3774 97.0831C81.3887 97.0831 79.7764 95.4731 79.7764 93.4873C79.7764 91.5015 81.3887 89.8916 83.3774 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 89.8916C95.6152 89.8916 97.227 91.5015 97.227 93.4873C97.227 95.4731 95.6152 97.0831 93.6259 97.0831C91.6372 97.0831 90.0249 95.4731 90.0249 93.4873C90.0249 91.5015 91.6372 89.8916 93.6259 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 89.8916C105.587 89.8916 107.199 91.5015 107.199 93.4873C107.199 95.4731 105.587 97.0831 103.598 97.0831C101.609 97.0831 99.9972 95.4731 99.9972 93.4873C99.9972 91.5015 101.609 89.8916 103.598 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 89.8916C115.558 89.8916 117.17 91.5015 117.17 93.4873C117.17 95.4731 115.558 97.0831 113.569 97.0831C111.581 97.0831 109.968 95.4731 109.968 93.4873C109.968 91.5015 111.581 89.8916 113.569 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 89.8916C125.531 89.8916 127.143 91.5015 127.143 93.4873C127.143 95.4731 125.531 97.0831 123.542 97.0831C121.553 97.0831 119.941 95.4731 119.941 93.4873C119.941 91.5015 121.553 89.8916 123.542 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 89.8916C135.503 89.8916 137.115 91.5015 137.115 93.4873C137.115 95.4731 135.503 97.0831 133.514 97.0831C131.525 97.0831 129.913 95.4731 129.913 93.4873C129.913 91.5015 131.525 89.8916 133.514 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 89.8916C145.475 89.8916 147.087 91.5015 147.087 93.4873C147.087 95.4731 145.475 97.0831 143.486 97.0831C141.497 97.0831 139.885 95.4731 139.885 93.4873C139.885 91.5015 141.497 89.8916 143.486 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 89.8916C155.447 89.8916 157.059 91.5015 157.059 93.4873C157.059 95.4731 155.447 97.0831 153.458 97.0831C151.469 97.0831 149.857 95.4731 149.857 93.4873C149.857 91.5015 151.469 89.8916 153.458 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 89.8916C165.419 89.8916 167.031 91.5015 167.031 93.4873C167.031 95.4731 165.419 97.0831 163.43 97.0831C161.442 97.0831 159.829 95.4731 159.829 93.4873C159.829 91.5015 161.442 89.8916 163.43 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 89.8916C175.392 89.8916 177.004 91.5015 177.004 93.4873C177.004 95.4731 175.392 97.0831 173.403 97.0831C171.414 97.0831 169.802 95.4731 169.802 93.4873C169.802 91.5015 171.414 89.8916 173.403 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 89.8916C185.363 89.8916 186.975 91.5015 186.975 93.4873C186.975 95.4731 185.363 97.0831 183.374 97.0831C181.385 97.0831 179.773 95.4731 179.773 93.4873C179.773 91.5015 181.385 89.8916 183.374 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 89.8916C195.612 89.8916 197.225 91.5015 197.225 93.4873C197.225 95.4731 195.612 97.0831 193.624 97.0831C191.635 97.0831 190.023 95.4731 190.023 93.4873C190.023 91.5015 191.635 89.8916 193.624 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 89.8916C205.584 89.8916 207.196 91.5015 207.196 93.4873C207.196 95.4731 205.584 97.0831 203.595 97.0831C201.606 97.0831 199.994 95.4731 199.994 93.4873C199.994 91.5015 201.606 89.8916 203.595 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 89.8916C215.556 89.8916 217.168 91.5015 217.168 93.4873C217.168 95.4731 215.556 97.0831 213.567 97.0831C211.578 97.0831 209.966 95.4731 209.966 93.4873C209.966 91.5015 211.578 89.8916 213.567 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.539 89.8916C225.528 89.8916 227.14 91.5015 227.14 93.4873C227.14 95.4731 225.528 97.0831 223.539 97.0831C221.55 97.0831 219.938 95.4731 219.938 93.4873C219.938 91.5015 221.55 89.8916 223.539 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 89.8916C235.5 89.8916 237.112 91.5015 237.112 93.4873C237.112 95.4731 235.5 97.0831 233.511 97.0831C231.523 97.0831 229.91 95.4731 229.91 93.4873C229.91 91.5015 231.523 89.8916 233.511 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 89.8916C245.471 89.8916 247.084 91.5015 247.084 93.4873C247.084 95.4731 245.471 97.0831 243.482 97.0831C241.494 97.0831 239.881 95.4731 239.881 93.4873C239.881 91.5015 241.494 89.8916 243.482 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M253.455 89.8916C255.444 89.8916 257.056 91.5015 257.056 93.4873C257.056 95.4731 255.444 97.0831 253.455 97.0831C251.466 97.0831 249.854 95.4731 249.854 93.4873C249.854 91.5015 251.466 89.8916 253.455 89.8916Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.5452 99.8503C25.5339 99.8503 27.1462 101.46 27.1462 103.446C27.1462 105.432 25.5339 107.042 23.5452 107.042C21.5564 107.042 19.9442 105.432 19.9442 103.446C19.9442 101.46 21.5564 99.8503 23.5452 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 99.8503C35.505 99.8503 37.1173 101.46 37.1173 103.446C37.1173 105.432 35.505 107.042 33.5163 107.042C31.5276 107.042 29.9153 105.432 29.9153 103.446C29.9153 101.46 31.5276 99.8503 33.5163 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 99.8503C45.4774 99.8503 47.0897 101.46 47.0897 103.446C47.0897 105.432 45.4774 107.042 43.4887 107.042C41.4999 107.042 39.8876 105.432 39.8876 103.446C39.8876 101.46 41.4999 99.8503 43.4887 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 99.8503C55.4497 99.8503 57.062 101.46 57.062 103.446C57.062 105.432 55.4497 107.042 53.461 107.042C51.4717 107.042 49.8599 105.432 49.8599 103.446C49.8599 101.46 51.4717 99.8503 53.461 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 99.8503C65.4215 99.8503 67.0338 101.46 67.0338 103.446C67.0338 105.432 65.4215 107.042 63.4328 107.042C61.444 107.042 59.8317 105.432 59.8317 103.446C59.8317 101.46 61.444 99.8503 63.4328 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 99.8503C75.3938 99.8503 77.0061 101.46 77.0061 103.446C77.0061 105.432 75.3938 107.042 73.4051 107.042C71.4164 107.042 69.8041 105.432 69.8041 103.446C69.8041 101.46 71.4164 99.8503 73.4051 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 99.8503C85.3661 99.8503 86.9784 101.46 86.9784 103.446C86.9784 105.432 85.3661 107.042 83.3774 107.042C81.3887 107.042 79.7764 105.432 79.7764 103.446C79.7764 101.46 81.3887 99.8503 83.3774 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 99.8503C95.6152 99.8503 97.227 101.46 97.227 103.446C97.227 105.432 95.6152 107.042 93.6259 107.042C91.6372 107.042 90.0249 105.432 90.0249 103.446C90.0249 101.46 91.6372 99.8503 93.6259 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 99.8503C105.587 99.8503 107.199 101.46 107.199 103.446C107.199 105.432 105.587 107.042 103.598 107.042C101.609 107.042 99.9972 105.432 99.9972 103.446C99.9972 101.46 101.609 99.8503 103.598 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 99.8503C115.558 99.8503 117.17 101.46 117.17 103.446C117.17 105.432 115.558 107.042 113.569 107.042C111.581 107.042 109.968 105.432 109.968 103.446C109.968 101.46 111.581 99.8503 113.569 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 99.8503C125.531 99.8503 127.143 101.46 127.143 103.446C127.143 105.432 125.531 107.042 123.542 107.042C121.553 107.042 119.941 105.432 119.941 103.446C119.941 101.46 121.553 99.8503 123.542 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 99.8503C135.503 99.8503 137.115 101.46 137.115 103.446C137.115 105.432 135.503 107.042 133.514 107.042C131.525 107.042 129.913 105.432 129.913 103.446C129.913 101.46 131.525 99.8503 133.514 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 99.8503C145.475 99.8503 147.087 101.46 147.087 103.446C147.087 105.432 145.475 107.042 143.486 107.042C141.497 107.042 139.885 105.432 139.885 103.446C139.885 101.46 141.497 99.8503 143.486 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 99.8503C155.447 99.8503 157.059 101.46 157.059 103.446C157.059 105.432 155.447 107.042 153.458 107.042C151.469 107.042 149.857 105.432 149.857 103.446C149.857 101.46 151.469 99.8503 153.458 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 99.8503C165.419 99.8503 167.031 101.46 167.031 103.446C167.031 105.432 165.419 107.042 163.43 107.042C161.442 107.042 159.829 105.432 159.829 103.446C159.829 101.46 161.442 99.8503 163.43 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 99.8503C175.392 99.8503 177.004 101.46 177.004 103.446C177.004 105.432 175.392 107.042 173.403 107.042C171.414 107.042 169.802 105.432 169.802 103.446C169.802 101.46 171.414 99.8503 173.403 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 99.8503C185.363 99.8503 186.975 101.46 186.975 103.446C186.975 105.432 185.363 107.042 183.374 107.042C181.385 107.042 179.773 105.432 179.773 103.446C179.773 101.46 181.385 99.8503 183.374 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 99.8503C195.612 99.8503 197.225 101.46 197.225 103.446C197.225 105.432 195.612 107.042 193.624 107.042C191.635 107.042 190.023 105.432 190.023 103.446C190.023 101.46 191.635 99.8503 193.624 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 99.8503C205.584 99.8503 207.196 101.46 207.196 103.446C207.196 105.432 205.584 107.042 203.595 107.042C201.606 107.042 199.994 105.432 199.994 103.446C199.994 101.46 201.606 99.8503 203.595 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 99.8503C215.556 99.8503 217.168 101.46 217.168 103.446C217.168 105.432 215.556 107.042 213.567 107.042C211.578 107.042 209.966 105.432 209.966 103.446C209.966 101.46 211.578 99.8503 213.567 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.539 99.8503C225.528 99.8503 227.14 101.46 227.14 103.446C227.14 105.432 225.528 107.042 223.539 107.042C221.55 107.042 219.938 105.432 219.938 103.446C219.938 101.46 221.55 99.8503 223.539 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 99.8503C235.5 99.8503 237.112 101.46 237.112 103.446C237.112 105.432 235.5 107.042 233.511 107.042C231.523 107.042 229.91 105.432 229.91 103.446C229.91 101.46 231.523 99.8503 233.511 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 99.8503C245.471 99.8503 247.084 101.46 247.084 103.446C247.084 105.432 245.471 107.042 243.482 107.042C241.494 107.042 239.881 105.432 239.881 103.446C239.881 101.46 241.494 99.8503 243.482 99.8503Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M33.5163 109.806C35.505 109.806 37.1173 111.416 37.1173 113.402C37.1173 115.388 35.505 116.998 33.5163 116.998C31.5276 116.998 29.9153 115.388 29.9153 113.402C29.9153 111.416 31.5276 109.806 33.5163 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.4887 109.806C45.4774 109.806 47.0897 111.416 47.0897 113.402C47.0897 115.388 45.4774 116.998 43.4887 116.998C41.4999 116.998 39.8876 115.388 39.8876 113.402C39.8876 111.416 41.4999 109.806 43.4887 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M53.461 109.806C55.4497 109.806 57.062 111.416 57.062 113.402C57.062 115.388 55.4497 116.998 53.461 116.998C51.4717 116.998 49.8599 115.388 49.8599 113.402C49.8599 111.416 51.4717 109.806 53.461 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 109.806C65.4215 109.806 67.0338 111.416 67.0338 113.402C67.0338 115.388 65.4215 116.998 63.4328 116.998C61.444 116.998 59.8317 115.388 59.8317 113.402C59.8317 111.416 61.444 109.806 63.4328 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 109.806C75.3938 109.806 77.0061 111.416 77.0061 113.402C77.0061 115.388 75.3938 116.998 73.4051 116.998C71.4164 116.998 69.8041 115.388 69.8041 113.402C69.8041 111.416 71.4164 109.806 73.4051 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 109.806C85.3661 109.806 86.9784 111.416 86.9784 113.402C86.9784 115.388 85.3661 116.998 83.3774 116.998C81.3887 116.998 79.7764 115.388 79.7764 113.402C79.7764 111.416 81.3887 109.806 83.3774 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 109.806C95.6152 109.806 97.227 111.416 97.227 113.402C97.227 115.388 95.6152 116.998 93.6259 116.998C91.6372 116.998 90.0249 115.388 90.0249 113.402C90.0249 111.416 91.6372 109.806 93.6259 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 109.806C105.587 109.806 107.199 111.416 107.199 113.402C107.199 115.388 105.587 116.998 103.598 116.998C101.609 116.998 99.9972 115.388 99.9972 113.402C99.9972 111.416 101.609 109.806 103.598 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 109.806C115.558 109.806 117.17 111.416 117.17 113.402C117.17 115.388 115.558 116.998 113.569 116.998C111.581 116.998 109.968 115.388 109.968 113.402C109.968 111.416 111.581 109.806 113.569 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 109.806C125.531 109.806 127.143 111.416 127.143 113.402C127.143 115.388 125.531 116.998 123.542 116.998C121.553 116.998 119.941 115.388 119.941 113.402C119.941 111.416 121.553 109.806 123.542 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 109.806C135.503 109.806 137.115 111.416 137.115 113.402C137.115 115.388 135.503 116.998 133.514 116.998C131.525 116.998 129.913 115.388 129.913 113.402C129.913 111.416 131.525 109.806 133.514 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 109.806C145.475 109.806 147.087 111.416 147.087 113.402C147.087 115.388 145.475 116.998 143.486 116.998C141.497 116.998 139.885 115.388 139.885 113.402C139.885 111.416 141.497 109.806 143.486 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 109.806C155.447 109.806 157.059 111.416 157.059 113.402C157.059 115.388 155.447 116.998 153.458 116.998C151.469 116.998 149.857 115.388 149.857 113.402C149.857 111.416 151.469 109.806 153.458 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 109.806C165.419 109.806 167.031 111.416 167.031 113.402C167.031 115.388 165.419 116.998 163.43 116.998C161.442 116.998 159.829 115.388 159.829 113.402C159.829 111.416 161.442 109.806 163.43 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 109.806C175.392 109.806 177.004 111.416 177.004 113.402C177.004 115.388 175.392 116.998 173.403 116.998C171.414 116.998 169.802 115.388 169.802 113.402C169.802 111.416 171.414 109.806 173.403 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 109.806C185.363 109.806 186.975 111.416 186.975 113.402C186.975 115.388 185.363 116.998 183.374 116.998C181.385 116.998 179.773 115.388 179.773 113.402C179.773 111.416 181.385 109.806 183.374 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 109.806C195.612 109.806 197.225 111.416 197.225 113.402C197.225 115.388 195.612 116.998 193.624 116.998C191.635 116.998 190.023 115.388 190.023 113.402C190.023 111.416 191.635 109.806 193.624 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 109.806C205.584 109.806 207.196 111.416 207.196 113.402C207.196 115.388 205.584 116.998 203.595 116.998C201.606 116.998 199.994 115.388 199.994 113.402C199.994 111.416 201.606 109.806 203.595 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 109.806C215.556 109.806 217.168 111.416 217.168 113.402C217.168 115.388 215.556 116.998 213.567 116.998C211.578 116.998 209.966 115.388 209.966 113.402C209.966 111.416 211.578 109.806 213.567 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.539 109.806C225.528 109.806 227.14 111.416 227.14 113.402C227.14 115.388 225.528 116.998 223.539 116.998C221.55 116.998 219.938 115.388 219.938 113.402C219.938 111.416 221.55 109.806 223.539 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 109.806C235.5 109.806 237.112 111.416 237.112 113.402C237.112 115.388 235.5 116.998 233.511 116.998C231.523 116.998 229.91 115.388 229.91 113.402C229.91 111.416 231.523 109.806 233.511 109.806Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.4328 119.765C65.4215 119.765 67.0338 121.375 67.0338 123.361C67.0338 125.346 65.4215 126.956 63.4328 126.956C61.444 126.956 59.8317 125.346 59.8317 123.361C59.8317 121.375 61.444 119.765 63.4328 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.4051 119.765C75.3938 119.765 77.0061 121.375 77.0061 123.361C77.0061 125.346 75.3938 126.956 73.4051 126.956C71.4164 126.956 69.8041 125.346 69.8041 123.361C69.8041 121.375 71.4164 119.765 73.4051 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.3774 119.765C85.3661 119.765 86.9784 121.375 86.9784 123.361C86.9784 125.346 85.3661 126.956 83.3774 126.956C81.3887 126.956 79.7764 125.346 79.7764 123.361C79.7764 121.375 81.3887 119.765 83.3774 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M93.6259 119.765C95.6152 119.765 97.227 121.375 97.227 123.361C97.227 125.346 95.6152 126.956 93.6259 126.956C91.6372 126.956 90.0249 125.346 90.0249 123.361C90.0249 121.375 91.6372 119.765 93.6259 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 119.765C105.587 119.765 107.199 121.375 107.199 123.361C107.199 125.346 105.587 126.956 103.598 126.956C101.609 126.956 99.9972 125.346 99.9972 123.361C99.9972 121.375 101.609 119.765 103.598 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 119.765C115.558 119.765 117.17 121.375 117.17 123.361C117.17 125.346 115.558 126.956 113.569 126.956C111.581 126.956 109.968 125.346 109.968 123.361C109.968 121.375 111.581 119.765 113.569 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 119.765C125.531 119.765 127.143 121.375 127.143 123.361C127.143 125.346 125.531 126.956 123.542 126.956C121.553 126.956 119.941 125.346 119.941 123.361C119.941 121.375 121.553 119.765 123.542 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 119.765C135.503 119.765 137.115 121.375 137.115 123.361C137.115 125.346 135.503 126.956 133.514 126.956C131.525 126.956 129.913 125.346 129.913 123.361C129.913 121.375 131.525 119.765 133.514 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 119.765C145.475 119.765 147.087 121.375 147.087 123.361C147.087 125.346 145.475 126.956 143.486 126.956C141.497 126.956 139.885 125.346 139.885 123.361C139.885 121.375 141.497 119.765 143.486 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 119.765C155.447 119.765 157.059 121.375 157.059 123.361C157.059 125.346 155.447 126.956 153.458 126.956C151.469 126.956 149.857 125.346 149.857 123.361C149.857 121.375 151.469 119.765 153.458 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 119.765C165.419 119.765 167.031 121.375 167.031 123.361C167.031 125.346 165.419 126.956 163.43 126.956C161.442 126.956 159.829 125.346 159.829 123.361C159.829 121.375 161.442 119.765 163.43 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 119.765C175.392 119.765 177.004 121.375 177.004 123.361C177.004 125.346 175.392 126.956 173.403 126.956C171.414 126.956 169.802 125.346 169.802 123.361C169.802 121.375 171.414 119.765 173.403 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 119.765C185.363 119.765 186.975 121.375 186.975 123.361C186.975 125.346 185.363 126.956 183.374 126.956C181.385 126.956 179.773 125.346 179.773 123.361C179.773 121.375 181.385 119.765 183.374 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 119.765C195.612 119.765 197.225 121.375 197.225 123.361C197.225 125.346 195.612 126.956 193.624 126.956C191.635 126.956 190.023 125.346 190.023 123.361C190.023 121.375 191.635 119.765 193.624 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M203.595 119.765C205.584 119.765 207.196 121.375 207.196 123.361C207.196 125.346 205.584 126.956 203.595 126.956C201.606 126.956 199.994 125.346 199.994 123.361C199.994 121.375 201.606 119.765 203.595 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 119.765C215.556 119.765 217.168 121.375 217.168 123.361C217.168 125.346 215.556 126.956 213.567 126.956C211.578 126.956 209.966 125.346 209.966 123.361C209.966 121.375 211.578 119.765 213.567 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.539 119.765C225.528 119.765 227.14 121.375 227.14 123.361C227.14 125.346 225.528 126.956 223.539 126.956C221.55 126.956 219.938 125.346 219.938 123.361C219.938 121.375 221.55 119.765 223.539 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 119.765C235.5 119.765 237.112 121.375 237.112 123.361C237.112 125.346 235.5 126.956 233.511 126.956C231.523 126.956 229.91 125.346 229.91 123.361C229.91 121.375 231.523 119.765 233.511 119.765Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.598 129.721C105.587 129.721 107.199 131.331 107.199 133.317C107.199 135.303 105.587 136.913 103.598 136.913C101.609 136.913 99.9972 135.303 99.9972 133.317C99.9972 131.331 101.609 129.721 103.598 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M113.569 129.721C115.558 129.721 117.17 131.331 117.17 133.317C117.17 135.303 115.558 136.913 113.569 136.913C111.581 136.913 109.968 135.303 109.968 133.317C109.968 131.331 111.581 129.721 113.569 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 129.721C125.531 129.721 127.143 131.331 127.143 133.317C127.143 135.303 125.531 136.913 123.542 136.913C121.553 136.913 119.941 135.303 119.941 133.317C119.941 131.331 121.553 129.721 123.542 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 129.721C135.503 129.721 137.115 131.331 137.115 133.317C137.115 135.303 135.503 136.913 133.514 136.913C131.525 136.913 129.913 135.303 129.913 133.317C129.913 131.331 131.525 129.721 133.514 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 129.721C145.475 129.721 147.087 131.331 147.087 133.317C147.087 135.303 145.475 136.913 143.486 136.913C141.497 136.913 139.885 135.303 139.885 133.317C139.885 131.331 141.497 129.721 143.486 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 129.721C155.447 129.721 157.059 131.331 157.059 133.317C157.059 135.303 155.447 136.913 153.458 136.913C151.469 136.913 149.857 135.303 149.857 133.317C149.857 131.331 151.469 129.721 153.458 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M163.43 129.721C165.419 129.721 167.031 131.331 167.031 133.317C167.031 135.303 165.419 136.913 163.43 136.913C161.442 136.913 159.829 135.303 159.829 133.317C159.829 131.331 161.442 129.721 163.43 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.403 129.721C175.392 129.721 177.004 131.331 177.004 133.317C177.004 135.303 175.392 136.913 173.403 136.913C171.414 136.913 169.802 135.303 169.802 133.317C169.802 131.331 171.414 129.721 173.403 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 129.721C185.363 129.721 186.975 131.331 186.975 133.317C186.975 135.303 185.363 136.913 183.374 136.913C181.385 136.913 179.773 135.303 179.773 133.317C179.773 131.331 181.385 129.721 183.374 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M193.624 129.721C195.612 129.721 197.225 131.331 197.225 133.317C197.225 135.303 195.612 136.913 193.624 136.913C191.635 136.913 190.023 135.303 190.023 133.317C190.023 131.331 191.635 129.721 193.624 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M213.567 129.721C215.556 129.721 217.168 131.331 217.168 133.317C217.168 135.303 215.556 136.913 213.567 136.913C211.578 136.913 209.966 135.303 209.966 133.317C209.966 131.331 211.578 129.721 213.567 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M223.539 129.721C225.528 129.721 227.14 131.331 227.14 133.317C227.14 135.303 225.528 136.913 223.539 136.913C221.55 136.913 219.938 135.303 219.938 133.317C219.938 131.331 221.55 129.721 223.539 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 129.721C235.5 129.721 237.112 131.331 237.112 133.317C237.112 135.303 235.5 136.913 233.511 136.913C231.523 136.913 229.91 135.303 229.91 133.317C229.91 131.331 231.523 129.721 233.511 129.721Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M123.542 139.956C125.531 139.956 127.143 141.566 127.143 143.551C127.143 145.538 125.531 147.147 123.542 147.147C121.553 147.147 119.941 145.538 119.941 143.551C119.941 141.566 121.553 139.956 123.542 139.956Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 139.956C135.503 139.956 137.115 141.566 137.115 143.551C137.115 145.538 135.503 147.147 133.514 147.147C131.525 147.147 129.913 145.538 129.913 143.551C129.913 141.566 131.525 139.956 133.514 139.956Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 139.956C145.475 139.956 147.087 141.566 147.087 143.551C147.087 145.538 145.475 147.147 143.486 147.147C141.497 147.147 139.885 145.538 139.885 143.551C139.885 141.566 141.497 139.956 143.486 139.956Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M153.458 139.956C155.447 139.956 157.059 141.566 157.059 143.551C157.059 145.538 155.447 147.147 153.458 147.147C151.469 147.147 149.857 145.538 149.857 143.551C149.857 141.566 151.469 139.956 153.458 139.956Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M183.374 139.956C185.363 139.956 186.975 141.566 186.975 143.551C186.975 145.538 185.363 147.147 183.374 147.147C181.385 147.147 179.773 145.538 179.773 143.551C179.773 141.566 181.385 139.956 183.374 139.956Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.511 139.956C235.5 139.956 237.112 141.566 237.112 143.551C237.112 145.538 235.5 147.147 233.511 147.147C231.523 147.147 229.91 145.538 229.91 143.551C229.91 141.566 231.523 139.956 233.511 139.956Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 139.956C245.471 139.956 247.084 141.566 247.084 143.551C247.084 145.538 245.471 147.147 243.482 147.147C241.494 147.147 239.881 145.538 239.881 143.551C239.881 141.566 241.494 139.956 243.482 139.956Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.514 149.914C135.503 149.914 137.115 151.524 137.115 153.51C137.115 155.496 135.503 157.106 133.514 157.106C131.525 157.106 129.913 155.496 129.913 153.51C129.913 151.524 131.525 149.914 133.514 149.914Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 149.914C145.475 149.914 147.087 151.524 147.087 153.51C147.087 155.496 145.475 157.106 143.486 157.106C141.497 157.106 139.885 155.496 139.885 153.51C139.885 151.524 141.497 149.914 143.486 149.914Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.482 149.914C245.471 149.914 247.084 151.524 247.084 153.51C247.084 155.496 245.471 157.106 243.482 157.106C241.494 157.106 239.881 155.496 239.881 153.51C239.881 151.524 241.494 149.914 243.482 149.914Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M143.486 159.871C145.475 159.871 147.087 161.481 147.087 163.466C147.087 165.452 145.475 167.062 143.486 167.062C141.497 167.062 139.885 165.452 139.885 163.466C139.885 161.481 141.497 159.871 143.486 159.871Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
