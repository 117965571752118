import { Slide01 } from '../../../components/cloud/slide-01';
import { Slide02 } from '../../../components/cloud/slide-02';
import { Slide03 } from '../../../components/cloud/slide-03';
import { Slide04 } from '../../../components/cloud/slide-04';
import { Slide05 } from '../../../components/cloud/slide-05';
import { Slide06 } from '../../../components/cloud/slide-06';
import { Slide07 } from '../../../components/cloud/slide-07';
import { Slide08 } from '../../../components/cloud/slide-08';
import { Slide09 } from '../../../components/cloud/slide-09';
import { Slide10 } from '../../../components/cloud/slide-10';
import { Slide11 } from '../../../components/cloud/slide-11';
import { Slide12 } from '../../../components/cloud/slide-12';
import { Slide13 } from '../../../components/cloud/slide-13';
import { Slide14 } from '../../../components/cloud/slide-14';
import { Slide15 } from '../../../components/cloud/slide-15';
import { Slide16 } from '../../../components/cloud/slide-16';
import { Slide17 } from '../../../components/cloud/slide-17';
import { Eye } from '../../../components/svg/eye';
import { Bell } from '../../../components/svg/bell';
import { Cloud } from '../../../components/svg/cloud';
import { Clock } from '../../../components/svg/clock';

export const CLOUD_CATEGORY = {
  AWS: 'AWS Brute Force',
  INVESTIGATION: 'investigation',
  ESCALATION: 'escalation',
};

export const CLOUD_COLUMNS = [
  [CLOUD_CATEGORY.AWS],
  [CLOUD_CATEGORY.INVESTIGATION],
  [CLOUD_CATEGORY.ESCALATION],
];

export const CLOUD_CATEGORY_ICONS = {
  [CLOUD_CATEGORY.AWS]: Cloud,
  [CLOUD_CATEGORY.INVESTIGATION]: Eye,
  [CLOUD_CATEGORY.ESCALATION]: Bell,
};

export const cloudSlides = [
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'AWS Brute FOrce',
    subtitle: ' Scenario Overview',
    component: Slide01,
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'AWS Brute Force Attack',
    product: 'eSentire MDR for Log',
    time: '00:00:00:00',
    component: Slide02,
    icon: Cloud,
    category: CLOUD_CATEGORY.AWS,
    appScreenshotUrl: require('../../../assets/cloud/cloud-2.jpg'),
    description:
      'Attacker launches a dictionary attack against a client’s AWS instance for a specific username. After numerous attempts, the attacker is able to determine the password and gain access to the AWS instance.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Brute Force Threshold Exceeded',
    product: 'eSentire MDR for Log',
    time: '00:00:00:00',
    component: Slide03,
    // icon: Bug,
    category: CLOUD_CATEGORY.AWS,
    appScreenshotUrl: require('../../../assets/cloud/cloud-3.jpg'),
    description:
      'The attacker attempted 31 passwords before successfully logging in. The threshold of 5 is exceeded and an event is generated into the SOC.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'SOC Investigation Initiated',
    product: 'eSentire MDR for Log',
    time: '00:00:00:00',
    component: Slide04,
    icon: Eye,
    category: CLOUD_CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-4.jpg'),
    description:
      'The SOC reviews the triggered event before diving into investigating AWS log data with eSentire MDR for Log.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'User Login Activity - Login Counts',
    product: 'eSentire MDR for Log',
    time: '00:00:00:30',
    component: Slide05,
    // icon: Bug,
    category: CLOUD_CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-5.jpg'),
    description:
      'The SOC investigates AWS log data with eSentire MDR for Log and focused on the specific username: dthomas.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'User Login Activity - Geolocation',
    product: 'eSentire MDR for Log',
    time: '00:00:01:15',
    component: Slide06,
    // icon: Bug,
    category: CLOUD_CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-6.jpg'),
    description:
      'The SOC uses the last 30 days of login logs for dthomas to determine the common geolocations.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'User Login Activity - Trend',
    product: 'eSentire MDR for Log',
    time: '00:00:01:35',
    component: Slide07,
    // icon: Bug,
    category: CLOUD_CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-7.jpg'),
    description:
      'The SOC uses the last 30 days of login logs for dthomas to determine if the user commonly fails to login.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Client Alert',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:02:15',
    component: Slide08,
    icon: Bell,
    clientCommunication: true,
    category: CLOUD_CATEGORY.ESCALATION,
    // appScreenshotUrl: require('../../../assets/cloud/cloud-8.jpg'),
    description: 'The SOC alerts the client of the activity.',
    emailDemo: {
      title:
        'RE: ESENTIRE ALERT: ESENTIRE CAMBRIDGE - THREAT MALWARE - 10.0.5.38 - Acknowledge Required - Ticket 1263843',
      start: [
        'We have detected an internal host that has been compromised as a result of a successful exploit. [US Federal Agency/CERT Incident Category: CAT 1] ',
        'Please open a Priority 2 Ticket for desktop technicians to investigate the situation. ',
        `
        Recommendations:
        a) Isolate this internal host from the rest of your network immediately. If a forensic investigation is required, do not power off the machine.
        b) Preserve volatile forensic data as required.
        c) Engage in a forensic investigation as necessary.
        d) Re-image the internal host. e) Implement a rule on your firewall to block all traffic with the Remote IP address listed below.
        `,
      ],
      table: [
        ['Local IP:', 'N/A'],
        ['Local Host:', 'AWS-CA'],
        ['Intrusion Vector:', 'Successful Brute Force'],
        [
          'Evidence of Intrusion:',
          "eSentire MDR for Log has detected numerous failed login attempts followed by a successful login for username 'dthomas",
        ],
        ['Remote IP:', '213.112.147.15'],
        ['Remote Hostname/WHOIS:', 'B2-BISP'],
        ['Country of Origin:', 'Sweden'],
        ['Time of Detection:', '2019-01-16 10:56:12 EST'],
      ],
      finish:
        'Please acknowledge receipt of this alert. The SOC will be reaching out as per your escalation policy if acknowledgement is not received.',
    },
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Escalation Initiated',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:03:00',
    component: Slide09,
    // icon: Bug,
    category: CLOUD_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-9.jpg'),
    description: 'Escalator is initiated due to the alert’s severity.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Escalation With Client',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:06:30',
    component: Slide10,
    // icon: Bug,
    clientCommunication: true,
    category: CLOUD_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-10.jpg'),
    description:
      'Initial acknowledgement time is skipped and the client is called immediately. The SOC informs the client that the user password should be reset immediately and the external IP should be blocked. Client asks for a deeper investigation into what activities the attacker performed after the successful login.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Deep Dive - Initial Follow-Up',
    product:
      'eSentire MDR for Endpoint  +  eSentire MDR for Network  +  eSentire MDR for Log',
    time: '00:00:06:45',
    component: Slide11,
    // icon: Bug,
    clientCommunication: true,
    category: CLOUD_CATEGORY.ESCALATION,
    // appScreenshotUrl: require('../../../assets/cloud/cloud-11.jpg'),
    description:
      'The SOC confirms the deep dive with the client to ensure everything was accurately captured during the escalation call.',
    emailDemo: {
      title:
        'ESENTIRE ALERT: ESENTIRE CAMBRIDGE - THREAT INTRUSION - Acknowledgement Required - Ticket 1642351',
      start: [
        'Hey Matt,',
        'Thank you for your time on the call. As discussed, I have opened a ticket for you for the SOC to review any additional activities performed by the malicious attacker.',
        'Please let us know if you have any additional questions or concerns.',
        'Kind Regards,',
        "Jocelyn Guenther I Customer Support Triage Representative\neSentire Inc. — The Industry's only pure-play MDR provider.",
        'Direct-to-SOC Toll Free North America +1 844 552 5837\nDirect-to-SOC Outside of North America +353 21 4757102\nUnited Kingdom Toll Free 0800 044 3242\nInternational +1 519 651 2200 x 5700 ',
      ],
    },
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Deep Dive - Unique Events',
    product: 'eSentire MDR for Log',
    time: '00:00:07:45',
    component: Slide12,
    // icon: Bug,
    category: CLOUD_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-12.jpg'),
    description:
      'The SOC checks all unique event types for the last 24 hours and last 30 days.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Deep Dive - Unique Events',
    product: 'eSentire MDR for Log',
    time: '00:00:08:45',
    component: Slide13,
    // icon: Bug,
    category: CLOUD_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-13.jpg'),
    description:
      'The SOC dives into the 24 query to investigate deeper into the results.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Deep Dive - Unique Events',
    product: 'eSentire MDR for Log',
    time: '00:00:10:30',
    component: Slide14,
    // icon: Bug,
    category: CLOUD_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-14.jpg'),
    description:
      'The SOC reviews the raw log data for the CreateUser event. The attacker created a user called “ev1l-us3r”.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Deep Dive - Created User Activity',
    product: 'eSentire MDR for Log',
    time: '00:00:11:20',
    component: Slide15,
    // icon: Bug,
    category: CLOUD_CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/cloud/cloud-15.jpg'),
    description:
      'The SOC confirms that the “ev1l-us3r” account has not logged into the AWS instance. The SOC enables temporary detection for “ev1l-us3r” logging into the AWS intance while the client remediates.',
  },
  {
    title: 'AWS Brute Force',
    subtitle: 'Deep Dive - Investigation Follow-Up',
    product: 'eSentire MDR for Log',
    time: '00:00:16:20',
    component: Slide16,
    // icon: Bug,
    clientCommunication: true,
    category: CLOUD_CATEGORY.ESCALATION,
    // appScreenshotUrl: require('../../../assets/cloud/cloud-16.jpg'),
    description:
      'The SOC informs the client of the activity and the “ev1l-us3r” account should be disabled / deleted immediately.',
    emailDemo: {
      title:
        'RE: ESENTIRE ALERT: ESENTIRE CAMBRIDGE - THREAT INTRUSION - Acknowledgement Required - Ticket 1642351',
      start: [
        'Hey Matt, ',
        'As an update, find attached a summary of the malicious attacker\'s activity. One main activity to note, the attacker created an account called "evil-us3r". We recommend that this account be disabled / deleted immediately to ensure no further access occurs. No login has been detected with this account and we have implemented detection for any activity pertaining to this account. Let us know once the original account\'s password has been reset and this account has been deleted. This will allow us to adjust the detection mechanism and before a final activity check. ',
        'As always, please let us know if you have any additional questions or concerns. ',
        'Kind Regards,',
        "Troy Gouweloos | Senior Analyst, SOC Customer Care\neSentire Inc. — The Industry's only pure-play MDR provider.",
        'Direct-to-SOC Toll Free North America +1 844 552 5837\nDirect-to-SOC Outside of North America +353 21 4757102\nUnited Kingdom Toll Free 0800 044 3242\nInternational +1 519 651 2200 x 5700 ',
      ],
      finish: [],
    },
  },
  // Timeline
  {
    title: 'AWS Brute Force',
    subtitle: 'Timeline Summary',
    component: Slide17,
    icon: Clock,
    timelineSummary: true,
    category: CLOUD_CATEGORY.ESCALATION,
  },
];
