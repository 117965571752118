/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Cloud extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 18,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 40 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fillOpacity={opacity}
          fillRule="evenodd"
          fill={color}
          clipRule="evenodd"
        >
          <path d="M29.958 10.025C29.627 6.087 27.275 0 20 0C12.723 0 10.371 6.09 10.042 10.027C3.114 10.432 0 15.236 0 20C0 25.635 4.112 29 11 29H29C35.888 29 40 25.635 40 20C40 14.034 36.268 10.358 29.958 10.025Z" />
        </g>
      </svg>
    );
  }
}
