/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './waves.scss';

@observer
export class PlayerWaves extends Component {
  rippleStyles(index) {
    const {
      borderWidth = 3,
      ripple = 40,
      duration = 2000,
      whole = false,
      easing = 'linear',
    } = this.props;

    // Calculate delays between each ripple
    let totalWaves = 4;
    let delay = (duration / totalWaves) * index * -1;

    return {
      width: ripple.toString() + 'px',
      height: ripple.toString() + 'px',
      borderWidth: borderWidth.toString() + 'px',
      top: 'calc(50% - ' + (ripple / 2).toString() + 'px)',
      left: whole
        ? 'calc(50% - ' + (ripple / 2).toString() + 'px)'
        : ((ripple / 2) * -1).toString() + 'px',
      animation: `${duration}ms ${easing} ${delay}ms infinite normal none running waveGrow`,
    };
  }

  render() {
    const { className = '', whole = false, size = 300 } = this.props;

    // Classes
    let classes = ['Player__Waves'];

    // Wrapper defaults
    let wrapperStyles = {};
    wrapperStyles.width = size.toString() + 'px';
    wrapperStyles.height = size.toString() + 'px';

    // If it's the whole circle
    if (whole) {
      classes.push('full-waves');
      wrapperStyles.height = size.toString() + 'px';
    }

    return (
      <div style={wrapperStyles} className={classes.join(' ')}>
        <span style={this.rippleStyles(0)}></span>
        <span style={this.rippleStyles(1)}></span>
        <span style={this.rippleStyles(2)}></span>
        <span style={this.rippleStyles(3)}></span>
      </div>
    );
  }
}
