/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import {
  activate,
  deactivate,
  shimmer,
  activateShimmer,
  getColor,
} from '../helpers';
import ReactDOM from 'react-dom';
import { AnimatedSlide } from '../animated-slide';
import { StoryBlock } from '../story-block';
import { PlayerDemoGoals } from '../players/demo-goals';

// Players
import { PlayerPath } from '../players/path';

// SVG
import { Bell } from '../svg/bell';
import { Eye } from '../svg/eye';
import { Target } from '../svg/target';
import { Proxyshell } from '../svg/proxyshell';
import { Bug } from '../svg/bug';

@observer
export class Slide01 extends AnimatedSlide {
  // Animation players
  players = {
    title1: null,
    title2: null,
    title3: null,
    title4: null,
    title5: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
    description: null,
  };
  titles = [
    {
      color: this.colors.red,
      className: 'active',
      description: (
        <span>
          At Black Hat in August of 2021 a cybersecurity researcher, who had
          originally discovered the ProxyLogon vulnerability in March, presented
          the technical details of additional vulnerabilities impacting
          on-premises Microsoft Exchange servers. Although Microsoft released
          patches for both vulnerabilities, many Microsoft customers were either
          unaware or assumed the patches were the same.
          <br />
          <br />
          Quickly after disclosure of the technical vulnerability details at the
          conference, the eSentire Threat Response Unit (TRU) began observing
          cybercriminals ramping up scanning public IP space for Exchange
          servers vulnerable to this exploit. This risk and the threat it posed
          to eSentire customers was quickly identified by TRU a day after the
          presentation via worldwide scans.
        </span>
      ),
    },
    {
      color: this.colors.red,
      className: '',
      description: (
        <span>
          The TRU team leverages Shodan, an Internet fingerprinting service, to
          determine which eSentire customers may be at risk of exploitation from
          the ProxyShell vulnerability. Based on proactive scanning, 29
          customers are identified as being impacted by this vulnerability
          within only a few days of its release. The TRU and the SOC teams
          coordinate to notify impacted customers and provide recommended
          actions to protect against this vulnerability.
        </span>
      ),
    },
    {
      color: this.colors.blue,
      className: '',
      description: (
        <span>
          Plugins are released on eSentire's Managed Vulnerability Service (MVS)
          for identifying vulnerable assets and ah hoc scans begin for all MVS
          customers. After 24/7 monitoring and investigation for impacted
          customers, the TRU team begins detection engineering enhancements to
          protect all MDR customers from the ProxyShell vulnerability.
        </span>
      ),
    },
    {
      color: this.colors.blue,
      className: '',
      description: (
        <span>
          TRU and SOC cyber analysts coordinate to perform global threat hunts
          for the ProxyShell vulnerability across all customers. This hunt
          confirms the ProxyShell vulnerability could be used for widespread
          exploitation. This information is used to create the new runbook for
          SOC cyber analysts to leverage if there's a positive hit in a customer
          environment for the ProxyShell vulnerability.
        </span>
      ),
    },
    {
      color: this.colors.blue,
      className: '',
      description: (
        <span>
          TRU issues an initial Threat Advisory to all customers notifying them
          of the ProxyShell vulnerability. Ongoing communication and recommended
          actions are provided through additional Threat Advisories. The TRU
          team hosts a live webinar with customers to provide more information.
        </span>
      ),
    },
  ];
  description = this.titles[0].description;

  componentWillUnmount() {
    // Nothing
  }

  // Run the first animation
  componentDidMount() {
    const {
      title1,
      title2,
      title3,
      title4,
      title5,
      path1,
      path2,
      path3,
      path4,
      description,
    } = this.players;

    this.steps = [
      () => {
        this.animateProgress(3000);
        this.autoNext(500);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        this.fadeIn(path1, 1000),
        this.fadeIn(path2, 1500),
        this.fadeIn(path3, 2000),
        this.fadeIn(path4, 2500),
        this.fadeIn(title1, 0),
        this.fadeIn(title2, 1000),
        this.fadeIn(title3, 1500),
        this.fadeIn(title4, 2000),
        this.fadeIn(title5, 2500),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-01 ${className}`}>
        {children}
        <div className="content">
          {/* -------------------CONTENT------------------- */}

          {/* Title 3 */}
          <div
            className="player title1"
            data-active="0"
            ref={node => (this.players.title1 = node)}
          >
            <StoryBlock
              className={this.titles[0].className}
              IconComponent={Bug}
              title={'ProxyShell Vulnerability is Exposed at Black Hat 2021'}
              color={this.titles[0].color}
              description={this.titles[0].description}
            />
          </div>

          {/* Title 2 */}
          <div
            className="player title2"
            data-active="0"
            ref={node => (this.players.title2 = node)}
          >
            <StoryBlock
              className={this.titles[1].className}
              IconComponent={Target}
              title={'TRU Identifies eSentire Customers Are At Risk'}
              color={this.titles[1].color}
              description={this.titles[1].description}
            />
          </div>

          {/* Title 3 */}
          <div
            className="player title3"
            data-active="0"
            ref={node => (this.players.title3 = node)}
          >
            <StoryBlock
              className={this.titles[2].className}
              IconComponent={Eye}
              title={
                'Plugins Are Released and Detection Engineering Enhancements are Pushed to All Customers'
              }
              color={this.titles[2].color}
              description={this.titles[2].description}
            />
          </div>

          {/* Title 4 */}
          <div
            className="player title4"
            data-active="0"
            ref={node => (this.players.title4 = node)}
          >
            <StoryBlock
              className={this.titles[3].className}
              IconComponent={Proxyshell}
              title={
                'Global Threat Hunt for ProxyShell Launched & SOC Runbook Created'
              }
              color={this.titles[3].color}
              description={this.titles[3].description}
            />
          </div>

          {/* Title 5 */}
          <div
            className="player title5"
            data-active="0"
            ref={node => (this.players.title5 = node)}
          >
            <StoryBlock
              className={this.titles[4].className}
              IconComponent={Bell}
              title={'Customer Notification and Live Webinar'}
              color={this.titles[4].color}
              description={this.titles[4].description}
            />
          </div>

          {/* Path 1 */}
          <div
            className="player path1"
            data-active="0"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,0', '0,20']}
              dots={{
                stroke: this.colors.red,
              }}
              reveal={{
                delay: 1500,
                duration: 500,
              }}
            />
          </div>

          {/* Path 2 */}
          <div
            className="player path2"
            data-active="0"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,537', '0,570', '250,570', '250,0', '280,0']}
              gradient={{
                preset: 'blueRed',
                direction: -120,
              }}
              reveal={{
                delay: 2000,
                duration: 1000,
              }}
            />
          </div>

          {/* Path 3 */}
          <div
            className="player path3"
            data-active="0"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,0', '0,20']}
              dots={{
                stroke: this.colors.blue,
              }}
              reveal={{
                delay: 2500,
                duration: 500,
              }}
            />
          </div>

          {/* Path 4 */}
          <div
            className="player path4"
            data-active="0"
            ref={node => (this.players.path4 = node)}
          >
            <PlayerPath
              points={['0,0', '0,20']}
              dots={{
                stroke: this.colors.blue,
              }}
              reveal={{
                delay: 3000,
                duration: 500,
              }}
            />
          </div>

          {/* -------------------CONTENT------------------- */}
        </div>
      </div>
    );
  }
}
