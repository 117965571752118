// Adding this polyfill to allow support for focus-within in IE/Edge
// https://caniuse.com/#feat=css-focus-within
// https://gist.github.com/aFarkas/a7e0d85450f323d5e164
(function(window, document) {
  'use strict';
  let slice = [].slice;
  let removeClass = function(elem) {
    elem.classList.remove('focus-within');
  };
  let update = (function() {
    let running, last;
    let action = function() {
      let element = document.activeElement;
      running = false;
      if (last !== element) {
        last = element;
        slice
          .call(document.getElementsByClassName('focus-within'))
          .forEach(removeClass);
        while (element && element.classList) {
          element.classList.add('focus-within');
          element = element.parentNode;
        }
      }
    };
    return function() {
      if (!running) {
        requestAnimationFrame(action);
        running = true;
      }
    };
  })();
  document.addEventListener('focus', update, true);
  document.addEventListener('blur', update, true);
  update();
})(window, document);
