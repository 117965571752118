import { Slide01 } from '../../../components/kaseya/slide-01';
import { Slide02 } from '../../../components/kaseya/slide-02';
import { Slide03 } from '../../../components/kaseya/slide-03';
import { Slide04 } from '../../../components/kaseya/slide-04';
import { Slide05 } from '../../../components/kaseya/slide-05';
import { Slide06 } from '../../../components/kaseya/slide-06';
import { Slide07 } from '../../../components/kaseya/slide-07';
import { Slide08 } from '../../../components/kaseya/slide-08';
import { Slide09 } from '../../../components/kaseya/slide-09';
import { Slide10 } from '../../../components/kaseya/slide-10';
import { Slide11 } from '../../../components/kaseya/slide-11';
import { Slide12 } from '../../../components/kaseya/slide-12';
import { Slide13 } from '../../../components/kaseya/slide-13';
import { Slide14 } from '../../../components/kaseya/slide-14';
import { Slide15 } from '../../../components/kaseya/slide-15';
import { Slide16 } from '../../../components/kaseya/slide-16';
import { Slide17 } from '../../../components/kaseya/slide-17';
import { Slide18 } from '../../../components/kaseya/slide-18';
import { Slide19 } from '../../../components/kaseya/slide-19';

import { Bug } from '../../../components/svg/bug';
import { Eye } from '../../../components/svg/eye';
import { CheckShield } from '../../../components/svg/check-shield';
import { Bell } from '../../../components/svg/bell';
import { Clock } from '../../../components/svg/clock';
import { Triage } from '../../../components/svg/triage';
import { Person } from '../../../components/svg/person';

export const KASEYA_CATEGORY = {
  STAGE1: 'Identification',
  STAGE2: 'Initial Triage',
  STAGE3: 'Assemble Working Group for Significant Event',
  STAGE4: 'Collection, Threat Hunting and Prevention Activities',
  STAGE5: 'Customer Communication',
  STAGE6: 'Follow-on Collection, Threat Hunting and Prevention Activities',
  STAGE7: 'Follow-on Customer Communications',
};

export const KASEYA_COLUMNS = [
  [KASEYA_CATEGORY.STAGE1, KASEYA_CATEGORY.STAGE2],
  [KASEYA_CATEGORY.STAGE3, KASEYA_CATEGORY.STAGE4, KASEYA_CATEGORY.STAGE5],
  [KASEYA_CATEGORY.STAGE6],
  [KASEYA_CATEGORY.STAGE7],
];

export const KASEYA_CATEGORY_ICONS = {
  [KASEYA_CATEGORY.STAGE1]: Bug,
  [KASEYA_CATEGORY.STAGE2]: Triage,
  [KASEYA_CATEGORY.STAGE3]: Person,
  [KASEYA_CATEGORY.STAGE4]: CheckShield,
  [KASEYA_CATEGORY.STAGE5]: Bell,
  [KASEYA_CATEGORY.STAGE6]: CheckShield,
  [KASEYA_CATEGORY.STAGE7]: Bell,
};

export const kaseyaSlides = [
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Kaseya',
    subtitle: 'Scenario Overview',
    component: Slide01,
  },
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Kaseya',
    subtitle: 'Scenario Overview',
    component: Slide02,
  },
  {
    title: 'Kaseya',
    subtitle:
      "eSentire's TRU Identifies Public Reports Of Active Attacks And Triage Begins",
    time: '00:00:00:00',
    component: Slide03,
    icon: Bug,
    category: KASEYA_CATEGORY.STAGE1,
    appScreenshotUrl: require('../../../assets/kaseya/screen-1.png'),
    description:
      "Just before the July 4th long weekend, eSentire's TRU team identifies indicators that Kaseya may be the victim Of a supply chain attack. Possibility Of mass exploitation and malware deployment is considered.",
  },
  {
    title: 'Kaseya',
    subtitle: 'Kaseya Issues Public Advisory',
    time: '00:00:24:00',
    component: Slide04,
    icon: Eye,
    category: KASEYA_CATEGORY.STAGE1,
    description:
      'eSentire notifies Kaseya of the vulnerability. Kaseya confirms reports of mass exploitation of their VSA product and recommends disabling it.',
  },
  {
    title: 'Kaseya',
    subtitle:
      'Threat Hunt Identifies Impacted Customer Within 30 Minutes Of First Public Reports',
    product: 'MDR for Endpoint and MDR for Log',
    time: '00:00:27:00',
    component: Slide05,
    category: KASEYA_CATEGORY.STAGE2,
    appScreenshotUrl: [
      require('../../../assets/kaseya/screen-3-1.png'),
      require('../../../assets/kaseya/screen-3-2.png'),
    ],
    description:
      'eSentire TRU team members begin weaponizing early intel to drive hypothesis driven hunts across our global client base. Proactive threat hunting investigations sweep for indicators in MDR for Log and MDR for Endpoint, correlating positive hits in log data with endpoint query results. A positive hit is identified and confirmed through threat hunting activities in a client environment. eSentire SOC cyber analysts isolate impacted endpoints to prevent lateral spread, remediate and return impacted systems to service.',
  },
  {
    title: 'Kaseya',
    subtitle: 'TRU Security Advisory Drafted',
    time: '00:01:05:00',
    component: Slide06,
    category: KASEYA_CATEGORY.STAGE2,
    clientCommunication: true,
    description:
      'Initial triage confirms a Security Advisory to all clients is warranted, TRU completes an initial draft advisory.',
  },
  {
    title: 'Kaseya',
    subtitle: 'Cross-Functional Team Is Formed',
    time: '00:01:05:00',
    component: Slide07,
    category: KASEYA_CATEGORY.STAGE3,
    clientCommunication: true,
    appScreenshotUrl: require('../../../assets/kaseya/screen-5.png'),
    description:
      'A cross-functional team is assembled. This team is compromised of Elite Threat Hunters from TRU and SOC Cyber Analysts, as well as Executive Leadership. A collaboration channel is opened.',
  },
  {
    title: 'Kaseya',
    subtitle: 'Indicators Added To The Threat Intelligence Platform',
    time: '00:01:10:00',
    component: Slide08,
    icon: CheckShield,
    category: KASEYA_CATEGORY.STAGE3,
    appScreenshotUrl: require('../../../assets/kaseya/screen-6.png'),
    description:
      'eSentire TRU team collects known IoCs based on proactive threat hunts and adds them to our Threat Intelligence Platform for processing and development of machine-learning powered threat hunting activities across the global client base.',
  },
  {
    title: 'Kaseya',
    subtitle: 'SOC Blocks C2 IP Using AMP',
    product: 'MDR for Network',
    time: '00:01:21:00',
    component: Slide09,
    category: KASEYA_CATEGORY.STAGE4,
    appScreenshotUrl: [
      require('../../../assets/kaseya/screen-07-01.png'),
      require('../../../assets/kaseya/screen-07-02.png'),
      require('../../../assets/kaseya/screen-07-03.png'),
      require('../../../assets/kaseya/screen-07-04.png'),
    ],
    description:
      "Now that the investigation has confirmed IoCs, our SOC cyber analysts block the C2 IP across all client networks and environments. eSentire's Atlas XDR platform automatically protects all client assets against these malicious ransomware-related IoCs and IPs, leveraging our global IP blocklist and amplifying Security Network Effects to our entire customer base.",
  },
  {
    title: 'Kaseya',
    subtitle: 'Security Advisory Sent To Customers',
    time: '00:02:36:00',
    component: Slide10,
    clientCommunication: true,
    category: KASEYA_CATEGORY.STAGE5,
    description: "eSentire's TRU team sends Security Advisory to all clients.",
  },
  {
    title: 'Kaseya',
    subtitle:
      'Additional Impacted Customers Identified Via MDR For Log Threat Hunts',
    time: '00:03:03:00',
    product: 'MDR for Log',
    component: Slide11,
    icon: Bell,
    appScreenshotUrl: require('../../../assets/kaseya/screen-9.png'),
    category: KASEYA_CATEGORY.STAGE6,
    description:
      'The TRU team continues active threat hunts, developing additional hunting queries. These accelerated threat investigations with MDR for Log detect positive hits in additional customers environments.',
  },
  {
    title: 'Kaseya',
    subtitle: 'By 11pm TRU Bans Hashes Across All Customers',
    time: '00:03:26:00',
    product: 'MDR for Endpoint',
    component: Slide12,
    category: KASEYA_CATEGORY.STAGE6,
    description:
      'eSentire SOC cyber analysts ban known malicious hashes across MDR for Endpoint customers, effectively protecting the entire customer base from any Kaseya exploit.',
  },
  {
    title: 'Kaseya',
    subtitle: 'Kaseya Releases Detection Tool',
    time: '00:07:24:00',
    component: Slide13,
    category: KASEYA_CATEGORY.STAGE6,
    description:
      'Kaseya releases a tool for administrators to quickly identify compromised VSA instances.',
  },
  {
    title: 'Kaseya',
    subtitle: 'TRU Mines Kaseya Detection Tool For Hunting Leads',
    product: 'MDR for Endpoint',
    time: '02:17:24:00',
    component: Slide14,
    category: KASEYA_CATEGORY.STAGE6,
    appScreenshotUrl: require('../../../assets/kaseya/screen-12.png'),
    description:
      '24/7 monitoring & investigations over the July 4th weekend demonstrated additional IoCs in the Kaseya detection tool. TRU begins developing incremental runbooks and updating machine learning models to improve detections and automated blocks.',
  },
  {
    title: 'Kaseya',
    subtitle:
      'The TRU Team Finalizes Detection And Investigation Runbook For Kaseya',
    product: 'MDR for Endpoint',
    time: '02:21:46:00',
    component: Slide15,
    category: KASEYA_CATEGORY.STAGE6,
    appScreenshotUrl: require('../../../assets/kaseya/screen-13.png'),
    description:
      'By the end Of the day Monday, a machine-learning powered detection is pushed across all MDR for Endpoint customers, automating threat detections for Kaseya to protect clients from potential impact, and a new investigation runbook is ready for eSentire SOC cyber analysts to use for any new detections and human-led investigation.',
  },
  {
    title: 'Kaseya',
    subtitle: 'Kaseya Releases Patch And Begins Restoring SaaS Infrastructure',
    time: '08:22:24:00',
    component: Slide16,
    category: KASEYA_CATEGORY.STAGE7,
    description:
      'Patch for VSA vulnerability is released and Kaseya begins restoring their cloud infrastructure.',
  },
  {
    title: 'Kaseya',
    subtitle: 'Second Security Advisory Sent To Customers',
    time: '10:01:18:00',
    component: Slide17,
    clientCommunication: true,
    category: KASEYA_CATEGORY.STAGE7,
    appScreenshotUrl: require('../../../assets/kaseya/screen-15.png'),
    description:
      "eSentire's TRU team sends second Security Advisory concerning the latest updates to all clients.",
  },
  {
    title: 'Kaseya',
    subtitle: 'TRU Team Threat Intelligence Webinar With Customers',
    time: '10:19:24:00',
    component: Slide18,
    clientCommunication: true,
    category: KASEYA_CATEGORY.STAGE7,
    appScreenshotUrl: require('../../../assets/kaseya/screen-16.png'),
    description:
      'eSentire TRU team conducts a live webinar with global client base to cover our security recommendations, capabilities and answer any questions they may have.',
  },
  // Timeline
  {
    title: 'Kaseya',
    subtitle: 'Timeline Summary',
    component: Slide19,
    icon: Clock,
    timelineSummary: true,
    category: KASEYA_CATEGORY.STAGE7,
  },
];
