/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import anime from 'animejs';

// Players
import { PlayerListComplete } from '../players/list-complete';

// SVG
import { Card } from '../svg/card';
import { Person } from '../svg/person';
import { Shield } from '../svg/shield';
import { Signal } from '../svg/signal';

@observer
export class Slide12 extends AnimatedSlide {
  // Animation players
  players = {
    analyst: null,
    info: null,
    shield: null,
    data: null,
  };

  // Run the first animation
  componentDidMount() {
    const { analyst, info, shield, data } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(11000);
        this.autoNext(500);
      },
      // Step 1
      () => {
        this.autoNext(2200);
      },
      // Step 2
      () => {
        // Do nothing
      },
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: analyst,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: info,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
        {
          targets: shield,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          begin: activate,
          complete: activateShimmer,
        },
      ],
      //
      // Step 2
      //
      [
        {
          targets: data,
          translateX: [0, 100],
          easing: 'linear',
          delay: 0,
          duration: 1000,
          loop: true,
          begin: activate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-12 ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}
          {/* SOC Analyst */}
          <div
            className="player analyst"
            data-active="0"
            ref={node => (this.players.analyst = node)}
          >
            <Card IconComponent={Person} text="SOC ANALYST" />
            <div className={'animated-dotted-line reverse'}></div>
          </div>
          {/* Known malicious hashes */}
          <div
            className="player info"
            data-active="0"
            ref={node => (this.players.info = node)}
          >
            <PlayerListComplete
              title={'KNOWN MALICIOUS HASHES'}
              width={400}
              items={[
                'acbb7413aa2383e4003a204fbb545d99353f8858',
                'ec1db83fd7b79cf0dbc62f2a6d74311687f8ca94',
                '3ec5c76319a948de023ed1ba2a4abf48da45ac5a',
                '9b0c6763e934be032d625063c92d12095d25b31f',
                'e978f868350d50ebe88342ab8b4357a4dc01753f',
                '675380986ec3aeabd8a48e0c8baa2a499fbdf1db',
                '0d71777710e25ff7e7bfc1d303cfe5f20a3371c7',
                'dd2f848df564a3fbc7ace12677fa5eb3a5e7cfd9',
                '1b5837698304b7d74e67ec1c290963636abee72f',
                '1b5837698304b7d74e67ec1c290963636abee72f',
              ]}
            />
          </div>
          {/* Shield */}
          <div
            className="player shield"
            data-active="0"
            ref={node => (this.players.shield = node)}
          >
            <Shield
              IconComponent={Signal}
              IconSize={70}
              letterSpacing={1}
              uppercase={false}
              className="pulse-blue"
              text={['MDR FOR', 'ENDPOINT']}
            />
            <div
              ref={node => (this.players.update = node)}
              className="update"
            ></div>
            <div className={'animated-dotted-line reverse blue'}></div>
          </div>
          {/* Data */}
          <div
            className="player data"
            data-active="0"
            ref={node => (this.players.data = node)}
          ></div>
          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
