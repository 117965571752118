/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class NewDocument extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 36,
      x = 36,
      y = 36,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.7497 18.0028C29.0779 18.0028 30.3447 18.2713 31.4996 18.7551V7.50293H0V21.7528C0 23.8205 1.68223 25.5027 3.74996 25.5027H18.272C19.292 21.209 23.1492 18.0028 27.7497 18.0028Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2498 4.50343C10.8351 4.50343 10.4998 4.16818 10.4998 3.75344C10.4998 3.33869 10.8351 3.00345 11.2498 3.00345C11.6638 3.00345 11.9998 3.33869 11.9998 3.75344C11.9998 4.16818 11.6638 4.50343 11.2498 4.50343ZM8.24994 4.50343C7.83519 4.50343 7.49994 4.16818 7.49994 3.75344C7.49994 3.33869 7.83519 3.00345 8.24994 3.00345C8.66393 3.00345 8.99993 3.33869 8.99993 3.75344C8.99993 4.16818 8.66393 4.50343 8.24994 4.50343ZM5.24993 4.50343C4.83519 4.50343 4.49994 4.16818 4.49994 3.75344C4.49994 3.33869 4.83519 3.00345 5.24993 3.00345C5.66393 3.00345 5.99993 3.33869 5.99993 3.75344C5.99993 4.16818 5.66393 4.50343 5.24993 4.50343ZM31.4996 3.74996C31.4996 1.68223 29.8174 0 27.7497 0H3.74996C1.68223 0 0 1.68223 0 3.74996V5.99993H31.4996V3.74996Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.4999 28.5003H28.4999V31.5002C28.4999 31.9142 28.1647 32.2502 27.7499 32.2502C27.3352 32.2502 26.9999 31.9142 26.9999 31.5002V28.5003H24C23.5852 28.5003 23.25 28.1643 23.25 27.7503C23.25 27.3363 23.5852 27.0003 24 27.0003H26.9999V24.0003C26.9999 23.5863 27.3352 23.2503 27.7499 23.2503C28.1647 23.2503 28.4999 23.5863 28.4999 24.0003V27.0003H31.4999C31.9146 27.0003 32.2499 27.3363 32.2499 27.7503C32.2499 28.1643 31.9146 28.5003 31.4999 28.5003ZM27.7497 19.5C23.201 19.5 19.4998 23.2012 19.4998 27.7499C19.4998 32.2986 23.201 35.9998 27.7497 35.9998C32.2984 35.9998 35.9996 32.2986 35.9996 27.7499C35.9996 23.2012 32.2984 19.5 27.7497 19.5Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
