/** @jsx createElement */
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { AppActions } from '../app-actions';
import { PlayerTimeline } from '../players/timeline';

@observer
export class Slide17 extends AnimatedSlide {
  // Animation players
  players = {
    timeline: null,
    events: null,
  };

  timeline = {
    unit: 'minute',
    units: 18,
    start: 0,
    alternate: true,
    details: [
      {
        start: '0:00',
        end: '0:30',
        description: 'Scenario',
        active: true,
      },
      {
        start: '0:00',
        end: '0:30',
        description: 'Attack Stage',
      },
      {
        start: '0:00',
        end: '0:30',
        description: 'Investigation Triggered',
      },
      {
        start: '0:30',
        description: 'Login Investigation',
      },
      {
        start: '2:15',
        description: 'Analyst Alert',
      },
      {
        start: '3:00',
        description: 'Escalation',
      },
      {
        start: '6:45',
        description: 'Follow-up Investigation Confirmation',
      },
      {
        start: '7:45',
        end: '16:20',
        description: 'Advanced Investigation',
      },
      {
        start: '16:20',
        end: '16:30',
        description: 'Investigation Summary Provided',
      },
    ],
  };

  // Run the first animation
  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.hideRightArrow();
    AppActions.disableAppScreenshot();

    this.steps = [
      // Step 1
      () => {},
    ];

    this.animations = [
      // Step 1
      [],
    ];

    // Play the first animation
    this.play();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
    AppActions.showRightArrow();
    AppActions.enableAppScreenshot();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-23 timeline-slide ${className}`}>
        {children}
        <div className="content">
          <PlayerTimeline
            events={this.timeline.details}
            unit={this.timeline.unit}
            units={this.timeline.units}
            start={this.timeline.start}
            alternate={this.timeline.alternate}
          />
        </div>
      </div>
    );
  }
}
