// @ts-check
import { observer } from 'mobx-react';
import * as React from 'react';
import { Component } from 'react';
import './asset-chart.scss';
import { ChartShell } from './chart-shell.jsx';
import { XAxis, YAxis, CartesianGrid, BarChart, Bar, Tooltip } from 'recharts';
import { autorun, observable, toJS } from 'mobx';
import {
  assetVulnerabilities,
  getAssetDataAttackTypes,
  AssetVulnerabilityOutput,
  GLOBAL_FILTER_OPTION,
} from '../../data/graph-data-transforms';
import { generateColor } from '../../data/color-generator';
import { Color } from 'csstype';
import { RiskAnalysisToolState } from '../risk-analysis-tool/risk-analysis-tool-actions';

const formatPercent = new Intl.NumberFormat(undefined, {
  style: 'percent',
  maximumFractionDigits: 2,
}).format;

interface IProps extends Pick<Partial<React.HTMLAttributes<{}>>, 'className'> {
  filter: string;
  /** Name of the country to filter by */
  category: string;
  maxBuckets: number;
  width?: number;
  height?: number;
  colors?: Color[];
  attackType: string;
  onTooFewClients?(tooFew: boolean): void;
}

/** This is used to ensure only the latest stream of async execution passes as the correct execution */
let asyncContext = 0;

@observer
export class AssetChart extends Component<IProps> {
  @observable attackTypes: string[] = [];
  @observable
  data: AssetVulnerabilityOutput = {
    totalClients: 0,
    filter: '',
    buckets: [],
  };
  private _dispose = () => {};

  async componentDidMount() {
    this.attackTypes = await getAssetDataAttackTypes();
    this.componentWillReceiveProps(this.props, undefined, true);
  }

  componentWillReceiveProps(
    nextProps: IProps,
    _nextContex?: any,
    forceUpdate?: boolean
  ) {
    const { attackType, filter, maxBuckets, category } = nextProps;

    if (
      attackType === this.props.attackType &&
      filter === this.props.filter &&
      maxBuckets === this.props.maxBuckets &&
      category === this.props.category &&
      !forceUpdate
    ) {
      return;
    }

    const context = ++asyncContext;

    (async () => {
      let checkData = await assetVulnerabilities({
        attackTypeFilter: attackType,
        filter,
        maxBuckets,
        category,
        // Trim anything less than 1%
        trimSmallBucketValues: 0.005,
      });

      if (context < asyncContext) return;
      this.data = checkData;

      if (this.props.onTooFewClients) {
        this.props.onTooFewClients(this.data.totalClients < 10);
      }

      if (this.data.totalClients < 10) {
        checkData = await assetVulnerabilities({
          attackTypeFilter: attackType,
          filter: GLOBAL_FILTER_OPTION,
          maxBuckets,
          category,
          // Trim anything less than 1%
          trimSmallBucketValues: 0.005,
        });

        if (context < asyncContext) return;
        this.data = checkData;
      }
    })();
  }

  render() {
    const {
      children,
      className = '',
      width = 900,
      height = 500,
      colors = [],
      attackType,
    } = this.props;
    const { buckets: data } = this.data;
    const colorIndex = Math.max(this.attackTypes.indexOf(attackType), 0);
    const color = colors[colorIndex] || generateColor(colorIndex);

    return (
      <ChartShell
        className={`AssetChart ${className}`}
        leftSection="Percentage of Clients"
        bottomSection="Total Assets Involved"
      >
        <BarChart
          {...{ data, width, height }}
          margin={{ left: -20, right: 20, bottom: 30 }}
        >
          <YAxis
            tickLine={false}
            axisLine={false}
            tickFormatter={formatPercent}
          />
          <Tooltip
            isAnimationActive={false}
            cursor={false}
            content={this.renderTooltip}
          />
          <CartesianGrid vertical={false} stroke="rgba(255, 255, 255, 0.5)" />
          <XAxis
            dataKey="name"
            tickLine={false}
            axisLine={false}
            interval={0}
            tick={({ x, y, payload }) => {
              // Normally none of this would be needed. All of this is strickly
              // for the purpose of rotating the tick label 90 degrees
              return (
                <g transform={`translate(${x},${y})`}>
                  <text
                    x={0}
                    y={'0.25em'}
                    textAnchor="end"
                    style={{
                      transform: 'rotate(-90deg)',
                    }}
                  >
                    {payload.value}
                  </text>
                </g>
              );
            }}
          />
          {attackType && (
            <Bar
              stackId={1}
              key={attackType}
              dataKey={attackType}
              fill={`${color}`}
            />
          )}
          {children}
        </BarChart>
      </ChartShell>
    );
  }

  renderTooltip = data => {
    if (!(data && data.payload && data.payload.length)) return;

    return (
      <div
        className="AssetChart__Tooltip"
        style={{ backgroundColor: data.payload[0].fill }}
      >
        <div className="AssetChart__Tooltip__Industry">{this.props.filter}</div>
        <div className="AssetChart__Tooltip__AttackType">
          {this.props.attackType}
        </div>
        <div className="AssetChart__Tooltip__Percent">
          {formatPercent(data.payload[0].value)}
        </div>
      </div>
    );
  };
}
