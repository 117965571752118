/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './text-block.scss';

@observer
export class PlayerTextBlock extends Component {
  defaults = {
    lines: 0,
    lineColumns: 1,
    delay: 0,
    multiline: false,
    compact: false,
    grid: false,
    color: '',
    colorCells: 0,
    showDots: false,
    width: '200px',
  };

  drawLines(section) {
    section.lines = section.lines || 0;
    section.lineColumns = section.lineColumns || 1;
    section.delay = section.delay || 0;
    if (!section.lines) return;
    let lines = [];
    let columns = [];
    for (let i = 0; i < section.lineColumns; i++) {
      columns.push(<span key={i} />);
    }
    let lineDelay = 100;
    for (let i = 0; i < section.lines; i++) {
      let lineStyles = {
        animationDelay: section.delay
          ? `${section.delay + lineDelay * i}ms`
          : '0s',
      };
      lines.push(
        <div key={i} className={'player--textBlock-line'} style={lineStyles}>
          {columns}
        </div>
      );
    }
    return <div className="player--textBlock-section-lines">{lines}</div>;
  }

  renderSectionTitles(section) {
    const { compact = this.defaults.compact } = this.props;
    const {
      title = this.defaults.title,
      grid = this.defaults.grid,
      colors = [],
    } = section;

    // Defaults
    let colorCells = section.colorCells || 0;
    let color = section.color || '';
    let multiline = section.multiline ? 'multiline' : '';
    let className = section.className ? section.className : '';
    let multipleClass = 'multiple';
    if (compact == true || section.compact == true) multipleClass = '';
    if (grid) multipleClass = 'multiple';
    let compactClass = compact ? 'compact' : '';

    // Support string title
    if (typeof title === 'string') {
      return (
        <div
          className={`player--textBlock-section-title ${compactClass} ${multiline} ${className}`}
        >
          <span>{title}</span>
        </div>
      );
    }
    // Support array of titles
    if (title instanceof Array) {
      let titleStyles = {};
      if (grid) titleStyles.gridTemplateColumns = '1fr 1fr';
      return (
        <div
          className={`player--textBlock-section-title ${multipleClass} ${multiline} ${className}`}
          style={titleStyles}
        >
          {title.map((value, index) => {
            let cellColor = index < colorCells && color ? color : '';
            let styles = {};
            if (colors[index]) styles.backgroundColor = colors[index];
            return (
              <div key={index} className={cellColor} style={styles}>
                {value}
              </div>
            );
          })}
        </div>
      );
    }
  }

  renderSection(section, index) {
    const { className = '' } = section;
    const { compact = this.defaults.compact } = this.props;
    let columnClass = section.columns ? 'column-mode' : '';
    let animateClass = section.animate ? 'animate-in' : '';
    let compactClass = compact ? 'compact' : '';
    let sectionStyles = {};
    if (section.delay) sectionStyles.animationDelay = `${section.delay}ms`;
    return (
      <div
        key={index}
        className={`player--textBlock-section ${compactClass} ${className} ${columnClass} ${animateClass}`}
        style={sectionStyles}
      >
        {section.title && this.renderSectionTitles(section)}
        {this.drawLines(section)}
      </div>
    );
  }

  renderSections() {
    if (this.props.sections === undefined) return;
    const { sections } = this.props;
    return (
      <div className={'player--textBlock-sections'}>
        {sections.map((section, index) => this.renderSection(section, index))}
      </div>
    );
  }

  makeArray(length) {
    return Array.from({ length: length }, (v, i) => i);
  }

  render() {
    const {
      children,
      className = '',
      title = '',
      box = '',
      width = 200,
      showDots = false,
      multiple = true,
      showTimeline = false, // Show a timeline
      actionTitle = null, // Larger title that goes above the text box
      icon = null, // Optional icon, will change the layout

      // Example section
      sections = [],
    } = this.props;

    /*
    Section properties
    {
        title: '',      // Optional section title
        lines: 3,       // Optional number of lines to draw
        columns: false, // Place the title to the left of the lines if 'true'
        animate: false, // Animate the section into existence
        delay: 1000,    // Amount of time to wait before this section becomes visible
    }
    */

    const styles = {
      width: width.toString().replace(/px/g, '') + 'px',
    };

    // The icon layout
    let iconClass = icon ? 'icon-layout' : '';

    // Action title class
    let actionClass = sections.length > 0 ? '' : 'action-only';

    return (
      <div
        className={`player--textBlock ${className} ${iconClass} ${actionClass}`}
        style={styles}
      >
        {icon && <div className={'player--textBlock-icon'}>{icon}</div>}
        {actionTitle && (
          <div className={'player--textBlock-action'}>{actionTitle}</div>
        )}
        {title && (
          <div className="player--textBlock-top-bar">
            {title}
            {showDots && (
              <div className="player--textBlock-dots">
                <div className="player--textBlock-dots" />
              </div>
            )}
          </div>
        )}
        {showTimeline && (
          <div className={'player--textBlock-timeline'}>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        )}
        {box && (
          <div className="player--textBlock-box">
            <span>{box}</span>
          </div>
        )}
        {this.renderSections()}
      </div>
    );
  }
}
