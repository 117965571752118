/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Template__GlobalScanInfo } from '../../templates/global-scan-info';

@observer
export class Slide04 extends Component {
  componentDidMount() {
    // Do something
  }

  render() {
    const { children } = this.props;
    return (
      <div className={`slide-04`}>
        {children}
        <Template__GlobalScanInfo
          sections={[
            {
              type: 'header',
              color: 'blue',
              content: 'TRU SCAN RESULTS',
            },
            {
              type: 'grid',
              content: [
                {
                  html: 'PROXYSHELL VULNERABILITY',
                  preset: 'redBorder',
                },
              ],
            },
            {
              type: 'cells',
              content: [
                { html: 'EXCHANGE SERVER' },
                {
                  preset: 'red',
                  html: 'SCAN DETECTED',
                },
              ],
            },
            {
              type: 'cells',
              content: [
                { html: 'EXCHANGE SERVER' },
                {
                  preset: 'red',
                  html: 'SCAN DETECTED',
                },
              ],
            },
            {
              type: 'cells',
              content: [
                { html: 'EXCHANGE SERVER' },
                {
                  preset: 'red',
                  html: 'SCAN DETECTED',
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
}
