/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { Stage } from '../stage';

// SVG
import { Bug } from '../svg/bug';
import { Eye } from '../svg/eye';
import { CheckShield } from '../svg/check-shield';
import { Bell } from '../svg/bell';
import { Triage } from '../svg/triage';
import { Person } from '../svg/person';

@observer
export class Slide02 extends AnimatedSlide {
  // Animation players
  players = {
    card1: null,
    card2: null,
    card3: null,
    card4: null,
    card5: null,
    card6: null,
    card7: null,
  };

  // Run the first animation
  componentDidMount() {
    const { card1, card2, card3, card4, card5, card6, card7 } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(1500);
        this.autoNext(200);
      },
      // Step 1
      () => {
        this.autoNext(200);
      },
      // Step 2
      () => {
        this.autoNext(200);
      },
      // Step 3
      () => {
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        {
          targets: card1,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card2,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card3,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1000,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card4,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 1500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card5,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2000,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card6,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 2500,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: card7,
          opacity: 1,
          easing: 'easeOutQuad',
          delay: 3000,
          duration: 500,
          complete: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-02 ${className}`}>
        {children}
        <div className="content">
          {/* Card 1 */}
          <div
            className="player card1"
            data-active="0"
            ref={node => (this.players.card1 = node)}
          >
            <Stage
              Icon={Bug}
              stage={'01'}
              title={'Identification'}
              className={'short'}
            />
          </div>

          {/* Card 2 */}
          <div
            className="player card2"
            data-active="0"
            ref={node => (this.players.card2 = node)}
          >
            <Stage
              Icon={Triage}
              stage={'02'}
              title={'Initial Triage'}
              className={'short'}
            />
          </div>

          {/* Card 3 */}
          <div
            className="player card3"
            data-active="0"
            ref={node => (this.players.card3 = node)}
          >
            <Stage
              Icon={Person}
              stage={'03'}
              title={'Assemble Working Group for Significant Event'}
              className={'short'}
            />
          </div>

          {/* Card 4 */}
          <div
            className="player card4"
            data-active="0"
            ref={node => (this.players.card4 = node)}
          >
            <Stage
              Icon={CheckShield}
              stage={'04'}
              title={'Collection, Threat Hunting and Prevention Activities'}
              className={'short'}
            />
          </div>

          {/* Card 5 */}
          <div
            className="player card5"
            data-active="0"
            ref={node => (this.players.card5 = node)}
          >
            <Stage
              Icon={Bell}
              stage={'05'}
              title={'Customer Communication'}
              className={'short'}
            />
          </div>

          {/* Card 6 */}
          <div
            className="player card6"
            data-active="0"
            ref={node => (this.players.card6 = node)}
          >
            <Stage
              Icon={CheckShield}
              stage={'06'}
              title={
                'Follow-on Collection, Threat Hunting and Prevention Activities'
              }
              className={'short'}
            />
          </div>

          {/* Card 7 */}
          <div
            className="player card7"
            data-active="0"
            ref={node => (this.players.card7 = node)}
          >
            <Stage
              Icon={Bell}
              stage={'07'}
              title={'Follow-on Customer Communications'}
              className={'short'}
            />
          </div>
        </div>
      </div>
    );
  }
}
