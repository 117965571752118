/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Template__ScanBenignOrMalicious } from '../../templates/scan-benign-or-malicious';

@observer
export class Slide09 extends Component {
  componentDidMount() {
    // Do something
  }

  render() {
    const { children } = this.props;
    return (
      <div className={`slide-09`}>
        {children}
        <Template__ScanBenignOrMalicious />
      </div>
    );
  }
}
