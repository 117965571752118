/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class EsentireE extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 2,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: 'visible' }}
        {...containerProps}
      >
        <g fill={color} strokeWidth={strokeWidth} opacity={opacity}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2893 21.0553C20.2893 19.2226 20.5329 17.7614 21.0241 16.68C21.5111 15.5985 22.1551 14.7234 22.9558 14.0548C23.2695 13.7782 23.6369 13.5264 24.0579 13.3035C24.4789 13.0848 25.0568 12.9155 25.7915 12.8041C26.5263 12.6926 27.5128 12.6101 28.7593 12.5564C30.0059 12.4986 29.4899 12.4986 30.7324 12.5564C31.9789 12.6101 32.9778 12.6926 33.7332 12.8041C34.4927 12.9155 35.0582 13.0848 35.4338 13.3035C35.8136 13.5264 36.1809 13.7782 36.5359 14.0548C37.3367 14.7234 37.9806 15.5985 38.4677 16.68C38.9589 17.7614 39.2024 19.2226 39.2024 21.0553L39.1983 23.3627H20.2852L20.2893 21.0553ZM53.4842 20.3288C53.4842 17.4766 52.993 14.8844 52.2582 12.3583C51.5235 9.8404 50.7888 8.74656 49.1418 6.86021C47.7673 5.28343 45.3114 3.34755 43.846 2.55504C42.3766 1.76665 40.5315 1.09796 39.8711 0.899835C38.3521 0.44579 37.1839 0 35.1118 0H25.0403C23.9712 0 21.3213 0.437534 19.9963 0.767749C18.4566 1.15162 16.8262 1.96478 15.3568 2.75316C13.8914 3.54568 12.0464 5.07292 10.7214 6.39791C8.95059 8.16869 8.0714 9.71244 7.20871 12.3583C6.39968 14.8597 6.00755 17.4766 6.00755 20.3288V37.9375C5.98279 38.4865 6.02407 39.7166 6.05709 40.2986C6.32126 45.2105 8.28191 48.9378 9.42115 50.9191C9.68945 51.3938 14.2547 60 28.813 60H53.4842V46.6965L31.6694 46.6882C24.8092 46.6882 20.2852 45.5036 20.2852 38.924L20.2893 36.53H53.4842V20.3288Z"
          />
        </g>
      </svg>
    );
  }
}
