/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';

// SVG
import { DiskDrive } from '../svg/disk-drive';

@observer
export class Slide17 extends AnimatedSlide {
  // Animation players
  players = {
    computer: null,
    dots: null,
    circle: null,
    mask: null,
    animatedDots: null,
  };

  // Run the first animation
  componentDidMount() {
    const { computer, dots, circle, mask, animatedDots } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(5000);
        mask.classList.remove('highlighted');
        this.autoNext(2000);
      },
      // Step 2
      () => {
        mask.classList.add('highlighted');
        this.autoNext(2000);
      },
      // Step 3
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: computer,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: mask,
          easing: 'easeOutQuad',
          width: 600,
          height: 600,
          duration: 1000,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: mask,
          easing: 'easeOutQuad',
          width: 200,
          height: 200,
          duration: 1000,
          begin: shimmer,
        },
        {
          targets: animatedDots,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: dots,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: shimmer,
        },
        {
          targets: animatedDots,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-17 ${className}`}>
        {children}
        <div className="content">
          {/* Dots */}
          <div className="center-player">
            <div
              className="player dots"
              data-active="1"
              ref={node => (this.players.dots = node)}
            >
              <div className="mask" ref={node => (this.players.mask = node)}>
                <div
                  className="dotContainer"
                  ref={node => (this.players.animatedDots = node)}
                >
                  <PlayerDots />
                  <PlayerDots />
                  <PlayerDots />
                  <PlayerDots />
                  <PlayerDots />
                  <PlayerDots />
                  <PlayerDots />
                  <PlayerDots />
                </div>
              </div>
            </div>
          </div>

          {/* Computer */}
          <div
            className="player computer"
            data-active="1"
            ref={node => (this.players.computer = node)}
          >
            <DiskDrive size={'60'} />
          </div>
        </div>
      </div>
    );
  }
}
