/** @jsx createElement */
// SVG
import { ComputerGroup } from '../svg/computer-group';
import { Shield } from '../svg/shield';
import { Server } from '../svg/server';
import { Atlas } from '../svg/atlas';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import { Badge } from '../badge';

@observer
export class Slide16 extends AnimatedSlide {
  // Animation players
  players = {
    network: null,
    dots: null,
    shield: null,
    badge1: null,
    badge2: null,
    badge3: null,
    badge4: null,
    badge5: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      network,
      dots,
      shield,
      badge1,
      badge2,
      badge3,
      badge4,
      badge5,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(7000);
        this.autoNext(3000);
      },
      // Step 2
      () => {
        badge2.classList.remove('dimmed', 'shimmer');
        this.autoNext(4000);
      },
      // Step 3
      () => {
        badge2.classList.add('shimmer');
        setTimeout(() => {
          badge2.classList.add('dimmed');
        }, 200);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: network,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
        {
          targets: dots,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 500,
          begin: activateShimmer,
        },
        {
          targets: shield,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: badge1,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
        {
          targets: badge2,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
        {
          targets: badge3,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
        {
          targets: badge4,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
        {
          targets: badge5,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 0,
          complete: activate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: shield,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 100,
          delay: 0,
          begin: shimmer,
        },
        {
          targets: badge1,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: badge2,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 50,
          begin: activateShimmer,
        },
        {
          targets: badge3,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 100,
          begin: activateShimmer,
        },
        {
          targets: badge4,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 150,
          begin: activateShimmer,
        },
        {
          targets: badge5,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 200,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        // Nothing really needed
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-16 ${className}`}>
        {children}
        <div className="content">
          {/* network */}
          <div
            className="player network"
            data-active="0"
            ref={node => (this.players.network = node)}
          >
            <ComputerGroup color={'#ffffff'} />
          </div>

          {/* Shield */}
          <div
            className="player shield"
            data-active="0"
            ref={node => (this.players.shield = node)}
          >
            <Shield IconComponent={Atlas} text="ATLAS XDR" />
          </div>

          {/* dots */}
          <div
            className="player dots"
            data-active="0"
            ref={node => (this.players.dots = node)}
          >
            <PlayerDots
              color={this.colors.blue}
              width={160}
              dots={10}
              className={'in-out'}
            />
          </div>

          {/* Badge 1 */}
          <div
            className="player badge badge1"
            data-active="0"
            ref={node => (this.players.badge1 = node)}
          >
            <Badge text={'6.131.167.14'} className={'blue'} />
            <PlayerDots rotate={'-26deg'} />
          </div>

          {/* Badge 2 */}
          <div
            className="player badge badge2"
            data-active="0"
            ref={node => (this.players.badge2 = node)}
          >
            <Badge text={'92.218.114.31'} className={'red'} />
            <PlayerDots
              rotate={'-14deg'}
              width={'300px'}
              className={'red-dots'}
            />
          </div>

          {/* Badge 3 */}
          <div
            className="player badge badge3"
            data-active="0"
            ref={node => (this.players.badge3 = node)}
          >
            <Badge text={'6.123.122.17'} className={'blue'} />
            <PlayerDots rotate={'0deg'} width={'290px'} />
          </div>

          {/* Badge 4 */}
          <div
            className="player badge badge4"
            data-active="0"
            ref={node => (this.players.badge4 = node)}
          >
            <Badge text={'6.144.111.67'} className={'blue'} />
            <PlayerDots rotate={'14deg'} width={'300px'} />
          </div>

          {/* Badge 5 */}
          <div
            className="player badge badge5"
            data-active="0"
            ref={node => (this.players.badge5 = node)}
          >
            <Badge text={'6.144.167.82'} className={'blue'} />
            <PlayerDots rotate={'26deg'} />
          </div>
        </div>
      </div>
    );
  }
}
