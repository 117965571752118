/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Key extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 19,
      x = 0,
      y = 0,
      opacity = 1,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g opacity={opacity} fill={color}>
          <path d="M3.61467 39.0953C8.43424 43.9149 16.2758 43.9149 21.0953 39.0953C22.4392 37.7515 23.4545 36.1054 24.0612 34.2983L37.5454 34.2991V40.126L42.5193 40.1252V34.2984L46.2864 34.2983V41.582L52.7164 41.582V34.2983L56.0567 34.2984C58.2315 34.2984 60 32.5299 60 30.355C60 28.2486 58.1631 26.4117 56.0567 26.4117L24.0612 26.4117C23.4545 24.6046 22.4392 22.9585 21.0953 21.6147C16.2758 16.7951 8.43424 16.7951 3.61467 21.6147C-1.20489 26.4342 -1.20489 34.2758 3.61467 39.0953ZM16.7252 25.9848C19.1353 28.395 19.1353 32.315 16.7252 34.7252C14.315 37.1353 10.395 37.1353 7.98484 34.7252C5.57469 32.315 5.57469 28.395 7.98484 25.9848C10.395 23.5747 14.315 23.5747 16.7252 25.9848Z" />
        </g>
      </svg>
    );
  }
}
