/** @jsx createElement */

// SVG
import { Alert } from '../svg/alert';
import { Card } from '../svg/card';
import { Warning } from '../svg/warning';
import { Computer } from '../svg/computer';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './home.scss';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

@observer
export class Slide17 extends AnimatedSlide {
  // Animation players
  players = {
    terminal1: null,
    terminal2: null,
    dots: null,
    alert: null,
  };

  // Run the first animation
  componentDidMount() {
    // Save the border since I can't bind it in the DOM like the others
    this.players.border = this.players.alert.querySelector('.border');

    const { terminal1, terminal2, dots, alert, border } = this.players;

    this.steps = [
      () => {
        this.animateProgress(5000);
        this.autoNext(2000);
      },
      () => {
        this.autoNext(2000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: terminal1,
          opacity: 1,
          easing: 'easeOutQuad',
          translateX: 0,
          duration: 200,
        },
        {
          targets: terminal2,
          opacity: 0,
          easing: 'easeOutQuad',
          translateX: 0,
          duration: 200,
          complete: deactivate,
        },
        {
          targets: dots,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 200,
          complete: deactivate,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: terminal1,
          opacity: 1,
          easing: 'easeOutQuad',
          translateX: -150,
          duration: 200,
          begin: shimmer,
        },
        {
          targets: terminal2,
          opacity: 1,
          easing: 'easeOutQuad',
          translateX: 150,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: dots,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 200,
          begin: activateShimmer,
        },

        // Deactivate
        {
          targets: alert,
          opacity: 0,
          easing: 'easeOutQuad',
          duration: 500,
          complete: deactivate,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: alert,
          opacity: 1,
          easing: 'easeOutQuad',
          duration: 500,
          begin: activateShimmer,
        },
        {
          targets: border,
          opacity: [0, 1],
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 1000,
          easing: 'easeInOutSine',
        },

        // Dim
        {
          targets: terminal1,
          opacity: 0.1,
          easing: 'easeOutQuad',
          duration: 200,
        },
        {
          targets: terminal2,
          opacity: 0.1,
          easing: 'easeOutQuad',
          duration: 200,
        },
        { targets: dots, opacity: 0.1, easing: 'easeOutQuad', duration: 200 },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`SandboxFindings3 ${className}`}>
        {children}
        <div className="content">
          {/* Trojan detected */}
          <div
            className="player alert"
            data-active="0"
            ref={node => (this.players.alert = node)}
          >
            <Alert
              title={'EMERGING THREAT'}
              subtitle={'Network Trojan Detected'}
            />
          </div>

          {/* Terminal 1 */}
          <div
            className="player terminal1"
            data-active="1"
            ref={node => (this.players.terminal1 = node)}
          >
            <Card IconComponent={Computer} text={'192.178.29.105'} />
          </div>

          {/* Terminal 2 */}
          <div
            className="player terminal2"
            data-active="0"
            ref={node => (this.players.terminal2 = node)}
          >
            <Card IconComponent={Computer} text={'175.255.217.114'} />
          </div>

          {/* Animated red dots */}
          <div
            className="player dots"
            data-active="0"
            ref={node => (this.players.dots = node)}
          >
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
            <span className="dot" />
          </div>
        </div>
      </div>
    );
  }
}
