/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { observable } from 'mobx';

// Players
import { PlayerDonut } from '../players/donut';
import { PlayerTextLines } from '../players/text-lines';
import { PlayerTextBlock } from '../players/text-block';
import { Page } from '../svg/page';

@observer
export class Slide22 extends AnimatedSlide {
  // Animation players
  players = {
    donut: null,
    binary: null,
    extracting: null,
    pages: null,
  };

  @observable
  donut = {
    color: 'blue',
  };

  @observable extractingText = 'Extracting Files';

  // Run the first animation
  componentDidMount() {
    const { donut, binary, extracting, pages } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
        this.donut.color = 'blue';
        this.autoNext(2000);
      },
      // Step 2
      () => {
        this.donut.color = 'green';
        this.autoNext(2000);
      },
      // Step 3
      () => {
        this.autoNext(3000);
      },
      // Step 3
      () => {
        this.players.extracting
          .querySelector('.player--textBlock')
          .classList.add('green-top');
        this.extractingText = 'Files Extracted';
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [],

      //
      // Step 2
      //
      [
        {
          targets: extracting,
          opacity: 1,
          duration: 500,
          delay: 0,
          begin: activateShimmer,
        },
      ],

      //
      // Step 3
      //
      [
        {
          targets: binary,
          opacity: 0,
          duration: 200,
          delay: 0,
          begin: shimmer,
          complete: deactivate,
        },
        {
          targets: pages,
          opacity: 1,
          duration: 200,
          delay: 0,
          begin: shimmer,
          begin: activate,
        },
      ],
      //
      // Step 4
      //
      [],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-22 ${className}`}>
        {children}
        <div className="content">
          {/* Extracting files */}
          <div
            className="player extracting"
            data-active="0"
            ref={node => (this.players.extracting = node)}
          >
            <PlayerTextBlock
              title="Bro"
              actionTitle={this.extractingText}
              className={'wrap'}
            />
          </div>

          {/* Binary */}
          <div
            className={`player binary`}
            data-active="1"
            ref={node => (this.players.binary = node)}
          >
            <PlayerTextLines
              characters={'010101010101010101010101010111010101'}
              rows={3}
            />
          </div>

          {/* Pages */}
          <div
            className={`player pages`}
            data-active="0"
            ref={node => (this.players.pages = node)}
          >
            <div className={'page'}>
              <Page size={50} />
            </div>
            <div className={'page'}>
              <Page size={50} />
            </div>
            <div className={'page'}>
              <Page size={50} />
            </div>
            <div className={'page'}>
              <Page size={50} />
            </div>
            <div className={'page'}>
              <Page size={50} />
            </div>
            <div className={'page'}>
              <Page size={50} />
            </div>
          </div>

          {/* Donut */}
          <div
            className={`player donut ${this.donut.color}`}
            data-active="1"
            ref={node => (this.players.donut = node)}
          >
            <PlayerDonut />
          </div>
        </div>
      </div>
    );
  }
}
