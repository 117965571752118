/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';
import ReactDOM from 'react-dom';

// SVG
import { Card } from '../svg/card';
import { Skull } from '../svg/skull';
import { CMD } from '../svg/cmd';

@observer
export class Slide13 extends AnimatedSlide {
  // Animation players
  players = {
    attacker: null,
    process1: null,
    process2: null,
    process3: null,
    info1: null,
    info2: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      attacker,
      process1,
      process2,
      process3,
      info1,
      info2,
    } = this.players;

    this.steps = [
      // Step 0
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Final step
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [
        // Pause for effect
      ],

      //
      // Step 1
      //
      [
        {
          targets: attacker,
          opacity: 1,
          delay: 0,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: process1,
          opacity: 1,
          delay: 400,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: process2,
          opacity: 1,
          delay: 600,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: process3,
          opacity: 1,
          delay: 800,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: info1,
          opacity: 1,
          delay: 1000,
          duration: 500,
          complete: activateShimmer,
        },
        {
          targets: info2,
          opacity: 1,
          delay: 1200,
          duration: 500,
          complete: activateShimmer,
        },
      ],

      //
      // Step 2
      //
      [
        // Do nothing
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-13 ${className}`}>
        {children}
        <div className="content">
          {/* Attacker */}
          <div
            className="player attacker"
            data-active="0"
            ref={node => (this.players.attacker = node)}
          >
            <Card IconComponent={Skull} text={'Attacker'} />
            <div className={'animated-dotted-line reverse red'}></div>
          </div>

          {/* Process1 */}
          <div
            className="player process1"
            data-active="0"
            ref={node => (this.players.process1 = node)}
          >
            <PlayerCircleIcon
              color={this.colors.blue}
              Icon={CMD}
              title={'CMD.EXE'}
            />
            <div className={'animated-dotted-line reverse ants-1 blue'}></div>
            <div className={'animated-dotted-line reverse ants-2 blue'}></div>
            <div className={'animated-dotted-line ants-3 blue'}></div>
            <div className={'animated-dotted-line reverse ants-4 blue'}></div>
            <div className={'animated-dotted-line reverse ants-5 blue'}></div>
          </div>
          {/* Process2 */}
          <div
            className="player process2"
            data-active="0"
            ref={node => (this.players.process2 = node)}
          >
            <PlayerCircleIcon
              color={this.colors.blue}
              Icon={CMD}
              title={'PSEXEC.EXE'}
            />
          </div>
          {/* Process3 */}
          <div
            className="player process3"
            data-active="0"
            ref={node => (this.players.process3 = node)}
          >
            <PlayerCircleIcon
              color={this.colors.blue}
              Icon={CMD}
              title={'PSEXEC.EXE'}
            />
          </div>

          {/* Connection Info */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerTextBlock
              title={'Connection info'}
              sections={[
                {
                  title: ['PSEXEC.EXE @IPS.TXT'],
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['HOST', 'WINDOWS SERVER'],
                  grid: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  lines: 4,
                  animate: true,
                  delay: 600,
                },
              ]}
            />
          </div>
          {/* Carbon Black */}
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerTextBlock
              title={'VMWare Carbon Black'}
              sections={[
                {
                  title: ['PSEXEC.EXE @IPS.TXT'],
                  animate: true,
                  delay: 200,
                },
                {
                  title: ['TYPE', 'REMOVE INTERATIVE'],
                  grid: 2,
                  color: 'red',
                  colorCells: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['STATE', 'TERMINATED'],
                  grid: 2,
                  color: 'red',
                  colorCells: 2,
                  animate: true,
                  delay: 600,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}
