/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Chain extends Component {
  render() {
    const {
      children,
      className = '',
      color = 'white',
      size = 100,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        {...{ x, y }}
        className={className}
        width={size}
        height={size}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 7.51562C18.6875 7.51562 19.2656 7.76562 19.7344 8.26562C20.2344 8.73438 20.4844 9.3125 20.4844 10C20.4844 10.6875 20.2344 11.2812 19.7344 11.7812C19.2656 12.25 18.6875 12.4844 18 12.4844C16.875 12.4844 16.1094 11.9844 15.7031 10.9844H12.7969C12.3906 11.9844 11.625 12.4844 10.5 12.4844C9.375 12.4844 8.60938 11.9844 8.20312 10.9844H5.29688C4.89062 11.9844 4.125 12.4844 3 12.4844C2.3125 12.4844 1.71875 12.25 1.21875 11.7812C0.75 11.2812 0.515625 10.6875 0.515625 10C0.515625 9.3125 0.75 8.73438 1.21875 8.26562C1.71875 7.76562 2.3125 7.51562 3 7.51562C4.125 7.51562 4.89062 8.01562 5.29688 9.01562H8.20312C8.60938 8.01562 9.375 7.51562 10.5 7.51562C11.625 7.51562 12.3906 8.01562 12.7969 9.01562H15.7031C16.1094 8.01562 16.875 7.51562 18 7.51562Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
