/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Phone extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 46,
      x = 0,
      y = 0,
      opacity = 0.3,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 46 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.9985 32.882L39.0915 27.977C38.1245 27.008 36.8365 26.476 35.4675 26.476C34.0985 26.476 32.8115 27.008 31.8425 27.977L30.5275 29.29C25.3885 25.04 20.4525 20.103 16.2105 14.971L17.5245 13.656C19.5225 11.658 19.5225 8.406 17.5245 6.408L12.6175 1.502C11.6495 0.533 10.3625 0 8.99248 0C7.62348 0 6.33548 0.533 5.36748 1.502L2.67648 4.194C0.190484 6.679 -0.204516 10.615 1.73748 13.553C9.60548 25.447 20.0515 35.894 31.9475 43.762C33.1695 44.57 34.5895 44.997 36.0535 44.997C38.0405 44.997 39.9065 44.225 41.3065 42.823L43.9985 40.131C44.9665 39.164 45.5005 37.877 45.5005 36.507C45.5005 35.139 44.9665 33.851 43.9985 32.882Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
