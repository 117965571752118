/** @jsx createElement */
import './slides.scss';
import { observer } from 'mobx-react';
import { createElement } from 'react';
import './app.scss';
import { BackgroundGrid } from './background-grid';
import { KitchenSink } from './kitchen-sink';
import { Login } from './login';
import { SlideList } from './slide-list';
import { SlideShow } from './slide-show';
import { Router, RouteManager } from 'vega-router';
import { Navigation } from './navigation';
import { KeyboardShortcuts } from './keyboard-shortcuts';
import { AppActions, AppState } from './app-actions';
import { Button } from './button';
import { ComplianceAlignment } from './compliance-alignment/compliance-alignment';
import { Killchain } from './killchain/killchain';
import { RiskAnalysisTool } from './risk-analysis-tool/risk-analysis-tool';
import { SOCCalculator } from './soc-calculator/soc-calculator';
import { UseCasePicker } from './use-case-picker';
import { Modal } from './modal';

import './focus-within.polyfill.js';
import { MitreMapping } from './mitre-mapping/mitre-mapping';

@observer
export class App extends Router {
  constructor(props) {
    super(props);

    RouteManager.setRouteClassName('Content');

    this.addRoute('login', /^\/login/, Login);
    this.addRoute('kitchen-sink', /^\/(kitchen|sink)/, KitchenSink);
    this.addRoute('slide-show', /^\/([^/]+)\/slides\/?(.*)/, SlideShow, [
      'useCaseKey',
      'slideNumber',
    ]);
    this.addRoute('slide-list', /^\/([^/]+)\/list/, SlideList, ['useCaseKey']);
    this.addRoute('risk', /^\/risk/, RiskAnalysisTool);
    this.addRoute('soc', /^\/soc/, SOCCalculator);
    this.addRoute('compliance', /^\/compliance/, ComplianceAlignment);
    this.addRoute('killchain', /^\/killchain/, Killchain);
    this.addRoute('use-cases', /^\/use-cases/, UseCasePicker);
    this.addRoute('mitre', /^\/mitre/, MitreMapping);

    // Must be last
    this.addRoute('navigation', /^\/(.*)/, Navigation);
  }

  componentDidMount() {
    this.checkAuth();

    AppActions.setUseCaseKey(this.params.useCaseKey);
  }

  componentDidUpdate() {
    this.checkAuth();

    if (
      this.params.useCaseKey &&
      AppState.useCaseKey !== this.params.useCaseKey
    ) {
      AppActions.setUseCaseKey(this.params.useCaseKey);
    }
  }

  checkAuth = async () => {
    await AppActions.checkAuth();

    if (this.route.name !== 'login' && !AppState.authed) {
      const redirect =
        window.location.pathname.indexOf('/login') !== 0
          ? `?redirect=${window.location.pathname}`
          : '';

      RouteManager.transitionTo(`/login${redirect}`);
    }
  };

  handleCloseAlert = () => {
    AppActions.hideAlert();
  };

  handleShortcut = chord => () => {
    switch (chord) {
      case 'gk':
        RouteManager.transitionTo('/kitchen-sink');
        break;
      case 'ge':
        if (AppState.useCaseKey)
          RouteManager.transitionTo(`/${AppState.useCaseKey}/list`);
        else
          AppActions.showAlert(
            'Error',
            'Cannot navigate to the events list because no use case is selected.'
          );
        break;
      case 'gu':
        RouteManager.transitionTo('/use-cases');
        break;
      case 'gr':
        return RouteManager.transitionTo('/risk');
    }
  };

  render() {
    const { children, className = '' } = this.props;

    // Wait for initial auth check
    if (AppState.initial) {
      return null;
    }

    return (
      <div className={`App ${className}`} tabIndex={-1}>
        {AppState.authed && (
          <KeyboardShortcuts
            shortcuts={{
              gk: this.handleShortcut('gk'),
              gu: this.handleShortcut('gu'),
              ge: this.handleShortcut('ge'),
              gr: this.handleShortcut('gr'),
            }}
          />
        )}
        {AppState.authed && (
          <BackgroundGrid
            hidden={!AppState.gridShow}
            width="100%"
            height="100%"
          />
        )}
        {super.render()}
        {children}
        <Modal show={AppState.alertShow} onClose={this.handleCloseAlert}>
          <h3>{AppState.alertTitle}</h3>
          <p>{AppState.alertMessage}</p>
          <Button onClick={this.handleCloseAlert}>Ok</Button>
        </Modal>
      </div>
    );
  }
}
