import { observable, reaction } from 'mobx';
import { RiskAnalysisToolState } from '../risk-analysis-tool-actions';
import { Scenario, Interval } from '../../../data/graph-data-transforms';

export const INTERVAL_OPTIONS = [
  { label: 'Instantaneous', value: Interval.INSTANTANEOUS },
  { label: 'Cumulative', value: Interval.CUMULATIVE },
];

export const SCENARIO_OPTIONS = [
  { label: 'Average Case', value: Scenario.AVERAGE },
  { label: 'Worst Case', value: Scenario.WORST },
  { label: 'Best Case', value: Scenario.BEST },
];

export const RiskAnalysisProbabilityState = observable({
  interval: INTERVAL_OPTIONS[0],
  scenario: SCENARIO_OPTIONS[0],
  filters: [
    {
      filter: 'Technology',
      attackType: 'Malware',
    },
    {
      filter: 'Technology',
      attackType: 'Phishing',
    },
    {
      filter: 'Technology',
      attackType: 'Exploit',
    },
  ],
});

export class RiskAnalysisProbabilityActions {
  static updateField(name, value) {
    RiskAnalysisProbabilityState[name] = value;
  }

  static updateFilter(filterToUpdate, enabled) {
    const existingFilter = RiskAnalysisProbabilityState.filters.find(
      filter =>
        filter.filter === filterToUpdate.filter &&
        filter.attackType === filterToUpdate.attackType
    );
    if (enabled && !existingFilter) {
      RiskAnalysisProbabilityState.filters = RiskAnalysisProbabilityState.filters.concat(
        filterToUpdate
      );
    } else if (!enabled && existingFilter) {
      RiskAnalysisProbabilityState.filters.splice(
        RiskAnalysisProbabilityState.filters.indexOf(existingFilter),
        1
      );
    }
  }
}

reaction(
  () => [RiskAnalysisToolState.filter],
  () => {
    if (!RiskAnalysisToolState.filter) return;

    RiskAnalysisProbabilityState.filters = [
      {
        filter: RiskAnalysisToolState.filter.value,
        attackType: 'Malware',
      },
      {
        filter: RiskAnalysisToolState.filter.value,
        attackType: 'Phishing',
      },
      {
        filter: RiskAnalysisToolState.filter.value,
        attackType: 'Exploit',
      },
    ];
  }
);
