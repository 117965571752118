/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Windows extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 13,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          fill={color}
          fillRule="evenodd"
          clipRule="evenodd"
          fillOpacity={opacity}
        >
          <path d="M0 8.49501L24.4445 5.16591L24.4552 28.7447L0.02233 28.8838L0 8.49501ZM24.4329 31.4614L24.4519 55.0607L0.0189737 51.7015L0.0176037 31.3031L24.4329 31.4614ZM27.3962 4.73041L59.8076 0V28.4448L27.3962 28.7021V4.73041ZM59.8151 31.6833L59.8075 60L27.3961 55.4255L27.3507 31.6303L59.8151 31.6833Z" />
        </g>
      </svg>
    );
  }
}
