/** @jsx createElement */
import { createElement, Component } from 'react';
import './badge.scss';

export class Badge extends Component {
  render() {
    const {
      children,
      className = '',
      text = '',
      primary = false,
      danger = false,
    } = this.props;

    let badgeType = '';
    if (primary) {
      badgeType = ' Badge--primary';
    } else if (danger) {
      badgeType = ' Badge--danger';
    }

    return (
      <div className={`Badge${badgeType} ${className}`}>
        {text}
        {children}
      </div>
    );
  }
}
