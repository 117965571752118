/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Plugin extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 2,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...containerProps}
      >
        <g
          stroke={color}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          opacity={opacity}
        >
          <path d="M52.0089 28.7054C53.9077 28.7054 55.5476 29.3958 56.9286 30.7768C58.3095 32.0714 59 33.6682 59 35.567C59 37.4658 58.3095 39.1057 56.9286 40.4866C55.5476 41.7813 53.9077 42.4286 52.0089 42.4286H47.8661V53.5625C47.8661 55.0298 47.3051 56.3244 46.183 57.4464C45.1473 58.4821 43.8958 59 42.4286 59H31.942V54.8571C31.942 52.7857 31.2083 51.0595 29.7411 49.6786C28.2738 48.2113 26.5045 47.4777 24.433 47.4777C22.3616 47.4777 20.5923 48.2113 19.125 49.6786C17.6577 51.0595 16.9241 52.7857 16.9241 54.8571V59H6.4375C4.97024 59 3.6756 58.4821 2.55357 57.4464C1.51786 56.3244 1 55.0298 1 53.5625V43.0759H5.14286C7.21429 43.0759 8.94048 42.3423 10.3214 40.875C11.7887 39.4077 12.5223 37.6384 12.5223 35.567C12.5223 33.4955 11.7887 31.7262 10.3214 30.2589C8.94048 28.7917 7.21429 28.058 5.14286 28.058H1V17.5714C1 16.1042 1.51786 14.8527 2.55357 13.817C3.6756 12.6949 4.97024 12.1339 6.4375 12.1339H17.5714V7.99107C17.5714 6.09226 18.2188 4.45238 19.5134 3.07143C20.8943 1.69048 22.5342 1 24.433 1C26.3318 1 27.9286 1.69048 29.2232 3.07143C30.6042 4.45238 31.2946 6.09226 31.2946 7.99107V12.1339H42.4286C43.8958 12.1339 45.1473 12.6949 46.183 13.817C47.3051 14.8527 47.8661 16.1042 47.8661 17.5714V28.7054H52.0089Z" />
        </g>
      </svg>
    );
  }
}
