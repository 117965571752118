/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { Template__TeamScansThreats } from '../../templates/team-scans-threats';

// Players
import { PlayerBoxScanner } from '../players/box-scanner';

@observer
export class Slide08 extends Component {
  componentDidMount() {
    // Do something
  }

  render() {
    const { children } = this.props;
    return (
      <div className={`slide-08`}>
        {children}
        <Template__TeamScansThreats />
      </div>
    );
  }
}
