/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class FingerPrint extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 32,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 26 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.8959 5.21602C22.641 4.88591 22.2292 4.71701 21.8159 4.773C21.4026 4.829 21.0506 5.10137 20.8927 5.48741C20.7348 5.87345 20.795 6.31442 21.0506 6.64402C22.4294 8.41897 23.1744 10.6044 23.1666 12.852V19.148C23.1607 24.7605 18.6124 29.3088 12.9999 29.3147C12.3556 29.3147 11.8333 29.837 11.8333 30.4814C11.8333 31.1257 12.3556 31.648 12.9999 31.648C19.9004 31.6407 25.4926 26.0485 25.4999 19.148V12.852C25.5093 10.0873 24.5926 7.39899 22.8959 5.21602Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M9.04668 28.5173C5.27727 26.931 2.82795 23.2376 2.83335 19.148V16.6293C2.83335 15.985 2.31101 15.4627 1.66668 15.4627C1.02235 15.4627 0.500013 15.985 0.500013 16.6293V19.148C0.492863 24.1759 3.50422 28.7169 8.13868 30.6667C8.73085 30.9113 9.40947 30.6326 9.65881 30.0424C9.90814 29.4522 9.63487 28.7714 9.04668 28.5173Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M1.61992 13.388H1.68525C2.3041 13.3869 2.81464 12.9032 2.84926 12.2853C3.04698 8.75974 5.05946 5.58907 8.16558 3.90947C11.2717 2.22988 15.0268 2.28182 18.0853 4.04668C18.4464 4.26545 18.8976 4.27199 19.265 4.06378C19.6323 3.85557 19.8585 3.46511 19.8563 3.04286C19.8542 2.6206 19.624 2.23247 19.2546 2.02802C15.4938 -0.144145 10.8752 -0.208835 7.05507 1.85715C3.23495 3.92313 0.7608 7.82371 0.519922 12.16C0.502473 12.4688 0.608665 12.7719 0.815051 13.0023C1.02144 13.2327 1.31104 13.3715 1.61992 13.388Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M21.7226 19.148V12.852C21.7205 10.0555 20.3786 7.42924 18.1137 5.78887C15.8488 4.14849 12.9351 3.69257 10.2773 4.56267C9.86848 4.68152 9.55685 5.01364 9.46424 5.42921C9.37164 5.84478 9.51276 6.27779 9.83245 6.55898C10.1521 6.84018 10.5996 6.92489 11 6.78001C12.9469 6.14472 15.0802 6.4795 16.7389 7.68061C18.3976 8.88172 19.3814 10.8041 19.3853 12.852V19.148C19.3853 19.7923 19.9076 20.3147 20.552 20.3147C21.1963 20.3147 21.7186 19.7923 21.7186 19.148H21.7226Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M8.14668 6.94534C7.91622 6.73896 7.61315 6.63269 7.30427 6.64995C6.99539 6.66721 6.70605 6.80657 6.50001 7.03734C5.06755 8.63522 4.27598 10.706 4.27735 12.852V19.148C4.27767 22.764 6.50903 26.0049 9.88689 27.2954C13.2648 28.586 17.0886 27.6586 19.5 24.964C19.8079 24.6592 19.9215 24.2089 19.7951 23.7946C19.6688 23.3802 19.3232 23.07 18.8976 22.9889C18.4721 22.9078 18.0366 23.0691 17.7667 23.408C16.0006 25.3829 13.1989 26.0629 10.724 25.1175C8.24907 24.1721 6.61434 21.7974 6.61468 19.148V12.852C6.61405 11.2806 7.19326 9.76419 8.24135 8.59334C8.66985 8.11196 8.62749 7.37448 8.14668 6.94534Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M10.3892 19.148C10.3892 18.5037 9.86691 17.9814 9.22257 17.9814C8.57824 17.9814 8.05591 18.5037 8.05591 19.148C8.05591 21.8785 10.2694 24.092 12.9999 24.092C15.7304 24.092 17.9439 21.8785 17.9439 19.148V12.852C17.9439 10.1215 15.7304 7.90802 12.9999 7.90802C10.2694 7.90802 8.05591 10.1215 8.05591 12.852V14.7414C8.05591 15.3857 8.57824 15.908 9.22257 15.908C9.86691 15.908 10.3892 15.3857 10.3892 14.7414V12.852C10.3892 11.4102 11.5581 10.2414 12.9999 10.2414C14.4417 10.2414 15.6106 11.4102 15.6106 12.852V19.148C15.6106 20.5899 14.4417 21.7587 12.9999 21.7587C11.5581 21.7587 10.3892 20.5899 10.3892 19.148Z"
          fill={color}
          fillOpacity={opacity}
        />
        <path
          d="M14.1666 18.5187V14.7413C14.1666 14.097 13.6443 13.5747 12.9999 13.5747C12.3556 13.5747 11.8333 14.097 11.8333 14.7413V18.5187C11.8333 19.163 12.3556 19.6853 12.9999 19.6853C13.6443 19.6853 14.1666 19.163 14.1666 18.5187Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
