/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Shodan extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 60,
      x = 0,
      y = 0,
      opacity = 1,
      strokeWidth = 2,
      containerProps,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ overflow: 'visible' }}
        {...containerProps}
      >
        <g stroke={color} strokeWidth={strokeWidth} opacity={opacity}>
          <path d="M43.4075 24.0542C45.6442 21.5027 47 18.1597 47 14.5C47 6.49187 40.5081 0 32.5 0C26.6429 0 21.597 3.4727 19.3088 8.47143C18.0902 8.16356 16.8142 8 15.5 8C6.93959 8 0 14.9396 0 23.5C0 32.0604 6.93959 39 15.5 39C19.1545 39 22.5137 37.7352 25.1636 35.6195C24.4116 37.6027 24 39.7533 24 42C24 51.9411 32.0589 60 42 60C51.9411 60 60 51.9411 60 42C60 32.5325 52.6908 24.7722 43.4075 24.0542ZM44 14.5C44 20.8513 38.8513 26 32.5 26C30.8665 26 29.3126 25.6594 27.9054 25.0455C27.1441 31.2198 21.8803 36 15.5 36C8.59644 36 3 30.4036 3 23.5C3 16.5964 8.59644 11 15.5 11C17.5519 11 19.4884 11.4944 21.1968 12.3706C22.1954 7.0367 26.8763 3 32.5 3C38.8513 3 44 8.14873 44 14.5ZM42 57C50.2843 57 57 50.2843 57 42C57 33.7157 50.2843 27 42 27C33.7157 27 27 33.7157 27 42C27 50.2843 33.7157 57 42 57Z" />
          <path d="M43 14.5C43 20.299 38.299 25 32.5 25C30.4833 25 28.5994 24.4315 26.9999 23.4459L27 23.5C27 29.8513 21.8513 35 15.5 35C9.14873 35 4 29.8513 4 23.5C4 17.1487 9.14873 12 15.5 12C17.9169 12 20.1596 12.7456 22.0108 14.0193C22.2621 8.44342 26.8621 4 32.5 4C38.299 4 43 8.70101 43 14.5ZM39 14.5C39 18.0899 36.0899 21 32.5 21C28.9101 21 26 18.0899 26 14.5C26 10.9101 28.9101 8 32.5 8C36.0899 8 39 10.9101 39 14.5ZM23 23.5C23 27.6421 19.6421 31 15.5 31C11.3579 31 8 27.6421 8 23.5C8 19.3579 11.3579 16 15.5 16C19.6421 16 23 19.3579 23 23.5Z" />
          <path d="M42 56C49.732 56 56 49.732 56 42C56 34.268 49.732 28 42 28C34.268 28 28 34.268 28 42C28 49.732 34.268 56 42 56ZM42 51C46.9706 51 51 46.9706 51 42C51 37.0294 46.9706 33 42 33C37.0294 33 33 37.0294 33 42C33 46.9706 37.0294 51 42 51Z" />
        </g>
      </svg>
    );
  }
}
