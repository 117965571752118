/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement, Component, HTMLAttributes } from 'react';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerInfoBlock } from '../players/info-block';
import { PlayerPath } from '../players/path';

// SVG
import { Shield } from '../svg/shield';
import { TRU } from '../svg/tru';
import { EsentireE } from '../svg/esentire-e';

@observer
export class Slide10 extends AnimatedSlide {
  // Animation players
  players = {
    team1: null,
    team2: null,
    info1: null,
    info2: null,
  };

  // Team shield
  team1 = {
    icon: <TRU size={60} color={this.colors.white} opacity={1} />,
    text: 'TRU Team',
    stroke: this.colors.blue,
    strokeOpacity: 1,
    letterSpacing: 0,
  };

  // Team shield
  team2 = {
    icon: <EsentireE size={50} color={this.colors.white} opacity={1} />,
    text: 'SOC Analysts',
    stroke: this.colors.blue,
    strokeOpacity: 1,
    letterSpacing: 0,
    iconOffsetY: -10,
  };

  // Run the first animation
  componentDidMount() {
    const { team1, team2, info1, info2 } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(1500);
        this.autoNext(200);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(team1, 0),
        this.fadeIn(info1, 1000),
        this.fadeIn(info2, 1500),
        this.fadeIn(team2, 2000),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    let dots = {
      stroke: this.colors.blue,
      strokeWidth: 2,
      strokeDashArray: '2,2',
    };

    return (
      <div className={`slide-10 ${className}`}>
        {children}
        <div className="content">
          {/* -------------------CONTENT------------------- */}
          {/* Teams */}
          <div
            className="player team1"
            data-active="0"
            ref={node => (this.players.team1 = node)}
          >
            <Shield {...this.team1} />
            <PlayerPath
              points={['0,98', '0,78', '70,0', '110,0']}
              reveal={this.revealPreset(1000, 1000)}
            />
          </div>
          <div
            className="player team2"
            data-active="0"
            ref={node => (this.players.team2 = node)}
          >
            <Shield {...this.team2} />
          </div>
          {/* Info */}
          <div
            className="player info1"
            data-active="0"
            ref={node => (this.players.info1 = node)}
          >
            <PlayerInfoBlock
              gap={10}
              width={280}
              animation={{
                delay: 1000,
                increment: 500,
              }}
              sections={[
                {
                  type: 'header',
                  color: 'blue',
                  content: 'DETECTOR DETAILS',
                },
                {
                  type: 'grid',
                  content: [
                    {
                      html: 'DETECTS URP PATTERNS & STATUS CODES',
                      preset: 'blueBorder',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'mitreid' },
                    {
                      preset: 'blue',
                      html: 'T1190',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'deployment state' },
                    {
                      preset: 'blue',
                      html: 'Production',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'deployed' },
                    {
                      preset: 'blue',
                      html: 'Aug 13, 2021',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'service' },
                    {
                      preset: 'blue',
                      html: 'MDR for Log',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'product' },
                    {
                      preset: 'blue',
                      html: 'Sumologic',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'LABEL' },
                    {
                      preset: 'blue',
                      html: 'Exchange',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'LABEL' },
                    {
                      preset: 'blue',
                      html: 'InitialAccess',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'LABEL' },
                    {
                      preset: 'blue',
                      html: 'ProxyShell',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'LABEL' },
                    {
                      preset: 'blue',
                      html: 'WebServer',
                    },
                  ],
                },
              ]}
            />
          </div>
          <div
            className="player info2"
            data-active="0"
            ref={node => (this.players.info2 = node)}
          >
            <PlayerInfoBlock
              gap={10}
              width={280}
              animation={{
                delay: 2000,
                increment: 500,
              }}
              sections={[
                {
                  type: 'header',
                  color: 'blue',
                  content: 'INVESTIGATION STEPS',
                },
                {
                  type: 'grid',
                  content: [
                    {
                      html:
                        'GET: “/autodiscover.json” AND (“/powershell” OR “/mapi/nspi” OR “/EWS” OR “X-Rps-CAT”) AND (“200” or “301”)',
                      preset: 'blueBorder',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'CS_URI_STEM' },
                    {
                      preset: 'orange',
                      html: '/mapi/nspi/',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'CS_PORT' },
                    {
                      preset: 'orange',
                      html: '444',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'CS_IP' },
                    {
                      preset: 'orange',
                      html: '10.0.0.91',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'CS_USER_AGENT' },
                    {
                      preset: 'orange',
                      html: 'python-requests/2.25.1',
                    },
                  ],
                },
                {
                  type: 'cells',
                  content: [
                    { html: 'cs_status' },
                    {
                      preset: 'red',
                      html: '401',
                    },
                  ],
                },
                {
                  type: 'grid',
                  content: [
                    {
                      className: 'small',
                      html:
                        '&Email=autodiscover/autodiscover.json%3F@foo.com& FrontEnd=Bck2TheFuture81& RequestId=647a3e53-fb45-49db-9630-b29fbb0f2ec6& ClientRequestInfo=R:<null>;RT:<null>;CI:<null>; CID:<null>& Stage=BegR:2021-08-10T07:24:37.4720302Z;EndR:0',
                      preset: 'redFutura',
                    },
                  ],
                },
              ]}
            />
            <div className="paths">
              <PlayerPath
                points={['0,0', '20,0', '88,64']}
                dots={dots}
                reveal={this.revealPreset(3000, 1000)}
              />
              <PlayerPath
                points={['0,0', '80,0', '120,30', '157,30']}
                dots={dots}
                gradient={{ preset: 'orangeBlue', direction: 'right' }}
                reveal={this.revealPreset(3500, 1000)}
              />
              <PlayerPath
                points={['0,0', '231,0']}
                dots={dots}
                gradient={{ preset: 'orangeBlue', direction: 'right' }}
                reveal={this.revealPreset(4000, 1000)}
              />
              <PlayerPath
                points={['0,30', '138,30', '178,0', '212,0']}
                dots={dots}
                gradient={{ preset: 'orangeBlue', direction: 'right' }}
                reveal={this.revealPreset(4500, 1000)}
              />
              <PlayerPath
                points={['0,58', '59,0', '76,0']}
                dots={dots}
                gradient={{ preset: 'orangeBlue', direction: 'right' }}
                reveal={this.revealPreset(5000, 1000)}
              />
              <PlayerPath
                points={['0,76', '153,76', '230,0']}
                dots={dots}
                gradient={{ preset: 'blueRed' }}
                reveal={this.revealPreset(5500, 1000)}
              />
              <PlayerPath
                points={['0,80', '15,80', '90,0']}
                dots={dots}
                gradient={{ preset: 'blueRed' }}
                reveal={this.revealPreset(6000, 1000)}
              />
            </div>
          </div>

          {/* -------------------CONTENT------------------- */}
        </div>
      </div>
    );
  }
}
