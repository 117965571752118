/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class DiskDrive extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 75,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 75 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.7778 12.4451C62.9145 12.4451 62.2222 11.7498 62.2222 10.8895C62.2222 10.0293 62.9145 9.33398 63.7778 9.33398C64.6349 9.33398 65.3333 10.0293 65.3333 10.8895C65.3333 11.7498 64.6349 12.4451 63.7778 12.4451ZM63.7778 18.6658H10.8889C10.0302 18.6658 9.33331 17.9705 9.33331 17.1102C9.33331 16.25 10.0302 15.5547 10.8889 15.5547H63.7778C64.6364 15.5547 65.3333 16.25 65.3333 17.1102C65.3333 17.9705 64.6364 18.6658 63.7778 18.6658ZM57.5556 9.33398C58.4127 9.33398 59.1111 10.0293 59.1111 10.8895C59.1111 11.7498 58.4127 12.4451 57.5556 12.4451C56.6922 12.4451 56 11.7498 56 10.8895C56 10.0293 56.6922 9.33398 57.5556 9.33398ZM66.8889 0H7.77778C3.48911 0 0 3.48911 0 7.77778V20.2222C0 24.5109 3.48911 28 7.77778 28H66.8889C71.1776 28 74.6667 24.5109 74.6667 20.2222V7.77778C74.6667 3.48911 71.1776 0 66.8889 0Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
