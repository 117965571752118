/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';

// Players
import { PlayerCircleIcon } from '../players/circle-icon';
import { PlayerTextBlock } from '../players/text-block';

// Icons
import { Page } from '../svg/page';
import { Lock } from '../svg/lock';

@observer
export class Slide23 extends AnimatedSlide {
  // Animation players
  players = {
    lock: null,
    filename: null,
    data: null,
    page1: null,
    page2: null,
    page3: null,
    page4: null,
    page5: null,
    page6: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      lock,
      filename,
      data,
      page1,
      page2,
      page3,
      page4,
      page5,
      page6,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
      },
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: page1,
          easing: 'easeInOutCubic',
          opacity: [1, 0],
          delay: 0,
          translateX: -100,
          translateY: 20,
          duration: 500,
        },
        {
          targets: page2,
          easing: 'easeInOutCubic',
          opacity: [1, 0],
          delay: 100,
          translateX: -160,
          translateY: 20,
          duration: 500,
        },
        {
          targets: page3,
          easing: 'easeInOutCubic',
          opacity: [1, 0],
          delay: 200,
          translateX: -220,
          translateY: 20,
          duration: 500,
        },
        {
          targets: page4,
          easing: 'easeInOutCubic',
          opacity: [1, 0],
          delay: 300,
          translateX: -100,
          translateY: -10,
          duration: 500,
        },
        {
          targets: page5,
          easing: 'easeInOutCubic',
          opacity: [1, 0],
          delay: 400,
          translateX: -160,
          translateY: -10,
          duration: 500,
        },
        {
          targets: page6,
          easing: 'easeInOutCubic',
          opacity: [1, 0],
          delay: 500,
          translateX: -220,
          translateY: -10,
          duration: 500,
        },

        // Animate other stuff in
        {
          targets: lock,
          opacity: 1,
          delay: 500,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: filename,
          opacity: 1,
          delay: 500,
          duration: 200,
          begin: activateShimmer,
        },
        {
          targets: data,
          opacity: 1,
          delay: 1000,
          duration: 200,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-23 ${className}`}>
        {children}
        <div className="content">
          {/* Pages */}
          <div className={`player pages`} data-active="1">
            <div className={'page'} ref={node => (this.players.page1 = node)}>
              <Page size={50} />
            </div>
            <div className={'page'} ref={node => (this.players.page2 = node)}>
              <Page size={50} />
            </div>
            <div className={'page'} ref={node => (this.players.page3 = node)}>
              <Page size={50} />
            </div>
            <div className={'page'} ref={node => (this.players.page4 = node)}>
              <Page size={50} />
            </div>
            <div className={'page'} ref={node => (this.players.page5 = node)}>
              <Page size={50} />
            </div>
            <div className={'page'} ref={node => (this.players.page6 = node)}>
              <Page size={50} />
            </div>
          </div>

          {/* VPN Info */}
          <div
            className="player filename"
            data-active="0"
            ref={node => (this.players.filename = node)}
          >
            <PlayerTextBlock
              title={'Filename'}
              className={''}
              showDots={true}
              sections={[
                {
                  title: ['HTTP-FADYqA4Sgw...'],
                },
              ]}
            />
          </div>

          {/* Login attempt */}
          <div
            className="player data"
            data-active="0"
            ref={node => (this.players.data = node)}
          >
            <PlayerTextBlock
              title={'File data'}
              className={''}
              sections={[
                {
                  lines: 3,
                  animate: true,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                  animate: true,
                  delay: 400,
                },
                {
                  title: ['Confidential info acquired'],
                  className: 'red-box',
                  animate: true,
                  delay: 800,
                },
              ]}
            />
          </div>

          {/* Folder */}
          <div
            className="player lock"
            data-active="0"
            ref={node => (this.players.lock = node)}
          >
            <PlayerCircleIcon
              color={'#ffffff'}
              Icon={Page}
              IconSize={'50'}
              showTitle={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
