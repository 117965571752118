/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Alert } from '../svg/alert';
import { Computer } from '../svg/computer';
import { Shield } from '../svg/shield';
import { Server } from '../svg/server';
import { ComputerGroup } from '../svg/computer-group';
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerDots } from '../players/dots';
import { Badge } from '../badge';
import { Warning } from '../svg/warning';
import { PlayerCircleIcon } from '../players/circle-icon';
import { Binoculars } from '../svg/binoculars';
import { Download2 } from '../svg/download-2';

@observer
export class Slide04 extends AnimatedSlide {
  // Animation players
  players = {
    computerGroup: null,
    dotsLeft: null,
    dotsRight1: null,
    dotsRight2: null,
    computer: null,
    recon: null,
    db1: null,
    db2: null,
  };

  // Run the first animation
  componentDidMount() {
    const {
      computerGroup,
      dotsLeft,
      recon,
      dotsRight1,
      dotsRight2,
      db1,
      db2,
      computer,
      download,
    } = this.players;

    this.steps = [
      // Step 1
      () => {
        this.animateProgress(4000);
        this.autoNext(3000);
      },
      // Step 2
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: computerGroup,
          easing: 'easeOutQuad',
          opacity: 0,
          duration: 500,
          delay: 1000,
        },
        {
          targets: db1,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 1000,
          translateY: -150,
          translateX: -50,
        },
        {
          targets: db2,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          delay: 1000,
          translateY: 150,
          translateX: -50,
        },
      ],

      //
      // Step 2
      //
      [
        {
          targets: dotsRight1,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 400,
          begin: activateShimmer,
        },
        {
          targets: dotsRight2,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 300,
          begin: activateShimmer,
        },
        {
          targets: dotsLeft,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 200,
          begin: activateShimmer,
        },
        {
          targets: recon,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 0,
          opacity: 0,
        },
        {
          targets: download,
          easing: 'easeOutQuad',
          duration: 500,
          delay: 0,
          opacity: 1,
          begin: activate,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-04 ${className}`}>
        {children}
        <div className="content">
          {/* computer group */}
          <div
            className="player computerGroup"
            data-active="1"
            ref={node => (this.players.computerGroup = node)}
          >
            <ComputerGroup color={'#ffffff'} />
          </div>

          {/* computer */}
          <div
            className="player computer"
            data-active="1"
            ref={node => (this.players.computer = node)}
          >
            <Card IconComponent={Computer} text={'TSMITH-DESKTOP'} />
          </div>

          {/* db1 */}
          <div
            className="player db1"
            data-active="1"
            ref={node => (this.players.db1 = node)}
          >
            <Card IconComponent={Server} text={'DBSERVER01'} />
          </div>

          {/* db2 */}
          <div
            className="player db2"
            data-active="1"
            ref={node => (this.players.db2 = node)}
          >
            <Card IconComponent={Server} text={'DBSERVER02'} />
          </div>

          {/* Animated dots left */}
          <div
            className="player dots-left"
            data-active="0"
            ref={node => (this.players.dotsLeft = node)}
          >
            <PlayerDots dots={6} className={'reverse-collection'} />
          </div>

          {/* Animated dots right 1 */}
          <div
            className="player dots-right-1"
            data-active="0"
            ref={node => (this.players.dotsRight1 = node)}
          >
            <PlayerDots className={'reverse-collection'} />
          </div>

          {/* Animated dots right 2 */}
          <div
            className="player dots-right-2"
            data-active="0"
            ref={node => (this.players.dotsRight2 = node)}
          >
            <PlayerDots className={'reverse-collection'} />
          </div>

          {/* Recon */}
          <div
            className="player recon"
            data-active="1"
            ref={node => (this.players.recon = node)}
          >
            <PlayerCircleIcon Icon={Binoculars} />
          </div>

          {/* Download */}
          <div
            className="player download"
            data-active="0"
            ref={node => (this.players.download = node)}
          >
            <PlayerCircleIcon Icon={Download2} />
          </div>
        </div>
      </div>
    );
  }
}
