/** @jsx createElement */
import { observer } from 'mobx-react';
import { createRef, createElement } from 'react';
import { AnimatedSlide } from '../components/animated-slide';
import { getColor } from '../components/helpers';

// SCSS
import './team-identifies-exploit.scss';

// Players
import { PlayerPath } from '../components/players/path';
import { PlayerDataScan } from '../components/players/data-scan';
import { PlayerReveal } from '../components/players/reveal';

// SVG
import { Shield } from '../components/svg/shield';
import { TRU } from '../components/svg/tru';
import { Proxyshell } from '../components/svg/proxyshell';

@observer
export class Template__TeamIdentifiesExploit extends AnimatedSlide {
  // Animation players
  players = {
    team: null,
    path1: null,
    path2: null,
    path3: null,
    path4: null,
    scanner: null,
    reveal: null,
  };

  children = {
    gradient1: createRef(),
    gradient2: createRef(),
  };

  // Team shield
  team = {
    icon: <TRU size={60} color={this.colors.white} opacity={1} />,
    text: 'TRU Team',
    stroke: this.colors.blue,
    strokeOpacity: 1,
  };

  scanner = {
    width: 180,
    height: 160,
    gap: [10, 4],
    rows: 20,
    increment: 0.25,
    columns: [
      '20px 1fr 1fr',
      '20px 1fr 1fr 1fr',
      '20px 1fr 1fr 1fr 1fr',
      '20px 2fr 1fr',
      '20px 2fr 1.5fr 1fr',
      '20px 1fr 1.5fr 2fr 1fr',
    ],
  };

  // Exploit revealer
  reveal = {
    // icon: <Proxyshell size={70} color={this.colors.white} opacity={1} />,
    IconComponent: Proxyshell,
    text: 'PoC exploit',
    width: 160,
    height: 160,
    delay: 1000,
    complete: () => this.changeGradients(),
  };

  changeGradients() {
    if (!this.children.gradient1.current) return;
    this.children.gradient1.current.change();
    this.children.gradient2.current.change();
    this.players.reveal.classList.add('red');
  }

  // Run the first animation
  componentDidMount() {
    const { team, path1, path2, path3, path4, scanner, reveal } = this.players;

    this.steps = [
      // Initial step
      () => {
        this.animateProgress(4000);
        this.autoNext(500);
      },
      // Step 4
      () => {},
    ];

    this.animations = [
      //
      // Step 0
      //
      [],

      //
      // Step 1
      //
      [
        this.fadeIn(team, 0),
        this.fadeIn(scanner, 1000),
        this.fadeIn(reveal, 2000),
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const {
      children,
      className = '',
      team = {},
      reveal = {},
      scanner = {},
    } = this.props;

    Object.assign(this.team, team);
    Object.assign(this.reveal, reveal);
    Object.assign(this.scanner, scanner);

    // Line reveal settings
    let reveal1 = { delay: 1000, duration: 1000 };
    let reveal3 = { delay: 1500, duration: 1000 };
    let reveal4 = { delay: 2500, duration: 1000 };
    let reveal2 = { delay: 3000, duration: 1000 };

    return (
      <div className={`Template__TeamIdentifiesExploit ${className}`}>
        {children}
        <div className="content">
          {/* --------------- BEGINNING OF CONTENT ---------------*/}

          {/* TRU Team */}
          <div
            className="player team"
            data-active="0"
            ref={node => (this.players.team = node)}
          >
            <Shield {...this.team} />
          </div>

          {/* Data scanner */}
          <div
            className="player scanner"
            data-active="0"
            ref={node => (this.players.scanner = node)}
          >
            <PlayerDataScan {...this.scanner} />
          </div>

          {/* Reveal */}
          <div
            className="player reveal"
            data-active="0"
            ref={node => (this.players.reveal = node)}
          >
            <PlayerReveal {...this.reveal} />
          </div>

          {/* Path 1 */}
          <div
            className="player path1"
            data-active="1"
            ref={node => (this.players.path1 = node)}
          >
            <PlayerPath
              points={['0,50', '40,50', '100,0', '150,0']}
              gradient={{
                color: this.colors.blue,
              }}
              reveal={reveal1}
              data={false}
            />
          </div>

          {/* Path 2 */}
          <div
            className="player path2"
            data-active="1"
            ref={node => (this.players.path2 = node)}
          >
            <PlayerPath
              points={['0,0', '40,0', '100,50', '150,50'].reverse()}
              gradient={{
                color: this.colors.blue,
              }}
              reveal={reveal2}
              data={false}
            />
          </div>

          {/* Path 3 */}
          <div
            className="player path3"
            data-active="1"
            ref={node => (this.players.path3 = node)}
          >
            <PlayerPath
              points={['0,0', '50,0', '110,50', '150,50']}
              gradient={{
                direction: 'right',
                preset: 'blueWhite',
              }}
              gradientChange={{
                direction: 'right',
                preset: 'blueRed',
              }}
              reveal={reveal3}
              data={false}
              ref={this.children.gradient1}
            />
          </div>

          {/* Path 4 */}
          <div
            className="player path4"
            data-active="1"
            ref={node => (this.players.path4 = node)}
          >
            <PlayerPath
              points={['0,50', '50,50', '110,0', '150,0'].reverse()}
              gradient={{
                direction: 'right',
                preset: 'blueWhite',
              }}
              gradientChange={{
                direction: 'right',
                preset: 'blueRed',
              }}
              reveal={reveal4}
              data={false}
              ref={this.children.gradient2}
            />
          </div>

          {/* --------------- END OF CONTENT ---------------*/}
        </div>
      </div>
    );
  }
}
