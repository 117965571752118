/** @jsx createElement */
import { createElement } from 'react';
import './currency-cell.scss';

const formatCurrency = new Intl.NumberFormat('en-US', {
  currency: 'USD',
}).format;

export const CurrencyCell = function(props) {
  const { className = '', value = 0 } = props;

  return (
    <div className={`CurrencyCell ${className}`}>
      <div className="CurrencyCell__Sign">$</div>
      <div className="CurrencyCell__Value">
        {value ? formatCurrency(value) : '-'}
      </div>
    </div>
  );
};
