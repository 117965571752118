/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class NavDot extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#50C2E3',
      size = 10,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={`NavDot ${className}`}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="10"
          height="10"
          rx="5"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
