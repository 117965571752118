/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class Atlas extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      size = 41,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.7626 58.9706C53.7627 54.6834 63.2578 38.2374 58.9706 22.2374C54.6834 6.23735 38.2374 -3.25777 22.2374 1.02942C6.23735 5.31661 -3.25777 21.7626 1.02942 37.7626C5.31661 53.7627 21.7626 63.2578 37.7626 58.9706ZM28.3034 32.9387L33.4604 52.1851C26.8055 47.939 22.8017 43.2767 20.4904 38.7054C19.9348 37.6066 19.473 36.5049 19.0945 35.4062L28.3034 32.9387ZM6.39465 38.8091C10.0185 48.5548 19.2203 54.9182 29.3088 55.1909C22.9258 50.8063 18.7565 45.9114 16.2079 40.8707C15.4961 39.4629 14.9154 38.053 14.4493 36.6508L6.39465 38.8091ZM27.0613 28.3034L17.9536 30.7438C17.6024 28.4107 17.5709 26.1285 17.7535 23.9538C18.1423 19.3234 19.5004 15.2149 20.7785 12.2456C21.2301 11.1967 21.6682 10.2978 22.0438 9.57747L27.0613 28.3034ZM16.3708 10.3482C16.713 9.5532 17.0493 8.83322 17.3632 8.19744C8.41321 13.3775 3.38101 23.7025 5.15263 34.1738L13.2933 31.9925C12.8016 29.0635 12.7477 26.2186 12.9715 23.5523C13.4162 18.2561 14.9577 13.631 16.3708 10.3482ZM38.0957 50.943C41.736 43.9384 42.8723 37.8988 42.5882 32.7843C42.52 31.5549 42.3691 30.3699 42.1476 29.2291L32.9387 31.6966L38.0957 50.943ZM46.7927 27.9845C47.0902 29.4318 47.2922 30.9431 47.3797 32.5182C47.6929 38.158 46.5296 44.4817 43.1941 51.4703C51.7946 46.1899 56.5819 36.0781 54.8474 25.8262L46.7927 27.9845ZM45.4647 23.3722C44.426 20.5897 43.0503 18.0991 41.5232 15.9019C38.4901 11.5376 34.8425 8.30282 31.9774 6.16643C31.2835 5.64905 30.6323 5.19368 30.0425 4.80002C40.3834 4.81107 49.904 11.2367 53.6053 21.1909L45.4647 23.3722ZM26.679 8.33545C27.3645 8.77151 28.1933 9.33086 29.1088 10.0135C31.7004 11.9459 34.9308 14.8249 37.5826 18.6406C38.8281 20.4326 39.942 22.4249 40.8044 24.6209L31.6966 27.0613L26.679 8.33545Z"
          fill={color}
          fillOpacity={opacity}
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    );
  }
}
