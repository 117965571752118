/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class CheckShield extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      x = 0,
      y = 0,
      size = 42,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 35 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.1818 0L0 7.63636V19.0909C0 29.6864 7.33091 39.5945 17.1818 42C27.0327 39.5945 34.3636 29.6864 34.3636 19.0909V7.63636L17.1818 0ZM13.3636 30.5455L5.72727 22.9091L8.41909 20.2173L13.3636 25.1427L25.9445 12.5618L28.6364 15.2727L13.3636 30.5455Z"
          fill={color}
          fillOpacity={opacity}
        />
      </svg>
    );
  }
}
