/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class List extends Component {
  render() {
    const {
      className = '',
      color = '#ffffff',
      size = 26,
      x = 0,
      y = 0,
      opacity = 1,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 26 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="5" width="21" height="2" fill={color} fillOpacity={opacity} />
        <rect
          x="5"
          y="6"
          width="21"
          height="2"
          fill={color}
          fillOpacity={opacity}
        />
        <rect
          x="5"
          y="12"
          width="21"
          height="2"
          fill={color}
          fillOpacity={opacity}
        />
        <rect width="2" height="2" fill={color} fillOpacity={opacity} />
        <rect y="6" width="2" height="2" fill={color} fillOpacity={opacity} />
        <rect y="12" width="2" height="2" fill={color} fillOpacity={opacity} />
      </svg>
    );
  }
}
