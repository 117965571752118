/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import './circle-icon.scss';
import { Skull } from '../svg/skull';

@observer
export class PlayerCircleIcon extends Component {
  render() {
    const {
      children,
      className = '',
      title = '',
      color = '#C53D43',
      rotate = '25deg',
      align = 'left',
      showDots = false,
      showTitle = true,
      Icon = Skull,
      IconSize = 35,
      size = 70,
    } = this.props;

    const circleStyles = {
      borderColor: color,
      width: size + 'px',
      height: size + 'px',
    };

    const dotStyles = {
      transform: `rotate(${rotate})`,
    };

    return (
      <div className={`player--circleIcon ${className} ${align}`}>
        <div className={'player--circleIcon-circle'} style={circleStyles}>
          <Icon color={color} size={IconSize} />
          {showDots && (
            <div className={'player--circleIcon-dots'} style={dotStyles} />
          )}
        </div>
        {showTitle && <div className={'player--circleIcon-title'}>{title}</div>}
      </div>
    );
  }
}
