/** @jsx createElement */
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { AppActions } from '../app-actions';
import { PlayerTimeline } from '../players/timeline';

@observer
export class Slide12 extends AnimatedSlide {
  // Animation players
  players = {
    timeline: null,
    events: null,
  };

  timeline = {
    unit: 'day', // day, hour, minute
    units: 9,
    start: 0,
    width: 1000,
    details: [
      // Step 1
      {
        start: '00:00:00:00',
        end: '00:00:30:00',
        description: 'TRU Identifies Log4j Vulnerabilities',
        active: true,
      },
      // Step 2
      {
        start: '00:00:30:00',
        description: 'TRU Establishes Communication Channel',
      },
      // Step 3
      {
        start: '00:00:54:00',
        description: 'Proactive Threat Hunt Begins',
      },
      // Step 4
      {
        start: '00:01:14:00',
        description: 'Customer Threat Intelligence Advisory',
      },
      // Step 5
      {
        start: '02:20:23:00',
        description: 'Log4j Detection and Response Runbooks Deployed',
      },
      // Step 6
      {
        start: '03:01:55:00',
        description: 'Customer Threat Intelligence Advisory Update',
      },
      // Step 7
      {
        start: '04:01:30:00',
        description: 'Successful Log4j Exploitation Observed',
      },
      // Step 8
      {
        start: '04:07:00:00',
        description: 'Ad-Hoc scans for Managed Vulnerability Service customers',
      },
      // Step 15
      {
        start: '08:01:30:00', // 259:24
        end: '08:02:30:00', // Approximate ending
        description: 'Inform Customer Base of eSentire Risk Mitigation Actions',
      },
    ],
  };

  // Run the first animation
  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.hideRightArrow();
    AppActions.disableAppScreenshot();

    this.steps = [
      // Step 1
      () => {},
    ];

    this.animations = [
      // Step 1
      [],
    ];

    // Play the first animation
    this.play();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
    AppActions.showRightArrow();
    AppActions.enableAppScreenshot();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-12 timeline-slide ${className}`}>
        {children}
        <div className="content">
          <PlayerTimeline
            events={this.timeline.details}
            unit={this.timeline.unit}
            width={this.timeline.width}
            units={this.timeline.units}
            start={this.timeline.start}
            alternate={this.timeline.alternate}
          />
        </div>
      </div>
    );
  }
}
