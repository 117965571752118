/** @jsx createElement */
// Other
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import { AnimatedSlide } from '../animated-slide';
import { AppActions } from '../app-actions';
import { PlayerTimeline } from '../players/timeline';

@observer
export class Slide16 extends AnimatedSlide {
  // Animation players
  players = {
    timeline: null,
    events: null,
  };

  timeline = {
    unit: 'day', // day, hour, minute
    units: 40,
    start: 0,
    width: 2000,
    details: [
      // Step 1
      {
        start: '00:00:00:00',
        end: '00:00:30:00',
        description: 'ProxyShell Details Emerge at Black Hat 2021',
        active: true,
      },
      // Step 2
      {
        start: '01:21:40:00',
        description: 'Scans Identify Exploitation of ProxyShell is Likely ',
      },
      // Step 3
      {
        start: '04:00:40:00',
        description: 'The TRU Team Examines ProxyShell Scan Data',
      },
      // Step 5
      {
        start: '04:22:02:00',
        description: '29 Customers Proactively Notified',
      },
      // Step 6
      {
        start: '05:14:40:00',
        description: 'ProxyShell Plugins are Released',
      },
      // Step 7
      {
        start: '05:16:30:00',
        description: 'Detection Engineering Backlog Item Created',
      },
      // Step 8
      {
        start: '05:19:15:00',
        description: 'ProxyShell Hunt Group',
      },
      // Step 9
      {
        start: '08:00:01:00',
        description: 'ProxyShell Detection is Fully Deployed',
      },
      // Step 10
      {
        start: '10:16:13:00',
        description: 'The TRU Team Identifies PoC Exploit for ProxyShell',
      },
      // Step 11
      {
        start: '10:20:43:00',
        description: 'The TRU Team Issues a Threat Advisory',
      },
      // Step 12
      {
        start: '12:18:05:00',
        description:
          'SOC Cyber Analysts Identify Exploit Targeting eSentire Customer',
      },
      // Step 13
      {
        start: '18:23:29:00',
        description: 'The TRU Team Issues a Threat Advisory',
      },
      // Step 14
      {
        start: '39:22:40:00',
        end: '39:23:40:00', // Approximate ending
        description: 'The TRU Team Hosts a Customer Webinar',
      },
    ],
  };

  // Run the first animation
  componentDidMount() {
    AppActions.hideBackgroundGrid();
    AppActions.hideRightArrow();
    AppActions.disableAppScreenshot();

    this.steps = [
      // Step 1
      () => {},
    ];

    this.animations = [
      // Step 1
      [],
    ];

    // Play the first animation
    this.play();
  }

  componentWillUnmount() {
    AppActions.showBackgroundGrid();
    AppActions.showRightArrow();
    AppActions.enableAppScreenshot();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-16 timeline-slide ${className}`}>
        {children}
        <div className="content">
          <PlayerTimeline
            events={this.timeline.details}
            unit={this.timeline.unit}
            width={this.timeline.width}
            units={this.timeline.units}
            start={this.timeline.start}
            alternate={this.timeline.alternate}
          />
        </div>
      </div>
    );
  }
}
