import { CATEGORY } from '../../category';
import { Slide01 } from '../../../components/ransomware/slide-01';
import { Slide02 } from '../../../components/ransomware/slide-02';
import { Slide03 } from '../../../components/ransomware/slide-03';
import { Slide04 } from '../../../components/ransomware/slide-04';
import { Slide05 } from '../../../components/ransomware/slide-05';
import { Slide06 } from '../../../components/ransomware/slide-06';
import { Slide07 } from '../../../components/ransomware/slide-07';
import { Slide08 } from '../../../components/ransomware/slide-08';
import { Slide09 } from '../../../components/ransomware/slide-09';
import { Slide10 } from '../../../components/ransomware/slide-10';
import { Slide11 } from '../../../components/ransomware/slide-11';
import { Slide12 } from '../../../components/ransomware/slide-12';
import { Slide13 } from '../../../components/ransomware/slide-13';
import { Slide14 } from '../../../components/ransomware/slide-14';
import { Slide15 } from '../../../components/ransomware/slide-15';
import { Slide16 } from '../../../components/ransomware/slide-16';
import { Slide17 } from '../../../components/ransomware/slide-17';
import { Slide18 } from '../../../components/ransomware/slide-18';
import { Slide19 } from '../../../components/ransomware/slide-19';
import { Slide20 } from '../../../components/ransomware/slide-20';
import { Slide21 } from '../../../components/ransomware/slide-21';
import { Slide22 } from '../../../components/ransomware/slide-22';
import { Slide23 } from '../../../components/ransomware/slide-23';
import { Bug } from '../../../components/svg/bug';
import { Eye } from '../../../components/svg/eye';
import { CheckShield } from '../../../components/svg/check-shield';
import { Bell } from '../../../components/svg/bell';
import { Clock } from '../../../components/svg/clock';

export const COLUMNS = [
  [CATEGORY.INFECTION],
  [CATEGORY.ESCALATION],
  [CATEGORY.INVESTIGATION],
  [CATEGORY.CONTAINMENT],
];

export const ransomwareSlides = [
  {
    active: true,
    selected: true,
    slideOverview: true,

    title: 'Ransomware',
    subtitle: ' Scenario Overview',
    component: Slide01,
  },
  {
    title: 'Ransomware',
    subtitle: 'Beaconing Detected',
    product: 'eSentire MDR for Network',
    time: '00:00:00:00',
    component: Slide02,
    icon: Bug,
    category: CATEGORY.INFECTION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-3.jpg'),
    description:
      'eSentire’s Network Sensor observes and disrupts a number of attempts to reach out to known Command and Control (CnC) servers that are already known to our global network block list (AMP). Following the multiple blocked attempts from a number of hosts, eSentire MDR for Network also identifies and flags one possible malicious file download and subsequent possible beaconing activity from another endpoint that was not blocked or explained by Atlas and needs immediate review.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Inspect Suspect IP Address',
    product: 'eSentire MDR for Network',
    time: '00:00:00:13',
    component: Slide03,
    icon: Eye,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-4.jpg'),
    description:
      'The SOC Analyst immediately shifts their focus to the beaconing activity that is being identified by eSentire MDR for Network and begins the forensic investigation.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Inspect Suspect IP Address',
    product: 'eSentire MDR for Network',
    time: '00:00:00:14',
    component: Slide04,
    appScreenshotUrl: require('../../../assets/ransomware/ran-5.jpg'),
    description:
      'The SOC Analyst reviews asset information enriched from eSentire Atlas Extended Detection and Response (XDR) Platform in the SOCDash to understand the asset’s threat surface and mitigations already in place.  Any technology connected to eSentire Atlas Extended Detection and Response (XDR) Platform will be periodically scanned for asset information useful to security analysts, said information will be extracted, correlated, and normalized, then enriched to incoming signals to help analysts make better decisions during investigations.',
  },
  {
    title: 'Ransomware',
    subtitle: "Assess Client's Situation",
    product: 'eSentire MDR for Network',
    time: '00:00:00:32',
    component: Slide06,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-7.png'),
    description:
      'The SOC Analyst quickly scans recent information known by our system about the client so they are aware of any larger events that this may be a part of.  The Analyst has access to all historical contextual data for the customer to consider possible investigative paths without having to do any additional manual querying in other tools, speeding response time.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Inspect PCAP',
    product: 'eSentire MDR for Network',
    time: '00:00:00:45',
    component: Slide07,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-8.jpg'),
    description:
      'The SOC Analyst pivots to review the full packet capture of the identified traffic utilizing the integrated analysis tool; CloudShark.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Inspect HTTP Activity',
    product: 'eSentire MDR for Network',
    time: '00:00:00:50',
    component: Slide08,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-10.jpg'),
    description:
      'The SOC Analyst quickly filters the traffic to review all HTTP-specific traffic to review for anything suspicious.  Based on URL analysis, the last entry appears to be suspicious in nature.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Inspect HTTP Activity',
    product: 'eSentire MDR for Network',
    time: '00:00:01:10',
    component: Slide09,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-11.jpg'),
    description:
      'From here, the SOC Analyst follows the wire conversation to the host and determines that several factors are suspicious.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Inspect Activity In Analyzer',
    product: 'eSentire MDR for Network',
    time: '00:00:01:30',
    component: Slide10,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-12.jpg'),
    description:
      'The SOC Analyst retrieves a snippet of the full packet capture from the wire conversation in order to replay the traffic in the SOC’s sandbox environment.  This sandbox environment utilized heightened sensitivity to identify any and all activity that could be suspicious.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Inspect Activity In Analyzer',
    product: 'eSentire MDR for Network',
    time: '00:00:01:35',
    component: Slide11,
    appScreenshotUrl: require('../../../assets/ransomware/ran-13.jpg'),
    description:
      'Very quickly, the SOC Analyst can now view the output of this suspicious traffic being replayed in eSentire’s sandbox environment.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Inspect Payload In Suricata',
    product: 'eSentire MDR for Network',
    time: '00:00:01:55',
    component: Slide12,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-14.jpg'),
    description:
      'Within the results, the SOC Analyst has access to multiple highly-tuned IDS engines to inspect the traffic against multiple different vectors.  Initially the SOC Analyst can review the findings from the Suricata engine for additional potential flags not initially identified.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Inspect Payload In Bro',
    product: 'eSentire MDR for Network',
    time: '00:00:02:05',
    component: Slide13,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-15.jpg'),
    description:
      'The SOC Analyst also has access to a Bro IDS post-detonation analysis of the traffic.  This tool provides deeper contextual analysis of protocol versus other (standard) IDS engines and provides the Analyst a unique view into the suspicious traffic.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Confirm Malicious Payload',
    product: 'Unknown',
    time: '00:00:02:08',
    component: Slide14,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-16.jpg'),
    description:
      'After SOC Analyst continues to confirm their suspicion that the traffic is potentially malicious, they pivot to the extracted files, from the submitted PCAP,  and submit the raw files for analysis within the sandbox environment.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Review Drop File Analysis',
    product: 'eSentire MDR for Network',
    time: '00:00:03:25',
    component: Slide15,
    category: CATEGORY.INVESTIGATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-17.jpg'),
    description:
      'After the sandbox analysis is complete from the submitted sample, the SOC Analyst can review the results of the initial payload analysis such as additional Dropped Files.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Isolate Host',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:03:55',
    icon: CheckShield,
    component: Slide16,
    category: CATEGORY.CONTAINMENT,
    appScreenshotUrl: require('../../../assets/ransomware/ran-18.jpg'),
    description:
      'Based on the analysis and the agreed upon policies with the customer, the SOC Analyst moves immediately to isolate the host from the customer network, using eSentire MDR for Endpoint, to prevent any additional damage or spread of the threat.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Create Blocking Rule',
    product: 'eSentire MDR for Network',
    time: '00:00:04:25',
    component: Slide17,
    category: CATEGORY.CONTAINMENT,
    appScreenshotUrl: require('../../../assets/ransomware/ran-19.jpg'),
    description:
      'The SOC Analyst applies new rules to block any other users (or hosts) from downloading or communicating with the newly discovered malicious host.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Alert Client',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    time: '00:00:06:45',
    component: Slide18,
    clientCommunication: true,
    category: CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-20.jpg'),
    description:
      'The SOC Analyst promptly sends the alert to the customer to inform them of the threat we identified, the steps we have taken, and requests acknowledgement from the customer.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Alert Escalation',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    time: '00:00:06:45',
    component: Slide19,
    category: CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-21.jpg'),
    description:
      'The SOC Analyst sends the initial alert to the client. Before the timer expires, the analyst follows up with the client if they have not responded to the alert.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Alert Escalation',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    time: '00:00:09:51',
    component: Slide20,
    clientCommunication: true,
    appScreenshotUrl: require('../../../assets/ransomware/ran-22.jpg'),
    description:
      'The SOC Analyst sends the follow-up communication to the client to confirm that additional investigation is ongoing, looking for additional IoCs. As this happens, the information learned through detailed investigations is being run in log searches for similar activity in case this wasn’t an isolated incident.  Any hits for wider activity would trigger follow up and escalation to an intrusion incident.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Alert Escalation',
    product:
      'eSentire MDR for Endpoint / eSentire MDR for Network / eSentire MDR for Log',
    time: '00:00:09:51',
    component: Slide21,
    clientCommunication: true,
    appScreenshotUrl: require('../../../assets/ransomware/ran-23.jpg'),
    description:
      'The SOC Analyst sends the follow-up communication to the client to confirm that additional investigation is ongoing, looking for additional IoCs.',
  },
  {
    title: 'Ransomware',
    subtitle: 'Send Attack Details',
    product: 'eSentire MDR for Endpoint',
    time: '00:00:14:55',
    component: Slide22,
    clientCommunication: true,
    category: CATEGORY.ESCALATION,
    appScreenshotUrl: require('../../../assets/ransomware/ran-24.jpg'),
    description:
      'The SOC Analyst uses our endpoint visibility to answer more detailed questions for the customer (Explicit process chain, files touched, what was modified on the endpoint, etc.)',
  },
  // Timeline
  {
    title: 'Ransomware',
    subtitle: 'Timeline Summary',
    component: Slide23,
    icon: Clock,
    timelineSummary: true,
    category: CATEGORY.ESCALATION,
  },
];
