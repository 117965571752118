/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';

@observer
export class BackArrow extends Component {
  render() {
    const {
      children,
      className = '',
      color = '#ffffff',
      opacity = 0.5,
      x = 0,
      y = 0,
      size = 24,
      circle = true,
    } = this.props;

    return (
      <svg
        className={className}
        {...{ x, y }}
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={opacity}>
          {circle && (
            <rect
              x="1"
              y="1"
              width="22"
              height="22"
              rx="11"
              stroke={color}
              strokeWidth="2"
            />
          )}
          <path
            d="M17.625 12H6.375"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.625 6.75L6.375 12L11.625 17.25"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </svg>
    );
  }
}
