/** @jsx createElement */
import { observer } from 'mobx-react';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';

// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { observable } from 'mobx';

@observer
export class Slide05 extends AnimatedSlide {
  // Animation players
  players = {
    terminal: null,
    client: null,
    filters: null,
    policies: null,
  };

  // Run the first animation
  componentDidMount() {
    const { terminal, client, filters, policies } = this.players;

    this.steps = [
      () => {
        this.animateProgress(5000);
        this.autoNext(2000);
      },
      () => {
        this.autoNext(2000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: terminal,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
        },
        {
          targets: client,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },

        // Deactivate
        { targets: policies, opacity: 0, duration: 500, complete: deactivate },
      ],

      //
      // Step 2
      //
      [
        {
          targets: policies,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },

        // Deactivate
        { targets: filters, opacity: 0, duration: 500, complete: deactivate },
      ],

      //
      // Step 3
      //
      [
        {
          targets: filters,
          easing: 'easeOutQuad',
          opacity: 1,
          duration: 500,
          begin: activate,
          complete: shimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`InspectAnalysis2 ${className}`}>
        {children}
        <div className="content">
          {/* Permanent filters */}
          <div
            className="player filters"
            data-active="0"
            ref={node => (this.players.filters = node)}
          >
            <PlayerTextBlock
              title={'Permanent filters'}
              width="300px"
              sections={[
                {
                  lines: 5,
                  animate: true,
                  delay: 500,
                },
                {
                  lines: 2,
                  lineColumns: 2,
                },
              ]}
            />
          </div>

          {/* Policies */}
          <div
            className="player policies"
            data-active="0"
            ref={node => (this.players.policies = node)}
          >
            <PlayerTextBlock
              title={'Policies'}
              width="300px"
              sections={[
                {
                  lines: 3,
                  animate: true,
                  delay: 500,
                },
                {
                  lines: 3,
                  lineColumns: 2,
                  animate: true,
                  delay: 750,
                },
              ]}
            />
          </div>

          {/* Client */}
          <div
            className="player client"
            data-active="1"
            ref={node => (this.players.client = node)}
          >
            <PlayerTextBlock
              title={'Client'}
              width="250px"
              showDots={true}
              sections={[
                {
                  title: '10.0.5.38',
                },
              ]}
            />
          </div>

          {/* Computer terminal icon */}
          <div
            className="player terminal"
            data-active="1"
            ref={node => (this.players.terminal = node)}
          >
            <Card IconComponent={Computer} text={'10.0.5.38'} />
          </div>
        </div>
      </div>
    );
  }
}
