/** @jsx createElement */
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { parse } from 'query-string';
import { createElement, Component } from 'react';
import './login.scss';
import { RouteManager } from 'vega-router';
import { AppActions, AppState } from './app-actions';
import { EsentireIcon } from './svg/esentire-icon';
import { Button } from './button';
import { Input } from './input';

@observer
export class Login extends Component {
  @observable error = '';
  @observable password = '';
  timer;

  componentDidMount() {
    if (AppState.authed) {
      RouteManager.transitionTo('/');
    } else {
      AppActions.setShortcutName('Login');
    }
  }

  componentDidUpdate() {
    if (AppState.authed) {
      RouteManager.transitionTo('/');
    }
  }

  handlePassword = value => {
    this.password = value;
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      AppActions.login(value);
    }, 1000);
  };

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`Login ${className}`}>
        <div className="Login__Header">
          <EsentireIcon />
        </div>
        <div className="Login__Main">
          {AppState.authType === 'input' ? (
            this.renderLoginForm()
          ) : (
            <Button onClick={() => (location.href = '/api/v1/auth/saml')}>
              Log In with eSentire SSO
            </Button>
          )}
          {children}
        </div>
      </div>
    );
  }

  renderLoginForm() {
    return (
      <Input
        type="password"
        value={this.password}
        onChange={this.handlePassword}
      />
    );
  }
}
