/** @jsx createElement */
// SVG
import { Card } from '../svg/card';
import { Computer } from '../svg/computer';
import { Warning } from '../svg/warning';
import { Shield } from '../svg/shield';
import { Server } from '../svg/server';
import { Person } from '../svg/person';
// Other
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { createElement, Component, HTMLAttributes } from 'react';
import ReactDOM from 'react-dom';
import { KeyboardShortcuts } from '../keyboard-shortcuts';
import anime from 'animejs';
import { activate, deactivate, shimmer, activateShimmer } from '../helpers';
import { AnimatedSlide } from '../animated-slide';
import { PlayerTextBlock } from '../players/text-block';
import { PlayerCircleIcon } from '../players/circle-icon';

@observer
export class Slide12 extends AnimatedSlide {
  // Animation players
  players = {
    request: null,
    client: null,
  };

  // Run the first animation
  componentDidMount() {
    const { request, client } = this.players;

    this.steps = [
      () => {
        this.animateProgress(3000);
      },
      () => {},
    ];

    this.animations = [
      //
      // Step 1
      //
      [
        {
          targets: client,
          opacity: 1,
          duration: 200,
          delay: 0,
          begin: activateShimmer,
        },
        {
          targets: request,
          opacity: 1,
          duration: 200,
          delay: 1000,
          begin: activateShimmer,
        },
      ],
    ];

    // Play the first animation
    this.play();
  }

  render() {
    const { children, className = '' } = this.props;

    return (
      <div className={`slide-12 ${className}`}>
        {children}
        <div className="content">
          {/* Request */}
          <div
            className="player request"
            data-active="0"
            ref={node => (this.players.request = node)}
          >
            <PlayerTextBlock
              title={'Incoming Request'}
              width={'250px'}
              showDots={true}
              className={''}
              sections={[
                {
                  title: ['additional information'],
                },
              ]}
            />
          </div>

          {/* Client */}
          <div
            className="player client"
            data-active="0"
            ref={node => (this.players.client = node)}
          >
            <Card IconComponent={Person} IconSize={60} text={'CLIENT'} />
          </div>
        </div>
      </div>
    );
  }
}
